var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isBackButtonEnabled
    ? _c(
        "button",
        {
          staticClass: "back-button-navigation__container btn-clear clickable",
          attrs: { type: "button", alt: _vm.ariaDescription },
          on: {
            click: function($event) {
              return _vm.goToLastAction()
            }
          }
        },
        [
          _c("PhCaretLeft", {
            staticClass: "back-button-navigation--icon",
            attrs: { size: 18, alt: _vm.ariaDescription }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }