var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          subtitle: _vm.subtitle,
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.actions
        },
        on: {
          "action-PDF": _vm.getPdf,
          "action-EDIT": function($event) {
            return _vm.$refs.einstellungenModal.open()
          }
        }
      }),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c(
                "div",
                { staticClass: "box__title" },
                [
                  _c("CurrencyLabel", {
                    attrs: {
                      label: "Vermögen heute:",
                      showInlineLabel: "",
                      value: _vm.gesamtStartVermoegen(_vm.scenario)
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "font-bold" }, [
                _vm._v("Alter bei Beginn Ruhestand")
              ]),
              _vm.alterList && _vm.alterList.length
                ? _c("InputSlider", {
                    attrs: {
                      value: _vm.scenario.alterRente + "",
                      values: _vm.alterList
                    },
                    on: {
                      input: function($event) {
                        return _vm.doChanges("alterRente", +$event)
                      }
                    }
                  })
                : _vm._e(),
              !_vm.scenario.ohneKapitalverzehr
                ? _c(
                    "div",
                    { staticClass: "box__title" },
                    [
                      _c("CurrencyLabel", {
                        attrs: {
                          label:
                            "Wunschrente (zu Rentenbeginn berüksichtig Inflation):",
                          showInlineLabel: "",
                          value: _vm.wunschrenteMitInflation(_vm.scenario)
                        }
                      })
                    ],
                    1
                  )
                : _c("div", { staticClass: "box__title" }, [
                    _vm._v(
                      "Monatliche Rente aus Kapitalvermögen (sieh Zahlungsplan)"
                    )
                  ])
            ],
            1
          ),
          _c("div", { staticClass: "dashboard-panel__widgets" }, [
            _c(
              "div",
              { staticClass: "box__container dashboard-panel-widget" },
              [
                _c(
                  "div",
                  { staticClass: "layout__negative-margin--8 pb-3 text-right" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: { isLink: "" },
                        on: {
                          click: function($event) {
                            return _vm.openGoalModal({ zuflussType: "AUSGABE" })
                          }
                        }
                      },
                      [_vm._v(" Ausgabe hinzufügen ")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        attrs: { isLink: "" },
                        on: {
                          click: function($event) {
                            return _vm.openGoalModal({
                              zuflussType: "EINNAHME"
                            })
                          }
                        }
                      },
                      [_vm._v(" Einnahme hinzufügen ")]
                    )
                  ],
                  1
                ),
                _vm.rowsMyGoals && _vm.rowsMyGoals.length
                  ? _c("Table", {
                      attrs: {
                        title: "Meine Einahmen und Verbindlichkeiten",
                        rowId: "id",
                        headers: _vm.headersMyGoals,
                        rows: _vm.rowsMyGoals,
                        rowsPerPage: 10,
                        hidePagination: "",
                        mobileConfig: {},
                        selected: _vm.selectedGoals
                      },
                      on: {
                        selected: _vm.onSelectedMyGoals,
                        "action-EDIT": _vm.editGoal,
                        "action-DELETE": _vm.deleteAction,
                        "click-title": _vm.editGoal
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "chart",
                            fn: function(row) {
                              return [
                                _c("PieChart", {
                                  staticStyle: { width: "40px" },
                                  attrs: {
                                    chartData: _vm.eChartData(row),
                                    customColors: _vm.eChartColors,
                                    isTooltip: false,
                                    height: "40px",
                                    doughnut: ""
                                  }
                                })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        197734938
                      )
                    })
                  : _c("NoData", { attrs: { title: "Meine Ziele" } }),
                (_vm.vermoegen && _vm.vermoegen.length) ||
                (_vm.ausgaben && _vm.ausgaben.length)
                  ? _c("GesamtVermoegen")
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box__container dashboard-panel-widget" },
              [
                _c("div", { staticClass: "mt-3 mb-3" }, [
                  _c(
                    "div",
                    [
                      _c("RetirementChart", {
                        attrs: {
                          data: {
                            data: _vm.apexSeriesWithOriginalWert,
                            series: _vm.apexSeriesWithOriginalWert,
                            xaxis: _vm.apexXaxisCurrentScenario,
                            annotations: _vm.apexAnnotationsCurrentScenario,
                            markers: _vm.apexMarkers,
                            dataLabels: { enabled: false },
                            stroke: { curve: "smooth" },
                            autoUpdateSeries: true
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                attrs: { showSection: _vm.showExpanded1 },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v(" Berechnung benötiges Vermögen ")
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _vm.tilgungsplanVermoegenBenoetigt
                          ? _c("Table", {
                              attrs: {
                                rowId: "jahr",
                                headers: _vm.headersTilgungsplan,
                                rows: _vm.tilgungsplanVermoegenBenoetigt,
                                rowsPerPage: 50,
                                mobileConfig: {}
                              }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                attrs: { showSection: _vm.showExpanded2 },
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v(" Zahlungsplan")
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _vm.tilgungsplanRowsCurrentScenario
                          ? _c("Table", {
                              attrs: {
                                rowId: "jahr",
                                headers: _vm.headersTilgungsplan,
                                rows: _vm.tilgungsplanRowsCurrentScenario,
                                rowsPerPage: 50,
                                mobileConfig: {}
                              }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "goalModal",
              attrs: {
                modalTitle: _vm.goalModalTitle,
                labelButtonConfirm: "Speichern",
                confirmDisabled: !_vm.canSaveGoal
              },
              on: { onConfirmButton: _vm.saveGoal }
            },
            [
              _c("EditForm", {
                ref: "editForm",
                attrs: {
                  goal: _vm.selectedGoal,
                  comboboxEventTypes: _vm.comboboxEventTypes
                },
                on: { isValid: _vm.updateCanSaveGoal }
              })
            ],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "einstellungenModal",
              attrs: {
                modalTitle: "Einstellungen",
                labelButtonConfirm: "Speichern",
                showCancelButton: false
              },
              on: { close: _vm.saveFinancialPlaning }
            },
            [_c("Einstellungen")],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "initModal",
              attrs: {
                modalTitle: "Financial planing erstellen",
                labelButtonConfirm: "Erstellen",
                confirmDisabled: !_vm.canSaveScenario
              },
              on: {
                onConfirmButton: _vm.saveFinancialPlaning,
                onCancelButton: _vm.initModalClosed,
                onCloseButton: _vm.initModalClosed,
                onEscButton: _vm.initModalClosed
              }
            },
            [
              _c("InputField", {
                attrs: {
                  id: "scenario.title",
                  label: "Name des Scenarios",
                  value: _vm.scenario.title,
                  validateUntouched: ""
                },
                on: {
                  input: function($event) {
                    return _vm.doChanges("title", $event)
                  }
                }
              }),
              _c("InputTextArea", {
                attrs: {
                  label: "Notizen",
                  value: _vm.scenario.bemerkung,
                  rows: 5
                },
                on: {
                  input: function($event) {
                    return _vm.doChanges("bemerkung", $event)
                  }
                }
              }),
              !_vm.scenario.geburtsdatum
                ? _c("DatePickerField", {
                    attrs: {
                      label: "Geburtstag",
                      placeholder: "DD.MM.JJJJ",
                      isValueAsString: "",
                      value: _vm.scenario.geburtsdatum
                    },
                    on: {
                      change: function($event) {
                        return _vm.doChanges("geburtsdatum", $event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }