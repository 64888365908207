<template>
  <div class="ghost-loading__block" 
    :class="{
      [`ghost-loading__block--${type}`]: !!type,
    }" 
    :style="style"
  >
    <PhImage v-if="type === TYPE_IMAGE" :size="72" color="#dddddd" />
    <PhDotsThreeVertical v-else-if="type === TYPE_TABLE_ROW" :size="24" color="#dddddd" alt="Icon für weitere Aktionen"/>
  </div>
</template>

<script>
import { PhImage, PhDotsThreeVertical } from 'phosphor-vue';

const TYPE_IMAGE = 'image';
const TYPE_TITLE = 'title';
const TYPE_PARAGRAPH = 'paragraph';
const TYPE_TABLE_ROW = 'table-row';
const TYPE_INPUT = 'form-input';
const TYPE_INPUT_SWITCH = 'form-input-switch';
const TYPE_BUTTON = 'button';
const PROP_TYPE_VALIDS = [TYPE_IMAGE, TYPE_TITLE, TYPE_PARAGRAPH, TYPE_TABLE_ROW, TYPE_INPUT, TYPE_INPUT_SWITCH, TYPE_BUTTON];

function isNumber(val) {
  return typeof val === 'number' || (/^[\d]+$/).test(val);
}

export default {
  props: {
    type: {
      type: String,
      default: null,
      validator: (value) => PROP_TYPE_VALIDS.indexOf(value) >= 0,
    },
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
    opacity: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      TYPE_IMAGE,
      TYPE_TITLE,
      TYPE_PARAGRAPH,
      TYPE_TABLE_ROW,
      TYPE_INPUT,
      TYPE_INPUT_SWITCH,
    };
  },
  computed: {
    style() {
      return {
        width: isNumber(this.width) ? `${this.width}px` : this.width,
        height: isNumber(this.height) ? `${this.height}px` : this.height,
        opacity: this.opacity,
      };
    },
  },
  components: {
    PhImage,
    PhDotsThreeVertical,
  },
}
</script>

<style lang="scss" scoped>
.ghost-loading__block {
  background-color: var(--color-box-neutral); 
  border-radius: 2px;
  display: flex;
  margin: 0 0 8px;
  height: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &.ghost-loading__block--image {
    justify-content: center;
    height: 120px;
  }

  &.ghost-loading__block--title {
    width: 75%;
    height: 24px;
  }

  &.ghost-loading__block--paragraph {
    height: 16px;
  }

  &.ghost-loading__block--table-row {
    justify-content: right;
    height: 40px;
    margin: 0 0 1px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.ghost-loading__block--form-input {
    height: 32px;
  }

  &.ghost-loading__block--form-input-switch {
    max-width: 36px;
    height: 18px;
  }

  &.ghost-loading__block--button {
    min-height: 32px;
  }
}
.app--is-mobile-native-context {
  .ghost-loading__block {
    &.ghost-loading__block--button {
      min-height: 40px;
    }
  }
}
</style>
