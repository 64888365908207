var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", [_vm._v("Profilbild")]),
      _c("ImageUpload", {
        staticClass: "user-picture",
        attrs: {
          tid: _vm._generateTidFromString("user-picture"),
          hasDeleteButton: "",
          image: _vm.userPicture,
          loading: _vm.loading,
          config: _vm.imageUploadConfig,
          disabled: _vm.loading || _vm.disabled,
          uploadButtonText: "Profilbild hochladen"
        },
        on: {
          file: _vm.uploadImage,
          change: _vm.uploadImage,
          delete: _vm.deleteImage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }