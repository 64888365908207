<template>
  <div>
    <BaseModal ref="showOldPoliceNumbersModal" modalTitle="Historische VSNR" 
    :showCloseButton="true" :showCancelButton="false" :showConfirmButton="false"  
    >
    <GhostLoading v-if="loading" type="table" useBoxContainer/>
    <Table class="box__container" v-else
                :headers="headers"
                :rows="rows"
                hidePagination
    >
    </Table>   
    </BaseModal>  

   
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import axios from 'axios';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn} from "@/components/table2/table_util.js";

const config = {
  defaultSpinner: true
}
export default {
    components: {
      BaseModal,
      Table,
      GhostLoading
    },
    
    data() {
      return {
        rows: {          
        },
        loading: false,
      }
    },
    computed: {
      headers() {

    let result = {
      lockedLeft: [
          TextColumn("polNr", "Police Nummer"),
          DateTimeColumn("dateChanged", "Bis"),
      ],
      center: [
      ],
      lockedRight: [
      ],
    };

    return result;
    },
   // rows() {
     // return this.rows;
    //},
    },
    methods: {
      open({vertragId}) {
        this.$refs.showOldPoliceNumbersModal.open();
        this.loading = true;
        axios.get(`${process.env.VUE_APP_API}/insurances/police_nr_history?vertragId=${vertragId}`, config).then(response => {
        if (response.status === 200 && response.data) {
            this.rows = response.data;
        }
        }).finally(()=>{
          this.loading=false;
        })
      },
              
    }
}
</script>
