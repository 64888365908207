<template>
    <div>
        <div class="box__container">
            <div class="box__title">Beschreibungen</div>
            <div v-if="schemaData.isAdvisor || schemaData.isVermoegensverwalter">
                <InputField
                    v-model="factsheetEmail"
                    label="Factsheet E-Mail"
                    validateUntouched
                    @change="updateStore({factsheetEmail: $event})"
                />
                <InputField
                    v-model="factsheetInternet"
                    label="Factsheet Internet"
                    validateUntouched
                    @change="updateStore({factsheetInternet: $event})"
                />

                <InputTextArea
                    label="automatischer Marktkommentar zur Strategie (Der Marktkommentar wird von Ihrer Struktur vorgegeben und ändert sich jedes Quartal.)"
                    v-model="marktKommentarFactsheet"
                    :disabled="!schemaData.isVermoegensverwalter"
                    :rows="marktKommentarFactsheet ? 10 : 1"
                    :autoGrow="!marktKommentarFactsheet"
                    validateUntouched
                    @change="updateStore({marktKommentarFactsheet: $event})"
                />
                <InputTextArea
                    label="Beschreibung der Anlagestrategie (kurze Beschreibung Ihrer Strategie zur Infomation an den Kunden)"
                    v-model="anlageStrategieFactsheet"
                    :rows="anlageStrategieFactsheet ? 10 : 1"
                    :autoGrow="!anlageStrategieFactsheet"
                    validateUntouched
                    @change="updateStore({anlageStrategieFactsheet: $event})"
                />
                <InputTextArea
                    label="Vorstellung des Strategieberaters (kurze Beschreibung des Strategieberaters/Advisors)"
                    v-model="strategieBeraterFactsheet"
                    :rows="strategieBeraterFactsheet ? 10 : 1"
                    :autoGrow="!strategieBeraterFactsheet"
                    validateUntouched
                    @change="updateStore({strategieBeraterFactsheet: $event})"
                />
                <InputTextArea
                    label="Portfoliokommentar zur Strategie (für Quartalsberichte und Factsheet)"
                    v-model="portfolioKommentarFactsheet"
                    :rows="portfolioKommentarFactsheet ? 10 : 1"
                    :autoGrow="!portfolioKommentarFactsheet"
                    validateUntouched
                    @change="updateStore({portfolioKommentarFactsheet: $event})"
                />
                <InputTextArea
                    v-model="schlusssatzPortfoliokommentar"
                    :rows="schlusssatzPortfoliokommentar ? 10 : 1"
                    :autoGrow="!schlusssatzPortfoliokommentar"
                    disabled
                    @change="updateStore({schlusssatzPortfoliokommentar: $event})"
                />
            </div>
            <div v-else>
                <div class="label-title">Marktkommentar zur Strategie:</div>
                <div>{{ this.marktKommentarFactsheet }}</div>
                <div class="label-title">Anlagestrategie:</div>
                <div>{{ this.anlageStrategieFactsheet }}</div>
                <div class="label-title">Strategieberater:</div>
                <div>{{ this.strategieBeraterFactsheet }}</div>
                <div class="label-title">Portfoliokommentar zur Strategie:</div>
                <div>{{ this.portfolioKommentarFactsheet }}</div>
                <br />
                <div>{{ this.schlusssatzPortfoliokommentar }}</div>
            </div>
        </div>

        <BaseModal
            ref="changeAllMarktkommentarModal"
            modalTitle="Marktkommentar bei allen Vermögensverwaltungen ändern"
            labelButtonConfirm="Überschreiben"
            @onConfirmButton="saveKommentarAll()"
        >
            <InputTextArea v-model="changeAllMarktkommentarText" :autoGrow="true" />
        </BaseModal>
    </div>
</template>

<script>
import InputTextArea from "@/components/core/forms/InputTextArea.vue"
import VV_TYPES from "@/store/vermoegensverwaltung/types"
import InputField from "@/components/core/forms/InputField.vue"
import BaseModal from "@/components/core/BaseModal.vue"
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js"
import validator from "@/mixins/validator";

import EVENT_BUS, { VV_BUTTON_CHANGE_KOMMENTAR_ALL } from '@/event-bus';

export default {
    mixins: [mixin, validator],
    validators: {},
    components: {
        InputTextArea,
        InputField,
        BaseModal,
    },
    data() {
        return {
            factsheetEmail: "",
            factsheetInternet: "",
            marktKommentarFactsheet: "",
            anlageStrategieFactsheet: "",
            strategieBeraterFactsheet: "",
            portfolioKommentarFactsheet: "",
            schlusssatzPortfoliokommentar: "",
            changeAllMarktkommentarText: "",
        }
    },
    mounted() {
        this.registerEventBus();
        this.initData()
        this.updateWarnings(false);
    },
    destroyed() {
        this.unregisterEventBus();
    },
    watch: {
        schemaData(value) {
            this.initData()
        },
    },
    computed: {
    },
    methods: {
        registerEventBus() {
            EVENT_BUS.$off(VV_BUTTON_CHANGE_KOMMENTAR_ALL);
            EVENT_BUS.$on(VV_BUTTON_CHANGE_KOMMENTAR_ALL, () => {
                this.openKommentarAllModal()
            });
        },
        unregisterEventBus() {
            EVENT_BUS.$off(VV_BUTTON_CHANGE_KOMMENTAR_ALL);
        },
        saveKommentarAll() {
            this.$store
                .dispatch(VV_TYPES.ACTIONS.CHANGE_MARKTKOMMENTAR_ALL, {
                    kommentarNeu: this.changeAllMarktkommentarText,
                })
                .then((this.marktKommentarFactsheet = this.changeAllMarktkommentarText))
        },
        openKommentarAllModal() {
            this.changeAllMarktkommentarText = ""
            this.$refs.changeAllMarktkommentarModal.open()
        },
        initData() {
            if (this.schemaData) {
                /**
                 * NOTE:
                 * if for example factsheetEmail is different to the data.factsheetEmail this would call 
                 * the "value" watcher in the inputfield and remove validation errors
                 */
                this.factsheetEmail = this.schemaData.factsheetEmail || ""
                this.factsheetInternet = this.schemaData.factsheetInternet || ""
                this.marktKommentarFactsheet = this.schemaData.marktKommentarFactsheet || ""
                this.anlageStrategieFactsheet = this.schemaData.anlageStrategieFactsheet || ""
                this.strategieBeraterFactsheet = this.schemaData.strategieBeraterFactsheet || ""
                this.portfolioKommentarFactsheet = this.schemaData.portfolioKommentarFactsheet || ""
                this.schlusssatzPortfoliokommentar = this.schemaData.schlusssatzPortfoliokommentar || ""
            }
        },
    },
}
</script>

<style scoped>
.label-title {
    font-weight: 600 !important;
    margin-top: 1rem;
}
</style>