var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.title, id: _vm.$appNavigation.currentOptionMenuId }
      }),
      _c("div", { staticClass: "flex-container mb-4" }, [
        _c(
          "div",
          { staticClass: "flex-table-item mr-4" },
          [
            _vm.loading
              ? _c("GhostLoading", {
                  attrs: {
                    type: "block",
                    config: { block: { height: 24 } },
                    repeat: Object.keys(_vm.labels).length
                  }
                })
              : _c(
                  "div",
                  [
                    _c("dl", { staticClass: "key-value--item" }, [
                      _c("dt", { staticClass: "value--item-label" }, [
                        _vm._v("Testergebnis:")
                      ]),
                      _c("dd", { staticClass: "value--item-value" }, [
                        !this.suitabilityResult
                          ? _c("span", [_vm._v("-")])
                          : this.suitabilityResult.gesamt_test
                          ? _c("span", { staticClass: "color-success" }, [
                              _vm._v("Positiv")
                            ])
                          : _c("span", { staticClass: "color-danger" }, [
                              _vm._v("Negativ")
                            ])
                      ])
                    ]),
                    _vm._l(_vm.labels, function(label, key) {
                      return _c(
                        "dl",
                        { key: key, staticClass: "key-value--item" },
                        [
                          _c("dt", { staticClass: "value--item-label" }, [
                            _vm._v(_vm._s(label + ": "))
                          ]),
                          _c("dd", { staticClass: "value--item-value" }, [
                            _vm._v(
                              _vm._s(_vm.values[key] ? _vm.values[key] : "-")
                            )
                          ])
                        ]
                      )
                    })
                  ],
                  2
                )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex-legidocs-item" },
          [
            _vm.loading
              ? _c("GhostLoading", {
                  attrs: { type: "block", config: { block: { height: 100 } } }
                })
              : _c(
                  "div",
                  [
                    _c("LegiDocsView", {
                      attrs: {
                        signatureAuthor: _vm.signatureAuthor,
                        kunde: _vm.kunde,
                        zusaetzlichePersonen: _vm.zusaetzlichePersonen
                      }
                    })
                  ],
                  1
                )
          ],
          1
        )
      ]),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Risikoprüfung 1",
          id: _vm.$appNavigation.currentOptionMenuId,
          showBackButton: false
        }
      }),
      _c("div", { staticClass: "flex-container mb-4" }, [
        _c(
          "div",
          { staticClass: "risikopruefung-column" },
          [
            _c("h4", [_vm._v("Finanzielle Verhältnisse")]),
            _vm.loading
              ? _c("GhostLoading", {
                  attrs: {
                    type: "block",
                    config: { block: { height: 24 } },
                    repeat: _vm.fv.length
                  }
                })
              : _vm._l(_vm.fv, function(field) {
                  return _c(
                    "dl",
                    { key: field.label, staticClass: "key-value--item" },
                    [
                      _c("dt", { staticClass: "value--item-label" }, [
                        _vm._v(_vm._s(field.label))
                      ]),
                      _c(
                        "dd",
                        {
                          staticClass: "value--item-value",
                          class: field.cssClass
                        },
                        [_vm._v(_vm._s(field.value))]
                      )
                    ]
                  )
                })
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "risikopruefung-column" },
          [
            _c("h4", [_vm._v("Anlageziele")]),
            _vm.loading
              ? _c("GhostLoading", {
                  attrs: {
                    type: "block",
                    config: { block: { height: 24 } },
                    repeat: _vm.anlageziele.length
                  }
                })
              : _vm._l(_vm.anlageziele, function(field) {
                  return _c(
                    "dl",
                    { key: field.label, staticClass: "key-value--item" },
                    [
                      _c("dt", { staticClass: "value--item-label" }, [
                        _vm._v(_vm._s(field.label))
                      ]),
                      _c(
                        "dd",
                        {
                          staticClass: "value--item-value",
                          class: field.cssClass
                        },
                        [_vm._v(_vm._s(field.value))]
                      )
                    ]
                  )
                })
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "risikopruefung-column" },
          [
            _c("h4", [_vm._v("Risikobereitschaft")]),
            _vm.loading
              ? _c("GhostLoading", {
                  attrs: {
                    type: "block",
                    config: { block: { height: 24 } },
                    repeat: _vm.risikobereitschaft.length
                  }
                })
              : _vm._l(_vm.risikobereitschaft, function(field) {
                  return _c(
                    "dl",
                    { key: field.label, staticClass: "key-value--item" },
                    [
                      _c("dt", { staticClass: "value--item-label" }, [
                        _vm._v(_vm._s(field.label))
                      ]),
                      _c(
                        "dd",
                        {
                          staticClass: "value--item-value",
                          class: field.cssClass
                        },
                        [_vm._v(_vm._s(field.value))]
                      )
                    ]
                  )
                })
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "risikopruefung-column" },
          [
            _c("h4", [_vm._v(" Verlusttragfähigkeit")]),
            _vm.loading
              ? _c("GhostLoading", {
                  attrs: {
                    type: "block",
                    config: { block: { height: 24 } },
                    repeat: _vm.verlustfaehigkeit.length
                  }
                })
              : _vm._l(_vm.verlustfaehigkeit, function(field) {
                  return _c(
                    "dl",
                    { key: field.label, staticClass: "key-value--item" },
                    [
                      _c("dt", { staticClass: "value--item-label" }, [
                        _vm._v(_vm._s(field.label))
                      ]),
                      _c(
                        "dd",
                        {
                          staticClass: "value--item-value",
                          class: field.cssClass
                        },
                        [_vm._v(_vm._s(field.value))]
                      )
                    ]
                  )
                })
          ],
          2
        )
      ]),
      _c("SignaturenVergleichCards", {
        staticClass: "mb-4",
        attrs: { loading: _vm.loadingSignatures },
        on: {
          rejected: _vm.onRejected,
          signatureAuthor: function($event) {
            return _vm.onNewSignatureAuthor($event)
          }
        }
      }),
      _c(
        "div",
        [
          _c("PageHeaderTitleNavigation", {
            attrs: {
              title: "Überprüfung und Annahme",
              id: _vm.$appNavigation.currentOptionMenuId,
              showBackButton: false
            }
          }),
          _c(
            "div",
            { staticClass: "flex-container mb-4" },
            [
              _c("InputRadioBoxGroup", {
                staticClass: "flex-radiobox-item",
                attrs: {
                  title: "Prüfungsstatus",
                  values: _vm.pruefungsstatusValues,
                  labelClass: "font-bold"
                },
                model: {
                  value: _vm.pruefungsErgebnis,
                  callback: function($$v) {
                    _vm.pruefungsErgebnis = $$v
                  },
                  expression: "pruefungsErgebnis"
                }
              }),
              _vm.pruefungsErgebnis === "ABGELEHNT"
                ? _c("InputTextArea", {
                    staticClass: "flex-textarea-item",
                    attrs: {
                      label: "Begründung des negativen Testergebnisses",
                      autoGrow: ""
                    },
                    model: {
                      value: _vm.suitabilityResult.bemerkungText,
                      callback: function($$v) {
                        _vm.$set(_vm.suitabilityResult, "bemerkungText", $$v)
                      },
                      expression: "suitabilityResult.bemerkungText"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "button-container" },
            [
              _vm.pruefungsErgebnis === "ABGELEHNT"
                ? _c("InputToggleSwitch", {
                    attrs: {
                      label: "Beratungsmappe für Nachbearbeitung kopieren",
                      inLineLabel: "",
                      validateUntouched: ""
                    },
                    on: { change: _vm.rebuildCommentText },
                    model: {
                      value: _vm.suitabilityResult.pruefungCopyMappe,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.suitabilityResult,
                          "pruefungCopyMappe",
                          $$v
                        )
                      },
                      expression: "suitabilityResult.pruefungCopyMappe"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "button-container" },
        [
          _c("BaseButton", {
            attrs: {
              label: "Überprüfung abschließen",
              disabled: !!_vm.submitDisabled
            },
            on: { click: _vm.save }
          }),
          _c("BaseButton", {
            attrs: { label: "Weiter", isSecondary: "" },
            on: { click: _vm.goToNextMappe }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }