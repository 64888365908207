<template>
    <div v-if="showAddImage">
        <tippy to="addImageTrigger" arrow="true" trigger="click" interactive="true" multiple>
              <h5>Bild</h5>
              <BaseButton label="Aus Datei" @click="onAddImageFromFile()">
                  Aus Datei
              </BaseButton>
              <BaseButton label="Aus URL" @click="showAddImageFromUrl()">
                  Aus URL
              </BaseButton>
        </tippy>

        <input
            aria-label="Datei hochladen"
            ref="imageFileInput"
            :disabled="disabled"
            type="file"
            class="input-hidden"
            accept="image/png,image/jpeg,image/gif"
            @change="onAddImage($event)"
        />

        <BaseToolbarImageURL
            v-if="showUrlModal" 
            :linkForm="linkForm"
            @close="showUrlModal = false"
            @onConfirmButton="onAddImageFromUrl" />

        <BaseToolbarButton
            name="addImageTrigger"
            title="Image"
            :icon="icon"
            :disabled="disabled"
            class="mr-0 tb-image-button"
            content="Bild"
            v-tippy="{ arrow: true, multiple: true }"
        >
        </BaseToolbarButton>
    </div>
</template>

<script>
import {PhImage} from "phosphor-vue"
import BaseToolbarButton from "../base-components/BaseToolbarButton.vue"
import BaseButton from "@/components/core/BaseButton.vue"
import {loadImagePropertiesOfBase64} from "../custom-extensions/CustomImage"
import BaseToolbarImageURL from "../base-components/BaseToolbarImageURL.vue";

export default {
    components: {
        BaseToolbarButton,
        BaseButton,
        BaseToolbarImageURL
    },

    props: {
        editor: {},
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            showUrlModal: false,
            linkForm: null,
            
        }
    },

    computed: {
        icon() {
            return PhImage
        },
        showAddImage() {
            return !this.editor?.isActive("image")
        },
    },

    methods: {
        onAddImageFromFile() {
            this.$refs["imageFileInput"].click()
        },
        onAddImage(event) {
            const files = event.target.files
            if (FileReader && files && files.length) {
                const fr = new FileReader()
                fr.onload = () => {
                    loadImagePropertiesOfBase64(fr.result, files[0].name).then((imageProps) => {
                        this.editor
                            .chain()
                            .focus()
                            .setImage(imageProps)
                            .run()
                    })
                }
                fr.readAsDataURL(files[0])
            }
        },
        showAddImageFromUrl() {
            this.showUrlModal = true

        },
        onAddImageFromUrl(linkform) {
            this.editor
                    .chain()
                    .focus()
                    .setImage({src: linkform.url})
                    .run()
        }
    },
}
</script>

<style scoped>
.tb-image-button {
    position: relative;
    overflow: hidden;
}

.input-hidden {
    opacity: 0;
    position: absolute;
    left: 0;
    width: 0px;
    cursor: pointer;
}
</style>
