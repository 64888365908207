var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.editor
    ? _c(
        "div",
        { staticClass: "html-editor__container" },
        [
          _c(
            "div",
            { staticClass: "html-editor__menu layout__negative-margin--8" },
            [
              _c("TBBold", {
                attrs: { editor: _vm.editor, disabled: _vm.disableAllControls }
              }),
              _c("TBItalic", {
                attrs: { editor: _vm.editor, disabled: _vm.disableAllControls }
              }),
              _c("TBUnderline", {
                attrs: { editor: _vm.editor, disabled: _vm.disableAllControls }
              }),
              _vm.showMoreToolbarControllers
                ? [
                    _c("TBTextAlignLeft", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBTextAlignCenter", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBTextAlignRight", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBTextAlignJustify", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBUnorderedList", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBOrderedList", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBHorizontalRule", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBImage", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBImageEdit", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBLink", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBUnlink", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBAddTable", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBEditTable", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBUndo", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBRedo", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    })
                  ]
                : _vm._e(),
              _vm.showFontParams
                ? [
                    _c("TBFontSize", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBFontFamily", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBFontHeight", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    }),
                    _c("TBFontColor", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    })
                  ]
                : _vm._e(),
              _vm._t("controlsMixInline", null, { editor: _vm.editor }),
              _vm.isTest && false
                ? [
                    _c("TBLayout", {
                      staticClass: "hide-on-small-screen",
                      attrs: {
                        editor: _vm.editor,
                        disabled: _vm.disableAllControls
                      }
                    })
                  ]
                : _vm._e(),
              _vm._t("controls", null, { editor: _vm.editor })
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "mb-3 mt-3 flex gap-1" },
            [
              _vm.isPreviewButton
                ? _c("TBPreviewerToggle", {
                    attrs: { disabled: _vm.isPreviewButtonDisabled },
                    on: { input: _vm.handleTogglePreview },
                    model: {
                      value: _vm.isPreviewVisible,
                      callback: function($$v) {
                        _vm.isPreviewVisible = $$v
                      },
                      expression: "isPreviewVisible"
                    }
                  })
                : _vm._e(),
              _vm.textPlaceholderOptions
                ? _c("TBPlaceholderOptions", {
                    staticClass: "hide-on-small-screen",
                    attrs: {
                      editor: _vm.editor,
                      disabled: _vm.disableAllControls,
                      textPlaceholderOptions: _vm.textPlaceholderOptions
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("div", [_vm._t("controlsLine", null, { editor: _vm.editor })], 2),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isPreviewVisible,
                  expression: "!isPreviewVisible"
                }
              ],
              staticClass: "html-editor__content mt-3"
            },
            [
              _c("editor-content", {
                ref: "theEditor",
                attrs: { editor: _vm.editor }
              })
            ],
            1
          ),
          _vm.editorEmpty && _vm.showEmptyValidation
            ? [
                _c("div", { staticClass: "input-forms__errors-container" }, [
                  _c("div", { staticClass: "fc-form-danger" }, [
                    _vm._v(" " + _vm._s(_vm.emptyValidationMessage) + " ")
                  ])
                ])
              ]
            : _vm._e(),
          _vm.isPreviewVisible
            ? _c("div", { staticClass: "mt-3" }, [
                _vm.previewHTML
                  ? _c("div", {
                      staticClass:
                        "html-editor__preview html-editor__paragraph--clean-margin",
                      domProps: { innerHTML: _vm._s(_vm.previewHTML) }
                    })
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.cssStyle
            ? [_c("VStyle", [_vm._v(" " + _vm._s(_vm.cssStyle) + " ")])]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }