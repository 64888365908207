import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import FinancialCalculator from '@/components/retirementScenario/financialCalculator';
import { parse } from '@/helpers/number-formatter';

const mixin = {
    mixins: [],
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            isIntern: CORE_TYPES.GETTERS.IS_INTERN,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
            screenWidth: CORE_TYPES.GETTERS.SCREEN_WIDTH,
            isFa: CORE_TYPES.GETTERS.IS_FA,
        }),
        suffixGesamt() {
          return this.screenWidth == 'xs' ? '(ges.)' : '(gesamt)';
        },
    },
    methods: {
        isNumber(val) {
            return (`${val}`)[val.length - 1] === '€' || !isNaN(FinancialCalculator.convertStringToNumber(val));
        },
        convertStringToNumber(val) {
            return this.isNumber(val) ? FinancialCalculator.convertStringToNumber(String(val).replace(' €', '')) : val;
        },
        getDataFromLabels(data) {
            if (data === undefined) {
              return [];
            }
        
            const filteredKeys = JSON.parse(JSON.stringify(Object.keys(data)
              .filter(key => key !== 'LABEL_DEPOT_WERTENTWICKLUNG' && key !== 'LABEL_SALDO_BESTAND' && key !== 'LABEL_DEPOTAUSZUG_SUMME')));
            const result = filteredKeys
              .map(key => [...data[key], key]);
            const summe = [...data['LABEL_DEPOTAUSZUG_SUMME']]
            const saldoBestand = data['LABEL_SALDO_BESTAND'] && [...data['LABEL_SALDO_BESTAND']]
        
            if (saldoBestand) {
              saldoBestand[1] = this.convertStringToNumber(saldoBestand[1]) + (this.isVersicherungVermoegen && !isNaN(this.rueckkaufswertTotalAmount) && this.rueckkaufswertTotalAmount || 0)
              result.push(saldoBestand)
            } else if (summe) {
              summe[1] = this.convertStringToNumber(summe[1]) + (this.isVersicherungVermoegen && !isNaN(this.rueckkaufswertTotalAmount) && this.rueckkaufswertTotalAmount || 0)
              result.push(summe)
            }
        
            const dwe = data['LABEL_DEPOT_WERTENTWICKLUNG'];
            if (dwe && dwe.length > 1 && dwe[0] ) {
              const val = dwe[1];
              let values = [val]
              if (val.includes('/')) {
                values = val.split(/\//);
              } else {
                values = val.replace(')', '').split(/\(/);
              }
              result.push([`Wertentwicklung ${this.suffixGesamt}:`, values[0], 'LABEL_DEPOT_WERTENTWICKLUNG', values[0].includes('%')]);
              if (values.length > 1) {
                result.push(['Wertentwicklung p.a.:', values[1], 'LABEL_DEPOT_WERTENTWICKLUNG', values[1].includes('%')]);
              }
            }
        
            return result;
        },
        getBadgesClassCategory(sum) {
            if (!this.depotauszugGuvFarbe) {
              return '';
            }
      
            if (!sum[2] || sum[2] === 'LABEL_SALDO_EINAUS') {
              return '';
            }
      
            return this.getColorClassBasedOnValue(sum[1]);
          },
          getColorClassBasedOnValue(value) {
            const doubleValue = parse(value);
            if (doubleValue > 0) {
              return "color-success";
            } else if (doubleValue < 0) {
              return "color-danger"
            }
            return "text-color";
          },
    },
}

export default mixin;