import { ColorSchemeChangedEvent } from "@/configs/color-config";

const undraw = {
  props: {
    textColorPropertyName: {
      type: String,
      default: '--color-text'
    },
    colorPropertyName: {
      type: String,
      default: '--color-primary'
    },
    secondaryColorPropertyName: {
      type: String,
      default: '--color-box'
    },
    thirdColorPropertyName: {
      type: String,
      default: '--color-background-secondary'
    },
  },
  data() {
    return {
      textColor: null,
      color: null,
      secondColor: null,
      thirdColor: null,
    };
  },
  watch: {
    textColorPropertyName: 'setColors',
    colorPropertyName: 'setColors',
    secondaryColorPropertyName: 'setColors',
    thirdColorPropertyName: 'setColors',
  },
  methods: {
    setColors() {
      requestAnimationFrame(() => {
        this.textColor = document.documentElement.style.getPropertyValue(this.textColorPropertyName);
        this.color = document.documentElement.style.getPropertyValue(this.colorPropertyName);
        this.secondColor = document.documentElement.style.getPropertyValue(this.secondaryColorPropertyName);
        this.thirdColor = document.documentElement.style.getPropertyValue(this.thirdColorPropertyName);
      })
    },
  },
  mounted() {
    window.addEventListener(ColorSchemeChangedEvent.name, this.setColors);
    this.setColors();
  },
  destroyed() {
    window.removeEventListener(ColorSchemeChangedEvent.name, this.setColors);
  },
}

export default undraw