<template>
  <BaseModal
    ref="modal"
    :showDefaultButtons="false"
    :actions="baseModalActions"
    @close="onClose"
    @action-RESTORE_DEFAULT="restoreDefault()"
    @action-CLOSE="saveConfigEdited()"
    @onCloseButton="saveConfigEdited()"
  >
    <template #modalTitle>
      <div class="dashboard-panel-config-modal--title-container clearfix">
        <span class="dashboard-panel-config-modal--title">Verwalten</span>
      </div>
    </template>

    <template #modalHeaderBottom>
      <DashboardPanelConfigTabs v-if="ignoreUserLevelConfig !== true" :disabled="loading" 
        :selected="userLevelSaveConfig" @tabSelected="onTabSelected($event.key)" />
    </template>

    <template #default>
      <GhostLoading v-if="loading">
        <Block height="40px" :style="{ margin: '0 0 1px 0', }" />
        <Block height="40px" :style="{ margin: '0 0 1px 0', }" />
        <Block height="40px" :style="{ margin: '0 0 1px 0', }" />
        <Block height="40px" :style="{ margin: '0 0 1px 0', }" />
        <Block height="40px" :style="{ margin: '0 0 1px 0', }" />
        <Block height="40px" class="mb-0" />
      </GhostLoading>
      <div v-else-if="isSmallScreen">
        <SortableList :items="widgetsIntern" @orderChanged="orderChanged($event)">
          <ul class="dashboard-panel-config__items list-bordered" data-sortable-container>
            <template v-for="widget in widgetsIntern">
              <SortableListItemDeleteConfirm
                :key="widget.title"
                :visible="isVisible(widget)"
                :removable="isRemovable(widget)"
                :title="widget.title"
                :showConfirmRemove="confirmRemove === widget"
                @onRemove="remove(widget)"
                @onConfirmRemove="confirmRemove ? confirmRemove = null : confirmRemove = widget">
              </SortableListItemDeleteConfirm>
            </template>
          </ul>
        </SortableList>

        <div v-if="widgetsToSelect.length" class="dashboard-panel-config__to-add__container">
          <h3 class="box__title">Auswahl</h3>
          <ul class="dashboard-panel-config__items list-bordered">
            <template v-for="(widget, index) in widgetsToSelect">
              <li v-if="isVisible(widget)" :key="index" class="dashboard-panel-config__item list-bordered-item">
                <div class="dashboard-panel-config__item--actions sortable-list-item__actions">
                  <button type="button" @click="add(widget)" class="btn-clear clickable color-success">
                    <PhPlusCircle :size="18" weight="fill" />
                  </button>
                </div>
                <span class="dashboard-panel-config__item--label">{{ widget.title }}</span>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <div v-else :key="userLevelSaveConfig">
        <div v-if="widgetsIntern.length" class="dashboard-panel-config__to-added__container">
          <ul class="dashboard-panel-config__items list-bordered">
            <template v-for="widget in widgetsIntern">
              <li v-if="isVisible(widget)" :key="widget.title" class="dashboard-panel-config__item list-bordered-item">
                <span class="dashboard-panel-config__item--label">{{ widget.title }}</span>
                <div class="dashboard-panel-config__item--actions">
                  <button v-if="isRemovable(widget)" type="button" @click="remove(widget)" class="btn-clear clickable" :alt="ariaDescription">
                    <PhTrashSimple :size="16" :alt="ariaDescription"/>
                  </button>
                </div>
              </li>
            </template>
          </ul>
        </div>

        <div v-if="widgetsToSelect.length" class="dashboard-panel-config__to-add__container">
          <h3 class="box__title">Auswahl</h3>
          <ul class="dashboard-panel-config__items list-bordered">
            <template v-for="(widget, index) in widgetsToSelect">
              <li v-if="isVisible(widget)" :key="index" class="dashboard-panel-config__item list-bordered-item">
                <span class="dashboard-panel-config__item--label">{{ widget.title }}</span>
                <div class="dashboard-panel-config__item--actions">
                  <button type="button" @click="add(widget)" class="btn-clear clickable">
                    <PhPlus />
                  </button>
                </div>
              </li>
            </template>
          </ul>
        </div>

        <div v-if="!widgetsIntern.length && !widgetsToSelect.length" class="text-centered">Keine Daten</div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import CORE_TYPES from '@/store/core/types';

import { PhTrashSimple, PhPlus, PhPlusCircle } from 'phosphor-vue';
import BaseModal from '@/components/core/BaseModal.vue';
import DashboardPanelConfigTabs from '@/components/dashboard/DashboardPanelConfigTabs.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import FC_CONFIG from '@/configs/fcConfig';
import SortableList from '@/components/core/SortableList.vue';
import SortableListItemDeleteConfirm from '@/components/core/SortableListItemDeleteConfirm.vue';

import { removeInvalidWidget, assignWidget, contentToSave, } from '@/components/dashboard/dashboard-panel-utils';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    widgets: {
      type: Array,
      default: () => [],
    },
    externalConfig: {
      type: Array,
      default: () => [],
    },
    hiddenCards: {
      type: Array,
      default: () => [],
    },
    ignoreUserLevelConfig: {
      type: Boolean,
      default: false,
    },
    ariaDescription: {
      type: String,
      default: 'Icon'
    }, 
  },
  emits: ['saved'],
  data() {
    return {
      loading: false,
      widgetsEdited: {},
      widgetsIntern: [],
      widgetsToSelect: [],
      widgetsRemoved: [],
      userLevelSaveConfig: null,
      confirmRemove: null,
    };
  },
  computed: {
    ...mapGetters({
      fcConfigDashboardPanelConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_DASHBOARD_PANEL,
      fcConfigByUserLevel: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_BY_USER_LEVEL,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN
    }),
    dashboardPanelConfig() {
      const { id, userLevelSaveConfig, } = this;
      return this.fcConfigByUserLevel(FC_CONFIG.DASHBOARD_PANEL_CONFIG, id, userLevelSaveConfig);
    },
    savedWidgets() {
      const savedConfig = this.dashboardPanelConfig;

      if (!this.id || !savedConfig) {
        return null;
      }

      if (!savedConfig?.content) {
        return null;
      }

      return JSON.parse(savedConfig.content);
    },
    configuredWidgets() {
      const { widgets, savedWidgets, externalConfig, } = this;
      return removeInvalidWidget(assignWidget(widgets, savedWidgets, externalConfig));
    },
    widgetsNeverConfigured() {
      return this.widgets.filter(widget => !this.configuredWidgets || !this.configuredWidgets.some(cw => cw.name === widget.name)) || [];
    },
    baseModalActions() {
      return [ 
        BaseModalSimpleAction('RESTORE_DEFAULT', 'Zurücksetzen')
          .withDisabled(() => !this.widgetsIntern.length && !this.widgetsToSelect.length)
          .withPrimary(() => false),

        BaseModalSimpleAction('CLOSE', 'Schließen')
          .withDisabled(() => !this.widgetsIntern.length && !this.widgetsToSelect.length)
          .withVisibleOnSmallScreen(() => false)
      ];
    },
  },
  watch: {
    widgetsIntern() {
      this.setWidgetsToSelect();
    },
  },
  methods: {
    init() {
      const configuredWidgets = this.userLevelSaveConfig in this.widgetsEdited ? [ ...this.widgetsEdited?.[this.userLevelSaveConfig], ] : [ ...this.configuredWidgets || [], ...this.widgetsNeverConfigured, ];
      this.$set(this, 'widgetsIntern', [ ...configuredWidgets.filter(w => w.removed !== true), ]);
      this.$set(this, 'widgetsRemoved', configuredWidgets.filter(w => w.removed === true) || []);
    },
    open(initialUserLevel) {
      this.$refs.modal.open();
      this.onTabSelected(initialUserLevel);
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.widgetsEdited = {};
      this.userLevelSaveConfig = null;
    },
    async onTabSelected(userLevel) {
      this.userLevelSaveConfig = userLevel;
      await this.findFCConfigByUserLevel(userLevel);
      this.init();
    },
    async findFCConfigByUserLevel(userLevel) {
      try {
        const { id, } = this;
        const payload = {
          configId: id,
          configType: FC_CONFIG.DASHBOARD_PANEL_CONFIG,
          userLevel,
          forceReload: true,
        };

        this.loading = true;
        await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG_BY_USER_LEVEL, payload);
      } finally {
        this.loading = false;
      }
    },
    isVisible(widget) {
      return 'visible' in widget ? widget.visible() : (this.hiddenCards?.length ? !this.hiddenCards.includes(widget.name) : true);
    },
    isRemovable(widget) {
      return 'removable' in widget ? widget.removable() : true;
    },
    remove(widget) {
      const widgetIndex = this.widgetsIntern.findIndex(w => w.name === widget.name);
      if(widgetIndex < 0) return;
      this.widgetsIntern.splice(widgetIndex, 1);
      this.widgetsRemoved.push({
        ...widget,
        removed: true,
      });

      this.setWidgetsEdited();
      this.confirmRemove = null;
    },
    add(widget) {
      delete widget.removed;
      this.widgetsIntern.push(widget);
      const widgetIndex = this.widgetsRemoved.findIndex(w => w.name === widget.name);
      this.widgetsRemoved.splice(widgetIndex, 1);

      this.setWidgetsEdited();
    },
    setWidgetsEdited() {
      this.$set(this.widgetsEdited, this.userLevelSaveConfig, [ ...this.widgetsIntern, ...this.widgetsRemoved, ]);
    },
    setWidgetsToSelect() {
      this.$set(this, 'widgetsToSelect', this.widgets.filter(w => this.widgetsIntern.findIndex(iw => iw.name === w.name) < 0));
    },
    restoreDefault() {
      this.$emit('onRestoreDefault', this.userLevelSaveConfig);
      this.close();
    },
    orderChanged(listOrdered) {
      this.widgetsIntern = [...listOrdered];
      this.setWidgetsEdited();
    },
    async saveConfigEdited() {
      const widgetsEdited = { ...this.widgetsEdited, };
      const promises = Object.keys(widgetsEdited).map(async userLevel => {
        const payload = {
          configId: this.id,
          configType: FC_CONFIG.DASHBOARD_PANEL_CONFIG,
          content: contentToSave(widgetsEdited?.[userLevel]),
          userLevel: userLevel,
        };
        return await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_ONLY, payload);
      });
      await Promise.all(promises);
      this.$emit('saved');
      this.close();
    },
  },
  components: {
    PhTrashSimple,
    PhPlus,
    BaseModal,
    DashboardPanelConfigTabs,
    GhostLoading,
    Block,
    SortableList,
    SortableListItemDeleteConfirm,
    PhPlusCircle,
  },
}
</script>

<style scoped>
.dashboard-panel-config__item {
  display: flex;
}
.dashboard-panel-config__item--label {
  flex: 1 1 auto;
  word-break: break-word;
}
.dashboard-panel-config__item--actions {
  align-self: center;
  flex: 0 0 auto;
  margin: 0 0 0 12px;
}
.dashboard-panel-config__item--actions > * {
  margin: 0 0 0 16px;
}
.dashboard-panel-config__item--actions > *:first-child {
  margin-left: 0;
}
.dashboard-panel-config__to-add__container {
  margin: 16px 0 0;
}

.sortable-list-item__actions,
.sortable-list-item__actions > span {
  margin: 0 8px;
  display: flex;
}
</style>
