<template>
  <div>
    <OptionMenu
      :id="$appNavigation.parentOptionMenuId"
      :defaultMenu="$appNavigation.parentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      title="Vermögensverwaltung"
      :subtitle="title"
      :defaultMenu="schemaMenuVarianten"
      :actions="schemaHeaderActions"
      @action="handleAction"
    />

    <AntragSidetext
      :warnings="warnings"
      :hinweiseProps="hinweise"
      showMessageErrorMustBeFixed
      :showExpanded="false"
      hinweisText="Die Strategie ist fehlerhaft"
    />

    <div v-if="schemaData && schemaData.titleEditable" class="box__container">
      <div class="d-flex">
        <span class="mr-2">Name der Vermögensverwaltung</span>
        <InputField :value="schemaData.title" @change="updateStore({ title: $event })" />
      </div>
    </div>

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      :actions="varianteHeaderActions"
      @action="handleAction"
      :title="varianteTitle"
      :showBackButton="false"
    >
      <template #subtitle>
        <template v-for="(status, index) of variante.stati || []">
          <span v-if="index > 0" :key="'SEP' + index">&nbsp;|&nbsp;</span>
          <span :class="status.status" class="pl-2" :key="'LABEL' + index">{{
            status.label
          }}</span>
        </template>
      </template>
    </PageHeaderTitleNavigation>

    <div class="box__container">
      <GhostLoading v-if="isSchemaDataLoading" type="table" />
      <VVWertpapiereList
        :positions="(variante && variante.positions) || []"
        :extraHeaders="schemaData.extraHeaders"
        :isEditor="true"
        v-else-if="variante"
        :disabled="disabled"
        :versionId="varianteId"
        :showKennzahlen="wertpapiereShowKennzahlen"
        @updateVarianteStore="updateVariantePositionStore(varianteId, $event)"
      />
    </div>
    <div class="box__container">
      <InputTextArea
        :disabled="!variante.advisorMessageEditable || disabled"
        label="Anmerkung des Erstellers"
        v-model="commentZulassung"
        @change="updateVarianteStore(varianteId, { commentZulassung: $event })"
      />
      <InputTextArea
        :disabled="!variante.vermVerwMessageEditable || disabled"
        label="Kommentar Vermögensverwalter"
        v-model="commentVerwalter"
        @change="updateVarianteStore(varianteId, { commentVerwalter: $event })"
      />
    </div>
    <div class="box__container">
      <StrategienZielmarktTable
        :zielmarktdaten="variante.zielmarktdaten"
        :disabled="disabled"
      />
    </div>
    <div class="box__container">
      <StrategienRichtbandbreiteTable
        :tableRows="variante.richtbandbreiten || []"
        :isAdvisor="variante.isAdvisor"
      />
    </div>

    <BaseModal
      ref="pdfAenderungenModal"
      labelButtonConfirm="PDF erzeugen"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="convertPdf"
    >
      <template v-slot:modalTitle> Änderungs-Dokument erstellen </template>
      <div>
        <InputRadioBoxGroup
          v-model="typ"
          label="Änderungsdokument erstellen"
          :values="typValues"
        />
        <ComboBox
          v-if="typ === 'VALUE_TYP_VERGLEICH'"
          v-model="versionAktuell"
          label="Vergleiche Variante"
          :values="comboVersionenAktuell"
          :sortComboboxValues="false"
          @change="updateComboboxVersionenPrevious"
        />
        <ComboBox
          v-if="typ === 'VALUE_TYP_VERGLEICH'"
          v-model="versionPrevious"
          label="Vergleiche Variante"
          :values="comboVersionenPrevious"
          :sortComboboxValues="false"
        />
      </div>
    </BaseModal>

    <BaseModal
      ref="archivierungModal"
      labelButtonConfirm="Fortfahren"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="moveVarianteIntoArchive"
    >
      <template v-slot:modalTitle> Archivierung der Variante</template>
      <div>
        Diese Variante wird in das Archiv verschoben und ist nicht mehr in
        dieser Maske sichtbar.
      </div>
      <div>
        Dadurch steht sie auch nicht mehr zur Genehmigung zur Verfügung.
      </div>
      <div>Sie können Kopien dieser Variante im Archiv erstellen.</div>
      <div class="mt-2">Möchen Sie fortfahren?</div>
    </BaseModal>

    <BaseModal
      ref="statusChangeFehlerModal"
      :showConfirmButton="false"
      labelButtonCancel="Ok"
      modalTitle="Fehlerliste"
      size="lg"
    >
      <div v-for="row in scmErrors" :key="row.title">
        <div style="padding-bottom: 1em">
          <span style="font-weight: bold">{{ row.title }}</span
          >: {{ row.message }}
        </div>
      </div>
    </BaseModal>

    <BaseModal
      ref="statusChangeModal"
      :showConfirmButton="true"
      :labelButtonConfirm="this.scmButtonLabel"
      labelButtonCancel="Abbrechen"
      modalTitle="Status ändern"
      @onConfirmButton="doChangeStatusScm"
    >
      <div v-html="this.scmMessage" />
    </BaseModal>
  </div>
</template>

<script>
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import CORE_TYPES from '@/store/core/types'

import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";
import AntragSidetext from "@/components/antrag/AntragSidetext.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import { PageHeaderSimpleAction } from "@/components/core/header-title-navigation/page-header-utils";
import { mapGetters } from "vuex";
import VVWertpapiereList from "@/components/vermoegensverwaltung/VVWertpapiereList.vue";
import StrategienZielmarktTable from "@/components/vermoegensverwaltung/StrategienZielmarktTable.vue";
import StrategienRichtbandbreiteTable from "@/components/vermoegensverwaltung/StrategienRichtbandbreiteTable.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import { SimpleAction } from "@/components/table2/table_util.js";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";

export default {
  mixins: [mixin],
  components: {
    AntragSidetext,
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    BaseModal,
    InputRadioBoxGroup,
    ComboBox,
    VVWertpapiereList,
    StrategienZielmarktTable,
    StrategienRichtbandbreiteTable,
    InputTextArea,
    GhostLoading,
  },
  data() {
    return {
      doReloadData: true,
      typ: "VALUE_TYP_ARCHIV",
      typValues: [
        {
          value: "VALUE_TYP_ARCHIV",
          label:
            `<b>Archivdokument erstellen</b></br>Dieser Modus gibt ein vollständiges Archiv aller 
            jemals zugelassenen Varianten der Vermögensverwaltung aus. Es vergleicht jeweils zwei 
            benachbarte Varianten miteinander, absteigend von der neuesten Variante zur ältesten. 
            Vorschläge und abgelehnte Varianten werden nicht berücksichtigt.`,
        },
        {
          value: "VALUE_TYP_VERGLEICH",
          label:
            `<b>Versionsvergleich erstellen</b></br>Dieser Modus vergleicht die ausgewählte Variante mit 
            der aktuell aktiven Variante. Wird keine "alte" Variante ausgewählt, so wird 
            ein Dokument mit allen Positionen und Kommentaren erstellt.`,
        },
      ],
      versionAktuell: "",
      versionPrevious: "NICHT_VERWENDEN",
      comboVersionenPrevious: [],

      commentZulassung: "",
      commentVerwalter: "",

      scmMessage: "",
      scmNewStatus: "",
      scmButtonLabel: "",
      scmErrors: [],

      wertpapiereShowKennzahlen: false,
    };
  },
  watch: {
    schemaData(value) {
      if (value?.isUpdateSubstep) {
        const varianteId =
          (value?.varianten?.length && value?.varianten[0].versionId) || "";

        if (varianteId) {
          this.$router
            .push(
              `/${this.basePath}/vv/vv-schema-editor/${this.schemaId}/${varianteId}`
            )
            .then(() =>
              this.$store.commit(
                VV_TYPES.MUTATIONS.SET_UPDATE_VARIANTEN_STEP,
                false
              )
            );
        }
      }
    },
    variante(value) {
      this.initValuesVariante(value);
    },
  },
  mounted() {
    // schema data
    if (this.doReloadData) {
      this.$store.commit(VV_TYPES.MUTATIONS.RESET_STRATEGIEN_SCHEMA_DATA);
      this.$store.commit(
        VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_LOADING,
        true
      );
      this.$store
        .dispatch(VV_TYPES.ACTIONS.GET_STRATEGIEN_SCHEMA_DATA, {
          schemaId: this.schemaId,
          isEditor: 1,
        })
        .then(() =>
          this.$store.commit(
            VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_LOADING,
            false
          )
        )
        .then(() => this.doCheckAndForwardVariante());
    } else {
      this.doCheckAndForwardVariante();
    }

    this.initValuesVariante(this.variante);
  },
  computed: {
    ...mapGetters({
      isSchemaDataLoading: VV_TYPES.GETTERS.STRATEGIEN_SCHEMA_DATA_LOADING,
    }),
    varianteId() {
      return this.$route.params.varianteId || "";
    },
    variante() {
      return (
        (this.varianteId &&
          this.schemaData?.varianten?.find(
            (variante) => variante.versionId === this.varianteId
          )) ||
        {}
      );
    },
    varianteHeaderActions() {
      return this.variante?.actions
        ?.filter((act) => act.value === "NEUES_WERTPAPIER")
        .map((act) =>
          PageHeaderSimpleAction(act.value, act.label)
        );
    },
    varianteTitle() {
      return this.variante?.title || "Variante ";
    },
    title() {
      return this.schemaData?.title || ""
    },
    schemaMenuVarianten() {
      return [
        ...(this.schemaData?.varianten?.map((v) => {
          return {
            label: v.title,
            path: `/${this.basePath}/vv/vv-schema-editor/${v.schemaId}/${v.versionId}`,
            action: () => {
              let versionIdAlt = this.$route.params.varianteId;

              this.$store.dispatch(VV_TYPES.ACTIONS.SAVE_SCHEMA_VERSION, {
                versionId: versionIdAlt,
              });
              this.$router.push(
                `/${this.basePath}/vv/vv-schema-editor/${v.schemaId}/${v.versionId}`
              );
            },
          };
        }) || []),
      ];
    },
    disabled() {
      return this.variante.stati?.some(
        (status) => status.value === "ABGELEHNT"
      );
    },
    schemaHeaderActions() {
      const { menu } = this;

      let actions = [
        // all from current variante except neues_wertpapier
        ...(this.variante?.actions
          ?.filter(
            (a) =>
              a.value !== "NEUES_WERTPAPIER" &&
              a.value !== "ZIELMARKT_BERECHNEN"
          )
          .map((act) =>
            PageHeaderSimpleAction(act.value, act.label)
          ) || []),
        PageHeaderSimpleAction(
          "BUTTON_TOGGLE_KENNZAHLEN",
          "Wechsel Wertpapierdaten"
        ),
        PageHeaderSimpleAction("BUTTON_EINSTELLUNGEN", "Einstellungen"),
        PageHeaderSimpleAction(
          "BUTTON_OPEN_ARCHIVE",
          "Vorschläge-Archiv"
        ).withVisible(() => menu?.BUTTON_OPEN_ARCHIVE),
        PageHeaderSimpleAction(
          "BUTTON_ARCHIV_PDF",
          "PDF-Historie & Vergleiche"
        ).withVisible(() => menu?.BUTTON_ARCHIV_PDF),
        PageHeaderSimpleAction(
          "BUTTON_FACTSHEET_VV_CALC",
          "Factsheet"
        ).withVisible(() => menu?.BUTTON_FACTSHEET_VV_CALC),
        PageHeaderSimpleAction(
          "BUTTON_FACTSHEET_HISTORY",
          "Factsheets History"
        ).withVisible(() => menu?.BUTTON_FACTSHEET_HISTORY),
        PageHeaderSimpleAction(
          "BUTTON_FACTSHEET_VV_KAG",
          "Prospekt aufrufen"
        ).withVisible(() => menu?.BUTTON_FACTSHEET_VV_KAG),
        PageHeaderSimpleAction(
          "BUTTON_OPEN_WK_KUNDE_DEPOTAUSZUG_PDF",
          "Depotauszug"
        ).withVisible(() => menu?.BUTTON_OPEN_WK_KUNDE_DEPOTAUSZUG_PDF),
        PageHeaderSimpleAction(
          "BUTTON_OPEN_WK_KUNDE",
          "Musterkunde"
        ).withVisible(() => menu?.BUTTON_OPEN_WK_KUNDE),
        PageHeaderSimpleAction(
          "BUTTON_OPEN_PDF_MANUAL",
          "Hinweise"
        ).withVisible(() => menu?.BUTTON_OPEN_PDF_MANUAL),
      ];

      if (this.variante.defaultAction) {
        let indexDefaultAction = actions.findIndex(action => action.actionKey === this.variante.defaultAction.value)

        if (indexDefaultAction > 0) {
          actions.unshift(actions.splice(indexDefaultAction, 1)[0])
        }
      }

      return actions
    },
    menu() {
      return this.schemaData?.menu || {};
    },
    comboVersionenAktuell() {
      return this.schemaData?.comboVersionenAktuell || [];
    },
  },
  methods: {
    initValuesVariante(values) {
      this.commentZulassung = values?.commentZulassung;
      this.commentVerwalter = values?.commentVerwalter;
      this.actions = this.variante?.actions?.map((act) =>
        SimpleAction(act.value, act.status, act.label)
      );
    },
    doCheckAndForwardVariante() {
      if (!this.$route.params.varianteId || this.$route.params.varianteId === 'undefined') {
        let openVarianteId = this.schemaData?.varianten?.reduce((v1, v2) =>
          parseInt(v1.ordinal) > parseInt(v2.ordinal) ? v1 : v2
        ).versionId;

        if (openVarianteId) {
          this.$router.push(
            `/${this.basePath}/vv/vv-schema-editor/${this.schemaId}/${openVarianteId}`
          );
        }

      }
    },
    handleAction(data) {
      switch (data.key) {
        case "BUTTON_EINSTELLUNGEN":
          this.$addBreadcrumb({
            label: `zurück zur Vermögensverwaltung "${this.title}"`,
            fullPath: `/${this.basePath}/vv/vv-schema-editor/${this.schemaId}/${this.varianteId}`,
            breadcrumb: "Einstellungen",
          });
          this.$router.push(
            `/${this.basePath}/vv/vv-schema-einstellungen/${this.schemaId}`
          );
          break;
        case "BUTTON_OPEN_ARCHIVE":
          this.$addBreadcrumb({
            label: `zurück zur Vermögensverwaltung "${this.title}"`,
            fullPath: `/${this.basePath}/vv/vv-schema-editor/${this.schemaId}`,
            breadcrumb: "Archiv für Schema",
          });
          this.$router.push(`/${this.basePath}/vv/archiv-list/${this.schemaId}`);
          break;
        case "BUTTON_ARCHIV_PDF":
          this.typ = "VALUE_TYP_ARCHIV";
          this.versionAktuell = this.schemaData?.versionAktuell || "";
          this.versionPrevious = this.schemaData?.versionPrevious || "";
          this.updateComboboxVersionenPrevious(this.versionAktuell);
          this.$refs.pdfAenderungenModal.open();
          break;
        case "BUTTON_VORSCHLAG_NEU":
          this.$store.dispatch(VV_TYPES.ACTIONS.MAKE_NEW_VERSION, {
            schemaId: this.schemaId,
            stepKey: this.VARIANTEN,
            params: {},
          });
          break;
        case "BUTTON_TOGGLE_KENNZAHLEN":
          this.wertpapiereShowKennzahlen = !this.wertpapiereShowKennzahlen;
          break;
        case "BUTTON_FACTSHEET_VV_CALC":
          this.handlePdf(VV_TYPES.ACTIONS.GET_PDF_FACTSHEET_VV_CALC);
          break;
        case "BUTTON_FACTSHEET_HISTORY":
          this.$addBreadcrumb({
            label: `zurück zur Vermögensverwaltung "${this.title}"`,
            fullPath: `/${this.basePath}/vv/vv-schema-editor/${this.schemaId}`,
            breadcrumb: "Factsheet History",
          });
          this.$router.push(
            `/${this.basePath}/vv/vv-factsheet-history/${
              this.schemaId
            }/${encodeURIComponent(this.schemaData.title)}`
          );
          break;
        case "BUTTON_FACTSHEET_VV_KAG":
          break;
        case "BUTTON_OPEN_WK_KUNDE_DEPOTAUSZUG_PDF":
          this.handlePdf(VV_TYPES.ACTIONS.GET_PDF_WK_KUNDE_DEPOTAUSZUG);
          break;
        case "BUTTON_OPEN_WK_KUNDE":
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
            customerId: this.schemaData.musterkundennr,
            path: '/home/vermogensubersicht/depot',
          });
          break;
        case "BUTTON_OPEN_PDF_MANUAL":
          this.handlePdf(VV_TYPES.ACTIONS.GET_PDF_MANUAL);
          break;
        case "DELETE_VERSION":
          this.$confirmModal({
            title: "Vermögensverwaltung Variante löschen",
            message: `Soll ${this.title}, Variante ${
              this.variante?.ordinal || ""
            } wirklich gelöscht werden?`,
            labelButtonConfirm: "Löschen",
          }).then(() =>
            this.$store.dispatch(
              VV_TYPES.ACTIONS.DELETE_SCHEMA_VERSION,
              this.varianteId
            )
          );
          break;
        case "CLONE_VARIANTE":
          this.$store.dispatch(VV_TYPES.ACTIONS.CLONE_SCHEMA_VERSION, {
            versionId: this.varianteId,
            params: {},
          });
          break;
        case "NEUES_WERTPAPIER":
          this.$store.dispatch(VV_TYPES.ACTIONS.SAVE_SCHEMA_VERSION, {
            versionId: this.varianteId,
          });
          this.$addBreadcrumb({
            label: `zurück zur Vermögensverwaltung "${this.title}"`,
            fullPath: `${this.$route.path}`,
            breadcrumb: "Liste der verfügbaren Wertpapiere",
          });
          this.$router.push({
            path: `/${this.basePath}/vv/fondsfinder/${this.variante?.schemaId}/${this.varianteId}`,
            query: { categoryId: `vv_available_${this.varianteId}` },
          });
          break;
        case "ZIELMARKT_BERECHNEN":
          this.$store.dispatch(VV_TYPES.ACTIONS.CALC_ZIELMARKT_DATA, {
            versionId: this.varianteId,
          });
          break;
        case "ZIELMARKT_UEBERNEHMEN":
          this.$store.dispatch(
            VV_TYPES.ACTIONS.ZIELMARKT_UEBERNEHMEN,
            this.varianteId
          ).then(() => {
            if (this.variante.defaultAction.value === "ZIELMARKT_UEBERNEHMEN") {
              this.handleAction({ key: "BUTTON_EINSTELLUNGEN" });
            }
          })
          break;
        case "ARCHIVE_VERSION":
          this.$refs.archivierungModal.open();
          break;
        default:
          if (data?.key?.startsWith("STATUSCHANGE_")) {
            this.$store
              .dispatch(VV_TYPES.ACTIONS.SAVE_SCHEMA_VERSION, {
                versionId: this.varianteId,
              })
              .then(() => {
                let newStatus =
                  data.key.substring("STATUSCHANGE_".length) || "";

                this.$store
                  .dispatch(VV_TYPES.ACTIONS.VERSION_STATUSCHANGE_CHECK, {
                    versionId: this.varianteId,
                    newStatus: newStatus,
                  })
                  .then((response) => {
                    if (response?.data?.fehlerListe) {
                      this.scmErrors = response.data.fehlerListe;
                      this.$refs.statusChangeFehlerModal.open();
                    } else if (response?.data?.popupMessage) {
                      this.scmMessage = response.data.popupMessage;
                      this.scmButtonLabel = response.data.buttonLabel;
                      this.scmNewStatus = newStatus;
                      this.$refs.statusChangeModal.open();
                    } else {
                      this.doChangeStatus(newStatus);
                    }
                  });
              });
            break;
          }
      }
    },
    convertPdf() {
      this.handlePdf(VV_TYPES.ACTIONS.CONVERT_PDF, {
        typ: this.typ,
        versionAktuell: this.versionAktuell,
        versionPrevious: this.versionPrevious,
      });
    },
    updateComboboxVersionenPrevious(versionAktuell) {
      this.versionPrevious = "NICHT_VERWENDEN";
      this.$store
        .dispatch(VV_TYPES.ACTIONS.GET_COMBO_VALUES_VERSIONEN_PREVIOUS, {
          schemaId: this.schemaId,
          versionAktuell: versionAktuell,
        })
        .then((response) => {
          this.comboVersionenPrevious = response.data;
        });
    },
    doChangeStatusScm() {
      this.doChangeStatus(this.scmNewStatus);
      this.scmNewStatus = "";
    },
    doChangeStatus(newStatus) {
      this.$store.dispatch(VV_TYPES.ACTIONS.VERSION_STATUSCHANGE, {
        versionId: this.varianteId,
        newStatus: newStatus,
      });
    },
    moveVarianteIntoArchive() {
      this.$store.dispatch(
        VV_TYPES.ACTIONS.MOVE_VARIANTE_INTO_ARCHIV,
        this.varianteId
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück zur Variante",
      to,
      from,
    });

    this.$store
      .dispatch(VV_TYPES.ACTIONS.SAVE_SCHEMA_VERSION, {
        versionId: this.$route.params.varianteId,
      })
      .finally(() => next());
  },
  beforeRouteEnter(to, from, next) {
    if (from.matched.find((x) => x.path.includes("/vv/fondsfinder"))) {
      // fondsfinder can add produkte and will then during the back action add them and reload the schema.
      // mounted will also simultaneously request the schema data that might not yet have the new produkte as both are called almost simultaneously
      // just skip the reload on mount if back from stuff that does not change data or reloads changed data
      next((vm) => (vm.doReloadData = false));
    } else {
      next((vm) => (vm.doReloadData = true));
    }
  },

};
</script>

<style>
.button_right {
  display: flex;
  justify-content: right;
  align-items: right;
}
</style>