<template>
  <BaseModal
    ref="confirmModal"
    :modalTitle="options.title"
    :size="options.size"
    :labelButtonCancel="options.labelButtonCancel"
    :labelButtonConfirm="options.labelButtonConfirm || 'Bestätigen'"
    :showCancelButton="options.showCancelButton"
    :showConfirmButton="options.showConfirmButton"
    :isModalAsFullPage="false"
    :autoCloseOnRouteNavigation="options.autoCloseOnRouteNavigation"
    @onConfirmButton="onConfirmButton"
    @close="onClose"
  >
    <template v-if="options.icon" v-slot:modalTitle>
      <div class="row">
        <component class="col-auto pr-1"  :is="options.icon" :class="options.iconClass" />
        <span class="col p-0" v-html="sanitize(options.title)"></span>
      </div>
    </template>
    <template v-if="options.message" #default>
      <div v-html="sanitize(options.message)"></div>
    </template>
  </BaseModal>
</template>

<script>
import CORE_TYPES from '@/store/core/types';

import BaseModal from '@/components/core/BaseModal.vue';

import { sanitize } from '@/helpers/string-helper.js';

const DEFAULT_TITLE = 'Bestätigen';
const DEFAULT_OPTIONS = {
  title: DEFAULT_TITLE,
};

export default {
  components: {
    BaseModal,
  },
  props: {
    config: {},
  },
  data() {
    return {
      confirmed: false,
    };
  },
  computed: {
    options() {
      const { options } = this.config;
      return {
        ...DEFAULT_OPTIONS,
        ...options || {},
      };
    },
  },
  methods: {
    open() {
      this.$refs.confirmModal.open();
    },
    confirm(feedback) {
      this.$store.commit(CORE_TYPES.MUTATIONS.REPLY_CONFIRM_MODAL, {
        ...this.config,
        feedback,
      });
    },
    onConfirmButton() {
      this.confirmed = true;
      this.confirm(true);
    },
    onClose() {
      if(!this.confirmed) {
        this.confirm(false);
      }
    },
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
  },
  mounted() {
    this.$nextTick(() => this.open());
  },
  beforeDestroy() {
    this.$nextTick(() => this.onClose());
  },
}
</script>
