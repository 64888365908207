var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "addEditModal",
          attrs: { modalTitle: "Shortcut", autoClose: false },
          on: {
            onConfirmButton: function($event) {
              return _vm.submit()
            },
            close: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("InputField", {
                attrs: { label: "Shortcut", maxLength: 100 },
                on: {
                  input: function($event) {
                    return _vm.changeShortcut(_vm.shortcut, $event, "key")
                  }
                },
                model: {
                  value: _vm.shortcut.key,
                  callback: function($$v) {
                    _vm.$set(_vm.shortcut, "key", $$v)
                  },
                  expression: "shortcut.key"
                }
              }),
              _vm.htmlShortcuts.some(function(htmlShortcut) {
                return htmlShortcut.key == _vm.shortcut.key
              })
                ? _c("span", { staticClass: "validation_error" }, [
                    _vm._v("Der Shortcut ist bereits belegt.")
                  ])
                : _vm._e(),
              _c("InputTextArea", {
                attrs: { label: "Beschreibung", maxlength: 1000 },
                on: {
                  input: function($event) {
                    return _vm.changeShortcut(
                      _vm.shortcut,
                      $event,
                      "description"
                    )
                  }
                },
                model: {
                  value: _vm.shortcut.description,
                  callback: function($$v) {
                    _vm.$set(_vm.shortcut, "description", $$v)
                  },
                  expression: "shortcut.description"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: { label: "Für Struktur freigeschaltet" },
                on: {
                  input: function($event) {
                    return _vm.changeShortcut(_vm.shortcut, $event, "forBroker")
                  }
                },
                model: {
                  value: _vm.shortcut.forBroker,
                  callback: function($$v) {
                    _vm.$set(_vm.shortcut, "forBroker", $$v)
                  },
                  expression: "shortcut.forBroker"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }