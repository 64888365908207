import MAILCOMPOSER_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue';

export default {
  [MAILCOMPOSER_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.GET_SENDERS](state, payload) {
    state.senders = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.GET_PLACEHOLDERS](state, payload) {
    state.placeholders = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.SET_UNSET_PLACEHOLDERS](state, payload) {
    state.unsetPlaceholders = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.GET_HTML_TEMPLATES](state, payload) {
    state.templates = {
      ...state.templates,
      [payload.type]: payload.data
    }
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.GET_HTML_TEMPLATE](state, payload) {
    state.template = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.GET_HTML_TEXT](state, payload) {
    state.text = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.GET_WORD_TEMPLATE](state, payload) {
    Vue.set(state, 'wordTemplate', payload);
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG](state, payload) {
    state.preview = '';
    let cache = state.data.cache || {}
    if (payload.type && (payload.action === 'save' || payload.action === 'save-cache')) {
      cache[payload.type] = payload
      state.data = {
        ...payload,
        cache,
      };
      return
    }
    if (payload.type && payload.action === 'clear') {
      cache[payload.type] = undefined
    }
    state.data = {
      ...payload,
      cache,
    };
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.GET_PREVIEW](state, payload) {
    state.preview = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.SEND_MAIL](state, payload) {
    Vue.set(state, 'sendMail', payload);
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS](state, payload) {
    state.participants = payload && state.participants && (state.participants.length ? state.participants.concat(payload) : payload) || [];
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS](state, payload) {
    if (payload?.type && payload.action && payload.action === 'clear') {
      state.attachmentsCache[payload.type] = null
      state.attachments = []
      return
    }
    if (payload?.type && payload.action && payload.action === 'clear-cache') {
      state.attachmentsCache[payload.type] = null
      return
    }
    if (payload?.type && payload.action && payload.action === 'save-cache') {
      state.attachmentsCache = {
        ...(state.attachmentsCache || {}),
        [payload.type]: state.attachments
      }
      state.attachments = []
      return
    }
    let result = []
    if (payload && state.attachments && state.attachments.length) {
      result = state.attachments.concat(payload)
    } else {
      result = payload
    }
    Vue.set(state, 'attachments', result || []);
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.APPZUSENDEN](state, payload) {
    state.appzusenden = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.SET_PDF](state, payload) {
    state.preview = '';
    let cache = state.pdf && state.pdf.cache || {};
    if (payload.type && (payload.action === 'save' || payload.action === 'save-cache')) {
      cache[payload.type] = payload
      state.pdf = {
        cache,
      };
      return
    }
    if (payload.type && payload.action === 'clear') {
      cache[payload.type] = undefined
    }
    state.pdf = {
      ...payload,
      cache,
    };
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.SEND_FAX](state, payload) {
    state.fax = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.SEND_BRIEF](state, payload) {
    state.brief = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.WORD_TEMPLATES](state, payload) {
    state.wordTemplates = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.TEST_SENDERS](state, payload) {
    state.testSenders = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.GET_INIT](state, payload) {
    Vue.set(state, 'init', payload);
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.GET_INIT_MULTIPLE](state, payload) {
    state.init = {
      ...state.init,
      ...payload,
      participants: [
        ...state.init.participants,
        ...payload.participants
      ],
    }
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT](state, payload) {
    Vue.set(state, 'linkedObject', payload);
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE](state, payload) {
    state.insuranceCancel = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_ANTRAG](state, payload) {
    state.mailAntrag = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_ANSPRECHPARTNER](state, payload) {
    state.mailAnsprechpartner = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_APPOINTMENT](state, payload) {
    state.mailAppointment = {...payload.selectedObject, ...payload.data};
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_SCHADENSMELDUNG](state, payload) {
    state.mailSchadensmeldung = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_ANLAGEEMPFELUNG](state, payload) {
    state.mailAnlageempfehlung = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_BERATUNGSMAPPEN](state, payload) {
    state.mailBeratungsmappen = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL](state, payload) {
    state.mailVersUebertragBlockGesell = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_VERSDOKU](state, payload) {
    state.mailVersDoku = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.GET_CONSTANTS](state, payload) {
    state.constants = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.GET_MAIL_MODUS](state, payload) {
    state.modus = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.SET_ERROR](state, payload) {
    state.errorMessage = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.SEND_BERICHT_PDF_SUCCESS](state, data) {
    state.mailAuftragsdokumente = data
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_VERMITTLERNUMMERN](state, payload) {
    state.mailVermittlernummern = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL](state, payload) {
    state.mailAktuellerQuartalsberichte = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN](state, payload) {
    state.mailHonorareAbrechnungenVerschicken = payload;
  },

  [MAILCOMPOSER_TYPES.MUTATIONS.ANTRAG_CHECK_MAIL](state, payload) {
    state.ANTRAG_CHECK_MAIL = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_VERTRAGSSPIEGEL](state, payload) {
    state.mailVertragsspiegel = payload;
  },
  [MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_FONDSHOP_ACTION](state, payload) {
    state.mailFondshopAction = payload;
  },

  
}