<template>
    <a v-if="isMobileNativeContext" @click="callBridgeViewDocument" :class="linkClass" draggable="false"> 
      <slot>{{title}}</slot>
    </a>
    <a v-else target="_blank" rel="noopener noreferer"
      style="text-overflow: ellipsis" 
      draggable="false"
      :class="linkClass"
      :title="title" 
      :href="!disabled && computedLink || null"
      @click="emitClickEvent()">
      <slot>{{title}}</slot>
    </a>
</template>

<script>
import BRIDGE_TYPES from '@/store/bridge/types'
import { mapGetters } from 'vuex'
import { downloadLinkMaker } from '@/helpers/utils-helper';
import store from '@/store';

import { openFile, byteArrayToBase64, downloadFile } from '@/components/table/export/utils';

const ROOT_DOWNLOAD_SERVICE_PATH = '/download_service'

/**
 * @deprecated don't use this function directly.
 * viewDocument shows documents in mobile context if it is enabled
 * 
 * payload: 
 * - href: must be a string, if has a href data and contentType are ignored
 * - data: can be a base64 string or an ArrayBuffer
 * - filename
 * - contentType
 */
export function viewDocument(payload, forceDownload = false) {
  if(store.getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]) { // mobile context
    store.dispatch(BRIDGE_TYPES.ACTIONS.VIEW_DOCUMENT, {
      ...payload,
      data: typeof payload.data === 'string' ? payload.data : byteArrayToBase64(payload.data),
    });

    if (store.getters[BRIDGE_TYPES.GETTERS.IS_BROWSER_SIMULATION]) {
      window.open(payload.href, '_blank');
    }
  } else if(forceDownload) { // web context, force download
    downloadFile(payload?.filename, payload?.data, payload?.contentType, payload.href);
  } else { // web context
    if('href' in payload) {
      const target = payload?.useRedirect === true ? '_self' : '_blank';
      window.open(payload.href, target);
    } else {
      openFile(payload?.data, payload?.contentType, payload?.filename, payload?.windowRef);
    }
  }
}

export default {
  props: {
    title: {
      type: String,
      default:''
    },
    href: {
      type: String,
      default: ''
    },
    asButton: {
      type: Boolean,
      default: false,
    },
    asLabel: {
      type: Boolean,
      default: false,
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    queryParams: {
      type: Object,
    },
    filename: {
      type: String,
    },
    downloadServicePath: {
      type: String,
    },
    buttonFullSize: {
      type: Boolean,
      default: false,
    },
    asLabelNoDisable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    linkClass() {
      return {
        'button': this.asButton,
        'button--primary': this.asButton && !this.isSecondary,
        'button--secondary': this.asButton && this.isSecondary,
        'button--disabled': this.asButton && this.disabled,
        'link--disabled': !this.asLabelNoDisable && !this.asButton && this.disabled,
        'button--mobile-native-size': this.asButton && this.isMobileNativeContext,
        'as-label': this.asLabel,
        'button-full-size': this.buttonFullSize,
      }
    },
    computedLink() {
      if (this.href) {
        return this.href
      }

      if (this.downloadServicePath) {
        return downloadLinkMaker(this.$store.getters, `${ROOT_DOWNLOAD_SERVICE_PATH}${this.downloadServicePath}`, this.filename, this.queryParams);
      }

      return ''
    },
    
  },
  methods: {
    callBridgeViewDocument() {
      if (!this.disabled) {
        viewDocument({
          href: this.computedLink, 
          title: this.title,
          filename: this.filename,
        });
        this.emitClickEvent();
      }
    },
    emitClickEvent() {
      if (!this.disabled) {
        this.$emit("clicked");
      }
    },
  }
}
</script>

<style scoped>
.button {
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 4px;
  line-height: 14px;
  display: inline-block;
  box-sizing: border-box;
}

.button--mobile-native-size {
  min-height: 40px;
  min-width: 40px;
  line-height: 22px;
}

.button--primary {
  background-color: var(--color-primary);
  color: var(--color-primary-text);
  border: 1px solid;
  border-color: var(--color-primary);
}
.button--secondary {
  border: 1px solid;
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-box);
}

.button--disabled, .link--disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.as-label {
  color: var(--color-text);
  text-decoration: none;
}

.button-full-size {
  width: 100%;
  text-align: center;
}

</style>
