<template>
  <div>
    <div class="box__container" tid="a822115c-09fd-4d06-981d-b935279f97df">
      <div class="layout__negative-margin--8">
        <InputToggleSwitch
          label="Kunde hat Vertrag gekündigt zum"
          v-model="kundeWechselIntern.kuendigungErfolgt"
          v-if="isKundeWechselInternVisible"
        />
        <DatePickerField
          v-if="isKundeWechselInternVisible"
          isComponentHalfSize
          isValueAsString
          v-model="kundeWechselIntern.kuendigungDatum"
        />

        <BaseButton
          v-if="!pin.zugangFrei"
          @click="changeAccess(!pin.zugangFrei)"
          :disabled="saving">
          Zugang freischalten
        </BaseButton>
        <BaseButton
          isSecondary
          v-if="pin.zugangFrei"
          @click="changeAccess(!pin.zugangFrei)"
          :disabled="saving">
          Zugang sperren
        </BaseButton>

        <BaseButton
          isSecondary
          v-if="!pin.checkKundeInaktiv"
          @click="changeActive(!pin.checkKundeInaktiv)"
          :disabled="saving">
          Kunde auf inaktiv setzen
        </BaseButton>
        <BaseButton
          v-if="pin.checkKundeInaktiv"
          @click="changeActive(!pin.checkKundeInaktiv)"
          :disabled="saving">
          Kunde auf aktiv setzen
        </BaseButton>
      </div>

      <AskForSendEmailModal ref="askForSendEmailModal" :pin="pin" />
      <BaseModal
        ref="activationLetterModal"
        modalTitle="Aktivierungsbrief erstellen"
        labelButtonConfirm="Ja"
        :isModalAsFullPage="false"
        @onConfirmButton="generateActivationLetter()"
        @close="closeActivationLetterModal()"
      >
        Es wurde bereits ein Aktivierungsbrief erstellt, dessen Freischaltcode noch gültig ist.
        Wenn Sie einen Neuen erstellen, kann der Code aus dem alten Brief nicht mehr verwendet werden.
        Soll trotzdem ein neuer Brief mit neuem Code erstellt werden?
      </BaseModal>
    </div>

    <div class="box__container" v-if="usesKsc2 || showActivationLetterButton">
      <div v-if="usesKsc2">
        <InputField label="Passwort" 
          :isComponentHalfSize="true"
          v-model="pin.kundenzugangPin"
          :disabled="true">
        </InputField>
        <BaseButton @click="newPin()" :disabled="loadingNewPin"  tid="157301cd-56ae-4512-8bbf-3e7c9ade5816">
          Neue PIN <AnimatedSpinner v-if="loadingNewPin" />
        </BaseButton>
      </div>
      <div v-else-if="showActivationLetterButton">
        {{activationLetterDescription}}<br>
        <BaseButton @click="clickActivationLetterButton">
          Aktivierungsbrief erstellen
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from '@/store/core/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types.js';
import LOG_TYPES from '@/store/log/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG, { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig.js';

import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from "../core/forms/date-picker2/DatePickerField.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import AskForSendEmailModal from './reset-pin/AskForSendEmailModal.vue';
import BaseModal from "@/components/core/BaseModal.vue";

import axios from 'axios';
import { buildMessage } from '@/helpers/log-message-helper';
import { downloadLinkMaker, } from '@/helpers/utils-helper';
import { viewDocument } from '../core/download/DownloadLink.vue';

export default {
  data() {
    return {
        pin: {},
        loadingNewPin: false,
        showActivationLetterButton: false,
        activationLetterExists: false,
        activationLetterDescription: "",
        saving: false,
        kundeWechselIntern: {
          kuendigungErfolgt: false,
          kuendigungDatum: null,
        }
    };
  },
  computed: {
    ...mapGetters({
      pinData: CUSTOMERDATA_TYPES.GETTERS.PIN_DATA,
      usesKsc2: CORE_TYPES.GETTERS.CUSTOMERS_USE_KSC2,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      advancedParameter: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ADVANCED_PARAMETER,
    }),
    isKundeWechselInternVisible() {
      return this.advancedParameter?.['KUNDE_WECHSEL_INTERN_VISIBLE']?.content === 'true';
    },
    kuendigungDatum() {
      return this.advancedParameter?.['KUNDE_WECHSEL_INTERN_KUENDIGUNG_DATUM']?.content || null;
    }
  },
  watch: {
    pinData: {
      handler() {
        this.copyPinData();
      },
      immediate: true,
    },
    'kundeWechselIntern.kuendigungErfolgt': {
      handler(newValue, oldValue) {
        if (!newValue && oldValue) {
          this.kundeWechselIntern.kuendigungDatum = null;
        }
      }
    },
    'kundeWechselIntern.kuendigungDatum': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.kundeWechselIntern.kuendigungErfolgt = true;
        }
      }
    },
    kuendigungDatum(value) {
      this.kundeWechselIntern.kuendigungDatum = value;
    }
  },
  mounted() {
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_PIN_DATA);

    if (!this.usesKsc2) {
      axios.get(`${process.env.VUE_APP_API}/activation_letter/enabled`).then(response => {
        this.showActivationLetterButton = response.data.value;
        this.activationLetterExists = response.data.exists;
        this.activationLetterDescription = response.data.description;
      });
    }
    this.copyPinData();
    this.loadKundeWechselIntern();
  },
  methods: {
    loadKundeWechselIntern() {
      const payload = [
        {
          configId: 'KUNDE_WECHSEL_INTERN_KUENDIGUNG_DATUM',
          configType: FC_CONFIG.ADVANCED_PARAMETER,
          forceReload: true,
        },
        {
          configId: 'KUNDE_WECHSEL_INTERN_VISIBLE',
          configType: FC_CONFIG.ADVANCED_PARAMETER,
          forceReload: true,
        }
      ];

      this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
    },
    async saveKundeWechselIntern() {
      const date = this.kundeWechselIntern.kuendigungDatum;

      if (this.kuendigungDatum === date) {
        return;
      }

      const payload = {
        configId: 'KUNDE_WECHSEL_INTERN_KUENDIGUNG_DATUM',
        configType: FC_CONFIG.ADVANCED_PARAMETER,
        content: date || null,
        userLevel: FC_CONFIG_USER_LEVEL.KUNDE,
      };

      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, payload);
    },
    changeAccess(changedValue) {
      this.pin.zugangFrei = changedValue;
      this.save();
    },
    async changeActive(inaktiv) {
      let canSaveInactive = true;
      if (inaktiv) {
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.CHECK_SET_KUNDE_INACTIVE).then(response => 
        canSaveInactive = response)
      }
      if (canSaveInactive) {
        this.pin.checkKundeInaktiv = inaktiv;
        this.save();
      } else {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Der Kunde kann nicht auf inaktiv gesetzt werden, da Depotbestände vorhanden sind.', 'danger'))
      }
    },
    copyPinData() {
      this.pin = Object.assign({}, this.pin, this.pinData);
    },
    newPin() {
      this.loadingNewPin = true;
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.RESET_PIN)
        .then((response) => {
          if(response?.askForSendEmail) {
            this.openAskForSendEmailModal();
          }
        })
        .catch(error => {
          this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {error});
        })
        .finally(() => this.loadingNewPin = false);
    },
    async save() {
      this.saving = true;
      try {
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_PIN_DATA, { pinData: this.pin });
      } finally {
        this.saving = false;
      }
    },
    openAskForSendEmailModal() {
      this.$refs.askForSendEmailModal.open();
    },
    clickActivationLetterButton() {
        if (this.activationLetterExists) {
            this.$refs.activationLetterModal.open();
        } else {
            this.generateActivationLetter();
        }
    },
    generateActivationLetter() {
        this.closeActivationLetterModal();
        const filename = 'Aktivierungsbrief.pdf';
        const href = downloadLinkMaker(this.$store.getters, "/download_service/activation_letter", filename, {});
        viewDocument({ href, filename });
        this.activationLetterExists = true;
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Bitte versenden Sie den Aktivierungsbrief aus Sicherheitsgründen nur per Post oder händigen Sie ihn direkt Ihren Kunden aus. Versenden Sie ihn auf keinen Fall per E-Mail!", 'info'));
    },
    closeActivationLetterModal() {
        if (this.$refs.activationLetterModal?.isOpen)
            this.$refs.activationLetterModal.close();
    },
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveKundeWechselIntern();
    next();
  },
  components: {
    InputToggleSwitch,
    InputField,
    BaseButton,
    AnimatedSpinner,
    AskForSendEmailModal,
    BaseModal,
    DatePickerField,
  },
}
</script>
