var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "updaterModal",
    attrs: {
      modalTitle: _vm.modalTitle,
      labelButtonCancel: "Schliessen",
      labelButtonConfirm: _vm.confirmButtonLabel,
      autoCloseOnRouteNavigation: false,
      confirmLoading: _vm.refreshing
    },
    on: { onConfirmButton: _vm.refreshApp },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [_vm._v(_vm._s(_vm.message))]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }