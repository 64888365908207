import { StepperMediator } from '@/components/stepper/utils';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import STECKBRIEF_TYPES from '@/components/steckbrief/store/types';
import CORE_TYPES from '@/store/core/types.js';
import validator from "@/mixins/validator";
import { mapGetters } from 'vuex';
import { removeBackActionFromQuery } from '@/router/breadcrumb/index';
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import { parse } from '@/helpers/number-formatter';
import BRIDGE_TYPES from '@/store/bridge/types';

const categoriesGF =[
  { path: 'IMMOBILIEN', label: 'Immobilien' },
  { path: 'INFRASTRUKTUR', label: 'Infrastruktur' },
  { path: 'SCHIFFE', label: 'Schiffe' },
  { path: 'FLUGZEUGE', label: 'Flugzeuge' },
  { path: 'ERNEUERBARE_ENERGIE', label: 'Erneuerbare Energie (z.B. Windkraft, Solar)' },
  { path: 'CONTAINER', label: 'Container' },
  { path: 'ROHSTOFFE', label: 'Rohstoffe' },
  { path: 'PRIVATE_EQUITY', label: 'Private Equity' },
  { path: 'SONSTIGES', label: 'Sonstiges' },
];

const NICHT_VERWENDEN = 'NICHT_VERWENDEN';

const mixin = {
  mixins: [validator],
  validators: {},
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      anlegerprofil: ANLEGERPROFIL_TYPES.GETTERS.ANLEGERPROFIL,
      personType: ANLEGERPROFIL_TYPES.GETTERS.PERSON_TYPE,
      beratungVermittler: STECKBRIEF_TYPES.GETTERS.STECKBRIEF_BERATUNG_VERMITTLER,
      _warnings: ANLEGERPROFIL_TYPES.GETTERS.WARNINGS,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    data() {
      return this.anlegerprofil || {};
    },
    warnings() {
      this.stepper && this.stepper.updateWarnings({
        warnings: this._warnings, 
        personType: this.getPersonType,
        substepCurrentValue: 0, 
        checkAllSteps: this.loginData?.rights?.isBroker || this.loginData?.rights?.isBypSession
      });
      return this._warnings;
    },
    warningsProStep() {
      return this.warnings.filter(warn => {
        if (this.isPersonenAuswahlStep(this.getStepKey)) {
          return this.getStepKey === warn.stepKey;
        } else { 
          return warn.personType === this.getPersonType
          && (warn.substepKey && warn.substepKey === this.getSubstepKey || !warn.substepKey && warn.stepKey === this.getStepKey);
        }
      }) || [];
    },
    schiene() {
      return this.$route.query.schiene || this.data?.extraData?.schiene || '';
    },
    finance() {
      return this.data?.finance || {};
    },
    isRefusedPageFinance() {
      return this._isRefusedPage(this.data, 'finanzen');
    },
    path() {
      return this.isWealthKonzept ? '/beratung/anlegerprofilWK' : '/beratung/anlegerprofil';
    },
    hasRefuse() {
      return !this.isWealthKonzept && !this.isVermittlung
        && this.getPersonType === 'person1' && ['step1-3', 'step2-1', 'step3-1', 'anlageziele', 'step5-1', 'step6-1'].includes(this.getSubstepKey);
    },
    hasConfirmMoney() {
      return this.getStepKey === 'finanzen' && !this.isFA && !this.isWealthKonzept && !this.isVermittlung && !this.isDialogComfort;
    },
    isDialogComfort() {
      return !!this.data?.extraData?.isDialogComfort;
    },
    isDialogComfortSchema() {
      return !!this.data?.extraData?.isDialogComfortSchema;
    },
    isVermittlung() {
      return this.beratungVermittler === 'VERMITTLUNG';
    },
    isChildOrFirm() {
      return this.data?.extraData?.isChildOrFirm;
    },
    stepper() {
      return this.getStepper(this.getPersonType, this.isChildOrFirm);
    },
    getPersonType() {
      return this.personType;
    },
    getStepKey() {
      return this.$route.params.step || 'aktionen';
    },
    getSubstepKey() {
      return (this.$route.path.includes('step2-card') ? 'step2-card/' 
        : (this.$route.path.includes('stepGF-card') ? 'stepGF-card/' : '')) 
        + this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1);
    },
    isFA() {
      return this.loginData && this.loginData.rights && this.loginData.rights.isFA;
    },
    getBM1Label() {
      return this.isFA ? '1. Zeichnungsberechtigter' : '1. Bevollmächtigter';
    },
    getBM2Label() {
      return this.isFA ? '2. Zeichnungsberechtigter' : '2. Bevollmächtigter';
    },
    categoriesGF() {
      return categoriesGF;
    },
    isDisabled() {
      return this.data?.extraData?.isDisabled;
    },
    stepperPersonen() {
      return [
        { stepKey: 'person1', label: '1. Person', current: true },
        { stepKey: 'person2', label: '2. Person', hidden: !this.data?.startStep?.depotOwner2Id || this.data?.startStep?.depotOwner2Id === NICHT_VERWENDEN},
        { stepKey: 'person3', label: '3. Person', hidden: !this.data?.startStep?.depotOwner3Id || this.data?.startStep?.depotOwner3Id === NICHT_VERWENDEN },
        { stepKey: 'bm1', label: this.getBM1Label, hidden: !this.data?.startStep?.mandatory1Id || this.data?.startStep?.mandatory1Id === NICHT_VERWENDEN },
        { stepKey: 'bm2', label: this.getBM2Label, hidden: !this.data?.startStep?.mandatory2Id || this.data?.startStep?.mandatory2Id === NICHT_VERWENDEN },
        { stepKey: 'aktionen', label: 'Zusammenfassung', totalProgress: 1 }
    ];
  },
    stepperBM() {
      return new StepperMediator([
        this.stepKenntnisse,
        this.stepKenntnisseGF,
      ]);
    },
    stepperExtraPerson() {
      return new StepperMediator([
        { stepKey: 'personInfo', label: 'Wirtschaftliche Angaben', totalProgress: 3, personType: this.getPersonType,
          substeps: [{substepKey: 'step1-1', title: 'Wirtschaftliche Angaben' },
            {substepKey: 'step1-2', title: 'Stehen wesentliche Ereignisse bevor?' },
            {substepKey: 'step1-3', title:  'Bisherige Erfahrungen' }]},
        this.stepKenntnisse,
        this.stepKenntnisseGF,
      ]);
    },
    stepperMain() {
      const steps = [
        { stepKey: 'auswahl', label: 'Personenauswahl', totalProgress: 1, personType: 'person1', ignorePersonType: true,
            substeps: [{ substepKey: 'personenauswahl' }] },
        { stepKey: 'personInfo', label: 'Wirtschaftliche Angaben', totalProgress: 3,
            substeps: [{substepKey: 'step1-1',title: 'Wirtschaftliche Angaben' },
                        {substepKey: 'step1-2', title: 'Stehen wesentliche Ereignisse bevor?', hidden: this.isDialogComfortSchema },
                        {substepKey: 'step1-3', title:  'Bisherige Erfahrungen' }] },
        { stepKey: 'finanzen', label: 'Finanzielle Verhältnisse', totalProgress: 7, hidden: this.isVermittlung || this.isDialogComfortSchema,
            substeps: [{substepKey: 'step3-1', title: 'Grundlage und Höhe des derzeit regelmäßigen Einkommens' },
                        {substepKey: 'step3-2', title: 'Aktuelle liquide Vermögenswerte' },
                        {substepKey: 'step3-3', title: 'Immobilienvermögen' },
                        {substepKey: 'step3-4', title: 'Kapitalanlage / sonstige Vermögenswerte' },
                        {substepKey: 'step3-5', title: 'Verbindlichkeiten / Schulden' },
                        {substepKey: 'step3-6', title: 'Regelmäßige finanzielle Verpflichtungen (pro Monat)' },
                        {substepKey: 'step3-summe', title: 'Summen' },
                        {substepKey: 'step3-7', title: 'Weitere Angaben', hidden: this.isWealthKonzept }] },
        { stepKey: 'finanzenKompakt', label: 'Finanzielle Verhältnisse', totalProgress: 1, hidden: !this.isDialogComfortSchema,
            substeps: [{substepKey: 'finanzenKompakt' }] },
        { stepKey: 'anlageziele', label: 'Anlageziele', totalProgress: 1, hidden: this.isVermittlung,
        substeps: [{ substepKey: 'anlageziele' }] },
        { stepKey: 'risikoneigung', label: 'Risikoneigung', totalProgress: 1, hidden: this.isVermittlung,
            substeps: [{ substepKey: 'step5-1' }] },
        { stepKey: 'risikobereitschaft', label: 'Risikobereitschaft', totalProgress: 1, hidden: this.isVermittlung,
            substeps: [{ substepKey: 'step6-1' }] },
        { stepKey: 'nachhaltigkeit', label: 'Nachhaltigkeit', totalProgress: 1, hidden: !(this.data?.extraData?.hatNachhaltigkeit || false),
            substeps: [{ substepKey: 'nachhaltigkeit' }] },
        { stepKey: 'geschlosseneInvest', label: 'Geschlossene Investmentvermögen', totalProgress: 1, hidden: !this.hasGF,
          substeps: [{ substepKey: 'geschlosseneInvest' }] },
        { stepKey: 'zusatz-dje', label: 'Zusatzangaben Managed Depot', totalProgress: 1, hidden: !(this.data?.extraData?.zeigeZusatzDJE || false),
          substeps: [{ substepKey: 'zielmarkt-dje', title: 'Zielmarkt' }, 
            { substepKey: 'verlusttragfaehigkeit-dje', title: 'Verlusttragfähigkeit' }, 
            { substepKey: 'kontakt-dje', title: 'Kontakt' }, 
            { substepKey: 'nachhaltigkeit-dje', title: 'Nachhaltigkeit' }] },
        { stepKey: 'warnhinweise', 
          label: `${this.data?.extraData?.warnhinweise === 'HINWEIS_1_2' ? 'Warnhinweise ' : 'Warnhinweis '}${this.isVermittlung ? 'Vermittlung' : 'Beratung'}`, 
          totalProgress: 1, hidden: !this.data?.extraData?.warnhinweise,
          substeps: [{ substepKey: 'warnhinweise' }] }
      ];
      if (!this.isChildOrFirm) {
        const stepKenntnisse = this.stepKenntnisse;
        steps.splice(2, 0, stepKenntnisse);
        
        const stepKenntnisseGF = this.stepKenntnisseGF;
        steps.splice(9, 0, stepKenntnisseGF);
      }
      return new StepperMediator(steps);
    },
    stepKenntnisse() {
      let substeps = [{substepKey: 'step2-1', title: 'Bisherige Anlagen vor diesem Vermittlungs-/ Beratungsgespräch' }];
      const exp = this.getExperience(this.getPersonType);
      exp?.categories?.forEach(cat => 
        substeps.push({ substepKey: 'step2-card/' + cat.path, title: cat.label, hidden: exp?.emptyCategories?.includes(cat.path) } ));

      return { 
        stepKey: 'kenntnisse', 
        label: 'Kenntnisse und Erfahrungen Kapitalanlagen', 
        totalProgress: 1,
        personType: this.getPersonType,
        warningTitle: this.getPersonType === 'bm1' ? '1. Bevollmächtigter' : '',
        substeps:  substeps };
    },
    stepKenntnisseGF() {
      const substeps = [{substepKey: 'stepKenntnisseGF', title: 'Bisherige Anlagen vor diesem Vermittlungs-/ Beratungsgespräch' }];   
      const emptyCategoriesGF = this.isGfZusatzAktiv && this.hasGFKenntnisse && this.getExperienceGF(this.getPersonType)?.emptyCategories 
        || this.categoriesGF.map(cat => cat.path);
      this.categoriesGF?.forEach(cat => {
        substeps.push({ substepKey: 'stepGF-card/' + cat.path, title: cat.label, hidden: emptyCategoriesGF?.includes(cat.path) } )
      });
      const zeigeGF = !this.hasGFKenntnisse ? false : this.hasGF && this.isGfZusatzAktiv ||  false;

      return { 
        stepKey: 'kenntnisseGF', 
        label: 'Kenntnisse und Erfahrungen geschlossene Investmentvermögen', 
        totalProgress: 1,
        personType: this.getPersonType,
        hidden: !zeigeGF,
        substeps: substeps }
    },
    stepZusammenfassung() {
       const steps = [ { stepKey: 'aktionen', label: 'Zusammenfassung', totalProgress: 1 }];
       return new StepperMediator(steps);
    },
    isGfZusatzAktiv() {
      return this.data?.closedProperty?.erlaubtGf || this.data?.closedProperty?.erlaubtSonstige || this.data?.closedProperty?.erlaubtDirekt;
    },
    isWealthKonzept() {
      return this.schiene === 'WEALTH_KONZEPT';
    },
    isZusatzDJEAktiv() {
      return this.data?.zusatzDJE?.djeAktiv || false;
    },
    hasGF() {
      return !this.isFA && !this.isWealthKonzept && this.data?.extraData?.zeigeZusatzGF;
    },
    hasGFKenntnisse() {
      return this.hasGF && this.data?.extraData?.zeigeGFExperience || false;
    },
    isDialogFVV() {
      return this.data?.extraData?.isDialogFVV
    },
    summeEinkommen() {
      return (parse(this.finance?.salary) || 0) + (parse(this.finance?.freelancerRevenue) || 0) + (parse(this.finance?.capitalRevenue) || 0) 
        + (parse(this.finance?.otherRevenue) ||0);
    },
    summeVermoegen() {
      return (parse(this.finance?.cash) || 0) + (parse(this.finance?.freeBankDeposit) || 0);
    },
    sumImmobilien() {
      return calculateSumImmobilien(this.finance);
    },
    summeAnlage() {
      return (parse(this.finance?.actualDepot) || 0) +
        (parse(this.finance?.bondDepot) || 0) +
        (parse(this.finance?.bondDepotNotRelevant) || 0) +
        (parse(this.finance?.boundBankDeposit) || 0) +
        (parse(this.finance?.alternativeInvestment) || 0) +
        (parse(this.finance?.art) || 0) +
        (parse(this.finance?.buildingSaving) || 0) +
        (parse(this.finance?.insurance) || 0) +
        (parse(this.finance?.otherInvestment) || 0);
    },
    summeSchulden() {
      return (parse(this.finance?.longTermCredit) || 0) +
        (parse(this.finance?.shortTermCredit) || 0) +
        (parse(this.finance?.otherCredit) || 0);
    },
    summeAusgaben() {
      return (parse(this.finance?.rent) || 0) +
        (parse(this.finance?.leasing) || 0) +
        (parse(this.finance?.creditPayment) || 0) +
        (parse(this.finance?.household) || 0) +
        (parse(this.finance?.maintainance) || 0) +
        (parse(this.finance?.insurancePayment) || 0) +
        (parse(this.finance?.otherPayment) || 0);
    },
    summeFreiMonatlich() {
      return parse(this.summeEinkommen) - parse(this.summeAusgaben);
    },
    sumCapital() {
      return (parse(this.finance?.bondDepot) || 0) + (parse(this.finance?.freeBankDeposit) || 0) 
        + (parse(this.finance?.otherInvestment) || 0);
    },
  },
  methods: {
    isPersonenAuswahlStep(stepKey) {
      return stepKey === 'auswahl';
    },
    isCustomerDataLink(warning) {
      return this.isPersonenAuswahlStep(warning?.stepKey) && warning?.id?.includes('/persoenlichedaten');
    },
    goToCustomerData(warning) {
      if (warning?.id) {
        this.$addBreadcrumb({
          label: 'zurück zum Anlegerprofil',
          fullPath: this.$route.fullPath,
          breadcrumb: "Schulbildung und Qualifikationen",
        });
        this.$router.push(warning.id)
      }
    },
    getDateStr(value) {
      return value && DatePickerUtils.toDateStr(value) || ''
    },
    updatePersonType(personType) {
      if (personType) {
        this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.SET_PERSON_TYPE, personType);
      }
    },
    valueChangedFinance({compId, value}) {
      this.updateStore('finance', compId, value);
    },
    updateStore(category, compId, value, forceUpdate = false) {
      if (category && compId) {
        this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_DATA_STORE, { category: category, data: { [compId]: value }, forceUpdate});
      }
    },
    updateStoreCheckBoxGroup(category, data, forceUpdate = false) {
      if (category && data) {
        this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_DATA_STORE, { category: category, data: data, forceUpdate });
      }
    },
    updateStoreExperienceCard(category, data) {
      if (category && data && Object.keys(data).length) {
        this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_DATA_STORE_EXPERIENCE_CARD, { category: category, data: data });
      }
    },
    doExperienceCardChange(data = {}, category= {}, compId, value) {
      if (compId) {
        const card = Object.assign({}, data, {[compId]: value});
        card.path = category.path;
        this.updateStoreExperienceCard(this.getCategory(), card)
      }
    },
    getPersonTypeForce() {
      return this.personType;
    },
    getVisibleKenntnisseSubsteps(stepper) {
      const step = stepper?.getCurrentStep('kenntnisse');
      return step?.substeps && step.substeps.length ? step.substeps
        .filter(substep => !substep.hidden && substep.substepKey.includes('card')) : [];
    },
    getVisibleKenntnisseGFSubsteps(stepper) {
      const step = stepper?.getCurrentStep('kenntnisseGF');
      return step?.substeps && step.substeps.length ? step.substeps
        .filter(substep => !substep.hidden && substep.substepKey.includes('card')) : [];
    },
    updateWarnings(hasFormStr = false) {
      this.warningsProStep.filter(warn => warn.id).forEach(warn => {
        this.$pushErrors((hasFormStr ? 'form.' : '') + warn.id, [warn.message]);
      });
    },
    getRouteParams() {
      const knownParams = [
        'pruefnummer',
        'beratungsMappeId',
        'schiene',
      ]
      const routeQuery = this.$route.query || {};
      const payload = knownParams.reduce((accu, curr) => {
        routeQuery[curr] ? accu[curr] = routeQuery[curr] : ''
        return accu;
      }, {})

      return payload;
    },
    doSave(data = {}) {
      if (this.getStepKey === 'aktionen') {
        return;
      }
      data.category = this.getCategory();
      if (!data.category) {
        return;
      }
      data.step = this.getCategorySubstep();
      data.personType =  this.getPersonType;
      if (this.getStepKey === 'kenntnisse') {
        data.categoryRequest = 'experience'
      } else if (this.getStepKey === 'kenntnisseGF') {
        data.categoryRequest = 'experienceGF'
      }
      return this.$store.dispatch(ANLEGERPROFIL_TYPES.ACTIONS.SAVE_ANLEGERPROFIL, data);
    },
    getCategory() {
      switch(this.getStepKey) {
        case 'auswahl': 
          return 'startStep';
        case 'personInfo': 
          switch(this.getPersonType) {
            case 'person1':
              return 'depotOwner1'
            case 'person2':
              return 'depotOwner2'
            case 'person3':
              return 'depotOwner3'
            default: return;
          }
        case 'kenntnisse': 
          switch(this.getPersonType) {
            case 'person1':
              return 'experience1'
            case 'person2':
              return this.isChildOrFirm ? 'experience1' : 'experience2'
            case 'person3':
              return 'experience2'
            case 'bm1':
              return 'mandatory1'
            case 'bm2':
              return 'mandatory2'
            default: return;
          }
        case 'finanzen': 
          return 'finance';
        case 'finanzenKompakt':
          return 'financeCompact'
        case 'anlageziele': 
          return 'anlageziele';
        case 'risikoneigung': 
          return 'risikoneigung';
        case 'risikobereitschaft': 
          return 'riskRange';
        case 'geschlosseneInvest': 
          return 'closedProperty';
        case 'nachhaltigkeit': 
          return 'nachhaltigkeit';
        case 'warnhinweise': 
          return 'warnhinweise';
        case 'kenntnisseGF': 
          switch(this.getPersonType) {
            case 'person1':
              return 'experienceGF1'
            case 'person2':
              return this.isChildOrFirm ? 'experienceGF1' : 'experienceGF2'
            case 'person3':
              return 'experienceGF2'
            case 'bm1':
              return 'mandatoryGF1'
            case 'bm2':
              return 'mandatoryGF2'
            default: return;
          }
        case 'zusatz-dje':
          return 'zusatzDJE';
        default:
          return;
      }
    },
    getCategorySubstep() {
      let substepKey = this.getSubstepKey || '';
      if (substepKey.includes('/')) {
        substepKey = substepKey.split('/')[0];
      }
      switch (substepKey) {
        case 'step1-1':
        case 'step3-1':
          return 'step1';
        case 'step1-2':
        case 'step3-2':
          return 'step2';
        case 'step1-3':
        case 'step3-3':
          return 'step3';
        case 'finanzenKompakt':
          return 'financeCompact'
        case 'step3-4':
          return 'step4';
        case 'step3-5':
          return 'step5';
        case 'step3-6':
          return 'step6';
        case 'step3-7':
          return 'step7';
        case 'step2-1':
        case 'stepKenntnisseGF':
          return 'main';
        case 'step2-card':
        case 'stepGF-card':
          return 'card';
        default: return substepKey;
      }
    },
    getStepper(personType = 'person1') {
      switch (personType) {
        case 'person1':
          return this.stepperMain;
          case 'person2':
          case 'person3':
            return this.stepperExtraPerson;
          case 'bm1':
          case 'bm2':
            return this.stepperBM;
          case 'aktionen':
            return this.stepZusammenfassung;
          default:
            return this.stepperMain;
      }
    },
    async setStepPerson(personType, selectFirstStep = true) {
      await this.doSave();

      if (personType) {
        this.updatePersonType(personType);

        let step, substepKey;
        if (personType !== 'aktionen') {
          // if next step or click to person tab => open first step
          if (selectFirstStep) {
            step = this.stepper?.getFirstStep();
            const substeps = step && step.substeps && step.substeps.filter(substep => !substep.hidden);
            substepKey = substeps && substeps[0].substepKey || '0';
          } else {
            // if previous step => open last step and last substep
            step = this.stepper?.getLastStep();
            const substeps = step && step.substeps && step.substeps.filter(substep => !substep.hidden);
            substepKey = substeps && substeps[substeps.length-1].substepKey || '0';
          }
        }

        this.navigateToStep(step?.stepKey, substepKey, personType); 
        this.stepperPersonen.forEach(person => person.stepKey === personType ? person.current = true : person.current = false);
      }
    },
    getPersonStep(stepKey = 'person1') {
      return this.stepperPersonen.find(step => stepKey === step.stepKey) || {};
    },
    getNextPersonStep(stepKey) {
      const currentList = this.stepperPersonen.filter(step => !step.hidden);
      const index = currentList.findIndex((step) => step.stepKey === stepKey);
      return index < (currentList.length - 1) ? currentList[index + 1] : null;
    },
    getPreviousPersonStep(stepKey) {
      const currentList = this.stepperPersonen.filter(step => !step.hidden);
      const index = currentList.findIndex((step) => step.stepKey === stepKey);
      return index > 0 && currentList[index - 1];
    },
    _isRefusedPage(data, stepKey) {
      let refused = false;
      if (data?.infoRefuse) {
        switch(stepKey) {
          case 'personInfo':
            refused = data.infoRefuse.refuseBehavior;
            break;
          case 'kenntnisse':
            refused = data.infoRefuse.refuseKnowledge;
            break;
          case 'finanzen':
            refused = data.infoRefuse.refuseFinance;
            break;
          case 'anlageziele':
            refused = data.infoRefuse.refuseAnlageziele;
            break;
          case 'risikoneigung':
            refused = data.infoRefuse.refuseRisikoneigung;
            break;
          case 'risikobereitschaft':
            refused = data.infoRefuse.refuseRiskRange;
            break;
          default:
            break;
        }
      }
      return refused;
    },
    getRefuseCategory(stepKey = '') {
      switch(stepKey) {
        case 'personInfo':
          return 'person';
        case 'kenntnisse':
          return 'experience';
        case 'finanzen':
          return 'finance';
        case 'anlageziele':
          return 'anlageziele';
        case 'risikoneigung':
          return 'risikoneigung';
        case 'risikobereitschaft':
          return 'riskRange';
        default:
          break;
      }
    },
    async navigateToStep(stepKey, substepKey, personType) {
      let query = this.$route.query || {}
      query = removeBackActionFromQuery(query)
      if (personType === 'aktionen') {
        const path = `${this.path}/aktionen`;
        if (path !== this.$route.path) {
          await this.$router.push({ path: path,
          query: query })
        }
      } else if (stepKey) {
        const path = `${this.path}/${personType}/${stepKey}/${substepKey}`;
        if (path !== this.$route.path) {
          await this.$router.push({ path: path,
            query: query })
        }
      }  
    },
    getDescription(warning) {
        if (warning) {
          return this.isSimpleWarning(warning) ? warning : this.getWarnMessage(warning.title, warning.message);
        }
    },
    getWarningTitle(title) {
        return title ? title + ': ' : '';
    },
    getWarnMessage(title, message) {
        return this.getWarningTitle(title) + (message || '');
    },
    isSimpleWarning(warning) {
        return typeof warning === 'string';
    },
    getPerson(profile, personType) {
      if (profile && personType) {
        switch (personType) {
          case 'person1':
            return profile.depotOwner1;
          case 'person2':
            return profile.depotOwner2;
          case 'person3':
            return profile.depotOwner3;
          case 'bm1':
            return profile.mandatory1;
          case 'bm2':
            return profile.mandatory2;
          default:
            return profile.depotOwner1;
        }
      }
    },
    getPersonId(person = {}) {
      return person.personId ? person.personId + '' : (person.person ? person.person.value : '');
    },
    getExperience(personType) {
      if (this.data && personType) {
        switch (personType) {
          case 'person1':
            return this.data.experience1;
          case 'person2':
            return this.data.extraData?.isChildOrFirm ? this.data.experience1 : this.data.experience2;
          case 'person3':
            return this.data.experience2;
          case 'bm1':
            return this.data.mandatory1;
          case 'bm2':
            return this.data.mandatory2;
          default:
            return this.data.experience1;
        }
      }
    },
    getExperienceGF(personType) {
      if (this.data && personType) {
        switch (personType) {
          case 'person1':
            return this.data.experienceGF1;
          case 'person2':
            return this.data.extraData?.isChildOrFirm ? this.data.experienceGF1 : this.data.experienceGF2;
          case 'person3':
            return this.data.experienceGF2;
          case 'bm1':
            return this.data.mandatoryGF1;
          case 'bm2':
            return this.data.mandatoryGF2;
          default:
            return this.data.experienceGF1;
        }
      }
    },
    updateExperienceCategories(personType, categories, isGF = false) {
      if (personType && categories) {
        const payload = {
          categories
        }

        switch (personType) {
          case 'person1':
            payload.propertyName = isGF ? 'experienceGF1' : 'experience1'
            break;
          case 'person2':
            const name = isGF ? 'experienceGF' : 'experience';
            payload.propertyName = this.isChildOrFirm ? (name + '1') : (name + '2');
            break;
          case 'person3':
            payload.propertyName = isGF ? 'experienceGF2': 'experience2'
            break;
          case 'bm1':
            payload.propertyName = isGF ? 'mandatoryGF1' : 'mandatory1'
            break;
          case 'bm2':
            payload.propertyName = isGF ? 'mandatoryGF2' : 'mandatory2'
            break;
          default:
            payload.propertyName = 'experience1'
            break;
        }

        this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_ANLEGERPROFIL_EXPERIENCE_CATEGORIES, payload)
      }
    },
    getBooleanValue(strValue) {
      return strValue && strValue === "yes" ? true : false;
    },
    isKennntnisseCardFilled(card) {
      return card && (card.experience !== '0' || card.knowledge !== '0' || card.sum !== '0' || card.transaction !== '0');
    },
    isKennntnisseGFCardFilled(card) {
      return card && (card.experience && card.experience !== '0' 
      || card.knowledge && card.knowledge !== '0' || card.transaction
      || card.amountTotal || card.amountEur || card.amountUsd || card.amountGbp || card.amountSonstige );
    },
    isKenntnisseStepEmpty(stepper) {
      const step = stepper?.getCurrentStep('kenntnisse');
      return step && step.substeps?.length === 1;
    },
    removeAllKenntnisse() {
      const emptyCategories = this.data?.experience1?.categories?.map(cat => cat.path) || [];
      const emptyCategoriesGF = categoriesGF.map(cat => cat.path);
      this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.REMOVE_ALL_KENNTNISSE, { emptyCategories: emptyCategories, emptyCategoriesGF: emptyCategoriesGF })
    },
  },
};

export default mixin;

export function calculateSumImmobilien(data) {
  if (!data) {
    return 0;
  }
  let sum = 0;
  sum += !data?.homeHollyOwned ? 0 
    : (parse(data?.home) * (data?.homeHollyOwned === 'CO_OWNER' 
      ? (parse(data?.homePercentage) / 100) : 1)); 
  sum += !data?.appartmentsHollyOwned ? 0 
    : (parse(data?.appartments) * (data?.appartmentsHollyOwned  === 'CO_OWNER' 
      ? (parse(data?.appartmentsPercentage) / 100) : 1));
  sum += !data?.otherPropertyHollyOwned ? 0 
    : (parse(data?.otherProperty) * (data?.otherPropertyHollyOwned  === 'CO_OWNER' 
      ? (parse(data?.otherPropertyPercentage) / 100) : 1));
  return sum;
}