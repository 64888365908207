<template>
  <div>
    <FinanzenRegelmaessigeRate :disabled="isDisabled || isRefusedPageFinance" :finance="finance" :showHinweisBetragNiedrig="showHinweisBetragNiedrig" 
      @changed="valueChangedFinance"/>
    
    <FinanceLineSum  :summe="summeAusgaben" title="Regelmäßige finanzielle Verpflichtungen" />
  </div>
</template>

<script>
import anlegerprofilMixin from "@/mixins/anlegerprofil/anlegerprofil-mixin.js";
import FinanzenRegelmaessigeRate from '@/components/anlegerprofil/FinanzenRegelmaessigeRate.vue';
import FinanceLineSum from '@/components/anlegerprofil/FinanceLineSum.vue';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    FinanceLineSum,
    FinanzenRegelmaessigeRate,
  },
  computed: {
    showHinweisBetragNiedrig() {
      if (!this.finance.labelHinweisBetragNiedrig || !this.finance.limitHinweisBetragNiedrig) {
        return false;
      } else if (!this.summeAusgaben || this.summeAusgaben < this.finance.limitHinweisBetragNiedrig) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.updateWarnings();
  },
 
};
</script>

<style scoped>
</style>