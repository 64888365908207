<template>
  <div>
    <div v-if="showAsTable">
      <SortableTable 
        ref="sortableTable"
        :headers="legendHeaders"
        :rows="rowsWithId"
        :scrollHorizontally="false"
        :pageSize="pageSize"
        :page="pageIndex"
        :maxHeaderLines="1"
        :sortedManually="sortedManually"
        :sortableRows="sortRows"
        @sort="onSort"
        @orderChanged="onOrderChanged"
      >
        <template #header_marker>
          <InputCheckBoxItem
              :value="areAllSelected"
              :indeterminate="areOnlySomeSelected"
              tid="legendTable"
              @input="changeStatus"
              style="margin-left: 3px"
          />
        </template>
        <template #marker="{row}">
          <div class="like-link" :style="row.marker" @click="onClickLegend(row.name)"></div>
        </template>
        <template #name="{row}">
          <div class="like-link" :style="row.styName" @click="onClickLegend(row.name)"
            @mouseover="changeHighlight(row.name, true)" 
            @mouseleave="changeHighlight(row.name, false)" 
          >{{row.name}}</div>
        </template>
      </SortableTable>
      <div v-if="legendRows.length > pageSize" class="table-pagination">
        <div class="table-pagination--left">
          <PageRowCount :displayedItems="displayedRowCount" :totalRows="legendRows.length"/>
        </div>
        <div class="table-pagination--right">
          <Pagination v-if="displayedRowCount < legendRows.length"
              :totalPages="pageCount"
              :currentPage="pageIndex"
              @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>
    <div v-else  class="raster">
      <div v-for="row in legendRows" :key="row.name"
        @click="onClickLegend(row.name)"
        @mouseover="changeHighlight(row.name, true)" 
        @mouseleave="changeHighlight(row.name, false)" 
      >
        <div>
          <div class="legend-marker"><div :style="row.marker"></div></div>
          <div class="legend-name" :style="row.styName">{{row.name}}</div>
        </div>
        <template v-if="addOnHeaders && addOnHeaders.length">
          <div class="" v-for="(col, idx) in addOnHeaders" :key="idx">
            <div v-if="row[col.key]">
              <span class="label-small">{{col.label}}&nbsp;</span><span class="font-bold">{{displayValue(row, col)}}</span><span v-if="col.props && col.props.symbol">&nbsp;{{col.props.symbol}}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import SortableTable from "@/components/table2/core/SortableTable.vue";
import {SlotColumn, numberToSortable, textToSortable} from "@/components/table2/table_util.js";
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import { formatNumber } from '@/helpers/number-formatter.js';
import PageRowCount from '@/components/table2/core/PageRowCount.vue';
import Pagination from '@/components/table2/core/Pagination.vue';

export default {
  components: {
    SortableTable,
    InputCheckBoxItem,
    PageRowCount,
    Pagination,
  },
  mixins: [
    // menuGenerator
  ],
  props: {
    showAsTable: {
      type: Boolean,
      default: true,
    },
    chartSeries: {
      type: Array,
      required: true,
    },
    lineChart: {
      type: Object,
      required: true,
    },
    addOnHeaders: {
      type: Array,
      required: false,
    },
    addOnRows: {
      type: Object,
      required: false,
    },
    sortRows: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      legendRows: [],
      sortedManually: null,
      unsorted: '',
      pageIndex: 0,
      pageSize: 20,
    }
  },
  computed: {
    legendHeaders() {
      const lockedLeft = [SlotColumn("marker", null, 50, 0).makeAlwaysVisible()];
      lockedLeft.push(SlotColumn("name", "Name", 150).makeAlwaysVisible().makeSortable());

      return {
        lockedLeft,
        center: (this.addOnHeaders || []),
        lockedRight: [],
      }
    },
    rowsWithId() {
        return this.legendRows.map(row => {
            return {
                id: row.name,
                row,
                acceptsFile: false
            };
        });
    },
    pageCount() {
      return Math.ceil(this.legendRows.length / this.pageSize);
    },
    displayedRowCount() {
        if (this.pageIndex < this.pageCount - 1)
            return this.pageSize;
        else
            return this.legendRows.length - this.pageSize * this.pageIndex;
    },
    hiddenSeries() {
      const result = {};
      this.legendRows.forEach(legend => {
        result[legend.name] = !!legend.status;
      })
      return result;
    },
    areAllSelected() {
      return this.legendRows.every(l => l.status);
    },
    areOnlySomeSelected() {
      return this.legendRows.some(l => l.status) && this.legendRows.some(l => !l.status);
    },
  },
  watch: {
    'chartSeries': 'setLegendRows',
    'addOnRows': 'setLegendRows',
  },
  mounted() {
    if (this.sortRows) {
      this.sortedManually = {
        ascending: true,
        key: null,
      };
    }
    this.setLegendRows();
  },
  beforeDestroy() {
    if (this.sortRows) {
      this.$emit('newOrder', this.legendRows)
    }
  },
  methods: {
    onOrderChanged(ordered) {
      const legendRows = [ ...ordered, ];
      this.unsorted = JSON.stringify(legendRows);
      this.setLegendRowsValue(legendRows);
    },
    setLegendRows() {
      const old = {};
      if (this.chartSeries?.length && !this.chartSeries[0].newSeries){
        this.legendRows.forEach(row => old[row.name] = row );
      }
      const legendRows = this.chartSeries.map((serie, idx) => {
        // 0 hidden, 1 normal, 2 highlight by mouseover, 3 highlight by click
        const status = (old?.[serie.name]?.status != null ? old[serie.name].status 
                        : (!serie.hidden ? (serie.highlight ? 3 : 1) : 0));
        let row = this.legendStyle({name: serie.name, color: serie.color, status})
        if (this.addOnRows?.[serie.name]) {
          row = {...row, ...this.addOnRows[serie.name]}
        }
        if (!status && this.lineChart?.$refs?.chart) {
          this.lineChart.$refs.chart.dispatchAction({
            type: 'legendUnSelect', name: row.name
          });
        }
        if (this.sortRows) {
          row.oben = [];
          row.unten = [];
        }
        return row;
      })
      this.unsorted = JSON.stringify(legendRows);
      this.pageIndex = 0;
      this.setLegendRowsValue(legendRows)
    },
    legendStyle(legend) {
      const dim = legend.status ? (legend.status == 1 ? 10 :12) : 8;
      const m = 6 - dim / 2
      const op = legend.status ? 1 : 0.5;
      legend.marker = `background-color:${legend.color};width:${dim}px;height:${dim}px;border-radius:50%;opacity:${op};margin:${m}px;`;
      const fw = legend.status == 3 ? 600 :400;
      legend.styName = `opacity:${op};font-weight:${fw};`;
      return legend;
    },
    onClickLegend(name) {
      const legend = this.legendRows.find(l => l.name===name);
      if (legend) {
        if (this.lineChart?.$refs?.chart){
          if (legend.status === 0) {
            legend.status = 1;
          } else if (legend.status === 3) {
            legend.status = 0;
          } else {
            legend.status = 3;
            if (this.lineChart?.$refs?.chart) {
              this.lineChart.$refs.chart.dispatchAction({
                type: 'highlight', seriesName: name,
              });
            }
            this.legendStyle(legend);
            return;
          }
          this.legendStyle(legend);
          if (this.lineChart.handleToggle) {
            this.lineChart.handleToggle(name);
          }
          if (this.lineChart?.$refs?.chart) {
            this.lineChart.$refs.chart.dispatchAction({
              type: (legend.status ? 'legendSelect' : 'legendUnSelect'), name
            });
          }
          this.$emit('changeVisible', name, legend.status, this.legendRows);
        }
      }
    },
    changeHighlight(name, highlight) {
      const legend = this.legendRows.find(l => l.name===name);
      if (legend.status === 1 || legend.status === 2) { 
        // 0 hidden, 1 normal, 2 highlight by mouseover, 3 highlight by click
        if (legend.status === 1 && !highlight || legend.status === 2 && highlight) {
          return;
        }
        legend.status = highlight ? 2 : 1;
        this.legendStyle(legend);
        if (this.lineChart?.$refs?.chart) {
          this.lineChart.$refs.chart.dispatchAction({
            type: (legend.status === 2 ? 'highlight' : 'downplay'), seriesName: name,
          });
        }
        this.$emit('changeHighlight', name, highlight);
      }
    },
    changeStatus(checked) {
      this.legendRows.forEach(legend => {
        if (legend.status && !checked) {
          legend.status = 0;
          if (this.lineChart?.$refs?.chart) {
            this.lineChart.$refs.chart.dispatchAction({ type: 'legendUnSelect', name: legend.name });
          }
          this.legendStyle(legend);
        } else if (!legend.status && checked) {
          legend.status = 1;
          if (this.lineChart?.$refs?.chart) {
            this.lineChart.$refs.chart.dispatchAction({ type: 'legendSelect', name: legend.name });
          }
          this.legendStyle(legend);
        }
      });
    },
    displayValue(row, col) {
      let value = row[col.key];
      if (typeof value == 'number') {
        value = formatNumber(value, 2);
      }
      return value;
    },
    setLegendRowsValue(legendRows) {
      this.legendRows = legendRows;
    },
    onPageChange(index) {
      this.pageIndex = Math.max(0, Math.min(this.pageCount - 1, index));
    },
    onSort(config) {
      if (!config.key) {
        this.setLegendRowsValue(JSON.parse(this.unsorted))
      } else {
        let toSortable = (arg) => arg;
        if (typeof this.legendRows[0][config.key] === 'number') {
          toSortable = numberToSortable;
        } else if (typeof this.legendRows[0][config.key] === 'string') {
          toSortable = textToSortable;
        }
        const legendRows = this.legendRows.slice().sort((a, b) => {
          const sortableA = toSortable(a[config.key]);
          const sortableB = toSortable(b[config.key]);
          if (sortableA == sortableB) {
            return 0;
          } else if (sortableA < sortableB) {
            return config.ascending ? -1 : 1;
          } else {
            return config.ascending ? 1 : -1;
          }
        });
        this.setLegendRowsValue(legendRows);
      }
      this.sortedManually = config;
    },
  },
}
</script>

<style scoped>
.like-link {
  cursor: pointer;
}
.legend-marker {
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
}
.legend-name {
  display: inline-block;
  max-width: 180px;
  margin-left: 3px;
  font-weight: 600;
  font-size: 10pt;
}
.label-small {
  font-size: 9pt;
  margin-left: 15px;
}
.raster {
  cursor: pointer;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(100px,auto));
  font-size: 13px;
}
.font-bold {
  font-weight: 600;
}
.table-pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px 0 12px;
}
.table-pagination--left,
.table-pagination--right {
  display: flex;
}
</style>