var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Eine neue Position ordern:")
        ]),
        _c("div", [
          _c("div", { staticClass: "row layout__negative-margin--8" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-4 col-xl-2 mx-0" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      isBlock: "",
                      isSecondary: "",
                      disabled: !_vm.kaufAvailable || _vm.isDisabled
                    },
                    on: {
                      click: function($event) {
                        return _vm.openFondsfinder("NEU_KAUF")
                      }
                    }
                  },
                  [_vm._v(" Kauf ")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-sm-4 col-xl-2 mx-0" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      isBlock: "",
                      isSecondary: "",
                      disabled: !_vm.sparplanAvailable || _vm.isDisabled
                    },
                    on: {
                      click: function($event) {
                        return _vm.openFondsfinder("NEU_SPARPLAN")
                      }
                    }
                  },
                  [_vm._v(" Sparplan ")]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Eine bestehende Position bearbeiten:")
          ]),
          _c("ComboBox", {
            key: _vm.depotNr,
            attrs: { values: _vm.depotComboboxValues, disabled: !_vm.hasDepot },
            on: {
              change: function($event) {
                return _vm.updateBgsNr($event)
              }
            },
            model: {
              value: _vm.bgsNr,
              callback: function($$v) {
                _vm.bgsNr = $$v
              },
              expression: "bgsNr"
            }
          }),
          _c("WertpapierorderAnteileInfo", {
            attrs: { position: _vm.selectedPosition, isMain: "" }
          }),
          _c("div", [
            _c("div", { staticClass: "row layout__negative-margin--8" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-4 col-xxl-2 mx-0" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        isBlock: "",
                        isSecondary: "",
                        disabled:
                          !_vm.hasDepot || !_vm.kaufAvailable || _vm.isDisabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.prepareAddPosition("ALT_KAUF")
                        }
                      }
                    },
                    [_vm._v(" Nachkauf ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12 col-sm-4 col-xxl-2 mx-0" },
                [
                  _vm.lagerstelle != "DWS"
                    ? _c(
                        "BaseButton",
                        {
                          attrs: {
                            isBlock: "",
                            isSecondary: "",
                            disabled:
                              !_vm.hasDepot ||
                              !_vm.verkaufAvailable ||
                              _vm.isDisabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.prepareAddPosition("ALT_VERKAUF")
                            }
                          }
                        },
                        [_vm._v(" Verkauf ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.isButtonVisible("ALT_TAUSCH")
                ? _c(
                    "div",
                    { staticClass: "col-12 col-sm-4 col-xxl-2 mx-0" },
                    [
                      _vm.lagerstelle !== "DAB"
                        ? _c(
                            "BaseButton",
                            {
                              attrs: {
                                isBlock: "",
                                isSecondary: "",
                                disabled:
                                  !_vm.hasDepot ||
                                  !_vm.tauschAvailable ||
                                  _vm.isDisabled
                              },
                              on: {
                                click: function($event) {
                                  return _vm.prepareAddPosition("ALT_TAUSCH")
                                }
                              }
                            },
                            [_vm._v(" Umschichtung ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "col-12 col-sm-4 col-xxl-2 mx-0" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        isBlock: "",
                        isSecondary: "",
                        disabled:
                          !_vm.hasDepot ||
                          !_vm.sparplanAvailable ||
                          _vm.isDisabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.prepareAddPosition("ALT_SPARPLAN")
                        }
                      }
                    },
                    [_vm._v(" Sparplan ")]
                  )
                ],
                1
              ),
              _vm.isButtonVisible("ALT_ENTNAHMEPLAN")
                ? _c(
                    "div",
                    { staticClass: "col-12 col-sm-4 col-xxl-2 mx-0" },
                    [
                      _vm.lagerstelle !== "DWS"
                        ? _c(
                            "BaseButton",
                            {
                              attrs: {
                                isBlock: "",
                                isSecondary: "",
                                disabled:
                                  !_vm.hasDepot ||
                                  !_vm.entnahmeplanAvailable ||
                                  _vm.isDisabled
                              },
                              on: {
                                click: function($event) {
                                  return _vm.prepareAddPosition(
                                    "ALT_ENTNAHMEPLAN"
                                  )
                                }
                              }
                            },
                            [_vm._v(" Entnahmeplan ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isButtonVisible("ALT_VERKAUF_ALLE_ANTEILE")
                ? _c(
                    "div",
                    { staticClass: "col-12 col-sm-4 col-xxl-2 mx-0" },
                    [
                      _vm.lagerstelle !== "FODB" && _vm.lagerstelle !== "DWS"
                        ? _c(
                            "BaseButton",
                            {
                              attrs: {
                                isBlock: "",
                                isSecondary: "",
                                disabled:
                                  !_vm.hasDepot ||
                                  !_vm.verkaufAvailable ||
                                  _vm.isDisabled
                              },
                              on: {
                                click: function($event) {
                                  return _vm.prepareAddPosition(
                                    "ALT_VERKAUF_ALLE_ANTEILE"
                                  )
                                }
                              }
                            },
                            [_vm._v(" Alle Bestände des Depots verkaufen ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ],
        1
      ),
      _vm.orderId
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Eine offene Börsenorder bearbeiten:")
              ]),
              _c("ComboBox", {
                key: _vm.orderId,
                attrs: {
                  values: _vm.orderbookComboboxValues,
                  disabled: !_vm.hasOrder || _vm.isDisabled
                },
                on: {
                  change: function($event) {
                    return _vm.updateOrderId($event)
                  }
                },
                model: {
                  value: _vm.orderId,
                  callback: function($$v) {
                    _vm.orderId = $$v
                  },
                  expression: "orderId"
                }
              }),
              _c("div", [
                _c("div", { staticClass: "row layout__negative-margin--8" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-sm-4 col-xxl-2 mx-0" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: {
                            isBlock: "",
                            isSecondary: "",
                            disabled:
                              !_vm.hasOrder ||
                              !_vm.isOrderEditable ||
                              _vm.isDisabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.prepareAddOrder("LIMITAENDERUNG")
                            }
                          }
                        },
                        [_vm._v(" Limitänderung ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-sm-4 col-xxl-2 mx-0" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: {
                            isBlock: "",
                            isSecondary: "",
                            disabled:
                              !_vm.hasOrder ||
                              !_vm.isOrderDeletable ||
                              _vm.isDisabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.prepareAddOrder("STREICHUNG")
                            }
                          }
                        },
                        [_vm._v(" Streichung ")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _vm._m(0),
          _c("WertpapierorderPositionCards", {
            staticClass: "mb-4",
            attrs: { focusOnPosition: _vm.focusOnPosition },
            on: {
              ebaseEdelmetalFound: function($event) {
                return _vm.$emit("ebaseEdelmetalFound")
              }
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "allesVerkaufen",
          attrs: {
            modalTitle: "Verkauf des gesamten Bestandes des Depots",
            labelButtonConfirm: "Verkaufen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.setAllPositionsForVerkauf()
            }
          }
        },
        [
          _c("InputToggleSwitch", {
            attrs: {
              label: "Alle bestehenden Verkäufe und Tauschaufträge löschen",
              inLineLabel: "",
              suppressValidationMessage: ""
            },
            model: {
              value: _vm.clearAltePositionen,
              callback: function($$v) {
                _vm.clearAltePositionen = $$v
              },
              expression: "clearAltePositionen"
            }
          })
        ],
        1
      ),
      _c("BaseModal", {
        ref: "selectKonto",
        attrs: {
          modalTitle: "Bitte wählen Sie ein Konto aus.",
          labelButtonCancel: "Ok",
          showConfirmButton: false
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "fehlerModal",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "color-danger mr-2",
                    attrs: { size: 16 }
                  }),
                  _c("span", { staticClass: "color-danger" }, [
                    _vm._v("Fehler")
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
      ),
      _c(
        "BaseModal",
        {
          ref: "infoModalImmobilien",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-info", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Hinweis: ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "var(--color-danger) font-bold" }, [
            _c("hr"),
            _vm._v(
              " Die gewählte Position ist ein offener Immobilienfonds. Verwenden Sie bitte eines der speziellen Formulare für offene Immobilienfonds oder verwalten Sie bestehende Positionen Über die Vermögensübersicht: "
            ),
            !_vm.isCustomerLogin
              ? _c("div", [
                  _c(
                    "a",
                    {
                      attrs: {
                        tid: _vm._generateTidFromString(
                          "Rückgabeerklärung für offene Immobilienfonds"
                        )
                      },
                      on: { click: _vm.verkaufImmobilieURL }
                    },
                    [_vm._v(" Rückgabeerklärung für offene Immobilienfonds ")]
                  ),
                  _c("br")
                ])
              : _vm._e()
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "infoModalDimensional",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-info", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Hinweis: ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "var(--color-danger) font-bold" }, [
            _c("hr"),
            _vm._v(
              " Die gewählte Position ist eine Dimensional Funds. Verwenden Sie bitte eines der speziellen Formulare für Dimensional Funds oder verwelten Sie bestehende Positionen Über die Vermögensübersicht: "
            ),
            !_vm.isCustomerLogin
              ? _c("div", [
                  _c(
                    "a",
                    {
                      attrs: {
                        tid: _vm._generateTidFromString(
                          "Dimensional Funds Nachkauf"
                        )
                      },
                      on: { click: _vm.nachkaufURL }
                    },
                    [_vm._v(" Dimensional Funds Nachkauf ")]
                  ),
                  _c("br"),
                  _c(
                    "a",
                    {
                      attrs: {
                        tid: _vm._generateTidFromString(
                          "Dimensional Funds Verkauf"
                        )
                      },
                      on: { click: _vm.verkaufURL }
                    },
                    [_vm._v(" Dimensional Funds Verkauf ")]
                  ),
                  _c("br"),
                  _c(
                    "a",
                    {
                      attrs: {
                        tid: _vm._generateTidFromString(
                          "Dimensional Funds Sparplan"
                        )
                      },
                      on: { click: _vm.sparplanURL }
                    },
                    [_vm._v(" Dimensional Funds Sparplan ")]
                  ),
                  _c("br"),
                  _c(
                    "a",
                    {
                      attrs: {
                        tid: _vm._generateTidFromString(
                          "Dimensional Funds Entnahmeplan"
                        )
                      },
                      on: { click: _vm.entnahmeplanURL }
                    },
                    [_vm._v(" Dimensional Funds Entnahmeplan ")]
                  ),
                  _c("br"),
                  _c(
                    "a",
                    {
                      attrs: {
                        tid: _vm._generateTidFromString(
                          "Dimensional Funds Einmaliger Tausch"
                        )
                      },
                      on: { click: _vm.tauschURL }
                    },
                    [_vm._v(" Dimensional Funds Einmaliger Tausch ")]
                  ),
                  _c("br")
                ])
              : _vm._e()
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "infoModalETF",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-info", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Hinweis: ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "var(--color-danger) font-bold" }, [
            _c("hr"),
            _vm._v(
              " Die gewählte Position ist ein ETF. Verwenden Sie bitte eines der speziellen Formulare für ETFs oder benutzen Sie die bestehende Positionen Über die Vermögensübersicht: "
            ),
            !_vm.isCustomerLogin
              ? _c("div", [
                  _c(
                    "a",
                    {
                      attrs: {
                        tid: _vm._generateTidFromString("ETF Nachkauf")
                      },
                      on: { click: _vm.nachkaufETFURL }
                    },
                    [_vm._v(" ETF Nachkauf ")]
                  ),
                  _c("br"),
                  _c(
                    "a",
                    {
                      attrs: { tid: _vm._generateTidFromString("ETF Verkauf") },
                      on: { click: _vm.verkaufETFURL }
                    },
                    [_vm._v(" ETF Verkauf ")]
                  ),
                  _c("br"),
                  _c(
                    "a",
                    {
                      attrs: {
                        tid: _vm._generateTidFromString("ETF Sparplan")
                      },
                      on: { click: _vm.sparplanETFURL }
                    },
                    [_vm._v(" ETF Sparplan ")]
                  ),
                  _c("br")
                ])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v(" Es werden Formulare für die folgenden Orderaufträge erzeugt: ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }