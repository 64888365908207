var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "datepicker__selector-container",
      class: { disabled: _vm.disabled }
    },
    [
      _c("div", { staticClass: "datepicker__controllers" }, [
        _c(
          "button",
          {
            staticClass: "datepicker__controllers-item btn-clear",
            attrs: {
              type: "button",
              disabled: _vm.disabled,
              alt: "Icon zur Navigation nach links"
            },
            on: {
              click: function($event) {
                return _vm.subtractPeriodReferenceDay()
              }
            }
          },
          [
            _c("ph-caret-double-left", {
              attrs: {
                size: _vm.controlsSize,
                alt: "Icon zur Navigation nach links"
              }
            })
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass: "datepicker__controllers-month btn-clear",
            attrs: { type: "button", disabled: _vm.disabled },
            on: {
              click: function($event) {
                return _vm.togglePeriodSelector()
              }
            }
          },
          [
            _vm._v(" " + _vm._s(_vm.currentMonthYear) + " "),
            _c("ph-caret-down", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showDaySelector || _vm.showMonthSelector,
                  expression: "showDaySelector || showMonthSelector"
                }
              ],
              staticClass: "ml-1",
              attrs: { size: _vm.controlsSize }
            })
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass: "datepicker__controllers-item btn-clear",
            attrs: {
              type: "button",
              disabled: _vm.disabled,
              alt: "Icon zur Navigation nach rechts"
            },
            on: {
              click: function($event) {
                return _vm.addPeriodReferenceDay()
              }
            }
          },
          [
            _c("ph-caret-double-right", {
              attrs: {
                size: _vm.controlsSize,
                alt: "Icon zur Navigation nach rechts"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDaySelector,
              expression: "showDaySelector"
            }
          ]
        },
        [
          _c("div", { staticClass: "datepicker__day-container" }, [
            _c(
              "div",
              { staticClass: "datepicker__week" },
              _vm._l(_vm.daysName, function(day) {
                return _c(
                  "button",
                  {
                    key: day,
                    staticClass: "datepicker__day-unit btn-clear",
                    attrs: { type: "button", disabled: _vm.disabled }
                  },
                  [_vm._v(" " + _vm._s(day) + " ")]
                )
              }),
              0
            )
          ]),
          _c(
            "div",
            { staticClass: "datepicker__day-container" },
            [
              _c(
                "transition-group",
                { attrs: { name: "reference-transition" } },
                _vm._l(_vm.daysOfMonthByWeek, function(daysOfWeek, index) {
                  return _c(
                    "div",
                    {
                      key: _vm.currentMonthYear + index,
                      staticClass: "datepicker__week",
                      class: {
                        selectable: _vm.isHighlightWeek,
                        "work-week": _vm.isHighlightWorkWeek,
                        "no-work-week": !_vm.isHighlightWorkWeek,
                        "datepicker__selected-week":
                          _vm.isHighlightWeek && _vm.isWeekSelected(daysOfWeek)
                      }
                    },
                    _vm._l(daysOfWeek, function(day) {
                      return _c(
                        "button",
                        {
                          key: day.date.unix(),
                          staticClass:
                            "datepicker__day-unit selectable btn-clear",
                          class: {
                            "text-muted": !day.isSameMonth,
                            "work-day": day.isWorkDay,
                            "datepicker__selected-day":
                              !_vm.isHighlightWeek &&
                              _vm.isDateSelected(day.date, "day") &&
                              day.isSameMonth,
                            "is-today": day.isToday
                          },
                          attrs: { type: "button", disabled: _vm.disabled },
                          on: {
                            click: function($event) {
                              return _vm.selectDay(day.date)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "datepicker__day-unit",
                              class: {
                                "day-unit__in-range":
                                  _vm.isDayInBetweenSelectedRange(day.date) &&
                                  day.isSameMonth,
                                "day-unit__begin-range":
                                  _vm.isDayBeginOfSelectedRange(day.date) &&
                                  day.isSameMonth,
                                "day-unit__end-range":
                                  _vm.isDayEndOfSelectedRange(day.date) &&
                                  day.isSameMonth
                              }
                            },
                            [_vm._v(" " + _vm._s(day.day) + " ")]
                          )
                        ]
                      )
                    }),
                    0
                  )
                }),
                0
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMonthSelector,
              expression: "showMonthSelector"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "datepicker__month-container" },
            _vm._l(_vm.monthsOfYear, function(month) {
              return _c(
                "button",
                {
                  key: month.month,
                  staticClass: "datepicker__month-unit selectable btn-clear",
                  class: {
                    "datepicker__selected-day": _vm.isDateSelected(
                      month.date,
                      "month"
                    ),
                    "is-current-month": _vm.isCurrentMonth(month.date)
                  },
                  attrs: { type: "button", disabled: _vm.disabled },
                  on: {
                    click: function($event) {
                      return _vm.selectMonth(month.date)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(month.month) + " ")]
              )
            }),
            0
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showYearSelector,
              expression: "showYearSelector"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "datepicker__month-container" },
            _vm._l(_vm.years, function(year) {
              return _c(
                "button",
                {
                  key: year,
                  staticClass: "datepicker__month-unit selectable btn-clear",
                  class: {
                    "datepicker__selected-day": _vm.isDateSelected(
                      year,
                      "year"
                    ),
                    "is-current-year": _vm.isCurrentYear(year)
                  },
                  attrs: { type: "button", disabled: _vm.disabled },
                  on: {
                    click: function($event) {
                      return _vm.selectYear(year)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(year) + " ")]
              )
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }