export const MUTATION_PREFIX = 'STORNO_WARNUNG: ';
export const ACTIONS_PREFIX = 'STORNO_WARNUNG_ACTIONS_';
export const GETTERS_PREFIX = 'STORNO_WARNUNG_GETTERS_';

export default {
  MUTATIONS: {
    GET_STORNO_WARNUNG_OVERVIEW_SUCCESS: MUTATION_PREFIX + 'GET_STORNO_WARNUNG_OVERVIEW_SUCCESS',
    GET_STORNO_WARNUNG_SUCCESS: MUTATION_PREFIX + 'GET_STORNO_WARNUNG_SUCCESS',
    UPDATE_PAGE_INDEX: MUTATION_PREFIX + 'UPDATE_PAGE_INDEX',
    RESET_STORNOS: MUTATION_PREFIX + 'RESET_STORNOS',
    RESET_FILTERS: MUTATION_PREFIX + 'RESET_FILTERS',
    SET_FILTERS: MUTATION_PREFIX + 'SET_FILTERS',
    GET_STORNO_ARTS_SUCCESS: MUTATION_PREFIX + 'GET_STORNO_ARTS_SUCCESS',
    EXECUTE_FREIGABE_SUCCESS: MUTATION_PREFIX + 'EXECUTE_FREIGABE_SUCCESS',
    SET_IS_LOADING: MUTATION_PREFIX + 'SET_IS_LOADING',
    SET_STORNO_SELECTED: MUTATION_PREFIX + 'SET_STORNO_SELECTED',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_STORNO_WARNUNG_OVERVIEW: ACTIONS_PREFIX + 'GET_STORNO_WARNUNG_OVERVIEW',
    GET_STORNO_WARNUNG: ACTIONS_PREFIX + 'GET_STORNO_WARNUNG',
    CONFIRM_STORNO_WARNUNG: ACTIONS_PREFIX + 'CONFIRM_STORNO_WARNUNG',
    GET_STORNO_ARTS: ACTIONS_PREFIX + 'GET_STORNO_ARTS',
    CREATE_ATTACHMENT_TEMP_FILE: ACTIONS_PREFIX + 'CREATE_ATTACHMENT_TEMP_FILE',
    EXECUTE_FREIGABE: ACTIONS_PREFIX + 'EXECUTE_FREIGABE',
    SEND_COMDIRECT_DOCUMENTS: ACTIONS_PREFIX + 'SEND_COMDIRECT_DOCUMENTS',
    SIGNO_VIEWER: ACTIONS_PREFIX + 'SIGNO_VIEWER',
    GET_EMAIL_DATA_KUNDE: ACTIONS_PREFIX + 'GET_EMAIL_DATA_KUNDE',
    GET_EMAIL_DATA_BEARBEITENDER: ACTIONS_PREFIX + 'GET_EMAIL_DATA_BEARBEITENDER',
    WWSI_EXPORT: ACTIONS_PREFIX + 'WWSI_EXPORT',
  },
  GETTERS: {
    STORNO_WARNUNG_OVERVIEW: GETTERS_PREFIX + 'STORNO_WARNUNG_OVERVIEW',
    STORNO_WARNUNG: GETTERS_PREFIX + 'STORNO_WARNUNG',
    PAGE_INDEX: GETTERS_PREFIX + 'PAGE_INDEX',
    TOTAL_ROWS: GETTERS_PREFIX + 'TOTAL_ROWS',
    DEFAULT_FILTERS: GETTERS_PREFIX + 'DEFAULT_FILTERS',
    GET_STORNO_ARTS: GETTERS_PREFIX + 'GET_STORNO_ARTS',
    IS_LOADING: GETTERS_PREFIX + 'IS_LOADING',
    IS_FILTER_SAVED: GETTERS_PREFIX + 'IS_FILTER_SAVED',
    STORNO_SELECTED: GETTERS_PREFIX + 'STORNO_SELECTED',
  }
}
