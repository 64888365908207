var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "draggable--hook", style: _vm.draggableHookStyle },
    [
      _c(
        "div",
        {
          ref: "draggableContainer",
          staticClass: "draggable--container",
          style: _vm.draggableContainerStyle
        },
        [
          _c(
            "div",
            { staticClass: "draggable--area" },
            [
              _c("PhArrowsOutCardinal", {
                staticClass: "draggable--item",
                attrs: { size: 20, alt: _vm.ariaDescription },
                on: {
                  mousedown: _vm.dragMouseDown,
                  touchstart: _vm.dragMouseDown
                }
              }),
              _vm._t("default")
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }