import VERSICHERUNG_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_INSURANCES_SUCCESS](state, payload) {
    state.versicherungen = payload;
  },
  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION_SUCCESS](state, payload) {
    Vue.set(state, 'vertragsdatenFieldsDefinition', payload);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.CLEAR_INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION](state) {
    Vue.set(state, 'vertragsdatenFieldsDefinition', null);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_INSURANCE_DETAILS_SUCCESS](state, payload) {
    Vue.set(state, 'versicherungenDetails', payload);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.UPDATE_VERTRAG_SUCCESS](state, payload) {
    if (payload) {
      Vue.set(state.versicherungenDetails, 'insurance', payload.insurance);
      payload.steps?.length && Vue.set(state.versicherungenDetails, 'steps', payload.steps);
      payload.componentMap && Vue.set(state.versicherungenDetails, 'componentMap', payload.componentMap);
      payload.comboboxValues && Vue.set(state.versicherungenDetails, 'comboboxValues', payload.comboboxValues);
    }
    Vue.set(state, 'externalUrl', payload.donauWstCallbackUrl);
    Vue.set(state, 'versicherungDetailsEdited', {});
  },
  [VERSICHERUNG_TYPES.MUTATIONS.UPDATE_VERTRAG_SPARTEN_SUCCESS](state, payload) {
    if (state.versicherungenDetails?.insurance && payload?.length) {
      Vue.set(state.versicherungenDetails.insurance, 'sparten', payload);
    }
    const selectedSparten = state.versicherungenDetails?.extraData?.selectedSparten || [];
    const excludedSparten = Object.entries(selectedSparten)?.filter(([key, value]) => value == false).map(([key, value]) => key);
    const schaedenToKeep = state.versicherungenDetails?.schadenInfos?.filter(schaeden => !excludedSparten.includes(schaeden.spartenTypId))
    Vue.set(state.versicherungenDetails, 'schadenInfos', schaedenToKeep);
    Vue.set(state.versicherungenDetails, 'extraData', null);
    Vue.set(state, 'versicherungDetailsEdited', {});
  },
  [VERSICHERUNG_TYPES.MUTATIONS.FILTERED_CATEGORY](state, payload) {
    state.filteredCategory = payload.category;
  },
  [VERSICHERUNG_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_CATEGORIES_SUCCESS](state, payload) {
    Vue.set(state, 'categories', payload);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_ALL_CATEGORIES_SUCCESS](state, payload) {
    Vue.set(state, 'allCategories', payload);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.EDITING_DETAILS_START](state) {
    Vue.set(state, 'editingDetails', true);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.EDITING_DETAILS_STOP](state) {
    Vue.set(state, 'editingDetails', false);
    
  },
  [VERSICHERUNG_TYPES.MUTATIONS.NODE_ID](state, payload) {
    Vue.set(state, 'nodeId', payload?.nodeId);
    
  },
  [VERSICHERUNG_TYPES.MUTATIONS.FILTER_CATEGORY_STOP](state) {
    Vue.set(state, 'filteredCategory', null);
    
  },
  [VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_INSURANCE_GESELLSCHAFT](state, payload) {
    Vue.set(state, 'gesellschaftList', payload)
  },
  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_DETAILS_COMBINED_SUCCESS](state, payload) {
    state.insuranceDetails = payload;
  },
  [VERSICHERUNG_TYPES.MUTATIONS.DELETE_SCHADENSMELDUNG_SUCCESS](state, payload) {
    state.deletedSchadensmeldungId = payload;
  },
  [VERSICHERUNG_TYPES.MUTATIONS.DELETE_VERTRAG](state, { insuranceId }) {
    const index = state.insurancesFiltered?.versVertragFilter?.findIndex(insurance => insurance.id === insuranceId);
    if (index > -1) {
      state.insurancesFiltered?.versVertragFilter?.splice(index, 1);
    }
  },
  [VERSICHERUNG_TYPES.MUTATIONS.CLEAR_INSURANCE_DETAILS](state) {
    Vue.set(state, 'versicherungenDetails', getInitialState()?.versicherungenDetails);
    Vue.set(state, 'versicherungDetailsEdited', {});
    Vue.set(state, 'gesellschaftMaklerGesellNr', {});
  },
  [VERSICHERUNG_TYPES.MUTATIONS.GET_SPARTEN_LIST_SUCCESS](state, payload) {
    state.spartenList = payload;
  },
  [VERSICHERUNG_TYPES.MUTATIONS.GET_ZAHLRHYTHMUS_LIST_SUCCESS](state, payload) {
    state.zahlrhythmusList = payload;
  },
  [VERSICHERUNG_TYPES.MUTATIONS.GET_DEPOT_POSITIONS_SUCCESS](state, payload) {
    Vue.set(state, 'depotPositions', payload);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.CLEAR_DEPOT_POSITIONS](state) {
    state.depotPositions = {};
  },
  [VERSICHERUNG_TYPES.MUTATIONS.GET_INSURANCE_FILTERS_DATA_SUCCESS](state, payload) {
    state.insuranceFiltersData = payload;
  },
    [VERSICHERUNG_TYPES.MUTATIONS.GET_INSURANCES_FILTERED_SUCCESS](state, {pageId, versVertragFilter, count}) {
        if (pageId > 0) {
            Vue.set(state.insurancesFiltered.pages, pageId, versVertragFilter);
            if (count != null)
                Vue.set(state.insurancesFiltered, "count", count);
        } else {
            state.insurancesFiltered = {
                pages: {
                    [0]: versVertragFilter,
                },
                count,
            };
        }
    },
    [VERSICHERUNG_TYPES.MUTATIONS.CLEAR_INSURANCES_FILTERED](state) {
          state.insurancesFiltered = {};
      
  },
  [VERSICHERUNG_TYPES.MUTATIONS.SET_TEMP_FILES](state, payload) {
    if(payload?.length) {
      state.tempFiles = [...payload];
    }
  },
  [VERSICHERUNG_TYPES.MUTATIONS.CLEAR_TEMP_FILES](state) {
    state.tempFiles = [];
  },
  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_AUSWAHL_VERTRAGSART_SELECTED](state, selected) {
    Vue.set(state, 'categorySelected', selected);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.CLEAR_INSURANCE_AUSWAHL_VERTRAGSART_SELECTED](state, selected) {
    Vue.set(state, 'categorySelected', {category: null, group: null});
  },
  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_COMBOBOX_VALUES_SUCCESS](state, selected) {
    const treated = selected.gesellschaftId.replace(/\s/g, '_');
    Vue.set(state, 'gesellschaftMaklerGesellNr', {[treated]: selected.maklerGesellNr});
  },
  [VERSICHERUNG_TYPES.MUTATIONS.APPEND_VERSICHERUNGEN_DETAILS_EDITED](state, payload) {
    const details = Object.assign({}, state.versicherungDetailsEdited, payload);
    Vue.set(state, 'versicherungDetailsEdited', details);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_VERTRAGSDATEN_POLNR_VALIDATION_SUCCESS](state, payload) {
    const nummer = {...state.vertragsdatenFieldsDefinition.polNr, ...payload}
    Vue.set(state.vertragsdatenFieldsDefinition, 'polNr', nummer);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.UPDATE_VERSICHERUNGEN_FORM_VALIDATION](state, validation) {
    Vue.set(state, 'versicherungFormValidation', validation);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.CLEAR_VERSICHERUNG_FORM_VALIDATION](state) {
    Vue.set(state, 'versicherungFormValidation', {});
  },
  [VERSICHERUNG_TYPES.MUTATIONS.GET_ANTRAG_CHECK_VERSICHERUNG_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'antragCheckVersData', payload || {});
  },
  [VERSICHERUNG_TYPES.MUTATIONS.UPDATE_PRUEFSTATUS_ANTRAG_CHECK_VERSICHERUNG_LIST_SUCCESS](state, payload) {
    const index = state.antragCheckVersData?.insurances?.findIndex(item => item.id === payload.id);
    if(index >=0) {
      const changedComboValue = state.antragCheckVersData.insurances[index].pruefStatusComboboxValues.find(comboItem => comboItem.value == payload.pruefstatusChanged);
      Vue.set(state.antragCheckVersData.insurances[index], 'pruefstatus', changedComboValue );
    }
  },
  [VERSICHERUNG_TYPES.MUTATIONS.SET_PRUEFSTATUS_CHANGE](state, payload) {
    Vue.set(state, 'pruefstatusChange', payload);
  },

  [VERSICHERUNG_TYPES.MUTATIONS.ADD_ATTACHMENT_SUCCESS](state, payload) {
    Vue.set(state, 'pruefstatusChange', Object.assign(state.pruefstatusChange || {}, payload || {}));
  },
  
  [VERSICHERUNG_TYPES.MUTATIONS.UPDATE_STORE_SPARTEN_DATA](state, payload) {
    if (payload?.sparteId && state.versicherungenDetails?.insurance) {
      if (payload?.componentId) {
        const index = state.versicherungenDetails.insurance.sparten?.findIndex(sp => sp.sparteId === payload.sparteId);
        if (index < 0 || index > state.versicherungenDetails?.insurance?.sparten?.length) {
          // Hauptdata is saved directly in the sparte, other values are saved in the sparte.parameters
          if (payload.isHauptdata) {
              Vue.set(state.versicherungenDetails.insurance, 'sparten', (state.versicherungenDetails.insurance.sparten || [])
              .concat(...[{ sparteId: payload.sparteId, [payload.componentId]: payload.value}])); // guedes
          } else {
              Vue.set(state.versicherungenDetails.insurance, 'sparten', (state.versicherungenDetails.insurance.sparten || [])
              .concat(...[{ sparteId: payload.sparteId, parameters: {[payload.componentId]: payload.value}}])); // guedes              
          }
        } else {
          if (payload.isHauptdata) {
            Vue.set(state.versicherungenDetails.insurance.sparten[index], payload.componentId, payload.value)
          }
          else {
            if (!state.versicherungenDetails.insurance.sparten[index]?.parameters) {
              Vue.set(state.versicherungenDetails.insurance.sparten[index], 'parameters', {})
            }
            Vue.set(state.versicherungenDetails.insurance.sparten[index].parameters, payload.componentId, payload.value);
          }
        }
      }
      
      const spartenChanged = state.versicherungenDetails?.extraData?.spartenChanged || [];
      if (!spartenChanged.some(sp => sp === payload.sparteId)) {
        spartenChanged.push(payload.sparteId)
        Vue.set(state.versicherungenDetails, 'extraData', Object.assign(state.versicherungenDetails?.extraData || {}, {spartenChanged: spartenChanged}));
      }
      this.commit(VERSICHERUNG_TYPES.MUTATIONS.APPEND_VERSICHERUNGEN_DETAILS_EDITED, { sparten: state.versicherungenDetails?.insurance?.sparten, extraData: state.versicherungenDetails.extraData })
    }
  },
 
  [VERSICHERUNG_TYPES.MUTATIONS.UPDATE_STORE_SELECTED_SPARTEN](state, payload) {
    if (payload?.id && state.versicherungenDetails) {
      const selection = state.versicherungenDetails.extraData?.selectedSparten || {};
      selection[payload.id] = payload.value;
      state.versicherungenDetails.extraData = Object.assign(state.versicherungenDetails?.extraData || {}, { selectedSparten: selection});
      this.commit(VERSICHERUNG_TYPES.MUTATIONS.APPEND_VERSICHERUNGEN_DETAILS_EDITED, { extraData: state.versicherungenDetails.extraData });
    }
  },

  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_ANMERKUNG_GET_SUCCESS](state, payload) {
    Vue.set(state, 'anmerkungen', [ ...payload || [] ]);
  },

  [VERSICHERUNG_TYPES.MUTATIONS.SET_CAN_EDIT_ANMERKUNG](state, payload) {
    Vue.set(state, 'canEditAnmerkung', payload || false);
  },

  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_ANMERKUNG_SAVE_SUCCESS](state, payload) {
    if(payload?.length) {
      state.anmerkungen = payload;
    }
  },

  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_ANMERKUNG_DELETE_SUCCESS](state, payload) {
    const index = state.anmerkungen?.findIndex(anmerkung => anmerkung.id === payload);
    if (index > -1) {
      state.anmerkungen?.splice(index, 1);
    }
  },

  [VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_INSURANCES_BY_CUSTOMER_SUCCESS](state, payload) {
    state.insurancesByCustomer = payload;
  },
  
  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_VERTRAGSDATEN_ANBINDUNG_COMBOBOX_VALUES_SUCCESS](state, response) {
    Vue.set(state.vertragsdatenFieldsDefinition.anbindung, 'value', response.anbindung);
    Vue.set(state.vertragsdatenFieldsDefinition.betreuungsstufe, 'value', response.betreuungsstufeGewerbe);
  },

  [VERSICHERUNG_TYPES.MUTATIONS.FIND_ALL_INSURANCE_COURTAGE_SUCCESS](state, response) {
    state.courtage = response;
  },

  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_COURTAGE_DELETE_SUCCESS](state, response) {
    const index = state.courtage?.findIndex(courtage => courtage.id === response);
    if (index > -1) {
      state.courtage?.splice(index, 1);
    }
  },
  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_COURTAGE_SAVE_SUCCESS](state, payload) {
    if(payload?.length) {
      Vue.set(state, 'courtage', payload);
    }
  },
  
  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_COURTAGE_UPDATE_SUCCESS](state, payload) {
    if(payload?.length) {
      Vue.set(state, 'courtage', payload);
    }
  },

  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_COURTAGE_CONFIG_SUCCESS](state, payload) {
    if(payload) {
      Vue.set(state, 'insuranceCourtageConfig', payload);
    }
  },
  
  [VERSICHERUNG_TYPES.MUTATIONS.FIND_ALL_INSURANCE_ABRECHNUNGEN_SUCCESS](state, payload) {
    if(payload?.length) {
      Vue.set(state, 'abrechnungen', payload);
    } else {
      Vue.set(state, 'abrechnungen', []);
    }
  },
  
  [VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_BUCHUNG_DELETE_SUCCESS](state, response) {
    const index = state.abrechnungen?.findIndex(buchung => buchung.buchungnr === response);
    if (index > -1) {
      state.abrechnungen?.splice(index, 1);
    }
  },

  [VERSICHERUNG_TYPES.MUTATIONS.COPY_INSURANCE_SUCCESS](state, response) {
    state.insuranceCopy = response;
  },

  [VERSICHERUNG_TYPES.MUTATIONS.UPDATE_RUECKKAUFSWERTE_SUCCESS](state, response) {
    if(response.id) {
      const index = state.versicherungenDetails?.insurance?.sparten?.findIndex(sparte => sparte.id === response.sparteId);
      if (index > -1) {
        const rueckkaufswerteIndex = state.versicherungenDetails.insurance?.sparten[index]?.rueckkaufswerte?.findIndex(rueckkaufswerte => rueckkaufswerte.id === response.id);
        if (rueckkaufswerteIndex > -1) {
          state.versicherungenDetails.insurance.sparten[index]?.rueckkaufswerte?.splice(rueckkaufswerteIndex, 1, response);
        }
      }
    }
  },
  [VERSICHERUNG_TYPES.MUTATIONS.SAVE_RUECKKAUFSWERTE_SUCCESS](state, response) {
    if(response.id) {
      const index = state.versicherungenDetails?.insurance?.sparten?.findIndex(sparte => sparte.id === response.sparteId);
      if (index > -1) {
        if(!state.versicherungenDetails.insurance?.sparten[index]?.rueckkaufswerte?.length) {
          state.versicherungenDetails.insurance.sparten[index].rueckkaufswerte = [];
        }
        state.versicherungenDetails.insurance?.sparten[index]?.rueckkaufswerte?.unshift(response);
      }
    }
  },
  [VERSICHERUNG_TYPES.MUTATIONS.DELETE_RUECKKAUFSWERTE_SUCCESS](state, response) {
    if(response.ruckkaufswerteId) {
      const index = state.versicherungenDetails?.insurance?.sparten?.findIndex(sparte => sparte.id === response.sparteId);
      if (index > -1) {
        const rueckkaufswerteIndex = state.versicherungenDetails.insurance?.sparten[index]?.rueckkaufswerte?.findIndex(rueckkaufswerte => rueckkaufswerte.id === response.ruckkaufswerteId);
        if (rueckkaufswerteIndex > -1) {
          state.versicherungenDetails.insurance.sparten[index]?.rueckkaufswerte?.splice(rueckkaufswerteIndex, 1);
        }
      }
    }
  },
  [VERSICHERUNG_TYPES.MUTATIONS.DELETE_EINGEBUNDENE_WERTPAPIERE_SUCCESS](state, response) {
    if(response.eingebundeneWertpapiereId) {
      const index = state.versicherungenDetails?.insurance?.sparten?.findIndex(sparte => sparte.id === response.sparteId);
      if (index > -1) {
        const eingebundeneWertpapiereIndex = state.versicherungenDetails.insurance?.sparten[index]?.eigebundeneWertpapiere
        ?.findIndex(eingebundeneWertpapiere => eingebundeneWertpapiere.id === response.eingebundeneWertpapiereId);
        if (eingebundeneWertpapiereIndex > -1) {
          state.versicherungenDetails.insurance.sparten[index]?.eigebundeneWertpapiere?.splice(eingebundeneWertpapiereIndex, 1);
        }
      }
    }
  },
  [VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_VERTRAGSPERSONENDATA_SUCCESS](state, payload) {
    state.vertragsPersonenData = payload;
  },
  [VERSICHERUNG_TYPES.MUTATIONS.SAVE_VERTRAG_PERSONEN_DATA_SUCCESS](state, payload) {
  },
  [VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_VERTRAGPERSONEN_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'vertragPersonenList', payload);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.DELETE_VERTRAG_PERSON_SUCCESS](state, payload) {
  },
  [VERSICHERUNG_TYPES.MUTATIONS.GET_FAHRZEUGDATEN_LIST_SUCCESS](state, response) {
    if(response) {
      state.fahrzeugdatenList = response;
    }
  },
  [VERSICHERUNG_TYPES.MUTATIONS.SET_VERTRAGS_VERSCHIEBUNGEN_DATA](state, payload) {
    state.vertragsverschiebungenData = payload;
  },
  [VERSICHERUNG_TYPES.MUTATIONS.UPDATE_FAHRZEUGDATEN_SUCCESS](state, response) {
    if(response) {
      const index = state.fahrzeugdatenList.findIndex(fahrzeugdaten => fahrzeugdaten.id === response.id);
      
      if (index > -1) {
        state.fahrzeugdatenList?.splice(index, 1, response);
      }

      if (response?.kennzeichen) {
        Vue.set(state.versicherungenDetails, 'kennzeichen', response?.kennzeichen);
      }
    }
  },
  [VERSICHERUNG_TYPES.MUTATIONS.GET_SPARTEN_SUCCESS](state, payload) {
    Vue.set(state, 'sparten', payload);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.CREATE_FAHRZEUGDATEN_SUCCESS](state, response) {
    if(response?.id) {
      
      if (response?.kennzeichen) {
        Vue.set(state.versicherungenDetails, 'kennzeichen', response?.kennzeichen);
      }

      state.fahrzeugdatenList.push(response);
    }
  },
  [VERSICHERUNG_TYPES.MUTATIONS.SEARCH_CUSTOMER_SUCCESS](state, payload) {
    Vue.set(state, 'foundCustomer', payload);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_VERSICHERUNGSSUMMEN_SUCCESS](state, payload) {
    Vue.set(state, 'versicherungsSummen', payload);
  },  
  [VERSICHERUNG_TYPES.MUTATIONS.SET_EXTERNAL_URL](state, payload) {
    Vue.set(state, 'externalUrl', payload);
  },
  [VERSICHERUNG_TYPES.MUTATIONS.GET_MAKLER_VOLLMACHT_SUCCESS](state, payload) {
    Vue.set(state, 'maklerVollmacht', payload);
  }, 
  [VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_VERSICHERUNGSSUMMEN_FA_SUCCESS](state, payload) {
    Vue.set(state, 'versicherungsSummenFA', payload);
  },  
  [VERSICHERUNG_TYPES.MUTATIONS.GET_TEMP_FILE_SUCCESS](state, payload) {
    Vue.set(state, 'tempFiles', [...payload]);
  },    
}