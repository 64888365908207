<template>
  <div class="login-container" v-if="isReady">
    <HeaderLogin class="login-header" />
    <div data-app-content class="login-content" :style="{ backgroundImage: loginContentBackground }">
      <div class="login-box">
        <div class="login-logo-box">
          <img :src="logoLoginBoxBackground"/>
        </div>
        <hr class="login-logo__bottom--spacer"/>
        <div class="login-logo__box-title">
          {{loginWelcomeTitle}}
        </div>
        <div>
          <AnimatedSpinner/> Bitte haben Sie einen Augenblick Geduld. Die Anwendung wird geladen!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import { removeItem } from '@/helpers/local-storage-helper';
import HeaderLogin from '@/components/core/login/HeaderLogin.vue'
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue'

export default {
  methods: {
    openNextUrlIfIsLoggedIn() {
      if(this.isLoggedIn && this.nextUrl) {
        this.$router.push(this.nextUrl);
      }
    },
  },
  computed: {
    ...mapGetters({
      apiAddressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
      imageBackgroundLoginUrl: CORE_TYPES.GETTERS.GET_BROKER_IMAGE_BACKGROUND_LOGIN_URL,
      maklerLogo: CORE_TYPES.GETTERS.GET_BROKER_LOGO,
      hasColorSchema: CORE_TYPES.GETTERS.HAS_COLOR_SCHEMA,
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      loginWelcomeTitle: CORE_TYPES.GETTERS.GET_LOGIN_WELCOME_TITLE,
    }),
    isReady() {
      return this.hasColorSchema;
    },
    loginContentBackgroundImage() {
      return this.imageBackgroundLoginUrl || `${this.apiAddressLegacy}/../images/etc/layout/msc_wolken4.png`;
    },
    loginContentBackground() {
      return `url(${this.loginContentBackgroundImage})`;
    },
    logoLoginBoxBackground() {
      return this.maklerLogo
    },
    emailid() {
      return this.$route.query?.emailid;
    },
    chatid() {
      return this.$route.query?.chatid
    },
    nextUrl() {
      if(this.emailid) {
        return `/communication/postfach/emails/email/${this.emailid}`;
      }

      if (this.chatid?.includes('_')) {
        return `/communication/postfach/chats/chat/${this.chatid.split('_').join('/')}`
      }

      return null;
    },
  },
  mounted() {
    const token = this.$route.query.token;
    const sitzungID = this.$route.query.SitzungID;
    const callbackURL = this.$route.query.callbackURL;
    removeItem('originalUser');

    if (sitzungID) {
      const loginPayload = {
        sitzungID: sitzungID,
        callbackURL:callbackURL,
      };
      this.$store.dispatch(CORE_TYPES.ACTIONS.LOGIN, loginPayload);
    }

    if (token) {
      const loginPayload = {
        token: token,
        nextUrl: '/home'
      };
      this.$store.dispatch(CORE_TYPES.ACTIONS.TOKEN_LOGIN, loginPayload);
    }

    this.openNextUrlIfIsLoggedIn();
  },
  components: {
    HeaderLogin,
    AnimatedSpinner,
  },
}
</script>

<style src="../login.scss" lang="scss" scoped></style>
