var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InputTextArea", {
    attrs: {
      label: _vm.label,
      value: _vm.internalValue,
      placeholder: _vm.placeholder,
      disabled: _vm.disabled,
      validateUntouched: _vm.validateUntouched,
      suppressValidationMessage: _vm.suppressValidationMessage,
      isComponentHalfSize: _vm.isComponentHalfSize,
      rows: _vm.rows,
      autoGrow: _vm.autoGrow,
      autoFocus: _vm.autoFocus,
      maxlength: _vm.maxlength,
      isNotReactive: _vm.isNotReactive,
      hideScrollBar: _vm.hideScrollBar
    },
    on: {
      input: function($event) {
        return _vm.input($event)
      },
      change: function($event) {
        return _vm.$emit("change")
      },
      keyup: function($event) {
        return _vm.$emit("keyup")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }