<template>
  <div>
    <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Erwartete Courtage"
      :actions="headerActions"
      @action-PLATTFORM="selectPlattform()" />

    <HinweiseUndFehler :hints="hints" />
    
    <BaseFilter ref="refFilter"
      title="Erwartete Courtage Einstellungen"
      filterId="a8525196-63a5-4d65-83cf-06ec17352cb9"
      :metadata="metadata"
      :hasSmartSearch="false"
      :immidiateSearch="false"
      :configFilter="configFilter"
      :defaultOptions="defaultOptions"
			
      showSaveButton
			titleSearchButton="Berechnen"
      @onFilter="calcCourtage" 
    />
	   
		<div class="box__container">
			<GhostLoading v-if="loading">
				<Block height="100" />
			</GhostLoading>
			<Table v-else-if="rows.length"
				:title="title"
				:headers="headers"
				:rows="rows"
				rowId="Wertpapier"
				:rowsPerPage="25"
				:headerActions="tblActions"
			>
				<template #JAHR-AUSWAHL>
					<div v-for="year in years" :key="year.key"  class="mt-2 mb-2">
					<BaseButton :label="year.label" :isClear="true" :class="getCls(year)"
						@click="jahre=year.key"
					/></div>
				</template>
			</Table>
		</div>
		
    <BaseModal ref="showPlattform" 
      labelButtonConfirm="Speichern"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="onChangePlattform">
      <template v-slot:modalTitle>
        Plattformen
      </template>
      <div>
				<div v-for="item in plattformen" :key="item[0]">
					<InputToggleSwitch v-model="item[1]" inLineLabel  :label="item[0]" class="plattform"	/>
				</div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from "vuex";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, PageHeaderSlotAction} from '@/components/core/header-title-navigation/page-header-utils';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, CurrencyColumn} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";
import BaseFilter from '@/components/core/BaseFilter.vue';

export default {
  
	components: {
    OptionMenu, PageHeaderTitleNavigation, PageHeaderSimpleAction, Table, GhostLoading, Block, PageHeaderSlotAction,
    BaseModal, InputToggleSwitch, InputField, BaseButton, HinweiseUndFehler, BaseFilter, 
	},
	data() {
		return {
			inclCourtagePA: false,
			jahre: '0',
			loading: false,
			headerActions: [
        PageHeaderSimpleAction('PLATTFORM', 'Plattform-Auswahl')
      ],
			tblActions: [PageHeaderSlotAction('JAHR-AUSWAHL')],
			plattformen: [],
			wertsteigerung: null,
			options: {wertsteigerung: 3},
			lagerstellen: {},
      configFilter: {
        placeholderForDefSearchInput: 'Berechnete Wertsteigerung p.a.',
        defaultSearchInputKeys: ['wertsteigerung'],
        filterType: 'wertpapierinfo',
				checkDefaultSearchInput: false,
				hideFirstColumn: true,
        filterZurucksetzen: () => {
          this.defaultOptions = {wertsteigerung: 3};
        }
      },
			defaultOptions: {wertsteigerung: 3}
    }
  },
  computed: {
    ...mapGetters({
      courtage: DEPOTPOSITIONS_TYPES.GETTERS.GET_CALC_COURTAGE,
			isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
		title() {
			return 'Erwartete Courtage - ' + this.label(this.jahre)
		},
		hints() {
			const result = [{message: `Die angezeigten Anlagesummen sind bei Käufen die Bestände nach Ablauf des gewählten Zeitraums. 
			Bei Sparplänen werden die Einzahlungen im letzten Jahr gemittelt, die Anlagesumme ist der resultierende 
			Betrag auf dem die Courtageberechnung beruht. Alle angebenen Courtagen sind die insgesamt über den gesamten 
			Zeitraum ausgezahlten Courtagen, die Courtage im jeweiligen Jahr kann zusätzlich angezeigt werden.`}];
			if (this.isFA) {
				result.push({message: `Es werden nur die Plattformen angezeigt, die eine Bestandscourtage anbieten, für wenigstens einen Fonds aus dem Depot des Kunden.`})
			}
			return result;
		},
		metadata() {
			const menuItems = [
				
				{type: 'default',	key: 'inclCourtagePA',	label: 'Jahrescourtagen anzeigen'},
				{type: 'text',		key: 'gebuehr',					label: 'Servicegebühr %'},
			]
			if (this.lagerstellen) {
				Object.keys(this.lagerstellen).forEach(key => {
					const name = this.lagerstellen[key];
					menuItems.push({type: 'text',		key: 'gebuehr_'+key, label: `Servicegebühr ${name} %`});
				});
			}
			menuItems.push({type: 'text',		key: 'wertsteigerung',	label: 'Wertsteigerung p.a.' })
      return [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Einstellungen',
          allowMultipleSearch: false,
          menuItems
				}]
		},
		headers() {
			const headers = {
				lockedLeft: [
					TextColumn('type', ''),
					TextColumn('Wertpapier', 'Wertpapier'),
					CurrencyColumn('Anlagesumme', 'Anlagesumme / Bestand').withSumFooter(),
				],
				center: [],
				lockedRight: []
			}
			if (this.lagerstellen) {
				Object.keys(this.lagerstellen).forEach(key => {
					const name = this.lagerstellen[key];
					headers.center.push(CurrencyColumn(name, name).withSumFooter() );
					if (this.options.inclCourtagePA) {
						headers.center.push(CurrencyColumn(name + '_Jahrescourtage', '- Jahrescourtage').withSumFooter() );
					}
				});
			}
			return headers;
		},
		years() {
			const result = [];
			if (this.courtage.years) {
				this.courtage.years.forEach( j => {
					result.push( { key: ''+j, label: this.label(''+j) } )
				})
			}
			return result;
		}
  },
	mounted() {
		if (!this.courtage?.plattformen) {
			this.berechnen();
		}
	},
  methods: {
		label(j) {
			if (j == '0') {
				return 'Ohne Sparplan'
			} else if (j == '1') {
				return 'Nach einem Jahr'
			} else {
				return 'Nach ' + j +' Jahren'
			}
		},
		getCls(year) {
			return this.jahre==year.key ? 'text-bold' : '';
		},
		setPlttformen() {
			if (this.courtage?.plattformen) {
				this.lagerstellen = this.courtage.plattformen.filter(l => l[1] == '1')
				.reduce((result, plattform) => {
				result[plattform[2]] = plattform[0]
				return result;
			}, {});
				this.plattformen = this.courtage.plattformen.map(l => ([l[0], l[1]]));
			}
		},
		calcCourtage(settings) {
			this.options = settings.reduce((result, sets) => {
				result[sets.key] = sets.value
				return result;
			}, {});
			this.calcRows();
		},
    berechnen(params = {}) {
			if (this.courtage.isinQuery) {
				params.isinQuery = this.courtage.isinQuery;
				params.bgsLst = this.courtage.bgsLst;
				params.wertsteigerung = ('' + (this.options.wertsteigerung||3));
			}
			this.loading = true;
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.LOAD_CALC_COURTAGE, params);
			this.loading = false;
    },
		selectPlattform() {
			this.$refs.showPlattform.open();
		},
		async onChangePlattform(value) {

			let lsSet = [];
			let lsReset = [];
			this.courtage.plattformen.forEach( (p, rix) => {
				if (p[1] == '1' && this.plattformen[rix][1] == '0' ) {
					lsReset.push(p[0])
				}
				if (p[1] == '0' && this.plattformen[rix][1] == '1' ) {
					lsSet.push(p[0])
				}
			})
			const params = {};
			if (lsSet.length) {
				params.lsSet = lsSet.join('\t');
			}
			if (lsReset.length) {
				params.lsReset = lsReset.join('\t');
			}
			this.berechnen(params);
		},
		getProz(value) {
			let nr = 0;
			if(typeof value == 'number') {
				nr = value;
			}
			if (typeof value == 'string') {
				value = value.replace(/[.]/g, '').replace(/[,]/, '.');
				if (!isNaN(parseFloat(value))) {
					nr = parseFloat(value);
				}
			}
			if (nr <0 || nr > 100) {
				nr = 0;
			}
			return nr / 100;
		},
		calcRows() {
			if (this.courtage?.bgsLst?.length) {
				const rows = [];
				let anlage, courtagen = [], lgstProv, value, fond;
				const prov = this.courtage.lagerstelleFondsProv;
				const wertsteigerung = this.options.wertsteigerung*0.01
				this.courtage.bgsLst.forEach(bgs => {
					fond = bgs.split('\t'); // isin,betrag,name
					anlage = this.jahre == 0 ? fond[1] : fond[1] * Math.pow(1 + wertsteigerung, this.jahre);
					for (let j = 0; j <= this.jahre; j++) {
						courtagen[j] = {}
						Object.keys(prov).forEach(lgst => {
							lgstProv = prov[lgst];
							value = lgstProv[fond[0]] || 0;
							courtagen[j][lgst] = fond[1] * value * Math.pow(1 + wertsteigerung, j);
							if (this.options['gebuehr_'+lgst]) {
								value = this.getProz(this.options['gebuehr_'+lgst])
								courtagen[j][lgst] = courtagen[j][lgst] * (1 - value);
							} else if (this.options.gebuehr) {
								value = this.getProz(this.options.gebuehr)
								courtagen[j][lgst] = courtagen[j][lgst] * (1 - value);
							}
						})
					}
					const row = {
						type: 'Kauf',
						isin: fond[0],
						Wertpapier: fond[2],
						Anlagesumme: anlage,
						accordance: this.jahre,
					};
					Object.keys(this.lagerstellen).forEach(ls => {
						let courtage = courtagen[this.jahre][ls];
						row[this.lagerstellen[ls]+ "_Jahrescourtage"] = courtage;
						if (this.jahre > 1) {
							for (let k = 1; k < this.jahre; k++ ) {
								courtage += courtagen[k][ls];
							}
						}
						row[this.lagerstellen[ls]] = courtage;
					})
					rows.push(row);
				})
				this.rows = rows;
			}
		},
  },
	watch: {
		courtage: {
			handler() {
				this.setPlttformen();				
				this.calcRows();
			},
			immediate: true,
		},
		jahre() {
			this.calcRows();
		}
	}
}
</script>

<style scoped>
.plattform {
	margin-bottom: 7px;
}
::v-deep .plattform .input-forms__label-container{
	width: 100%;
}
</style>