var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { showDefaultButtons: false, actions: _vm.baseModalActions },
    on: {
      close: _vm.onClose,
      "action-RESTORE_DEFAULT": function($event) {
        return _vm.restoreDefault()
      },
      "action-CLOSE": function($event) {
        return _vm.saveConfigEdited()
      },
      onCloseButton: function($event) {
        return _vm.saveConfigEdited()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass:
                  "dashboard-panel-config-modal--title-container clearfix"
              },
              [
                _c(
                  "span",
                  { staticClass: "dashboard-panel-config-modal--title" },
                  [_vm._v("Verwalten")]
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "modalHeaderBottom",
        fn: function() {
          return [
            _vm.ignoreUserLevelConfig !== true
              ? _c("DashboardPanelConfigTabs", {
                  attrs: {
                    disabled: _vm.loading,
                    selected: _vm.userLevelSaveConfig
                  },
                  on: {
                    tabSelected: function($event) {
                      return _vm.onTabSelected($event.key)
                    }
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _vm.loading
              ? _c(
                  "GhostLoading",
                  [
                    _c("Block", {
                      style: { margin: "0 0 1px 0" },
                      attrs: { height: "40px" }
                    }),
                    _c("Block", {
                      style: { margin: "0 0 1px 0" },
                      attrs: { height: "40px" }
                    }),
                    _c("Block", {
                      style: { margin: "0 0 1px 0" },
                      attrs: { height: "40px" }
                    }),
                    _c("Block", {
                      style: { margin: "0 0 1px 0" },
                      attrs: { height: "40px" }
                    }),
                    _c("Block", {
                      style: { margin: "0 0 1px 0" },
                      attrs: { height: "40px" }
                    }),
                    _c("Block", {
                      staticClass: "mb-0",
                      attrs: { height: "40px" }
                    })
                  ],
                  1
                )
              : _vm.isSmallScreen
              ? _c(
                  "div",
                  [
                    _c(
                      "SortableList",
                      {
                        attrs: { items: _vm.widgetsIntern },
                        on: {
                          orderChanged: function($event) {
                            return _vm.orderChanged($event)
                          }
                        }
                      },
                      [
                        _c(
                          "ul",
                          {
                            staticClass:
                              "dashboard-panel-config__items list-bordered",
                            attrs: { "data-sortable-container": "" }
                          },
                          [
                            _vm._l(_vm.widgetsIntern, function(widget) {
                              return [
                                _c("SortableListItemDeleteConfirm", {
                                  key: widget.title,
                                  attrs: {
                                    visible: _vm.isVisible(widget),
                                    removable: _vm.isRemovable(widget),
                                    title: widget.title,
                                    showConfirmRemove:
                                      _vm.confirmRemove === widget
                                  },
                                  on: {
                                    onRemove: function($event) {
                                      return _vm.remove(widget)
                                    },
                                    onConfirmRemove: function($event) {
                                      _vm.confirmRemove
                                        ? (_vm.confirmRemove = null)
                                        : (_vm.confirmRemove = widget)
                                    }
                                  }
                                })
                              ]
                            })
                          ],
                          2
                        )
                      ]
                    ),
                    _vm.widgetsToSelect.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "dashboard-panel-config__to-add__container"
                          },
                          [
                            _c("h3", { staticClass: "box__title" }, [
                              _vm._v("Auswahl")
                            ]),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "dashboard-panel-config__items list-bordered"
                              },
                              [
                                _vm._l(_vm.widgetsToSelect, function(
                                  widget,
                                  index
                                ) {
                                  return [
                                    _vm.isVisible(widget)
                                      ? _c(
                                          "li",
                                          {
                                            key: index,
                                            staticClass:
                                              "dashboard-panel-config__item list-bordered-item"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "dashboard-panel-config__item--actions sortable-list-item__actions"
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-clear clickable color-success",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.add(widget)
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("PhPlusCircle", {
                                                      attrs: {
                                                        size: 18,
                                                        weight: "fill"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "dashboard-panel-config__item--label"
                                              },
                                              [_vm._v(_vm._s(widget.title))]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _c("div", { key: _vm.userLevelSaveConfig }, [
                  _vm.widgetsIntern.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "dashboard-panel-config__to-added__container"
                        },
                        [
                          _c(
                            "ul",
                            {
                              staticClass:
                                "dashboard-panel-config__items list-bordered"
                            },
                            [
                              _vm._l(_vm.widgetsIntern, function(widget) {
                                return [
                                  _vm.isVisible(widget)
                                    ? _c(
                                        "li",
                                        {
                                          key: widget.title,
                                          staticClass:
                                            "dashboard-panel-config__item list-bordered-item"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dashboard-panel-config__item--label"
                                            },
                                            [_vm._v(_vm._s(widget.title))]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "dashboard-panel-config__item--actions"
                                            },
                                            [
                                              _vm.isRemovable(widget)
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn-clear clickable",
                                                      attrs: {
                                                        type: "button",
                                                        alt: _vm.ariaDescription
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.remove(
                                                            widget
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("PhTrashSimple", {
                                                        attrs: {
                                                          size: 16,
                                                          alt:
                                                            _vm.ariaDescription
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.widgetsToSelect.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "dashboard-panel-config__to-add__container"
                        },
                        [
                          _c("h3", { staticClass: "box__title" }, [
                            _vm._v("Auswahl")
                          ]),
                          _c(
                            "ul",
                            {
                              staticClass:
                                "dashboard-panel-config__items list-bordered"
                            },
                            [
                              _vm._l(_vm.widgetsToSelect, function(
                                widget,
                                index
                              ) {
                                return [
                                  _vm.isVisible(widget)
                                    ? _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass:
                                            "dashboard-panel-config__item list-bordered-item"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dashboard-panel-config__item--label"
                                            },
                                            [_vm._v(_vm._s(widget.title))]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "dashboard-panel-config__item--actions"
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-clear clickable",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.add(widget)
                                                    }
                                                  }
                                                },
                                                [_c("PhPlus")],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      )
                    : _vm._e(),
                  !_vm.widgetsIntern.length && !_vm.widgetsToSelect.length
                    ? _c("div", { staticClass: "text-centered" }, [
                        _vm._v("Keine Daten")
                      ])
                    : _vm._e()
                ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }