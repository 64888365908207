<template>
  <div>
    <OptionMenu
        :id="$appNavigation.currentOptionMenuId"
        :defaultMenu="$appNavigation.currentOptionMenu" >
    </OptionMenu>

    <PageHeaderTitleNavigation
        :title="$appNavigation.currentMenu.label"
        :id="$appNavigation.currentOptionMenuId"
        :actions="actions"
        @action-EMAIL="sendAsEmailOrFax('email')">
    </PageHeaderTitleNavigation>

    <div class="box__container">
      <ComboBox
        label="Versicherung"
        v-model="vertragsspiegel.versicherungStatus"
        :values="fieldsDefinition.versicherungStatus.value"
        :disabled="fieldsDefinition.versicherungStatus.disabled"
        isComponentHalfSize
      />

      <InputRadioBoxGroup 
        title="Betrieblich"
        v-model="vertragsspiegel.betrieblich"
        :values="yesNoValues"
        :disabled="fieldsDefinition.betrieblich.disabled"
      />

      <InputRadioBoxGroup 
        title="Gesundheit"
        v-model="vertragsspiegel.gesundheit"
        :values="yesNoValues"
        :disabled="fieldsDefinition.gesundheit.disabled"
      />

      <InputRadioBoxGroup 
        title="Sachversicherungen"
        v-model="vertragsspiegel.sachversicherungen"
        :values="yesNoValues"
        :disabled="fieldsDefinition.sachversicherungen.disabled"
      />

      <InputRadioBoxGroup 
        title="Sonstige"
        v-model="vertragsspiegel.sonstige"
        :values="yesNoValues"
        :disabled="fieldsDefinition.sonstige.disabled"
      />

      <InputRadioBoxGroup 
        title="Vorsorge"
        v-model="vertragsspiegel.vorsorge"
        :values="yesNoValues"
        :disabled="fieldsDefinition.vorsorge.disabled"
      />
    </div>
    <div class="box__container">
      <InputRadioBoxGroup 
        title="Wertpapiere"
        v-model="vertragsspiegel.wertpapier"
        :values="yesNoValues"
        :disabled="fieldsDefinition.wertpapier.disabled"
      />

      <InputRadioBoxGroup 
        title="Sonstige Produkte"
        v-model="vertragsspiegel.sonstigeProdukte"
        :values="yesNoValues"
        :disabled="fieldsDefinition.sonstigeProdukte.disabled"
      />

      <InputRadioBoxGroup 
        title="Bankkonten"
        v-model="vertragsspiegel.bankkonten"
        :values="yesNoValues"
        :disabled="fieldsDefinition.bankkonten.disabled"
      />

      <InputRadioBoxGroup 
        title="Weitere Vermögenswerte"
        v-model="vertragsspiegel.verschiedene"
        :values="yesNoValues"
        :disabled="fieldsDefinition.verschiedene.disabled"
      />

      <InputRadioBoxGroup 
        title="Kredite/Darlehen"
        v-model="vertragsspiegel.kredite"
        :values="yesNoValues"
        :disabled="fieldsDefinition.kredite.disabled"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VERTRAGSSPIEGEL_TYPES from "@/store/vertragsspiegel/types";
import CORE_TYPES from '@/store/core/types';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction, PageHeaderDownloadLinkAction } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
    PageHeaderTitleNavigation,
    ComboBox,
    InputRadioBoxGroup,
    OptionMenu,
  },
  data() {
    return {
      vertragsspiegel: {
        versicherungStatus: "aktiv, Angebot, beitragsfrei, beantragt, Mahnung, Nachbearbeitung, Switch, Ruhend",
        betrieblich: true,
        gesundheit: true,
        sachversicherungen: true,
        sonstige: true,
        vorsorge: true,
        wertpapier: true,
        sonstigeProdukte: true,
        bankkonten: true,
        verschiedene: true,
        kredite: true
      },
      yesNoValues: [
        { label: 'Ja', value: true, },
        { label: 'Nein', value: false, },
      ],
    }
  },
  computed: {
    ...mapGetters({
      fieldsDefinition: VERTRAGSSPIEGEL_TYPES.GETTERS.FIELDS_DEFINITION,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
    }),
    actions() {
      return [
        PageHeaderDownloadLinkAction('DOWNLOAD', 'PDF', 
          'Vertragsspiegel.pdf', '/vertragsspiegelGeneratePDF', this.vertragsspiegel),
        PageHeaderSimpleAction('EMAIL', 'E-Mail'),
      ]
    }
  },
  methods: {
    sendAsEmailOrFax(type) {
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_VERTRAGSSPIEGEL, {...this.vertragsspiegel, filename: `Vertragsspiegel-${this.fullname}.pdf`})
        .then(response => {
          const customResponse = {
            ...response.data,
          }

          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_VERTRAGSSPIEGEL, customResponse);
          if (customResponse.attachments.length) {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
              type: 'mailcomposer-vertragsspiegel',
              action: 'clear',
            });
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
              type: 'mailcomposer-vertragsspiegel',
              action: 'clear',
            });
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, customResponse.attachments.map(v => ({...v, preloaded: true})));
          }
          if('email' === type) {
            this.$router.push({ path: '/communication/mailcomposer-single', query: {replaceContent: true} });
          } else {
            this.$router.push('/communication/mailcomposer-fax');
          }
        });
    },
  },
  mounted() {
    this.$store.dispatch(VERTRAGSSPIEGEL_TYPES.ACTIONS.GET_FIELDS_DEFINITION)
  },
  beforeRouteLeave(to, from, next) {
    let url = from.fullPath;
    this.$addBreadcrumb({
      label: 'zurück zu Vertragsspiegel',
      fullPath: url,
      breadcrumb: 'E-Mail an Kunde ',
    }); 
    next();
},

}
</script>