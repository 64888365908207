<template>
  <div>
    <div>
      <BaseButton :isPrimary="isPrimary" :disabled="disabled" class="upload-btn">
        <slot>Datei hinzufügen</slot>
        <label class="upload-btn--label" :class="{'clickable': !disabled }" aria-label="Datei hinzufügen">
          <input type="file" :accept="accept" @change="onAddFiles" multiple :disabled="disabled"/>
        </label>
      </BaseButton>
    </div>
    <hr v-if="value && value.length">
    <div class="flex-layout">
      
      <template v-for="file in value" >
        <div class="pill__container"  :key="file.name+file.size+file.lastModified">
          <Pill :label="file.name" type="default" :showCloseIcon="true" @removePill="onRemoveFile(file)"></Pill>
        </div>
        
      </template>
      
    </div>
    <hr v-if="value && value.length">
  </div>
</template>
<script>
import BaseButton from '@/components/core/BaseButton.vue';
import Pill from '@/components/core/Pill.vue';
import { buildMessage } from '@/helpers/log-message-helper';
import LOG_TYPES from '@/store/log/types';

export default {
  props: {
    accept: {
      type: String,
      default: '*',
    },
    value: {
      type: Array,
      default: () => [],
    },
    isPrimary: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxFileSizeMB: {
      type: Number,
      default: 0
    }
  },
  methods: {
    onAddFiles(event) {
      const files = this.value?.slice() || [];
      const forbiddenFilesBySize = [];
      // if there is no maxFileSizeMB or informed zero, any size is allowed
      const maxSize = this.maxFileSizeMB * 1024 * 1024; //in MB
      event.target.files.forEach(file => {
        if(maxSize && file.size > maxSize) {
          forbiddenFilesBySize.push(file);
        } else {
          files.push(file);
        }
      });
      if(forbiddenFilesBySize.length) {
        this.$store.dispatch(
          LOG_TYPES.ACTIONS.ADD_MESSAGE,
          buildMessage(`Diese Dateien überschreiten die Größenbegrenzung von ${this.maxFileSizeMB} MB. Bitte laden Sie erneut eine kleinere Datei hoch.`, 'warning')
        );
        return;
      }
      this.$emit("input", files)
      event.target.value = ''
    },
    onRemoveFile(file) {
      const files = this.value.slice()
      files.splice(files.indexOf(file), 1)
      this.$emit("input", files)
    },
  },
  components: {
    BaseButton,
    Pill,
  },
}
</script>
<style scoped>
.multi-file-primary {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-top-nav-background);
}
.multi-file-secondary {
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary);
  background-color: var(--color-workspaces-nav-background);
}
.multi-file-primary, .multi-file-secondary {
  text-align: center;
  font-size: 1em;
  display: flex;
}
.multi-file-primary > span, .multi-file-secondary > span {
  flex-grow: 1;
}
.multi-file-primary > i, .multi-file-secondary > i {
  border-left: inherit;
  color: #e00;
  padding: 3px;
  cursor: pointer;
}
.upload-btn {
  position: relative;
}
.upload-btn--label {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.upload-btn--label input {
  display: none;
}
.flex-layout {
  display: inline-flex;
  flex-flow: wrap;
  margin-top: 8px;
  /* margin-bottom: 8px; */
}

.flex-layout div {
  margin-bottom: 8px;
}

.pill__container {
  margin-right: 8px;
}

hr {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
