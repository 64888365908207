var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Termine",
          actions: _vm.headerActions
        },
        on: {
          "action-NEW_TERMIN": _vm.newAppointment,
          "action-NEW_AUFGABE": _vm.newTask
        }
      }),
      _vm.defaultOptions
        ? _c("BaseFilter", {
            attrs: {
              title: "Termine Filter",
              filterId: "PostfachTAAFilter",
              isCache: _vm.hasFilterCache,
              configFilter: _vm.filterConfig,
              metadata: _vm.metadata,
              defaultOptions: _vm.defaultOptions,
              showSaveButton: "",
              hasSmartSearch: "",
              immidiateSearch: ""
            },
            on: { onFilter: _vm.onFilter }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows.length
            ? _c(
                "ScrollLoading",
                {
                  attrs: {
                    hasScroll:
                      _vm.rows.length !== _vm.maxRowCount && !_vm.reachedEnd
                  },
                  on: { scroll: _vm.onScroll }
                },
                [
                  _vm.rows.length
                    ? _c("Table", {
                        attrs: {
                          rowId: "rowid",
                          headers: _vm.headers,
                          rows: _vm.rows,
                          headerActions: _vm.tableHeaderActions,
                          exportConfig: { roottext: "Postfach" },
                          mobileConfig: _vm.mobileConfig,
                          count: _vm.maxRowCount,
                          hidePagination: ""
                        },
                        on: {
                          "click-subject": _vm.clickBetreff,
                          "action-DELETE": _vm.deleteRow,
                          "headerAction-BULK_DELETE": _vm.deleteSelected
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "participants",
                              fn: function(row) {
                                return [
                                  _c(
                                    "ul",
                                    {
                                      staticStyle: { padding: "0", margin: "0" }
                                    },
                                    _vm._l(row.participants, function(
                                      participant,
                                      index
                                    ) {
                                      return _c(
                                        "li",
                                        {
                                          key: participant.id,
                                          staticStyle: {
                                            display: "inline-block"
                                          }
                                        },
                                        [
                                          participant.isKunde &&
                                          _vm.canOpenCustomer(participant) &&
                                          !row.mobileTableContext
                                            ? _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openCustomerNewTab(
                                                        participant.nummer
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(participant.name)
                                                  )
                                                ]
                                              )
                                            : _c("span", [
                                                _vm._v(_vm._s(participant.name))
                                              ]),
                                          index < row.participants.length - 1
                                            ? _c("span", [_vm._v("; ")])
                                            : _vm._e()
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1471458454
                        ),
                        model: {
                          value: _vm.selectedRows,
                          callback: function($$v) {
                            _vm.selectedRows = $$v
                          },
                          expression: "selectedRows"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm.loading && !_vm.reachedEnd
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 10 } } }
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }