import { ROLES } from '@/router/roles';
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from 'vuex'
import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import COMMUNICATION_TYPES from '@/store/communication/types';

const mixin = {
    data() {
      return {
        expanded: {},
        zusDokumente: {},
        selectedOpenSignatureDocumentDetail: null,
        selectedAdditionalDocuments: [],
        selectedOpenSignatureForAdditionalDocuments: [],
        loading: {},
        selectedElement: null,
        showOffeneUnterschriftenDisableWarnung: false,
      }
    },
    computed: {
      ...mapGetters({
        openSigns: OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS,
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        readOnly: CORE_TYPES.GETTERS.IS_OPEN_SIGNATURES_READ_ONLY,
      }),
      offeneUnterschriften() {
        return this.openSigns?.offeneUnterschriften || [];
      },
      showButtonDokumentAbschliessen() {
        return this.$hasRoles([ROLES.SHOW_BUTTON_DOKUMENT_ABSCHLIESSEN]);
      },
    },
    methods: {
      async loadOpenSigns(rowsPerPage, page = 0, filterOptions = {}, keepMeta = false) {
        this.isLoading = true;
  
        return this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS, { 
          rowsPerPage: rowsPerPage, 
          page,
          keepMeta,
          ...filterOptions })
        .finally(() => {
          this.isLoading = false;
        });
      },
      getTitle(index, elem = {}) {
        const date = elem.vorlegeDatum;
        const dateDescription = date ? `${date} | ` : ''
        const indexStr = typeof index === 'number' ? `${index + 1}.` : ''
  
        return `${indexStr} ${dateDescription} ${elem.name || 'Dokument'} `.trim();
      },
      getStatusStr(elem = {}) {
        let statusStr = 'nicht unterschrieben';
        if (elem.status) {
          switch(elem.status) {
            case 'TEILWEISE':
              statusStr = 'teilweise unterschrieben';
              break;
            case 'KOMPLETT':
              statusStr = 'komplett unterschrieben, aber noch nicht abgeschlossen';
              break;
            default: break;
          }
          return statusStr;
        }
      },
      getStatusClass(elem = {}, hasColor = true) {
        let statusStr = hasColor && 'color-danger' || 'danger';
        if (elem.status) {
          switch(elem.status) {
            case 'TEILWEISE':
            case 'teilweise unterschrieben':
              statusStr = hasColor && 'color-warning' || 'warning';
              break;
            case 'KOMPLETT':
            case 'komplett unterschrieben, aber noch nicht abgeschlossen':
              statusStr = hasColor && 'color-success' || 'success';
              break;
            default: break;
          }
          return statusStr;
        }
      },
      isAbschliessenButtonVisible(elem) {
        return this.showButtonDokumentAbschliessen && elem?.showSubmitButton;
      },
      isAbschliessenButtonDisabled(elem) {
        return this.readOnly || elem?.status !== 'KOMPLETT';
      },
      async selectAdditionalDocuments(element) {
        const folder = element.zusDokumente
        await this.getZusaetzlicheDok(folder)
        this.selectedOpenSignatureForAdditionalDocuments = element
        this.selectedAdditionalDocuments = this.zusDokumente[folder.nodeId]
        this.$refs.zusaetzlicheDokumente.open();
      },
      async getZusaetzlicheDok(folder) {
        if (folder?.nodeId) {
          if (!this.zusDokumente[folder.nodeId]) {
            this.$set(this.loading, folder.nodeId, true)
  
            try {
              const response = await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.GET_ZUSAETZLICHE_DOCUMENT_OFFENE_UNTERSCHRIFTEN, { nodeId: folder.nodeId })
  
              if (response.data?.offeneUnterschriften) {
                this.zusDokumente[folder.nodeId] = response && response.data.offeneUnterschriften;
                this.$set(this.expanded, folder.nodeId, true)
              }
            } catch (error) {
              // empty block
            } finally {
              this.$set(this.loading, folder.nodeId, false)
            }
          } else {
            this.$set(this.expanded, folder.nodeId, !this.expanded[folder.nodeId])
          }
        }
      },
      canGenerateSignoViewerLink(element) {
        return !!(element.nodeId && element.viewFileId)
      },
      openVorlageModal(element) {
        this.selectedElement = element;
        this.$refs.removeVorlageModal.openVorlageModal();
      },
      openCustomerNewTab(sign) {
        if (sign?.kundennr) {
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: sign.kundennr });
        }
      },
      openOffeneUnterschriftenDisableWarnung(element) {
        this.selectedElement = element;
        this.showOffeneUnterschriftenDisableWarnung = true;
      },
      async submitDocument(elem) {
        try {
          const metadata = await this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.METADATA_SUBMIT_DOCUMENT, {
            viewFileId: elem.viewFileId,
            nodeId: elem.nodeId,
          });
  
          let title = 'Dokument endgültig abschließen';
          let message = 'Das Dokument wird gespeichert und endgültig abgeschlossen.<br/>Anschließend sind keine Änderungen mehr möglich.';
  
          if (metadata?.submitConfirmationText) {
            message = metadata.submitConfirmationText;
          }
          if (metadata?.submitConfirmationTitle) {
            title = metadata.submitConfirmationTitle;
          }
  
          await this.$confirmModal({
            title,
            message,
            labelButtonCancel: 'Abbrechen',
            labelButtonConfirm: 'Abschließen',
          });
  
          await this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.SUBMIT_DOCUMENT, { 
            viewFileId: elem.viewFileId,
            nodeId: elem.nodeId,
            dokId: elem.dokId,
          });
  
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Dokument wurde abgeschlossen/eingereicht', 'success'));
  
          // this.doInitialLoad();
        } catch(e) {
          // empty block
        }
      },
    }
};

export default mixin;