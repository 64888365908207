<template>
<div>
    <div class="box__container" v-if="rowsEmail.length">
        <Table v-if="!loading && rowsEmail.length"
            tableId="9e599a44-66b5-4178-8fd7-6a98b658feb0"
            title="E-Mails"
            :headers="headersEmail"
            :rows="rowsEmail"
            rowId="rowid"
            :rowsPerPage="30"
            :selected="!isInsurance ? selectedRowsEmail : null"
            :exportConfig="{roottext: 'Postfach'}"
            @selected="selected"
            @click-Subject="clickBetreff"
            @action-WEITERLEITEN="handleActionWeiterleiten"
            @action-WEITERLEITEN_USER="handleActionWeiterleitenUser"
            @action-WEITERLEITEN_COMPANY="handleActionWeiterleitenCompany"
            @action-DELETE="handleActionDelete"
            @action-KUNDEN_OFFNEN="openCustomerNewTab"
            @click-Name="openCustomerNewTab"
        >
          <template v-slot:Subject="row">
            Von {{ row.FROM }} an {{ row.TO }}
            <div v-if="row.category !== 'Schriftverkehr'" class="subject-column--content text-cell cell">
              <DownloadLink
                v-if="row.category === 'Fax'"
                :title="row.Subject"
                :disabled="!row.commId"
                downloadServicePath="/downloadFax"
                :filename="row.Subject.includes('.pdf') ? row.Subject : `${row.Subject}.pdf`"
                :queryParams="{ id: row.commId }"
              />
              <a v-else key="Subject" @click="clickBetreff(row)"
                :tid="_generateTidFromString('9e599a44-66b5-4178-8fd7-6a98b658feb0:Subject:' + row.id)"
              >
                {{row.Subject}}
              </a>
              <template v-if="row.status !== 'success' && row.status !== 'Keine Angaben'">
                <span class="mx-2"> | </span>
                <ph-warning :size="16" :class="`color-${row.status}`" />
              </template>
            </div>
            <div v-else class="subject-column--content text-cell cell">
              <DownloadLink
                :title="row.Subject"
                :disabled="!row.commId"
                downloadServicePath="/get_simple_file"
                :filename="row.Subject.includes('.pdf') ? row.Subject : `${row.Subject}.pdf`"
                :queryParams="{
                    fileId: row.commId
                }"
              />
            </div>
          </template>
        </table>
        <GhostLoading v-else-if="loading" type="table" title="E-Mails" />
        <NoData v-else title="E-Mails" />
      </div>

      <div class="box__container" v-if="rowsAktivitaet.length">



        <Table v-if="!loading && rowsAktivitaet.length"
            tableId="c390bddb-e0b9-49dc-a211-266555a47b94"
            title="Aufgaben"
            :headers="headersAufgaben"
            :rows="rowsAktivitaet"
            rowId="rowid"
            :rowsPerPage="30"
            :selected="!isInsurance ? selectedRowsAktivitaet : null"
            :exportConfig="{roottext: 'Postfach'}"
            @selected="selected"
            @click-Subject="clickBetreff"
            @action-WEITERLEITEN="handleActionWeiterleiten"
            @action-WEITERLEITEN_USER="handleActionWeiterleitenUser"
            @action-WEITERLEITEN_COMPANY="handleActionWeiterleitenCompany"
            @action-DELETE="handleActionDelete"
            @action-KUNDEN_OFFNEN="openCustomerNewTab"
            @action-DONE="doneRow"
            @click-Name="openCustomerNewTab"
        >
          <template v-slot:Subject="row">
            {{ getTitleForActivity(row) }}
            <a v-if="row.category !== 'Schriftverkehr'" key="Subject" @click="clickBetreff(row)"
              :tid="_generateTidFromString('9e599a44-66b5-4178-8fd7-6a98b658feb0:Subject:' + row.id)"
            >
              <div class="subject-column--content text-cell cell">
                <span>{{row.Subject}}</span>

                <template v-if="row.status !== 'success' && row.status !== 'Keine Angaben'">
                  <span class="mx-2"> | </span>
                  <ph-warning :size="16" :class="`color-${row.status}`" />
                </template>
              </div>
            </a>
            <div v-else class="subject-column--content text-cell cell">
              <DownloadLink
                :title="row.Subject"
                :disabled="!row.commId"
                downloadServicePath="/get_simple_file"
                :filename="row.Subject.includes('.pdf') ? row.Subject : `${row.Subject}.pdf`"
                :queryParams="{
                    fileId: row.commId
                }"
              />
            </div>
          </template>
          <template v-slot:participants="row">
                        <ul style="padding: 0; margin: 0">
                            <li
                                style="display:inline-block;"
                                v-for="(participant, index) in row.participants"
                                :key="participant.id"
                            >
                                <span>{{ participant.name }}</span>
                                <span v-if="index < row.participants.length - 1">;&nbsp;</span>
                            </li>
                        </ul>
          </template>
          <template v-slot:date="row">
            <div>
              <span v-if="row.status != 'Erledigt'">
                {{ dateToString(row.date) }}
              </span>
              <span v-else>

              </span>
            </div>
          </template>
        </table>
        <GhostLoading v-else-if="loading" type="table" title="Aufgaben" />
        <NoData v-else title="Aufgaben" />        
    </div>
    <BaseModal
      ref="confirm"
      :modalTitle="confirmWarning"
      labelButtonConfirm="Ok"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="confirmDelete">
    </BaseModal>
    <BaseModal
      ref="mailContacts"
      modalTitle="Kontakte"
      size="lg"
      :showCancelButton="false"
      labelButtonConfirm="Auswahl übernehmen"
      @onConfirmButton="confirmContactsToSend">
      <InsuranceCompanyMailContacts :records="mailUsers" @selectedRows="selectRowsContacts" />
    </BaseModal>
    <BaseModal 
            ref="modalStatus"
            modalTitle="Aufgabe wiederholen?"
            :showConfirmButton="true"
            labelButtonConfirm="Aktuelle Aufgabe bestätigen"
            labelButtonCancel="Alle Aufgaben bestätigen"
            @onConfirmButton="doRepeate()"
            @onCancelButton="dontRepeate()"> 
              <div>
                Diese Aufgabe soll wiederholt werden. Alle Wiederholungen bestätigen?
              </div>
          </BaseModal>
</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import COMMUNICATION_TYPES from '@/store/communication/types';
import LOG_TYPES from '@/store/log/types';
import CALENDAR_TYPES from "@/store/calendar/types";
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, DateTimeColumn, IconColumn, PillColumn, ActionColumn, Icon, SimpleAction, DateColumn} from "@/components/table2/table_util.js";
import { PhPaperclip, PhPencilLine, PhEnvelope, PhEnvelopeOpen, PhArrowLeft, PhArrowRight } from "phosphor-vue";
import BaseModal from '@/components/core/BaseModal.vue';
import axios from 'axios';
import {openFileInNewTab, getRedirectionWindow} from "@/components/table/export/utils";
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import AppointmentEditCreate from "@/components/calendar/appointmentFiles/AppointmentEditCreate.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import InsuranceCompanyMailContacts from '@/views/versicherungen/InsuranceCompanyMailContacts.vue'
import InteractiveHelpCommonsMixin from '@/assets/mixins/interactivehelpcommonsmixins.js';
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import {looksLikeKundennr} from '@/views/customer/customer-utils';
import { PhWarning } from 'phosphor-vue'
import {makeQueryParam} from "@/helpers/utils-helper"
import { toDateString } from "@/filters.js"


export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    PhPaperclip,
    PhPencilLine,
    PhEnvelope,
    PhEnvelopeOpen,
    PhArrowLeft,
    PhArrowRight,
    BaseModal,
    AppointmentEditCreate,
    GhostLoading,
    NoData,
    InsuranceCompanyMailContacts,
    DownloadLink,
    PhWarning,
  },
    props: {
        title: {
          type: String,
          required: true,
        },
        records: {
          type: Array,
          default: null,
        },
        loading: {
          type: Boolean,
          default: false,
        },
        disabledColumns: {
          type: Array,
          default: () => ([])
        },
        parentId: {
          type: String,
        },
        isInsurance: {
          type: Boolean,
          default: false,
        },
    },
    computed: {
      ...mapGetters({      
        initMailData: MAILCOMPOSER_TYPES.GETTERS.GET_INIT,
        isCustomerView: CORE_TYPES.GETTERS.IS_CUSTOMER,
      }),
        headersEmail() {

            let lockedLeft = [];
            
            if (this.isCustomerView) {
              lockedLeft = [
                SlotColumn("Subject", "Betreff", 200, 1).makeAlwaysVisible(),
                DateTimeColumn("date", "Datum"),
              ];
            } else if(this.rowsEmail.length) {              
                lockedLeft = [
                SlotColumn("Subject", "Betreff", 200, 1).makeAlwaysVisible(),
                DateTimeColumn("date", "Datum"),
                IconColumn("statusIcon", "Status").setColorFn(
                  (row) => `color-${row.status.type}`
                ),
                TextColumn("EXTERN", "Quelle"),
              ];             
            }

            return {
                lockedLeft: lockedLeft.filter(column => !this.disabledColumns.includes(column.key)),
                center: [
                    TextColumn("contact", "Kontakt"),
                ].filter(column => !this.disabledColumns.includes(column.key)),
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ].filter(column => !this.disabledColumns.includes(column.key))
            };
        },
        headersAufgaben(){
            return {
                lockedLeft: [
                SlotColumn("Subject", "Betreff", 200, 1).makeAlwaysVisible(),
                TextColumn("name", "Besitzer").alignCenter().makeConditionalLink('hasNummer'),
                SlotColumn("participants", "Teilnehmer", 80, 1),     
                SlotColumn("date", "Datum (der nächsten Wiederholung)").alignCenter(),
              ].filter(column => !this.disabledColumns.includes(column.key)),
                center: [
                TextColumn("repetitionType","Wiederholung", 20, 1).alignCenter(),  
                DateColumn("lastModified", "Zuletzt geändert").alignCenter(),
                ].filter(column => !this.disabledColumns.includes(column.key)),
                lockedRight: [
                  TextColumn("status", "Status"),
                  ActionColumn("actions", "Aktionen"),
                ].filter(column => !this.disabledColumns.includes(column.key))
            };

        },
        rows() {
            if (!this.records)
                return [];
            return this.records.map(value => {
                const record = {...value};
                const row = {
                    ...record,
                    rowid: (record.commId + record.category + record.date),
                    cat: {
                        label: record.category,
                        type: "default",
                    },
                    Subject: record.subject || "[kein Betreff]",
                    status: record.status,
                    Attachment: record.hasAttachment ? [Icon(PhPaperclip, "Ja", 16)] : null,
                    hasNummer: !!record?.nummer && looksLikeKundennr(record?.nummer),
                    actions: this.makeActions(record)
                };
                
                switch (record.STATUS) {
                    case 'Ok':
                        row.STATUS = {label: "versendet", type: "success"};
                        break;
                    case 'Error':
                        row.STATUS = {label: "senden fehlgeschlagen", type: "danger"};
                        break;
                    case 'Sending':
                        row.STATUS = {label: "wird versendet", type: "warning"};
                        break;
                    case 'Bereitgestellt':
                        row.STATUS = {label: "bereitgestellt", type: "success"};
                        break;    
                }
                if (record.EXTERN != null)
                    row.EXTERN = record.EXTERN ? "extern" : "intern";
                return row;
            });
        },
        rowsEmail() {
          return this.rows.filter(r => r?.cat?.label  =='E-Mail');
        },
        rowsAktivitaet() {
          return this.rows.filter(r => r?.cat?.label  =='Aktivität' ||  r?.cat?.label  =='Aufgabe');
        },        
      currentInsuranceId() {
        return this.$route.params.id || this.parentId;
      },
    },
  data() {
    return {
      apiAddress: process.env.VUE_APP_API,
      isTerminModalActivity: false,
      confirmWarning: '',
      confirmRow: {},
      selectedRowsEmail: [],
      mailUsers: [],
      selectedRowsContacts: [],
      rowSelected: {},
      insuranceId: '',
    }
  },
  mounted() {
    this.insuranceId = this.$route.params.id || this.parentId;
  },
  watch: {
    initMailData() {
      this.mailUsers = this.initMailData?.participants;
    },
  },
  methods: {
    dateToString(value) {
            return toDateString(value)
        }, 
    makeActions(row) {
      const actions = []

      if (row.category === "E-Mail") {
        if (this.isInsurance || row.subject?.toLowerCase().includes('pol.nr.') || row.subject?.toLowerCase().includes('pol. nr.') || row.subject?.toLowerCase().includes('[[fkv') || row.subject?.toLowerCase().includes('{{fkv')) {
          actions.push(SimpleAction("WEITERLEITEN_COMPANY", 'PhShare', "Weiterleiten an Gesellschaft"))
          actions.push(SimpleAction("WEITERLEITEN_USER", 'PhShare', "Weiterleiten an Kunde"))
        } else {
          actions.push(SimpleAction("WEITERLEITEN", 'PhShare', "Weiterleiten"))
        }
      }

      if (row.deletable) {
        actions.push(SimpleAction("DELETE", 'PhTrash', "Löschen"))
      }

      if(row.status != "Erledigt")
      actions.push(SimpleAction("DONE", "PhCheck" , "Auf Erledigt setzen"))

      return actions
    },
    clickBetreff(tableRow) {
      if (tableRow.category) {
        switch (tableRow.category) {
          case "Nachricht":
            this.$router.push({name: 'ticket', params: {chatId: parseInt(tableRow.chatId), channelId: parseInt(tableRow.channelId)}});
            break;
          case "E-Mail":
            this.$router.push(`/communication/postfach/emails/email/${tableRow.commId}`);
            break;
          case "SMS":
            this.$router.push(`/communication/postfach/smses/sms/${tableRow.commId}`);
            break;
          case "Gespräch":
            this.$router.push(`/communication/postfach/talks/talk/${tableRow.commId}`);
            break;
          case "Bemerkung":
            this.$router.push(`/communication/postfach/bemerkungen/bemerkung/${tableRow.commId}`);
            break;
          case "Aufgabe":
          case "Termin":
          case "Aktivität":
            this.isTerminModalActivity = (tableRow.category == "Aktivität" || tableRow.category == "Aufgabe");
            this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, tableRow.commId);
            this.openAppointment();
            break;
        }
      }
    },
    handleActionWeiterleiten(row, url = 'mailcomposer-postfach') {
        this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_EMAIL, row.commId)
        .then(responseData => {
            this.$router.push({name: url, params: {
                subject: "Fwd: " + row.Subject,
                copiedFiles: responseData.attachments,
                // receiver: this.isInsurance ? [] : null,
                content: "<br><br>-------- Weitergeleitete Nachricht --------<br><i>"
                + "<u>Betreff:</u> " + row.Subject + "<br>"
                + "<u>Datum:</u> " + responseData.dateSend + "<br>"
                + "<u>Von:</u> " + responseData.fromAddress + "<br>"
                + "<u>An:</u> " + responseData.toAddress + "</i><br>"
                + (responseData.ccAddress ? `<u>CC:</u> ${responseData.ccAddress}<br>` : '')
                + (responseData.bccAddress ? `<u>BCC:</u> ${responseData.bccAddress}<br>` : '')
                + responseData.text,
            }});
        })
    },
    handleActionWeiterleitenUser(row) {
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
          type: 'mailcomposer-insurance',
          action: 'clear',
        });
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
          type: 'mailcomposer-insurance',
          action: 'clear',
        });
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
          data: [this.currentInsuranceId],
          toCompany: false,
          participants: undefined,
        });
        this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_EMAIL, row.commId)
        .then(responseData => {
            this.$router.push({name: 'mailcomposer-insurance', params: {
                subject: "Fwd: " + row.Subject,
                copiedFiles: responseData.attachments,
                // receiver: this.isInsurance ? [] : null,
                content: "<br><br>-------- Weitergeleitete Nachricht --------<br><i>"
                + "<u>Betreff:</u> " + row.Subject + "<br>"
                + "<u>Datum:</u> " + responseData.dateSend + "<br>"
                + "<u>Von:</u> " + responseData.fromAddress + "<br>"
                + "<u>An:</u> " + responseData.toAddress + "</i><br>"
                + responseData.text,
            }});
        })
    },
    handleActionWeiterleitenCompany(row) {
      this.rowSelected = row;
      if (this.isInsurance) {
        this.startCompanySelector(this.currentInsuranceId)
      } else {
        axios.post(this.apiAddress + '/../mrsmailviewer', {commId: row.commId, commType: 'email'}, {defaultSpinner: true})
        .then(response => {
          if (response.data.insurance) {
            this.startCompanySelector(response.data.insurance)
          } else {
            this.handleActionWeiterleiten(row)
          }
        }).catch(error => {
          this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'Error opening E-Mail', error});
        });
      }
    },
    startCompanySelector(insurance) {
      this.insuranceId = insurance;
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_INIT, {
        insurance,
        toCompany: true
      }).then(response => {
        if (response?.data?.participants && response?.data?.participants?.length) {
          this.$refs.mailContacts.open();
        } else {
          this.handleActionWeiterleiten(row, 'mailcomposer-insurance');
        }
      });
    },
    confirmContactsToSend() {
      this.$refs.mailContacts.close();
      this.sendMailWeiterleitenCompany();
    },
    selectRowsContacts(rows) {
      this.selectedRowsContacts = rows;
    },
    sendMailWeiterleitenCompany() {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: 'mailcomposer-insurance',
        action: 'clear',
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: 'mailcomposer-insurance',
        action: 'clear',
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
        data: [this.insuranceId],
        toCompany: true,
        participants: this.selectedRowsContacts || []
      });
      const receiver = this.isInsurance ? this.selectedRowsContacts || [] : null;
      this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_EMAIL, this.rowSelected.commId)
        .then(responseData => {
          this.$router.push({name: 'mailcomposer-insurance', params: {
            subject: "Fwd: " + this.rowSelected.Subject,
            copiedFiles: responseData.attachments,
            receiver,
            content: "<br><br>-------- Weitergeleitete Nachricht --------<br><i>"
            + "<u>Betreff:</u> " + this.rowSelected.Subject + "<br>"
            + "<u>Datum:</u> " + responseData.dateSend + "<br>"
            + "<u>Von:</u> " + responseData.fromAddress + "<br>"
            + "<u>An:</u> " + responseData.toAddress + "</i><br>"
            + responseData.text,
          }});
        })
    },
    handleActionDelete(row) {
      this.confirmRow = row;
      this.confirmWarning = "Soll der Eintrag '" + row.Subject + "' wirklich gelöscht werden?";
      switch(row.category) {
        case "E-Mail":
          this.confirmWarning = "Soll die E-Mail für Sie gelöscht werden?";
          break;
        case "Gespräch":
          this.confirmWarning = "Soll das Gespräch '" + row.Subject + "' gelöscht werden?";
          break;
        case "Bemerkung":
          this.confirmWarning = "Soll die Bemerkung '" + row.Subject + "' gelöscht werden?";
          break;
        case "Schriftverkehr":
          this.confirmWarning = "Soll der Brief '" + row.Subject + "' gelöscht werden?";
          break;
      }
      this.$refs.confirm.open();
    },
    confirmDelete() {
      this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.DELETE_FROM_POSTFACH, {id: this.confirmRow.commId, url: this.getUrlForCategory(this.confirmRow)}).then(v => {
        if (this.parentId)
          this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_COMMUNICATION_INSURANCE_LISTS, this.parentId);
      })
      this.$refs.confirm.close();
    },
    getUrlForCategory(tableRow) {
      if (tableRow.category) {
        switch (tableRow.category) {
          case 'Schriftverkehr':
            return 'schriftverkehr'
          case "Nachricht":
            return 'chat'
          case "E-Mail":
            return 'email'
          case "SMS":
            return 'sms'
          case "Gespräch":
            return 'talk'
          case "Bemerkung":
            return 'bemerkung'
          case "Fax":
            return 'fax'
          case "Aufgabe":
          case "Termin":
          case "Aktivität":
            return 'termin'
        }
      }
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: `${this.isTerminModalActivity ? 'Aufgabe' : 'Termin'} bearbeiten`,
        isActivity: this.isTerminModalActivity,
        selectedDate: null,
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    selected(rows) {
      this.selectedRows = rows;
      this.$emit('selected', rows);
    },
    openCustomerNewTab(customer) {
      if (customer?.nummer) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: customer.nummer });
      }
    },
    getTitleForActivity(whatRow) {
      if (whatRow.participants?.length) {
        return whatRow.participants[0].name;
      }
    },
    // TODO use postfachTableMixin for this feature
    doRepeate() {
            this.doneRow(this.selectedRow, true)
    },
    dontRepeate() {
            this.doneRow(this.selectedRow, false)
    },
        doneRow(data, repeate) {
            //Show status update modal
            //&& data.isRepeating
            console.log(repeate)
            if(repeate === undefined) {
                this.selectedRow = data
                this.$refs.modalStatus.open();
                return
            }

            const params = makeQueryParam({status: "ERLEDIGT"})

            axios
                .post(
                    `${process.env.VUE_APP_API}/calendarService/setStatus/${data.commId}?${params}`,
                    {status: "ERLEDIGT", repeate: repeate},
                    {defaultSpinner: true},
                )
                .then(() => {
                  this.$emit('refresh');
                    //this.loadPage(this.selectedPage)
                })
                .catch((error) => {
                    let msg = error?.data || "Es ist ein unerwarteter Fehler aufgetreten."
                    if (error?.response?.status == 400 || error?.response?.status == 403) {
                        msg = error?.response?.data
                    }
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, buildMessage(msg, "danger"))
                })
        },
  },
}
</script>

<style scoped>
.subject {
  display: inline-block;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.subject-column--content {
  -webkit-line-clamp: 1;
}
@media (max-width: 767px) {
  .subject-column--content {
    -webkit-line-clamp: 2;
  }
}
</style>