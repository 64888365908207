var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [_vm._v("Beschreibungen")]),
        _vm.schemaData.isAdvisor || _vm.schemaData.isVermoegensverwalter
          ? _c(
              "div",
              [
                _c("InputField", {
                  attrs: { label: "Factsheet E-Mail", validateUntouched: "" },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({ factsheetEmail: $event })
                    }
                  },
                  model: {
                    value: _vm.factsheetEmail,
                    callback: function($$v) {
                      _vm.factsheetEmail = $$v
                    },
                    expression: "factsheetEmail"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Factsheet Internet", validateUntouched: "" },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({ factsheetInternet: $event })
                    }
                  },
                  model: {
                    value: _vm.factsheetInternet,
                    callback: function($$v) {
                      _vm.factsheetInternet = $$v
                    },
                    expression: "factsheetInternet"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    label:
                      "automatischer Marktkommentar zur Strategie (Der Marktkommentar wird von Ihrer Struktur vorgegeben und ändert sich jedes Quartal.)",
                    disabled: !_vm.schemaData.isVermoegensverwalter,
                    rows: _vm.marktKommentarFactsheet ? 10 : 1,
                    autoGrow: !_vm.marktKommentarFactsheet,
                    validateUntouched: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({
                        marktKommentarFactsheet: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.marktKommentarFactsheet,
                    callback: function($$v) {
                      _vm.marktKommentarFactsheet = $$v
                    },
                    expression: "marktKommentarFactsheet"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    label:
                      "Beschreibung der Anlagestrategie (kurze Beschreibung Ihrer Strategie zur Infomation an den Kunden)",
                    rows: _vm.anlageStrategieFactsheet ? 10 : 1,
                    autoGrow: !_vm.anlageStrategieFactsheet,
                    validateUntouched: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({
                        anlageStrategieFactsheet: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.anlageStrategieFactsheet,
                    callback: function($$v) {
                      _vm.anlageStrategieFactsheet = $$v
                    },
                    expression: "anlageStrategieFactsheet"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    label:
                      "Vorstellung des Strategieberaters (kurze Beschreibung des Strategieberaters/Advisors)",
                    rows: _vm.strategieBeraterFactsheet ? 10 : 1,
                    autoGrow: !_vm.strategieBeraterFactsheet,
                    validateUntouched: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({
                        strategieBeraterFactsheet: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.strategieBeraterFactsheet,
                    callback: function($$v) {
                      _vm.strategieBeraterFactsheet = $$v
                    },
                    expression: "strategieBeraterFactsheet"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    label:
                      "Portfoliokommentar zur Strategie (für Quartalsberichte und Factsheet)",
                    rows: _vm.portfolioKommentarFactsheet ? 10 : 1,
                    autoGrow: !_vm.portfolioKommentarFactsheet,
                    validateUntouched: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({
                        portfolioKommentarFactsheet: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.portfolioKommentarFactsheet,
                    callback: function($$v) {
                      _vm.portfolioKommentarFactsheet = $$v
                    },
                    expression: "portfolioKommentarFactsheet"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    rows: _vm.schlusssatzPortfoliokommentar ? 10 : 1,
                    autoGrow: !_vm.schlusssatzPortfoliokommentar,
                    disabled: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({
                        schlusssatzPortfoliokommentar: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.schlusssatzPortfoliokommentar,
                    callback: function($$v) {
                      _vm.schlusssatzPortfoliokommentar = $$v
                    },
                    expression: "schlusssatzPortfoliokommentar"
                  }
                })
              ],
              1
            )
          : _c("div", [
              _c("div", { staticClass: "label-title" }, [
                _vm._v("Marktkommentar zur Strategie:")
              ]),
              _c("div", [_vm._v(_vm._s(this.marktKommentarFactsheet))]),
              _c("div", { staticClass: "label-title" }, [
                _vm._v("Anlagestrategie:")
              ]),
              _c("div", [_vm._v(_vm._s(this.anlageStrategieFactsheet))]),
              _c("div", { staticClass: "label-title" }, [
                _vm._v("Strategieberater:")
              ]),
              _c("div", [_vm._v(_vm._s(this.strategieBeraterFactsheet))]),
              _c("div", { staticClass: "label-title" }, [
                _vm._v("Portfoliokommentar zur Strategie:")
              ]),
              _c("div", [_vm._v(_vm._s(this.portfolioKommentarFactsheet))]),
              _c("br"),
              _c("div", [_vm._v(_vm._s(this.schlusssatzPortfoliokommentar))])
            ])
      ]),
      _c(
        "BaseModal",
        {
          ref: "changeAllMarktkommentarModal",
          attrs: {
            modalTitle: "Marktkommentar bei allen Vermögensverwaltungen ändern",
            labelButtonConfirm: "Überschreiben"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveKommentarAll()
            }
          }
        },
        [
          _c("InputTextArea", {
            attrs: { autoGrow: true },
            model: {
              value: _vm.changeAllMarktkommentarText,
              callback: function($$v) {
                _vm.changeAllMarktkommentarText = $$v
              },
              expression: "changeAllMarktkommentarText"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }