<template>
    <div class="color-danger" v-if="webserviceWarnings && webserviceWarnings.length">
        <div class="color-danger box__title">Vorsicht, die folgenden Daten haben sich geändert:</div>
        <div v-for="(warn, index) of webserviceWarnings" :key="index">
        {{ warn.key }}: {{ warn.value }}
        </div>
        <a class="color-danger" @click="$emit('weitereWarnungen')" v-if="hasWeitereWarnungen">weitere Warnungen ...</a>
    </div>
</template>

<script>

export default {
  props: {
    webserviceWarnings: {
      default: () => [],
    },
    hasWeitereWarnungen: {
        default: false,
    }
  },

}
</script>