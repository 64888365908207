var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fonds_container" }, [
    _c(
      "div",
      { staticClass: "einzelfonds" },
      [
        !_vm.isIntern
          ? _c("fondsshop-element", { attrs: { zeige: "vv" } })
          : _vm._e(),
        _vm.isIntern
          ? _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
              _vm._v("Hier kommt der Fondsshopsanteil.")
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }