var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Unterlagen der FinanzAdmin")
      ]),
      _c("FormDivider"),
      _c("FormLabel", {
        attrs: {
          label:
            'Anforderung: Abwicklungsrichtlinien und Organisationshandbuch der FinanzAdmin im smartMSC. (Pfad: "Formulare" -> "weitere Unterlagen" -> "FinanzAdmin Wertpapierdienstleistungen GmbH")'
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          value: _vm.form.INPUT_UNTERLAGEN_BEST1,
          values: _vm.unterlagenBestValues,
          labelClass: "font-bold",
          disabled: _vm.disabledStatusOK
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged($event, "INPUT_UNTERLAGEN_BEST1")
          }
        }
      }),
      _c("div", { staticClass: "mt-4" }, [
        _c("a", { on: { click: _vm.openWertpapierdienstleistungen } }, [
          _vm._v("Abwicklungsrichtlinien und Organisationshandbuch")
        ])
      ]),
      _c("FormDivider"),
      _c("InputToggleSwitch", {
        attrs: {
          label:
            "Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben.",
          inLineLabel: true,
          config: { bold: true },
          isComponentHalfSize: "",
          disabled: _vm.disabledStatusOK,
          value: true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }