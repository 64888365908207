var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu,
          tid: "41b35e89-c184-4cdf-96f0-5360b8441d58"
        }
      }),
      _c(
        "DragnDropArea",
        {
          attrs: {
            hoverText: "Datei hier ablegen",
            disabled: _vm.draggingChildComponent || !_vm.allowVersUpload
          },
          on: {
            files: function($event) {
              return _vm.openAddDocumentModal($event)
            }
          }
        },
        [
          _c("CardOverview", {
            attrs: {
              id: "bafefe38-bef2-451c-bfd7-f4afe290ca3a",
              columns: _vm.columns,
              headerActions: _vm.headerActionsWithBearbeiten,
              noPrimaryAction: false,
              defaultOptionsMenu: _vm.customOptionsMenu
            },
            on: {
              executeAction: function($event) {
                return _vm.handleExecuteAction($event)
              },
              "action-BEARBEITEN": function($event) {
                return _vm.openMainStep()
              },
              "action-NEW-ACTIVITY": function($event) {
                return _vm.newAppointment()
              },
              "action-OLD-POLICE-NUMBERS": function($event) {
                return _vm.showOldPoliceNumbers()
              },
              "action-SCHADEN-MELDEN": function($event) {
                return _vm.createSchaden()
              },
              "action-EMAIL-KUNDE": function($event) {
                return _vm.emailStart(false)
              },
              "action-EMAIL-GESELLSCHAFT": function($event) {
                return _vm.emailStart(true)
              },
              "action-BRIEF-ERSTELLEN": function($event) {
                return _vm.briefStart()
              },
              "action-KUENDIGUNG-VERSICHERUNGEN": function($event) {
                return _vm.emailCancel()
              },
              "action-AUFGABE-HINZUFUEGEN": function($event) {
                return _vm.handleAddActivity()
              },
              "action-VERSICHERUNG-KOPIEREN": function($event) {
                return _vm.versicherungKopierenBestaetigung()
              },
              "action-DOKUMENTE-HOCHLADEN": function($event) {
                return _vm.dateiHochladen()
              },
              "action-EXTERNEN-ZUGANG-AUFRUFEN": function($event) {
                return _vm.externenZugangOeffnen()
              },
              "action-VERS-ADMIN-A": function($event) {
                return _vm.versAdminn("A")
              },
              "action-VERS-ADMIN-M": function($event) {
                return _vm.versAdminn("M")
              },
              draggingCard: function($event) {
                _vm.draggingChildComponent = true
              },
              droppedCard: function($event) {
                _vm.draggingChildComponent = false
              },
              remove: _vm.onRemoveCard,
              action: _vm.executeGenericAction
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _vm.isCustomerOnly
                      ? _c("span", [_vm._v(_vm._s(_vm.gesellschaftName))])
                      : _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.navigateToGesellschaft()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.gesellschaftName))]
                        )
                  ]
                },
                proxy: true
              },
              {
                key: "subtitle",
                fn: function() {
                  return [
                    _vm.bezeichnung
                      ? [
                          _c("span", { staticClass: "subtitle-part" }, [
                            _vm._v(_vm._s(_vm.bezeichnung))
                          ]),
                          _vm.nummer || _vm.statusAktuell || _vm.showKennzeichen
                            ? _c("span", [_vm._v(" | ")])
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm.nummer
                      ? [
                          _c("span", { staticClass: "subtitle-part" }, [
                            _vm._v(_vm._s(_vm.nummer))
                          ]),
                          _vm.statusAktuell || _vm.showKennzeichen
                            ? _c("span", [_vm._v(" | ")])
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm.statusAktuell
                      ? [
                          _c(
                            "span",
                            { staticClass: "subtitle-part" },
                            [
                              _c("Pill", {
                                attrs: {
                                  label: _vm.statusAktuell,
                                  type: _vm.statusToPillType[_vm.statusAktuell]
                                }
                              })
                            ],
                            1
                          ),
                          _vm.showKennzeichen
                            ? _c("span", [_vm._v(" | ")])
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm.showKennzeichen
                      ? [
                          _c("span", { staticClass: "subtitle-part" }, [
                            _vm._v(_vm._s(_vm.kennzeichen))
                          ])
                        ]
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c("EmailVersand"),
      _c("AufgabeBearbeitenModal", {
        ref: "modalAdd",
        attrs: {
          bereichId: _vm.$route.params.id,
          gesellschaftName: _vm.gesellschaftName,
          nummer: _vm.nummer,
          bezeichnung: _vm.bezeichnung,
          bereichText: _vm.bereichText
        }
      }),
      _c("AddDocument", {
        ref: "addDocumentModal",
        attrs: { nodeId: _vm.nodeId, versicherungId: _vm.$route.params.id },
        on: {
          close: function($event) {
            return _vm.goToDownloadArea(_vm.$route.params.id, true)
          }
        }
      }),
      _c("CopyInsuranceConfirmationModal", {
        ref: "copyInsuranceConfirmationModal",
        on: { "open-item": _vm.openItemSelectedDetail }
      }),
      _c("ShowOldPoliceNumbersModal", {
        ref: "showOldPoliceNumbersModal",
        on: { "open-item": _vm.openItemSelectedDetail }
      }),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: _vm.modalTitle,
            size: "lg",
            showCancelButton: false,
            showConfirmButton: false
          },
          on: {
            onCloseButton: function($event) {
              return _vm.saveBeforeLeave()
            }
          }
        },
        [
          _vm.editComponent == "allgemeine"
            ? _c("AllgemeineVertragsdaten", {
                ref: "allgemeine",
                attrs: {
                  canChangeVertragKundennr: _vm.canChangeVertragKundennr
                },
                on: {
                  changeStep: function($event) {
                    return _vm.setStepByKey($event)
                  }
                }
              })
            : _vm._e(),
          _vm.editComponent == "weitere-vertragsdetails"
            ? _c("WeitereVertragsdetails", { ref: "weitere-vertragsdetails" })
            : _vm._e(),
          _vm.editComponent == "schadensmeldungen"
            ? _c("InsuranceDetailSchadensmeldungenTable", {
                attrs: { schadenInfos: _vm.detailsData.schadenInfos }
              })
            : _vm._e(),
          _vm.editComponent == "courtage"
            ? _c("InsuranceAbrechnungDetails", {
                attrs: { editable: _vm.detailsData.insurance.allowNewBuchung }
              })
            : _vm._e(),
          _vm.editComponent == "fahrzeugDaten"
            ? _c("FahrzeugDatenEdit", {
                attrs: { versicherungId: _vm.$route.params.id }
              })
            : _vm._e(),
          _vm.editComponent == "sparteUbersicht"
            ? _c("SparteUbersicht")
            : _vm._e(),
          _vm.editComponent == "wertpapiere"
            ? _c("InsuranceDetailDepotPositionsTable", {
                attrs: { depotPositions: _vm.depotPositions }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.askOptions
        ? _c("ExterneZugangAskOptions", {
            attrs: {
              askOptions: _vm.askOptions,
              confirmButtonLabel: _vm.actionLabel,
              data: _vm.data,
              title: _vm.externeZugang.companyLabel
            },
            on: {
              close: function($event) {
                return _vm.resetExterneZugang()
              },
              onConfirmButton: function($event) {
                _vm.askOptionsConfirmed($event)
                _vm.navigateToVersicherungstoolsHome()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }