<template>
  <div class="kontoverwaltung-account-list" v-if="isUserAllowedToGetMultibankingData && filteredAccounts && accountDetails" :key="accounts && accounts.length">
    <KontoverwaltungAccountTable
        hasActions
        @action-EDIT_ACCOUNT="editAccount($event.account)"
        @action-MAKE_TRANSFER="transferRequest($event.account)"
        @action-GO_TO_TRANSACTIONS="goToTransactions($event.account)"
        @action-AUTHENTICATE_ACCOUNT="authenticateAccount($event.account)"
        @action-UPDATE_ACCOUNT_SELECTION="updateAccountSelection($event.account)"
    />

    <div class="box__container" v-if="showGhost">
      <GhostLoading>
        <Block type="title" width="25%" />
        <Block />
        <Block />
        <Block />
        <Block />
        <Block />
      </GhostLoading>
    </div>

    <div class="box__container" v-else-if="!accounts || !accounts.length">
      <NoData />
    </div>

    <BaseModal
      ref="editAccountModal"
      :showDefaultButtons="false"
      :actions="baseModalActions"
      @action-SAVE="saveEditingAccount()"
      @action-DELETE="askDeleteAccount()"
      @action-CLOSE="$refs.editAccountModal.close()">
      <template #modalTitle>
        {{ editingAccount.title }} {{ editingAccount.iban }}
      </template>
      <InputField 
        v-if="editingAccount"
        label="Bezeichnung ändern:" 
        v-model="editingAccount.displayName"/>
    </BaseModal>

    <BaseModal
      @onConfirmButton="deleteAccount"
      ref="deleteAccountModal">
      <template #modalTitle>
        {{ editingAccount.title }} {{ editingAccount.iban }}
      </template>
      
      <div v-if="editingAccount">
        <p>Soll das Konto <strong>{{ editingAccount.displayName }} (IBAN: {{ editingAccount.iban }})</strong>
          wirklich entfernt werden?</p>
        <p>Die Umsätze dieses Kontos werden nicht mehr in Ihren Auswertungen berücksichtigt!</p>
      </div>
    </BaseModal>

    <BaseModal
      :showCancelButton="false"
      modalTitle="Kontoaktualisierung"
      ref="dataWillBeUpdated"
      labelButtonConfirm="Schließen">
      Daten werden aktualisiert
    </BaseModal>

    <BaseModal
      :showCancelButton="false"
      ref="errorModal"
      modalTitle="Fehler"
      labelButtonConfirm="Schließen">
      {{errorMessage}}
    </BaseModal>

    <BanksAPIZugangWebcomponent ref="banksapiwebcomponent" :banksapiURL="banksapiURL" :modalTitle="banksAPIModalTitle"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import BALANCE_SHEET_TYPES from '@/store/balance-sheet/types';
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import KontoverwaltungAccountTable from '@/components/multiBanking/KontoverwaltungAccountTable.vue'
import BaseModal from '@/components/core/BaseModal.vue'
import InputField from '@/components/core/forms/InputField.vue'
import BaseButton from '@/components/core/BaseButton'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import NoData from '@/components/core/NoData.vue';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import BanksAPIZugangWebcomponent from '@/views/multiBanking/BanksAPIZugangWebcomponent.vue';

const KONTOVERWALTUNG_PATH = '/home/multibanking-account'

export default {
  components: {
    KontoverwaltungAccountTable,
    BaseModal,
    InputField,
    BaseButton,
    GhostLoading,
    Block,
    NoData,
    BanksAPIZugangWebcomponent,
  },
  watch: {
    userSettings() {
      this.shareAccounts = this.userSettings?.shareAccounts
    },
    accounts() {
      if (this.requestedAccountToAuthenticateAgain) {
        const accountFound = this.accounts.find((acc) => acc.externalId === this.requestedAccountToAuthenticateAgain.externalId);

        if (accountFound?.displayName) {
          this.requestedAccountToAuthenticateAgain = null;
        }
      }
    }
  },
  data() {
    return {
      editingAccount: null,
      errorMessage: null,
      shareAccounts: false,
      showExpanded: false,
      requestedAccountToAuthenticateAgain: null,
      requestedAccountToAuthenticateAgainMessage: null,
      banksAPIModalTitle: "Multibanking"
      
    }
  },
  props: {
    showAccounts: {
      type: String,
      default: ''
    },
    showGhost: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      parameters: MULTIBANKING_TYPES.GETTERS.PARAMETERS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      brokerData: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      userSettings: MULTIBANKING_TYPES.GETTERS.USER_SETTINGS,
      roles: CORE_TYPES.GETTERS.GET_USER_ROLES,
      accounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
      savingsPotentialByAccount: MULTIBANKING_TYPES.GETTERS.SAVINGS_POTENTIAL_BY_ACCOUNT,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      accountDetails: BALANCE_SHEET_TYPES.GETTERS.ACCOUNTS_DETAILS,
      loaded: MULTIBANKING_TYPES.GETTERS.LOADED_STATUS,
      banksapiURL: MULTIBANKING_TYPES.GETTERS.BANKSAPI_URL,
    }),
    accountTypeLabels() {
      return {
        DEPOT: 'Depots',
        BANKACCOUNT: 'Konten',
        CREDITCARD: 'Kreditkarten',
        OTHER: 'Weitere'
      }
    },
    filteredAccounts() {
      return this.accounts?.sort?.((a, b) => a.accountType === 'DEPOT' ? -1 : 1)
    },
    isUserAllowedToGetMultibankingData () {
      return this.roles?.includes('MULTIBANKING_DATA_ALLOWED')
    },
    showMultibankingActionButtons() {
      return !this.isBrokerOrBypass && this.isUserAllowedToGetMultibankingData
    },
    baseModalActions() {
      return [
        BaseModalSimpleAction('CLOSE', 'Abbrechen').withPrimary(() => false),
        BaseModalSimpleAction('DELETE', 'Konto entfernen').withPrimary(() => false),
        BaseModalSimpleAction('SAVE', 'Speichern').withPrimary(() => true),
      ];
    },
  },
  methods: {
    async transferRequest(account) {
      if (account?.externalId) {
        
        const callbackURL = window.location.origin + this.$route.path
        const payload = {
          externalId: account.externalId,
          callbackURL
        }
        await this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.GET_BANK_TRANSACTIONS_TRANSFER_REQUEST, payload)
        this.banksAPIModalTitle = "Multibanking - Überweisung"
        this.$refs.banksapiwebcomponent.open();
      }
    },
    goToTransactions(account) { 
      this.$router.push({ name: `balance-per-period`, query: { accountId: account.externalId } })
    },
    
    askDeleteAccount() {
      this.$refs.deleteAccountModal.open()
    },
    async deleteAccount() {
      await this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.HIDE_ACCOUNT, {
        externalId: this.editingAccount.externalId,
        providerId: this.editingAccount.providerId,
      })
      this.$refs.deleteAccountModal.close()
      this.$refs.editAccountModal.close()
    },
    async saveEditingAccount() {
      if (this.editingAccount) {
        await this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.UPDATE_ACCOUNT, {
          accountId: this.editingAccount.id,
          externalId: this.editingAccount.externalId,
          providerId: this.editingAccount.providerId,
          displayName: this.editingAccount.displayName,
        })

        this.$refs.editAccountModal.close();
      }
    },
    
    async authenticateAccount(account) {
        this.requestedAccountToAuthenticateAgain = account;
        const callbackPath = window.location.origin + this.$route.path;
        await this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.AUTHENTICATE_ACCOUNT, {
            callbackPath,
            providerId: account.providerId,
        })
        this.banksAPIModalTitle = "Multibanking - Account akualisieren";
        this.$refs.banksapiwebcomponent.open();
    },
    async updateAccountSelection(account) {
        this.requestedAccountToAuthenticateAgain = account
        const callbackPath = window.location.origin + this.$route.path

        await this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.UPDATE_SELECTED_ACCOUNTS, {
            callbackPath,
            providerId: account.providerId,
        })
        this.banksAPIModalTitle = "Multibanking - Kontenauswahl ändern";
        this.$refs.banksapiwebcomponent.open();
    },
    editAccount(account) {
      if (account) {
        this.editingAccount = account;
        this.$refs.editAccountModal.open()
      }
    },
  },
  mounted() {
    this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_ACCOUNTS_DETAILS)
    this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_ALL_MULTIBANKING_DATA)
  }
}
</script>
