import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    productUtilization: null,
    vertriebsInfo: null,
  }
}

export const PRODUCT_LABELS = {
  aif: 'AIF/Beteiligungen',
  versicherung: 'Versicherungen',
  investment: 'Investment',
  wk: 'WealthKonzept',
  finkonzept: 'FinKonzept',
  isSaas: 'SaaS',
}

export const PRODUCT_ORDER = [
  'versicherung',
  'investment',
  'wk',
  'finkonzept',
  'aif',
  'isSaas',
]

export const ORGANIZATION_TYPES = {
  VERTRIEBSORGANISATION: 'Vertriebsorganisation (Vo)',
  EINZELMAKLER: 'Einzelmakler',
  SOZIETAET: 'Sozietät',
}

export const ORGANIZATION_TYPES_LABEL_VALUE = Object.entries(ORGANIZATION_TYPES)
  .map(([value, label]) => ({ value, label }))

export default {
  state: {
    ...getInitialState(),
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
}
