var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { key: _vm.formKey, staticClass: "contact_container" }, [
    _c("div", { staticClass: "contact_wrapper" }, [
      _c("div", { staticClass: "banner" }, [_vm._v("IHR KONTAKT ZU UNS")]),
      _c("hr"),
      _c("div", { staticClass: "zweispalte" }, [
        _c("div", [
          _c("div", { staticClass: "question_title" }, [
            _vm._v("Sie haben Fragen oder wünschen eine persönliche Beratung?")
          ]),
          _c("div", { staticClass: "zweifields" }, [
            _c(
              "div",
              [
                _c("InputField", {
                  ref: "nachnameInp",
                  attrs: { label: "Name: " },
                  model: {
                    value: _vm.credentials.nachname,
                    callback: function($$v) {
                      _vm.$set(_vm.credentials, "nachname", $$v)
                    },
                    expression: "credentials.nachname"
                  }
                }),
                _c("InputField", {
                  ref: "vornameInp",
                  attrs: { label: "Vorname: " },
                  model: {
                    value: _vm.credentials.vorname,
                    callback: function($$v) {
                      _vm.$set(_vm.credentials, "vorname", $$v)
                    },
                    expression: "credentials.vorname"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c("InputField", {
                  ref: "emailInp",
                  attrs: { label: "E-Mail: " },
                  model: {
                    value: _vm.credentials.email,
                    callback: function($$v) {
                      _vm.$set(_vm.credentials, "email", $$v)
                    },
                    expression: "credentials.email"
                  }
                }),
                _c("InputField", {
                  ref: "telefonInp",
                  attrs: { label: "Telefon: ", inputmode: "tel" },
                  model: {
                    value: _vm.credentials.telefon,
                    callback: function($$v) {
                      _vm.$set(_vm.credentials, "telefon", $$v)
                    },
                    expression: "credentials.telefon"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            [
              _c("div", { staticClass: "nachricht_label" }, [
                _vm._v("Nachricht:")
              ]),
              _c("InputTextArea", {
                attrs: { rows: 3 },
                model: {
                  value: _vm.content.inhalt,
                  callback: function($$v) {
                    _vm.$set(_vm.content, "inhalt", $$v)
                  },
                  expression: "content.inhalt"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "zweifields mt-3" },
            [
              _c("CaptchaInputField", {
                model: {
                  value: _vm.credentials.captcha,
                  callback: function($$v) {
                    _vm.$set(_vm.credentials, "captcha", $$v)
                  },
                  expression: "credentials.captcha"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "checkbox_plus_label" },
            [
              _c("InputCheckBoxItem", {
                model: {
                  value: _vm.content.readDatenschutz,
                  callback: function($$v) {
                    _vm.$set(_vm.content, "readDatenschutz", $$v)
                  },
                  expression: "content.readDatenschutz"
                }
              }),
              _c("span", {
                staticStyle: { display: "inline-block", width: ".3em" }
              }),
              _c(
                "div",
                [
                  _vm._v(" Ich stimme den "),
                  _c("router-link", { attrs: { to: "datenschutz" } }, [
                    _vm._v("Datenschutzbestimmungen")
                  ]),
                  _vm._v(" zu. ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "to_the_left" },
            [
              _c(
                "BaseButton",
                {
                  attrs: {
                    disabled: _vm.sending || _vm.isFormInvalid,
                    animated: _vm.sending
                  },
                  on: { click: _vm.sendKontakt }
                },
                [_vm._v("Absenden")]
              ),
              _vm.isIntern
                ? _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
                    _vm._v("Die Sendung ist für die Intern deaktiviert.")
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _c("div", { staticClass: "makler_info" }, [_c("MaklerInfo")], 1)
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }