var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isFA
      ? _c(
          "div",
          [
            _c("InputToggleSwitch", {
              attrs: {
                label: "Arbeitnehmersparzulage",
                inLineLabel: true,
                disabled: _vm.disabled
              },
              on: {
                input: function($event) {
                  return _vm.updateStore("employeeSavingAllowance", $event)
                }
              },
              model: {
                value: _vm.form.employeeSavingAllowance,
                callback: function($$v) {
                  _vm.$set(_vm.form, "employeeSavingAllowance", $$v)
                },
                expression: "form.employeeSavingAllowance"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label: "Riester-Rente",
                inLineLabel: true,
                disabled: _vm.disabled
              },
              on: {
                input: function($event) {
                  return _vm.updateStore("riesterPension", $event)
                }
              },
              model: {
                value: _vm.form.riesterPension,
                callback: function($$v) {
                  _vm.$set(_vm.form, "riesterPension", $$v)
                },
                expression: "form.riesterPension"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label: "Rürup-Rente",
                inLineLabel: true,
                disabled: _vm.disabled
              },
              on: {
                input: function($event) {
                  return _vm.updateStore("ruerupPension", $event)
                }
              },
              model: {
                value: _vm.form.ruerupPension,
                callback: function($$v) {
                  _vm.$set(_vm.form, "ruerupPension", $$v)
                },
                expression: "form.ruerupPension"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label: "Wohnungsbauprämie",
                inLineLabel: true,
                disabled: _vm.disabled
              },
              on: {
                input: function($event) {
                  return _vm.updateStore("buildingSubsidy", $event)
                }
              },
              model: {
                value: _vm.form.buildingSubsidy,
                callback: function($$v) {
                  _vm.$set(_vm.form, "buildingSubsidy", $$v)
                },
                expression: "form.buildingSubsidy"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label: "bAV",
                inLineLabel: true,
                disabled: _vm.disabled
              },
              on: {
                input: function($event) {
                  return _vm.updateStore("fedInsuranceComm", $event)
                }
              },
              model: {
                value: _vm.form.fedInsuranceComm,
                callback: function($$v) {
                  _vm.$set(_vm.form, "fedInsuranceComm", $$v)
                },
                expression: "form.fedInsuranceComm"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.isAnlegerprofil
      ? _c(
          "div",
          [
            !_vm.isWealthKonzept
              ? _c("InputField", {
                  attrs: {
                    label:
                      "Wieviele Personen sind während des Anlagezeitraumes auf Ihre finanzielle Unterstützung angewiesen (z.B. nichtarbeitende Ehe-/Lebenspartner, Kinder, zu pflegende Angehörige etc.)?",
                    isComponentHalfSize: "",
                    disabled: _vm.disabled
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore("numberPerson", $event)
                    }
                  },
                  model: {
                    value: _vm.form.numberPerson,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "numberPerson", $$v)
                    },
                    expression: "form.numberPerson"
                  }
                })
              : _vm._e(),
            !_vm.isWealthKonzept
              ? _c("InputField", {
                  attrs: {
                    label:
                      "Bei Minderjährigen: Der Anlagebetrag stammt aus einer Zuwendung / Schenkung von (z.B. Eltern, Großeltern, Tante):",
                    isComponentHalfSize: "",
                    disabled: _vm.disabled
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore("carePerson", $event)
                    }
                  },
                  model: {
                    value: _vm.form.carePerson,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "carePerson", $$v)
                    },
                    expression: "form.carePerson"
                  }
                })
              : _vm._e(),
            _c("InputTextArea", {
              attrs: {
                label: "Zusätzliche Angaben zu den finanziellen Verhältnissen",
                isComponentHalfSize: "",
                disabled: _vm.disabled
              },
              on: {
                change: function($event) {
                  return _vm.updateStore("annotationFinance", $event)
                }
              },
              model: {
                value: _vm.form.annotationFinance,
                callback: function($$v) {
                  _vm.$set(_vm.form, "annotationFinance", $$v)
                },
                expression: "form.annotationFinance"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }