import DEPOTPOSITIONS_TYPES from './types';

import { ALL_DEPOTS, NICHT_ZUGEORDNET_DEPOT, WERTPAPIER_VERSICHERUNG_DEPOT, VORSORGE_DEPOT, } from '@/components/depotpositions/types';

export default {
  [DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS](state) {
    return state.depotpositions;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CHARTS](state) {
    return state.depotpositionsCharts;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CONFIG](state) {
    return state.depotpositionsConfig;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_ESG_CHART](state) {
    return state.depotpositionsESGChart;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_TRANSACTIONS](state) {
    return state.transactions;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_FONDSINFO](state) {
    return state.fondsinfo;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.LIMITS](state) {
    return state.limits;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.LIMITS_200](state) {
    return state.limits200;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID](state) {
    return state.id;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_DEPOT_TITLE](state, getters) {
    const { id, } = state;

    if(!id || id === ALL_DEPOTS) return 'Depotpositionen aller Depots';
    if(id === NICHT_ZUGEORDNET_DEPOT) return 'Nicht zugeordnete Positionen';
    if(id === WERTPAPIER_VERSICHERUNG_DEPOT) return 'Versicherungsvermögen';
    if(id === VORSORGE_DEPOT) return 'Wertpapiere Vorsorge';

    const config = getters[DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CONFIG];

    const selected = config?.depotFiltr?.find(item => item.key === id);
 
    return selected?.value || '';
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_CHART_WIDGET](state) {
    return state.selectedChartWidget;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_PDF_OPTIONS](state) {
    return { ...state.depotPdfOptions };
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_OUT_OF_LIMITS](state) {
    return [ ...state.depotpositionsOutOfLimits || [] ];
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.BROKER_CHANGES](state) {
    return [ ...state.brokerChanges || [] ];
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.GET_SHOW_COURTAGE](state) {
    return state.showCourtage;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.GET_CALC_COURTAGE](state) {
    return state.calcCourtage;
  },
  [DEPOTPOSITIONS_TYPES.GETTERS.GET_DEPOTPOSITIONS_GUV](state) {
    return state.depotGuV;
  },
}