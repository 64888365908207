import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { ALL_DEPOTS } from '../../components/depotpositions/types';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */

export function getInitialState() {
  return {
    depotpositions: {},
    depotpositionsConfigHash: null,
    depotpositionsCharts: {},
    depotpositionsConfig: {},
    depotpositionsESGChart: {},
    id: ALL_DEPOTS,
    transactions: {},
    fondsinfo: {},
    limits: {},
    limits200: {},
    selectedChartWidget: '',
    depotPdfOptions: {},
    depotpositionsOutOfLimits: [],
    brokerChanges: [],
    showCourtage: 0,
    calcCourtage: {},
    depotGuV: {}
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}