<template>
  <div>
    <div id="wrapper">
      <!-- <LineChart xAxisType="category" :chartSeries="series()" :xLabels="xLabels" :markAreaData="markAreaData"
        :markPointData="markPointData" :isTooltip="false" :isArea="false" height="370px" /> -->
      <ApexChart :series="seriesApex" :options="optionsApex" height="370px" />
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/echarts/LineChart.vue';
import { getColorsMixedContrastDecreasing } from '@/helpers/colors-helper';
import { getActiveColorScheme, ColorSchemeChangedEvent } from '@/configs/color-config';
import { formatCompactNumber } from '@/helpers/number-formatter';

export const chartColorScheme = getColorsMixedContrastDecreasing();

export default {
  props: {
    data: {
      type: Object,
      default: () => { },
      required: true,
    },
  },
  data() {
    return {
        themeKey: getActiveColorScheme(),
        themeColors: getColorsMixedContrastDecreasing(),
    };
  },

  methods: {
    getColor(index) {
      return chartColorScheme[index % chartColorScheme.length];
    },
    // series() {
    //   return this.data.series.map((serie, i) => ({
    //     name: 'Euro',
    //     data: serie.data || [],
    //     ...(i == 0 ? { areaStyle: {} } : {})
    //   }));
    // },
    handleColorSchemeChanged() {
      this.$nextTick(() => requestAnimationFrame(() => {
        this.themeKey = getActiveColorScheme();
        this.themeColors = getColorsMixedContrastDecreasing();
      }));
    },
  },
  computed: {
    xLabels() {
      return this.data?.xaxis?.categories || [];
    },
    markAreaData() {
      return (this.data?.annotations?.xaxis || []).map(annotation => [
        {
          name: annotation.label?.text || '',
          xAxis: `${annotation.x}`,
          itemStyle: {
            borderColor: annotation.label?.borderColor,
          },
          label: {
            color: annotation.label?.style?.color,
            backgroundColor: annotation.label?.style?.background,
          },
        },
        {
          xAxis: `${annotation.x}`,
        }
      ]);
    },
    markPointData() {
      return (this.data?.annotations?.points || []).map(point => ({
        coord: [point.x - 1, point.y],
        itemStyle: {
          color: point.marker.fillColor,
          borderWith: 1,
          borderColor: point.marker.strokeColor,
        },
      }));
    },
    seriesApex() {
      return this.data.series.map((serie, i) => ({
        name: 'Euro',
        data: serie.data.map((item, j) => ({
          x: this.data.xaxis.categories[j],
          y: item
        })) || [],

      }))
    },
    optionsApex() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          type: 'area',
          height: 370,
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 1000
          },
          events: {
            click: function(event, chartContext, seriesIndex) {
              console.log(chartContext, seriesIndex);
            },
          }
        },
        annotations: {
          ...this.data.annotations,
      },
        markers: {
          ...this.data.markers
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },

        title: {
          text: 'Vermögensverlauf',
          align: 'left',
          style: {
            color: 'var(--color-text)',
            fontSize: '14px'
          }
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
            colors: 'var(--color-text)',
            fontSize: '10px'
          },
            formatter: function (value) {
              return Math.floor(value);
            }
          },
          tickAmount: 12,
        },
        yaxis: {
          tickAmount: 4,
          floating: false,

          labels: {
            style: {
            colors: 'var(--color-text)',
            fontSize: '10px'
          },
            offsetY: 0,
            offsetX: 0,
            formatter: formatCompactNumber
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.5
        },
        tooltip: {
          x: {
            format: "yyyy",
          },
          fixed: {
            enabled: false,
            position: 'topRight'
          },
          theme:  this.themeKey,
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30
            }
          },
          padding: {
            left: 20
          }
        }
      }
    }
  },
  components: {
    LineChart,
  },
  mounted() {
    window.addEventListener(ColorSchemeChangedEvent.name, this.handleColorSchemeChanged)
  },
  destroyed() {
    window.removeEventListener(ColorSchemeChangedEvent.name, this.handleColorSchemeChanged)
  },
}
</script>

<style scoped>
</style>
