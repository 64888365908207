<template>
  <div>
    <GesellschaftTitleNavigation
      :headerActions="headerActions"
      @action-CREATE_ACTIVITY="editOrCreateAppointment"
    />

    <div class="box__container">
      <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
      <Table 
        v-else
        rowId="rowId"
        :title="TABLE_TITLE"
        :rows="rows"
        :headers="headers"
        :rowsPerPage="20"
        @click-subject="editOrCreateAppointment"
        @action-DELETE="deleteRow"
      >

        <template v-slot:participants="row">
          <ul style="padding: 0; margin: 0">
            <li style="display:inline-block;" v-for="(participant, index) in row.participants" :key="participant.id">
              <span>{{participant.name}}</span>
              <span v-if="index < row.participants.length - 1">;&nbsp;</span>
            </li>
          </ul>
        </template>

      </Table>
    </div>
  </div>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import CALENDAR_TYPES from "@/store/calendar/types";
import COMMUNICATION_TYPES from '@/store/communication/types';

import { mapGetters } from 'vuex';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import { TextColumn, DateColumn, PillColumn, SlotColumn, ActionColumn, ConfirmedAction } from "@/components/table2/table_util.js";
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'Aufgaben';

export default {
  mixins: [],
  components: {
    GesellschaftTitleNavigation,
    GhostLoading,
    Table,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    headers() {
      return {
        lockedLeft: [
          DateColumn("date", "Zeitpunkt"),
          PillColumn("wvStatus", ""),
          TextColumn("subject", "Betreff").makeLink(),
        ],
        center: [
          TextColumn("name", "Besitzer", 250),
          SlotColumn("participants", "Teilnehmer", 80, 1),
          TextColumn("text", "Text", 400).addCellProps({lineClamp: 4}),
          TextColumn("art", "Art"),
          DateColumn("dateCreated", "Erstellungsdatum"),
          // CurrencyColumn("geschaeftwert", "Geschätzter Geschäftswert"),
          // TextColumn("produkt", "Produkt"),
          // TextColumn("verkaufphase", "Verkaufphase"),
          TextColumn("status", "Status"),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ]
      }
    },
    rows() {
      return (this.gesellschaft.aktivitaeten || []).map(record => ({
          ...record,
          wvStatus: this.getWVStatus(record.wvStatus),
          rowId: ('' + record.commId + record.date),
          subject: record.subject || "[kein Betreff]",
          actions: this.makeActions(record)
      }))
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('CREATE_ACTIVITY', `Neue Aufgabe`),
      ]
    },
  },
  mounted() {
      this.loading = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_AKTIVITAETEN, { gesellschaftId: this.$route.params.gesellId })
      .finally(() => this.loading = false);
  },
  methods: {
    editOrCreateAppointment(row) {

      if (row?.commId) {
        this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, row.commId);
      } else {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: true});
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_ACTIVITY);
      }

      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: row?.commId ? 'Aufgabe bearbeiten' : 'Aufgabe erstellen',
        isActivity: true,
        bereich: 'Gesellschaft',
        bereichId: this.$route.params.gesellId,
        back: this.$route.fullPath,
      })

      this.$addBreadcrumb({
        fullPath: this.$route.fullPath,
        breadcrumb: 'Aufgaben',
      });

      // is needed to reload the table if something was edited
      this.$store.commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_AKTIVITAETEN_SUCCESS, undefined)

      this.$router.push({ path: `/communication/appointment` });
    },
    makeActions(row) {
      return [
        ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll die Aufgabe "${row.subject || "[kein Betreff]"}" wirklich gelöscht werden?`, `Aufgabe löschen`, "Löschen")
      ]
    },
    deleteRow(row) {
      this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.DELETE_FROM_POSTFACH, {id: row.commId, url: 'termin'}).then(() => 
        this.gesellschaft.aktivitaeten = this.gesellschaft.aktivitaeten.filter(act => act.commId !== row.commId)
      )
    },
    getWVStatus(value) {
      switch (value) {
        case 'green':
          return {
            label: 'erledigt', 
            type: 'success',
          }
        case 'blue':
          return {
            label: 'wird versendet', 
            type: 'info',
          }
        case 'yellow':
          return {
            label: 'Handlung notwendig', 
            type: 'warning',
          };
        default:
          return '';
      }
    },
  },
}
</script>