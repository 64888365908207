<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
        
        <PageHeaderTitleNavigation 
        :id="$appNavigation.currentOptionMenuId"
        :title="$appNavigation.currentMenu.label">
         </PageHeaderTitleNavigation>

        <BaseFilter
        ref="filter"
        filterId="940c19f6-8505-4ce1-967a-2bc47c649f22"
        :configFilter="configFilter"
        :metadata="menuMetadaData"
        :defaultOptions="initialFilters"
        :immidiateSearch="isCustomer"
        @onFilter="handleSearch"
        showSaveButton
        />
        
        <div class="box__container">
            <GhostLoading
                v-if="loading"
                type="table"
                title="Schadensuche"
            />

            <Table
                v-else-if="rows && rows.length"
                tableId="7e9b1dc1-8784-4a95-b43a-df2649bc32a2"
                ref="tableResult"
                title="Schadensuche"
                :headers="computedHeaders"
                :rows="rows"
                :rowsPerPage="rowsPerPage"
                :thHoverDisabled="true"
                :mobileConfig="{title: 'gesellschaftName', headers: ['name', 'schadennr', 'meldedat']}"
                @click-kundennr="openCustomerNewTab"
                @click-schadennr="openSchaden"
                @click-polNr="openInsurance"
                @click-vermittlernummer="openBroker"
                @click-vermittlerName="openBroker"
                @click-gesellschaftName="openGesellschaft"
                @action-SCHADEN-OEFFNEN="openSchaden"
                @action-VERTRAG-OEFFNEN="openInsurance"
                >
            
             </Table>

             <NoData v-else />
        
        </div>

    </div>
    
</template>

<script>
import { mapGetters } from "vuex";
import LIST_SCHADEN_TYPES from "@/store/listSchaden/types";
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import Table from '@/components/table2/Table.vue';
import {TextColumn, DateColumn, ActionColumn, CurrencyColumn, SimpleAction } from "@/components/table2/table_util.js";
import { VIEW_ROLES } from "@/router/roles";
import CORE_TYPES from "@/store/core/types";
import BaseFilter from "@/components/core/BaseFilter.vue";
import dayjs from "dayjs";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import NoData from '@/components/core/NoData.vue';
import VERSICHERUNG_TYPES from '@/store/versicherungen/types';
import {PhPencilLine, } from 'phosphor-vue';

const DATE_FORMAT = 'DD.MM.YYYY';

  export default {
    data() {
        return {
            loading: false,
            rowsPerPage: 20,
            configFilter: {
                defaultSearchInputKeys: ['SCHADEN_NR', 'KUNDENNR', 'SPARTE', 'GESELLSCHAFT', 'VSNR', 'MELDE_DATUM'],
            },
        };
    },
    components: {
        Table,
        GhostLoading,
        BaseFilter,
        OptionMenu,
        PageHeaderTitleNavigation,
        NoData,
        PhPencilLine,
    },    
    computed: {
        ...mapGetters({
            schadensmeldungen: LIST_SCHADEN_TYPES.GETTERS.LIST_SCHADEN,
            isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
            schadenBearbstandValues: SCHADENSMELDUNG_TYPES.GETTERS.SCHADEN_BEARBSTAND_VALUES,
        }),
        initialFilters() {
            const today = new Date();
            var maxDate = dayjs(today).format("DD.MM.YYYY");
            var minDate = dayjs(today)
                .subtract(3, "month")
                .format("DD.MM.YYYY");
            let filters = {
                von30Tage: [
                { key: "min", value: minDate },
                { key: "max", value: maxDate },
                ],
            };

            return filters;
        },        
        rows() {
            if (!this.schadensmeldungen?.length) {
             return [];
            }

            const defaultActions = [
                SimpleAction('VERTRAG-OEFFNEN', 'PhPencilLine', 'Vertrag öffnen'),
                SimpleAction('SCHADEN-OEFFNEN', 'PhPencilLine', 'Schaden öffnen')
            ];

            return this.schadensmeldungen.map(row => ({
                ...row,
                bearbstand: this.findBearbstandValue(row.bearbstand),
                actions: defaultActions,
            }));
        },
        computedHeaders() {
            if (this.isIntern && !this.isViewCustomer) {
                return this.headersForIntern;
            }
            if (this.isBroker && !this.isViewCustomer) {
                return this.headersForBroker;
            }
            return this.headers;
        },
        headersForIntern() {
            const columns = {
                lockedLeft: [
                    TextColumn("name", "Kundenname (Nachname)"),
                    TextColumn("vorname", "Kundenname (Vorname)"),
                    TextColumn("kundennr", "Kundennummer").makeLink(),
                    TextColumn("schadennr", "Schadensnummer").makeLink(),
                    TextColumn("sparte", "Sparte"),
                    TextColumn("gesellschaftName", "Gesellschaft").makeLink(),
                    TextColumn("polNr", "VSNR").makeLink(),
                    TextColumn("schadurstxt", "Schadensursache").addCellProps({ lineClamp: 2, }),
                    DateColumn("ereignisdat", "Ereignisdatum"),
                    TextColumn("ereignisUhrzeit", "Uhrzeit"),
                    DateColumn("meldedat", "Meldedatum"),
                    TextColumn("bearbstand", "Bearbeitungsstand"),
                    CurrencyColumn("leistungGeschaetzt", "Schadenhöhe (ca.)"),
                    TextColumn("schadenKommentar", "Schadennotizen Vermittler").addCellProps({ lineClamp: 2, }),
                    TextColumn("documenteVorhandenCalculated", "Uhrzeit"),
                ],
                center: [
                    TextColumn("adresse", "Adresse").addCellProps({ lineClamp: 2, }),
                    TextColumn("schadenOrt", "Schadenort"),
                    TextColumn("vermittlerName", "Vermittlername").makeLink(),
                    TextColumn("vermittlernummer", "Vermittlernummer").makeLink(),
                    TextColumn("schadensReserve", "Schadensreserve"),
                    TextColumn("betreuer", "Betreuer"),
                    TextColumn("geschaedigter", "Geschädigter"),
                    TextColumn("kennzeichen", "Kennzeichen"),
                ],
                lockedRight: [ActionColumn("actions", "")],
            };

            return columns;
        },
        headersForBroker() {
            const columns = {
                lockedLeft: [
                    TextColumn("name", "Kundenname (Nachname)"),
                    TextColumn("vorname", "Kundenname (Vorname)"),
                    TextColumn("kundennr", "Kundennummer").makeLink(),
                    TextColumn("schadennr", "Schadensnummer").makeLink(),
                    TextColumn("sparte", "Sparte"),
                    TextColumn("gesellschaftName", "Gesellschaft").makeLink(),
                    TextColumn("polNr", "VSNR").makeLink(),
                    TextColumn("schadurstxt", "Schadensursache").addCellProps({ lineClamp: 2, }),
                    DateColumn("ereignisdat", "Ereignisdatum"),
                    TextColumn("ereignisUhrzeit", "Uhrzeit"),
                    DateColumn("meldedat", "Meldedatum"),
                    TextColumn("bearbstand", "Bearbeitungsstand"),
                    CurrencyColumn("leistungGeschaetzt", "Schadenhöhe (ca.)"),
                    TextColumn("schadenKommentar", "Schadennotizen Vermittler").addCellProps({ lineClamp: 2, }),
                    TextColumn("documenteVorhandenCalculated", "Uhrzeit"),
                ],
                center: [
                    TextColumn("adresse", "Adresse").addCellProps({ lineClamp: 2, }),
                    TextColumn("schadenOrt", "Schadenort"),
                    TextColumn("vermittlerName", "Vermittlername"),
                    TextColumn("vermittlernummer", "Vermittlernummer"),
                    TextColumn("schadensReserve", "Schadensreserve"),
                    TextColumn("betreuer", "Betreuer"),
                    TextColumn("geschaedigter", "Geschädigter"),
                    TextColumn("kennzeichen", "Kennzeichen"),
                ],
                lockedRight: [ActionColumn("actions", "")],
            };

            return columns;
        },        
        headers() {
            const columns = {
                lockedLeft: [
                    TextColumn("name", "Kundenname (Nachname)"),
                    TextColumn("vorname", "Kundenname (Vorname)"),
                    TextColumn("kundennr", "Kundennummer"),
                    TextColumn("schadennr", "Schadensnummer").makeLink(),
                    TextColumn("sparte", "Sparte"),
                    TextColumn("gesellschaftName", "Gesellschaft").makeLink(),
                    TextColumn("polNr", "VSNR").makeLink(),
                    TextColumn("schadurstxt", "Schadensursache").addCellProps({ lineClamp: 2, }),
                    DateColumn("ereignisdat", "Ereignisdatum"),
                    TextColumn("ereignisUhrzeit", "Uhrzeit"),
                    DateColumn("meldedat", "Meldedatum"),
                    TextColumn("bearbstand", "Bearbeitungsstand"),
                    CurrencyColumn("leistungGeschaetzt", "Schadenhöhe (ca.)"),
                    TextColumn("schadenKommentar", "Schadennotizen Vermittler").addCellProps({ lineClamp: 2, }),
                    TextColumn("documenteVorhandenCalculated", "Uhrzeit"),
                ],
                center: [
                    TextColumn("adresse", "Adresse").addCellProps({ lineClamp: 2, }),
                    TextColumn("schadenOrt", "Schadenort"),
                    TextColumn("vermittlerName", "Vermittlername"),
                    TextColumn("vermittlernummer", "Vermittlernummer"),
                    TextColumn("schadensReserve", "Schadensreserve"),
                    TextColumn("betreuer", "Betreuer"),
                    TextColumn("geschaedigter", "Geschädigter"),
                    TextColumn("kennzeichen", "Kennzeichen"),
                ],
                lockedRight: [ActionColumn("actions", "")],
            };

            return columns;
        },
        isInternOrByPass(){
            return this.$hasRoles([
                [VIEW_ROLES.VIEW_INTERN],
                [VIEW_ROLES.VIEW_BROKER],
                [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER],
                [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN],
            ]);
        },
        isBroker() {
            return this.$hasRoles([
                    [VIEW_ROLES.VIEW_BROKER],
                    [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER],
                ]);
        },
        isBypassedBroker() {
            return this.$hasRoles([
                [VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
            ]);
        },
        isIntern() {
            return this.$hasRoles([
                [VIEW_ROLES.VIEW_INTERN],
                [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN],
            ]);
        },
        isViewCustomer() {
            return this.$hasRoles([VIEW_ROLES.VIEW_CUSTOMER]);
        },
        menuItemsAllgemein() {
            const items = [
                {
                    type: "text",
                    label: "Schadensnummer",
                    key: "SCHADEN_NR",
                    dataType: 'number',
                    inputmode: 'numeric',
                },
                {
                    type: "text",
                    label: "Sparte",
                    key: "SPARTE",
                },
                {
                    type: "text",
                    label: "Gesellschaft",
                    key: "GESELLSCHAFT",
                },
                {
                    type: "text",
                    label: "Nachname",
                    key: "NACHNAME",
                },
                {
                    type: "text",
                    label: "Vorname",
                    key: "VORNAME",
                },                                  
                {
                    type: "text",
                    label: "VSNR",
                    key: "VSNR",
                },
            ];
            if (this.isInternOrByPass) {
                items.push({
                    type: "text",
                    label: "Kundennummer",
                    key: "KUNDENNR",
                });
            }
            if (this.isBroker || this.isBypassedBroker) {
                items.push({
                    type: "default",
                    label: "inkl. Schäden der Struktur",
                    key: "INKL_SCHAEDEN_STRUKTUR"
                });
            }            
            return items;
        },
        menuMetadaData() {
            return [
                {
                type: "group",
                key: "allgemein",
                label: "Allgemein",
                menuItems: this.menuItemsAllgemein,
                },
                {
                type: "group",
                key: "MELDE_DATUM",
                label: "Meldedatum",
                menuItems: [
                    {
                        type: "default",
                        key: "von7Tage",
                        label: "7 Tage",
                    },            {
                        type: "default",
                        key: "von14Tage",
                        label: "14 Tage",
                    },            
                    {
                        type: "default",
                        key: "von30Tage",
                        label: "30 Tage",
                    },
                    {
                        type: "default",
                        key: "von60Tage",
                        label: "60 Tage",
                    },   
                    {
                        type: "default",
                        key: "von90Tage",
                        label: "90 Tage",
                    },   
                    {
                        type: "default",
                        key: "von180Tage",
                        label: "180 Tage",
                    },                                
                    {
                        type: "dateRange",
                        key: "zwischen",
                        label: "Zwischen",
                    },
                ],
                },
            ];
         },        
    },
    methods: {
        async retrieveSchadensBearbStandValues() {
            await this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.RETRIEVE_SCHADEN_BEARBSTAND_VALUES);
        },        
        findBearbstandValue(bearbstand){
            let res = this.schadenBearbstandValues.filter(sbv=>sbv.value == bearbstand).map(sbv=>sbv.label);
            return res.length ? res[0] : bearbstand;
        },        
        openCustomerNewTab(row) {
            this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.VALIDATE_CUSTOMER, { kundennr: row?.kundennr }).then(() => {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: row?.kundennr });
            });
        },
        openPathNewTab({customerId, kundennr}, path) {
            if (customerId || kundennr && this.canOpenCustomerNewTab) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: customerId || kundennr, path })
            }
        },              
        openInsuranceNewTab(row) {
            let path = `/home/versicherungen/insurance-group/overview/${row?.id}`;

            this.openPathNewTab({customerId: row?.kundennr, kundennr: row?.kundennr}, path);
        },
        openSchadenNewTab(row) {
            this.openPathNewTab({customerId: row?.kundennr, kundennr: row?.kundennr}, `/home/versicherungen/insurance-group/schadensmeldung/${row?.id}/${row?.schadenId}`);
        },
        openBroker({row}) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId: row?.vermittlernummer })
        },
        openInsuranceSameTab(row) {
            this.$router.push({path: `/home/versicherungen/insurance-group/overview/${row?.id}`});
        },
        openSchadenSameTab(row) {
            this.$router.push({path: `/home/versicherungen/insurance-group/schadensmeldung/${row?.id}/${row?.schadenId}`});
        },
        openSchaden(row) {
            if (this.isViewCustomer) {
                this.openSchadenSameTab(row);
            } else {
                this.openSchadenNewTab(row);
            }
        },
        openInsurance(row) {
            if (this.isViewCustomer) {
                this.openInsuranceSameTab(row);
            } else {
                this.openInsuranceNewTab(row);
            }
        },
        openGesellschaft(row) {
            if (this.isViewCustomer) {
                this.openGesellschaftSameTab(row);
            } else {
                this.openGesellschaftNewTab(row);
            }
        },
        openGesellschaftNewTab(row) {
            let path = `/service/gesellschaften/VERSICHERUNGEN/${row?.gesellschaftId}/ansprechpartnerList?gesellArt=VERSICHERUNGEN`;
            this.openPathNewTab({customerId: row?.kundennr, kundennr: row?.kundennr}, path);
        },
        openGesellschaftSameTab(row) {
            let path = `/service/gesellschaften/VERSICHERUNGEN/${row?.gesellschaftId}/ansprechpartnerList?gesellArt=VERSICHERUNGEN`;
            this.$router.push({path});
        },        
        async retrieveListSchaden(payload) {
            this.loading = true;
            await this.$store.dispatch(LIST_SCHADEN_TYPES.ACTIONS.RETRIEVE_SCHADENSMELDUNGEN, payload);
            this.loading = false;
        },
        async handleSearch(filterParams) {

            const filterConfig = filterParams.reduce((acc, curr) => ({...acc, [curr.key] : curr.value}), {})

            let dateFromStr = null;
            let dateEndStr = null;

            let zwischen = filterConfig?.zwischen;
            if (zwischen) {
                const parsedFilterVon = dayjs(zwischen.find(v => v.key == 'min').value, DATE_FORMAT).toDate();
                const parsedFilterBis = dayjs(zwischen.find(v => v.key == 'max').value, DATE_FORMAT).toDate();

                dateFromStr = dayjs(parsedFilterVon).format(DATE_FORMAT);
                dateEndStr = dayjs(parsedFilterBis).format(DATE_FORMAT);
            }

            let von30Tage = filterConfig?.von30Tage;
            if (von30Tage) {
                dateFromStr = dayjs().subtract(30, "day").format("DD.MM.YYYY");
                dateEndStr = dayjs().format("DD.MM.YYYY");
            }

            let von60Tage = filterConfig?.von60Tage;
            if (von60Tage) {
                dateFromStr = dayjs().subtract(60, "day").format("DD.MM.YYYY");
                dateEndStr = dayjs().format("DD.MM.YYYY");
            }

            let von90Tage = filterConfig?.von90Tage;
            if (von90Tage) {
                dateFromStr = dayjs().subtract(90, "day").format("DD.MM.YYYY");
                dateEndStr = dayjs().format("DD.MM.YYYY");
            }

            let von180Tage = filterConfig?.von180Tage;
            if (von180Tage) {
                dateFromStr = dayjs().subtract(180, "day").format("DD.MM.YYYY");
                dateEndStr = dayjs().format("DD.MM.YYYY");
            }

            let von14Tage = filterConfig?.von14Tage;
            if (von14Tage) {
                dateFromStr = dayjs().subtract(14, "day").format("DD.MM.YYYY");
                dateEndStr = dayjs().format("DD.MM.YYYY");
            }

            let von7Tage = filterConfig?.von7Tage;
            if (von7Tage) {
                dateFromStr = dayjs().subtract(7, "day").format("DD.MM.YYYY");
                dateEndStr = dayjs().format("DD.MM.YYYY");
            }

            let kundennr = filterConfig?.KUNDENNR;
            let vertragId = filterConfig?.VERTRAG_ID;
            let nachName = filterConfig?.NACHNAME;
            let vorName = filterConfig?.VORNAME;
            let gesellschaft = filterConfig?.GESELLSCHAFT;
            let VSNR  = filterConfig?.VSNR;
            let sparte = filterConfig?.SPARTE;
            let inklSchaedenStruktur  = filterConfig?.INKL_SCHAEDEN_STRUKTUR;
            let schadennr = filterConfig?.SCHADEN_NR;

            const payload = {dateFromStr, dateEndStr, kundennr, vertragId, nachName, vorName, gesellschaft, VSNR, sparte, inklSchaedenStruktur, schadennr };

            await this.retrieveListSchaden(payload);
        },        
    },
    async mounted(){
        if(!this.schadenBearbstandValues?.length){
            await this.retrieveSchadensBearbStandValues()
        }
    },    
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({
            to,from,
        });
        next();
    },    
}


</script>

<style scoped>

</style>