import store from '@/store';
import CORE_TYPES from '@/store/core/types';
import BRIDGE_TYPES from '@/store/bridge/types';
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper';

import { _hasLinkResolver, _resolveLink } from './resolvers';
import { WrapType } from './types';
import { handleURL, handleFormData, handleViewDocument, handleDownloadLink, handleDownloadLinkHref } from './utils';

export const DEFAULT_ERROR_MESSAGE = 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an unseren Support.';

/**
 * Finds the best way to open an async link on mobile / web context and prevent popup blockers
 * 
 * @param {string} resolverId 
 * @param {object} data 
 * @returns 
 */
export function openLink(resolverId, data) {
  if (!_hasLinkResolver(resolverId, data)) return;

  const isMobileContext = store.getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT];
  if (isMobileContext) {
    _handleMobileRequest(resolverId, data);
  } else {
    _openLinkInNewWindow(resolverId, data);
  }
}

async function _handleMobileRequest(resolverId, data) {
  try {
    const response = await _resolveLink(resolverId, data);
    switch (response?.type) {
      case WrapType.Url: // Url
      case WrapType.FormData: // FormData
        _openLinkInNewWindow(resolverId, response, true);
        break;

      case WrapType.ViewDocument: // ViewDocument
        handleViewDocument(response);
        break;

      case WrapType.DownloadLink: // DownloadLink
        handleDownloadLink(response);
        break;

      case WrapType.DownloadLinkHref: // DownloadLinkHref
        handleDownloadLinkHref(response);
        break;

      default: // Error
        throw new Error(response?.message || DEFAULT_ERROR_MESSAGE);
    }
  } catch (e) {
    store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(e.message, 'danger'));
  }
}

function _openLinkInNewWindow(resolverId, data, isResolvedAlready = false) {
  const appToken = store.getters[CORE_TYPES.GETTERS.GET_TOKEN];
  const appApiAddress = store.getters[CORE_TYPES.GETTERS.API_ADDRESS];

  const requestData = {
    resolverId,
    data,
    appToken,
    appApiAddress,
    isResolvedAlready,
  };

  const payload = {
    data: encodeURIComponent(btoa(JSON.stringify(requestData))),
  };

  const params = Object.entries(payload)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  window.open(`/open-link?${params}`);
}

/**
 * Processes a request to '/open-link' route
 * 
 * @param {string} requestData - base64 encoded string
 */
export async function _handleOpenLinkRequest(requestData) {
  if (!requestData) {
    throw new Error(DEFAULT_ERROR_MESSAGE);
  }

  const dataParsed = JSON.parse(atob(decodeURIComponent(requestData)));
  if (!dataParsed.appToken) { // show an error message when token is missing
    throw new Error(DEFAULT_ERROR_MESSAGE);
  }

  // set token and api address
  store.state.LinkResolvers__Token = dataParsed.appToken;
  store.state.LinkResolvers__ApiAddress = dataParsed.appApiAddress;

  // resolve link
  const { resolverId, data, isResolvedAlready } = dataParsed;
  const response = isResolvedAlready ? data : await _resolveLink(resolverId, data);
  switch (response?.type) {
    case WrapType.Url: // Url
      handleURL(response);
      break;

    case WrapType.FormData: // FormData
      handleFormData(response);
      break;

    case WrapType.ViewDocument: // ViewDocument
      handleViewDocument(response);
      setTimeout(window.close, 2000);
      break;

    case WrapType.DownloadLink: // DownloadLink
      handleDownloadLink(response);
      break;

    case WrapType.DownloadLinkHref: // DownloadLinkHref
      handleDownloadLinkHref(response);
      break;

    case 'text/plain':

      const preElement = document.createElement('pre');
      var obj = JSON.parse(response.data);
      preElement.textContent = JSON.stringify(obj, undefined, 2);

      // Apply some basic styles for better readability
      preElement.style.backgroundColor = '#f5f5f5';
      preElement.style.padding = '10px';
      preElement.style.border = '1px solid #ccc';
      preElement.style.borderRadius = '5px';
      preElement.style.fontSize = '14px';
      preElement.style.color = '#333';
      preElement.style.overflowX = 'auto';

      // Append the <pre> element to the body (or any other container)
      const appDiv = document.getElementById('app');
      if (appDiv) {
        appDiv.innerHTML = '';
        appDiv.appendChild(preElement);
      }
      break;

    case "TextCSV":
      try {
        const link = document.createElement('a');

        const blob = new Blob([response.data], { type: 'text/csv' });

        const url = window.URL.createObjectURL(blob);

        link.href = url;
        link.setAttribute('download', response.filename);

        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
        window.close();
      } catch (error) {
        console.error('Error downloading CSV file:', error);
      }



      break;
    default: // Error
      throw new Error(response?.message || DEFAULT_ERROR_MESSAGE);
  }
}
