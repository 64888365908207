<template>
    <div>
        <BaseFilter
            filterId="47546ded-8fac-495a-8667-7696a0e9050d"
            :metadata="searchMenu"
            :defaultOptions="defaultOptionsData"
            :configFilter="configFilter"
            :isCustomerSearch="false"
            hasSmartSearch
            showSaveButton
            isCache
            @onFilter="search"
        />

        <div class="box__container">
            <GhostLoading v-if="loading" type="table" />
            <NoData v-else-if="!rows || !rows.length" />
            <Table
                v-else
                :headers="headers"
                :rows="rows"
                :rowsPerPage="50"
                rowId="depotnr"
                title="Portfolios"
                tableId="090cc497-99c5-48dd-b83f-92790b551d66"
            />    
        </div>    
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import VV_TYPES from "@/store/vermoegensverwaltung/types"
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js"
import Table from "@/components/table2/Table.vue"
import NoData from "@/components/core/NoData.vue"
import GhostLoading from "@/components/core/loading/GhostLoading.vue"
import BaseFilter from "@/components/core/BaseFilter.vue"
import {DateColumn, TextColumn, CurrencyColumn} from "@/components/table2/table_util.js"

export default {
    mixins: [mixin],
    components: {
        Table,
        NoData,
        GhostLoading,
        BaseFilter,
    },
    props: {},
    data() {
        return {
            isLoading: false,
            headers: {
                lockedLeft: [TextColumn("depotnr", "Depotnummer"), TextColumn("produkt", "Produkt")],
                center: [
                    CurrencyColumn("bestand", "Bestand"),
                    TextColumn("kunde", "kunde"),
                    DateColumn("dateGeschlossen", "Geschlossen"),
                ],
                lockedRight: [],
            },
        }
    },
    mounted() {
        this.updateWarnings(false)
    },
    computed: {
        ...mapGetters({
            portfolioList: VV_TYPES.GETTERS.PORTFOLIO_LIST,
        }),
        rows() {
            return this.portfolioList
        },
        loading() {
            return this.isLoading
        },
        searchMenu() {
            return [
                {
                    type: "group",
                    key: "allgemein",
                    label: "Allgemein",
                    menuItems: [
                        {
                            type: "text",
                            label: "Kundenname / -nummer",
                            key: "kunde",
                        },
                        {
                            type: "text",
                            label: "Depotnummer",
                            key: "depotnr",
                        },
                        {
                            type: "text",
                            label: "Strategie",
                            key: "produktbez",
                        },
                    ],
                },
                {
                    type: "group",
                    key: "dateGeschlossen",
                    label: "gekündigt",
                    menuItems: [
                        {
                            type: "default",
                            label: "Nein",
                            key: "geschlossenNein",
                        },
                        {
                            type: "default",
                            label: "Ja",
                            key: "geschlossenJa",
                        },
                        {
                            type: "dateRange",
                            label: "im Zeitraum",
                            key: "geschlossenZeitraum",
                        },
                    ],
                },
            ]
        },
        defaultOptionsData() {
            return {
                geschlossenNein: {value: true},
            }
        },
        configFilter() {
            return {
                placeholderForDefSearchInput: "Depotnummer, Strategie",
                defaultSearchInputKeys: ["defaultText"],
                noResetOnDefaultSearchInputExit: false,
            }
        },
    },
    methods: {
        search(filterParams) {
            this.isLoading = true

            const queryParams = {}

            filterParams.forEach((param) => {
                if (param.key === "geschlossenZeitraum") {
                    queryParams[param.key + "Min"] = param.value[0].value
                    queryParams[param.key + "Max"] = param.value[1].value
                } else queryParams[param.key] = param.value
            })

            this.$store.dispatch(VV_TYPES.ACTIONS.GET_PORTFOLIO_LIST, queryParams).finally(() => {
                this.isLoading = false
            })
        },
    },
}
</script>

<style>
</style>