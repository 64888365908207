var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.title,
          subtitle: "Einstellungen",
          actions: _vm.headerActions
        },
        on: {
          "action-NEW_BEMERKUNG": _vm.createBemerkung,
          "action-VV_BUTTON_CHANGE_KOMMENTAR_ALL": _vm.openKommentarAllModal,
          "action-VV_UPLOAD_DOCUMENT": _vm.uploadDocument
        }
      }),
      _c("AntragSidetext", {
        attrs: {
          warnings: _vm.warnings,
          hinweiseProps: _vm.hinweise,
          steps: _vm.stepper.getCurrentStepList(),
          hinweisText: "Die Strategie ist fehlerhaft",
          showMessageErrorMustBeFixed: "",
          showExpanded: _vm.showExpanded,
          highestStepVisited: 4
        },
        on: {
          setStep: function($event) {
            return _vm.setStepByKey($event)
          },
          setSubstep: function($event) {
            return _vm.setSubstepByKey($event)
          }
        }
      }),
      _c("StepperForm", {
        ref: "stepperForm",
        attrs: {
          stepType: "tab",
          stepperName: "vv-schema-einstellungen-stepper",
          stepperMediator: _vm.stepper,
          selectedStepKey: _vm.stepKey,
          selectedSubstepKey: _vm.substepKey
        },
        on: {
          "set-step": _vm.setStepByKey,
          "set-substep": _vm.setSubstepByKey,
          "on-fertig": function($event) {
            return _vm.$goToLastPage()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [_c("router-view")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }