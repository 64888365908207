var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "showOldPoliceNumbersModal",
          attrs: {
            modalTitle: "Historische VSNR",
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false
          }
        },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", useBoxContainer: "" }
              })
            : _c("Table", {
                staticClass: "box__container",
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  hidePagination: ""
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }