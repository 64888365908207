import VERTRAGSSPIEGEL_TYPES from './types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  [VERTRAGSSPIEGEL_TYPES.ACTIONS.GET_FIELDS_DEFINITION]({ commit, rootState }) {
    let url = rootState.core.apiAddress + '/vertragsspiegel/vertragsspiegelFieldsDefinition';

    axios.get(url).then(response => {
      if (response && response.data) {
        commit(VERTRAGSSPIEGEL_TYPES.MUTATIONS.FIELDS_DEFINITION_SUCCESS, response.data);
      }
    })
  },

}