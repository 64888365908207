<template>
  <div class="box__container">
    <BaseCollapsable ref="baseCollapsable" :showSection="showExpanded">
      <template v-slot:title>
        <span class="box__title">Hinweise / Fehler </span>
        <span v-if="hasHints">&nbsp;|&nbsp; ({{hints.length}} Hinweis{{hints.length > 1 ? 'e' : ''}})</span>
        <span v-if="hasErrors" class="color-danger">&nbsp;|&nbsp; {{errorTitle}}</span>
      </template>
      <template v-slot:content>
        <div v-if="!hasHints && !hasErrors">
          Keine Hinweise / Fehler vorhanden.
        </div>
        <div v-else-if="hasErrors">
          <div class="row">
            <div class="col-12">
              <span class="font-bold">Fehler:</span>
            </div>
          </div>
          <div class="row" v-for="(error, index) of errors" :key="index">
            <div class="col-12" 
                :class="{clickable: isNavigable(error)}"
                @click="navigateTo(error)">
              {{ getDescription(error) }}
              <PhPencilLine v-if="isNavigable(error)" :size="20"/>
            </div>
          </div>
        </div>
        <div v-if="hasHints">
          <div class="row">
            <div class="col-12">
              <span class="font-bold">Hinweise:</span>
            </div>
          </div>
          <div class="row" v-for="(hint, index) of hints" :key="index">
            <div class="col-12" v-html="hintHtml(hint)"></div>
          </div>
        </div>
      </template>
    </BaseCollapsable>
  </div>
</template>

<script>
import { PhPencilLine } from 'phosphor-vue'

import BaseCollapsable from '@/components/core/BaseCollapsable.vue'
import {sanitize} from '@/helpers/string-helper.js';


export default {  
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    hints: {
      type: Array,
      default: () => [],
    },
    showExpanded: {
      type: Boolean,
      default: false,
    },
    errorTitle: {
      type: String,
      default: 'Das Formular enthält Fehler',
    },
  },
  components: {
    PhPencilLine,
    BaseCollapsable,
  },
  computed: {
    hasErrors() {
      return this.errors && this.errors.length
    },
    hasHints() {
      return this.hints && this.hints.length
    },
  },
  methods: {    
    hintHtml(hint) {
      if (hint && hint.message) {
        return sanitize(`<div><b>${hint.title || ''}</b></div>${hint.message}`);
      }
      else return '';
    },
    isSimpleError(error) {
      return typeof error === 'string';
    },
    getDescription(error) {
      if (error) {
        return this.isSimpleError(error) ? error : this.getErrorMessage(error.title, error.message);
      }
      return '';
    },
    getErrorTitle(title) {
      return (title ? `Schritt ${title}: ` : '');
    },
    getErrorMessage(title, message) {
      return (this.getErrorTitle(title) + (message || ''));
    },
    isNavigable(item) {
      return item && item.stepKey;
    },
    navigateTo(item) {
      const { stepKey, substepKey } = item;
      if(!stepKey) return;
      if (substepKey) {
        this.$emit('set-substep', {
          stepKey,
          substepKey,
        });
      } else {
        this.$emit('set-step', stepKey);
      }
    },
    expand(){
      this.$refs.baseCollapsable?.expand()
    },
    collapse() {
      this.$refs.baseCollapsable?.collapse()
    }
  }
}
</script>

<style scoped>
</style>
