<template>
  <div class="legitimation-images-firma__container">
    <div v-if="!rechtsform" class="box__container">
      <p><span class="font-bold">Schritt Stammdaten: </span>Bitte setzen Sie zuerst die Rechtsform.</p>
    </div>
    <div v-else>
      <div class="scans-container" v-for="requiredFile in requiredFiles">
        <div class="box__container">
          <div class="box__title">{{ requiredFile.title }}</div>
          <SingleFileUpload :filename="getFilename(requiredFile.title)"
            :fileId="unterlagenFirma[requiredFile.shorthand] ? unterlagenFirma[requiredFile.shorthand].fileId : null" @file="file => upload(file, requiredFile.shorthand)"
            @delete="deleteDocument(requiredFile.shorthand)"></SingleFileUpload>
          <DatePickerField v-if="unterlagenFirma[requiredFile.shorthand] && needsAusstellungsdatum(requiredFile.shorthand)" label="Ausstellungsdatum" isValueAsString
            isComponentHalfSize v-model="unterlagenFirma[requiredFile.shorthand].ausstellungsdatum"
            :disabled="isLoading || !isEditable || disabled || unterlagenFirma[requiredFile.shorthand] === undefined"
            @change="date => changeAusstellungsdatum(date, requiredFile.shorthand)" />
        </div>
      </div>
    </div>

    <div class="box__container" v-if="hasActions && !isSmallScreen">
      <div class="row mb-0">
        <div class="col-auto">
          <BaseButton isSecondary @click="deleteAllDocsModal" :disabled="isLoading || disabled">Gespeicherte
            Bilder
            löschen</BaseButton>
        </div>
        <div class="col-auto">
          <DownloadLink downloadServicePath="/generateLegitimationPDF" title="PDF herunterladen"
            :queryParams="{ personId: customerData.personId }" asButton />
        </div>
      </div>
    </div>
    <div class="box__container">
      <p>Sonstige Unterlagen</p>
      <div v-for="otherFile in otherFiles">
        <div v-if="unterlagenFirma[otherFile.shorthand]">
          <SingleFileUpload :filename="getFilename(otherFile.title)"
            :fileId="unterlagenFirma[otherFile.shorthand].fileId" @file="file => upload(file, otherFile.shorthand)"
            @delete="deleteDocument(otherFile.shorthand)"></SingleFileUpload>
            <ComboBox label="Unterlagentyp ändern" :values="requiredEmptyFilesAsCombobox" :disabled="!isEditable" isComponentHalfSize
            @change="changeLegidocType(otherFile.shorthand, $event)" />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue'
import { mapGetters } from 'vuex'
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import CORE_TYPES from '@/store/core/types';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue';
import SingleFileUpload from './SingleFileUpload.vue';
import mainFormMixin from '@/views/schadensmeldung/steps/main-form-mixin';

export default {
  mixins: [mainFormMixin],
  props: {
    rechtsform: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      legitimationsurkundeChanged: CUSTOMERDATA_TYPES.GETTERS.LEGITIMATIONSURKUNDE_CHANGED,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN
    }),
    legitimation() {
      return this.customerData?.legitimation;
    },
    unterlagenFirma() {
        return this.legitimation?.unterlagenFirma || {};
    },
    hasDocumentAdded() {
      return !!Object.keys(this.unterlagenFirma).length;
    },
    isLoading() {
      return this.uploading || this.reading || this.updatingProfile || this.savingLegitimation || this.convertingFile || this.deleting;
    },
    isEditable() {
      return this.customerData?.isLegitimationEditable;
    },
    allFiles() {
      return this.isFA ? this.allFilesFA : this.allFilesFK;
    },
    requiredFiles() {
      return this.allFiles.filter(file => {
        return file.rechtsformen.includes(this.rechtsform);
      });
    },
    requiredEmptyFiles() {
      return this.requiredFiles.filter(file => {
        return !this.unterlagenFirma[file.shorthand];
      })
    },
    requiredEmptyFilesAsCombobox() {
      return this.requiredEmptyFiles.map(file => {
        return { label: file.title, value: file.shorthand };
      });
    },
    otherFiles() { //returns all files, that are not mapped to any requiredFiles
      return this.allFiles.filter(file => {
        return !file.rechtsformen.includes(this.rechtsform);
      });
    },
    allFilesFK() {
      return [
        {
          shorthand: 'TRANSPARENZREGISTERAUSZUG', title: 'Auszug Transparenzregister',
          rechtsformen: ['EINZELFIRMA', 'PERSONENGESELLSCHAFT', 'GBR', 'STIFTUNG', 'VEREIN']
        },
        {
          shorthand: 'FATCAAUSKUNFT', title: 'Selbstauskunft für juristische Personen (CRS/FATCA)',
          rechtsformen: ['EINZELFIRMA', 'PERSONENGESELLSCHAFT', 'GBR', 'STIFTUNG', 'VEREIN']
        },
        { shorthand: 'GEWERBEANMELDUNG', title: 'Gewerbeanmeldung', rechtsformen: ['EINZELFIRMA'] },
        { shorthand: 'HRBSEITE1', title: 'HRB Seite 1', rechtsformen: ['PERSONENGESELLSCHAFT'] },
        { shorthand: 'HRBSEITE2', title: 'HRB Seite 2', rechtsformen: ['PERSONENGESELLSCHAFT'] },
        { shorthand: 'UNTERSCHRIFTSVERZEICHNIS', title: 'Unterschriftsverzeichnis', rechtsformen: ['PERSONENGESELLSCHAFT'] },
        { shorthand: 'VEREINSREGISTERAUSZUG', title: 'Bestätigte Kopie des Vereinsregisterauszuges', rechtsformen: ['GBR', 'VEREIN'] },
        { shorthand: 'ANERKENNTNISURKUNDE', title: 'Bestätigte Kopie der Anerkenntnisurkunde', rechtsformen: ['STIFTUNG'] },
        { shorthand: 'VERTRETUNGSBESCHEINIGUNG', title: 'Bestätigte Kopie der Vertretungsbescheinigung', rechtsformen: ['STIFTUNG'] },
        { shorthand: 'SATZUNGSBESTAETIGUNG', title: 'Bestätigte Kopie der Satzung', rechtsformen: ['STIFTUNG'] }
      ];
    },
    allFilesFA() {
      return [
        {
          shorthand: 'TRANSPARENZREGISTERAUSZUG', title: 'Erweiterten WiEReG gemäß §9',
          rechtsformen: ['EINZELFIRMA', 'PERSONENGESELLSCHAFT', 'STIFTUNG', 'VEREIN']
        },
        { shorthand: 'GEWERBEANMELDUNG', title: 'Gewerbeschein', rechtsformen: ['EINZELFIRMA'] },
        { shorthand: 'HRBSEITE1', title: 'Firmenbuchauszug 1', rechtsformen: ['PERSONENGESELLSCHAFT'] },
        { shorthand: 'HRBSEITE2', title: 'Firmenbuchauszug 2', rechtsformen: ['PERSONENGESELLSCHAFT'] },
        { shorthand: 'UNTERSCHRIFTSVERZEICHNIS', title: 'Unterschriftsverzeichnis', rechtsformen: ['PERSONENGESELLSCHAFT'] },
        { shorthand: 'VEREINSREGISTERAUSZUG', title: 'Bestätigte Kopie des Vereinsregisterauszuges', rechtsformen: ['GBR', 'VEREIN'] },
        { shorthand: 'ANERKENNTNISURKUNDE', title: 'Bestätigte Kopie der Anerkenntnisurkunde', rechtsformen: ['STIFTUNG'] },
        { shorthand: 'VERTRETUNGSBESCHEINIGUNG', title: 'Bestätigte Kopie der Vertretungsbescheinigung', rechtsformen: ['STIFTUNG'] },
        { shorthand: 'SATZUNGSBESTAETIGUNG', title: 'Bestätigte Kopie der Satzung', rechtsformen: ['STIFTUNG'] }
      ];
    },
    hasActions() {
      return this.hasDocumentAdded && this.isEditable;
    },
  },
  data() {
    return {
      uploading: false,
      deleting: false
    }
  },
  methods: {
    needsAusstellungsdatum(type) {
      return this.isFK || (this.isFA && (type === 'HRBSEITE1' || type === 'TRANSPARENZREGISTERAUSZUG'))
    },
    getFilename(title) {
      return title.replaceAll(' ', '-') + '.pdf' //FIXME ALSO HANDLE IMAGETYPES
    },
    deleteDocument(legidocType) {
      this.deleting = true;
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_LEGITIMATION_IMAGE_FIRMA, {
        personId: this.personId,
        legiTypFirma: legidocType,
      })
        .then(() => {
          this.unterlagenFirma[legidocType] = null;
        })
        .finally(() => this.deleting = false);
    },
    upload(file, typ) {
      let payload = {
        rechtsform: this.rechtsform,
        personId: this.personId,
        typ
      }
      payload[typ] = file;
      //Add ausstellungsdatum info
      if(this.unterlagenFirma[typ]?.ausstellungsdatum) {
        payload['ausstellungsdatum'] = this.unterlagenFirma[typ].ausstellungsdatum;
      }
      return this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.UPLOAD_FIRMA_LEGITIMATION_IMAGES, payload)
        .then(v => {
          this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.LEGITIMATIONSURKUNDE_CHANGED, { legitimationsurkundeChanged: true });
        })
        .finally(() => this.uploading = false);
    },
    changeLegidocType(typ, neuerTyp) {
      let payload = {
        rechtsform: this.rechtsform,
        personId: this.personId,
        typ,
        neuerTyp,
        action: "CHANGE_TYP"
      }      
      return this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.UPLOAD_FIRMA_LEGITIMATION_IMAGES, payload)
        .then(v => {
          this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.LEGITIMATIONSURKUNDE_CHANGED, { legitimationsurkundeChanged: true });
        })
        .finally(() => this.uploading = false);
    },
    changeAusstellungsdatum(ausstellungsdatum, typ) {
      let payload = {
        rechtsform: this.rechtsform,
        personId: this.personId,
        ausstellungsdatum,
        typ,
        action: "CHANGE_DATE"
      }      
      return this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.UPLOAD_FIRMA_LEGITIMATION_IMAGES, payload)
        .then(v => {
          this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.LEGITIMATIONSURKUNDE_CHANGED, { legitimationsurkundeChanged: true });
        })
        .finally(() => this.uploading = false);
    },
    deleteAllDocsModal() {
      this.$confirmModal({
        title: 'ALLE Legitimationsdokumente löschen',
        message: 'Wollen Sie wirklich ALLE Legitimationsdokumente löschen?',
        labelButtonConfirm: 'Löschen',
      }).then(() => {
        this.deleteDocuments();
      });
    },
    deleteDocuments() {
      this.legitimation.unterlagenFirma = {};
      this.deleting = true;

      let personId = this.personId;
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_LEGITIMATION_IMAGES, {
        personId: personId,
      }).finally(() => this.deleting = false);
    },
  },
  components: {
    BaseButton,
    BaseModal,
    InputToggleSwitch,
    DownloadLink,
    DatePickerField,
    ComboBox,
    SingleFileUpload

  }
}
</script>

<style scoped>
.scans-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  gap: 10px;
}

.scans-container>div {
  flex: 1 1 344px;
  max-width: 100%;
}

.scan-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.scan-container.no-image {
  max-width: 344px;
}
</style>
