import Image from "@tiptap/extension-image"
import {VueNodeViewRenderer} from "@tiptap/vue-2"
import ResizableImageTemplate from "./ResizableImageTemplate.vue"
import {Plugin, PluginKey /* NodeSelection, Selection */} from "@tiptap/pm/state"
import {mergeAttributes} from "@tiptap/core"

const NODE_NAME = "image"

export const loadImagePropertiesOfBase64 = (src, filename) => {
    return new Promise((resolve) => {
        const image = document.createElement("img")

        // get the image size from base64
        image.addEventListener("load", () => {
            const imageProps = {
                src,
                height: image.height,
                width: image.width,
                naturalWidth: image.naturalWidth,
                naturalHeight: image.naturalHeight,
                alt: filename,
            }

            resolve(imageProps)
        })

        image.src = src
    })
}

function sliceSingleNode(slice) {
    return slice.openStart == 0 && slice.openEnd == 0 && slice.content.childCount == 1 ? slice.content.firstChild : null
}

function handleDefaultPaste(view, event, slice) {
    if (!slice) return false

    let singleNode = sliceSingleNode(slice)
    let tr = singleNode
        ? view.state.tr.replaceSelectionWith(singleNode, false)
        : view.state.tr.replaceSelection(slice)
    view.dispatch(
        tr
            .scrollIntoView()
            .setMeta("paste", true)
            .setMeta("uiEvent", "paste"),
    )
}

const CustomImage = Image.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            src: {
                default: "",
                renderHTML: (attributes) => {
                    return {
                        src: attributes.src,
                    }
                },
            },
            width: {
                renderHTML: ({width}) => ({width}),
            },
            height: {
                renderHTML: ({height}) => ({height}),
            },
            naturalWidth: {
                renderHTML: ({naturalWidth}) => ({naturalWidth}),
            },
            naturalHeight: {
                renderHTML: ({naturalHeight}) => ({naturalHeight}),
            },
            isDraggable: {
                default: true,
                renderHTML: () => {
                    return {}
                },
            },
        }
    },

    parseHTML() {
        return [
            {
                tag: "img[src]",
            },
        ]
    },

    renderHTML({HTMLAttributes}) {
        return ["img", mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(ResizableImageTemplate)
    },

    addCommands() {
        return {
            ...this.parent?.(),
            updateImageSize: (proportion) => ({commands}) => {
                return commands.updateAttributes(this.name, {...proportion})
            },
        }
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey(NODE_NAME),
                props: {
                    handleDrop(view, event) {
                        event.preventDefault()
                        if (event.dataTransfer?.files?.length !== 1) {
                            return
                        }

                        const images = Array.from(event.dataTransfer.files).filter((file) => /image/i.test(file.type))
                        if (images.length === 1) {
                            const {schema} = view.state
                            const coordinates = view.posAtCoords({left: event.clientX, top: event.clientY})
                            const reader = new FileReader()
                            reader.onload = (readerEvent) => {
                                loadImagePropertiesOfBase64(readerEvent.target.result, images[0].name).then(
                                    (imageProps) => {
                                        const node = schema.nodes[NODE_NAME].create(imageProps)
                                        const transaction = view.state.tr.insert(coordinates.pos, node)
                                        view.dispatch(transaction)
                                    },
                                )
                            }

                            reader.readAsDataURL(images[0])
                        }
                    },

                    async handlePaste(view, event, slice) {
                        const {files = []} = event?.clipboardData ?? {}
                        if (files.length <= 0) {
                            return handleDefaultPaste(view, event, slice)
                        }

                        const images = Array.from(files).filter((file) => /image/i.test(file.type))
                        if (images.length <= 0) {
                            return handleDefaultPaste(view, event, slice)
                        }

                        event.preventDefault()

                        const {schema} = view.state
                        for (const image of images) {
                            await new Promise((resolve) => {
                                const reader = new FileReader()
                                reader.onload = (readerEvent) => {
                                    loadImagePropertiesOfBase64(readerEvent.target.result, image.name).then(
                                        (imageProps) => {
                                            const node = schema.nodes[NODE_NAME].create(imageProps)
                                            const transaction = view.state.tr.replaceSelectionWith(node)
                                            view.dispatch(transaction)
                                            resolve()
                                        },
                                    )
                                }
                                reader.readAsDataURL(image)
                            })
                        }
                    },
                },
            }),
        ]
    },
})

export {CustomImage}
export default CustomImage
