var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.editor
    ? _c(
        "div",
        { staticClass: "margin-0 flex gap-1 overflow-x-scroll w-full" },
        _vm._l(_vm.textPlaceholderOptions, function(category) {
          return _c("BaseToolbarComboBox", {
            key: category.name,
            attrs: {
              value: "",
              values: _vm.placeholders(category.name),
              disabled: _vm.disabled,
              firstEmpty: true
            },
            on: {
              change: function($event) {
                return _vm.insertPlaceholder($event, _vm.editor)
              }
            }
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }