var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MultiBankingNavigation", { attrs: { subtitle: "Contracts" } }),
      _c(
        "div",
        { staticClass: "box__container" },
        [_c("ContractsList", { attrs: { contracts: _vm.contractsData } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }