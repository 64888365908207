var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : !_vm.hasLinks
        ? _c("span", [
            _c(
              "p",
              {
                staticStyle: { "text-align": "center", "font-weight": "bold" }
              },
              [_vm._v("Keine Daten vorhanden*")]
            ),
            _c("br"),
            _c("p", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v("Hinweis zur Meldung: (*)")
            ]),
            _vm._v(
              " Diese Meldung erscheint, wenn keine Antwort von der Versicherungsgesellschaft vorliegt."
            ),
            _c("br"),
            _vm._v(
              " Dies bedeutet nicht zwangsläufig, dass die Deeplinks-Funktionalität fehlerhaft ist, sondern vielmehr, dass auf Basis der hinterlegten Kunden- und Vertragsdaten keine passenden Deeplinks von der Gesellschaft bereitgestellt wurden. "
            )
          ])
        : _c(
            "ul",
            { staticClass: "m-0" },
            _vm._l(_vm.deepLinks, function(link) {
              return _c("li", { key: link.name }, [
                !link.clicked
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: link.url,
                          target: "_blank",
                          name: "key"
                        },
                        on: {
                          click: function($event) {
                            return _vm.onLinkClick(link)
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(link.name) + " "),
                        _vm.hasSameBeschreibung
                          ? _c(
                              "tippy",
                              {
                                attrs: {
                                  to: _vm.key,
                                  trigger: "mouseenter",
                                  theme: "deeplinkToolTip",
                                  boundary: "viewport",
                                  followCursor: "Vertical",
                                  placement: "right",
                                  arrow: "true"
                                }
                              },
                              [
                                _vm._v(
                                  " Die vorgeschlagenen Links basieren auf den hinterlegten Zugangsdaten des Nutzers, die unter 'Interner Bereich -> Einstellungen -> Externe Zugänge einrichten -> BiPRO Zugänge' für die jeweilige Versicherungsgesellschaft hinterlegt sind."
                                ),
                                _c("br"),
                                _vm._v(
                                  " Es kann vorkommen, dass für einen Vertrag mehrere Links mit unterschiedlichen Benutzer-IDs angezeigt werden."
                                ),
                                _c("br"),
                                _vm._v(
                                  " Diese basieren auf den hinterlegten Zugangsdaten der BiPRO Zugänge für die jeweilige Versicherungsgesellschaft und werden durch Bezeichnungen wie z. B. 'Vertragssuche', 'Dokumente zum Vertrag' oder 'Schadenmeldung' etc. ergänzt."
                                ),
                                _c("br"),
                                _vm._v(
                                  " Die Vertragsdaten können jedoch nur über eine Benutzer-ID abgerufen werden, da die angezeigten Links von den Berechtigungen und Zugangsdaten des jeweiligen Nutzers bei der Versicherungsgesellschaft abhängen."
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _c(
                      "span",
                      [
                        _vm._v(_vm._s(link.name) + " "),
                        _c(
                          "tippy",
                          {
                            attrs: {
                              trigger: "mouseenter",
                              theme: "deeplinkToolTip",
                              boundary: "viewport",
                              followCursor: "Vertical",
                              placement: "right",
                              arrow: "true"
                            }
                          },
                          [
                            _vm._v(
                              "Der Deeplink kann nur einmal verwendet werden."
                            ),
                            _c("br"),
                            _vm._v("Für eine erneute Nutzung bitte den Button"),
                            _c("br"),
                            _vm._v("'Deeplinks aktualisieren' klicken.")
                          ]
                        )
                      ],
                      1
                    )
              ])
            }),
            0
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }