<template>
    <div>
        <div class="box__container">
            <div class="box__title">Abrechnungskonto für Einmalanlage</div>
            <FormLabel label="Das Abrechnungskonto ist das Verrechnungskonto. Sie haben hier die Möglichkeit, eine Überweisung vom KONTO plus auf das Verrechnungskonto anzuweisen."/>
            <ComboBox
                v-model="INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF" 
                label="Verwende Konto plus, IBAN"
                :values="comboboxValues ? comboboxValues.INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF : []"
                :disabled="!hasKauf"
                @change="updateStoreData({ componentId: 'INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF', value: $event})" />
            <InputIbanBic 
                :componentData="INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF" 
                validation_path="INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF"
                label="Freie Eingabe" 
                type="IBAN" 
                :disabled="!(hasKauf && INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF === FREIE_EINGABE)"
                @change="updateStoreData({ componentId: 'INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF', value: $event})"/>
            <InputField
                label="Betrag"
                v-model="INPUT_CAPITALBANK_BETRAG_KONTOPLUS"
                type="currency"
                @change="updateStoreData({ componentId: 'INPUT_CAPITALBANK_BETRAG_KONTOPLUS', value: convertValueToForm($event)})"
                :disabled="!hasKauf"/>
        </div>
        <div class="box__container">
            <div class="box__title">Abrechnungskonto für Verkauf</div>
            <InputRadioBoxGroup 
                v-model="INPUT_CB_BANK_VERKAUF" 
                :validateUntouched="true"
                :disabled="!hasVerkauf"
                :values="valuesBank"
                @input="updateStoreData({ componentId: 'INPUT_CB_BANK_VERKAUF', value: $event})" />
            <ComboBox
                v-model="INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF" 
                label="Verwende Konto plus, IBAN"
                :values="comboboxValues ? comboboxValues.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF : []"
                :disabled="!(hasVerkauf && data.INPUT_CB_BANK_VERKAUF === 'kontoplus_GRAWE')"
                @change="updateStoreData({ componentId: 'INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF', value: $event})" />
            <InputIbanBic 
                :componentData="INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF" 
                validation_path="INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF"
                label="Freie Eingabe" 
                type="IBAN" 
                :disabled="!(hasVerkauf && data.INPUT_CB_BANK_VERKAUF === 'kontoplus_GRAWE' && data.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF === FREIE_EINGABE)"
                @change="updateStoreData({ componentId: 'INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF', value: $event})"/>
        </div>
        <div class="box__container">
            <div class="box__title">Abrechnungskonto für Vermögensaufbauplan</div>
            <InputRadioBoxGroup 
                v-model="INPUT_CB_BANK_SPARPLAN" 
                :validateUntouched="true"
                :disabled="!hasSparplan"
                :values="valuesBank"
                @input="updateStoreData({ componentId: 'INPUT_CB_BANK_SPARPLAN', value: $event})" />
            <ComboBox
                v-model="INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN" 
                label="Verwende Konto plus, IBAN"
                :values="comboboxValues ? comboboxValues.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN : []"
                :disabled="!(hasSparplan && data.INPUT_CB_BANK_SPARPLAN === 'kontoplus_GRAWE')"
                @change="updateStoreData({ componentId: 'INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN', value: $event})" />
            <InputIbanBic 
                :value="INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN"
                validation_path="INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN"
                label="Freie Eingabe" 
                type="IBAN" 
                :componentData="INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN"
                :disabled="!(hasSparplan && data.INPUT_CB_BANK_SPARPLAN === 'kontoplus_GRAWE' && data.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN === FREIE_EINGABE)"
                @change="updateStoreData({ componentId: 'INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN', value: $event})"/>
        </div>
        <div class="box__container">
            <div class="box__title">Optionen für Verkäufe</div>
            <FormLabel label="Für den Fall, dass ich nur einen Teil meiner Bestände verkaufen möchte und sich diese in einen Bestand, der vor dem 01.01.2011 angeschafft wurde (Altbestand) und einen, der ab 01.01.2011 angeschafft wurde (Neubestand) aufteilen, gebe ich den Auftrag, zuerst den Altbestand zu veräußern." />
            <InputToggleSwitch 
                v-model="INPUT_VERKAUF_AUFGETEILT" 
                label="Abweichend vom Obigen gebe ich den Auftrag, zuerst meinen Neubestand zu verkaufen." 
                inLineLabel 
                :disabled="!hasVerkauf"
                @input="updateStoreData({ componentId: 'INPUT_VERKAUF_AUFGETEILT', value: $event})"/>
            <InputToggleSwitch 
                v-model="INPUT_CB_BANK_SCHLIESSEN" 
                label="Ich ersuche Sie, mein Depot und Verrechnungskonto nach den erfolgten Verkäufen zu schließen. (Unterschrift aller Depotinhaber erforderlich!)" 
                inLineLabel 
                :disabled="!hasVerkauf"
                @input="updateStoreData({ componentId: 'INPUT_CB_BANK_SCHLIESSEN', value: $event})" />
        </div>
    </div>
</template>

<script>
import ComponentList from '@/components/wertpapierorder/ComponentList.vue';
import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';
import mixin from '@/mixins/wertpapierorder/mixin.js';
import { FREIE_EINGABE } from '@/mixins/wertpapierorder/mixin.js';
import { mapGetters } from "vuex";
import FormLabel from '@/components/core/forms/FormLabel.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputIbanBic from '@/components/core/forms/InputIBAN_BIC.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

const IBAN_COMPONENT_IDS = ['INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF', 'INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF', ''];

export default {
    mixins: [mixin],
    components: {
        ComponentList,
        FormLabel,
        ComboBox,
        InputIbanBic,
        InputToggleSwitch,
        InputField,
        InputRadioBoxGroup,
    },
    data() {
        return {
            disabled: false,
            changed: false,
            valuesBank: [{ label: 'Verrechnungskonto bei der Capital Bank', value: 'verrechnungskonto'},
                        { label: 'Bestehendes Referenzkonto', value: 'referenzkonto'},
                        { label: 'KONTO plus, IBAN', value: 'kontoplus_GRAWE'}],
            INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF: '',
            INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF: '',
            INPUT_CAPITALBANK_BETRAG_KONTOPLUS: '',
            INPUT_CB_BANK_VERKAUF: '',
            INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF: '',
            INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF: '',
            INPUT_CB_BANK_SPARPLAN: '',
            INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN: '',
            INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN: '',
            INPUT_VERKAUF_AUFGETEILT: '0',
            INPUT_CB_BANK_SCHLIESSEN: '0',
            FREIE_EINGABE: FREIE_EINGABE,
        }
    },
    mounted() {    
        this.removeKaufValues();
        this.removeVerkaufValues();
        this.removeSparplanValues();
        setTimeout(() => {
            this.initForm();
        }, 600);
    },
    computed: {
        ...mapGetters({
            comboboxValues: WERTPAPIERORDER_TYPES.GETTERS.COMBOBOX_VALUES || {},
            lagerstelle: WERTPAPIERORDER_TYPES.GETTERS.SELECTED_LAGERSTELLE,
        }),
    },
    updated() {
        this.updateWarnings('capitalbank', false);
    },
    watch: {
        storeWarnings: function(value) {
            this.updateWarnings('capitalbank', false);
        }
    },
    methods: {
        convertValueToForm(value) {
            return value.replaceAll('.',',');
        },
        initForm() {
            this.INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF = this.data?.INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF;
            this.INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF = this.data?.INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF;
            this.INPUT_CAPITALBANK_BETRAG_KONTOPLUS = this.data?.INPUT_CAPITALBANK_BETRAG_KONTOPLUS || '';
            this.INPUT_CB_BANK_VERKAUF = this.data?.INPUT_CB_BANK_VERKAUF;
            this.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF = this.data?.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF;
            this.INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF = this.data?.INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF;
            this.INPUT_CB_BANK_SPARPLAN = this.data?.INPUT_CB_BANK_SPARPLAN;
            this.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN = this.data?.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN;
            this.INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN = this.data?.INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN;
            this.INPUT_VERKAUF_AUFGETEILT = this.data?. INPUT_VERKAUF_AUFGETEILT || '0';
            this.INPUT_CB_BANK_SCHLIESSEN = this.data?.INPUT_CB_BANK_SCHLIESSEN || '0';
        },
        removeKaufValues() {
            if (!this.hasKauf) {
                this.updateStoreDataValues({
                    INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF: null, 
                    INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF: null, 
                    INPUT_CAPITALBANK_BETRAG_KONTOPLUS: null });
            } else if (this.data?.INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF !== FREIE_EINGABE && this.data?.INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF) {
                this.updateStoreDataValues({INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF: null });
            }
        },
        removeVerkaufValues() {
            if (!this.hasVerkauf && (this.data?.INPUT_CB_BANK_VERKAUF || this.data?.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF || this.data?.INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF
                || this.data?.INPUT_VERKAUF_AUFGETEILT === '1' || this.data?.INPUT_CB_BANK_SCHLIESSEN === '1')) {
                this.updateStoreDataValues({
                    INPUT_CB_BANK_VERKAUF: null,
                    INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF: null,
                    INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF: null,
                    INPUT_VERKAUF_AUFGETEILT: '0',
                    INPUT_CB_BANK_SCHLIESSEN: '0',
                });
            } else if (this.hasVerkauf) {
                if (this.data?.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF !== FREIE_EINGABE && this.data?.INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF) {
                    this.updateStoreDataValues({INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF: null});
                } else if (this.data?.INPUT_CB_BANK_VERKAUF !== 'kontoplus_GRAWE' && (this.data?.INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF || this.data?.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF !== FREIE_EINGABE)) {
                    this.updateStoreDataValues({
                        INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF: null, 
                        INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF: FREIE_EINGABE});
                }
            }
        },
        removeSparplanValues() {
            if (!this.hasSparplan && (this.data?.INPUT_CB_BANK_SPARPLAN || this.data?.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN || this.data?.INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN)) {
                this.updateStoreDataValues({
                    INPUT_CB_BANK_SPARPLAN: null,
                    INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN: null,
                    INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN: null,
                });
            } else if (this.hasSparplan) {
                if (this.data?.INPUT_CB_BANK_SPARPLAN !== 'kontoplus_GRAWE' && (this.data?.INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN || this.data?.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN !== FREIE_EINGABE)) {
                    this.updateStoreDataValues({
                        INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN: null, 
                        INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN: FREIE_EINGABE});
                } else if(this.data?.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN !== FREIE_EINGABE && this.data?.INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN) {
                    this.updateStoreDataValues({INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN: null});
                }
            }
        },
    },
}
</script>

<style>
    .title-row {
        border-top: 1px solid #f7f3f3;
        background-color: #f7f3f3;
        padding: 1rem;
    }
</style>