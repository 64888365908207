var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: {
              title: "Digital Asset Kategorien",
              actions: _vm.headerActions,
              noPrimaryAction: false
            },
            on: {
              "action-TIPP-GEBEN": function($event) {
                return _vm.tippGeben()
              }
            }
          },
          _vm.$listeners
        )
      ),
      _vm._l(_vm.categories, function(asset) {
        return _c("div", { key: asset.id, staticClass: "box__container" }, [
          _c(
            "div",
            { staticClass: "box-title" },
            [
              _c("InputToggleSwitch", {
                attrs: { label: asset.category },
                model: {
                  value: asset["selected"],
                  callback: function($$v) {
                    _vm.$set(asset, "selected", $$v)
                  },
                  expression: "asset['selected']"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box-content" },
            [
              _c("Table", {
                ref: "table",
                refInFor: true,
                attrs: {
                  tableId: asset.id,
                  headers: _vm.headers,
                  rows: _vm.getAssets(asset.id),
                  rowId: "id",
                  hidePagination: ""
                }
              })
            ],
            1
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }