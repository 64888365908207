<template>
  <div class="formulare-list__container">
    <PageHeaderTitleNavigation
      title="Formulare"
      :subtitle="formArtTitle"
      :defaultMenu="customOptionMenu"
    />

    <div class="box__container" :tid="_generateTidFromString('box__container')">
      <div class="box__title">{{ formArtTitle }}</div>

      <InputField 
        isComponentHalfSize
        placeholder="Suchen nach Bezeichnung"
        trimValue
        :disabled="isLoading"
        v-model="descriptionSearch"/>
    </div>

    <div class="box__container" :tid="_generateTidFromString('cards__container layout__negative-margin--8')" >
      <GhostLoading v-if="isLoading" type="table" />
      <Table
        v-else
        tableId="0eca709b-022c-4078-871f-633007348bae"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="20"
        rowId="name"
        @click-name="openFormList"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ANTRAG_TYPES from '@/store/antrag/types';

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import UndrawFillForms from '@/components/icons/undraw/UndrawFillForms.vue';
import UndrawSecureServer from '@/components/icons/undraw/UndrawSecureServer.vue';
import UndrawWallet from '@/components/icons/undraw/UndrawWallet.vue';
import BaseImage from '@/components/core/BaseImage.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputField from '@/components/core/forms/InputField.vue';
import Table from '@/components/table2/Table.vue';
import { TextColumn } from '@/components/table2/table_util';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { baseURLFromPath } from '@/helpers/commonfunctions';

const BASE_GESELLSCHAFTS_FORMULARE_PATH = '/gesellschaft-formulare';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  data() {
    return {
      loadingMainStructure: false,
      descriptionSearch: '',
      loadingByArt: {},
    }
  },
  watch: {
    gesellschaftsMainStructure: {
      handler() {
        this.selectedFirstIfNeeded();
      },
      immediate: true,
    },
    formArt: {
      handler(newValue) {
        this.descriptionSearch = '';
        this.findGesellschaftsFormulare(newValue);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      gesellschaftsMainStructure: ANTRAG_TYPES.GETTERS.GESELLSCHAFTS_MAINSTRUCTURE,
      gesellschaftsFormulare: ANTRAG_TYPES.GETTERS.GESELLSCHAFTS_FORMULARE,
    }),
    customOptionMenu() {
      const componentByLagerstelle = (lagerstelle) => {
        switch (lagerstelle?.group) {
          case 'BANK':
            return UndrawWallet;
          case 'WEITERE_UNTERLAGEN':  
            return UndrawFillForms;
          case 'VERSICHERUNG':
            return UndrawSecureServer;
          default:
            return BaseImage;
        }
      };

      const result = this.gesellschaftsMainStructure.map((lagerstelle) => ({
        ...lagerstelle,
        label: lagerstelle.name,
        component: componentByLagerstelle(lagerstelle),
        componentProps: {
          src: lagerstelle.gesellschaft?.toUpperCase() ? `${this.addressLegacy}/mrscompanylogo?company=${lagerstelle.gesellschaft?.toUpperCase()}` : null,
          fit: 'contain',
          placeholder: {
            width: '78px',
            height: '48px',
          }
        },
        path: `${baseURLFromPath(this.$route.path, BASE_GESELLSCHAFTS_FORMULARE_PATH)}/gesellschaften/${lagerstelle.art}`,
      }));

      return result;
    },
    formArt() {
      return this.$route.params.formArt;
    },
    formArtTitle() {
      const currentFormArt = this.gesellschaftsMainStructure.find(gesell => gesell.art === this.formArt);
      return currentFormArt?.name;
    },
    isLoading() {
      return this.loadingMainStructure || Object.keys(this.loadingByArt).length > 0;
    },
    headers() {
      return {
        lockedLeft: [
          TextColumn('name', 'Label').makeLink(),
        ],
        center: [
          TextColumn('art', 'Art'),
        ],
      };
    },
    rows() {
      const descriptionSearch = this.descriptionSearch?.trim();
      return this.gesellschaftsFormulare
        .filter(r => !descriptionSearch || r.name.search(new RegExp(descriptionSearch, "i")) > -1)
        .map((formItem) => ({
          name: formItem.name,
          gesellschaft: formItem.gesellschaft,
          art: formItem.art,
          group: formItem.group,
        }));
    },
  },
  methods: {
    async findGesellschaftsMainStructure() {
      try {
        this.loadingMainStructure = true;
        await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_GESELLSCHAFTS_MAINSTRUCTURE);
      } finally {
        this.loadingMainStructure = false;
      }
    },
    async findGesellschaftsFormulare(filterArt) {
      try {
        this.$set(this.loadingByArt, filterArt, true);

        const payload = { filterArt };
        await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_GESELLSCHAFTS_FORMULARE, payload);
      } finally {
        this.$delete(this.loadingByArt, filterArt, true);
      }
    },
    openFormList(lagerstelle) {
      this.$router.push({
        path: '/beratung/gesellschaft-formulare/formList', 
        query: {
          gesellschaftId: lagerstelle?.gesellschaft, 
          lagerstelle: lagerstelle?.name
        },
      });
    },    
    selectedFirstIfNeeded() {
      if (this.formArt) return;

      const { path } = this.customOptionMenu?.[0] ?? {};
      if (path) {
        this.$router.replace(path);
      }
    },
  },
  async mounted() {
    await this.findGesellschaftsMainStructure();
  },
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      to,
      from,
    });
    next()
  },
  components: {
    Table,
    InputField,
    GhostLoading,
    PageHeaderTitleNavigation,
  },
}
</script>
