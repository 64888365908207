<template shadowrootmode="closed">
    <fk-fonds-shop :broker="getBroker" :start="zeige" :regcode="regCode" class="fk-fs-element" :offsetscroll="scroll" />
</template>

<script>
import { mapGetters } from "vuex";
import CMS_TYPES from "@/store/cms/types";
import CORE_TYPES from '@/store/core/types';

export default {
  props: {
    zeige: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    ...mapGetters({
      userID: CORE_TYPES.GETTERS.GET_USER_ID,
      cmsUserID: CMS_TYPES.GETTERS.GET_USER_ID,
      getFSParam: CMS_TYPES.GETTERS.GET_FONDSSHOP_PARAMS
    }),
    getBroker() {
      return this.userID || this.cmsUserID;
    },
    regCode() {
      if (this.getFSParam?.regCode?.length === 10 ) {
        return this.getFSParam.regCode;
      }
      return '';
    },
    scroll() {
      return -173;
    }
  }
};
</script>

<style scoped>
.fk-fs-element {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
