var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("GhostLoading", {
            staticClass: "box__container",
            attrs: { type: "table", title: "Sonstiges" }
          })
        : [
            _vm.isIntern
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("InputToggleSwitch", {
                      attrs: { label: "Eigene App", inLineLabel: "" },
                      model: {
                        value: _vm.eigeneApp,
                        callback: function($$v) {
                          _vm.eigeneApp = $$v
                        },
                        expression: "eigeneApp"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        label: "App Name",
                        disabled: !_vm.sonstiges.eigeneApp
                      },
                      model: {
                        value: _vm.appName,
                        callback: function($$v) {
                          _vm.appName = $$v
                        },
                        expression: "appName"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Makler Kriterien")
                ]),
                _c("MaklerKriterien", {
                  attrs: {
                    maklerKriterien: _vm.brokerDataConfig.maklerKriterien,
                    alleStrukturKriterien:
                      _vm.brokerDataConfig.alleStrukturKriterien
                  }
                })
              ],
              1
            ),
            _vm.isIntern || _vm.sonstiges.showUnterMakler
              ? _c("Hobbys", {
                  attrs: {
                    optionen: _vm.sonstiges.hobbyOptionen || [],
                    liste: _vm.sonstiges.hobbies,
                    isEditable: true
                  },
                  on: {
                    setHobbys: function($event) {
                      _vm.hobbies = $event
                    }
                  }
                })
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }