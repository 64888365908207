<template>
  <div class="input-forms__container captcha-input-field__container">
    <div class="captcha-input-field--img">
      <GhostLoading v-if="isLoading" type="block" :config="{ block: { width: 200, height: 50 } }" />
      <img v-else :src="captchaImg" alt="CAPTCHA-Text"/>
    </div>
    <InputField 
      class="captcha-input-field--input p-0 m-0" 
      :label="label" 
      :placeholder="placeholder" 
      v-model="internalValue"
      :renderDanger="isValid === false"
      :validateUntouched="validateUntouched" 
      :validation_path="validationPath" 
      trimValue
      :disabled="disabled || loadingToken" 
      @input="onInput($event)" 
      @change="checkCaptcha($event)" 
    >
      <template #icon>
        <AnimatedSpinner v-if="checking" />
      </template>
    </InputField>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';

import InputField from '@/components/core/forms/InputField.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';

import validatorComponentUtils from '@/mixins/validator/validator-component-utils';

const DEFAULT_LABEL = 'CAPTCHA-Test';
const DEFAULT_PLACEHOLDER = 'Geben Sie bitte den Text vom Bild ein';
const DEFAULT_VALUE = {
  captcha: null,
  captchaToken: null,
};
const MIN_VALUE_LENGTH = 6;

export default {
  name: 'CaptchaInputField',
  mixins: [validatorComponentUtils],
  components: {
    InputField,
    GhostLoading,
    AnimatedSpinner,
  },
  props: {
    label: {
      type: String,
      default: DEFAULT_LABEL,
    },
    placeholder: {
      type: String,
      default: DEFAULT_PLACEHOLDER,
    },
    value: {
      type: Object,
      default: () => DEFAULT_VALUE,
    },
    validateUntouched: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: '',
      loadingToken: false,
      captchaToken: null,
      loadingImg: false,
      captchaImg: null,
      checking: false,
      isValid: null,
    };
  },
  computed: {
    isLoading() {
      return this.loadingToken || this.loadingImg;
    },
  },
  watch: {
    value: {
      handler(value) {
        this.internalValue = value?.captcha;
      },
      immediate: true,
    },
  },
  methods: {
    onInput(captcha) {
      this.isValid = null;
      this.emitInput({
        ...this.value, 
        captcha, 
      });
    },
    async findCaptchaToken() {
      try {
        this.loadingToken = true;
        const captchaToken = await this.$store.dispatch(CORE_TYPES.ACTIONS.GET_CAPTCHA_TOKEN);
        this.captchaToken = captchaToken;
      } finally {
        this.emitInput({ captcha: this.internalValue });
        this.loadingToken = false;
      }
    },
    async findCaptchaImg() {
      try {
        this.loadingImg = true;
        const { captchaToken } = this;
        const captchaImg = await this.$store.dispatch(CORE_TYPES.ACTIONS.GET_CAPTCHA_IMG, { captchaToken });
        this.captchaImg = captchaImg;
      } finally {
        this.loadingImg = false;
      }
    },
    async checkCaptcha(captcha) {
      this.emitInput({ captcha });

      if (captcha.length < MIN_VALUE_LENGTH) {
        this.isValid = false;
        return;
      }

      try {
        this.checking = true;

        const payload = {
          captcha, 
          captchaToken: this.captchaToken, 
        };
        const valid = await this.$store.dispatch(CORE_TYPES.ACTIONS.CHECK_CAPTCHA, payload);
        if (!valid) {
          this.findCaptchaImg();
        }
        this.emitInput(valid ? payload : {});
        this.isValid = valid;
      } finally {
        this.checking = false;
      }
    },
    emitInput({ captcha=null, captchaToken=null } = {}) {
      this.$emit('input', {
        captcha, 
        captchaToken, 
      });
    },
  },
  mounted() {
    this.findCaptchaToken()
      .then(() => this.findCaptchaImg());
  },
}
</script>

<style lang="scss" scoped>
.captcha-input-field__container {
  .captcha-input-field--img {
    margin: 0 0 .375rem;
    max-width: 200px;
    min-height: 50px;
  }
}
</style>
