var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: {
              title: _vm.$appNavigation.currentMenu.label,
              id: _vm.$appNavigation.currentOptionMenuId,
              actions: _vm.headerActions
            },
            on: {
              "action-SAVE": function($event) {
                return _vm.saveShortcut()
              },
              "action-REFRESH": function($event) {
                return _vm.onRefresh()
              }
            }
          },
          _vm.$listeners
        )
      ),
      _c(
        "div",
        [
          _c("InputField", {
            attrs: { label: "Shortcut", maxLength: 1000 },
            on: {
              input: function($event) {
                return _vm.changeShortcut(_vm.shortcut, $event, "key")
              }
            },
            model: {
              value: _vm.shortcut.key,
              callback: function($$v) {
                _vm.$set(_vm.shortcut, "key", $$v)
              },
              expression: "shortcut.key"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Beschreibung", maxlength: 1000 },
            model: {
              value: _vm.shortcut.description,
              callback: function($$v) {
                _vm.$set(_vm.shortcut, "description", $$v)
              },
              expression: "shortcut.description"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: { label: "Für Struktur freigeschaltet" },
            on: {
              input: function($event) {
                return _vm.changeShortcut(_vm.shortcut, $event, "forBroker")
              }
            },
            model: {
              value: _vm.shortcut.forBroker,
              callback: function($$v) {
                _vm.$set(_vm.shortcut, "forBroker", $$v)
              },
              expression: "shortcut.forBroker"
            }
          }),
          _c("HtmlEditor", {
            staticClass: "text-input",
            attrs: {
              isFontParams: "",
              isPreviewButton: "",
              suppressValidationMessage: "",
              startEditorFocused: "",
              validateUntouched: "",
              textPlaceholderOptions: _vm.placeholdersOptions,
              previewHTML: _vm.shortcut.value
            },
            on: {
              input: function($event) {
                return _vm.changeShortcut(_vm.shortcut, $event, "value")
              },
              onTogglePreviewEnabled: _vm.handlePreview,
              isEditorValid: _vm.setEditorValid,
              editor: _vm.initEditor,
              cursorPosition: _vm.updateCursorPosition
            },
            model: {
              value: _vm.shortcut.value,
              callback: function($$v) {
                _vm.$set(_vm.shortcut, "value", $$v)
              },
              expression: "shortcut.value"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }