import SCHADENSMELDUNG_TYPES from './types';
import axios from 'axios';

const config = {
  defaultSpinner: true,
  cancelableRequest: {
    title: 'Schadensuche'
  }  
};

export default {
  [SCHADENSMELDUNG_TYPES.ACTIONS.RETRIEVE_SCHADENSMELDUNGEN]({ commit, rootState }, params) {
    let url = '/schadensmeldungen/listSchaden';

    axios.get(rootState.core.apiAddress + url, {...config, params})
        .then(response => {
            commit(SCHADENSMELDUNG_TYPES.MUTATIONS.RETRIEVE_SCHADENSMELDUNGEN_SUCCESS, response?.data);
        })
        .catch(error => {
            console.error('Error fetching schadensmeldungen:', error);
        });
  }



}