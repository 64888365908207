var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FinanzenKapitalanlage", {
        attrs: {
          disabled: _vm.isDisabled || _vm.isRefusedPageFinance,
          finance: _vm.finance,
          showBondDepotRelevant: _vm.showBondDepotRelevant
        },
        on: { changed: _vm.valueChangedFinance }
      }),
      _c("FinanceLineSum", {
        attrs: {
          summe: _vm.summeAnlage,
          title: "Summe Kapitalvermögen / sonstiger Vermögenswerte"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }