
<!--

        How to use this component:
        
        <DropDownCell 
                :options="[
                  {label: 'nicht  geprüft', value: 'NICHT_GEPRUEFT', state: 'PAUSED'}, 
                  {label: 'geprüft', value: 'GEPRUEFT', state: 'IN_PROGRESS'}, 
                  {label: 'erledigt', value: 'ERLEDIGT', state: 'READY'}, 
                  {label: 'in Bearbeitung', value: 'BEARBEITUNG', state: 'DEFAULT'}]" 
                  @selectOption="selectOption"
                 v-model="row.pruefstatusMapped"/> 


        "row.pruefstatusMapped" should have the following structure:      
        pruefstatusMapped: {label: insurance?.pruefstatus?.label, value: insurance?.pruefstatus?.value, state: 'DEFAULT'},             
                 
                 
-->
<template>
    <div ref="trigger" class="dropdown-trigger"  :style="getStyle(selectedOption)" @click="toggleDropdown">
        <span :title="selectedOption.label || 'Auswählen'">{{ selectedOption.label || "Auswählen" }}</span>
        <PhCaretDown :size="12" />



    </div>
</template>
  
<script>
import {PhCaretDown} from "phosphor-vue"

// State types
export const STATE_SUCCESS = "SUCCESS";  
export const STATE_INFO = "INFO";
export const STATE_DANGER = "DANGER";
export const STATE_DEFAULT = "DEFAULT";

export default {
    name: "DropdownCell",
    props: {
        options: Array,
        value: Object,
        showBackgroundColor: {
            type: Boolean,
            default: false,
        },        
    },
    components: {
        PhCaretDown,
    },
    data() {
        return {
            selectedOption: this.value,
            dropdownEl: null,
            colorSuccess: {
                backgroundColor: 'var(--big-table-dropdown-color-success-background)',
                foregroundColor: 'var(--color-success)'
            },
            colorInfo: {
                backgroundColor: 'var(--big-table-dropdown-color-info-background)',
                foregroundColor: 'var(--color-info)'
            },
            colorDanger: {
                backgroundColor: 'var(--big-table-dropdown-color-danger-background)',
                foregroundColor: 'var(--color-danger)'
            },
            colorDefault: {
                backgroundColor: 'var(--big-table-dropdown-color-default-background)',
                foregroundColor: 'var(--big-table-dropdown-color-default-foreground)'
            },                                       
        }
    },
    methods: {
        toggleDropdown() {
            if (this.dropdownEl) {
                this.closeDropdown();
                return
            }

            const rect = this.$refs.trigger.getBoundingClientRect();

            // Temporary element to measure dropdown height
            const tempDropdown = document.createElement("div");
            tempDropdown.style.position = "absolute";
            tempDropdown.style.visibility = "hidden";
            tempDropdown.style.pointerEvents = "none"
            tempDropdown.style.minWidth = rect.width + "px";
            tempDropdown.style.background = "white";
            tempDropdown.style.border = "1px solid var(--big-table-column-border)";

            this.options.forEach((option) => {
                const item = document.createElement("div");
                item.textContent = option?.label;
                item.style.padding = "6px";
                tempDropdown.appendChild(item);
            })

            document.body.appendChild(tempDropdown);
            const dropdownHeight = tempDropdown.offsetHeight;
            document.body.removeChild(tempDropdown);

            const spaceBelow = window.innerHeight - rect.bottom;
            const spaceAbove = rect.top;

            const shouldOpenAbove = spaceBelow < dropdownHeight && spaceAbove > dropdownHeight;

            // Create actual dropdown
            this.dropdownEl = document.createElement("div");
            this.dropdownEl.className = "global-dropdown";
            this.dropdownEl.style.position = "absolute";
            this.dropdownEl.style.width = 'max-content';
            this.dropdownEl.style.minWidth = "150px";
            const dropdownWidth = this.dropdownEl.style.width;

            const viewportWidth = window.innerWidth;
            const triggerLeft = rect.left + window.scrollX;
            const dropdownContentWidth = this.dropdownEl.scrollWidth || 150; // fallback width
            let left = triggerLeft;

            // Shift left if it overflows to the right
            if (triggerLeft + dropdownContentWidth > viewportWidth) {
                // Align right edge of dropdown with right edge of trigger (or as far left as possible)
                left = Math.max(viewportWidth - dropdownContentWidth - 8, 0); // 8px padding from edge
            }

            // Shrink width if even shifting left doesn’t fit
            let finalWidth = dropdownContentWidth;
            if (left + finalWidth > viewportWidth) {
                finalWidth = viewportWidth - left - 8;
            }

            this.dropdownEl.style.left = left + "px";
            this.dropdownEl.style.minWidth = dropdownWidth + "px";
            this.dropdownEl.style.width = finalWidth + 'px';
            this.dropdownEl.style.boxSizing = 'border-box';
            this.dropdownEl.style.background = "var(--color-box)";
            this.dropdownEl.style.border = "1px solid var(--big-table-column-border)";
            this.dropdownEl.style.borderRadius = "4px";
            this.dropdownEl.style.boxShadow = "0 2px 8px rgba(0,0,0,0.15)";
            this.dropdownEl.style.zIndex = 9999;
            this.dropdownEl.style.padding = "4px 0";

            // Position: above or below
            if (shouldOpenAbove) {
                this.dropdownEl.style.top = rect.top + window.scrollY - dropdownHeight + "px";
            } else {
                this.dropdownEl.style.top = rect.bottom + window.scrollY + "px";
            }

            // Fill options
            this.options.forEach((option) => {
                const item = document.createElement("div");
                item.className = "dropdown-item";
                item.style.padding = "6px";
                item.style.cursor = "pointer";
                item.onclick = () => this.selectOption(option);
                item.style.borderLeft = '2px solid transparent';
                item.style.setProperty('padding', '2px', 'important');

                item.onmouseenter = () => {
                    item.style.setProperty('background-color', 'var(--big-table-highlighted-row)', 'important');
                    item.style.setProperty('border-left-color', 'var(--big-table-dropdown-left-border)', 'important');
                };

                item.onmouseleave = () => {
                    item.style.setProperty('background-color', 'transparent', 'important');
                    item.style.setProperty('border-left-color', 'transparent', 'important');
                };

                const itemText = document.createElement("div");
                itemText.style.setProperty('width', 'fit-content', 'important');
                itemText.style.setProperty('height', 'fit-content', 'important');
                itemText.style.setProperty('font-size', '14px', 'important');
                itemText.style.setProperty('z-index', '2', 'important');
                itemText.style.setProperty('background-color', this.getColorMetadata(option)?.backgroundColor, 'important');
                itemText.style.setProperty('color', this.getColorMetadata(option)?.foregroundColor, 'important');
                itemText.style.setProperty('border-radius', '8px', 'important');
                itemText.style.setProperty('border', 'var(--big-table-dropdown-left-border)', 'important');
                itemText.style.setProperty('overflow', 'hidden', 'important');
                itemText.style.setProperty('padding', '4px', 'important');
                itemText.textContent = option?.label;
                item.appendChild(itemText);

                this.dropdownEl.appendChild(item);
            });

            document.body.appendChild(this.dropdownEl);
            document.addEventListener("mousedown", this.onClickOutside);
        },
        getColorMetadata(option) {
            switch (option?.state) {
                case STATE_SUCCESS:
                return this.colorSuccess;
                case STATE_INFO:
                    return this.colorInfo;
                case STATE_DANGER:
                    return this.colorDanger;
                default:
                    return this.colorDefault;

            }
        },
        getStyle(option) {
            const colorMetadata = this.getColorMetadata(option);
            let result = `color: ${colorMetadata?.foregroundColor};`;

            if (this.showBackgroundColor) {
                result += `background-color: ${colorMetadata?.backgroundColor};`;
            }
            return result;

        },
        selectOption(option) {
            this.selectedOption = option;
            this.$emit("selectOption", option);
            this.closeDropdown();
        },
        onClickOutside(e) {
            if (!this.$refs.trigger.contains(e.target) && !this.dropdownEl.contains(e.target)) {
                this.closeDropdown();
            }
        },
        closeDropdown() {
            if (this.dropdownEl) {
                document.body.removeChild(this.dropdownEl);
                this.dropdownEl = null;
                document.removeEventListener("mousedown", this.onClickOutside);
            }
        },
    },
    beforeDestroy() {
        this.closeDropdown();
    },
}
</script>
  
  
<style scoped>
.dropdown-trigger {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    border-radius: 8px !important;
    width: fit-content !important;
    height: fit-content !important;
    font-size: 14px !important;
    overflow: hidden !important;
    padding: 2px !important;

    max-width: 100%;
}

svg {
    flex-shrink: 0;
}

.dropdown-trigger > span {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 2px;
}

.dropdown-item:hover {
  background-color: var(--big-table-highlighted-row) !important;
}




</style>
  