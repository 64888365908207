<template>
  <div v-if="vertragsdatenFieldsDefinition">

    <div style="display:flex; flex-direction:column"></div>

      <div class="row" v-if="vertragsdatenFieldsDefinition.maklerGesellNr.visible" :style="styleOrder('maklerGesellNr')">
        <div class="col-12 col-sm-6">
          <ComboBox
            :label="isFK ? 'Vermittlernummer Gesellschaft' : 'Courtagenummer'"
            v-model="versicherung.maklerGesellNr"
            :values="vertragsdatenFieldsDefinition.maklerGesellNr.value"
            :disabled="vertragsdatenFieldsDefinition.maklerGesellNr.disabled"
            :firstEmpty="false"
            @change="dataChanged('maklerGesellNr', $event)"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.vermittlernummerBemerkung.visible" :style="styleOrder('vermittlernummerBemerkung')">
        <div class="col-12 col-sm-6">
          <InputTextArea
            v-model="versicherung.vermittlernummerBemerkung"
            label="Bemerkung Vermittlernummer"
            :disabled="vertragsdatenFieldsDefinition.vermittlernummerBemerkung.disabled"
            autoGrow
            hideScrollBar
          />
        </div>
      </div>      
      <div class="row" v-if="vertragsdatenFieldsDefinition.bezug.visible" :style="styleOrder('bezug')">
        <div class="col-12 col-sm-6">
          <ComboBox
            label="Bezug"
            v-model="versicherung.bezug"
            :values="vertragsdatenFieldsDefinition.bezug.value"
            :disabled="vertragsdatenFieldsDefinition.bezug.disabled"
            :validateUntouched="true"
            firstEmpty
            @change="dataChanged('bezug', $event)"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.bemerkungStatus.visible" :style="styleOrder('bemerkungStatus')">
        <div class="col-12 col-sm-6">
          <InputField
            v-model="versicherung.bemerkungStatus"
            label="Bemerkung Status"
            :disabled="vertragsdatenFieldsDefinition.bemerkungStatus.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>      
      <div class="row" v-if="vertragsdatenFieldsDefinition.interneNummer.visible" :style="styleOrder('id')">
        <div class="col-12 col-sm-6">
          <InputField
            v-model="versicherung.id"
            label="Interne Nummer"
            :disabled="vertragsdatenFieldsDefinition.interneNummer.disabled"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.kundennr.visible" :style="styleOrder('kundennr')">
        <div class="col-12 col-sm-6 form-control">
          <InputField
            class="align-end"
            v-model="versicherung.kundennr"
            label="Kundennummer"
            :disabled="vertragsdatenFieldsDefinition.kundennr.disabled"
          />
          <a class="align-end" style="flex-grow: 0;" @click="kundennrAendern()" v-if="canChangeVertragKundennr">
            <PhPencilLine :size="24"/>
          </a>   
        </div>
      </div>    
      <div class="row" v-if="vertragsdatenFieldsDefinition.geteiltmitKunde.visible" :style="styleOrder('geteiltmitKunde')">
        <div class="col-12 col-sm-6 form-control">
          <InputField
            class="align-end"
            v-model="versicherung.geteiltmitKunde"
            label="Geteilt mit Kundennummer"
            :disabled="vertragsdatenFieldsDefinition.geteiltmitKunde.disabled"
            @input="dataChanged()"
          />
          <a class="align-end" style="flex-grow: 0;" @click="openCustomerNewTab(versicherung.geteiltmitKunde)">
            <PhArrowSquareOut   :size="24"/>
          </a>            
          <a class="align-end" style="flex-grow: 0;" @click="handleAddUsers">
            <PhBookOpen :size="24"/>
          </a>        
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.erfassungsdatum.visible" :style="styleOrder('erfassungsdatum')">
        <div class="col-12 col-sm-6">
          <DatePickerField
            v-model="versicherung.erfassungsdatum"
            label="Erfassungsdatum"
            :disabled="!isNewVertrag || vertragsdatenFieldsDefinition.erfassungsdatum.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.autoUpdate.visible" :style="styleOrder('autoUpdate')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            :label="vertragsdatenFieldsDefinition.autoUpdate.value"
            v-model="versicherung.einlesen_updaten"
            :disabled="vertragsdatenFieldsDefinition.autoUpdate.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.letzteGDVAnderung.visible" :style="styleOrder('letzteGDVAnderung')">
        <div class="col-12 col-sm-6">
          <InputField
            v-model="versicherung.dateUpdatetdGDV"
            :label="`Letzte ${isFA ? 'OMDS Änderung' : 'GDV-Datenänderung'} `"
            :disabled="vertragsdatenFieldsDefinition.letzteGDVAnderung.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.pramieBruttoJahr.visible" :style="styleOrder('pramieBruttoJahr')">
        <div class="col-12 col-sm-6">
          <InputField
            type="currency"
            :precision="2"
            v-model="pramieBruttoJahr"
            label="Bruttoprämie pro Jahr"
            :disabled="vertragsdatenFieldsDefinition.pramieBruttoJahr.disabled"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.pramieNettoJahr.visible" :style="styleOrder('pramieNettoJahr')">
        <div class="col-12 col-sm-6">
          <InputField
            type="currency"
            :precision="2"
            v-model="pramieNettoJahr"
            label="Nettoprämie pro Jahr"
            :disabled="vertragsdatenFieldsDefinition.pramieNettoJahr.disabled"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.bestandsubertragung.visible" :style="styleOrder('bestandsubertragung')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Bestandsübertragung"
            v-model="versicherung.bestandsubertragung"
            :disabled="vertragsdatenFieldsDefinition.bestandsubertragung.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.alleRechtePflichten.visible" :style="styleOrder('alleRechtePflichten')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Inkl. aller Rechte/Pflichten"
            v-model="versicherung.alleRechtePflichten"
            :disabled="vertragsdatenFieldsDefinition.alleRechtePflichten.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.korrespondenz.visible" :style="styleOrder('korrespondenz')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="Korrespondenz"
            v-model="versicherung.korrespondenz"
            :disabled="vertragsdatenFieldsDefinition.korrespondenz.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.buAbgelehnt.visible" :style="styleOrder('buAbgelehnt')">
        <div class="col-12 col-sm-6">
          <InputToggleSwitch
            label="BÜ abgelehnt"
            v-model="versicherung.buAbgelehnt"
            :disabled="vertragsdatenFieldsDefinition.buAbgelehnt.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.letzteAnderung.visible" :style="styleOrder('letzteAnderung')">
        <div class="col-12 col-sm-6">
          <InputField
            v-model="versicherung.letzteAnderung"
            label="Letzte Änderung"
            :disabled="vertragsdatenFieldsDefinition.letzteAnderung.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>
      <div class="row" v-if="vertragsdatenFieldsDefinition.vertragssumme.visible" :style="styleOrder('vertragssumme')">
        <div class="col-12 col-sm-6">
          <InputField
            type="currency"
            :precision="2"
            v-model="versicherung.vertragssumme"
            label="Vertragssumme"
            :disabled="vertragsdatenFieldsDefinition.vertragssumme.disabled"
            @input="dataChanged()"
          />
        </div>
      </div>

    </div>
    

  </div>
</template>


<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import BoxContainer from "@/components/core/BoxContainer.vue";
import InputCheckBoxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import InputRadioBoxItem from "@/components/core/forms/radiobox/InputRadioBoxItem.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import { PhNotePencil, PhCopy, PhInfo, PhNoteBlank, PhPencilLine, PhBookOpen, PhArrowSquareOut } from "phosphor-vue";
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import InsuranceDetailMixin from './InsuranceDetail-mixin.js';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import validator from '@/mixins/validator';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";

export default {
  mixins: [InsuranceDetailMixin, validator],
  components: {
    BoxContainer,
    InputCheckBoxItem,
    InputRadioBoxItem,
    InputRadioBoxGroup,
    ComboBox,
    InputField,
    DatePickerField,
    PhNotePencil,
    PhCopy,
    PhInfo,
    PhNoteBlank,
    EntitySelector,
    InputToggleSwitch,
    PhPencilLine, 
    PhBookOpen, 
    PhArrowSquareOut,
    InputTextArea,
  },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA, 
    }),
    tableId() {
            const uniqueUUID = '87a21185-5360-4f71-b7f8-583d23be53df';
            const vertragsparte = this.vertragsdatenFieldsDefinition?.vertragssparte?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.description?.id)
            return `${uniqueUUID}-${vertragsparte?.label}`;
    },    
   
  },
  methods: {
    kundennrAendern() {
      let payload =  {
        beschreibung: this.versicherung.beschreibung,
      }
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_VERTRAGS_VERSCHIEBUNGEN_DATA, payload)
      
      if(this.$route.params.id) {
        this.$router.push({ path: `/home/versicherungen/kundennr-aendern/${this.$route.params.id}`})
      }
    },  
    handleAddUsers() {
      this.navigateTo('/communication/mailcomposer-customer-search/allgemeineVertragsdaten?singleSelection=true&insurance=true&back=' + this.getCurrentRoute())
    },    
    async validateNummer(serverError) {
      const nummerPath = 'versicherung.nummer';

      this.validation.reset(nummerPath);
      if(!this.versicherung.gesellschaft.id) {
        return;
      }

      if(serverError) {
        this.$pushErrors(nummerPath, serverError);
        return;
      }

      const response = await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_POLNR_RUN_VALIDATION, 
        { gesellschaftId: this.versicherung.gesellschaft.id, polNummer: this.versicherung.nummer });
      if(this.versicherung.nummer && !response?.valid){
        this.$pushErrors(nummerPath, response?.validationMessage || '');
      } else {
        this.validation.reset(nummerPath);
      }

      if(this?.versicherungFormValidation?.versicherung?.nummer.valid && this.versicherung?.fehler?.length){
        const index = this.versicherung?.fehler.findIndex(errorMsg => errorMsg.includes('Nummer'));
        if(index > -1) {
          this.versicherung.fehler.splice(index, 1);
        }
      }
    },      
  },
  mounted() {
    this.$configureValidators(this.fieldsValidators);
    if(this.versicherung?.gesellschaft?.id && this.vertragsdatenFieldsDefinition?.polNr){
      this.validateNummer();
    }
  },  
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 8px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>