<template>
    <div>
        <PageHeaderTitleNavigation
            :title="title"
            :id="$appNavigation.currentOptionMenuId"
            :defaultMenu="optionsMenu"
            :showBackButton="false"
        />

        <GhostLoading v-if="loading" type="block" :config="{block: {height: 450}}" :repeat="4" :inline="true" />
        <div class="cards__container layout__negative-margin--8" v-else-if="Object.keys(signatures).length > 0">
            <div
                class="box__container cards__item"
                v-for="(signature, fileId) in signaturesForAuthor"
                :key="signature.title"
            >
                <div class="cards__item-header">
                    <div class="box__title breakable-words">
                        <DownloadLink
                            :title="signature.title"
                            downloadServicePath="/get_simple_file"
                            :filename="signature.title"
                            :queryParams="{fileId: fileId}"
                        />
                    </div>
                </div>
                <div v-if="signature.img">
                    <img :src="signature.img" class="signature-img" />
                </div>
                <div class="action-button-list mt-3">
                    <InputToggleSwitch
                        label="Abgelehnt"
                        @input="onChangeSignatureAbgelehnt($event, signature.title, fileId)"
                    />
                </div>
            </div>
        </div>
        <div v-else>
            <NoData />
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex"
import VV_TYPES from "@/store/vermoegensverwaltung/types"
import CORE_TYPES from "@/store/core/types"
import NoData from "@/components/core/NoData.vue"
import DownloadLink from "@/components/core/download/DownloadLink.vue"
import InputToggleSwitch from "../core/forms/InputToggleSwitch.vue"
import {SIGNATURE_AUTHOR_TYPES} from "@/components/suitabilityTest/SuitabilityTest.vue"
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue"
import GhostLoading from "@/components/core/loading/GhostLoading.vue"

export default {
    components: {
        NoData,
        DownloadLink,
        InputToggleSwitch,
        PageHeaderTitleNavigation,
        GhostLoading,
    },
    props: {
        loading: {
            //is also used to reset this.fehler, since components do not get reloaded when base url (of suitability test) doesnt change
            type: Boolean,
        },
    },
    data: function () {
        return {
            antraege: [],
            fehler: [],
            rejectedFileIds: [],
            title: "Unterschriften",
            signatureAuthor: SIGNATURE_AUTHOR_TYPES.ALLE,
        }
    },
    computed: {
        ...mapGetters({
            API_ADDRESS: CORE_TYPES.GETTERS.API_ADDRESS,
            suitabilityResult: VV_TYPES.GETTERS.SUITABILITYRESULT,
            signatures: VV_TYPES.GETTERS.SUITABILITYSIGNATURES,
        }),
        signaturesForAuthor() {
            return this.signatures[this.signatureAuthor.key]
        },
        secondDepotinhaber() {
            return this.suitabilityResult?.zusaetzlichePersonen.find((p) => {
                return p.relationship.extraDepotOwner
            })
        },
        optionsMenu() {
            return Object.keys(SIGNATURE_AUTHOR_TYPES)
                .filter((type) => {
                    return type != SIGNATURE_AUTHOR_TYPES.DEPOTINHABER_2.key || this.secondDepotinhaber //Removes DEPOTINHABER_2 option from the menu, if there is no 2. Depotinhaber
                })
                .map((key) => {
                    return {
                        action: () => {
                            this.signatureAuthor = SIGNATURE_AUTHOR_TYPES[key]
                        },
                        label: SIGNATURE_AUTHOR_TYPES[key].label,
                        textBold: this.signatureAuthor === SIGNATURE_AUTHOR_TYPES[key],
                    }
            })
        },
    },
    watch: {
        signatureAuthor: {
            handler() {
                this.onChangeSignatureAuthor()
            },
            immediate: true,
        },
        loading: {
            handler() {
                this.fehler = []
                this.rejectedFileIds = []
            },
            immediate: true,
        },
    },
    methods: {
        onChangeSignatureAbgelehnt(isAbgelehnt, abgelehnteSignaturTitle, fileId) {
            const message = `${abgelehnteSignaturTitle}`
            if (isAbgelehnt) {
                this.fehler.push(message)
                this.rejectedFileIds.push(fileId)

            } else {
                //checkbox was unchecked so remove error if it exists
                const index = this.fehler.indexOf(message)
                if (index > -1) this.fehler.splice(index, 1)

                const indexFile = this.rejectedFileIds.indexOf(fileId)
                if (indexFile > -1) this.rejectedFileIds.splice(indexFile, 1)

            }

            this.$emit("rejected", this.fehler, this.rejectedFileIds)
        },
        onChangeSignatureAuthor() {
            this.$emit("signatureAuthor", this.signatureAuthor)
        },
    },
}
</script>
<style scoped>
.breakable-words {
    display: flex;
    flex-wrap: wrap;
    word-wrap: break-word;
    word-break: break-word;
}

.action-button-list {
    display: flex;
    justify-content: flex-end;
}

.cards__container {
    display: flex;
    flex-wrap: wrap;
}

.cards__item {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
}

.cards__item-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}

@media (min-width: 992px) {
    .cards__item {
        flex: 1 1 calc(32% - 16px);
    }
}

.signature-img {
    max-height: 350px;
    cursor: pointer;
}
</style>