import VERSICHERUNGSTARIFE_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';
import LOG_TYPES from '@/store/log/types';

export default {
  [VERSICHERUNGSTARIFE_TYPES.ACTIONS.RETRIEVE_VERSICHERUNGSTARIFE_DATA]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Versicherungstarife'
      }      
    };

    let serviceUrl = '/versicherungstarife/getVersicherungstarife';

    const params = payload;

    return new Promise((resolve, reject) => {
      axios.get(rootState.core.apiAddress + serviceUrl, {params, ...config}).then(response => {
        if (response && response.data) {
  
          commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RETRIEVE_VERSICHERUNGSTARIFE_SUCCESS, response.data);
          resolve(response.data);
        } else {
          reject();
        }     
  
      });
    });

  },

  [VERSICHERUNGSTARIFE_TYPES.ACTIONS.RETRIEVE_FILTER_METADATA]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
    };

    let serviceUrl = '/versicherungstarife/getFilterMetadata';

    return new Promise((resolve, reject) => {
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
          commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.VERSICHERUNGSTARIFE_GESELLSCHAFTEN, response.data.gesellschaften);
          commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.VERSICHERUNGSTARIFE_SPARTENTYPEN, response.data.spartenTypen);
          resolve(response.data);
        } else {
          reject();
        }     
  
      });
    });

  },


  [VERSICHERUNGSTARIFE_TYPES.ACTIONS.PERSIST_ROW]({ getters, dispatch, commit }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versicherungstarife/persist`, payload, config)
        .then((response) => {
          commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.PERSIST_ROW_SUCCESS, response.data);
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Tarifdaten erfolgreich gespeichert.', 'info'));
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },  

  [VERSICHERUNGSTARIFE_TYPES.ACTIONS.REMOVE_ROW]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };    
   
    let url = '/versicherungstarife/removeItem';

    if(payload && payload.id) {
      url = `${url}?id=${payload.id}`;
    }

    return new Promise((resolve, reject) => {
      axios.delete(rootState.core.apiAddress + url, config).then(response => {
        if (response?.data) {
          commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.REMOVE_ROW_SUCCESS, response.data);
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Tarif wurde erfolgreich gelöscht.', 'info'));
          resolve(response.data);
        } else {
          reject();
        }
      })
    });


  
}, 

[VERSICHERUNGSTARIFE_TYPES.ACTIONS.REMOVE_DOCUMENT]({ commit, state, dispatch, rootState }, payload) {
  const config = {
    defaultSpinner: true
  };    
 
  let serviceUrl = '/versicherungstarife/removeDocument';

  if(payload && payload.tarifId) {
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'tarifId', payload.tarifId);
  }

  if(payload && payload.documentId) {
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'documentId', payload.documentId);
  }  

  return new Promise((resolve, reject) => {
    axios.delete(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response?.data) {
        commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.REMOVE_DOCUMENT_SUCCESS, response.data);
        resolve(response.data);
      } else {
        reject();
      }
    })
  });



},

[VERSICHERUNGSTARIFE_TYPES.ACTIONS.RETRIEVE_TARIF_DOKUMENTE]({ commit, dispatch, rootState }, payload) {
  const config = {
    defaultSpinner: true
  };

  let serviceUrl = '/versicherungstarife/getTarifDokumente';
  const paramId = payload?.id;

  if (paramId) {
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'id', paramId);
  }  

  return new Promise((resolve, reject) => {
    axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {
        commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RETRIEVE_TARIF_DOKUMENTE_SUCCESS, response.data);
        resolve(response.data);
      } else {
        reject();
      }     
  
    })
  });
}, 

[VERSICHERUNGSTARIFE_TYPES.ACTIONS.UPLOAD_TARIF_DOKUMENT]({ commit, dispatch, rootState }, payload) {
  const config = {
    defaultSpinner: true
  };

  let serviceUrl = '/versicherungstarife/addTarifDokument';


  const paramId = payload?.id;
  const paramFileId = payload?.fileId;

  if (paramId) {
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'id', paramId);
  }  

  if (paramFileId) {
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'fileId', paramFileId);
  }   

  axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
    if (response && response.data) {
      commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.UPLOAD_TARIF_DOKUMENT_SUCCESS, response.data);
    }      

  })
},

[VERSICHERUNGSTARIFE_TYPES.ACTIONS.RETRIEVE_SPARTE]({ commit, dispatch, rootState }, payload) {
  const config = {
    defaultSpinner: true
  };

  let serviceUrl = '/versicherungstarife/getSparte';
  const paramGesellschaftId = payload?.gesellschaftId;

  if (paramGesellschaftId) {
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'id', paramGesellschaftId);
  }  

  return new Promise((resolve, reject) => {
    axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {
        commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RETRIEVE_SPARTE_SUCCESS, response.data);
        resolve(response.data);
      } else {
        reject();
      }     
  
    })
  });
}, 

[VERSICHERUNGSTARIFE_TYPES.ACTIONS.VORGABE_TARIFDATEN]({ commit, dispatch, rootState }, payload) {
  const config = {
    defaultSpinner: true
  };

  let serviceUrl = '/versicherungstarife/getVorgabeTarifdatenDefinitions';

  if(payload && payload.tarifId) {
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'tarifId', payload.tarifId);
  } 

  return new Promise((resolve, reject) => {
    axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {
        commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.VORGABE_TARIFDATEN_SUCCESS, response.data);
        resolve(response.data);
      } else {
        reject();
      }     
  
    })
  });
}, 

[VERSICHERUNGSTARIFE_TYPES.ACTIONS.PERSIST_VORGABE]({ getters, dispatch, commit }, payload) {
  const config = {
    defaultSpinner: true
  };

  return new Promise((resolve, reject) => {
    axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versicherungstarife/persistVorgabe`, payload, config)
      .then((response) => {
        commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.PERSIST_VORGABE_SUCCESS, response.data);
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}, 

}