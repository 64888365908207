<template>
  <div>
    <BaseModal
      ref="mailContacts"
      modalTitle="Kontakte"
      size="lg"
      :showCancelButton="false"
      :showConfirmButton="false">
      <InsuranceCompanyMailContacts isSingleSelection :records="records" @onSelectParticipant="onSelectParticipant" />
    </BaseModal>


  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import InsuranceCompanyMailContacts from './InsuranceCompanyMailContacts.vue'

export default {
    components: {
      BaseModal,
      InsuranceCompanyMailContacts,
    },
    props: {
      records: {
        type: Array,
        default: () => [],
      },    
    },
    data() {
      return {
        selectedRowsValues: [],
      }
    },
    computed: {
    },
    methods: {
        onSelectParticipant(whatParticipant) {
            this.$emit("select-bearbeiter", whatParticipant);
            this.$refs.mailContacts.close();
        },        
        open() {
            this.$refs.mailContacts.open();
        },
        selectedRows(rows) {
            this.selectedRowsValues = rows;
        },
    }
}
</script>
