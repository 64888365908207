<template>
  <div>
    <FinanzenGrundlage :disabled="isDisabled || isRefusedPageFinance" :finance="finance" :isAnlegerprofil="true" 
      @changed="valueChangedFinance"/>
    
    <FinanceLineSum  :summe="summeEinkommen" title="Summe des derzeit regelmäßigen Einkommens (mtl.)"/>
  </div>
</template>

<script>

import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import FinanzenGrundlage from '@/components/anlegerprofil/FinanzenGrundlage.vue';
import FinanceLineSum from '@/components/anlegerprofil/FinanceLineSum.vue';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    FinanceLineSum,
    FinanzenGrundlage,
  },
  mounted() {
    this.updateWarnings();
  },
}
</script>

<style scoped>
</style>