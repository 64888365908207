<template>
  <div>
    <div v-if="goal">
      <div class="dashboard-panel__widgets">
        <div class="box__container dashboard-panel-widget">
          <ImageUpload
            class="scan-container"
            :title="goal.title || 'Mein Ziel'"
            hoverText="Mein Ziel hier ablegen"
            edgeCorrection
            :image="image"
            :loading="loading"
            :disabled="loading"
            :config="imageUploadConfig"
            hasDeleteButton
            photoCamera="back"
            photoHighQuality
            uploadButtonText="Bild hochladen"
            @file="uploadMyGoalImage($event)"
            @change="uploadMyGoalImage($event)"
            @delete="deleteFile"
          />
        </div>
        <div class="box__container dashboard-panel-widget">
          <MyGoalMoneyChart
            :data="chartData"
            :labels="chartLabels" />
        </div>

        <div class="box__container dashboard-panel-widget">
          <Table
            ref="myGoalTable"
            tableId="dac58861-120a-4f32-a469-f55f487e86a4"
            rowId="name"
            hidePagination
            :headers="headers"
            :rows="rows"
            @click-action="editForm"
          >
            <template v-slot:value="row">
              <div class="font-bold">{{ formatValue(row.value, row.type) }}</div>
            </template>
          </Table>
        </div>

        <div class="box__container dashboard-panel-widget">
          <VirtuelleDepots />
        </div>

      </div>

      <BaseModal 
        ref="editFieldModal"
        modalTitle="Meine Ziele anpassen"
        labelButtonConfirm="Anwenden"
        labelButtonCancel="Abbrechen"
        :showDefaultButtons="editFieldModalHasDefaultButtons"
        :confirmDisabled="!editFieldValid"
        @onConfirmButton="updateCalculation()">

          <InputField v-if="editedField === 'startkapital'"
            label="Vorhandenes Kapital"
            v-model="form.startkapital"
            type="currency" />
          <div v-else-if="editedField === 'zielsumme'">
            <InputField 
              label="Benötigtes Kapital"
              v-model="form.zielsumme"
              type="currency" />
              <div class="color-danger" v-if="!editFieldValid">
                {{zielsummeInvalidText}}
              </div>
          </div>
          <InputField v-else-if="editedField === 'rendite'"
            label="Wertentwicklung"
            v-model="form.rendite"
            precision="2"
            type="percent" />
          <div v-else-if="editedField === 'endDate'">
            <DatePickerField 
              label="Zieldatum (Ende)"
              isValueAsString
              validateUntouched
              monthPicker
              v-model="form.endDate" />
            <div class="color-danger" v-if="!editFieldValid">
              Zielsdatum is nicht gültig. Bitte korrigieren.
            </div>
          </div>
          <div v-else-if="editedField === 'startDate'">
            <DatePickerField 
              label="Startdatum (Beginn)"
              isValueAsString
              validateUntouched
              monthPicker
              v-model="form.startDate" />
            <div class="color-danger" v-if="!startDateValid()">
              Startdatum is nicht gültig. Bitte korrigieren.
            </div>  
          </div>
          <div v-else-if="editedField === 'sparrate'">
            <InputField v-if="isSparrateEditable"
              label="Sparrate pro Monat"
              v-model="form.sparrate"
              type="currency" />
            <div v-if="!isImmobilie">
              <div v-if="isSparrateEditable">
                <div>Möchten Sie, dass sich diese Änderung auf die Höhe des benötigtes Kapital oder auf das Ziel Datum auswirkt?</div>
                <div class="mt-4 layout__negative-margin--8 antrag-action-button__container">
                  <BaseButton isSecondary @click="$refs.editFieldModal.close()">Abbrechen</BaseButton>
                  <BaseButton @click="updateCalculation('endDate')" :disabled="sparrateButtonDisabled">Zieldatum (Ende)</BaseButton>
                  <BaseButton @click="updateCalculation('zielsumme')" :disabled="sparrateButtonDisabled">Benötigtes Kapital</BaseButton>
                </div>
              </div>
              <div v-else>
                <div class="mb-3">Sie erhalten das benötigtes Kapital ohne Sparraten mit Kapitalüberschuss. Bitte den Betrag von "benötigtes Kapital" anpassen.</div>
                <BaseButton @click="$refs.editFieldModal.close()">Ok</BaseButton>
              </div>
            </div>
          </div>
      </BaseModal>

    </div>
    <div v-else-if="!goal">
      <GhostLoading useBoxContainer type="block" :config="{ block: { height: 280, }, }" />
      <GhostLoading useBoxContainer type="table" :config="{ table: { rows: 10, }, }" />
    </div>

  </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import MY_GOALS_TYPES from '@/store/myGoals/types';
import MyGoalMoneyChart from './MyGoalMoneyChart.vue';
import FinancialCalculator from '../retirementScenario/financialCalculator';
import { goalTypes } from '@/components/retirementScenario/types.js';
import { getLifeExpectation } from './calculations/my-goal-calculations.retirement';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import InputField from '@/components/core/forms/InputField.vue';
import { PhPencilLine } from 'phosphor-vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import mixin from './my-goals-mixin.js';
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import ImageUpload from '@/components/fileUpload/ImageUpload.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, IconColumn, Icon} from "@/components/table2/table_util.js";
import dayjs from 'dayjs';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import VirtuelleDepots from "@/components/myGoals/VirtuelleDepots.vue";

export default {
  mixins: [mixin],
  components: {
    BaseButton,
    BaseModal,
    MyGoalMoneyChart,
    AnimatedSpinner,
    InputField,
    PhPencilLine,
    GhostLoading,
    DatePickerField,
    ImageUpload,
    PageHeaderTitleNavigation,
    Table,
    VirtuelleDepots,
  },
  data () {
    return {
      goalTypes: goalTypes,
      editedField: '',
      imagePreview: null,
      form: {
        startkapital: '',
        sparrate: '',
        zielsumme: '',
        endDate: '',
        startDate: '',
        rendite: '',
      },
      loading: false,
      imageUploadConfig: {
        height: "212px",
        fit: "contain",
      },
      headers: {
        lockedLeft: [
          TextColumn("name", ""),
          SlotColumn("value", ""),
          IconColumn("action", "").makeLink(),
        ],
        center: [],
        lockedRight: [],
      },
    }
  },
  computed: {
    image() {
      if(this.imagePreview) {
        return this.imagePreview;
      } else {
        return this.myGoalImage;
      }
    },
    editFieldValid() {
      switch (this.editedField) {
        case 'zielsumme': 
          return this.zielsummeValid(this.form.zielsumme);
        case 'endDate': 
          return this.endDateValid(this.form.endDate);
        case 'startDate': 
          return this.startDateValid();

        default:
          return true;
      }
    },
    editFieldModalHasDefaultButtons() {
      return this.editedField !== 'sparrate' || this.isImmobilie;
    },
    chartLabels() {
      return this.isImmobilie ? ['Vorhandenes Kapital', 'Angespartes Kapital', 'Finanzierungsbedarf']
        : ['Vorhandenes Kapital', 'Summe Sparraten', 'Wertentwicklung'];
    },
    chartData() {
      const sparraten = this.goal.sparrate * (this.anzahlRaten || 1);
      const zinsen = !this.rendite || this.goal.startkapital >= this.goal.zielsumme || this.goal.sparrate >= this.goal.zielsumme
        ? 0 : (this.goal.zielsumme - this.goal.startkapital - sparraten); 

      return this.isImmobilie ? [
        this.goal.startkapital,
        this.calculateAngespartesKapital,
        this.calculateFinanzierungsbedarf
      ] : [
        this.goal.startkapital,
        sparraten,
        this.goal.endDate ? zinsen : 0,
        this.goal.endDate ? 0 : zinsen,
      ];
    },
    rows() {
      const rows = [
        { name: 'Benötigtes Kapital', value: this.goal.zielsumme, key: 'zielsumme', type: 'currency', action: Icon("PhPencilLine", "Editieren") },
        { name: 'Vorhandenes Kapital', value: this.goal.startkapital, key: 'startkapital',type: 'currency', action: this.hasDepots ? '' : Icon("PhPencilLine", "Editieren") },
        { name: 'Sparrate pro Monat', value: this.goal.sparrate, key: 'sparrate',type: 'currency', action: this.hasDepots ? '' : Icon("PhPencilLine", "Editieren") },
        { name: 'Anzahl an Raten', value: this.goal.sparrate ? (this.anzahlRaten || 1) : '' },
        { name: 'Startdatum (Beginn)', value: this.goal.startDate, key: 'startDate',type: 'date', action: Icon("PhPencilLine", "Editieren") },
        { name: 'Zielsdatum (Ende)', value: this.goal.endDate, key: 'endDate',type: 'date', action: this.hasDepots ? '' : Icon("PhPencilLine", "Editieren") },
        { name: 'Wertentwicklung', value: this.goal.rendite, key: 'rendite',type: 'percent', action: this.hasDepots ? '' : Icon("PhPencilLine", "Editieren") },
      ];
      if (this.isImmobilie) {
        rows.push(... [{ name: 'Angespartes Kapital', value: this.calculateAngespartesKapital, type: 'currency' },
        { name: 'Finanzierungsbedarf', value: this.calculateFinanzierungsbedarf, type: 'currency' }])
      }
      return rows;
    },
    sparrateButtonDisabled() {
      return this.editedField === 'sparrate' && this.isSparrateEditable && this.form?.sparrate === this.goal?.sparrate;
    }
  },
  methods: {
    startDateValid() {
      const startDate = !this.form.startDate ? null : (typeof this.form.startDate === 'string' ? dayjs.utc(this.form.startDate, 'MM.YYYY').toDate() : dayjs(this.form.startDate).toDate());
      const endDate = !this.goal.endDate ? null : (typeof this.goal.endDate === 'string' ? dayjs.utc(this.goal.endDate, 'MM.YYYY').toDate() : dayjs(this.goal.endDate).toDate());
      const isValid = !startDate || !endDate || startDate <= endDate;
      return isValid;    
    },
    editForm(field) {
      if (field?.key) {
        this.editedField = field?.key;
        this.form[this.editedField] = this.goal[this.editedField];
        this.$refs.editFieldModal.open();
      }
    },
    updateCalculation(sparrateUpdateType) {
      switch (this.editedField) {
        case 'zielsumme':
        case 'startkapital':
        case 'startDate':
        case 'endDate':
        case 'rendite':
          this.doChanges(this.editedField, this.form[this.editedField])
          this.form[this.editedField] = '';
          break;

        case 'sparrate':
          this.doChanges('sparrate', this.form.sparrate);
          
          if (!this.isImmobilie) {
            if (sparrateUpdateType === 'endDate') {
              this.updateEndDate();
              // wenn der die Sparrate plus Startkapital GRÖSSER ODER GLEICH der Zielsumme sind, wird ein Modal mit dem Glückwunsch angezeigt
              if (this.form.sparrate && +this.form.sparrate + (this.goal.startkapital || 0) >= this.goal.zielsumme) {
                this.$confirmModal({
                  title: 'Ziel erreicht', 
                  message: 'Glückwunsch! Sie haben Ihres Ziel erreicht!' 
                    + (+this.form.sparrate + (this.goal.startkapital || 0) > this.goal.zielsumme ? 'Der Betrag von \"benötigtes Kapital\" wurde angepasst.' : ''), 
                  showCancelButton: false,
                  labelButtonConfirm: 'Ok',
                });
                // wenn der die Sparrate plus Startkapital GRÖSSER der Zielsumme sind, wird die Zielsumme neu berechnet
                if (+this.form.sparrate + (this.goal.startkapital || 0) > this.goal.zielsumme) {
                  this.updateZielsumme();
                }
              }
            } else {
              this.updateZielsumme();
            }
          }

          this.form.sparrate = '';
          break;
      }
      this.$refs.editFieldModal.close();
    },
    uploadMyGoalImage(file) {
      this.loading = true;
      this.imagePreview = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.onload = () => {
        let base64string = reader.result.split(",").pop();
        const payload = {
          fileName: file.name,
          data: base64string
        };
        this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.UPLOAD_FILE, {goalId: this.id, file: payload})
        .finally(() => {
          this.loading = false;
        });
      }
      reader.readAsDataURL(file);
    },
    deleteFile() {
      this.imagePreview = null;
      this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.DELETE_FILE, this.id);
    },
  },
};
</script>

<style src='./my-goals.scss' lang='scss' lang="scss" scoped></style>