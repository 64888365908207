var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isDialogFVV
        ? _c("InputField", {
            attrs: {
              label: "Bargeld",
              type: "currency",
              disabled: _vm.disabled,
              isComponentHalfSize: ""
            },
            on: {
              input: function($event) {
                return _vm.updateStore("cash", $event || null)
              }
            },
            model: {
              value: _vm.form.cash,
              callback: function($$v) {
                _vm.$set(_vm.form, "cash", $$v)
              },
              expression: "form.cash"
            }
          })
        : _vm._e(),
      _c("InputField", {
        staticClass: "font-bold",
        attrs: {
          label:
            "täglich verfügbare Bankeinlagen (z.B. Tagesgeld, Kontokorrent)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("freeBankDeposit", $event || null)
          }
        },
        model: {
          value: _vm.form.freeBankDeposit,
          callback: function($$v) {
            _vm.$set(_vm.form, "freeBankDeposit", $$v)
          },
          expression: "form.freeBankDeposit"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }