<template>
  <div>
    <BaseModal 
      ref="openSignatureAdditionalDocuments"
      labelButtonConfirm="Ok"
      modalTitle="zusätzliche Dokumente"
      :showDefaultButtons="false"
      @close="close()"
    >
      <div>
        <div class="mb-4">
          <div class="box__title">{{title}}</div>
            <div>
              <span class="font-bold" :class="statusClass">{{statusStr}}</span>
              <span v-if="missingSignatureDescription">{{ missingSignatureDescription }}</span>
          </div>
        </div>

        <div class="row" v-for="(file, index2) in selectedAdditionalDocuments" :key="index2">
          <div class="col-12">
            <div class="d-flex">
              <template v-if="canGenerateSignoViewerLink(file)"> 
                <PhFile :size="24"/>
                <DownloadLink 
                  :title="file.name || 'Dokument'"
                  downloadServicePath="/get_signo_document_preview"
                  :filename="file.name"
                  :queryParams="{
                      nodeId: encodeURIComponent(file.nodeId), 
                      viewFileId: encodeURIComponent(file.viewFileId),
                  }"
                />
              </template>
              <template v-else>
                {{file.name}}
              </template>
            </div>
            <div>
              <div class="row">
                <div class="col-12" v-for="(param, index3) in file.parameters" :key="index3">
                  <div v-if="param.type === 'IMAGE'">
                    {{param.label}}
                  </div>
                  <div v-if="param.type !== 'IMAGE'">
                    <span>{{param.label}}:</span>&nbsp;<span>{{param.value}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue'
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import { PhFile } from 'phosphor-vue'
// import mixin from '@/components/openSigns/offene-interschriften-mixin.js'

export default {
  // mixins: [mixin],
  props: {
    selectedAdditionalDocuments: {
      default: () => [],
    },
    selectedOpenSignatureForAdditionalDocuments: {
      default: () => []
    },
    title: {
      default: ''
    },
    statusClass: {
      default: ''
    },
    statusStr: {
      default: ''
    },
    missingSignatureDescription: {
      default: ''
    }
  },

  components: {
    BaseModal,
    DownloadLink,
    PhFile
  },

  methods: {
    canGenerateSignoViewerLink(element) {
      return !!(element?.nodeId && element?.viewFileId)
    },
    close() {
      this.$emit("close")
    },
    open() {
      this.$refs.openSignatureAdditionalDocuments.open()
    }
  },
}
</script>

<style scoped>

</style>