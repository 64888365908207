var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isBrokerBypassedByIntern
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("ComboBox", {
              attrs: {
                label: "Organisationstyp",
                isComponentHalfSize: "",
                values: _vm.organizationTypes,
                disabled: _vm.isDisabled
              },
              on: {
                input: function($event) {
                  return _vm.wasEdited()
                }
              },
              model: {
                value: _vm.vertriebsInfoEdit.organisationsTyp,
                callback: function($$v) {
                  _vm.$set(_vm.vertriebsInfoEdit, "organisationsTyp", $$v)
                },
                expression: "vertriebsInfoEdit.organisationsTyp"
              }
            }),
            _c("InputCheckboxItem", {
              attrs: {
                label: "Software as a Service (SaaS)",
                disabled: _vm.isDisabled
              },
              on: {
                input: function($event) {
                  return _vm.wasEdited()
                }
              },
              model: {
                value: _vm.vertriebsInfoEdit.isSaas,
                callback: function($$v) {
                  _vm.$set(_vm.vertriebsInfoEdit, "isSaas", $$v)
                },
                expression: "vertriebsInfoEdit.isSaas"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "Maklergruppe",
                isComponentHalfSize: "",
                disabled: _vm.isDisabled
              },
              on: {
                input: function($event) {
                  return _vm.wasEdited()
                }
              },
              model: {
                value: _vm.vertriebsInfoEdit.maklergruppe,
                callback: function($$v) {
                  _vm.$set(_vm.vertriebsInfoEdit, "maklergruppe", $$v)
                },
                expression: "vertriebsInfoEdit.maklergruppe"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "Vertriebsgebiet",
                isComponentHalfSize: "",
                disabled: ""
              },
              on: {
                input: function($event) {
                  return _vm.wasEdited()
                }
              },
              model: {
                value: _vm.vertriebsInfoEdit.vertriebsgebiet,
                callback: function($$v) {
                  _vm.$set(_vm.vertriebsInfoEdit, "vertriebsgebiet", $$v)
                },
                expression: "vertriebsInfoEdit.vertriebsgebiet"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }