<template>
  <div>
    <DragnDropArea hoverText="Datei hier ablegen" @files="openAddVersicherungPage($event)" :disabled="!allowVersUpload">
      <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

      <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Versicherungen" :actions="headerActions" 
        @action-KUENDIGUNG="handleMailComposer()"
        @action-MAIL-GESELLSCHAFTEN="handleMailComposerCompany()"
        @action-ADD="openAddVersicherungPage()"
      />

      <BaseFilter
        v-if="isInsuranceCompany"
        title="Versicherungsfilter" 
        saveKey="versicherungsfilterInsuranceCompany" 
        :metadata="insuranceFilterMetadata"
        @onFilter="handleSearch($event)"
        hasSmartSearch
        :configFilter="configFilter">
      </BaseFilter>

      <div class="insurance-group__categories">
        <div class="box__container" v-for="(category, i) in categories" :key="i">
            <Table
                :title="category.friendlyName"
                :tableId="`6d94bde2-2085-4af3-8745-92ef38ac247c-${category.filter}`"
                :headers="headers(category)"
                :rows="rows_by_category[category.filter]"
                :dragnDropFilesOnRow="allowVersUpload"
                :isLoading="isLoading"
                :selected="(isByPass || isBroker) ? selectedRows : null"
                :mobileConfig="mobileConfig"
                @selected="onSelectedRows"
                @files="handleRowFiles($event)"
                @click-bezeichnung="openItemSelectedOverview($event)"
                @action="handleInsuranceAction($event)"
            >
                <template #gesellschaftText="row">
                    <div class="insurance__table--company">
                        <BaseImage v-if="row.gesellschaft.logoUrl"
                            class="insurance__table--company__logo"
                            :src="`${APP_API}/${row.gesellschaft.logoUrl}`"
                            :placeholder="imagePlaceholder"
                        />
                        <span class="ellipsis">{{ row.gesellschaft.zweigstelleName || row.gesellschaft.shortName }}</span>
                    </div>
                </template>
            </Table>
        </div>
      </div>
    </DragnDropArea>

    <DeleteInsuranceModal ref="deleteModal" :insurance="insuranceToDelete" :insuranceLabel="getInsuranceLabel(insuranceToDelete)"
      @delete="removeInsurance()">
    </DeleteInsuranceModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VERSICHERUNG_TYPES from '@/store/versicherungen/types';

import BaseButton from '@/components/core/BaseButton.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import BaseImage from '@/components/core/BaseImage.vue';
import Table from "@/components/table2/Table.vue";
import {CurrencyColumn, TextColumn, DateColumn, SlotColumn, NumberColumn, PillColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";

import CORE_TYPES from '@/store/core/types';
import insuranceGroupMixin from './insurance-group-mixin';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import DeleteInsuranceModal from './DeleteInsuranceModal.vue'
import BaseFilter from '@/components/core/BaseFilter.vue'
import { ROLES, VIEW_ROLES }from '@/router/roles';
import { MAP_STATUS_TO_PILL_TYPE } from '@/views/versicherungen/insurance-group/insuranceSearchConstants';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const APP_API = `${process.env.VUE_APP_API}/../`;
const categoryNetto = 'VORSORGE';
const UNFALLVERSICHERUNG = 'Unfallversicherung';

function getRate(value, period) {
  switch (period) {
    case 'monatlich':
      return value * 12;
    case 'vierteljährlich':
      return value * 4;
    case 'halbjährlich':
      return value * 2;
  }
  return value;
}

export default {
  mixins: [insuranceGroupMixin],
	props: {},
	data() {
		return {
            APP_API,
            imagePlaceholder: {
                width: '40px',
                height: '24px',
            },
      isLoading: false,
      showAddVersicherungModal: false,
      insuranceToDelete: null,
      insuranceFilterId: null,
      nameOrInsuranceNumber: null,
      configFilter: {
        placeholderForDefSearchInput: 'Versicherungsnummer, Name oder Vorname',
        checkDefaultSearchInput: true,
        defaultSearchInputKeys: ['versNummer', 'insured'],
        noResetOnDefaultSearchInputExit: true,
        cbDefSearchInput: (value) => this.nameOrInsuranceNumber = value,
        hideFirstColumn: true,
        filterZurucksetzen: () => this.resetFilter(),
        filterType: 'versicherung',
      },
      mobileConfig: {
        title: 'bezeichnung',
        headers: ['gesellschaftText', 'status', 'annual', 'nummer'],
      },
    }
  },
  computed: {
    ...mapGetters({
      categories: VERSICHERUNG_TYPES.GETTERS.CATEGORIES_LIST,
      insuranceList: VERSICHERUNG_TYPES.GETTERS.INSURANCE_LIST,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      mailLinkedObject: MAILCOMPOSER_TYPES.GETTERS.GET_MAIL_LINKED_OBJECT,
      userId: CORE_TYPES.GETTERS.GET_USER_ID,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    isSuperCustomer() {
      return this.hasRoles(ROLES.SUPER_CUSTOMER);
    },
    allowVersSave() {
      return this.hasRoles(ROLES.ALLOW_VERS_SAVE);
    },
    headerActions() {
      const { isCustomer, isTest, isSuperCustomer, allowVersSave } = this;
      const actions = [];

      if (!isSuperCustomer && allowVersSave) {
        actions.push(PageHeaderSimpleAction('ADD', 'Versicherung hinzufügen'))
      }

      if(!isCustomer) {
        // actions.push(PageHeaderSimpleAction('KUENDIGUNG', 'Kündigung Versicherungen'))
      }
      if(isTest && !isCustomer) {
        actions.push(PageHeaderSimpleAction('MAIL-GESELLSCHAFTEN', 'Rundmail an Gesellschaften'))
      }

      return actions;
    },
    isOpenInNewTab() {
      return this.hasRoles([[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]])
    },
    insuranceFilterMetadata() {
      const versichertComboList = [...this.insuranceFilterOptions]
      return [
          {
            type: 'group',
            key: 'allgemein',
            label: 'Allgemein',
            menuItems: [
              {
                type: 'combobox',
                label: 'Versichert',
                key: 'insured',
                comboOptions: versichertComboList,
              },
              {
                type: 'text',
                dataType: 'Number',
                label: 'Vers. Nr.',
                key: 'versNummer',
                emptyDenied: true,
              },
            ]
          }
        ]
    },
    isInsuranceCompany() {
      return this.hasRoles([ROLES.INSURANCE_COMPANY])
    },
    rows_by_category() {
      let records = this.insuranceList || [];
      if (this.insuranceFilterId) {
          records = records.filter(item => item.id === this.insuranceFilterId)
      }
      records = this.filterInsuranceRecords(records, this.nameOrInsuranceNumber)
      
      let result = {};
      records.forEach(row => {
        if (!result[row.kategorie]) {
          result[row.kategorie] = [];
        }
        const actions = [];
        if (!row.hasDocuments) {
          actions.push(SimpleAction("DELETE", 'PhTrash', "Löschen"));
        }
        let annual = '';
        if (row.kategorie === categoryNetto && row.bezeichnung !== UNFALLVERSICHERUNG && !this.isFA) {
          annual = getRate(row.praemiento_zahlweise, row.zahlrhythm)
        } else {
          annual = getRate(row.praemiebto_zahlweise, row.zahlrhythm)
        }
        result[row.kategorie].push({
          ...row,
          zeigstelleName: row.gesellschaft?.zweigstelleName,
          gesellschaftText: row.gesellschaft?.zweigstelleName || '',
          status: row.status ? {
              label: row.status,
              type: MAP_STATUS_TO_PILL_TYPE[row.status],
          } : null,
          annual,
          vertragsinhaber:  row?.kundennr !== this.userId ? `${row?.kundeName}, ${row?.kundeVorname}` : null,
          actions,
        });
      })
      return result;
    },
    insuranceFilterOptions() {
      const insuranceFilterOptions = this.insuranceList
        ?.filter(item => item.versObject)
        ?.map(item => ({value: item.id, label: item.versObject})) || []

      const collator = new Intl.Collator('de');
      insuranceFilterOptions.sort(function(n1, n2) {
        const p1 = n1.label.substr( n1.label.lastIndexOf(' ') + 1 ).toLowerCase();
        const p2 = n2.label.substr( n2.label.lastIndexOf(' ') + 1 ).toLowerCase();
        return collator.compare(p1, p2);
      });
      insuranceFilterOptions.unshift({label: 'Alle versicherte Personen', value: ''});

      return insuranceFilterOptions
    }
  },
  methods: {
    headers(kategorie) {
        const brutto = this.isFA && kategorie?.category == 'VORSORGE' ? CurrencyColumn("praemiebto_zahlweise", "Prämie Brutto").withSumFooter() : CurrencyColumn("praemiebto_zahlweise", "Prämie Brutto");
        const netto = CurrencyColumn("praemiento_zahlweise", "Prämie Netto");
        return {
            lockedLeft: [
                TextColumn("bezeichnung", "Bezeichnung").makeLink().makeAlwaysVisible(),
                PillColumn("status", "Status", 130),
            ],
            center: [
                ...(this.$isSmallScreen ? [TextColumn("gesellschaftText", "Gesellschaft")] : [SlotColumn("gesellschaftText", "Gesellschaft", 200, 1).makeSortable()]),
                TextColumn("id", "Id"),
                TextColumn("nummer", "Vertragsnummer"),
                TextColumn("produktbez", "Versichert"),
                DateColumn("beginn", "Beginn"),
                DateColumn("ende", "Ende"),
                TextColumn("vertragsinhaber", "Vertragsinhaber "),
                TextColumn("zahlrhythm", "Zahlweise").makeAlwaysVisible(),
                netto.makeAlwaysVisible(),
            ],
            lockedRight: [
                brutto,
                CurrencyColumn("annual", "Beitrag / Jahr").withSumFooter(),
                ActionColumn("actions", "Aktionen"),
            ],
        }
    },    
    resetFilter() {
      this.insuranceFilterId = null
      this.nameOrInsuranceNumber = null
    },
    handleSearch(event) {
      this.insuranceFilterId = null
      this.nameOrInsuranceNumber = null

      if (Array.isArray(event)) {
        for (const item of event) {
          if (item.key === 'insured') {
            this.insuranceFilterId = item.value
          }
        }
      }
    },
    filterInsuranceRecords(records, searchTerm) {
      if (!searchTerm) {
        return records
      } 

      let regexSearchFilter = null
      try {
        regexSearchFilter = searchTerm && new RegExp(searchTerm, 'gi')
      } catch (e) {
        // empty code
      }

      return records.filter(item => regexSearchFilter.test(item.nummer) || regexSearchFilter.test(item.versObject))
    },
    retrieveInsurances() {
      this.isLoading = true;
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCES)
        .finally(() => this.isLoading = false);
    },
    openSavedItem(vertragId) {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_DETAILS, { versVertrag: vertragId })
        .then((response) => {
          const insurance = response?.insurance;
          this.openItemSelectedDetail(insurance);
        });
    },
    handleMailComposerCompany() {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: 'mailcomposer-insurance',
        action: 'clear',
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: 'mailcomposer-insurance',
        action: 'clear',
      });
      this.storeSelectedAndKundbar()
      .then(() => {
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
          ...this.mailLinkedObject,
          toCompany: true,
        });
        this.$router.push(`/communication/mailcomposer-insurance-rund`);
      })
    },
    handleMailComposer() {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: 'mailcomposer-insurance-cancel',
        action: 'clear',
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
        data: this.selectedRows.map(v => v.id)
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE, null);
        
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: 'mailcomposer-insurance-cancel',
        action: 'clear',
        });

      this.$router.push(`/communication/mailcomposer-insurance-cancel`);
    },
    onSelectedRows(values) {
      this.selectedRows = values;
    },
  },
	mounted() {
    this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_CATEGORIES);

    this.retrieveInsurances();
	},
	components: {
        Table,
        BaseImage,
    BaseButton,
    DragnDropArea,
    DeleteInsuranceModal,
    ComboBox,
    InputField,
    BaseFilter,
    OptionMenu,
    PageHeaderTitleNavigation,
	}
}
</script>

<style scoped>
.insurance__table--company {
  display: flex;
  align-items: center;
}
.insurance__table--company__logo {
  margin: 0 8px 0 0;
  max-width: 40px;
  height: auto;
}
</style>
