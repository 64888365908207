<template>
  <div>
    <PageHeaderTitleNavigation 
      :defaultMenu="$appNavigation.currentTabMenu"
      title="Courtagetabellen" 
      subtitle="Versicherung"
    />

    <BaseFilter
      filterId="courtagetabellesuche"
      title="Courtagetabelle"
      showSaveButton
      saveKey="courtageTabelleSearch"
      v-if="searchMetadata && searchMetadata.length"
      :metadata="searchMetadata"
      :defaultOptions="defaultOptionsData"
      @onFilter="handleSearch"
      @changeSubFilter="handleFilterChanges"
    />

    <div class="box__container" v-if="!searchMetadata || !searchMetadata.length">
      <GhostLoading type="table" />
    </div>

    <div class="box__container">
      <GhostLoading v-if="loading" type="table" />

      <NoData v-else-if="rowCount == 0" />
      <template v-else-if="rowCount">
        <PaginatedTable
          tableId="383abbeb-da72-4638-8d24-090a439c215a"
          title="Courtagetabelle"
          :headers="headers"
          :pages="pages"
          :pageCount="pageCount"
          :rowCount="rowCount"
          :page="selectedPage"
          :exportConfig="exportConfig"
          :sorted="orderBy"
          @page="selectedPage = $event"
          @click-infoButton="loadInfo"
          @requestPage="loadPage"
          @action-BEARBEITEN="bearbeiten"
          @action-DELETE="removeRow"
          @sort="sortDataByColumn($event)"
          v-model="selectedRow"
        />
      </template>
      
    </div>
    <div class="box__container">
       <div class="box__title">Info</div>
       <p>Konditionsänderungen vorbehalten. Alle Angaben wurden sorgfältig geprüft, die FondsKonzept Assekuranzmakler GmbH übernimmt keine Haftung für die Richtigkeit der Angaben. Falls Sie über andere Maklerpools höhere Courtagen erhalten, teilen Sie uns dies bitte umgehend unter courtage@fondskonzept.ag mit. </p>
    </div>

    <BaseModal ref="infoModal" labelButtonCancel="Ok" :showConfirmButton="false" @onCancelButton="closeInfoModal">
      <template v-slot:modalTitle>
        <ph-info/>
        Information
      </template>
      <div class="row">
        <div class="col-12">
          <span v-html="sanitize(selectedInfo)"/>
        </div>
      </div>
    </BaseModal>

    <BaseModal 
      :showDefaultButtons="false"
      ref="editRowModal" 
      modalTitle="Eintrag bearbeiten">

      <div>
        <div v-for="(key) in formMetadataKeys" :key="key" >
          <div class="courtage___label" v-if="formMetadata[key].type=='LABEL'"><b>{{formMetadata[key].label}}:</b> {{formMetadata[key].value}}</div>

          <InputField 
          v-if="formMetadata[key].type=='STRING'"
          v-model="formMetadata[key].value" 
          :label="formMetadata[key].label"/>          
          
          <InputField 
          v-if="formMetadata[key].type=='TEXT_PROZENT'"
          v-model="formMetadata[key].value" 
          type="percent"
          :precision="2"          
          :label="formMetadata[key].label"/>

          <InputToggleSwitch 
            v-model="formMetadata[key].value" 
            :label="formMetadata[key].label"
            v-if="formMetadata[key].type=='SWITCHER'"
            inLineLabel
            suppressValidationMessage />  

          <InputField 
            v-if="formMetadata[key].type=='NUMBER'"
            v-model="formMetadata[key].value" 
            type="number"
            :label="formMetadata[key].label"/>  
          
          <InputTextArea 
            v-if="formMetadata[key].type=='TEXTAREA'"
            :label="formMetadata[key].label" 
            v-model="formMetadata[key].value"/>
        
        </div>

        <div style="display: flex">
            <BaseButton isPrimary  @click="saveOrUpdateItem()">
              Bestätigen
            </BaseButton>

            <BaseButton isSecondary @click="cancelUpdateItem()">
              Abbrechen
            </BaseButton>

        </div>        

      </div>

    </BaseModal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import COURTAGETABELLE_VERSICHERUNG_TYPES from '@/store/courtagetabelleVersicherung/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import PaginatedTable from '@/components/table2/PaginatedTable.vue';
import {TextColumn, IconColumn, Icon, PercentageColumn, NumberColumn, ActionColumn, SimpleAction, ConfirmedAction } from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseFilter from '@/components/core/BaseFilter.vue';
import { mapListToComboBoxValues } from '@/helpers/mapping.js';
import {PhInfo, PhPencil, PhTrash} from 'phosphor-vue';
import BaseModal from '@/components/core/BaseModal.vue';
import {sanitize} from '@/helpers/string-helper.js';
import pageSizeMixin from './page-size-mixin.js';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import { VIEW_ROLES } from '@/router/roles';

const PRECISION_4 = 4;
const PAGE_COUNT = 100;

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    PaginatedTable,
    GhostLoading,
    BaseFilter,
    NoData,
    PhInfo,
    PhPencil,
    PhTrash,
    BaseModal,
    InputField,
    InputToggleSwitch,
    InputTextArea,
    BaseButton,
  },
  mixins: [pageSizeMixin],
  data() {
    return {
      loading: false,
      selectedRow: null,
      selectedPage: 0,
      selectedFilter: null,
      orderBy: null,
      selectedInfo: null,
      selectedItem: null,
      selectedCategory: null,
    }
  },
  computed: {
    ...mapGetters({
      filterFieldsDefinition: COURTAGETABELLE_VERSICHERUNG_TYPES.GETTERS.FILTER_FIELDS_DEFINITIONS,
      courtagetabelleVersicherungList: COURTAGETABELLE_VERSICHERUNG_TYPES.GETTERS.COURTAGETABELLE_VERSICHERUNG_LIST,
      courtagetabelleVersicherungDisplayInfo: COURTAGETABELLE_VERSICHERUNG_TYPES.GETTERS.COURTAGETABELLE_VERSICHERUNG_DISPLAY_INFO,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      formMetadata: COURTAGETABELLE_VERSICHERUNG_TYPES.GETTERS.FORM_METADATA,
    }),
    formMetadataKeys() {
      return Object.keys(this.formMetadata);
    },
     viewIntern() {
      return this.hasRoles([VIEW_ROLES.VIEW_INTERN]);
    },    
    selectedTitel(){
      if (this.filterFieldsDefinition && Object.keys(this.filterFieldsDefinition).length && this.selectedFilter) {
        const sparten = {};
        Object.keys(this.filterFieldsDefinition).forEach(key => {
          const parts = key.split('_');
          sparten[parts[0]] = parts[1];
        })
        if (sparten[this.selectedFilter?.key]) {
          return sparten[this.selectedFilter?.key];
        }
      }
      return 'Versicherung';
    },
    exportConfig() { return {
      roottext: 'Courtagetabelle ' + this.selectedTitel,
      dispatch: this.exportAsFile,
    }},
    defaultOptionsData() {
      const category = this.selectedCategory || 'SACHEN';
      const defaultVertragssparten = `${category}_ALLGEMEIN`;
      const defaultGesellschaft= `${category}_GESELLSCHAFT`;


      return {
        [defaultVertragssparten]: {
          value: 'Alle Vertragssparten'
        },
        [defaultGesellschaft]:  {
          value: 'Alle'
        }
      }; 
    },     
    searchMetadata(){
      if (!this.filterFieldsDefinition?.gesellschaften || !this.filterFieldsDefinition?.vertragssparte) {
        return [];
      }

      const filters =  [
      {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          allowMultipleSearch: true,
          menuItems: Object.entries(this.filterFieldsDefinition?.vertragssparte)
            .map(([category, values]) => {
              const categoryKeyDescription = category.split('_');
              return {
                type: 'combobox',
                key: categoryKeyDescription[0] + '_ALLGEMEIN',
                label: categoryKeyDescription[1],
                sortComboboxValues: false,
                comboOptions: [...mapListToComboBoxValues(values, 'label', 'label')],
                emptyDenied: false,
              }
            })  || []
        },         
        {
          type: 'group',
          key: 'gesellschaft',
          label: 'Gesellschaft',
          allowMultipleSearch: true,
          menuItems: Object.entries(this.filterFieldsDefinition?.gesellschaften)
            .map(([category, values]) => {
              const categoryKeyDescription = category.split('_');
              return {
                type: 'combobox',
                key: categoryKeyDescription[0] + '_GESELLSCHAFT',
                label: categoryKeyDescription[1],
                sortComboboxValues: false,
                comboOptions: [...mapListToComboBoxValues(values, 'value', 'label')],
                emptyDenied: false,
              }
            }).filter(mi => mi.key.split('_')[0] == (this.selectedCategory || 'SACHEN') ) || []
        },


      ];

      return filters;
    },
    gesellschaftList() {
      return this.filterFieldsDefinition?.gesellschaften[this.selectedCategory];
    },
    headers() {
      if(this.courtagetabelleVersicherungList?.headers) {

        const columnsOrder = [/*"gesellschaftName", "sparteName", "tarif", */"provErhaltAP", "provWeitergabeAP", "abschlussProv", "abschlussStornohaftungMonate", 
        "provErhaltBP", "provWeitergabeBP", "bestandsProv", "bestandsStornohaftungMonate"];
        let headers = this.courtagetabelleVersicherungList.headers;
        headers = headers.sort((a, b) => columnsOrder.indexOf(a.key) - columnsOrder.indexOf(b.key))

        const centerColumns = headers.map(header => {
          if(header.dataType === 'Procent'){
            return PercentageColumn(header.key, header.label,PRECISION_4, 250, 0).makeSortable(false)
          } 
          if(header.dataType === 'String'){
            return TextColumn(header.key, header.label)
          }
          if(header.dataType === 'Integer'){
            return NumberColumn(header.key, header.label)
          }
          if(header.dataType === 'Double'){
            return NumberColumn(header.key, header.label, PRECISION_4, 250, 0).makeSortable(false)
          }
        })
        return {
          lockedLeft: [
            TextColumn("gesellschaftName","Gesellschaft"),
            TextColumn("sparteName", "Vertragssparte"),
            TextColumn("tarif", "Produkt / Tarif"),
          ],
          center: centerColumns,
          lockedRight: [
            IconColumn("infoButton", "Bemerkungen").makeLink(),
            ... this.viewIntern ? [ActionColumn("actions", "Aktionen")] : [],
          ],
        }
      }
      return null;
    },
    pages() {
      if (!this.courtagetabelleVersicherungList?.rows) {
          return [];
      }
      const actions = [];
      
      if (this.viewIntern) {
        actions.push(SimpleAction("BEARBEITEN", PhPencil, "Bearbeiten"));
        actions.push(ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll den Eintrag wirklich gelöscht werden?`, 'Eintrag löschen', "Löschen"));
      }

      const pages =  {
       [this.selectedPage]: [...this.courtagetabelleVersicherungList.rows].map(row => {
          const record = {...row, actions};
          record.provErhaltAP = (row.provErhaltAP || 0).toFixed(PRECISION_4);
          record.provErhaltBP = (row.provErhaltBP || 0).toFixed(PRECISION_4);
          record.prov = (row.prov || 0).toFixed(PRECISION_4);
          record.infoButton = row.info ? Icon(PhInfo, '', 18, null, 'color-info') : null
          return record;
        })
      }
      return pages;
    },
    rowCount() {
      if (!this.selectedFilter) {
        return null;
      }
      return this.courtagetabelleVersicherungList?.count || 0;
    },
    pageCount() {
      return Math.ceil(this.rowCount / PAGE_COUNT);
    },
  },
  methods:{
    handleFilterChanges(event, filterRows) {
      if (event?.key === 'allgemein') {
        const defaultCategory = 'SACHEN';
        const filterRow = filterRows.find(fr => fr?.key === 'allgemein');
        const secondaryKey = filterRow?.secondaryKey;

        this.selectedCategory = secondaryKey?.split('_')[0] || defaultCategory;
      }
    },
    async bearbeiten(whatRow) {
      this.selectedItem = whatRow;
         const payload = {
          id: whatRow.id
        }

        await this.$store.dispatch(COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.GET_FORM_METADATA, payload);

        console.log(this.formMetadata);
        this.$refs.editRowModal.open();
    },
    async saveOrUpdateItem() {
      await this.$store.dispatch(COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.SAVE_OR_UPDATE, this.formMetadata);
      this.$refs.editRowModal.close();
      this.filterData();
    },
    async removeRow(whatRow) {
      await this.$store.dispatch(COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.DELETE_COURTAGETABELLE_ITEM, {
        courtageTabelleId: whatRow?.id, 
      });

      this.filterData();
    },    
    cancelUpdateItem() {
      this.$refs.editRowModal.close();

    },
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    handleSearch(filters){
      this.selectedPage = 0;
      this.orderBy = null;
      this.selectedFilter = filters;
      this.filterData();
    },
    filterData() {
      if(!this.selectedFilter) {
        return;
      }      
      this.loading = true;
      this.$store.dispatch(COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.GET_COURTAGETABELLE_VERSICHERUNG_LIST, 
          {filter: this.selectedFilter, page: this.selectedPage, order: this.orderBy, maxCount: PAGE_COUNT}
        )
        .finally(() => this.loading = false);
    },
    loadPage(event){
      if(event){
        this.selectedPage = event;
        this.filterData();
      }
    },
    loadInfo(event) {
      if(event){
        this.selectedInfo = event.info;
        this.$refs.infoModal.open();
      }
    },
    closeInfoModal() {
      this.selectedInfo = null;
    },
    sortDataByColumn(event) {
      if(event && event?.key !== undefined){
        this.selectedPage = 0;
        this.orderBy = event;
        this.filterData();
      } else {
        this.selectedPage = 0;
        this.orderBy = null;
      }
    },
    exportAsFile(fileType) {
      if (this.selectedFilter ){
        const filter = this.selectedFilter;

        const payload = {
          filter: filter,
          title: this.selectedTitel, 
          fileType: fileType, 
          selectedPage: this.selectedPage, 
          orderKey: this.orderBy?.key, 
          orderDirection: this.orderBy?.sortDirection
        };        
        return this.$store.dispatch(COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.COURTAGETABELLE_VERSICHERUNG_EXPORT, payload);
      }
    },
  },
  mounted(){
    this.$store.dispatch(COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.GET_FILTER_FIELDS_DEFINITIONS);
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Details', 
      to,
      from,
      replaceLastPreviousData: true,
    });

    this.selectedFilter = null;
    this.$store.commit(COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.CLEAR_COURTAGETABELLE_VERSICHERUNG_LIST);

    next()
  },

}
</script>
<style scoped>
  .courtage___label {
    margin-bottom: 4px;
  }

</style>
