var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "content-with-sidebar__container",
      class: {
        "show-sidebar": _vm.showSidebar,
        "changing-content": _vm.changingContent,
        "content-active": _vm.contentActive,
        "sidebar-active": _vm.sidebarActive
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "content-with-sidebar__sidebar",
          on: {
            transitionend: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleTransitionEnd()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "content-with-sidebar__sidebar--wrap" },
            [_vm._t("sidebar")],
            2
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "content-with-sidebar__content",
          on: {
            touchstart: function($event) {
              return _vm.handleSwipeLeft($event)
            },
            touchend: function($event) {
              return _vm.handleSwipeLeft($event)
            }
          }
        },
        [
          _c("div", { staticClass: "content-with-sidebar__content--wrap" }, [
            _c(
              "div",
              {
                staticClass: "content-with-sidebar__content--button__container"
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "content-with-sidebar__content--button--toggle__sidebar clickable",
                    attrs: {
                      type: "button",
                      alt: "Icon zum Ausklappen des Verzeichnisses"
                    },
                    on: {
                      click: function($event) {
                        return _vm.toggleSidebar()
                      }
                    }
                  },
                  [
                    _c("ph-caret-left", {
                      attrs: {
                        size: 24,
                        alt: "Icon zum Ausklappen des Verzeichnisses"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass:
                      "content-with-sidebar__content--button--sidebar clickable",
                    attrs: {
                      type: "button",
                      alt: "Icon zum Ausklappen des Verzeichnisses"
                    },
                    on: {
                      click: function($event) {
                        return _vm.backSidebar()
                      }
                    }
                  },
                  [
                    _c("ph-caret-left", {
                      attrs: {
                        size: 24,
                        alt: "Icon zum Ausklappen des Verzeichnisses"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "content-with-sidebar__content--main" },
              [_vm._t("content")],
              2
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }