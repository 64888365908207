import UndrawFinance from '@/components/icons/undraw/UndrawFinance.vue'
import * as undrawIcons from '@/components/icons/undraw';

export const MenuType = Object.freeze({
  PRIMARY_MENU: 'PrimaryMenu',
  MENU_ITEM: 'MenuItem',
  GROUP_MENU: 'GroupMenu',
  TAB_MENU: 'TabMenu',
  ASYNC_MENU: 'AsyncMenu',
  CUSTOM_GROUP_MENU: 'CustomGroupMenu',
});

export function validatesPrimaryMenuAsRoot(menu) {
  if (menu.type !== MenuType.PRIMARY_MENU) {
    throw new Error(`a root menu must be a "${MenuType.PRIMARY_MENU}"! The "${menu.path}" menu must be changed!`);
  }
}

export function validatesPrimaryMenuAsSubMenu(menu) {
  if (menu?.subMenu?.some(sm => sm.type === MenuType.PRIMARY_MENU)) {
    const primarySubMenu = menu?.subMenu?.filter(sm => sm.type === MenuType.PRIMARY_MENU).map(sm => sm.path);
    throw new Error(`"${MenuType.PRIMARY_MENU}" must not be used as a submenu! The "\n${primarySubMenu.join(',\n')}\n" submenu must be changed!`);
  }
}

function findInvalidSubmenusRelationship(menu, menuType, invalidSubmenuType) {
  if (menu?.type === menuType && menu?.subMenu?.some(sm => sm.type === invalidSubmenuType)) {
    const invalidSubMenu = menu?.subMenu?.filter(sm => sm.type === invalidSubmenuType)?.map(sm => sm.path);
    throw new Error(`"${menuType}" must not have "${invalidSubmenuType}"! The "\n${invalidSubMenu.join(',\n')}\n" submenu must be changed!`);
  }
}

export const validatesPrimaryMenuWithTabMenu = (menu) => findInvalidSubmenusRelationship(menu, MenuType.PRIMARY_MENU, MenuType.TAB_MENU);
export const validatesGroupMenuWithTabMenu = (menu) => findInvalidSubmenusRelationship(menu, MenuType.GROUP_MENU, MenuType.TAB_MENU);
export const validatesMenuItemWithGroupMenu = (menu) => findInvalidSubmenusRelationship(menu, MenuType.MENU_ITEM, MenuType.GROUP_MENU);
export const validatesMenuItemWithMenuItem = (menu) => findInvalidSubmenusRelationship(menu, MenuType.MENU_ITEM, MenuType.MENU_ITEM);

function BaseMenu(path, label, component, type, subMenu = []) {
  if(!label) {
    throw 'a menu must have a label';
  }

  const [componentName, vueComponent] = typeof component === 'string' 
    ? [component, undrawIcons[component]]
    : [null, component];

  return {
    id: path, // by default id is path, but it can be changed
    path,
    label,
    componentName,
    component: vueComponent || UndrawFinance,
    type,
    group: type === MenuType.GROUP_MENU || type === MenuType.CUSTOM_GROUP_MENU,
    tab: type === MenuType.TAB_MENU,
    async: type === MenuType.ASYNC_MENU,
    subMenu,
    hasSubMenu: subMenu?.length > 0, // it must be set again when checking permissions
    withId(id) {
      this.id = id;
      return this;
    },
    withIcon(component, componentProps) {
      this.component = component || UndrawFinance;
      this.componentProps = componentProps;
      return this;
    },
    withUnmodifiablePermission() {
      this.unmodifiablePermission = true;
      return this;
    }
  };
}

function BaseMenuAutoSelectable(path, label, component, type, subMenu = []) {
  return {
    ...BaseMenu(path, label, component, type, subMenu),
    autoSelectTo: [],
    withAutoSelectTo(autoSelectTo) {
      if (Array.isArray(autoSelectTo)) {
        this.autoSelectTo = autoSelectTo;
      } else if (typeof autoSelectTo === 'string') {
        this.autoSelectTo = [autoSelectTo];
      }
      return this;
    },
    withAutoSelectFirst() {
      this.autoSelectTo = this.subMenu.map(sm => sm.path);
      return this;
    },
  };
}

export function PrimaryMenu(path, label, component, subMenu = []) {
  return BaseMenuAutoSelectable(path, label, component, MenuType.PRIMARY_MENU, subMenu);
}

/**
 * A group menu is a menu item with a back button added when is accessed
 *
 * @param {string} path
 * @param {string} label
 * @param {VueComponent} [component]
 * @param {Array} [subMenu]
 * @returns 
 */
export function GroupMenu(path, label, component, subMenu = []) {
  return BaseMenuAutoSelectable(path, label, component, MenuType.GROUP_MENU, subMenu);
}

/**
 * A menu item with path
 * 
 * @param {string} label
 * @param {string} path
 * @param {VueComponent} [component]
 * @param {Array} [subMenu]
 * @returns 
 */
export function MenuItem(path, label, component, subMenu = []) {
  return BaseMenu(path, label, component, MenuType.MENU_ITEM, subMenu);
}

/**
 * A menu item with path but not available to configure in options menu
 * 
 * @param {String} label 
 * @param {String} path 
 * @param {VueComponent} [component]
 * @returns 
 */
export function TabMenu(path, label, component) {
  return BaseMenu(path, label, component, MenuType.TAB_MENU);
}

export function AsyncMenu(path, label = 'Asynchrone Menüs') {
  return BaseMenu(path, label, null, MenuType.ASYNC_MENU);
}

export function CustomGroupMenu(path, label, component, subMenu) {
  const checkedPath = path.startsWith('/m/') ? path : `/m/${path}`;
  return BaseMenu(checkedPath, label, component, MenuType.CUSTOM_GROUP_MENU, subMenu);
}

export function mapMenuConfigToMenu(menuStructure, systemMenu = []) {
  // TODO refactor "isDefaultMenu" flag
  // TODO refactor this method mapping
  if (!menuStructure) return [];

  const { optionsMenu = [] } = menuStructure;

  const mapMenu = menu => {
    const sMenu = systemMenu?.find(sm => sm.path === menu.path) || {};
    const subMenu = sMenu.hasTabMenu
      ? sMenu.subMenu
      : optionsMenu.filter(sm => sm.parent === menu.path).map(mapMenu);

    return {
        ...menu,
        ...sMenu,
        subMenu,
    };
  };

  const toMenu = (menu) => {
    const subMenu = menu.subMenu.map(toMenu);

    // changes menu id to "<menu path>@<menu structure id>", example: /home@1
    const id = `${menu.path}@${menuStructure.id}`;

    switch(menu.type) {
      case MenuType.CUSTOM_GROUP_MENU:
        return CustomGroupMenu(menu.path, menu.label, menu.icon, subMenu).withId(id);
      default: 
        return {
          ...menu,
          id,
          subMenu,
        };
    }
  }

  const addNonDefault = (dMenu, sMenu) => {
    return {
      ...(dMenu ?? sMenu),
      isDefaultMenu: !!dMenu,
      subMenu: [
        ...(
          (dMenu?.subMenu ?? [])
            .map(sm => addNonDefault(sm, sMenu?.subMenu?.find(sm2 => sm2.path == sm.path)))
        ),
        ...(
          (sMenu?.subMenu ?? [])
            .filter(sm => !dMenu?.subMenu?.find(sm2 => sm2.path == sm.path))
            .map(sm => addNonDefault(dMenu?.subMenu?.find(sm2 => sm2.path == sm.path), sm)) 
        )
      ],
    };
  };

  const definedMenu = optionsMenu
    .map(mapMenu)
    .filter(menu => !menu.parent)
    .map(toMenu);

  return systemMenu
    .filter(menu => !menu.parent)
    .map(toMenu)
    .map(menu => addNonDefault(definedMenu.find(cm => cm.path == menu.path), menu));
}

/**
 * Creates a menu label object with roles
 * 
 * @param {*} label 
 * @returns 
 */
function Label(label) {
  return {
    label,
    withRoles(roles) {
      this.roles = [ ...roles || [], ];
      return this;
    },
  };
}

/**
 * Creates a menu labels with roles
 * 
 * Note: Can be used when there is conditional labels. In this kind of scenario, order menu labels from specific to generic
 * Example: 
 *  ConditionalLabelsBuild()
 *    .add('Specific', [ROLES1, ROLES2])
 *    .add('Generic')
 *
 * @returns 
 */
export function ConditionalLabelsBuild() {
  return {
    labels: [],
    add(label, roles) {
      this.labels.push(Label(label).withRoles(roles));
      return this;
    },
    build() {
      return [ ...this.labels, ];
    },
  };
}

/**
 * Configures workspace and options menu visibility
 * 
 * @returns 
 */
export function PageMenuCfg() {
  return {
    pageMenuCfg: {
      showOptionsMenu: true,
      showWorkspaceHeader: true,
    },
    /**
     * No workspace menu and options menu
     * @returns PageMenuCfg instance
     */
    noMenu() {
      this.pageMenuCfg.showOptionsMenu = false;
      this.pageMenuCfg.showWorkspaceHeader = false;
      return this;
    },
    noOptionsMenu() {
      this.pageMenuCfg.showOptionsMenu = false;
      return this;
    },
    noWorkspaceHeader() {
      this.pageMenuCfg.showWorkspaceHeader = false;
      return this;
    },
  };
}
