import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import COURTAGETABELLE_VERSICHERUNG_TYPES from './types';
import axios from 'axios';
import { buildMessage } from '../../helpers/log-message-helper';

const config = {
  defaultSpinner: true
};

export default {
  [COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.GET_FILTER_FIELDS_DEFINITIONS]({ commit, getters}) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/courtagetabelleVersicherung/getFilterFieldsDefinition`;

      axios.get(url, config).then(response => {
        commit(COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.FILTER_FIELDS_DEFINITIONS_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  [COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.GET_COURTAGETABELLE_VERSICHERUNG_LIST]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/courtagetabelleVersicherung/filterCourtagetabeleVersicherungen`;
      url += '?page=' + (payload?.page >= 0 ? payload.page : 0)
      if(payload?.order) {
        url += '&orderKey='+encodeURIComponent(payload.order.key)+'&orderDirection='+encodeURIComponent(payload.order.sortDirection)
      }
      if(payload?.maxCount) {
        url += '&maxCount=' + payload.maxCount;
      }

      const config = {
        defaultSpinner: true,
        cancelableRequest: { title: 'Courtagetabelle Versicherung' }
      };

      axios.post(url, payload.filter, config).then(response => {
        commit(COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.COURTAGETABELLE_VERSICHERUNG_LIST_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  [COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.COURTAGETABELLE_VERSICHERUNG_EXPORT]({ rootState }, payload) {
      const { filter, title, fileType, selectedPage, orderKey, orderDirection } = payload;

      let baseUrl = rootState.core.apiAddress + '/courtagetabelleVersicherung/exportCourtagetabeleVersicherungen';

      let queryParams = [];
      if (title) queryParams.push(`titleParam=${encodeURIComponent(title)}`);
      if (fileType) queryParams.push(`fileType=${encodeURIComponent(fileType)}`);
      if (selectedPage !== undefined) queryParams.push(`selectedPage=${encodeURIComponent(selectedPage)}`);
      if (orderKey) queryParams.push(`orderKey=${encodeURIComponent(orderKey)}`);
      if (orderDirection) queryParams.push(`orderDirection=${encodeURIComponent(orderDirection)}`);

      let queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';
      let fullUrl = baseUrl + queryString;

      return axios.post(fullUrl, filter, config);
  },


  [COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.GET_MENU_ITEM_ACCESS_RULE]({ commit, getters}) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/courtagetabelleVersicherung/getMenuItemAccessRule`;

      axios.get(url, config).then(response => {
        commit(COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.GET_MENU_ITEM_ACCESS_RULE_SUCCESS, response?.data || false);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  [COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.SAVE_OR_UPDATE]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/courtagetabelleVersicherung/saveOrUpdate`;

      const config = {
        defaultSpinner: true,
        cancelableRequest: { title: 'Courtagetabelle Versicherung' }
      };

      axios.post(url, payload, config).then(response => {
        commit(COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.SAVE_OR_UPDATE_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  [COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.GET_FORM_METADATA]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/courtagetabelleVersicherung/getFormMetadata`;

      const config = {
        defaultSpinner: true,
        cancelableRequest: { title: 'Courtagetabelle Versicherung' }
      };

      axios.post(url, payload, config).then(response => {
        commit(COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.GET_FORM_METADATA_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  }, 

  [COURTAGETABELLE_VERSICHERUNG_TYPES.ACTIONS.DELETE_COURTAGETABELLE_ITEM]({ rootState }, payload) {

    return new Promise((resolve, reject) => {
      let url = '/courtagetabelleVersicherung/deleteItem';

      if(payload?.courtageTabelleId) {
        url = `${url}?courtageTabelleId=${payload?.courtageTabelleId}`;
      } else {
        return;
      }
  
      axios.delete(rootState.core.apiAddress + url, config).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });


    });
   
  
  
  },  


}