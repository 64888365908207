var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FinanzenRegelmaessigeRate", {
        attrs: {
          disabled: _vm.isDisabled || _vm.isRefusedPageFinance,
          finance: _vm.finance,
          showHinweisBetragNiedrig: _vm.showHinweisBetragNiedrig
        },
        on: { changed: _vm.valueChangedFinance }
      }),
      _c("FinanceLineSum", {
        attrs: {
          summe: _vm.summeAusgaben,
          title: "Regelmäßige finanzielle Verpflichtungen"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }