<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="input-forms__label-content" v-if="label" v-html="sanitize(label)"></div>
      <div class="input-forms__input-container" :class="{ 'forms__input--half-size': isComponentHalfSize, 'autogrow-container': autoGrow }">
        <div class="autogrow-size-dummy fc-textarea" v-if="autoGrow">{{internalValue}}.</div>
        <textarea class="fc-textarea"
          ref="innerTextarea"
          v-model="internalValue" 
          :class="getTextAreaClass" 
          :placeholder="placeholder" 
          :disabled="disabled"
          @blur="onBlur"
          @keyup="$emit('keyup', $event)"
          :rows="rows"
          :maxlength="maxlength"
          aria-label="Beschreibung / Bemerkung"
        >
        </textarea>
      </div>
      <template v-if="isValidationConfigured()">
        <div :key="validation.updated" v-if="!suppressValidationMessage && validation" class="input-forms__errors-container">
          <div class="fc-form-danger" 
            v-for="error in validation.getErrors(this.validationPath, this.validateUntouched)" 
            :key="error">
              {{ error }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import { sanitize } from '@/helpers/string-helper.js';

export default {
  mixins: [validatorComponentUtils],
  props: {
    label: {
    },
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validateUntouched: {
      type: Boolean,
      default: false
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
    },
    autoGrow: {
      type: Boolean,
      default: false
    },
    autoFocus: {
      type: Boolean,
      default: false
    },    
    maxlength: {
      type: Number,
      default: null,
    },
    isNotReactive: {
      type: Boolean,
      default: false
    },
    hideScrollBar: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue
      this.$runValidator(this.internalValue)
    },
    disabled() {
      if (this.disabled) {
        this.$reset()
      } else { 
        this.$runValidator(this.internalValue)
      }
    }
  },
  data() {
    return {
      modifiedValue: undefined
    }
  },
  computed: {
    internalValue: {
      get: function() {
        return this.isNotReactive ? (this.modifiedValue || this.value || '') : this.value ;
      },
      set: function(modifiedValue) {
        this.$emit('input', modifiedValue);
        this.$setDirty();
        this.$runValidator(modifiedValue)
        if (this.isNotReactive) {
          this.modifiedValue = modifiedValue;
        }
      }
    },
    getTextAreaClass() {
      return {
        'fc-form-danger': this.renderDanger || (this.isValidationConfigured() && this.validation.isInvalid(this.validationPath, this.validateUntouched)),
        'autogrow-textarea': this.autoGrow,
        'scrollBarHidden': this.hideScrollBar,
      }
    }
  },
  mounted() {
    
    this.$nextTick(() => {
      if(this.autoFocus) {
        this.$refs.innerTextarea.focus();
      }
    });

    this.$runValidator(this.value, true)
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    onBlur() {
      this.$setTouched()
      this.$emit('change', this.internalValue);
    }
  }
}
</script>

<style scoped>
.autogrow-container{
  min-height:30px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.autogrow-size-dummy {
  border: 1px solid transparent;
  color: rgba(0, 0, 0, 0);
}
.autogrow-textarea {
  position: absolute;
  height:100%;
  resize: none;
}
.scrollBarHidden {
  overflow-y: hidden
}
</style>