import axios from 'axios';

import { ResolverId } from '../types';
import { createLinkResolver, wrapUrl, wrapFormData, wrapViewDocument, wrapError } from '../utils';
import { base64ToArrayBuffer } from '@/helpers/download-helper';

export default createLinkResolver(ResolverId.ExterneZugangBank, async function ({ apiAddress }, { company, id, payload }) {
  if (!company) return;

  const params = [`company=${company}`];
  if (id) params.push(`id=${id}`);

  const requestURL = `${apiAddress}/externe_zugang_bank/url?${params.join('&')}`;
  const response = await axios.post(requestURL, payload || {}, { defaultSpinner: true });

  if (response?.data?.data) {
    const label = response?.data?.label;
    const data = response?.data?.data;

    switch (response?.data?.responseType) {
      case 'url':
        return wrapUrl(data);
      case 'form-data':
        const parsedData = JSON.parse(data);
        return wrapFormData(parsedData.action, parsedData.data);
      case 'pdf':
        return wrapViewDocument(label, 'application/pdf', base64ToArrayBuffer(data));
      case 'text/plain':
        return {
          type: 'text/plain',
          data: data,
        };
    }
  } else if (response?.data?.errorMessage) {
    return wrapError(response?.data?.errorMessage);
  } else if (response?.data !== undefined) {
    return {
      type: "TextCSV",
      filename: "data.csv",
      contentType: "text/csv;charset=UTF-8",
      data: response.data,
    };
  }
});
