<template>
  <div class="fonds_container">
    <div class="einzelfonds">
        <fondsshop-element v-if="!isIntern" zeige="vv"  />
        <div class="fc-alert fc-alert-warning" v-if="isIntern">Hier kommt der Fondsshopsanteil.</div>
    </div>
  </div>
</template>
<script>
import CMS_TYPES from "@/store/cms/types";
import { mapGetters } from "vuex";
import EditableFrame from "@/components/cms/EditableFrame.vue";
import FondsshopElement from "@/components/cms/parts/fondsshopElement.vue";
import CORE_TYPES from "@/store/core/types";
export default {
  components: {
    EditableFrame,
    FondsshopElement
  },
  computed: {
    ...mapGetters({
      getSelectedFond: CMS_TYPES.GETTERS.GET_SELECTED_FOND,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,

    })
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  beforeDestroy() {
    this.$store.commit(CMS_TYPES.MUTATIONS.SET_SELECTED_FOND, null);
  }
};
</script>
<style  scoped>
.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-text);
}
.fonds_container {
  margin: 0 auto;
  max-width: 1200px;
}

.beachten_wrapper {
  background: var(--color-background);
  padding-top: 2em;
  padding-bottom: 1em;
  margin-bottom: 2em;
}
.beachten {
  margin: 0 auto;
  text-align: justify;
  padding-left: 2em;
  padding-right: 2em;
}

.text_only_wrapper {
  padding: 2em;
}
.text_only {
  width: 70%;
  margin: 0 auto;
  text-align: justify;
}

.einzelfonds {
  padding-bottom: 2em;
  height: calc(100vh - 154px)
}
a {
  color: var(--color-link);
}
</style>