var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("GhostLoading", {
            attrs: { title: _vm.TABLE_TITLE, type: "table" }
          })
        : _c("Table", {
            attrs: {
              title: _vm.TABLE_TITLE,
              headers: _vm.headers,
              rows: _vm.rows,
              headerActions: _vm.tableHeaderActions
            },
            on: {
              "headerAction-ADD": function($event) {
                return _vm.addCustomer()
              },
              "action-DELETE": function($event) {
                return _vm.$emit("removeConfig", $event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "menuStructure",
                fn: function(row) {
                  return [
                    _c("ComboBox", {
                      attrs: {
                        values: _vm.menuStructureValues,
                        sortComboboxValues: false
                      },
                      on: {
                        change: function($event) {
                          return _vm.$emit("menuStructureChanged", {
                            kundennr: row.kundennr,
                            value: $event
                          })
                        }
                      },
                      model: {
                        value: row.menuStructureId,
                        callback: function($$v) {
                          _vm.$set(row, "menuStructureId", $$v)
                        },
                        expression: "row.menuStructureId"
                      }
                    })
                  ]
                }
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }