var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Versicherungstarife",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.hinzufugen()
          }
        }
      }),
      _vm.gesellschaften && _vm.gesellschaften.length
        ? _c("BaseFilter", {
            attrs: {
              filterId: "6552e591-cf1e-4737-ae8e-0e35ad3d7064",
              title: "Versicherungstarife filtern",
              metadata: _vm.filterMetadata,
              defaultOptions: _vm.defaultOptionsData
            },
            on: {
              onFilter: function($event) {
                return _vm.filterForm($event)
              }
            }
          })
        : !_vm.gesellschaften || !_vm.gesellschaften.length
        ? _c("GhostLoading", { attrs: { type: "block", useBoxContainer: "" } })
        : _vm._e(),
      _vm.tableRows && _vm.tableRows.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "f0741643-ed48-4d80-b9e1-d8e264690b01" }
            },
            [
              !_vm.loading && _vm.tableRows.length
                ? _c("Table", {
                    staticClass: "table-container",
                    attrs: {
                      tableId: "fb5a1434-a732-49c7-87ed-a43498bf9292",
                      title: _vm.TABLE_TITLE,
                      headers: _vm.tableHeaders,
                      rows: _vm.tableRows,
                      rowsPerPage: 20,
                      count: _vm.resultCount,
                      mobileConfig: _vm.tableMobileConfig
                    },
                    on: {
                      "click-bezeichnung": function($event) {
                        return _vm.bearbeiten($event)
                      },
                      action: function($event) {
                        return _vm.executeAction($event)
                      }
                    }
                  })
                : _vm.loading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: _vm.TABLE_TITLE }
                  })
                : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "removeItemConfirmation",
          attrs: { modalTitle: "Tarif löschen", labelButtonConfirm: "Löschen" },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeItem()
            }
          }
        },
        [
          _vm._v(
            " Wollen Sie den " +
              _vm._s(_vm.selectedTarif.bezeichnung) +
              ", " +
              _vm._s(_vm.selectedTarif.gesellschaftsname) +
              " wirklich löschen? "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }