export const CUSTOMER_SEARCH_HOME = '/customer/customer-search';
export const NEW_CUSTOMER_ID = 'new_customer';
export const CUSTOMER_PERSONALDATAADDRESS_TITLE_DEFAULT = 'Herr';
export const LAST_NAME_REQUIRED_TEXT = 'Name ist erforderlich!';
export const TITLE_REQUIRED_TEXT = 'Anrede ist erforderlich!';
export const PERSOENLICHEDATEN_HOME = '/persoenlichedaten/customer-data/steps/stammdaten';


export function isNewCustomerPage(route) {
  return route?.path?.search(/new-customer/gi) >= 0;
}

export function isStammdatenPage(route) {
  return route?.path?.search(/stammdaten/gi) >= 0;
}

export function getStammdatenLink(route) {
  if(isNewCustomerPage(route)) {
    return '/customer/kunde-anlegen/stammdaten';
  } else {
    return PERSOENLICHEDATEN_HOME;
  }
}

export function looksLikeKundennr(value) {
  // See ticket MSC-31834: some customers have negative values and 5 numbers (the same number as the broker)
  return /^\d{7}$/gi.test(`${value}`)  ||  /^-\d{5}$/.test(`${value}`);

}
