<template>
<div>
  <OptionMenu
    :id="$appNavigation.currentOptionMenuId"
    :defaultMenu="$appNavigation.currentOptionMenu" >
  </OptionMenu>

  <PageHeaderTitleNavigation
    :title="$appNavigation.currentMenu.label"
    :id="$appNavigation.currentOptionMenuId"
    :actions="actions"
    @action-NEW="goToScenario('new')">
  </PageHeaderTitleNavigation>

    <div>

      <div v-if="!scenariosList">
        <div class="row">
          <div class="col col-md-6">
            <GhostLoading type="block" useBoxContainer :config="{ block: { height: '250px', }, }" />
          </div>
          <div class="col col-md-6">
            <GhostLoading type="block" useBoxContainer :config="{ block: { height: '250px', }, }" />
          </div>
        </div>
      </div>

      <div class="row" v-else-if="scenariosList.length">
        <div class="col col-md-6" v-for="(scenario, index) of scenariosList" :key="index">
          <div class="box__container">

            <PageHeaderTitleNavigation 
              :id="scenario.id" 
              :actions="scenarioHeaderActions" 
              noPrimaryAction
              :showBackButton="false"
              @action-REMOVE="deleteScenario(scenario)"
            >
              <template #title><slot name="title">
                <div class="cards__item--header ">
                  <a @click="goToScenario(scenario.id)">
                    {{scenario.title}} | {{scenario.updateDate || scenario.startDate}}
                  </a>
                </div></slot>
              </template>
            </PageHeaderTitleNavigation>
            <div>
              <RetirementChart
                :data="{
                  data: apexSeries(scenario),
                  series: apexSeries(scenario),
                  xaxis: apexXaxis(scenario),
                  annotations: apexAnnotations(scenario),
                  markers: apexMarkers,
                  dataLabels: {enabled: false},
                  stroke: {curve: 'smooth'},
                  autoUpdateSeries: true,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <NoData v-else class="box__container"/>
    </div>
  </div>
</template>

<script>
import RUHESTANDSPLANUNG_TYPES from '@/store/ruhestandsplanung/types';
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import RetirementChart from '@/components/retirementScenario/RetirementChart.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import mixin from '@/views/ruhestandsplanung/mixin.js';
import { mapGetters } from 'vuex';

export default {
  mixins: [mixin],
  components: {
    NoData,
    GhostLoading,
    RetirementChart,
    PageHeaderTitleNavigation,
    OptionMenu,
  },
  computed: {
    ...mapGetters({
      scenarios: RUHESTANDSPLANUNG_TYPES.GETTERS.SCENARIO_LIST,
    }),
    scenariosList() {
      return this.scenarios || null;
    },
    actions() {
      return [
        PageHeaderSimpleAction('NEW', 'Neue Ruhestandsplanung')
      ]
    },
    scenarioHeaderActions() {
      return [PageHeaderSimpleAction('REMOVE', 'Ruhestandsplanung löschen')];
    },
  },
  mounted() {
    this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_SCENARIO_LIST);
  },
  methods: {
    goToScenario(id) {
      if (this.$route.path.includes('financial-planing')) {
        this.$router.push({ path: id })
      } else {
        this.$router.push({ path: `${id}/settings` });
      }
    },
    deleteScenario(scenario) {
      this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.DELETE_SCENARIO, scenario?.id);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Ruhestandsplanung', 
      to,
      from,
    });

    next();
  },
};
</script>

<style scoped>
.btn-group button {
  margin-right: 10px;
}
.hidden {
  display: none;
}
</style>