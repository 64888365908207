<template>
  <div v-if="editor" class="margin-0 flex gap-1 overflow-x-scroll w-full">
    <BaseToolbarComboBox
      v-for="category in textPlaceholderOptions"
      :key="category.name"
      :value="''"
      :values="placeholders(category.name)"
      :disabled="disabled"
      :firstEmpty="true"
      @change="insertPlaceholder($event, editor)"
    >
    </BaseToolbarComboBox>
  </div>
</template>

<script>
import BaseToolbarComboBox from "../../base-components/BaseToolbarComboBox.vue";
import { PhCaretRight, PhCaretLeft, PhXCircle, PhRewind } from "phosphor-vue";
import { BubbleMenu } from "@tiptap/vue-2";
import BaseButton from "@/components/core/BaseButton.vue";

export default {
  components: {
    PhCaretLeft,
    PhCaretRight,
    PhXCircle,
    PhRewind,
    BubbleMenu,
    BaseButton,
    BaseToolbarComboBox
  },

  props: {
    editor: {},

    textPlaceholderOptions: {
      type: Array,
      default: null,
    },

    disabled: {
      default: false,
    },
  },

  data() {
    return {
    };
  },

  computed: {
    hasFocus() {
      return this.editor && this.editor.view && this.editor.view.hasFocus()
    },
  },

  methods: {
    placeholders(category) {
      let list = []
      list.push(...(this.textPlaceholderOptions.find((item) => item.name == category)?.data || [])); //A seperate array is created to keep the unshift from adding to the original array (deepcopy)
      list.unshift({label: category, value: '' });
      return list
    },
    insertPlaceholder(value, editor) {
      this.editor?.commands.insertContent(value);
    }
  },
  watch: {
    
    
  },
  mounted() {
  }
};
</script>
<style>
.tippy-box[data-theme~='smartmsc'] {
  background-color: var(--color-background);
  color: var(--color-text);
}

.tippy-content[data-theme~='smartmsc'] {
  margin: 0px;
  padding: 0px;
}
.tippy-box[data-theme~='smartmsc'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: var(--color-background);
}
.tippy-box[data-theme~='smartmsc'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: var(--color-background);
}
</style>
<style scoped>
.margin-0 {
  margin: 0px;
}

.flex {
  display: flex;
}

.gap-1 {
  gap: 1rem;
}

.w-full {
  width: 100%;
}

.overflow-x-scroll {
  overflow-x: auto;
}

.placeholderSection {
  display: flex;
  flex: 0 0 auto;
  column-gap: 1rem;
  line-height: 1em;
  padding: 0;
  border: none;
}
</style>
