var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-body" },
    [
      _vm.isLoadingAppoiment
        ? [
            _c(
              "GhostLoading",
              { attrs: { useBoxContainer: "" } },
              [
                _c("Block", { attrs: { type: "title" } }),
                _c("Block", { attrs: { height: "250" } })
              ],
              1
            )
          ]
        : _vm.modus === "Empfänger"
        ? [
            _vm.rowsEmpf.length
              ? _c("Table", {
                  attrs: {
                    rowId: "label",
                    headers: _vm.empfHeaders,
                    rows: _vm.rowsEmpf,
                    hidePagination: ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "rolle",
                        fn: function(empf) {
                          return [
                            _c(
                              "div",
                              { staticStyle: { "min-width": "9em" } },
                              [
                                _c("ComboBox", {
                                  attrs: {
                                    label: "",
                                    value: empf.rolle,
                                    values: _vm.rolleOptions
                                  },
                                  on: {
                                    input: function(rolle) {
                                      empf.rolle = rolle
                                      _vm.onChangeEmpf(
                                        empf.index,
                                        "rolle",
                                        rolle
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3527917488
                  ),
                  model: {
                    value: _vm.selectedEmpf,
                    callback: function($$v) {
                      _vm.selectedEmpf = $$v
                    },
                    expression: "selectedEmpf"
                  }
                })
              : _c("NoData", {
                  attrs: { noIcon: "", content: "Keine Empfänger vorhanden" }
                }),
            _c("BaseButton", {
              attrs: { isSecondary: "", label: "Zurück" },
              on: {
                click: function($event) {
                  _vm.showEmpfaenger = false
                }
              }
            }),
            _vm.rowsEmpf.length
              ? _c("BaseButton", {
                  attrs: {
                    isSecondary: "",
                    label: "Markierte Empfänger hinzufügen",
                    disabled: !_vm.selectedEmpf.length
                  },
                  on: { click: _vm.setEmpfaenger }
                })
              : _vm._e(),
            _c("BaseButton", {
              attrs: { isSecondary: "", label: "Empfängersuche" },
              on: { click: _vm.handleAddUsers }
            })
          ]
        : [
            _c("div", { staticClass: "d-flex" }, [
              _c("span", [_vm._v("Titel")]),
              _vm.versId
                ? _c("span", { staticClass: "pl-2" }, [
                    _vm._v(" | Interne Nummer: "),
                    _c("a", { on: { click: _vm.openInsurance } }, [
                      _vm._v(_vm._s(_vm.versId))
                    ])
                  ])
                : _vm._e(),
              _vm.versId && _vm.schadenId
                ? _c("span", { staticClass: "pl-2" }, [
                    _vm._v(" | Schaden-Nr. "),
                    _c("a", { on: { click: _vm.openSchaden } }, [
                      _vm._v(_vm._s(_vm.schadenId))
                    ])
                  ])
                : _vm._e()
            ]),
            !_vm.preview
              ? _c("ShortcutInputField", {
                  attrs: {
                    label: "",
                    validateUntouched: "",
                    id: "title",
                    disabled: !_vm.canEdit || _vm.loading
                  },
                  model: {
                    value: _vm.label,
                    callback: function($$v) {
                      _vm.label = $$v
                    },
                    expression: "label"
                  }
                })
              : _vm._e(),
            _vm.preview
              ? _c("ShortcutInputField", {
                  attrs: {
                    label: "Titel",
                    disabled: "",
                    id: "preview_title",
                    validateUntouched: "",
                    disabled: !_vm.canEdit || _vm.loading
                  },
                  model: {
                    value: _vm.previewSubject,
                    callback: function($$v) {
                      _vm.previewSubject = $$v
                    },
                    expression: "previewSubject"
                  }
                })
              : _vm._e(),
            _vm.modus === "Aktivität" || _vm.modus === "Aufgabe"
              ? _c(
                  "div",
                  [
                    _c("InputField", {
                      attrs: {
                        label: "Bereich",
                        value: _vm.additionalParamsBereich,
                        disabled: true
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("hr"),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _vm.modus === "Termin"
                  ? _c("InputToggleSwitch", {
                      staticClass: "pr-3",
                      attrs: {
                        label: "Ganztägig",
                        disabled: !_vm.canEdit || _vm.loading
                      },
                      model: {
                        value: _vm.fullDay,
                        callback: function($$v) {
                          _vm.fullDay = $$v
                        },
                        expression: "fullDay"
                      }
                    })
                  : _vm._e(),
                _vm.modus !== "Aktivität"
                  ? _c("InputColorPicker", {
                      attrs: {
                        label: "Farbe",
                        disabled: !_vm.canEdit || _vm.loading
                      },
                      model: {
                        value: _vm.color,
                        callback: function($$v) {
                          _vm.color = $$v
                        },
                        expression: "color"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm.modus === "Aufgabe"
              ? _c(
                  "div",
                  { staticClass: "data-triple" },
                  [
                    _c("div", { staticClass: "padding-top" }, [
                      _c("label", { staticClass: "gridlabel" }, [
                        _vm._v("Von")
                      ]),
                      _c(
                        "div",
                        [
                          _c("DatePickerField", {
                            attrs: {
                              dateFormat: "DD.MM.YYYY",
                              disabled: !_vm.canEdit || _vm.loading
                            },
                            model: {
                              value: _vm.beginDate,
                              callback: function($$v) {
                                _vm.beginDate = $$v
                              },
                              expression: "beginDate"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "padding-top" }, [
                      _c("label", { staticClass: "gridlabel" }, [
                        _vm._v("Fälligkeit")
                      ]),
                      _c(
                        "div",
                        [
                          _c("DatePickerField", {
                            attrs: {
                              dateFormat: "DD.MM.YYYY",
                              disabled: !_vm.canEdit || _vm.loading
                            },
                            model: {
                              value: _vm.endDate,
                              callback: function($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("ComboBox", {
                      staticClass: "padding-top",
                      attrs: {
                        label: "Status",
                        values: _vm.statusOptions,
                        disabled: !_vm.canEdit || _vm.loading
                      },
                      model: {
                        value: _vm.additionalParamsstatus,
                        callback: function($$v) {
                          _vm.additionalParamsstatus = $$v
                        },
                        expression: "additionalParamsstatus"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.modus === "Aktivität" || _vm.modus === "Aufgabe"
              ? _c("ComboBox", {
                  attrs: {
                    label: "Verkaufsphase",
                    values: _vm.sellingOptions,
                    disabled: !_vm.canEdit || _vm.loading
                  },
                  model: {
                    value: _vm.additionalParamssellingPhase,
                    callback: function($$v) {
                      _vm.additionalParamssellingPhase = $$v
                    },
                    expression: "additionalParamssellingPhase"
                  }
                })
              : _vm._e(),
            _vm.modus === "Termin"
              ? _c("div", { staticClass: "data-double" }, [
                  _c("div", { staticClass: "padding-top" }, [
                    _c("label", { staticClass: "gridlabel" }, [_vm._v("Von")]),
                    _c(
                      "div",
                      { staticClass: "data-double" },
                      [
                        _c("DatePickerField", {
                          attrs: { dateFormat: "DD.MM.YYYY" },
                          model: {
                            value: _vm.beginDate,
                            callback: function($$v) {
                              _vm.beginDate = $$v
                            },
                            expression: "beginDate"
                          }
                        }),
                        _vm.beginDate
                          ? _c("TimePicker", {
                              attrs: {
                                label: "",
                                disabled:
                                  _vm.fullDay || !_vm.canEdit || _vm.loading
                              },
                              model: {
                                value: _vm.beginTime,
                                callback: function($$v) {
                                  _vm.beginTime = $$v
                                },
                                expression: "beginTime"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "padding-top" }, [
                    _c("label", { staticClass: "gridlabel" }, [_vm._v("Bis")]),
                    _c(
                      "div",
                      { staticClass: "data-double" },
                      [
                        _c("DatePickerField", {
                          attrs: { dateFormat: "DD.MM.YYYY" },
                          model: {
                            value: _vm.endDate,
                            callback: function($$v) {
                              _vm.endDate = $$v
                            },
                            expression: "endDate"
                          }
                        }),
                        _vm.endDate
                          ? _c("TimePicker", {
                              attrs: {
                                label: "",
                                disabled:
                                  _vm.fullDay || !_vm.canEdit || _vm.loading
                              },
                              model: {
                                value: _vm.endTime,
                                callback: function($$v) {
                                  _vm.endTime = $$v
                                },
                                expression: "endTime"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _vm.modus === "Aktivität" || _vm.modus === "Aufgabe"
              ? _c(
                  "div",
                  { staticClass: "data-double" },
                  [
                    _c("ComboBox", {
                      attrs: {
                        label: "Wiederholung",
                        values: _vm.retryCombo,
                        disabled: !_vm.canEdit || _vm.loading
                      },
                      model: {
                        value: _vm.retry,
                        callback: function($$v) {
                          _vm.retry = $$v
                        },
                        expression: "retry"
                      }
                    }),
                    _c("ComboBox", {
                      staticClass: "padding-top",
                      attrs: {
                        label: "Art",
                        values: _vm.artOptions,
                        disabled: !_vm.canEdit || _vm.loading
                      },
                      model: {
                        value: _vm.additionalParamsart,
                        callback: function($$v) {
                          _vm.additionalParamsart = $$v
                        },
                        expression: "additionalParamsart"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.modus === "Termin"
              ? _c(
                  "div",
                  { staticClass: "data-triple" },
                  [
                    _c("ComboBox", {
                      staticClass: "padding-top",
                      attrs: {
                        label: "Wiederholung",
                        values: _vm.retryCombo,
                        disabled: !_vm.canEdit || _vm.loading
                      },
                      model: {
                        value: _vm.retry,
                        callback: function($$v) {
                          _vm.retry = $$v
                        },
                        expression: "retry"
                      }
                    }),
                    _c("InputField", {
                      staticClass: "padding-top",
                      attrs: {
                        label: "Anzahl Termine",
                        disabled:
                          _vm.retry === "Keine" || !_vm.canEdit || _vm.loading
                      },
                      model: {
                        value: _vm.retryCount,
                        callback: function($$v) {
                          _vm.retryCount = $$v
                        },
                        expression: "retryCount"
                      }
                    }),
                    _c("DatePickerField", {
                      attrs: {
                        label: "Höchstens bis zum",
                        dateFormat: "DD.MM.YYYY",
                        disabled:
                          _vm.retry === "Keine" || !_vm.canEdit || _vm.loading
                      },
                      model: {
                        value: _vm.retryMaxDate,
                        callback: function($$v) {
                          _vm.retryMaxDate = $$v
                        },
                        expression: "retryMaxDate"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.modus === "Aktivität" || _vm.modus === "Aufgabe"
              ? [
                  _c(
                    "div",
                    { staticClass: "data-double padding-top" },
                    [
                      _c("InputField", {
                        attrs: {
                          label: "Produkt",
                          disabled: !_vm.canEdit || _vm.loading
                        },
                        model: {
                          value: _vm.additionalParamsprodukt,
                          callback: function($$v) {
                            _vm.additionalParamsprodukt = $$v
                          },
                          expression: "additionalParamsprodukt"
                        }
                      }),
                      _c("InputField", {
                        attrs: {
                          label: "Geschätzter Geschäftswert",
                          type: "number",
                          disabled: !_vm.canEdit || _vm.loading
                        },
                        model: {
                          value: _vm.additionalParamsgeschaeftwert,
                          callback: function($$v) {
                            _vm.additionalParamsgeschaeftwert = $$v
                          },
                          expression: "additionalParamsgeschaeftwert"
                        }
                      })
                    ],
                    1
                  )
                ]
              : _vm._e(),
            _c("ParticipantInput", {
              staticClass: "mb-1 padding-top",
              attrs: {
                label: "Teilnehmer",
                combinedDisplay: true,
                hidePagination: true,
                maxItemsVisible: _vm.maxItemsVisible,
                value: _vm.beteiligteValue,
                availableUsers: _vm.availableUsers,
                isUserSelect: false,
                isRole: true,
                isReceiveUserClickable: _vm.isReceiveUserClickable,
                showMailAndAddressActions: _vm.modus === "Termin",
                bereich: _vm.bereich,
                loading: _vm.searchReceiverUsersLoading,
                disabled: !_vm.canEdit || _vm.loading
              },
              on: {
                searchReceiverUsers: function($event) {
                  return _vm.searchUsers($event)
                },
                deleteReceiveUsers: function($event) {
                  return _vm.deleteUsers($event)
                },
                onChange: _vm.onChange,
                input: _vm.onAddParticipant,
                clickReceiveUsers: function($event) {
                  return _vm.openReceiveUser($event)
                },
                setBesitzer: _vm.setBesitzer,
                copyAddress: _vm.copyAddress,
                sendEmail: function($event) {
                  return _vm.mailSend()
                },
                sendEmailSingle: _vm.mailSend,
                setAddress: _vm.setAddress
              }
            }),
            _vm.modus === "Aktivität" || _vm.modus === "Aufgabe"
              ? _c(
                  "div",
                  { staticClass: "d-flex input-forms__container" },
                  [
                    _c(
                      "BaseButton",
                      {
                        staticClass: "mr-16",
                        attrs: {
                          isSecondary: "",
                          disabled: !_vm.canEdit || _vm.loading
                        },
                        on: { click: _vm.getEmpfaenger }
                      },
                      [_vm._v("Empfänger")]
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "d-flex input-forms__container justify-between overflow-x-scroll"
                  },
                  [
                    _c(
                      "BaseButton",
                      {
                        staticClass: "mr-16",
                        attrs: {
                          isSecondary: "",
                          disabled: !_vm.canEdit || _vm.loading
                        },
                        on: { click: _vm.addUserVermittler }
                      },
                      [_vm._v("Vermittler")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        staticClass: "mr-16",
                        attrs: {
                          isSecondary: "",
                          disabled: !_vm.canEdit || _vm.loading
                        },
                        on: { click: _vm.addUserKunde }
                      },
                      [_vm._v("Kunde")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        staticClass: "mr-16",
                        attrs: {
                          isSecondary: "",
                          disabled: !_vm.canEdit || _vm.loading
                        },
                        on: { click: _vm.addUserGesellschaft }
                      },
                      [_vm._v("Gesellschaft")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        staticClass: "mr-16",
                        attrs: {
                          isSecondary: "",
                          disabled: !_vm.canEdit || _vm.loading
                        },
                        on: { click: _vm.addUserKontakt }
                      },
                      [_vm._v("Kontakt")]
                    )
                  ],
                  1
                ),
            _vm.modus !== "Aktivität"
              ? _c(
                  "div",
                  { staticClass: "d-flex items-center " },
                  [
                    _c("InputField", {
                      staticClass: "flex_grow_1",
                      attrs: {
                        label: "Ort",
                        disabled: !_vm.canEdit || _vm.loading
                      },
                      model: {
                        value: _vm.place,
                        callback: function($$v) {
                          _vm.place = $$v
                        },
                        expression: "place"
                      }
                    }),
                    _c("InputToggleSwitch", {
                      staticClass: "pl-3",
                      attrs: {
                        label: "Privattermin",
                        disabled: !_vm.canEdit || _vm.loading
                      },
                      model: {
                        value: _vm.additionalParamsprivate,
                        callback: function($$v) {
                          _vm.additionalParamsprivate = $$v
                        },
                        expression: "additionalParamsprivate"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("ShortcutInputTextArea", {
              attrs: {
                label: "Beschreibung",
                disabled: !_vm.canEdit || _vm.loading
              },
              model: {
                value: _vm.text,
                callback: function($$v) {
                  _vm.text = $$v
                },
                expression: "text"
              }
            }),
            _c("hr"),
            _c(
              "div",
              { staticClass: "data-double" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Benachrichtigung per Popup",
                    values: _vm.durationOptions,
                    sortComboboxValues: false,
                    disabled: !_vm.canEdit || _vm.loading
                  },
                  model: {
                    value: _vm.popup,
                    callback: function($$v) {
                      _vm.popup = $$v
                    },
                    expression: "popup"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Benachrichtigung per E-Mail",
                    values: _vm.durationOptions,
                    sortComboboxValues: false,
                    disabled: !_vm.canEdit || _vm.loading
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c(
                  "DragnDropArea",
                  {
                    attrs: { hoverText: "Datei hier ablegen" },
                    on: {
                      files: function($event) {
                        return _vm.addDocument($event)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-block justify-content-between" },
                      [
                        _vm.allAttachments.length
                          ? _c("Table", {
                              attrs: {
                                headers: _vm.headers,
                                rows: _vm.rows,
                                hidePagination: "",
                                disabled: !_vm.canEdit || _vm.loading
                              },
                              on: { "action-DELETE": _vm.actionDelete },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "dateiname",
                                    fn: function(row) {
                                      return [
                                        _vm.appointmentSid
                                          ? _c("DownloadLink", {
                                              attrs: {
                                                title: row.dateiname,
                                                downloadServicePath:
                                                  "/termin_attachment",
                                                filename: row.dateiname,
                                                queryParams: {
                                                  sid: _vm.appointmentSid,
                                                  fileId: row.id
                                                }
                                              }
                                            })
                                          : _c("span", [
                                              _vm._v(_vm._s(row.dateiname))
                                            ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                727560957
                              )
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center"
                          },
                          [
                            _c(
                              "BaseButton",
                              {
                                staticClass: "upload-btn",
                                attrs: {
                                  isSecondary: "",
                                  disabled: !_vm.canEdit || _vm.loading
                                }
                              },
                              [
                                _vm._t("default", function() {
                                  return [_vm._v("Datei hochladen")]
                                }),
                                _c("input", {
                                  attrs: {
                                    type: "file",
                                    accept: _vm.acceptFileTypes,
                                    multiple: ""
                                  },
                                  on: { change: _vm.onAddFiles }
                                })
                              ],
                              2
                            ),
                            _vm.hasAccess(
                              "/communication/mailcomposer-dokumentenarchiv/calendar"
                            )
                              ? _c(
                                  "BaseButton",
                                  {
                                    attrs: {
                                      isPrimary: "",
                                      disabled: !_vm.canEdit || _vm.loading
                                    },
                                    on: { click: _vm.handleAddDocuments }
                                  },
                                  [_vm._v(" Dokumentenarchiv ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "action-buttons mb-3" },
              [
                _vm.getSelectedEvent.appointment &&
                _vm.getSelectedEvent.appointment.sid
                  ? _c(
                      "BaseButton",
                      {
                        staticClass: "mr-16",
                        attrs: {
                          isSecondary: "",
                          disabled: !_vm.canEdit || _vm.loading
                        },
                        on: { click: _vm.deleteAppointment }
                      },
                      [_vm._v("Löschen")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }