export const MUTATION_PREFIX = 'MENU_STRUCTURE: ';
export const ACTIONS_PREFIX = 'MENU_STRUCTURE_ACTIONS_';
export const GETTERS_PREFIX = 'MENU_STRUCTURE_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',

    SET_MENU_STRUCTURES: MUTATION_PREFIX + 'SET_MENU_STRUCTURES',
    SET_MENU_STRUCTURE_CONTEXT: MUTATION_PREFIX + 'SET_MENU_STRUCTURE_CONTEXT',
    SET_MENU_STRUCTURE_EDITED: MUTATION_PREFIX + 'SET_MENU_STRUCTURE_EDITED',
    RESET_MENU_STRUCTURE_CONTEXT_EDITED: MUTATION_PREFIX + 'RESET_MENU_STRUCTURE_CONTEXT_EDITED',
    ADD_MENU: MUTATION_PREFIX + 'ADD_MENU',
    REMOVE_MENU: MUTATION_PREFIX + 'REMOVE_MENU',
    ORDER_CHANGED: MUTATION_PREFIX + 'ORDER_CHANGED',

    SET_MENU_STRUCTURE_CONFIGS: MUTATION_PREFIX + 'SET_MENU_STRUCTURE_CONFIGS',
    ADD_MENU_STRUCTURE_CONFIGS_EDITED: MUTATION_PREFIX + 'ADD_MENU_STRUCTURE_CONFIGS_EDITED',
    REMOVE_MENU_STRUCTURE_CONFIGS_EDITED: MUTATION_PREFIX + 'REMOVE_MENU_STRUCTURE_CONFIGS_EDITED',
    RESET_MENU_STRUCTURE_CONFIGS_EDITED: MUTATION_PREFIX + 'RESET_MENU_STRUCTURE_CONFIGS_EDITED',
  },
  ACTIONS: {
    FIND_MENU_STRUCTURES: ACTIONS_PREFIX + 'FIND_MENU_STRUCTURES',
    SETUP_MENU_STRUCTURE_CONTEXT: ACTIONS_PREFIX + 'SETUP_MENU_STRUCTURE_CONTEXT',
    SAVE_MENU_STRUCTURE: ACTIONS_PREFIX + 'SAVE_MENU_STRUCTURE',
    REMOVE_MENU_STRUCTURE: ACTIONS_PREFIX + 'REMOVE_MENU_STRUCTURE',
    SAVE_OPTIONS_MENU: ACTIONS_PREFIX + 'SAVE_OPTIONS_MENU',

    FIND_MENU_STRUCTURE_GLOBAL_CONFIG: ACTIONS_PREFIX + 'FIND_MENU_STRUCTURE_GLOBAL_CONFIG',
    SAVE_MENU_STRUCTURE_GLOBAL_CONFIG: ACTIONS_PREFIX + 'SAVE_MENU_STRUCTURE_GLOBAL_CONFIG',
    REMOVE_MENU_STRUCTURE_GLOBAL_CONFIG: ACTIONS_PREFIX + 'REMOVE_MENU_STRUCTURE_GLOBAL_CONFIG',
  },
  GETTERS: {
    MENU_STRUCTURES: GETTERS_PREFIX + 'MENU_STRUCTURES',
    MENU_STRUCTURE_CONTEXT: GETTERS_PREFIX + 'MENU_STRUCTURE_CONTEXT',
    HAS_MENU_STRUCTURE_EDITED: GETTERS_PREFIX + 'HAS_MENU_STRUCTURE_EDITED',

    MENU_STRUCTURE_CONFIGS: GETTERS_PREFIX + 'MENU_STRUCTURE_CONFIGS',
    HAS_MENU_STRUCTURE_CONFIGS_EDITED: GETTERS_PREFIX + 'HAS_MENU_STRUCTURE_CONFIGS_EDITED',
  },
}
