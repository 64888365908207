import { regex, required, requiredTrue, minValue, maxValue, formatPLZ, formatPhone, minDate, email, maxLength } from "@/mixins/validator/rules";

export const fondsArt = {
  Investmentfonds: 'INVESTMENTFONDS',
  InvestmentfondsOhneZertifikat: 'INVESTMENTFONDS_OHNE_ZERTIFIKAT',
  Zertifikate: 'ZERTIFIKATE',
  Beteiligungen: 'GESCHLOSSENEFONDS'
}

export function isHiddenDisabled(hiddenOrDisabled, data = {}, respectImmer = true) {
  if (hiddenOrDisabled && Object.keys(hiddenOrDisabled).length) {
    const contains = Object.keys(hiddenOrDisabled).some(key => key === 'IMMER'
      ? respectImmer : hiddenOrDisabled[key].some((value) =>
      (value && typeof(value) === 'string' && value === "neverIfEmpty" && data[key]) || 
      (value && typeof(value) === 'string' && value === "immerIfEmpty" && !data[key]) || 
      (value && typeof(value) === 'string' && value.startsWith('not(') && value.length > 5 
      && value.substring(4, value.length - 1) !== data[key])
      || (value === data[key])
    ));
    return contains;
  }
  return false;
}

export function isStepHidden(hiddenArgument, value) {
  if (hiddenArgument?.startsWith('not(') && hiddenArgument?.length > 5) {
    return hiddenArgument.substring(4, hiddenArgument.length - 1) !== value
  } else {
    return hiddenArgument === value
  }
}

export function getStepVisible(completeStepList, antragData) {
  const resultList = []

  for (const step of completeStepList) {
    if (!step.hidden) {
      resultList.push(step)
      continue;
    }

    if (antragData) {
      if(Object.keys(step.hidden).length>1){
        let isHidden = false
        for(const hiddenProp of Object.keys(step.hidden)){
          if (Array.isArray(step.hidden[hiddenProp])) {
            if (step.hidden[hiddenProp].some(value => isStepHidden(value, antragData[hiddenProp]) || (!value && !antragData[hiddenProp]))) {
              isHidden = true
            }
          } else if (isStepHidden(step.hidden[hiddenProp], antragData[hiddenProp])) {
            isHidden = true
          }
        }
        if(!isHidden){
          resultList.push(step)
        }
      }else{
        for (const hiddenProp of Object.keys(step.hidden)) {
          if (Array.isArray(step.hidden[hiddenProp])) {
            if (!step.hidden[hiddenProp].some(value => isStepHidden(value, antragData[hiddenProp]) || (!value && !antragData[hiddenProp]))) {
              resultList.push(step)
            }
          } else if (!isStepHidden(step.hidden[hiddenProp], antragData[hiddenProp])) {
            resultList.push(step)
          }
        }
      }
      
    }
  }

  return resultList
}

export function getFondsArt(isBeteiligung) {
  if (isBeteiligung){
      return fondsArt.Beteiligungen;
  } else {
      return fondsArt.InvestmentfondsOhneZertifikat;
  }
}

export function getServletName(lagerstelle) {
  if (lagerstelle) {
    return `mrs${lagerstelle.toLocaleLowerCase().replaceAll('_', '').replaceAll(' ', '').replaceAll('.', '')}servlet`;
  }
}

export function getSavingServletName(id, isDynamic = false) {
  if (isDynamic) {
    return 'mrsdynamicantragsaveservlet'
  }
  if (id) {
    return id.startsWith('VERSICHERUNG') ? 'mrsversicherungmaklerauftragsaveservlet' : 'mrsantragsaveservlet';
  }
}

export function getSubmitServletName(id) {
  if (id) {
    return 'mrsantragsubmitservlet';
  }
}

export function calculateAntragID(payload) {
  return `${payload.lagerstelle}-${payload.antragsName}`;
}

export function componentValidatorToRule(componentValidator) {
  const options = {...componentValidator}

  if(componentValidator.type === 'pattern') {
    return regex(new RegExp(componentValidator.value), componentValidator.errorMessage, options);
  }

  if(componentValidator.type === 'patternEmptyAllowed') {
    return regex(new RegExp(componentValidator.value), componentValidator.errorMessage, {...options, emptyAllowed: true});
  }

  if(componentValidator.type === 'required') {
    return required(componentValidator.errorMessage, options);
  }

  if(componentValidator.type === 'requiredTrue') {
    return requiredTrue(componentValidator.errorMessage, options);
  }

  if(componentValidator.type === 'min') {
    return minValue(componentValidator.value, componentValidator.errorMessage, options);
  }

  if(componentValidator.type === 'max') {
    return maxValue(componentValidator.value, componentValidator.errorMessage, {...options, inclusive: true});
  }

  if(componentValidator.type === 'formatPLZ') {
    return formatPLZ(componentValidator.value, options);
  }

  if(componentValidator.type === 'formatPhone') {
    return formatPhone(componentValidator.value, options);
  }

  if(componentValidator.type === 'email') {
    return email(componentValidator.errorMessage, options);
  }
  
  if(componentValidator.type === 'minDate') {
    return minDate(componentValidator.value, componentValidator.errorMessage, options);
  }

  if(componentValidator.type === 'maxLength') {
    return maxLength(componentValidator.value, componentValidator.errorMessage, options);
  }

  throw 'Component Validator not mapped in "componentValidatorToRule" function';
}
