<template>
	<div class="box__container">
		<div class="font-strong">
			Zu prüfende Vermittlernummern
		</div>

		<InputField 
			label="Maklernummer des Hauptvermittlers"
			isComponentHalfSize
			:disabled="true"
			v-model="form.displayMaklernrVertrag"/>

		<div>
			Hier können Sie die aktuellem einbezogenen Vermittlernummern überprüfen:
		</div>

		<div class="mb-2">
			<div class="layout__negative-margin--8">
				<BaseButton isSecondary @click="loadMaklerPreview()">
					Vermittlernummern prüfen
				</BaseButton>

				<BaseButton isSecondary @click="loadBestaendePreview()">
					Kundenbestände prüfen
				</BaseButton>
			</div>
		</div>

		<InputTextArea
			label="Gegebenenfalls weitere Untervermittler (bitte beachten Sie, dass diese
			Untervermittler für die Prüfung die gleiche Registernr §34 eingetragen haben müssen):"
			@change="fieldChanged()"
			v-model="form.unternummernZusatz"/>
		
		<hr/>

		<div class="font-strong">
			Aufklärungen und Nachweise
		</div>

		<div>
			Die Aufklärungen und Nachweise, um die Sie mich (uns) gemäß § 25 Abs. 1 FinVermV gebeten haben, habe ich (haben 
			wir) Ihnen nach bestem Wissen und Gewissen gegeben. Als Auskunftspersonen habe ich (haben wir) Ihnen die nachfolgend
			aufgeführten Personen benannt:
		</div>

		<InputTextArea
			label="Bitte machen Sie auch Angaben zur Kontaktaufnahme, vorzugsweise per Telefon. 
			Dies beschleunigt die Bearbeitung des Auftrags."
			@change="fieldChanged()"
			v-model="form.personenAuskunft"/>

		<ComboBox 
			isComponentHalfSize
			label="Bekannte Personen hinzufügen:"
			v-model="form.comboPersonenAuskunft"
			:values="vertragWPValues.maklerPeople"/>

		<div class="mb-2">
			<BaseButton 
				@click="addPerson()"
				isSecondary>
				Hinzufügen
			</BaseButton>
		</div>

		<div>
			Diese Person/en ist/sind von mir (uns) angewiesen worden, 
			Ihnen alle gewünschten Auskünfte und Nachweise richtig und vollständig zu geben.
		</div>

		<hr/>
		
		<div class="font-strong">
			Bücher, Schriften und sonstige Unterlagen
		</div>

		<InputRadioBoxGroup 
			title="Im Rahmen der geforderten Buchführung werden:"
			v-model="form.edvModus"
			@input="fieldChanged()"
			:values="vertragWPValues.buchfuehrungEdv"/>

		<BaseModal 
			ref="bestaendePreviewData" 
			modalTitle="Wirtschaftsprüfung Kontrolle - Bestände"
			size="xl"
			:showDefaultButtons="false">
			<div>
				<Table v-if="bestaendePreview.length"
					:headers="bestaendePreviewHeaders"
					:rows="bestaendePreview"
					rowId="kundennr"
					hidePagination
        />
				<NoData v-else />
			</div>
		</BaseModal>

		<BaseModal 
			ref="maklerPreviewData" 
			modalTitle="Wirtschaftsprüfung Kontrolle - Vermittlernummern"
			size="xl"
			:showDefaultButtons="false">
			<div>
				<Table v-if="maklerPreview.length"
					:headers="maklerPreviewHeaders"
					:rows="maklerPreview"
					rowId="maklernr"
					hidePagination
        />

				<NoData v-else />
			</div>
		</BaseModal>

	</div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue'
import VERTRAG_WP_TYPES from '@/store/vertragWP/types.js'
import axios from 'axios'
import CORE_TYPES from '@/store/core/types.js'
import LOG_TYPES from '@/store/log/types.js'
import { mapGetters } from 'vuex';
import { makeQueryParam } from '@/helpers/utils-helper';
import { buildMessageWith } from "@/helpers/log-message-helper";
import validator from "@/mixins/validator";
import BaseModal from '@/components/core/BaseModal.vue';
import Table from "@/components/table2/Table.vue";
import NoData from '@/components/core/NoData.vue';
import {TextColumn, CurrencyColumn} from "@/components/table2/table_util.js";

export default {
	mixins: [validator],

	props: {
		formData: {
			type: Object,
			default: {}
		}
	},

	computed: {
		...mapGetters({
			hinweise: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_HINWEIS,
			vertragWPValues: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_VALUES,
			apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
			errors: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_ERRORS,
		}),
		errorsCurrentStep() {
			return this.errors?.filter?.(err => err.stepKey === 'contractData1') || []
		},

		maklerPreviewHeaders() {
			return {
				center: [
					TextColumn("maklernr", "Vermittlernr"),
					TextColumn("maklerName", "Vermittlername"),
					TextColumn("nr34f", "zugeh. Registernr."),
					TextColumn("passivmakler", "Passivmakler"),
				],
			}
		},

		bestaendePreviewHeaders() {
			return {
				center: [
					TextColumn("maklernr", "Vermittlernr"),
					TextColumn("maklerName", "Vermittlername"),
					TextColumn("kundennr", "Kundennr"),
					TextColumn("kundeName", "Kundenname"),
					CurrencyColumn("wertB", "Beratungen (EUR)").withSumFooter(),
					CurrencyColumn("wertV", "Vermittlungen (EUR)").withSumFooter(),
				],
			}
		}
	},

	watch: {
		formData: {
			handler(newValue) {
				this.loadForm(newValue)
			},
			immediate: true,
		},
		errorsCurrentStep(errors) {
			for (const error of errors) {
				this.$pushErrors('form.' + error.id, error.message)
			}
		}
	},

	data() {
		return {
			form: {},
			maklerPreview: [],
			bestaendePreview: [],
		}
	},

	validators: {
		form: []
	},

	methods: {
		loadForm(newValue) {
			this.$set(this, 'form', Object.assign({}, newValue))
		},

		async addPerson() {
			const params = {
				type: 'AUSKUNFT',
				personId: this.form?.comboPersonenAuskunft,
				currentPersonen: this.form?.personenAuskunft
			}

			const queryParams = makeQueryParam(params, true)

			const response = await axios.get(`${this.apiAddress}/vertrag_wp/text_add_person?${queryParams}`)
			if (response?.data) {
					if (response?.data?.message) {
						this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
							message: response?.data?.message
						}));
						
					} else {
						this.$set(this.form, 'personenAuskunft', response.data.personenAuskunft)
						this.fieldChanged();
					}
				}
		},

		loadMaklerPreview() {
			this.$confirmModal({ 
				message: "Vermittlernummern die hier bereits aufgelistet sind brauchen NICHT nochmals im folgenden Textfeld vermerkt werden.",
				title: 'Information',
				showCancelButton: false,
        labelButtonConfirm: 'Ok' })
			.then(async () => {
				this.maklerPreview = []
				this.maklerPreview = await this.$store.dispatch(VERTRAG_WP_TYPES.ACTIONS.GET_MAKLER_PREVIEW_DATA);
				this.$refs.maklerPreviewData.open()
			})
		},

		async loadBestaendePreview() {
			this.bestaendePreview = []
			this.bestaendePreview = await this.$store.dispatch(VERTRAG_WP_TYPES.ACTIONS.GET_BESTAENDE_PREVIEW_DATA);
			this.$refs.bestaendePreviewData.open()
		},

		fieldChanged() {
			this.$emit('fieldChanged', this.form)
		}
	},

	components: {
		InputField,
		BaseButton,
		InputTextArea,
		ComboBox,
		InputRadioBoxGroup,
		BaseModal,
		Table,
		NoData,
	}
}
</script>

<style scoped>

</style>