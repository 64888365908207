import MULTIBANKING_TYPES from './types';

export default {
  [MULTIBANKING_TYPES.GETTERS.PARAMETERS](state) {
    return state.parameters;
  },
  [MULTIBANKING_TYPES.GETTERS.ACCOUNTS](state) {
    return state.accounts;
  },
  [MULTIBANKING_TYPES.GETTERS.SAVINGS_POTENTIAL_BY_ACCOUNT](state) {
    return state.savingsPotentialByAccount;
  },
  [MULTIBANKING_TYPES.GETTERS.SAVINGS_POTENTIAL](state) {
    return state.savingsPotential;
  },
  [MULTIBANKING_TYPES.GETTERS.SAVINGS_POTENTIAL_TOTAL](state) {
    return state.savingsPotential ? state.savingsPotential.sumTotal : 0;
  },
  [MULTIBANKING_TYPES.GETTERS.TRANSACTIONS](state) {
    return state.transactions;
  },
  [MULTIBANKING_TYPES.GETTERS.BANKSAPI_URL](state) {
    return state.banksapiURL;
  },
  [MULTIBANKING_TYPES.GETTERS.LOAD_CONTRACTS](state) {
    return state.contracts;
  },
  [MULTIBANKING_TYPES.GETTERS.CATEGORY_TREE](state) {
    if (!state.categories)
      return null
    const result = []
    for (let i = 0; i < state.categories.length; i++)
      if (!state.categories[i].parentCategory)
        result.push(state.categories[i])
    return result;
  },
  [MULTIBANKING_TYPES.GETTERS.CATEGORY_TREE_ALL](state) {
      if (!state.availableCategories.categories)
        return null
      const result = []
      for (let i = 0; i < state.availableCategories.categories.length; i++)
        if (!state.availableCategories.categories[i].parentCategory)
          result.push(state.availableCategories.categories[i])
      return result;
    },
  [MULTIBANKING_TYPES.GETTERS.CATEGORIES_BY_KEY](state) {
    const result = {}
    state.categories?.forEach(category => {
      result[category.category] = category
    })
    return result
  },
  [MULTIBANKING_TYPES.GETTERS.DEPOTS](state) {
    return state.depots;
  },
  [MULTIBANKING_TYPES.GETTERS.ACCOUNTS_SUM](state) {
    return state.accountsSum;
  },
  [MULTIBANKING_TYPES.GETTERS.DAILY_ACCOUNT_HISTORY](state) {
    return state.dailyAccountsHistory;
  },
  [MULTIBANKING_TYPES.GETTERS.TRANSACTION_SPLITS](state) {
    return state.transactionSplits;
  },
  [MULTIBANKING_TYPES.GETTERS.USER_SETTINGS](state) {
    return state.userSettings;
  },
  [MULTIBANKING_TYPES.GETTERS.SUMMARY](state) {
    return state.summary
  },
  [MULTIBANKING_TYPES.GETTERS.LOADED_STATUS](state) {
    return state.loaded
  },
  [MULTIBANKING_TYPES.GETTERS.FILTERS](state) {
    return state.filters
  }
}