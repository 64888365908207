import GUBBI_TYPES from './types';
import { getInitialState } from './index'

export default {
  [GUBBI_TYPES.MUTATIONS.RETRIEVE_GUBBI_ASSETS_SUCCESS](state, payload) {
    state.gubbi_assets = payload;
  }, 
  [GUBBI_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  },
  
}