import {preformancePerAnno} from "@/helpers/commonfunctions"

/*
* Map old table structure to new one
*/
export function mapNewTableStructure(tableData, priority = {}, sum = {}, isActionAvailable = false, autoSum = {}, ) {
    return {
        ...tableData,
        records: tableData && tableData.records && tableData.records.map(record => 
            record
                .filter((v, i) => isActionAvailable || tableData.tableHeaders[i].dataType !== 'Action')
                .reduce((acc, row, index) => ({
                ...acc,
                [tableData.tableHeaders[index].key]: row
            }), {})
        ) || [],
        headers: tableData.tableHeaders
            .filter(v => isActionAvailable || v.dataType !== 'Action')
            .reduce((acc, curr) => ({
            ...acc,
            [curr.key]: {
                dataType: curr.dataType,
                filterable: false,
                label: curr.label,
                priority: priority[curr.key] || curr.priority ? 'VISIBLE_ALWAYS' : 'HIDDEN',
                visible: true,
                key: curr.key,
                sortable: false,
                sum: sum[curr.key] || false,
                autoSum: autoSum[curr.key]
            }
        }), {})
    }
}

export const virtualDepot = {
    rows: [],
    guvAbDatum: null,
    depots(vermoegen) {
        const depots = vermoegen.depots || [];
        this.rows = vermoegen.rows || [];
        const ab = (vermoegen.guvAbDatum || '01.01.1970').split('.').map(a => parseInt(a, 10));
        this.guvAbDatum = Date.UTC(ab[2], ab[1]-1, ab[0]);
        let kunde = '', filter, value, kunden = [];
        if (depots.length) {
            let data = []
            if (!vermoegen.superKunde) {
                kunden.push({kname: '', data})
            }
            depots.forEach(d => {
                const item = JSON.parse(JSON.stringify(d));
                item.key = item.kundennr + '_' + item.depotname;
                if (vermoegen.superKunde) {
                    if (kunde != vermoegen.namen[item.kundennr]) {
                        kunde = vermoegen.namen[item.kundennr]
                        item.kunde = kunde
                        data = [];
                        kunden.push({kname: kunde, data})
                    }
                }
                data.push(item)
                filter = {kundennr: item.kundennr, depotname: item.depotname};
                item.perf_pa = this.getPerfPA(filter, item.perf_virtual_depot);
                const guv_sum_final = vermoegen.useRealisiert ? item.guv_sum : item.guv_sum - item.guv_r
                const zahlungen = item.depotwert - guv_sum_final
                item.summen = [
                    [vermoegen.useRealisiert ? 'Ein-/Auszahlungen' : 'Einzahlungen', zahlungen, '€'],
                    // ['Ein-/Auszahlungen', item.auszahlungen + item.einzahlungen + item.wechsel, '€' ], 
                    ['Depotbestand', item.depotwert, '€' ], 
                    ['G&V', guv_sum_final, '€'],
                    // ['G&V', item.guv_sum, '€' ]
                ];
                if (vermoegen.showGVRealisiert && vermoegen.useRealisiert) {
                    item.summen.push(
                    ['aktuelle G&V', item.guv_a, '€' ], 
                    ['realisierte G&V', item.guv_r, '€' ] );
                }
                if (vermoegen.guvProzent){
                    item.summen.push(['Wertentwicklung (gesamt)', item.perf_virtual_depot, '%' ])
                }
                if (vermoegen.guvPerAnno){
                    item.summen.push(['Wertentwicklung p.a.', item.perf_pa, '%' ])
                }
                const bezug = {};
                this.rows.filter(row => this.matchRow(row, filter)).forEach(row => {
                    bezug[row.lagerstelle] = 1
                })
                item.lagerstelle = [];
                Object.keys(bezug).forEach(key => {
                    filter.lagerstelle = key;
                    value = this.getSum(filter, 'depotwert');
                    item.lagerstelle.push([key, this.getSum(filter, 'depotwert') * 100 / d.depotwert ]);
                })
            })
        }
        vermoegen.virtualDepots = kunden;
        
        const gesamt = vermoegen.gesamt || {};
        filter = {};
        gesamt.perf_pa = this.getPerfPA(filter, gesamt.perf_gesamt);
        const guv_sum_final = vermoegen.useRealisiert ? gesamt.guv_sum : gesamt.guv_sum - gesamt.guv_r
        const zahlungen = gesamt.depotwert - guv_sum_final
        gesamt.summen = [
            [vermoegen.useRealisiert ? 'Ein-/Auszahlungen' : 'Einzahlungen', zahlungen, '€'],
            // ['Ein-/Auszahlungen', gesamt.auszahlungen + gesamt.einzahlungen + gesamt.wechsel, '€' ], 
            ['Depotbestand', gesamt.depotwert, '€' ], 
            ['G&V', guv_sum_final, '€' ], 
            // ['G&V', gesamt.guv_sum, '€' ], 
            
        ];
        if (vermoegen.showGVRealisiert && vermoegen.useRealisiert) {
            gesamt.summen.push(
            ['aktuelle G&V', gesamt.guv_a, '€' ], 
            ['realisierte G&V', gesamt.guv_r, '€' ] );
        }
        if (vermoegen.guvProzent){
            gesamt.summen.push(['Wertentwicklung (gesamt)', gesamt.perf_gesamt, '%' ])
        }
        if (vermoegen.guvPerAnno){
            gesamt.summen.push(['Wertentwicklung p.a.', gesamt.perf_pa, '%' ])
        }
        vermoegen.gesamt = gesamt;
        return vermoegen;
    },
    matchRow(row, filter) {
      return (filter.kundennr === undefined || filter.kundennr === row.kundennr)
      && (filter.depotname === undefined || filter.depotname === row.depotname)
      && (filter.lagerstelle === undefined || filter.lagerstelle === row.lagerstelle)
      && row.datum_zuerst != null
    },
    getSum(filter, key) {
      return this.rows.reduce((sum, row) => sum + (this.matchRow(row, filter) ? (row[key] || 0) : 0), 0);
    },
    getMax(filter, key) {
      return this.rows.reduce((max, row) => (this.matchRow(row, filter) ?  Math.max(max===null ? row[key] : max, row[key]): max), null);
    },
    getMin(filter, key) {
      return this.rows.reduce((min, row) => (this.matchRow(row, filter) ?  Math.min(min===null ? row[key] : min, row[key]): min), null);
    },
    getPerfPA(filter, perf) {
      const now = new Date();
      const heute = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
      const anteile = this.getSum(filter, 'anteile');
      const begin = Math.max(this.getMin(filter, 'datum_zuerst'), this.guvAbDatum);
      const ende = anteile == 0 ? this.getMax(filter, 'datum_zuletzt') : heute;
      const years = (ende - begin) / 86400000 / 365;
      return preformancePerAnno(years, perf);
    },

}
