var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        showCancelButton: false,
        labelButtonConfirm: "Schließen",
        modalTitle: "Monte-Carlo-Simulation Erklärung"
      },
      on: {
        onCloseButton: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("p", [
        _vm._v(
          "Zur Berechnung der obigen Renditewahrscheinlichkeiten wird ein Modell erstellt, das mit Hilfe der Kriterien Rendite und Volatilität zahlreiche Szenarien generiert, um die möglichen Entwicklungen eines Portfolios oder einer Anlage zu simulieren. Anleger können mit dieser, auch Monte-Carlo-Simulation genannten, Methode ein besseres Verständnis dafür gewinnen, wie sich ihre Investitionen unter unterschiedlichen Marktbedingungen entwickeln könnten. Unsicherheiten und Risiken von Investitionen können so besser bewertet werden, wie zum Beispiel das Erreichen eines bestimmten Anlageziels oder das Risiko eines Verlusts."
        )
      ]),
      _c("p", [
        _vm._v(
          "Im obigen Schaubild werden drei Wahrscheinlichkeitskorridore gezeigt. Der wichtigste (blau hinterlegte) Korridor ist der, der zwischen der ungünstigen und günstigen Wertentwicklung liegt. Er sagt aus, dass mit einer Wahrscheinlichkeit von 50 % mit einem Wert in diesem Intervall gerechnet werden kann. In 25 % der Fälle sogar mit einer höheren Summe, in weiteren 25 % allerdings auch mit niedrigeren Werten. Der Maximal- und Minimalwert stellen die besten beziehungsweise schlechtesten Verläufe dar. Zu guter Letzt zeigt der Median den Mittelwert aller Wahrscheinlichkeiten an, d. h., die Hälfte aller Renditen liegt über dieser Linie, die andere Hälfte darunter."
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }