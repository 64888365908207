var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "row" }, [
          _vm.canViewCourtageStep
            ? _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputField", {
                    attrs: {
                      id: "courtageBemerkung",
                      label:
                        _vm.isIntern && !_vm.isMaklerzugang
                          ? "Bemerkung zur Courtage (Sichtbar für Vermittler)"
                          : "Bemerkung zur Courtage",
                      disabled: _vm.cannotEditAPBemerkung
                    },
                    on: {
                      input: function($event) {
                        return _vm.dataChanged("courtageBemerkung", $event)
                      }
                    },
                    model: {
                      value:
                        _vm.versicherungenDetails.insurance.courtageBemerkung,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.versicherungenDetails.insurance,
                          "courtageBemerkung",
                          $$v
                        )
                      },
                      expression:
                        "versicherungenDetails.insurance.courtageBemerkung"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("InputField", {
                attrs: {
                  id: "produktbezeichnung",
                  label: "Produktbezeichnung",
                  disabled: ""
                },
                model: {
                  value: _vm.versicherungenDetails.produktbez,
                  callback: function($$v) {
                    _vm.$set(_vm.versicherungenDetails, "produktbez", $$v)
                  },
                  expression: "versicherungenDetails.produktbez"
                }
              })
            ],
            1
          )
        ]),
        _vm.isIntern
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("InputCheckBoxItem", {
                    attrs: { label: "ThinkSurance Vergleichsrechner" },
                    model: {
                      value: _vm.form.thinksuranceVergleich,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "thinksuranceVergleich", $$v)
                      },
                      expression: "form.thinksuranceVergleich"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.isIntern
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("InputCheckBoxItem", {
                    attrs: { label: "ThinkSurance Angebotserstellung" },
                    model: {
                      value: _vm.form.thinksuranceAngebot,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "thinksuranceAngebot", $$v)
                      },
                      expression: "form.thinksuranceAngebot"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.editable
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col 12" },
                [
                  _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.openNewBuchung()
                        }
                      }
                    },
                    [_vm._v("Neue")]
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.loading && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "a97811c0-a7f2-4ac8-abb1-7dde57ab6810",
                  title: "Abrechnungen",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10,
                  rowId: "buchungsnr",
                  exportConfig: { roottext: "Abrechnungen" }
                },
                on: {
                  "click-buchungsnr": function($event) {
                    return _vm.openVerteilungAufDieStruktur($event)
                  },
                  "action-BEARBEITEN": _vm.openEditBuchung,
                  "action-STRUKTUR": _vm.addNewStruktur,
                  "action-DELETE": function($event) {
                    return _vm.deleteAbrechnung($event)
                  },
                  "action-COPY": function($event) {
                    return _vm.copyVersicherungBuchung($event)
                  },
                  "action-STORNO": function($event) {
                    return _vm.stornierenVersicherungBuchung($event)
                  }
                },
                model: {
                  value: _vm.selectedRowsAbrechnungTable,
                  callback: function($$v) {
                    _vm.selectedRowsAbrechnungTable = $$v
                  },
                  expression: "selectedRowsAbrechnungTable"
                }
              })
            : _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _c("NoData", {
                attrs: { content: "Es wurden keine Einträge gefunden" }
              })
        ],
        1
      ),
      _vm.selectedMasterItem
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              !_vm.loadingDetails && _vm.detailRows.length
                ? _c("Table", {
                    attrs: {
                      title:
                        "Buchungsnr " +
                        _vm.selectedMasterItem.buchungsnr +
                        " - Verteilung der Abrechnung auf die Struktur",
                      headers: _vm.detailHeaders,
                      rows: _vm.detailRows,
                      rowsPerPage: 10
                    },
                    on: {
                      "click-lfdNr": _vm.openEditStruktur,
                      "action-DELETE": function($event) {
                        return _vm.deleteStruktur($event)
                      }
                    },
                    model: {
                      value: _vm.detailSelectedRows,
                      callback: function($$v) {
                        _vm.detailSelectedRows = $$v
                      },
                      expression: "detailSelectedRows"
                    }
                  })
                : _vm.loadingDetails
                ? _c("GhostLoading", { attrs: { type: "table" } })
                : _c("NoData", {
                    attrs: { content: "Es wurden keine Einträge gefunden" }
                  })
            ],
            1
          )
        : _vm._e(),
      _c("BaseModal", {
        ref: "buchungDeleteModal",
        attrs: {
          size: "sm",
          modalTitle: "Buchung löschen",
          labelButtonConfirm: "Löschen"
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.deleteBuchungItem()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " Soll die Buchung " +
                    _vm._s(_vm.abrechnungItemToDelete.buchungsnr) +
                    " wirklich gelöscht werden? "
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "buchungCopyModal",
        attrs: {
          size: "sm",
          modalTitle: "Buchung kopieren",
          labelButtonConfirm: "kopieren"
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.copyBuchungItem()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("h3", { staticClass: "color-danger" }, [_vm._v("ACHTUNG!")]),
                _c("p", [
                  _vm._v(
                    "Die Courtagesätze werden gemäß der Verteilung des Originals ebenfalls kopiert!"
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "Dies gilt allerdings nur solange Sie die Verteilung nicht neu berechnen lassen!"
                  )
                ]),
                _c("p", [_vm._v("Möchten Sie mit dem Kopieren fortfahren?")])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "buchungStornierenModal",
        attrs: {
          size: "sm",
          modalTitle: "Buchung Stornieren",
          labelButtonConfirm: "Stornieren"
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.stornierenBuchungItem()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("h3", { staticClass: "color-danger" }, [_vm._v("ACHTUNG!")]),
                _c("p", [
                  _vm._v(
                    "Die Courtagesätze werden gemäß der Verteilung des Originals ebenfalls kopiert und ins Minus gesetzt!"
                  )
                ]),
                _c("p", [
                  _vm._v(
                    "Dies gilt allerdings nur solange Sie die Verteilung nicht neu berechnen lassen!"
                  )
                ]),
                _c("p", [_vm._v("Möchten Sie mit dem Stornieren fortfahren?")])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "verteilungStrukturModal",
        attrs: {
          size: "sm",
          modalTitle: "Verteilung auf die Struktur",
          showDefaultButtons: false
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm.isNewStruktur
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("ComboBox", {
                            attrs: {
                              id: "previousLfdNr",
                              label: "Lfd. Nummer",
                              values: _vm.comboboxValues,
                              validateUntouched: true
                            },
                            model: {
                              value: _vm.detailForm.previousLfdNr,
                              callback: function($$v) {
                                _vm.$set(_vm.detailForm, "previousLfdNr", $$v)
                              },
                              expression: "detailForm.previousLfdNr"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("InputField", {
                            attrs: {
                              id: "lfdNr",
                              label: "Lfd. Nummer",
                              disabled: ""
                            },
                            model: {
                              value: _vm.detailForm.lfdNr,
                              callback: function($$v) {
                                _vm.$set(_vm.detailForm, "lfdNr", $$v)
                              },
                              expression: "detailForm.lfdNr"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("InputField", {
                        attrs: {
                          label: "Vermittlernummer",
                          validateUntouched: ""
                        },
                        on: {
                          input: function($event) {
                            return _vm.findMaklerDataDebounce($event)
                          },
                          onBlur: function($event) {
                            return _vm.checkIfMaklerDataIsValid()
                          }
                        },
                        model: {
                          value: _vm.detailForm.maklernr,
                          callback: function($$v) {
                            _vm.$set(_vm.detailForm, "maklernr", $$v)
                          },
                          expression: "detailForm.maklernr"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("InputField", {
                        attrs: {
                          id: "provSatz",
                          label: "Courtagesatz",
                          type: "percent",
                          precision: 2,
                          validateUntouched: true
                        },
                        model: {
                          value: _vm.detailForm.provSatz,
                          callback: function($$v) {
                            _vm.$set(_vm.detailForm, "provSatz", $$v)
                          },
                          expression: "detailForm.provSatz"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-3",
                    attrs: { isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.closeEditStruktur()
                      }
                    }
                  },
                  [_vm._v("Abbrechen")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      disabled:
                        _vm.validation.isInvalid("detailForm") ||
                        _vm.isMaklernrInvalid
                    },
                    on: {
                      click: function($event) {
                        _vm.saveForm()
                        _vm.closeEditStruktur()
                      }
                    }
                  },
                  [_vm._v("Speichern")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "deleteModal",
        attrs: {
          size: "sm",
          modalTitle: "Provisionssatz löschen",
          labelButtonConfirm: "Löschen"
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.deleteStrukturItem()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [_vm._v(" Soll der Datensatz wirklich gelöscht werden? ")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }