<template>
  <div class="box__container">
    <BaseCollapsable :showSection="showExpanded">
      <template v-slot:title>
        <span class="box__title">
          Hinweise und Fehler
        </span>
        <span v-if="hasHinweis">&nbsp;|&nbsp; ({{hinweise.length}} Hinweis{{hinweise.length > 1 ? 'e' : ''}})</span>
        <span v-if="hasWarnings" class="color-danger">&nbsp;|&nbsp; {{hinweisText}}</span>
        
      </template>
      <template v-slot:content>
        <div class="antrag-sidetext__container mt-8px" v-if="!hasHinweis && !hasWarnings">
          <div class="row">
            <div class="col-12">
              Keine Hinweise vorhanden.
            </div>
          </div>
        </div>
        <div class="antrag-sidetext__container mt-8px" v-if="hasHinweis || hasWarnings">
          <template v-if="hasWarnings">
            <div class="row">
              <div class="col-12">
                <span class="font-bold">Fehler:</span>
              </div>
            </div>
            <ul>
              <div class="row" v-for="(warning, index) of sortWarnings" :key="index">
                <div class="col-12" 
                  :class="{clickable: isWarningNavigable(warning),
                            'color-danger': warning.status === 'FEHLER'}"
                  @click="warningClicked(warning)">
                  <li>
                    <span class="font-bold">{{getWarTitle(warning)}}</span>
                    <span>{{getWarnMessage(warning)}}</span>
                    <span v-if="isWarningNavigable(warning)" class="ml-1">
                      <ph-pencil-line :size="16" />
                    </span>
                  </li>
                </div>
              </div>
            </ul>
            <!--
            <div class="color-danger font-bold mt-2" v-if="hasFehlerStatus">
              Rot markierte Einträge müssen korrigiert werden, um speichern zu können.
            </div>
            -->
          </template>
          <template v-if="hasHinweis">
            <div class="row">
              <div class="col-12" >
                <span class="font-bold">Hinweise:</span>
              </div>
            </div>
            <ul>
              <div class="row" v-for="(hinweis, index) of hinweise" :key="index">
                <div class="col-12"
                  :class="{clickable: isWarningNavigable(hinweis), 'loading-active': hinweis.loading || isSomeActionLoading(hinweis), }"
                    @click="warningClicked(hinweis)">
                  <li>
                    <span v-if="hinweis.loading" class="mr-1"><AnimatedSpinner /></span>
                    <span class="font-bold" v-if="hinweis.label || hinweis.title">{{ hinweis.label || hinweis.title }}: </span> 
                    <span v-html="sanitize(hinweis.text || hinweis.message)"></span>
                    <span v-if="hinweis.actions && hinweis.actions.length" class="ml-1">
                      <a v-for="(action, index) in hinweis.actions" :key="index" :class="{ 'ml-2': index > 0 }" 
                        @click="executeAction(hinweis, action)"><span v-if="action.loading" class="mr-1"><AnimatedSpinner /> </span>{{ action.label }}</a>
                    </span>
                    <span v-if="isWarningNavigable(hinweis)" class="ml-1">
                      <ph-pencil-line :size="16" />
                    </span>
                  </li>
                </div>
              </div>
            </ul>
          </template>
        </div>
      </template>
    </BaseCollapsable>
  </div>
</template>

<script>
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import { PhPencilLine } from 'phosphor-vue'
import BaseCollapsable from '@/components/core/BaseCollapsable.vue'
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import {sanitize} from '@/helpers/string-helper.js';

export default {
  mixins: [antragNavigationMixin, antragMixin],
  props: {
    showMessageErrorMustBeFixed: {
      type: Boolean,
      default: false,
    },
    showExpanded: {
      type: Boolean,
      default: false,
    },
    warnings: {
    },
    steps: {
      type: Array,
      default: () => [],
    },
    highestStepVisited: {
      type: Number,
      default: -1,
    },
    hinweiseProps: {
      type: Array,
      default: () => []
    },
    hinweisText: {
      type: String,
      default: "Das Formular enthält Fehler",
    },
    showHinweisOnEveryStep: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    currentStep() {
      const routeStep = this.$route.params.step || 
        this.$route.fullPath.lastIndexOf('/') > 0 && this.$route.fullPath.lastIndexOf('/') < this.$route.fullPath.length 
        && this.$route.fullPath.substring(this.$route.fullPath.lastIndexOf('/') + 1);
      if (routeStep && this.steps?.length) {
        return this.steps.find(st => st.stepKey === routeStep);
      }
    },
    currentWarnings() {
      let result = [];
      if (this.warnings) {
        if (this.warnings.antragWarnings)
          result = result.concat(this.warnings.antragWarnings);
        if (this.warnings.positionWarnings)
          result = result.concat(this.warnings.positionWarnings);
      }
      result = result.filter(warn => warn.status !== 'HINWEIS')
        .filter(warning => warning.status === 'FEHLER' || (!this.highestStepVisited)
          || this.steps.filter(step => !step.hidden).findIndex(step => step.stepKey === warning.stepKey) <= this.highestStepVisited);

      return result.sort((a,b) => (a.title || '').localeCompare(b.title || ''));
    },
    currentHinweise() {
      let result = [];
      if (!this.showHinweisOnEveryStep && this.currentStep?.stepKey !== 'aktionen') {
        return result;
      }
      result = result.concat(this.warnings.antragWarnings || []);
      result = result.concat(this.warnings.positionWarnings || []);
      return result.filter(warn => warn.status === 'HINWEIS')?.map(warn => ({label: warn.title || '', text: warn.message || ''}));
    },
    hasWarnings() {
      return this.sortWarnings?.length;
    },
    hinweise() {
      if (this.hinweiseProps?.length) {
        return this.hinweiseProps;
      }
      let text = [];
      if (this.currentStep?.hinweise) {
        text.splice(0, 0, ...this.currentStep.hinweise);
      }
      if (this.currentStep?.substeps) {
        const routeSubstep = this.$route.params.substep;        
        const currentSubstep = this.currentStep?.substeps.find(sst => sst.substepKey === routeSubstep);
        if (currentSubstep?.hinweise) {
          text.splice(text.length, 0, ...currentSubstep.hinweise);
        }
      }
      // remove duplicates
      const mapUnique = (this.currentHinweise || []).concat(text).reduce((a, b) => {
        if (b?.text) {
          a[b.text] = b.label;
        }
        return a;
      }, {});
      text = Object.keys(mapUnique).map(key => ({ label: mapUnique[key], text: key}));
      return text;
    },
    hasHinweis() {
      return this.hinweise && this.hinweise.length
    },
    sortWarnings() {
      const sortWarnings = this.currentWarnings?.length ? [...this.currentWarnings] : [];
      return sortWarnings.sort((a,b) => (a.status === 'FEHLER' ? -1 : (b.status === 'FEHLER' ? 1 : 0)));
    },
    hasFehlerStatus() {
      return this.sortWarnings && this.sortWarnings.length && this.sortWarnings.some(warn => warn.status === 'FEHLER');
    },
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    getClass(warning)  {
      return {'clickable': this.isWarningNavigable(warning),
              'color-danger': warning.status === 'FEHLER'}
    },
    isSimpleWarning(warning) {
      return typeof warning === 'string';
    },
    getWarTitle(warning) {
      if (warning) {
        if (!this.isSimpleWarning(warning)) {
          let title = (warning.substepKey ? this.steps.find(step => step.stepKey === warning.stepKey)?.substeps?.find(substep => substep.substepKey === warning.substepKey)?.title
            : warning.stepKey ? (this.steps.find(step => step.stepKey === warning.stepKey) || {}).label
            : warning.title) || '';
          if (warning.config?.titleExtra) {
            title += (' ' + warning.config.titleExtra);
          }
          return title && `Schritt ${title}: ` || '';
        }
      }
    },
    getWarnMessage(warning) {
      if (warning) {
        return this.isSimpleWarning(warning) ? warning : (warning.message || '')
      }
    },
    isWarningNavigable(warning) {
      return warning?.stepKey;
    },
    warningClicked(warning = {}) {
      if (warning.substepKey) {
        this.$emit('setSubstep', { stepKey: warning.stepKey, substepKey: warning.substepKey })
      } else {
        this.$emit('setStep', warning.stepKey);
        this.$emit('navigateToWarning', warning);
      }
    },
    isSomeActionLoading(warning) {
      return warning?.actions?.some(action => action?.loading);
    },
    executeAction(warning, action) {
      if(warning?.loading || this.isSomeActionLoading(warning)) {
        return;
      }

      if(action?.key) {
        this.$emit('executeAction', { ...action, })
      }
    },
  },
  components: {
    PhPencilLine,
    BaseCollapsable,
    AnimatedSpinner,
  }
}
</script>

<style scoped>

.loading-active {
  opacity: 0.5;
}

</style>