<template>
  <div class="layout__negative-margin--8 antrag-action-button__container">
    <DownloadLink :href="downloadButtonLink" asButton>
      {{label}}
    </DownloadLink>
  </div>
</template>

<script>
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import { downloadLinkMaker } from '@/helpers/utils-helper';

export default {
  components: {
    DownloadLink,
  },
  props: {
    label: {
      type: String,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    downloadButtonLink() {
      return downloadLinkMaker(this.$store.getters, '/downloadbutton/download', null, { file:  this.config.file });
    },
  },
};
</script>

<style scoped>
</style>
