var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "layout__negative-margin--8 antrag-action-button__container"
    },
    [
      _c(
        "DownloadLink",
        { attrs: { href: _vm.getHilfeLinkMaker("de"), asButton: "" } },
        [_vm._v(" Ausfüllhilfe (Deutsch) herunterladen ")]
      ),
      _c(
        "DownloadLink",
        { attrs: { href: _vm.getHilfeLinkMaker("eng"), asButton: "" } },
        [_vm._v(" Vollständiges Formular (Englisch) herunterladen ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }