var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "tree", class: "level-" + _vm._level },
    _vm._l(_vm.itemsWithParents, function(item, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "tree__item",
          class: {
            "has-children":
              item[_vm.childrenKey] && item[_vm.childrenKey].length,
            selected: _vm.itemSelectedId === item[_vm.idKey],
            opened:
              !_vm.collapsed(item) &&
              item[_vm.childrenKey] &&
              item[_vm.childrenKey].length
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "draggable-target",
                  rawName: "v-draggable-target",
                  value: item.$droppable,
                  expression: "item.$droppable"
                }
              ],
              staticClass: "tree__item--container",
              on: {
                dropped: function($event) {
                  return _vm.$emit("dropped", $event)
                }
              }
            },
            [
              item[_vm.childrenKey] && item[_vm.childrenKey].length
                ? _c(
                    "button",
                    {
                      staticClass: "tree__item--collapse-toggle clickable",
                      class: { opened: !_vm.collapsed(item) },
                      attrs: {
                        type: "button",
                        tid: _vm._generateTidFromString("btn1" + item.nodeId),
                        alt: _vm.ariaDescription
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleCollapse(item)
                        }
                      }
                    },
                    [
                      _c("ph-caret-right", {
                        attrs: { size: 24, alt: _vm.ariaDescription }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "tree__item--content clickable",
                  attrs: { tid: _vm._generateTidFromString(item.nodeId) },
                  on: {
                    click: function($event) {
                      return _vm.handleClick(item, $event, true)
                    }
                  }
                },
                [_vm._t("default", null, { item: item })],
                2
              ),
              _vm.showArrowRight
                ? _c(
                    "button",
                    {
                      staticClass: "tree__item--arrow-right clickable",
                      attrs: {
                        type: "button",
                        tid: _vm._generateTidFromString("btn2" + item.nodeId),
                        alt: _vm.ariaDescription
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleClick(item, $event, true)
                        }
                      }
                    },
                    [
                      _c("ph-caret-right", {
                        attrs: { size: 24, alt: _vm.ariaDescription }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _c(
            "transition",
            { attrs: { name: "collapse" } },
            [
              item[_vm.childrenKey] && item[_vm.childrenKey].length
                ? _c("Tree", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.collapsed(item),
                        expression: "!collapsed(item)"
                      }
                    ],
                    ref: "subtree",
                    refInFor: true,
                    staticClass: "subtree",
                    attrs: {
                      items: item[_vm.childrenKey],
                      childrenKey: _vm.childrenKey,
                      idKey: _vm.idKey,
                      itemSelectedId: _vm.itemSelectedId,
                      showArrowRight: _vm.showArrowRight,
                      defaultCollapsed: _vm.defaultCollapsed,
                      uniqueId: _vm.uniqueId,
                      _level: _vm._level + 1,
                      isCollapsable: _vm.isCollapsable
                    },
                    on: {
                      itemSelect: _vm.handleClick,
                      dropped: function($event) {
                        return _vm.$emit("dropped", $event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._t("default", null, { item: item })]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }