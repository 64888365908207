<template>
    <div class="p-24px">
        <div class="box__container">
            <div class="box__title">Aktivierung Ihres Zugangs</div>
            <p>Vielen Dank für Ihre Registrierung.</p>
            <p>in Kürze erhalten Sie von uns eine E-Mail und eine SMS mit je einem Aktivierungscode. Die E-Mail enthält außerdem Ihre Kundennummer, diese entspricht Ihrem Loginnamen.</p>
            <p>Falls Sie die E-Mail nicht erhalten, prüfen Sie Ihren Spam-Ordner bzw. wenden sich an Ihren Betreuer.</p>
            <InputField
                label="Login / Kundennummer"
                v-model="loginName"
                :disabled="lockLoginName"
            />
            <InputField
                label="Sicherheitscode aus E-Mail"
                v-model="emailToken"
                :disabled="lockEmailToken"
            />
            <InputField
                label="SMS Code"
                v-model="smsToken"
            />
            
            <div>
                <BaseButton @click="activateAccount()" :disabled="loading ||!loginName || !emailToken || !smsToken">Zugang aktivieren</BaseButton>
                <BaseButton @click="resendTokens()" :disabled="loading" isSecondary>Sicherheitscode erneut senden</BaseButton>
                <BaseButton @click="goToLogin()" isSecondary>Zurück zum Login</BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';

import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';

import axios from 'axios';
import { buildMessage } from '@/helpers/log-message-helper';

export default {
    components: {
        InputField,
        BaseButton,
        AnimatedSpinner,
    },
    data() {
        return {
            lockLoginName: false,
            lockEmailToken: false,
            loginName: "",
            emailToken: "",
            smsToken: "",
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        async resendTokens() {
            this.loading = true;
            try {
                await axios.post(`${process.env.VUE_APP_API}/register/request_tokens`, {loginName: this.loginName});
            } catch(e) {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Aktivierungstoken konnten nicht gesendet werden. Entweder ist der Login schon aktiviert, oder die Kundennummer ist falsch.", 'danger'));
            } finally {
                this.resetFilledTokens();
                this.loading = false;
            }
        },
        async activateAccount() {
            this.loading = true;
            let data = {
                loginName: this.loginName,
                emailToken: this.emailToken,
                smsToken: this.smsToken,
            };
            try {
                let response = await axios.post(`${process.env.VUE_APP_API}/register/activate`, data);
                const responseData = { ...response.data, currentDate: new Date().getTime()}
                const referrer = document.referrer;
                this.$store.dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, {
                    data: {
                        ...responseData,
                        rights: {
                            ...responseData?.rights,
                        }
                    }, 
                    isOriginalUser: true,
                    referrer,
                })
            } catch (e) {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Der Login konnte nicht aktiviert werden. Lassen Sie sich erneut Tokens zusenden, bevor Sie es noch einmal versuchen.", 'danger'));
                this.resetFilledTokens();
            } finally {
                this.loading = false;
            }
        },
        resetFilledTokens() {
            this.lockEmailToken = false;
            this.emailToken = "";
            this.smsToken = "";
        },
        goToLogin() {
            this.$router.push('/login');
        },
    },
    mounted() {
        this.loginName = this.$route.query?.loginName || "";
        this.emailToken = this.$route.query?.emailToken || "";
        this.lockLoginName = !!this.loginName;
        this.lockEmailToken = !! this.emailToken;
    },
}
</script>
