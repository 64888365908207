<template>
  <div class="box__container">
    <div class="box__title">Hobbys</div>
    <div class="row">
      <div class="col-12">
        <div v-if="isEditable" class="mb-8px">
          <ComboBox 
            :isComponentHalfSize="true"
            :values="optionen" 
            v-model="hobbyCombo" 
            :sortComboboxValues="false" 
            @change="resetHobbyField()" />
          <InputField 
            :isComponentHalfSize="true" 
            v-model="hobbyField" 
            @input="resetHobbyCombo()" />
          <BaseButton :disabled="!isValid" @click="addHobbys()">Hinzufügen</BaseButton>
        </div>

        <div>
            <Table v-if="rows.length"
                tableId="2bab20e1-b1e1-44ad-aad2-ebacbb3eb51c"
                :headers="headers"
                :rows="rows"
                :rowsPerPage="10"
                rowId="hobby"
                @action-DELETE="removeHobbys($event.hobby)"
            />
            <NoData v-else noIcon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import NoData from '@/components/core/NoData.vue';


export default {
  props: {
    optionen: {
      type: Array,
      required: true
    },
    liste: {
      type: Array,
      required: true
    },
    isEditable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
        ...this.initialState(),
        hobbyCombo: '',
        hobbyField: '',
        headers: {
            lockedLeft: [
                TextColumn("hobby", "Hobby"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ]
        },
    };
  },
  computed: {
    rows() {
        const actions = [
            SimpleAction("DELETE", 'PhTrash', "Löschen"),
        ];
        return (this.hobbies || []).map(hobby => ({
            hobby,
            actions: this.isEditable ? actions : null,
        }))
    },
    currentValue() {
      return (this.hobbyCombo || this.hobbyField || '').trim();
    },
    isValid() {
      return !!this.currentValue;
    },
  },
  methods: {
    initialState() {
      return {
        hobbies: [],
      };
    },
    resetHobbyField() {
      this.hobbyField = '';
    },
    resetHobbyCombo() {
      this.hobbyCombo = '';
    },
    updateGroupsIntoData() {
      this.hobbies = [...this.liste];
    },
    addHobbys() {
      const hobby = this.currentValue;
      if (hobby && this.hobbies.findIndex(v => v?.toLowerCase?.() === hobby?.toLowerCase?.()) < 0) {
        this.hobbies.unshift(hobby);
        this.$emit('setHobbys', this.hobbies);
      }

      this.resetHobbyField();
      this.resetHobbyCombo();
    },
    removeHobbys(hobby) {
      const i = this.hobbies.findIndex((v)=> v === hobby);
      this.hobbies.splice(i, 1);
      this.$emit('setHobbys', this.hobbies);
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  components: {
    Table,
    InputField,
    ComboBox,
    BaseButton,
    NoData,
  },
}
</script>
