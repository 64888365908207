var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vertragsdatenFieldsDefinition
    ? _c("div", [
        _c(
          "div",
          { staticStyle: { display: "flex", "flex-direction": "column" } },
          [
            _vm.vertragsdatenFieldsDefinition.gesellschaft.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("gesellschaft") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("ComboBox", {
                          attrs: {
                            label: "Gesellschaft",
                            values: _vm.gesellschaftList,
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.gesellschaft
                                .disabled,
                            validateUntouched: true
                          },
                          on: {
                            change: function($event) {
                              return _vm.dataChanged("gesellschaft", $event)
                            }
                          },
                          model: {
                            value: _vm.versicherung.gesellschaft.id,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung.gesellschaft, "id", $$v)
                            },
                            expression: "versicherung.gesellschaft.id"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.displayFremdges.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("displayFremdges")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputToggleSwitch", {
                          attrs: {
                            label: "Zeige Fremdgesellschaften",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.displayFremdges
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged("displayFremdges")
                            }
                          },
                          model: {
                            value: _vm.versicherung.displayFremdges,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "displayFremdges", $$v)
                            },
                            expression: "versicherung.displayFremdges"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("vertragssparte") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("ComboBox", {
                      attrs: {
                        label: "Vertragssparte",
                        values:
                          _vm.vertragsdatenFieldsDefinition.vertragssparte
                            .value,
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.vertragssparte
                            .disabled,
                        validateUntouched: true
                      },
                      on: {
                        change: function($event) {
                          return _vm.dataChanged("vertragssparte", $event)
                        }
                      },
                      model: {
                        value: _vm.versicherung.description.id,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung.description, "id", $$v)
                        },
                        expression: "versicherung.description.id"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm.vertragsdatenFieldsDefinition.aktuellerStatus.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("aktuellerStatus")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6 form-control" },
                      [
                        _c("InputField", {
                          staticClass: "align-end",
                          attrs: {
                            label: "Aktueller Status",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.aktuellerStatus
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.status,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "status", $$v)
                            },
                            expression: "versicherung.status"
                          }
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "align-end",
                            staticStyle: { "flex-grow": "0" },
                            on: {
                              click: function($event) {
                                return _vm.goToVertraghistory()
                              }
                            }
                          },
                          [_c("PhPencilLine", { attrs: { size: 24 } })],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.polNr.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("nummer") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            id: "versicherung_nummer",
                            label:
                              _vm.vertragsdatenFieldsDefinition.polNr.value,
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.polNr.disabled,
                            placeholder:
                              _vm.vertragsdatenFieldsDefinition.polNr
                                .validationMessage,
                            validateUntouched: true
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged("nummer")
                            }
                          },
                          model: {
                            value: _vm.versicherung.nummer,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "nummer", $$v)
                            },
                            expression: "versicherung.nummer"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.produktbezeichnung.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("produktbezeichnung")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            label: "Produktbezeichnung",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition
                                .produktbezeichnung.disabled,
                            maxLength: 300
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.produktbez,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "produktbez", $$v)
                            },
                            expression: "versicherung.produktbez"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.vertragsbeginn.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("vertragsbeginn")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("DatePickerField", {
                          attrs: {
                            label: "Vertragsbeginn",
                            isValueAsString: "",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.vertragsbeginn
                                .disabled,
                            validateUntouched: _vm.isFPPlus
                          },
                          on: {
                            input: function($event) {
                              return _vm.laufzeitChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.beginn,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "beginn", $$v)
                            },
                            expression: "versicherung.beginn"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.vertragsende.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("vertragsende") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6 form-control" },
                      [
                        _c("InputRadioBoxGroup", {
                          staticClass: "align-end",
                          staticStyle: { "flex-grow": "0" },
                          attrs: { values: [{ value: "ENDE", label: "" }] },
                          on: {
                            input: function($event) {
                              _vm.versicherung.laufzeit = ""
                            }
                          },
                          model: {
                            value: _vm.versicherung.anzeigeModusDauer,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.versicherung,
                                "anzeigeModusDauer",
                                $$v
                              )
                            },
                            expression: "versicherung.anzeigeModusDauer"
                          }
                        }),
                        _c("DatePickerField", {
                          staticClass: "align-end",
                          attrs: {
                            label: "Vertragsende",
                            isValueAsString: "",
                            disabled:
                              _vm.versicherung.anzeigeModusDauer === "LAUFZEIT"
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged("vertragsende")
                            }
                          },
                          model: {
                            value: _vm.versicherung.ende,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "ende", $$v)
                            },
                            expression: "versicherung.ende"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.laufzeit.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("laufzeit") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6 form-control" },
                      [
                        _c("InputRadioBoxGroup", {
                          staticClass: "align-end",
                          staticStyle: { "flex-grow": "0" },
                          attrs: { values: [{ value: "LAUFZEIT", label: "" }] },
                          on: {
                            input: function($event) {
                              _vm.versicherung.ende = ""
                            }
                          },
                          model: {
                            value: _vm.versicherung.anzeigeModusDauer,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.versicherung,
                                "anzeigeModusDauer",
                                $$v
                              )
                            },
                            expression: "versicherung.anzeigeModusDauer"
                          }
                        }),
                        _c("InputField", {
                          staticClass: "align-end",
                          attrs: {
                            label: "Laufzeit (Jahre)",
                            disabled:
                              _vm.versicherung.anzeigeModusDauer === "ENDE",
                            validateUntouched: _vm.isFPPlus
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged("laufzeit")
                            }
                          },
                          model: {
                            value: _vm.versicherung.laufzeit,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "laufzeit", $$v)
                            },
                            expression: "versicherung.laufzeit"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.hauptfaelligkeit.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("hauptfaelligkeit")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("DatePickerField", {
                          attrs: {
                            label:
                              _vm.vertragsdatenFieldsDefinition.hauptfaelligkeit
                                .value,
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.hauptfaelligkeit
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.hauptfaelligkeit,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.versicherung,
                                "hauptfaelligkeit",
                                $$v
                              )
                            },
                            expression: "versicherung.hauptfaelligkeit"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.pramieBrutto.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("pramieBrutto") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            id: "input__field__pramieBrutto",
                            type: "currency",
                            precision: 2,
                            label: "Bruttoprämie gem. Zahlweise",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.pramieBrutto
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.praemiebto_zahlweise,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.versicherung,
                                "praemiebto_zahlweise",
                                $$v
                              )
                            },
                            expression: "versicherung.praemiebto_zahlweise"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.pramieNetto.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("pramieNetto") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            type: "currency",
                            precision: 2,
                            label: "Nettoprämie gem. Zahlweise",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.pramieNetto
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.praemiento_zahlweise,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.versicherung,
                                "praemiento_zahlweise",
                                $$v
                              )
                            },
                            expression: "versicherung.praemiento_zahlweise"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.zahlrhythmus.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("zahlrhythmus") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("ComboBox", {
                          attrs: {
                            label: "Zahlweise",
                            firstEmpty: true,
                            values:
                              _vm.vertragsdatenFieldsDefinition.zahlrhythmus
                                .value,
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.zahlrhythmus
                                .disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.dataChanged("zahlrhythm", $event)
                            }
                          },
                          model: {
                            value: _vm.versicherung.zahlrhythm,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "zahlrhythm", $$v)
                            },
                            expression: "versicherung.zahlrhythm"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.isFA &&
            _vm.vertragsdatenFieldsDefinition.zahlweiseBemerkung.visible &&
            _vm.versicherung.zahlrhythm == "Sonstiges"
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("zahlweiseBemerkung")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            id: "input__field__zahlweiseBemerkung",
                            label: "Zahlweise Bemerkung",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition
                                .zahlweiseBemerkung.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.zahlweiseBemerkung,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.versicherung,
                                "zahlweiseBemerkung",
                                $$v
                              )
                            },
                            expression: "versicherung.zahlweiseBemerkung"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.zahlungsart.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("zahlungsart") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("ComboBox", {
                          attrs: {
                            label: "Zahlungsart",
                            firstEmpty: true,
                            values:
                              _vm.vertragsdatenFieldsDefinition.zahlungsart
                                .value,
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.zahlungsart
                                .disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.dataChanged("zahlungsart", $event)
                            }
                          },
                          model: {
                            value: _vm.versicherung.zahlungsart,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "zahlungsart", $$v)
                            },
                            expression: "versicherung.zahlungsart"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("bankverbindung") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("EntitySelector", {
                      attrs: {
                        label: "Bankverbindung",
                        values:
                          _vm.vertragsdatenFieldsDefinition.bankverbindung.value
                      },
                      on: {
                        change: function($event) {
                          return _vm.dataChanged("bankverbindung", $event)
                        },
                        new: function($event) {
                          return _vm.newBankverbindung()
                        },
                        edit: function($event) {
                          return _vm.editBankverbindung()
                        }
                      },
                      model: {
                        value: _vm.versicherung.bankverbindung,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "bankverbindung", $$v)
                        },
                        expression: "versicherung.bankverbindung"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _vm.vertragsdatenFieldsDefinition.externeKennung.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("externeKennung")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            label: "Ext. Kennung",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.externeKennung
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.externeKennung,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "externeKennung", $$v)
                            },
                            expression: "versicherung.externeKennung"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.eigenbestand.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("eigenbestand") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputToggleSwitch", {
                          attrs: {
                            label: "Eigenbestand",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.eigenbestand
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.isEigenerVertrag,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.versicherung,
                                "isEigenerVertrag",
                                $$v
                              )
                            },
                            expression: "versicherung.isEigenerVertrag"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.fremdanlage.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("fremdanlage") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputToggleSwitch", {
                          attrs: {
                            label: "Fremdanlage",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.fremdanlage
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.fremdanlage,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "fremdanlage", $$v)
                            },
                            expression: "versicherung.fremdanlage"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.kundeEingelesen.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("kundeEingelesen")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputToggleSwitch", {
                          attrs: {
                            label: "Durch Kunde angelegt",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.kundeEingelesen
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.kundeEingelesen,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "kundeEingelesen", $$v)
                            },
                            expression: "versicherung.kundeEingelesen"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.provisionsfrei.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("provisionsfrei")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputToggleSwitch", {
                          attrs: {
                            label: "Provisionsfrei",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.provisionsfrei
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.provisionsfrei,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "provisionsfrei", $$v)
                            },
                            expression: "versicherung.provisionsfrei"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.teilung.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("teilung") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputToggleSwitch", {
                          attrs: {
                            label: "Teilung geprüft",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.teilung.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.teilung,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "teilung", $$v)
                            },
                            expression: "versicherung.teilung"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.anbindung.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("anbindung") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("ComboBox", {
                          attrs: {
                            label: "Anbindung",
                            values:
                              _vm.vertragsdatenFieldsDefinition.anbindung.value,
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.anbindung
                                .disabled
                          },
                          on: {
                            change: function($event) {
                              return _vm.dataChanged("anbindung", $event)
                            }
                          },
                          model: {
                            value: _vm.versicherung.anbindung,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "anbindung", $$v)
                            },
                            expression: "versicherung.anbindung"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.betreuungsstufe.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("betreuungsstufe")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("ComboBox", {
                          attrs: {
                            label: "Betreuungsstufe",
                            values: _vm.betreungsstufeComputedValues,
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.betreuungsstufe
                                .disabled ||
                              !_vm.versicherung.anbindung ||
                              _vm.disableBetreungsstufe
                          },
                          on: {
                            change: function($event) {
                              return _vm.dataChanged("betreuungsstufe", $event)
                            }
                          },
                          model: {
                            value: _vm.versicherung.betreuungsstufe,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "betreuungsstufe", $$v)
                            },
                            expression: "versicherung.betreuungsstufe"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.konvertierung.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("konvertierung")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputToggleSwitch", {
                          attrs: {
                            label: "Konvertierung",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.konvertierung
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.konvertierung,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "konvertierung", $$v)
                            },
                            expression: "versicherung.konvertierung"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.kennzeichen.visible
              ? _c(
                  "div",
                  { staticClass: "row", style: _vm.styleOrder("kennzeichen") },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6 form-control" },
                      [
                        _c("InputField", {
                          staticClass: "align-end",
                          attrs: {
                            label: "Kennzeichen",
                            disabled: "",
                            placeholder:
                              _vm.vertragsdatenFieldsDefinition.kennzeichen
                                .validationMessage
                          },
                          model: {
                            value: _vm.versicherung.kennzeichen,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "kennzeichen", $$v)
                            },
                            expression: "versicherung.kennzeichen"
                          }
                        }),
                        !_vm.shouldHideFahrzeugDatenStep
                          ? _c(
                              "a",
                              {
                                staticClass: "align-end",
                                staticStyle: { "flex-grow": "0" },
                                on: {
                                  click: function($event) {
                                    return _vm.goToFahrzeugdaten()
                                  }
                                }
                              },
                              [_c("PhPencilLine", { attrs: { size: 24 } })],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.beitragszahlungsdauer.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("beitragszahlungsdauer")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputField", {
                          attrs: {
                            type: "number",
                            label: "Beitragszahlungsdauer",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition
                                .beitragszahlungsdauer.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.beitragszahlungsdauer,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.versicherung,
                                "beitragszahlungsdauer",
                                $$v
                              )
                            },
                            expression: "versicherung.beitragszahlungsdauer"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.letzte_aenderung_gueltig_ab
              .visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("letzte_aenderung_gueltig_ab")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("DatePickerField", {
                          attrs: {
                            isValueAsString: "",
                            label: "letzte Vertragsänderung gültig ab",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition
                                .letzte_aenderung_gueltig_ab.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.letzteAenderungGueltigAb,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.versicherung,
                                "letzteAenderungGueltigAb",
                                $$v
                              )
                            },
                            expression: "versicherung.letzteAenderungGueltigAb"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.vertragsdatenFieldsDefinition.aenderungs_grund.visible
              ? _c(
                  "div",
                  {
                    staticClass: "row",
                    style: _vm.styleOrder("aenderungs_grund")
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-6" },
                      [
                        _c("InputTextArea", {
                          attrs: {
                            label: "Änderungsgrund",
                            disabled:
                              _vm.vertragsdatenFieldsDefinition.aenderungs_grund
                                .disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged()
                            }
                          },
                          model: {
                            value: _vm.versicherung.aenderungsGrund,
                            callback: function($$v) {
                              _vm.$set(_vm.versicherung, "aenderungsGrund", $$v)
                            },
                            expression: "versicherung.aenderungsGrund"
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }