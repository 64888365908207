<template>
  <div class="input-forms__container">
    <div class="input-forms__input-container checkbox-input__container">
      <div class="input-forms__label-container" :aria-label="title || 'Titel'">
        <div class="input-forms__label-content" v-if="title" v-html="sanitize(title)"></div>
      </div>
      <div v-for="val in normalizedValues" :key="val.path">
        <InputCheckBoxItem :id="val.path" :label="val.label" :value="value[val.path]" :disabled="disabled || val.disabled" @input="onInput($event, val)"/> 
        <template v-if="isValidationConfigured()">
          <div :key="validation.updated" v-if="!suppressValidationMessage && validation">
            <div class="input-forms__error-message" 
              v-for="error in validation.getErrors(getCheckboxErrorPath(val.path), validateUntouched)" 
              :key="error">
                {{ error }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue'
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import { sanitize } from '@/helpers/string-helper.js';

export default {
  mixins: [validatorComponentUtils],
  props: {
    title: {
      type: String,
      default: ''
    },
    values: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    validateUntouched: {
      type: Boolean,
      default: false
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    normalizedValues() {
      return this.values.map(item => ({
        ...item,
        path: 'value' in item ? item.value : item.path,
      }));
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    getValue(path) {
      return this.value?.[path];
    },
    onInput($event, val) {
      this.$set(this.value, val.path, $event)
      this.$emit('checkBoxToggle', { [val.path]: $event })
      this.$emit('input', { ...this.value, [val.path]: $event });
      this.$runValidator($event)
    },
    getCheckboxErrorPath(path) {
      return `${this.validationPath}.${path}`
    }
  },
  components: {
    InputCheckBoxItem
  }
}
</script>

<style lang="scss" scoped>
.input-forms__label-container {
  margin-bottom: 4px;
}
</style>
