<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Bemerkungen" 
      :actions="headerActions"
      @action-NEW_BEMERKUNG="createBemerkung"
    />

    <BaseFilter
      v-if="defaultOptions"
      title="Bemerkungen Filter"
      filterId="PostfachBemerkungFilter"
      :configFilter="filterConfig"
      :metadata="metadata"
      :defaultOptions="defaultOptions"
      showSaveButton
      hasSmartSearch
      immidiateSearch
      @onFilter="onFilter"
    />

    <BemerkungenList 
      :records="records" 
      :recordCount="recordCount" 
      :loading="loading" 
      :typ="VALUE_TYP"
      :additionalHeaders="additionalHeaders"
      useInvertSelection
      @onScroll="onScroll"
      @delete="deleteRow"
      @deleteSelected="deleteSelected"
    />
  </div>
</template>

<script>
import postfachTableMixin from '@/views/communication/postfach/tables/postfach-table-mixin.js';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import axios from 'axios';
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import BemerkungenList from "@/components/persoenliche-daten/BemerkungenList.vue";
import { makeQueryParam } from '@/helpers/utils-helper';
import { TextColumn } from "@/components/table2/table_util.js";

const VALUE_TYP = 'postfach';

export default {
  mixins: [postfachTableMixin],
  components: {
    BemerkungenList,
  },
  data() {
    return {
      VALUE_TYP,
      recordCount: null,
      onScrollEnd: () => {},
      filterConfig: {
        placeholderForDefSearchInput: 'Thema',
        defaultSearchInputKeys: ['subject'],
        hideFirstColumn: true,
      },
    }
  },
  computed: {
    metadata() {
      return [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems: [
            {
              type: 'text',
              label: 'Thema',
              key: 'subject',
            },
            {
              type: 'text',
              label: 'Vermittlernummer',
              key: 'vermittlernr',
            },
            {
              type: 'text',
              label: 'Kundennummer',
              key: 'kundennr',
            },
            {
              type: 'text',
              label: 'Kundenname',
              key: 'name',
            },
            {
              type: 'dateRange',
              label: 'Zeitraum',
              key: 'zeitraum',
            },
            {
              type: 'datepicker',
              label: 'Datum',
              key: 'datum',
            },
          ],
        },
      ]
    },
    headerActions() {
      return [
          PageHeaderSimpleAction('NEW_BEMERKUNG', 'Neue Bemerkung'),
      ];
    },
    additionalHeaders() {
      if (this.isBroker) {
        return  [
          TextColumn("vermittlernr", "Vermittlernummer").makeLink(),
          TextColumn("kundennr", "Kundennummer").makeLink(),
          TextColumn("kundenname", "Kundenname"),
        ]
      }

      return []
    },
  },
  methods: {
    createBemerkung() {
      this.$router.push({path: `/communication/postfach/bemerkungen/bemerkung`, query: {typ: VALUE_TYP}});
    },
    onFilter(filterConfig) {
        let params = {};
  
        filterConfig.forEach(fc => {
            let value = fc.value;
            if (fc.key == 'zeitraum') {
                params.fromDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'min').value);
                params.toDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'max').value);
            } else if (fc.key == 'datum') {
                params.fromDate = DatePickerUtils.toDateStr(value);
                params.toDate = DatePickerUtils.toDateStr(value);
            } else {
                params[fc.key] = value;
            }
        });
  
        this.filterParams = params
        this.records = []
        this.doSearch()
    },
    onScroll(onScrollEnd) {
      this.onScrollEnd = onScrollEnd
      this.doSearch()
    },
    doSearch(offset = this.records.length, limit = 20) {
        this.loading = true;

        const payload = {
          ...this.filterParams,
          offset,
          limit
        }
        
        axios.post(`${process.env.VUE_APP_API}/postfachBemerkung/list`, payload, { defaultSpinner: true, })
        .then(response => {
            this.records.push(...response.data.records)
            this.recordCount = response.data.recordCount
        })
        .finally(() => {
          this.onScrollEnd()
          this.loading = false
          this.selectedRows = []
        })
    },
    deleteRow({id}) {
      const params = makeQueryParam({id, typ: VALUE_TYP})

      axios.delete(`${process.env.VUE_APP_API}/postfachBemerkung/bemerkung?${params}`, {defaultSpinner: true})
      .then(() => {
        const index = this.records.findIndex(record => record.id == id);
        if (index >= 0)
          this.records.splice(index, 1);
      })
    },
    deleteSelected({ids, invertSelection, next}) {
      
      const records = this.records || []
      const params = makeQueryParam({invertSelection, ...this.filterParams, ids, typ: VALUE_TYP})

      axios.delete(`${process.env.VUE_APP_API}/postfachBemerkung/bemerkungen?${params}`, {defaultSpinner: true})
      .then(response => {
        if (response && response.data && response.data.length) {
          response.data.forEach(id => {
            const index = records.findIndex(record => record.id == id);
            if (index >= 0)
              records.splice(index, 1);
          })
          next()
        }

        this.selectedRows = []
        this.invertSelection = false
      })
    },
  },
}
</script>