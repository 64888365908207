var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading && _vm.hasData
    ? _c("PaginatedTable", {
        ref: "InsuranceGroupTable",
        attrs: {
          tableId: _vm.tableId,
          title: _vm.title,
          headers: _vm.headers,
          pages: _vm.computedPages,
          pageCount: _vm.pageCount,
          rowCount: _vm.count,
          dragnDropFilesOnRow: _vm.dragnDropFilesOnRow,
          menuRowsPerPage: _vm.menuRowsPerPage,
          pageSize: _vm.pageSize,
          exportConfig: _vm.exportConfig,
          selected: _vm.isByPass || _vm.isBroker ? _vm.selected : null,
          selectionAsBlacklist: _vm.selectionAsBlacklist,
          page: _vm.page,
          headerActions: _vm.headerActions,
          sorted: _vm.sortConfig,
          thHoverDisabled: true,
          mobileConfig: _vm.mobileConfig,
          enableToggleHorizontalScrollingConfig: ""
        },
        on: {
          rowsPerPage: function($event) {
            return _vm.$emit("rowsPerPage", $event)
          },
          selected: function($event) {
            return _vm.$emit("selected", $event)
          },
          selectionAsBlacklist: function($event) {
            return _vm.$emit("selectionAsBlacklist", $event)
          },
          page: function($event) {
            _vm.page = $event
          },
          requestPage: function($event) {
            return _vm.$emit("requestPage", $event)
          },
          sort: function($event) {
            return _vm.sortDataByColumn($event)
          },
          files: function($event) {
            return _vm.$emit("row-files", $event)
          },
          "click-bezeichnung": _vm.editItemDetails,
          "click-maklernr": _vm.openBroker,
          action: function($event) {
            return _vm.$emit("action", $event)
          },
          headerAction: _vm.handleHeaderAction,
          onScroll: function($event) {
            return _vm.$emit("onScroll", $event)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "zurdnenAktion",
              fn: function(row) {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "insurance__table--company",
                      attrs: {
                        title: "Interne Nr. für die Zuordnung übernehmen"
                      }
                    },
                    [
                      _c("PhArrowFatRight", {
                        staticClass: "clickable",
                        attrs: { size: 24 },
                        on: {
                          click: function($event) {
                            return _vm.$emit("zuordnenAktion", {
                              key: "ZUORDNEN_AKTION",
                              row: row
                            })
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "statusMapped",
              fn: function(row) {
                return [
                  _c("DropDownCell", {
                    attrs: {
                      options: [
                        { label: "aktiv", value: "AKTIV", state: "SUCCESS" },
                        {
                          label: "beantragt",
                          value: "BEANTRAGT",
                          state: "INFO"
                        },
                        {
                          label: "gekündigt Mandant",
                          value: "GEKUENDIGT_MANDANT",
                          state: "DEFAULT"
                        },
                        {
                          label: "gekündigt Partner",
                          value: "GEKUENDIGT_PARTNER",
                          state: "DEFAULT"
                        },
                        {
                          label: "storniert",
                          value: "STORNIERT",
                          state: "DEFAULT"
                        }
                      ]
                    },
                    on: {
                      selectOption: function($event) {
                        return _vm.selectOption($event, row)
                      }
                    },
                    model: {
                      value: row.statusMapped,
                      callback: function($$v) {
                        _vm.$set(row, "statusMapped", $$v)
                      },
                      expression: "row.statusMapped"
                    }
                  })
                ]
              }
            },
            {
              key: "gesellschaft",
              fn: function(row) {
                return [
                  row.gesellschaft
                    ? _c(
                        "div",
                        { staticClass: "insurance__table--company" },
                        [
                          row.gesellschaft.logoUrl
                            ? _c("BaseImage", {
                                staticClass: "insurance__table--company__logo",
                                attrs: {
                                  src:
                                    _vm.APP_API +
                                    "/" +
                                    row.gesellschaft.logoUrl,
                                  placeholder: _vm.imagePlaceholder
                                }
                              })
                            : _vm._e(),
                          _c("span", [
                            _vm._v(_vm._s(_vm.getGesellschaft(row)) + " ")
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ],
          null,
          false,
          725596073
        )
      })
    : _vm.isLoading
    ? _c("GhostLoading", { attrs: { type: "table", title: _vm.title } })
    : _c("NoData", { attrs: { title: _vm.title } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }