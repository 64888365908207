<template>
  <div class="input-forms__container">
    <label class="input-forms__label-container clickable" :class="labelContainerClasses">
      <div :class="{'font-bold': config && config.bold}" class="input-forms__label" v-if="label" v-html="sanitize(label)">
      </div>
      <div class="input-forms__input-container" :class="{ 'input-forms__input-container--top': switchHandlerTopAlign }">
        <label class="toggle-switch__container" aria-label="Toggle Icon">
          <span class="toggle-switch__body" :class="{'colored': colored}">
            <input type="checkbox"
              :checked="checked"
              :disabled="disabled"
              @input="onChange($event)">
            <span class="toggle-switch__slider round" 
              :class="{ 'toggle-switch__slider--error': isComponentInvalid }"></span>
          </span>
        </label>
        
      </div>
    </label>
    <template v-if="isValidationConfigured()">
      <div :key="validation.updated" v-if="!suppressValidationMessage && validation">
        <div class="input-forms__error-message" 
          v-for="error in validation.getErrors(validationPath, validateUntouched)" 
          :key="error">
            {{ error }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import { getBooleanValue } from '@/helpers/mapping'
import {sanitize} from '@/helpers/string-helper.js';

const IN_LINE_ALIGNMENT_LEFT = 'left';
const IN_LINE_ALIGNMENT_RIGHT = 'right';

export default {
  mixins: [validatorComponentUtils],

  props: {
    inLineLabel: {
      type: Boolean,
      default: true, // MSC-21075
    },
    inLineAlignment: {
      type: String,
      default: IN_LINE_ALIGNMENT_RIGHT,
    },
    colored: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [Boolean, String],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validateUntouched : {
      type: Boolean,
      default: false
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false
    },
    config: {
    },
    switchHandlerTopAlign: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: function(newValue) {
      this.internalValue = newValue;
      this.$runValidator(this.internalValue)
    },
    disabled() {
      if (this.disabled) {
        this.$reset()
      } else { 
        this.$runValidator(this.internalValue)
      }
    }
  },
  computed: {
    checked() {
      const value = this.internalValue;
      const isPositiveRegex = /(1|j[a]?)/gi;
      return [1, true, "true"].some(available => available === value) || isPositiveRegex.test(value);
    },
    isComponentInvalid() {
      return this.isValidationConfigured() && this.validation && this.validation.isInvalid(this.validationPath, this.validateUntouched)
    },
    labelContainerClasses() {
      return {
        'toggle-switch__inline-label': this.inLineLabel, 
        'toggle-switch__disabled': this.disabled, 
        'inline-label-right': this.inLineLabel && this.inLineAlignment === IN_LINE_ALIGNMENT_RIGHT,
        'inline-label-left': this.inLineLabel && this.inLineAlignment === IN_LINE_ALIGNMENT_LEFT,
      };
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    onChange(event) {
      const checked = event.target.checked

      let internalValue = this.internalValue
      if (this.config?.forceValueAsString) {
        internalValue = internalValue ? 'Ja' : 'Nein'
      }

      const newValue = getBooleanValue(internalValue, checked);
      this.internalValue = newValue;
      
      this.$emit('input', this.internalValue);
      this.$emit('change', this.internalValue);
      this.$runValidator(this.internalValue);
    },
  },
  data: () => {
    return {
      internalValue: null
    }
  },
  mounted() {
    this.internalValue = this.value
    this.$runValidator(this.internalValue, true)
  }
}
</script>

<style scoped>
.toggle-switch__container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.toggle-switch__body {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}

.toggle-switch__body input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch__label {
  margin-bottom: 0.5rem;
}

.toggle-switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-box);
  -webkit-transition: .3s;
  transition: .3s;
}

.toggle-switch__slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 3px;
  background-color: var(--color-box);
  -webkit-transition: .3s;
  transition: .3s;
}

input:checked + .toggle-switch__slider {
  background-color: var(--color-text);
}

input:checked + .toggle-switch__slider--error {
  background-color: var(--color-danger);
}

/* Alt styling */
input:checked + .toggle-switch__slider {
  border: none;
}

input + .toggle-switch__slider {
  border: 1px solid var(--color-text);
}

.toggle-switch__slider.round:before {
  border: 1px solid var(--color-text);
  top: 1px;
}

input:checked + .toggle-switch__slider.round:before {
  border: none;
  top: auto;
  background-color: var(--color-background);
}
/* Alt styling end */

input:focus + .toggle-switch__slider,
input:hover:enabled + .toggle-switch__slider {
  /* box-shadow: 0px 0px 3px var(--color-text); */
  outline: none;
}

input:focus + .toggle-switch__slider--error,
input:hover:enabled + .toggle-switch__slider--error {
  box-shadow: 0px 0px 3px var(--color-danger);
  outline: none;
}

input:checked + .toggle-switch__slider:before {
  transform: translateX(18px);
}

.toggle-switch__slider.round {
  border-radius: 18px;
}

.toggle-switch__slider.round:before {
  border-radius: 50%;
}

input:disabled + .toggle-switch__slider {
  cursor: not-allowed;
}

.toggle-switch__inline-label {
  flex-direction: row-reverse;
  padding-right: 5px;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;
}

.toggle-switch__inline-label.inline-label-right {
  flex-direction: row-reverse;
}

.toggle-switch__inline-label.inline-label-left {
  flex-direction: row;
  justify-content: space-between;
  padding-right: 0;
}

.toggle-switch__inline-label > * {
  margin-right: 5px;
  justify-content: center;
}

.toggle-switch__inline-label.inline-label-left > .input-forms__input-container {
  margin-right: 0;
}

.toggle-switch__inline-label.inline-label-right > .input-forms__label {
  margin-right: 0;
}

.toggle-switch__inline-label > .input-forms__label-content {
  padding-bottom: 0px;
  display: flex;
  align-items: center;
}

.input-forms__input-container--top {
  justify-content: flex-start;
  padding-top: 2px;
}

.toggle-switch__disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.input-forms__container {
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: bold;
}

::v-deep .input-forms__label-container p:last-child {
  margin-bottom: 0;
}
</style>