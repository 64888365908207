import MY_GOALS_TYPES from '@/store/myGoals/types';
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from 'vuex';
import FinancialCalculator from '../retirementScenario/financialCalculator';
import dayjs from 'dayjs';
import { base64ToArrayBuffer } from "@/helpers/download-helper";
import {formatNumber, parse} from '@/helpers/number-formatter.js';
import {DatePickerUtils} from '@/components/core/forms/DatePicker/date-picker-utils.js';
import { getColorsMixedContrastDecreasing } from "@/helpers/colors-helper.js";

const UPDATE_CALCULATIONS = ['zielsumme', 'startkapital', 'endDate', 'startDate', 'rendite'];

const mixin = {
    data () {
        return {
            goalsTypes: [
                {
                    name: 'Immobilie',
                    key: 'immobilie',
                    icon: 'home',
                    hasTitle: false,
                    img: this.getImageUrl('goals/AdobeStock_Immobilie_kl.jpg'),
                },
                {
                    name: 'Rücklage',
                    key: 'ruecklage',
                    icon: 'coins',
                    hasTitle: false,
                    img: this.getImageUrl('goals/AdobeStock_Rucklagen_kl.jpg'),
                },
                {
                    name: 'Urlaub',
                    key: 'urlaub',
                    icon: 'plane',
                    hasTitle: true,
                    img: this.getImageUrl('goals/AdobeStock_Urlaub_kl.jpg'),
                },
                {
                    name: 'größere Anschaffung',
                    key: 'grossereanschaffung',
                    icon: 'car',
                    hasTitle: true,
                    img: this.getImageUrl('goals/AdobeStock_Anschaffung_kl.jpg'),
                },
                {
                    name: 'Sonstiges',
                    key: 'sonstiges',
                    icon: 'coins',
                    hasTitle: false,
                    img: this.getImageUrl('goals/AdobeStock_Rucklagen_kl.jpg'),
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
          goals: MY_GOALS_TYPES.GETTERS.GOALS,
          isCustomerLogin: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
        }),   
        id() {
            return this.$route.params?.id || this.idProp || '';
        },
        path() {
            return this.$route.matched[1]?.path || '/service/meineziele';
        },
        goal() {
            return this.goals?.find(goal => this.id && this.id === goal.id) || {};
        },
        // if there are virtuelle depots then the middle value of their rendite otherweise customer defined rendite
        rendite() {
            return (parse(this.goal.rendite) || 0) / 100;
        },
        anzahlRaten() {
            const anzahl = !this.goal.endDate ? 0 : this.monthsDiff(this.goal.startDate, this.goal.endDate);
            return anzahl < 0 ? 1 : anzahl; 
        },
        isImmobilie() {
            return this.goal.type === 'immobilie';
        },
        isRuecklage() {
            return this.goal.type === 'ruecklage';
        },
        calculateAngespartesKapital() {
            if (this.isImmobilie && this.goal.sparrate) {
                // return FinancialCalculator.calculateFutureValue(this.goal.startkapital || 0, this.anzahlRaten, this.goal.sparrate, this.rendite, 12);
                return FinancialCalculator.calculateEndkapital(this.goal.startkapital || 0, this.anzahlRaten || 1, this.goal.sparrate, this.rendite, 12)
            } else {
                return 0;
            }
        },
        calculateFinanzierungsbedarf() {
            if (this.isImmobilie) {
                return this.goal.zielsumme && this.goal.zielsumme > this.calculateAngespartesKapital ? (this.goal.zielsumme - this.calculateAngespartesKapital) : 0;
            } else {
                return 0;
            }
        },
        isSparrateEditable() {
            return !this.isImmobilie && !this.hasDepots; 
        },
        toCalculateEndDate() {
            return this.hasDepots;
        },
        hasDepots() {
            return this.goal?.depots?.length > 0;
        },
        myGoalImage() {
            return this.goalImage(this.goal);
        },
        defaultMyGoalImage() {
            return this.myGoalImage || this.goalsTypes.find(type => type.key === this.goal.type)?.img || '';
        },
        eChartColors() {
            return [getColorsMixedContrastDecreasing()[0], getColorsMixedContrastDecreasing()[1]];
        },
        zielsummeInvalidText() {
            return `Das benötigtes Kapital ist kleiner als vorhandenes Kapital plus Rendite. Der Betrag sollte größer als ${this.formatValue(this.calculateZielsummeMinimal(), 'currency')} sein.`;
        },
    },
    methods: {
        async saveGoal() {
            if (this.goal?.title) {
              await this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.SAVE_GOAL, this.id).then(response => {
                if (this.id === 'new' && response?.id) {
                  this.$router.push(`${this.path}/${response.id}/${this.selectedStepKey || 'form'}`)
                }
              });
            } 
        },
        calculateSparrate() {
            let sparrate = 0;
            const zielsumme = +(this.goal.zielsumme || 0) - (this.goal.startkapital || 0);
            if (zielsumme > 0 && this.anzahlRaten > 0) {
                sparrate = FinancialCalculator.calculateSparrate(this.goal.zielsumme, this.goal.startkapital, this.anzahlRaten, this.rendite,  12)
            } else {
                sparrate = zielsumme - (this.goal.startkapital || 0);
            }
            return sparrate >= 0 && parseFloat(sparrate?.toFixed(2)) || 0;
        },
        calculateZielsumme() {
            const result = parse(this.formatValue(FinancialCalculator.calculateEndkapital(this.goal.startkapital || 0, this.anzahlRaten, this.goal.sparrate, this.rendite, 12),
                'number') || 0);
            return result;
        },
        calculateZielsummeMinimal() {
            const result = FinancialCalculator.calculateEndkapital(this.goal.startkapital || 0, this.anzahlRaten, 0, this.rendite, 12)
            return result;
        },
        zielsummeValid(zielsumme = 0) {
            return zielsumme >= this.calculateZielsummeMinimal();
        },
        endDateValid(endDate) {
            endDate = !endDate ? null : (typeof endDate === 'string' ? dayjs.utc(endDate, 'MM.YYYY').toDate() : dayjs(endDate).toDate());
            const startDate = !this.goal.startDate ? null : (typeof this.goal.startDate === 'string' ? dayjs.utc(this.goal.startDate, 'MM.YYYY').toDate() : dayjs(this.goal.startDate).toDate());
            const isValid = !endDate || !startDate ||  endDate >= startDate;
            return isValid;    
        },
        goalImage(goal) {
            if (goal?.image?.data) {
                const byteArray = base64ToArrayBuffer(goal?.image?.data);
                const file = new Blob([byteArray]);
                const linkUrl = URL.createObjectURL(new File([file], ''));
                return linkUrl;
              }
              return '';
        },
        doChanges(componentId, value) {
            this.$store.commit(MY_GOALS_TYPES.MUTATIONS.UPDATE_STORE, { value: { [componentId]: value}, id: this.id });
            if (UPDATE_CALCULATIONS.includes(componentId)) {
                if (this.toCalculateEndDate) {
                    this.updateEndDate();
                } else if (this.isSparrateEditable && this.goal.zielsumme) {
                    const sparrate = this.calculateSparrate();
                    this.$store.commit(MY_GOALS_TYPES.MUTATIONS.UPDATE_STORE, { value: { sparrate: sparrate < 0 ? 0 : sparrate}, id: this.id });
                    setTimeout(() => {
                        if (this.goal.id !== 'new' && sparrate <= 0) {
                            this.updateZielsumme();
                            this.$confirmModal({
                                message: "Sie erhalten das benötigtes Kapital ohne Sparraten mit Kapitalüberschuss. Der Betrag von \"benötigtes Kapital\" wurde angepasst.",
                                title: "Kapitalüberschuss",
                                showCancelButton: false,
                                labelButtonConfirm: 'Ok'
                            });
                        }
                    }, 300);
                } else if (componentId === 'zielsumme' && !value) {
                    this.$store.commit(MY_GOALS_TYPES.MUTATIONS.UPDATE_STORE, { value: { sparrate: 0 }, id: this.id });
                }
            }
        },
        updateZielsumme() {
            this.$store.commit(MY_GOALS_TYPES.MUTATIONS.UPDATE_STORE, { value: { zielsumme: this.calculateZielsumme()}, id: this.id });
        }, 
        monthsDiff(fromVal, toVal) {
            const from = !fromVal ? new Date() : (fromVal instanceof Date ? fromVal : dayjs(fromVal, 'MM.YYYY').toDate());
            const to = !toVal ? new Date() : (toVal instanceof Date ? toVal : dayjs(toVal, 'MM.YYYY').toDate());
            const months = to.getMonth() - from.getMonth() + 12 * (to.getFullYear() - from.getFullYear());
            return months + 1;
        },
        getNumberOfPaymentsPerPaymentPeriod(){
            return Math.abs(FinancialCalculator.getNumberOfPaymentsPerPaymentPeriod(this.anzahlRaten, 12));
        },
        getImageUrl(filename) {
            return process.env.VUE_APP_API + '/../../images/etc/ksc3/' + filename;
        },
        formatValue(value, type) {
            return type === 'date' ? this.getDateStr(value) : this.getNumber(value, type);
        },
        getNumber(value, type) {
            return !type ? value : `${value && formatNumber(value, 2) || ''} ${value && (type == 'currency' ? '€' : (type === 'percent' ? '%' : '')) || ''}`;
        },
        getDateStr(theDate) {
            if (!theDate) {
              return '';
            } else if (theDate instanceof String) {
              return theDate;
            } else {
              return DatePickerUtils.toDateStr(theDate);
            }
        },
        eChartData(goal) {
            let payments = 0;
            if (dayjs(goal?.endDate, 'MM.YYYY')?.isBefore(new Date())) {
                payments = goal.zielsumme || 0;
            } else if ((goal.startkapital || 0) < goal.zielsumme && goal.endDate) {
                payments = FinancialCalculator.calculateEndkapital(
                    goal?.startkapital || 0, 
                    -1 * (dayjs(goal?.startDate, 'MM.YYYY').diff(new Date(), 'month')), 
                    goal.sparrate, 
                    (goal?.rendite || 0) / 100,
                    12) || 0;
            }
            const percent = (payments / (goal.zielsumme || 1)) * 100;
            
            let resultData = [];
            resultData.push({
              label: 'angespartes Kapital',
              value: percent,
            })
            resultData.push({
                label: 'Finanzierungsbedarf',
                value: 100 - percent,
            })
            return resultData;
        },
        updateEndDate() {
            // 2 situtions are possible: with monthly payment and without monthly payment
            const months = this.goal.sparrate 
                ? FinancialCalculator.calculateNumberOfPayments(this.goal.startkapital || 0, this.goal.zielsumme, this.goal.sparrate, this.rendite, 12)
                : FinancialCalculator.monthsToAchieveFutureValue(this.goal.startkapital || 0, this.goal.zielsumme || 0, this.rendite || 0);
            
            let endDate = null;
            if (months > 12*100 ) {
                this.$confirmModal({
                    message: "Mit den eingegebenen Daten erreichen Sie Ihres Ziel in überschaubarer Zeit nicht.",
                    title: "Ziel nicht erreichbar",
                    showCancelButton: false,
                    labelButtonConfirm: 'Ok'
                });
            } else {
                endDate = this.getEndDate(months);
            }
            this.$store.commit(MY_GOALS_TYPES.MUTATIONS.UPDATE_STORE, { value: { endDate: endDate}, id: this.id });
        },
        getEndDate(anzahlRaten = 0) {
            const endDate = !this.goal.startDate ? new Date() : (typeof this.goal.startDate === 'string' ? dayjs.utc(this.goal.startDate, 'MM.YYYY').toDate() 
                : dayjs(this.goal.startDate).toDate());
                
            const num = endDate.getMonth() + (anzahlRaten > 0 ? Math.ceil(anzahlRaten - 1) : 0);
            if (num) {
              endDate.setMonth(num);
              return dayjs(endDate)?.format("MM.YYYY");
            }
        },
        navigateTo(event) {
            this.$router.push({ path: `${event}` });
        },
    },
}

export function getMyGoalCards(myGoals) {
    return myGoals?.length ? myGoals.map(goal => ({
        name: `goal${goal.id}`,
        title: `Mein Ziel: ${goal.title} ${goal.endDate && ('zum ' + goal.endDate) || ''}`,
        component: () => import('@/components/steckbrief/cards/MyGoalsCard.vue'),
        props: {
          idProp: goal.id,
        },
        isNavigable: false
        })) : [];
}

export default mixin;