import Vue from 'vue';
import DEPOTPOSITIONS_TYPES from './types';
import axios from 'axios';
import { ALL_DEPOTS } from '../../components/depotpositions/types';
import { mapNewTableStructure, virtualDepot } from './utils';
import LOG_TYPES from '@/store/log/types';

const transactionsPriorities = {
  'Umsatzart': 'VISIBLE_ALWAYS',
  'Datum': 'VISIBLE_MEDIUM',
  'ISIN': 'COLLAPSED',
  'Wertpapiername': 'COLLAPSED',
  'Kontonr': 'VISIBLE_MEDIUM',
  'Anteile': 'VISIBLE_MEDIUM',
  'Preis': 'COLLAPSED',
  'aktueller_Kurs': 'VISIBLE_MEDIUM',
  'Währung': 'VISIBLE_MEDIUM',
  'Nettoanlage': 'COLLAPSED',
  'Bruttoanlage': 'COLLAPSED',
  'Wechselkurs': 'COLLAPSED',
  'Bruttoanlage_EUR': 'VISIBLE_ALWAYS',
  'AA_IN_PROC': 'VISIBLE_LARGE',
  'Rabatt': 'VISIBLE_LARGE',
  'Gesellschaft': 'COLLAPSED',
  'Lagerstelle': 'COLLAPSED',
  'Kundenname': 'COLLAPSED',
  'Eröffnungsdatum': 'COLLAPSED',
  'Erstellung_Depotposition': 'COLLAPSED',
};

export default {
  async [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    const depotid = payload.depotid || ALL_DEPOTS;
    let url = `/depotpositions/list?depotId=${depotid}&zeigeWith0=true`;
    if (payload && payload.ignoreVorsorgeParam) {
      url = `${url}&ignoreVorsorgeParam=true`;
    } else {
      url = `${url}&ignoreVorsorgeParam=false`;
    }
    await axios.get(rootState.core.apiAddress + url, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_SUCCESS, {
          [depotid]: response.data
        });
      }
    })
  },
  async [DEPOTPOSITIONS_TYPES.ACTIONS.RESET_RETRIEVED_DEPOTPOSITIONS_IF_NEEDED]({ rootState, state, commit, }) {
    const config = { defaultSpinner: true };
    const response = await axios.get(`${rootState.core.apiAddress}/depotpositions/configHash`, config);
    const currentDepotpositionsConfigHash = response?.data || {};
    const { depotpositionsConfigHash = {} } = state;

    const shouldResetRetrievedDepotpositions = currentDepotpositionsConfigHash !== depotpositionsConfigHash;

    Vue.set(state, 'depotpositionsConfigHash', currentDepotpositionsConfigHash);
    if (shouldResetRetrievedDepotpositions) {
      commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RESET_RETRIEVED_DEPOTPOSITIONS);
    }
  },
  async [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_CHARTS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    const depotid = payload.depotid || ALL_DEPOTS;
    let url = '/assetschart/list';
    url = `${url}?depotId=${depotid}`;
    if (payload && payload.charts) {
      payload.charts.map(chartType => url = `${url}&chartType=${chartType}`)
    }
    await axios.get(rootState.core.apiAddress + url, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_CHARTS_SUCCESS, {
          depotid,
          ...response.data
        });
      }
    })
  },
  
  async [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_ESG_CHART]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    const depotid = payload.depotid || '';
    let url = '/assetschart/TaxonomiePoi';
    url = depotid && depotid !== ALL_DEPOTS ? `${url}?depotId=${depotid}` : url;
    await axios.get(rootState.core.apiAddress + url, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_ESG_CHART_SUCCESS, {
          depotid,
          ...response.data
        });
      }
    })
  },

  async [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_CONFIG]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrsdepotconfig';
    const response = await axios.get(rootState.core.apiAddress + '/..' + url, config)

    if (response && response.data)
      commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_CONFIG_SUCCESS, response.data);
        
    return response.data
  },
  
  [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_TRANSACTIONS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrstransactionslist';
    const {isin} = payload;
    axios.post(rootState.core.apiAddress + '/..' + url, {isin}, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_TRANSACTIONS_SUCCESS, mapNewTableStructure(response.data, transactionsPriorities));
      }
    })
  },
  
  [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_NAME]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrsfundsdetailname';
    const {isin} = payload;
    axios.post(rootState.core.apiAddress + '/..' + url, {isin}, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS, {'FUNDS_NAME': response.data});
      }
    })
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_REPORT]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrsfundsdetailreport';
    const {isin} = payload;
    axios.post(rootState.core.apiAddress + '/..' + url, {isin}, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS, {'FUNDS_DETAILS_REPORT': response.data});
      }
    })
  },
  
  [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_KEYS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrsfundsdetailperformancekeys';
    const {isin} = payload;
    axios.post(rootState.core.apiAddress + '/..' + url, {isin}, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS, {'FUNDS_PERFORMANCE_KEYS': response.data});
      }
    })
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_KEYS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrsfundsdetailriskkeys';
    const {isin} = payload;
    axios.post(rootState.core.apiAddress + '/..' + url, {isin}, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS, {'FUNDS_RISK_KEYS': response.data});
      }
    })
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_GENERAL]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrsfundsdetailgeneral';
    const {isin} = payload;
    axios.post(rootState.core.apiAddress + '/..' + url, {isin}, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS, {'FUNDS_DETAILS_GENERAL': response.data});
      }
    })
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_CHART]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrsfundshistoricalchart';
    const {isin} = payload;
    axios.post(rootState.core.apiAddress + '/..' + url, {isin}, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS, {'FUNDS_PERFORMANCE_CHART': response.data});
      }
    })
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_CHART]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let data = state.fondsinfo['FUNDS_RISK_CHART'];
    if (!data || data.isin !== payload.isin) {
      data = Object.assign({}, payload);
    }
    data['data' + payload.year] = null;
    commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS, {FUNDS_RISK_CHART: data});
    let url = '/mrsfundsriskchart';
    const {isin} = payload;
    axios.post(rootState.core.apiAddress + '/..' + url, {...payload}, config).then(response => {
      const schwerpunkt = response.data.headerLabels?.length ? response.data.headerLabels[0] : 'Schwerpunkt';
      if (response.data?.records?.length) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS,
          { 'FUNDS_RISK_CHART': { ...data, ['data'+payload.year]: response.data }, schwerpunkt });
      } if (response.data?.records?.length === 0) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS,
          { 'FUNDS_RISK_CHART': { ...data, ['data'+payload.year]: {errorMesage: 'Dieser Fond hat keine Rendite / Risiko Daten für die letzten '+payload.year+' Jahre'} } });
      }
    }).catch(error => {
      commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS,
        { 'FUNDS_RISK_CHART': { ...data, ['data'+payload.year]: [] } });
    })
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_DESCRIPTION]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrsfundsdetaildescription';
    const {isin} = payload;
    axios.post(rootState.core.apiAddress + '/..' + url, {isin}, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS, {'FUNDS_DETAILS_DESCRIPTION': response.data});
      }
    })
  },

  [DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      params: {
        bgsNr: payload.begleitscheinNr
      }
    };
    let url = '/mrslimits';
    axios.get(rootState.core.apiAddress + '/..' + url, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.GET_LIMITS_SUCCESS, response.data);
      }
    })
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.SET_LIMITS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
    };
    let url = '/mrslimits';
    try {
      axios.post(rootState.core.apiAddress + '/..' + url, payload, config).then(response => {
        if (response && response.data) {
          // if (response.data.errorMesage) {
          //   dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorMesage, 'danger'));
          // } else {
            commit(DEPOTPOSITIONS_TYPES.MUTATIONS.SET_LIMITS_SUCCESS, response.data);
          // }
        } else {
          commit(DEPOTPOSITIONS_TYPES.MUTATIONS.SET_LIMITS_SUCCESS, {});
        }
      }).catch(error => {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.SET_LIMITS_SUCCESS, {...error.response.data, bgsNr: payload.bgsNr});
      })
    } catch (e) {
      console.log(e)
    }
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.DELETE_LIMITS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      params: {
        bgsNr: payload.begleitscheinNr
      }
    };
    let url = '/mrslimits';
    axios.delete(rootState.core.apiAddress + '/..' + url, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.DELETE_LIMITS_SUCCESS, response.data);
      }
    })
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.RESET_LIMITS]({ commit, rootState }, payload) {
    const config = {
      defaultSpinner: true,
    };
    let url = '/mrslimits';
    axios.put(rootState.core.apiAddress + '/..' + url, payload, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.SET_LIMITS_SUCCESS, response.data);
      }
    })
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.GET_LIMITS_200]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      params: {
        bgsNr: payload.begleitscheinNr
      }
    };
    let url = '/mrslimits200';
    axios.get(rootState.core.apiAddress + '/..' + url, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.GET_LIMITS_200_SUCCESS, response.data);
      }
    })
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.SET_LIMITS_200]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
    };
    let url = '/mrslimits200';
    axios.post(rootState.core.apiAddress + '/..' + url, payload, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.SET_LIMITS_200_SUCCESS, response.data);
      }
    }).catch(error => {
      commit(DEPOTPOSITIONS_TYPES.MUTATIONS.SET_LIMITS_200_SUCCESS, {...error.response.data, bgsNr: payload.bgsNr});
    })
  },
  [DEPOTPOSITIONS_TYPES.ACTIONS.DELETE_LIMITS_200]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      params: {
        bgsNr: payload.begleitscheinNr
      }
    };
    let url = '/mrslimits200';
    axios.delete(rootState.core.apiAddress + '/..' + url, config).then(response => {
      if (response && response.data) {
        commit(DEPOTPOSITIONS_TYPES.MUTATIONS.DELETE_LIMITS_200_SUCCESS, response.data);
      }
    })
  },
  async [DEPOTPOSITIONS_TYPES.ACTIONS.LOAD_DEPOTPOSITIONS_GUV]({ rootState, commit }) {
    const config = {
      defaultSpinner: true,
    };
    const response = await axios.get(`${rootState.core.apiAddress}/depotpositions/guv_depot`, config);
    commit(DEPOTPOSITIONS_TYPES.MUTATIONS.SET_DEPOTPOSITIONS_GUV, virtualDepot.depots(response.data));
    
    return response.data;
  },


  
  async [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_OUT_OF_LIMITS]({ rootState, commit, }, { lastDays }) {
    if(!lastDays && lastDays !== 0) return;

    const config = {
      defaultSpinner: true,
    };
    const response = await axios.get(`${rootState.core.apiAddress}/depotpositions/outOfLimits?lastDays=${lastDays}`, config);
    commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_OUT_OF_LIMITS_SUCCESS, response?.data || []);
  },
  async [DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_BROKER_CHANGES]({ rootState, commit, }, { lastDays }) {
    if(!lastDays && lastDays !== 0) return ;

    const config = {
      defaultSpinner: true,
    };
    const response = await axios.get(`${rootState.core.apiAddress}/depotpositions/brokerChanges?lastDays=${lastDays}`, config);
    commit(DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_BROKER_CHANGES_SUCCESS, response?.data || []);
  },
  async [DEPOTPOSITIONS_TYPES.ACTIONS.GET_DEPOT_PDF_OPTIONS]({ rootState, commit, }, { type }) {
    const config = {
      defaultSpinner: true,
    };
    const response = await axios.get(`${rootState.core.apiAddress}/../mrsdepotpdf`, {
      ...config,
      params: {
        type,
      },
    });
    commit(DEPOTPOSITIONS_TYPES.MUTATIONS.GET_DEPOT_PDF_OPTIONS_SUCCESS, response?.data || {});
  },

  [DEPOTPOSITIONS_TYPES.ACTIONS.GET_DEPOT_DETAILS]({ rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    return axios.get(rootState.core.apiAddress + '/depotpositions/depot-details?bgsNr=' + payload, config)
  },
  
  async [DEPOTPOSITIONS_TYPES.ACTIONS.LOAD_SHOW_COURTAGE]({ rootState, commit }) {
    const config = {
      defaultSpinner: true
    };
    const response = await axios.get(rootState.core.apiAddress + '/depotpositions/show-courtage', config)
    commit(DEPOTPOSITIONS_TYPES.MUTATIONS.SET_SHOW_COURTAGE, response.data);
  },
  
  async [DEPOTPOSITIONS_TYPES.ACTIONS.LOAD_CALC_COURTAGE]({ rootState, commit }, payload) {
    const config = {
      defaultSpinner: true
    };
    const response = await axios.post(rootState.core.apiAddress + '/depotpositions/calc-courtage', payload, config);
    if (response.data) {
      commit(DEPOTPOSITIONS_TYPES.MUTATIONS.SET_CALC_COURTAGE, response.data);
    }
  },
}
