var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseDropdownMenu", {
    attrs: {
      placement: "bottom-right",
      containerClass: "option-menu-help--dropdown",
      closeWithInnerClick: false
    },
    scopedSlots: _vm._u([
      {
        key: "hook-target",
        fn: function() {
          return [
            _c(
              "button",
              { staticClass: "btn-clear clickable", attrs: { type: "button" } },
              [
                _c("PhQuestion", {
                  attrs: { size: 20, alt: "Icon zur Erläuterung der Optionen" }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("ul", { staticClass: "option-menu-help--items" }, [
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [
                    _c("PhEye", {
                      attrs: { size: 16, alt: "Option kann abgewählt werden" }
                    })
                  ],
                  1
                ),
                _c("span", [_vm._v("Option kann abgewählt werden")])
              ]),
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [
                    _c("PhEye", {
                      attrs: {
                        size: 16,
                        weight: "fill",
                        alt: "Option kann nicht abgewählt werden"
                      }
                    })
                  ],
                  1
                ),
                _c("span", [_vm._v("Option kann nicht abgewählt werden")])
              ]),
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [
                    _c("PhLock", {
                      attrs: {
                        size: 16,
                        alt:
                          "Option kann an anderer Stelle platziert oder versteckt werden"
                      }
                    })
                  ],
                  1
                ),
                _c("span", [
                  _vm._v(
                    "Option kann an anderer Stelle platziert oder versteckt werden"
                  )
                ])
              ]),
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [
                    _c("PhLock", {
                      attrs: {
                        size: 16,
                        weight: "fill",
                        alt: "Option ist fix und kann nicht bearbeitet werden"
                      }
                    })
                  ],
                  1
                ),
                _c("span", [
                  _vm._v("Option ist fix und kann nicht bearbeitet werden")
                ])
              ]),
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [
                    _c("PhTrashSimple", {
                      attrs: { size: 16, alt: "Option kann versteckt werden" }
                    })
                  ],
                  1
                ),
                _c("span", [_vm._v("Option kann versteckt werden")])
              ]),
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [
                    _c("PhList", {
                      attrs: { size: 16, alt: "an anderer Stelle platzieren" }
                    })
                  ],
                  1
                ),
                _c("span", [_vm._v("an anderer Stelle platzieren")])
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }