<template>
    <div>
        <BaseModal
            ref="noSignoFilesModal"
            labelButtonCancel="Zurück"
            :showConfirmButton="false"
            @onCloseButton="closeModal()"
            @onCancelButton="closeModal">

            <template #modalTitle>
                <b>{{message}}</b>
            </template>
            <template #default>
                <p>Sie können jedoch das Dokument herunterladen</p>

                <div class="grid">
                <div class="row">
                    <div class="col">
                        <DownloadLink :href="documentLink">{{fileName}}</DownloadLink>
                    </div>
                </div>
                </div>
            </template>
        </BaseModal>

        <PictureAttachmentsModal
            ref="pictureAttachmentsModal"
            :selectedDoc="selectedDoc">
        </PictureAttachmentsModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import STORNO_WARNUNG_TYPES from '@/store/stornoWarnung/types';
import CORE_TYPES from '@/store/core/types';
import BaseModal from '@/components/core/BaseModal.vue';
import mixin from '@/mixins/stornos/stornos-mixin.js';
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '../../helpers/log-message-helper';
import { openSignoViewDocument } from '@/components/core/download/SignoViewerLink.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import PictureAttachmentsModal from "@/views/documents/PictureAttachmentsModal.vue";

export default {
    mixins: [mixin],
    components: {
        BaseModal,
        DownloadLink,
        PictureAttachmentsModal,
    },
    data() {
        return {
            fileName: '',
            dokId: '',
            fileId: '',
            selectedDoc: null,
            message: 'Das Dokument ist nicht zur e-Signatur geeignet',
        }
    },
    computed: {
        ...mapGetters({
            getPdfFilesLinkFn: CORE_TYPES.GETTERS.GET_PDF_FILES_LINK_FN,
        }),
        documentLink() {
            if (this.dokId || this.fileId) {
                return this.getPdfFilesLinkFn({ 
                    paramName: 'pdf', 
                    fileId: this.fileId, 
                    docId: this.dokId, 
                });
            }
            return null;
        },
    },
    mounted() {
        setTimeout(() => {
            const stornoId = this.$route.params.stornoId || '6407818';
                this.$store.dispatch(STORNO_WARNUNG_TYPES.ACTIONS.SIGNO_VIEWER, stornoId).then(response => {
                    if (response.data?.errorStr) {
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
                        this.$router.push('/home');
                    } else if (response.data?.signoURI) {
                        openSignoViewDocument(response.data.signoURI, response.data.fileName || '', false, true);
                        this.$router.push('/home');
                    } else if (response.data.signoElement) {
                        this.openZusatzDokModal(response.data);
                    } else {
                        this.fileName = response.data?.fileName || '';
                        this.dokId = response.data?.dokId || '';
                        this.fileId = response.data?.fileId || '';
                        if (response.data?.message) {
                            this.message = response.data.message;
                        }
                        this.$refs.noSignoFilesModal.open();
                    }
                });
        }, 500);
    },
    methods: {
        closeModal() {
            this.$router.push('/home');
        },
        openZusatzDokModal(item) {
            if (item.signoElement) {
                this.selectedDoc = item.signoElement
                setTimeout(() => {
                this.$refs.pictureAttachmentsModal.open();
                }, 100);
            }
        },
    }
}
</script>

<style>

</style>