var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-content-menu__container" },
    [
      _c("BaseDropdownMenu", {
        ref: "contextMenuDropdown",
        attrs: {
          placement: "bottom-right",
          disabled: _vm.disabled,
          containerClass: _vm.parsedContainerClass
        },
        scopedSlots: _vm._u(
          [
            {
              key: "hook-target",
              fn: function() {
                return [
                  _c(
                    "button",
                    {
                      staticClass: "btn-clear base-content-menu--btn",
                      attrs: {
                        type: "button",
                        id:
                          "base__context__menu__" +
                          _vm._generateTidFromString(_vm.tid + "..."),
                        tid: _vm._generateTidFromString(_vm.tid + "..."),
                        alt: _vm.ariaDescription
                      }
                    },
                    [
                      _c("PhDotsThreeVertical", {
                        staticClass: "clickable",
                        attrs: {
                          size: _vm.iconSize,
                          weight: "bold",
                          alt: _vm.ariaDescription
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "default",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "base-content-menu__content" },
                    [_vm._t("default")],
                    2
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }