import RUHESTANDSPLANUNG_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIO_LIST_SUCCESS](state, payload) {
    state.scenarioList = payload.scenarios;
    state.dataHasChanged = false;
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIO_SUCCESS](state, payload) {
    state.scenario = payload;
    state.dataHasChanged = false;
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.SCENARIO_URSPRUNG](state, payload) {
    if (payload?.id) {
      state.scenarioUrsprung = Object.assign({}, payload, { 
        ausgaben: payload.ausgaben?.map(ausgabe => Object.assign({}, ausgabe)) || null,
        vermoegen: payload.vermoegen?.map(verm => Object.assign({}, verm)) || null,
        myGoalList: payload.myGoalList?.map(goal => Object.assign({}, goal)) || null,
        scenarioEventList: payload.scenarioEventList?.map(event => Object.assign({}, event)) || null,
        isUrsprung: true 
      })
    } else {
      state.scenarioUrsprung = null;
    }
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_VERMOEGEN_SUCCESS](state, payload) {
    state.vermoegen = payload.vermoegen;
    state.ausgaben = payload.ausgaben;
    state.comboboxEventTypes = payload.comboboxEventTypes;
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.RESET_SCENARIO](state) {
    state.scenario = null;
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_STORE](state, {value, id}) {
    if (id && value) {
      state.scenario = Object.assign({}, state.scenario || {}, value);
      state.dataHasChanged = true;
    }
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_SELECTED_GOALS](state, {goal}) {
    if (goal?.id) {
      if (state.scenario) {
        if (goal.goalType === 'MY_GOAL') {
          const indexMyGoal = state.scenario.myGoalList?.findIndex(myGoal => myGoal.id === goal.id);
          if (indexMyGoal >= 0 && !goal.selected) {
            state.scenario.myGoalList.splice(indexMyGoal, 1);
            state.scenario.myGoalChanged = true;
          } else if ((indexMyGoal === undefined || indexMyGoal < 0) && goal.selected) {
            if (!state.scenario.myGoalList) {
              state.scenario.myGoalList = [];
            }
            state.scenario.myGoalList.push({ id: goal.id, startDate: goal.startDate, endDate: goal.endDate, zielsumme: goal.zielsumme, title: goal.title});
            state.scenario.myGoalChanged = true;
          }
        } else if (goal.goalType === 'SCENARIO_EVENT') {
          const indexScGoal = state.scenario.scenarioEventList?.findIndex(sc => sc.id === goal.id);
          if (indexScGoal >= 0) {
            state.scenario.scenarioEventList[indexScGoal].selected = goal.selected || false;
            state.scenario.scenarioEventChanged = true;
          }
        }
      }
      state.dataHasChanged = true;
    }
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_VERMOEGEN_AUSGABEN](state, {row, id, type}) {
    if (row && id && type) {
      if (state.scenario) {
        if (!state.scenario?.[type]) {
          state.scenario[type] = [];
        }
        const index2 = state.scenario?.[type]?.findIndex(verm => verm.name === row.name);
        if (index2 < 0) {
          state.scenario[type].push(row);
        } else {
          state.scenario[type][index2] = Object.assign({}, state.scenario[type][index2], row);
          state.scenario[type] = Object.assign([], state.scenario[type]);
        }
      } else if (id === 'new') {
        const scenario = {id: id, [type]: [row]};
        if (!state.scenarioList) {
          state.scenarioList = [];
        }
        state.scenarioList.push(scenario);
      }
      state.dataHasChanged = true;
    }
  },
}