<template>
    <div class="finance-line-sum">
        <div class="my-2">{{ title }}</div>
        <div class="my-2 pr-3"><CurrencyLabel :value="summe"/></div>
    </div>
</template>

<script>

import CurrencyLabel from '@/components/core/CurrencyLabel.vue';

export default {
    props: {
        summe: {
            default: 0,
        },
        title: {
        },
    },
    components: {
        CurrencyLabel,
    },
    computed: {
        summeAnlage() {
            return this.summe;
        }
    }
}

</script>

<style src='@/styles/anlegerprofil.scss' lang='scss' scoped>
</style>