var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _vm.brokerData.isUntermaklerDesktop || _vm.isBrokerBypass
          ? _c(
              "div",
              [
                _vm.brokerData.isUntermaklerDesktop || _vm.isBrokerBypass
                  ? _c("InputCheckboxItem", {
                      staticClass: "mb-3",
                      attrs: {
                        label:
                          "Passivmakler §34f/h, die Kunden werden betreut von:"
                      },
                      on: {
                        input: function($event) {
                          return _vm.addBrokerDataEdited("brokerData")
                        }
                      },
                      model: {
                        value: _vm.brokerData.isPassivMakler,
                        callback: function($$v) {
                          _vm.$set(_vm.brokerData, "isPassivMakler", $$v)
                        },
                        expression: "brokerData.isPassivMakler"
                      }
                    })
                  : _vm._e(),
                _vm.brokerData.isUntermaklerDesktop || _vm.isBrokerBypass
                  ? _c("ComboBox", {
                      attrs: {
                        isComponentHalfSize: "",
                        values: _vm.brokerData.passivMaklerValues
                      },
                      on: {
                        change: function($event) {
                          return _vm.addBrokerDataEditedCombo(
                            "brokerData",
                            "passivMaklerAuswahl",
                            $event
                          )
                        }
                      },
                      model: {
                        value: _vm.brokerData.passivMaklerAuswahl,
                        callback: function($$v) {
                          _vm.$set(_vm.brokerData, "passivMaklerAuswahl", $$v)
                        },
                        expression: "brokerData.passivMaklerAuswahl"
                      }
                    })
                  : _vm._e(),
                _vm.brokerData.isUntermaklerDesktop || _vm.isBrokerBypass
                  ? _c(
                      "div",
                      [
                        _c("ph-warning", {
                          staticClass: "color-danger mr-2",
                          attrs: { size: 16 }
                        }),
                        _vm._v(
                          "Bitte beachten Sie, dass bei Passivmaklern alle Courtagen außer Bestandscourtage auf 0 gesetzt werden. "
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c("InputField", {
          attrs: { label: "Gerichtsstand", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.gerichtsstand,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "gerichtsstand", $$v)
            },
            expression: "brokerData.sonstiges.gerichtsstand"
          }
        }),
        _c("InputField", {
          attrs: { label: "Zust. Behörde §34c", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragP34CRrgisterbeh,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragP34CRrgisterbeh", $$v)
            },
            expression: "brokerData.sonstiges.vertragP34CRrgisterbeh"
          }
        }),
        _c("InputField", {
          attrs: { label: "Zust. Behörde §34d", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragP34DRrgisterbeh,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragP34DRrgisterbeh", $$v)
            },
            expression: "brokerData.sonstiges.vertragP34DRrgisterbeh"
          }
        }),
        _c("InputField", {
          attrs: { label: "Zust. Behörde §34f/h", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragP34FHRrgisterbeh,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragP34FHRrgisterbeh", $$v)
            },
            expression: "brokerData.sonstiges.vertragP34FHRrgisterbeh"
          }
        }),
        _c("InputField", {
          attrs: { label: "Zust. Behörde §34i", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragP34IRrgisterbeh,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragP34IRrgisterbeh", $$v)
            },
            expression: "brokerData.sonstiges.vertragP34IRrgisterbeh"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("BaseButton", { on: { click: _vm.gesellschaftenEinstellen } }, [
          _vm._v("Erlaubte Gesellschaften nach §34d/e und §34f/h einstellen ")
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach §32",
            disabled: !_vm.brokerData.sonstiges.darfAendern32
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragP32Chk,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragP32Chk", $$v)
            },
            expression: "brokerData.sonstiges.vertragP32Chk"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "BAK Nummer",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.sonstiges.darfAendern32
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertrag32RegisterNr,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertrag32RegisterNr", $$v)
            },
            expression: "brokerData.sonstiges.vertrag32RegisterNr"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach § 34c Abs. 1 Satz 1",
            disabled: !_vm.brokerData.sonstiges.darfAendern34c1
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragPara34cAbs1Satz1,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragPara34cAbs1Satz1", $$v)
            },
            expression: "brokerData.sonstiges.vertragPara34cAbs1Satz1"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach § 34c Abs. 1 Satz 2",
            disabled: !_vm.brokerData.sonstiges.darfAendern34c2
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragPara34cAbs1Satz2,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragPara34cAbs1Satz2", $$v)
            },
            expression: "brokerData.sonstiges.vertragPara34cAbs1Satz2"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach § 34d Absatz 1",
            disabled: !_vm.brokerData.sonstiges.darfAendern34d1
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragPara34d,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragPara34d", $$v)
            },
            expression: "brokerData.sonstiges.vertragPara34d"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Registernummer / IHK Nummer",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.sonstiges.darfAendern34d1
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragP34dAbsatz1Registernr,
            callback: function($$v) {
              _vm.$set(
                _vm.brokerData.sonstiges,
                "vertragP34dAbsatz1Registernr",
                $$v
              )
            },
            expression: "brokerData.sonstiges.vertragP34dAbsatz1Registernr"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach § 34d Absatz 2",
            disabled: !_vm.brokerData.sonstiges.darfAendern34e
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragPara34e,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragPara34e", $$v)
            },
            expression: "brokerData.sonstiges.vertragPara34e"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Registernummer / IHK Nummer",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.sonstiges.darfAendern34e
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.para34eRegisternummer,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "para34eRegisternummer", $$v)
            },
            expression: "brokerData.sonstiges.para34eRegisternummer"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach § 34i",
            disabled: !_vm.brokerData.sonstiges.darfAendern34i
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragPara34i,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragPara34i", $$v)
            },
            expression: "brokerData.sonstiges.vertragPara34i"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Registernummer / IHK Nummer",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.sonstiges.darfAendern34i
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragP34iRegisternr,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragP34iRegisternr", $$v)
            },
            expression: "brokerData.sonstiges.vertragP34iRegisternr"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach § 34f Abs. 1 Satz 1 Nr. 1",
            disabled: !_vm.brokerData.sonstiges.darfAendern34f1
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vetragPara34fAbs1Satz1Nr1,
            callback: function($$v) {
              _vm.$set(
                _vm.brokerData.sonstiges,
                "vetragPara34fAbs1Satz1Nr1",
                $$v
              )
            },
            expression: "brokerData.sonstiges.vetragPara34fAbs1Satz1Nr1"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Registernummer / IHK Nummer",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.sonstiges.darfAendern34f1
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragP34fRegisternr,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragP34fRegisternr", $$v)
            },
            expression: "brokerData.sonstiges.vertragP34fRegisternr"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach § 34f Abs. 1 Satz 1 Nr. 2",
            disabled: !_vm.brokerData.sonstiges.darfAendern34f2
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vetragPara34fAbs1Satz1Nr2,
            callback: function($$v) {
              _vm.$set(
                _vm.brokerData.sonstiges,
                "vetragPara34fAbs1Satz1Nr2",
                $$v
              )
            },
            expression: "brokerData.sonstiges.vetragPara34fAbs1Satz1Nr2"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach § 34f Abs. 1 Satz 1 Nr. 3",
            disabled: !_vm.brokerData.sonstiges.darfAendern34f3
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vetragPara34fAbs1Satz1Nr3,
            callback: function($$v) {
              _vm.$set(
                _vm.brokerData.sonstiges,
                "vetragPara34fAbs1Satz1Nr3",
                $$v
              )
            },
            expression: "brokerData.sonstiges.vetragPara34fAbs1Satz1Nr3"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach § 34h Abs. 1 Satz 1 Nr. 1",
            disabled: !_vm.brokerData.sonstiges.darfAendern34h1
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragPara34hAbs1Satz1Nr1,
            callback: function($$v) {
              _vm.$set(
                _vm.brokerData.sonstiges,
                "vertragPara34hAbs1Satz1Nr1",
                $$v
              )
            },
            expression: "brokerData.sonstiges.vertragPara34hAbs1Satz1Nr1"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Registernummer / IHK Nummer",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.sonstiges.darfAendern34h1
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragP34hRegisternr,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vertragP34hRegisternr", $$v)
            },
            expression: "brokerData.sonstiges.vertragP34hRegisternr"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach § 34h Abs. 1 Satz 1 Nr. 2",
            disabled: !_vm.brokerData.sonstiges.darfAendern34h2
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragPara34hAbs1Satz1Nr2,
            callback: function($$v) {
              _vm.$set(
                _vm.brokerData.sonstiges,
                "vertragPara34hAbs1Satz1Nr2",
                $$v
              )
            },
            expression: "brokerData.sonstiges.vertragPara34hAbs1Satz1Nr2"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputCheckboxItem", {
          attrs: {
            label: "Erlaubnis nach § 34h Abs. 1 Satz 1 Nr. 3",
            disabled: !_vm.brokerData.sonstiges.darfAendern34h3
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vertragPara34hAbs1Satz1Nr3,
            callback: function($$v) {
              _vm.$set(
                _vm.brokerData.sonstiges,
                "vertragPara34hAbs1Satz1Nr3",
                $$v
              )
            },
            expression: "brokerData.sonstiges.vertragPara34hAbs1Satz1Nr3"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v(
            "Der beratende Makler unterhält gemäß den gesetzlichen Bestimmungen eine Vermögensschadenshaftpflichtversicherung"
          )
        ]),
        _c("InputField", {
          attrs: { label: "§34d Vers.-Nr.", isComponentHalfSize: "" },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vshNr34d,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vshNr34d", $$v)
            },
            expression: "brokerData.sonstiges.vshNr34d"
          }
        }),
        _c("InputField", {
          attrs: { label: "bei der", isComponentHalfSize: "" },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vshGesell34d,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vshGesell34d", $$v)
            },
            expression: "brokerData.sonstiges.vshGesell34d"
          }
        }),
        _c("InputField", {
          attrs: { label: "§34f Vers.-Nr.", isComponentHalfSize: "" },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vshNr34f,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vshNr34f", $$v)
            },
            expression: "brokerData.sonstiges.vshNr34f"
          }
        }),
        _c("InputField", {
          attrs: { label: "bei der", isComponentHalfSize: "" },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vshGesell34f,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vshGesell34f", $$v)
            },
            expression: "brokerData.sonstiges.vshGesell34f"
          }
        }),
        _c("InputField", {
          attrs: { label: "§34i Vers.-Nr.", isComponentHalfSize: "" },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vshNr34i,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vshNr34i", $$v)
            },
            expression: "brokerData.sonstiges.vshNr34i"
          }
        }),
        _c("InputField", {
          attrs: { label: "bei der", isComponentHalfSize: "" },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.sonstiges.vshGesell34i,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.sonstiges, "vshGesell34i", $$v)
            },
            expression: "brokerData.sonstiges.vshGesell34i"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }