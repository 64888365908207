var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hidePageHeader
        ? _c(
            "PageHeaderTitleNavigation",
            _vm._g(
              {
                attrs: {
                  id: _vm.id,
                  title: _vm.title,
                  subtitle: _vm.subtitle,
                  defaultMenu: _vm.defaultOptionsMenu,
                  actions: _vm.headerActionsInternal,
                  noPrimaryAction: _vm.noPrimaryAction
                },
                on: {
                  "action-EDIT": function($event) {
                    return _vm.openCardOverviewConfigModal()
                  },
                  action: function($event) {
                    return _vm.$emit("action", $event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _vm._t("title", function() {
                            return [_vm._v(_vm._s(_vm.title))]
                          })
                        ]
                      },
                      proxy: true
                    },
                    _vm.$slots.subtitle
                      ? {
                          key: "subtitle",
                          fn: function() {
                            return [_vm._t("subtitle")]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                )
              },
              _vm.$listeners
            )
          )
        : _vm._e(),
      _c("CardsOrganizer", {
        attrs: { id: _vm.id, columns: _vm.columns },
        on: {
          "update-columns": function($event) {
            return _vm.$emit("updateColumns", $event)
          },
          draggingCard: function($event) {
            return _vm.$emit("draggingCard", $event)
          },
          droppedCard: function($event) {
            return _vm.$emit("droppedCard", $event)
          },
          executeAction: function($event) {
            return _vm.$emit("executeAction", $event)
          },
          remove: function($event) {
            return _vm.$emit("remove", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "card",
            fn: function(ref) {
              var card = ref.card
              return [
                card.visible && !card.removed
                  ? [
                      _c(card.component, {
                        ref: "cardComponent",
                        tag: "component"
                      })
                    ]
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("CardOverviewConfigModal", {
        ref: "dashboardPanelConfigModal",
        attrs: { id: _vm.id, cards: _vm.cards }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }