import { render, staticRenderFns } from "./InternerAnsprechpartnerCard.vue?vue&type=template&id=169bd239&scoped=true&"
import script from "./InternerAnsprechpartnerCard.vue?vue&type=script&lang=js&"
export * from "./InternerAnsprechpartnerCard.vue?vue&type=script&lang=js&"
import style0 from "@/components/steckbrief/steckbrief.scss?vue&type=style&index=0&id=169bd239&lang=scss&scoped=true&"
import style1 from "./InternerAnsprechpartnerCard.vue?vue&type=style&index=1&id=169bd239&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "169bd239",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('169bd239')) {
      api.createRecord('169bd239', component.options)
    } else {
      api.reload('169bd239', component.options)
    }
    module.hot.accept("./InternerAnsprechpartnerCard.vue?vue&type=template&id=169bd239&scoped=true&", function () {
      api.rerender('169bd239', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/broker/makler-ansprechpartner/InternerAnsprechpartnerCard.vue"
export default component.exports