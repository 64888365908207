<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Digital Asset Kategorien" 
      :actions="headerActions" 
      :noPrimaryAction="false"
      v-on="$listeners"
      @action-TIPP-GEBEN="tippGeben()">
      
    </PageHeaderTitleNavigation>   

    <div class="box__container" v-for="asset in categories" :key="asset.id">
      <div class="box-title">
          <InputToggleSwitch
          :label="asset.category"
                      v-model="asset['selected']"
                    />
        </div>
        <div class="box-content">
          <Table
            ref="table"
            :tableId="asset.id"
            :headers="headers"
            :rows="getAssets(asset.id)"
            rowId="id"
            hidePagination
        >
        </Table>  
        </div>    

    </div>

  </div>
</template>

<script>
import GUBBI_TYPES from '@/store/gubbi/types';
import CORE_TYPES from '@/store/account/types';
import { mapGetters } from 'vuex';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue'
import Table from "@/components/table2/Table.vue";
import BaseCollapsable from '@/components/core/BaseCollapsable.vue';
import {TextColumn, CurrencyColumn, NumberColumn} from "@/components/table2/table_util.js";
import Vue from 'vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";

export default {
  mixins: [],
  components: {
    Table,
    GhostLoading,
    NoData,
    BaseCollapsable,
    PageHeaderTitleNavigation,
    InputToggleSwitch
  },
  data() {
    return {
      loading: false,
      selectedRows: {},
      mailUsers: [],
      page: 0,
      categories: []
    }
  },
  watch: {
    gubbiAssets: {
      handler(newValue) {
        Object.keys(newValue).forEach(nv => {
          Vue.set(this.selectedRows, nv, []);
        });

        this.categories = [...new Set(Object.keys(newValue).map(c => ({id: c, category: c.split("_")[0], selected: false})))];
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters({
      gubbiAssets: GUBBI_TYPES.GETTERS.GUBBI_ASSETS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("name", "Name"));
      result.center.push(TextColumn("description", "Beschreibung"));
      result.center.push(TextColumn("assetTypeName", "Vermögenswert Typ Name"));
      result.center.push(CurrencyColumn("currentPricePerUnit", "Preis"));
      result.center.push(NumberColumn("availableUnits", "Vol. Verfügbar"));
      result.center.push(CurrencyColumn("expectedReturn", "erwartete Erträge"));

      return result;
    },
    gubbiAssetCategories() {
      if (!this.gubbiAssets) return [];
      return [...new Set(Object.keys(this.gubbiAssets).map(c => ({id: c, category: c.split("_")[0], selected: false})))];
    },
    selectedAssets() {
      return Object.values(this.selectedRows).flat();
    },
    selectedCategories() {
      return this.categories.filter(c => c.selected);
    },
    headerActions() {
      const actions = [];

      actions.push(PageHeaderSimpleAction('TIPP-GEBEN', 'Tipps geben').withVisible(() => this.selectedCategories?.length));
      return actions;
    },     
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from});
    next();
  },
  methods: {
    async retrieveData() {
      this.loading = true;
      await this.$store.dispatch(GUBBI_TYPES.ACTIONS.RETRIEVE_GUBBI_ASSETS);  
      this.loading = false;
    },
    getAssets(whatCategory) {
      return this.gubbiAssets[whatCategory];
    },
    async tippGeben() {
      this.redirectToMailComposer();
    },
    resetMail(type) {
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, null);
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE, null);
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
          type: type,
          action: 'clear',
        });
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
          type: type,
          action: 'clear',
        });
    },    
    redirectToMailComposer() {
        const linkedObject = 'GUBBI_REFERALL' + [...new Set(this.selectedCategories.map(row => row.category))].join(', ');


        const mailData = {
          input_subject: 'Gubbi',
          to: [],
          input_html: `<p>[[Briefanrede]],</p><p>Über den folgenden Link erhalten Sie Zugriff auf eine Übersicht dieser Möglichkeiten:<br>[[GUBBI_REFERRAL_LINK]]<br>[[SIGNATUR]]</p>`
        }
       this.resetMail();
       this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, linkedObject);
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_INIT, mailData);
        this.$router.push({ path: `/communication/mailcomposer-postfach?stateKeys=mailcomposer,init` });
        
    },
    confirmContactsToSend(sendData) {
      console.log("send email logic")
      console.log(sendData.map(sd => sd.id));
    },
    async loadPage(pageId, force = false, filterType = null) {
      this.page = pageId;
      if (!force && this.customersData?.customers && this.customersData.customers[pageId])
        return;

      const params = {
        ...this.searchParams,
        checkpin: true,
        section: pageId,
        maxCount: this.maxRowsPerPage,
        inklOhneVertrag: true,
        otherCustomerInDesktopAllow: this.isSuperCustomer,
        withSequence: true,
        seq: this.customersData.sequenceId,
      };
      if (this.filterType || filterType) {
        params.filterType = filterType || this.filterType;
      }
      if (this.customerSearchSort && this.customerSearchSort.key) {
        params.sort = this.customerSearchSort.key
        params.sortDirection = this.customerSearchSort.sortDirection || 'asc'
      } else if(params?.lastName && !this.customerSearchSort?.key) {
        params.sort = 'name'
        params.sortDirection = 'asc'
      } else {
        delete params.sort
        delete params.sortDirection
      }
      this.loading = true;
      this.sendParams = params;
      await this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_CUSTOMER, params);
      this.loading = false;
    },    
  },
  async mounted() {
      await this.retrieveData();  
  },  
}
</script>

<style lang='scss' scoped></style>

</style>