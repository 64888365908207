<template>
    <div class="box__container-wrap">
        <div class="box__container-rows">
            <GhostLoading v-if="isLoading" type="table" :config="{ table: { rows: rowsPerPage, }, }" />
            <Table v-else-if="rows && rows.length" rowId="dokId" hidePagination :headers="headers" :rows="rows"
                :rowsPerPage="rowsPerPage" :mobileConfig="mobileConfig" @click-kundenLinkName="openCustomerNewTab"
                @action-REVERT="openVorlageModal" @action-CLOSE="dokumentAbschliessen"
                @action-E-SIGN="startDocumentSigning" @action-DEACTIVATE="openOffeneUnterschriftenDisableWarnung"
                @action-ADDITIONAL-DOCUMENTS="selectAdditionalDocuments">
                <template v-slot:name="row">
                    <DownloadLink v-if="canGenerateSignoViewerLink(row)" :title="row.name || 'Dokument'"
                        downloadServicePath="/get_signo_document_preview" :filename="row.name" :queryParams="{
                            nodeId: encodeURIComponent(row.nodeId),
                            viewFileId: encodeURIComponent(row.viewFileId),
                        }" />
                    <span v-else>
                        {{ row.name || 'Dokument' }}
                    </span>
                </template>
                <template v-slot:status="row">
                    <span class="text-smaller" :class="getStatusClass(row)">{{ row.status }}</span>
                </template>
            </Table>
            <NoData v-else noIcon />
        </div>

        <div v-if="!isLoading && !$isSmallScreen" class="bottom-row text-right mt-3">
            <router-link to="/communication/offene-unterschriften">
                <div v-if="rows.length">weitere...</div>
                <div v-else>Übersicht anzeigen</div>
            </router-link>
        </div>

        <RemoveVorlageModal :selectedElement="selectedElement" :isBroker="true" ref="removeVorlageModal"
            @error="errorStr = $event; $refs.fehlerVorlage.open()" @close="loadOpenSigns(rowsPerPage)" />

        <OpenSignatureAdditionalDocuments ref="zusaetzlicheDokumente"
            :selectedAdditionalDocuments="selectedAdditionalDocuments"
            :title="getTitle(null, selectedOpenSignatureForAdditionalDocuments)"
            :statusClass="getStatusClass(selectedOpenSignatureForAdditionalDocuments)"
            :statusStr="getStatusStr(selectedOpenSignatureForAdditionalDocuments)"
            :missingSignatureDescription="selectedOpenSignatureForAdditionalDocuments.missingSignatureDescription"
            @close="selectedAdditionalDocuments = null" />

        <OffeneUnterschriftenDisableWarnung v-if="showOffeneUnterschriftenDisableWarnung"
            :selectedElement="selectedElement" 
            @close="closeWarnungModal" 
        />
            
        <BaseModal ref="fehlerVorlage" :showConfirmButton="false" labelButtonCancel="Ok">
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered" />
                {{ errorStr }}
            </template>
        </BaseModal>
        <Viewer :session="signoViewerSession" :saveLastPosition="false" fullClientHeight ref="resizablePopup" id="882dbfca-2ba3-4e6f-a372-e6098e828e06" />
    </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import { TextColumn, SlotColumn, ActionColumn, SimpleAction, DownloadLinkHrefAction } from "@/components/table2/table_util.js";
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES, VIEW_ROLES } from '@/router/roles';
import { PhNotePencil, PhWarning } from 'phosphor-vue'
import RemoveVorlageModal from '@/components/openSigns/RemoveVorlageModal.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import mixin from '@/components/openSigns/offene-interschriften-mixin.js'
import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import OpenSignatureAdditionalDocuments from '@/components/communication/OpenSignatureAdditionalDocuments.vue'
import OffeneUnterschriftenDisableWarnung from '@/components/communication/OffeneUnterschriftenDisableWarnung.vue'
import Viewer from '@/components/communication/signosign/Viewer.vue'

const ROWS_PER_PAGE = 5;

export default {
    mixins: [mixin],
    components: {
        Table,
        DownloadLink,
        NoData,
        GhostLoading,
        RemoveVorlageModal,
        BaseModal,
        PhWarning,
        OpenSignatureAdditionalDocuments,
        OffeneUnterschriftenDisableWarnung,
        Viewer,
    },
    data() {
        return {
            rowsPerPage: ROWS_PER_PAGE,
            errorStr: null,
            signoViewerSession: '',
            isLoading: false,
        }
    },
    mounted() {
        this.loadOpenSigns(ROWS_PER_PAGE);
    },
    computed: {
        zugriffKunden() {
            return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]])
        },
        isCustomerLogin() {
            return this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_ONLY]])
        },
        headers() {
            const headers = {
                lockedLeft: [
                    TextColumn("kundenLinkName", "Name").makeAlwaysVisible(),
                ],
                center: [
                    SlotColumn("name", "Bezeichnung", 200, 1),
                ],
                lockedRight: [
                    SlotColumn("status", "e-Signatur", 200).makeAlwaysVisible(),
                    ActionColumn("actions"),
                ]
            };
            if (this.zugriffKunden)
                headers.lockedLeft[0].makeLink();
            return headers;
        },
        async rows() {
            return await Promise.all(this.offeneUnterschriften.map(async row => {
                const actions = [
                    SimpleAction("E-SIGN", PhNotePencil, "Elektronisch unterschreiben")
                ];
                if (!this.isCustomerLogin) {
                    actions.push(...[
                        SimpleAction("DEACTIVATE", PhNotePencil, "Warnung deaktivieren (inkl. Kommentar)"),
                        SimpleAction("REVERT", PhNotePencil, "Vorlage des Dokuments zurückziehen"),
                    ])
                }
                if (row.zusDokumente) {
                    actions.push(SimpleAction("ADDITIONAL-DOCUMENTS", PhNotePencil, "Zusätzliche Dokumente anzeigen"));
                }
                if (this.isAbschliessenButtonVisible(row) && !this.isAbschliessenButtonDisabled(row)) {
                    actions.push(SimpleAction("CLOSE", PhNotePencil, "Dokument abschließen"));
                }
                return {
                    ...row,
                    status: this.getStatusStr(row),
                    kundenLinkName: `${row.lastName}, ${row.firstName}`,
                    actions,
                }
            }).slice(0, ROWS_PER_PAGE))
        },
        mobileConfig() {
            return {
                title: '',
                headers: ["kundenLinkName", "name", "status"],
            }
        }
    },
    methods: {
        async startDocumentSigning(elem) {
            const session = await this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_SIGNO_VIEWER_LINK, {viewFileId: elem.viewFileId, nodeId: elem.nodeId})

            this.signoViewerSession = session

            if (this.$refs.resizablePopup) {
                this.$refs.resizablePopup.showPopup(elem);
            }
        },
        async dokumentAbschliessen(elem) {
            await this.submitDocument(elem);
            this.loadOpenSigns(ROWS_PER_PAGE)
        },
        closeWarnungModal() {
            this.showOffeneUnterschriftenDisableWarnung = false;
            this.selectedElement = null;
        }
    }

}
</script>

<style></style>