<template>
  <div>
    <GhostLoading v-if="isLoading" :title="TABLE_TITLE" type="table" />
    <Table
      v-else
      :title="TABLE_TITLE"
      :headers="headers"
      :rows="rows"
      :headerActions="tableHeaderActions"
      @headerAction-ADD="addCustomer()"
      @action-DELETE="$emit('removeConfig', $event)"
    >
      <template #menuStructure="row">
        <ComboBox 
          :values="menuStructureValues"
          v-model="row.menuStructureId" 
          :sortComboboxValues="false"
          @change="$emit('menuStructureChanged', { kundennr: row.kundennr, value: $event })" 
        />
      </template>
    </Table>
  </div>
</template>

<script>
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from '@/components/table2/Table.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import { TextColumn, ActionColumn, ConfirmedAction, SlotColumn } from '@/components/table2/table_util';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'bestimmte Kunden';

export default {
  components: {
    GhostLoading,
    Table,
    ComboBox,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    menuStructureValues: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TABLE_TITLE,
    };
  },
  computed: {
    headers() {
      return {
        center: [
          TextColumn('kundennr', 'Kundennummer'),
          SlotColumn('menuStructure', 'Menüstruktur'),
          ActionColumn('actions'),
        ],
      };
    },
    rows() {
      return this.items.map(item => ({
        ...item,
        actions: [
          ConfirmedAction('DELETE', 'PhTrash', 'löschen', 'Wollen Sie diesen Eintrag wirklich löschen?'),
        ],
      }));
    },
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('ADD', 'hinzufügen')
          .withDisabled(() => this.disabled)
          .withAsPrimary(() => true),
      ];
    },
  },
  methods: {
    addCustomer() {
      const { fullPath:back } = this.$route;
      this.$router.push({ 
        path: '/communication/mailcomposer-customer-search/customer/customer', 
        query: { 
          back, 
          hideTabs: true, 
          useGenericSelection: true, 
        },
      })
    },
  },
}
</script>
