var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("ComboBox", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isNewSchaden,
                expression: "isNewSchaden"
              }
            ],
            attrs: {
              label:
                "Zugehörige Sparte (kann nachträglich nicht mehr geändert werden!)",
              isComponentHalfSize: true,
              values: _vm.sparte,
              validateUntouched: _vm.isNewSchaden
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.sparteId,
              callback: function($$v) {
                _vm.$set(_vm.form, "sparteId", $$v)
              },
              expression: "form.sparteId"
            }
          }),
          _c("InputField", {
            attrs: { label: "Schadensnummer", isComponentHalfSize: true },
            on: {
              input: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.schadennr,
              callback: function($$v) {
                _vm.$set(_vm.form, "schadennr", $$v)
              },
              expression: "form.schadennr"
            }
          }),
          _c(
            "div",
            { staticClass: "col-12 col-sm-6 form-control" },
            [
              _c("InputField", {
                staticClass: "align-end",
                attrs: {
                  label:
                    "Sachbearbeiter Versicherungsgesellschaft sofern vorhanden",
                  isComponentHalfSize: false
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.form.sachbearbvu,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sachbearbvu", $$v)
                  },
                  expression: "form.sachbearbvu"
                }
              }),
              _c(
                "a",
                {
                  staticClass: "align-end",
                  staticStyle: { "flex-grow": "0" },
                  on: { click: _vm.handleAddContact }
                },
                [_c("PhBookOpen", { attrs: { size: 24 } })],
                1
              )
            ],
            1
          ),
          _c("InputField", {
            attrs: {
              label: "Sachbearbeiter E-Mail sofern vorhanden",
              isComponentHalfSize: true
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.sachbearbeiterEmail,
              callback: function($$v) {
                _vm.$set(_vm.form, "sachbearbeiterEmail", $$v)
              },
              expression: "form.sachbearbeiterEmail"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Sachbearbeiter Telefonnumer",
              isComponentHalfSize: true
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.sachbearbeiterTelefonnummer,
              callback: function($$v) {
                _vm.$set(_vm.form, "sachbearbeiterTelefonnummer", $$v)
              },
              expression: "form.sachbearbeiterTelefonnummer"
            }
          }),
          _c("DatePickerField", {
            attrs: {
              label: "Wann wurde der Schaden verursacht?",
              isComponentHalfSize: true
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.ereignisdatum,
              callback: function($$v) {
                _vm.$set(_vm.form, "ereignisdatum", $$v)
              },
              expression: "form.ereignisdatum"
            }
          }),
          _c("TimePicker", {
            attrs: { label: "Um wieviel Uhr?", isComponentHalfSize: true },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.ereignisUhrzeit,
              callback: function($$v) {
                _vm.$set(_vm.form, "ereignisUhrzeit", $$v)
              },
              expression: "form.ereignisUhrzeit"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Ursache (Höchstwert 100 Zeichen)",
              isComponentHalfSize: true
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.schadensursacheText,
              callback: function($$v) {
                _vm.$set(_vm.form, "schadensursacheText", $$v)
              },
              expression: "form.schadensursacheText"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Verschulden",
              isComponentHalfSize: true,
              values: _vm.verschulden,
              firstEmpty: true
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.verschulden,
              callback: function($$v) {
                _vm.$set(_vm.form, "verschulden", $$v)
              },
              expression: "form.verschulden"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Schadensablauf", isComponentHalfSize: true },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.schadenKommentar,
              callback: function($$v) {
                _vm.$set(_vm.form, "schadenKommentar", $$v)
              },
              expression: "form.schadenKommentar"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputToggleSwitch", {
            attrs: { label: "Schadenort", inLineLabel: "" },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.displaySchadenort,
              callback: function($$v) {
                _vm.$set(_vm.form, "displaySchadenort", $$v)
              },
              expression: "form.displaySchadenort"
            }
          }),
          _vm.form.displaySchadenort
            ? _c(
                "div",
                [
                  _c("FormLabel", [_vm._v("Schadensort")]),
                  _c("InputField", {
                    attrs: {
                      label: "Straße, Hausnummer",
                      isComponentHalfSize: true
                    },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.form.adresseStrasse,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "adresseStrasse", $$v)
                      },
                      expression: "form.adresseStrasse"
                    }
                  }),
                  _c("InputZIP", {
                    attrs: { label: "PLZ", isComponentHalfSize: true },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      },
                      city: function($event) {
                        _vm.form.adresseOrt = $event
                        _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.form.adressePlz,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "adressePlz", $$v)
                      },
                      expression: "form.adressePlz"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Ort", isComponentHalfSize: true },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.form.adresseOrt,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "adresseOrt", $$v)
                      },
                      expression: "form.adresseOrt"
                    }
                  }),
                  _c("ComboBox", {
                    attrs: {
                      label: "Land",
                      isComponentHalfSize: true,
                      values: _vm.configData.lands,
                      firstEmpty: true
                    },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.form.adresseLand,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "adresseLand", $$v)
                      },
                      expression: "form.adresseLand"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("DatePickerField", {
            attrs: { label: "Meldedatum", isComponentHalfSize: true },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.meldedatum,
              callback: function($$v) {
                _vm.$set(_vm.form, "meldedatum", $$v)
              },
              expression: "form.meldedatum"
            }
          }),
          _c("DatePickerField", {
            attrs: {
              label: "Datum erledigt",
              isComponentHalfSize: true,
              validateUntouched: true
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.erledigtDatum,
              callback: function($$v) {
                _vm.$set(_vm.form, "erledigtDatum", $$v)
              },
              expression: "form.erledigtDatum"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Bearbeitungsstand",
              isComponentHalfSize: true,
              values: _vm.bearbeitungsStandValues
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.bearbstand,
              callback: function($$v) {
                _vm.$set(_vm.form, "bearbstand", $$v)
              },
              expression: "form.bearbstand"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Schadenshöhe (ca.)",
              type: "number",
              isComponentHalfSize: true
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.schadenshoehe,
              callback: function($$v) {
                _vm.$set(_vm.form, "schadenshoehe", $$v)
              },
              expression: "form.schadenshoehe"
            }
          }),
          _c("InputTextArea", {
            attrs: {
              label: "Schadensreserve (Gesellschaft)",
              isComponentHalfSize: true
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.schadensReserve,
              callback: function($$v) {
                _vm.$set(_vm.form, "schadensReserve", $$v)
              },
              expression: "form.schadensReserve"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Währung",
              isComponentHalfSize: true,
              values: _vm.configData.wahrungen,
              firstEmpty: true
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.waehrung,
              callback: function($$v) {
                _vm.$set(_vm.form, "waehrung", $$v)
              },
              expression: "form.waehrung"
            }
          })
        ],
        1
      ),
      _c("SachbearbeiterModal", {
        ref: "sachbearbeiterModal",
        attrs: { records: _vm.mailUsers },
        on: { "select-bearbeiter": _vm.confirmBearbeiter }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }