<template>
  <div class="insurance-option-menu__container">
    <OptionMenu :id="$appNavigation.currentOptionMenuId" v-bind="$attrs" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      :actions="headerActions" 
      v-on="$listeners"
      :defaultMenu="customOptionsMenu"
      :noPrimaryAction="noPrimaryAction"
      @action="$emit('action', $event)"
    >
      <template #title><slot name="title">{{ title }}</slot></template>
      <template v-if="$slots.subtitle" #subtitle><slot name="subtitle"></slot></template>
    </PageHeaderTitleNavigation>


    <ExterneZugangAskOptions 
      :askOptions="askOptions"
      :title="externeZugang.companyLabel"
      v-if="askOptions"
      @close="resetExterneZugang()"
      @onConfirmButton="askOptionsConfirmed($event)"/>

    <BaseModal
      ref="dokumenteAbholenModal"
      modalTitle="Dokumente abholen"
      :showDefaultButtons="false"
      size="lg">
      {{dokumenteAbholenResult.value}}
    </BaseModal>       
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EXTERNE_ZUGANG_TYPES from '@/store/externeZugang/types'
import ANTRAG_TYPES from '@/store/antrag/types';
import CORE_TYPES from '@/store/core/types';
import VERSICHERUNG_TYPES from '@/store/versicherungen/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import UndrawLogin from '@/components/icons/undraw/UndrawLogin.vue';
import UndrawSecureServer from '@/components/icons/undraw/UndrawSecureServer.vue'

import vergleichsrechnerMixin from '@/components/externeZugang/vergleichsrechner-mixin';
import BERATUNGSMAPPE_TYPES from "@/store/beratungVersicherung/types";
import BaseModal from '@/components/core/BaseModal.vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { TabMenu, } from '@/menu/menu-utils';
import { makeQueryParam, addParamToURL } from '@/helpers/utils-helper';

export default {
  mixins: [vergleichsrechnerMixin],
  props: {
    title: {
      type: String,
      default: 'Versicherungen',
    },
    headerActions: {
      type: Array,
      default: () => [],
    },
    insuranceId: {
      type: String,
      default: null,
    },
    noPrimaryAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      externeZugangVergleichList: EXTERNE_ZUGANG_TYPES.GETTERS.EXTERNE_ZUGANG_VERGLEICH_LIST,
      antragList: ANTRAG_TYPES.GETTERS.ANTRAG_LIST,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
    }),
    customOptionsMenu() {
      const { insuranceId, isCustomerOnly, } = this;

      if (!insuranceId) return [];

      const isOverview = this.$route?.query?.fromOverview;
      const params = makeQueryParam({ fromOverview: isOverview });
      const isDeeplinkAvailable = this.versicherungenDetails?.insurance?.isDeeplinkAvailable ?? false;

      return [
        isOverview 
          ? TabMenu(`/home/versicherungen/insurance-group/overview/${insuranceId}`, 'Übersicht') 
          : TabMenu(`/home/versicherungen/insurance-group/details/${insuranceId}`, 'Details'),
        !isCustomerOnly 
          ? TabMenu(addParamToURL(`/home/versicherungen/insurance-group/insurancePostfach/${insuranceId}`, params), 'E-Mails & Aufgaben') 
          : null,
        TabMenu(addParamToURL(`/home/versicherungen/insurance-group/dokumente/${insuranceId}`, params), 'Dokumente'),
        TabMenu(addParamToURL(`/home/versicherungen/insurance-group/anmerkungen/${insuranceId}`, params), 'Anmerkungen'),
        isDeeplinkAvailable
          ? TabMenu(addParamToURL(`/home/versicherungen/insurance-group/deeplinks/${insuranceId}`, params), 'Versicherungsportal')
          : null,
      ].filter(tab => !!tab);
    },
    extraOptionMenu() {
      return [].concat(this.externeZugangOptions).concat(this.versicherungenFormOptions);
    },
    externeZugangOptions() {
      if(!this.externeZugangVergleichList?.length) return [];

      const list = this.externeZugangVergleichList
        .map(externeZugang => ({
          ...externeZugang,
          parent: {
            path: '/home/versicherungen',
          },
          label: externeZugang?.labelHtml || externeZugang?.itemLabel,
          component: UndrawLogin,
          action: () => this.askOptionsExterneZugang(externeZugang),
        }));

      return list || []
    },
    versicherungenFormOptions() {
      if(!this.antragList?.formGroups?.length) return [];

      const versicherungenForm = this.antragList.formGroups.find(form => form.path === 'VERSICHERUNG');
      const list = versicherungenForm?.children?.
        filter(child => !child.hideForm)?.
        map(child => ({
          parent: {
            path: '/home/versicherungen',
          },          
          path: child?.label,
          label: child?.labelHtml || child?.label,
          component: UndrawSecureServer,
          action: () => this.$router.push({ path: `/beratung/formulare/antrag/${versicherungenForm.path}/${child.path}`, query: { hideBeratungsmappepopup: child.hideBeratungsmappepopup, dynamicFormId: child?.dynamicFormId, } }),
        }))
      return list || []
    },
  },
  data() {
    return {
      dokumenteAbholenResult: {},
    }
  },
  mounted() {
    this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST)
  },
  methods: {
    dokumenteAbholen() {
      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.DO_DOWNLOAD_DOKUMENT_SMART_MSC).then(
        response => {
            this.dokumenteAbholenResult = response;

            this.$refs.dokumenteAbholenModal.open()          
        }
      );
    }
  },
  components: {
    OptionMenu,
    BaseModal,
    PageHeaderTitleNavigation,
  },
}
</script>
