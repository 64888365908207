export const MUTATION_PREFIX = 'EXTERNE_ZUGANG: ';
export const ACTIONS_PREFIX = 'EXTERNE_ZUGANG_ACTIONS_';
export const GETTERS_PREFIX = 'EXTERNE_ZUGANG_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    GET_EXTERNE_ZUGANG_LIST_VERGLEICH_SUCCESS: MUTATION_PREFIX + 'GET_EXTERNE_ZUGANG_LIST_VERGLEICH_SUCCESS',
    GET_EXTERNE_ZUGANG_LIST_ABSCHLUSS_SUCCESS: MUTATION_PREFIX + 'GET_EXTERNE_ZUGANG_LIST_ABSCHLUSS_SUCCESS',
    GET_EXTERNE_ZUGANG_VERGLEICH_PAGE_HEADER_ACTIONS_SUCCESS: MUTATION_PREFIX + 'GET_EXTERNE_ZUGANG_VERGLEICH_PAGE_HEADER_ACTIONS_SUCCESS',
  },
  ACTIONS: {
    GET_EXTERNE_ZUGANG_VERGLEICH_LIST: ACTIONS_PREFIX + 'GET_EXTERNE_ZUGANG_VERGLEICH_LIST',
    GET_EXTERNE_ZUGANG_ABSCHLUSS_LIST: ACTIONS_PREFIX + 'GET_EXTERNE_ZUGANG_ABSCHLUSS_LIST',
    OPEN_EXTERNE_ZUGANG: ACTIONS_PREFIX + 'OPEN_EXTERNE_ZUGANG',
    GET_EXTERNE_ZUGANG_VERGLEICH_OPTION_MENU: ACTIONS_PREFIX + 'GET_EXTERNE_ZUGANG_VERGLEICH_OPTION_MENU',
    GET_FFP_PLUS_LOGIN_DATA: ACTIONS_PREFIX + 'GET_FFP_PLUS_LOGIN_DATA',
    GET_FFP_PLUS_URL: ACTIONS_PREFIX + 'GET_FFP_PLUS_URL',
    GET_EXTERNE_ZUGANG_VERGLEICH_PAGE_HEADER_ACTIONS: ACTIONS_PREFIX + 'GET_EXTERNE_ZUGANG_VERGLEICH_PAGE_HEADER_ACTIONS',
  },
  GETTERS: {
    EXTERNE_ZUGANG_ABSCHLUSS_LIST: GETTERS_PREFIX + 'EXTERNE_ZUGANG_ABSCHLUSS_LIST',
    EXTERNE_ZUGANG_VERGLEICH_LIST: GETTERS_PREFIX + 'EXTERNE_ZUGANG_VERGLEICH_LIST',
    EXTERNE_ZUGANG_VERGLEICH_PAGE_HEADER_ACTIONS: GETTERS_PREFIX + 'EXTERNE_ZUGANG_VERGLEICH_PAGE_HEADER_ACTIONS',
  }
}
