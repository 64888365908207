import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import globalMixin from '@/mixins/global-mixin'
import { registerBrowserBackButtonEvent } from '@/router/breadcrumb/browser-back-button';

import './vuejs-devtools.js'
import './registerServiceWorker'
import './http-request-interceptor'
import './polyfill/broadcast-channel'
import './cookies-config'
import './dayjs-config'
import './vue-js-bridge'
import './events-interceptor-configs'
import './apex-chart-config'
import './popup-blocked-detector'

Vue.mixin(globalMixin);
Vue.use(VueCompositionApi);
registerBrowserBackButtonEvent();

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  'fk-fonds-shop',
  'fk-fonds-shop-dom'
];