var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.typ
        ? _c("OptionMenu", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              defaultMenu: _vm.$appNavigation.currentOptionMenu
            }
          })
        : _vm._e(),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Änderungslog"
        }
      }),
      _c("BaseFilter", {
        attrs: {
          filterId: "2fc8bb01-73b9-4b9b-99bc-b591d586acc3",
          title: "Suchen",
          metadata: _vm.filterMetadata,
          defaultOptions: _vm.defaultOptionsData,
          immidiateSearch: ""
        },
        on: {
          onFilter: function($event) {
            return _vm.filterForm($event)
          }
        }
      }),
      _vm.filtered
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.typ == "vers"
                ? _c("InputCheckboxItem", {
                    attrs: { label: "Erweiterter Log inkl. Schaden" },
                    model: {
                      value: _vm.mitSchadensmeldung,
                      callback: function($$v) {
                        _vm.mitSchadensmeldung = $$v
                      },
                      expression: "mitSchadensmeldung"
                    }
                  })
                : _vm._e(),
              !_vm.loading && _vm.rows && _vm.rows.length
                ? _c("Table", {
                    staticClass: "table-container",
                    attrs: {
                      title: _vm.typ
                        ? _vm.TABLE_TITLE_VERMITTLER_KUNDEN
                        : _vm.TABLE_TITLE,
                      tableId: "86ecad57-0325-44c3-b6e8-00a1364e7e7d",
                      configModalTitle: "'Änderungslog für Maklerauftrag",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 25
                    },
                    on: {
                      "click-aeunderung": function($event) {
                        return _vm.loadAenderung($event)
                      }
                    }
                  })
                : _vm.loading
                ? _c("GhostLoading", {
                    attrs: {
                      type: "table",
                      title: _vm.typ
                        ? _vm.TABLE_TITLE_VERMITTLER_KUNDEN
                        : _vm.TABLE_TITLE
                    }
                  })
                : _c("NoData", {
                    attrs: {
                      title: _vm.typ
                        ? _vm.TABLE_TITLE_VERMITTLER_KUNDEN
                        : _vm.TABLE_TITLE
                    }
                  })
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "infoModal",
          attrs: { showDefaultButtons: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Änderung: ")]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_c("span", [_vm._v(_vm._s(_vm.fullText))])])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }