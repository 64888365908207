var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _vm.auswahlTitle
          ? _c("ComboBox", {
              attrs: {
                label: _vm.auswahlTitle,
                values: _vm.sources,
                isComponentHalfSize: true,
                sortComboboxValues: false
              },
              model: {
                value: _vm.source,
                callback: function($$v) {
                  _vm.source = $$v
                },
                expression: "source"
              }
            })
          : _vm._e(),
        _vm.sparplan
          ? [
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "Wert",
                  validateUntouched: true,
                  isComponentHalfSize: true
                },
                model: {
                  value: _vm.amount,
                  callback: function($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount"
                }
              })
            ]
          : _vm._e(),
        _vm.main
          ? [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputField", {
                      attrs: {
                        type: "currency",
                        label: "Depotwert",
                        disabled: true
                      },
                      model: {
                        value: _vm.amount,
                        callback: function($$v) {
                          _vm.amount = $$v
                        },
                        expression: "amount"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-12 col-sm-6 content-right" }, [
                  _vm.source == "-6"
                    ? _c(
                        "div",
                        [
                          _c("BaseFileSelect", {
                            attrs: {
                              buttonText: "Importieren",
                              buttonClass: "ph-download-simple",
                              accept: ".xls, .xlsx, .csv"
                            },
                            on: { files: _vm.confirmImport }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ]
          : _vm._e(),
        _vm.asVVSchema != null
          ? [
              _c("InputRadioBoxGroup", {
                attrs: {
                  values: _vm.vvSchemaOption,
                  title: "Backtesting Berechnung"
                },
                model: {
                  value: _vm.asVVSchema,
                  callback: function($$v) {
                    _vm.asVVSchema = $$v
                  },
                  expression: "asVVSchema"
                }
              })
            ]
          : _vm._e()
      ],
      2
    ),
    _vm.hasTableData
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _vm.readingRows
              ? _c(
                  "GhostLoading",
                  [_c("Block", { attrs: { height: "100" } })],
                  1
                )
              : !_vm.shouldShowBigTable && _vm.showedRows.length
              ? _c("Table", {
                  attrs: {
                    headers: _vm.headers,
                    rows: _vm.showedRows,
                    headerActions: _vm.headerActions,
                    tableId: "Anlageempfehlung_" + _vm.stepName,
                    showColumnsConfigEvenNoTitle: true,
                    rowId: "unique",
                    rowsPerPage: 25,
                    scrollHorizontally: _vm.stepName == _vm.stepNames.FAVORITES
                  },
                  on: {
                    headerAction: _vm.onHeaderActions,
                    "action-FACTSHEET": function($event) {
                      return _vm.viewDocument($event.FACTSHEET_URL)
                    },
                    "action-FACTSHEET_KAG": function($event) {
                      return _vm.viewDocument($event.FACTSHEET_KAG_URL)
                    },
                    "action-KID": function($event) {
                      return _vm.viewDocument($event.KID_URL)
                    },
                    "action-PRIIP_BIB": function($event) {
                      return _vm.viewDocument($event.PRIIP_BIB_URL)
                    },
                    "action-LOCKM$JA": function($event) {
                      return _vm.setLock($event, "JA")
                    },
                    "action-LOCKM$FEST": function($event) {
                      return _vm.setLock($event, "FEST")
                    },
                    "action-LOCKM$KEINKAUF": function($event) {
                      return _vm.setLock($event, "KEINKAUF")
                    },
                    "action-LOCKM$KEINVERKAUF": function($event) {
                      return _vm.setLock($event, "KEINVERKAUF")
                    },
                    "action-EDIT": function($event) {
                      return _vm.edit($event.isin)
                    },
                    "action-DELETE": _vm.delRow,
                    "click-name": _vm.gotoFondsinfo
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "current",
                        fn: function(row) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "edited-row",
                                on: {
                                  click: function($event) {
                                    return _vm.edit(row.isin)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      selected: _vm.edit_isin == row.isin
                                    }
                                  },
                                  [
                                    _c("PhPencilLine", {
                                      style: {
                                        opacity:
                                          _vm.edit_isin == row.isin
                                            ? "1"
                                            : "0.3"
                                      },
                                      attrs: { size: 18 }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2083815516
                  ),
                  model: {
                    value: _vm.activeRows,
                    callback: function($$v) {
                      _vm.activeRows = $$v
                    },
                    expression: "activeRows"
                  }
                })
              : _vm.shouldShowBigTable && _vm.bigTableRows.length
              ? _c("BigTable", {
                  attrs: {
                    selectable: "",
                    title: _vm.tableTitle,
                    tableId: "f40334fa-53be-4c3e-87e7-8aca023c942e",
                    headers: _vm.bigTableHeaders,
                    rows: _vm.bigTableRows,
                    page: _vm.bigTablepage,
                    sortingState: _vm.sortingState,
                    initialPageSize: _vm.minNumRows,
                    colorMetadata: _vm.colorMetadata,
                    headerActions: _vm.tableActions
                  },
                  on: {
                    selectedRow: _vm.onRowSelection,
                    selectedRows: _vm.onAllRowsSelection,
                    onClickHeader: _vm.onClickHeader,
                    "action-FACTSHEET": function($event) {
                      return _vm.viewDocument($event.FACTSHEET_URL)
                    },
                    "action-FACTSHEET_KAG": function($event) {
                      return _vm.viewDocument($event.FACTSHEET_KAG_URL)
                    },
                    "action-KID": function($event) {
                      return _vm.viewDocument($event.KID_URL)
                    },
                    "action-PRIIP_BIB": function($event) {
                      return _vm.viewDocument($event.PRIIP_BIB_URL)
                    },
                    "action-LOCKM$JA": function($event) {
                      return _vm.setLock($event, "JA")
                    },
                    "action-LOCKM$FEST": function($event) {
                      return _vm.setLock($event, "FEST")
                    },
                    "action-LOCKM$KEINKAUF": function($event) {
                      return _vm.setLock($event, "KEINKAUF")
                    },
                    "action-LOCKM$KEINVERKAUF": function($event) {
                      return _vm.setLock($event, "KEINVERKAUF")
                    },
                    "click-link-name": _vm.gotoFondsinfo,
                    "action-EDIT": function($event) {
                      return _vm.edit($event.isin)
                    },
                    "action-DELETE": _vm.delRow,
                    "headerAction-EXPORT-PDF": function($event) {
                      return _vm.getExportBigTable("pdf")
                    },
                    "headerAction-EXPORT-XLS": function($event) {
                      return _vm.getExportBigTable("xls")
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "isin",
                        fn: function(row) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color:
                                    row.isNichtHandelbar === true
                                      ? "var(--color-danger)"
                                      : "inherit"
                                }
                              },
                              [_vm._v(_vm._s(row.isin))]
                            )
                          ]
                        }
                      },
                      {
                        key: "auflagedatum",
                        fn: function(ref) {
                          var auflagedatum = ref.auflagedatum
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.timeToStr(auflagedatum)) + " "
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2125450749
                  )
                })
              : _c("NoData", { attrs: { noIcon: true } })
          ],
          1
        )
      : _vm._e(),
    _vm.source == "-4"
      ? _c("div", { staticClass: "box__container" }, [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Eine neue Position hinzufügen")
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("InputField", {
                  attrs: { label: "ISIN/WKN" },
                  model: {
                    value: _vm.isinOrWkn,
                    callback: function($$v) {
                      _vm.isinOrWkn = $$v
                    },
                    expression: "isinOrWkn"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 content-right" },
              [
                _c(
                  "BaseButton",
                  {
                    staticClass: "btn btn-primary my-1",
                    attrs: { disabled: !_vm.isinOrWkn },
                    on: { click: _vm.addRow }
                  },
                  [_vm._v("Fonds suchen")]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _vm.rowEditable && _vm.showedRows.length
      ? _c("div", { staticClass: "box__container" }, [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Positionen bearbeiten")
          ]),
          _c("div", { staticClass: "box__title" }, [
            _vm._v(_vm._s(_vm.edit_name))
          ]),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("ComboBox", {
                staticClass: "col-12 col-sm-6",
                attrs: {
                  label: "Einschränkungen",
                  sortComboboxValues: false,
                  values: _vm.lockModeOpt
                },
                on: { input: _vm.rowEdited },
                model: {
                  value: _vm.lockingMode,
                  callback: function($$v) {
                    _vm.lockingMode = $$v
                  },
                  expression: "lockingMode"
                }
              }),
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c(
                    "div",
                    { staticClass: "two-label" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "pr-2",
                          style: { opacity: _vm.isWert ? 0.4 : 1 },
                          on: {
                            click: function($event) {
                              _vm.isWert = false
                            }
                          }
                        },
                        [_vm._v("Anteile")]
                      ),
                      _c("InputToggleSwitch", {
                        attrs: {
                          inLineLabel: "",
                          suppressValidationMessage: ""
                        },
                        model: {
                          value: _vm.isWert,
                          callback: function($$v) {
                            _vm.isWert = $$v
                          },
                          expression: "isWert"
                        }
                      }),
                      _c(
                        "div",
                        {
                          style: { opacity: !_vm.isWert ? 0.4 : 1 },
                          on: {
                            click: function($event) {
                              _vm.isWert = true
                            }
                          }
                        },
                        [_vm._v("Wert (€)")]
                      )
                    ],
                    1
                  ),
                  !_vm.isWert
                    ? _c("InputField", {
                        attrs: { type: "number", precision: "6" },
                        on: { change: _vm.rowEdited },
                        model: {
                          value: _vm.due,
                          callback: function($$v) {
                            _vm.due = $$v
                          },
                          expression: "due"
                        }
                      })
                    : _c("InputField", {
                        attrs: { type: "currency" },
                        on: { change: _vm.rowEdited },
                        model: {
                          value: _vm.value,
                          callback: function($$v) {
                            _vm.value = $$v
                          },
                          expression: "value"
                        }
                      })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { isSecondary: true, disabled: _vm.edit_idx <= 0 },
                  on: {
                    click: function($event) {
                      return _vm.changeRow(-1)
                    }
                  }
                },
                [_vm._v("Vorherige Zeile")]
              ),
              _c(
                "BaseButton",
                {
                  attrs: {
                    isSecondary: true,
                    disabled:
                      _vm.edit_idx < 0 ||
                      _vm.edit_idx > _vm.showedRows.length - 2
                  },
                  on: {
                    click: function($event) {
                      return _vm.changeRow(1)
                    }
                  }
                },
                [_vm._v("Nächste Zeile")]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }