<template>
  <div class="menu-structure-config__container">
    <div class="box__container">
      <div class="box__title">Menüstrukturen für Kunden</div>

      <div>
        <div class="box__title">alle Kunden</div>

        <GhostLoading v-if="loading" class="forms__input--half-size">
          <Block type="form-input" />
        </GhostLoading>
        <ComboBox 
          v-else
          :values="customerMenuStructureValues" 
          v-model="form.allCustomers"
          isComponentHalfSize 
          :sortComboboxValues="false"
          :disabled="saving"
        />
      </div>

      <div>
        <div class="box__title">neue Kunden</div>

        <GhostLoading v-if="loading" class="forms__input--half-size">
          <Block type="form-input" />
        </GhostLoading>
        <ComboBox 
          v-else
          :values="customerMenuStructureValues" 
          v-model="form.newCustomers"
          isComponentHalfSize 
          :sortComboboxValues="false" 
          :disabled="saving"
        />
      </div>

      <div>
        <SpecificCustomersTable 
          :items="form.specificCustomers" 
          :menuStructureValues="customerMenuStructureValues" 
          :disabled="saving"
          :isLoading="loading"
          @menuStructureChanged="onMenuStructureChanged($event)" 
          @removeConfig="onRemoveConfig($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import ComboBox, { buildValue } from '@/components/core/forms/ComboBox.vue';
import SpecificCustomersTable from '@/views/menu/menuStructure/menu-structure-config/SpecificCustomersTable.vue';

import { SYSTEM_ID } from '@/components/core/menu/menuStructure/menu-structure-utils';

export default {
  components: {
    GhostLoading, 
    Block, 
    ComboBox, 
    SpecificCustomersTable, 
  },
  data() {
    return {
      loading: false,
      saving: false,
      customerMenuStructure: [],
      form: {
        allCustomers: null,
        newCustomers: null,
        specificCustomers: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      genericSelectionFn: CORE_TYPES.GETTERS.GENERIC_SELECTION_FN, 
    }),
    customerMenuStructureValues() {
      return [
        buildValue(null, 'keine Angabe'),
        buildValue(SYSTEM_ID, 'System'),
        ...(this.customerMenuStructure?.map(ms => buildValue(ms.id, ms.label))),
      ];
    },
  },
  methods: {
    async fundCustomerMenuStructure() { 
      // TODO move this to the store
      await axios.get(`${process.env.VUE_APP_API}/menuConfig/customer_menu_structure`, { defaultSpinner: true, })
        .then(result => {
          this.$set(this, 'customerMenuStructure', result?.data ?? []);
        });
    },
    async findConfig() {
      // TODO move this to the store
      await axios.get(`${process.env.VUE_APP_API}/menuConfig/customer_menu_structure_config`, { defaultSpinner: true, })
        .then(result => {
          this.$set(this.form, 'allCustomers', result.data.allCustomers);
          this.$set(this.form, 'newCustomers', result.data.newCustomers);
          this.$set(this.form, 'specificCustomers', result.data.specificCustomers);
        });
    },
    async saveConfig() {
      try {
        this.saving = true;
        // TODO move this to the store
        await axios.post(`${process.env.VUE_APP_API}/menuConfig/customer_menu_structure_config`, this.form, { defaultSpinner: true, });
      } finally {
        this.saving = false;
      }
    },
    onRemoveConfig({ kundennr }) {
      const index = this.form.specificCustomers.findIndex(sc => sc.kundennr === kundennr);
      const item = this.form.specificCustomers[index];
      if (item.id) {
        // TODO move this to the store
        axios.delete(`${process.env.VUE_APP_API}/menuConfig/customer_menu_structure_config/${item.id}`, { defaultSpinner: true, });
      }
      this.form.specificCustomers.splice(index, index + 1);
    },
    addSpecificCustomersSelected() {
      const { 
        usersselected = '', 
        genericSelectionUUID, 
      } = this.$route.query;

      if (!genericSelectionUUID || (usersselected+'') !== 'true') {
        return;
      }

      const { genericSelectionFn } = this;
      const { items, exists, remove } = genericSelectionFn(genericSelectionUUID);
      if (exists) {
        const specificCustomers = items.map(item => ({ kundennr: item.id }));
        this.form.specificCustomers = [
          ...(this.form.specificCustomers ?? []),
          ...specificCustomers,
        ];
        remove();
      }
    },
    onMenuStructureChanged({ kundennr, value }) {
      const item = this.form.specificCustomers.find(sc => sc.kundennr === kundennr);
      this.$set(item, 'menuStructureId', value);
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await Promise.all([
        this.fundCustomerMenuStructure(),
        this.findConfig()
      ]);
      this.addSpecificCustomersSelected();
    } finally {
      this.loading = false;
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveConfig();
    next();
  },
}
</script>
