<template>
  <div>
    <div class="font-bold">{{ person1 }}</div>
    <div class="fc-form-danger" v-if="warningsPerson1">
      <div v-if="isCustomerDataLink(warningsPerson1)"><a class="color-danger" @click="goToCustomerData(warningsPerson1)">{{warningsPerson1.message}}</a></div>
      <div v-else>{{warningsPerson1.message}}</div>
    </div>
    <div class="box__container">
      <InputToggleSwitch v-model="hasExtraPersons" 
        label="Weitere Personen dem Anlegerprofil hinzufügen *" inLineLabel
        :disabled="isDisabled" validateUntouched
        @input="updatePersonenStepper($event)" />
      
      <div class="ml-2" v-if="hasExtraPersons">
        <InputToggleSwitch v-model="selectPerson2" inLineLabel suppressValidationMessage :label="getPerson2Label" :disabled="isDisabled"
          @input="personChanged('person2')"/>
        <EntitySelector v-if="selectPerson2" 
          isPersonPicker isComponentHalfSize 
          @new="newPerson({ personType: 'person2'})" 
          @edit="editPerson"
          v-model="person2" id="person2" :values="personListDepotOwner" 
          :disabled="isDisabled"
          @change="personComboChanged('person2', $event)">
        </EntitySelector>
        
        <InputToggleSwitch v-if="isChildOrFirm" v-model="selectPerson3" inLineLabel suppressValidationMessage :label="getPerson3Label"
          :disabled="isDisabled" @input="personChanged('person3')"/>
        <EntitySelector v-if="selectPerson3" 
          isPersonPicker isComponentHalfSize 
          @new="newPerson({ personType: 'person3'})" 
          @edit="editPerson"
          v-model="person3" id="person3" :values="personListDepotOwner" 
          :disabled="isDisabled"
          @change="personComboChanged('person3', $event)">
        </EntitySelector> 
        
        <InputToggleSwitch v-model="selectBM1" v-if="zeigeBM1" inLineLabel suppressValidationMessage :label="getBM1Label" :disabled="isDisabled"
          @input="personChanged('bm1')"/>
        <EntitySelector v-if="selectBM1" 
          isPersonPicker isComponentHalfSize 
          @new="newPerson({ personType: 'bm1'})" 
          @edit="editPerson"
          v-model="bm1" id="bm1" :values="personListBM" :disabled="isDisabled"
          @change="personComboChanged('bm1', $event)">
        </EntitySelector> 
        
        <InputToggleSwitch v-model="selectBM2" v-if="zeigeBM2" inLineLabel suppressValidationMessage :label="getBM2Label" :disabled="isDisabled"
          @input="personChanged('bm2')"/>
        <EntitySelector v-if="selectBM2" 
          isPersonPicker isComponentHalfSize 
          @new="newPerson({ personType: 'bm2'})" 
          @edit="editPerson"
          v-model="bm2" id="bm2" :values="personListBM" :disabled="isDisabled"
          @change="personComboChanged('bm2', $event)">
        </EntitySelector> 
      </div>
    </div>

    <div class="mt-2">
      <InputField
        label="Ort"
        v-model="fillPlace"
        :disabled="isDisabled"
        isComponentHalfSize
        @change="updateStore('startStep', 'fillPlace', $event)" />
      <DatePickerField
        label="Datum"
        v-model="fillDate" 
        dateFormat="DD.MM.YYYY" 
        placeholder="DD.MM.JJJJ"
        :disabled="isDisabled"
        isComponentHalfSize
        isValueAsString
        @input="updateStore('startStep', 'fillDate', getDateStr($event))"
      />
      <TimePicker
        label="Uhrzeit/Anfang"
        :disabled="isDisabled"
        isComponentHalfSize
        v-model="fillTime"
        @input="updateStore('startStep', 'fillTime', $event)"
      />
    </div>
  </div>
</template>

<script>
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import { mapGetters } from 'vuex';
import InputToggleSwitch from '../../components/core/forms/InputToggleSwitch';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import validator from '@/mixins/validator'
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import TimePicker from "@/components/core/forms/TimePicker.vue";

const NICHT_VERWENDEN = 'NICHT_VERWENDEN';

export default {
  mixins: [anlegerprofilMixin, validator],
  validators: {},
  components: {
    InputToggleSwitch,
    EntitySelector,
    InputField,
    DatePickerField,
    TimePicker,
  },
  data() {
    return {
      hasExtraPersons: false,
      selectPerson2: false,
      selectPerson3: false,
      selectBM1: false,
      selectBM2: false,
      person2: '',
      person3: '',
      bm1: '',
      bm2: '',
      disabled: false,
      fillDate: '',
      fillTime: '',
      fillPlace: '',
      isInit: true,
    };
  },
  mounted() {
    this.$nextTick().then(() => {
      this.createForm();
    });
  },
  watch: {
    data() {
      this.createForm();
    },
  },
  computed: { 
    ...mapGetters({
      warnings: ANLEGERPROFIL_TYPES.GETTERS.WARNINGS,
    }),
    person1() {
      return this.data?.depotOwner1?.person?.label || '';
    },
    getPerson2Label() {
      return this.data?.depotOwner2?.pageTitle?.label || '';
    },
    getPerson3Label() {
      return this.data?.depotOwner3?.pageTitle?.label || '';
    },
    personListDepotOwner() {
      return this.data?.depotOwner2?.personList || [{label: 'Nicht verwenden', value: NICHT_VERWENDEN}];
    },
    personListBM() {
      return this.data?.mandatory2?.personList || [{label: 'Nicht verwenden', value: NICHT_VERWENDEN}];
    },
    zeigeBM1() {
      return this.data?.extraData && !!this.data.extraData.zeigeBM1
    },
    zeigeBM2() {
      return this.data?.extraData && !!this.data.extraData.zeigeBM2
    },
    warningsPerson1() {
      return (this.warningsProStep?.find(warn => warn.personType === 'person1' && (!warn.id || this.isCustomerDataLink(warn))) || {});
    },
  },
  methods: {
    updateNewPerson() {
      if (this.$route.query['backAction'] === 'true') {
        const savedPerson = this.$store.getters[CUSTOMERDATA_TYPES.GETTERS.LAST_SAVED_NEW_PERSON];
        const personType = this.$store.getters[ANLEGERPROFIL_TYPES.GETTERS.SELECTED_PERSON_TYPE];
        if (personType && savedPerson && savedPerson !== 'neue_person' && savedPerson !== 'person1') {
          switch (personType) {
            case 'person2':
              this['selectPerson2'] = true;
              break;
            case 'person3':
              this['selectPerson3'] = true;
              break;
            case 'bm1':
              this['selectBM1'] = true;
              break;
            case 'bm2':
              this['selectBM2'] = true;
              break;
          }
          this.personComboChanged(personType, savedPerson);
          this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.SET_SELECTED_PERSON_TYPE, null);
          this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.LAST_SAVED_NEW_PERSON, null);
          this.doSave();
        }
      }
    },
    updateWarningsPersData() {
      this.warningsProStep?.forEach(warn => 
        this.$pushErrors(warn.personType, warn.message));
    },
    newPerson(event) {
      if (event?.personType) {
        const data = {};
        switch(event.personType) {
          case 'person2':
          case 'person3':
            data.relationship = this.isChildOrFirm ? { isLegalAgent: true } : { isExtraDepotOwner: true };
            break;
          case 'bm1':
          case 'bm2':
            data.relationship = { isMandatory: true };
            break;
        }
        this.doSave();
        this.setBeadcrumbs();

        this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.SET_SELECTED_PERSON_TYPE, event.personType);
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, 'neue_person');
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_SELECTED_EXTRA_DATA, data);
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_CUSTOMER_DATA);
      }
    },
    editPerson(event) {
      this.doSave();
      this.setBeadcrumbs();

      const personId = event;
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_CUSTOMER_DATA);
    },
    setBeadcrumbs() {
      const to = this.$route.path;
      const from = this.$route.fullPath;
      if (to && from) {
        this.$addBreadcrumb({
          label: 'zurück zum Anlegerprofil', 
          to,
          from,
        });
      }
    },
    isPersonSet(stepKey) {
      switch(stepKey) {
        case 'person2': return this.selectPerson2 && this.person2 && this.person2 !== NICHT_VERWENDEN;
        case 'person3': return this.selectPerson3 && this.person3 && this.person3 !== NICHT_VERWENDEN;
        case 'bm1': return this.selectBM1 && this.bm1 && this.bm1 !== NICHT_VERWENDEN;
        case 'bm2': return this.selectBM2 && this.bm2 && this.bm2 !== NICHT_VERWENDEN;
        default: return false;
      }
    },
    hasPerson(stepKey) {
      switch(stepKey) {
        case 'person2': return this.selectPerson2;
        case 'person3': return this.selectPerson3;
        case 'bm1': return this.selectBM1;
        case 'bm2': return this.selectBM2;
        default: return '';
      }
    },
    getPersonLabel(stepKey) {
      switch(stepKey) {
        case 'person2': return this.getPerson2Label;
        case 'person3': return this.getPerson3Label;
        case 'bm1': return this.getBM1Label;
        case 'bm2': return this.getBM2Label;
        default: return '';
      }
    },
    updatePersonenStepper(selected) {
      if (!selected) {
        this.updateStoreCheckBoxGroup('startStep', { depotOwner2Id: null, depotOwner3Id: null, mandatory1Id: null, mandatory2Id:  null })
      }
    },
    personComboChanged(personType, value) {
      this[personType] = value;
      this.personChanged(personType);
    },
    personChanged(personType) {
      if (this.hasPerson(personType) && !this.isPersonSet(personType)) {
        this.$pushErrors(personType, 'Bitte wählen Sie eine Person.');
      }
      this.updateStoreCheckBoxGroup('startStep', {
          depotOwner2Id: this.selectPerson2 ? this.person2 : null,
          depotOwner3Id: this.selectPerson3 ? this.person3 : null,
          mandatory1Id: this.selectBM1 ? this.bm1 : null,
          mandatory2Id: this.selectBM2 ? this.bm2 : null,
        }, true)
    },
    createForm() {
    if (this.isInit && this.data.depotOwner1) {
        this.hasExtraPersons = !!(this.data.depotOwner2?.person || this.data.depotOwner3?.person
          || this.data.mandatory1?.personId || this.data.mandatory2?.personId);
        this.selectPerson2 = !!this.data.depotOwner2?.person;
        this.person2 = this.data.depotOwner2?.person?.value || '';
        this.selectPerson3 = !!this.data.depotOwner3?.person;
        this.person3 = this.data.depotOwner3?.person?.value || '';
        this.selectBM1 = !!this.data.mandatory1?.personId;
        this.bm1 = this.data.mandatory1?.personId ? this.data.mandatory1.personId + ''  : '';
        this.selectBM2 = !!this.data.mandatory2?.personId;
        this.bm2 = this.data.mandatory2?.personId ? this.data.mandatory2.personId + '' : '';
        
        this.fillPlace = this.data.startStep?.fillPlace || '';
        this.fillDate = this.data.startStep?.fillDate || '';
        this.fillTime = this.data.startStep?.fillTime || '';

        if (this.data.startStep?.fillDate && this.data.startStep?.fillDateMarkAsChanged) {
          this.updateStore('startStep', 'fillDate', this.data.startStep?.fillDate);
        }

        this.updateWarningsPersData();
        this.updateWarnings();
        this.isInit = false;
      }
    },
  },
}
</script>

<style scoped>
  .errorMessage {
    color: var(--color-danger);
    margin-left: 2px;
  }
  .container__img {
    height: 300px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
</style>
