<template>
  <DragnDropArea
    class="depotnummer-anfordern-button-component mb-4"
    hoverText="Hochladen"
    @files="onFileChange"
  >
    <BaseFileSelect @files="onFileChange" accept=".pdf" :disabled="disabled">
      Hochladen
    </BaseFileSelect>
    <span>{{ filename }}</span
    ><br />
  
    <span
      >{{
        !config.sorgrechtsnachweis
          ? " Die Datei wird hochgeladen, wenn Sie zum nächsten Schritt wechseln"
          : antraegeData[antragId].PARAM_FILE_SORGERECHTSNACHWEIS
      }} </span
    ><br />
  </DragnDropArea>
</template>

<script>
import { mapGetters } from "vuex";

import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import ANTRAG_TYPES from "@/store/antrag/types";
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import { uploadFileChunked } from "@/helpers/upload-helper";

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    config: {},
    antragId: String,
  },
  data() {
    return {
      filename: null,
    };
  },
  computed: {
    ...mapGetters({
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
    disabled() {
      this.antraegeData[this.antragId].PARAM_FILE_SORGERECHTSNACHWEIS != "";
    },
  },
  components: {
    BaseFileSelect,
    DragnDropArea,
  },
  methods: {
    onFileChange(files) {
      if (this.config && this.config.sorgrechtsnachweis) {
        uploadFileChunked(files[0]).then((id) => {
          this.saveSorgrechtsnachweis(id);
        });
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          let base64string = reader.result.split(",").pop();
          this.$emit("input", { [files[0].name]: base64string });
        };
        reader.readAsDataURL(files[0]);
      }
       this.filename = files[0].name;
    },
    saveSorgrechtsnachweis(fileId) {
      const response = this.$store.dispatch(
        ANTRAG_TYPES.ACTIONS.SAVE_FFB_SORGRECHTSNACHWEIS,
        fileId
      );
      this.updateStore(fileId);
    },
    updateStore(data) {
      const payload = {
        id: this.antragId,
        data: {
          HIDDEN_FILEID_SORGERECHTSNACHWEIS: data || "",
          PARAM_FILE_SORGERECHTSNACHWEIS:
            "Sorgerechtsausweis/Geburtsurkunde wurde erfolgreich hochgeladen und wird beim Erzeugen der PDF zur FFB geschickt",
        },
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload);
    },
  },
};
</script>

<style scoped>
</style>
