<template>
    <div class="box__container">
    <ChartMontecarlo :adviceId="adviceId"/>
    </div>
</template>

<script>
import ChartMontecarlo from '@/components/charts/ChartMontecarlo.vue';
    export default {
        components: {
            ChartMontecarlo
        },
        props: {
            adviceId: Number,
        }
    }
</script>

<style lang="scss" scoped>

</style>