<template>
    <div>
        <InputField
            label="langfristige Kredite / Darlehen"
            v-model="form.longTermCredit"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('longTermCredit', $event || null)" />
        <InputField
            label="kurzfristige Kredite (Kontokorrent, Zwischenfinanzierungen)"
            v-model="form.shortTermCredit"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('shortTermCredit', $event || null)" />
        <InputField
            label="Sonstige Verpflichtungen zu Gunsten Dritter (Verpfändungen, Bürgschaften)"
            v-model="form.otherCredit"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('otherCredit', $event || null)" /> 

    </div>
  </template>

<script>

import InputField from '@/components/core/forms/InputField.vue';

export default {
    props: {
        disabled: {
            default: false,
        },
        finance: {
            default: () => {},
        },
    },
    data() {
        return {
            form: {}
        }
    },
    components: {
        InputField,
    },
    mounted() {
        this.form = Object.assign({}, this.finance);
    },
    methods: {
        updateStore(compId, value) {
            this.$emit('changed', {compId, value});
        },
    },

}
</script>