<template>
  <div class="broker-info__container" v-if="Object.keys(information).length">
    <div class="broker-info__item" v-for="(infoItem, index) in Object.keys(information)" :key="index">
      <span v-if="information[infoItem]" class="item__status color-success">
        <PhCheck :size="24" />
      </span>
      <span v-if="!information[infoItem]" class="item__status color-danger">
        <PhWarning :size="24" />
      </span>
      <span class="item__text">
        {{infoItem}}
      </span>
    </div>
  </div>
</template>

<script>
import { PhCheck, PhWarning } from 'phosphor-vue';

export default {
  props: {
    information: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    PhCheck,
    PhWarning,
  }
}

</script>

<style lang="scss">
.broker-info__container {
  display: flex;
  flex-wrap: wrap;

  .broker-info__item {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin: 0 0.5rem 0 0;

    &:last-child {
      margin-right: 0;
    }

    .item__status {
      margin: 0 0.25rem 0 0;
      svg {
        margin: 0;
      }
    }
  }
}

</style>