<template>
    <div>
        <div class="dashboard-panel__widgets">
            <div class="box__container dashboard-panel-widget">
                <div class="box__title">Ihre Einstellungen</div>
                <Table
                    ref="scenarioOverviewSettingsTable"
                    tableId="dac58861-120a-4f32-0cc6-f55f487e86a4"
                    rowId="name"
                    hidePagination
                    :headers="headers"
                    :rows="rowsSettings"
                >
                    <template v-slot:value="row">
                        <div class="font-bold text-right">{{ formatValue(row.value, row.type) }}</div>
                    </template>
                </Table>
            </div>

            <div class="box__container dashboard-panel-widget">
                <div class="box__title">Chart</div>
                <RetirementChart
                    :data="{
                    data: apexSeriesCurrentScenario,
                    series: apexSeriesCurrentScenario,
                    xaxis: apexXaxisCurrentScenario,
                    annotations: {},
                    markers: apexMarkers,
                    dataLabels: {enabled: false},
                    stroke: {curve: 'smooth'},
                    autoUpdateSeries: true,
                    }"
                />
            </div>

            <div class="box__container dashboard-panel-widget" v-if="!isRenteOk">
                <div class="box__title">Aktuelle Situation</div>
                <div v-html="aktuelleSituationText1"></div>
                <div class="mb-2" v-html="aktuelleSituationText2"></div>
            </div>

            <div class="box__container dashboard-panel-widget">
                <Table
                    ref="scenarioOverviewCalcTable"
                    tableId="dac58861-120a-4f32-0cc6-f55f487e86a4"
                    rowId="name"
                    hidePagination
                    :headers="headers"
                    :rows="rowsCalc"
                >
                    <template v-slot:value="row">
                        <div class="font-bold text-right">{{ formatValue(row.value, row.type, 0) }}</div>
                    </template>
                </Table>
            </div>
        </div>

        <div class="box__container" v-if="!isRenteOk">
            <div class="box__title">Lösungsmöglichkeit 1: Höhere Sparrate</div>
            <div v-html="loesungSparrateText"></div>
        </div>
        
        <div class="box__container" v-if="!isRenteOk">
            <div class="box__title">Lösungsmöglichkeit 2: Höhere Rendite</div>
            <div v-html="loesungRenditeText"></div>
        </div>
    </div>
</template>

<script>
import mixin from '@/views/ruhestandsplanung/mixin.js';
import {TextColumn, SlotColumn, IconColumn} from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import RetirementChart from '@/components/retirementScenario/RetirementChart.vue';
import { formatNumber } from '@/helpers/number-formatter.js'
import {sanitize} from '@/helpers/string-helper.js';

export default {
    mixins: [mixin],
    components: {
        Table,
        RetirementChart,
    },
    data() {
        return {
            headers: {
                lockedLeft: [
                    TextColumn("name", ""),
                    SlotColumn("value", ""),
                    IconColumn("action", "").makeLink(),
                ],
                center: [],
                lockedRight: [],
            },
        }
    },
    computed: {
        rowsSettings() {
            const rows = [
                { name: 'Aktuelles Alter', value: this.currentYear - this.geburtsjahr(this.scenario), key: 'alter', type: 'age'},
                { name: 'Geplannter Ruhestandsbeginn', value: this.alterRente, key: 'alterRenteBeginn', type: 'age' },
                { name: 'Angenommene Lebenserwartung', value: this.lebenserwartung(this.scenario), key: 'lebenserwartung',type: 'age' },
                { name: 'Wunschrente (mit Inflation)', value: this.wunschrenteMitInflation(this.scenario), key: 'wunschRente', type: 'currency' },
                { name: 'Erwartete Rente', value: this.scenario.renteMonatlichErwartet, key: 'renteEffektiv',type: 'currency' },
                { name: 'Für den Ruhestand eingeplantes Vermögen', value: this.vermoegenGeplant, key: 'vermoegenGeplant',type: 'currency'},
                { name: 'Derzeitige Sparrate für den Ruhestand', value: this.sparrate, key: 'sparrate',type: 'currency' },
                //{ name: 'Angenommene, durchschnittliche Rendite (mit Inflation)', value: this.renditeDepotGewichtetCurrentScenario * 100, key: 'rendite',type: 'percent' },
                { name: 'Inflationsrate', value: this.scenario.inflation, key: 'inflation',type: 'percent' },
            ];
            return rows;
        },
        rowsCalc() {
            const rows = [
                { name: 'Versorgungslücke aus heutiger Sicht', 
                    value: this.isRenteOk ? 'keine' : this.vermoegenLuecke, 
                    key: 'vermoegenLuecke', 
                    type: this.isRenteOk ? '' : 'currency'},
                { name: 'Vermögen zum Ruhestandsbeginn', value: this.vermoegenGeplant, key: 'vermoegenRenteBeginn', type: 'currency' },
                { name: 'Vermögen reicht bis Alter', value: this.alterVermoegenEnde, key: 'alterVermoegenEnde',type: 'age' },
                { name: 'Benötigtes Vermögen zum Ruhestandsbeginn', value: this.vermoegenBenoetigtRenteBeginn, key: 'vermoegenBenoetigtRenteBeginn', type: 'currency' },
                { name: 'Geplantes Restkapital', value: this.scenario.restKapital || '', key: 'vermoegenBenoetigtRenteBeginn', type: 'currency' },
            ];
            return rows;
        },
        aktuelleSituationText1() {
            return this.restKapitalEffektiv < 0 
                ? 'Mit Ihrer derzeitigen Sparrate und dem bereits vorhandenen Vermögen werden Sie <span class="font-strong">nicht</span> genügend Geld '
                + 'zum Ruhestandsbeginn anhäufen, um während der gesamten Ruhestandspfase ausreichend versorgt zu sein. '
                
                : 'Mit Ihrer derzeitigen Sparrate und dem bereits vorhandenen Vermögen werden Sie nicht genügend Restkapital haben.';
        },
        aktuelleSituationText2() {
            return this.restKapitalEffektiv < 0 
                ? sanitize(`Durch Ihre aktuellen Sparanstrengungen von <span class="font-strong">${formatNumber(this.sparrate, 0)} €</span>
                 werden Sie bei Ruhestandsbeginn auf ein <span class="font-strong>Vermögen von 
                ${formatNumber(this.vermoegenGeplant, 0)} €</span> kommen. Da Sie monatlich ab Ruhestandsbeginn von Ihrem Vermögen einen Betrag von 
                <span class="font-strong">${formatNumber(this.wunschrenteMitInflation(this.scenario), 0)} €</span> 
                entnehmen müssen, wird das Kapital nur <span class="font-strong">bis zum Alter von ca. ${this.alterVermoegenEnde || 0} Jahre</span>
                 reichen - anstatt bis zu 
                der von Ihnen erwarteten <span class="font-strong">Lebensdauer von ${this.lebenserwartung(this.scenario)} Jahren.</span>`)
                
                : sanitize(`Durch Ihre aktuellen Sparanstrengungen von <span class="font-strong">${formatNumber(this.sparrate, 0)} €</span>
                 werden Sie bei Ruhestandsbeginn auf ein <span class="font-strong>Vermögen von 
                ${formatNumber(this.vermoegenGeplant, 0)} €</span> kommen. Da Sie monatlich ab Ruhestandsbeginn von Ihrem Vermögen einen Betrag von 
                <span class="font-strong">${formatNumber(this.wunschrenteMitInflation(this.scenario), 0)} €</span> 
                entnehmen müssen, werden Sie an der von Ihnen erwarteten 
                <span class="font-strong">Lebensdauer von ${this.lebenserwartung(this.scenario)} Jahren</span> das Restkapital von nur
                <span class="font-strong">${formatNumber(this.restKapitalEffektiv, 0)} €</span> haben statt gewünschte  
                <span class="font-strong">${formatNumber(this.scenario.restKapital || 0, 0)} €</span>.`);
        },
        loesungSparrateText() {
            return sanitize(`Um das <span class="font-strong">notwendige Kapital von ${formatNumber(this.vermoegenBenoetigtRenteBeginn, 0)} €</span> zu erhalten, muss 
            <span class="font-strong">die Sparrate um ${formatNumber(this.sparrateErhoehungCalc(), 0)} €</span> 
            erhöht werden. Die <span class="font-strong">gesammte Sparrate</span> würde somit ca. <span class="font-strong">${formatNumber(this.sparrateCalc, 0)} €</span> betragen.`);
        },
        loesungRenditeText() {
            return sanitize(`Wenn monatlich nicht mehr gespart werden kann, muss das vorhandene Vermögen, sowie die Sparrate höher verzinst werden. 
            Um das <span class="font-strong">notwendige Vermögen von ${formatNumber(this.vermoegenBenoetigtRenteBeginn, 0)} €</span> ohne weitere Sparrate zu erreichen, 
            muss das Kapital mit <span class="font-strong">${formatNumber(this.renditeCalc, 2)} %</span> (plus Inflationsrate)
            statt mit <span class="font-strong">${formatNumber(this.renditeDepotGewichtetCurrentScenario * 100, 2) || 0} %</span> verzinst werden.`);
        },
        vermoegenLuecke() {
            return this.isRenteOk ? 0 : (this.vermoegenBenoetigtRenteBeginn - this.vermoegenGeplant);
        },
    },
}
</script>

<style lang="scss" scoped>
.dashboard-panel__widgets {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.dashboard-panel-widget {
  margin: 0 12px 24px;
  width: calc(50% - 24px);
  &.box__container {
    .dashboard-panel-widget__content {
      .box__container,
      .box__shadow {
        background: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
</style>