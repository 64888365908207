<template>
  <div class="zusaetzliche-person-daten-view">
    <PersonTopNavigation 
      title="Person"
      :subtitle="fullName"
    />

    <StepperForm
      stepType="tab"
      stepperName="person-data"
      :stepperMediator="tabsStepperMediator"
      :hasFertig="false"
      :selectedStepKey="step"
      :selectedSubstepKey="substep"
      :additionalActionsForMobile="additionalActionsForMobile"
      @set-step="navigateToPath($event)"
      @set-substep="navigateToSubstepPath($event)"
      @handleOtherActions="handleMobileActions"
    >
      <template #headerTemplate>
        <HinweiseUndFehler :errors="warnings"  :hints="hints" 
          @set-step="navigateToPath($event)" @set-substep="navigateToSubstepPath($event)" />
      </template>
      <template #contentTemplate>
        <router-view></router-view>
      </template>
    </StepperForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import { StepperMediator } from '@/components/stepper/utils';

import PersonTopNavigation from '@/components/persoenliche-daten/components/PersonTopNavigation.vue';
import StepperForm from '@/components/stepper/StepperForm.vue';
import HinweiseUndFehler from '@/components/core/HinweiseUndFehler.vue';

import { personSteps, } from '../persoenliche-daten-steps-utils';
import { prepareDepoteroffnungenWarningsPerson, hasDepoteroffnungenWarnings } from '../persoenliche-daten-warnings-utils';

import persoenlicheDatenMixin from '../persoenliche-daten-mixin';
import { RELATION_BEKANNT } from '../zusaetzliche-personen/PersonBeziehungFields.vue';

export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      tabs: [],
      stepperCollapsed: false,
    };
  },
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isMainPerson: CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON,
      hints: CUSTOMERDATA_TYPES.GETTERS.HINTS,
    }),
    personIdParam() {
      return this.$route?.params?.personId;
    },
    personalDataAddress() {
      return this.customerData?.personalDataAddress;
    },
    personalDataAddressEdited() {
      return this.customerDataEdited?.personalDataAddress;
    },
    title() {
      return this.personalDataAddressEdited?.title || this.personalDataAddress?.title;
    },
    tabsStepperMediator() {
      return new StepperMediator(this.tabs);
    },
    warnings() {
      return [...prepareDepoteroffnungenWarningsPerson(this.personId, this.depoteroffnungenWarnings, this.isFirma, this.isMainPerson)];
    },
  },
  watch: {
    title(newTitle, oldTitle) {
      if(newTitle !== oldTitle) {
        this.setTabs();
      }
    },
    depoteroffnungenWarnings() {
      this.setTabs();
    },
    personIdParam: {
      handler() {
        this.init();
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$route.query?.isBrokerContact) {
      const payload = {
          personId: this.personIdParam,
          personType: 'Kontakt',
        }

      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload)
    }
  },
  methods: {
    async init() {
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, this.personIdParam);
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_CUSTOMER_DATA_EDITED);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG)
      if(this.personIdParam === 'person1') {
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
      } else if(this.personIdParam !== 'neue_person') {
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_SELECTED_PERSON_DATA);
      } else if(this.personIdParam === 'neue_person') {
         this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
        const payload = {
          personId: this.personIdParam,
          relationship: {
            personId: this.personIdParam,
            relation: { 
              value: this.$route.query?.relation || RELATION_BEKANNT,
            },
          },
        };
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload);
      }
      if(this.isMainPerson) {
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS, { list: true });
      }
      this.setTabs();
    },
    setTabs() {
      this.tabs = this.findTabs();
    },
    findTabs() {
      const steps = personSteps(this.personIdParam, this.isFirma, this.isMainPerson);
      return steps.map(step => {
        const hasWarning = hasDepoteroffnungenWarnings(this.depoteroffnungenWarnings, step.stepKey, this.isFirma, this.isMainPerson);
        return {
          ...step,
          warning: hasWarning,
          substeps: step.substeps?.map(substep => ({
            ...substep,
            warning: hasWarning && this.warnings?.some(warning => warning?.substepKey === substep.substepKey),
          }))
        };
      });
    },
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveChanges();

    this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_HINTS);
    if(to?.path?.indexOf('customer-data') < 0) {
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_STATE);
    }
    next();
  },
  components: {
    PersonTopNavigation,
    StepperForm,
    HinweiseUndFehler,
  },
}
</script>
