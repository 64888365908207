var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu,
          tid: "3c886523-0f75-4a62-bb04-2bd422c441b8"
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Vermögensübersicht",
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentTabMenu
        }
      }),
      _vm.loading
        ? _c(
            "div",
            [
              _c(
                "GhostLoading",
                { attrs: { repeat: 3, useBoxContainer: "", inline: "" } },
                [_c("Block"), _c("Block")],
                1
              ),
              _c(
                "div",
                { staticClass: "kacheln" },
                [
                  _c(
                    "GhostLoading",
                    { staticClass: "kachel", attrs: { useBoxContainer: "" } },
                    [
                      _c("Block", { attrs: { type: "title" } }),
                      _c("Block", { attrs: { height: 175 } })
                    ],
                    1
                  ),
                  _c(
                    "GhostLoading",
                    { staticClass: "kachel", attrs: { useBoxContainer: "" } },
                    [
                      _c("Block", { attrs: { type: "title" } }),
                      _c("Block", { attrs: { height: 175 } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("div", [
            _vm.depotsgesamt
              ? _c("div", [
                  !_vm.$isSmallScreen
                    ? _c(
                        "div",
                        { staticClass: "overview__container" },
                        _vm._l(
                          _vm.getDataFromLabels(_vm.depotsgesamt.resultSums),
                          function(sum, index) {
                            return _c("div", { key: "sum" + index }, [
                              sum && sum[1] !== ""
                                ? _c("div", { staticClass: "box__container" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-small text-centered mp-0"
                                      },
                                      [_vm._v(_vm._s(sum[0]))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-centered mp-0",
                                        class: _vm.getBadgesClassCategory(sum)
                                      },
                                      [
                                        sum[3]
                                          ? [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.convertStringToNumber(
                                                    sum[1]
                                                  )
                                                ) + " %"
                                              )
                                            ]
                                          : _vm.isNumber(sum[1])
                                          ? _c("CurrencyLabel", {
                                              attrs: {
                                                value: _vm.convertStringToNumber(
                                                  sum[1]
                                                )
                                              }
                                            })
                                          : [_vm._v(_vm._s(sum[1]))]
                                      ],
                                      2
                                    )
                                  ])
                                : _vm._e()
                            ])
                          }
                        ),
                        0
                      )
                    : _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _vm._l(
                            _vm.getDataFromLabels(_vm.depotsgesamt.resultSums),
                            function(sum, index) {
                              return [
                                _c("MobileInfoRow", {
                                  key: "sum" + index,
                                  attrs: { label: sum[0] },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "value",
                                        fn: function() {
                                          return [
                                            sum[3]
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.convertStringToNumber(
                                                        sum[1]
                                                      )
                                                    ) + " %"
                                                  )
                                                ]
                                              : _vm.isNumber(sum[1])
                                              ? _c("CurrencyLabel", {
                                                  attrs: {
                                                    value: _vm.convertStringToNumber(
                                                      sum[1]
                                                    )
                                                  }
                                                })
                                              : [_vm._v(_vm._s(sum[1]))]
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ]
                            }
                          )
                        ],
                        2
                      )
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "kacheln mt-3" },
              _vm._l(_vm.depotlist, function(kunde, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    { staticClass: "box__container kachel" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "text-bold",
                          on: {
                            click: function($event) {
                              return _vm.navigateToDepot(kunde)
                            }
                          }
                        },
                        [_vm._v(_vm._s(kunde.depotname))]
                      ),
                      _c("hr"),
                      _vm._l(_vm.getDataFromLabels(kunde.resultSums), function(
                        sum,
                        index
                      ) {
                        return [
                          _c("MobileInfoRow", {
                            key: "sum" + index,
                            attrs: { label: sum[0] },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "value",
                                  fn: function() {
                                    return [
                                      sum[3]
                                        ? [
                                            _vm._v(
                                              _vm._s(
                                                _vm.convertStringToNumber(
                                                  sum[1]
                                                )
                                              ) + " %"
                                            )
                                          ]
                                        : _c("CurrencyLabel", {
                                            attrs: {
                                              value: _vm.convertStringToNumber(
                                                sum[1]
                                              )
                                            }
                                          })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ]
                      })
                    ],
                    2
                  )
                ])
              }),
              0
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }