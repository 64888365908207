var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "font-strong" }, [
        _vm._v(" Zu prüfende Vermittlernummern ")
      ]),
      _c("InputField", {
        attrs: {
          label: "Maklernummer des Hauptvermittlers",
          isComponentHalfSize: "",
          disabled: true
        },
        model: {
          value: _vm.form.displayMaklernrVertrag,
          callback: function($$v) {
            _vm.$set(_vm.form, "displayMaklernrVertrag", $$v)
          },
          expression: "form.displayMaklernrVertrag"
        }
      }),
      _c("div", [
        _vm._v(
          " Hier können Sie die aktuellem einbezogenen Vermittlernummern überprüfen: "
        )
      ]),
      _c("div", { staticClass: "mb-2" }, [
        _c(
          "div",
          { staticClass: "layout__negative-margin--8" },
          [
            _c(
              "BaseButton",
              {
                attrs: { isSecondary: "" },
                on: {
                  click: function($event) {
                    return _vm.loadMaklerPreview()
                  }
                }
              },
              [_vm._v(" Vermittlernummern prüfen ")]
            ),
            _c(
              "BaseButton",
              {
                attrs: { isSecondary: "" },
                on: {
                  click: function($event) {
                    return _vm.loadBestaendePreview()
                  }
                }
              },
              [_vm._v(" Kundenbestände prüfen ")]
            )
          ],
          1
        )
      ]),
      _c("InputTextArea", {
        attrs: {
          label:
            "Gegebenenfalls weitere Untervermittler (bitte beachten Sie, dass diese\n\t\t\tUntervermittler für die Prüfung die gleiche Registernr §34 eingetragen haben müssen):"
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.unternummernZusatz,
          callback: function($$v) {
            _vm.$set(_vm.form, "unternummernZusatz", $$v)
          },
          expression: "form.unternummernZusatz"
        }
      }),
      _c("hr"),
      _c("div", { staticClass: "font-strong" }, [
        _vm._v(" Aufklärungen und Nachweise ")
      ]),
      _c("div", [
        _vm._v(
          " Die Aufklärungen und Nachweise, um die Sie mich (uns) gemäß § 25 Abs. 1 FinVermV gebeten haben, habe ich (haben wir) Ihnen nach bestem Wissen und Gewissen gegeben. Als Auskunftspersonen habe ich (haben wir) Ihnen die nachfolgend aufgeführten Personen benannt: "
        )
      ]),
      _c("InputTextArea", {
        attrs: {
          label:
            "Bitte machen Sie auch Angaben zur Kontaktaufnahme, vorzugsweise per Telefon. \n\t\t\tDies beschleunigt die Bearbeitung des Auftrags."
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.personenAuskunft,
          callback: function($$v) {
            _vm.$set(_vm.form, "personenAuskunft", $$v)
          },
          expression: "form.personenAuskunft"
        }
      }),
      _c("ComboBox", {
        attrs: {
          isComponentHalfSize: "",
          label: "Bekannte Personen hinzufügen:",
          values: _vm.vertragWPValues.maklerPeople
        },
        model: {
          value: _vm.form.comboPersonenAuskunft,
          callback: function($$v) {
            _vm.$set(_vm.form, "comboPersonenAuskunft", $$v)
          },
          expression: "form.comboPersonenAuskunft"
        }
      }),
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c(
            "BaseButton",
            {
              attrs: { isSecondary: "" },
              on: {
                click: function($event) {
                  return _vm.addPerson()
                }
              }
            },
            [_vm._v(" Hinzufügen ")]
          )
        ],
        1
      ),
      _c("div", [
        _vm._v(
          " Diese Person/en ist/sind von mir (uns) angewiesen worden, Ihnen alle gewünschten Auskünfte und Nachweise richtig und vollständig zu geben. "
        )
      ]),
      _c("hr"),
      _c("div", { staticClass: "font-strong" }, [
        _vm._v(" Bücher, Schriften und sonstige Unterlagen ")
      ]),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Im Rahmen der geforderten Buchführung werden:",
          values: _vm.vertragWPValues.buchfuehrungEdv
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.edvModus,
          callback: function($$v) {
            _vm.$set(_vm.form, "edvModus", $$v)
          },
          expression: "form.edvModus"
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "bestaendePreviewData",
          attrs: {
            modalTitle: "Wirtschaftsprüfung Kontrolle - Bestände",
            size: "xl",
            showDefaultButtons: false
          }
        },
        [
          _c(
            "div",
            [
              _vm.bestaendePreview.length
                ? _c("Table", {
                    attrs: {
                      headers: _vm.bestaendePreviewHeaders,
                      rows: _vm.bestaendePreview,
                      rowId: "kundennr",
                      hidePagination: ""
                    }
                  })
                : _c("NoData")
            ],
            1
          )
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "maklerPreviewData",
          attrs: {
            modalTitle: "Wirtschaftsprüfung Kontrolle - Vermittlernummern",
            size: "xl",
            showDefaultButtons: false
          }
        },
        [
          _c(
            "div",
            [
              _vm.maklerPreview.length
                ? _c("Table", {
                    attrs: {
                      headers: _vm.maklerPreviewHeaders,
                      rows: _vm.maklerPreview,
                      rowId: "maklernr",
                      hidePagination: ""
                    }
                  })
                : _c("NoData")
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }