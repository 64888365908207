<template>
  <div class="contact_container" :key="formKey">
    <div class="contact_wrapper">
      <div class="banner">IHR KONTAKT ZU UNS</div>
      <hr />
      <div class="zweispalte">
        <div>
          <div
            class="question_title"
          >Sie haben Fragen oder wünschen eine persönliche Beratung?</div>
          <div class="zweifields">
            <div>
              <InputField v-model="credentials.nachname" label="Name: " ref="nachnameInp"/>
              <InputField v-model="credentials.vorname" label="Vorname: " ref="vornameInp" />
            </div>
            <div>
              <InputField v-model="credentials.email" label="E-Mail: " ref="emailInp" />
              <InputField v-model="credentials.telefon" label="Telefon: " ref="telefonInp" inputmode="tel"/>
            </div>
          </div>
          <div>
            <div class="nachricht_label">Nachricht:</div>
            <InputTextArea v-model="content.inhalt" :rows="3" />
          </div>
          <div class="zweifields mt-3">
            <CaptchaInputField v-model="credentials.captcha" />
          </div>
          <div class="checkbox_plus_label">
            <InputCheckBoxItem v-model="content.readDatenschutz" />
            <span style="display:inline-block; width: .3em;"></span>
            <div>
              Ich stimme den
              <router-link to="datenschutz">Datenschutzbestimmungen</router-link> zu.
            </div>
          </div>
          <div class="to_the_left">
            <BaseButton
              :disabled="sending || isFormInvalid"
              :animated="sending"
              @click="sendKontakt"
            >Absenden</BaseButton>
              <div class="fc-alert fc-alert-warning" v-if="isIntern">Die Sendung ist für die Intern deaktiviert.</div>
          </div>
        </div>
        <div class="makler_info">
          <MaklerInfo />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import InputCheckBoxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import MaklerInfo from "@/components/cms/parts/MaklerInfo.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import CMS_TYPES from "@/store/cms/types";
import CORE_TYPES from "@/store/core/types";
import validator from "@/mixins/validator";
import InputField from "@/components/core/forms/InputField.vue";
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import CaptchaInputField from '@/components/core/forms/CaptchaInputField.vue';

import {
  required,
  email,
  telefon,
  vorname,
  nachname
} from "@/mixins/validator/rules";
export default {
  components: {
    BaseButton,
    InputField,
    InputTextArea,
    MaklerInfo,
    InputCheckBoxItem,
    CaptchaInputField,
  },
  data() {
    return {
      sending: false,
      formKey: Date.now(),
      credentials: {
        vorname: null,
        nachname: null,
        email: null,
        telefon: null,
        captcha: null,
      },
      content: {
        inhalt: "",
        readDatenschutz: false
      },
    };
  },
  validators: {
    credentials: {
      vorname: [vorname()],
      nachname: [nachname()],
      email: [email()],
      telefon: [telefon()],
      captcha: [required('Captcha ist erforderlich!')],
    },
    content: {
      inhalt: [required()],
      readDatenschutz: [required()]
    }
  },
  mixins: [validator],
  computed: {
    ...mapGetters({
      getUserID: CMS_TYPES.GETTERS.GET_USER_ID,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    isFormInvalid() {
      return this.validation?.updated && (this.validation?.isInvalid('credentials') || this.validation?.isInvalid('content'));
    },
    buildUnloggedMessage() {
      return {
        messageText:
          this.credentials.nachname +
          " " +
          this.credentials.vorname +
          " wünscht eine Beratung!<br/>Telefon: " +
          this.credentials.telefon +
          "<br/>E-Mail: " +
          this.credentials.email +
          "<br/>Inhalt: <br/>" +
          this.content.inhalt,
        receiverId: this.getUserID,
        thema: "Kontakt von " + this.credentials.nachname,
        ...(this.credentials.captcha ?? {}),
      };
    }
  },
  methods: {
    async sendKontakt() {
      if (!this.isIntern){
        if (!this.credentials?.captcha?.captchaToken) {
          this.$pushErrors('credentials.captcha', 'Fehler: Captcha ist ungültig');
          return;
        }

        this.sending = true;
        this.$store
          .dispatch(CMS_TYPES.ACTIONS.SEND_KONTAKT, this.buildUnloggedMessage)
          .then(() => this.resetFields())
          .finally(() => this.sending = false);
      }
    },
    resetFields(){
      this.$set(this.credentials, 'vorname', null);
      this.$set(this.credentials, 'nachname', null);
      this.$set(this.credentials, 'email', null);
      this.$set(this.credentials, 'telefon', null);
      this.$set(this.credentials, 'captcha', null);

      this.$set(this.content, 'inhalt', null);
      this.$set(this.content, 'readDatenschutz', false);

      this.$nextTick(() => this.formKey = Date.now());
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style  scoped>
.contact_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}

.banner {
  margin-top: 2em;
  font-size: 26px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: left;
  color: var(--color-text);
}
a {
  color: var(--color-link);
}

.zweispalte {
  padding-top: 2em;
  padding-bottom: 3em;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1.5em;
}
.zweifields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5em;
}
.contact_content {
  margin: 0 auto;
  padding-top: 2em;
  padding-bottom: 2em;
  display: grid;
  gap: 4em;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "fondsshop_image fondsshop_text";
}
.question_title {
  text-align: left;
  font-weight: 700;
  padding-bottom: 1em;
}
.nachricht_label {
  text-align: left;
  font-weight: 700;
  padding-top: 1em;
}
.checkbox_plus_label {
  padding-top: 1em;
  display: flex;
}
.to_the_left {
  text-align: left;
  padding-top: 1em;
}


@media only screen and (max-width: 1024px) {
  .zweispalte {
    padding-top: 0px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .home_header_image {
    display: none;
  }
  .zweifields {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;
  }
  .makler_info {
    justify-self: left;
  }
}
</style>