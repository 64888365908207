<template>
  <div>
    <PageHeaderTitleNavigation 
      v-if="!hidePageHeader"
      :id="id" 
      :title="title" 
      :subtitle="subtitle" 
      :defaultMenu="defaultOptionsMenu" 
      :actions="headerActionsInternal" 
      :noPrimaryAction="noPrimaryAction"
      v-on="$listeners"
      @action-EDIT="openCardOverviewConfigModal()"
      @action="$emit('action', $event)">
      
      <template #title><slot name="title">{{ title }}</slot></template>
      <template v-if="$slots.subtitle" #subtitle><slot name="subtitle"></slot></template>
    </PageHeaderTitleNavigation>   


    <CardsOrganizer 
      :id="id" 
      :columns="columns" 
      @update-columns="$emit('updateColumns', $event)" 
      @draggingCard="$emit('draggingCard', $event)"  
      @droppedCard="$emit('droppedCard', $event)"
      @executeAction="$emit('executeAction', $event)"
      @remove="$emit('remove', $event)"
      >
      <template v-slot:card="{ card }">
        <template v-if="card.visible && !card.removed">
          <component ref="cardComponent" :is="card.component"  />
        </template>
      </template>
    </CardsOrganizer>

    <CardOverviewConfigModal ref="dashboardPanelConfigModal" :id="id" :cards="cards" >

    </CardOverviewConfigModal>

  </div>
</template>

<script>
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import CardsOrganizer from './CardsOrganizer.vue';
import Card from './Card.vue';
import CardOverviewConfigModal from './CardOverviewConfigModal.vue';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG from '@/configs/fcConfig.js';
import { mapGetters } from 'vuex';

export default {
  name: 'CardsOverview',
  components: {
    CardsOrganizer,
    Card,
    PageHeaderTitleNavigation,
    CardOverviewConfigModal,
  },
  props: {
    title: {
      type: String,
      default: 'Übersicht',
    },
    subtitle: {
      type: String,
    },    
    columns: {
      type: Array,
      required: true
    },
    hidePageHeader: {
      type: Boolean,
      default: false,
    },
    defaultOptionsMenu: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      required: true,
      validator: (value) => {
        const notEmpty = !!value && !!value.trim();
        return notEmpty;
      },
    },
    headerActions: {
      type: Array,
      default: () => [],
    },
    noPrimaryAction: {
      type: Boolean,
      default: true,
    },                
  },
  watch: {
    fcConfigCardsOverview: {
      handler(newValue) {
        // console.log("fcConfigCardsOverview", this.fcConfigCardsOverview);
  
        const config = newValue[this.id];
        const contentToParse = config?.content;
        if (contentToParse) {
          // console.log("contentToParse?.content", contentToParse?.content);
          this.cards = [...JSON.parse(contentToParse)];
        }

      },
      deep: true,


    },
  },    
  data() {
    return {
      draggedCard: null,
      isDragging: false,
      placeholder: null, // To track the placeholder position
      placeholderSize: { width: '0px', height: '0px' }, // To track the size of the placeholder
      cards: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      fcConfigCardsOverview: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_CARDS_OVERVIEW,
    }),    
    headerActionsInternal() {
      const actions = [];
      // if(this.isEditAvailable) {
        actions.push(PageHeaderSimpleAction('EDIT', 'Anpassen'));
      // }
      return [
        ...this.headerActions,
        ...actions,
      ];
    },   
  },
  methods: {
    async openCardOverviewConfigModal() {
      await this.loadConfig(true);
      this.$refs.dashboardPanelConfigModal.open();
    },
    async loadConfig(forceReload = false) {
      if (!this.id) {
        return ;
      }

      // console.log("loadConfig called");

      const payload = {
        configId: this.id,
        configType: FC_CONFIG.CARD_OVERVIEW_CONFIG,
        forceReload,
      };

      // console.log("loadConfig payload", payload);

      this.loading = true;
      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
      this.loading = false;
      // console.log("loadConfig finished", this.loading);
    },

  }
};
</script>

<style scoped>

</style>
