import OPEN_SIGNS_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  async [OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS]({ commit, getters }, params) {
    if (!params.keepMeta) {
      commit(OPEN_SIGNS_TYPES.MUTATIONS.RESET_OPEN_SIGNATURES);
    }
    delete params.keepMeta;

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/offene_unterschriften/getOpenSigns`, { ...config, params }).then(response => {
      if(response.status === 200) {
        commit(OPEN_SIGNS_TYPES.MUTATIONS.GET_OPEN_SIGNS_SUCCESS, response?.data || []);
      }
    });
  },

  [OPEN_SIGNS_TYPES.ACTIONS.EXPORT_OPEN_SIGNS]({ getters }, params) {
    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/offene_unterschriften/exportOpenSigns`, { ...config, params })
  },

  async [OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS_COUNT]({ commit, getters }) {

    // ignore call if it is already counting
    if(getters[OPEN_SIGNS_TYPES.GETTERS.IS_COUNTING]) return;

    // only set as counting if there is no value loaded
    const openSignatures = getters[OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS];
    if(openSignatures?.count === undefined) {
      commit(OPEN_SIGNS_TYPES.MUTATIONS.SET_IS_COUNTING, true);
    }

    const openSignUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/offene_unterschriften/getOpenSignsCountOnly`
    await axios.get(openSignUrl).then(response => {
      if(response.status === 200) {
        commit(OPEN_SIGNS_TYPES.MUTATIONS.SET_OPEN_SIGNS_COUNT, response?.data || 0)
      }
    })
    .finally(() => commit(OPEN_SIGNS_TYPES.MUTATIONS.SET_IS_COUNTING, false))
  },

  async [OPEN_SIGNS_TYPES.ACTIONS.GET_NEXT_PAGE_OPEN_SIGNATURES]({ commit, dispatch }) {
    await dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS, { keepMeta: true })
  },

  async [OPEN_SIGNS_TYPES.ACTIONS.GET_SIGNO_VIEWER_LINK_MAKER]({getters}, payload) {
    // return (rawViewFileId, rawNodeId) => {
      const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS]
      const token = getters[CORE_TYPES.GETTERS.GET_TOKEN]

      const viewFileId = encodeURIComponent(payload?.viewFileId);
      const nodeId = encodeURIComponent(payload?.nodeId);

      const params = { nodeId, viewFileId, token };
      try {
        const response = await axios.get(`${apiAddress}/offene_unterschriften/get_link_to_signo_viewer`, {
          ...config,
          params,
        });
        return response;
      }  catch (error) {
        return { error: error };
      }
      
    // }
  },

  async [OPEN_SIGNS_TYPES.ACTIONS.SUBMIT_DOCUMENT]({ getters }, params) {
    await axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/offene_unterschriften/submit_document`, null, { ...config, params });
  },

  async [OPEN_SIGNS_TYPES.ACTIONS.METADATA_SUBMIT_DOCUMENT]({ getters }, params) {
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/offene_unterschriften/metadata_submit_document`, { ...config, params });

      return response.data ?? {};
    } catch (e) {
      return {};
    }
  },

  async [OPEN_SIGNS_TYPES.ACTIONS.OPEN_SIGNATURES_REMOVE_VORLAGE]({ getters, commit, }, payload) {
    const params = {
      dokId: payload?.dokId,
      kundennr: payload.kundennr
    };

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/offene_unterschriften/removeVorlage`, null, { ...config, params });
    if (!response?.data?.errorStr) {
      commit(OPEN_SIGNS_TYPES.MUTATIONS.OPEN_SIGNATURES_REMOVE_VORLAGE_SUCCESS, payload.dokId);
      return null;
    } else {
      return response.data.errorStr;
    }
  },

  async [OPEN_SIGNS_TYPES.ACTIONS.GET_DOKUMENT_SIGNATUR_STORNOS_BESTAETIGT]({ getters }, dokId) {
    try {
      const dokUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/offene_unterschriften/dokument_signatur_stornos_bestaetigt/${dokId}`;
      const response = await axios.get(dokUrl, config);
      return response.data ? response.data : null;
    } catch(e) {
      // empty block
    }
  },

  async [OPEN_SIGNS_TYPES.ACTIONS.SAVE_DOKUMENT_SIGNATUR_STORNOS_BESTAETIGT]({ getters }, payload) {
    try {
      const dokUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/offene_unterschriften/dokument_signatur_stornos_bestaetigt/${payload.dokId}`;
      await axios.post(dokUrl, payload.dokStornos, config);
    } catch(e) {
      // empty block
    }
  },

  async [OPEN_SIGNS_TYPES.ACTIONS.GET_HAS_OPEN_SIGNS]({ getters, commit, }) {
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/offene_unterschriften/hasOpenSigns`);
      commit(OPEN_SIGNS_TYPES.MUTATIONS.SET_HAS_OPEN_SIGNS, response?.data);
    } catch(e) {
      // empty block
    }
  },

  // WARNING: Please never use this function in a loop. This calls the backend for a loop
  async [OPEN_SIGNS_TYPES.ACTIONS.GET_SIGNO_VIEWER_LINK]({state, getters}, payload) {
    const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS]

    const params = {nodeId: payload.nodeId, viewFileId: payload.viewFileId, noRedirect: true}

    const response = await axios.get(
        `${apiAddress}/offene_unterschriften/get_link_to_signo_viewer`,
        {
            ...{
                defaultSpinner: true,
            },
            params,
        },
    )
    
    return response.data
},

async [OPEN_SIGNS_TYPES.ACTIONS.CLEANUP_SIGNO_VIEWER]({state, getters}, payload) {
  const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS]

  const params = {nodeId: payload.nodeId, viewFileId: payload.viewFileId, noRedirect: true}

  const response = await axios.get(
      `${apiAddress}/offene_unterschriften/cleanup_signo_viewer`,
      {
          ...{
              defaultSpinner: true,
          },
          params,
      },
  )
  
  return response.data
},

async [OPEN_SIGNS_TYPES.ACTIONS.KEEP_ALIVE_SIGNO_VIEWER]({state, getters}, payload) {
  const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS]

  const response = await axios.get(
      `${apiAddress}/offene_unterschriften/keep_alive_signo_viewer`,
      {
          ...{
              defaultSpinner: true,
          },
      },
  )
  
  return response.data
},
}