var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ghost-loading__block",
      class:
        ((_obj = {}),
        (_obj["ghost-loading__block--" + _vm.type] = !!_vm.type),
        _obj),
      style: _vm.style
    },
    [
      _vm.type === _vm.TYPE_IMAGE
        ? _c("PhImage", { attrs: { size: 72, color: "#dddddd" } })
        : _vm.type === _vm.TYPE_TABLE_ROW
        ? _c("PhDotsThreeVertical", {
            attrs: {
              size: 24,
              color: "#dddddd",
              alt: "Icon für weitere Aktionen"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }