<template>
  <div>
    <BaseListWithSearch
      :componentIds="componentIds"
      :pageHeaderActions="actions"
      :pageHeaderActionListeners="pageHeaderActionListeners"
      :baseFilterConfigs="baseFilterConfigs"
      :baseFilterListeners="baseFilterListeners"
      :tableConfigs="tableConfigs"
      :tableListeners="tableListeners"
      :loading="loading"/>

    <BaseModal
      ref="modalImportProcess"
      modalTitle="Vorgang importieren"
      labelButtonConfirm="Importieren"
      :confirmDisabled="!isImportFormValid"
      @onConfirmButton="importProcess">

      <ComboBox
        label="Makler Externer Zugang"
        v-model="vorgangImport.maklerExtZugangId"
        :values="maklerExtZugangList"
      />

      <InputField
        label="VorgangsNr."
        v-model="vorgangImport.vorgangsNr"
      />
    </BaseModal>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import PROCESS_TYPES from '@/store/process/types';
import { mapGetters } from 'vuex';
import { ActionColumn, ConfirmedAction, SimpleAction, TextColumn } from '@/components/table2/table_util.js';
import dayjs from 'dayjs';
import InteractiveHelpCommonsMixin from '@/assets/mixins/interactivehelpcommonsmixins.js';
import { PageHeaderLoadingAction } from '@/components/core/header-title-navigation/page-header-utils';
import BaseListWithSearch from '@/components/base/BaseListWithSearch.vue'
import LOG_TYPES from "@/store/log/types";
import { buildMessage } from "@/helpers/log-message-helper";
import { openLink } from "@/link-resolvers";
import { ResolverId } from "@/link-resolvers/types";
import BaseModal from "@/components/core/BaseModal.vue"
import ComboBox from "@/components/core/forms/ComboBox.vue"
import InputField from "@/components/core/forms/InputField.vue"
import validator from "@/mixins/validator"
import { required } from "@/mixins/validator/rules"

const TABLE_TITLE = 'Vorgänge Liste';

export default {
  mixins: [InteractiveHelpCommonsMixin, validator],
  components: {
    BaseListWithSearch,
    BaseModal,
    ComboBox,
    InputField,
  },
  validators: {
    vorgangImport: {
      vorgangsNr: [required("VorgangsNr. ist erforderlich")],
      maklerExtZugangId: [required("Makler Externer Zugang ist erforderlich")],
    },
  },
  data() {
    return {
      TABLE_TITLE,
      defaultOptions: {
        LETZTEN_3_MONATEN: {
          value: true,
        },
      },
      filter: {},
      loading: {
        create: false,
        list: false,
      },
      vorgangImport: {
        maklerExtZugangId: null,
        vorgangsNr: '',
      }
    };
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE,
      processList: PROCESS_TYPES.GETTERS.PROCESS_LIST,
      maklerExtZugangList: PROCESS_TYPES.GETTERS.ZUGANG_LIST,
    }),
    isImportFormValid() {
      return this.validation.updated && this.validation.isValid('vorgangImport');
    },
    pageHeaderActionListeners() {
      return {
        'action-CREATE': this.createProcess,
        'action-IMPORT': this.openImportProcessModal,
        'action-EUROPACE2_LOGIN': this.openEuropaceLogin,
      }
    },
    componentIds() {
      return {
        filterId: '418b46b3-32f0-428a-aae7-28e7836990aa',
        tid: 'd379db51-d390-4c34-8801-616bbe563e4e',
        tableId: '51e939da-3b1e-48ed-8141-9812fe1cae1c',
      }
    },
    baseFilterConfigs() {
      return {
        showSaveButton: this.isBroker,
        title: "Vorgänge filtern",
        saveKey: this.isBroker ? 'processSearchAdvanced' : null,
        metadata: this.filterMetadata,
        hasSmartSearch: this.isBroker,
        immidiateSearch: true,
        defaultOptions: this.isBroker ? this.defaultOptions : {},
      }
    },
    tableConfigs() {
      return {
        title: TABLE_TITLE,
        headers: this.headers,
        rows: this.rows,
        rowsPerPage: 20,
        exportConfig: { roottext: 'Vorgänge' },
      }
    },
    baseFilterListeners() {
      return {
        onFilter: this.filterForm
      }
    },
    tableListeners() {
      return {
        'clickRow': this.editProcess,
        'click-kundennr': this.editProcess,
        'click-description': this.editProcess,
        'action-OPEN_IN_EUROPACE': this.openProcessInEuropace,
        'action-DELETE': this.deleteProcess,
        'action-SYNCHRONIZE': this.synchronizeProcess,
      }
    },
    actions() {
      return [
        PageHeaderLoadingAction('CREATE','Neu Vorgang',),
        PageHeaderLoadingAction('IMPORT','Vorgang importieren',),
        PageHeaderLoadingAction('EUROPACE2_LOGIN','Europace öffnen',),
      ];
    },
    filterMetadata() {
      let result = [
        {
          type: 'group',
          key: 'abDatum',
          label: 'Ab Datum',
          menuItems: [
            {
              type: 'default',
              label: 'Alle',
              key: 'VALUE_ALLE',
            },
            {
              type: 'default',
              label: 'letzte 3 Monate',
              key: 'LETZTEN_3_MONATEN',
            },
            {
              type: 'dateRange',
              label: 'Zeitraum',
              key: 'ZEITRAUM',
            },
            {
              type: 'integer',
              label: 'Jahr',
              key: 'jahr',
            },
          ],
        },
        {
          type: 'group',
          key: 'fehlerstatus',
          label: 'Fehlerstatus',
          menuItems: [
            {
              type: 'default',
              label: 'Alle',
              key: 'VALUE_ALLE',
            },
            {
              type: 'default',
              label: 'nur fehlerfreie',
              key: 'VALUE_FEHLER_OK',
            },
            {
              type: 'default',
              label: 'nur fehlerhafte',
              key: 'VALUE_FEHLER_ROT',
            },
          ],
        },
        
      ];

      if (this.isBroker) {
        result.push({
          type: 'group',
          key: 'allgemein',
          label: 'Kunde',
          menuItems: [
            {
              type: 'text',
              label: 'Name',
              key: 'lastName',
            },
            {
              type: 'text',
              label: 'Kundennummer',
              key: 'customerId',
              inputmode: 'numeric',
            },
            {
              type: 'text',
              label: 'Vorname',
              key: 'firstName',
            },
          ],
        });
      }

      return result;
    },
    rows() {
      if (!this.processList) return [];
      return this.processList.map((item) => {
        const deleteMessage = `Soll die Vorgang wirklich gelöscht werden?`
        const synchronizeMessage = `Möchten Sie die Vorgang synchronisieren?`
        const actions = [
          SimpleAction('OPEN_IN_EUROPACE', 'PhArrowSquareOut', 'Vorgang in Europace öffnen'),
          ConfirmedAction('DELETE', 'PhTrash', 'Löschen', deleteMessage, 'Vorgang löschen', 'Löschen'),
          ConfirmedAction('SYNCHRONIZE', 'PhArrowsClockwise', 'Synchronisieren', synchronizeMessage, 'Vorgang synchronisieren', 'Synchronisieren'),
        ];

        return {
          id: item.id,
          kundennr: item.kundennr,
          description: item.description,
          dateCreated: item.dateCreated ? dayjs(item.dateCreated).format('DD.MM.YYYY') : '',
          vorgangsNr: item.vorgangsNr,
          actions,
        };
      });
    },
    headers() {
      const lockedLeft = [];

      if (this.isBroker) {
        lockedLeft.push(TextColumn('kundennr', 'Kunde').makeLink());
        lockedLeft.push(TextColumn('description', 'Beschreibung'));
      } else {
        lockedLeft.push(TextColumn('description', 'Beschreibung').makeLink());
      }

      lockedLeft.push(TextColumn('dateCreated', 'Datum'));

      return {
        lockedLeft,
        lockedRight: [ActionColumn('actions', 'Aktionen')],
      };
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu den Beratungsmappen',
      to,
      from,
    });

    next();
  },
  methods: {
    async retrieveProcessList() {
      try {
        this.loading.list = true;
        this.$store.commit(PROCESS_TYPES.MUTATIONS.RESET_STATE);
        await this.$store.dispatch(PROCESS_TYPES.ACTIONS.GET_PROCESS_LIST);
      } finally {
        this.loading.list = false;
      }
    },
    editProcess(data) {
      this.$router.push({
        path: '/beratung/processes/form',
        query: {
          id: data.id,
        },
      });
    },
    createProcess() {
      this.$router.push({
        path: '/beratung/processes/create'
      });
    },

    openImportProcessModal() {
      this.$refs.modalImportProcess.open();
    },

    async importProcess() {
      if (!this.vorgangImport.maklerExtZugangId || !this.vorgangImport.vorgangsNr) {
        return;
      }
      try {
        const process = await this.$store.dispatch(PROCESS_TYPES.ACTIONS.IMPORT_PROCESS, this.vorgangImport);
        if (process.id) {
          this.editProcess(process);
        }
      } catch (e) {
        const message = buildMessage('Vorgang importieren nicht möglich', 'danger');
        return this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, message);
      }
    },

    async deleteProcess(row) {
      await this.$store.dispatch(PROCESS_TYPES.ACTIONS.DELETE_PROCESS, row.id);
      await this.retrieveProcessList();
    },

    async loadAuxiliarData() {
      await Promise.all([
        this.$store.dispatch(PROCESS_TYPES.ACTIONS.GET_ZUGANG_LIST)
      ])
    },

    loadInitialData() {
      this.loadAuxiliarData();
    },

    async synchronizeProcess(row) {
      if (!row.vorgangsNr) {
        const message = buildMessage('Der Vorgang hat keinen Vorgangs-Nr', 'danger');
        return this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, message);
      }

      const success = await this.$store.dispatch(PROCESS_TYPES.ACTIONS.SYNCHRONIZE_PROCESS, row.id);

      if (success) {
        const message = buildMessage('Der Vorgang wurde erfolgreich synchronisiert', 'success');
        return this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, message);
      }
    },

    openProcessInEuropace(row) {
      if (!row.vorgangsNr || !row.id) {
        const message = buildMessage('Der Vorgang hat keinen Vorgangs-Nr', 'danger');
        return this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, message);
      }
      openLink(ResolverId.Europace2Vorgang, { processId: row.id });
    },

    openEuropaceLogin() {
      openLink(ResolverId.Europace2Vorgang, { processId: null });
    },

    filterForm(filterConfig) {
      const kunde = filterConfig.filter((fc) => fc.group === 'allgemein');
      let kundeKey = null;
      let customerId = null;
      let firstName = null;
      let lastName = null;

      if (kunde) {
        kundeKey = filterConfig.find((fc) => fc.key === 'customerId');
        if (kundeKey) {
          customerId = kundeKey.value;
        }
        kundeKey = filterConfig.find((fc) => fc.key === 'firstName');
        if (kundeKey) {
          firstName = kundeKey.value;
        }
        kundeKey = filterConfig.find((fc) => fc.key === 'lastName');
        if (kundeKey) {
          lastName = kundeKey.value;
        }
      }

      const abDatum = filterConfig.find((fc) => fc.group === 'abDatum');

      let fromDate = null;
      let toDate = null;

      if (abDatum && abDatum.key === 'ZEITRAUM') {
        fromDate = abDatum.value.find((v) => v.key === 'min').value;
        toDate = abDatum.value.find((v) => v.key === 'max').value;
      }

      if (abDatum && abDatum.key === 'LETZTEN_3_MONATEN') {
        fromDate = dayjs()
          .subtract(3, 'month')
          .format('DD.MM.YYYY');
      }

      let filterDateJahr = null;

      if (abDatum && abDatum.key === 'jahr') {
        filterDateJahr = abDatum.value;
      }

      const filterModusDatum = filterConfig.find(
        (fc) => fc.group === 'abDatumIst'
      );
      const filterFehler = filterConfig.find(
        (fc) => fc.group === 'fehlerstatus'
      );
      let filterStatus = filterConfig.find((fc) => fc.group === 'abgeschlossen');
      const filterMitScan = filterConfig.find(
        (fc) => fc.group === 'hinterlegtenOrderdokumenten'
      );
      const ort = filterConfig.find((fc) => fc.group === 'ort');

      if (this.$route.query && this.$route.query.investementAdviceReferrer) {
        filterStatus = 'VALUE_STATUS_OFFEN';
      }

      this.filter = {
        firstName,
        lastName,
        filterKundennr: customerId,
        filterDateVon: fromDate,
        filterDateBis: toDate,
        filterModusDatum: filterModusDatum && filterModusDatum.key,
        filterWithScan: filterMitScan && filterMitScan.value,
        filterFehler: filterFehler && filterFehler.key,
        filterStatus: filterStatus && filterStatus.key,
        filterDateJahr: filterDateJahr,
        filterOrt: ort && ort.key,
      };

      this.retrieveProcessList();
    },
  },
  mounted() {
    this.loadInitialData();
  }
};
</script>

<style scoped></style>
