var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "trigger",
      staticClass: "dropdown-trigger",
      style: _vm.getStyle(_vm.selectedOption),
      on: { click: _vm.toggleDropdown }
    },
    [
      _c(
        "span",
        { attrs: { title: _vm.selectedOption.label || "Auswählen" } },
        [_vm._v(_vm._s(_vm.selectedOption.label || "Auswählen"))]
      ),
      _c("PhCaretDown", { attrs: { size: 12 } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }