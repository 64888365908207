<template>
  <div class="base-content-menu__container">
    <BaseDropdownMenu ref="contextMenuDropdown" placement="bottom-right" :disabled="disabled" :containerClass="parsedContainerClass">
      <template v-slot:hook-target>
        <button type="button" class="btn-clear base-content-menu--btn" :id="'base__context__menu__'+_generateTidFromString(tid + '...')" :tid="_generateTidFromString(tid + '...')" :alt="ariaDescription">
          <PhDotsThreeVertical class="clickable" :size="iconSize" weight="bold" :alt="ariaDescription"/>
        </button>
      </template>
      <template #default>
        <div class="base-content-menu__content">
          <slot />
        </div>
      </template>
    </BaseDropdownMenu>
  </div>
</template>

<script>
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';
import { PhDotsThreeVertical, } from 'phosphor-vue';

import InteractiveHelpCommonsMixin from '@/assets/mixins/interactivehelpcommonsmixins';

export default {
  name: 'BaseContextMenu',
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    tid: {
      type: String,
    },
    title: {
      type: String,
      default: '',
    },
    containerClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    ariaDescription: {
      type: String,
      default: 'Icon für weitere Aktionen'
    },
  },
  computed: {
    parsedContainerClass() {
      return `${this.containerClass} base-content-menu__dropdown-container`;
    },
    iconSize() {
      return this.$isSmallScreen ? 24 : 20;
    },
  },
  methods: {
    close() {
      const { contextMenuDropdown, contextMenuModal, } = this.$refs;
      contextMenuDropdown?.close();
      contextMenuModal?.close();
    },
    autoCloseModal($event) {
      const { contextMenuModal = {}, } = this.$refs;
      const notCloseEl = $event.target.closest('[data-dropdown-not-close]') || $event.target;
      if('close' in contextMenuModal && !notCloseEl?.hasAttribute('data-dropdown-not-close')) {
        setTimeout(contextMenuModal.close);
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.close);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.close);
  },
  components: {
    BaseDropdownMenu,
    PhDotsThreeVertical,
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.base-content-menu__container {
  flex: 0 0 auto;
  width: 20px;
}

@media screen and (max-width: 767px) {
  .base-content-menu__container {
    width: 24px;
  }
}
</style>

<!-- GLOBAL STYLE -->
<style lang="scss">
.base-content-menu__dropdown-container {
  min-width: 240px;
}

.base-content-menu__content {
  display: flex;
  flex-direction: column;
  padding: 0;
  max-width: 320px;

  button, a, .input-forms__container {
    text-align: left !important;
  }

  .layout__negative-margin--8 {
    margin: 0;

    > * {
      margin: 0;
    }
  }

  hr {
    margin: 0 0 8px 0;
  }

  * + hr {
    margin-top: 8px;
  }
}

@media screen and (min-width: 768px) {
  .base-content-menu__dropdown-container {
    &.positioned-bottom-left,
    &.positioned-top-left {
      margin-left: -16px;
    }

    &.positioned-bottom-right,
    &.positioned-top-right {
      margin-left: 16px;
    }
  }
}

@media screen and (max-width: 767px) {
  .base-content-menu__dropdown-container {
    &::after { /** hides arrow */
      display: none;
    }
  }

  .base-content-menu__content {
    padding: 6px 0;
  }
}

@media screen and (max-width: 420px) {
  .base-content-menu__content {
    max-width: 258px;
  }
}

@media screen and (max-width: 380px) {
  .base-content-menu__content {
    max-width: 238px;
  }
}

@media screen and (max-width: 240px) {
  .base-content-menu__dropdown-container {
    min-width: 100vw;
  }
}
</style>
