import VERSICHERUNG_TYPES from  '@/store/versicherungen/types';
import CORE_TYPES from '@/store/core/types';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import CALENDAR_TYPES from "@/store/calendar/types";
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from "@/helpers/log-message-helper";
import BIPRO_TYPES from '@/store/bipro/types';
import { ROLES, VIEW_ROLES } from '@/router/roles.js'
import { mapGetters } from "vuex";
import EVENT_BUS, { REFRESH_INSURANCE_TABLE } from '@/event-bus';

export default {
  data() {
    return {
        selectedItem: null,
        sortByColumn: {},
        selectedRows: [],
        selectionAsBlacklist: false,
        ignoreAddBreadcrumb: false,
        isLoading: false,
        selectedRowsValues: [],
        currentRow: {},
        backAction: {},
        attachCustomer: true,
        currentPage: 0,
    }
  },
    computed: {
        ...mapGetters({
          hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
          maklerVollmacht: VERSICHERUNG_TYPES.GETTERS.MAKLER_VOLLMACHT,
          originalToken: CORE_TYPES.GETTERS.ORIGINAL_TOKEN,
        }),      
        allowVersUpload() {
          return this.isViewCustomerAsBypass || this.hasRoles([ROLES.KUNDE_APP_ALLOW_VERS_UPLOAD]);
        },
        isViewCustomerAsBypass() {
          return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]);
        },                  
        insurancesCount() {
            return this.insurancesFiltered?.count || 0;
        },
        pages() {
            return this.insurancesFiltered?.pages || {};
        },
        pageCount() {
            if (this.rowsPerPage)
                return Math.ceil(this.insurancesCount / this.rowsPerPage);
            else
                return 1;
        },
        numberSelected() {
            if (this.selectionAsBlacklist)
                return this.insurancesCount - this.selectedRows.length;
            else
                return this.selectedRows.length;
        },
        allSelectedLoaded() {
            if (this.selectionAsBlacklist) {
                return Object.keys(this.pages).length == this.pageCount;
            } else {
                return true;
            }
        },
        selectedAndLoaded() {
            if (this.selectionAsBlacklist) {
                // invert the selection
                return Object.values(this.pages)
                .flat()
                .filter(row => !this.selectedRows.some(selected => selected.id == row.id));
            } else {
                return this.selectedRows;
            }
        },
        computedBreadcrumb() {

          let sections = [];

          if (this.selectedItem?.kategorieName) {
            sections.push(this.selectedItem?.kategorieName);
          }

          if (this.selectedItem?.bezeichnung) {
            sections.push(this.selectedItem?.bezeichnung);
          }


          return sections.join(" | ");
        }
    },
  methods: {
    async requestPage(pageId, force = false) {
        if (!force && this.pages[pageId])
            return;

        const params = {
            ...this.filtersObj,
            section: pageId + 1,
            maxCount: this.rowsPerPage,
        };
        if (this.filterType) {
            params.filterType = this.filterType;
        }
        if(this.sortByColumn){
            Object.assign(params, this.sortByColumn);
        }

        try {
          this.currentPage = pageId
          this.isLoading = true;
          await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_INSURANCES_FILTERED, {
              ...params,
              kuendigungsEmail: true,
          });
        } finally {
          this.isLoading = false;
        }
    },
    sortDataByColumn(event){
        if(event?.sortDirection && event.key) {
          this.sortByColumn = event;
          this.requestPage(0, true);
          return;
        }
        this.sortByColumn = {}
    },
    onSelect(rows) {
        this.selectedRows = rows;
    },
    async storeSelectedAndKundbar() {
        if (this.selectionAsBlacklist) {
            for (let i = 0; i < this.pageCount; i++) {
                if (!this.pages[i])
                    await this.requestPage(i);
            }
        }
        return this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
            data: this.selectedAndLoaded.map(v => v.id)
        });
    },
    openItemSelectedDetail(itemSelected) {
      this.selectedItem = itemSelected;
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.CLEAR_INSURANCE_DETAILS);
      if(this.isOpenInNewTab) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, 
          { customerId: itemSelected.kundennr, insurances: true, path: `/home/versicherungen/insurance-group/details/${itemSelected.id}` });
      } else {
        this.$router.push(`/home/versicherungen/insurance-group/details/${itemSelected.id}`);
      }
    },
    openItemSelectedInNewTab(itemSelected) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
        customerId: itemSelected.kundennr, 
        insurances: true, 
        path: `/home/versicherungen/insurance-group/details/${itemSelected.id}`,
        originalToken: this.originalToken
      });
    },
    openItemSelectedOverview(itemSelected) {
      this.selectedItem = itemSelected;
      if(this.isOpenInNewTab) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, 
          { customerId: itemSelected.kundennr, insurances: true, path: `/home/versicherungen/insurance-group/overview/${itemSelected.id}` });
      } else {
        this.$router.push(`/home/versicherungen/insurance-group/overview/${itemSelected.id}`);
      }
    },    
    openAddVersicherungPage(tempFiles) {
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_TEMP_FILES, tempFiles);
      this.$router.push({path: '/home/versicherungen/auswahl-vertragsart'})
    },
    handleRowFiles(event) {
      const tempFiles = event.files;
      const insurance = event.row;
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_TEMP_FILES, tempFiles);
      this.openItemSelectedDetail(insurance);
    },
    async handleInsuranceAction({key, row}){
        this.currentRow = row;
        if(key === 'DELETE') {
            this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.CAN_DELETE_VERTRAG, row.id).then(response => {
                if (!Object.keys(response).length) {
                  this.openModalDeleteInsurance(row);
                } else if (response.errorMessage) {
                  this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.errorMessage, 'danger'))
                } else if (response.confirmationMessage) {
                  this.openModalDeleteInsurance(row, response.confirmationMessage);
                }
            });
          
        } else if(key === 'KUNDIGUNG') {
            await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_MAKLER_VOLLMACHT,
              {
                versVertrag: row?.id,
              }
            );


            this.selectionAsBlacklist = false;
            this.onSelect([row]);
            if(!this.maklerVollmacht?.hasMaklerVollmachtDocuments) {
              this.$confirmModal({
                message: 'Für die Nutzung des Kündigungstool wird eine Maklervollmacht benötigt. Bitte hinterlegen Sie eine unterzeichnete Maklervollmacht beim Kunden.',
                title: 'Hinweis',
                labelButtonConfirm: 'ok'
              });
              return;
            }
            this.handleMailComposer();
        } else if(key === 'ADD_ACTIVITY') {
            this.selectionAsBlacklist = false;
            this.onSelect([row]);
            this.handleAddActivity(row);
        } else if(key === 'EMAIL_GESELLSCHAFT') {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
                type: 'mailcomposer-insurance',
                action: 'clear',
              });
              this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
                type: 'mailcomposer-insurance',
                action: 'clear',
              });
              this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_INIT, {
                insurance: row.id,
                toCompany: true
              }).then(response => {
                if (response?.data?.participants && response?.data?.participants?.length) {
                  this.$refs.mailContacts.open();
                } else {
                  this.direktInDieMailSpringen();
                }
              });
        } else if(key === 'EMAIL_KUNDE') {
              this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
                  type: 'mailcomposer-insurance',
                  action: 'clear',
                });
                this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
                  type: 'mailcomposer-insurance',
                  action: 'clear',
                });
                this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_INIT, {
                  insurance: row.id,
                  toCompany: false
                }).then(response => {
                  this.direktInDieMailSpringen(false, response?.data?.participants);
                });              
        } else if (key === 'EMAIL_BRIEF') {
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
            type: 'mailcomposer-brief-insurance',
            action: 'clear',
          });
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
            type: 'mailcomposer-brief-insurance',
            action: 'clear',
          });
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, null);
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE, null);
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
            data: [row.id],
            toCompany: false
          });
          this.$router.push('/communication/mailcomposer-brief-insurance');
        } else if(key === 'SCHADENMELDEN') {
          const schadensmeldungURL = `/home/versicherungen/insurance-group/schadensmeldung/${row.id}/neuesSchaden`;
          if(this.isOpenInNewTab) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: row.kundennr, insurances: true, path: schadensmeldungURL });
          } else {
            this.$router.push(schadensmeldungURL);
          }
        } else if(key === 'Deeplink') {
          this.$refs.showDeeplinksModal.open({vertragId: row.id});
        } else if(key === 'OLD-POLICE-NUMBERS') {
          this.$refs.showOldPoliceNumbersModal.open({vertragId: row.id});
        } else if(key === 'VERTRAG_VEREINIGEN') {
          this.$refs.vertragVereinigenModal.open({bezeichnung: row.bezeichnung, vertragId: row.id, polNr: row.nummer});
        } else if(key === 'DECKBLATT') {
          const deckblatURL = `/home/versicherungen/insurance-group/deckblatt/${row.id}`;
          if(this.isOpenInNewTab) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: row.kundennr, insurances: true, path: deckblatURL });
          } else {
            this.$router.push(deckblatURL);
          }
        } else if (key == 'ZUORDNEN_AKTION') {
          this.$store.commit(BIPRO_TYPES.MUTATIONS.PARAM_RETURN_VERS_VERTRAG, row?.id);
          this.$router.go(-1);
        } else if(key === 'VERTRAG_OFFNEN') {
          this.openItemSelectedDetail(row)
        } else if(key === 'OPEN-IN-NEW-TAB') {
          this.openItemSelectedInNewTab(row)
        } else if (this.backAction) {
            const action = this.backAction.action;
            if (action?.actionKey == key) {
                this.$router.push({
                    path: this.backAction.url,
                    query: {
                        insurance: row,
                    }
                });
            }
        }
    },
    async direktInDieMailSpringen(toCompany = true, participants) {
      await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_MAKLER_VOLLMACHT,
        {
          versVertrag: this.currentRow.id,
        }
      );

      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
        data: [this.currentRow.id],
        hasMaklerVollmacht: this.maklerVollmacht?.hasMaklerVollmachtDocuments,
        maklerVollmacht: this.maklerVollmacht?.maklerVollmacht,
        toCompany,
        participants: participants || this.selectedRowsValues.map(value => {
          const {index, ...row} = value;
          return row
        }) || []
      });
      this.$router.push(`/communication/mailcomposer-insurance`);
    },
    confirmContactsToSend() {
      this.$refs.mailContacts.close();
      this.direktInDieMailSpringen();

    },
    openModalDeleteInsurance(insurance, message) {
      this.insuranceToDelete = insurance;
      this.$refs.deleteModal.open(message)
    },
    removeInsurance() {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.DELETE_VERTRAG, this.insuranceToDelete.id)
        .then(() => {
          EVENT_BUS.$emit(REFRESH_INSURANCE_TABLE)
        })
      
    },
    getInsuranceLabel(insurance) {
      return insurance?.description?.label
    },
    handleAddActivity(row) {
      this.attachCustomer = false;
      
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aufgabe bearbeiten',
        mode: 'Aufgabe',
        subject: `${row.gesellschaft?.shortName || ''} ${(row.nummer || row.bezeichnung) && `(${row.nummer || row.bezeichnung})`}`,
        status: 'OFFEN',
        bereich: 'VersVertrag',
        vsnr: this.nummer,
        bereichId: row.id,
        bereichText: `Versicherung ${row.nummer || row.bezeichnung} | ${row.bezeichnung} | ${row.kundeVorname || ''} ${row.kundeName || ''}`,
        discardChanges: this.resetAppointment,
        isActivity: this.isActivity,
        attachCustomer: this.attachCustomer,
        back: this.$router.currentRoute.fullPath,
      });

      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: true});
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_ACTIVITY);
      this.$router.push({ path: '/communication/appointment' });
    },
    handleMailComposer() {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
        data: [this.currentRow.id],
        hasMaklerVollmacht: true
      });


      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: 'mailcomposer-insurance-cancel',
        action: 'clear',
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: 'mailcomposer-insurance-cancel_one',
        action: 'clear',
      });
     
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE, null);
      
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
       type: 'mailcomposer-insurance-cancel_one',
       action: 'clear',
       });
       
       this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: 'mailcomposer-insurance-cancel',
        action: 'clear',
        });
       
       
       this.$router.push(`/communication/mailcomposer-insurance-cancel_one`);



    },    
  },
  beforeRouteLeave(to, from, next) {

    if (this.ignoreAddBreadcrumb==false) {
      this.$addBreadcrumb({
        label: 'zurück zu Versicherungen', 
        to,
        from,
        breadcrumb: this.computedBreadcrumb,
      });

    }

    next();
  },
}
