import axios from 'axios';
import MENU_STRUCTURE_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG, { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig';

import { _createRouter } from '@/router';
import { createAppMenu } from '@/menu';

const config = { defaultSpinner: true, };

export default {

  async [MENU_STRUCTURE_TYPES.ACTIONS.FIND_MENU_STRUCTURES]({ getters, commit }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/menu_structure`, config);
    commit(MENU_STRUCTURE_TYPES.MUTATIONS.SET_MENU_STRUCTURES, [ ...response?.data || [] ]);
  },

  async [MENU_STRUCTURE_TYPES.ACTIONS.SETUP_MENU_STRUCTURE_CONTEXT]({ getters, commit }, { id }) {
    if (!id) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/menu_structure/${id}`, config);
    const menuStructure = response?.data || {};

    const configContext = menuStructure.context;
    const createAppMenuOptions = {
      configContext, 
      disableResolve: true, 
      withEmptyGroups: true, 
    }

    const roles = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_ROLES](configContext);
    const router = _createRouter(roles);

    // defaultMenu
    const appMenuDefault = await createAppMenu(router, createAppMenuOptions);
    const { flatMenu: flatMenuDefault } = appMenuDefault;

    // configuredMenu
    const appMenuConfigured = await createAppMenu(router, { 
      ...createAppMenuOptions,
      menuStructure,
    });
    const mapOnlyDefaultMenu = (menu) => {
      if (menu.isDefaultMenu) { // TODO refactor "isDefaultMenu" flag
        return {
          ...menu,
          subMenu: menu.subMenu.map(mapOnlyDefaultMenu).filter(m => m != null),
        };
      }
      return null;
    };
    const flatMenuConfigured = appMenuConfigured.flatMenu
      .map(mapOnlyDefaultMenu)
      .filter(m => m != null);

    commit(MENU_STRUCTURE_TYPES.MUTATIONS.SET_MENU_STRUCTURE_CONTEXT, {
      menuStructure, 
      flatMenuConfigured, 
      flatMenuDefault, 
    });
  },

  async [MENU_STRUCTURE_TYPES.ACTIONS.SAVE_MENU_STRUCTURE]({ getters }, payload) {
    if (!payload?.label) return;
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/menu_structure`, payload, config);
    return response?.data || {};
  },

  async [MENU_STRUCTURE_TYPES.ACTIONS.REMOVE_MENU_STRUCTURE]({ getters }, { id }) {
    if (!id) return;
    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/menu_structure/${id}`, config);
  },

  async [MENU_STRUCTURE_TYPES.ACTIONS.SAVE_OPTIONS_MENU]({ getters }, { menuStructureId }) {
    try {
      const hasEdited = getters[MENU_STRUCTURE_TYPES.GETTERS.HAS_MENU_STRUCTURE_EDITED];
      if (!menuStructureId || !hasEdited) return;

      const makeFlatMenu = (menuList, menu, parent) => {
        return [
          ...menuList,
          {
            ...menu,
            parent,
          },
          ...menu.subMenu.reduce((ml, sm) => makeFlatMenu(ml, sm, menu.path), []),
        ];
      };

      const { primaryMenu, menuStructure } = getters[MENU_STRUCTURE_TYPES.GETTERS.MENU_STRUCTURE_CONTEXT];
      const flatMenuLocal = primaryMenu.reduce((ml, m) => makeFlatMenu(ml, m, null), []);
      const { optionsMenu : optionsMenuSaved } = menuStructure;
      const optionsMenu = flatMenuLocal.map((menu, index) => {
        const menuSaved = optionsMenuSaved.find(m => m.path === menu.path && m.parent === menu.parent);
        return {
          ...(menuSaved ?? {}),
          path: menu.path,
          parent: menu.parent,
          label: menu.label,
          icon: menu.componentName,
          type: menu.type,
          position: index,
        };
      });

      const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/menu_structure/${menuStructureId}/options_menu`, optionsMenu, config);
      return response?.data || {};
    } catch(e) {
      // empty block
    }
  },

  async [MENU_STRUCTURE_TYPES.ACTIONS.FIND_MENU_STRUCTURE_GLOBAL_CONFIG]({ getters, commit }) {
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/menuConfig/menu_structure_global_config`, config);
      commit(MENU_STRUCTURE_TYPES.MUTATIONS.SET_MENU_STRUCTURE_CONFIGS, [ ...response?.data ]);
    } catch(e) {
      // empty block
    }
  },

  async [MENU_STRUCTURE_TYPES.ACTIONS.SAVE_MENU_STRUCTURE_GLOBAL_CONFIG]({ getters, state, dispatch, commit }) {
    const hasEdited = getters[MENU_STRUCTURE_TYPES.GETTERS.HAS_MENU_STRUCTURE_CONFIGS_EDITED];
    if (!hasEdited) return;

    const { menuStructureConfigsEdited } = state;
    const savePayloads = menuStructureConfigsEdited
      .filter(config => config.maklernr)
      .map(config => {
        const fcConfigs = [];

        if (config.menuStructure) {
          fcConfigs.push({ // Makler
            configId: FC_CONFIG.MENU_STRUCTURE_CONFIG, 
            configType: FC_CONFIG.MENU_STRUCTURE_CONFIG, 
            configContext: config.configContext, 
            userLevel: FC_CONFIG_USER_LEVEL.MAKLER,
            content: config.menuStructure,
          });
        }

        if (config.substructureMenuStructure) {
          fcConfigs.push({ // Makler
            configId: FC_CONFIG.MENU_STRUCTURE_CONFIG, 
            configType: FC_CONFIG.MENU_STRUCTURE_CONFIG, 
            configContext: config.configContext, 
            userLevel: FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR,
            content: config.substructureMenuStructure,
          });
        }

        return {
          maklernr: config.maklernr,
          fcConfigs,
        };
      });
    
    const deletePayloads = menuStructureConfigsEdited
      .filter(config => config.maklernr)
      .flatMap(config => {
        const fcConfigs = [];

        if (!config.menuStructure) {
          fcConfigs.push({ // Makler
            configId: FC_CONFIG.MENU_STRUCTURE_CONFIG, 
            configType: FC_CONFIG.MENU_STRUCTURE_CONFIG, 
            configContext: config.configContext, 
            userLevel: FC_CONFIG_USER_LEVEL.MAKLER, 
            maklernr: config.maklernr, 
          });
        }

        if (!config.substructureMenuStructure) {
          fcConfigs.push({ // Makler
            configId: FC_CONFIG.MENU_STRUCTURE_CONFIG, 
            configType: FC_CONFIG.MENU_STRUCTURE_CONFIG, 
            configContext: config.configContext, 
            userLevel: FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR, 
            maklernr: config.maklernr, 
          });
        }

        return fcConfigs;
      })

    await Promise.all([
      ...savePayloads.map(payload => dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_AS_INTERN, payload)), 
      ...deletePayloads.map(payload => dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_AS_INTERN, payload)), 
    ]);

    commit(MENU_STRUCTURE_TYPES.MUTATIONS.RESET_MENU_STRUCTURE_CONFIGS_EDITED);
  },

  async [MENU_STRUCTURE_TYPES.ACTIONS.REMOVE_MENU_STRUCTURE_GLOBAL_CONFIG]({ dispatch }, { maklernr, configContext }) {
    try {
      const payload = {
        configId: FC_CONFIG.MENU_STRUCTURE_CONFIG, 
        configType: FC_CONFIG.MENU_STRUCTURE_CONFIG, 
        configContext, 
        maklernr, 
      };
      await Promise.all([
        dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_AS_INTERN, { ...payload, userLevel: FC_CONFIG_USER_LEVEL.MAKLER }), 
        dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_AS_INTERN, { ...payload, userLevel: FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR }), 
      ]);
    } catch(e) {
      // empty block
    }
  },

}
