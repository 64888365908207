import axios from 'axios';

import { ResolverId } from '../types';
import { createLinkResolver, wrapUrl, wrapError } from '../utils';

export default createLinkResolver(ResolverId.Europace2Vorgang, async function({ apiAddress }, { processId }) {
    const findURL = async () => {
        try {
            let url = `${apiAddress}/process/deeplink`
            if (processId) {
                url = `${apiAddress}/process/${processId}/deeplink`
            }
            return await axios.get(url, { defaultSpinner: true });
        } catch(e) {
            return e.response;
        }
    };

    const response = await findURL();
    if(response?.data?.url) {
        return wrapUrl(response?.data?.url);
    } else if(response?.data?.message) {
        return wrapError(response?.data?.message);
    }
});
