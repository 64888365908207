
export function filterStruktur(rights, schwerpunkt, region) {

  const allgemein = [
    { label: 'Gesellschaft', type: 'text', key: 'inputgesellschaft' },
    { label: 'Lagerstelle', type: 'text', key: 'INPUT_LAGERSTELLE' },
    { label: 'Depotnummer', type: 'text', key: 'inputdepotnr' },
    { label: 'Interne Nummer', type: 'text', key: 'inputbgsnr' },
    { label: 'ISIN / WKN', type: 'text', key: 'inputisin' },
    { label: 'Nur Portfolio-Depotpositionen', type: 'default', key: 'onlyPortfolioBgs' },
    { label: 'Nur Depotpositionen mit Anteilen', type: 'default', key: 'CHECK_NUR_MIT_ANTEILEN' },
    { label: 'Inkl. Depotpositionen der Struktur', type: 'default', key: 'CHECK_INCL_STRUKTUR' },
  ];
  if (!rights.isCustomer || rights.isSuperCustomer) {
    allgemein.push(
      { label: 'Kundennummer', type: 'text', key: 'inputkundennr' },
      { label: 'Kundenname', type: 'text', key: 'inputname' },
      { label: 'Kundenvorname', type: 'text', key: 'inputvorname' },
    );
    if (!rights.isSuperCustomer) {
      allgemein.push(
        { label: 'Vermittlernummer', type: 'text', key: 'inputmaklernr' },
      );
    }
  }
  const COMBO_FONDSTYP = [
    {label: "Offene Wertpapiere", value: "1"},
    {label: "Beteiligungen", value: "2"},
    {label: "Bankkonto", value: "3"},
  ]
  const zusatz = [
    { label: 'Positionen mit Sparpläne', type: 'default', key: 'SELECTION_SPARPLAN', deniable: true },
    { label: 'Fondstyp', type: 'combobox', key: 'COMBO_FONDSTYP', comboOptions: COMBO_FONDSTYP },
    { label: 'Mit Abweichungen zu Anteilen in Trans. (akt. berechnet)', type: 'default', key: 'CHECK_TRANSAKTIONEN_ABWEICHUNGEN' },
    { label: 'Mit Korrektur-/ein-/Ausbuchungen', type: 'default', key: 'CHECK_KORREKTURBUCHUNGEN' },
    { label: 'Fremdanlagen', type: 'default', key: 'CHECK_FREMDANLAGEN', deniable: true },
    { label: 'Transaktionen zu anderen Depotpositionen übertragen', type: 'default', key: 'CHECK_TRANSAKTIONEN_UEBERTRAGEN' },
  ];
  const eigenschaften = [
    { label: 'Vermögensverwaltungsdepots', type: 'default', key: 'EIGENSCHAFT_VERMOEGENSVERWALTUNG', deniable: true },
    { label: 'Minderjährigendepots', type: 'default', key: 'EIGENSCHAFT_MINDERJAEHRIG', deniable: true },
    { label: 'verpfändete Depots', type: 'default', key: 'EIGENSCHAFT_VERPFAENDET', deniable: true },
    { label: 'Garantiedepots', type: 'default', key: 'EIGENSCHAFT_GARANTIEDEPOT', deniable: true },
    { label: 'Schwerpunkt', type: 'combobox', key: 'COMBO_SCHWERPUNKT', comboOptions: schwerpunkt },
    { label: 'Region', type: 'combobox', key: 'COMBO_REGION', comboOptions: region },
  ];
  if (rights.isFA) {
    eigenschaften.push(
      { label: '§14 ESTG', type: 'default', key: 'EIGENSCHAFT_PARA14_EKST', deniable: true },
      { label: 'Verlassenschaft', type: 'default', key: 'EIGENSCHAFT_VERLASSENSCHAFT', deniable: true }
    )
  }
  return [
    {
      type: 'group',
      key: 'allgemein',
      label: 'Allgemein',
      menuItems: allgemein
    },
    {
      type: 'group',
      key: 'zusatz',
      label: 'Zusätzliche Kriterien',
      menuItems: zusatz
    },
    {
      type: 'group',
      key: 'eigenschaften',
      label: 'Depoteigenschaften',
      menuItems: eigenschaften
    }
  ]
}