<template>
  <div>
    <InputField
      ref="inputField"
      v-model="internalValue"
      :label="label"
      :renderDanger="isInvalid"
      @contentSelected="handleSelection"
      @input="handleInputData"
      @keydown="handleKeydown"
      @keyup="handleKeyup"
      @change="handleChange"
      @onBlur="handleBlur"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import InputField from "@/components/core/forms/InputField.vue";

const DATE_SEPARATOR = '.';
const DAY_WILDCARD = 'tt';
const MONTH_WILDCARD = 'mm';
const YEAR_WILDCARD = 'jjjj';
const DEFAULT_VALUE = [DAY_WILDCARD, MONTH_WILDCARD, YEAR_WILDCARD].join(DATE_SEPARATOR);

const POSITION_NONE = 'none';
const POSITION_DAY = 'day';
const POSITION_MONTH = 'month';
const POSITION_YEAR = 'year';

const FEBRUARY = 2;
const MONTHS_WITH_30_DAYS = [4, 6, 9, 11];
const FEBRUARY_DAYS_RANGE = [1, 28];
const FEBRUARY_DAYS_LEAP_YEAR_RANGE = [1, 29];
const MONTH_WITH_DAYS_30_DAYS_RANGE = [1, 30];
const MONTH_WITH_DAYS_31_DAYS_RANGE = [1, 31];
const MONTH_RANGE = [1, 12];
const YEAR_RANGE = [1900, 2100];

const EXTRACT_DATE_REGEX = /^(\d{2})(\d{2})(\d{4})$/;

function isDayValid(day, month, year) {
  const dayNum = parseInt(day);
  const monthNum = parseInt(month);
  const isLeapYear = year?.length == 4 && year !== YEAR_WILDCARD ? dayjs({ year }).isLeapYear() : true;
  const [min, max] = (() => {
    if (monthNum == FEBRUARY) {
      return isLeapYear ? FEBRUARY_DAYS_LEAP_YEAR_RANGE : FEBRUARY_DAYS_RANGE;
    }
    return MONTHS_WITH_30_DAYS.indexOf(monthNum) >= 0 ? MONTH_WITH_DAYS_30_DAYS_RANGE : MONTH_WITH_DAYS_31_DAYS_RANGE;
  })();
  return !Number.isNaN(dayNum) && dayNum >= min && dayNum <= max;
}

function isMonthValid(month) {
  const monthNum = parseInt(month);
  const [min, max] = MONTH_RANGE;
  return !Number.isNaN(monthNum) && monthNum >= min && monthNum <= max;
}

function isYearValid(year) {
  const yearNum = parseInt(year);
  const [min, max] = YEAR_RANGE;
  return !Number.isNaN(yearNum) && yearNum >= min && yearNum <= max;
}

export default {
  components: {
    InputField,
  },
  props: {
    value: {
      type: String,
      default: DEFAULT_VALUE,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentPosition: POSITION_NONE,
      internalValue: null,
      isInvalid: false,
    }
  },
  watch: {
    value: {
      handler() {
        this.setInternalValue(this.value);
      },
    },
  },
  methods: {
    findInputField() {
      return this.$refs?.inputField?.$refs?.inputField ?? {};
    },
    getSplittedInternalValue() {
      const [day='', month='', year=''] = this.internalValue.split(DATE_SEPARATOR);
      return { day, month, year };
    },
    handleBlur() {
      this.currentPosition = POSITION_NONE;
      if (!this.internalValue) {
        this.setInternalValue(DEFAULT_VALUE);
      }
    },
    handleKeydown(event) {
      const key = event.key || event.keyCode;

      const { selectionStart = 0, selectionEnd = 0 } = event.target;
      const hasAlreadyPositionSelected = selectionStart !== selectionEnd;

      const selectPrevPosition = () => {
        switch(this.currentPosition) {
          case POSITION_YEAR:
            event.preventDefault();
            this.selectMonthPosition();
            break;
          case POSITION_MONTH:
            event.preventDefault();
            this.selectDayPosition();
            break;
        }
      };

      const selectNextPosition = () => {
        switch(this.currentPosition) {
          case POSITION_DAY:
            event.preventDefault();
            this.selectMonthPosition();
            break;
          case POSITION_MONTH:
            event.preventDefault();
            this.selectYearPosition();
            break;
        }
      };

      const selectPosition = () => {
        switch(key) {
          case 'ArrowLeft':
          case 37:
            selectPrevPosition();
            break;
          case 'ArrowRight':
          case 39:
            selectNextPosition();
            break;
        }
      };

      if (hasAlreadyPositionSelected) {
        selectPosition();
      }
    },
    handleKeyup(event) {
      const { selectionStart = 0 } = event.target;
      this.currentPosition = this.findCursorPosition(selectionStart);
    },
    findCursorPosition(selectionStart) {
      const {day, month} = this.getSplittedInternalValue();
      if(selectionStart <= day.length) {
        return POSITION_DAY;
      } else if(selectionStart >= day.length && selectionStart <= (day.length + month.length + 1)) {
        return POSITION_MONTH;
      } else {
        return POSITION_YEAR;
      }
    },
    handleSelection(event) {
      if (!event?.target) return;

      const shouldSelectPosition = (position, selectFn) => {
        if (this.currentPosition !== position) {
          selectFn();
        }
      };

      const { selectionStart = 0 } = event.target;

      requestAnimationFrame(() => {
        const position = this.findCursorPosition(selectionStart);
        switch(position) {
          case POSITION_DAY:
            shouldSelectPosition(POSITION_DAY, this.selectDayPosition);
            break;
          case POSITION_MONTH:
            shouldSelectPosition(POSITION_MONTH, this.selectMonthPosition);
            break;
          case POSITION_YEAR:
            shouldSelectPosition(POSITION_YEAR, this.selectYearPosition);
            break;
        }
      });
    },
    selectDayPosition() {
      const { day } = this.getSplittedInternalValue();
      this.findInputField().setSelectionRange(0, day.length);
      this.currentPosition = POSITION_DAY;
    },
    selectMonthPosition() {
      const {day, month} = this.getSplittedInternalValue();
      const start = day.length + 1;
      const end = start + month.length;
      this.findInputField().setSelectionRange(start, end);
      this.currentPosition = POSITION_MONTH;
    },
    selectYearPosition() {
      const {day, month, year} = this.getSplittedInternalValue();
      const start = day.length + 1 + month.length + 1;
      const end = start + year.length;
      this.findInputField().setSelectionRange(start, end);
      this.currentPosition = POSITION_YEAR;
    },
    handleChange(value) {
      if (!value) {
        this.setInternalValue(value);
        return;
      }

      let {day, month, year} = this.getSplittedInternalValue();
      if (day && !month && !year && day.length > 2) {
        this.internalValue = [day.slice(0, 2), day.slice(2, 4), day.slice(4, 8)].join(DATE_SEPARATOR);
        const splitted = this.getSplittedInternalValue();
        day = splitted.day;
        month = splitted.month;
        year = splitted.year;
      }
      day = isDayValid(day, month, year) ? (parseInt(day)+'').padStart(2, '0') : DAY_WILDCARD;
      month = isMonthValid(month) ? (parseInt(month)+'').padStart(2, '0') : MONTH_WILDCARD;
      year = isYearValid(year) ? parseInt(year) : YEAR_WILDCARD;

      this.isInvalid = false;
      this.setInternalValue([day, month, year].join(DATE_SEPARATOR));
    },
    handleInputData() {
      const {day, month, year} = this.getSplittedInternalValue();

      let isInvalid = !!(day && day !== DAY_WILDCARD && !isDayValid(day, month, year) 
        || month && month !== MONTH_WILDCARD && !isMonthValid(month)
        || year && year !== YEAR_WILDCARD && !isYearValid(year));

      if(!isInvalid) {
        this.$nextTick(() => {
          if(this.currentPosition === POSITION_DAY && day.length == 2) {
            this.selectMonthPosition();
          } else if(this.currentPosition === POSITION_MONTH && month.length == 2) {
            this.selectYearPosition();
          }
        });
      }

      this.isInvalid = isInvalid;
      if (EXTRACT_DATE_REGEX.test(this.internalValue)) {
        this.internalValue = this.internalValue.replace(EXTRACT_DATE_REGEX, '$1.$2.$3');
        this.currentPosition = POSITION_NONE;
        this.handleInputData();
      }
    },
    setInternalValue(value) {
      this.internalValue = value;
      this.$emit('input', this.internalValue);
    },
  },
  mounted() {
    this.setInternalValue(this.value ? this.value : DEFAULT_VALUE);
  },
}
</script>
