var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseListWithSearch", {
        attrs: {
          componentIds: _vm.componentIds,
          pageHeaderActions: _vm.actions,
          pageHeaderActionListeners: _vm.pageHeaderActionListeners,
          baseFilterConfigs: _vm.baseFilterConfigs,
          baseFilterListeners: _vm.baseFilterListeners,
          tableConfigs: _vm.tableConfigs,
          tableListeners: _vm.tableListeners,
          loading: _vm.loading
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "modalImportProcess",
          attrs: {
            modalTitle: "Vorgang importieren",
            labelButtonConfirm: "Importieren",
            confirmDisabled: !_vm.isImportFormValid
          },
          on: { onConfirmButton: _vm.importProcess }
        },
        [
          _c("ComboBox", {
            attrs: {
              label: "Makler Externer Zugang",
              values: _vm.maklerExtZugangList
            },
            model: {
              value: _vm.vorgangImport.maklerExtZugangId,
              callback: function($$v) {
                _vm.$set(_vm.vorgangImport, "maklerExtZugangId", $$v)
              },
              expression: "vorgangImport.maklerExtZugangId"
            }
          }),
          _c("InputField", {
            attrs: { label: "VorgangsNr." },
            model: {
              value: _vm.vorgangImport.vorgangsNr,
              callback: function($$v) {
                _vm.$set(_vm.vorgangImport, "vorgangsNr", $$v)
              },
              expression: "vorgangImport.vorgangsNr"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }