var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menu-structure-config__container" }, [
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Menüstrukturen für Kunden")
      ]),
      _c(
        "div",
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("alle Kunden")]),
          _vm.loading
            ? _c(
                "GhostLoading",
                { staticClass: "forms__input--half-size" },
                [_c("Block", { attrs: { type: "form-input" } })],
                1
              )
            : _c("ComboBox", {
                attrs: {
                  values: _vm.customerMenuStructureValues,
                  isComponentHalfSize: "",
                  sortComboboxValues: false,
                  disabled: _vm.saving
                },
                model: {
                  value: _vm.form.allCustomers,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "allCustomers", $$v)
                  },
                  expression: "form.allCustomers"
                }
              })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("neue Kunden")]),
          _vm.loading
            ? _c(
                "GhostLoading",
                { staticClass: "forms__input--half-size" },
                [_c("Block", { attrs: { type: "form-input" } })],
                1
              )
            : _c("ComboBox", {
                attrs: {
                  values: _vm.customerMenuStructureValues,
                  isComponentHalfSize: "",
                  sortComboboxValues: false,
                  disabled: _vm.saving
                },
                model: {
                  value: _vm.form.newCustomers,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "newCustomers", $$v)
                  },
                  expression: "form.newCustomers"
                }
              })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("SpecificCustomersTable", {
            attrs: {
              items: _vm.form.specificCustomers,
              menuStructureValues: _vm.customerMenuStructureValues,
              disabled: _vm.saving,
              isLoading: _vm.loading
            },
            on: {
              menuStructureChanged: function($event) {
                return _vm.onMenuStructureChanged($event)
              },
              removeConfig: function($event) {
                return _vm.onRemoveConfig($event)
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }