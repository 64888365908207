var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "confirmModal",
    attrs: {
      modalTitle: _vm.options.title,
      size: _vm.options.size,
      labelButtonCancel: _vm.options.labelButtonCancel,
      labelButtonConfirm: _vm.options.labelButtonConfirm || "Bestätigen",
      showCancelButton: _vm.options.showCancelButton,
      showConfirmButton: _vm.options.showConfirmButton,
      isModalAsFullPage: false,
      autoCloseOnRouteNavigation: _vm.options.autoCloseOnRouteNavigation
    },
    on: { onConfirmButton: _vm.onConfirmButton, close: _vm.onClose },
    scopedSlots: _vm._u(
      [
        _vm.options.icon
          ? {
              key: "modalTitle",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c(_vm.options.icon, {
                        tag: "component",
                        staticClass: "col-auto pr-1",
                        class: _vm.options.iconClass
                      }),
                      _c("span", {
                        staticClass: "col p-0",
                        domProps: {
                          innerHTML: _vm._s(_vm.sanitize(_vm.options.title))
                        }
                      })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          : null,
        _vm.options.message
          ? {
              key: "default",
              fn: function() {
                return [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.sanitize(_vm.options.message))
                    }
                  })
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }