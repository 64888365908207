export const MUTATION_PREFIX = 'MULTI_BANKING: ';
export const ACTIONS_PREFIX = 'MULTI_BANKING_';
export const GETTERS_PREFIX = 'MULTI_BANKING_';

export default {
  MUTATIONS: {
    LOAD_TRANSACTION_CATEGORIES: MUTATION_PREFIX + 'LOAD_TRANSACTION_CATEGORIES',
    LOAD_TRANSACTION_CATEGORIES_COMBO: MUTATION_PREFIX + 'LOAD_TRANSACTION_CATEGORIES_COMBO',
    LOAD_CONTRACTS: MUTATION_PREFIX + 'LOAD_CONTRACTS',
    LOAD_TRANSACTIONS: MUTATION_PREFIX + 'LOAD_TRANSACTIONS',
    LOAD_DEPOTS: MUTATION_PREFIX + 'LOAD_DEPOTS',
    LOAD_SAVINGS_POTENTIAL: MUTATION_PREFIX + 'LOAD_SAVINGS_POTENTIAL',
    LOAD_ACCOUNT_SUMMARY: ACTIONS_PREFIX + 'LOAD_ACCOUNT_SUMMARY',
    LOAD_ACCOUNT_DATA_SUCCESS: MUTATION_PREFIX + 'LOAD_ACCOUNT_DATA_SUCCESS',
    LOAD_PARAMS: MUTATION_PREFIX + 'LOAD_PARAMS',
    LOAD_TRANSACTION_SPLIT: MUTATION_PREFIX + 'LOAD_TRANSACTION_SPLIT',
    SET_LOADED: MUTATION_PREFIX + 'SET_LOADED',
    RESET_LOADED: MUTATION_PREFIX + 'RESET_LOADED',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',

    SAVE_USER_SETTINGS: MUTATION_PREFIX + 'SAVE_USER_SETTINGS',
    UPDATE_ACCOUNT: MUTATION_PREFIX + 'UPDATE_ACCOUNT',
    HIDE_ACCOUNT: MUTATION_PREFIX + 'HIDE_ACCOUNT',
    SAVE_TRANSACTION_COMMENT: MUTATION_PREFIX + 'SAVE_TRANSACTION_COMMENT',
    SAVE_TRANSACTION_CATEGORY: MUTATION_PREFIX + 'SAVE_TRANSACTION_CATEGORY',
    UPDATE_CATEGORY: MUTATION_PREFIX + 'UPDATE_CATEGORY',

    UPDATE_LOADED_STATUS: MUTATION_PREFIX + 'UPDATE_LOADED_STATUS',
    UPDATE_FILTERS: MUTATION_PREFIX + 'UPDATE_FILTERS',
    BANKSAPI_URL: MUTATION_PREFIX + 'BANKSAPI_URL'
  },
  ACTIONS: {
    GET_BANK_TRANSACTIONS_TRANSFER_REQUEST: ACTIONS_PREFIX + 'GET_BANK_TRANSACTIONS_TRANSFER_REQUEST',
    REGISTER_NEW_ACCOUNT: ACTIONS_PREFIX + 'REGISTER_NEW_ACCOUNT',
    UPDATE_SELECTED_ACCOUNTS: ACTIONS_PREFIX + 'UPDATE_SELECTED_ACCOUNTS',
    ABORT_REGISTER_NEW_ACCOUNT: ACTIONS_PREFIX + 'ABORT_REGISTER_NEW_ACCOUNT',
    LOAD_ALL_MULTIBANKING_DATA: ACTIONS_PREFIX + 'LOAD_ALL_MULTIBANKING_DATA',

    LOAD_TRANSACTION_CATEGORIES: ACTIONS_PREFIX + 'LOAD_TRANSACTION_CATEGORIES',
    LOAD_CONTRACTS: ACTIONS_PREFIX + 'LOAD_CONTRACTS',
    LOAD_TRANSACTIONS: ACTIONS_PREFIX + 'LOAD_TRANSACTIONS',
    LOAD_DEPOTS: ACTIONS_PREFIX + 'LOAD_DEPOTS',
    LOAD_EXTERNAL_DEPOTS: ACTIONS_PREFIX + 'LOAD_EXTERNAL_DEPOTS',
    LOAD_SAVINGS_POTENTIAL: ACTIONS_PREFIX + 'LOAD_SAVINGS_POTENTIAL',
    LOAD_ACCOUNT_SUMMARY: ACTIONS_PREFIX + 'LOAD_ACCOUNT_SUMMARY',
    LOAD_ACCOUNT_DATA: ACTIONS_PREFIX + 'LOAD_ACCOUNT_DATA',
    LOAD_EXTERNAL_ACCOUNT_DATA: ACTIONS_PREFIX + 'LOAD_EXTERNAL_ACCOUNT_DATA',
    LOAD_PARAMS: ACTIONS_PREFIX + 'LOAD_PARAMS',
    LOAD_TRANSACTION_SPLIT: ACTIONS_PREFIX + 'LOAD_TRANSACTION_SPLIT',

    SAVE_USER_SETTINGS: ACTIONS_PREFIX + 'SAVE_USER_SETTINGS',
    FINALIZE_ACCOUNT: ACTIONS_PREFIX + 'FINALIZE_ACCOUNT',
    UPDATE_ACCOUNT: ACTIONS_PREFIX + 'UPDATE_ACCOUNT',
    HIDE_ACCOUNT: ACTIONS_PREFIX + 'HIDE_ACCOUNT',
    SAVE_TRANSACTION_COMMENT: ACTIONS_PREFIX + 'SAVE_TRANSACTION_COMMENT',
    SAVE_TRANSACTION_CATEGORY: ACTIONS_PREFIX + 'SAVE_TRANSACTION_CATEGORY',
    UPDATE_CATEGORY: ACTIONS_PREFIX + 'UPDATE_CATEGORY',
    // todo: there is no delete category action or service. it looks like this service couldn't be used in KSC3 either, although it was implemented
    SAVE_TRANSACTION_SPLIT: ACTIONS_PREFIX + 'SAVE_TRANSACTION_SPLIT',
    AUTHENTICATE_ACCOUNT: ACTIONS_PREFIX + 'AUTHENTICATE_ACCOUNT',
    REGISTER_ACCOUNT_URL: ACTIONS_PREFIX + 'REGISTER_ACCOUNT_URL'
  },
  GETTERS: {
    PARAMETERS: GETTERS_PREFIX + 'PARAMETERS',
    ACCOUNTS: GETTERS_PREFIX + 'ACCOUNTS',
    SAVINGS_POTENTIAL: GETTERS_PREFIX + 'SAVINGS_POTENTIAL',
    SAVINGS_POTENTIAL_BY_ACCOUNT: GETTERS_PREFIX + 'SAVINGS_POTENTIAL_BY_ACCOUNT',
    SAVINGS_POTENTIAL_TOTAL: GETTERS_PREFIX + 'SAVINGS_POTENTIAL_TOTAL',
    SUMMARY: GETTERS_PREFIX + 'SUMMARY',
    TRANSACTIONS: GETTERS_PREFIX + 'TRANSACTIONS',
    DEPOTS: GETTERS_PREFIX + 'DEPOTS',
    ACCOUNTS_SUM: GETTERS_PREFIX + 'ACCOUNTS_SUM',
    DAILY_ACCOUNT_HISTORY: GETTERS_PREFIX + 'DAILY_ACCOUNT_HISTORY',
    CATEGORY_TREE: GETTERS_PREFIX + 'CATEGORY_TREE',
    CATEGORY_TREE_ALL: GETTERS_PREFIX + 'CATEGORY_TREE_ALL',
    CATEGORIES_BY_KEY: GETTERS_PREFIX + 'CATEGORIES_BY_KEY',
    TRANSACTION_SPLITS: GETTERS_PREFIX + 'TRANSACTION_SPLITS',
    USER_SETTINGS: GETTERS_PREFIX + 'USER_SETTINGS',
    AVAILABLE_CATEGORIES_FLAT: GETTERS_PREFIX + 'AVAILABLE_CATEGORIES_FLAT',
    LOADED_STATUS: GETTERS_PREFIX + 'LOADED_STATUS',
    FILTERS: GETTERS_PREFIX + 'FILTERS',
    BANKSAPI_URL: GETTERS_PREFIX + 'BANKSAPI_URL',
    LOAD_CONTRACTS: GETTERS_PREFIX + 'LOAD_CONTRACTS'
  }
}