var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Servicegebühr & Depotrabatt",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.editSgR(null)
          }
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c(
          "div",
          { staticClass: "box__container-rows" },
          [
            _vm.rows.length
              ? _c("Table", {
                  attrs: {
                    tableId: "f0470771-ba6e-49a6-b5d9-7d60142b3cc4",
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowId: "kontonr",
                    ignoreTablePageSize: "",
                    rowsPerPage: 5,
                    tableRowsPerPage: [],
                    hidePagination: _vm.$isSmallScreen,
                    mobileConfig: _vm.mobileTableConfig
                  },
                  on: {
                    "action-EDIT": _vm.editSgR,
                    "action-DELETE": _vm.deleteSgR
                  }
                })
              : _c("NoData", { attrs: { noIcon: "" } })
          ],
          1
        )
      ]),
      _c("BaseModal", {
        ref: "editSgR",
        attrs: {
          modalTitle: "Servicegebühr & Rabatt",
          labelButtonConfirm: "Speichern",
          autoClose: false
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.saveSgR()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("ComboBox", {
                  attrs: {
                    label: "Lagerstelle",
                    values: _vm.gesellschaften,
                    sortComboboxValues: false
                  },
                  model: {
                    value: _vm.gesellschaftID,
                    callback: function($$v) {
                      _vm.gesellschaftID = $$v
                    },
                    expression: "gesellschaftID"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Depotnummer" },
                  model: {
                    value: _vm.kontonr,
                    callback: function($$v) {
                      _vm.kontonr = $$v
                    },
                    expression: "kontonr"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Servicegebühr", type: "percent" },
                  model: {
                    value: _vm.gebuehr,
                    callback: function($$v) {
                      _vm.gebuehr = $$v
                    },
                    expression: "gebuehr"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Rabatt", type: "percent" },
                  model: {
                    value: _vm.rabatt,
                    callback: function($$v) {
                      _vm.rabatt = $$v
                    },
                    expression: "rabatt"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }