var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          id: _vm.$appNavigation.currentOptionMenuId
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "modalCreateProcess",
          attrs: {
            modalTitle: "Vorgang erstellen",
            labelButtonConfirm: "Erstellen",
            confirmDisabled: !_vm.isValid
          },
          on: {
            onCloseButton: _vm.navigateBack,
            onCancelButton: _vm.navigateBack,
            onConfirmButton: _vm.createProcess
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Beschreibung" },
            model: {
              value: _vm.processForm.description,
              callback: function($$v) {
                _vm.$set(_vm.processForm, "description", $$v)
              },
              expression: "processForm.description"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Makler Externer Zugang",
              values: _vm.maklerExtZugangList,
              validateUntouched: ""
            },
            model: {
              value: _vm.processForm.maklerExtZugang.id,
              callback: function($$v) {
                _vm.$set(_vm.processForm.maklerExtZugang, "id", $$v)
              },
              expression: "processForm.maklerExtZugang.id"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }