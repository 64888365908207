import EXTERNE_ZUGANG_TYPES from './types';

export default {
  [EXTERNE_ZUGANG_TYPES.GETTERS.EXTERNE_ZUGANG_VERGLEICH_LIST](state) {
    return state.externeZugangVergleich;
  },
  [EXTERNE_ZUGANG_TYPES.GETTERS.EXTERNE_ZUGANG_ABSCHLUSS_LIST](state) {
    return state.externeZugangAbschluss;
  },
  [EXTERNE_ZUGANG_TYPES.GETTERS.EXTERNE_ZUGANG_VERGLEICH_PAGE_HEADER_ACTIONS](state) {
    return state.externeZugangVergleichPageHeaderActions;
  },
  
}