import axios from 'axios';

import { mapGetters } from 'vuex';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import EVENT_BUS, {  SEND_EMAIL, } from '@/event-bus';

import BaseModal from '@/components/core/BaseModal.vue';
import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';

export default {
  props: {
    nodeId: {
      type: [String, Number],
    },
    versicherungId: {
      type: [String, Number],
      default: null,
      required: false
    },
    showOnlySchadenDocs: {
      type: Boolean,
      default: false
    },
    schadenId: {
      type: [String, Number],
      default: null,
      required: false
    },
  },
  data() {
    return {
      files: null,
      currentHeaderAction: null,
    };
  },
  computed: {
    ...mapGetters({
      selectedNode: DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS,
      selectedRows: DOKUMENTENARCHIV_TYPES.GETTERS.SELECTED_ROWS,
      additionalUploadInputs: DOKUMENTENARCHIV_TYPES.GETTERS.ADDITIONAL_UPLOAD_INPUTS,
      isSelectedAllInFolder: DOKUMENTENARCHIV_TYPES.GETTERS.IS_SELECTED_ALL_IN_FOLDER,
      isLoadingNode: DOKUMENTENARCHIV_TYPES.GETTERS.IS_LOADING_NODE,
      filesFromDokumentenarchiv: SCHADENSMELDUNG_TYPES.GETTERS.FILES_FROM_DOKUMENTENARCHIV,
    }),
    currentAdditionalInputs() {
      return this.additionalUploadInputs?.additionalUploadInputs || null;
    },
    confirmationMessage() {
      return this.currentHeaderAction?.confirmationMessage;
    },
    unselectedDocumentsIds() {
      // in case of all documents were selected except some, the store variable selectedRows will keep those unselected documents instead the selected ones
      return this.isSelectedAllInFolder && this.selectedRows?.length ? this.selectedRows.map(row => row?.dokId || row) : [];
    },
  },
  watch: {
    filesFromDokumentenarchiv: {
      handler(newValue) {
        this.files = newValue?.length > 0 ? newValue : null;
      },
      immediate: true,
    },
  },
  methods: {
    buttonDisabled(key) {
      return key !== 'DOKUMENTENARCHIV' && key !== 'DATEI_HOCHLADEN' && key !== 'DATEI_HINZUFUGEN' && !this.isSelectedAllInFolder && (!this.selectedRows.length || this.selectedRows.some(row => !row.exists));
    },
    closeSendEmailModal() {
      this.$refs.sendEmailModal.close();
    },
    async executeHeaderAction(headerAction) {
      if(!headerAction?.key) return;

      this.currentHeaderAction = headerAction?.value || headerAction;
      switch (headerAction?.key) {
        case 'CONFIRM_SELECTION':
          const selectedRowFiles = await this.retrieveSelectedRowsAsTempFiles()

          if (selectedRowFiles.length) {
            this.$emit('headerAction', { key: headerAction.actionKey, value: selectedRowFiles, });
            this.$emit(`headerAction-${headerAction.actionKey}`, selectedRowFiles);
          }

          break;
        case 'DATEI_HOCHLADEN':
          this.dateiHochladen();
          break;
        case 'DATEI_HINZUFUGEN':
          this.$router.push({
            path: `/communication/documents/zusaetzlichedokumente`, 
            query: { 
              nodeId: this.nodeId, 
              fileId: null,
            },
          });
          break;
        case 'MARKIERTE_MAIL_VERSCHICKEN':
          this.sendEmail(false);
          break;
        case 'MARKIERTE_MAIL_KUNDE_GESELL_VERSCHICKEN':
          this.$refs.sendEmailModal.open();
          break;
        case 'MARKIERTE_MAIL_KUNDE_VERSCHICKEN':
          this.sendEmail(false);
          break;
        case 'MARKIERTE_MAIL_GESELLSCHAFT_VERSCHICKEN':
          this.sendEmail(true);
          break;
        case 'DOKUMENTENARCHIV':
          this.$router.push({
            path: `/communication/schadensmeldung-dokumentenarchiv`, 
            query: { 
              back: this.$route.fullPath,
              nodeId: this.nodeId, 
              fileId: null,
              insuranceId: this.insuranceId,
              schadenId: this.schadenId,
            },
          });
          break;          
        default:
          // if it has an actionkey, it's probably an additionalAction
          if (headerAction.actionKey) {
            this.$emit('headerAction', { key: headerAction.actionKey, value: headerAction, });
            this.$emit(`headerAction-${headerAction.actionKey}`, headerAction);
          }

          if (this.selectedRows.length && !this.isSelectedAllInFolder || this.isSelectedAllInFolder) {
            this.$refs.confirmationModal.open();
          }
      }
    },
    confirmSendEmail(toCompany = false) {
      this.closeSendEmailModal()
      this.sendEmail(toCompany)
    },
    async retrieveSelectedRows() {
      let selectedRows = this.selectedRows

      if (this.isSelectedAllInFolder) {
        await this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOCUMENTS, {
          nodeId: this.nodeId,
          offset: 0,
          limit: 200,
          schadenId: this.schadenId,
          keepOldPages: false,
          reloadTree: false,
          linkVertrag: true,
          doAlwaysLoad: true,
          isSelectedAllInFolder: this.isSelectedAllInFolder,
          unselectedRows: this.unselectedDocumentsIds,
        });
        if (this.selectedNode?.documents) {
          selectedRows = []
          Object.fromEntries(Object.entries(this.selectedNode?.documents).map(([key, page]) => {
            return [key, page
                .map(document => {
                  selectedRows.push(document)
                })]
            }));
        }
      }

      // when there is no document of type antrag associated is not possible to filter in the back-end, thus we must to assure no unselected files will be added
      return selectedRows.filter(doc => !this.unselectedDocumentsIds.includes(doc.dokId));
    },
    async retrieveSelectedRowsAsTempFiles() {
      let selectedRows = await this.retrieveSelectedRows()

      let attachments = []
      const params = selectedRows
        .filter(v => (v?.node || v?.nodeId) && !v.uploaded && v.additionalColumns.documentCount > 0)
        .map(v => ({nodeId: v?.node || v?.nodeId, fileId: v?.id}))
      await axios.post(`${process.env.VUE_APP_API}/documents/getTempFiles`, params).then(response => {
        response?.data?.map(f => {
          attachments.push({
            id: f.id,
            name: f.name,
          })
        });
      }).catch(error => {
        this.downloadErrorMessage = error?.errorMessage;
        this.$refs.downloadErrorModal.open();
      });

      return attachments
    },
    async sendEmail(toCompany) {
      if (this.$route.path.includes("schadensmeldung")) {
        const attachments = await this.retrieveSelectedRows();
        this.$emit('sendEmail', {toCompany, attachments, isSelectedAllDocumentsInFolder: this.isSelectedAllInFolder});
      } else {
        const attachments = await this.retrieveSelectedRowsAsTempFiles()
        const insuranceId = this.selectedRows && this.selectedRows[0]?.vertragId;
        
        // Note: passing the info for maklervollmacht here is not very good because we do not have that information in documents archive
        const hasMaklerVollmachtDocuments =  this.maklerVollmacht?.hasMaklerVollmachtDocuments;
        const maklerVollmacht=  this.maklerVollmacht?.maklerVollmacht;

        const emailObject = {
          insuranceId,
          attachments,
          toCompany,
          hasMaklerVollmachtDocuments,
          maklerVollmacht,
          isSelectedAllDocumentsInFolder: this.isSelectedAllInFolder,
          unselectedDocuments: this.unselectedDocumentsIds
        };
        // currently there's no feature to send schadensdokumente email from documents archive
        // event captured by EmailVersand.vue
        EVENT_BUS.$emit(SEND_EMAIL, emailObject);
      }
    },
    getBackOptions() {
      if (this.$route.query.back.includes('pruefstatus') || this.$route.query.back.includes('mailcomposer-insurance')) return '?backAction=true'
      if (this.$route.params.type && this.$route.params.type.includes('calendar')) return '?usersselected=true'
      return ''
    },
    dateiHochladen(files = []) {
      this.files = files;
      this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_UPLOAD_INPUTS, {
        nodeId: this.nodeId,
        showOnlySchadenDocs: this.showOnlySchadenDocs,
      })
    },
    onConfirmHeaderActionModal() {
      const postData = {
        headerAction: this.currentHeaderAction.key,
        nodeId: this.nodeId,
        documentIds: this.selectedRows.map(sr => sr.id),
      };

      this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.EXECUTE_HEADER_ACTION, postData);

      this.currentHeaderAction = null;
    },
  },
  components: {
    BaseModal,
    DocumentsUploadModal,
  },
}
