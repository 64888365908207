var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "row my-0", class: { "mb-3": !_vm.outcome } }, [
        _vm.outcome
          ? _c("div", { staticClass: "col-12" }, [
              _c("p", { staticClass: "m-0" }, [
                _vm._v("Bitte tragen Sie hier die zu kaufenden Positionen ein:")
              ])
            ])
          : _vm._e()
      ]),
      _vm.outcome
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("WertpapierTable", {
                attrs: {
                  tableId: _vm.tableId,
                  rows: _vm.rows,
                  headers: _vm.headers,
                  singleSelection: _vm.singleSelection,
                  selected: _vm.isSelectable ? _vm.selectedRows : undefined,
                  showMobileSelectionAlways: _vm.showMobileSelectionAlways
                },
                on: { selected: _vm.onSelect, action: _vm.handleTableAction }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", [
        _c("div", { staticClass: "layout__negative-margin--8 d-flex" }, [
          _vm.goBackEnabled
            ? _c(
                "div",
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.goBack()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.zurueckButtonText) + " ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.hasDefaultButton && _vm.isSelectable && _vm.rows.length
            ? _c(
                "div",
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { disabled: !_vm.selectedRows.length },
                      on: {
                        click: function($event) {
                          return _vm.getSelectedAndGoBack()
                        }
                      }
                    },
                    [_vm._v(" Auswahl übernehmen ")]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _c(
        "BaseModal",
        {
          ref: "emptySelection",
          attrs: {
            modalTitle: "Kein Auswahl",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_c("label", [_vm._v("Bitte wählen Sie eine Position aus.")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "overMaxCount",
          attrs: { labelButtonConfirm: "Ok", showCancelButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Maximal " + _vm._s(_vm.maxCount) + " positionen. ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [_vm._v("Zu viele Positionen ausgewählt.")]),
          _c("div", [
            _vm._v(
              "Sie können insgesamt " +
                _vm._s(_vm.maxCount) +
                " Positionen hinzufügen."
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }