var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "scroll-container",
      on: { scroll: _vm.onScroll }
    },
    [
      _c("div", { ref: "titleBasePos", staticClass: "title-always-visibe" }, [
        _c("table", { style: _vm.styleTitle }, [
          _c(
            "colgroup",
            [
              _vm._l(_vm.leftHeaders, function(ref) {
                var key = ref.key
                var width = ref.width
                return _c("col", { key: key, style: { width: width + "px" } })
              }),
              _vm.centerHeaders.length
                ? _c("col", { style: { width: _vm.scrollButtonWidth + "px" } })
                : _vm._e(),
              _vm._l(_vm.centerHeaders, function(ref) {
                var key = ref.key
                var width = ref.width
                return _c("col", { key: key, style: { width: width + "px" } })
              }),
              _vm.centerHeaders.length
                ? _c("col", { style: { width: _vm.scrollButtonWidth + "px" } })
                : _vm._e(),
              _vm._l(_vm.rightHeaders, function(ref) {
                var key = ref.key
                var width = ref.width
                return _c("col", { key: key, style: { width: width + "px" } })
              })
            ],
            2
          ),
          _c(
            "thead",
            [
              _vm._l(_vm.leftHeaders, function(ref) {
                var key = ref.key
                var left = ref.left
                return _c(
                  "th",
                  {
                    key: key,
                    staticClass: "header header-cell text-left",
                    style: { left: left + "px" },
                    attrs: { "aria-label": "'header_' + key" }
                  },
                  [_vm._t("header_" + key, null, { collapsed: false })],
                  2
                )
              }),
              _vm.centerHeaders.length
                ? _c("th", {
                    staticClass: "header header-cell",
                    staticStyle: { background: "none" },
                    style: { left: _vm.leftWidth + "px" },
                    attrs: {
                      "aria-label":
                        "Label für Spalten in der Mitte der Tabelle, die linksbündig sind"
                    }
                  })
                : _vm._e(),
              _vm._l(_vm.centerHeaders, function(ref) {
                var key = ref.key
                return _c(
                  "td",
                  { key: key, staticClass: "header header-cell text-left" },
                  [_vm._t("header_" + key, null, { collapsed: false })],
                  2
                )
              }),
              _vm.centerHeaders.length
                ? _c("th", {
                    staticClass: "header header-cell",
                    staticStyle: { background: "none" },
                    style: { right: _vm.rightWidth + "px" },
                    attrs: {
                      "aria-label":
                        "Label für Spalten in der Mitte der Tabelle, die rechtsbündig sind"
                    }
                  })
                : _vm._e(),
              _vm._l(_vm.rightHeaders, function(ref) {
                var key = ref.key
                var right = ref.right
                return _c(
                  "th",
                  {
                    key: key,
                    staticClass: "th-right header header-cell text-left",
                    style: { right: right + "px" },
                    attrs: { "aria-label": "'header_' + key" }
                  },
                  [_vm._t("header_" + key, null, { collapsed: false })],
                  2
                )
              })
            ],
            2
          )
        ])
      ]),
      _c(
        "table",
        [
          _c(
            "colgroup",
            [
              _vm._l(_vm.leftHeaders, function(ref) {
                var key = ref.key
                var width = ref.width
                return _c("col", { key: key, style: { width: width + "px" } })
              }),
              _vm.centerHeaders.length
                ? _c("col", { style: { width: _vm.scrollButtonWidth + "px" } })
                : _vm._e(),
              _vm._l(_vm.centerHeaders, function(ref) {
                var key = ref.key
                var width = ref.width
                return _c("col", { key: key, style: { width: width + "px" } })
              }),
              _vm.centerHeaders.length
                ? _c("col", { style: { width: _vm.scrollButtonWidth + "px" } })
                : _vm._e(),
              _vm._l(_vm.rightHeaders, function(ref) {
                var key = ref.key
                var width = ref.width
                return _c("col", { key: key, style: { width: width + "px" } })
              })
            ],
            2
          ),
          _vm._l(_vm.rows, function(ref, index) {
            var acceptsFile = ref.acceptsFile
            var id = ref.id
            var row = ref.row
            var customRowStyle = ref.customRowStyle
            return _c(
              "DragnDropArea",
              {
                key: id,
                attrs: {
                  element: "tbody",
                  hoverText: "Datei hier ablegen",
                  disabled: !acceptsFile
                },
                on: {
                  files: function($event) {
                    return _vm.handleFile(row, $event)
                  }
                }
              },
              [
                _c(
                  "tr",
                  [
                    _vm._l(_vm.leftHeaders, function(ref) {
                      var key = ref.key
                      var left = ref.left
                      var classes = ref.classes
                      var align = ref.align
                      return _c(
                        "td",
                        {
                          key: key,
                          staticClass: "table-cell",
                          class: [classes, "text-" + align],
                          style: Object.assign({}, customRowStyle, {
                            left: left + "px"
                          }),
                          attrs: { valign: _vm.valign }
                        },
                        [
                          _vm._t(
                            key,
                            function() {
                              return [_vm._v(" " + _vm._s(row[key]) + " ")]
                            },
                            { row: row, id: id }
                          )
                        ],
                        2
                      )
                    }),
                    _vm.centerHeaders.length
                      ? _c(
                          "td",
                          {
                            class: [
                              "table-cell",
                              _vm.canScrollLeft ? "scroll-left" : "border-left"
                            ],
                            style: { left: _vm.leftWidth + "px" },
                            on: {
                              click: function($event) {
                                return _vm.scrollLeft()
                              }
                            }
                          },
                          [
                            Math.floor(_vm.rows.length / 2) == index &&
                            _vm.canScrollLeft
                              ? _c("PhCaretCircleLeft", {
                                  attrs: {
                                    size: 24,
                                    weight: "duotone",
                                    alt: "Icon zum Scrollen nach links"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.centerHeaders, function(ref) {
                      var key = ref.key
                      var classes = ref.classes
                      var align = ref.align
                      return _c(
                        "td",
                        {
                          key: key,
                          staticClass: "table-cell",
                          class: [classes, "text-" + align],
                          style: customRowStyle,
                          attrs: { valign: _vm.valign }
                        },
                        [
                          _vm._t(
                            key,
                            function() {
                              return [_vm._v(" " + _vm._s(row[key]) + " ")]
                            },
                            { row: row, id: id }
                          )
                        ],
                        2
                      )
                    }),
                    _vm.centerHeaders.length
                      ? _c(
                          "td",
                          {
                            class: [
                              "table-cell",
                              _vm.canScrollRight
                                ? "scroll-right"
                                : "border-right"
                            ],
                            style: { right: _vm.rightWidth + "px" },
                            on: {
                              click: function($event) {
                                return _vm.scrollRight()
                              }
                            }
                          },
                          [
                            Math.floor(_vm.rows.length / 2) == index &&
                            _vm.canScrollRight
                              ? _c("PhCaretCircleRight", {
                                  attrs: {
                                    size: 24,
                                    weight: "duotone",
                                    alt: "Icon zum Scrollen nach rechts"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.rightHeaders, function(ref) {
                      var key = ref.key
                      var right = ref.right
                      var classes = ref.classes
                      var align = ref.align
                      return _c(
                        "td",
                        {
                          key: key,
                          staticClass: "th-right table-cell",
                          class: [classes, "text-" + align],
                          style: Object.assign({}, customRowStyle, {
                            right: right + "px"
                          }),
                          attrs: { valign: _vm.valign }
                        },
                        [
                          _vm._t(
                            key,
                            function() {
                              return [_vm._v(" " + _vm._s(row[key]) + " ")]
                            },
                            { row: row, id: id }
                          )
                        ],
                        2
                      )
                    })
                  ],
                  2
                )
              ]
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }