var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.$appNavigation.currentMenu.label
        }
      }),
      _c("BaseFilter", {
        ref: "filter",
        attrs: {
          filterId: "940c19f6-8505-4ce1-967a-2bc47c649f22",
          configFilter: _vm.configFilter,
          metadata: _vm.menuMetadaData,
          defaultOptions: _vm.initialFilters,
          immidiateSearch: _vm.isCustomer,
          showSaveButton: ""
        },
        on: { onFilter: _vm.handleSearch }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: "Schadensuche" }
              })
            : _vm.rows && _vm.rows.length
            ? _c("Table", {
                ref: "tableResult",
                attrs: {
                  tableId: "7e9b1dc1-8784-4a95-b43a-df2649bc32a2",
                  title: "Schadensuche",
                  headers: _vm.computedHeaders,
                  rows: _vm.rows,
                  rowsPerPage: _vm.rowsPerPage,
                  thHoverDisabled: true,
                  mobileConfig: {
                    title: "gesellschaftName",
                    headers: ["name", "schadennr", "meldedat"]
                  }
                },
                on: {
                  "click-kundennr": _vm.openCustomerNewTab,
                  "click-schadennr": _vm.openSchaden,
                  "click-polNr": _vm.openInsurance,
                  "click-vermittlernummer": _vm.openBroker,
                  "click-vermittlerName": _vm.openBroker,
                  "click-gesellschaftName": _vm.openGesellschaft,
                  "action-SCHADEN-OEFFNEN": _vm.openSchaden,
                  "action-VERTRAG-OEFFNEN": _vm.openInsurance
                }
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }