<template>
    <div>
        <ResizableModal
            v-if="!$isSmallScreen"
            ref="resizablePopup"
            :withBackdropLayer="withBackdropLayer"
            :modalTitle="modalTitle"
            :initialWidth="initialWidth"
            :initialHeight="initialHeight"
            :sizeIsPercentage="sizeIsPercentage"
            :startPosition="startPosition"
            :fullClientHeight="fullClientHeight"
            :saveLastPosition="saveLastPosition"
            :id="id"
            @onMinimize="$emit('onMinimize')"
            @onMaximize="$emit('onMaximize')"
            @onResize="$emit('onResize')"
            @onClose="onClose"
        >
            <template #toolbar-buttons><slot name="toolbar-buttons"></slot></template>
            <slot></slot>
        </ResizableModal>

        <BaseModal
            v-else
            ref="baseModal"
            :modalTitle="modalTitle"
            :showDefaultButtons="false"
            :actions="actions"
            @action-SAVE="$emit('action-SAVE')"
            @action-FINISH="$emit('action-FINISH')"
            @action-PAGE_OVERVIEW="$emit('action-PAGE_OVERVIEW')"
            @action-ADD_SIGNATURE="$emit('action-ADD_SIGNATURE')"
            @onCloseButton="goToLastAction"
        >
            <div class="modal-content">
                <slot></slot>
                <div class="toolbar">
                    <slot name="toolbar-buttons"></slot>
                </div>
            </div>
        </BaseModal>
    </div>
</template>

<script lang="js">
import CORE_TYPES from '@/store/core/types';
import ResizableModal from '@/components/core/ResizableModal.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'DynamicModal',
  components: {
    ResizableModal,
    BaseModal
  },
  props: {
    // Change the modal title
    modalTitle: {
      default: 'Modal Title'
    },
    initialHeight: {
      type: Number,
      default: 400,
    },
    initialWidth: {
      type: Number,
      default: 600,
    },
    sizeIsPercentage: {
      type: Boolean,
      default: false,
    },
    fullClientHeight: {
      type: Boolean,
      default: false,
    },
    startPosition: {
      type: String,
      default: 'BOTTOM_RIGHT',
    },
    saveLastPosition: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: true,
    },
    actions: {
      type: Array
    },
    withBackdropLayer: {
      type: Boolean,
      default: false,
    },
  },
    data() {
        return {
          isVisible: false
        }
    },
  watch: {
    $isSmallScreen() {
        if(this.isVisible) this.$nextTick(() => this.showPopup())
    },
  },

  computed: {
    ...mapGetters({
      backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE,
    }),
    backButtonPeek() {
      return this.backToPreviousData?.slice(-1)?.pop();
    },
  },

  methods: {
    async showPopup() {
        if (this.$isSmallScreen) {
            this.$refs.baseModal?.open()
        } else {
            this.$refs.resizablePopup?.showPopup();
        }

        this.isVisible = true;

        this.$emit('showModal')
    },
    onClose() {
      this.$emit('onClose')
      this.isVisible = false;
    },
    async closePopup() {
        if (this.$isSmallScreen) {
            this.$refs.baseModal?.close()
        } else {
            this.$refs.resizablePopup?.closePopup();
        }

        this.isVisible = false;
    },
    goToLastAction() {
      this.isVisible = false;

      const query = { backAction: true };
      const fullPath = this.backButtonPeek?.fullPath || '/communication/kommunikationsubersicht'

      this.$router.push({ path: fullPath, query: query, });
    },
  }
}
</script>
<style scoped>
.modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.toolbar {
    padding: 10px;
    z-index: 1000;
}
</style>
