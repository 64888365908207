var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label: "langfristige Kredite / Darlehen",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("longTermCredit", $event || null)
          }
        },
        model: {
          value: _vm.form.longTermCredit,
          callback: function($$v) {
            _vm.$set(_vm.form, "longTermCredit", $$v)
          },
          expression: "form.longTermCredit"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "kurzfristige Kredite (Kontokorrent, Zwischenfinanzierungen)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("shortTermCredit", $event || null)
          }
        },
        model: {
          value: _vm.form.shortTermCredit,
          callback: function($$v) {
            _vm.$set(_vm.form, "shortTermCredit", $$v)
          },
          expression: "form.shortTermCredit"
        }
      }),
      _c("InputField", {
        attrs: {
          label:
            "Sonstige Verpflichtungen zu Gunsten Dritter (Verpfändungen, Bürgschaften)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("otherCredit", $event || null)
          }
        },
        model: {
          value: _vm.form.otherCredit,
          callback: function($$v) {
            _vm.$set(_vm.form, "otherCredit", $$v)
          },
          expression: "form.otherCredit"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }