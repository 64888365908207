<template>
  <div>
    <FinanzenVerbindlichkeiten :disabled="isDisabled || isRefusedPageFinance" :finance="finance"
      @changed="valueChangedFinance"/>
    
    <FinanceLineSum  :summe="summeSchulden" title="Summe Verbindlichkeiten / Schulden"/>
  </div>
</template>

<script>

import FinanzenVerbindlichkeiten from '@/components/anlegerprofil/FinanzenVerbindlichkeiten.vue';
import FinanceLineSum from '@/components/anlegerprofil/FinanceLineSum.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    FinanzenVerbindlichkeiten,
    FinanceLineSum,
  },
  mounted() {
    this.updateWarnings();
  },
}
</script>

<style scoped>
</style>