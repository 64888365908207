import BRIDGE_TYPES from './types';
import Vue from 'vue';
import { getInitialState } from './index';

export default {
  [BRIDGE_TYPES.MUTATIONS.LOAD_LOGIN_DATA_SUCCESS](state, payload) {
    Vue.set(state, 'loadedLoginData', payload)
  },
  [BRIDGE_TYPES.MUTATIONS.RESET_LOADED_LOGIN_DATA](state) {
    Vue.set(state, 'loadedLoginData', {})
    Vue.set(state, 'hasLoadedLoginData', false)
  },
  [BRIDGE_TYPES.MUTATIONS.HAS_LOADED_LOGIN_DATA](state) {
    Vue.set(state, 'hasLoadedLoginData', true)
  },
  [BRIDGE_TYPES.MUTATIONS.HAS_PASSWORD_PREVIOUSLY_SAVED](state) {
    Vue.set(state, 'hasPasswordPreviouslySaved', true)
  },
  [BRIDGE_TYPES.MUTATIONS.RESET_HAS_PASSWORD_PREVIOUSLY_SAVED](state) {
    Vue.set(state, 'hasPasswordPreviouslySaved', false)
  },
  [BRIDGE_TYPES.MUTATIONS.MOBILE_NATIVE_CONTEXT](state, payload) {
    Vue.set(state, 'mobileNativeContext', payload)
  },
  [BRIDGE_TYPES.MUTATIONS.SAVE_CLIENT_CALL_ID](state, clientCallId){
    Vue.set(state, 'clientCallId', clientCallId)
  },
  [BRIDGE_TYPES.MUTATIONS.MOBILE_NATIVE_SPEC](state, payload) {
    Vue.set(state, 'mobileNativeSpec', payload)
  },
  [BRIDGE_TYPES.MUTATIONS.RESET_STATE](state) {
    const mobileNativeContext = state.mobileNativeContext
    const mobileNativeSpec = state.mobileNativeSpec
    const trackUserStack = state.trackUserStack
    Object.assign(state, getInitialState())
    Vue.set(state, 'mobileNativeContext', mobileNativeContext)
    Vue.set(state, 'mobileNativeSpec', mobileNativeSpec)
    Vue.set(state, 'trackUserStack', trackUserStack)
  },
  [BRIDGE_TYPES.MUTATIONS.VIRTUAL_KEYBOARD_DETECTED](state, payload) {
    Vue.set(state, 'virtualKeyboardDetected', payload);
  },
  [BRIDGE_TYPES.MUTATIONS.SAVE_RECEIVED_DB_PREFIX](state, payload) {
    Vue.set(state, 'receivedDbPrefix', payload);
  },
  [BRIDGE_TYPES.MUTATIONS.SAVE_SHOW_BUTTON_SWITCH_AUDIO_OUTPUT](state, payload) {
    Vue.set(state, 'showButtonSwitchAudioOutput', payload);
  },
  [BRIDGE_TYPES.MUTATIONS.SET_BIOMETRIC_LOGIN_SETTINGS_RESPONSE](state, payload) {
    Vue.set(state, 'biometricLoginSettings', Object.assign({}, state.biometricLoginSettings, payload));
  },
  [BRIDGE_TYPES.MUTATIONS.SET_AUDIO_OUTPUT](state, payload) {
    Vue.set(state, 'audioOutput', payload);
  },
  [BRIDGE_TYPES.MUTATIONS.SET_DEEPLINK_READY](state, flag) {
    Vue.set(state.deeplink, 'ready', flag === true);
  },
  [BRIDGE_TYPES.MUTATIONS.SET_DEFERRED_DEEPLINK](state, path) {
    // keeps only the last deferred deep link
    Vue.set(state.deeplink, 'deferredPath', path);
  },

  [BRIDGE_TYPES.MUTATIONS.PUSH_TRACK_USER](state, payload) {
    Vue.set(state, 'trackUserStack', state.trackUserStack.concat(payload));
  },

  [BRIDGE_TYPES.MUTATIONS.POP_TRACK_USER](state) {
    Vue.set(state, 'trackUserStack', state.trackUserStack.slice(0, -1));
  },
}
