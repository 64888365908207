import ANTRAG_TYPES from '@/store/antrag/types';
import EXTERNE_ZUGANG_TYPES from '@/store/externeZugang/types';

import { VIEW_ROLES, } from '@/router/roles';

import { PrimaryMenu, MenuItem, GroupMenu, TabMenu, AsyncMenu, ConditionalLabelsBuild, } from './../menu-utils';

import { PhUsers, } from 'phosphor-vue';

import UndrawMyPersonalFiles from '@/components/icons/undraw/UndrawMyPersonalFiles.vue';
import UndrawLogin from '@/components/icons/undraw/UndrawLogin.vue';
import UndrawDataPoints from '@/components/icons/undraw/UndrawDataPoints.vue'
import UndrawGoogleAnalytics from '@/components/icons/undraw/UndrawGoogleAnalytics.vue'
import UndrawRunnerStart from '@/components/icons/undraw/UndrawRunnerStart.vue'
import UndrawDetailedAnalysis from '@/components/icons/undraw/UndrawDetailedAnalysis.vue'
import UndrawCreativity from '@/components/icons/undraw/UndrawCreativity.vue'
import UndrawSecureServer from '@/components/icons/undraw/UndrawSecureServer.vue'
import UndrawWallet from '@/components/icons/undraw/UndrawWallet.vue'
import UndrawContainerShip from '@/components/icons/undraw/UndrawContainerShip.vue'
import UndrawTrends from '@/components/icons/undraw/UndrawTrends.vue';
import UndrawFillForms from '@/components/icons/undraw/UndrawFillForms.vue';
import UndrawOnlineFriends from '@/components/icons/undraw/UndrawOnlineFriends.vue';
import UndrawGoals from '@/components/icons/undraw/UndrawGoals.vue';
import UndrawProjections from '@/components/icons/undraw/UndrawProjections.vue';
import UndrawSteppingUp from '@/components/icons/undraw/UndrawSteppingUp.vue';
import UndrawPreferences from '@/components/icons/undraw/UndrawPreferences.vue';
import UndrawGubbi from '@/components/icons/undraw/UndrawGubbi.vue';

export default PrimaryMenu('/beratung', ConditionalLabelsBuild().add('Service', [VIEW_ROLES.VIEW_CUSTOMER_ONLY]).add('Beratung').build(), PhUsers, [
  MenuItem('/beratung/beratung', 'Beratungsmappen', UndrawMyPersonalFiles),
  MenuItem('/beratung/beratungsmappe_versicherungen', 'Beratungsmappen Versicherung', UndrawMyPersonalFiles),

  MenuItem('/beratung/processes', 'Vorgänge', UndrawMyPersonalFiles),

  GroupMenu('/beratung/formulare', 'Formulare', UndrawFillForms, [
    AsyncMenu(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_OPTION_MENU, 'Formulare Asynchrone Menüs'),
  ]),

  MenuItem('/beratung/anlegerprofil', 'Anlegerprofil', UndrawGoals),
  MenuItem('/beratung/anlegerprofilWK', 'Anlegerprofil WealthKonzept', UndrawGoals),
  GroupMenu('/beratung/vergleichsrechner', 'Versicherungstools', UndrawLogin, [
    AsyncMenu(EXTERNE_ZUGANG_TYPES.ACTIONS.GET_EXTERNE_ZUGANG_VERGLEICH_OPTION_MENU, 'Versicherungstools Asynchrone Menüs'),
  ]),
  MenuItem('/beratung/bestandsuebertragung', 'Bestands&shy;übertragung', UndrawLogin),
  MenuItem('/beratung/anlageempfehlung', 'Anlageempfehlung', UndrawDataPoints),
  MenuItem('/beratung/anlagebeispiel', 'Anlagebeispiel (Anlageempfehlung)', UndrawDataPoints),
  MenuItem('/beratung/wertpapierinfo', 'Wertpapierinfo'),
  MenuItem('/beratung/abschluss-portale', 'Abschluss-Portale', UndrawLogin),
  MenuItem('/beratung/externe-zugaenge', 'Externe Zugänge', UndrawLogin),
  MenuItem('/beratung/ruhestandsplanung-old', 'Ruhestandsplanung', UndrawGoogleAnalytics),
  MenuItem('/beratung/ruhestandsplanung-alt', 'Financial planing alte Version', UndrawGoogleAnalytics),
  MenuItem('/beratung/financial-planing', 'Financial planing', UndrawGoogleAnalytics),
  MenuItem('/beratung/meineziele', 'Meine Ziele', UndrawRunnerStart),
  MenuItem('/beratung/wertpapierorder', 'Wertpapierorder', UndrawFillForms),
  MenuItem('/beratung/fondsvergleich', 'FondsVergleich', UndrawDetailedAnalysis),
  GroupMenu('/beratung/gesellschaften', 'Gesellschaften', UndrawCreativity, [
    MenuItem('/beratung/gesellschaften/FONDSGESELLSCHAFTEN', 'Fondsgesellschaften', UndrawWallet),
    MenuItem('/beratung/gesellschaften/BETEILIGUNGEN', 'Beteiligung', UndrawContainerShip),

    MenuItem('/beratung/gesellschaften/VERSICHERUNGEN_FP_SERVICE_GMBH', 'FP+ Service GmbH Versicherungen', UndrawSecureServer),
    MenuItem('/beratung/gesellschaften/VERSICHERUNGEN_FP_GMBH', 'FP+ GmbH Versicherungen', UndrawSecureServer),
    MenuItem('/beratung/gesellschaften/VERSICHERUNGEN_FP_GEWERBE_GMBH', 'FP+ Gewerbe GmbH Versicherungen', UndrawSecureServer),

    MenuItem('/beratung/gesellschaften/VERSICHERUNGEN', 'Versicherungen', UndrawSecureServer),

    MenuItem('/beratung/gesellschaften/BANKEN_PLATTFORMEN', 'Banken/Plattformen', UndrawWallet),
  ]),

  MenuItem('/beratung/gesellschaft-formulare', 'Formulare', UndrawFillForms),
  MenuItem('/beratung/gesellschaft-versdaten', 'Gesellschaftsdaten Versicherung', UndrawCreativity),
  MenuItem('/beratung/vermittlernummern-overview', 'Vermittlernummern', null, [
    TabMenu('/beratung/vermittlernummern-overview/vermittlernummern', 'Versicherungen', UndrawOnlineFriends),
    TabMenu('/beratung/vermittlernummern-overview/vermittlernummernfondsgesellschaften', 'Fondsgesellschaften', UndrawOnlineFriends),
    TabMenu('/beratung/vermittlernummern-overview/vermittlernummernbank', 'Banken/Plattformen', UndrawOnlineFriends),
    TabMenu('/beratung/vermittlernummern-overview/vermittlernummerngeschlossenefonds', 'Beteiligungen', UndrawOnlineFriends),
    TabMenu('/beratung/vermittlernummern-overview/vermittlernummernallemitarbeiter', 'für alle Mitarbeiter', UndrawOnlineFriends),
  ]),
  MenuItem('/beratung/courtageliste', 'Courtageliste', null, [
    TabMenu('/beratung/courtageliste/konditionen', 'Konditionen', UndrawOnlineFriends),
  ]),  
  MenuItem('/beratung/honorare', 'Honorar / Serviceleistung', UndrawOnlineFriends),
  GroupMenu('/beratung/vv', 'Vermögens&shy;verwaltung', null, [
    MenuItem('/beratung/vv/strategien', 'Strategien'),
    MenuItem('/beratung/vv/strategien-abonnieren', 'Strategien abonnieren'),
    MenuItem('/beratung/vv/produkte?categoryId=vv_produkte', 'Produktliste'),
    MenuItem('/beratung/vv/advisor-fragebogen/wealthkonzept/wk_advisor_fragebogen', 'Advisor-Fragebogen'),
    MenuItem('/beratung/vv/beschwerde', 'Erfassung Kundenbeschwerde'),
  ]),
  GroupMenu('/beratung/fp-plus-zugaenge', 'FP+ Zugänge', UndrawLogin, [
    MenuItem('/beratung/fp-plus-zugaenge/hauptportal_fp_plus', 'Hauptportal FP+', null),
    MenuItem('/beratung/fp-plus-zugaenge/gmbh_data_control', 'GmbH DataControl', null),
    MenuItem('/beratung/fp-plus-zugaenge/gewerbe_data_control', 'Gewerbe DataControl', null),
    MenuItem('/beratung/fp-plus-zugaenge/gbr_data_control', 'GBR DataControl', null),
  ]),
  GroupMenu('/beratung/wertpapiere-alle-kunden', 'Wertpapiere Verkauf / Tausch', UndrawCreativity, [
    MenuItem('/beratung/wertpapiere-alle-kunden/verkauf', 'Verkauf eines Wertpapiers', UndrawOnlineFriends),
    MenuItem('/beratung/wertpapiere-alle-kunden/tausch', 'Tausch / Umschichtung eines Wertpapiers', UndrawOnlineFriends),
  ]),
  GroupMenu('/beratung/finanzanalyse', 'Finanzanalyse', UndrawSteppingUp, [
    MenuItem('/beratung/finanzanalyse/finanzportal24?path=/get_finanzportal24', 'FinanzPlaner Online', UndrawProjections),
    MenuItem('/beratung/finanzanalyse/finanzportal24-online-bestellanleitung?path=/get_finanzportal24_online_bestellanleitung', 'Finanzportal24 Online Bestellanleitung', UndrawProjections),
  ]),
  MenuItem('/beratung/watchliste', 'Watchliste Wertpapiere', UndrawPreferences),
  MenuItem('/beratung/gubbi', 'Gubbi Assets', UndrawGubbi),
]);
