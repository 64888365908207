import CORE_TYPES from '@/store/core/types'
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES } from '@/router/roles';
// import { ROLES } from '@/router/roles';

import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters({
            isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
            isIntern: CORE_TYPES.GETTERS.IS_INTERN,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        canOpenCustomerNewTab() {
            return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN,  BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]])
        },
    },
    methods: {
        /**
         * 
         * @param {*} gesellId 
         * @param {*} gesellArt 
         * 
         * possible extensions: 
         * - reset state could be optional
         * - we could add steps to which shall be navigated
         */
        navigateToGesellschaft(gesellId, gesellArt) {
            this.$store.commit(GESELLSCHAFT_TYPES.MUTATIONS.RESET_STATE);

            if (this.isIntern) {
                this.$router.push(`/intern/gesellschaften/${gesellId}`)
            } else {
                this.$router.push({ 
                    path: `/beratung/gesellschaften/${gesellArt || 'NONE'}/${gesellId}`,
                });
            }
        },
        openCustomerNewTab({customerId, kundennr}, path) {
            if (customerId || kundennr && this.canOpenCustomerNewTab) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: customerId || kundennr, path })
            }
        },
        openBrokerNewTab(brokerId, path) {
            if (brokerId) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId, path })
            }
        },
        openInsurancesNewTab({customerId, kundennr, versId, vertragId}, stepKey) {
            let path = `/home/versicherungen/insurance-group/overview/${versId || vertragId}`;

            if (stepKey) {
                path += `?stepKey=${stepKey}`;
            }
            this.openCustomerNewTab({customerId, kundennr}, path);
        },
        openSchadenNewTab({customerId, versId, vertragId, schadenId}) {
            this.openCustomerNewTab({customerId}, `/home/versicherungen/insurance-group/schadensmeldung/${versId || vertragId}/${schadenId}`);
        },        
        openVermoegenNewTab({customerId}) {
            this.openCustomerNewTab({customerId}, '/home/vermogensubersicht/depot')
        },
    },
}