var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "openSignatureDocumentDetails",
          attrs: {
            showDefaultButtons: false,
            modalTitle: "Offene Unterschriften"
          },
          on: {
            close: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _vm.selectedOpenSignatureDocumentDetail.webserviceWarnings
            ? _c("WebserviceWarnings", {
                staticClass: "mb-2",
                attrs: {
                  webserviceWarnings:
                    _vm.selectedOpenSignatureDocumentDetail.webserviceWarnings
                }
              })
            : _vm._e(),
          _c(
            "div",
            [
              _c("div", { staticClass: "mb-4 mt-2" }, [_vm._t("title")], 2),
              _vm._l(
                _vm.selectedOpenSignatureDocumentDetail.parameters,
                function(param, index4) {
                  return _c("div", { key: index4 }, [
                    param.type === "IMAGE"
                      ? _c("div", [_vm._v(" " + _vm._s(param.label) + " ")])
                      : _vm._e(),
                    param.type !== "IMAGE"
                      ? _c("div", [
                          param.label
                            ? _c("span", [_vm._v(_vm._s(param.label) + ": ")])
                            : _vm._e(),
                          _c("span", [_vm._v(_vm._s(param.value))])
                        ])
                      : _vm._e()
                  ])
                }
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }