<template>
  <div class="box__container">
    <div class="row my-0" :class="{ 'mb-3': !outcome }">
      <div v-if="outcome" class="col-12">
        <p class="m-0">Bitte tragen Sie hier die zu kaufenden Positionen ein:</p>
      </div>
    </div>
    <div v-if="outcome" class="mt-3">
      <WertpapierTable
        :tableId="tableId"
        :rows="rows"
        :headers="headers"
        :singleSelection="singleSelection"
        :selected="isSelectable ? selectedRows : undefined"
        :showMobileSelectionAlways="showMobileSelectionAlways"
        @selected="onSelect"
        @action="handleTableAction"
      />
    </div>

    <div>
      <div class="layout__negative-margin--8 d-flex">
        <div v-if="goBackEnabled">
          <BaseButton 
            isSecondary 
            @click="goBack()">
              {{ zurueckButtonText }}
          </BaseButton>
        </div>
        <div v-if="hasDefaultButton && isSelectable && rows.length">
          <BaseButton @click="getSelectedAndGoBack()" :disabled="!selectedRows.length">
            Auswahl übernehmen
          </BaseButton>
        </div>
      </div>
    </div>
    <BaseModal
        ref="emptySelection"
        modalTitle="Kein Auswahl"
        labelButtonConfirm="Ok"
        :showCancelButton="false">
        <label>Bitte wählen Sie eine Position aus.</label>
    </BaseModal>
    <BaseModal
      ref="overMaxCount"
      labelButtonConfirm="Ok"
      :showCancelButton="false">

      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
          Maximal {{maxCount}} positionen.
      </template>

      <div>Zu viele Positionen ausgewählt.</div>
      <div>Sie können insgesamt {{maxCount}} Positionen hinzufügen.</div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import ANTRAG_TYPES from '@/store/antrag/types';
import BaseButton from '@/components/core/BaseButton.vue';
import InputRadioBoxItem from '@/components/core/forms/radiobox/InputRadioBoxItem.vue';
import { calculateAntragID } from '../antrag-utils';
import BaseModal from "@/components/core/BaseModal.vue";

import WERTPAPIERINFO_TYPES from "@/store/wertpapierinfo/types";
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import Table from '@/components/table/Table.vue';
import Pill from '@/components/core/Pill.vue';
import { PhDotsThreeVertical, PhFileArrowDown, PhFile, PhFileText, PhWarning } from 'phosphor-vue'
import BaseDropdownMenu from "@/components/core/BaseDropdownMenu.vue";
import {wpListeMixin} from '@/mixins/wp-suche.js';
import NoData from '@/components/core/NoData.vue';
import WertpapierTable from '@/views/brokerData/WertpapierTable.vue'

export default {
  components: {
    BaseButton, BaseDropdownMenu, BaseModal,
    InputRadioBoxItem, InputCheckBoxItem, 
    Pill, PhDotsThreeVertical, PhFileArrowDown, PhFile, PhFileText, PhWarning,
    Table,
    NoData,
    WertpapierTable,
  },
  mixins: [wpListeMixin],
  props: {
    lagId: {
      type: String,
      default: '',
    },
    zurueckButtonText: {
      type: String,
      default: 'Zurück zum Antrag',
    },
    hasDefaultButton: {
      type: Boolean,
      default: true,
    },
    singleSelectionProp: {
      type: Boolean,
      default: false
    },
    goBackEnabled: {
      type: Boolean,
      default: true
    },
    addPositionsOnBack: {
      type: Boolean,
      default: true,
    },
    isSelectable: {
      type: Boolean,
      default: true,
    },
    ignoreLagerstelle: {
      type: Boolean,
      default: false,
    },
    showMobileSelectionAlways: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      singleSelection: false,
      categoryId: '',
      lagerstelle: '',
      antragsName: '',
      antragId: '',
      saveType: '',
      suppressFilterLagerstelle: false,
      rowsPerPage: 10,
      currentPageIndex: 0,
      maxCount: '',

      gesellschaft: '',
      act: '',
      idxPending: -1,

      lockedColumns: {
        toLeft: [
          { key: 'selected' },
        ],
        toRight: [],
      },
      selectedRows: [],
    }
  },
  computed: {
    ...mapGetters({
      getWPIAct: WERTPAPIERINFO_TYPES.GETTERS.ACT,
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
    }),
    antrag() {
      if (this.antraege && this.antraege[this.antragId]) {
        return this.antraege[this.antragId]
      }
      return null;
    },
    antragTyp() {
      return this.antrag && this.antrag.antragTyp
    },
    outcome() {
      return this.getWPIAct(this.act)?.outcome
    },  
    headers() {
      return this.outcome?.headers || []
    },
    rows() {
      return this.outcome?.records || []
    },
    instData() {
      const result = this.getWPIAct(this.act);
      return result;
    },
    wp_type() {
      return this.instData && this.instData.phase && this.instData.phase.wp_type || '1';
    },
    tableId() {
      const idx = parseInt(this.wp_type) - 1;
      return [
        '4e0e84db-bc3a-4e4a-87fa-eea4d638359f',
        '934a4c92-187b-47ad-b8f7-6400e6704ed4',
        '1a5a37ee-2b56-4237-b490-b6d4e03203fc',
      ][idx];
    },
    getLagerstelle() {
      return !this.ignoreLagerstelle && (this.$route.query.lagerstelle || this.lagId || this.$route.params.lagerstelle || '') || '';
    },
  },
  created() {
    this.getParametersFromRouter();
  },
  watch: {
    outcome: {
      handler() {
        this.selectedRows = []
      },
      immediate: true,
    },
  },
  methods: {
    goBack(fonds = []) {
      this.$emit('goBack', fonds);
    },
    getSelectedOutcome(data = {}) {
      this.$emit('getSelectedOutcome', data);
    },
    getParametersFromRouter() {
      // this.lagerstelle = this.lagId || this.$route.params.lagerstelle || this.$route.query.lagerstelle || '';
      this.lagerstelle = this.getLagerstelle;
      this.categoryId = this.$route.params.categoryId  || this.$route.query.categoryId || '';
      this.antragsName = this.$route.params.antragsName
      this.antragId = calculateAntragID({antragsName: this.antragsName, lagerstelle: this.lagerstelle})
      this.singleSelection = this.categoryId === 'VWL' || !!this.$route.query.singleSelection || this.singleSelectionProp;
      this.saveType = this.$route.query.saveType;
      this.maxCount = this.$route.query.maxCount;
      
      this.act = 'search_'+ this.categoryId +'.'+ this.gesellschaft +'.'+ this.lagerstelle;
    },
    getClassValueAmount(value) {
      return value ? (value < 0 ? 'negative-number' : 'positive-number') : ''
    },
    getSelectedAndGoBack() {
      const data = this.getSelected();
      if (data) {
        if (!this.isOverMaxCount(data)) {
          this.goBack(data.positionInfo);
          this.getSelectedOutcome({
            headers: this.headers,
            records: data.positionInfo
            });
          this.$store.commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT, {
            act: this.act, 
            outcome: null,
          });
        }
      } else {
        this.$refs.emptySelection.open();
      }
    },
    isOverMaxCount(isins) {
      if (this.maxCount && isins && isins.length) {
        return isins.length > +this.maxCount;
      }
    },
    getSelected() {
      const isins = {};
      let positionInfo = [];

      this.selectedRows.forEach(row => {
        if (row.isin) {
          isins[row.isin] = this.categoryId;
        } else {
          // this should never be the case
          isins[row._fondsData.isin] = this.categoryId;
        }
        positionInfo.push(row);
      });

      positionInfo.forEach(p => {
        p.wertpapiername = p.fondsname;
        p.isin = p.isin || p._fondsData?.isin,
        p.wkn = p.old_wkn;
        p.defaultAusgabeaufschlag = p.aa_netto;
        p.anteilspreis = p.ruecknahme_preis_fondswaehrung;
        p.sri = p.sri_zielmarkt;
        p.srri = p.srri_zielmarkt;
        p.fundstars = p.fundstars_fww;
        if (p.fundstars && typeof p.fundstars === 'number') {
          p.fundstarsStr = '\u272a\u272a\u272a\u272a\u272a'.substr(0, p.fundstars)
        }
      });
      
      if (Object.keys(isins).length) {
       
        const payload = {
          antragTyp: this.antragTyp || null,
          antragId: this.antragId || null,
          categoryId: this.categoryId,
          saveType: this.saveType,
          singleSelection: this.singleSelection,
          positionInfo,
          checkPositions: {
            lagerstelle: this.lagerstelle,
            isins
          },
          additionalIdentifier: this.$route.query.additionalIdentifier || null,
        }
        if (this.categoryId === 'TAUSCH' && positionInfo && positionInfo.length) {
          const tauschObj = {
            tauschZuName: this.$route.query.tauschZuName,
            tauschVonName: this.$route.query.tauschVonName,
            tauschVonValue: this.$route.query.tauschVonValue,
            tauschVonIsin: this.$route.query.tauschVonIsin,
            isinVerkauf: this.$route.query.isinVerkauf,
          };
          positionInfo = positionInfo.map(info => Object.assign(info, tauschObj));
        }
        
        if (this.addPositionsOnBack) {
          this.$store.dispatch(FONDSFINDER_TYPES.ACTIONS.TAKE_SELECTED_ISINS, payload)
        }
        return payload;
      }
    },
    onSelect(rows) {
      this.selectedRows = rows
      this.$emit('selected', this.selectedRows);
    },
  },
  
}
</script>