var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseFilter", {
        attrs: {
          filterId: "47546ded-8fac-495a-8667-7696a0e9050d",
          metadata: _vm.searchMenu,
          defaultOptions: _vm.defaultOptionsData,
          configFilter: _vm.configFilter,
          isCustomerSearch: false,
          hasSmartSearch: "",
          showSaveButton: "",
          isCache: ""
        },
        on: { onFilter: _vm.search }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : !_vm.rows || !_vm.rows.length
            ? _c("NoData")
            : _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 50,
                  rowId: "depotnr",
                  title: "Portfolios",
                  tableId: "090cc497-99c5-48dd-b83f-92790b551d66"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }