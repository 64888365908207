import CUSTOMER_SEARCH_TYPES from './types';
import CORE_TYPES from '../core/types';
import WEBRTC_TYPES from '@/store/webrtc/types'
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

import { VIEW_ROLES } from '@/router/roles';

import { looksLikeKundennr } from '@/views/customer/customer-utils';

function convertLastCustomerOpenedToOpenCustomer(customer) {
  return {
    data: {
      person: {
        personalDataAddress: {
          ...customer,
        },
      },
      customerID: customer.customerId,
      ...customer,
      _source: 'LAST_CUSTOMERS_OPENED',
    },
  };
}

const config = {
  defaultSpinner: true,
};

export default {
  
  async [CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_CUSTOMER]({ commit, state, dispatch, rootState, getters }, payload) {
    if (payload && getters[CORE_TYPES.GETTERS.IS_WEBRTC_MAKLERZUGANG]) {
      payload.loadChatBeteiligterIds = true;
    }
    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Kundenfilter'
      }
    };
    return await axios.post(rootState.core.apiAddress + '/customerFilters/getCustomersFiltered', payload, config)
    .then(response => {
      if (response && response.data) {
        if (typeof response.data.askPIN == 'number') {
          commit(CORE_TYPES.MUTATIONS.CHECK_PIN, {
            key: 'TABELLE_KUNDENLISTE', 
            value: response.data.askPIN,
            action: CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_CUSTOMER,
            payload: JSON.stringify(payload)
          })
        }
        commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.SEARCH_CUSTOMER_SUCCESS, {responseData: response.data, section: payload.section});
        if (response.data.outputs && payload.loadChatBeteiligterIds) {
          response.data.outputs.forEach(customerData => {
            if (customerData.calleeChatBeteiligterId)
              commit(WEBRTC_TYPES.MUTATIONS.UPDATE_AVAILABILITY, {
                calleeChatBeteiligterId: customerData.calleeChatBeteiligterId,
                available: customerData.calleeChatBeteiligterAvailable,
              });
          });
        }
      }
    })
    .catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_CUSTOMER", error});
    });
  },

  [CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_CUSTOMER_EXPORT]({ commit, rootState, getters, state }, payload) {
    const config = {
      defaultSpinner: true,
      // cancelableRequest: {
      //   title: 'Export Kundenliste'
      // }
    };
    return axios.post(rootState.core.apiAddress + '/customerFilters/exportSearchCustomers', payload, config);
  },

  [CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_CUSTOMER_SETUP]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultErrorMessage: true,
      disableDefaultLog: true,
    };
    let url = '/mrscustfiltersetup';
    axios.get(rootState.core.apiAddress + '/..' + url, config).then(response => {
      if (response && response.data) {
        commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_SEARCH_CUSTOMER_SETUP_SUCCESS, response);
      }
    }).catch(() => {
      // temporarily do nothing 
    })
  },
  [CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_CUSTOMER_SETUP_DEPOT]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrscustfiltersetupdepot';
    axios.get(rootState.core.apiAddress + '/..' + url, {config, params: payload}).then(response => {
      if (response && response.data) {
        commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_SEARCH_CUSTOMER_SETUP_DEPOT_SUCCESS, response);
      }
    })
  },
  [CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_CUSTOMER_SETUP_VERS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrscustfiltersetupvers';
    axios.get(rootState.core.apiAddress + '/..' + url, config).then(response => {
      if (response && response.data) {
        commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_SEARCH_CUSTOMER_SETUP_VERS_SUCCESS, response);
      }
    })
  },

  async [CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_BROKER]({ commit, state, dispatch, rootState }, payload) {
    let section = payload.pageId + 1;
    const params = {
      section,
      ...(payload.filterType ? {filterType: payload.filterType} : {}),
      maxCount: (payload.maxCount || 20),
    }
    if (payload.sendKeys?.length) {
      payload.sendKeys.forEach(key => {
        if (payload[key] != undefined) {
          params[key] = payload[key];
        }
      })
    }
    await axios.get(rootState.core.apiAddress + '/brokerSearch/getBrokers', {
      ...config,
      params,
      // {
      //   section,
      //   ...(payload.filterType ? {filterType: payload.filterType} : {}),
      //   maxCount: (payload.maxCount || 20),
      //   ...(payload.maxCount ? {maxCount: payload.maxCount} : {}),

      //   ...(payload.brokerId ? {brokerId: payload.brokerId} : {}),
      //   ...(payload.customerId ? {customerId: payload.customerId} : {}),

      //   ...(payload.structure ? {structure: payload.structure} : {}),
      //   ...(payload.includeSelf ? {includeSelf: payload.includeSelf} : {}),
      //   ...(payload.firma ? {firma: payload.firma} : {}),
      //   ...(payload.firstName ? {firstName: payload.firstName} : {}),
      //   ...(payload.surname ? {surname: payload.surname} : {}),
      //   ...(payload.strukturleiter ? {strukturleiter: payload.strukturleiter} : {}),
      //   ...(payload.fremdnummer ? {fremdnummer: payload.fremdnummer} : {}),
      //   ...(payload.referenznummer ? {referenznummer: payload.referenznummer} : {}),

      //   ...(payload.strasse ? {strasse: payload.strasse} : {}),
      //   ...(payload.plz ? {plz: payload.plz} : {}),
      //   ...(payload.plzVon ? {plzVon: payload.plzVon} : {}),
      //   ...(payload.plzBis ? {plzBis: payload.plzBis} : {}),
      //   ...(payload.ort ? {ort: payload.ort} : {}),
      //   ...(payload.fehlendeAngaben ? {fehlendeAngaben: payload.fehlendeAngaben} : {}),
      //   ...(payload.gesellschaftDepot ? {gesellschaftDepot: payload.gesellschaftDepot} : {}),
      //   ...(payload.lagerstelleDepot ? {lagerstelleDepot: payload.lagerstelleDepot} : {}),
      //   ...(payload.wertpapier ? {wertpapier: payload.wertpapier} : {}),
      //   ...(payload.isin ? {isin: payload.isin} : {}),
      //   ...(payload.mitBestandInvestment ? {mitBestandInvestment: payload.mitBestandInvestment} : {}),
      //   ...(payload.mitBestandInvestmentStruktur ? {mitBestandInvestmentStruktur: payload.mitBestandInvestmentStruktur} : {}),
      //   ...(payload.gesellschaftVers ? {gesellschaftVers: payload.gesellschaftVers} : {}),
      //   ...(payload.lagerstelleVers ? {lagerstelleVers: payload.lagerstelleVers} : {}),
      //   ...(payload.email ? {email: payload.email} : {}),
      //   ...(payload.ohneGeldwaesche ? {ohneGeldwaesche: payload.ohneGeldwaesche} : {}),
      //   ...(payload.mitZuverlaessigkeit ? {mitZuverlaessigkeit: payload.mitZuverlaessigkeit} : {}),
      //   ...(payload.ohneZuverlaessigkeit ? {ohneZuverlaessigkeit: payload.ohneZuverlaessigkeit} : {}),
      //   ...(payload.withPerson ? {withPerson: payload.withPerson} : {}),
      //   ...(payload.maklerkriterien? {maklerkriterien: payload.maklerkriterien} : {}),
      //   ...(payload.maklerkriterienText ? {maklerkriterienText: payload.maklerkriterienText} : {}),

      //   ...(payload.emailInvstmentfonds ? {emailInvstmentfonds: payload.emailInvstmentfonds} : {}),
      //   ...(payload.emailGeschlosseneFonds ? {emailGeschlosseneFonds: payload.emailGeschlosseneFonds} : {}),
      //   ...(payload.emailAnsprechspartner ? {emailAnsprechspartner: payload.emailAnsprechspartner} : {}),
      //   ...(payload.vertragVon ? {vertragVon: payload.vertragVon} : {}),
      //   ...(payload.vertragBis ? {vertragBis: payload.vertragBis} : {}),
      //   ...(payload.vertragZuruekVon ? {vertragZuruekVon:payload.vertragZuruekVon} : {}),
      //   ...(payload.vertragZuruekBis ? {vertragZuruekBis: payload.vertragZuruekBis} : {}),
      //   ...(payload.vertragGekuendigt ? {vertragGekuendigt: dayjs(payload.vertragGekuendigt).format("DD.MM.YYYY")} : {}),
      //   ...(payload.verschiktNichtZuruekerhalten ? {verschiktNichtZuruekerhalten: payload.verschiktNichtZuruekerhalten} : {}),
      //   ...(payload.verschikt ? {verschikt: payload.verschikt} : {}),
      //   ...(payload.zuruekerhalten ? {zuruekerhalten: payload.zuruekerhalten} : {}),

      //   ...(payload.vertragVonAK ? {vertragVonAK: payload.vertragVonAK} : {}),
      //   ...(payload.vertragBisAK ? {vertragBisAK: payload.vertragBisAK} : {}),
      //   ...(payload.vertragZuruekVonAK ? {vertragZuruekVonAK: payload.vertragZuruekVonAK} : {}),
      //   ...(payload.vertragZuruekBisAK ? {vertragZuruekBisAK: payload.vertragZuruekBisAK} : {}),
      //   ...(payload.vertragGekuendigtAK ? {vertragGekuendigtAK: dayjs(payload.vertragGekuendigtAK).format("DD.MM.YYYY")} : {}),
      //   ...(payload.verschiktNichtZuruekerhaltenAK ? {verschiktNichtZuruekerhaltenAK: payload.verschiktNichtZuruekerhaltenAK} : {}),
      //   ...(payload.verschiktAK ? {verschiktAK: payload.verschiktAK} : {}),
      //   ...(payload.zuruekerhaltenAK ? {zuruekerhaltenAK: payload.zuruekerhaltenAK} : {}),

      //   ...(payload.kategorie ? {kategorie: payload.kategorie} : {}),
      //   ...(payload.potential ? {potential: payload.potential} : {}),
      //   ...(payload.interessent ? {interessent: payload.interessent} : {}),
      //   ...(payload.servicegebuerSpezial ? {servicegebuerSpezial: payload.servicegebuerSpezial} : {}),
      //   ...(payload.mitStornorisiko ? {mitStornorisiko: payload.mitStornorisiko} : {}),
      //   ...(payload.sonstigeSicherheiten ? {sonstigeSicherheiten: payload.sonstigeSicherheiten} : {}),
      //   ...(payload.uebertragskontoVon ? {uebertragskontoVon: payload.uebertragskontoVon} : {}),
      //   ...(payload.uebertragskontoBis ? {uebertragskontoBis: payload.uebertragskontoBis} : {}),
      //   ...(payload.ohneWitschaft ? {ohneWitschaft: payload.ohneWitschaft} : {}),
      //   ...(payload.dokPruefungAusstehend ? {dokPruefungAusstehend: payload.dokPruefungAusstehend} : {}),
      //   ...(payload.buergelSchlechter ? {buergelSchlechter: payload.buergelSchlechter} : {}),
      //   ...(payload.buergelBesser ? {buergelBesser: payload.buergelBesser} : {}),
      //   ...(payload.evistraFehlederUpload ? {evistraFehlederUpload: payload.evistraFehlederUpload} : {}),
      //   ...(payload.evistraHatteVorjahr ? {evistraHatteVorjahr: payload.evistraHatteVorjahr} : {}),
      //   ...(payload.evistraNeuerVermittler ? {evistraNeuerVermittler: payload.evistraNeuerVermittler} : {}),

      //   ...(payload.besuchsberichtTeilnehmer ? {besuchsberichtTeilnehmer: payload.besuchsberichtTeilnehmer} : {}),
      //   ...(payload.besucher ? {besucher: payload.besucher} : {}),
      //   ...(payload.nichtAnzeigen ? {nichtAnzeigen: payload.nichtAnzeigen} : {}),
      //   ...(payload.besuchtsberichtVon ? {besuchtsberichtVon: payload.besuchtsberichtVon} : {}),
      //   ...(payload.besuchtsberichtBis ? {besuchtsberichtBis: payload.besuchtsberichtBis} : {}),
      //   ...(payload.telefonischerTermin ? {telefonischerTermin: payload.telefonischerTermin} : {}),
      //   ...(payload.ausentermin ? {ausentermin: payload.ausentermin} : {}),
      //   ...(payload.hobbies ? {hobbies: payload.hobbies} : {}),
      //   ...(payload.betreuer ? {betreuer: payload.betreuer} : {}),
      //   ...(payload.istGekuendigt ? {istGekuendigt: payload.istGekuendigt} : {}),
      //   ...(payload.istGekuendigtAK ? {istGekuendigtAK: payload.istGekuendigtAK} : {}),

      //   ...(payload.courtageAbschlussWp ? {courtageAbschlussWp: payload.courtageAbschlussWp} : {}),
      //   ...(payload.courtageAbschlussWpBis ? {courtageAbschlussWpBis: payload.courtageAbschlussWpBis} : {}),
      //   ...(payload.courtageBestand ? {courtageBestand: payload.courtageBestand} : {}),
      //   ...(payload.courtageBestandBis ? {courtageBestandBis: payload.courtageBestandBis} : {}),
      //   ...(payload.courtageVWL ? {courtageVWL: payload.courtageVWL} : {}),
      //   ...(payload.courtageVWLBis ? {courtageVWLBis: payload.courtageVWLBis} : {}),
      //   ...(payload.courtageAbschlussVers ? {courtageAbschlussVers: payload.courtageAbschlussVers} : {}),
      //   ...(payload.courtageAbschlussVersBis ? {courtageAbschlussVersBis: payload.courtageAbschlussVersBis} : {}),
      //   ...(payload.courtageBestandVers ? {courtageBestandVers: payload.courtageBestandVers} : {}),
      //   ...(payload.courtageBestandVersBis ? {courtageBestandVersBis: payload.courtageBestandVersBis} : {}),
      //   ...(payload.emailVersicherungen ? {emailVersicherungen: payload.emailVersicherungen} : {}),
      //   ...(payload.vermittlerAktiv ? {vermittlerAktiv: payload.vermittlerAktiv} : {}),
      //   ...(payload.vermittlerInaktiv ? {vermittlerInaktiv: payload.vermittlerInaktiv} : {}),
      //   ...(payload.maklerPassiv ? {maklerPassiv: payload.maklerPassiv} : {}),
      //   ...(payload.keineMaklerPassiv ? {keineMaklerPassiv: payload.keineMaklerPassiv} : {}),
      //   ...(payload.vvAdvisor ? {vvAdvisor: payload.vvAdvisor} : {}),
      //   ...(payload.sort ? {sort: payload.sort} : {}),
      //   ...(payload.sortDirection ? {sortDirection: payload.sortDirection} : {}),
       
      // }
    }
    ).then(response => {
        if (response && response.data) {
          if (typeof response.data.askPIN == 'number') {
            commit(CORE_TYPES.MUTATIONS.CHECK_PIN, {
              key: 'TABELLE_VERMITTLERLISTE', 
              value: response.data.askPIN,
              action: CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_BROKER,
              payload: JSON.stringify(payload)
            })
          }
          commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.SEARCH_BROKER_SUCCESS, {...response.data, pageId: payload.pageId});
        }
    })
  },

  [CUSTOMER_SEARCH_TYPES.ACTIONS.EXPORT_BROKERS]({ rootState }, payload) {
    return axios.post(rootState.core.apiAddress + '/brokerSearch/exportBrokers', payload, {defaultSpinner: true});
  },

  [CUSTOMER_SEARCH_TYPES.ACTIONS.GET_BROKER_START_PANELS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let url = '/mrslistbrokerspanels';
    axios.get(rootState.core.apiAddress + '/..' + url, config).then(response => {
      if (response && response.data) {
        Promise.all(
          response.data.objects.map(value => axios.get(rootState.core.apiAddress + '/../' + value, config))
        ).then(response => commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_BROKER_START_PANELS_SUCCESS, response))
      }
    })
  },

  [CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_BROKER_SETUP]({ commit, rootState }) {
    axios.get(rootState.core.apiAddress + '/brokerSearch/brokerFilterSetup', config).then(response => {
      if (response && response.data) {
        commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_SEARCH_BROKER_SETUP_SUCCESS, response.data);
      }
    })
  },

  [CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_CONTACTS_SETUP]({ commit, rootState }) {
    axios.get(rootState.core.apiAddress + '/ContactFilters/filterSetup', config).then(response => {
      if (response && response.data) {
        commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_SEARCH_CONTACTS_SETUP_SUCCESS, response.data);
      }
    })
  },

  async [CUSTOMER_SEARCH_TYPES.ACTIONS.TRACK_OPEN_CUSTOMER]({ getters, commit, }, { customerId }) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    if(hasRoles([VIEW_ROLES.VIEW_CUSTOMER]) || !looksLikeKundennr(customerId)) {
      return;
    }

    const config = {
      defaultSpinner: true,
    };

    try {
      // sets the customer as the first item, before the save request - it is to avoid rough reorder and inconsistency when the request is slow
      commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, convertLastCustomerOpenedToOpenCustomer({ customerId }));

      // saves the last customer opened
      const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/last_customers_opened`, customerId, {
        ...config,
        headers: {
          'Content-Type': 'text/plain',
        },
      })

      const lastCustomersOpened = [ ...response?.data || [] ];
      lastCustomersOpened.forEach(customer => {
        commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.UPDATE_LAST_OPENED_CUSTOMER_DATA_ONLY, convertLastCustomerOpenedToOpenCustomer(customer));
      });
    } catch (error) {
      // empty block
    }
  },

  async [CUSTOMER_SEARCH_TYPES.ACTIONS.GET_LAST_CUSTOMERS_OPENED]({ getters, commit, }) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    const openedCustomers = getters[CUSTOMER_SEARCH_TYPES.GETTERS.OPEN_CUSTOMERS];
    if(!hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_INTERN]) || openedCustomers?.filter(oc => oc?.data?._source === 'LAST_CUSTOMERS_OPENED')?.length > 0) {
      return;
    }

    const config = {
      defaultSpinner: true,
    };

    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/last_customers_opened`, config)
      const lastCustomersOpened = [ ...response?.data || [] ];
      lastCustomersOpened.forEach(customer => {
        commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, convertLastCustomerOpenedToOpenCustomer(customer));
      });
    } catch (error) {
      // empty block
    }
  },

  [CUSTOMER_SEARCH_TYPES.ACTIONS.KUNDEN_BEREINIGEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/kundenBereinigen';

      axios.get(`${rootState.core.apiAddress}${serviceUrl}?${makeQueryParam(payload)}`, config).then(response => {
        if (response && response.data) {
  
          commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.KUNDEN_BEREINIGEN_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });
  },

  [CUSTOMER_SEARCH_TYPES.ACTIONS.SET_KUNDEN_INAKTIV]({ rootState }, {customerIds, inaktiv, seqId, invertSelection}) {
    const config = {
      defaultSpinner: true
    };

    const params = makeQueryParam({inaktiv, seqId, invertSelection})

    return new Promise((resolve, reject) => {
      axios.post(`${rootState.core.apiAddress}/customerFilters/setKundenInaktiv?${params}`, customerIds, config).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },

  [CUSTOMER_SEARCH_TYPES.ACTIONS.KUNDEN_LOESCHEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/kundenLoeschen';

      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.KUNDEN_LOESCHEN_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });
  },


  [CUSTOMER_SEARCH_TYPES.ACTIONS.KUNDEN_LOESCHEN_RESTRICTIONS]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/kundenLoeschenRestrictions';

      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.KUNDEN_LOESCHEN_RESTRICTIONS_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });
  },

  
  async [CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_CONTACT]({ commit, state, dispatch, rootState, getters }, payload) {
    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Kontaktefilter'
      }
    };
    const params = {
      ...payload,
      section: payload.section + 1,
    }
    return await axios.post(rootState.core.apiAddress + '/ContactFilters/getContactsFiltered', params, config)
    .then(response => {
      if (response && response.data) {
        commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.SEARCH_CONTACT_SUCCESS, {responseData: response.data, section: payload.section});
      }
    })
    .catch(error => {
      dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_CUSTOMER", error});
    });
  },
  [CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_CONTACT_EXPORT]({rootState }, payload) {
    const config = {
      defaultSpinner: true,
    };
    return axios.post(rootState.core.apiAddress + '/ContactFilters/getContactsExport', payload, config);
  }

}
