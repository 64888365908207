/* eslint-disable no-console */

import { register, unregister } from 'register-service-worker';
import router from '@/router';
import store from '@/store';
import LOG_TYPES from '@/store/log/types';

const INTERVAL_TIME = 15 * 60 * 1000;

export const AppUpdatedEvent = {
  name: 'app-updated',
  dispatch(mandatory = false, registration = null) {
    const { name } = this;
    document.dispatchEvent(new CustomEvent(name, {
      detail: {
        mandatory,
        registration,
      },
    }));
  },
};

function createCheckForUpdateGuard(registration) {
  let lastTimeCheck = null;
  return (to, from, next) => {
    if (!lastTimeCheck || ((Date.now() - lastTimeCheck) > INTERVAL_TIME)) {
      store.dispatch(LOG_TYPES.ACTIONS.INFO, 'check for update guard');
      registration?.update();
      lastTimeCheck = Date.now();
    }
    next();
  };
}

if ((process.env.VUE_APP_ENABLE_SERVICE_WORKER+'') === 'true') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered (registration) {
      console.log('Service worker has been registered.')

      registration.update();
      router.beforeEach(createCheckForUpdateGuard(registration));
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      AppUpdatedEvent.dispatch(false, registration);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  });
} else {
  unregister()
}
