<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <AntragHeaderTitleNavigation 
      :disabled="!data || !data.antragsdatenId || data.dataHasChanged || isDisabled"
      antragId="wertpapierorder"
      antragTyp="ORDERPOSITION"
      :antragData="data"
      :warnings="warnings"
      :actionConfigs="actionConfigs"
      :hatPDF = "data.hatPDF"
    >
      <template #title>Wertpapierorder</template>
      <template #subtitle>
        {{lagerstelleLabel}} | {{depotNrLabel}}
      </template>
    </AntragHeaderTitleNavigation>

    <div class="row my-0">
      <div class="d-lg-none col-12">
        <div class="box__container">
          <Stepper2 v-if="stepper"
            ref="refStepper2"
            stepType="step"
            :visible="true"
            stepperName="wertpapierorderStepper"
            :selectedStepKey="routeStep"
            :stepperService="stepper"
            @setStep="navigateToStep($event)"
          ></Stepper2>
        </div>
      </div>
    </div>
    <div class="row my-0">
      <div class="col-12" v-if="getStepKey !== 'aktionen'">
        <AntragSidetext
          antragId="wertpapierorder"
          :antragData="data"
          :warnings="warnings"
          :steps="stepper && stepper.getCurrentStepList()"
          :highestStepVisited="getHighestStepVisited"
          @navigateToWarning="goToStep($event)"
        >
        </AntragSidetext>
      </div>
    </div>

    <ContentWithStepper :stepperCollapsed="stepperCollapsed">
      <template #stepper>
        <div class="box__container">
          <VerticalStepper
            ref="refStepper"
            :selectedStepKey="routeStep"
            :stepperService="stepper"
            :showSaveSpinner="saveAntragState"
            :showCollapse="true"
            @setStep="navigateToStep($event)"
            @collapsed="stepperCollapsed = $event"
          ></VerticalStepper>
        </div>
      </template>
      <template #content>
        <div>
          <WertpapierorderStepContainer @ebaseEdelmetalFound = "setEbaseEdelmetalTemplate" 
           :focusOnPosition="focusOnPosition" 
            @navigateToWarning="goToStep($event)"/>
          <div class="box__container">
            <div>
              <div class="d-flex layout__negative-margin--8">
                <BaseButton
                  @click="prevStep()"
                  :isPrimary="false"
                  :isSecondary="true"
                  :disabled="isLoading"
                  v-if="getStepKey !== 'main'">
                  Zurück <AnimatedSpinner v-if="showPreviousSpinner"/>
                </BaseButton>
                <BaseButton
                  @click="nextStep()"
                  :isPrimary="true"
                  :isSecondary="false"
                  :disabled="isLoading"
                  v-if="getStepKey !== 'aktionen'">
                  Weiter <AnimatedSpinner v-if="snowNextSpinner"/>
                </BaseButton>
                <BaseButton
                  @click="fertigstellen"
                  isPrimary
                  :isSecondary="false"
                  :disabled="isDisabled"
                  v-if="getStepKey === 'aktionen' && !isCustomerLogin">
                  Fertigstellen
                </BaseButton>
                <BaseButton
                  @click="signPdf"
                  isPrimary
                  :isSecondary="false"
                  :disabled="isDisabled || hasStatusFehler"
                  v-if="getStepKey == 'aktionen' && isCustomerLogin">
                  PDF signieren
                </BaseButton>
                <BaseButton
                  @click="abbrechen"
                  isSecondary
                  :disabled="isDisabled"
                  v-if="getStepKey == 'aktionen' && isCustomerLogin">
                  Abbrechen
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ContentWithStepper>

    <WertpapierorderInitModal ref="initModal"/>
    <BaseModal ref="infoModal" :showDefaultButtons="false">
          <template v-slot:modalTitle>
             Bitte warten...  <AnimatedSpinner/>
          </template>
          <div>
              <span>Warte auf Antwort vom Webservice... <AnimatedSpinner /></span>
          </div>
      </BaseModal>
    
    <BaseModal
      ref="vorlegenModal"
      modalTitle="Dokument zur E-Signatur vorgelegen"
      labelButtonConfirm="Ja"
      labelButtonCancel="Nein"
      @onConfirmButton="vorlegen"
      @onCancelButton="close"
      @onCloseButton="close" >
      <div>
        Soll das Dokument in der "Unterschreiben" Liste (beim Vermittler und beim Kunden) angezeigt werden?
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
import mixin,  {PATH_CUSTOMER}  from "@/mixins/wertpapierorder/mixin.js";
import WERTPAPIERORDER_TYPES from "@/store/wertpapierorder/types";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import { mapGetters } from "vuex";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import AntragHeaderTitleNavigation from '@/components/antrag/AntragHeaderTitleNavigation.vue';
import AntragSidetext from "@/components/antrag/AntragSidetext.vue";
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue'
import ContentWithStepper from '@/components/core/ContentWithStepper.vue'
import Stepper2 from '@/components/stepper/Stepper2.vue';
import ANTRAG_TYPES from '@/store/antrag/types'
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import WertpapierorderStepContainer from '@/components/wertpapierorder/WertpapierorderStepContainer.vue';
import WertpapierorderInitModal from '@/components/wertpapierorder/WertpapierorderInitModal.vue';
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import BaseBackButtonBreadcrumb from '@/components/core/BaseBackButtonBreadcrumb.vue'
import BaseModal from "@/components/core/BaseModal.vue";
import EVENT_BUS, { ANTRAG_SIGN_ACTION, ANTRAG_E_SIGNATUR, ANTRAG_CLOSE } from '@/event-bus';

export default {
  mixins: [mixin],
  components: {
    BaseButton,
    OptionMenu,
    AntragHeaderTitleNavigation,
    AntragSidetext,
    VerticalStepper,
    ContentWithStepper,
    Stepper2,
    AnimatedSpinner,
    WertpapierorderStepContainer,
    WertpapierorderInitModal,
    BaseBackButtonBreadcrumb,
    BaseModal
  },
  data() {
    return {
      showHeaderCard: true,
      stepperCollapsed: false,
      lagerstelleSelectorConfirmDisabled: true,
      lastClickedButton: '',
      focusOnPosition: '',
      geldwaescheVisible: false,
      depotNr: '',
      FREIE_EINGABE: 'freie Eingabe:',
      lagerstelleSelect: '',
      depotSelect: '',
      kontoFreeText: '',
    };
  },
  mounted() {
    if (!this.$route.query.backAction) {
      this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.RESET_STATE);
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.RESET_ANTRAG_ACTION_LOADING_ACTIVE);
    }
    this.loadData();
    this.updateStepperWithWarnings();
    this.registerEventBus();
  },
  destroyed() {
    this.unregisterEventBus();
  },
  computed: {
    ...mapGetters({
      _warnings: WERTPAPIERORDER_TYPES.GETTERS.WARNINGS,
      investmentAdviceOrder: INVESTMENT_ADVICE.GETTERS.INVESTMENT_ADVICE_ORDER,
      highestStepVisited: WERTPAPIERORDER_TYPES.GETTERS.HIGHEST_STEP_VISITED,
      isFirma: CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA,
    }),
    bgsNr() {
      return this.$route.query.bgsNr || this.$route.query.bgs || '';
    },
    lagerstelleLabel() {
      return this.data?.lagerstelleStr || this.data?.lagerstelle || this.data.lagerstelleSelect || '';
    },
    depotNrLabel() {
      return 'Depotnummer: ' + (this.data?.depotNr || this.data?.depotSelect); 
    },
    getTitle() {
      const step = this.stepper && this.stepper.getCurrentStep(this.getStepKey);
      return step && step.label;
    },
    getHighestStepVisited() {
      const highestStepVisited = this.getStepKey && this.isNewAntrag ? this.stepper?.getHighestStepVisitedIndex() || 0 :
        this.lagerstelle && this.stepper?.getCurrentStepList()?.filter(step => !step.hidden)?.length || 0;
      return this.highestStepVisited > highestStepVisited ? this.highestStepVisited : highestStepVisited;
    },
    snowNextSpinner() {
      return this.saveAntragState && this.lastClickedButton === 'next'
    },
    showPreviousSpinner() {
      return this.saveAntragState && this.lastClickedButton === 'previous'
    },
    routeStep() {
      return this.$route.params.step;
    },
    warnings() {
      this.updateStepperWithWarnings();
      return this._warnings;
    },
    warningsArr() {
        let warningsArr = [];
        if (this.warnings) {
          warningsArr.push(...(this.warnings['positionWarnings'] || []));
          warningsArr.push(...(this.warnings['antragWarnings'] || [])); 
        }
        return warningsArr;
      },
    actionConfigs() {
      return {
        hideAGB: true,
        hideESignatur: this.isBeratungsmappe || !this.isSignatureAllowed,
        hideSendEmail: this.isBeratungsmappe,
        hideSendFax: this.isBeratungsmappe,
        hideAuftragsdokumente: this.isBeratungsmappe,
      }
    },
    isDataLoaded() {
      return Object.keys(this.data).length
    },
    isNewAntrag() {
      return this.data?.antragsdatenId
    },
    lastBackToPreviousData() {
      const { $breadcrumbs } = this;
      return $breadcrumbs?.[$breadcrumbs.length - 1] || {};
    },
    hasStatusFehler() {
      return this.warningsArr?.some(warn => warn.status === 'FEHLER');
    },
  },
  async beforeRouteLeave(to, from, next) {  
    if (this.saveAntragState) {
      return;
    } else if(!this.data?.isWebserviceAktiv) {
      await this.doSave();
    } else if(!to.fullPath.includes('/fondsfinder') && !to.fullPath.includes('wertpapierorder/tausch')){
      await this.saveWerbserviceAntrag();
    }
    
    this.$addBreadcrumb({
      label: 'zurück zur Wertpapierorder', 
      to,
      from,
    });
    next()   
  },
  methods: {
    /* sends the save request do webservice */
    async saveWerbserviceAntrag() {
      if(!this.readOnly && this.data?.isWebserviceAktiv && (!this.data?.hatPDF || this.data.dataHasChanged)) {
        this.$refs.infoModal.open();
        this.data.synchronisieren = "true"
        this.data.dataHasChanged = "true"  
        await this.doSave().then((response)=>{
          this.data.dataHasChanged = false;
          this.data.synchronisieren = false;     
          })
          .finally(() => {
            this.$refs.infoModal.close();
          })
      }
    },
    loadData() {
      if (this.$route.query.initData && !this.$route.query.backAction) {
        const payload = this.getRouteParams;
        if (this.$route.query.investementAdviceReferrer && this.investmentAdviceOrder) {
          payload.posBesitzerId = this.investmentAdviceOrder.posBesitzerId;
          payload.lagerstelleAnlageempfehlung = this.investmentAdviceOrder.lagerstelle;
          payload.depotnrAnlageempfehlung = this.investmentAdviceOrder.depotnr;
        }
       
        if (this.bgsNr || this.$route.query.antragsdatenId || this.$route.query.antragsnrIntern || payload.posBesitzerId) {
          this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.GET_WERTPAPIERORDER, payload);
        } else {
          if (this.$route.query.investementAdviceReferrer) {
            // should be set through investment advice 
            this.loadWertpapierorder();
          } else {
            this.openLagerstelleKontoModal();
          }
        }
      }
    },

    openLagerstelleKontoModal() {
      this.$refs.initModal?.open();
    },
    nextStep() {
      this.lastClickedButton = 'next'
      const nextStep = this.stepper.getNextStep(this.getStepKey);
      this.stepper.progress(nextStep);
      this.navigateToStep(nextStep);
    },
    prevStep() {
      this.lastClickedButton = 'previous'
      const prevStep = this.stepper.getPreviousStep(this.getStepKey);
      this.stepper.progress(prevStep);
      this.navigateToStep(prevStep);
    },
    goToStep(warning) {
      if (warning?.stepKey) {
        this.stepper.progress(warning.stepKey);
        this.focusOnPosition = warning.posGrpId || null;
        this.navigateToStep(warning.stepKey);
        if (this.focusOnPosition) {
          setTimeout(() => { this.focusOnPosition = null; }, 200);
        }
      }
    },
    updateStepperWithWarnings() {
      let visible = this.stepper?.getCurrentStepList()?.filter(step => !step.hidden);
      if (this.getHighestStepVisited < visible?.length) {
        visible = visible.slice(0, this.getHighestStepVisited + 1);
      }
      visible.forEach((step) => {
        const result = this.getWarnings(step.stepKey);
        if (result) {
          this.stepper.markStepAsWarning(step.stepKey);
        } else {
          this.stepper.markStepAsSuccess(step.stepKey);
        }
      });
    },
    updateStore(data) {
      if (data) {
        this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_DATA, data);
      }
    },
    setEbaseEdelmetalTemplate(){
       this.stepper.setStepHidden('ebase-edelmetal',false);
        this.$refs.refStepper?.updateStepList();
        this.$refs.refStepper2?.updateSteps();
    },
    setGeldwaescheBoolean(){
      this.data.geldwaescheDone = true
      this.updateStore(this.data)
    },
    async fertigstellen() {
      if (this.isCustomerLogin) {
        return;
      }
      await this.saveWerbserviceAntrag();
      
      // MSC-26174 always save
      // this.updateStore(this.data)

      // MSC-25889 - If we save here we delete the PDF (ServiceWertpapierorderSaveImpl l146)
      // We would need to recreate the pdf, but why do we even save here? It's the final step, 
      // shouldn't it have saved before?
     
      if (this.warningsArr?.filter(warn => warn.status !== 'HINWEIS')?.length) {
        this.$confirmModal({
          title: 'Fehler', 
          message: 'Die Wertpapierorder ist fehlerhaft.', 
          labelButtonConfirm: 'Speichern',
          labelButtonCancel: 'Zurück',
        }).then(() => {
          this.close();
        })
      } else {
        if (!this.isBeratungsmappe && !this.data?.IS_VORGELEGT && this.data?.hatPDF) {
          this.$refs.vorlegenModal.open();
        } else {
          this.close();
        }   
      }
    },
    vorlegen() {
      EVENT_BUS.$emit(ANTRAG_E_SIGNATUR, null, true, false);
      this.close();
    },
    close() {
      this.getMeldungEinreichung();
      
      this.$router.push({
        path: this.lastBackToPreviousData.fullPath || '/home',
        query: { backAction: true},
      })
    },
    signPdf() {
      EVENT_BUS.$emit(ANTRAG_SIGN_ACTION, true, false, true);
    },
    abbrechen() {
      this.$router.push('/home');
    },
    registerEventBus() {
      // Close Wertpapierorder
      EVENT_BUS.$on(ANTRAG_CLOSE, () => {
        this.abbrechen();
      });
    },
    unregisterEventBus() {
      EVENT_BUS.$off(ANTRAG_CLOSE);
    },
  },
  beforeRouteEnter(to, from, next) {
    const except = ['/fondsinfo', '/wertpapierorder', '/fondsfinder', '/persoenlichedaten']
    if (except.find(path => from.path.includes(path))) {
      next()
      return
    }

    if (from.path === '/beratung/antrag-beratungsmappe-selector') {
      next()
    } else if (to.query?.bmOptChosen || to.query?.beratungsMappeId || to.query?.avoidAntragLoading || to.path?.includes(PATH_CUSTOMER) || (to.query?.hideBeratungsmappepopup === "true")) {
      next()
    } else {
      next({ path: '/beratung/antrag-beratungsmappe-selector', query: { formPath: to.fullPath } })
    }
  },
};
</script>

<style scoped>
  .wertpapierorder__title--centered{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
</style>
