import VERSICHERUNG_TYPES from './types';
import CORE_TYPES from '../core/types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { buildMessage, } from '@/helpers/log-message-helper';
import { BROKER_STRUCTURE_ROLES } from '@/router/roles'
import { prepareParams } from '@/store/antrag/actions.js'

const config = {
  defaultSpinner: true
};

export default {
  [VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCES]({ commit, state, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let url = '/insurances/getInsuranceById';

      if(payload && payload.category) {
        url = `${url}?category=${payload.category}`;
      }

      axios.get(rootState.core.apiAddress + url, config).then(response => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_INSURANCES_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_DETAILS]({ commit, state, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let url = '/insurances/getInsuranceById';
  
      if(payload && payload.versVertrag) {
        url = `${url}?versVertrag=${payload.versVertrag}`;
      }
  
      axios.get(rootState.core.apiAddress + url, config).then(response => {
        if (response && response.data) {
          commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_INSURANCE_DETAILS_SUCCESS, response.data);
        }
        resolve(response?.data || {});
      }).catch(error => {
        reject(error);
      })
    });
  },
  [VERSICHERUNG_TYPES.ACTIONS.DELETE_SCHADENSMELDUNG]({ commit, state, dispatch, rootState }, payload) {
   
      let url = '/insurances/deleteSchadensmeldung';
  
      if(payload && payload.schadenId) {
        url = `${url}?schadenId=${payload.schadenId}`;
      }
  
      axios.delete(rootState.core.apiAddress + url, config).then(response => {
        if (response && response.data) {
          commit(VERSICHERUNG_TYPES.MUTATIONS.DELETE_SCHADENSMELDUNG_SUCCESS, response.data);
        }
      })
    
  },

  async [VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_ALL_CATEGORIES]({ getters, commit }) {
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/getAllInsuranceCategories`, config).then(response => {
      if (response?.data) {
        commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_ALL_CATEGORIES_SUCCESS, response.data);
      }
    })
  },

  [VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_GESELLSCHAFT]({getters, commit}, params) {
    if (!params.groupId) {
      return
    }
    const queryParams = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/getInsuranceGesellschaftList?${queryParams}`, config).then(response => {
      if (response?.data) {
        commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_INSURANCE_GESELLSCHAFT, response.data)
      } else {
        commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_INSURANCE_GESELLSCHAFT, [])
      }
    }).catch(() => {
      commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_INSURANCE_GESELLSCHAFT, [])
    })
  },

  [VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_CATEGORIES]({ commit, dispatch, rootState }) {
    axios.get(rootState.core.apiAddress + '/insurances/getCategoriesGroup', config).then(response => {
      if (response && response.data) {

        commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_CATEGORIES_SUCCESS, response.data);

      }      

    })
  },

  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION]({ commit, dispatch, rootState }, payload) {
    
    let url = rootState.core.apiAddress + '/insurances/vertragsdatenFieldsDefinition';
    let params = {};

    if(payload?.versVertrag) {
      params.versVertrag = payload.versVertrag;
    }
    if(payload?.groupId) {
      params.groupId = payload.groupId;
    }

    axios.get(url, {params}).then(response => {
      if (response && response.data) {

        commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION_SUCCESS, response.data);

      }

    })
  },
  
  [VERSICHERUNG_TYPES.ACTIONS.GET_INSURANCE_DETAILS_COMBINED]({ commit, getters, rootState }, payload) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES]
    if (hasRoles(BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN)) {
      Promise.all([
        axios.get(rootState.core.apiAddress + `/../mrsinsurance`, config),
        axios.get(rootState.core.apiAddress + `/../mrsschadensmeldung?vertragId=${payload.id}&schadenId=${null}`, config),
        axios.get(rootState.core.apiAddress + `/insurances/getInsuranceById?versVertrag=${payload.id}`, config),
      ]).then(values => {
        let record = values[2].data.insurance;
        record = Array.isArray(record) ? record[0] : record;
        const docId = record.doks.split('/')[3];
        axios.get(rootState.core.apiAddress + `/../mrsinsurance?versVertrag=${docId}`, config).then(documents => {
          commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_DETAILS_COMBINED_SUCCESS, {
                insurance: values[2].data,
                schadenInfos: values[1].data && values[1].data.node,
                record,
                documents
            });
        })
      })
    }
  },

  [VERSICHERUNG_TYPES.ACTIONS.GET_SPARTEN_LIST]({ commit, rootState }) {
    axios.get(rootState.core.apiAddress + `/../mrsinsuranceadd?spartenList=true`, config)
      .then((response) => {
        const spartenList = response?.data?.sparten || [];
        commit(VERSICHERUNG_TYPES.MUTATIONS.GET_SPARTEN_LIST_SUCCESS, spartenList);
      })
      .catch((error) => console.log(error));
  },

  [VERSICHERUNG_TYPES.ACTIONS.GET_ZAHLRHYTHMUS_LIST]({ commit, rootState }) {
    axios.get(rootState.core.apiAddress + `/../mrsinsuranceadd?getZahlrhythmus=true`, config)
      .then((response) => {
        const zahlrhythmusList = response?.data?.listZahlrhythmus || [];
        commit(VERSICHERUNG_TYPES.MUTATIONS.GET_ZAHLRHYTHMUS_LIST_SUCCESS, zahlrhythmusList);
      })
      .catch((error) => console.log(error));
  },

  [VERSICHERUNG_TYPES.ACTIONS.SAVE_VERTRAG]({ getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/saveInsurance`, payload, config)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.UPDATE_VERTRAG]({state, commit, getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/updateInsurance`, payload, config)
      .then(response => {
        if(response?.data){
          commit(VERSICHERUNG_TYPES.MUTATIONS.UPDATE_VERTRAG_SUCCESS, response.data)
          resolve(response?.data);
        }
        return response.data
      })
      .catch((error) => {
        reject(error);
      });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.UPDATE_VERTRAG_SPARTEN]({state, commit, getters, dispatch }, payload) {
    if (state.versicherungenDetails?.extraData && Object.keys(state.versicherungenDetails.extraData).length) {
      const sparteChanged = { id: state.versicherungenDetails.insurance?.id, extraData: state.versicherungenDetails.extraData, sparten: state.versicherungenDetails.insurance?.sparten };
      return axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/updateSparten`, sparteChanged, config)
      .then(response => {
        if(response?.data){
          commit(VERSICHERUNG_TYPES.MUTATIONS.UPDATE_VERTRAG_SPARTEN_SUCCESS, response.data)
        }
        return response.data
      })
    } else {
      return Promise.resolve(true);
    }
  },

  [VERSICHERUNG_TYPES.ACTIONS.CAN_DELETE_VERTRAG]({ getters }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/canDeleteInsurance?insuranceId=${payload}`, config)
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.DELETE_VERTRAG]({ getters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/deleteInsurance?insuranceId=${payload}`, config)
        .then((response) => {
          commit(VERSICHERUNG_TYPES.MUTATIONS.DELETE_VERTRAG, payload)
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.GET_DEPOT_POSITIONS]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      if(!payload?.vertragsNr) {
        console.error('VERSICHERUNG_TYPES.ACTIONS.GET_DEPOT_POSITIONS -> you must pass the "vertragsNr"');
        reject();
        return ;
      }

      axios.get(rootState.core.apiAddress + `/../mrsdepotpositions?vertragsNr=${payload.vertragsNr}`, config)
        .then((response) => {
          const data = response?.data || {};
          commit(VERSICHERUNG_TYPES.MUTATIONS.GET_DEPOT_POSITIONS_SUCCESS, data);
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_UPLOAD]({ dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      if(!payload?.vtgId || !payload?.file) {
        reject('VERSICHERUNG_TYPES.ACTIONS.INSURANCE_UPLOAD -> You must pass a "vtgId" and a "file" in the payload');
        return ;
      }

      const multipartFormDataConfig = { 
        ...config, 
        headers: {'Content-Type': 'multipart/form-data'}, 
      };

      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      axios.post(rootState.core.apiAddress + '/../mrsinsuranceupload', formData, multipartFormDataConfig).then((response) => {
        resolve();
      }).catch((error) => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Beim importieren des Filters ist ein Fehler aufgetreten.', 'danger'));
        reject(error);
      });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.GET_INSURANCE_UPLOAD_INFO]({ rootState }) {
    return new Promise((resolve, reject) => {
      axios.get(rootState.core.apiAddress + '/../mrsinsuranceupload', config)
        .then((response) => resolve(response?.data || {}))
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.GET_INSURANCE_FILTERS_DATA]({ rootState, commit }) {
    axios.get(rootState.core.apiAddress + '/customerFilters/getCustomerFiltersVersSetup', config)
      .then((response) => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.GET_INSURANCE_FILTERS_DATA_SUCCESS, response?.data || {});
      })
      .catch((error) => {
        console.log(error);
      });
  },

  [VERSICHERUNG_TYPES.ACTIONS.GET_INSURANCES_FILTERED]({ state, rootState, commit }, payload) {
    if (payload.brokerId && !Array.isArray(payload.brokerId)) {
      payload.brokerId = [payload.brokerId]
    }

    
    return new Promise((resolve, reject) => {
     
      axios.post(rootState.core.apiAddress + '/insurances/getInsurancesFiltered', payload, 
      {defaultSpinner: true, cancelableRequest: {title: 'Versicherungssuche'}})
        .then((response) => {
          const data = response?.data || [];
          if (typeof data.askPIN == 'number') {
            commit(CORE_TYPES.MUTATIONS.CHECK_PIN, {
              key: 'TABELLE_VERSICHERUNGEN', 
              value: data.askPIN,
              action: VERSICHERUNG_TYPES.ACTIONS.GET_INSURANCES_FILTERED,
              payload: JSON.stringify(payload)
            })
          }
          commit(VERSICHERUNG_TYPES.MUTATIONS.GET_INSURANCES_FILTERED_SUCCESS, {...data, pageId: payload.section - 1} );
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_COMBOBOX_VALUES]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const gesellschaftId = payload.gesellschaftId;
      if(!gesellschaftId) {
        reject('you must pass the "gesellschaftId"');
        return ;
      }

      let url = rootState.core.apiAddress + '/insurances/getMaklergesellnrValues';
      axios.get(url, {params: {gesellschaftId: payload.gesellschaftId, groupId: payload.groupId}}).then((response) => {
          const maklerGesellNr = response?.data || [];
          const maklerGesellNrbYGesellschaft = { gesellschaftId, maklerGesellNr };
          commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_COMBOBOX_VALUES_SUCCESS, maklerGesellNrbYGesellschaft);
          resolve(maklerGesellNrbYGesellschaft);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    })
  },
  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_ANBINDUNG_COMBOBOX_VALUES]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const gesellschaftId = payload.gesellschaftId;
      if(!gesellschaftId) {
        reject('you must pass the "gesellschaftId"');
        return ;
      }

      let url = rootState.core.apiAddress + '/insurances/getAnbindungValues';
      axios.get(url, {params: {gesellschaftId: payload.gesellschaftId, groupId: payload.groupId}})
      .then((response) => {
        const comboboxValues = response?.data || {};
        commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_VERTRAGSDATEN_ANBINDUNG_COMBOBOX_VALUES_SUCCESS, comboboxValues);
        resolve(comboboxValues)
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
    })
  },

  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_POLNR_VALIDATION]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const gesellschaftId = payload.gesellschaftId;
      if(!gesellschaftId) {
        reject('you must pass the "gesellschaftId"');
        return ;
      }

      let url = rootState.core.apiAddress + '/insurances/policeNummerValidation';
      axios.get(url, {params: {gesellschaftId: payload.gesellschaftId}}).then((response) => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_VERTRAGSDATEN_POLNR_VALIDATION_SUCCESS, response?.data || {});
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
    })
  },

  [VERSICHERUNG_TYPES.ACTIONS.GET_ANTRAG_CHECK_VERSICHERUNG_LIST]({ getters, commit }, payload) {
    let localConfig = {
      ...config,
      cancelableRequest: {
        title: 'Antragsliste Versicherungen Filter'
      }
    };

    return new Promise((resolve, reject) => {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/antragCheckVersicherung/getAntragCheckVersicherungList`, payload, localConfig).then(response => {
        if (response?.data) {
          if (typeof response.data.askPIN == 'number') {
            commit(CORE_TYPES.MUTATIONS.CHECK_PIN, {
              key: 'TABELLE_VERSICHER_ANTRAEGE', 
              value: response.data.askPIN,
              action: VERSICHERUNG_TYPES.ACTIONS.GET_ANTRAG_CHECK_VERSICHERUNG_LIST,
              payload: JSON.stringify(payload)
            })
          }
          commit(VERSICHERUNG_TYPES.MUTATIONS.GET_ANTRAG_CHECK_VERSICHERUNG_LIST_SUCCESS, response.data);
          resolve();
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
    })
  },
  
  async [VERSICHERUNG_TYPES.ACTIONS.GET_ANTRAG_CHECK_VERSICHERUNG_DOCUMENT]({ getters }, payload) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/antragCheckVersicherung/getDocument?versCheckId=${payload}`, config);
    return response?.data  
  },

  async [VERSICHERUNG_TYPES.ACTIONS.GET_ANTRAG_CHECK_PROTOKOLL]({ getters, dispatch }, payload) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/antragCheckVersicherung/getProtokoll?versCheckId=${payload}`, config);
    if (response.data.protokoll ) {
      const protokoll = response.data.protokoll.charAt(0) == '\n' ? response.data.protokoll.slice(1) : response.data.protokoll;
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(protokoll.replace(/\n/g, '<br>'), 'info'));
    }
    return response?.data  
  },

  async [VERSICHERUNG_TYPES.ACTIONS.CAN_CHANGE_PRUEFSTATUS]({ getters }, payload) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/antragCheckVersicherung/canChangePruefstatus?versCheckId=${payload?.versCheckId || ''}&&newStatus=${payload?.newStatus || ''}`, config);
    return response?.data  
  },
  
  async [VERSICHERUNG_TYPES.ACTIONS.SAVE_PRUEFSTATUS]({ getters }, payload) {
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/antragCheckVersicherung/savePruefstatus`, payload, config);
    return response?.data  
  },
  
  async [VERSICHERUNG_TYPES.ACTIONS.ADD_ATTACHMENT]({ getters }, payload) {
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/antragCheckVersicherung/addAttachment?checkVersicherungId=${payload?.checkVersicherungId}`, payload, config)
    return response.data
  },

  [VERSICHERUNG_TYPES.ACTIONS.VERSICHERUNG_VEREINIGEN]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/mergeInsurances?insuranceToKeep=${payload.insuranceToKeep}&insuranceToRemove=${payload.insuranceToRemove}`;

      axios.put(url, config).then(response => {
        if (response?.data) {
          commit(VERSICHERUNG_TYPES.MUTATIONS.DELETE_VERTRAG, {insuranceId: payload.insuranceToRemove});
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'success'));
          resolve();
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
    })
  },

  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_MESSAGE]({ rootState }, payload) {
    return new Promise((resolve, reject) => {
      const maklerGesellNr = payload.maklerGesellNr;
      if(!maklerGesellNr) {
        reject('you must pass the "maklerGesellNr"');
        return ;
      }
      
      let url = rootState.core.apiAddress + '/insurances/maklerGeselNummerMessage';
      axios.get(url, {params: {maklerGesellNr}}).then((response) => {
        const maklerGesellNrMessage = response?.data || '';
        resolve(maklerGesellNrMessage);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
    })
  },
  async [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_POLNR_RUN_VALIDATION]({ rootState }, payload) {
    const response = await axios.get(rootState.core.apiAddress + '/insurances/policeNummerRunValidation', {params: payload});
    return response.data;
  },

  // [VERSICHERUNG_TYPES.ACTIONS.GET_INSURANCE_PRAEMIE_PA]({ rootState, commit, state }, payload) {
  //   const id = state?.versicherungenDetails?.insurance?.id;
  //   if (id) {
  //     const param = prepareParams({ vertragId: id, praemie: payload?.praemie });
  //     axios.get(`${rootState.core.apiAddress}/insurances/getPremiePa?${param}`, config)
  //       .then((response) => {
  //         if (response?.data) {
  //           const param = Object.assign({}, payload, {value: response.data})            ;
  //           commit(VERSICHERUNG_TYPES.MUTATIONS.GET_INSURANCE_PRAEMIE_PA_SUCCESS, param);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // },

  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_ANMERKUNG_GET]({ getters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/getAnmerkungen`, {params: {vertragId: payload}}, config)
        .then((response) => {
          const list = [ ...response.data?.listing || [] ];
          commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_ANMERKUNG_GET_SUCCESS, list);
          commit(VERSICHERUNG_TYPES.MUTATIONS.SET_CAN_EDIT_ANMERKUNG, response.data?.canEdit);
          resolve(list);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  
  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_ANMERKUNG_SAVE]({ getters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/saveAnmerkung`, payload, {params: {vertragId: payload.owner}}, config)
        .then((response) => {
          commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_ANMERKUNG_SAVE_SUCCESS, response.data)
          resolve(response?.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_ANMERKUNG_DELETE]({ commit, getters }, payload) {
    axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/deleteAnmerkung`, {params: {anmerkungId: payload}}, config)
    .then(response => {
      if (response && response.data) {
        commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_ANMERKUNG_DELETE_SUCCESS, response.data);
      }
    })
  },

  [VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCES_BY_CUSTOMER]({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/getInsurancesByCustomer`;

      let params = {};

      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }

      axios.get(url, {params, config}).then(response => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_INSURANCES_BY_CUSTOMER_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  

  [VERSICHERUNG_TYPES.ACTIONS.SEARCH_CUSTOMER]({ commit, getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/searchCustomer`;

      let params = {};

      if(payload?.kundennr) {
        params.kundennr = payload.kundennr;
      }

      axios.get(url, {params, ...config}).then(response => {
        if (response?.data) {
          commit(VERSICHERUNG_TYPES.MUTATIONS.SEARCH_CUSTOMER_SUCCESS, response?.data || {});
          resolve(response?.data);
        }
        
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },
  

  [VERSICHERUNG_TYPES.ACTIONS.KUNDENNR_AENDERN]({ commit, getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/vertragVerschieben`;

      let params = {};

      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }

      if(payload?.newCustomer) {
        params.newCustomer = payload.newCustomer;
      }

      axios.get(url, {params, config}).then(response => {
        if (response?.data?.value) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.value, 'info'));
        }
        
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  
  [VERSICHERUNG_TYPES.ACTIONS.FIND_ALL_INSURANCE_COURTAGE]({ commit, getters, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/findAllInsuranceCourtage`;

      let params = {};

      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }

      axios.get(url, {params, config}).then(response => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.FIND_ALL_INSURANCE_COURTAGE_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_COURTAGE_DELETE]({ commit, getters }, payload) {
    axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/deleteInsuranceCourtage`, {params: {insuranceCourtageId: payload.courtageId}}, config)
    .then(response => {
      if (response && response.data) {
        commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_COURTAGE_DELETE_SUCCESS, response.data);
      }
    })
  },

  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_COURTAGE_SAVE]({ getters, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/saveInsuranceCourtage`, payload, config)
        .then((response) => {
          commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_COURTAGE_SAVE_SUCCESS, response.data)
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
          reject(error);
        });
    });
  },
  
  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_COURTAGE_UPDATE]({commit, getters, dispatch }, payload) {
    return axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/updateInsuranceCourtage`, payload, config)
    .then(response => {
      if(response?.data){
        commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_COURTAGE_UPDATE_SUCCESS, response.data)
      }
      return response.data
    })
    .catch((error) => {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      reject(error);
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_COURTAGE_CONFIG]({commit, getters, dispatch }, payload) {
    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/getInsuranceCourtageConfig`, config)
    .then(response => {
      if(response?.data){
        commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_COURTAGE_CONFIG_SUCCESS, response.data)
      }
      return response.data
    })
    .catch((error) => {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      reject(error);
    });
  },
  
  [VERSICHERUNG_TYPES.ACTIONS.FIND_ALL_INSURANCE_ABRECHNUNGEN]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/findAllAbrechnungen`;
      
      let params = {};
      
      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }
      
      axios.get(url, {params, config}).then(response => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.FIND_ALL_INSURANCE_ABRECHNUNGEN_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  
  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_DELETE]({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
      const URL = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/deleteInsuranceBuchung`;
      axios.delete(URL, {params: {buchungsnr: payload.buchungsnr}}, config)
      .then(response => {
        if (response && response.data) {
          commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_BUCHUNG_DELETE_SUCCESS, response.data);
          resolve();
        }
      }).catch(error => {
        reject(error);
      });
    });
  },
  
  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_COPY]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      
      const URL = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/copyInsuranceBuchung`;
      
      let params = {};
      
      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }
      if(payload?.buchungsnr) {
        params.buchungsnr= payload.buchungsnr
      }
      
      axios.post(URL, {}, {params}, config).then((response) => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.FIND_ALL_INSURANCE_ABRECHNUNGEN_SUCCESS, response?.data || {});
        resolve();
      })
      .catch((error) => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
        reject(error);
      });
    });
  },
  
  [VERSICHERUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_STORNO]({ getters, commit }, payload) {
    return new Promise((resolve, reject) => {
      
      const URL = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/stornierenInsuranceBuchung`;
      
      let params = {};
      
      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }
      if(payload?.buchungsnr) {
        params.buchungsnr= payload.buchungsnr
      }
      
      axios.put(URL, {}, {params}, config).then((response) => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.FIND_ALL_INSURANCE_ABRECHNUNGEN_SUCCESS, response?.data || {});
        resolve();
      })
      .catch((error) => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
        reject(error);
      });
    });
  },


  [VERSICHERUNG_TYPES.ACTIONS.COPY_INSURANCE]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {

      const URL =`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/copyInsurance`;

      let params = {};
      
      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }      

      axios.post(URL, {}, {params}, config)
        .then((response) => {
          commit(VERSICHERUNG_TYPES.MUTATIONS.COPY_INSURANCE_SUCCESS, response.data)
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
          reject(error);
        });
    });
  },
  [VERSICHERUNG_TYPES.ACTIONS.SAVE_RUECKKAUFSWERTE]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {

      const URL =`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/saveRueckkaufswerte`;

      let params = {};
      
      if(payload?.versicherungId) {
        params.vertragId = payload.versicherungId;
      }
      if(payload?.sparteId) {
        params.sparteId = payload.sparteId;
      }

      axios.post(URL, payload.ruckkaufswerteEdit, {params, config})
        .then((response) => {
          commit(VERSICHERUNG_TYPES.MUTATIONS.SAVE_RUECKKAUFSWERTE_SUCCESS, {...response.data, sparteId: payload?.sparteId})
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
          reject(error);
        });
    });
  },  
  [VERSICHERUNG_TYPES.ACTIONS.UPDATE_RUECKKAUFSWERTE]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {

      const URL =`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/updateRueckkaufswerte`;

      let params = {};
      
      if(payload?.versicherungId) {
        params.vertragId = payload.versicherungId;
      }
      if(payload?.sparteId) {
        params.sparteId = payload.sparteId;
      }

      axios.put(URL, payload.ruckkaufswerteEdit, {params, config})
        .then((response) => {
          commit(VERSICHERUNG_TYPES.MUTATIONS.UPDATE_RUECKKAUFSWERTE_SUCCESS, {...response.data, sparteId: payload?.sparteId})
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
          reject(error);
        });
    });
  },  
  [VERSICHERUNG_TYPES.ACTIONS.DELETE_RUECKKAUFSWERTE]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {

      const URL =`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/deleteRueckkaufswerte`;

      let params = {};
      
      if(payload?.versicherungId) {
        params.vertragId = payload.versicherungId;
      }
      if(payload?.sparteId) {
        params.sparteId = payload.sparteId;
      }
      if(payload?.ruckkaufswerteId) {
        params.ruckkaufswerteId = payload.ruckkaufswerteId;
      }

      axios.delete(URL, {params, config})
        .then((response) => {
          commit(VERSICHERUNG_TYPES.MUTATIONS.DELETE_RUECKKAUFSWERTE_SUCCESS, params)
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
          reject(error);
        });
    });
  },
  [VERSICHERUNG_TYPES.ACTIONS.DELETE_EINGEBUNDENE_WERTPAPIERE]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {

      const URL =`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versicherungEingebundeneWertpapier/deleteEingebundeneWertpapiere`;

      let params = {};
      
      if(payload?.versicherungId) {
        params.vertragId = payload.versicherungId;
      }
      if(payload?.eingebundeneWertpapiereId) {
        params.eingebundeneWertpapiereId = payload.eingebundeneWertpapiereId;
      }
      if(payload?.sparteId) {
        params.sparteId = payload.sparteId;
      }

      axios.delete(URL, {params, config})
        .then((response) => {
          commit(VERSICHERUNG_TYPES.MUTATIONS.DELETE_EINGEBUNDENE_WERTPAPIERE_SUCCESS, params)
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
          reject(error);
        });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERTRAGSPERSONENDATA]({ commit, state, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let url = '/insurances/getVertragPersonenData';


      let params = {};
      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }

      if(payload?.vertragsPersonId) {
        params.vertragsPersonId = payload.vertragsPersonId;
      }      

      axios.get(rootState.core.apiAddress + url, {params}, config).then(response => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_VERTRAGSPERSONENDATA_SUCCESS, response?.data);
        resolve(response?.data);
      }).catch(error => {
        reject(error);
      });
    });
  }, 

  [VERSICHERUNG_TYPES.ACTIONS.SAVE_VERTRAG_PERSONEN_DATA]({ commit, state, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let url = '/insurances/saveVertragPersonenData';
  
  
      let params = {};
      if(payload?.id) {
        params.id = payload.id;
      }

      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }
  
      if(payload?.selectedPerson) {
        params.personId = payload.selectedPerson;
      }     
      
      if(payload?.rolle) {
        params.rolle = payload.rolle;
      }       
  
      axios.post(rootState.core.apiAddress + url, params, config).then(response => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.SAVE_VERTRAG_PERSONEN_DATA_SUCCESS, response?.data);
        resolve(response?.data);
      }).catch(error => {
        reject(error);
      });
    });
  }, 

  [VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERTRAGPERSONEN_LIST]({ commit, state, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let url = '/insurances/getVertragPersonenList';
  
  
      let params = {};
      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }
  
      axios.get(rootState.core.apiAddress + url, {params}, config).then(response => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_VERTRAGPERSONEN_LIST_SUCCESS, response?.data);
        resolve(response?.data);
      }).catch(error => {
        reject(error);
      });
    });
  }, 

  [VERSICHERUNG_TYPES.ACTIONS.DELETE_VERTRAG_PERSON]({ commit, state, dispatch, rootState }, payload) {

    return new Promise((resolve, reject) => {
      let url = '/insurances/deleteVertragPerson';

      if(payload && payload.vertragsPersonId) {
        url = `${url}?vertragsPersonId=${payload.vertragsPersonId}`;
      }
  
      axios.delete(rootState.core.apiAddress + url, config).then(response => {
        if (response?.data) {
          commit(VERSICHERUNG_TYPES.MUTATIONS.DELETE_VERTRAG_PERSON_SUCCESS, response?.data);
          resolve(response?.data);
        }
      }).catch(error => {
        reject(error);
      });

    });
   

  
  },

  [VERSICHERUNG_TYPES.ACTIONS.GET_FAHRZEUGDATEN_LIST]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/vehicleDataList`;
      
      let params = {};
      
      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }
      
      axios.get(url, {params}, config).then(response => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.GET_FAHRZEUGDATEN_LIST_SUCCESS, response?.data || []);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  
  [VERSICHERUNG_TYPES.ACTIONS.EXPORT_INSURANCES]({ rootState }, payload) {
    const config = {
      defaultSpinner: true,
    };
    return axios.post(rootState.core.apiAddress + '/insurances/exportInsurancesFiltered', payload, config);
  },


  [VERSICHERUNG_TYPES.ACTIONS.GET_SPARTEN]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/getSparten`;
      
      let params = {};
      
      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }
      
      axios.get(url, {params}, config).then(response => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.GET_SPARTEN_SUCCESS, response?.data || {});
        resolve(response?.data);
      }).catch(error => {
        reject(error);
      });
    });
  },

  [VERSICHERUNG_TYPES.ACTIONS.ADD_SPARTE]({ commit, getters, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/addSparte`;
      
      let params = {};
      
      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }

      if(payload?.sparteId) {
        params.sparteId = payload.sparteId;
      }
      
      axios.get(url, {params}, config).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },  

  [VERSICHERUNG_TYPES.ACTIONS.DELETE_VERSICHERUNG_SUMME]({ commit, state, dispatch, rootState }, payload) {

    return new Promise((resolve, reject) => {
      let url = '/insurances/deleteVersicherungSumme';

      if(payload?.id  && payload?.vertragId) {
        url = `${url}?id=${payload?.id}&vertragId=${payload?.vertragId}`;
      } else {
        return;
      }
  
      axios.delete(rootState.core.apiAddress + url, config).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });


    });
   
  
  
  },

  [VERSICHERUNG_TYPES.ACTIONS.SAVE_OR_UPDATE_VERSICHERUNG_SUMME]({ commit, getters, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/saveOrUpdateVersicherungSumme`;
      
      axios.post(url, payload, config).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    });
  },    
  [VERSICHERUNG_TYPES.ACTIONS.VALIDATE_CUSTOMER]({ commit, getters, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API}/login/getToken?userType=KUNDE&userid=${payload.kundennr}`, config).then(response => {
          resolve(response);
      }).catch(() => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Diese Kundennummer ist ungülig', 'danger'));
        reject();
      })   

    });

  },
  [VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERSICHERUNGSSUMMEN]({ commit, getters, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/getVersicherungssummenAlle`;
      
      let params = {};
      
      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }
      
      axios.get(url, {params}, config).then(response => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_VERSICHERUNGSSUMMEN_SUCCESS, response?.data);
        resolve(response?.data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  [VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERSICHERUNGSSUMMEN_FA]({ commit, getters, dispatch}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/getVersicherungssummenAlleFA`;
      
      let params = {};
      
      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }
      
      axios.get(url, {params}, config).then(response => {
        commit(VERSICHERUNG_TYPES.MUTATIONS.RETRIEVE_VERSICHERUNGSSUMMEN_FA_SUCCESS, response?.data);
        resolve(response?.data);
      }).catch(error => {
        reject(error);
      });
    });
  },  
  [VERSICHERUNG_TYPES.ACTIONS.RESET_EXTERNAL_URL]({ commit, getters, dispatch}, payload){
    commit(VERSICHERUNG_TYPES.MUTATIONS.SET_EXTERNAL_URL, null)
  },
  async [VERSICHERUNG_TYPES.ACTIONS.GET_MAKLER_VOLLMACHT]({ commit, getters }, payload) {
    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/getMaklerVollmacht`;
  
    const response = await axios.get(url, { params: payload }, config);
  
    commit(VERSICHERUNG_TYPES.MUTATIONS.GET_MAKLER_VOLLMACHT_SUCCESS, response?.data || {});
    return response?.data; 
  },
  [VERSICHERUNG_TYPES.ACTIONS.GET_TEMP_FILE]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
    console.log(payload.id)
      if (payload.id) {
        let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/tempFile/get`;
            axios.get(url, {
                defaultSpinner: true,
                responseType: 'arraybuffer',
                params: {
                  tempFileId: payload.id
                }
              }).then(response => {
                console.log(response)
                if (response.status == 200){       
                  let fileName = response?.headers["content-disposition"]?.match('(").*?(")')[0].replaceAll('"', '')                
                  commit(VERSICHERUNG_TYPES.MUTATIONS.GET_TEMP_FILE_SUCCESS, 
                    [{
                      data: response.data,
                      filename: fileName,
                      contentType: payload.contenttype,
                      fileExtension: payload.type,
                      size: response.data.byteLength
                    }]
                  )
                  resolve()
                 }
              });
          }
  })
},
}



