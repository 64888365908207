import MULTIBANKING_TYPES from "./types"
import CORE_TYPES from "../core/types"
import BRIDGE_TYPES from "@/store/bridge/types"
import axios from "axios"
import {setSessionObject} from "@/helpers/local-storage-helper"
import dayjs from "dayjs"
import ROLES from "@/router/roles"
import {makeQueryParam} from "@/helpers/utils-helper"

export const KONTOVERWALTUNG_PATH = "/home/multibanking-account"

const config = {
    defaultSpinner: true,
}

function hasRoles(roles, getters) {
    return getters[CORE_TYPES.GETTERS.HAS_ROLES]?.(roles)
}

export default {
    async [MULTIBANKING_TYPES.ACTIONS.LOAD_ACCOUNT_DATA]({getters, commit}) {
        if (!hasRoles([[ROLES.MULTIBANKING_DATA_ALLOWED, ROLES.MULTIBANKING_ACCOUNT_USER]], getters)) {
            return
        }

        commit(MULTIBANKING_TYPES.MUTATIONS.LOAD_ACCOUNT_DATA_SUCCESS, {
            accounts: [],
            dailyAccountsHistory: {},
            accountsSum: null,
            userSettings: null,
        })

        try {
            const response = await axios.get(
                `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionaccount`,
                config,
            )
            commit(MULTIBANKING_TYPES.MUTATIONS.LOAD_ACCOUNT_DATA_SUCCESS, response.data)
        } catch (error) {
            // empty block
        }

        commit(MULTIBANKING_TYPES.MUTATIONS.UPDATE_LOADED_STATUS, {accounts: true})
    },

    async [MULTIBANKING_TYPES.ACTIONS.GET_BANK_TRANSACTIONS_TRANSFER_REQUEST]({getters, commit, rootState}, payload) {
        const response = await axios.post(
            `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktransfer`,
            payload,
            config,
        )
        setSessionObject("deepLink-loginData", rootState.core.loginData)

        if (response.data?.startTransferURL) {
            commit(MULTIBANKING_TYPES.MUTATIONS.BANKSAPI_URL, response.data.startTransferURL)
        }
    },

    async [MULTIBANKING_TYPES.ACTIONS.AUTHENTICATE_ACCOUNT](
        {getters, dispatch, commit, rootState},
        {callbackPath, providerId},
    ) {
        const response = await axios.get(
            `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionauthagain?providerId=${providerId}`,
            config,
        )

        if (response.data?.newLocationIsInternal) {
            dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_ACCOUNT_DATA)
        } else {
            setSessionObject("deepLink-loginData", rootState.core.loginData)

            const callbackUrl = callbackPath + "?accountId=" + response.data.accountId
            const newHref = response.data.url + "&callbackUrl=" + encodeURIComponent(callbackUrl)

            commit(MULTIBANKING_TYPES.MUTATIONS.BANKSAPI_URL, newHref)
        }
    },

    async [MULTIBANKING_TYPES.ACTIONS.REGISTER_ACCOUNT_URL](
        {getters, commit},
        {callbackPath},
    ) {
        const queryParam = {
            mobileNativeContext: getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT],
        }

        const response = await axios.get(
            `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionaccountregister?${makeQueryParam(
                queryParam,
            )}`,
            config,
        )
        const callbackUrl = callbackPath + "?accountId=" + response.data.accountId
        const mobileNativeMultibankingCallbackUrl = response?.data?.mobileNativeMultibankingCallbackUrl
        if (response?.data?.url) {
            const mbParam = {
                callbackUrl: mobileNativeMultibankingCallbackUrl ? mobileNativeMultibankingCallbackUrl : callbackUrl,
            }

            const newHref = response.data.url + `&${makeQueryParam(mbParam, true)}`
            commit(MULTIBANKING_TYPES.MUTATIONS.BANKSAPI_URL, newHref)
        }
    },

    async [MULTIBANKING_TYPES.ACTIONS.UPDATE_ACCOUNT]({commit, getters}, account) {
        await axios.post(
            `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionaccount`,
            {account},
            config,
        )
        commit(MULTIBANKING_TYPES.MUTATIONS.UPDATE_ACCOUNT, account)
    },

    async [MULTIBANKING_TYPES.ACTIONS.HIDE_ACCOUNT]({commit, getters, dispatch}, {externalId, providerId}) {
        const params = {
            externalId,
            providerId,
            hidden: true,
        }

        await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionaccount`, params, config)
        commit(MULTIBANKING_TYPES.MUTATIONS.HIDE_ACCOUNT, {externalId, providerId})

    dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_ACCOUNT_DATA);
    dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTIONS);
    dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_CONTRACTS);
    dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTION_CATEGORIES, { withoutAmount: false });
    dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_SAVINGS_POTENTIAL);
  },

    async [MULTIBANKING_TYPES.ACTIONS.FINALIZE_ACCOUNT]({getters, dispatch, commit}, {baReentry, accountId}) {
        const params = {
            baReentry,
            accountId,
        }
        await axios.post(
            `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionaccountregister`,
            params,
            config,
        )
        commit(CORE_TYPES.MUTATIONS.ADD_BELATED_ROLE, [ROLES.MULTIBANKING_ACCOUNT_USER])
        commit(MULTIBANKING_TYPES.MUTATIONS.RESET_LOADED)
        await dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_ALL_MULTIBANKING_DATA)
    },

    [MULTIBANKING_TYPES.ACTIONS.LOAD_PARAMS]({commit, getters}) {
        axios
            .get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionparams`, config)
            .then((response) => {
                if (response.data?.parameters) {
                    commit(MULTIBANKING_TYPES.MUTATIONS.LOAD_PARAMS, response.data.parameters)
                }
            })
    },

    async [MULTIBANKING_TYPES.ACTIONS.SAVE_USER_SETTINGS]({commit, getters, state}, change) {
        commit(MULTIBANKING_TYPES.MUTATIONS.SAVE_USER_SETTINGS, change)
        const userSettings = {}

        for (let prop in state.userSettings)
            if (Object.prototype.hasOwnProperty.call(state.userSettings, prop)) {
                userSettings[prop] = state.userSettings[prop]
            }

        await axios.post(
            `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionaccount`,
            {userSettings},
            config,
        )
    },

    async [MULTIBANKING_TYPES.ACTIONS.ABORT_REGISTER_NEW_ACCOUNT]({getters}, params) {
        await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/multibanking_registration/abort`, params, config)
    },

    [MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTIONS]({commit, getters}, payload) {
        if (!hasRoles([[ROLES.MULTIBANKING_DATA_ALLOWED, ROLES.MULTIBANKING_ACCOUNT_USER]], getters)) {
            return
        }

        const {toDate, fromDate} = payload || {}

        let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasaction?`
        if (fromDate) url += "fromDate=" + fromDate.getTime()
        if (toDate) {
            if (url.charAt(url.length - 1) != "?") url += "&"
            url += "toDate=" + toDate.getTime()
        }
        axios.get(url, config).then((response) => {
            if (response.data?.transactions) {
                const transactions = response.data.transactions.map((tr) => ({
                    ...tr,
                    valueDate: dayjs(tr.valueDate, "DD.MM.YYYY").toDate(),
                    entryDate: dayjs(tr.entryDate, "DD.MM.YYYY").toDate(),
                }))
                commit(MULTIBANKING_TYPES.MUTATIONS.LOAD_TRANSACTIONS, transactions)
            }
        })
    },

    async [MULTIBANKING_TYPES.ACTIONS.UPDATE_SELECTED_ACCOUNTS]({ getters, commit }, { callbackPath, providerId }) {
        const queryParam = {
          mobileNativeContext: getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT],
          providerId:providerId
        }
        const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionupdateaccountselection?${makeQueryParam(queryParam)}`, config)
        const callbackUrl = callbackPath + '?accountId=' + response.data.accountId;
        const mobileNativeMultibankingCallbackUrl = response?.data?.mobileNativeMultibankingCallbackUrl
        if (response?.data?.url) {
          const mbParam = {
            callbackUrl: mobileNativeMultibankingCallbackUrl ? mobileNativeMultibankingCallbackUrl : callbackUrl,
          }
          const newHref = response.data.url + `&${makeQueryParam(mbParam, true)}`;
          commit(MULTIBANKING_TYPES.MUTATIONS.BANKSAPI_URL, newHref);
        }
      },


    async [MULTIBANKING_TYPES.ACTIONS.SAVE_TRANSACTION_COMMENT]({commit, getters}, {transactionId, category, comment}) {
        const params = {
            categoryKey: category,
            customerComment: comment,
            transactionId,
        }

        await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactioncomment`, params, config)
        commit(MULTIBANKING_TYPES.MUTATIONS.SAVE_TRANSACTION_COMMENT, {transactionId, comment})
    },

    [MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTION_CATEGORIES]({commit, getters}, {withoutAmount}) {
        if (!hasRoles([[ROLES.MULTIBANKING_DATA_ALLOWED, ROLES.MULTIBANKING_ACCOUNT_USER]], getters)) {
            return
        }

        let url = `${
            getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]
        }/mrsbanktrasactioncategory?withoutAmount=${withoutAmount}`

        axios.get(url, config).then((response) => {
            let commitName = MULTIBANKING_TYPES.MUTATIONS.LOAD_TRANSACTION_CATEGORIES

            if (withoutAmount) {
                commitName = MULTIBANKING_TYPES.MUTATIONS.LOAD_TRANSACTION_CATEGORIES_COMBO
            }

            commit(commitName, {
                categories: response.data?.categories,
                byAccountExternalId: response.data?.byAccountExternalId,
            })
        })
    },
    [MULTIBANKING_TYPES.ACTIONS.LOAD_CONTRACTS]({ commit, getters }) {
        if (!hasRoles([[ROLES.MULTIBANKING_DATA_ALLOWED, ROLES.MULTIBANKING_ACCOUNT_USER]], getters)) {
          return
        }

        let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactioncontracts`

        axios.get(url, config).then(response => {
          let commitName = MULTIBANKING_TYPES.MUTATIONS.LOAD_CONTRACTS

          commit(commitName, {
            contracts: response.data?.contracts,
            accountId: response.data?.accountId,
          });
        });
      },
    [MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTION_SPLIT]({commit, getters}, transactionId) {
        axios
            .get(
                `${
                    getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]
                }/mrsbanktrasactionsplit?transactionId=${transactionId}`,
                config,
            )
            .then((response) => {
                if (response?.data?.transactionSplits) {
                    commit(MULTIBANKING_TYPES.MUTATIONS.LOAD_TRANSACTION_SPLIT, {
                        transactionId,
                        splits: response.data.transactionSplits,
                    })
                }
            })
    },
    [MULTIBANKING_TYPES.ACTIONS.SAVE_TRANSACTION_SPLIT]({commit, getters, dispatch}, {transactionId, splits}) {
        axios
            .post(
                `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionsplit`,
                {transactionId, transactionSplits: splits},
                config,
            )
            .then(() => {
                commit(MULTIBANKING_TYPES.MUTATIONS.LOAD_TRANSACTION_SPLIT, {transactionId, splits})
                dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTIONS)
                dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTION_CATEGORIES, {withoutAmount: false})
            })
    },

    [MULTIBANKING_TYPES.ACTIONS.SAVE_TRANSACTION_CATEGORY]({commit, getters}, {transactionId, category}) {
        const params = {
            transactionId,
            category: category.category,
            parentCategory: category.parentCategory?.category,
        }

        axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasaction`, params, config).then(() => {
            commit(MULTIBANKING_TYPES.MUTATIONS.SAVE_TRANSACTION_CATEGORY, {transactionId, category})
        })
    },

    [MULTIBANKING_TYPES.ACTIONS.UPDATE_CATEGORY]({commit, getters}, {categoryKey, budget, displayName}) {
        const params = {
            categoryKey,
            budget,
            displayName,
        }

        axios
            .post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactioncategory`, params, config)
            .then(() => {
                commit(MULTIBANKING_TYPES.MUTATIONS.UPDATE_CATEGORY, {categoryKey, budget, displayName})
            })
    },

    [MULTIBANKING_TYPES.ACTIONS.LOAD_SAVINGS_POTENTIAL]({commit, getters}) {
        if (!hasRoles([[ROLES.MULTIBANKING_DATA_ALLOWED, ROLES.MULTIBANKING_ACCOUNT_USER]], getters)) {
            return
        }

        axios
            .get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionsavingspot`, config)
            .then((response) => {
                if (response?.data) {
                    commit(MULTIBANKING_TYPES.MUTATIONS.LOAD_SAVINGS_POTENTIAL, response.data)
                }
            })
    },

    async [MULTIBANKING_TYPES.ACTIONS.LOAD_ALL_MULTIBANKING_DATA]({dispatch, state, commit, getters}) {
        if (state.dataLoaded) {
            return
        }

        const promises = []

    if (hasRoles([ROLES.MULTIBANKING_DATA_ALLOWED], getters)) {
      promises.push(dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_PARAMS));
    }
    
    if (hasRoles([[ROLES.MULTIBANKING_DATA_ALLOWED, ROLES.MULTIBANKING_ACCOUNT_USER]], getters)) {
      promises.push(dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_ACCOUNT_DATA));
      promises.push(dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_PARAMS));
      promises.push(dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTIONS));
      promises.push(dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTION_CATEGORIES, { withoutAmount: false }));
      promises.push(dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTION_CATEGORIES, { withoutAmount: true }));
      promises.push(dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_SAVINGS_POTENTIAL));
      promises.push(dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_ACCOUNT_SUMMARY));
      promises.push(dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_CONTRACTS));
      commit(MULTIBANKING_TYPES.MUTATIONS.SET_LOADED)
    } else {
      commit(MULTIBANKING_TYPES.MUTATIONS.UPDATE_LOADED_STATUS, { accounts: true })
    }

        await Promise.all(promises)
    },

    [MULTIBANKING_TYPES.ACTIONS.LOAD_ACCOUNT_SUMMARY]({commit, getters}) {
        if (!hasRoles([[ROLES.MULTIBANKING_DATA_ALLOWED, ROLES.MULTIBANKING_ACCOUNT_USER]], getters)) {
            return
        }

        axios
            .get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsbanktrasactionsummary`, config)
            .then((response) => {
                commit(MULTIBANKING_TYPES.MUTATIONS.LOAD_ACCOUNT_SUMMARY, response.data)
            })
    },
}
