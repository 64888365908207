export const MUTATION_PREFIX = 'PROCESS: ';
export const ACTIONS_PREFIX = 'PROCESS_';
export const GETTERS_PREFIX = 'PROCESS_';

export default {
  MUTATIONS: {
    GET_PROCESS_LIST_SUCCESS: GETTERS_PREFIX + 'GET_PROCESS_LIST_SUCCESS',
    GET_PROCESS_SUCCESS: GETTERS_PREFIX + 'GET_PROCESS_SUCCESS',
    GET_PROCESS_VORHABEN_OVERVIEW_SUCCESS: GETTERS_PREFIX + 'GET_PROCESS_VORHABEN_OVERVIEW_SUCCESS',
    GET_ZUGANG_LIST_SUCCESS: GETTERS_PREFIX + 'GET_ZUGANG_LIST_SUCCESS',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_PROCESS_LIST: ACTIONS_PREFIX + 'GET_PROCESS_LIST',
    GET_PROCESS: ACTIONS_PREFIX + 'GET_PROCESS',
    GET_PROCESS_VORHABEN_OVERVIEW: ACTIONS_PREFIX + 'GET_PROCESS_VORHABEN_OVERVIEW',
    DELETE_PROCESS: ACTIONS_PREFIX + 'DELETE_PROCESS',
    DELETE_PROCESS_ITEM: ACTIONS_PREFIX + 'DELETE_PROCESS_ITEM',
    SAVE_PROCESS: ACTIONS_PREFIX + 'SAVE_PROCESS',
    UPDATE_PROCESS: ACTIONS_PREFIX + 'UPDATE_PROCESS',
    SYNCHRONIZE_PROCESS: ACTIONS_PREFIX + 'SYNCHRONIZE_PROCESS',
    GET_ZUGANG_LIST: ACTIONS_PREFIX + 'GET_ZUGANG_LIST',
    EXPORT_PROCESS_TO: ACTIONS_PREFIX + 'EXPORT_PROCESS_TO',
    IMPORT_PROCESS: ACTIONS_PREFIX + 'IMPORT_PROCESS',
  },
  GETTERS: {
    PROCESS_LIST: GETTERS_PREFIX + 'PROCESS_LIST',
    PROCESS: GETTERS_PREFIX + 'PROCESS',
    PROCESS_VORHABEN_OVERVIEW: GETTERS_PREFIX + 'PROCESS_VORHABEN_OVERVIEW',
    ZUGANG_LIST: GETTERS_PREFIX + 'ZUGANG_LIST',
  }
}