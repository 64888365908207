import { BASE_AXIOS_CONFIG as config } from '@/configs/http-request-interceptor';
import axios from 'axios';
import VERTRIEBS_INFO_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import { VIEW_ROLES } from '@/router/roles';

export default {

  async [VERTRIEBS_INFO_TYPES.ACTIONS.LOAD_VERTRIEBS_DATA]({ dispatch }) {
    await Promise.all([
      dispatch(VERTRIEBS_INFO_TYPES.ACTIONS.PRODUCT_UTILIZATION),
      dispatch(VERTRIEBS_INFO_TYPES.ACTIONS.VERTRIEBS_INFO)
    ]);
  },

  async [VERTRIEBS_INFO_TYPES.ACTIONS.PRODUCT_UTILIZATION]({ dispatch, getters, commit, }, payload) {
    if (!getters[CORE_TYPES.GETTERS.HAS_ROLES]([VIEW_ROLES.VIEW_BROKER_AS_INTERN])) {
      return;
    }

    const params = {
      maklernr: payload?.maklernr || getters[CORE_TYPES.GETTERS.GET_USER_ID]
    }

    try {
      const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/vertriebsinfos/makler/produktnutzung`;
      const response = await axios.get(url, { ...config, params });
      if (response.data) {
        commit(VERTRIEBS_INFO_TYPES.MUTATIONS.PRODUCT_UTILIZATION_UPDATE, response.data);
      } else {
        throw Error('The request returned no value');
      }
    } catch (error) {
      dispatch(LOG_TYPES.ACTIONS.ERROR, error);
      commit(VERTRIEBS_INFO_TYPES.MUTATIONS.PRODUCT_UTILIZATION_UPDATE, {});
    }
  },

  async [VERTRIEBS_INFO_TYPES.ACTIONS.VERTRIEBS_INFO]({ dispatch, getters, commit, }, payload) {
    if (!getters[CORE_TYPES.GETTERS.HAS_ROLES]([VIEW_ROLES.VIEW_BROKER_AS_INTERN])) {
      return;
    }

    const params = {
      maklernr: payload?.maklernr || getters[CORE_TYPES.GETTERS.GET_USER_ID]
    }

    try {
      const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/vertriebsinfos/makler/infos`;
      const response = await axios.get(url, { ...config, params });
      if (response.data) {
        commit(VERTRIEBS_INFO_TYPES.MUTATIONS.VERTRIEBS_INFO_UPDATE, response.data);
      } else {
        throw Error('The request returned no value');
      }
    } catch (error) {
      dispatch(LOG_TYPES.ACTIONS.ERROR, error);
      commit(VERTRIEBS_INFO_TYPES.MUTATIONS.VERTRIEBS_INFO_UPDATE, {});
    }
  },

  async [VERTRIEBS_INFO_TYPES.ACTIONS.SAVE_VERTRIEBS_INFO]({ dispatch, getters, }, payload) {
    if (!getters[CORE_TYPES.GETTERS.HAS_ROLES]([VIEW_ROLES.VIEW_BROKER_AS_INTERN])) {
      return;
    }
    
    try {
      const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/vertriebsinfos/makler/infos`;
      await axios.post(url, payload, config);
    } catch (error) {
      dispatch(LOG_TYPES.ACTIONS.ERROR, error);
      throw error;
    }
  }

}
