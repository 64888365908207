<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu" >
    </OptionMenu>

    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :subtitle="subtitle"
      :id="$appNavigation.currentOptionMenuId"
      :actions="actions"
      @action-DELETE="deleteGoal()"
      @action-NEW_TICKET="newTicket"
      @action-EDIT_TITLE="editTitle()"
      @action-BACK_TO_SCENARIO="navigateTo('ruhestandsplanung/view/' + scenarioId)"
    >
    </PageHeaderTitleNavigation>

    <BaseModal
      ref="errorModal"
      :showDefaultButtons="false"
      modalTitle="Das Datum darf nicht zurück liegen!">
    </BaseModal>
    <BaseModal
      ref="titleModal"
      modalTitle="Bezeichnung anpassen"
      :confirmDisabled="!canSaveTitle"
      @onConfirmButton="title && doChanges('title', title)"
      >
        <InputField
          v-model="title"
          type="text" />
    </BaseModal>

    <StepperForm
      stepperName="myGoals"
      :stepperMediator="stepper"
      :hasFertig="false"
      :selectedStepKey="selectedStepKey"
      @set-step="setStepByKey"
    >

      <template #contentTemplate>
        <router-view></router-view>
      </template>
    </StepperForm>

  </div>
</template>

<script>
import InputField from "@/components/core/forms/InputField.vue";
import FinancialCalculator from '@/components/retirementScenario/financialCalculator';
import BaseModal from '@/components/core/BaseModal.vue';
import StepperForm from '@/components/stepper/StepperForm.vue';
import { StepperMediator } from '@/components/stepper/utils';
import MY_GOALS_TYPES from '@/store/myGoals/types';
import { PhPencilLine } from 'phosphor-vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import { PageHeaderSimpleAction, PageHeaderConfirmedAction } from '@/components/core/header-title-navigation/page-header-utils';
import mixin from './my-goals-mixin.js';

export default {
  mixins: [mixin],
  data() {
    return {
      title: '',
      steps: [
        {
          stepKey: 'form',
          label: 'Ziel definieren',
          totalProgress: 1
        },
        {
          stepKey: 'preview',
          label: 'Ergebnis',
          totalProgress: 2
        }
      ],
    }
  },
  computed: {
    selectedStepKey() {
      return this.$route.path?.endsWith('/form') ? 'form' : 'preview';
    },
    subtitle() {
      return `<b>${this.typeDesc} | ${this.goal.title || ''}${this.goal.endDate && ' | Zieldatum ' + this.goal.endDate || ''}</b>`;
    },
    typeDesc() {
      return this.goal?.typeDesc || this.$route.query.type && this.goalsTypes.find(type => type?.key === this.$route.query.type)?.name || '';
    },
    stepper() {
      return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
    },
    canSaveTitle() {
      return this.title?.length > 0;
    },
    brokerText() {
      if (!this.goal || !Object.keys(this.goal)?.length) {
        return ''
      }
      let text = 'Sehr geehrte Damen und Herren, <br><br>'
      + 'Ich möchte auf ein Ziel sparen und benötige eine Beratung. <br>'
      + 'Meine Berechnungen für das Ziel lauten wie folgt: <br><br>'
      + 'Typ: ' + this.typeDesc + '<br>'
      + 'Anfangs Datum: ' + (this.goal.startDate || '') + '<br>';

      if (this.isImmobilie) {
        text += 'Anzahl an Zahlungen: ' + Math.round(this.anzahlRaten) + '<br>'
        + 'Sparrate: ' + FinancialCalculator.toGermanFormat(this.goal.sparrate) + ' €<br>'
        + 'Startkapital: ' + FinancialCalculator.toGermanFormat(this.goal.startkapital) + ' €<br>'
        + 'Benötigtes Kapital: ' + FinancialCalculator.toGermanFormat(this.goal.zielsumme) + ' €<br>'
        + 'Angespartes Kapital: ' + FinancialCalculator.toGermanFormat(this.calculateAngespartesKapital) + ' €<br>'
        + 'Finanzierungsbedarf: ' + FinancialCalculator.toGermanFormat(this.calculateFinanzierungsbedarf) + ' €<br>';

      } else {
        text += 'Anzahl an Zahlungen: ' + Math.round(this.anzahlRaten) + '<br>'
        + 'Sparrate: ' + FinancialCalculator.toGermanFormat(this.goal.sparrate) + ' €<br>'
        + 'Startkapital: ' + FinancialCalculator.toGermanFormat(this.goal.startkapital) + ' €<br>'
        + 'Zukunftswert: ' + FinancialCalculator.toGermanFormat(this.goal.zielsumme) + ' €<br>';
      }
      return text;
    },
    actions() {
      const actions = []

      if (this.id && this.id !== 'new') {
        actions.push(PageHeaderSimpleAction('NEW_TICKET', 'Nachricht an Betreuer'))
        actions.push(PageHeaderConfirmedAction('DELETE', 'Ziel Löschen', '', 'Möchten Sie dieses Ziel wirklich löschen?', 'Ja'))
        // actions.push(PageHeaderSimpleAction('DEPOTS', 'Depot verknüpfen'))
      }
      if (this.scenarioId) {
        actions.push(PageHeaderSimpleAction('BACK_TO_SCENARIO', 'Zurück zur Ruhestandsplanung'))
      }
      if (this.selectedStepKey !== 'form') {
        actions.push(PageHeaderSimpleAction('EDIT_TITLE', 'Bezeichnung ändern'))
      }

      return actions;
    },
  },
  methods: {
    newTicket() {
      this.$addBreadcrumb({
        breadcrumb: 'Mein Ziel',
        fullPath: this.$route.fullPath,
      });
      this.$router.push({name: 'new-ticket', params: {textInput: this.brokerText, richText: true, thema: `Mein Ziel - ${this.goal.typeDesc} - ${this.goal.title}`}});
    },
    editTitle() {
      this.title = this.goal.title;
      this.$refs.titleModal.open();
    },
    setStepByKey(stepKey) {
      if (!this.goal.title) {
        this.$confirmModal({
          title: 'Bezeichnung', 
          message: 'Ohne Bezeichnung wird mein Ziel nich gespeichert. Bitte geben Sie die Bezeichnung ein.', 
          showCancelButton: false,
          labelButtonConfirm: 'Ok'
        })
      } else if (stepKey && this.selectedStepKey !== stepKey) {
        this.saveGoal();
        this.$router.push(`${this.path}/${this.id}/${stepKey}`)
      }
    },
    deleteGoal() {
      this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.DELETE_GOAL, {id: this.id});
      this.$router.push(`${this.path}`)
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.goal.title) {
      await this.saveGoal();
      setTimeout(() => {next()}, 1000)
    } else {
      this.$confirmModal({
        title: 'Bezeichnung', 
        message: 'Ohne Bezeichnung wird mein Ziel nich gespeichert. Bitte geben Sie die Bezeichnung ein.', 
        labelButtonCancel: 'Auf der Seite bleiben',
        labelButtonConfirm: 'Ohne speichern zurück gehen'
      }).then(() => {
        next();
      })
    }
  },
  components: {
    BaseModal,
    InputField,
    StepperForm,
    PageHeaderTitleNavigation,
    OptionMenu,
    PhPencilLine,
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
</style>