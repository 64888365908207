import VERTRIEBS_INFO_TYPES from './types';
import { PRODUCT_LABELS, ORGANIZATION_TYPES, PRODUCT_ORDER } from './index';

export default {

  [VERTRIEBS_INFO_TYPES.GETTERS.PRODUCT_UTILIZATION](state) {
    return state.productUtilization;
  },

  [VERTRIEBS_INFO_TYPES.GETTERS.VERTRIEBS_INFO](state) {
    return state.vertriebsInfo;
  },

  [VERTRIEBS_INFO_TYPES.GETTERS.FORMATTED_PRODUCT_UTILIZATION](state) {
    if (!state.productUtilization && !state.vertriebsInfo) {
      return null;
    }

    const response = Object.fromEntries(
      Object.entries({ ...state.productUtilization, ...state.vertriebsInfo } || {})
        .filter(([key]) => PRODUCT_LABELS[key])
        .sort(([aKey], [bKey]) => PRODUCT_ORDER.indexOf(aKey) - PRODUCT_ORDER.indexOf(bKey))
        .map(([key, value]) => [PRODUCT_LABELS[key], value])
    );

    if (state.vertriebsInfo?.maklergruppe) {
      response[state.vertriebsInfo.maklergruppe] = true;
    }

    const organizationTypeLabel = ORGANIZATION_TYPES[state.vertriebsInfo?.organisationsTyp];
    if (organizationTypeLabel) {
      response[organizationTypeLabel] = true;
    }

    return response;
  },

}
