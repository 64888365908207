import VERTRIEBS_INFO_TYPES from './types';
import Vue from 'vue';
import { getInitialState } from './index'

export default {

  [VERTRIEBS_INFO_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [VERTRIEBS_INFO_TYPES.MUTATIONS.PRODUCT_UTILIZATION_UPDATE](state, payload) {
    Vue.set(state, 'productUtilization', payload);
  },

  [VERTRIEBS_INFO_TYPES.MUTATIONS.VERTRIEBS_INFO_UPDATE](state, payload) {
    Vue.set(state, 'vertriebsInfo', payload);
  },

}
