<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
    />

    <PageHeaderTitleNavigation
      title="Vermittlersuche"
      :defaultMenu="customOptionMenu"
      :actions="headerActions"
      @action-SELECTION-DONE="handleBackAction"
      @action-NEW-BROKER="addNewBroker()"
    />

    <GenericPersonFilter
      filterId="fa9b49fb-41b0-4c49-bc6e-981c0741d8bc"
      title="Vermittlersuche"
      saveKey="borkerSearchAdvanced"
      :metadata="searchMenu"
      :predefinedFilter="predefinedFilter"
      @search="handleSearch($event)"
      :isCustomerSearch="false"
      :configFilter="configFilter"
      :isCache="$route.meta.isCache"
      :defaultOptions="defaultFilter"
    />

    <div class="box__container" v-if="isExternalSelect || showWithPerson">
      <InputCheckBoxItem
        label="Ansprechpartner anzeigen"
        v-model="withPerson"
      />
    </div>
    <HandleTablePin keyOfPIN="TABELLE_VERMITTLERLISTE" />
    <div
      v-if="filtered"
      class="box__container"
      tid="57259cdd-9d9c-4ef5-8afc-66c34a2f5fcf"
    >
      <GhostLoading
        v-if="loading && !scrollLoading"
        type="table"
        :title="TABLE_TITLE"
      />

      <UnexpectedError v-else-if="error" />

      <PaginatedTable
        v-else
        :title="TABLE_TITLE"
        tableId="9815ec29-a2c2-49e4-95b9-04831b92d2c5"
        ref="tableBrokers"
        v-model="selectedRows"
        :selectionAsBlacklist="invertSelection"
        :headers="headers"
        :pages="pages"
        :pageCount="pageCount"
        :rowCount="rowCount"
        :page="pageId"
        :exportConfig="exportConfig"
        :sorted="brokerSearchSort"
        :thHoverDisabled="true"
        :headerActions="tableHeaderActions"
        :mobileConfig="{
          title: 'fullName',
          headers: ['userId', 'structureLeader'],
          selectionActive: isExternalSelect,
        }"
        @page="pageId = $event"
        @requestPage="loadPage"
        @selectionAsBlacklist="selectionAsBlacklist"
        @click-fullName="selectUserId($event.userId)"
        @click-phone="callTapi($event.userId, $event.phone)"
        @click-structureLeader="openStrukturleiter($event)"
        @headerAction-SEND-MESSAGE-TO-SELECTED="handleNewEmail"
        @sort="onBrokerSearchResultSort"
        @onScroll="onScroll"
        @scrollLoading="scrollLoading = $event"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CUSTOMER_SEARCH_TYPES from "@/store/customerSearch/types";
import CORE_TYPES from "@/store/core/types";
import GenericPersonFilter from "@/components/core/GenericPersonFilter.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import { searchMenu } from "./brokerSearchConstants.js";
import { searchMenuIntern } from "./brokerSearchInternConstants.js";
import BaseButton from "@/components/core/BaseButton.vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import InputCheckBoxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import PaginatedTable from "@/components/table2/PaginatedTable.vue";
import {
  CurrencyColumn,
  TextColumn,
  NumberColumn,
  PillColumn,
  IconColumn,
  Icon,
} from "@/components/table2/table_util.js";
import { PhCheck, PhWarning } from 'phosphor-vue';
import TAPI from "@/store/tapi/types";
import UnexpectedError from "@/components/core/UnexpectedError.vue";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import { PageHeaderSimpleAction } from "@/components/core/header-title-navigation/page-header-utils";
import HandleTablePin from "@/components/core/HandleTablePin.vue";
import { isPathAllowed } from "@/router/guards";
import dayjs from "dayjs";
import { looksLikeMaklernr } from '@/store/brokerSearch/mutations';
import { VIEW_ROLES } from '@/router/roles';
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import { ORGANIZATION_TYPES } from "@/store/vertriebsinfo/index.js";

const TABLE_TITLE = "Vermittlerliste";
const ADD_NEW_MAKLER_PATH = "/customer/vermittler-anlegen";

export default {
  props: {
    isExternalSelect: {
      type: Boolean,
      default: false,
    },
    showWithPerson: {
      type: Boolean,
      default: false,
    },
    isWithPerson: {
      type: Boolean,
      default: false,
    },
    customOptionMenu: {
      type: Array,
    },
  },
  data() {
    return {
      TABLE_TITLE,
      withPerson: false,
      searchValue: null,
      // defaultParameters: [
      //   { menuItemKey: 'maxCount', menuItemValue: 20 },
      //   // { menuItemKey: 'structure', menuItemValue: true },
      // ],
      searchKeyborkerSearchAdvanced: "borkerSearchAdvanced",

      pageId: 0,
      invertSelection: false,
      selectedRows: [],
      searchParams: {},
      externParams: null,
      singleSelection: false,
      error: false,
      loading: false,
      filtered: false,
      brokerSearchSort: null,
      scrollLoading: false,
    };
  },
  mixins: [InteractiveHelpCommonsMixin],
  watch: {
    brokerFilterSetup(value) {
      if (value) {
        if (value.maklerkriterien) {
          if (this.isIntern) {
            this.updateComboBoxOptionMenu(
              value.maklerkriterien,
              "diverses2",
              "maklerkriterien"
            );
          } else {
            this.updateComboBoxOptionMenu(
              value.maklerkriterien,
              "sonstiges",
              "maklerkriterien"
            );
          }
        }
      }
    },

    customerFilterSetupDepot(next, oldValue) {
      if (next && next.data) {
        if (next.data.gesellschaftDepot) {
          this.updateComboBoxOptionMenu(
            next.data.gesellschaftDepot,
            "depot",
            "gesellschaftDepot"
          );
        }
        if (next.data.lagerstelleDepot) {
          this.updateComboBoxOptionMenu(
            next.data.lagerstelleDepot,
            "depot",
            "lagerstelleDepot"
          );
        }
        if (next.data.betreuer) {
          this.updateComboBoxOptionMenu(
            next.data.betreuer,
            "diverses2",
            "betreuer"
          );
        }
        if (next.data.besucher) {
          this.updateComboBoxOptionMenu(
            next.data.besucher,
            "diverses2",
            "besucher"
          );
        }
        if (next.data.hobbies) {
          this.updateComboBoxOptionMenu(
            next.data.hobbies,
            "diverses2",
            "hobbies"
          );
        }
        if (next.data.kategorie) {
          this.updateComboBoxOptionMenu(
            next.data.kategorie,
            "diverses1",
            "kategorie"
          );
        }
        if (next.data.potential) {
          this.updateComboBoxOptionMenu(
            next.data.potential,
            "diverses1",
            "potential"
          );
        }
      }
    },
    customerFilterSetupVers(next, oldValue) {
      if (next && next.data) {
        if (next.data.fremdGesellschaft) {
          this.updateComboBoxOptionMenu(
            next.data.fremdGesellschaft,
            "versicherung",
            "gesellschaftVers"
          );
        }
        if (next.data.vertragGesellschaft) {
          this.updateComboBoxOptionMenu(
            next.data.vertragGesellschaft,
            "versicherung",
            "lagerstelleVers"
          );
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      brokersData: CUSTOMER_SEARCH_TYPES.GETTERS.BROKERS,
      brokerFilterSetup: CUSTOMER_SEARCH_TYPES.GETTERS.BROKER_FILTER_SETUP,
      customerFilterSetupDepot:
        CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_FILTER_SETUP_DEPOT,
      customerFilterSetupVers:
        CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_FILTER_SETUP_VERS,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      inaktivAllowed: CORE_TYPES.GETTERS.IS_RIGHT_SET_MAKLER_INAKTIV,
      isBrokerMaster: CORE_TYPES.GETTERS.IS_BROKER_MASTER,
      mailLinkedObject: MAILCOMPOSER_TYPES.GETTERS.GET_MAIL_LINKED_OBJECT,
      tablePageSize: BROKERDATA_TYPES.GETTERS.GET_BROKER_PAGE_TABLE_SIZE,
      userId: CORE_TYPES.GETTERS.GET_USER_ID,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      istDGFRP: CORE_TYPES.GETTERS.IS_DGFRP_MASTER,
      newCustomerData: CORE_TYPES.GETTERS.NEW_CUSTOMER_DATA,
    }),
    searchMenu() {
      return this.isIntern
        ? searchMenuIntern(this.isFK)
        : searchMenu(this.inaktivAllowed, this.isBrokerMaster, this.istDGFRP, this.isFK);
    },
    headerActions() {
      if (this.singleSelection) {
        return [];
      }
      return [
        PageHeaderSimpleAction("SELECTION-DONE", "Auswahl übernehmen")
          .withVisible(() => this.isExternalSelect)
          .withDisabled(() => !this.hasSelection),
        PageHeaderSimpleAction("NEW-BROKER", "Vermittler anlegen").withVisible(
          () => this.isAddNewBrokerVisible
        ),
      ];
    },
    isAddNewBrokerVisible() {
      return isPathAllowed(ADD_NEW_MAKLER_PATH);
    },
    predefinedFilter() {
      if (!this.searchValue) {
        return null;
      }
      return {
        id: 529,
        searchKey: "borkerSearchAdvanced",
        searchLabel: "Testing",
        parameters: `[{"key":"allgemein","primarySelection":[{"value":"allgemein","label":"Allgemein","type":"group"}],"secondaryKey":"surname","componentType":"text","type":"group","disabled":false,"brokerId":"","comboOptions":[],"surname":"${this.searchValue}","value":"${this.searchValue}"}]`,
        visible: true,
        columnOrder: 4,
      };
    },
    configFilter() {
      return {
        placeholderForDefSearchInput: "Firmenname, Vermittlernummer",
        filterType: "vermittlersuche",
      };
    },
    defaultFilter() {
      if (this.$route.path && this.$route.path?.includes('mailcomposer-bulk') ) {
        return {emailRundmailEmpfeanger: true}
      }

      return {}
    },
    exportConfig() {
      const name =
        TABLE_TITLE + " " + dayjs(new Date()).format("DD MMM YYYY HH mm");
      return {
        pdf: { name: name + ".pdf" },
        xls: { name: name + ".xlsx" },
        title: TABLE_TITLE,
        dispatch: this.exportAsFile,
      };
    },
    headers() {
      let headers = {
        lockedLeft: [
          TextColumn("fullName", "Name").makeLink(),
          TextColumn("userId", "Vermittlernummer").makeSortable((cell) => {
            if (cell.indexOf("-") > 0) {
              try {
                cell = cell.replace(/ /g, "");
                const nr = 1000 + parseInt(cell.slice(cell.indexOf("-") + 1));
                return cell.slice(0, cell.indexOf("-")) + nr;
              } catch (e) {
                console.error(e);
              }
            }
            return cell;
          }),
        ],
        center: [
          TextColumn("structureLeader", "Strukturleiter")
            .makeConditionalLink("canOpenStrukturleiter"),
          TextColumn("phone", "Telefon").makeLink(
            this.$store.getters[TAPI.GETTERS.CAN_OUT_CALL]
          ),
          TextColumn("smsStandard", "Mobilnummer"),
          TextColumn("email", "E-Mail"),
          TextColumn("street", "Straße"),
          TextColumn("plz", "PLZ"),
          TextColumn("location", "Ort"),
          // TextColumn("zip", "TIN"),
          TextColumn("fax", "Fax"),
          TextColumn("organisationsTyp", "OrganisationsTyp"),
          IconColumn("saas", "SaaS")
        ],
      };
      if (this.isFK) {
        headers.center.push(
          TextColumn("regIHK", "IHK-Reg."),
          TextColumn("gwg", "GWG"),
          TextColumn("ze", "ZE"),
        )
      }
      if (!this.isIntern) {
        headers.lockedLeft.push(CurrencyColumn("provBestand", "Bestand"));

        if (this.inaktivAllowed) {
          headers.center.splice(
            0,
            0,
            PillColumn("active", "Status").makeHidden()
          );
        }
      } else {
        // insert after Strukturleiter
        if (this.isFK) {
          headers.center.splice(
            1,
            0,
            TextColumn("buergel", "Bürgel"),
            CurrencyColumn("stornoRrisiko", "Stornorisiko"),
            CurrencyColumn("stornoRrisikoStuct", "Stornorisiko Struktur"),
            TextColumn("stufe", "Stufe"),
            NumberColumn("insurances", "Versicherungen"),
            TextColumn("vertrag", "Vertrag"),
            TextColumn("betreuer", "Betreuer"),
          );
        } else {
          headers.center.splice(
            1,
            0,
            CurrencyColumn("stornoRrisiko", "Stornorisiko"),
            CurrencyColumn("stornoRrisikoStuct", "Stornorisiko Struktur"),
            TextColumn("stufe", "Stufe"),
            NumberColumn("insurances", "Versicherungen"),
          );
        }
        // insert before Strukturleiter
        headers.center.splice(
          0,
          0,
          PillColumn("active", "Status"),
          TextColumn("category", "Kategorie"),
          NumberColumn("topCourtage", "Top Courtage"),
          CurrencyColumn("portfolioOpenFondsSingle", "Investment"),
          CurrencyColumn("portfolioOpenFondsStructure", "Investment Struktur"),
          CurrencyColumn("portfolioClosedSingle", "Beteiligung"),
          CurrencyColumn("portfolioClosedStructure", "Beteiligung Struktur")
        );
      }

      return headers;
    },
    allPages() {
      if (!this.brokersData.pages) return {};
      return Object.fromEntries(
        Object.entries(this.brokersData.pages).map(([key, page]) => {
          return [
            key,
            page.flatMap((item) => {
              const result = [
                {
                  customerID: item.user?.userId,
                  userId: item.user?.userId,
                  id: item.user?.userId,
                  email: item.email,
                  name:
                    (item.email?.includes("<") && item.email) || item.fullName,
                  type: item.user?.type || "MAKLER",
                },
              ];
              return result;
            }),
          ];
        })
      );
    },
    pages() {
      const result = {};
      for (const [pageId, rows] of Object.entries(
        this.brokersData.pages || {}
      )) {
        result[pageId] = (rows||[]).map((row) => {
          let active = row.inactive
            ? { label: "inaktiv", type: "danger" }
            : { label: "aktiv", type: "info" };

          const saas = row.internData?.saas
            ? Icon(PhCheck, 'Mit SaaS', 20)
            : Icon(PhWarning, 'Ohne SaaS', 20);

          const organisationsTyp = row.internData?.organisationsTyp ? ORGANIZATION_TYPES[row.internData.organisationsTyp] : row.internData?.organisationsTyp;

          return {
            id: row.userId,
            ...row,
            ...(row.internData || {}),
            active,
            canOpenStrukturleiter: this.$hasRoles([VIEW_ROLES.VIEW_INTERN]) && looksLikeMaklernr(row.structureLeader),
            saas,
            organisationsTyp
          };
        });
      }
      return result;
    },
    rowCount() {
      return this.brokersData?.count || 0;
    },
    pageCount() {
      if (this.tablePageSize > 0) {
        return Math.ceil(this.rowCount / this.tablePageSize);
      }
      return Math.ceil(this.rowCount / 20);
    },
    hasSelection() {
      return (
        (!this.invertSelection && this.selectedRows?.length > 0) ||
        (this.invertSelection && this.selectedRows?.length < this.rowCount)
      );
    },
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction(
          "SEND-MESSAGE-TO-SELECTED",
          "Nachricht an Vermittler"
        )
          .withVisible(() => !!this.selectedRows)
          .withDisabled(() => !this.hasSelection),
      ];
    },
  },
  created() {
    this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.RESET_STATE_SEARCH);
  },
  mounted: function() {
    this.withPerson = this.isWithPerson;
    this.searchValue = this.$route.params.value;
    if (this.tablePageSize === -1) {
      this.$store.dispatch(
        BROKERDATA_TYPES.ACTIONS.LOAD_BROKER_PAGE_TABLE_SIZE
      );
    }
    this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_BROKER_SETUP);
    this.$store.dispatch(
      CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_CUSTOMER_SETUP_DEPOT
    );
    this.$store.dispatch(
      CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_CUSTOMER_SETUP_VERS
    );
    if (this.$route.query.bipro) {
      this.externParams = { includeSelf: true };
      this.singleSelection = true;
      this.invertSelection = null;
      this.selectedRows = null;
    }
    this.isLoaded = true;
  },
  methods: {
    addNewBroker() {
      this.$router.push(ADD_NEW_MAKLER_PATH);
    },
    onBrokerSearchResultSort(brokerSearchSort) {
      this.brokerSearchSort = brokerSearchSort;
      this.loadPage(0, true);
    },
    selectUserId(brokerId) {
      if (this.$route.query.bipro) {

        if (this.newCustomerData && brokerId) {
          this.createNewCustomerAndOpenIt(brokerId);

        } else {
          if (this.userId == brokerId) {
            this.$router.push("/" + this.$route.query.nextUrl);
            return;
          }

        }

      } else {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {
          brokerId,
          path: this.$route.query.nextUrl,
        });

      }
    },
    createNewCustomerAndOpenIt(brokerId) {
      const payload = {
          ...this.newCustomerData,
          maklernr: brokerId,
        }

      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_NEW_CUSTOMER_AS_INTERN, payload).then(response => {

        let path = `/persoenlichedaten/customer-data/steps/stammdaten`;

        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
          customerId: response?.customerID,
          path
        });

      });


    },
    updateComboBoxOptionMenu(setupList, menuGroupKey, menuItemKey) {
      if (setupList) {
        const foundMenuGroup = this.getMenuGroupByKey(menuGroupKey);

        if (foundMenuGroup) {
          const foundMenuItem = foundMenuGroup.menuItems.find(
            (menuItem) => menuItem.key === menuItemKey
          );

          if (foundMenuItem) {
            foundMenuItem.comboOptions = setupList;
          }
        }
      }
    },
    callTapi(maklerNr, phone) {
      //this.$store.dispatch(TAPI.ACTIONS.OPEN_CALL_OUT_DIALOG,{'Kunde'});
      this.$store.dispatch(TAPI.ACTIONS.OPEN_CALL_OUT_DIALOG, {
        userType: "Makler",
        userId: maklerNr,
        phoneNumber: phone,
      });
      //this.$refs.OutcomingCallTapiDialog?.open(phoneNumber);
    },

    getMenuGroupByKey(menuGroupKey) {
      return this.searchMenu.find(
        (menuGroup) => menuGroup.key === menuGroupKey
      );
    },

    search(e = null, section = null, cb = null) {
      if ((e && e.target && e.target.id === "global") || e === "") {
        return;
      }
    },

    handleSearch(params) {
      this.searchParams = params;
      if (this.externParams) {
        Object.assign(this.searchParams, this.externParams);
      }
      this.loadPage(0, true);
    },
    async loadPage(pageId, force = false, filterType = null) {
      try {
        this.pageId = pageId;
        if (!force && this.brokersData.pages && this.brokersData.pages[pageId]) {
          return;
        }
        let searchParams = JSON.parse(JSON.stringify(this.searchParams));
        ['gewOInv', 'gewOInvB','gewOVers','gewOVersB'].forEach(key => {
          if (searchParams[key]) {
            searchParams[key] = searchParams[key].map(i => i.value).join('_')
          }
        })
        console.log('searchParams', JSON.parse(JSON.stringify(searchParams)) );
        const sendKeys = this.searchMenu.map(s => s.menuItems).flatMap( s => s).map(s => s.key);
        if (searchParams.filterNot?.length) {
          const sParams = {};
          Object.keys(searchParams).forEach(key => {
            if (key != 'filterNot') {
              if (searchParams.filterNot.find(notK => notK == key) ) {
                if (key.slice(0,3) == 'mit') {
                  sParams['ohne' + key.slice(3)] = searchParams[key];
                  sendKeys.push('ohne' + key.slice(3))
                } else {
                  sParams[key] = '!' + searchParams[key];
                }
              } else {
                sParams[key] = searchParams[key];
              }
            }
          })
          searchParams = sParams
        }
        const params = {
          ...searchParams,
          pageId,
          maxCount: this.tablePageSize > 0 ? this.tablePageSize : 20,
          withPerson: this.withPerson,
        };
        if (this.withPerson) {
          sendKeys.push('withPerson')
        }

        if (this.brokerSearchSort && this.brokerSearchSort.key) {
          params.sort = this.brokerSearchSort.key;
          params.sortDirection = this.brokerSearchSort.sortDirection || "asc";
          sendKeys.push('sort', 'sortDirection');
        } else {
          delete params.sort;
          delete params.sortDirection;
        }
        if (filterType) {
          params.filterType = filterType;
        }
        params.sendKeys = sendKeys;
        this.loading = true;
        this.filtered = true;
        this.error = false;
        await this.$store.dispatch(
          CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_BROKER,
          params
        );
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    exportAsFile(fileType) {
        let searchParams = this.searchParams;
        if (this.searchParams.filterNot?.length) {
          searchParams = {};
          Object.keys(this.searchParams).forEach(key => {
            if (key != 'filterNot') {
              if (this.searchParams.filterNot.find(notK => notK == key) ) {
                if (key.slice(0,3) == 'mit') {
                  searchParams['ohne' + key.slice(3)] = this.searchParams[key];
                } else {
                  searchParams[key] = '!' + this.searchParams[key];
                }
              } else {
                searchParams[key] = this.searchParams[key];
              }
            }
          })

        }
      const params = {
        ...searchParams,
        pageId: 0,
        section: 1,
        maxCount: 10000,
        withPerson: this.withPerson,
      };

      if (this.brokerSearchSort && this.brokerSearchSort.key) {
        params.sort = this.brokerSearchSort.key;
        params.sortDirection = this.brokerSearchSort.sortDirection || "asc";
      } else {
        params.sort = "fullName";
        params.sortDirection = "asc";
      }
      params.fileType = fileType;
      params.title = this.exportConfig.title;
      params.keys = this.$refs?.tableBrokers?.$refs?.sortableTable?.visibleHeadersFlat
        .filter((h) => h.exportFn)
        .map((h) => h.key);
      params.headers = this.$refs?.tableBrokers?.$refs?.sortableTable?.visibleHeadersFlat
        .filter((h) => h.exportFn)
        .map((h) => h.label);

      return this.$store.dispatch(
        CUSTOMER_SEARCH_TYPES.ACTIONS.EXPORT_BROKERS,
        params
      );
    },
    async onScroll(onScrollEnd) {
      try {
        await this.loadPage(this.pageId + 1);
      } finally {
        onScrollEnd();
      }
    },
    async handleBackAction(setValues = true) {
      if (setValues && this.invertSelection) {
        await this.loadPage(-1, false, "MailComposer");
        // invert the selection
        const selectedRows = Object.values(this.allPages)
          .flat()
          .filter(
            (row) =>
              !this.selectedRows.some(
                (selected) => selected.userId == row.userId
              )
          );
        this.$emit("handleBackAction", selectedRows);
      } else {
        this.$emit("handleBackAction", setValues ? this.selectedRows : []);
      }
    },
    async handleNewEmail() {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: "mailcomposer-bulk",
        action: "clear",
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: "mailcomposer-bulk",
        action: "clear",
      });
      if (this.invertSelection) {
        await this.loadPage(-1, false, "MailComposer");
        // invert the selection
        const selectedRows = Object.values(this.allPages)
          .flat()
          .filter(
            (row) =>
              !this.selectedRows.some(
                (selected) => selected.userId == row.userId
              )
          );
        this.$store.commit(
          MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS,
          selectedRows.map((v) => ({
            customerID: v.id,
            id: v.id,
            name:
              `${v.fullName} ${v.email ? `<${v.email}>` : ""}` ||
              v.email ||
              (v.name && v.name.replace(",", "")),
            email: v.email,
            type: "MAKLER",
          }))
        );
      } else {
        this.$store.commit(
          MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS,
          this.selectedRows.map((v) => ({
            id: v.id,
            customerID: v.id,
            name:
              `${v.fullName} ${v.email ? `<${v.email}>` : ""}` ||
              v.email ||
              (v.name && v.name.replace(",", "")),
            email: v.email,
            type: "MAKLER",
          }))
        );
      }
      this.$router.push(`/communication/mailcomposer-bulk`);
    },
    selectionAsBlacklist(value) {
      this.invertSelection = value;
    },
    openStrukturleiter(row) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {
        brokerId: row.structureLeader,
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück zur Vermittlersuche",
      to,
      from,
    });

    next();
  },
  components: {
    PaginatedTable,
    GenericPersonFilter,
    OptionMenu,
    BaseButton,
    InputCheckBoxItem,
    GhostLoading,
    UnexpectedError,
    PageHeaderTitleNavigation,
    HandleTablePin,
  },
};
</script>

<style scoped>
::v-deep .input-multiple__item-container {
  display: inline-block !important;
  min-height: 32px;
}
::v-deep .layout__negative-margin--8 {
  margin: 0 !important;
}
::v-deep .layout__negative-margin--8 > * {
  margin: 0 !important;
}
::v-deep .input-multiple__item--row {
  display: inline !important;
  vertical-align: middle;
  padding-right: 8px;
}
</style>
