var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.getTitel,
          actions: _vm.headerActions
        },
        on: { "action-SIGN": _vm.startDocumentSigning }
      }),
      _vm.hinweiseMessages.length || _vm.errors.length
        ? _c("HinweiseUndFehler", {
            ref: "hinweiseUndFehler",
            attrs: { hints: _vm.hinweiseMessages, errors: _vm.errors },
            on: {
              "set-step": function($event) {
                return _vm.setStep($event)
              }
            }
          })
        : _vm._e(),
      !_vm.form.showWarningEarly
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputField", {
                attrs: { label: _vm.getLabelStand, disabled: true },
                model: {
                  value: _vm.form.currentStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "currentStatus", $$v)
                  },
                  expression: "form.currentStatus"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.form.showContent
        ? _c("ContentWithStepper", {
            attrs: { stepperCollapsed: _vm.stepperCollapsed },
            scopedSlots: _vm._u(
              [
                {
                  key: "stepper",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("VerticalStepper", {
                            attrs: {
                              stepType: "tab",
                              stepperName: "VertragWP",
                              selectedStepKey: _vm.selectedStepKey,
                              stepperService: _vm.steps,
                              showCollapse: true
                            },
                            on: {
                              setStep: function($event) {
                                return _vm.setStep($event)
                              },
                              collapsed: function($event) {
                                _vm.stepperCollapsed = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        [
                          _vm.selectedStepKey === "contractData1"
                            ? _c("ContractData1", {
                                attrs: { formData: _vm.form },
                                on: {
                                  fieldChanged: function($event) {
                                    return _vm.fieldChanged($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.selectedStepKey === "contractData2"
                            ? _c("ContractData2", {
                                attrs: { formData: _vm.form },
                                on: {
                                  fieldChanged: function($event) {
                                    return _vm.fieldChanged($event)
                                  },
                                  changeVtgArt34Modus: function($event) {
                                    return _vm.changeVtgArt34Modus($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.selectedStepKey === "contractData3"
                            ? _c("ContractData3", {
                                attrs: { formData: _vm.form },
                                on: {
                                  fieldChanged: function($event) {
                                    return _vm.fieldChanged($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.selectedStepKey === "contractData4"
                            ? _c("ContractData4", {
                                attrs: { formData: _vm.form },
                                on: {
                                  fieldChanged: function($event) {
                                    return _vm.fieldChanged($event)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.selectedStepKey === "summary"
                            ? _c("Summary", {
                                on: {
                                  fieldChanged: function($event) {
                                    return _vm.fieldChanged($event)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", { staticClass: "box__container" }, [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "layout__negative-margin--8 d-flex"
                            },
                            [
                              _vm.selectedStepKey !== "contractData1"
                                ? _c(
                                    "BaseButton",
                                    {
                                      attrs: { isSecondary: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.onButtonPrevious()
                                        }
                                      }
                                    },
                                    [_vm._v(" Zurück ")]
                                  )
                                : _vm._e(),
                              _vm.selectedStepKey !== "summary"
                                ? _c(
                                    "BaseButton",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.onButtonNext()
                                        }
                                      }
                                    },
                                    [_vm._v(" Weiter ")]
                                  )
                                : _vm._e(),
                              _vm.selectedStepKey === "summary"
                                ? _c("BaseBackButtonBreadcrumb", {
                                    attrs: {
                                      showAsButton: "",
                                      fixedLabel: "Schließen"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3941795523
            )
          })
        : _vm.form.showWarningEarly
        ? _c("div", { staticClass: "box__container" }, [
            _c("span", [
              _vm._v(
                "Der Vertrag zur Wirtschaftsprüfung für das Jahr " +
                  _vm._s(_vm.form.currentYear) +
                  " ist aktuell noch in Arbeit. Sie werden über die Freischaltung in unserem Newsletter informiert."
              )
            ])
          ])
        : _vm.form.showWarningLate
        ? _c("div", { staticClass: "box__container" }, [
            _c("p", [
              _vm._v(
                "Die Frist zur Erstellung und Einreichung des Vertrages zur Wirtschaftsprüfung für das Jahr " +
                  _vm._s(_vm.form.currentYear) +
                  " ist leider abgelaufen."
              )
            ]),
            _c("p", [
              _vm._v(
                "Für eine nachträgliche Beauftragung wenden Sie sich bitte direkt an Evistra unter der Rufnummer +49 821 5088 5080."
              )
            ])
          ])
        : _vm._e(),
      _c("Viewer", {
        ref: "resizablePopup",
        attrs: {
          session: _vm.signoViewerSession,
          saveLastPosition: false,
          fullClientHeight: "",
          id: "882dbfca-2ba3-4e6f-a372-e6098e828e08"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }