<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" tid="3c886523-0f75-4a62-bb04-2bd422c441b8" />

    <!-- KOMMT WEG WENN ES PRODUKTIV GEHT -->
    <PageHeaderTitleNavigation 
      title="Vermögensübersicht"
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentTabMenu"
    />
    
    <!-- <div class="" v-if="gesamt">
      <div v-if="!$isSmallScreen" class="overview__container">
        <div v-for="(sum, index) of gesamt.summen" :key="'sum'+index">
          <div v-if="sum && sum[1] !== ''" class="box__container" >
            <div class="text-small text-centered mp-0">{{sum[0]}}</div>
            <div class="text-centered mp-0" :class="getBadgesClassCategory(sum)">
              <span><CurrencyLabel :value="sum[1]" :symb="sum[2]"/></span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="box__container">
        <template v-for="(sum, index) of gesamt.summen">
          <MobileInfoRow :key="'sum'+index" :label="sum[0]" >
            <template #value >
              <span>
                <span><CurrencyLabel :value="sum[1]" :symb="sum[2]"/></span>
              </span>
            </template>
          </MobileInfoRow>
        </template>
      </div>
    </div>
    <div class="" v-for="(kunde,index) of depots" :key="index" :class="{'mt-3': !index}">
      <a v-if="kunde.kname" class="text-bold box__container mb-2 d-block" @click="navigateToKunde(kunde)">{{kunde.kname}}</a>
      <div v-else class="mt-4"></div>
      <div class="kacheln" >
        <template v-for="item of kunde.data">
          <div class="box__container kachel" :key="item.key">
            <a class="text-bold" @click="navigateToDepot(item)">{{item.depotname}}</a>
            <hr>
            <template v-for="(sum, index) of item.summen">
              <MobileInfoRow :key="'sum'+index" :label="sum[0]" >
                <template #value >
                  <span>
                    <span><CurrencyLabel :value="sum[1]" :symb="sum[2]"/></span>
                  </span>
                </template>
              </MobileInfoRow>
            </template>
          </div>

        </template>
      </div>
    </div> -->
    <div v-if="loading">
      <GhostLoading :repeat="3" useBoxContainer inline>
        <Block />
        <Block />
      </GhostLoading>
      <div  class="kacheln">
        <GhostLoading class="kachel" useBoxContainer>
          <Block type="title" />
          <Block :height="175" />
        </GhostLoading>
        <GhostLoading class="kachel" useBoxContainer>
          <Block type="title" />
          <Block :height="175" />
        </GhostLoading>
      </div>
    </div>
    <div v-else>
      <div v-if="depotsgesamt">
        <div v-if="!$isSmallScreen" class="overview__container">
          <div v-for="(sum, index) of getDataFromLabels(depotsgesamt.resultSums)" :key="'sum'+index">
            <div v-if="sum && sum[1] !== ''" class="box__container" >
              <div class="text-small text-centered mp-0">{{sum[0]}}</div>
              <div class="text-centered mp-0" :class="getBadgesClassCategory(sum)">
                <template v-if="sum[3]">{{convertStringToNumber(sum[1])}} %</template>
                <CurrencyLabel v-else-if="isNumber(sum[1])" :value="convertStringToNumber(sum[1])"/>
                <template v-else>{{sum[1]}}</template>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="box__container">
          <template v-for="(sum, index) of getDataFromLabels(depotsgesamt.resultSums)">
            <MobileInfoRow :key="'sum'+index" :label="sum[0]" >
              <template #value >
                  <template v-if="sum[3]">{{convertStringToNumber(sum[1])}} %</template>
                  <CurrencyLabel v-else-if="isNumber(sum[1])" :value="convertStringToNumber(sum[1])"/>
                  <template v-else>{{sum[1]}}</template>
              </template>
            </MobileInfoRow>
          </template>
        </div>
      </div>
      <div class="kacheln mt-3">
        <div v-for="(kunde,index) of depotlist" :key="index">
          <div class="box__container kachel">
            <a class="text-bold" @click="navigateToDepot(kunde)">{{kunde.depotname}}</a>
            <hr>
            <template v-for="(sum, index) of getDataFromLabels(kunde.resultSums)">
              <MobileInfoRow :key="'sum'+index" :label="sum[0]" >
                <template #value >
                  <template v-if="sum[3]">{{convertStringToNumber(sum[1])}} %</template>
                  <CurrencyLabel v-else :value="convertStringToNumber(sum[1])" />
                </template>
              </MobileInfoRow>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";
import CORE_TYPES from '@/store/core/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import MobileInfoRow from '@/components/core/MobileInfoRow.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import depotMixin from './depot-mixin.js';
import { ROLES } from '@/router/roles'

export default {
  props: {
  },
  components: {
    OptionMenu, CurrencyLabel, MobileInfoRow, GhostLoading, Block, PageHeaderTitleNavigation
  },
  mixins: [depotMixin],
  data() {
    return {
      loading: false
    };
  },
  async mounted() {
    // this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.LOAD_DEPOTPOSITIONS_GUV)
    
    this.loading = true

    const data = await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_CONFIG, {})
    
    await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RESET_RETRIEVED_DEPOTPOSITIONS_IF_NEEDED);
    
    let values = []
    if (data?.depotFiltr) {
        values = data.depotFiltr
          .filter(v => v.depotGesamt > 0)
          .map(v => ({label: v.value, value: v.key, depotGesamt: v.depotGesamt}));
      } else {
        values = [{label: 'Positionen aller Depots anzeigen', value: 'ALLE_DEPOTS_ANZEIGEN'}];
    }

    const result = values.map(val => {
        return new Promise((resolve, reject) => {
          if (val.value && !this.depotpositions[val.value]) {
            this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS, {
              depotid: val.value
            }).finally(() => resolve())
          } else {
            resolve()
          }
        });
    });

    Promise.all(result).finally(() => {
      this.loading = false
    })
  },
  computed: {
    ...mapGetters({
      vermoegen: DEPOTPOSITIONS_TYPES.GETTERS.GET_DEPOTPOSITIONS_GUV,
      config: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CONFIG,
      depotpositions: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS,
    }),
    depots() {
      return this.vermoegen.virtualDepots || []
    },
    depotlist() {
      const result = Object.entries(this.depotpositions || {})
        .filter(([key, depot]) => /^\d+$/.test(key) && this.config?.depotFiltr?.find(item => item.key === key)?.depotGesamt > 0)
        .flatMap(([key, depot]) => {
            return {
              depotId: key,
              depotname: this.config?.depotFiltr?.find(item => item.key === key)?.value,
              resultSums: depot.resultSums,
            };
        });

      return result;
    },
    depotsgesamt() {
      return this.depotpositions['ALLE_DEPOTS_ANZEIGEN'];
    },
    gesamt() {
      return this.vermoegen.gesamt
    },
    // depotauszugGuvFarbe() {
    //   return this.vermoegen.guvFarbe
    // },
    depotauszugGuvFarbe() {
      return this.hasRoles(ROLES.DEPOTAUSZUG_GUV_FARBE);
    },
  },
  methods: {
    // getBadgesClassCategory(sum) {
    //   if (!this.depotauszugGuvFarbe || !sum[0] || sum[0] === 'Ein-/Auszahlungen') {
    //     return '';
    //   }
    //   if (sum[1] > 0) {
    //     return "color-success";
    //   } else if (sum[1] < 0) {
    //     return "color-danger"
    //   }
    //   return "text-color";
    // },
    navigateToKunde(kunde) {
      if (kunde.data.length) {        
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
          customerId: kunde.data[0].kundennr,
          path: '/home/vermogensubersicht/overview',
        })
      }
      
    },
    navigateToDepot(item) {
      if (item.depotId) {
        this.$addBreadcrumb({
          breadcrumb: 'Depots',
          fullPath: this.$route.fullPath,
        });

        this.$router.push({ path: '/home/vermogensubersicht/depot?depotid='+ item.depotId });
      }
    },
  },
};
</script>

<style scoped>

.overview__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px 16px;
}
.overview__container div {
  margin: 0;
}
.overview__container > div {
  flex-grow: 1;
  padding: 0 12px;
  margin: 0 0 8px;
}
.kacheln {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(25rem,auto));
  margin-bottom: 24px;
}
.kachel {
  min-width: 25rem;
  margin: 0;
}
::v-deep .row-content {
  font-weight: normal;
}
</style>