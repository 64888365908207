<template>
  <div class="flex flex-col my-2">
    <div class="customer-overview__container">
      <div class="customer-overview--image">
        <div class="img-wrap">
          <img :src="betreuer.bildUrl" />
        </div>
      </div>
      <div class="customer-overview--content pl-2">
            <span class="font-bold">{{ betreuer.fullName }}</span>
            <span>{{ betreuer.berufsbezeichnung }}</span> 
            <span>{{ betreuer.email }}</span> 
            <span class="mb-1">{{ betreuer.telefon }}</span> 
      </div>
    </div>
    <div class="mt-2">
    <a href="https://www.fondskonzept.ag/ihre-ansprechpartner" target="_blank">Hier finden Sie alle Ansprechpartner der Fondskonzept AG</a>
  </div>
  </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  
  export default {
    props: {
        betreuer: {
          type: Object,
        },
    },
    computed: {
      ...mapGetters({
      }),
    },
    methods: {
    },
    mounted() {
    },
    components: {
    },
  }
  </script>
  
  <style src='@/components/steckbrief/steckbrief.scss' lang='scss' scoped></style>
  
  <style lang="scss" scoped>
  .customer-overview__container {
    display: flex;
  
    .customer-overview--image {
      flex: 0 0 auto;
      margin: 0 16px 0 0;
  
      img {
        margin: 0;
      }
    }
  
    .customer-overview--content {
      // flex: 1 1 auto;
      padding-top: 0.5rem;
      display: flex;
      flex-direction: column;
    }
  
    .gruppenmitglieder__list {
      list-style: none;
      padding: 0;
      margin: 0 0 0 12px;
    }
  }
  .channel-status-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
     > div {
       display: flex;
       flex: 0 0 auto;
       align-items: center;
       > span {
         margin-right: 0.5rem;
       }
     }
  }
  
  .circle {
    height: 16px;
    width: 16px;
    background-color: gray;
    border-radius: 50%;
    display: inline-block;
  }
  
  .enabled {
    background-color: var(--color-success) !important;
  }
  
  .disabled {
    background-color: var(--color-danger);
  }
  
  @media screen and (max-width: 500px) {
    .customer-overview__container {
      flex-flow: column;
  
      .customer-overview--image {
        align-self: center;
        margin: 0 0 16px 0;
      }
    }
  }
  </style>
  