<template>
  <div class="justify-content-between align-items-center breadcrumb__back-button" v-if="showBackButtonAtAll">
    <slot name="backLink">
      <template v-if="showAsAnyButton">
        <BaseButton @click="goToLastAction()" :disabled="disabled" :isSecondary="showAsSecondaryButton">{{backLabel}}</BaseButton>
      </template>
      <template v-else>
        <a class="breadcrumb__link full-text" v-if="lastBackToPreviousData && lastBackToPreviousData.fullPath" @click="goToLastAction()" 
          :tid="_generateTidFromString(lastBackToPreviousData.fullPath)">
          <span v-html="sanitize(backLabel)"></span>
        </a>
        <BaseButton class="breadcrumb__link short-text m-0" isClear @click="goToLastAction()" alt="Icon zur Navigation auf die vorherige Seite">
          <PhCaretLeft :size="size" alt="Icon zur Navigation auf die vorherige Seite"/>
        </BaseButton>
      </template>
    </slot>
  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import { PhCaretLeft } from 'phosphor-vue'
import BaseButton from '@/components/core/BaseButton.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import {sanitize} from '@/helpers/string-helper';
import { onNavigationFailure } from '@/router/breadcrumb';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    showAsButton: {
      type: Boolean,
      default: false,
    },
    showAsSecondaryButton: {
      type: Boolean,
      default: false,
    },
    fixedLabel: {
      type: String,
    },
    size: {
      default: 18
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    PhCaretLeft,
    BaseButton,
  },
  computed: {
    showAsAnyButton() {
      return this.showAsSecondaryButton || this.showAsButton
    },
    lastBackToPreviousData() {
      const { $breadcrumbs } = this;
      return $breadcrumbs?.[$breadcrumbs.length - 1] || {};
    },
    backLabel() {
      return this.fixedLabel ? this.fixedLabel : this.lastBackToPreviousData.label;
    },
    showBackButtonAtAll() {
      return this.$slots.backLink || this.showAsAnyButton || this.lastBackToPreviousData?.fullPath
    }
  },
  methods: {
    goToLastAction() {
      this.$emit('beforeBackTrigged', true)
      let query = { backAction: true};
      const fullPath = this.lastBackToPreviousData.fullPath || '/home';

      if (this.lastBackToPreviousData?.dokumentenarchivLastFolder) {
        query.dokumentenarchivLastFolder = this.lastBackToPreviousData.dokumentenarchivLastFolder;
      }

      this.$router.push({
        path: fullPath,
        query: query,
      })
      .catch(onNavigationFailure);

      this.$emit('backTrigged', true)
    },
    sanitize(html) {
      return sanitize(html);
    },
  },
  mounted() {
    document.addEventListener('back-button-pressed', this.goToLastAction)
  },
  beforeDestroy() {
    document.removeEventListener('back-button-pressed', this.goToLastAction)
  }
};
</script>

<style scoped>
.breadcrumb__back-button {
  align-self: center;
  flex: 0 0 auto;
}

.short-text {
  display: none;
}

.full-text {
  display: flex;
}

@media (max-width: 767px) {
  .short-text {
    display: initial;
  }
  .full-text {
    display: none;
  }

  .breadcrumb__link {
    text-decoration: none;
  }

}
</style>