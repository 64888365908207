<template>
  <div>
    <ComboBox
      label="Typ"
      v-model="form.type"
      validateUntouched
      :values="(isEinnahme ? einnahmeTypes : ausgabeTypes)"
      @input="emitChange"
    />
    <InputField v-if="!isSparquote"
      label="Beschreibung"
      placeholder="Name"
      v-model="form.title"
      type="text"
      validateUntouched
      @input="emitChange"
    />
    <ComboBox v-if="isPeriodicEvent"
      label="Zeitraum"
      v-model="form.frequenz"
      :values="frequenzTypes"
    />
    <DatePickerField v-if="isPeriodicEvent || isSparquote"
      label="Startdatum"
      placeholder="MM.JJJJ"
      monthPicker
      isValueAsString
      v-model="form.startDate"
    />
    <DatePickerField v-if="!isSparquote"
      :label="isPeriodicEvent ? 'Endtermin' : 'Datum'"
      placeholder="MM.JJJJ"
      monthPicker
      isValueAsString
      v-model="form.endDate"
    />
    <InputField 
      label="Betrag" 
      v-model="form.betrag" 
      type="currency"
      validateUntouched
      @input="emitChange"
    />
    <InputField v-if="isEinnahme"
      label="Rendite" 
      v-model="form.rendite" 
      type="percent"
      validateUntouched
      @input="emitChange"
    />
  </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import validator from "@/mixins/validator";
import {required,} from "@/mixins/validator/rules";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import dayjs from 'dayjs';
import mixin from '@/views/ruhestandsplanung/mixin.js';

export default {
	props: {
    goal: {
    },
    comboboxEventTypes: {
      type: Array,
      default: () => [],
    }
  },
  mixins: [validator, mixin],
	data () {
		return {
			form: {
        betrag: '',
        frequenz: null,
        title: '',
        type: null,
        startDate: dayjs(new Date()).format('MM.YYYY'),
        endDate: '',
        zuflussType: '',
        rendite: null,
      },
      frequenzTypes: [{label: 'jährlich', value: 'JAERLICH'},	{label: 'monatlich', value: 'MONATLICH'}],
		}
  },
  validators: {
    form: {
      title: [required('Bitte geben Sie die Beschreibeung ein.')],
      type: [required('Bitte wählen Sie ein Typ.')],
      betrag: [required('Bitte geben Sie ein Betrag ein.')],
    }
  },
  mounted() {
    if (this.goal) {
      this.form = Object.assign(this.form, this.goal);
      this.emitChange();
    }
  },
  computed: {
    isPeriodicEvent() {
      return this.form?.type?.includes('periodisch');
    },
    einnahmeTypes() {
      return this.comboboxEventTypes?.filter(item => item.style === 'EINNAHME') || [];
    },
    ausgabeTypes() {
      return this.comboboxEventTypes?.filter(item => item.style === 'AUSGABE') || [];
    },
    isEinnahme() {
      return this.form.zuflussType === 'EINNAHME';
    },
    isSparquote() {
      return this.form.type === 'sparquote';
    },
  },
	methods: {
    getData() {
      if (this.isSparquote) {
        this.form.title = 'Sparquote';
        this.form.frequenzType = 'PERIODISCH';
        this.form.endDate = this.retirementYearCurrentScenario ? ('01.' + (this.retirementYearCurrentScenario - 1)) : '';
      } else if (this.isPeriodicEvent) {
        this.form.frequenzType = 'PERIODISCH';
      }
      this.form.selected = true;
      return this.form;
    },
    emitChange() {
      this.$emit('isValid', this.isValid());
    },
    isValid() {
      return this.form.type && this.form.betrag && (this.isSparquote || this.form.title) || false;
    },
	},
	components: {
    InputField,
    DatePickerField,
    BaseButton,
    ComboBox,
	},
}
</script>

<style scoped>
</style>
