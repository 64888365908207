<template>
  <div>
    <BaseModal 
      size='md'
      ref="PageLayoutModal"
      modalTitle="Seite einrichten"
      :showCancelButton="true"
      labelButtonConfirm="Übernehmen"
      @onConfirmButton="onConfirmModal()">
      <div>
        <div class="box__title">Seitenabstände</div>
        <div class="input-container">
          <InputField
            isEmbedded
            foreign_currency='cm'
            type='foreign_cy'
            label='links'
            v-model="pageInsets.left"/>
          <InputField
            foreign_currency='cm'
            type='foreign_cy'
            label='rechts'
            v-model="pageInsets.right"/>
        </div>
        <div class="input-container mb-3">
          <InputField
            isEmbedded
            foreign_currency='cm'
            type='foreign_cy'
            label='oben'
            v-model="pageInsets.up"/>
          <InputField
            foreign_currency='cm'
            type='foreign_cy'
            label='unten'
            v-model="pageInsets.bottom"/>
        </div>
        <div class="box__title">Position Barcode</div>
        <ComboBox
          isComponentHalfSize
          v-model="barcodePosition"
          :values="comboboxSelections && comboboxSelections.barcodePositions"/>
        <div class="box__title">Hintergrund (.pdf / .jpg / .png)</div>
        <DragnDropArea class="depotnummer-anfordern-button-component mb-4" hoverText="Hochladen" @files="onFileChange">
          <BaseFileSelect @files="onFileChange" accept='.pdf, image/png, image/jpeg,'>
            Hintergrund hochladen
          </BaseFileSelect>
          <div>
            <div class="input-chip" v-if="background">
            <a @click="openLink">{{background && background.fileName}}</a>
            <ph-x-circle class="clickable ml-1 del-icon" :size="16" @click.stop="background = null" />
            </div>
          </div>
        </DragnDropArea>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import DYNAMIC_DOCUMENT_TYPES from '@/store/dynamicDocument/types';
import { mapGetters } from 'vuex'
import BaseModal from "@/components/core/BaseModal.vue";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import { PhXCircle } from 'phosphor-vue'
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { extractMimeType } from '@/helpers/utils-helper';

export default {
  mixins: [],
  components: {
    BaseModal,
    ComboBox,
    InputField,
    BaseFileSelect,
    DragnDropArea,
    PhXCircle,
  },
  props: {
    document: {
    },
    documentHasChanged: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      pageInsets: {
        up: '',
        right: '',
        bottom: '',
        left: '',
      },
      barcodePosition: null,
      background: null,
    }
  },
  computed: {
    ...mapGetters({
      comboboxSelections: DYNAMIC_DOCUMENT_TYPES.GETTERS.COMBOBOX_SELECTIONS,
    }),
  },
  methods: {
    openLink() {
      if (this.background && this.background.data) {
        const { data, fileName:filename } = this.background;
        viewDocument({
          data,
          filename,
          contentType: extractMimeType(filename),
        }, true);
      }
    },
    onFileChange(files) {
      const reader = new FileReader();
      reader.onload = () => {
        let base64string = reader.result.split(",").pop();
        this.background = {
          fileName: files[0].name,
          data: base64string
        }
      }
      reader.readAsDataURL(files[0]);
    },
    onConfirmModal() {
      this.$store.commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED, true);

      this.document.pageInsetUp = this.convertCmToPoints(this.pageInsets.up)
      this.document.pageInsetBottom = this.convertCmToPoints(this.pageInsets.bottom)
      this.document.pageInsetRight = this.convertCmToPoints(this.pageInsets.right)
      this.document.pageInsetLeft = this.convertCmToPoints(this.pageInsets.left)
      this.document.barcodePosition = this.barcodePosition
      this.document.background = this.background
    },

    convertPointsToCm(value) {
      return Math.floor(value * 2.54 / 72 * 100) / 100
    },
    convertCmToPoints(value) {
      return Math.round(value * 72 / 2.54)
    },

    open() {
      this.pageInsets.up = this.convertPointsToCm(this.document.pageInsetUp)
      this.pageInsets.bottom = this.convertPointsToCm(this.document.pageInsetBottom)
      this.pageInsets.right = this.convertPointsToCm(this.document.pageInsetRight)
      this.pageInsets.left = this.convertPointsToCm(this.document.pageInsetLeft)
      this.barcodePosition = this.document.barcodePosition
      this.background = this.document.background
      
      this.$refs.PageLayoutModal.open();
    },
  },

}
</script>

<style scoped>
.text-input {
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 100;
  padding-bottom: 0;
  margin-bottom: 1rem;
}
.input-container {
  column-gap: 15px;
  display: flex;
  flex-wrap: wrap;
}
.input-chip {
  border-radius: 4px;
  border: 1px solid var(--color-text);
  padding: 0 4px;
  display: inline-block;
  margin: 10px 0px 4px 0px;
}
</style>