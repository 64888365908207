var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title:
            "Status der VideoIdents zu FFB Depoteröffnungen / Portfolioanträge"
        }
      }),
      _c("GenericPersonFilter", {
        ref: "filter",
        attrs: {
          saveKey: _vm.saveKey,
          metadata: _vm.searchMenu,
          configFilter: _vm.configFilter,
          predefinedFilter: null,
          defaultOptions: _vm.defaultOptions,
          immidiateSearch: "",
          extraButton: {
            buttonText: "Markierte Positionen bestätigen",
            isExtraButtonPrimary: _vm.selection && _vm.selection.length > 0
          }
        },
        on: {
          search: function($event) {
            return _vm.handleSearch($event)
          },
          extraButtonClick: function($event) {
            return _vm.confirmSelection()
          }
        }
      }),
      !_vm.loading && _vm.rows && _vm.rows.length
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                attrs: { showSection: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "box__title m-0" }, [
                            _vm._v(
                              "Ausprägungen des Status Depoteröffnungsanträge"
                            )
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "status-ffb--hinweise-items__container col-12 col-md-6"
                              },
                              [
                                _c("FormLabel", {
                                  staticClass: "color-warning",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "Angefordert teilweise -> Nur teilweise hinterlegt"
                                  }
                                }),
                                _c("FormLabel", {
                                  staticClass: "color-info",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "Bestätigt -> Vom Antragssteller bestätigt"
                                  }
                                }),
                                _c("FormLabel", {
                                  staticClass: "color-info",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "Legitimation angefordert -> Vorgang bei der Identifizierungsstelle angelegt"
                                  }
                                }),
                                _c("FormLabel", {
                                  staticClass: "color-success",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "Legitimation erfolgreich -> Legitimierung aller Antragbeteiligten war erfolgreich"
                                  }
                                }),
                                _c("FormLabel", {
                                  staticClass: "color-danger",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "FFB Sichtkontrolle negativ -> DEA wurde von der Sichtkontrolle abgelehnt"
                                  }
                                }),
                                _c("FormLabel", {
                                  staticClass: "color-danger",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      " DEA abgelaufen und anonymisiert -> Frist Bestätigung / Legitimierung abgel."
                                  }
                                }),
                                _c("FormLabel", {
                                  staticClass: "color-success",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "DEA erfolgreich -> DEA wurde erfolgreich durchgeführ"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "status-ffb--hinweise-items__container col-12 col-md-6"
                              },
                              [
                                _c("FormLabel", {
                                  staticClass: "color-info",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "Angefordert -> Angelegt und wartet auf die Bestätigung"
                                  }
                                }),
                                _c("FormLabel", {
                                  staticClass: "color-info",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "Bestätigt ->Bestätigt durch Unterlagen -> Vom Antragssteller bestätigt mit Unterlagen"
                                  }
                                }),
                                _c("FormLabel", {
                                  staticClass: "color-warnung",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "Legitimation teilweise -> Legitimierung nur teilweise erfolgreich"
                                  }
                                }),
                                _c("FormLabel", {
                                  staticClass: "color-danger",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "Legitimation abgelehnt -> Legitimierung wurde abgelehnt"
                                  }
                                }),
                                _c("FormLabel", {
                                  staticClass: "color-success",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "Depoteröffnung initiiert -> DEA bestätigt und legitimiert, nun initiiert"
                                  }
                                }),
                                _c("FormLabel", {
                                  staticClass: "color-danger",
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      " Ungültig -> DEA befindet sich in einem invaliden Zustand"
                                  }
                                }),
                                _c("FormLabel", {
                                  attrs: {
                                    config: _vm.LABEL_CONFIG,
                                    label:
                                      "DEA bestätigt -> Die DEA ist im MSC bestätigt"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1385474987
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.loading && _vm.rows && _vm.rows.length
            ? _c(
                "div",
                [
                  _c("Table", {
                    attrs: {
                      title: _vm.TABLE_TITLE,
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20,
                      rowId: "statusFFBDepots",
                      selected: _vm.selection
                    },
                    on: {
                      selected: _vm.onSelect,
                      "click-kundenName": _vm.openCustomerNewTab,
                      "click-identUrl1": function($event) {
                        return _vm.openIdentLink($event && $event.identUrl1)
                      },
                      "click-identUrl2": function($event) {
                        return _vm.openIdentLink($event && $event.identUrl2)
                      }
                    }
                  })
                ],
                1
              )
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { title: _vm.TABLE_TITLE, type: "table" }
              })
            : _c("NoData", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  content: "Keine Informationen"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }