<template>
  <div>
    <GhostLoading class="box__container" v-if="loading" type="table" title="Sonstiges" />
    <template v-else>
      <div class="box__container" v-if="isIntern">
        <InputToggleSwitch v-model="eigeneApp" label="Eigene App" inLineLabel />
        <InputField label="App Name" v-model="appName" :disabled="!sonstiges.eigeneApp" />
      </div>
      <div class="box__container">
        <div class="box__title">Makler Kriterien</div>
        <MaklerKriterien
          :maklerKriterien="brokerDataConfig.maklerKriterien"
          :alleStrukturKriterien="brokerDataConfig.alleStrukturKriterien"
        ></MaklerKriterien>
      </div>
      <Hobbys v-if="isIntern || sonstiges.showUnterMakler"
        :optionen="(sonstiges.hobbyOptionen||[])" :liste="sonstiges.hobbies" :isEditable="true"
        @setHobbys="hobbies=$event"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import { VIEW_ROLES } from '@/router/roles';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import { required } from "@/mixins/validator/rules";
import validator from '@/mixins/validator'
import Hobbys from '@/components/persoenliche-daten/hobbys-vorlieben/Hobbys.vue';
import MaklerKriterien from "@/components/broker/MaklerKriterien.vue";

export default {
  mixins: [validator],
  components: {
    GhostLoading, InputToggleSwitch, InputField, Hobbys, MaklerKriterien
  },
  data() {
    return {
      loading: true,
      changedValues: {},
    }
  },
  validators: {
    appName: [required('Bitte App Name eingeben', {forceTouch: true})],
  },
  computed: {
    ...mapGetters({
      sonstiges: BROKERDATA_TYPES.GETTERS.GET_SONSTIGES,
      brokerDataConfig: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_CONFIG,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isBrokerOnly: CORE_TYPES.GETTERS.is_brok,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isBrokerNoBypass(){
       return this.hasRoles(VIEW_ROLES.VIEW_BROKER_ONLY);
    },
    eigeneApp: {
      get() { return this.sonstiges.eigeneApp },
      set(value) { this.commit({eigeneApp: value, appName: this.appName}) }
    },
    appName: {
      get() {return this.sonstiges.eigeneApp ? this.sonstiges.appName : 'FinanceApp'},
      set(value) { this.commit({eigeneApp: this.eigeneApp, appName: value}) }
    },
    hobbies: {
      get() { return this.sonstiges.hobbies || []},
      set(value) { this.commit({hobbies: value}) }
    },

  },
  beforeRouteLeave(to, from, next) {
    if (from.path.indexOf('vermittlerdaten/sonstiges') == -1 || this.save()) {
      next();
    }
  },
  mounted() {
    this.loadSonstiges();
  },
  methods: {
    async loadSonstiges() {
      if (this.isBrokerNoBypass) { // nothing to read
        this.loading = false;
        return;
      }
      if (this.sonstiges.showUnterMakler != undefined) {
        this.loading = false;
      }
      await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.LOAD_SONSTIGES);
      this.loading = false;
    },
    commit(value) {
      this.$store.commit(BROKERDATA_TYPES.MUTATIONS.SET_SONSTIGES, {...this.sonstiges, ...value })
      this.changedValues = {...this.changedValues, ...value}
    },
    save() {
      if (this.sonstiges.eigeneApp && !this.sonstiges.appName) {
        return false;
      }
      if (Object.keys(this.changedValues).length) {
        this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.SAVE_SONSTIGES, this.changedValues);
      }
      return true;
    },
  },
};
</script>
