<template>
  <div>
    <BaseModal 
      ref="openSignatureDocumentDetails"
      :showDefaultButtons="false"
      modalTitle="Offene Unterschriften"
      @close="close()"
    >
      <WebserviceWarnings class="mb-2" v-if="selectedOpenSignatureDocumentDetail.webserviceWarnings" :webserviceWarnings="selectedOpenSignatureDocumentDetail.webserviceWarnings" />
      <div>
        <div class="mb-4 mt-2">
          <slot name="title"></slot>
        </div>
        <div v-for="(param, index4) in selectedOpenSignatureDocumentDetail.parameters" :key="index4">
          <div v-if="param.type === 'IMAGE'">
            {{param.label}}
          </div>
          <div v-if="param.type !== 'IMAGE'">
            <span v-if="param.label">{{param.label}}:&nbsp;</span><span>{{param.value}}</span>
          </div>
        </div>
      </div>
      
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue'
import WebserviceWarnings from '@/components/communication/WebserviceWarnings.vue'

export default {
  props: {
    selectedOpenSignatureDocumentDetail: {
      default: null,
    },
  },

  components: {
    BaseModal,
    WebserviceWarnings,
  },

  data: function () {
    return {
    }
  },

  methods: {
    close() {
      this.$emit("close")
    },
  },
  mounted() {
    this.$refs.openSignatureDocumentDetails.open()
  }
}
</script>

<style scoped>

</style>