<template>
  <div>
    <template v-if="hasPageHeader">
      <OptionMenu
        :id="$appNavigation.currentOptionMenuId"
        :defaultMenu="$appNavigation.currentOptionMenu"
      />

      <PageHeaderTitleNavigation
        :id="$appNavigation.currentOptionMenuId"
        :title="pageTitle"
        :actions="headerActions"
        @action-SEND-MAIL-POSTFACH="sendMail({}, {}, true)"
        @action-SEND-MAIL-VERSENDEN="sendMail({}, {}, false)"
        @action-SEND-MAIL-UNENCRYPTED="sendMail({}, {}, false)"
        @action-SEND-MAIL="sendMail({}, {}, false)"
        @action-SEND-TEST-MAIL="sendTestMailModal"
        @action-SEND-FAX="sendFax"
        @action-SWITCH-TO-EMAIL="switchBriefModus"
        @action-SWITCH-TO-FAX="switchBriefModus('FAX')"
        @action-SEND-BRIEF="sendBrief"
        @action-ADD-ACTIVITY="handleAddActivity"
        @action-RESET-EMAIL="resetEmail()"
        @action-GENERATE-PDF="handlePreview(true, true)"
      />
    </template>

    <div
      v-if="
        hasPageHeader &&
          ($route.meta.type === 'BRIEF' ||
            ($route.meta.type !== 'FAX' && showExtendedActionButtons))
      "
      class="box__container"
      :tid="_generateTidFromString('box__container__header')"
    >
      <div class="row">
        <template v-if="$route.meta.type === 'BRIEF'">
          <div class="col-12 col-md-6 col-lg-6 mb-3 mb-md-0">
            <ComboBox
              v-model="briefType"
              :values="briefTypeOptions"
              :firstEmpty="false"
            />
          </div>
        </template>
        <template
          v-if="$route.meta.type !== 'FAX' && showExtendedActionButtons"
        >
          <div class="col-6 col-md-6 col-lg-6">
            <ComboBox
              v-model="template"
              :values="templatesOptions"
              :firstEmpty="false"
              :disabled="isAlreadySent || vorlageDisabled"
              @change="changeTemplate($event)"
            />
          </div>
          <div class="col-6 col-md-6 col-lg-6" v-if="pdfPreviewLink">
            Vorschau:
            <DownloadLink
              :title="pdfPreviewLink.name || 'PDF'"
              :filename="pdfPreviewLink.name || 'PDF'"
              downloadServicePath="/docId"
              :queryParams="{
                attId: pdfPreviewLink.copyFileId,
                tempFileId: pdfPreviewLink.id,
              }"
            />
          </div>
        </template>
      </div>
    </div>

    <div class="box__container" :tid="_generateTidFromString('absender-data')">
      <template>
        <ComboBox
          label="Absender"
          v-model="sender"
          :firstEmpty="true"
          :values="sendersOptions"
          :disabled="isAlreadySent || absenderDisabled"
        />

        <div
          v-if="
            $route.query &&
              $route.query.singleUser &&
              $route.meta.type !== 'BRIEF_MAIL'
          "
        >
          Empfänger
        </div>

        <div class="participants-container" v-else>
          <ParticipantInput
            label="Empfänger"
            v-model="receiver"
            :maxItemsVisible="maxItemsVisible"
            :warning="warningMsg"
            :disabled="isAlreadySent || this.reciverDisable"
            :multipleReceivers="multipleReceivers"
            :isReceiveUserClickable="isReceiveUserClickable"
            :isUserSelect="isUserSelect && hasPageHeader"
            :availableUsers="availableUsers"
            :isFax="$route.meta.type === 'FAX'"
            @input="inputReceiver"
            @searchReceiverUsers="searchReceiverUsers($event)"
            @clickReceiveUsers="openReceiveUser($event)"
            @deleteReceiveUsers="deleteReceiveUsers($event)"
            @deleteAllReceiveUsers="deleteAllReceiveUsers()"
            @createManualReceiver="createManualReceiver($event)"
            @handleAddUsers="handleAddUsers('TO')"
            @userPreview="isUserPreviewActivated = $event"
          />

          <div
            class="d-flex participant-copy-container"
            :class="{ 'align-self-start': isUserPreviewActivated }"
            v-if="
              $route.meta.type !== 'FAX' &&
                $route.meta.type !== 'BRIEF' &&
                emailModus !== 'MANY_MAIL'
            "
          >
            <div class="p-1">
              <a @click="isCC = !isCC">CC</a>
            </div>
            <div class="p-1" v-if="hasPageHeader">
              <a @click="isBCC = !isBCC">BCC</a>
            </div>
          </div>
        </div>

        <template
          v-if="
            $route.meta.type !== 'FAX' &&
              $route.meta.type !== 'BRIEF' &&
              emailModus !== 'MANY_MAIL'
          "
        >
          <div v-if="isCC" class="participants-container">
            <ParticipantInput
              label="CC"
              v-model="receiverCC"
              :availableUsers="availableUsers"
              :isUserSelect="isUserSelect"
              :multipleReceivers="multipleReceivers"
              :isReceiveUserClickable="isReceiveUserClickable"
              @searchReceiverUsers="searchReceiverUsers($event)"
              @deleteReceiveUsers="deleteReceiveUsers($event)"
              @handleAddUsers="handleAddUsers('CC')"
              @createManualReceiver="createManualReceiver($event, 'CC')"
            />
          </div>

          <div v-if="isBCC" class="participants-container">
            <ParticipantInput
              label="BCC"
              v-model="receiverBCC"
              :availableUsers="availableUsers"
              :isUserSelect="isUserSelect"
              :isReceiveUserClickable="isReceiveUserClickable"
              @searchReceiverUsers="searchReceiverUsers($event)"
              @deleteReceiveUsers="deleteReceiveUsers($event)"
              @handleAddUsers="handleAddUsers('BCC')"
              @createManualReceiver="createManualReceiver($event, 'BCC')"
            />
          </div>
        </template>
      </template>

      <template v-if="$route.meta.type !== 'FAX'">
        <ShortcutInputField v-if="!isPreview"
          :disabled="isAlreadySent"
          label="Betreff"
          v-model="subject"
          @input="updateSubject" />
        <InputField
          v-if="isPreview"
          :disabled="true"
          label="Betreff"
          v-model="previewHtml.subject"
        />
        <InputField v-if="hasPasswort" label="Passwort" v-model="passwort" />
      </template>

      <template v-if="$route.meta.type === 'BRIEF'">
        <InputField label="Dateiname" v-model="dateiname" />
      </template>
      <template v-if="$route.meta.type === 'APP'">
        <div v-if="allowedToSendPin" class="d-flex">
          <div v-if="canSms" class="mr-3">
            <InputToggleSwitch
              label="PIN per SMS versenden"
              v-model="isSMS"
              :inLineLabel="true"
            />
          </div>
          <div class="text-color-info">
            {{ textSms }}
          </div>
        </div>
      </template>
      <template v-if="$route.meta.type === 'BRIEF'">
        <div class="d-flex brief-switches" v-if="briefType !== 'WORD'">
          <div class="mr-3">
            <InputToggleSwitch
              label="Hintergrund-Vorlage benutzen"
              v-model="briefBackground"
            />
          </div>
          <div class="mr-3">
            <InputToggleSwitch label="Querformat" v-model="briefLandscape" />
          </div>
          <div class="mr-3">
            <InputToggleSwitch
              label="Brief zusätzlich beim Empfänger speichern"
              v-model="briefSaveDocArchive"
            />
          </div>
        </div>
      </template>

      <div class="space-row">
        <template
          v-if="$route.meta.type !== 'FAX' && $route.meta.type !== 'BRIEF'"
        >
          <InputToggleSwitch
            label="E-Mail / App-Nachricht als reine Textmail ohne HTML versenden"
            v-model="isNotHTML"
            @input="changeNotHtml($event)"
            :inLineLabel="true"
          />

          <div class="rundMail-switches" v-if="$route.meta.type === 'MANY_MAIL' && $route.meta.mailMode === 'RUND_MAIL'">
          <InputToggleSwitch 
            label="Als E-Mail versenden"
            v-model="sendEmail"
            :inLineLabel="true"
          />

          <InputToggleSwitch 
            label="Bitte um Kenntnisnahme"
            v-model="sendNachricht"
            :inLineLabel="true"
          />
          </div>
        </template>
        <template
          v-if="$route.meta.type === 'ONE_MAIL'"
        >
          <InputToggleSwitch
            label="E-Mail direkt senden"
            v-model="isDirect"
            :inLineLabel="true"
          />
        </template>
      </div>

      <template v-if="$route.meta.type === 'FAX'">
        <ComboBox
          label="Benachrichtigung E-Mail"
          v-model="faxNotifyType"
          :values="faxNotifyTypeOptions"
          :disabled="isAlreadySent"
          :firstEmpty="false"
        >
        </ComboBox>
      </template>
    </div>

    <div
      class="box__container"
      v-if="
        $route.meta.type !== 'FAX' &&
          ($route.meta.type !== 'BRIEF' || briefType !== 'WORD')
      "
      :tid="_generateTidFromString('meta-type')"
    >
      <DragnDropArea
        hoverText="Datei hier ablegen"
        @files="addDocument($event)"
        :tid="_generateTidFromString('Datei hier ablegen')"
      >
        <HtmlEditor
          v-model="html"
          :disabled="isAlreadySent"
          :textPlaceholderOptions="placeholdersOptions"
          :isPreviewButtonDisabled="!receiver || (receiver && !receiver.length)"
          :isPlaceholderVisibleDefault="emailModus === 'MANY_MAIL'"
          :previewHTML="convertPreview(previewHtml.text, previewHtml.plainText)"
          @onTogglePreviewEnabled="handlePreview"
          isFontParams
          applySavedSettings
          isPreviewButton
        >
        </HtmlEditor>
      </DragnDropArea>
      <div class="space-row"  v-if ="allowedSendLater">
            Übermittlung verzögern bis
            <DatePickerField dateFormat="DD.MM.YYYY" v-model="dateSend"  label=""></DatePickerField>
            <TimePicker v-if="dateSend" label=""  v-model="timeSend"></TimePicker>
      </div>

    </div>

    <DragnDropArea
      hoverText="Datei hier ablegen"
      @files="addDocument($event)"
      :tid="_generateTidFromString('Datei hier ablegen')"
    >
      <div class="box__container" v-if="$route.meta.type !== 'BRIEF'">
        <div class="d-block justify-content-between" v-if="isLoaded">
          <div class="d-flex justify-content-between hide-on-large-screen">
            <div class="box__title">Anhänge</div>
            <BaseContextMenu>
              <ContextMenuItem
                class="upload-btn"
                :tid="_generateTidFromString('Datei hochladen')"
              >
                <slot>Datei hochladen</slot>
                <input
                  type="file"
                  :accept="acceptFileTypes"
                  @change="onAddFiles"
                  multiple
                  aria-label="Datei hochladen"
                />
              </ContextMenuItem>

              <ContextMenuItem
                v-if="canAddDocumentsFromArchiv"
                @click="handleAddDocuments"
                :tid="_generateTidFromString('Dokumentenarchiv')"
              >
                Dokumentenarchiv
              </ContextMenuItem>

              <ContextMenuItem
                @click="handleAddDocuments('LEGITIMATION')"
                :tid="_generateTidFromString('Legitimationsurkunde')"
                v-if="legitimation && legitimation.hasLegitimation"
                :disabled="legitimation && legitimation.disabled"
              >
                Legitimationsurkunde
              </ContextMenuItem>

              <ContextMenuItem
                @click="handleAddDocuments('VOLLMACHT')"
                :tid="_generateTidFromString('Vollmacht')"
                v-if="vollmacht && vollmacht.hasVollmacht"
                :disabled="vollmacht && vollmacht.disabled"
              >
                Vollmacht
              </ContextMenuItem>

              <ContextMenuItem v-if="!!$slots.maklervollmacht">
                <slot name="maklervollmacht"></slot>
              </ContextMenuItem>

              <ContextMenuItem class="upload-btn" :tid="_generateTidFromString('Markierte löschen')" @click="removeAllMarked">
                Markierte löschen
              </ContextMenuItem>
            </BaseContextMenu>
          </div>

          <Table
            :headers="headers"
            :rows="rows"
            v-model="selectedAttachmentDocumentsRow"
            @action-DELETE="deleteFile"
            hidePagination
          >
            <template #name="row">
              <DownloadLink
                v-if="!row.noPreview"
                :title="row.dateiname || row.name"
                :filename="row.fileName || row.name"
                downloadServicePath="/get_email_attachment"
                :queryParams="{
                  attId: row.copyFileId,
                  tempFileId: row.id,
                }"
              />
              <span v-else>{{ row.dateiname || row.name }}</span>
            </template>
          </Table>
          <div class="hide-on-small-screen">
            <div
              class="d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center"
            >
              <BaseButton
                isSecondary
                class="upload-btn"
                :tid="_generateTidFromString('Datei hochladen')"
              >
                <slot>Datei hochladen</slot>
                <input
                  type="file"
                  :accept="acceptFileTypes"
                  @change="onAddFiles"
                  multiple
                  aria-label="Datei hochladen"
                />
              </BaseButton>

              <BaseButton
                v-if="canAddDocumentsFromArchiv"
                isPrimary
                @click="handleAddDocuments"
                :tid="_generateTidFromString('Dokumentenarchiv')"
              >
                Dokumentenarchiv
              </BaseButton>

              <BaseButton
                isSecondary
                @click="handleAddDocuments('LEGITIMATION')"
                :tid="_generateTidFromString('Legitimationsurkunde')"
                v-if="legitimation && legitimation.hasLegitimation"
                :disabled="legitimation && legitimation.disabled"
              >
                Legitimationsurkunde
              </BaseButton>

              <BaseButton
                isSecondary
                @click="handleAddDocuments('VOLLMACHT')"
                :tid="_generateTidFromString('Vollmacht')"
                v-if="vollmacht && vollmacht.hasVollmacht"
                :disabled="vollmacht && vollmacht.disabled"
              >
                Vollmacht
              </BaseButton>

              <BaseButton
                v-if="this.rows"
                :tid="_generateTidFromString('Markierte löschen')"
                :disabled="this.selectedAttachmentDocumentsRow && !this.selectedAttachmentDocumentsRow.length"
                isPrimary
                @click="removeAllMarked"
              >
                Markierte löschen
              </BaseButton>

              <slot name="maklervollmacht"></slot>
            </div>
          </div>
        </div>
      </div>
      <AddDocument
        ref="addDocumentModal"
        :vtgId="$route.params.id"
        :nodeId="nodeId"
      />
    </DragnDropArea>

    <BaseModal
      ref="modalTestMail"
      modalTitle="Testmail senden an"
      labelButtonConfirm="Testmail versenden"
      @onConfirmButton="sendTestMail"
    >
      <ComboBox
        v-model="testMailPreselect"
        :values="testMailPreselectOptions"
        :firstEmpty="false"
      >
      </ComboBox>
      <InputField
        v-if="testMailPreselect === '-1' || testMailPreselect === ''"
        placeholder="E-Mail"
        v-model="testMailPreselectEmail"
      />
    </BaseModal>
    <BaseModal
      ref="modalError"
      modalTitle="Fehlerliste für Verfassen"
      labelButtonConfirm="Trotzdem fortfahren"
      :showConfirmButton="!isCriticalSendingError"
      @onConfirmButton="sendIgnoreError"
    >
      <div v-for="(e, i) in errorMessage" :key="i">
        {{ e }}
      </div>
    </BaseModal>
    <BaseModal
      ref="modalNoContact"
      modalTitle="Fehler"
      labelButtonConfirm="Zum Profil wecheln"
      @onConfirmButton="goToProfile"
    >
      <div v-if="$route.meta.type === 'FAX'">
        Der Kunde hat keine Fax-Nummer. Bitte fügen Sie diese zuerst ein.
      </div>
      <div v-else>
        Der Kunde hat keine E-Mail-Adresse. Bitte fügen Sie diese zuerst ein.
      </div>
    </BaseModal>
    <BaseModal
      ref="manualReceiverModal"
      modalTitle="Empfänger hinzufügen"
      labelButtonConfirm="Speichern"
      @onConfirmButton="setManualReceiver"
      @close="resetManualReceiver"
    >
      <InputField label="Name" v-model="manualReceiver.name" />
      <InputField label="Vorname" v-model="manualReceiver.vorname" />
      <ComboBox
        label="Anrede"
        :values="anredeValues"
        v-model="manualReceiver.anrede"
        v-if="$route.meta.type !== 'FAX'"
      />
      <InputField
        label="Briefanrede"
        v-model="manualReceiver.briefanrede"
        v-if="$route.meta.type !== 'FAX'"
      />
      <InputField
        label="Ort"
        v-model="manualReceiver.ort"
        v-if="$route.meta.type !== 'FAX'"
      />
      <InputField
        label="Straße"
        v-model="manualReceiver.strasse"
        v-if="$route.meta.type !== 'FAX'"
      />
      <InputZIP
        label="PLZ"
        v-model="manualReceiver.plz"
        v-if="$route.meta.type !== 'FAX'"
        @city="manualReceiver.ort = $event"
      />
      <InputField
        label="Fax"
        v-model="manualReceiver.fax"
        :validateUntouched="false"
        inputmode="tel"
      />
      <InputField
        label="E-Mail"
        v-model="manualReceiver.email"
        v-if="$route.meta.type !== 'FAX'"
      />
    </BaseModal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from "vuex";
import InputField from "@/components/core/forms/InputField.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputZIP from "@/components/core/forms/InputZIP.vue";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import { PhCaretUp, PhCaretDown, PhTrash } from "phosphor-vue";
import COMMUNICATION_TYPES from "@/store/communication/types";
import CORE_TYPES from "@/store/core/types";
import axios from "axios";
import AddDocument from "@/views/versicherungen/AddDocument.vue";
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import { uploadFileChunked } from "@/helpers/upload-helper";
import ParticipantInput from "./ParticipantInput";
import { extToMimes } from "./mime.js";
import CALENDAR_TYPES from "@/store/calendar/types";
import CUSTOMERDATA_TYPES from "@/store/customerData/types.js";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { email, telefon } from "@/mixins/validator/rules";
import validator from "@/mixins/validator";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import { viewDocument } from "@/components/core/download/DownloadLink.vue";
import sanitizeHtml from "sanitize-html";
import { ROLES, VIEW_ROLES } from "@/router/roles.js";
import Table from "@/components/table2/Table.vue";
import BaseContextMenu from "@/components/core/BaseContextMenu.vue";
import ContextMenuItem from "@/components/core/base-context-menu/ContextMenuItem.vue";
import { sanitizeEmail } from "@/helpers/string-helper.js";
import {
  SlotColumn,
  ActionColumn,
  SimpleAction,
} from "@/components/table2/table_util";
import HtmlEditor from "@/components/html-editor/HtmlEditor.vue";
import {
  PageHeaderSimpleAction,
  PageHeaderLoadingAction,
  PageHeaderDownloadLinkHrefAction,
} from "@/components/core/header-title-navigation/page-header-utils";
import SEARCH_CONFIGS_TYPES from "@/store/searchConfigs/types";
import FC_CONFIG_TYPES from "@/store/fcConfig/types";
import FC_CONFIG from "@/configs/fcConfig";
import ShortcutInputField from '@/components/core/forms/ShortcutInputField.vue';
import { downloadLinkMaker } from '@/helpers/utils-helper';

export const MAX_ITEMS_VISIBLE = 15;

export const KUNDE_TYPE = "KUNDE";
export const BROKER_TYPE = "MAKLER";

export const VERSICHERUNG_TAG_DESCRIPTION = "Versicherungsvertrag";
export const GESELLSCHAFT_TAG_DESCRIPTION = "Gesellschaft";
export const KUNDE_TAG_DESCRIPTION = "Kunde";
export const VERSICHERUNG_KUNDE_TAG_DESCRIPTION = "Versicherungen | Kunde";
export const VERSICHERUNG_GESELLSCHAFT_TAG_DESCRIPTION =
  "Versicherungen | Gesellschaft";
export const VORLAGEN_TYPE_MAIL = "EMAIL";
export const VORLAGEN_TYPE_BRIEF = "BRIEF";
import TimePicker from "@/components/core/forms/TimePicker.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import dayjs from 'dayjs';
export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function getFileName(name) {
  const fileParams = name?.split(".");
  if (fileParams?.length < 2) {
    return `${name}.pdf`;
  }
  return name;
}

export function getFileType(name) {
  const fileParams = name.split(".");
  if (fileParams.length < 2) {
    return "application/pdf";
  }
  const ext = fileParams[fileParams.length - 1];
  if (extToMimes[ext.toLowerCase()]) {
    return extToMimes[ext.toLowerCase()];
  }
  return "application/pdf";
}

export function defaultFilterTemplateOption(templates, type, isToCompany) {
  let neededTags = [];
  let templateList = templates;

  if (["ONE_MAIL", "BRIEF", "BRIEF_MAIL", "APP"].includes(type)) {
    if (isToCompany) {
      neededTags = [
        GESELLSCHAFT_TAG_DESCRIPTION,
        VERSICHERUNG_GESELLSCHAFT_TAG_DESCRIPTION,
      ];
    } else {
      neededTags = [KUNDE_TAG_DESCRIPTION, VERSICHERUNG_KUNDE_TAG_DESCRIPTION];
    }
  }

  if (neededTags.length) {
    templateList = templateList.filter(
      (template) =>
        template?.tags?.length &&
        template.tags.filter((tag) => neededTags.includes(tag.description))
          ?.length
    );
  }

  return templateList;
}

export default {
  mixins: [InteractiveHelpCommonsMixin, validator],
  name: "MailComposer",
  components: {
    InputField,
    InputToggleSwitch,
    InputZIP,
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
    BaseModal,
    ComboBox,
    Table,
    AppointmentEditCreate: () =>
      import(
        "@/components/calendar/appointmentFiles/AppointmentEditCreate.vue"
      ),
    AddDocument,
    DragnDropArea,
    ParticipantInput,
    DownloadLink,
    BaseContextMenu,
    ContextMenuItem,
    HtmlEditor,
    DatePickerField,
    TimePicker,
    ShortcutInputField
  },

  props: {
    custom: {
      type: Boolean,
      default: false,
    },
    customFunctions: {
      type: Object,
      default: () => ({}),
    },
    isUserSelect: {
      type: Boolean,
      default: true,
    },
    showDokArchiv: {
      type: Boolean,
      default: true,
    },
    allwaysManual: {
      type: Boolean,
      default: false,
    },
    reciverDisable: {
      type: Boolean,
      default: false,
    },
    isContactRequired: {
      type: Boolean,
      default: true,
    },
    showExtendedActionButtons: {
      type: Boolean,
      default: true,
    },
    additionalSendMailParams: {
      type: Object,
      default: () => ({}),
    },
    isGetPlaceholdersOnInit: {
      type: Boolean,
      default: true,
    },
    skipPreInit: {
      type: Boolean,
      default: false,
    },
    isCompany: {
      type: Boolean,
      default: false,
    },
    hasPageHeader: {
      type: Boolean,
      default: true,
    },
    multipleReceivers: {
      type: Boolean,
      default: true,
    },
    senderDisabled: {
      type: Boolean,
      default: false,
    },
    hasPasswort: {
      type: Boolean,
      default: false,
    },
    legitimation: {
      type: Object,
      default: null,
    },
    vollmacht: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      senders: MAILCOMPOSER_TYPES.GETTERS.GET_SENDERS,
      placeholders: MAILCOMPOSER_TYPES.GETTERS.GET_PLACEHOLDERS,
      unsetPlaceholders: MAILCOMPOSER_TYPES.GETTERS.GET_UNSET_PLACEHOLDERS,
      templates: MAILCOMPOSER_TYPES.GETTERS.GET_HTML_TEMPLATES,
      templateData: MAILCOMPOSER_TYPES.GETTERS.GET_HTML_TEMPLATE,
      text: MAILCOMPOSER_TYPES.GETTERS.GET_HTML_TEXT,
      savedConfiguration: MAILCOMPOSER_TYPES.GETTERS.GET_MAILCOMPOSER_CONFIG,
      previewHtml: MAILCOMPOSER_TYPES.GETTERS.GET_PREVIEW,
      appTemplate: MAILCOMPOSER_TYPES.GETTERS.APPZUSENDEN,
      participants: MAILCOMPOSER_TYPES.GETTERS.SET_PARTICIPANTS,
      attachments: MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS,
      attachmentsCache: MAILCOMPOSER_TYPES.GETTERS.GET_ATTACHMENTS_CACHE,
      pdf: MAILCOMPOSER_TYPES.GETTERS.SET_PDF,
      brief: MAILCOMPOSER_TYPES.GETTERS.SEND_BRIEF,
      wordTemplates: MAILCOMPOSER_TYPES.GETTERS.WORD_TEMPLATES,
      testSenders: MAILCOMPOSER_TYPES.GETTERS.TEST_SENDERS,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      originalUser: CORE_TYPES.GETTERS.ORIGINAL_USER,
      linkedObjectSelected: MAILCOMPOSER_TYPES.GETTERS.GET_MAIL_LINKED_OBJECT,
      initData: MAILCOMPOSER_TYPES.GETTERS.GET_INIT,
      constants: MAILCOMPOSER_TYPES.GETTERS.GET_CONSTANTS,
      initSchadensmeldungMail:
        MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_SCHADENSMELDUNG,
      error: MAILCOMPOSER_TYPES.GETTERS.SET_ERROR,
      customerData: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA,
      sendMailResult: MAILCOMPOSER_TYPES.GETTERS.SEND_MAIL,
      auftragsdokumenteInit: MAILCOMPOSER_TYPES.GETTERS.GET_BERICHT_PDF,
      sanitizeHtmlConfig: COMMUNICATION_TYPES.GETTERS.SANITIZE_HTML_CONFIG,
      initVermittlernummernMail:
        MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_VERMITTLERNUMMERN,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      initQuartalsberichteBrokerViewEmail:
        MAILCOMPOSER_TYPES.GETTERS.INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL,
      mailHonorareAbrechnungenVerschicken:
        MAILCOMPOSER_TYPES.GETTERS.BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN,
      initMailVertragsspiegel:
        MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_VERTRAGSSPIEGEL,
      mailFondshopAction: MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_FONDSHOP_ACTION,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      isKunde: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      // cancelInsurance: MAILCOMPOSER_TYPES.GETTERS.INIT_CANCEL_INSURANCE,
      configParameter: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ADD_PARAMETER,
      hasAccess: CORE_TYPES.GETTERS.HAS_ACCESS,
    }),
    canAddDocumentsFromArchiv() {
      return this.showDokArchiv && this.hasAccess("/communication/mailcomposer-dokumentenarchiv/" + this.getRoute())
    },
    headerActions() {
      if (!this.hasPageHeader) return [];

      const actions = [];

      if (
        this.$route.meta.type !== "BRIEF" &&
        this.$route.meta.type !== "FAX"
      ) {
        if (this.showExtendedActionButtons) {
          actions.push(
            PageHeaderLoadingAction(
              "SEND-MAIL-POSTFACH",
              "Im Postfach bereitstellen",
              () => this.isLoading
            )
              .withDisabled(
                () =>
                  this.isSendDisabled ||
                  !this.receiver?.length ||
                  !this.receiver?.filter((r) => r.value !== r.label && r.email)
                    ?.length
              )
              .withVisible(
                () =>
                  !this.isToCompany &&
                  this.$route.meta.type !== "APP" &&
                  !this.isDisabledEncrypted
              )
          );

          actions.push(
            PageHeaderLoadingAction(
              "SEND-MAIL-VERSENDEN",
              "E-Mail versenden",
              () => this.isLoading
            )
              .withDisabled(
                () =>
                  !this.isAnyEmail ||
                  this.isSendDisabled ||
                  !this.receiver?.length
              )
              .withVisible(() => this.isToCompany)
          );

          actions.push(
            PageHeaderLoadingAction(
              "SEND-MAIL-UNENCRYPTED",
              "Unverschlüsselt versenden",
              () => this.isLoading
            )
              .withDisabled(() => this.isSendDisabled || !this.receiver?.length)
              .withVisible(() => !this.isToCompany)
          );
        } else {
          actions.push(
            PageHeaderLoadingAction(
              "SEND-MAIL",
              "E-Mail senden",
              () => this.isLoading
            ).withDisabled(
              () =>
                !this.isAnyEmail ||
                this.isSendDisabled ||
                !this.receiver?.length ||
                this.isAlreadySent
            )
          );
        }

        actions.push(
          PageHeaderSimpleAction(
            "SEND-TEST-MAIL",
            "Testmail versenden"
          ).withDisabled(() => this.isSendDisabled || !this.receiver?.length)
        );
      }

      if (this.$route.meta.type === "FAX") {
        actions.push(
          PageHeaderSimpleAction("SEND-FAX", "Fax senden").withDisabled(
            () =>
              this.isSendDisabled ||
              !(this.allAttachments && this.allAttachments.length)
          )
        );

        actions.push(
          PageHeaderDownloadLinkHrefAction("PREVIEW", "Vorschau", "Fax.pdf", this.faxPreviewURL)
            .withDisabled(() => !(this.allAttachments && this.allAttachments.length))
        );
      }

      if (this.$route.meta.type === "BRIEF") {
        actions.push(
          PageHeaderSimpleAction("SEND-BRIEF", "PDF").withDisabled(
            () => !this.receiver?.length
          )
        );
        actions.push(PageHeaderSimpleAction("SWITCH-TO-EMAIL", "E-Mail"));
        actions.push(PageHeaderSimpleAction("SWITCH-TO-FAX", "Fax"));
      }

      actions.push(
        PageHeaderSimpleAction("ADD-ACTIVITY", "Aufgabe hinzufügen").withVisible(() => !this.isKunde)
      );
      actions.push(
        PageHeaderSimpleAction(
          "RESET-EMAIL",
          `${this.pageTitle || ""} zurücksetzen`
        )
      );
      actions.push(PageHeaderSimpleAction("GENERATE-PDF", `PDF generieren`));

      return [...actions];
    },
    faxPreviewURL() {
      const allAttachments = this.allAttachments ?? [];
      const fileIds = JSON.stringify(
        allAttachments.filter((v) => v && v.id).map((v) => v.id)
      );
      return downloadLinkMaker(this.$store.getters, '/mailComposer/getFaxPreview', null, { fileIds });
    },
    allowedToSendPin() {
      return this.hasRoles(ROLES.ALLOW_SEND_PIN_VIA_SMS);
    },
    allowedSendLater(){
      return (this.$route.meta.type === 'MANY_MAIL' ||this.$route.meta.type === 'ONE_MAIL')&&  this.hasRoles(ROLES.MAIL_SEND_LATER )
       &&! this.sendNachricht&&!this.isDirect;
    },
    absenderDisabled() {
      return this.senderDisabled || !!this.$route.query?.singleUser;
    },
    vorlageDisabled() {
      return !!this.$route.query?.novorlage;
    },
    sendersOptions() {
      if (!this.sendersValue) {
        return [];
      }
      return (
        this.sendersValue.map((v) => ({
          value: v.email,
          label: sanitizeEmail(v.email || v.fullname || v.fullName),
        })) || []
      );
    },
    placeholdersOptions() {
      const keys = this.placeholders && Object.keys(this.placeholders);
      return (
        (this.placeholders &&
          Object.values(this.placeholders).map((v, i) => ({
            name: keys[i],
            data: v.map((p) => ({
              value: Object.values(p)[0],
              label: Object.keys(p)[0],
            })),
          }))) ||
        []
      );
    },
    templatesOptions() {
      let result = [
        {
          value: "-1",
          label: "Vorlage",
        },
      ];

      let templateList = [];

      if (this.briefType !== "WORD") {
        const param =
          this.$route.meta.type === "BRIEF" ? this.briefType : "EMAIL";
        templateList = this.templates?.[param] || [];

        templateList = templateList.filter(
          (template) =>
            template.vorlagenTyp ===
            (this.$route.meta.type == "BRIEF"
              ? VORLAGEN_TYPE_BRIEF
              : VORLAGEN_TYPE_MAIL)
        );
      }

      if (this.briefType === "WORD") {
        templateList = this.wordTemplates || [];
      }

      if (this.customFunctions.templatesOptions) {
        templateList = this.customFunctions.templatesOptions(templateList);
      } else {
        templateList = defaultFilterTemplateOption(
          templateList,
          this.$route.meta.type,
          this.isToCompany
        );
      }

      result = result.concat(
        templateList.map((v) => ({
          value: v.id,
          label: v.bezeichnung || v.subject,
        })) || []
      );

      return result;
    },
    testMailPreselectOptions() {
      let result = [];
      if (this.testSenders) {
        const testSenders = (Array.isArray(this.testSenders)
          ? this.testSenders
          : [this.testSenders]
        ).map((v) => ({
          value: v.email,
          label: v.fullname,
        }));
        result = result.concat(testSenders);
      }
      result.push({
        value: "-1",
        label: "freie Eingabe",
      });
      return result;
    },

    pageTitle() {
      if (this.customFunctions.pageTitle) {
        return this.customFunctions.pageTitle();
      }
      switch (this.$route.meta.type) {
        case "ONE_MAIL":
          return "E-Mail an Kunde";
        case "MANY_MAIL":
          return "Rund E-Mail";
        case "APP":
          return "Kunden APP zusenden";
        case "BRIEF":
          return "Brief an Kunde";
        case "BRIEF_MAIL":
          return "Brief an Kunde";
        case "FAX":
          return "Fax an Kunde";
        default:
          return this.hasPageHeader ? "E-Mail schreiben" : "";
      }
    },
    userToAppointment() {
      return [
        {
          value: this.loginData.userid,
          label: this.loginData.fullname,
        },
      ];
    },
    isAnyEmail() {
      return this.receiver?.filter(
        (r) => r?.email?.trim() || r?.fax?.trim() || r?.value === r?.label
      )?.length;
    },
    someReceiverHasNoFax() {
      // for some reason the fax number is in the email property of the receiver
      return this.receiver?.some((r) => !r.fax && !r.email);
    },
    warningMsg() {
      let msg = "";
      if (this.$route.meta.type === "FAX") {
        if (this.someReceiverHasNoFax) {
          msg += "Bei dem Kunden ist kein Fax hinterlegt";
        }
        return msg;
      }
      if (this.$route.meta.type === "BRIEF") {
        msg = "Bei Mailversand erhält jeder Empfänger eine separate E-Mail";
      }
      if (this.$route.meta.type !== "BRIEF") {
        msg =
          this.emailModus === "MANY_MAIL"
            ? "Jeder Empfänger erhält eine separate E-Mail!"
            : "es wird eine E-Mail versendet. Jeder Empfänger kann die anderen Empfänger einsehen!";
      }
      if (!this.isAnyEmail) {
        if (
          this.savedConfiguration?.type === "mailcomposer-schadensmeldung" &&
          !this.savedConfiguration
            ?.sendEmailToCompanyAutomaticallyConfigParameter
        ) {
          return msg;
        }
        msg += " Bei der Gesellschaft ist keine E-Mail-Adresse hinterlegt!";
      }
      return msg;
    },

    allAttachments() {
      let attachments =
        (this.attachments &&
          Array.isArray(this.attachments) &&
          this.attachments) ||
        [];
      attachments = [
        ...new Set(
          attachments
            .filter((v) => v && (v.id || v.copyFileId))
            .map((obj) => obj.id || obj.copyFileId || 0)
        ),
      ].map((id) => {
        return attachments.find(
          (obj) => obj.id === id || obj.copyFileId === id
        );
      });
      return attachments;
    },
    replaceContent() {
      if (this.savedConfiguration?.replaceContent !== undefined) {
        return this.savedConfiguration?.replaceContent;
      }
      return this.$route.query.replaceContent;
    },
    composerId() {
      return this.$route.name || this.$route.meta.type;
    },
    rows() {
      const actions = [SimpleAction("DELETE", PhTrash, "Löschen")];
      return this.allAttachments
        .filter(
          (row) =>
            row && (row.fileName || row.name) && (row.id || row.copyFileId)
        )
        .map((row) => ({
          id: row.id,
          copyFileId: row.copyFileId,
          name: row.fileName || row.name,
          grosse: "",
          noPreview: row.id === true,
          actions,
        }));
    },
    isToCompany() {
      return (
        (this.linkedObjectSelected && this.linkedObjectSelected.toCompany) ||
        this.isCompany
      );
    },
    customerEmail() {
      if (this.isCustomer) {
        let defaultEmailContact = {};
        if (this.$route.meta.type !== "FAX") {
          defaultEmailContact =
            this.customerData?.contacts?.find(
              (c) =>
                c?.type?.includes("E-Mail") && (c?.isDefault || c?.standart)
            ) || {};
        } else {
          defaultEmailContact =
            this.customerData?.contacts?.find(
              (c) => c?.type?.includes("Fax") && (c?.isDefault || c?.standart)
            ) || {};
        }
        return defaultEmailContact?.value;
      }
      return null;
    },
    api() {
      return process.env.VUE_APP_DB_PREFIX;
    },
    isOpenInsuranceInNewTab() {
      return this.hasRoles([
        [VIEW_ROLES.VIEW_BROKER],
        [VIEW_ROLES.VIEW_INTERN],
      ]);
    },
  },

  data() {
    return {
      confidental: false,
      sender: "",
      sendersValue: null,
      receiver: [],
      receiverCC: [],
      receiverBCC: [],
      manualReceiverType: "TO",
      userType: "TO",
      subject: "",
      activitySubject: "",
      template: "-1",
      dateiname: "brief.pdf",
      textSms: "",
      canSms: false,
      isSMS: true,
      isNotHTML: false,
      isDirect: false,
      sendEmail: true,
      sendNachricht: false,
      faxNotifyType: "DEFAULT",
      briefBackground: false,
      briefLandscape: false,
      briefSaveDocArchive: true,
      briefType: "BRIEF",
      briefFile: null,
      testMailPreselect: "-1",
      testMailPreselectEmail: "",
      emailModus: this.$route.meta.modus,
      passwort: "",
      manualReceiver: {
        anrede: "Firma / Verein",
      },
      anredeValues: ["Herr", "Frau", "Firma / Verein"],

      html: "",
      isPreview: false,
      isCC: false,
      isBCC: false,
      isFromSaved: false,
      isDisabledEncrypted: false,
      dateSend:null,
      timeSend:null,
      availableUsers: [],
      allUsers: [],
      nodeId:
        "MTAlMjZtc2MuaGliZXJuYXRlLnBlcnNpc3RlbmNlLkRva3VtZW50Tm9kZS5UeXBlcy5BbnRyYWcuRG9rTm9kZVZlcnNpY2hlcnVuZyUyNm1zYy5oaWJlcm5hdGUucGVyc2lzdGVuY2UuR2VzZWxsc2NoYWZ0JTI2QUxMSUFOWiUyQlNBQ0hWRVJTSUNIRVJVTkcmbXNjLmhpYmVybmF0ZS5wZXJzaXN0ZW5jZS5Eb2t1bWVudE5vZGUuVHlwZXMuQW50cmFnLkRva05vZGVWZXJzaWNoZXJ1bmcmbXNjLmhpYmVybmF0ZS5wZXJzaXN0ZW5jZS5WZXJzVmVydHJhZyYxMTY0MjM0",

      isPresaveMail: false,
      // allAttachments: [],

      acceptFileTypes: "*/*",
      briefIsPdfView: true,
      briefTypeOptions: [
        {
          value: "BRIEF",
          label: "Brief",
        },
        {
          value: "BRIEF_MIT_DS",
          label: "Brief mit Datenschutzerklärung",
        },
        {
          value: "WORD",
          label: "Word Datei",
        },
      ],
      faxNotifyTypeOptions: [
        {
          value: "DEFAULT",
          label: "Im Fehlerfall",
        },
        {
          value: "NONE",
          label: "Nie",
        },
        {
          value: "ALWAYS",
          label: "Immer",
        },
      ],
      linkedObject: "",
      ignoreError: false,
      errorMessage: [],
      isSendDisabled: false,
      isAlreadySent: false,
      isCriticalSendingError: false,
      isLoaded: false,
      isLoading: false,

      stylesTemplate: "",
      headers: {
        lockedLeft: [SlotColumn("name", "Dateiname", 200, 1)],
        lockedRight: [ActionColumn("actions", "")],
      },
      isUserPreviewActivated: false,
      isPreInit: true,
      maxItemsVisible: MAX_ITEMS_VISIBLE,
      pdfPreviewLink: null,
      selectedAttachmentDocumentsRow: [],
    };
  },
  watch: {
    brief(v) {
      if (v) {
        const byteArray = (this.briefFile = v);
        const fileName = getFileName(this.dateiname);
        viewDocument({
          data: byteArray,
          contentType: getFileType(this.dateiname),
          filename: fileName,
        });
      }
    },
    unsetPlaceholders(v) {
      if (v && v.length > 0) {
        v.forEach((placeholder) => {
          this.html = this.html.replaceAll(
            placeholder,
            '[[<span style="color: var(--color-danger);">' +
              placeholder.replace(/\[|\]/g, "") +
              "</span>]]"
          );
        });
      }
    },
    templateData(v) {
      if (!v) {
        return;
      }
      this.subject = v.subject;
      this.activitySubject = v.subject;
      this.dateiname = v.subject + ".pdf";
      this.setHtmlText(v.html.replace(/\\n/g, "").replace(/\\t/g, ""));
    },
    appTemplate(v) {
      this.subject = v.subject;
      this.activitySubject = v.subject;
      this.setHtmlText(v.html);
      this.canSms = v.canSms;
      this.textSms = v.message;
      if (v.attachments?.length) {
        if (
          this.attachments &&
          !this.attachments?.find((val) => val.dokId !== undefined)
        ) {
          this.$store.commit(
            MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS,
            v.attachments
          );
        }
      }
    },
    savedConfiguration(data) {
      if (!this.replaceContent) {
        this.setSavedConfiguration(data);
      }
    },
    senders(values) {
      this.sendersValue = values;
      this.sender = values && values[0] && values[0].email;
      this.sender =
        this.savedConfiguration.sender ||
        (values && values[0] && values[0].email);
    },
    initData(value) {
      if (!value) return;
      this.linkedObject = value.linkedObject;
      if (this.customFunctions.initPlaceholders) {
        this.customFunctions.initPlaceholders(this.linkedObject);
      } else {
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PLACEHOLDERS, {});
      }
      let receiver =
        this.receiver && Array.isArray(this.receiver) ? [...this.receiver] : [];
      if (
        this.linkedObject &&
        !this.linkedObjectSelected?.participants &&
        !this.isFromSaved
      ) {
        const users = value.participants.map((p) => p.user?.userId);
        receiver = receiver.filter((r) => !users.includes(r.value));
        value.participants.map((p) => {
          receiver.push({
            data: p,
            value: p?.user?.userId,
            label: `${p?.email}`,
            email: `${p?.email}`,
            type: p?.user?.type,
            primaryObjectSerialized: this.linkedObject,
          });
        });
      }

      if (
        !this.isFromSaved &&
        (this.$route.meta.type === "ONE_MAIL" ||
          this.$route.meta.type === "MANY_MAIL" ||
          !this.isUserSelect) &&
        !this.skipPreInit
      ) {
        if (!this.subject) {
          this.subject =
            this.linkedObjectSelected?.subject ||
            (this.$route.params && this.$route.params.subject) ||
            value.subject;

        }
        this.passwort = this.hasPasswort && value.passwort;
        if (this.$route.meta.mailMode !== "AUFTRAGSDOKUMENTE") {
          this.setHtmlText(value?.html);
        }

        let participants = value.participants || [];
        if (this.$route.email && this.$route.userId) {
          participants = [
            {
              user: {
                type: this.$route.type,
                userId: this.$route.userId,
              },
              email: this.$route.email,
            },
          ];
        }
        if (this.customFunctions.replaceParticipants) {
          participants = this.customFunctions.replaceParticipants(participants);
        }

        participants
          .filter((p) => p && p.user)
          .map((p) => {
            receiver.push({
              data: p,
              value: p.user.userId,
              label: `${p.email}`,
              email: `${p.email}`,
              type: p.user.type,
            });
          });
        if (value && receiver.length) {
          receiver = [
            ...new Set(
              receiver.filter((v) => v && v.value).map((obj) => obj.value || 0)
            ),
          ].map((value) => {
            return receiver.find((obj) => obj.value === value);
          });
        }
      }
      if (this.$route.params && this.$route.params.receiver) {
        receiver = this.$route.params.receiver.map((p) => ({
          data: p,
          value: p.user.userId,
          label: `${p.email}`,
          email: `${p.email}`,
          type: p.user.type,
        }));
      }
      this.receiver = receiver;
      if (
        !this.isFromSaved &&
        this.linkedObjectSelected &&
        this.linkedObjectSelected.toCompany &&
        !this.$route.params.content
      ) {
        Vue.set(this, "html", value?.html);
        this.handlePreview(true);
      }
    },
    previewHtml(value) {
      this.activitySubject = value.subject;
      if (
        this.isPreInit &&
        this.linkedObjectSelected &&
        this.linkedObjectSelected.toCompany &&
        !this.isPreview
      ) {
        // Preview is now part of Html Editor. Don't need to be set on html
        //this.setHtmlText(this.splitBodyTemplate(value.text));
        if (
          !(this.linkedObjectSelected && this.linkedObjectSelected.toCompany)
        ) {
          this.subject = value.subject;
        }
        this.isPreInit = false;
      }
    },
    constants(values) {
      if (this.$route.meta.type === "BRIEF") {
        this.subject =
          this.subject || (values && values.serialBriefMailSubject) || "";
      }
      if (this.$route.meta.type === "BRIEF_MAIL") {
        if (!this.html) {
          this.setHtmlText((values && values.serialBriefMailText) || "");
        }
      }
    },
    error(message) {
      if (message && message.length) {
        this.errorMessage = message;
        this.$refs.modalError.open();
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ERROR, []);
      }
    },

    customerData(value) {
      let contacts = [];
      if (this.$route.meta.type === "FAX") {
        contacts =
          value.contacts &&
          value.contacts.find((c) => c.type && c.type.includes("Fax"));
      } else {
        contacts =
          value.contacts &&
          value.contacts.find((c) => c.type && c.type.includes("E-Mail"));
      }
      if (
        this.$route.meta.type !== "BRIEF" &&
        this.isCustomer &&
        (!contacts || (contacts && !contacts.value)) &&
        this.isContactRequired
      ) {
        this.isSendDisabled = true;
        this.$refs.modalNoContact.open();
      }

      this.setEmailIntoCustomerReceiver();
    },
    sendMailResult(value) {
      if (!value || !value.status) {
        return;
      }

      if (
        (value.status === "OK" && !value.errors) ||
        (value.errors && !Object.keys(value.errors).length) ||
        (value.errors && Object.keys(value.errors).length && this.ignoreError)
      ) {
        if (value.testMail === false) {
          this.isAlreadySent = true;
          this.isSendDisabled = true;
          this.$store.commit(
            MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG,
            {
              type: this.composerId,
              action: "clear",
            }
          );
        }

        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SEND_MAIL, {});
        
        if (this.linkedObjectSelected?.navigateToAfterSendMail?.path) {
          this.$router.push({
            path: this.linkedObjectSelected.navigateToAfterSendMail.path ,
            query: this.linkedObjectSelected.navigateToAfterSendMail.query,
          });
          return;
        } else if (
          this.linkedObjectSelected?.data?.length &&
          this.linkedObjectSelected.data[0]
        ) {
          if (this.isOpenInsuranceInNewTab && this.$route.query?.userId) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
              customerId: this.$route.query?.userId,
              insurances: true,
              path: `/home/versicherungen/insurance-group/details/${this
                .linkedObjectSelected?.data?.length &&
                this.linkedObjectSelected.data[0]}`,
            });
          } else {
            this.navigateTo(
              `/home/versicherungen/insurance-group/insurancePostfach/${this
                .linkedObjectSelected?.data?.length &&
                this.linkedObjectSelected.data[0]}`
            );
          }
        }

        this.activitySubject = this.subject
        if(value.testMail === false) {
          //Use preview subject here        
          this.handleAddActivity(`/communication/postfach/overview`)
        }
      } else {
        this.isCriticalSendingError = value.errorIgnorierbar === false;
        this.isSendDisabled = false;
      }
      this.isLoading = false;
      this.ignoreError = false;
    },
    $route(to, from) {
      this.confidental = true;
      this.sender = "";
      this.receiver = [];
      this.subject = "";
      this.passwort = "";
      this.template = "";
      this.html = "";
      // this.allAttachments = [];

      this.initialData();
    },
    configParameter: {
      deep: true,
      handler(value, oldValue) {
        this.isNotHTML = value.EMAIL_TEXT_ONLY?.content === "Ja";
      },
    },
  },
  async mounted() {
    this.isSendDisabled = false;
    this.isAlreadySent = false;
    this.isCriticalSendingError = false;
    this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SEND_MAIL, {});
    if (
      this.isCustomer &&
      (!this.customerData || (this.customerData && !this.customerData.contacts))
    ) {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
    }
    this.ignoreError = false;
    this.initialData();
    if (this.$route.meta.type === "APP") {
      this.confidental = false;
    }
    this.emailModus = this.$route.meta.modus;
    if (this.$route.meta.mailData || this.$route.query?.stateKeys) {
      this.setData();
    }
    if (this.$route.params && this.$route.params.subject) {
      this.subject = this.$route.params.subject;
    }
    if (this.$route.params && this.$route.params.content) {
      const content = (this.$route.params.content || "")
        .replaceAll("<html>", "")
        .replaceAll("</html>", "")
        .replaceAll("<body>", "")
        .replaceAll("</body>", "");

      Vue.set(this, "html", content);
    }
    // attachments of a forwarded e-mail
    if (this.$route.params && this.$route.params.copiedFiles) {
      const attachments = [];
      this.$route.params.copiedFiles.forEach((file) => {
        attachments.push({
          copyFileId: file.id,
          label: file.fileName,
          fileName: file.fileName,
          name: file.fileName,
          uploaded: true,
        });
      });
      if (attachments.length) {
        this.$store.commit(
          MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS,
          attachments
        );
      }
    }
    const { cache, action, type, ...pdf } = this.pdf || {};
    if (
      this.$route.meta.type === "BRIEF" &&
      this.constants &&
      this.pdf &&
      !Object.keys(pdf).length
    ) {
      this.subject = this.subject || this.constants.serialBriefMailSubject;
    }
    if (
      this.$route.meta.type === "BRIEF_MAIL" &&
      this.constants &&
      this.pdf &&
      !Object.keys(pdf).length &&
      !this.html
    ) {
      this.setHtmlText(this.constants.serialBriefMailText);
    }

    if (this.replaceContent) {
      let mailData;
      if (this.initSchadensmeldungMail) {
        mailData = this.initSchadensmeldungMail;
      }

      if (this.initVermittlernummernMail) {
        mailData = this.initVermittlernummernMail;
      }

      if (this.initQuartalsberichteBrokerViewEmail) {
        mailData = this.initQuartalsberichteBrokerViewEmail;
      }

      if (this.mailHonorareAbrechnungenVerschicken) {
        mailData = this.mailHonorareAbrechnungenVerschicken;
        this.receiver = [];
      }

      if (this.initMailVertragsspiegel) {
        mailData = this.initMailVertragsspiegel;
      }

      if (this.mailFondshopAction) {
        mailData = this.mailFondshopAction;
      }

      if (mailData) {
        this.subject = mailData.subject;
        this.setHtmlText(mailData.html);
        /* is validated by null or undefined because the value is boolean and therefore a short evalution of existence of "this.savedConfiguration?.toCompany"
         * could lead a wrong interpretation
         */
        const isMailingToCompany =
          this.initSchadensmeldungMail?.toCompany !== null ||
          this.initSchadensmeldungMail?.toCompany !== undefined
            ? this.initSchadensmeldungMail?.toCompany
            : this.$route.query?.toCompany;
        let receiver =
          this.receiver && Array.isArray(this.receiver) && !isMailingToCompany
            ? [...this.receiver]
            : [];
        mailData.participants &&
          mailData.participants.map((p) => {
            receiver.push({
              value: p && p.user ? p.user.userId : p?.email,
              label: p?.email || p?.fullName,
              email: p?.email,
              type: p?.user?.type,
              data: p,
            });
          });

        if (mailData && receiver.length) {
          receiver = [
            ...new Set(
              receiver
                .filter((v) => v && v.value)
                .map((obj) => `${obj.value}${obj.data?.tempFileId}` || 0)
            ),
          ].map((value) => {
            return receiver.find(
              (obj) => `${obj.value}${obj.data?.tempFileId}` === value
            );
          });
        }
        this.receiver = receiver;
      }
    }

    const payload = {
      configId: "EMAIL_TEXT_ONLY",
      configType: FC_CONFIG.ADD_PARAMETER,
    };
    this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);

    if (this.$route.meta.type === "BRIEF") {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PDF, {});
    }
    if (this.$route.meta.mailMode === "AUFTRAGSDOKUMENTE") {
      const value = this.auftragsdokumenteInit.reportResult;
      if (value) {
        if (!this.savedConfiguration.subject) {
          this.subject = value.mailBetreff;
        }
        if (this.hasPasswort && !this.savedConfiguration.passwort) {
          this.passwort = value.passwort;
        }
        if (
          !this.savedConfiguration.keys ||
          !this.savedConfiguration.getComputedText
        ) {
          this.setHtmlText(value.mailText);
        }
        this.emailModus = value.emailModus;
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, [
          {
            id: value.mailTempFileId,
            name: `Auftragsdokumente${this.loginData.userid}.pdf`,
          },
        ]);
      }
    } else if (this.$route.query.singleUser) {
      if (this.$route.meta.type === "BRIEF_MAIL") {
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
        this.receiver = [];
        //Selection of Receiver should be made of Ansprechpartner?
      } else if (this.participants && this.participants.length) {
        this.receiver = this.participants
          .filter((v) => v)
          .map((v) => ({
            value: v.personId,
            label: v.label,
            type: v.type,
          }));
      }
    }
    this.$nextTick(() => {
      this.isLoaded = true;
    });
    this.sendersValue = this.senders;
    if (this.vollmacht) {
      this.$emit("vollmacht", this.vollmacht);
    }
    this.$emit("callbacks", {
      setSubject: this.updateSubject,
      setReceiver: this.setReceiver,
      setHtmlText: this.setHtmlText,
      setSendersValue: this.setSendersValue,
      setSenders: this.setSenders,
      setSender: this.setSender,
      setEmailModus: this.setEmailModus,
      setPassword: this.setPassword,
      handleBeforeRouteLeave: this.handleBeforeRouteLeave,
    });

    if (this.$route.query?.email) {
      let receiver = [];
      receiver.push({
        data: this.$route.query,
        value: this.$route.query?.userId,
        label: `${this.$route.query?.fullName}`,
        email: `${this.$route.query?.email}`,
        type: this.$route.query?.type,
      });
      if (receiver?.length) {
        receiver = [
          ...new Set(
            receiver.filter((v) => v && v.value).map((obj) => obj.value || 0)
          ),
        ].map((value) => {
          return receiver.find((obj) => obj.value === value);
        });
      }
      this.receiver = receiver;
    }
  },

  methods: {
    setReceiver(value) {
      let receiver =
        this.receiver && Array.isArray(this.receiver) ? [...this.receiver] : [];
      receiver = receiver.concat(value);
      if (receiver?.length) {
        receiver = [
          ...new Set(
            receiver.filter((v) => v && v.value).map((obj) => obj.value || 0)
          ),
        ].map((value) => {
          return receiver.find((obj) => obj.value === value);
        });
      }
      this.receiver = receiver;
    },
    setSendersValue(value) {
      if (this.sendersValue) {
        this.sendersValue = this.senders.concat(
          value.filter((p) => p.sendType == "FROM")
        );
      }
    },
    setPassword(value) {
      this.passwort = value;
    },
    setEmailModus(value) {
      this.emailModus = value;
    },
    setSenders(value) {
      this.senders = value;
    },
    setSender(value) {
      this.sender = value;
    },
    updateSubject(value) {
      this.subject = value;
    },
    sanitize(htmlString) {
      return sanitizeHtml(htmlString, this.sanitizeHtmlConfig);
    },
    initialData() {
      this.pdfPreviewLink = null;
      this.isPresaveMail = false;
      if (
        this.$route.meta.type === "ONE_MAIL" &&
        !this.$route.meta.insuranceCancel &&
        !this.$route.meta.schadensmeldung
      ) {
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_INIT);
      }
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_SENDERS);
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_CONSTANTS);
      if (this.$route.meta.type !== "FAX") {
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_HTML_TEMPLATES);
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.TEST_SENDERS);
        if (this.isGetPlaceholdersOnInit) {
          let link = {};
          let t = this.additionalSendMailParams;

          if (t && t.linkedObject) {
            link = { linkedObject: t.linkedObject };
          } else {
            link =
              this.linkedObjectSelected?.data?.length &&
              this.linkedObjectSelected.data[0]
                ? {
                    linkedObject: "VERTRAG" + this.linkedObjectSelected.data[0],
                  }
                : {};
          }
          this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PLACEHOLDERS, {
            ...link,
          });
        }
      }
      // gets canceled because it's a repeated request
      if (this.$route.meta.type === "APP") {
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.APPZUSENDEN);
      }
      if (this.$route.meta.type === "BRIEF") {
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.WORD_TEMPLATES);
        this.$store.dispatch(
          MAILCOMPOSER_TYPES.ACTIONS.GET_HTML_TEMPLATES,
          "BRIEF"
        );
        this.$store.dispatch(
          MAILCOMPOSER_TYPES.ACTIONS.GET_HTML_TEMPLATES,
          "BRIEF_MIT_DS"
        );
      }
      // GET_HTML_TEXT: ACTIONS_PREFIX + 'GET_HTML_TEXT',
      this.setSavedConfiguration(this.savedConfiguration);
      this.isCC = !!this.receiverCC && this.receiverCC.length;
      this.isBCC = !!this.receiverBCC && this.receiverBCC.length;
      let currentReceiver = this.receiver;
      switch (this.userType) {
        case "CC":
          this.isCC = true;
          currentReceiver = this.receiverCC;
          break;
        case "BCC":
          this.isBCC = true;
          currentReceiver = this.receiverBCC;
          break;
      }
      let receiver = (currentReceiver && [...currentReceiver]) || [];
      if (this.userType === "TO") {
        if (
          this.isCustomer &&
          !receiver.find((v) => v.value === this.loginData.userid) &&
          this.isUserSelect &&
          this.isContactRequired
        ) {
          receiver = receiver.concat([
            {
              value: this.loginData.userid,
              label: this.loginData.fullname,
              email: this.customerEmail,
              role: "",
            },
          ]);
        }
      }
      if (this.participants && this.participants.length) {
        if (this.$route.meta.type !== "FAX") {
          receiver = receiver.concat(
            this.participants
              .filter((v) => v)
              .map((v) => ({
                value: v.id || v.value,
                label:
                  (v.type !== BROKER_TYPE && v.customerID) ||
                  v.maklernr ||
                  (v.user && v.user.type && v.user.type === KUNDE_TYPE)
                    ? (v.data &&
                        v.data.person &&
                        v.data.person.personalDataAddress &&
                        `${v.data.person.personalDataAddress.firstName || ""} ${
                          v.data.person.personalDataAddress.lastName
                        }`) ||
                      v.email ||
                      v.fullName ||
                      (v.name && v.name.replace(",", ""))
                    : (v.fullName && `${v.fullName}`) || v.email || "",
                email: v.email,
                type:
                  v.type ||
                  ((v.type !== BROKER_TYPE && v.customerID) ||
                  v.maklernr ||
                  (v.user && v.user.type && v.user.type === KUNDE_TYPE)
                    ? KUNDE_TYPE
                    : BROKER_TYPE),
              }))
          );
        } else {
          receiver = receiver.concat(
            this.participants
              .filter((v) => v && ((v.data && v.data.fax) || v.fax))
              .map((v) => ({
                value: v.id,
                fax: v?.data?.fax || v?.fax,
                email: v?.data?.fax || v?.fax,
                label:
                  (v.type !== BROKER_TYPE && v.customerID) ||
                  v.maklernr ||
                  (v.user && v.user.type && v.user.type === KUNDE_TYPE)
                    ? (v.data &&
                        v.data.person &&
                        v.data.person.personalDataAddress &&
                        `${v.data.person.personalDataAddress.firstName || ""} ${
                          v.data.person.personalDataAddress.lastName
                        }`) ||
                      v.email ||
                      v.fullName ||
                      (v.name && v.name.replace(",", ""))
                    : (v.fullName && `${v.fullName}`) || v.email || "",
                type: KUNDE_TYPE,
              }))
          );
        }
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
      }
      const currentUserId = this.originalUser?.userid || this.loginData.userid;
      receiver = receiver.filter((v) => v.value !== currentUserId);
      if (receiver && receiver.length) {
        receiver = [
          ...new Set(
            receiver.filter((v) => v && v.value).map((obj) => obj.value || 0)
          ),
        ].map((value) => {
          return receiver.find((obj) => obj.value === value);
        });
      }
      switch (this.userType) {
        case "CC":
          this.receiverCC = receiver;
          break;
        case "BCC":
          this.receiverBCC = receiver;
          break;
        case "TO":
          this.receiver = receiver;
          break;
      }
      if (this.attachmentsCache && this.attachmentsCache[this.composerId]) {
        this.$store.commit(
          MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS,
          this.attachmentsCache[this.composerId]
        );
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
          type: this.composerId,
          action: "clear-cache",
        });
      }
    },
    setBriefAnhang() {
      const {
        userTypeSender,
        userIdSender,
        participants,
        emailSender,
      } = this.getRequestParams();
      const payload = {
        userTypeSender,
        userIdSender,
        emailSender,
        briefInput: this.getBriefParams(),
        participants,
      };

      const config = {
        defaultSpinner: true,
      };
      return axios
        .post(
          `${process.env.VUE_APP_API}/mailComposer/getBriefAnhangId`,
          payload,
          config
        )
        .then((response) => {
          if (response && response.data) {
            const fileName =
              (this.dateiname || "brief.pdf").replace(".pdf", "") + ".pdf";
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, [
              {
                id: response.data,
                label: fileName,
                fileName: fileName,
              },
            ]);
          }
        });
    },
    setData() {
      const stateKeys =
        this.$route.meta.mailData || this.$route.query?.stateKeys?.split(",");
      if (stateKeys) {
        let mailData = this.$store.state;
        stateKeys.forEach((key) => {
          if (mailData) {
            mailData = mailData[key];
          }
        });
        if (mailData) {
          this.linkedObject = mailData.linkedObject;
          Vue.set(this, "subject", mailData.input_subject)
          this.receiver = mailData.receiver;
          if (this.$store.state.mailcomposer?.attachments && mailData.anhang) {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
              action: "clear",
              type: this.composerId,
            });
            this.$store.commit(
              MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS,
              mailData.anhang
            );
          }
          this.userType = "TO";
          this.receiver = mailData.to.map((to) => ({
            label: to?.email,
            email: to?.email,
            value: to?.user?.userId,
            type: to?.user?.type,
          }));
          this.setHtmlText(mailData.input_html);
        }
      }
    },
    sendMail(value, action, confidential = null) {
      this.confidental = !!confidential;
      if (value) {
        let testsender = {};
        if (action && action.testMail) {
          const testSendersArray = Array.isArray(this.testSenders)
            ? this.testSenders
            : [this.testSenders];
          const testSenders = testSendersArray.filter(
            (v) => v.email === this.testMailPreselect
          );
          testsender =
            (testSenders && testSenders[0]) ||
            (testSendersArray && testSendersArray[0]);
        } else {
          this.isSendDisabled = true;
          this.$store.commit(SEARCH_CONFIGS_TYPES.MUTATIONS.RESET_STATE);
        }

        this.isLoading = true;
        const params = this.getRequestParams();
        this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.SEND_MAIL, {
          ...params,
          attachments: this.allAttachments
            .filter((v) => v.id !== true && (v.copyFileId || v.id))
            .map((f) => ({
              encrypt: false,
              tempFileId: f.id,
              copyFileId: f.copyFileId, // instead of id if the attachment is part of a forwarded e-mail
            })),
          ignoreError: this.ignoreError,
          ...(action && action.testMail
            ? {
                testMail: true,
                testEmailParticipant: {
                  ...(this.testMailPreselect !== "-1"
                    ? {
                        user: {
                          type:
                            (testsender.user && testsender.user.type) ||
                            "MAKLER",
                          userId: testsender.user && testsender.user.userId,
                        },
                      }
                    : { email: this.testMailPreselectEmail }),
                },
              }
            : {}),
          ...(this.$route.meta.type === "APP" && this.canSms
            ? {
                appSms: this.isSMS,
              }
            : {}),
          emailAboutApp: this.$route.meta.type === "APP",
          ...this.additionalSendMailParams,
          direct: this.isDirect
        });
      }
    },
    sendFax() {
      const {
        userTypeSender,
        userIdSender,
        participants,
      } = this.getRequestParams();
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.SEND_FAX, {
        userTypeSender,
        userIdSender,
        participants: participants.map((p) => {
          const { email, ...data } = p;
          return {
            ...data,
            fax: p.fax || email,
          };
        }),
        faxNotifyType: this.faxNotifyType,
        // ignoreError:"true",
        attachments: this.allAttachments
          .filter((v) => v && v.id)
          .map((f) => ({
            tempFileId: f.id,
          })),
      });
      this.isAlreadySent = true;
      this.isSendDisabled = true;
    },
    sendBrief() {
      const briefInput = this.getBriefParams();
      if (briefInput.html) briefInput.html = this.htmlCleanUp(briefInput.html);
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.SEND_BRIEF, {
        ...this.getRequestParams(true),
        briefInput: this.getBriefParams(),
      });
    },
    htmlCleanUp(html) {
      return html
        .replaceAll("font-size: 1em", "font-size:12pt")
        .replaceAll('"', '"')
        .replaceAll("\t", "")
        .replaceAll("\n", "")
        .replaceAll("*/;/*", "*//*")
        .replace(/\s*(?!<\")\/\*[^\*]+\*\/(?!\")\s*/g, "");
    },
    getBriefParams() {
      if (this.$route.meta.type === "BRIEF_MAIL") {
        const fileName = this.pdf.dateiname?.replace(".pdf", "") + ".pdf";
        return {
          queryFormat: this.pdf.briefLandscape,
          hintergruend: this.pdf.briefBackground,
          vorlageTyp: this.pdf.briefType !== "WORD" ? this.pdf.briefType : null,
          worldId: this.pdf.briefType === "WORD" ? this.pdf.template : null,
          fileName,
          subject: this.pdf.subject,
          html: this.wrapBodyTemplate(this.pdf.getComputedText),
          saveInArchive: this.pdf.briefSaveDocArchive,
        };
      }
      const fileName = this.dateiname?.replace(".pdf", "") + ".pdf";
      return {
        queryFormat: this.briefLandscape,
        hintergruend: this.briefBackground,
        vorlageTyp: this.briefType !== "WORD" ? this.briefType : null,
        worldId: this.briefType === "WORD" ? this.template : null,
        fileName,
        subject: this.subject,
        html: this.wrapBodyTemplate(this.html),
        saveInArchive: this.briefSaveDocArchive,
      };
    },
    onAddFiles(event) {
      const files = Object.values(event.target.files);
      files.map((file) => {
        // upload file
        uploadFileChunked(file).then((id) => {
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, [
            {
              id: id,
              name: file.name,
              uploaded: true,
            },
          ]);
        });
      });
      event.target.value = "";
    },
    sendTestMailModal() {
      if (
        this.sender &&
        this.testMailPreselectOptions.filter((v) =>
          this.sender.includes(v.value)
        ).length
      ) {
        this.testMailPreselect = this.testMailPreselectOptions.filter((v) =>
          this.sender.includes(v.value)
        )[0].value;
      }
      this.$refs.modalTestMail.open();
    },
    sendIgnoreError() {
      this.ignoreError = true;
      this.sendMail(true);
      this.$refs.modalError.close();
    },
    goToProfile() {
      this.$refs.modalNoContact.close();
      this.navigateTo("/persoenlichedaten/customer-data/steps/kommunikationsdaten");
    },
    sendTestMailModalClose() {
      this.$refs.modalTestMail.close();
    },
    sendTestMail() {
      this.ignoreError = true;
      this.sendMail(true, { testMail: true });
      this.ignoreError = false;
    },
    handlePreview(value, createPdfLink = false) {
      return new Promise((resolve, reject) => {
      if (value) {
        this.$store
          .dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PREVIEW, {
            ...this.getRequestParams(true),
            ...(this.$route.meta.type === "BRIEF"
              ? { briefInput: this.getBriefParams() }
              : {}),
            ...this.additionalSendMailParams,
            createPdfLink,
          })
          .then((preview) => {
            if (createPdfLink) {
              this.pdfPreviewLink = preview.data?.tempFileLink;
            }
            resolve(preview)
          }).catch((error) => reject(error))
      }})
    },
    openAddDocumentModal(files) {
      this.$refs.addDocumentModal.open(files);
    },
    addDocument(files) {
      files &&
        files.map((file) => {
          uploadFileChunked(file).then((id) => {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, [
              {
                id: id,
                name: file.name,
                uploaded: true,
              },
            ]);
          });
        });
      // upload file
    },
    deleteReceiveUsers(value) {
      const index = this.availableUsers.indexOf(value);
      if (index > -1) {
        this.availableUsers.splice(index, 1);
      }
    },
    deleteAllReceiveUsers(type = "TO") {
      this.availableUsers = [];
      this.receiver = [];
    },
    resetEmail(reset = true) {
      // this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, null);
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: this.composerId,
        action: "clear",
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: this.composerId,
        action: "clear",
      });
      if (
        this.$route.meta.type !== "BRIEF_MAIL" ||
        (to && to.meta && !to.meta.modus)
      ) {
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PDF, {
          ...this.getPDFData(),
          type: this.composerId,
          action: "clear",
        });
      } else {
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PDF, {
          ...this.getPDFData(),
          type: this.composerId,
          action: "clear",
        });
      }
      this.$store.commit(SEARCH_CONFIGS_TYPES.MUTATIONS.RESET_STATE);
      this.isSendDisabled = false;
      this.isAlreadySent = false;
      this.isPreview = false;
      this.confidental = true;
      this.sender = "";
      this.receiver = [];
      this.subject = "";
      this.passwort = "";
      this.template = "";
      this.html = "";
      this.initialData();
    },
    /*searchReceiverUsers(value) {
      this.timeoutRef && clearTimeout(this.timeoutRef);
      this.timeoutRef = setTimeout(() => {
        if (!this.isCustomer && value && value.length > 2) {
          const params = {
            filterType:
              this.$route.meta.type !== "FAX" ? "MailComposer" : "SendFax",
            lastName: value,
          };
          axios
            .post(
              `${process.env.VUE_APP_API}/customerFilters/getCustomersFiltered`,
              params
            )
            .then((response) => {
              const emailParticipants =
                (response &&
                  response.data &&
                  (response.data.outputs || response.data.emailParticipants)) ||
                [];
              this.allUsers = this.allUsers.concat(emailParticipants);
              if (response.data.outputs) {
                this.availableUsers = emailParticipants.map((v) => {
                  const name =
                    (v.user && v.user.userId) ||
                    (v.person &&
                      v.person.personalDataAddress &&
                      `${v.person.personalDataAddress.firstName || ""} ${
                        v.person.personalDataAddress.lastName
                      }`);
                  const contacts = v.person.contacts || [];
                  let email = "";
                  let fax = "";
                  contacts.map((c) => {
                    if (c.typeId === "E-Mail") {
                      if (email && c.default) {
                        email = c.value;
                      } else {
                        email = c.value;
                      }
                    }
                    if (c.typeId === "Fax") {
                      if (fax && c.default) {
                        fax = c.value;
                      } else {
                        fax = c.value;
                      }
                    }
                  });
                  return {
                    value: v.customerID,
                    label:
                      this.$route.meta.type !== "FAX"
                        ? `${name}`
                        : `${name}${fax ? ` <${fax}>` : ""}`,
                  };
                });
              }

              if (response.data.emailParticipants) {
                if (this.$route.meta.type !== "FAX") {
                  this.availableUsers = emailParticipants.map((v) => ({
                    value: v.user?.userId || v.email,
                    label: `${v.email}`,
                  }));
                } else {
                  this.availableUsers = emailParticipants.map((v) => ({
                    value: v.user.userId,
                    label: `${v.fullName} ${v.fax}`,
                  }));
                }
              }
            });
        }
      }, 300);
    },*/
    searchReceiverUsers(value) {
      this.timeoutRef && clearTimeout(this.timeoutRef);
      this.timeoutRef = setTimeout(() => {
        if (value && value.length > 2) {
          axios
            .get(
              `${process.env.VUE_APP_API}/QuickSearch/getPerson?searchParam=${value}`
            )
            .then((response) => {
              this.availableUsers = response.data.map((v) => ({
                value: v.customerID, //handles e-mail and fax
                label: `${v.firstName ? v.firstName + " " : ""}${v.lastName}`,
                email: v.email,
                data: v,
                customerID: v.customerID,
              }));
            });
        }
      }, 300);
    },
    deleteReceiverUsers(value) {
      const users = this.savedBeteiligte.filter(
        (v) => (v.customerID = value.value)
      );
      users.map((beteiligte) => {
        if (beteiligte.rolle !== "BESITZER") {
          this.$store.dispatch(CALENDAR_TYPES.ACTIONS.DELETE_BETEILIGTE, {
            beteiligte: {
              id: beteiligte.id || beteiligte.customerID,
            },
          });
        }
      });
    },
    setSavedConfiguration(params) {
      let savedData = params;
      if (
        this.$route.meta.type === "BRIEF" &&
        this.$route.meta.insuranceBrief
      ) {
        return;
      }
      if (this.$route.meta.type === "BRIEF") {
        savedData =
          (this.pdf?.cache && this.pdf?.cache[this.composerId]) || params;
      } else if (
        params.cache &&
        params.cache[this.composerId] &&
        Object.keys(params.cache[this.composerId]).length
      ) {
        savedData = params.cache[this.composerId];
      }

      this.isDisabledEncrypted = savedData && savedData.isDisableEncrypted;

      const { cache, action, type, isDisableEncrypted, ...data } =
        savedData || {};
      if (data && Object.keys(data).length) {
        this.isFromSaved = true;
        this.confidental = data.confidental;
        this.sender = data.sender;

        const receiver =
          (data.receiver &&
            [
              ...new Set(
                data.receiver.concat(this.receiver).map((obj) => obj.value)
              ),
            ].map((value) => {
              return data.receiver.find((obj) => obj.value === value);
            })) ||
          [];
        const values = this.receiver.map((rec) => rec.value) || [];
        this.receiver = this.receiver.concat(
          receiver.filter((rec) => !values.includes(rec && rec.value))
        );
        const receiverCC =
          (data.receiverCC?.length &&
            this.receiverCC?.length &&
            [
              ...new Set(
                data.receiverCC.concat(this.receiverCC).map((obj) => obj.value)
              ),
            ].map((value) => {
              return data.receiverCC.find((obj) => obj.value === value);
            })) ||
          [];
        this.receiverCC = this.receiverCC.concat(receiverCC);
        const receiverBCC =
          (data.receiverBCC &&
            [
              ...new Set(
                data.receiverBCC
                  .concat(this.receiverBCC)
                  .map((obj) => obj.value)
              ),
            ].map((value) => {
              return data.receiverBCC.find((obj) => obj.value === value);
            })) ||
          [];
        this.receiverBCC = this.receiverBCC.concat(receiverBCC);

        if (!this.subject) {
          Vue.set(this, 'subject', data.subject);
        }

        this.passwort = this.hasPasswort && data.passwort;
        this.template = data.template;
        this.dateiname = data.dateiname;
        this.faxNotifyType = data.faxNotifyType;
        this.isSMS = data.isSMS;
        this.briefBackground = data.briefBackground;
        this.briefLandscape = data.briefLandscape;
        this.briefSaveDocArchive = data.briefSaveDocArchive;
        this.briefType = data.briefType;
        this.sendEmail = data.sendEmail;
        this.sendNachricht = data.sendNachricht;
        this.isNotHTML = data.isNotHTML;
        this.userType = data.userType || "TO";
        if (!this.replaceContent) {
          this.setHtmlText(this.splitBodyTemplate(data.getComputedText));
        }
        if (this.linkedObjectSelected && this.linkedObjectSelected.toCompany) {
          // this.html = data.getComputedText;
          Vue.set(this, "html", data.getComputedTex);
          this.handlePreview(true);
        }
      }
    },
    navigateTo(event) {
      this.$router.push({
        path: event?.path ? event.path : event,
        query: {
          singleUser: this.$route.query?.singleUser,
          ...(event?.query ? event.query : {}),
        },
      });
    },
    getTableDataUsers(value) {
      return {
        headers: {
          nummer: {
            label: "Nummer",
            key: "nummer",
            dataType: "String",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
          email: {
            label: this.$route.meta.type !== "FAX" ? "E-Mail" : "Fax",
            key: "email",
            dataType: "String",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
          actions: {
            label: "",
            key: "actions",
            priority: 1,
            dataType: "tableAction",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
        },
        records:
          (this.receiver &&
            this.receiver
              .filter((v) => v)
              .map((v) => ({
                nummer: v.value,
                // name: v.title,
                email: v.label,
              }))) ||
          [],
      };
    },
    handleActionUsers(event) {
      if (!event?.value || !event?.action) {
        return;
      }

      if (event.action?.legend?.key === "DELETE") {
        const index = this.receiver.findIndex(
          (a) => a.value === event?.value?.nummer
        );
        if (index > -1) {
          this.receiver.splice(index, 1);
        }
      }
    },
    deleteFile(row) {
      let allAttachments = (this.attachments && Array.isArray(this.attachments) && this.attachments) || [];
      const index = allAttachments.findIndex((a) => a.id === row.id && a.copyFileId === row.copyFileId);
      if (index > -1) {
        allAttachments.splice(index, 1);
      }
    },
    changeTemplate(value) {
      if (value < 0) {
        return;
      }
      if (this.briefType !== "WORD") {
        this.$store.dispatch(
          MAILCOMPOSER_TYPES.ACTIONS.GET_HTML_TEMPLATE,
          value
        );
      }
      if (this.$route.meta.serienbrief) {
        const option = this.templatesOptions.filter((v) => v.value === value);
        if (option && option[0]) {
          this.subject = option[0].label;
          this.dateiname = option[0].label + ".pdf";
        }
      }
      this.isPreview = false;
    },
    async handleAddActivity(back) {
      if(!back) back = this.$router.currentRoute.fullPath

      //Use preview subject
      const preview = await this.handlePreview(true);

      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_AUFGABE);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {
        activity: true,
      });

      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: "Aufgabe erstellen",
        user: this.userToAppointment,
        subject:  preview?.data?.subject || this.activitySubject,
        isActivity: true,
        mode: "Aufgabe",
        back: back,
      });
      this.$router.push({ path: `/communication/appointment` });
    },
    getSaveMailComposerData() {
      return {
        confidental: this.confidental,
        sender: this.sender,
        receiver: this.receiver,
        subject: this.subject,
        template: this.template,
        dateiname: this.dateiname,
        isSMS: this.isSMS,
        briefBackground: this.briefBackground,
        briefLandscape: this.briefLandscape,
        briefSaveDocArchive: this.briefSaveDocArchive,
        briefType: this.briefType,
        faxNotifyType: this.faxNotifyType,
        getComputedText: this.wrapBodyTemplate(this.html),
        receiverCC: this.receiverCC,
        receiverBCC: this.receiverBCC,
        userType: this.userType,
        type: this.composerId,
        sendEmail: this.sendEmail,
        isNotHTML: this.isNotHTML,
        sendNachricht: this.sendNachricht,
        action: "save",
      };
    },
    saveMailComposer() {
      this.isPresaveMail = true;
      this.$store.commit(
        MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG,
        this.getSaveMailComposerData()
      );
      // this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, this.receiver);
      // this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, this.allAttachments);
    },
    setHtmlText(text) {
      this.splitBodyTemplate(text);
      if (this.$route.params && this.$route.params.content) {
        this.$nextTick(() => {
          Vue.set(this, 'html', this.$route.params.content || "");
        });
        return;
      }
      this.$nextTick(() => {
        if (!this.html) {
          Vue.set(this, 'html', text || "");

        }
      });
    },
    getRoute() {
      if (this.$route.meta.navBack) {
        return this.$route.meta.navBack;
      }
      if (this.customFunctions.getRoute) {
        return this.customFunctions.getRoute();
      }
      if (this.$route.meta.postfach) {
        return "mailcomposer-postfach";
      }
      switch (this.$route.meta.type) {
        case "ONE_MAIL":
          return "mailcomposer";
        case "MANY_MAIL":
          return "mailcomposer-bulk";
        case "APP":
          return "mailcomposer-app";
        case "BRIEF":
          if (this.$route.meta.serienbrief) {
            return "mailcomposer-serienbrief";
          }
          return "mailcomposer-brief";
        case "BRIEF_MAIL":
          return "mailcomposer-brief-email";
        case "FAX":
          return this.$route.name || "mailcomposer-fax";
        default:
          return "mailcomposer";
      }
    },
    handleAddDocuments(type) {
      if (type === "LEGITIMATION" || type === "VOLLMACHT") {
        if (this.vollmacht) {
          this.$emit("fileUpload", type);
        }
      } else {
        this.saveMailComposer();
        this.navigateTo({
          path:
            "/communication/mailcomposer-dokumentenarchiv/" + this.getRoute(),
          query: { back: this.$route.fullPath },
        });
      }
    },
    handleAddUsers(userType) {
      this.userType = userType;
      this.saveMailComposer();
      this.navigateTo({
        path: "/communication/mailcomposer-customer-search/" + this.getRoute(),
        query: { back: this.$route.fullPath },
      });
    },
    setHtml(html) {
      Vue.set(this, "html", html);
      // this.html = html;
    },
    getParticipants(data, sendType) {
      const receiver = [
        ...new Set(
          data
            .filter((v) => v && v.value)
            .map((obj) => `${obj.value}${obj.data?.tempFileId}` || 0)
        ),
      ].map((value) => {
        return data.find(
          (obj) => `${obj.value}${obj.data?.tempFileId}` === value
        );
      });

      return receiver
        .map((v) => {
          const isManualReceiver =
            (v.data && v.data.manual) ||
            this.allwaysManual ||
            (Object.keys(v).length <= 2 && validateEmail(v.value)) ||
            v.value === v.label;
          if (v.value === " ") {
            return {
              ...v,
              label: undefined,
              value: undefined,
            };
          }
          if (this.$route.meta.type === "FAX" && isManualReceiver) {
            return {
              fax: v.value,
              sendType,
            };
          } else if (isManualReceiver) {
            /*
            if(this.cancelInsurance  && this.cancelInsurance.participants &&this.cancelInsurance.participants.length==1  ){
              return ({
              email: v.value === v.label ? (v.email || v.value) : v.value,
              sendType,
              primaryObjectSerialized	:this.cancelInsurance.participants[0].primaryObjectSerialized
            })*
           } */
            return {
              email: v.value === v.label ? v.email || v.value : v.value,
              sendType,
            };
          }
          if (v.primaryObjectSerialized) {
            if (v.name) {
              v.data.name = v.name;
            }
            if (v.email) {
              v.data.email = v.email;
            }
          }
          return {
            ...(!v.primaryObjectSerialized
              ? {
                  ...v.data,
                  email: v.email,
                  user: {
                    type: v.type || "KUNDE",
                    userId: v.value,
                  },
                  primaryObjectSerialized: v.primaryObjectSerialized,
                  sendType,
                }
              : {
                  ...v.data,
                  primaryObjectSerialized:
                    v.primaryObjectSerialized || v.data.primaryObjectSerialized,
                  sendType,
                }),
          };
        })
        .filter((v) => !v.user || (v.user && !isNaN(v.user.userId)))
        .map((v) => {
          const { index, ...props } = v;
          return { ...props };
        });
    },
    getRequestParams(preview = false) {
      const senders = this.senders.filter((v) => v.email === this.sender);
      const sender =
        (senders && senders[0]) || (this.senders && this.senders[0]);

      const participants = this.getParticipants(this.receiver, "TO")
        .concat(this.getParticipants(this.receiverCC, "CC"))
        .concat(this.getParticipants(this.receiverBCC, "BCC"));
      let text = this.wrapBodyTemplate(this.html);

      let result = {
        subject: preview ? this.subject || "" : this.subject,
        text,
        modus: this.emailModus,
        confidential: this.confidental,
        userTypeSender: (sender.user && sender.user.type) || "MAKLER",
        userIdSender: sender.user && sender.user.userId,
        emailSender: sender.email,
        participants:
          (participants.length &&
            participants.map((p) => ({ ...p, ...(p.data ? p.data : {}) }))) ||
          [],
        ...(this.isNotHTML ? { plainTextOnly: true } : {}),
        sendEmail: this.sendEmail,
        sendNachricht: this.sendNachricht,
        ...(this.$route.meta.type === "BRIEF_MAIL"
          ? { briefInput: this.getBriefParams() }
          : {}),
        linkedObject:  this.linkedObjectSelected,
      };
      if(this.allowedSendLater){
        result.dateSend= (this.dateSend? dayjs(this.dateSend).format('DD.MM.YYYY') :null);
        result.timeSend=this.timeSend;

      }
      if (preview) {
        result.linkedObject = this.linkedObject;
      }
      if(this.allowedSendLater&&(result.dateSend==null ||result.dateSend==="" )){
        result.timeSend=null;
        this.timeSend=null;
      }
      return result;
    },
    getPDFData() {
      return {
        confidental: this.confidental,
        sender: this.sender,
        receiver: this.receiver,
        subject: this.subject,
        template: this.template,
        dateiname: this.dateiname,
        isSMS: this.isSMS,
        faxNotifyType: this.faxNotifyType,
        briefBackground: this.briefBackground,
        briefLandscape: this.briefLandscape,
        briefSaveDocArchive: this.briefSaveDocArchive,
        briefType: this.briefType,
        getComputedText: this.wrapBodyTemplate(this.html),
        receiverCC: this.receiverCC,
        receiverBCC: this.receiverBCC,
        userType: this.userType,
        type: this.composerId,
      };
    },
    switchBriefModus(event) {
      this.isPresaveMail = true;
      this.$store.commit(
        MAILCOMPOSER_TYPES.MUTATIONS.SET_PDF,
        this.getPDFData()
      );
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        subject: this.subject,
        sender: this.sender,
        receiver: this.receiver,
      });
      this.setBriefAnhang().then(() => {
        if (event === "FAX") {
          this.navigateTo(
            this.isBroker
              ? "/communication/mailcomposer-fax-send"
              : "/communication/mailcomposer-fax"
          );
        } else {
          if (this.$route.meta.insuranceBrief) {
            this.navigateTo(
              "/communication/mailcomposer-brief-insurance-email"
            );
          } else {
            this.navigateTo("/communication/mailcomposer-brief-email");
          }
        }
      });
    },
    createManualReceiver(value, type = "TO") {
      this.manualReceiverType = type;
      this.$refs.manualReceiverModal.open();
    },
    setManualReceiver() {
      if (this.manualReceiverType === "CC") {
        this.receiverCC.push({
          ...this.manualReceiver,
          label: `${this.manualReceiver.name || ""} ${this.manualReceiver
            .vorname || ""}`,
          value: this.manualReceiver.email,
        });
      } else if (this.manualReceiverType === "BCC") {
        this.receiverBCC.push({
          ...this.manualReceiver,
          label: `${this.manualReceiver.name || ""} ${this.manualReceiver
            .vorname || ""}`,
          value: this.manualReceiver.email,
        });
      } else {
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, [
          this.manualReceiver,
        ]);
        this.receiver.push({
          ...this.manualReceiver,
          label: `${this.manualReceiver.name || ""} ${this.manualReceiver
            .vorname || ""}`,
          value: " ",
        });
      }
    },
    resetManualReceiver() {
      this.manualReceiver = {
        anrede: "Firma / Verein",
      };
    },
    inputReceiver(value) {
      this.receiver = [...value];
      this.isSendDisabled = !this.isAnyEmail;
    },
    changeNotHtml(notHtml) {
      this.isPreview = false;
    },
    convertToText(text, reverse = false) {
      var tempDiv = document.createElement("div");
      tempDiv.innerHTML = text
        .replaceAll("<p", "\n\t<p")
        .replaceAll("<br", "\n\t<br");
      const result = tempDiv.textContent || tempDiv.innerText;
      return result;
    },
    convertPreview(text, plainText) {
      return this.isNotHTML || plainText
        ? (text && text.replaceAll("\n", "<br>")) || ""
        : text;
    },
    wrapBodyTemplate(html) {
      const encodedText =
        `<html><style>${this.stylesTemplate}</style><body>` +
        html +
        "</body></html>";
      return encodedText
        .replaceAll("\t", "")
        .replaceAll("   ;", "")
        .replaceAll("&amp;", "&");
    },
    splitBodyTemplate(html) {
      const elements = (
        (html && html.match(/<style>(.*?)<\/style>/g)) ||
        []
      ).map((val) => {
        return val.replace(/<\/?style>/g, "");
      });

      if (elements) {
        this.stylesTemplate = elements
          .join(" ")
          .replace(/\s*(?!<\")\/\*[^\*]+\*\/(?!\")\s*/g, "")
          .replaceAll("{;", "{");
      }
      return html;
    },
    isReceiveUserClickable(receiveUser) {
      return (
        receiveUser.value === "..." ||
        (receiveUser?.type === KUNDE_TYPE &&
          !!receiveUser?.value?.trim() &&
          receiveUser.value !== this.loginData.userid)
      );
    },
    openReceiveUser(receiveUser) {
      this.openCustomer(receiveUser);
    },
    openCustomer(receiveUser) {
      if (receiveUser?.type !== KUNDE_TYPE && !receiveUser?.value?.trim())
        return;
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
        customerId: receiveUser.value,
      });
    },
    setEmailIntoCustomerReceiver() {
      if (!this.isCustomer) return;

      const index = this.receiver.findIndex(
        (r) => r.value === this.loginData.userid
      );
      if (index >= 0 && !this.receiver[index]?.email) {
        this.$set(this.receiver[index], "email", this.customerEmail);
      }
    },
    handleBeforeRouteLeave(to, from, next) {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        ...this.savedConfiguration,
        replaceContent: false,
      });
      if (!this.isPresaveMail) {
        if (!this.isAlreadySent) {
          this.$store.commit(
            MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG,
            {
              ...this.getSaveMailComposerData(),
              type: this.composerId,
              action: "save-cache",
              replaceContent: false,
            }
          );
        } else {
          this.$store.commit(
            MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT,
            null
          );
          this.$store.commit(
            MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG,
            {
              type: this.composerId,
              action: "clear",
              replaceContent: false,
            }
          );
        }

        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
          type: this.composerId,
          action: this.isAlreadySent ? "clear" : "save-cache",
        });
        if (
          this.$route.meta.type !== "BRIEF_MAIL" ||
          (to && to.meta && !to.meta.modus)
        ) {
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PDF, {
            ...this.getPDFData(),
            type: this.composerId,
            action: this.isAlreadySent ? "clear" : "save-cache",
          });
        } else {
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PDF, {
            ...this.getPDFData(),
            type: this.composerId,
            action: this.isAlreadySent ? "clear" : "save-cache",
          });
        }
      } else {
        this.$addBreadcrumb({
          label: `zurück zur ${this.pageTitle}`,
          to,
          from,
        });
      }
      this.isSendDisabled = false;
      this.isAlreadySent = false;
      this.isPreview = false;
      this.isCriticalSendingError = false;
      next();
    },
    removeAllMarked() {
      if(this.selectedAttachmentDocumentsRow?.length){
        this.selectedAttachmentDocumentsRow.forEach(row => this.deleteFile(row));
        this.selectedAttachmentDocumentsRow.length = 0;
      }
    },
  },
  validators: {
    manualReceiver: {
      fax: [
        telefon("Bitte geben Sie eine gültige Faxnummer ein", {
          emptyAllowed: true,
        }),
      ],
      email: [email(undefined, { emptyAllowed: true })],
    },
  },
  beforeRouteLeave(to, from, next) {
    this.handleBeforeRouteLeave(to, from, next);
  },
};
</script>

<style scoped>
.space-row {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.mail-composer--title-wrap {
  display: flex;
  justify-content: space-between;
}

.d-flex .grow-1:last-child {
  padding-right: 0;
}

.grow-1 {
  flex-grow: 1;
  padding-right: 0.5em;
}

.users-input {
  width: calc(100% - 80px);
}

.no-label {
  padding: 1.5rem 0 0 1rem;
  min-width: 40px;
  box-sizing: border-box;
}

.preview-switch {
  text-align: center;
}

.action-button__container button {
  white-space: nowrap;
}

.w-300 {
  max-width: 300px;
}

.w-100 {
  width: 100%;
}

.nowrap {
  white-space: nowrap;
  padding: 0;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.max-w-100 {
  max-width: 100%;
}

.mr-n8 {
  margin-right: -16px;
}

.f-grow-1 {
  flex-grow: 1;
  flex: 1;
}

.f-nowrap {
  flex-wrap: wrap;
}

.text-nowrap {
  white-space: nowrap;
}

.mr-3 {
  margin-right: 3rem;
}

.text-color-info {
  color: var(--color-info);
}

.participants-container {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.participants-container div:first-child {
  flex: 1 1 400px;
}
.participants-container div.participant-copy-container {
  flex: 0 0 auto;
}
.participant-copy-dropdown {
  padding: 0.5rem;
  flex: 0 0 auto;
}
.align-self-start {
  align-self: flex-start !important;
}

.brief-switches {
  flex-wrap: wrap;
}

@media screen and (max-width: 650px) {
  .brief-switches {
    flex-direction: column;
  }

  .brief-switches > div {
    margin: 0 0 8px;
  }

  .brief-switches > div:last-child {
    margin-bottom: 0;
  }

  .space-row {
    flex-direction: column;
  }
}
</style>
<style>
.mailcomoser-editor .ProseMirror {
  min-height: 15em;
  border: 1px solid;
  border-radius: 4px;
  border-color: var(--color-text);
  padding: 10px;
}

.mailcomoser-editor .ProseMirror p {
  padding: 0;
  margin: 0;
}

.mailcomoser-editor.hidden-editor .ProseMirror {
  display: none;
}

.mailcomoser-editor p {
  min-height: 1rem;
}
.mailcomoser-editor em {
  color: inherit;
}

.upload-btn {
  position: relative;
}

.upload-btn input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.input-forms-p .input-forms__container {
  padding-bottom: 0;
}

.rundMail-switches {
  display: flex; 
  gap: 8px
}

.data-double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: 10%;
  justify-self: stretch;
  column-gap: 0.5em;
  grid-gap: 1rem;
}
</style>
