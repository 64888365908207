import GUBBI_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';

export default {
  [GUBBI_TYPES.ACTIONS.RETRIEVE_GUBBI_ASSETS]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/gubbi/digital_assets';

    axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(GUBBI_TYPES.MUTATIONS.RETRIEVE_GUBBI_ASSETS_SUCCESS, response.data);

      }      

    })
  },

}