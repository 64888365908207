<template>
  
  <div class="">
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Servicegebühr & Depotrabatt" 
      :actions="headerActions" 
      @action-ADD="editSgR(null)"
    />
    <div class="box__container">
      <div class="box__container-rows">
        <Table v-if="rows.length"
            tableId="f0470771-ba6e-49a6-b5d9-7d60142b3cc4"
            :headers="headers"
            :rows="rows"
            rowId="kontonr"
            ignoreTablePageSize
            :rowsPerPage="5"
            :tableRowsPerPage="[]"
            :hidePagination="$isSmallScreen"
            :mobileConfig="mobileTableConfig"
            @action-EDIT="editSgR"
            @action-DELETE="deleteSgR"
        />
        <NoData v-else noIcon />
      </div>
    </div>
    
    <BaseModal
      ref="editSgR"
      modalTitle="Servicegebühr & Rabatt"
      labelButtonConfirm="Speichern"
      :autoClose = false
      @onConfirmButton="saveSgR()"
    >
      <template v-slot:default>
        <ComboBox label="Lagerstelle" v-model="gesellschaftID" 
          :values="gesellschaften" :sortComboboxValues="false" />
        <InputField label="Depotnummer" v-model="kontonr" />
        <InputField label="Servicegebühr" type="percent" v-model="gebuehr" />
        <InputField label="Rabatt" type="percent" v-model="rabatt" />
      </template>
  </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import STECKBRIEF_TYPES from '@/components/steckbrief/store/types';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, PillColumn, SimpleAction} from "@/components/table2/table_util.js";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import BaseModal from "@/components/core/BaseModal.vue";
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import { PhPencil, PhTrash } from 'phosphor-vue';


export default {
  components: {
    BaseModal, NoData, Table, OptionMenu, PageHeaderTitleNavigation, InputField, ComboBox
  },
  data() {
    return {
      headers: {
        lockedLeft: [
          TextColumn("kontonr", "Depotnr").makeAlwaysVisible(),
        ],
        center: [
          TextColumn("gesellschaft", "Lagerstelle"),
          PillColumn("type", "Herkunft"),
          TextColumn("gebuehr", "Servicegebühr"),
          TextColumn("rabatt", "Rabatt"),
        ],
        lockedRight: [
          ActionColumn("actions", "")
        ],
      },
      mobileTableConfig: {
        title: '',
        headers: ['kontonr', 'gesellschaft', 'gebuehr', 'rabatt'],
        disableClickRow: true,
      },
      headerActions: [
        PageHeaderSimpleAction('ADD', 'Neuer Eintrag für Servicegebühr und Rabatt'),
      ],
      editRow: null,
      rabatt: 0,
      gebuehr: 0,
      kontonr: '',
      gesellschaftID: '',
    };
  },
  computed: {
    ...mapGetters({
        info: STECKBRIEF_TYPES.GETTERS.STECKBRIEF_CUSTOMER_INFO,
    }),
    rows() {
      const rows = (this.info?.steckBreifZusatz?.depotsDatenOutput || []).map(row => {
        let min = row.gebuehrProzMin == null ? '' : row.gebuehrProzMin.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' %'
        let max = row.gebuehrProzMax == null ? '' : row.gebuehrProzMax.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' %'
        if (min == max) {
          row.gebuehr = min;
        } else if (min && max) {
          row.gebuehr = min + '-' + max;
        } else {
          row.gebuehr = min || max;
        }
        min = row.rabattMin == null ? '' : row.rabattMin.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' %'
        max = row.rabattMax == null ? '' : row.rabattMax.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' %'
        if (min == max) {
          row.rabatt = min;
        } else if (min && max) {
          row.rabatt = min + '-' + max;
        } else {
          row.rabatt = min || max;
        }
        row.type = {label: 'Import', type: 'info'}
        return row;
      });
      if (this.info?.steckBreifZusatz?.depotGebuehr?.rows?.length) {
        let gs = null;
        this.info.steckBreifZusatz.depotGebuehr.rows.map(row => {
          gs = this.gesellschaften.find( g => g.value == row.gesellschaft);
          rows.push({
            id: row.id,
            gesellschaftID: row.gesellschaft,
            gesellschaft: !gs ? row.gesellschaft : gs.label,
            rabatt: row.rabatt.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' %',
            gebuehr: row.servicegebuehr_proz.toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' %',
            kontonr: row.depotnr,
            type: {label: 'Manuell', type: 'info'},
            actions: [
              SimpleAction('EDIT', PhPencil, 'Bearbeiten'),
              SimpleAction('DELETE', PhTrash, 'Löschen')
            ],
          })
        })
      }
      return rows
    },
    gesellschaften() {
      return [{label: 'Bitte auswählen', value:''}].concat(this.info?.steckBreifZusatz?.depotGebuehr?.gesell || [])
    }
  },
  methods: {
    editSgR(row) {
      this.editRow = row;
      this.gesellschaftID = row ? row.gesellschaftID : '';
      this.kontonr = row ? row.kontonr : '';
      this.rabatt = row ? row.rabatt.replace(/ %/, '') : 0;
      this.gebuehr = row ? row.gebuehr.replace(/ %/, '') : 0;
      this.$refs.editSgR.open();
    },
    saveSgR() {
      if (this.gesellschaftID && this.kontonr && this.gebuehr) {
        const save = {
          gesellschaft: this.gesellschaftID,
          depotnr: this.kontonr,
          servicegebuehr_proz: this.gebuehr,
          rabatt: this.rabatt,
          id: this.editRow?.id
        }
        const param = {};
        if (!this.editRow) {
          param.insert = save;
        } else {
          param.update = save;
        }
        this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.SET_DEPOT_SERVICE_GEB, param);
        this.$refs.editSgR.close();
      }
    },
    async deleteSgR(row) {
      let confirm = true;
      try {
        await this.$confirmModal({
          title: 'Bestätigung',
          message: `Möchten Sie den Eintrag:<br>
          ${row.gesellschaft} | ${row.kontonr} | ${row.gebuehr} | ${row.rabatt}
          <br>löschen?`,
          labelButtonConfirm: 'Ja',
          labelButtonCancel: 'Nein',
        });
      } catch (e) {
        confirm = false;
      }
      if (confirm) {
        this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.SET_DEPOT_SERVICE_GEB, {delete: {id: row.id}});
      }
    },
  },
}
</script>

<!-- <style src='../steckbrief.scss' lang='scss' scoped></style> -->

<style scoped>
.value-range {
  display: flex;
  justify-content: flex-end;
}
.depot-gesellschaft {
  white-space: normal;
}
</style>
