<template>
  <div>
    <BaseFormWithStages
      :pageHeaderActions="actions"
      :pageHeaderActionListeners="pageHeaderActionListeners"
      :cardsData="cardsData"
      :subtitle="processForm.description"
      :loading="loading">
      <template #beforeCards>
        <div class="box__container">
          <div>
            <span class="font-strong">Makler Externer Zugang: </span>
            <span>{{ processForm.maklerExtZugang.bezeichnung }}</span>
          </div>
          <div>
            <span class="font-strong">Vorgangs-Nr.: </span>
            <span>{{ processForm.vorgangsNr }}</span>
          </div>
        </div>
      </template>
      <template #afterCards>
        <div class="box__container">
          <Table
            title="Ereignisse"
            :headers="ereignisseHeaders"
            :rows="ereignisseRows"
            :rowsPerPage="0"
            hidePagination>
          </Table>
        </div>
        <div v-if="processDateCreated">
          {{ processDateCreated }}
        </div>
      </template>
      <template #formContent>
        <div>
          <div class="box__container">
            <div class="box__title process__summary-header">
              <div>Finanzbedarf</div>
              <div>
                <CurrencyLabel class="currency_label_right"
                  label="Gesamtkapitalbedarf"
                  :value="processVorhabenOverview.gesamtkapitalbedarf"/>
              </div>
            </div>
            
          </div>

          <div class="box__container">
            <div class="box__title process__summary-header">
              <div class="text-small">
                Kosten
              </div>
              <div>
                <CurrencyLabel class="currency_label_right"
                  label="Kosten"
                  :value="processVorhabenOverview.kosten"/>
              </div>
            </div>

            <div class="row">
              <div class="col-2">
                <CurrencyLabel
                  label="Kaufpreis"
                  :value="processVorhabenOverview.kaufpreis"/>
              </div>
              <div class="col-2">
                <CurrencyLabel
                  label="Grunderwerbsteuer"
                  :value="processVorhabenOverview.grunderwerbsteuer"/>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <CurrencyLabel
                  label="Mobiliarkosten"
                  :value="processVorhabenOverview.mobiliarkosten"/>
              </div>
              <div class="col-2">
                <CurrencyLabel
                  label="Notargebühren"
                  :value="processVorhabenOverview.notargebuehr"/>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <CurrencyLabel
                  label="Sonstige Kosten"
                  :value="processVorhabenOverview.sonstigeKosten"/>
              </div>
              <div class="col-2">
                <CurrencyLabel
                  label="Maklergebühren"
                  :value="processVorhabenOverview.maklergebuehr"/>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <CurrencyLabel
                  label="Modernisierungskosten"
                  :value="processVorhabenOverview.modernisierungKosten"/>
              </div>
              <div class="col-2">
                <CurrencyLabel
                  label="Renovierungskosten"
                  :value="processVorhabenOverview.modernisierungRenovierungsKosten"/>
              </div>
            </div>
          </div>

          <div class="box__container">
            <div class="box__title process__summary-header">
              <div class="text-small">
                Finanzierungskosten
              </div>
              <div>
                <CurrencyLabel class="currency_label_right"
                  label="Beratungshonorar"
                  :value="processVorhabenOverview.beratungshonorar"/>
              </div>
            </div>

            <div class="row">
              <div class="col-2">
                <CurrencyLabel
                  label="Beratungshonorar"
                  :value="processVorhabenOverview.beratungshonorar"/>
              </div>
            </div>
          </div>

          <div class="box__container">
            <div class="box__title process__summary-header">
              <div>Eingesetztes Eigenkapital, Vermögen und Drittdarlehen</div>
              <div>
                <CurrencyLabel class="currency_label_right"
                  label="Eingesetztes Kapital"
                  :value="processVorhabenOverview.eingesetztesKapital"/>
              </div>
            </div>
          </div>

          <div class="box__container">
            <div class="box__title process__summary-header">
              <div>Präferenzen</div>
            </div>
          </div>

          <div class="box__container">
            <div class="box__title process__summary-header">
              <div>Finanzierungswunsch</div>
              <div>
                <CurrencyLabel class="currency_label_right"
                  label="Noch benötigte Darlehen"
                  :value="processVorhabenOverview.nochBenoetigteDarlehen"/>
              </div>
            </div>
          </div>
        </div>
      </template>
    </BaseFormWithStages>

    <BaseModal
      ref="modalExportProcess"
      modalTitle="Vorgang exportieren"
      labelButtonConfirm="exportieren"
      @click="exportProcess">

      <ComboBox
        label="Vorgang exportieren nach"
        v-model="exportMaklerExtZugangId"
        :values="maklerExtZugangList"
      />
    </BaseModal>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormUtil from '@/components/beratung/formsMenu/forms-menu.js';
import CORE_TYPES from '@/store/core/types'
import ANTRAG_TYPES from "@/store/antrag/types";
import { sanitize } from '@/helpers/string-helper.js';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { TextColumn, DateTimeColumn } from "@/components/table2/table_util.js";
import BaseFormWithStages from '@/components/base/BaseFormWithStages.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import Table from "@/components/table2/Table.vue";
import PROCESS_TYPES from '@/store/process/types';
import dayjs from "dayjs";
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";
import { buildMessage } from "@/helpers/log-message-helper";
import LOG_TYPES from "@/store/log/types";
import { openLink } from '@/link-resolvers';
import { ResolverId } from "@/link-resolvers/types";
import ComboBox from "@/components/core/forms/ComboBox.vue";

export default {
  data: function () {
    return {
      bemerkung: null,
      lagerstelle: null,
      exportMaklerExtZugangId: null,
      edited: false,
      processForm: {
        description: 'Neu Vorgang',
        maklerExtZugang: {
          id: null,
        },
        processItems: [],
      },
      loading: {
        getSend: false,
        getPdf: false,
        getSign: false,
        close: false,
        open: false,
        send: false,
        pdf: false,
        init: false,
        action: false,
        antraegeList: false,
      },
    };
  },
  components: {
    BaseFormWithStages,
    BaseModal,
    Table,
    CurrencyLabel,
    ComboBox,
  },
  watch: {
    process() {
      Object.assign(this.processForm, this.process)
    },
  },
  computed: {
    ...mapGetters({
      antragList: ANTRAG_TYPES.GETTERS.ANTRAG_LIST_PROCESS_FORMULARE,
      addressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      process: PROCESS_TYPES.GETTERS.PROCESS,
      processVorhabenOverview: PROCESS_TYPES.GETTERS.PROCESS_VORHABEN_OVERVIEW,
      maklerExtZugangList: PROCESS_TYPES.GETTERS.ZUGANG_LIST,
    }),
    processDateCreated() {
      if (!this.process?.dateCreated) {
        return "";
      }

      return `Erstellungsdatum: ${dayjs(this.process.dateCreated).format('DD.MM.YYYY')}`;
    },
    pageHeaderActionListeners() {
      return {
        'action-EXPORT_PROCESS' : this.openExportProcess,
        'action-SYNCHRONIZE_PROCESS' : this.synchronizeProcess,
        'action-OPEN_PROCESS_IN_EUROPACE': this.openProcessInEuropace,
      }
    },
    actions() {
      return [
        PageHeaderSimpleAction('EXPORT_PROCESS', 'Vorgang exportieren')
            .withDisabled(() => this.isSomeButtonLoading),
        PageHeaderSimpleAction('SYNCHRONIZE_PROCESS','Synchronisieren')
            .withDisabled(() => this.isSomeButtonLoading),
        PageHeaderSimpleAction('OPEN_PROCESS_IN_EUROPACE', 'Vorgang in Europace öffnen')
            .withDisabled(() => this.isSomeButtonLoading),
      ]
    },
    cardsData() {
      const cards = [];

      const finanzierung_antragsteller = this.formList.find(item => item.path === 'finanzierung_antragsteller');
      if (finanzierung_antragsteller) {
        const antrag = this.antragRows?.find(antrag => antrag.positionTyp === 'EUROPACE2_FINANZIERUNG_ANTRAGSTELLER');

        const card = {
          key: finanzierung_antragsteller.path,
          description: '',
          label: finanzierung_antragsteller.label,
          status: 'none',
          onClick: () => {
            if (antrag) {
              this.openExistingAntrag(antrag);
            } else {
              this.openNewAntrag(finanzierung_antragsteller)
            }
          },
          actionText: antrag ? 'anzeigen' : 'hinzufügen',
        };

        cards.push(card);
      }

      const finanzierung_immobilie = this.formList.find(item => item.path === 'finanzierung_immobilie');
      if (finanzierung_immobilie) {
        const antrag = this.antragRows?.find(antrag => antrag.positionTyp === 'EUROPACE2_FINANZIERUNG_IMMOBILIE');
        const card = {
          key: finanzierung_immobilie.path,
          description: '',
          label: finanzierung_immobilie.label,
          status: 'none',
          onClick: () => {
            if (antrag) {
              this.openExistingAntrag(antrag);
            } else {
              this.openNewAntrag(finanzierung_immobilie)
            }
          },
          actionText: antrag ? 'anzeigen' : 'hinzufügen',
        };

        cards.push(card);
      }

      const finanzierung_vorhaben = this.formList.find(item => item.path === 'finanzierung_vorhaben');
      if (finanzierung_vorhaben) {
        const antrag = this.antragRows?.find(antrag => antrag.positionTyp === 'EUROPACE2_FINANZIERUNG_VORHABEN');
        const card = {
          key: finanzierung_vorhaben.path,
          description: '',
          label: finanzierung_vorhaben.label,
          status: 'none',
          onClick: () => {
            if (antrag) {
              this.openExistingAntrag(antrag);
            } else {
              this.openNewAntrag(finanzierung_vorhaben)
            }
          },
          actionText: antrag ? 'anzeigen' : 'hinzufügen',
        };

        cards.push(card);
      }

      return cards;
    },

    isSomeButtonLoading() {
      return Object.values(this.loading).some(valueLoading => valueLoading)
    },
    selectedLagerstelle() {
      return this.antragList?.formGroups?.find(item => item.id === 'EUROPACE');
    },
    formList() {
      if (!this.selectedLagerstelle) {
        return [];
      }

      return FormUtil.getForms(this.selectedLagerstelle, { processId: this.$route.query.id });
    },
    antragRows() {
      return this.processForm?.processItems || []
    },
    ereignisseRows() {
      if (!this.processVorhabenOverview?.baufiEreignisse?.length) {
        return [];
      }
      return this.processVorhabenOverview?.baufiEreignisse;
    },
    ereignisseHeaders() {
      return {
        lockedLeft: [
          DateTimeColumn("zeitpunkt", "Ereignis"),
          TextColumn("meldung", "Meldung"),
        ],
        center: [
          TextColumn("typ", "Typ"),
          TextColumn("aktivitaet", "Aktivität"),
        ]
      }
    }
  },
  methods: {
    openExportProcess() {
      this.$refs.modalExportProcess.open()
    },
    openProcessInEuropace() {
      if (!this.processForm.vorgangsNr || !this.processForm.id) {
        const message = buildMessage('Der Vorgang hat keinen Vorgangs-Nr', 'danger');
        return this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, message);
      }
      openLink(ResolverId.Europace2Vorgang, { processId: this.processForm.id });
    },
    async synchronizeProcess() {
      if (!this.processForm.vorgangsNr || !this.processForm.id) {
        const message = buildMessage('Der Vorgang hat keinen Vorgangs-Nr', 'danger');
        return this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, message);
      }

      const success = await this.$store.dispatch(PROCESS_TYPES.ACTIONS.SYNCHRONIZE_PROCESS, this.processForm.id);

      if (success) {
        const message = buildMessage('Der Vorgang wurde erfolgreich synchronisiert', 'success');
        return this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, message);
      }
    },
    exportProcess() {
      if (!this.exportMaklerExtZugangId) {
        const message = buildMessage('Ungültiger Zugang', 'danger');
        return this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, message);
      }

      const payload = {
        processId: this.processForm.id,
        maklerExtZugangId: this.exportMaklerExtZugangId,
      }

      return this.$store.dispatch(PROCESS_TYPES.ACTIONS.EXPORT_PROCESS_TO, payload);
    },
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    async update() {
      if (!this.edited) {
        return;
      }
      if (this.processForm.id) {
        await this.$store.dispatch(PROCESS_TYPES.ACTIONS.UPDATE_PROCESS, this.processForm);
      }
    },
    async retrieveAntragList() {
      this.loading.antraegeList = true;
      const params = {
        lagerstelle: 'EUROPACE',
      };

      try {
        await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_FOR_PROCESS, params);
      } finally {
        this.loading.antraegeList = false
      }
    },
    async loadProcessData() {
      this.$store.commit(PROCESS_TYPES.MUTATIONS.GET_PROCESS_SUCCESS, {});
      const processId = this.$route.query.id;

      if (processId) {
        this.loading.init = true
        try {
          await Promise.all([
            this.$store.dispatch(PROCESS_TYPES.ACTIONS.GET_PROCESS, processId),
            this.$store.dispatch(PROCESS_TYPES.ACTIONS.GET_PROCESS_VORHABEN_OVERVIEW, processId),
          ]);
        } finally {
          this.loading.init = false
        }
      }
    },
    async loadAuxiliarData() {
      await Promise.all([
        this.$store.dispatch(PROCESS_TYPES.ACTIONS.GET_ZUGANG_LIST)
      ])
    },
    loadInitialData() {
      this.loadAuxiliarData();
      this.loadProcessData();
      this.retrieveAntragList();
    },
    async openExistingAntrag(row) {
      const antrag = {
        bezeichnung: row.antragBezeichnung,
        gesellschaft: row.gesellschaft, //'EUROPACE', //row.GesellschaftShort,
        antragsdatenId: row.antragsDatenId,
        hideBeratungsmappepopup: 'true',
        processId: this.$route.query.id || this.process.id,
        antragModus: "",
      }

      const link = FormUtil.getLoadURL(antrag, [this.selectedLagerstelle]);

      if (link) {
        this.$router.push({path: link}).catch(() => {});
      } else {
        this.$router.push({path: '/role-forbidden'});
      }
    },
    async openNewAntrag(row) {
      const antrag = row.link;
      if (!antrag) {
        return;
      }
      if (!antrag.query) {
        antrag.query = {}
      }

      antrag.query['hideBeratungsmappepopup'] = 'true';
      antrag.query['processId'] = this.$route.query.id || this.process.id;
      this.$router.push(antrag)
    },

    async deleteAntraegeConfirmation(row) {
      try {
        await this.$confirmModal({
          title: 'Antrag löschen?',
          message: `Möchten Sie diesen Antrag löschen?`,
        });
        await this.$store.dispatch(PROCESS_TYPES.ACTIONS.DELETE_PROCESS_ITEM, row.id);
        this.loadProcessData();
      } catch(e) {
        // empty block
      }
    },
  },
  mounted() {
    this.loadInitialData();
  },
  async beforeRouteLeave(to, from, next) {
    this.$store.commit(ANTRAG_TYPES.MUTATIONS.RETRIEVE_ANTRAG_LIST_SUCCESS, {});
    await this.update();

    this.$addBreadcrumb({
      label: 'zurück zur Beratungsmappe', 
      to,
      from,
    });

    next()
  },
}
</script>

<style scoped>
.process__summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.currency_label_right {
  align-items: end;
}
</style>