var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.isVisible && _vm.withBackdropLayer
          ? _c("div", { staticClass: "popup__backdrop" })
          : _vm._e()
      ]),
      _vm.isVisible
        ? _c(
            "div",
            {
              ref: "popup",
              class: ["popup", { popup__minimized: this.isMinimized }],
              style: {
                zIndex: _vm.zIndex,
                top: _vm.top + "px",
                left: _vm.left + "px"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "header",
                  attrs: { "data-draggable": "true" },
                  on: {
                    mousedown: _vm.startDrag,
                    touchstart: _vm.handleTouchStart
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "modal__title",
                      attrs: { "data-draggable": "true" }
                    },
                    [_vm._v(_vm._s(_vm.clampedModalTitle))]
                  ),
                  _c("div", { staticClass: "header_buttons" }, [
                    _vm.isMinimized == false
                      ? _c(
                          "button",
                          {
                            staticClass: "base-modal__header--button clickable",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.minimizePopup()
                              }
                            }
                          },
                          [
                            _c("PhMinus", {
                              attrs: {
                                size: 24,
                                alt: "Icon zum Minimieren der Vorschau"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isMinimized == true || _vm.isMaximized == true
                      ? _c(
                          "button",
                          {
                            staticClass: "base-modal__header--button clickable",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.restorePopup()
                              }
                            }
                          },
                          [
                            _c("PhCards", {
                              attrs: {
                                size: 24,
                                alt: "Icon zum Ausklappen der Vorschau"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isMaximized
                      ? _c(
                          "button",
                          {
                            staticClass: "base-modal__header--button clickable",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.maximizePopup()
                              }
                            }
                          },
                          [
                            _c("PhSquare", {
                              attrs: {
                                size: 24,
                                alt: "Icon zum Maximieren der Vorschau"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "base-modal__header--button clickable",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.closePopup()
                          }
                        }
                      },
                      [
                        _c("PhXCircle", {
                          attrs: {
                            size: 24,
                            alt: "Icon zum Schließen der Vorschau"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              !_vm.isToolbarEmpty
                ? _c(
                    "div",
                    { staticClass: "toolbar" },
                    [
                      _vm._t("toolbar-buttons"),
                      _c(
                        "div",
                        { staticClass: "toolbar-buttons-left" },
                        [_vm._t("toolbar-buttons-left")],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "toolbar-buttons-center" },
                        [_vm._t("toolbar-buttons-center")],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "toolbar-buttons-right" },
                        [_vm._t("toolbar-buttons-right")],
                        2
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "content", on: { scroll: _vm.handleScroll } },
                [_vm._t("default")],
                2
              ),
              _vm.isSafariOniOS && !_vm.isMinimized
                ? _c("div", {
                    staticClass: "resizable-handle",
                    on: {
                      touchstart: _vm.startResizing,
                      touchmove: _vm.resize,
                      touchend: _vm.stopResizing
                    }
                  })
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }