<template>
  <ResizableModal 
    ref="resizablePopup" 
    :modalTitle="modalTitle" 
    :initialWidth="initialWidth"
    :initialHeight="initialHeight"
    :sizeIsPercentage="sizeIsPercentage"
    :startPosition="startPosition"
    :fullClientHeight="fullClientHeight"
    :saveLastPosition="saveLastPosition"
    :withBackdropLayer="withBackdropLayer"
    :id="id"
    @onMinimize="resizePDFContainer" 
    @onMaximize="resizePDFContainer" 
    @onResize="resizePDFContainer">
      <div class="object-container"  ref="objectContainer" :class="{ 'ios-style': isIOS }">
          <object v-if="pdfUrl" :data="pdfUrl" type="application/pdf" width="100%" height="100%" :class="{ 'ios-style': isIOS }">
            This browser does not support PDFs. Please download the PDF to view it: <a :href="pdfUrl">Download PDF</a>.
          </object>
       </div>
  </ResizableModal> 
</template>

<script>
import ResizableModal from '@/components/core/ResizableModal.vue';

export default {
name: 'PreviewPDF',
components: {
  ResizableModal,
},  
props: {
  modalTitle: {
    default: 'PDF-Vorschau'
  },
  initialHeight: {
    type: Number,
    default: 400, 
  },
  initialWidth: {
    type: Number,
    default: 600, 
  }, 
  pdfUrl: {
    type: String,
  },
  sizeIsPercentage: {
    type: Boolean,
    default: false, 
  },
  fullClientHeight: {
    type: Boolean,
    default: false, 
  },    
  startPosition: {
    type: String,
    default: 'TOP_LEFT',
    validator(value) {
      return ['TOP_RIGHT', 'TOP_LEFT', 'BOTTOM_RIGHT', 'BOTTOM_LEFT'].includes(value);
    }
  },
  saveLastPosition: {
      type: Boolean,
      default: true, 
    },   
  id: {
    type: String,
    required: true,
    validator: (value) => {
      const notEmpty = !!value && !!value.trim();
      return notEmpty;
    },
  },
  withBackdropLayer: {
      type: Boolean,
      default: false, 
    },                      
},  
computed: {
  isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    }  
},
data() {
  return { };
},

methods: {
  resizePDFContainer(size) {
    let pdfContainer = document.querySelector('.object-container');
    pdfContainer.style.setProperty("min-height", `${size?.height - 80}px`, 'important');
  },
  showPopup() {
    this.$refs.resizablePopup.showPopup();
  },
},
};
</script>

<style scoped>

.object-container {
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  padding-top: 56.25%; /* Aspect ratio (example: 16:9) */
  overflow: auto;
}

.object-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.ios-style object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  border: none;
}


.ios-style {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

</style>
