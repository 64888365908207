import router from '@/router';
import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig';
import { stripTags } from '@/helpers/commonfunctions';

export const MAKLER_STRUKTUR_LEVELS = [
  FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR, 
  FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR,
  FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN,
];

const SPECIAL_CHAR_PATTERN = /&shy;/gi;

export function sortByName(om1, om2) {
  if(!om1.label || !om2.label) return 1;
  return stripTags(om1.label).toLowerCase().localeCompare(stripTags(om2.label).toLowerCase());
}

export function filterByText(text) {
  return menu => !text || stripTags(menu?.label)?.replace(SPECIAL_CHAR_PATTERN, '')?.toLowerCase()?.includes(text.toLowerCase());
}

export function findConfiguredMenuActives(configuredMenu) {
  if(!configuredMenu?.length) return [];
  return configuredMenu.filter((cm) => cm.removed !== true);
}

export function findDefaultMenuActives(defaultMenu) {
  if(!defaultMenu?.length) return [];
  return defaultMenu.filter((cm) => cm.isDefaultMenu !== false); // TODO refactor "isDefaultMenu" flag
}

function findDefaultMenuNeverConfigured(defaultMenu, configuredMenu) {
  if(!defaultMenu?.length) return [];
  return findDefaultMenuActives(defaultMenu).filter((dm) => {
    return !configuredMenu || !configuredMenu.some((cm) => cm.id == dm.id && cm.path === dm.path);
  });
}

export function contentToSave(menus, userLevel) {
  if(!menus) {
    return '';
  }

  const menusToSave = [...menus].map((menu) => {
    const menuToSave = {
      id: menu.id,
      path: menu.path,
      label: menu.label,
      icon: menu.icon,
      disabled: menu.disabled,
      redirect: getRedirectPath(menu),
      removed: menu.removed,
      ...(MAKLER_STRUKTUR_LEVELS.includes(userLevel) ? { locked: menu.locked, } : {}), // locked is only applied on MAKLER_STRUKTUR levels
      ...(MAKLER_STRUKTUR_LEVELS.includes(userLevel) ? { removeable: menu.removeable, } : {}), // removeable is only applied on MAKLER_STRUKTUR levels
    };
    return menuToSave;
  });
  return JSON.stringify(menusToSave);
}

export function getRedirectPath(menu) {
  if('redirect' in menu) {
    const matchedRoute = router.matcher.match(menu.path);
    return matchedRoute?.fullPath || '';
  }
  return undefined;
}

export function permissionConfigToSave(permissionConfig) {
  if (!permissionConfig) {
    return '';
  }

  const configToSave = Object.keys(permissionConfig)
    .filter(key => permissionConfig[key].visible === false) // only not allowed
    .reduce((acc, key) => ({
      ...acc,
      [key]: {
        visible: permissionConfig[key].visible,
      },
    }), {});

  return JSON.stringify(configToSave);
}

function replaceAsyncMenuByResolvedMenu(optionMenu, configuredMenu) {
  if (!configuredMenu) return configuredMenu;

  return configuredMenu.flatMap((menu) => {
    const resolvedMenu = optionMenu.filter(om => om.resolvedFrom?.path === menu.path);
    return resolvedMenu.length > 0 
      ? resolvedMenu.map(m => ({ ...m, removed: menu.removed }))
      : [menu];
  });
}

/**
 * Normalizes the configuredMenu looking for divergence in defaultMenu and optionMenu
 * 
 * @param {Array} optionMenu 
 * @param {Array} defaultMenu 
 * @param {Array} configuredMenu 
 * @returns 
 */
export function normalizeMenu(optionMenu, defaultMenu, configuredMenu) {
  const configuredMenuInner = replaceAsyncMenuByResolvedMenu(optionMenu, configuredMenu);
  const defaultNeverConfigured = findDefaultMenuNeverConfigured(defaultMenu, configuredMenuInner);
  const configuredMenuTemp = (configuredMenuInner ?? []).concat(defaultNeverConfigured);
  const actives = configuredMenuTemp.filter(menu => menu.removed !== true);
  const removed = configuredMenuTemp.filter(menu => menu.removed === true).sort(sortByName);
  const configuredMenuIntern = [
    ...actives,
    ...removed, // keep removed at the end
  ];

  if(!configuredMenuIntern?.length) {
    return [];
  }

  return removeInvalidMenu([...configuredMenuIntern].map((cm) => {
    // Try to get in optionMenu
    let menu = optionMenu?.find((om) => om.id == cm.id && om.path === cm.path);

    if(!menu) {
      // Try to get in defaultMenu
      menu = defaultMenu?.find((dm) => dm.id == cm.id && dm.path === cm.path);
    }

    // If the menu is not in optionMenu or defaultMenu it was removed or it is not allowed
    if(!menu) {
      return null;
    }

    return Object.assign({}, cm, menu);
  }));
}

function removeInvalidMenu(configuredMenu) {
  return configuredMenu.filter((cm) => cm != null)
}
