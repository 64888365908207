import A_C from './types';

import axios from 'axios';

const config = {
  defaultSpinner: true,
  cancelableRequest: {
    title: 'AntragCheck'
  }
};
export default {

  async [A_C.ACTIONS.SETUP]({ commit,rootState,state }, e) {
   

    commit(A_C.MUTATIONS.RESET_FILTER);
    
    await axios.get(rootState.core.apiAddress + '/fondsantragcheck/Setup',config).then(response => {
      
    
      commit(A_C.MUTATIONS.SETUP, response.data);
    
     
    })
  },
  
  async [A_C.ACTIONS.INFO]({ commit,rootState,state }, id) {
   

    commit(A_C.MUTATIONS.INFO, null);
    
    await axios.get(rootState.core.apiAddress + '/fondsantragcheck/getInfo/'+id,config).then(response => {
      
    
      commit(A_C.MUTATIONS.INFO, response.data);
    
     
    })
  },

  async [A_C.ACTIONS.FILTER]({ commit,rootState,state }, e) {    
    
    await axios.get(rootState.core.apiAddress + '/fondsantragcheck/filter', {
      params: e,
      ...config
    }).then(response => {
      commit(A_C.MUTATIONS.FILTER, {...response.data, page: e.page});
    })
  },

  async [A_C.ACTIONS.SET_STATUS]({ commit,rootState,getters, state }, e) {
    const config = {
      defaultSpinner: true,
    };

    await axios.post(rootState.core.apiAddress + '/fondsantragcheck/setStatus?antragCheckId='+e.antragCheckId +'&status='+e.status,config).then(response => {
      commit(A_C.MUTATIONS.UPDATE_ROW, response.data);
    })
  }


 

  
}


