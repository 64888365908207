export const MUTATION_PREFIX = 'GUBBI_MUTATIONS_';
export const ACTIONS_PREFIX = 'GUBBI_ACTIONS_';
export const GETTERS_PREFIX = 'GUBBI_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_GUBBI_ASSETS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_GUBBI_ASSETS_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    RETRIEVE_GUBBI_ASSETS: ACTIONS_PREFIX + 'RETRIEVE_GUBBI_ASSETS',
  },
  GETTERS: {
    GUBBI_ASSETS: GETTERS_PREFIX + 'GUBBI_ASSETS',
  }
}