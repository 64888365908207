var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "banksapiwebcomponentmodal",
      attrs: {
        showDefaultButtons: false,
        modalTitle: _vm.modalTitle,
        size: "lg",
        esc: false,
        showCloseButton: false
      },
      on: {
        opened: function($event) {
          return _vm.injectUrl()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "bareg__container" },
        [
          _c("ba-regprotect", {
            ref: "baRegElement",
            on: { subscribed: _vm.handleEvent }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }