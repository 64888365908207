<template>
  <div class="layout__negative-margin--8 antrag-action-button__container">
    <DownloadLink :href="getHilfeLinkMaker('de')" asButton>
      Ausfüllhilfe (Deutsch) herunterladen
    </DownloadLink>

    <DownloadLink :href="getHilfeLinkMaker('eng')" asButton>
      Vollständiges Formular (Englisch) herunterladen
    </DownloadLink>
  </div>
</template>

<script>
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import { downloadLinkMaker } from '@/helpers/utils-helper';

export default {
  components: {
    DownloadLink,
  },
  methods: {
    getHilfeLinkMaker(language) {
      return downloadLinkMaker(this.$store.getters, '/gethilfe/generatePDF', null, { language });
    },
  },
};
</script>

<style scoped>
</style>
