import axios from 'axios';

import { ResolverId } from '../types';
import { createLinkResolver, wrapUrl, wrapError } from '../utils';

export default createLinkResolver(ResolverId.DatenschutzURL, async function({ apiAddress }) {
  const findURL = async () => {
    try {
      return await axios.get(`${apiAddress}/linkResolver/datenschutzURL`, { defaultSpinner: true });
    } catch(e) {
      return e.response;
    }
  };

  const response = await findURL();
  if(response?.data?.url) {
    return wrapUrl(response?.data?.url);
  } else if(response?.data?.message) {
    return wrapError(response?.data?.message);
  }
});
