<template>
  <div v-if="customerDataEdited">
    <Table
      v-if="rows.length"
      ref="table"
      :tableId="tableId"
      cardView
      :headers="headers"
      :rows="rows"
      rowId="id"
      hidePagination
      :headersSize="isCustomerOnly ? 0 : 5"
      showWeiterLink
      @onNavigate="navigateToCard"
    >
    </Table>
    <NoData v-else noIcon />
  </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from "phosphor-vue";
import { TextColumn, CurrencyColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import NoData from '@/components/core/NoData.vue';

export default {
  components: {
    BoxContainer,
    PhPencilLine,
    PhBookOpen,
    Table,
    NoData,
  },
  mixins: [persoenlicheDatenMixin],
  data: function () {
    return {
      title: "Stammdaten",
      personalDataAddress: {
        anredePronomen: "",
        title: "",
        firstName: "",
        lastName: "",
        street: "",
        zip: "",
        city: "",
        country: "",
        personalStatus: "",
        co: "",
        state: "",
        firmenzusatz: "",
        briefanredeDepot: "",
        briefanredePerson: "",
        personDeceased: false,
        dateOfDeath: null,
        interneBemerkung: "",
        category: "",
        interested: "",
        categoryAutoLock: "",
        automaticUpdated: true,
        dateCreated: null,
        synchronisation: false,
        risikolevel: "",
        isRisikolevelManuel: false,
        hintRisikolevel: "",
      },
      relationship: {
        relation: null,
        isLegalAgent: false,
        isExtraDepotOwner: false,
        isMandatory: false,
      },
      kundenReferenzNummer: "",
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    headers() {
      const result = {
        center: [],
      };

      if (this.hatSyncUser) {
        result.center.push(TextColumn("synchronisation", "Synchronisation"));
      }

      if (this.isMainPerson && this.isByPass) {
        result.center.push(
          TextColumn(
            "automaticUpdated",
            "Kundendaten werden durch automatische Updates angepasst / überschrieben"
          )
        );
      }

      if (this.isIntern && this.showRisikolevel) {
        result.center.push(
          TextColumn(
            "isRisikolevelManuel",
            "Risikoeinstufung: manuelle Zuweisung"
          )
        );
      }

      if (this.showRisikolevel) {
        result.center.push(TextColumn("risikolevel", "Risikoeinstufung (GWG)"));
      }

      if (this.isByPass) {
        result.center.push(TextColumn("anredePronomen", "Anredeform"));
      }

      result.center.push(TextColumn("title", "Anrede"));

      if (this.hasFirstName) {
        result.center.push(TextColumn("firstName", "Ansprechpartner Vorname"));
      }

      result.center.push(TextColumn("lastName", "Firmenname"));

      result.center.push(TextColumn("firmenzusatz", "Firmenzusatz"));

      result.center.push(
        TextColumn("kundenReferenzNummer", "Kundenreferenznr.")
      );

      if (this.isByPass) {
        if (this.isMainPerson) {
          result.center.push(
            TextColumn("briefanredeDepot", "Briefanrede Depot")
          );
        }

        result.center.push(
          TextColumn("briefanredePerson", "Briefanrede Person")
        );

        if (this.isMainPerson) {
          result.center.push(TextColumn("category", "Kundenkategorie"));

          if (this.personalDataAddress?.dateCreated) {
            result.center.push(
              TextColumn("dateCreated", "Anlagedatum des Kunden")
            );
          }

          result.center.push(TextColumn("interested", "Interessent"));

          result.center.push(
            TextColumn("categoryAutoLock", "automatische Anpassung sperren")
          );

          result.center.push(
            TextColumn(
              "interneBemerkung",
              "Bemerkung (für Kunden nicht sichtbar)"
            )
          );
        }
      }

      return result;
    },
    rows() {
      const rows = [];

      const row = {};

      if (this.hatSyncUser) {
        row.synchronisation = this.personalDataAddress?.synchronisation
          ? "Ja"
          : "Nein";
      }

      row.automaticUpdated = this.personalDataAddress?.automaticUpdated
        ? "Ja"
        : "Nein";

      if (this.isIntern && this.showRisikolevel) {
        row.isRisikolevelManuel = this.personalDataAddress?.isRisikolevelManuel
          ? "Ja"
          : "Nein";
      }

      if (this.showRisikolevel) {
        row.risikolevel = this.personalDataAddress?.risikolevel;
      }

      if (this.isByPass) {
        row.anredePronomen = this.friendlyAnredePronomen;
      }

      row.title = this.personalDataAddress?.title || "-";

      if (this.hasFirstName) {
        row.firstName = this.personalDataAddress?.firstName || "-";
      }

      row.lastName = this.personalDataAddress?.lastName || "-";

      row.firmenzusatz = this.personalDataAddress?.firmenzusatz || "-";

      row.kundenReferenzNummer = this.kundenReferenzNummer || "-";

      if (this.isByPass) {
        if (this.isMainPerson) {
          row.briefanredeDepot =
            this.personalDataAddress?.briefanredeDepot || "-";
        }

        row.briefanredePerson =
          this.personalDataAddress?.briefanredePerson || "-";

        if (this.isMainPerson) {
          row.category = this.personalDataAddress?.category || "-";

          if (this.personalDataAddress?.dateCreated) {
            row.dateCreated = this.personalDataAddress?.dateCreated;
          }

          row.interested =
            this.personalDataAddress?.interested === "1" ? "Ja" : "Nein";
          row.categoryAutoLock =
            this.personalDataAddress?.categoryAutoLock === "1" ? "Ja" : "Nein";

          row.interneBemerkung = this.personalDataAddress?.interneBemerkung;
        }
      }

      rows.push(row);
      return rows;
    },
    tableId() {
      const uniqueUUID = "84a5148d-bd04-4635-ac97-56bb74490137";
      return uniqueUUID;
    },
  },
  methods: {
    navigateToCard(row) {
      this.$router.push(`/persoenlichedaten/customer-data/steps/stammdaten`);
    },
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    },
    // updateGroupIntoData(groupName) {
    //     Object.keys(this[groupName]).forEach(fieldName => {
    //         if (this.customerDataEdited && this.customerDataEdited[groupName] && fieldName in this.customerDataEdited[groupName]) {
    //         this[groupName][fieldName] = this.customerDataEdited[groupName][fieldName]
    //         } else if (this.customerData && this.customerData[groupName] && fieldName in this.customerData[groupName]) {
    //         this[groupName][fieldName] = this.customerData[groupName][fieldName]
    //         }
    //     })
    // },
    updateGroupsIntoData() {
      this.updateGroupIntoData("personalDataAddress");
      this.updateGroupIntoData("relationship");
      this.updateFieldIntoData("kundenReferenzNummer");
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>