<template>
  <div>

    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu" >
    </OptionMenu>

    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :id="$appNavigation.currentOptionMenuId"
      :actions="actions"
      @action-NEW="chooseGoal()">
    </PageHeaderTitleNavigation>

    <div>
      <div class="box__container">

        <Table v-if="rows.length"
          tableId="myGoalList"
          :title="TABLE_TITLE"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="10"
          :mobileConfig="{}"
          @click-title="navigateTo('meineziele/' + $event.id)"
          @action-EDIT="navigateTo('meineziele/' + $event.id)"
          @action-DELETE="deleteGoal"
        >
          <template #img="row">
            <div class="image-placeholder">
              <BaseImage
                class="logo"
                :src="goalImage(row)"
                :placeholder="imagePlaceholder"
              />
            </div>
          </template>

          <template #chart="row">
            <PieChart 
              style="width: 40px"
              :chartData="eChartData(row)" 
              :customColors="eChartColors"
              :isTooltip="false"
              height="40px"
              doughnut
            />
          </template>
        </Table>
        <NoData v-else :title="TABLE_TITLE" />
      </div>
    </div>
  </div>
</template>

<script>
import MY_GOALS_TYPES from '@/store/myGoals/types';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, SlotColumn, CurrencyColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue';
import PieChart from '@/components/charts/echarts/PieChart.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import mixin from './my-goals-mixin.js';
import BaseImage from '@/components/core/BaseImage.vue';

const TABLE_TITLE = 'Meine Ziele-Liste';

export default {
  mixins: [mixin],
  data() {
    return {
      TABLE_TITLE,
      headers: {
        lockedLeft: [
          TextColumn("title", "Bezeichnung").makeLink(),
          SlotColumn('img', ''),
          TextColumn("typeDesc", "Kategorie"),
          SlotColumn("chart", "Zielerreichung").makeAlwaysVisible(),
        ],
        center: [
          CurrencyColumn("sparrate", "Sparrate"),
          DateColumn("endDate", "Zieldatum (Ende)"),
        ],
        lockedRight: [
          CurrencyColumn("zielsumme", "Benötigtes Kapital"),
          ActionColumn("actions", "Aktionen"),
        ],
      },
      imagePlaceholder: {
        width: '0px',
        height: '0px',
      },
    }
  },
  computed: {
    rows() {
      const actions = [
        SimpleAction("EDIT", 'PhPencil', "Bearbeiten"),
        SimpleAction("DELETE", 'PhTrash', "Löschen"),
      ];
      return this.goals?.map(item => ({
        ...item,
        chart: '1',
        actions,
      })) || [];
    },
    actions() {
      return [
        PageHeaderSimpleAction('NEW', 'Neu')
      ]
    }
  },
  methods: {
    chooseGoal() {
      this.$router.push({ path: `${this.path}/select` });
    },
    deleteGoal(goal) {
      this.$confirmModal({
        title: 'Mein Ziel löschen', 
        message: 'Möchten Sie dieses Ziel wirklich löschen?', 
        labelButtonConfirm: 'Löschen',
      }).then(() => {
        if (goal?.id) {
          this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.DELETE_GOAL, {id: goal.id});
        }
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Meine Ziele', 
      to,
      from,
    });

    next();
  },
  components: {
    Table,
    BaseModal,
    PieChart,
    GhostLoading,
    NoData,
    PageHeaderTitleNavigation,
    OptionMenu,
    BaseImage,
  },
};
</script>

<style scoped>
.image-placeholder {
  width: 60px !important;
  height: 60px !important;
}
</style>