<template>

  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.parent.label"
      :subtitle="$appNavigation.currentMenu.label"
      :defaultMenu="$appNavigation.currentTabMenu"
    />

    <div class="box__container" tid="958f96aa-e0a6-4f21-8f14-1255805511c1">
      <InputRadioBoxGroup 
        title="Typ"
        v-model="listingType"
        value="listingType" 
        :values="listingTypes"
        labelClass="font-bold"
        @input="retrieveData($event)" />    
    </div>

    <div class="box__container" tid="3c197cb4-0331-425e-9a45-454239452fa8">
      <p v-if="listingType=='Versicherungen'">Bitte beachten Sie, dass von der Struktur geerbte Vermittlernummern nur sichtbar sind, wenn Sie den Makler öffnen und seine individuelle Liste anzeigen lassen.</p>

      <BigTable
              v-else-if="shouldShowBigTable"
              :title="listingType"
              tableId="f83a33a2-c8cf-45e4-9e7d-c08b73723be1"
              :headers="bigTableHeaders"
              :rows="bigTableRows"
              :page="bigTablepage"
              :sortingState="sortingState"
              :initialPageSize="minNumRows"
              :headerActions="tableActions"
              @onClickHeader="onClickHeader"
              @headerAction-EXPORT-PDF="getExportBigTable('pdf')"
              @headerAction-EXPORT-XLS="getExportBigTable('xls')"              
            >
      </BigTable>

      <Table
        tableId="f075f490-577a-4b51-898d-787c1606a398"
        :title="moduleTitle"
        rowId="rowIndex"
        class="table-container"
        v-else-if="$isSmallScreen && !loading && tableRows.length"
        :headers="tableHeaders"
        scrollHorizontally
        :rows="tableRows"
        :rowsPerPage="20"
        :count="resultCount"
        :exportConfig="exportConfig"
        @action="executeAction($event)"
      >
        
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="moduleTitle" />
      <NoData v-else :title="moduleTitle" />
    </div>    

    <BaseModal 
        ref="modalDownloadPin"
        :modalTitle="fileMitPIN ? 'Download PIN geschützte Datei' : tempFileName "
        :showConfirmButton="true"
        labelButtonConfirm="Herunterladen"
        labelButtonCancel="Abbrechen"
        :autoClose="false"
        @onConfirmButton="downloadPinFile"
        @close="onCloseModal" 
    > 
      <p>Diese Statistik ist zu umfangreich um dargestellt zu werden.<br>
        Sie können aber eine CSV-Datei herunterladen.
      </p>
      <form @submit.prevent="downloadPinFile()" v-if="fileMitPIN">
          <InputField 
          label="Bitte geben Sie die PIN ein"
          placeholder="Pin" 
          v-model="tempFilePin" />
      </form>
      <div class="fc-form-danger">
          {{ errorPinFile }}
      </div>
    </BaseModal>    

  </div>
    
</template>

<script>
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import VERMITTLERNUMMERN_TYPES from "../../store/vermittlernummern/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import BigTable from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, FIXED_LEFT, FIXED_RIGHT} from '@/components/bigTable/BigTable.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import CORE_TYPES from '@/store/core/types';
import { viewDocument as openDocument} from '@/components/core/download/DownloadLink.vue'
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';


export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    PageHeaderTitleNavigation,
    GhostLoading,
    NoData,
    InputRadioBoxGroup,
    OptionMenu,
    BigTable,
    BaseModal,
  },
  created() {
    this.FIXED_LEFT = FIXED_LEFT;
    this.FIXED_RIGHT = FIXED_RIGHT;
    this.STRING_COLUMN = STRING_COLUMN;
  },  
  data: function () {
      return {
        resultCount: null,
        loading: true,
        listingType: 'Fondsgesellschaft',
        listingTypes: [
          {
            value: 'Fondsgesellschaft', 
            label: 'Fondsgesellschaften'
          },
          {
            value: 'Geschlossene Fonds', 
            label: 'Beteiligungen'
          },           
          {
            value: 'Bank', 
            label: 'Banken/Plattformen'
          },    
          {
            value: 'Versicherungen', 
            label: 'Versicherungen'
          },                       
        ],
        bigTableRows: [],
        bigTableHeaders: [],
        sortingState: { key: "gesellschaftsname", direction: "asc" },
        minNumRows: 100,
        bigTablepage: 0,
        tempFileId: null,
        tempFileName: '',
        fileMitPIN: true,
        tempFilePin: '',
        errorPinFile: '', 
      }
  },
  computed: {
    ...mapGetters({
      alleMitarbeiterData: VERMITTLERNUMMERN_TYPES.GETTERS.ALLE_MITARBEITER_DATA,
    }),
    shouldShowBigTable() {
      return !this.$isSmallScreen && !this.loading  && this.bigTableRows?.length;
    },    
    exportConfig() {
      return {
        roottext: this.moduleTitle,
      }
    },
    tableActions() {
        return [
            PageHeaderSimpleAction('EXPORT-PDF', 'PDF')
            .withDisabled(() => !this.shouldShowBigTable),
            PageHeaderSimpleAction('EXPORT-XLS', 'XLS')
            .withDisabled(() => !this.shouldShowBigTable),            
        ];
    },    
    tableHeaders() {
      const lockedLeft = [];  
      const center = [];  

      const firstRowValues = this.alleMitarbeiterData[0]?.values;

      lockedLeft.push(TextColumn("gesellschaftsname", "Gesellschaft").makeAlwaysVisible());

      firstRowValues.forEach(fr => {
          center.push(TextColumn(fr.key, fr.key));
      });

      return {
        lockedLeft,
        center,
      };      
    },       
    tableRows () {
      if (!this.alleMitarbeiterData?.length ) {
        return [];
      }

      const result = [];
      this.alleMitarbeiterData.forEach((am, index) => {
        const row = {gesellschaftsname : am.gesellschaftsname};

        am.values.forEach(v => {
            row[v.key] = v.value;
        });

        row.rowIndex = index;

        result.push(row);
      })

      return result;
    },
    moduleTitle() {
        return this.listingTypes.find(lt => lt.value == this.listingType)?.label;

    },
  },
  watch: {
    alleMitarbeiterData(tableData) {
      this.generateHeadersForBigTable(tableData);
      this.generateRowsForBigTable(tableData);
    }
  },
  mounted() {
    this.retrieveData();  
  },
  methods: {
    async getExportBigTable(fileEnding) {

      const result = [];
      this.alleMitarbeiterData.forEach((am, index) => {
        const row = {gesellschaftsname : am.gesellschaftsname};

        am.values.forEach(v => {
            row[v.key] = v.value;
        });

        row.rowIndex = index;

        result.push(row);
      });

      let exportRows = result.map(row => {

        return this.bigTableHeaders.map( (header) => {
          let value = '';

          // right now there are only string columns
          switch (header.type) {
              
                default:
                  value = row[header.key] !== null ? String(row[header.key]) : "";
                  break;
              }

            return value;
        });

      });      

      const name = this.listingType + '.' + fileEnding;

      const params = {
            create: fileEnding,
            filename: name,
            title: 'Fondsfavoriten',
            columns: this.bigTableHeaders.map(header => header.label),
            rows: exportRows || [],
            printMaklerLogo: '1',
            colsNoFormat: 'Jahr',
        };
        const response = await   this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params);
        
        if (response.data.bytes) {        
          openDocument({
            data: response.data.bytes,
            filename: name,
            contentType: `application/${fileEnding}`,
          }, true);
        } else if (response.data.tempFileId) {
          this.tempPdfId = response.data.tempFileId;
          this.$refs.modalDownloadPin.open();
        }        

    },
    async downloadPinFile() {
      const error = await this.$store.dispatch(CORE_TYPES.ACTIONS.DOWNLOAD_PIN_FILE, {
        fileMitPIN: this.fileMitPIN,
        tempFilePin: this.tempFilePin,
        tempFileId: this.tempFileId,
        fileType: 'csv'
      });

      if (!error) {
          this.$refs.modalDownloadPin.close();
          this.onCloseModal();
      } else {
        this.errorPinFile = error;
      }

    },    
    onCloseModal() {
        this.tempFileId = null;
        this.tempFilePin = '';
        this.errorPinFile = ''
    },     
    onClickHeader(headerMetadata) {
        this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
    },       
    generateHeadersForBigTable(tableData) {
      const firstRowValues = tableData[0]?.values;

      if (!tableData.length || !this.alleMitarbeiterData?.length || !firstRowValues?.length) {
        this.bigTableHeaders = [];
      } else {
        const headers = [
            { label: "Gesellschaft", key: "gesellschaftsname", width: 200, type: STRING_COLUMN, position: FIXED_LEFT },
        ];
  
        firstRowValues.forEach(fr => {
          headers.push({ label: fr.key, key: fr.key, width: 150, type: STRING_COLUMN, visible: true });
        });
  
        // actions column should always be the last one
        // headers.push({ label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true });
        
        this.bigTableHeaders = headers;

      }


    },
    generateRowsForBigTable(tableData) {
      if (!tableData.length || !this.alleMitarbeiterData?.length) {
        this.bigTableRows = [];
      } else {
        const result = [];
        this.alleMitarbeiterData.forEach((am, index) => {
          const row = {gesellschaftsname : am.gesellschaftsname};
  
          am.values.forEach(v => {
              row[v.key] = v.value;
          });
  
          row.rowIndex = index;
  
          result.push(row);
        });
  
        this.bigTableRows = result;

      }

    },    
    retrieveData(param) {
      this.loading = true;

      /**
       * Possible values for gesellschaftsArtParam are: 'Fondsgesellschaft', 'Bank', 'Geschlossene Fonds', 'Versicherungen'
       */

      const gesellschaftsArtParam = param || 'Fondsgesellschaft' 

      this.$store.dispatch(
              VERMITTLERNUMMERN_TYPES.ACTIONS.GET_ALLE_MITARBEITER_DATA, {gesellschaftsArtParam}
            ).then(() => {
                this.loading = false;
            });  

    },
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Vermittlernummern für alle Mitarbeiter Übersicht', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    this.$store.commit(VERMITTLERNUMMERN_TYPES.MUTATIONS.RESET_LIST_ALLE_MITARBEITER);

    next();
  },


    
}
</script>