<template>
  <div>
    <div class="box__container">
      <div class="row">
        <div class="col-12">
          <BaseButton @click="neuEigebundeneWertpapiere()">Neues Wertpapier anlegen</BaseButton>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <GhostLoading v-if="loading" type="table" />
          <NoData v-else-if="eigebundeneWertpapiere && eigebundeneWertpapiere.rows.length == 0"/>
          <Table v-else
            title="Eingebundene Wertpapiere"
            tableId="1a4fb9da-4982-4f0f-98fc-8514438eb29a"
            :headers="eigebundeneWertpapiereHeaders"
            :rows="eigebundeneWertpapiere.rows"
            @action-EDIT="editEingebundeneWertpapiere($event)"
            @action-DELETE="deleteEingebundeneWertpapiere($event)"
          >

            <template #fondswert="row">
              <CurrencyLabel :value="row.fondswert" :symb="currencyTypeToLocaleIdentifier(row)"/>
            </template>

          </Table>
        </div>
      </div>
    </div>

    <div class="box__container">
      <div class="row">
        <div class="col-12">
          <BaseButton @click="newRueckkaufswerte()">Neuen Rückkaufswert anlegen</BaseButton>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <GhostLoading v-if="loading" type="table" />
          <NoData v-else-if="ruckkaufswerte && ruckkaufswerte.rows.length == 0" />
          <Table v-else
            title="Rückkaufswerte"
            tableId="1a4fb9da-4982-4f0f-98fc-8514438eb29a"
            :headers="ruckkaufswerteHeaders"
            :rows="ruckkaufswerte.rows"
            @action-EDIT="editRueckkaufswerte($event)"
            @action-DELETE="deleteRueckkaufswerte($event)"
          />
        </div>
      </div>
    </div>

    <div>
      <BaseModal ref="ruckkaufswerteEditModal" 
        labelButtonCancel="Abbrechen" 
        @onConfirmButton="saveRuckkaufswerte" 
        @onCancelButton="closeModal('ruckkaufswerteEditModal')">

        <template v-slot:modalTitle>
          Rückkaufswert
        </template>
        <div class="row">
          <div class="col-12">
            <InputField
              type="number"
              isInteger
              label="Jahr"
              v-model="ruckkaufswerteEdit.jahr"
              :validateUntouched="true"
            />
          </div>
          <div class="col-12">
            <InputField
              type="currency"
              label="Rückkaufswerte"
              :precision="2"
              v-model="ruckkaufswerteEdit.betrag"
              :validateUntouched="true"
            />
          </div>
        </div>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Table from '@/components/table2/Table.vue';
import {NumberColumn, CurrencyColumn, ActionColumn, TextColumn, SimpleAction, DateColumn, SlotColumn } from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import EINGEBUNDENE_WERTPAPIERE_TYPES from "@/store/eingebundeneWertpapiere/types"
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from "@/components/core/forms/InputField.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import { required, minValue, maxValue } from '@/mixins/validator/rules';
import validator from '@/mixins/validator'
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js'

export default {
  mixins: [validator, InsuranceDetailMixin],
  validators: {
    ruckkaufswerteEdit : {
      jahr : [required('Jahr ist erforderlich!'), minValue(1900, 'Das Jahr sollte größer sein als 1900'), maxValue(2101, 'Das Jahr sollte weniger als 2100')],
      betrag : [required('Betrag ist erforderlich!')],
    }
  },
  components: {
    Table,
    GhostLoading,
    NoData,
    InputField,
    BaseModal,
    BaseButton,
    CurrencyLabel,
  },
  data() {
    return {
      loading: false,
      ruckkaufswerteEdit: {
        id: null,
        jahr: null,
        betrag: null
      },
    }
  },
  computed: {
    ...mapGetters({
      ruckkaufswerte: VERSICHERUNG_TYPES.GETTERS.RUCKKAUFSWERTE_LIST,
      versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
      depotPositions: VERSICHERUNG_TYPES.GETTERS.DEPOT_POSITIONS,

    }),
    ruckkaufswerteHeaders() {
      return {
        lockedLeft: [TextColumn("jahr", "Jahr")],
        center: [CurrencyColumn("betrag", "Betrag")],
        lockedRight: [ActionColumn("actions", "Aktionen")],
      }
    },
    eigebundeneWertpapiereHeaders() {
      return {
        lockedLeft: [TextColumn("isin", "ISIN")],
        center: [
                  TextColumn("wertpapiername", "Wertpapiername"),
                  TextColumn("anlageart", "Anlageart"),
                  DateColumn("anteileDatum", 'Stand Anteile'),
                  NumberColumn("anteile", "Anteile", 2), 
                  CurrencyColumn("anlagesumme", "Anlagesumme"),
                  SlotColumn("fondswert", "Wert (Wp.whrg.)", 200, 1).alignRight(),
                  CurrencyColumn("fondswertEuro", "Wert").withSumFooter()
                ],
        lockedRight: [ActionColumn("actions", "Aktionen")],
      }
    },
    ruckkaufswerte() {
      const mainSparteStep = this.versicherungenDetails?.steps?.find(sparteStep => sparteStep?.config?.primary);

      if(this.versicherungenDetails?.insurance?.sparten?.length && mainSparteStep) {
        const sparte = this.versicherungenDetails.insurance.sparten.find(sparte => sparte.sparteId === mainSparteStep.stepKey);
        if(sparte?.rueckkaufswerte) {
          sparte?.rueckkaufswerte.forEach(element => {
            element.actions = [SimpleAction("EDIT", 'PhPencilLine', "Bearbeiten"), SimpleAction("DELETE", 'PhTrash', "Löschen")]
          });
          return {rows: sparte?.rueckkaufswerte}
        }
      }
      return {rows: []}
    },
    hasDepotPositions() {
      return this.depotPositions?.records?.length;
    },
    eigebundeneWertpapiere() {
      const mainSparteStep = this.versicherungenDetails?.steps?.find(sparteStep => sparteStep?.config?.primary);

      if(this.versicherungenDetails?.insurance?.sparten?.length && mainSparteStep) {
        const sparte = this.versicherungenDetails.insurance.sparten.find(sparte => sparte.sparteId === mainSparteStep.stepKey);
        if(sparte?.eigebundeneWertpapiere) {
          var eingebundeneWertpapiere = sparte?.eigebundeneWertpapiere

          eingebundeneWertpapiere.forEach(element => {
            if(element.id != null){
              element.actions = [SimpleAction("EDIT", 'PhPencilLine', "Bearbeiten"), SimpleAction("DELETE", 'PhTrash', "Löschen")]
            }
          }); 
          return {rows: eingebundeneWertpapiere}
        }
      }
      return {rows: []}
    },
    currentSparte() {
      const mainSparteStep = this.versicherungenDetails?.steps?.find(sparteStep => sparteStep?.config?.primary);
      if(this.versicherungenDetails?.insurance?.sparten?.length && mainSparteStep) {

        const sparte = this.versicherungenDetails.insurance.sparten.find(sparte => sparte.sparteId === mainSparteStep.stepKey);
        return sparte?.id;
      }

      return null;
    },
    versicherungId() {
      return this.versicherungenDetails?.id || this.$route?.params?.id;
    }
  },
  methods: {
    currencyTypeToLocaleIdentifier(row) {
      // TODO: implement a more generic method that can handle other currency symbols too
			let result =  row.waehrung;

      if (row.waehrung === 'EUR')
        result = '€';
			if (row.waehrung === 'USD') {
				result = '$'
			} 

			return result;
		},
    saveRuckkaufswerte() {
      if(this.validation.invalid) {
        this.resetModel();
        return;
      }
      const payload = {
        ruckkaufswerteEdit : this.ruckkaufswerteEdit, 
        sparteId: this.currentSparte, 
        versicherungId: this.versicherungId
      }
      if (this.ruckkaufswerteEdit?.id) {
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.UPDATE_RUECKKAUFSWERTE, payload);
      } else {
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.SAVE_RUECKKAUFSWERTE, payload);
      }
    },
    deleteRueckkaufswerte(event) {
      const payload = {
        ruckkaufswerteId : event.id, 
        sparteId: this.currentSparte, 
        versicherungId: this.versicherungId
      }
      this.$confirmModal({
        title: 'Ordner löschen',
        message: `Soll der Rückkaufswert für das Jahr <b>${event.jahr}</b> wirklich gelöscht werden?`,
        labelButtonConfirm: 'Löschen'
      })
      .then(() => this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.DELETE_RUECKKAUFSWERTE, payload));
    },
    closeModal(modalRef) {
      this.$refs[modalRef]?.close();
    },
    editRueckkaufswerte(event) {
      this.ruckkaufswerteEdit.id = event.id;
      this.ruckkaufswerteEdit.jahr = event.jahr;
      this.ruckkaufswerteEdit.betrag = event.betrag
      this.$refs.ruckkaufswerteEditModal.open();
    },
    editEingebundeneWertpapiere(event) {
      if(event) {
        this.$store.commit(EINGEBUNDENE_WERTPAPIERE_TYPES.MUTATIONS.EINGEBUNDENE_WERTPAPIERE_EDIT, event);
        this.$router.push(`/home/versicherungen/insurance-group/versicherung-eingebundene-wertpapiere/${this.versicherungId}/${this.currentSparte}`);
      }
    },
    deleteEingebundeneWertpapiere(event) {
      if(event.id) {
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.DELETE_EINGEBUNDENE_WERTPAPIERE, 
        {
          eingebundeneWertpapiereId: event.id,
          versicherungId: this.versicherungId,
          sparteId: this.currentSparte
        })
      }
    },
    newRueckkaufswerte(){
      this.resetModel();
      this.$refs.ruckkaufswerteEditModal.open();
    },
    neuEigebundeneWertpapiere() {
      this.$router.push(`/home/versicherungen/insurance-group/versicherung-eingebundene-wertpapiere/${this.versicherungId}/${this.currentSparte}`);
    },
    resetModel() {
      this.ruckkaufswerteEdit = { id: null, jahr: null, betrag: null};
    }
  },
}
</script>

<style>

</style>