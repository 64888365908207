import BRIDGE_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import { AUTHENTICATION_TYPES, MOBILE_NATIVE_CONTEXT } from './index';
import { ROLES } from '@/router/roles';

export default {
  [BRIDGE_TYPES.GETTERS.LOADED_LOGIN_DATA](state) {
    return state.loadedLoginData
  },
  [BRIDGE_TYPES.GETTERS.HAS_LOADED_LOGIN_DATA](state) {
    return state.hasLoadedLoginData === true
  },
  [BRIDGE_TYPES.GETTERS.HAS_MOBILE_DATA_FOR_NATIVE_MEANS_OF_LOGIN](state) {
    const { loginName, authenticationType } = state.loadedLoginData ?? {};
    return !!(loginName && authenticationType);
  },
  [BRIDGE_TYPES.GETTERS.GET_AUTHENTICATION_TYPE_LABEL](state) {
    const authenticationType = AUTHENTICATION_TYPES?.[state.loadedLoginData?.authenticationType];
    return authenticationType ? `${authenticationType} Login` : undefined;
  },
  [BRIDGE_TYPES.GETTERS.GET_AUTHENTICATION_TYPE](state) {
    return state.loadedLoginData?.authenticationType;
  },
  [BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT](state) {
    return state.mobileNativeContext
  },
  [BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT_IOS](state) {
    return MOBILE_NATIVE_CONTEXT.IOS === state.mobileNativeContext
  },
  [BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT_ANDROID](state) {
    return MOBILE_NATIVE_CONTEXT.ANDROID === state.mobileNativeContext
  },
  [BRIDGE_TYPES.GETTERS.MOBILE_NATIVE_SPEC](state) {
    return state.mobileNativeSpec;
  },
  [BRIDGE_TYPES.GETTERS.CLIENT_CALL_ID](state){
    return state.clientCallId;
  },
  [BRIDGE_TYPES.GETTERS.BIOMETRIC_LOGIN_SETTINGS](state) {
    return state.biometricLoginSettings;
  },
  [BRIDGE_TYPES.GETTERS.IS_BROWSER_SIMULATION](state) {
    return state.mobileNativeContext 
      && process.env.VUE_APP_PRODUCTION !== 'true' 
      && process.env.VUE_APP_VUE_JS_BRIDGE_DEBUG === 'true' 
      && process.env.VUE_APP_VUE_JS_BRIDGE_MOCK === 'true'
  },
  [BRIDGE_TYPES.GETTERS.IS_VIRTUAL_KEYBOARD_DETECTED](state){
    return state.virtualKeyboardDetected === true;
  },
  [BRIDGE_TYPES.GETTERS.RECEIVED_DB_PREFIX](state) {
    return state.receivedDbPrefix;
  },
  [BRIDGE_TYPES.GETTERS.SHOW_BUTTON_SWITCH_AUDIO_OUTPUT](state) {
    return state.showButtonSwitchAudioOutput;
  },
  [BRIDGE_TYPES.GETTERS.IS_AUDIO_OUTPUT_SPEAKER](state) {
    return state.audioOutput === 'SPEAKER';
  },
  [BRIDGE_TYPES.GETTERS.IS_AUDIO_OUTPUT_RECEIVER](state) {
    return state.audioOutput === 'RECEIVER';
  },
  [BRIDGE_TYPES.GETTERS.AUDIO_OUTPUT](state) {
    return state.audioOutput;
  },
  [BRIDGE_TYPES.GETTERS.SHOW_CLOSE_EXTRA_WEB_INSTANCE](state, getters) {
    const isMobileNativeContext = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT];
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    return  isMobileNativeContext && (
      hasRoles(ROLES.HIDE_LOGOUT_BUTTON) 
      || hasRoles(ROLES.IS_KUNDENZUGANG) 
      || hasRoles(ROLES.IS_MAKLERZUGANG) 
    );
  },
  [BRIDGE_TYPES.GETTERS.IS_DEEPLINK_READY](state) {
    return !!state.deeplink?.ready;
  },
  [BRIDGE_TYPES.GETTERS.TRACK_USER_PEEK](state) {
    const { trackUserStack } = state;
    return trackUserStack[trackUserStack.length - 1];
  },
}