<template>
  <div>
    <PageHeaderTitleNavigation :title="title" :subtitle="subtitle" :defaultMenu="$appNavigation.currentTabMenu"
      :actions="headerActions" @action-ADD="addNewAccount()">
      <template v-if="$slots.title" #title>
        <slot name="title" />
      </template>
      <template v-if="$slots.subtitle" #subtitle>
        <slot name="subtitle" />
      </template>

      <template #action-SHARE_ACCOUNT v-if="isMobileNativeContext">
        <InputToggleSwitch v-model="shareAccounts" :label="parameters.shareDataSwitchText" @input="toggleShareAccount"
          inLineLabel suppressValidationMessage />
      </template>

    </PageHeaderTitleNavigation>
    <BanksAPIZugangWebcomponent ref="banksapiwebcomponent" :banksapiURL="banksapiURL" @subscribed="finalizeAccount" modalTitle="Multibanking - Neues Konto hinzufügen"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, PageHeaderSlotAction, } from '@/components/core/header-title-navigation/page-header-utils';

import { KONTOVERWALTUNG_PATH } from '@/store/multiBanking/actions'
import ROLES from '@/router/roles'
import BRIDGE_TYPES from '@/store/bridge/types';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch';
import BanksAPIZugangWebcomponent from '@/views/multiBanking/BanksAPIZugangWebcomponent.vue';


export default {
  props: {
    title: {
      type: String,
      default: 'Multibanking',
    },
    subtitle: {
      type: String,
    },
  },
  data() {
    return {
      shareAccounts: false,
    }
  },
  computed: {
    ...mapGetters({
      parameters: MULTIBANKING_TYPES.GETTERS.PARAMETERS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      userSettings: MULTIBANKING_TYPES.GETTERS.USER_SETTINGS,
      banksapiURL: MULTIBANKING_TYPES.GETTERS.BANKSAPI_URL
    }),
    isUserAllowedToGetMultibankingData() {
      return this.hasRoles(ROLES.MULTIBANKING_DATA_ALLOWED);
    },
    showMultibankingActionButtons() {
      return !this.isBrokerOrBypass && !this.hasRoles(ROLES.IS_BYPASS_SLASH) && this.isUserAllowedToGetMultibankingData;
    },
    headerActions() {
      const { parameters, showMultibankingActionButtons, } = this;
      const actions = [];
      if (parameters && showMultibankingActionButtons) {
        actions.push(PageHeaderSimpleAction('ADD', 'Neues Konto'));

        if (this.isMobileNativeContext) {
          actions.push(PageHeaderSlotAction('SHARE_ACCOUNT', parameters.shareDataSwitchText));
        }
      }
      return actions;
    },
  },
  methods: {
    async addNewAccount() {
      const relativePath = KONTOVERWALTUNG_PATH
      const callbackPath = window.location.origin + relativePath
      await this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.REGISTER_ACCOUNT_URL, {
        callbackPath,
      })
      this.$refs.banksapiwebcomponent.open();
    },
    toggleShareAccount($event) {
      this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.SAVE_USER_SETTINGS, { shareAccounts: $event })
    },
    async finalizeAccount(event) {
      const urlParams = new URL(event.detail).searchParams;
      const accountId = urlParams.get('accountId');
      const baReentry = urlParams.get('baReentry');
      await this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.FINALIZE_ACCOUNT, { baReentry, accountId });
    }
  },
  mounted() {
    this.shareAccounts = this.userSettings?.shareAccounts
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputToggleSwitch,
    BanksAPIZugangWebcomponent
  },
}
</script>
