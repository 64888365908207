<template>
  <div class="customer-kontaktdaten__container box__container-wrap">
    <div class="box__container-rows">
      <template v-if="contacts.length">
        <div class="label-value--list">
          <div class="label-value--item" v-for="(contact, index) in contacts" :key="index">
            <div class="label-value--item-label">{{ contact.title }}</div>
            <div v-if="contact.typeId.includes('Telefon')" class="label-value--item-value">
              <DialablePhoneNumber :phoneNumber="contact.value"/>
              <PhCheck :size="16" class="color-success" :style="{ opacity: contact.default ? 1 : 0 }" />
            </div>
            <div v-else-if="contact.typeId.includes('Mobil')" class="label-value--item-value">
              <DialablePhoneNumber :phoneNumber="contact.value"/>
              <PhCheck :size="16" class="color-success" :style="{ opacity: contact.default ? 1 : 0 }" />
            </div>
            <div v-else class="label-value--item-value">
               {{ contact.value }} 
              <PhCheck :size="16" class="color-success" :style="{ opacity: contact.default ? 1 : 0 }" />
            </div>
          </div>
        </div>
      </template>
      <NoData v-else noIcon />
    </div>
    <div class="bottom-row" v-if="!$isSmallScreen">
      <div class="text-right">
        <router-link to="/persoenlichedaten/customer-data/steps/kommunikationsdaten">Kontaktdaten anzeigen</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import STECKBRIEF_TYPES from '../store/types';
import { PhCheck } from 'phosphor-vue';
import NoData from '@/components/core/NoData.vue';
import DialablePhoneNumber from './DialablePhoneNumber.vue';

export default {
  computed: {
    ...mapGetters({
      info: STECKBRIEF_TYPES.GETTERS.STECKBRIEF_CUSTOMER_INFO
    }),
    person() {
      return this.info?.person || {};
    },
    contacts() {
      const contacts = this.person?.contacts || []
      return contacts.sort((c1, c2) => {
        if(!c1.title || !c2.title) return 1;
        return c1.title.toLowerCase().localeCompare(c2.title.toLowerCase());
      });
    },
  },
  components: {
    PhCheck,
    NoData,
    DialablePhoneNumber
  },
}
</script>

<style src='../steckbrief.scss' lang='scss' scoped></style>
