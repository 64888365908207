<template>
  <div>
    <OptionMenu v-if="useParentOptionMenu" :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />
    <OptionMenu v-else :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" :title="aktDeepTitle" />

    <div v-if="prevDeepTitle" class="titel box__container">
      <a @click="toPreviusLevel">zurück zu {{prevDeepTitle}}</a>
    </div>
    
    <div class="box__container">
      <div class="m-0 p-0" ref="divTableWidth"></div>
      <template v-if="viewData.loading">
        <GhostLoading >
          <Block height="300" />
        </GhostLoading>
      </template>

      <template v-else-if="displayTable">
          <template v-if="shouldShowBigTable || shouldShowSmallTable">

            <BigTable
                v-if="shouldShowBigTable"
                :title="tableTitle"
                :tableId="tableId"
                :headers="bigTableHeaders"
                :rows="bigTableRows"
                :page="bigTablepage"
                :sortingState="sortingState"
                :initialPageSize="minNumRows"
                :footerMetadata="footerMetadata"
                :headerActions="tableActions"
                @onClickHeader="onClickHeader"
                @headerAction-EXPORT-PDF="getExportBigTable('pdf')"
                @headerAction-EXPORT-XLS="getExportBigTable('xls')"
              >

              <template #key0="row">
                <div class="ellipsis" >
                  <a v-if="viewData.table.records[row.rowIndex][0].load" @click="toNextLevel(row.rowIndex)">{{viewData.table.records[row.rowIndex][ixCell0].value}}</a>
                  <span v-else>{{viewData.table.records[row.rowIndex][ixCell0].value}}</span>
                </div>

              </template>              
         
            </BigTable>

            <Table class="table-container" 
              ref="viewstatistik"
              v-if="shouldShowSmallTable"
              :tableId="tableId"
              :title="tableTitle"
              :headers="headers"
              :rows="rows"
              :scrollHorizontally="true"
              :exportConfig="exportConfig" 
              rowId="index"
              :rowsPerPage="pageRows"
              :hidePagination = "hidePagination"
            >
              <template #key0="{key0}">
                <div class="ellipsis" >
                  <a v-if="viewData.table.records[key0][0].load" @click="toNextLevel(key0)">{{viewData.table.records[key0][ixCell0].value}}</a>
                  <span v-else>{{viewData.table.records[key0][ixCell0].value}}</span>
                </div>
              </template>
            </Table>
            <div class="table-pagination" v-if="count && $isSmallScreen">
              <PageRowCount :displayedItems="pageRows" :totalRows="rowCount"/>
              <Pagination v-if="pageRows < rowCount"
                  :totalPages="count"
                  :currentPage="page"
                  @pagechanged="onPageChange"
              />
            </div>            

          </template>
          <NoData v-else />
      </template>
    </div>

    <BaseModal 
        ref="modalDownloadPin"
        :modalTitle="fileMitPIN ? 'Download PIN geschützte Datei' : tempFileName "
        :showConfirmButton="true"
        labelButtonConfirm="Herunterladen"
        labelButtonCancel="Abbrechen"
        :autoClose="false"
        @onConfirmButton="downloadPinFile"
        @close="onCloseModal" 
    > 
      <p>Diese Statistik ist zu umfangreich um dargestellt zu werden.<br>
        Sie können aber eine CSV-Datei herunterladen.
      </p>
      <form @submit.prevent="downloadPinFile()" v-if="fileMitPIN">
          <InputField 
          label="Bitte geben Sie die PIN ein"
          placeholder="Pin" 
          v-model="tempFilePin" />
      </form>
      <div class="fc-form-danger">
          {{ errorPinFile }}
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BROKER_STATISTICS_TYPES from '@/store/brokerStatistics/types';
import CORE_TYPES from '@/store/core/types';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import {PhCaretLeft, PhCaretRight } from "phosphor-vue";
import Table from "@/components/table2/Table.vue";
import PageRowCount from '@/components/table2/core/PageRowCount.vue';
import Pagination from '@/components/table2/core/Pagination.vue';
import NoData from '@/components/core/NoData.vue';
import {TextColumn, ActionColumn, SlotColumn, CurrencyColumn, DateColumn, NumberColumn, PercentageColumn} from "@/components/table2/table_util.js";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import InputField from '@/components/core/forms/InputField.vue';
import { formatNumber } from '@/helpers/number-formatter';
import BigTable from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, DATE_COLUMN, ACTION_COLUMN, SLOT_COLUMN, FIXED_LEFT, FIXED_RIGHT, Icon, ICON_COLUMN, NUMBER_COLUMN, CURRENCY_COLUMN, INTEGER_COLUMN, FLOAT_COLUMN, DATETIME_COLUMN} from '@/components/bigTable/BigTable.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { viewDocument } from '@/components/core/download/DownloadLink.vue'

export default {
  name: 'MaklerStatistikView',
  components: {
    GhostLoading, Block, Table, NoData, PhCaretLeft, PhCaretRight, 
    PageRowCount, Pagination, OptionMenu, PageHeaderTitleNavigation, BaseModal, InputField,
    BigTable,
  },
  props: {
    useParentOptionMenu: {
      type: Boolean,
      default: true,
    },
  },
  created() {
      this.FIXED_LEFT = FIXED_LEFT;
      this.FIXED_RIGHT = FIXED_RIGHT;
      this.STRING_COLUMN = STRING_COLUMN;
      this.NUMBER_COLUMN = NUMBER_COLUMN;
      this.CURRENCY_COLUMN = CURRENCY_COLUMN;
      this.DATE_COLUMN = DATE_COLUMN;
      this.FLOAT_COLUMN = FLOAT_COLUMN;
      this.DATETIME_COLUMN = DATETIME_COLUMN;
      this.SLOT_COLUMN = SLOT_COLUMN;
      this.INTEGER_COLUMN = INTEGER_COLUMN;
  },  
  data: function() {
    return {
      keysTH: [],
      noScrollColumns: true,
      scrolling: false,
      scrollTo: '',
      awayHeaders: [],
      awayFistKey: [],
      headers: {},
      rows: [],
      exportCols: [],
      exportRows: [],
      ixCell0: 0,
      deepTitle: [''],
      displayTable: true,
      page: -1,
      count: 0,
      rowCount: 0,
      pageRows: 25,
      params: {},
      firstColWidth: 0,
      hidePagination: false,
      
      tempFileId: null,
      tempFileName: '',
      fileMitPIN: false,
      tempFilePin: '',
      errorPinFile: '',
      tableId: '',
      bigTableHeaders: [],
      bigTableRows: [],
      footerMetadata: null,
      sortingState: { key: "key0", direction: "desc" },
      minNumRows: 100,
      bigTablepage: 0,
    }
  },
  mounted() {
    this.params = this.viewData.params
    this.$updateCurrentBreadcrumb({
      breadcrumb: this.params?.title
    })
    this.deepTitle = this.getDeepTitles;
  },
  computed: {
    ...mapGetters({
      viewData: BROKER_STATISTICS_TYPES.GETTERS.GET_TABLE_VIEWER,
      getDeepTitles: BROKER_STATISTICS_TYPES.GETTERS.GET_DEEP_TITLE,
    }),
    tableActions() {
        return [
            PageHeaderSimpleAction('EXPORT-PDF', 'PDF')
            .withDisabled(() => !this.shouldShowBigTable),
            PageHeaderSimpleAction('EXPORT-XLS', 'XLS')
            .withDisabled(() => !this.shouldShowBigTable),            
        ];
    },    
    shouldShowBigTable() {
      return !this.$isSmallScreen && this.bigTableRows?.length;
    },
    shouldShowSmallTable() {
      return this.$isSmallScreen && this.rows?.length;
    },
    exportConfig() {
      return {
        pdf: true, 
        xls: true, 
        name: this.params.title, 
        title: this.params.group + ' - ' + this.params.title,
        dispatch: (this.count ? this.getExport : this.getExport),
        printMaklerLogo: '1',
      };
    },
    styleCaret1() {
      return !this.awayHeaders.length ? 'display: none;' : 'float: right;';
    },
    styleCaret2() {
        return this.noScrollColumns || this.firstColWidth < 1? 'display: none;' : 'display: block;left: ' + (this.firstColWidth - 22)+ 'px';
    },
    sortedManually() {
        return {
            key: this.sorted?.key,
            ascending: this.sorted?.sortDirection != 'desc',
        };
    },
    aktDeepTitle() {
      return this.viewData.param_title  || this.deepTitle[0];
    },
    prevDeepTitle() {
      return this.deepTitle.length > 1 ? this.deepTitle[1] : '';
    },
    tableTitle() {
      return this.deepTitle?.length && this.deepTitle[0] || '';
    }
  },
  watch: {
    'viewData': 'onChangeViewData'
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(BROKER_STATISTICS_TYPES.MUTATIONS.SET_TABLE_VIEWER,  {action: 'reset' } );
    next();
  },
  methods: {
    onClickHeader(headerMetadata) {
        this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
    },    
    generateHeadersForBigTable() {
      if (!this.viewData || !this.viewData.table || !this.viewData.table.headers) return;
      
      const typeMapping = {
        STRING: STRING_COLUMN,
        EURO: CURRENCY_COLUMN,
        PERCENTAGE: FLOAT_COLUMN,
        LONG: NUMBER_COLUMN,
        DOUBLE: FLOAT_COLUMN,
        DATE: DATETIME_COLUMN
      };
      
      let totalWidth = 0;
      const defaultWidth = 200;
      const clientAreaWidth = 1774;
      
      const firstColumnIsMSCButton = this.viewData.table.headers[0].type === 'BUTTON';
      let headers = this.viewData.table.headers.filter(header => header.type !== "BUTTON");
      
      // Calculate total width based on default width
      totalWidth = headers.length * defaultWidth;
      
      let columnWidth = totalWidth >= clientAreaWidth ? defaultWidth : Math.floor(clientAreaWidth / headers.length);
      
      this.bigTableHeaders = headers.map((header, index) => {
        return {
          label: header.label,
          key: `key${index}`,
          width: columnWidth,
          type: index === 0 && firstColumnIsMSCButton ? SLOT_COLUMN : typeMapping[header.dataType] || STRING_COLUMN,
          ...(index === 0 ? { position: FIXED_LEFT } : { visible: true })
        };
      });
    },    
    generateRowsForBigTable() {
      if (!this.viewData || !this.viewData.table || !this.viewData.table.records) return;

      const firstColumnIsMSCButton = this.viewData.table.headers[0].type==='BUTTON';
      
      this.bigTableRows = this.viewData.table.records
      .filter(row => !row.some(item => item.s_value === "Summe"))
      .map((record, rowIndex) => {
        let rowData = { index: `r${rowIndex}`, rowIndex };
        
        const headers = this.viewData.table.headers;
        headers.forEach((header, colIndex) => {
          if (firstColumnIsMSCButton) {
            rowData[`key${colIndex}`] = record[colIndex + 1]?.value;
          } else {
            rowData[`key${colIndex}`] = record[colIndex]?.value;
          }
        });

        this.ixCell0 = firstColumnIsMSCButton ? 1 : 0;
        
        return rowData;
      });

      this.footerMetadata = this.viewData.table.records.filter(row => row.some(item => item.s_value === "Summe"))
      .map((record, rowIndex) => {
        let rowData = { index: `r${rowIndex}`, rowIndex };
        
        const headers = this.viewData.table.headers;
        headers.forEach((header, colIndex) => {
          if (firstColumnIsMSCButton) {
            rowData[`key${colIndex}`] = record[colIndex + 1]?.value;
          } else {
            rowData[`key${colIndex}`] = record[colIndex]?.value;
          }
        });

        return rowData;
      })[0];

    },
    async getExportBigTable(fileEnding) {
      let name = this.viewData.param_title;

      if (name.indexOf(' - ') != -1) {
        name = name.substring(name.indexOf(' - ') + 3)
      }
      
      if (this.viewData.file_id) {
        const params = {
            create: fileEnding,
            filename: name + '.' + fileEnding,
            title: this.viewData.param_title,
            columns: this.bigTableHeaders.map(header => header.label),
            file_id: '' + this.viewData.file_id
        };
        const response = await  this.$store.dispatch(BROKER_STATISTICS_TYPES.ACTIONS.RETRIEVE_EXPORT_DATA, params);

        if (response.data.bytes) {        
          viewDocument({
            data: response.data.bytes,
            filename: name,
            contentType: `application/${fileEnding}`,
          }, true);
        } else if (response.data.tempFileId) {
          this.tempPdfId = response.data.tempFileId;
          this.$refs.modalDownloadPin.open();
        }

      } else {
        let exportRows = this.bigTableRows.map(row => {

          return this.bigTableHeaders.map( (header) => {
            let value = '';

            switch (header.type) {
                  case CURRENCY_COLUMN:
                    value = formatNumber(row[header.key], 2) + '€';
                    break;
                  case FLOAT_COLUMN:
                  case NUMBER_COLUMN:
                    value = formatNumber(row[header.key], header.nachkomma || 2);
                    break;
                  case INTEGER_COLUMN:
                    value = formatNumber(row[header.key], 0);
                    break;
                  case DATETIME_COLUMN:
                    value = row[header.key];
                    break;
                
                  default:
                    value = row[header.key];
                    break;
                }

              return value;
          });

        });

        const params = {
            create: fileEnding,
            filename: name + '.' + fileEnding,
            title: this.viewData.param_title,
            columns: this.bigTableHeaders.map(header => header.label),
            rows: exportRows,
            printMaklerLogo: '1',
            colsNoFormat: 'Jahr',
        };
        const response = await   this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params);
        
        if (response.data.bytes) {        
          viewDocument({
            data: response.data.bytes,
            filename: name,
            contentType: `application/${fileEnding}`,
          }, true);
        } else if (response.data.tempFileId) {
          this.tempPdfId = response.data.tempFileId;
          this.$refs.modalDownloadPin.open();
        }        

      }

    },
    getExport(fileEnding) {
      let name = this.viewData.param_title;
      if (name.indexOf(' - ') != -1) {
        name = name.substring(name.indexOf(' - ') + 3)
      }

      if (this.viewData.file_id) {
        const params = {
            create: fileEnding,
            filename: name + '.' + fileEnding,
            title: this.viewData.param_title,
            columns: this.exportCols.map(header => header.label),
            file_id: '' + this.viewData.file_id
        };
        return this.$store.dispatch(BROKER_STATISTICS_TYPES.ACTIONS.RETRIEVE_EXPORT_DATA, params);
      } else {
        let exportRows;
        if (this.$refs?.viewstatistik?.$refs?.sortableTable?.exportedRows) {
          const hdrs = this.viewData.table.headers;
          const cix = hdrs[0].type=='BUTTON' ? 1 : 0;
          exportRows = this.$refs.viewstatistik.$refs.sortableTable.exportedRows
          .map(row => {
            return hdrs.filter(h => h.type!='BUTTON').map( (h, ix) => {
              let value = '';
              if (ix == 0) {
                value = this.viewData.table.records[row.key0][cix].value;
              } else {
                switch (h.dataType) {
                  case 'EURO':
                    value = formatNumber(row['key'+ix], 2) + '€';
                    break;
                  case 'PERCENTAGE':
                    value = formatNumber(row['key'+ix], 2) + '%';
                    break;
                  case 'DOUBLE':
                    value = formatNumber(row['key'+ix], h.nachkomma || 2);
                    break;
                  case 'LONG':
                    value = formatNumber(row['key'+ix], 0);
                    break;
                  case 'DATE':
                    value = row['key'+ix];
                    break;
                
                  default:
                    value = row['key'+ix];
                    break;
                }
              }
              return value;
            })
          })
        } else {
          exportRows = this.exportRows;
        }
        const params = {
            create: fileEnding,
            filename: name + '.' + fileEnding,
            title: this.viewData.param_title,
            columns: this.exportCols.map(header => header.label),
            rows: exportRows,
            printMaklerLogo: '1',
            colsNoFormat: 'Jahr',
        };
        return this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params);
      }
    },
    async onPageChange(page) {
      this.displayTable = false;
      const id = this.viewData.file_id;
      const response = await this.$store.dispatch(BROKER_STATISTICS_TYPES.ACTIONS.RETRIEVE_PAGE_VIEWER, {id, page});
      if (response.status === 200 && response?.data?.records) {
        this.viewData.table.records = response.data.records;
        this.setRows();
        this.page = response.data.page || page;
        this.pageRows = response.data.records.length;
        this.displayTable = true;
      }
    },           
    onChangeViewData() {
      if ( this.viewData.loading || !this.viewData.table) {

        if (this.$isSmallScreen) {
          this.rows = [];
        } else {
          this.bigTableRows = [];
        }

        if (this.viewData.temp_file_id){
          this.tempFileId = this.viewData.temp_file_id;
          this.tempFileName = this.viewData.temp_file_name;
          this.fileMitPIN = this.viewData.temp_file_pin === '1';
          this.$refs.modalDownloadPin.open();
        }        

        return;
      }

      this.tableId = 'Vermittler-Statistiken-' + this.viewData.param_statistikinternid;

      if (this.$isSmallScreen) {
        this.doDataProcessingForSmallScreens();

      } else {
        this.generateHeadersForBigTable();
        this.generateRowsForBigTable();
      }

    },
    doDataProcessingForSmallScreens() {
      this.awayHeaders = [];
      this.awayFistKey = [];
      const lockedLeft = [];
      const center = [];
      this.exportCols = [];
      
      let hasSubstaitistik = false, key;

      this.viewData.table.headers.forEach((col, k) => {
        if (k === 0 && col.type==='BUTTON') {
          hasSubstaitistik = true;
        } else {
          key = hasSubstaitistik ? 'key' + (k - 1) : 'key' + k;
          this.exportCols.push(TextColumn(key, col.label));
          if (lockedLeft.length === 0) {
            const colLeft = SlotColumn(key, col.label, 200, 0)
            .makeSortable(this.slotToSortable)
            .makeAlwaysVisible()
            .withExporter((obj) => this.exportRows[obj][0]);
            colLeft.fixedWidth = -1;
            colLeft.minTitleWidth = 6*col.label.length + 64;
            lockedLeft.push(colLeft);
          } else {
            if ( col.dataType === 'STRING'){
              center.push( TextColumn(key, col.label) );
            } else if ( col.dataType === 'EURO'){
              center.push( CurrencyColumn(key, col.label) );
            } else if ( col.dataType === 'PERCENTAGE'){
              center.push( PercentageColumn(key, col.label) );
            } else if ( col.dataType === 'LONG'){
              center.push( NumberColumn(key, col.label, 0) );
            } else if ( col.dataType === 'DOUBLE'){
                if (col.label === 'Interne Vertragsnummer') {
                    center.push( NumberColumn(key, col.label, 0).addCellProps({separator: ""}) );
                } else {
                    center.push( NumberColumn(key, col.label, col.nachkomma || 2) );
                }
            } else if ( col.dataType === 'DATE'){
              center.push( DateColumn(key, col.label) );
            }
          }
        }
      });

      this.headers = {
        lockedLeft: lockedLeft,
        center: center,
        lockedRight: [
          ActionColumn("actions", null),
        ]
      };

      if (this.viewData.rowCount) {
        this.page = this.viewData.page || -1;
        this.count = this.viewData.count || 0;
        this.rowCount = this.viewData.rowCount || 0;
        this.pageRows = this.viewData.pageRows || 0;
        this.hidePagination = true;
      } else {
        this.hidePagination = false;
      }
        
      this.setRows();
    },
    setRows() {
      const rows = [];
      const hasSubstaitistik = this.viewData.table.headers[0].type==='BUTTON';
      this.exportRows = [];
      let data, dataExp;
      this.viewData.table.records.forEach((row, k) => {
        data = {index: 'r'+k};
        dataExp = [];
        row.forEach( (r, j) => {
          if (hasSubstaitistik) {
            if (j === 0) {
              // skip
            } else if (j === 1) {
              data.key0 = k;
              dataExp.push(r.s_value);
            } else {
              data['key' + (j - 1)] = r.value;
              dataExp.push(r.s_value);
            }
          } else {
            if (j === 0) {
              data.key0 = k;
              dataExp.push(r.s_value);
            } else {
              data['key' + j] = r.value;
              dataExp.push(r.s_value);
            }
          }
        })
        rows.push(data);
        this.exportRows.push(dataExp);
      });
      this.ixCell0 = hasSubstaitistik ? 1 : 0;
      this.rows = rows;
    },
    initScrollCols(toLeft) {
      if (this.scrollTo === '') {
        this.scrollCols(toLeft);
      }
    },
    scrollCols(toLeft) {
      const center = [].concat( this.headers.center);
      if (toLeft) {
        if (this.noScrollColumns) {
          this.scrollTo = '';
          return;
        }
        if ( !this.scrollTo){
          const len = Math.max(1, this.keysTH.length - 3);
          this.scrollTo = center[len].key;
          this.awayFistKey.unshift(center[0].key);
        }
        const h = center.shift();
        this.awayHeaders.unshift(h);
        if (center[0].key !== this.scrollTo ) {
          setTimeout(() => {
            this.scrollCols(toLeft);
          }, 0);
        } else {
          this.scrollTo = '';
        }
      } else {
        if (this.awayHeaders.length === 0) {
          this.scrollTo = '';
          return;
        }
        if (!this.scrollTo){
          this.scrollTo = this.awayFistKey.shift();
        }
        const h = this.awayHeaders.shift();
        center.unshift(h);
        if (this.scrollTo && center[0].key !== this.scrollTo){
          setTimeout(() => {
            this.scrollCols(toLeft);
          }, 0);
        } else {
          this.scrollTo = '';
        }
      }
      this.headers.center = center.map( (c,k) => ({...c, neverHide: k === 0 }));
    },
    slotToSortable(key0) {
      return ('' + this.viewData.table.records[key0][this.ixCell0].value).toUpperCase();
    },    
    toNextLevel(idx) {
      const load = this.viewData.table.records[idx][0].load;
      const convert = this.viewData.convert;
      const payload = {title: this.viewData.table.records[idx][1].value};
      Object.keys(load).forEach( key => {
        if (convert[key] !== undefined) {
          payload[convert[key]] = load[key];
        }
      })
      this.deepTitle.unshift(payload.title);
      this.$store.commit(BROKER_STATISTICS_TYPES.MUTATIONS.SET_DEEP_TITLE, this.deepTitle);
      this.$store.dispatch(BROKER_STATISTICS_TYPES.ACTIONS.RETRIEVE_TABLE_VIEWER, payload);
    },
    toPreviusLevel() {
      this.displayTable = false;
      setTimeout(() => {
        this.$store.commit(BROKER_STATISTICS_TYPES.MUTATIONS.SET_TABLE_VIEWER,  {action: 'decrement' } );
        this.deepTitle.shift();
        this.$store.commit(BROKER_STATISTICS_TYPES.MUTATIONS.SET_DEEP_TITLE, this.deepTitle);
        this.displayTable = true;
      }, 0);
    },
    async downloadPinFile() {
      const error = await this.$store.dispatch(CORE_TYPES.ACTIONS.DOWNLOAD_PIN_FILE, {
        fileMitPIN: this.fileMitPIN,
        tempFilePin: this.tempFilePin,
        tempFileId: this.tempFileId,
        fileType: 'csv'
      });

      if (!error) {
          this.$refs.modalDownloadPin.close();
          this.onCloseModal();
      } else {
        this.errorPinFile = error;
      }

    },
    onCloseModal() {
        this.tempFileId = null;
        this.tempFilePin = '';
        this.errorPinFile = ''
    },
  },
}
</script>

<style scoped>
.locked-left {
  display: inline-block;
  position: relative;
}
.locked-left .scrollColumns {
  position: absolute;
  top: 0;
  right: -40px;
}
.scrollColumns {
  background-color: var(--color-primary);
  color: var(--color-primary-text);
  text-align: center;
  font-size: 1em;
  text-decoration: none;
  display: block;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 20px;
}
.table-pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 11pt;
  padding: 16px 0 12px;
}
.titel {
  display: flex;
}

.titel > span {
  flex-grow: 1;
}
.titel > a {
  color: var(--color-text);
  font-size: 90%;
}
::v-deep .title-always-visibe > table {
  position: absolute
}

@media screen and (max-width: 640px) {
.titel {
  flex-direction: column;
}
}
</style>