<template>
  <div class="carousel__container" :class="carouselClasses" v-resize-observer="handleResize">
    <div class="carousel__body" :style="bodyStyle">
      <div v-if="!asGrid" class="carousel__arrow carousel__arrow--left" 
        :style="arrowContainerStyle">
        <button type="button" class="clickable" @click="prevItems()" :disabled="!hasPrevItems" :tid="_generateTidFromString($route.path+'ph-caret-left')+'|autoclick'">
          <PhCaretLeft :size="settings.arrows.size" alt="Icon zur Navigation nach Links"/>
        </button>
      </div>
      <div class="carousel__content" @mousedown="handleInitialScroll($event)" @touchstart="handleInitialScroll($event)">
        <div class="carousel__content--scroll" 
          :class="{'carousel__content--scroll-animated': isScrollAnimated}"
          :style="contentScrollStyle">
          <CarouselItem v-for="(item, index) of items" :key="index" :tid="getTid(item)"
            :item="item" :settings="settings" :asGrid="asGrid" />
        </div>
      </div>
      <div v-if="!asGrid" class="carousel__arrow carousel__arrow--right" 
        :style="arrowContainerStyle">
        <button type="button" class="clickable" @click="nextItems()" :disabled="!hasNextItems"  :tid="_generateTidFromString($route.path+'ph-caret-right')+'|autoclick'">
          <PhCaretRight :size="settings.arrows.size" alt="Icon zur Navigation nach Rechts"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BrowserSupport from '@/browser-support';

import { PhCaretLeft, PhCaretRight, } from 'phosphor-vue';
import CarouselItem from './CarouselItem.vue';

import { deepAssign } from '@/helpers/commonfunctions.js';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

const WHEEL_EVENT_NAME = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
const PASSIVE_EVENT_OPTIONS = BrowserSupport.supportsPassive ? { passive: true, } : false;
const NO_PASSIVE_EVENT_OPTIONS = BrowserSupport.supportsPassive ? { passive: false, cancelable: true, } : false;

const ARROW_CONTAINER_WIDTH_PADDING = 12;
const CONTENT_SELECTOR = '.carousel__content';
const CONTENT_SCROLL_SELECTOR = '.carousel__content--scroll';
const ACTIVE_ITEM_SELECTOR = '.item-active';
const MIN_INITIAL_SWIPE_SIZE = 32;
const HORIZONTAL_SCROLL_PADDING = 32;

const SCROLL_SPEED = 100;

const DEFAULT_OPTIONS = {
  item: {
    width: 128,
    height: 96,
  },
  arrows: {
    size: 24,
  },
};

const KEEP_HORIZONTAL_SIZE = new Map();

function trackHorizontalSize(id, size) {
  if (id) {
    KEEP_HORIZONTAL_SIZE.set(id, size);
  }
}

function clearHorizontalSizeKeptIfNeeded(id) {
  if (!KEEP_HORIZONTAL_SIZE.has(id)) { // if id do not exists clear previous data
    KEEP_HORIZONTAL_SIZE.clear();
  }
}

function eventCoord(event, coord) {
  let eventCoords = event;

  if(event.targetTouches && event.targetTouches.length) {
    eventCoords = event.targetTouches[0];
  } else if(event.changedTouches && event.changedTouches.length) {
    eventCoords = event.changedTouches[0];
  }

  return eventCoords[coord];
}

function clearSelection() {
  const selection = 'getSelection' in window 
    ? window.getSelection
    : document.selection;

  if(selection?.empty) {
    selection.empty();
  } else if(selection?.removeAllRanges) {
    selection.removeAllRanges();
  }
}

export default {
  mixins: [
    InteractiveHelpCommonsMixin,
  ],  
  props: {
    id: {
      type: String,
      default: '',
    },
    values: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    },
    asGrid: {
      type: Boolean,
      default: false,
    },
    ariaDescription: {
      type: String,
      default: 'Icon zur Navigation'
    },
  },
  emits: ['ready'],
  data() {
    return {
      initRequestAnimationId: null,
      hasOverflow: false,
      scroll: {
        active: false,
        maxHorizontalSize: 0,
        horizontalSize: KEEP_HORIZONTAL_SIZE.get(this.id) || 0,
      },
      settings: deepAssign({}, DEFAULT_OPTIONS, this.options),
    };
  },
  computed: {
    carouselClasses() {
      return {
        'carousel__as-grid': this.asGrid,
        'has-overflow': this.hasOverflow,
      };
    },
    items() {
      return this.values || [];
    },
    hasActiveItem() {
      return this.items.some(item => !!item.textBold);
    },
    hasPrevItems() {
      return this.scroll.horizontalSize < 0;
    },
    hasNextItems() {
      return Math.abs(this.scroll.maxHorizontalSize) > Math.abs(this.scroll.horizontalSize);
    },
    bodyStyle() {
      return {
        'flex-basis': `${this.settings.item.height}px`,
      };
    },
    arrowContainerStyle() {
      return {
        'flex-basis' : `${this.settings.arrows.size + ARROW_CONTAINER_WIDTH_PADDING}px`,
      };
    },
    isScrollAnimated() {
      return this.scroll.active !== true;
    },
    contentScrollStyle() {
      const itemWidth = this.settings.item.width;
      return {
        'flex-basis': `${itemWidth}px`,
        ...(this.asGrid ? { 
          'grid-template-columns': `repeat(auto-fill, ${itemWidth}px)`, 
        } : {
          'transform': `translateX(${this.scroll.horizontalSize}px)`,
        }),
      };
    },
  },
  watch: {
    items() {
      this.init(true);
    },
    asGrid() {
      if (this.asGrid) {
        this.resetHorizontalSize();
      }
    },
  },
  methods: {
    init(forceFocusOnActiveItem = false) {
      clearHorizontalSizeKeptIfNeeded(this.id);

      cancelAnimationFrame(this.initRequestAnimationId);
      this.initRequestAnimationId = requestAnimationFrame(() => {
        this.prepareCarousel(forceFocusOnActiveItem);
      });
    },
    handleResize() {
      this.init(true);
    },
    prepareCarousel(forceFocusOnActiveItem = false) {
      if (this.asGrid) return;

      const contentEl = this.$el.querySelector(CONTENT_SELECTOR);
      const contentScrollEl = this.$el.querySelector(CONTENT_SCROLL_SELECTOR);

      this.hasOverflow = contentScrollEl.clientWidth > contentEl.clientWidth;

      requestAnimationFrame(() => {
        const contentWidth = contentEl.clientWidth;
        const contentScrollWidth = contentScrollEl.clientWidth;

        const contentDiffWidth = contentWidth - contentScrollWidth;
  
        this.scroll.maxHorizontalSize = contentDiffWidth < 0 ? contentDiffWidth : 0;

        if (this.hasActiveItem && (!KEEP_HORIZONTAL_SIZE.has(this.id) || forceFocusOnActiveItem)) { // focus on active item
          this.scrollToActiveItem();
        } else { // keep scroll position
          this.horizontalScroll(this.scroll.horizontalSize);
        }
      });

      this.emitReady();
    },
    prevItems() {
      if(!this.hasPrevItems) return ;

      requestAnimationFrame(() => {
        const contentEl = this.$el.querySelector(CONTENT_SELECTOR);
        const halfContentWidth = contentEl.clientWidth / 2;
        this.horizontalScroll(this.scroll.horizontalSize + halfContentWidth);
      });
    },
    nextItems() {
      if(!this.hasNextItems) return ;

      requestAnimationFrame(() => {
        const contentEl = this.$el.querySelector(CONTENT_SELECTOR);
        const halfContentWidth = contentEl.clientWidth / 2;
        this.horizontalScroll(this.scroll.horizontalSize + -halfContentWidth);
      });
    },
    preventDefault(event) {
      if(this.scroll?.active) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    handleInitialScroll(event) {
      if(this.asGrid) return;

      let startClientX = eventCoord(event, 'clientX');
      let startClientY = eventCoord(event, 'clientY');

      const startHorizontalSize = this.scroll.horizontalSize;
      let startTime = 0;

      const preventDefault = (e) => {
        e.preventDefault();
        e.stopPropagation();
      };

      const handleInitialScrollMove = (e) => {
        const clientX = eventCoord(e, 'clientX');
        const clientY = eventCoord(e, 'clientY');
        const elementFromPoint = document.elementFromPoint(clientX, clientY);
        if(!this._isElementOnComponentContext(elementFromPoint)) { // cancel scroll, when touch / mouse moves outside the component context
          handleInitialScrollEnd();
        } else {
          const currentClientX = eventCoord(e, 'clientX');
          const currentClientY = eventCoord(e, 'clientY');
          const horizontalSwipeSize = Math.abs(currentClientX - startClientX);
          const verticalSwipeSize = Math.abs(currentClientY - startClientY);
          if(horizontalSwipeSize >= MIN_INITIAL_SWIPE_SIZE) { // start scroll
            handleInitialScrollEnd();
            clearSelection();
            handleScrollStart(e);
          } else if(verticalSwipeSize > MIN_INITIAL_SWIPE_SIZE) { // cancel scroll, when touch / mouse moves too long on vertical
            handleInitialScrollEnd();
          }
        }
      };

      const handleScrollStart = (event) => {
        if(this.asGrid) return;

        // update startClientX to the new clientX
        startClientX = eventCoord(event, 'clientX');

        startTime = new Date().getTime();

        // active scroll
        this.scroll.active = true;

        // add class
        document.querySelector('html').classList.add('carousel-scrolling');

        // add scroll events
        window.addEventListener('scroll', this.preventDefault, NO_PASSIVE_EVENT_OPTIONS);
        document.addEventListener('DOMMouseScroll', this.preventDefault, NO_PASSIVE_EVENT_OPTIONS);
        document.addEventListener(WHEEL_EVENT_NAME, this.preventDefault, NO_PASSIVE_EVENT_OPTIONS);

        // add touch / mouse events
        document.addEventListener('touchmove', handleScrollMove, NO_PASSIVE_EVENT_OPTIONS);
        document.addEventListener('touchend', handleScrollEnd);
        document.addEventListener('mousemove', handleScrollMove, NO_PASSIVE_EVENT_OPTIONS);
        document.addEventListener('mouseup', handleScrollEnd);
        document.addEventListener('selectstart', this.preventDefault);
      };

      const handleInitialScrollEnd = () => {
        // remove scroll events
        window.removeEventListener('scroll', handleInitialScrollEnd, PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('DOMMouseScroll', handleInitialScrollEnd, PASSIVE_EVENT_OPTIONS);
        document.removeEventListener(WHEEL_EVENT_NAME, handleInitialScrollEnd, PASSIVE_EVENT_OPTIONS);

        // remove touch / mouse events
        document.removeEventListener('touchmove', handleInitialScrollMove);
        document.removeEventListener('touchend', handleInitialScrollEnd);
        document.removeEventListener('mousemove', handleInitialScrollMove);
        document.removeEventListener('mouseup', handleInitialScrollEnd);
        document.removeEventListener('selectstart', preventDefault);
      };

      const handleScrollEnd = (event) => {
        // inactive scroll
        this.scroll.active = false;

        // scroll
        const deltaTime = new Date().getTime() - startTime;
        const deltaX = Math.abs(startClientX - eventCoord(event, 'clientX'));
        const scrollVelocity = (deltaX / deltaTime) * SCROLL_SPEED;
        const scrollDirection = startClientX - eventCoord(event, 'clientX') < 0 ? 1 : -1;
        this.horizontalScroll(this.scroll.horizontalSize + (scrollVelocity * scrollDirection));

        // remove class
        document.querySelector('html').classList.remove('carousel-scrolling');

        // remove scroll events
        window.removeEventListener('scroll', this.preventDefault, NO_PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('DOMMouseScroll', this.preventDefault, NO_PASSIVE_EVENT_OPTIONS);
        document.removeEventListener(WHEEL_EVENT_NAME, this.preventDefault, NO_PASSIVE_EVENT_OPTIONS);

        // remove touch / mouse events
        document.removeEventListener('touchmove', handleScrollMove, NO_PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('touchend', handleScrollEnd);
        document.removeEventListener('mousemove', handleScrollMove, NO_PASSIVE_EVENT_OPTIONS);
        document.removeEventListener('mouseup', handleScrollEnd);
        document.removeEventListener('selectstart', this.preventDefault);
      };

      const handleScrollMove = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const currentClientX = eventCoord(event, 'clientX');
        const movedClientX = currentClientX - startClientX;

        this.horizontalScroll(startHorizontalSize + movedClientX, HORIZONTAL_SCROLL_PADDING);
      };

      // add scroll events
      window.addEventListener('scroll', handleInitialScrollEnd, PASSIVE_EVENT_OPTIONS);
      document.addEventListener('DOMMouseScroll', handleInitialScrollEnd, PASSIVE_EVENT_OPTIONS);
      document.addEventListener(WHEEL_EVENT_NAME, handleInitialScrollEnd, PASSIVE_EVENT_OPTIONS);

      // remove touch / move events
      document.addEventListener('touchmove', handleInitialScrollMove);
      document.addEventListener('touchend', handleInitialScrollEnd);
      document.addEventListener('mousemove', handleInitialScrollMove);
      document.addEventListener('mouseup', handleInitialScrollEnd);
      document.addEventListener('selectstart', preventDefault);
    },
    horizontalScroll(size, offset = 0) {
      if (this.asGrid)  return;

      let checkedSize = size;
      if(size > offset) {
        checkedSize = offset;
      } else if(size < this.scroll.maxHorizontalSize - offset) {
        checkedSize = this.scroll.maxHorizontalSize - offset;
      }

      this.scroll.horizontalSize = checkedSize;
      trackHorizontalSize(this.id, checkedSize);
    },
    resetHorizontalSize() {
      KEEP_HORIZONTAL_SIZE.clear();
      this.scroll.horizontalSize = 0;
    },
    scrollToActiveItem() {
      const contentEl = this.$el.querySelector(CONTENT_SELECTOR);
      const contentScrollEl = this.$el.querySelector(CONTENT_SCROLL_SELECTOR);
      const activeItemEl = contentEl.querySelector(ACTIVE_ITEM_SELECTOR);
      if (!activeItemEl) return;

      requestAnimationFrame(() => {
        const contentBounding = contentEl.getBoundingClientRect();
        const contentScrollBounding = contentScrollEl.getBoundingClientRect();
        const activeItemBounding = activeItemEl.getBoundingClientRect();
        const scrollSize = (activeItemBounding.left - activeItemBounding.width / 2) - 
          (contentScrollBounding.left - contentBounding.left + contentBounding.width / 2); // keeps active item at center

        this.horizontalScroll(-scrollSize);
      });
    },
    _isElementOnComponentContext(element) {
      if(!element) return false;

      const rootEl = this.$el;
      return element === rootEl || element.closest?.('.carousel__container') === rootEl;
    },
    emitReady() {
      this.$emit('ready', {
        hasOverflow: this.hasOverflow,
      });
    },
    getTid(item) {
      return this._generateTidFromString(item.label + item.component.__file);
    },
  },
  mounted() {
    this.init();
  },
  components: {
    PhCaretLeft, 
    PhCaretRight,
    CarouselItem
  }
}
</script>

<style scoped>
* {
  user-select: none;
}

.carousel__container {
  display: flex;
  flex-direction: column;
}
.carousel__body {
  display: flex;
  flex: 0 0 120px;
}
.carousel__arrow {
  display: none;
}
.has-overflow .carousel__arrow {
  flex: 0 0 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel__arrow button {
  background: none;
  border: none;
  color: var(--color-secondary);
  margin: 0;
  padding: 0;
}
.carousel__arrow button[disabled="disabled"] {
  cursor: not-allowed;
  opacity: 0.4;
}
.carousel__content {
  display: flex;
  flex: 1 1 auto;
  margin: 0;
  overflow: hidden;
}
.carousel__content--scroll {
  flex: 1 0 164px; /* 0 1 (140 + 12 + 12)px */
  display: flex;
  margin: 0;
  will-change: transform;

}
.carousel__content--scroll-animated {
  transition: transform .3s ease-out;
}

.carousel__as-grid .carousel__content--scroll {
  display: grid;
  justify-content: space-around;
}

.carousel-scrolling .carousel__content,
.carousel-scrolling .carousel__content * {
  pointer-events: none !important;
}

@media screen and (max-width: 767px) {
  .has-overflow .carousel__arrow {
    display: none;
  }
}
</style>
