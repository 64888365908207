import OPEN_SIGNS_TYPES from './types';
import Vue from 'vue';
import { getInitialState } from './index'

export default {
  [OPEN_SIGNS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },

  [OPEN_SIGNS_TYPES.MUTATIONS.GET_OPEN_SIGNS_SUCCESS](state, payload) {
    // remove items already present
    const currentDokIds = state.openSignatures?.offeneUnterschriften?.map(item => item.dokId) || []
    const filtered = payload?.filter?.(item => !currentDokIds.includes(item.dokId))

    Vue.set(state.openSignatures, 'offeneUnterschriften', [
      ...state.openSignatures?.offeneUnterschriften || [],
      ...filtered || [],
    ]);
  },

  [OPEN_SIGNS_TYPES.MUTATIONS.SET_OPEN_SIGNS_COUNT](state, payload) {
    Vue.set(state.openSignatures, 'count', payload);
    // set an initial value for the list
    Vue.set(state.openSignatures, 'offeneUnterschriften', [ ...state.openSignatures?.offeneUnterschriften || [], ]);
  },

  [OPEN_SIGNS_TYPES.MUTATIONS.SET_IS_COUNTING](state, payload) {
    Vue.set(state, 'isCounting', payload);
  },
  
  [OPEN_SIGNS_TYPES.MUTATIONS.RESET_OPEN_SIGNATURES](state) {
    Vue.set(state, 'openSignatures', {
      count: state.openSignatures?.count || 0,
      offeneUnterschriften: [], // set an empty array to avoid reload the counter on places that rely on it
    })
  },

  [OPEN_SIGNS_TYPES.MUTATIONS.OPEN_SIGNATURES_REMOVE_VORLAGE_SUCCESS](state, dokId) {
    const openSigns = state.openSignatures?.offeneUnterschriften;
    if (openSigns?.length && dokId) {
        state.openSignatures.offeneUnterschriften = openSigns.filter(sign => sign?.dokId !== dokId);
        if (state.openSignatures?.count > 0) {
          state.openSignatures.count--;
        }
    }
  },

  [OPEN_SIGNS_TYPES.MUTATIONS.SET_IS_LOADING](state, isLoading) {
    Vue.set(state, 'isLoading', isLoading)
  },

  [OPEN_SIGNS_TYPES.MUTATIONS.SET_HAS_OPEN_SIGNS](state, payload) {
    Vue.set(state, 'hasOpenSigns', payload);
  },

}
