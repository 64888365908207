export const MUTATION_PREFIX = 'CUSTOMER_SEARCH_MUTATIONS_';
export const ACTIONS_PREFIX = 'CUSTOMER_SEARCH_ACTIONS_';
export const GETTERS_PREFIX = 'CUSTOMER_SEARCH_GETTERS_';

export default {
  MUTATIONS: {
    SEARCH_CUSTOMER_SUCCESS: MUTATION_PREFIX + 'SEARCH_CUSTOMER_SUCCESS',
    SEARCH_CUSTOMER_FAILTURE: MUTATION_PREFIX + 'SEARCH_CUSTOMER_FAILTURE',

    SEARCH_BROKER_SUCCESS: MUTATION_PREFIX + 'SEARCH_BROKER_SUCCESS',
    SEARCH_BROKER_FAILTURE: MUTATION_PREFIX + 'SEARCH_BROKER_FAILTURE',
    
    SEARCH_CONTACT_SUCCESS: MUTATION_PREFIX + 'SEARCH_CONTACT_SUCCESS',
    SEARCH_CONTACT_FAILTURE: MUTATION_PREFIX + 'SEARCH_CONTACT_FAILTURE',

    GET_SEARCH_CUSTOMER_SETUP_SUCCESS: MUTATION_PREFIX + 'GET_SEARCH_CUSTOMER_SETUP_SUCCESS',
    GET_SEARCH_CUSTOMER_SETUP_FAILTURE: MUTATION_PREFIX + 'GET_SEARCH_CUSTOMER_SETUP_FAILTURE',

    GET_SEARCH_CUSTOMER_SETUP_DEPOT_SUCCESS: MUTATION_PREFIX + 'GET_SEARCH_CUSTOMER_SETUP_DEPOT_SUCCESS',
    GET_SEARCH_CUSTOMER_SETUP_DEPOT_FAILTURE: MUTATION_PREFIX + 'GET_SEARCH_CUSTOMER_SETUP_DEPOT_FAILTURE',
    
    GET_SEARCH_CUSTOMER_SETUP_VERS_SUCCESS: MUTATION_PREFIX + 'GET_SEARCH_CUSTOMER_SETUP_VERS_SUCCESS',
    GET_SEARCH_CUSTOMER_SETUP_VERS_FAILTURE: MUTATION_PREFIX + 'GET_SEARCH_CUSTOMER_SETUP_VERS_FAILTURE',
    
    GET_SEARCH_BROKER_SETUP_SUCCESS: MUTATION_PREFIX + 'GET_SEARCH_BROKER_SETUP_SUCCESS',
    GET_SEARCH_CONTACTS_SETUP_SUCCESS: MUTATION_PREFIX + 'GET_SEARCH_CONTACTS_SETUP_SUCCESS',

    GET_BROKER_START_PANELS_SUCCESS: MUTATION_PREFIX + 'GET_BROKER_START_PANELS_SUCCESS',
    GET_BROKER_START_PANELS_FAILTURE: MUTATION_PREFIX + 'GET_BROKER_START_PANELS_FAILTURE',
    OPEN_CUSTOMER: MUTATION_PREFIX + 'OPEN_CUSTOMER',
    UPDATE_LAST_OPENED_CUSTOMER_DATA_ONLY: MUTATION_PREFIX + 'UPDATE_LAST_OPENED_CUSTOMER_DATA_ONLY',
    SET_SELECTED_CUSTOMERS: MUTATION_PREFIX + 'SET_SELECTED_CUSTOMERS',
    RESET_STATE_SEARCH: MUTATION_PREFIX + 'RESET_STATE_SEARCH',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    KUNDEN_BEREINIGEN_SUCCESS: MUTATION_PREFIX + 'KUNDEN_BEREINIGEN_SUCCESS',
    KUNDEN_LOESCHEN_SUCCESS: MUTATION_PREFIX + 'KUNDEN_LOESCHEN_SUCCESS',
    KUNDEN_LOESCHEN_RESTRICTIONS_SUCCESS: MUTATION_PREFIX + 'KUNDEN_LOESCHEN_RESTRICTIONS_SUCCESS',
    RESET_KUNDEN_LOESCHEN_DATA: MUTATION_PREFIX + 'RESET_KUNDEN_LOESCHEN_DATA',
  },
  ACTIONS: {
    SEARCH_CUSTOMER: ACTIONS_PREFIX + 'SEARCH_CUSTOMER',
    SEARCH_CUSTOMER_EXPORT: ACTIONS_PREFIX + 'SEARCH_CUSTOMER_EXPORT',
    SEARCH_BROKER: ACTIONS_PREFIX + 'SEARCH_BROKER',
    SEARCH_CONTACT: ACTIONS_PREFIX + 'SEARCH_CONTACT',
    SEARCH_CONTACT_EXPORT: ACTIONS_PREFIX + 'SEARCH_CONTACT_EXPORT',
    GET_SEARCH_CUSTOMER_SETUP: ACTIONS_PREFIX + 'CUSTOMER_FILTER_SETUP',
    GET_SEARCH_CUSTOMER_SETUP_DEPOT: ACTIONS_PREFIX + 'GET_SEARCH_CUSTOMER_SETUP_DEPOT',
    GET_SEARCH_BROKER_SETUP: ACTIONS_PREFIX + 'GET_SEARCH_BROKER_SETUP',
    GET_SEARCH_CONTACTS_SETUP: ACTIONS_PREFIX + 'GET_SEARCH_CONTACTS_SETUP',
    GET_SEARCH_CUSTOMER_SETUP_VERS: ACTIONS_PREFIX + 'GET_SEARCH_CUSTOMER_SETUP_VERS',
    GET_BROKER_START_PANELS: ACTIONS_PREFIX + 'GET_BROKER_START_PANELS',
    EXPORT_BROKERS: ACTIONS_PREFIX + 'EXPORT_BROKERS',

    TRACK_OPEN_CUSTOMER: ACTIONS_PREFIX + 'TRACK_OPEN_CUSTOMER',
    GET_LAST_CUSTOMERS_OPENED: ACTIONS_PREFIX + 'GET_LAST_CUSTOMERS_OPENED',
    KUNDEN_BEREINIGEN: ACTIONS_PREFIX + 'KUNDEN_BEREINIGEN',
    SET_KUNDEN_INAKTIV: ACTIONS_PREFIX + 'SET_KUNDEN_INAKTIV',
    KUNDEN_LOESCHEN: ACTIONS_PREFIX + 'KUNDEN_LOESCHEN',
    KUNDEN_LOESCHEN_RESTRICTIONS: ACTIONS_PREFIX + 'KUNDEN_LOESCHEN_RESTRICTIONS',
  },
  GETTERS: {
    CUSTOMERS: GETTERS_PREFIX + 'CUSTOMERS',
    SEARCH_CUSTOMER: GETTERS_PREFIX + 'SEARCH_CUSTOMER',
    CUSTOMER_FILTER_SETUP: GETTERS_PREFIX + 'CUSTOMER_FILTER_SETUP',
    CUSTOMER_FILTER_SETUP_DEPOT: GETTERS_PREFIX + 'CUSTOMER_FILTER_SETUP_DEPOT',
    BROKER_FILTER_SETUP: GETTERS_PREFIX + 'BROKER_FILTER_SETUP',
    CONTACT_FILTER_SETUP: GETTERS_PREFIX + 'CONTACT_FILTER_SETUP',
    CUSTOMER_FILTER_SETUP_VERS: GETTERS_PREFIX + 'CUSTOMER_FILTER_SETUP_VERS',
    CUSTOMER_WERTPAPIER_COMBO: GETTERS_PREFIX + 'CUSTOMER_WERTPAPIER_COMBO',
    BROKERS: GETTERS_PREFIX + 'BROKERS',
    BROKER_START_PANELS: GETTERS_PREFIX + 'BROKER_START_PANELS',
    OPEN_CUSTOMERS: GETTERS_PREFIX + 'OPEN_CUSTOMERS',
    SET_SELECTED_CUSTOMERS: GETTERS_PREFIX + 'SET_SELECTED_CUSTOMERS',
    KUNDEN_LOESCHEN: GETTERS_PREFIX + 'KUNDEN_LOESCHEN',
    KUNDEN_LOESCHEN_RESTRICTIONS: GETTERS_PREFIX + 'KUNDEN_LOESCHEN_RESTRICTIONS',
    CONTACTS: GETTERS_PREFIX + 'CONTACTS',
  }
}