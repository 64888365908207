var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DragnDropArea",
    {
      attrs: {
        hoverText: "Datei hier ablegen",
        disabled: _vm.isSomethingLoading
      },
      on: {
        files: function($event) {
          return _vm.dropFiles($event)
        }
      }
    },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.headerActions,
          title: "Besuchsbericht"
        },
        on: {
          "action-UPLOAD_DOCUMENT": _vm.uploadNewDocument,
          "action-SEND": function($event) {
            return _vm.sendBericht()
          }
        }
      }),
      _c("StepperForm", {
        ref: "stepperForm",
        attrs: {
          stepType: "tab",
          stepperName: "customer-data",
          stepperMediator: _vm.tabsStepperMediator,
          hasFertig: false,
          selectedStepKey: _vm.currentStep
        },
        on: {
          "set-step": function($event) {
            return _vm.setStep($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _vm.loading.init
                  ? _c("GhostLoading", {
                      attrs: { useBoxContainer: "", type: "table" }
                    })
                  : [
                      _vm.currentStep === "BERICHT"
                        ? [
                            _c("div", { staticClass: "box__container" }, [
                              _c("div", { staticClass: "box__title" }, [
                                _vm._v("Besucher")
                              ]),
                              _c(
                                "div",
                                { staticClass: "besucher__grid" },
                                _vm._l(_vm.besucherSelection, function(bs) {
                                  return _c("InputCheckboxItem", {
                                    key: bs.value,
                                    attrs: {
                                      id: bs.value,
                                      label: bs.label,
                                      value: _vm.selectedBesucher.includes(
                                        bs.value
                                      )
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.updateBericht(
                                          bs.value,
                                          $event
                                        )
                                      }
                                    }
                                  })
                                }),
                                1
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "box__container" },
                              [
                                _c("DatePickerField", {
                                  attrs: {
                                    label: "Datum",
                                    isComponentHalfSize: "",
                                    isValueAsString: ""
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.datum,
                                    callback: function($$v) {
                                      _vm.$set(_vm.besuchsbericht, "datum", $$v)
                                    },
                                    expression: "besuchsbericht.datum"
                                  }
                                }),
                                _c("TimePicker", {
                                  attrs: { label: "Uhrzeit" },
                                  model: {
                                    value: _vm.besuchsbericht.startTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "startTime",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.startTime"
                                  }
                                }),
                                _c("TimePicker", {
                                  attrs: { label: "bis" },
                                  model: {
                                    value: _vm.besuchsbericht.endTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "endTime",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.endTime"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: { label: "Ort" },
                                  model: {
                                    value: _vm.besuchsbericht.ort,
                                    callback: function($$v) {
                                      _vm.$set(_vm.besuchsbericht, "ort", $$v)
                                    },
                                    expression: "besuchsbericht.ort"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: { label: "Anwesende" },
                                  model: {
                                    value: _vm.besuchsbericht.anwesende,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "anwesende",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.anwesende"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: { label: "Thema/Betreff" },
                                  model: {
                                    value: _vm.besuchsbericht.thema,
                                    callback: function($$v) {
                                      _vm.$set(_vm.besuchsbericht, "thema", $$v)
                                    },
                                    expression: "besuchsbericht.thema"
                                  }
                                }),
                                !_vm.isGesellschaft
                                  ? [
                                      _c("InputRadioBoxGroup", {
                                        attrs: {
                                          title: "Kontakt",
                                          values: [
                                            {
                                              label: "Erst",
                                              value: "Erstkontakt"
                                            },
                                            {
                                              label: "Folge",
                                              value: "Folgekontakt"
                                            }
                                          ]
                                        },
                                        model: {
                                          value: _vm.besuchsbericht.kontakt,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.besuchsbericht,
                                              "kontakt",
                                              $$v
                                            )
                                          },
                                          expression: "besuchsbericht.kontakt"
                                        }
                                      }),
                                      _vm._v(" Termin "),
                                      _c("InputToggleSwitch", {
                                        attrs: {
                                          label: "Telefon",
                                          inLineLabel: ""
                                        },
                                        model: {
                                          value:
                                            _vm.besuchsbericht.isTelefontermin,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.besuchsbericht,
                                              "isTelefontermin",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "besuchsbericht.isTelefontermin"
                                        }
                                      }),
                                      _c("InputToggleSwitch", {
                                        attrs: {
                                          label: "Außen",
                                          inLineLabel: ""
                                        },
                                        model: {
                                          value:
                                            _vm.besuchsbericht.isAussentermin,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.besuchsbericht,
                                              "isAussentermin",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "besuchsbericht.isAussentermin"
                                        }
                                      }),
                                      _c("InputToggleSwitch", {
                                        attrs: {
                                          label: "Online",
                                          inLineLabel: ""
                                        },
                                        model: {
                                          value:
                                            _vm.besuchsbericht.isOnlinetermin,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.besuchsbericht,
                                              "isOnlinetermin",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "besuchsbericht.isOnlinetermin"
                                        }
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "parameter__grid" },
                                        [
                                          _c("InputCheckboxItem", {
                                            attrs: { label: "Makler §93" },
                                            model: {
                                              value:
                                                _vm.besuchsbericht
                                                  .para_93_makler,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.besuchsbericht,
                                                  "para_93_makler",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "besuchsbericht.para_93_makler"
                                            }
                                          }),
                                          _c("InputCheckboxItem", {
                                            attrs: { label: "§34 c GewO" },
                                            model: {
                                              value:
                                                _vm.besuchsbericht
                                                  .para_34_c_gew,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.besuchsbericht,
                                                  "para_34_c_gew",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "besuchsbericht.para_34_c_gew"
                                            }
                                          }),
                                          _c("InputCheckboxItem", {
                                            attrs: {
                                              label: "§34 f Abs.1 Satz 1 Nr.1"
                                            },
                                            model: {
                                              value:
                                                _vm.besuchsbericht
                                                  .para_34_f_Abs1,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.besuchsbericht,
                                                  "para_34_f_Abs1",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "besuchsbericht.para_34_f_Abs1"
                                            }
                                          }),
                                          _c("InputCheckboxItem", {
                                            attrs: {
                                              label: "§34 f Abs.1 Satz 1 Nr.2"
                                            },
                                            model: {
                                              value:
                                                _vm.besuchsbericht
                                                  .para_34_f_Abs2,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.besuchsbericht,
                                                  "para_34_f_Abs2",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "besuchsbericht.para_34_f_Abs2"
                                            }
                                          }),
                                          _c("InputCheckboxItem", {
                                            attrs: {
                                              label: "§34 f Abs.1 Satz 1 Nr.3"
                                            },
                                            model: {
                                              value:
                                                _vm.besuchsbericht
                                                  .para_34_f_Abs3,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.besuchsbericht,
                                                  "para_34_f_Abs3",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "besuchsbericht.para_34_f_Abs3"
                                            }
                                          }),
                                          _c("InputCheckboxItem", {
                                            attrs: { label: "§34 h GewO" },
                                            model: {
                                              value:
                                                _vm.besuchsbericht
                                                  .para_34_h_gew,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.besuchsbericht,
                                                  "para_34_h_gew",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "besuchsbericht.para_34_h_gew"
                                            }
                                          }),
                                          _c("InputCheckboxItem", {
                                            attrs: {
                                              label: "Mehrfachagent §84"
                                            },
                                            model: {
                                              value:
                                                _vm.besuchsbericht.para_84_mfa,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.besuchsbericht,
                                                  "para_84_mfa",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "besuchsbericht.para_84_mfa"
                                            }
                                          }),
                                          _c("InputCheckboxItem", {
                                            attrs: { label: "§34 d Abs.1" },
                                            model: {
                                              value:
                                                _vm.besuchsbericht.para_34_gew,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.besuchsbericht,
                                                  "para_34_gew",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "besuchsbericht.para_34_gew"
                                            }
                                          }),
                                          _c("InputCheckboxItem", {
                                            attrs: { label: "§34 d Abs.2" },
                                            model: {
                                              value:
                                                _vm.besuchsbericht
                                                  .para_34_d_abs2,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.besuchsbericht,
                                                  "para_34_d_abs2",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "besuchsbericht.para_34_d_abs2"
                                            }
                                          }),
                                          _c("InputCheckboxItem", {
                                            attrs: { label: "§32 KWG" },
                                            model: {
                                              value:
                                                _vm.besuchsbericht.para_32_kwg,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.besuchsbericht,
                                                  "para_32_kwg",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "besuchsbericht.para_32_kwg"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("InputCheckboxItem", {
                                        attrs: {
                                          label:
                                            "Interesse haftungsdach/Bereits bei"
                                        },
                                        model: {
                                          value:
                                            _vm.besuchsbericht.interesse_hd,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.besuchsbericht,
                                              "interesse_hd",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "besuchsbericht.interesse_hd"
                                        }
                                      }),
                                      _vm.besuchsbericht.interesse_hd
                                        ? _c("InputField", {
                                            model: {
                                              value:
                                                _vm.besuchsbericht
                                                  .interesse_hd_bei,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.besuchsbericht,
                                                  "interesse_hd_bei",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "besuchsbericht.interesse_hd_bei"
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  : _vm._e(),
                                _c("InputTextArea", {
                                  attrs: { label: "Aufgaben" },
                                  model: {
                                    value: _vm.besuchsbericht.aufgaben,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "aufgaben",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.aufgaben"
                                  }
                                }),
                                _c("InputTextArea", {
                                  attrs: { label: "Kurzbericht", rows: 8 },
                                  model: {
                                    value: _vm.besuchsbericht.text,
                                    callback: function($$v) {
                                      _vm.$set(_vm.besuchsbericht, "text", $$v)
                                    },
                                    expression: "besuchsbericht.text"
                                  }
                                })
                              ],
                              2
                            )
                          ]
                        : _vm.currentStep === "UNTERNEHMEN"
                        ? [
                            _c(
                              "div",
                              { staticClass: "box__container" },
                              [
                                _c("InputField", {
                                  attrs: { label: "Partner-Nr" },
                                  model: {
                                    value: _vm.besuchsbericht.partner_nr,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "partner_nr",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.partner_nr"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: { label: "Gesprächspartner" },
                                  model: {
                                    value: _vm.besuchsbericht.gespraechspartner,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "gespraechspartner",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "besuchsbericht.gespraechspartner"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: { label: "Funktion" },
                                  model: {
                                    value: _vm.besuchsbericht.funktion,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "funktion",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.funktion"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "box__container" },
                              [
                                _c("div", { staticClass: "box__title" }, [
                                  _vm._v(
                                    "Hintergrundinformationen zum Unternehmen"
                                  )
                                ]),
                                _c("InputField", {
                                  attrs: {
                                    label: "Anzahl Innendienst-Mitarbeiter"
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.anzahl_innen,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "anzahl_innen",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.anzahl_innen"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: {
                                    label: "Anzahl Außendienst-Mitarbeiter"
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.anzahl_aussen,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "anzahl_aussen",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.anzahl_aussen"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "box__container" },
                              [
                                _c("div", { staticClass: "box__title" }, [
                                  _vm._v("Geschäftsschwerpunkte")
                                ]),
                                _c("InputToggleSwitch", {
                                  attrs: {
                                    label: "Investment",
                                    inLineLabel: ""
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.sp_investment,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "sp_investment",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.sp_investment"
                                  }
                                }),
                                _vm.besuchsbericht.sp_investment
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bisher Eingereicht bei"
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht.sp_investment_bei,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_investment_bei",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_investment_bei"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.besuchsbericht.sp_investment
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bestandshöhe",
                                        type: "number",
                                        precision: 2
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht
                                            .sp_investment_hoehe,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_investment_hoehe",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_investment_hoehe"
                                      }
                                    })
                                  : _vm._e(),
                                _c("InputToggleSwitch", {
                                  attrs: {
                                    label: "Versicherungen",
                                    inLineLabel: ""
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.sp_versicher,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "sp_versicher",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.sp_versicher"
                                  }
                                }),
                                _c("InputToggleSwitch", {
                                  attrs: {
                                    label: "Sachgeschäft",
                                    inLineLabel: ""
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.sp_sachgeschf,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "sp_sachgeschf",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.sp_sachgeschf"
                                  }
                                }),
                                _vm.besuchsbericht.sp_sachgeschf
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bisher Eingereicht bei"
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht.sp_sachgeschf_bei,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_sachgeschf_bei",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_sachgeschf_bei"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.besuchsbericht.sp_sachgeschf
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bestandshöhe",
                                        type: "number",
                                        precision: 2
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht
                                            .sp_sachgeschf_hoehe,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_sachgeschf_hoehe",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_sachgeschf_hoehe"
                                      }
                                    })
                                  : _vm._e(),
                                _c("InputToggleSwitch", {
                                  attrs: {
                                    label: "Personengeschäft",
                                    inLineLabel: ""
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.sp_persongeschf,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "sp_persongeschf",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.sp_persongeschf"
                                  }
                                }),
                                _vm.besuchsbericht.sp_persongeschf
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bisher Eingereicht bei"
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht
                                            .sp_persongeschf_bei,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_persongeschf_bei",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_persongeschf_bei"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.besuchsbericht.sp_persongeschf
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bestandshöhe",
                                        type: "number",
                                        precision: 2
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht
                                            .sp_persongeschf_hoehe,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_persongeschf_hoehe",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_persongeschf_hoehe"
                                      }
                                    })
                                  : _vm._e(),
                                _c("InputToggleSwitch", {
                                  attrs: {
                                    label: "Geschlossene Beteiligungen",
                                    inLineLabel: ""
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.sp_geschlbeteil,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "sp_geschlbeteil",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.sp_geschlbeteil"
                                  }
                                }),
                                _vm.besuchsbericht.sp_geschlbeteil
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bisher Eingereicht bei"
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht
                                            .sp_geschlbeteil_bei,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_geschlbeteil_bei",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_geschlbeteil_bei"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.besuchsbericht.sp_geschlbeteil
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bestandshöhe",
                                        type: "number",
                                        precision: 2
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht
                                            .sp_geschlbeteil_hoeh,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_geschlbeteil_hoeh",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_geschlbeteil_hoeh"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.besuchsbericht.sp_geschlbeteil
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "jährl. Zeichnungssumme"
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht
                                            .sp_geschlbeteil_sum,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_geschlbeteil_sum",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_geschlbeteil_sum"
                                      }
                                    })
                                  : _vm._e(),
                                _c("InputToggleSwitch", {
                                  attrs: {
                                    label: "Finanzierungen",
                                    inLineLabel: ""
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.sp_finanz,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "sp_finanz",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.sp_finanz"
                                  }
                                }),
                                _vm.besuchsbericht.sp_finanz
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bisher Eingereicht bei"
                                      },
                                      model: {
                                        value: _vm.besuchsbericht.sp_finanz_bei,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_finanz_bei",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_finanz_bei"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.besuchsbericht.sp_finanz
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bestandshöhe",
                                        type: "number",
                                        precision: 2
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht.sp_finanz_hoehe,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_finanz_hoehe",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_finanz_hoehe"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.besuchsbericht.sp_finanz
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "jährl. Finanzierungsvolumen"
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht.sp_finanz_summ,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_finanz_summ",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_finanz_summ"
                                      }
                                    })
                                  : _vm._e(),
                                _c("InputToggleSwitch", {
                                  attrs: {
                                    label: "Immobilien",
                                    inLineLabel: ""
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.sp_immob,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "sp_immob",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.sp_immob"
                                  }
                                }),
                                _vm.besuchsbericht.sp_immob
                                  ? _c("InputField", {
                                      attrs: { label: "Schwerpunkte" },
                                      model: {
                                        value: _vm.besuchsbericht.sp_immob_sp,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_immob_sp",
                                            $$v
                                          )
                                        },
                                        expression: "besuchsbericht.sp_immob_sp"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.besuchsbericht.sp_immob
                                  ? _c("InputField", {
                                      attrs: { label: "jährl. vermittelt" },
                                      model: {
                                        value:
                                          _vm.besuchsbericht
                                            .sp_immob_jvermittelt,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_immob_jvermittelt",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_immob_jvermittelt"
                                      }
                                    })
                                  : _vm._e(),
                                _c("InputToggleSwitch", {
                                  attrs: {
                                    label: "Bausparen",
                                    inLineLabel: ""
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.sp_bauspar,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "sp_bauspar",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.sp_bauspar"
                                  }
                                }),
                                _vm.besuchsbericht.sp_bauspar
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bisher Eingereicht bei"
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht.sp_bauspar_bei,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_bauspar_bei",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_bauspar_bei"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.besuchsbericht.sp_bauspar
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "Bestandshöhe",
                                        type: "number",
                                        precision: 2
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht.sp_bauspar_hoehe,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_bauspar_hoehe",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_bauspar_hoehe"
                                      }
                                    })
                                  : _vm._e(),
                                _vm.besuchsbericht.sp_bauspar
                                  ? _c("InputField", {
                                      attrs: {
                                        label: "jährl. vermittelte BSS"
                                      },
                                      model: {
                                        value:
                                          _vm.besuchsbericht.sp_bauspar_jbss,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "sp_bauspar_jbss",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.sp_bauspar_jbss"
                                      }
                                    })
                                  : _vm._e(),
                                _c("InputToggleSwitch", {
                                  attrs: {
                                    label: "Übertragungsschreiben",
                                    inLineLabel: ""
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.uebertragung,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "uebertragung",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.uebertragung"
                                  }
                                }),
                                _vm.besuchsbericht.uebertragung
                                  ? _c("InputField", {
                                      attrs: { label: "ausgehändigt für:" },
                                      model: {
                                        value:
                                          _vm.besuchsbericht
                                            .uebertragungaushand,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "uebertragungaushand",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "besuchsbericht.uebertragungaushand"
                                      }
                                    })
                                  : _vm._e(),
                                _c("InputToggleSwitch", {
                                  attrs: {
                                    label:
                                      "Interesse an Vermögensverwaltung/dachfonds",
                                    inLineLabel: ""
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.interesseverm,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "interesseverm",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.interesseverm"
                                  }
                                }),
                                _c("InputToggleSwitch", {
                                  attrs: { label: "VSH", inLineLabel: "" },
                                  model: {
                                    value: _vm.besuchsbericht.vshchekc,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "vshchekc",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.vshchekc"
                                  }
                                }),
                                _vm.besuchsbericht.vshchekc
                                  ? _c("InputField", {
                                      attrs: { label: "bei" },
                                      model: {
                                        value: _vm.besuchsbericht.vsh,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "vsh",
                                            $$v
                                          )
                                        },
                                        expression: "besuchsbericht.vsh"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "box__container" },
                              [
                                _c("div", { staticClass: "box__title" }, [
                                  _vm._v("Schulung MSC")
                                ]),
                                _c("InputRadioBoxGroup", {
                                  attrs: {
                                    title: "Kontakt",
                                    values: [
                                      { label: "Komplett", value: "Komplett" },
                                      {
                                        label: "Teilbereich",
                                        value: "teilbereich"
                                      }
                                    ]
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.schulungmsc,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "schulungmsc",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.schulungmsc"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: {
                                    label: "andere gewünschte Schulungen"
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.schulungand,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "schulungand",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.schulungand"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: { label: "genutzte Tools" },
                                  model: {
                                    value: _vm.besuchsbericht.genutztool,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "genutztool",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.genutztool"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: {
                                    label: "Interessen/Hobby des Partners"
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.hobby,
                                    callback: function($$v) {
                                      _vm.$set(_vm.besuchsbericht, "hobby", $$v)
                                    },
                                    expression: "besuchsbericht.hobby"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: {
                                    label: "Wechselgrund zu FondsKonzept"
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.wechselgrund,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "wechselgrund",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.wechselgrund"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: { label: "Verbesserungsvorschläge" },
                                  model: {
                                    value: _vm.besuchsbericht.verbessvsl,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "verbessvsl",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.verbessvsl"
                                  }
                                }),
                                _c("InputField", {
                                  attrs: { label: "Einschätzung Potential" },
                                  model: {
                                    value: _vm.besuchsbericht.einschaetz,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "einschaetz",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.einschaetz"
                                  }
                                }),
                                _c("InputTextArea", {
                                  attrs: { label: "Persönlicher Eindruck" },
                                  model: {
                                    value: _vm.besuchsbericht.eindruck,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "eindruck",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.eindruck"
                                  }
                                }),
                                _c("InputRadioBoxGroup", {
                                  staticStyle: { "margin-bottom": "4px" },
                                  attrs: {
                                    title: "Folgetermin vereinbart:",
                                    values: [{ label: "Ja, am", value: "Ja" }]
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.terminvb,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "terminvb",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.terminvb"
                                  }
                                }),
                                _vm.besuchsbericht.terminvb === "Ja"
                                  ? _c("DatePickerField", {
                                      attrs: {
                                        label: "Stand",
                                        isValueAsString: ""
                                      },
                                      model: {
                                        value: _vm.besuchsbericht.termin,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.besuchsbericht,
                                            "termin",
                                            $$v
                                          )
                                        },
                                        expression: "besuchsbericht.termin"
                                      }
                                    })
                                  : _vm._e(),
                                _c("InputRadioBoxGroup", {
                                  attrs: {
                                    values: [
                                      { label: "Nein", value: "Nein" },
                                      {
                                        label: "Besuchsfrequenz",
                                        value: "Besuchsfrequenz"
                                      }
                                    ]
                                  },
                                  model: {
                                    value: _vm.besuchsbericht.terminvb,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.besuchsbericht,
                                        "terminvb",
                                        $$v
                                      )
                                    },
                                    expression: "besuchsbericht.terminvb"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm.currentStep === "DOKUMENTE"
                        ? [
                            _c(
                              "div",
                              { staticClass: "box__container" },
                              [
                                _c("InputToggleSwitch", {
                                  attrs: {
                                    label: "Gelöschte anzeigen",
                                    inLineLabel: "",
                                    value: _vm.showDeleted
                                  },
                                  on: { input: _vm.toggleShowDeleted }
                                }),
                                _c("Table", {
                                  attrs: {
                                    title: "Dokumente Verträge",
                                    headers: _vm.headers,
                                    rows: _vm.rows,
                                    rowsPerPage: 10
                                  },
                                  on: { "action-DELETE": _vm.deleteDocument },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "filename",
                                      fn: function(row) {
                                        return [
                                          row.deleted
                                            ? _c("ph-trash", {
                                                staticClass:
                                                  "color-danger mr-1 icon",
                                                staticStyle: {
                                                  "vertical-align": "sub"
                                                },
                                                attrs: { size: 16 }
                                              })
                                            : _vm._e(),
                                          _c("DownloadLink", {
                                            attrs: {
                                              title: row.filename,
                                              filename: row.filename,
                                              downloadServicePath:
                                                "/get_simple_file",
                                              queryParams: {
                                                fileId: row.id
                                              }
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ]
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "DokumentUploadModal",
        {
          ref: "uploadModal",
          attrs: { disableMultifile: "", disableFotoUpload: "" },
          on: { upload: _vm.uploadDocuments },
          model: {
            value: _vm.files,
            callback: function($$v) {
              _vm.files = $$v
            },
            expression: "files"
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Bezeichnung" },
            model: {
              value: _vm.fileData.bezeichnung,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "bezeichnung", $$v)
              },
              expression: "fileData.bezeichnung"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Bemerkung" },
            model: {
              value: _vm.fileData.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "bemerkung", $$v)
              },
              expression: "fileData.bemerkung"
            }
          }),
          _c("DatePickerField", {
            attrs: { label: "Stand", isValueAsString: "" },
            model: {
              value: _vm.fileData.dateStand,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "dateStand", $$v)
              },
              expression: "fileData.dateStand"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }