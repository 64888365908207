import FONDSINFO_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';
const config = {
  defaultSpinner: true
};

function verifyStatus(response) {
  return response?.status === 200 && response?.data;
}
function addAttribute(obj1, obj2) {
  Object.assign(obj1, obj2);
}
function catchErrors(commit) {
  commit(FONDSINFO_TYPES.MUTATIONS.GET_FAILTURE);
}

export default {
  [FONDSINFO_TYPES.ACTIONS.RESET_STATE]({commit}) {
    commit(FONDSINFO_TYPES.MUTATIONS.RESET_STATE)
  },
  async [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_DETAILS]({ commit, getters, dispatch }, payload) {
    if (!payload?.isin) return;

    let fondsinfoDetails = {};
    const urlDetailName = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetailname`;
    const fundsDetailName = await axios.post(urlDetailName, {isin: payload?.isin}, config).catch(error => catchErrors(commit, dispatch, error));
    
    const urlDetailGeneral = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetailgeneral`;
    const fundsDetailGeneral = await axios.post(urlDetailGeneral, {isin: payload?.isin}, config).catch(error => catchErrors(commit, dispatch, error));

    if (verifyStatus(fundsDetailName)) {
      addAttribute(fondsinfoDetails, fundsDetailName.data)
      addAttribute(fondsinfoDetails, fundsDetailGeneral.data)
    }

    commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_DETAILS_SUCCESS, fondsinfoDetails);

  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_ALGEMEIN]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetaildescription`;
    axios.post(url, {isin: payload?.isin, bgsNr: payload?.bgsNr} ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_ALGEMEIN_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_AUSSCHUTTUNG]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetaildistribution`;
    axios.post(url, {isin: payload?.isin, bgsNr: payload?.bgsNr} ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_AUSSCHUTTUNG_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_STEUERLICHES_ERGEBNIS]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetailtaxresults`;
    axios.post(url, {isin: payload?.isin, bgsNr: payload?.bgsNr} ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_STEUERLICHES_ERGEBNIS_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetailinheritancetax`;
    axios.post(url, {isin: payload?.isin, bgsNr: payload?.bgsNr} ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_EINZAHLUNGEN]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetailinpayments`;
    axios.post(url, {isin: payload?.isin, bgsNr: payload?.bgsNr} ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_EINZAHLUNGEN_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_MITTELHERKUNFT]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetailsource`;
    axios.post(url, {isin: payload?.isin, bgsNr: payload?.bgsNr} ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_MITTELHERKUNFT_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_INVESTITIONSKOSTEN]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetailinvestment`;
    axios.post(url, {isin: payload?.isin, bgsNr: payload?.bgsNr} ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_INVESTITIONSKOSTEN_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_DOKUMENTE]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetailfileslist`;
    axios.post(url, {isin: payload?.isin, bgsNr: payload?.bgsNr} ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_DOKUMENTE_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  async [FONDSINFO_TYPES.ACTIONS.DOWNLOAD_PDF]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/${payload?.url}`;
    const config = {
      defaultSpinner: true
    };
    const response = await axios.get(url, {...config, params: payload?.getParams, responseType: 'arraybuffer'} )
    if (response?.status === 200 && response?.data) {
      return response.data;
    }
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_PERFORMANCE]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetailperformancekeys`;
    axios.post(url, {isin: payload?.isin}).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_PERFORMANCE_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_RISIKO]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetailriskkeys`;
    axios.post(url, {isin: payload?.isin}).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_RISIKO_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_UNTERLAGEN]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsdetailreport`;
    axios.post(url, {isin: payload?.isin} ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_UNTERLAGEN_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_UNTERLAGEN_LINKS]({ commit, state, dispatch, rootState }, payload) {
    let url = '/factsheets/funddocs';
    const {isin} = payload;
    axios.get(rootState.core.apiAddress + url, {params: {isin}}, config).then(response => {
      if (response && response.data) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_UNTERLAGEN_LINKS_SUCCESS, response);
      }
    })
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_UNTERLAGEN_TEMP_ID]({rootState }, payload) {
    return axios.post(rootState.core.apiAddress + '/factsheets/fundtempfile', payload, config)
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_RISK_CHART]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundsriskchart`;
    axios.post(url, payload ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_RISK_CHART_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_PERFORMANCE_CHART]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundshistoricalchart`;
    axios.post(url, payload ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_PERFORMANCE_CHART_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_MONTECARLO_CHART]({ commit, getters, dispatch }, payload) {

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fundsDetails/monteCarloSimulation`;
    axios.post(url, payload, config).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_MONTECARLO_CHART_SUCCESS, response.data);
      }
    })
    .catch(error => {
      commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_MONTECARLO_CHART_ERROR, error.response.data)
    });
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_RENDITE_CHART]({state, commit, getters, dispatch }, payload) {
    if (state.renditeChart?.isin == payload.isin && state.renditeChart?.years == payload.years) {
      return;
    }
    commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_RENDITE_CHART_SUCCESS, {});
    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/funds/rendite`;
    axios.post(url, payload ).then(response => {
      if (verifyStatus(response)) {
        commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_RENDITE_CHART_SUCCESS, response.data);
      }
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
  [FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_PURCHASE]({ commit, getters, dispatch, rootState }, payload) {
    let url = '/factsheets/bezugsmoeglichkeiten';
    const {isin} = payload;
    axios.get(rootState.core.apiAddress + url, {params: {isin}}, config).then(response => {
      commit(FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_PURCHASE_SUCCESS, response.data);
    })
    .catch(error => catchErrors(commit, dispatch, error));
  },
}