var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "legitimation-images-firma__container" }, [
    !_vm.rechtsform
      ? _c("div", { staticClass: "box__container" }, [_vm._m(0)])
      : _c(
          "div",
          _vm._l(_vm.requiredFiles, function(requiredFile) {
            return _c("div", { staticClass: "scans-container" }, [
              _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v(_vm._s(requiredFile.title))
                  ]),
                  _c("SingleFileUpload", {
                    attrs: {
                      filename: _vm.getFilename(requiredFile.title),
                      fileId: _vm.unterlagenFirma[requiredFile.shorthand]
                        ? _vm.unterlagenFirma[requiredFile.shorthand].fileId
                        : null
                    },
                    on: {
                      file: function(file) {
                        return _vm.upload(file, requiredFile.shorthand)
                      },
                      delete: function($event) {
                        return _vm.deleteDocument(requiredFile.shorthand)
                      }
                    }
                  }),
                  _vm.unterlagenFirma[requiredFile.shorthand] &&
                  _vm.needsAusstellungsdatum(requiredFile.shorthand)
                    ? _c("DatePickerField", {
                        attrs: {
                          label: "Ausstellungsdatum",
                          isValueAsString: "",
                          isComponentHalfSize: "",
                          disabled:
                            _vm.isLoading ||
                            !_vm.isEditable ||
                            _vm.disabled ||
                            _vm.unterlagenFirma[requiredFile.shorthand] ===
                              undefined
                        },
                        on: {
                          change: function(date) {
                            return _vm.changeAusstellungsdatum(
                              date,
                              requiredFile.shorthand
                            )
                          }
                        },
                        model: {
                          value:
                            _vm.unterlagenFirma[requiredFile.shorthand]
                              .ausstellungsdatum,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.unterlagenFirma[requiredFile.shorthand],
                              "ausstellungsdatum",
                              $$v
                            )
                          },
                          expression:
                            "unterlagenFirma[requiredFile.shorthand].ausstellungsdatum"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          }),
          0
        ),
    _vm.hasActions && !_vm.isSmallScreen
      ? _c("div", { staticClass: "box__container" }, [
          _c("div", { staticClass: "row mb-0" }, [
            _c(
              "div",
              { staticClass: "col-auto" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      isSecondary: "",
                      disabled: _vm.isLoading || _vm.disabled
                    },
                    on: { click: _vm.deleteAllDocsModal }
                  },
                  [_vm._v("Gespeicherte Bilder löschen")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-auto" },
              [
                _c("DownloadLink", {
                  attrs: {
                    downloadServicePath: "/generateLegitimationPDF",
                    title: "PDF herunterladen",
                    queryParams: { personId: _vm.customerData.personId },
                    asButton: ""
                  }
                })
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("p", [_vm._v("Sonstige Unterlagen")]),
        _vm._l(_vm.otherFiles, function(otherFile) {
          return _c("div", [
            _vm.unterlagenFirma[otherFile.shorthand]
              ? _c(
                  "div",
                  [
                    _c("SingleFileUpload", {
                      attrs: {
                        filename: _vm.getFilename(otherFile.title),
                        fileId: _vm.unterlagenFirma[otherFile.shorthand].fileId
                      },
                      on: {
                        file: function(file) {
                          return _vm.upload(file, otherFile.shorthand)
                        },
                        delete: function($event) {
                          return _vm.deleteDocument(otherFile.shorthand)
                        }
                      }
                    }),
                    _c("ComboBox", {
                      attrs: {
                        label: "Unterlagentyp ändern",
                        values: _vm.requiredEmptyFilesAsCombobox,
                        disabled: !_vm.isEditable,
                        isComponentHalfSize: ""
                      },
                      on: {
                        change: function($event) {
                          return _vm.changeLegidocType(
                            otherFile.shorthand,
                            $event
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticClass: "font-bold" }, [
        _vm._v("Schritt Stammdaten: ")
      ]),
      _vm._v("Bitte setzen Sie zuerst die Rechtsform.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }