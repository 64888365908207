var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "box__title" },
        [
          _c("CurrencyLabel", {
            attrs: {
              label: "Gesamtvermögen:",
              showInlineLabel: "",
              value: _vm.gesamtStartVermoegen(_vm.scenario)
            }
          })
        ],
        1
      ),
      _vm.rowsVermoegen && _vm.rowsVermoegen.length
        ? _c("Table", {
            attrs: {
              tableId: "vermoegenTableRuhestandsplanung",
              headers: _vm.headersVermoegen,
              rows: _vm.rowsVermoegen,
              rowId: "name",
              hidePagination: "",
              mobileConfig: {},
              selected: _vm.selectedVermoegen
            },
            on: { selected: _vm.onSelectedVermoegen },
            scopedSlots: _vm._u(
              [
                {
                  key: "rendite",
                  fn: function(row) {
                    return [
                      _c("InputField", {
                        attrs: { type: "percent", precision: 2 },
                        on: {
                          change: function($event) {
                            return _vm.updateVermoegenAusgaben(
                              { name: row.name, rendite: row.rendite },
                              "vermoegen"
                            )
                          }
                        },
                        model: {
                          value: row.rendite,
                          callback: function($$v) {
                            _vm.$set(row, "rendite", $$v)
                          },
                          expression: "row.rendite"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "value",
                  fn: function(row) {
                    return [
                      row.isAdjustable
                        ? _c("InputField", {
                            attrs: { type: "currency", precision: 2 },
                            on: {
                              change: function($event) {
                                return _vm.updateVermoegenAusgaben(
                                  { name: row.name, value: row.value },
                                  "vermoegen"
                                )
                              }
                            },
                            model: {
                              value: row.value,
                              callback: function($$v) {
                                _vm.$set(row, "value", $$v)
                              },
                              expression: "row.value"
                            }
                          })
                        : _c(
                            "span",
                            [
                              _c("CurrencyLabel", {
                                attrs: { value: row.value }
                              })
                            ],
                            1
                          )
                    ]
                  }
                }
              ],
              null,
              false,
              729214762
            )
          })
        : _vm._e(),
      _vm.rowsAusgaben && _vm.rowsAusgaben.length
        ? _c("Table", {
            attrs: {
              tableId: "ausgabenTableRuhestandsplanung",
              headers: _vm.headersAusgaben,
              rows: _vm.rowsAusgaben,
              rowId: "name",
              hidePagination: "",
              mobileConfig: {},
              selected: _vm.selectedAusgaben
            },
            on: { selected: _vm.onSelectedAusgaben },
            scopedSlots: _vm._u(
              [
                {
                  key: "value",
                  fn: function(row) {
                    return [
                      row.isAdjustable
                        ? _c("InputField", {
                            attrs: { type: "currency", precision: 2 },
                            on: {
                              change: function($event) {
                                return _vm.updateVermoegenAusgaben(
                                  { name: row.name, value: row.value },
                                  "ausgaben"
                                )
                              }
                            },
                            model: {
                              value: row.value,
                              callback: function($$v) {
                                _vm.$set(row, "value", $$v)
                              },
                              expression: "row.value"
                            }
                          })
                        : _c(
                            "span",
                            [
                              _c("CurrencyLabel", {
                                attrs: { value: row.value }
                              })
                            ],
                            1
                          )
                    ]
                  }
                }
              ],
              null,
              false,
              2210136622
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }