import ANTRAG_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types'

import axios from 'axios';
import * as utils from '../../helpers/commonfunctions';

import { getServletName, getSavingServletName, getSubmitServletName} from '../../components/antrag/antrag-utils';
import { buildMessageWith } from '@/helpers/log-message-helper';

import { SUPPORT_ERROR_MESSAGE } from '@/store/log/actions'

import { MenuItem, GroupMenu } from '@/menu/menu-utils';
import { componentByLagerstelle, getFilenameFromHeaders } from '@/helpers/utils-helper';
import { knownParams } from '@/mixins/antrag/antrag-mixin';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import FormUtil from '@/components/beratung/formsMenu/forms-menu';
import Vue from 'vue';

const config = {
  defaultSpinner: true
};

export const prepareParams = (payload) => Object.entries(payload).map(([key, val]) => `${key}=${val}`).join('&');

/**
 * Add BreakLines in oder to let the label better readable into OptionsMenu
 * Adding the break here instead of in the backend, because this label is used in many places
 */
function addBreakLines(label) {
  return label.replace('Beratungsdokumentation', 'Beratungs&shy;dokumentation')
}

export default {
  [ANTRAG_TYPES.ACTIONS.GET_ANTRAG]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const requiredParams = knownParams.reduce((accu, curr) => {
        payload[curr] ? accu[curr] = payload[curr] : ''
        return accu;
      }, {})
      const params = prepareParams(requiredParams);
      // const params = prepareParams({id: payload.id, beratungsMappeId: payload.beratungsMappeId || ''});
      
      axios.post(`${rootState.core.apiAddress}/../${getServletName(payload.lagerstelle)}?${params}`, payload, config).then(response => {
        if (response.status === 200 && response.data.id) {
          commit(ANTRAG_TYPES.MUTATIONS.GET_ANTRAG_SUCCESS, {...response.data, dataHasChanged: payload.importPosBesitzer !== undefined});
          commit(ANTRAG_TYPES.MUTATIONS.SET_ANTRAG_RELOAD_PAYLOAD, payload);
          resolve();
        } else {
          reject();
        }
      });
    });
  },

  [ANTRAG_TYPES.ACTIONS.UPDATE_ANTRAG_DATA]({ state, commit, getters, rootState, dispatch }, {id, data, componentType, positions, antragData}) {
    commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, {id, data});
    const antrag = getters[ANTRAG_TYPES.GETTERS.ANTRAEGE][id]

    // When Hauptperson was changed we need to do a reload of all persons
    if (data[state.antraege[id].hauptpersonName] !== undefined) {
      const payload = {...state.antraegeReloadPayload[id], ...antragData}
      payload.hauptpersonId = data[state.antraege[id].hauptpersonName]
      payload.ignore = state.antraege[id].hauptpersonName;
      dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_PERSONEN_AUSWAHL_CHANGE, payload);
    }

    
    if (state.antraege[id].reloadTriggerNames && data && Object.keys(data).length > 0 && state.antraege[id].reloadTriggerNames.includes(Object.keys(data)[0])) {
      const triggerconfig = {
        defaultSpinner: true,
        params: {
          requestPositions: positions,
          antragTyp: antrag.antragTyp, 
        }
      };
      const payload = {...state.antraegeReloadPayload[id], ...antragData, reloadTrigger: Object.keys(data)[0], reloadTriggerValue: Object.values(data)[0]}

      dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_TRIGGER_CHANGE, {config: triggerconfig, payload: payload});

    } else if (componentType && (componentType === 'BUTTON' || componentType === 'ATTACHMENTS')) {
      const payload = {...state.antraegeReloadPayload[id], 
        submitTrigger: data.button, 
        submitDataName: data.submitDataName, 
        submitData: data.submitData, 
        id:id,
        antragTyp: antrag.antragTyp, }
      dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_SUBMIT, payload);

    } else if (state.antraege[id].submitTriggerNames && data && Object.keys(data).length > 0 
      && state.antraege[id].submitTriggerNames.includes(Object.keys(data)[0])) {
        const payload = {...state.antraegeReloadPayload[id], 
          submitTrigger: Object.keys(data)[0], 
          submitDataName: Object.keys(data)[0], 
          submitData: Object.values(data)[0], 
          id:id,
          antragTyp: antrag.antragTyp, }
        dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_SUBMIT, payload);

    } else if (componentType && componentType === 'MULTIPLE_SELECTION_TABLE') {
      const payload = {
        ...state.antraegeReloadPayload[id], 
        submitTrigger: data.action,
        submitData: data.actionData || {[data.componentId] : {[data.componentData.key]: data.componentData.row[data.componentData.key]}}, 
        submitDataName: data.actionDataName || data?.componentId, 
        id:id,
        antragTyp: antrag.antragTyp
      }
      dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_SUBMIT, payload);
    }
  },

  [ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAEGE_DATA]({ state, commit, rootState, dispatch }) {
    Object.keys(state.antraege).forEach(id => {
      if (state.antraegeReloadPayload[id] && state.antraegeData[id] && state.antraege[id].hauptpersonName) {
        const payload = {...state.antraegeReloadPayload[id], ...state.antraegeData[id]}
        payload.hauptpersonId = state.antraegeData[id][state.antraege[id].hauptpersonName]
        dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_PERSONEN_AUSWAHL_CHANGE, payload).then(() => {
          Vue.set(state.antraege[id], 'dataHasChanged', true);
          dispatch(ANTRAG_TYPES.ACTIONS.SAVE_ANTRAG, { id: id, lagerstelle: state.antraege[id].id.split('-')[0], action: 'save'});
        });
      }
    });
  },

  [ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_PERSONEN_AUSWAHL_CHANGE]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      // const param = prepareParams(payload);
      const requiredParams = knownParams.reduce((accu, curr) => {
        payload[curr] ? accu[curr] = payload[curr] : ''
        return accu;
      }, {})

      requiredParams.hauptpersonId = payload.hauptpersonId
      
      const params = prepareParams(requiredParams);
      return axios.post(`${rootState.core.apiAddress}/../${getServletName(payload.lagerstelle)}?${params}`, {...payload, data: payload}, config).then(response => {
        if (response.status === 200 && response.data.id) {
          commit(ANTRAG_TYPES.MUTATIONS.RELOAD_ANTRAG_SUCCESS, {...response.data, ignore: payload.ignore});
          resolve();
        } else {
          reject();
        }
      });
    });
  },
  [ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_SUBMIT]({ commit, getters, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const param = prepareParams(payload);
      const data = {...payload, data: getters[ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA][payload.id]};
      axios.post(`${rootState.core.apiAddress}/../${getSubmitServletName(payload.lagerstelle)}?${param}`, data, config).then(response => {
        if (response.status === 200 && response.data.id) {
          commit(ANTRAG_TYPES.MUTATIONS.RELOAD_ANTRAG_SUCCESS, response.data);
          resolve();
        } else {
          reject();
        }
      });
    });
  },
  [ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG_TRIGGER_CHANGE]({ commit, rootState }, {payload = {}, config}) {
    return new Promise((resolve, reject) => {
      const requiredParams = knownParams.reduce((accu, curr) => {
        payload[curr] ? accu[curr] = payload[curr] : ''
        return accu;
      }, {})
      const params = prepareParams(requiredParams);
      // const params = prepareParams({id: payload.id, reloadTrigger: payload.reloadTrigger, reloadTriggerValue: payload.reloadTriggerValue});
      axios.post(`${rootState.core.apiAddress}/../${getServletName(payload.lagerstelle)}?${params}`, {...payload, data: payload}, config).then(response => {
        if (response.status === 200 && response.data.id) {
          commit(ANTRAG_TYPES.MUTATIONS.RELOAD_ANTRAG_SUCCESS, response.data);
          resolve();
        } else {
          reject();
        }
      });
    });
  },

  [ANTRAG_TYPES.ACTIONS.SAVE_ANTRAG]({ dispatch, commit, getters, rootState}, payload) {
    return new Promise((resolve, reject) => {
      if (!payload || !payload.id 
          || !getters[ANTRAG_TYPES.GETTERS.ANTRAEGE] 
          || !getters[ANTRAG_TYPES.GETTERS.ANTRAEGE][payload.id]
          || !getters[ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA][payload.id]) {
        reject()
        return
      }

      const antragData = getters[ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA][payload.id]

      if (antragData?.READ_ONLY) {
        resolve();
        return;
      }

      const antrag = getters[ANTRAG_TYPES.GETTERS.ANTRAEGE][payload.id]
      const positions = getters[ANTRAG_TYPES.GETTERS.POSITIONS][payload.id]
      const positionData = getters[ANTRAG_TYPES.GETTERS.POSITION_DATA][payload.id]
      if ( payload.lagerstelle === 'CAPITALBANK'){
        if (antrag.id != 'CAPITALBANK-aenderungsformularspar' && antrag.id != 'CAPITALBANK-auszahlungsplan' && positionData ) {
          Object.keys(positionData).forEach(key => {
            if (positions?.[key]) {
              delete positionData[key];
            }
          })
        }
        if (positions?.SPARPLAN?.length) {
          if (antragData.RG_SparplanTag) {
            positions.SPARPLAN.forEach(pos => {
              if (antragData.RG_SparplanTag == '5' || antragData.RG_SparplanTag == '15' || antragData.RG_SparplanTag == '125'){
                pos.frequenz = 'MONATLICH';
                pos.startdatum = antragData.RG_SparplanTag;
              } else if (antragData.RG_SparplanTag == 'quartal') {
                pos.frequenz = 'VIERTELJ';
                pos.startdatum = '25';
              }
            })
          }
        }
      }

      
      if (antrag && antrag.id && (antrag.dataHasChanged || (!antrag.data?.antragsdatenId && !antrag.data?.antragsnrIntern && !antrag.data?.parameter_protokoll_id && !antrag.data?.PARAMETER_PROTOKOLL_ID))) {
        const data = {
          data: antragData, 
          positions: positions, 
          positionData: positionData, 
          lagerstelle: payload.lagerstelle,
          id: payload.id, 
          antragTyp: antrag.antragTyp, 
          positionClass: antrag.positionClass, 
          forceReload: payload.forceReload,
          action: payload.action,
        };

        const params = prepareParams({id: data.id});

        const requestConfig = {
          defaultSpinner: true,
          cancelableRequest: {
            title: antrag.label || 'Antrag speichern',
          },
        };

        commit(ANTRAG_TYPES.MUTATIONS.START_SAVE_ANTRAG_STATE)
        axios.post(`${rootState.core.apiAddress}/../${getSavingServletName(data.id, antragData.IS_DYNAMIC)}?${params}`, data, requestConfig).then(response => {
          if (response.status === 200 && response.data.id) {
            if (response.data?.warnings?.exceptionWarning) {
              dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({ message: response.data.warnings.exceptionWarning, type: 'info' }));
            }
            commit(ANTRAG_TYPES.MUTATIONS.SAVE_ANTRAG_SUCCESS, response.data)
            commit(ANTRAG_TYPES.MUTATIONS.END_SAVE_ANTRAG_STATE)
            resolve()
          }
        })
        .catch(() => {
          reject()
        })
        .finally(() => {
          commit(ANTRAG_TYPES.MUTATIONS.END_SAVE_ANTRAG_STATE)
        });
      } else {
        resolve()
      }
    });
  },

  async [ANTRAG_TYPES.ACTIONS.UPDATE_SELECTION]({ commit, dispatch, getters }, payload) {
    const saveType = payload.saveType
    const fonds = payload.fonds
    if (!saveType) {
      await dispatch(ANTRAG_TYPES.ACTIONS.ADD_POSITIONS, payload)
    } else if (saveType === 'serialize') {
      if (fonds && fonds.length) {
        const oldSerializedPositions = getters[ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA][payload.id][payload.categoryId] || '';
        let serializedPositions = fonds.map(fond => `${fond.isin}&=${fond.wertpapiername}&=`).join('&=');
        if (oldSerializedPositions) {
          serializedPositions = oldSerializedPositions + '&=' + serializedPositions;
        } else {
          serializedPositions = '=' + serializedPositions;
        }

        commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, {
          id: payload.id,
          data: { [payload.categoryId]: serializedPositions }
        });
      }
    } else if (saveType === 'isin' && fonds && fonds.length) {
      commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, {
        id: payload.id,
        data: { [payload.categoryId]: fonds[0].isin }
      });
    }
  },

  async [ANTRAG_TYPES.ACTIONS.ADD_POSITIONS]({ commit, dispatch, getters, state }, payload) {
    let posToDelete;
    if (payload.singleSelection) {
      posToDelete = state.positions?.[payload.id]?.[payload.categoryId];
      commit(ANTRAG_TYPES.MUTATIONS.DELETE_ALL_POSITIONS_PRO_CATEGORY, { id: payload.id, categoryId: payload.categoryId });
    }

    const fonds = payload.fonds.map(fond => {
      if (payload.categoryId !== 'TAUSCH') { 
        return { isin: fond.isin, 
          art: payload.categoryId, 
          istVWL: payload.categoryId === 'VWL', 
          typ: payload.antragTyp,
          bgsId: fond.bgsId,
          begleitscheinId: fond.begleitscheinId,
          isinAlt: fond.isinAlt,
          aenderungTyp: fond.aenderungTyp
        };
      }
      else {
        const tauschFond = { 
          isin: fond.isin,
          tauschZuName: fond.tauschZuName,
          tauschVonName: fond.tauschVonName,
          tauschVonIsin: fond.tauschVonIsin,
          isinVerkauf: fond.isinVerkauf,
          art: 'KAUF', 
          typ: payload.antragTyp,
        };
        if (fond.tauschZuName) {
          tauschFond[fond.tauschZuName] = fond.isin;
        }
        if (fond.tauschVonName) {
          tauschFond[fond.tauschVonName] = fond.tauschVonValue;
        }
        return tauschFond;
      }});

    const updatePositionsPayload = {
      id: payload.id,
      categoryId: payload.categoryId === 'TAUSCH' ? 'KAUF' : payload.categoryId,
      fonds,
      canHaveDuplicateFonds: payload.id == 'CAPITALBANK-aenderungsformularspar' || payload.id == 'FODB-dimensionalfundstausch',
      additionalIdentifier: payload.additionalIdentifier
    }

    commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, updatePositionsPayload)

    const serializedPositions = getters[ANTRAG_TYPES.GETTERS.POSITIONS][payload.id][payload.categoryId] || '';
    dispatch(ANTRAG_TYPES.ACTIONS.UPDATE_ANTRAG_DATA, {
      id: payload.id,
      positions: serializedPositions, 
      data: { [payload.categoryId]: 'positions' }
    })

    if (payload.singleSelection && posToDelete?.length) {
        const deletePayload = {
          id: payload.id,
          categoryId: payload.categoryId,
          isin: posToDelete[0].isin,
          posGrpId: posToDelete[0].posGrpId,
        };
        await dispatch(ANTRAG_TYPES.ACTIONS.DELETE_POSITION, deletePayload);
    }
  },

  async [ANTRAG_TYPES.ACTIONS.DELETE_POSITION]({ commit, getters, state, dispatch, rootState}, payload) {
    if (state.positions[payload.id] && state.positions[payload.id][payload.categoryId]) {
      if (payload.posGrpId) {
        const antrag = getters[ANTRAG_TYPES.GETTERS.ANTRAEGE][payload.id]
        await axios.delete(`${rootState.core.apiAddress}/../mrsantragsaveservlet?posGrpId=${payload.posGrpId}&antragTyp=${antrag.antragTyp}&positionClass=${antrag.positionClass}&antragsnrIntern=${antrag.data?.antragsnrIntern || ''}&antragsdatenId=${antrag.data?.antragsdatenId || ''}`, 
        config).then(response => {
          commit(ANTRAG_TYPES.MUTATIONS.DELETE_POSITIONS, payload)
        })
      } else if (payload.isin) {
        commit(ANTRAG_TYPES.MUTATIONS.DELETE_POSITIONS, payload)
      }
    const serializedPositions = getters[ANTRAG_TYPES.GETTERS.POSITIONS][payload.id][payload.categoryId] || '';
    dispatch(ANTRAG_TYPES.ACTIONS.UPDATE_ANTRAG_DATA, {
      id: payload.id,
      positions: serializedPositions, 
      data: { [payload.categoryId]: 'positions' }
      })
    }
  },

  [ANTRAG_TYPES.ACTIONS.REQUEST_EBASE_DEPOTNUMMER]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API}/../mrsebasedepotnummeranfordern?id=${payload.antragId}`).then(response => {
        let data = {};
  
        if(response && response.data) {
          data = response.data.dataUpdates;
        }
  
        const requestedEbaseDepotnummerPayload = {
          id: payload.antragId,
          data,
        }
  
        commit(ANTRAG_TYPES.MUTATIONS.SET_REQUESTED_EBASE_DEPOTNUMMER, requestedEbaseDepotnummerPayload);
  
        resolve(requestedEbaseDepotnummerPayload);
      });
    });
  },

  [ANTRAG_TYPES.ACTIONS.OPEN_PDF]({ getters }, payload) {
    return axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsantragsignpdf?id=${payload.id}&confirmed=${payload.confirmed}`, payload, config)
  },

  [ANTRAG_TYPES.ACTIONS.OPEN_BERICHT]({ getters }, payload) {
    const param = prepareParams(payload);
    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsantragreport?${param}`, config)
  },
  /**
   * @deprecated don't use this function. 
   * 
   * use /download_service/get_antrag_report/auftragsdokumente.pdf?${params}
   */
  [ANTRAG_TYPES.ACTIONS.OPEN_BERICHT_PDF]({ getters }, payload) {
    // return axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsantragreport`, payload, config)
  },

  [ANTRAG_TYPES.ACTIONS.PORTFOLIO_STRUKTUR]({ dispatch, getters, commit }, payload) {
      axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsffbservlet`, {portfolioId: payload.portfolioId}).then(response => {
        const data = Object.assign({}, payload, response);  
        commit(ANTRAG_TYPES.MUTATIONS.PORTFOLIO_STRUKTUR_SUCCESS, data);
      }, error => {
        commit(ANTRAG_TYPES.MUTATIONS.PORTFOLIO_STRUKTUR_SUCCESS, 
          Object.assign({}, payload, { errorMessage: `Keine Positionen für das Portfolio ${payload.portfolioId} vorhanden`,
          positions: [] }));
      })
  },

  /**
   * Use this method only if you need to save very specific data.
   * Otherwise, use SAVE_ANTRAG
   * 
   * @param {Object} payload
   * @return {Promise}
   */
  [ANTRAG_TYPES.ACTIONS.PATCH_ANTRAG]({ commit, rootState}, payload) {
    return new Promise((resolve, reject) => {
      if(!payload || !payload.id || !payload.data || !payload.lagerstelle) {
        reject('[ANTRAG_TYPES.ACTIONS.PATCH_ANTRAG]: Invalid payload!');
        return ;
      }

      axios.post(`${rootState.core.apiAddress}/../${getSavingServletName(payload.id)}?id=${payload.id}`, payload, config).then(response => {
        if (response.status === 200 && response.data.id) {
          commit(ANTRAG_TYPES.MUTATIONS.SAVE_ANTRAG_SUCCESS, response.data);
          resolve();
        }
      }, () => {
        reject();
      });
    });
  },

  /**
   * It uses the params used by GET_ANTRAG method to reload the Antrag data.
   * Note: It only must be used after a GET_ANTRAG invoke.
   * 
   * @param {Object} payload 
   * @return {Promise}
   */
  [ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG]({ commit, rootState, getters }, payload) {
    return new Promise((resolve, reject) => {
      if(!payload || !payload.id) {
        reject('[ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG]: Invalid payload!');
        return ;
      }

      const reloadPayload = getters[ANTRAG_TYPES.GETTERS.ANTRAEGE_RELOAD_PAYLOAD][payload.id];
      reloadPayload.reloadAntrag = '1';

      if(!reloadPayload) {
        return ;
      }

      // const param = prepareParams(reloadPayload);
      // const params = prepareParams({id: payload.id});
      const requiredParams = knownParams.reduce((accu, curr) => {
        reloadPayload[curr] ? accu[curr] = reloadPayload[curr] : ''
        return accu;
      }, {})
      const params = prepareParams(requiredParams);

      axios.post(`${rootState.core.apiAddress}/../${getServletName(reloadPayload.lagerstelle)}?${params}`, {...reloadPayload, data: reloadPayload}, config).then(response => {
        if (response.status === 200 && response.data.id) {
          commit(ANTRAG_TYPES.MUTATIONS.GET_ANTRAG_SUCCESS, response.data);
          resolve();
        } else {
          reject();
        }
      });
    });
  },

  async [ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_ONLY]({ dispatch, getters }, payload) {
    let serviceUrl = '/antragforms/list';


    if (payload) {
      const filterCompany = payload.filterCompany;
      const showInBeratungsmappe = payload.showInBeratungsmappe;
      const beratungsmappeType = payload.beratungsmappeType;
      const lagerstelle = payload.lagerstelle;

      if (filterCompany) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterCompany', filterCompany);
      }
      if (showInBeratungsmappe) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showInBeratungsmappe', showInBeratungsmappe);
      }
      if (beratungsmappeType) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsmappeType', beratungsmappeType);
      }
      if (lagerstelle) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'lagerstelle', lagerstelle);
      }
    }    

    try {
      const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS];
      const response = await axios.get(apiAddress + serviceUrl, config)
      
      const apiAddressLegacy = getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY];
      const antragList = (response?.data || {});

      // map local fields
      const formGroups = (antragList?.formGroups || []).map(formGroup => ({
        ...formGroup,
        logoURL: formGroup.id ? `${apiAddressLegacy}/mrscompanylogo?company=${formGroup.id?.toUpperCase()}` : null,
      }));

      return {
        ...antragList,
        formGroups,
      };
    } catch (error) {
      if (!error?.response?.data?.message) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({ message: SUPPORT_ERROR_MESSAGE, type: 'danger' }));
      }
      throw error;
    }
  },

  async [ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_FOR_PROCESS]({ commit, dispatch }, payload) {
    const antragList = await dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_ONLY, payload);
    commit(ANTRAG_TYPES.MUTATIONS.RETRIEVE_ANTRAG_LIST_PROCESS_SUCCESS, antragList);
  },

  async [ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST]({ commit, dispatch }, payload) {
    const antragList = await dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_ONLY, payload);
    commit(ANTRAG_TYPES.MUTATIONS.RETRIEVE_ANTRAG_LIST_SUCCESS, antragList);
  },

  async [ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_FORMULARE]({ commit, dispatch }, payload) {
    const antragList = await dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_ONLY, payload);
    commit(ANTRAG_TYPES.MUTATIONS.SET_ANTRAG_LIST_FORMULARE, antragList);
  },

  /**
   * finds the antrag list to be shown as the Formulare options menu/submenu
   * 
   * @returns 
   */
  async [ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_OPTION_MENU]({ dispatch, getters, }) {
    await dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_FORMULARE);

    const antragList = getters[ANTRAG_TYPES.GETTERS.ANTRAG_LIST_FORMULARE];

    const lagerstelleList = (antragList?.formGroups || []).sort((om1, om2) => {
      if(!om1.label || !om2.label) return 1;
      return om1.label.toLowerCase().localeCompare(om2.label.toLowerCase());
    })
    .filter(lagerstelle => (!lagerstelle.isHidden));

    const isLagerstelleVersicherung = (lagerstelle) => lagerstelle.path === 'VERSICHERUNG';
    const menuPath = (lagerstelle) => `/beratung/formulare/antrag/${lagerstelle.path}`;
    const mapSubMenuItem = (lagerstelle, item) => 
      MenuItem(FormUtil.getLinkPath(lagerstelle, item), addBreakLines(item.friendlyName));
    const mapSubMenu = (lagerstelle) => FormUtil.getForms(lagerstelle)
      ?.filter(item => !item.hideForm)
      ?.map((item) => mapSubMenuItem(lagerstelle, item));

    return lagerstelleList.map(lagerstelle => {
      const menu = isLagerstelleVersicherung(lagerstelle)
        ? GroupMenu(menuPath(lagerstelle), lagerstelle.label, null, mapSubMenu(lagerstelle))
        : MenuItem(menuPath(lagerstelle), lagerstelle.label);

      return menu
        .withIcon(componentByLagerstelle(lagerstelle), {
          src: lagerstelle.logoURL,
          fit: 'contain',
          placeholder: {
            width: '78px',
            height: '48px',
          }
        });
    });
  },
  async [ANTRAG_TYPES.ACTIONS.GET_DWS_BASISKOMFORT_VORSCHAU]({ rootState, getters, dispatch }, payload) {
      if (!payload || !payload.id 
          || !getters[ANTRAG_TYPES.GETTERS.ANTRAEGE] 
          || !getters[ANTRAG_TYPES.GETTERS.ANTRAEGE][payload.id]
          || !getters[ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA][payload.id]) {
        return ;
      }
      const mscdata = {
        ...payload,
        ...getters[ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA][payload.id]
      }
      try {
        await axios.post(`${rootState.core.apiAddress}/download_service/dwsbasisrentevorschau`, mscdata, {
            defaultSpinner: true,
            responseType: 'blob',
          }).then(response => {
            let filename = getFilenameFromHeaders(response.headers)
            if (response.data) {
              viewDocument({
                filename, 
                data: response.data, 
                contentType: 'application/pdf',
              }, true);
            }
          })
        } catch(error) {
            if (error?.response?.data) {
              const responseJSON = await JSON.parse(await error.response.data.text());
              dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({ message: responseJSON.message, type: 'danger' }));
            } else {
              dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({ message: SUPPORT_ERROR_MESSAGE, type: 'danger' }));
            }
          };
  },
  async [ANTRAG_TYPES.ACTIONS.RETRIEVE_GESELLSCHAFTS_FORMULARE]({ commit, rootState }, payload) {
    let serviceUrl = '/formulareService/list';


    if (payload) {
      const filterCompany = payload.filterCompany;
      const filterArt = payload.filterArt;

      if (filterCompany) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterCompany', filterCompany);
      }      

      if (filterArt) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'art', filterArt);
      } else {
        return;
      }

    }    

    commit(ANTRAG_TYPES.MUTATIONS.RETRIEVE_GESELLSCHAFTS_FORMULARE_SUCCESS, []);
    await axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      commit(ANTRAG_TYPES.MUTATIONS.RETRIEVE_GESELLSCHAFTS_FORMULARE_SUCCESS, response?.data ?? []);
    })
  },

  async [ANTRAG_TYPES.ACTIONS.RETRIEVE_GESELLSCHAFTS_FORMULARE_LINKS]({ commit, rootState }, payload) {
    let serviceUrl = '/formulareService/formList';


    if (payload) {
      const gesellschaftId = payload.gesellschaftId;

      if (gesellschaftId) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'gesellschaftId', gesellschaftId);
      } else {
        return;
      }

    }    

    await axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(ANTRAG_TYPES.MUTATIONS.RETRIEVE_GESELLSCHAFTS_FORMULARE_LINKS_SUCCESS, response.data);

      }      

    })
  },

  async [ANTRAG_TYPES.ACTIONS.RETRIEVE_GESELLSCHAFTS_MAINSTRUCTURE]({ commit, rootState }, payload) {
    const serviceUrl = '/formulareService/mainStructure';
    const response = await axios.get(rootState.core.apiAddress + serviceUrl, config);
    commit(ANTRAG_TYPES.MUTATIONS.RETRIEVE_GESELLSCHAFTS_MAINSTRUCTURE_SUCCESS, response?.data ?? []);
  },

  [ANTRAG_TYPES.ACTIONS.UPDATE_KONSORTE_EBASE_AAB]({ commit, rootState }, payload) {
    axios.post(rootState.core.apiAddress + '/antraegeService/updateKonsorteEbaseAab', payload, config).then(response => {
      if (response && response.data) {
        commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, response.data);
      }      

    })
  },   

  async [ANTRAG_TYPES.ACTIONS.GET_WAEHRUNG_VALUES]({ rootState }) {
    const response = await axios.get(`${rootState.core.apiAddress}/antraegeService/getWaehrungValues`, config);
    return response;
  },   

  async [ANTRAG_TYPES.ACTIONS.GET_FREMDWAEHRUNG_VALUES]({ rootState, state},payload) {
    let serviceUrl = `/antraegeService/getFremdwaehrungValues`;
    if (payload) {
      const antragsId = payload.id;

      if (antragsId) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'id', antragsId);
      } else {
        return;
      }
    }
     const response = await axios.get(rootState.core.apiAddress + serviceUrl, payload?.id);
     return response;  
  },   
  async [ANTRAG_TYPES.ACTIONS.CAPITALBANK_DEPOTS_VORHANDEN]({ rootState,commit},payload) {
    let serviceUrl = `/antraegeService/getCapitalbankDepotVorhanden`;
    const depotnr = payload.depotnr;
    if (depotnr) {
  
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'depotnr', depotnr);
    } else {
      return;
    }
     const response = await axios.get(rootState.core.apiAddress + serviceUrl,depotnr);
     if(response && response.data){
      return response.data
     }else{
       return []
     }
  },   

  [ANTRAG_TYPES.ACTIONS.START_VIDEO_INDENT]({ rootState }, payload) {
    return axios.get(rootState.core.apiAddress + '/antraegeService/startVideoIdent?id='+payload, {}, config);
  },
  
  [ANTRAG_TYPES.ACTIONS.LOAD_TODO_VIDEO_INDENT]({ commit, rootState }) {
    axios.post(rootState.core.apiAddress + '/antraegeService/getVideoIdentList', {}, config).then(response => {
      if (response && response.data) {
        commit(ANTRAG_TYPES.MUTATIONS.SET_TODO_VIDEO_INDENT, response.data);
      }
    })
  },
  [ANTRAG_TYPES.ACTIONS.LOAD_FFB_DOCS_DATA]({ rootState }, payload) {
    return axios.get(rootState.core.apiAddress + '/antraegeService/getFFBOnlineFlow?ffbId='+payload, config)
  },
  [ANTRAG_TYPES.ACTIONS.SAVE_FFB_SORGRECHTSNACHWEIS]({ commit, rootState},payload){
    if (!payload){
      return
    }
    let serviceUrl ='/antraegeService/saveFFBSorgrechtsnachweis?id=';
   
   return axios.post(rootState.core.apiAddress + serviceUrl + payload, config)
  
  },
  async [ANTRAG_TYPES.ACTIONS.IS_SPARPLAN_AVAILABLE]({rootState},payload){
    let serviceUrl = `/antraegeService/getIsSparplanAvailable?lagId=${payload.lagId}&isin=${payload.isin}`;
    return await axios.get(rootState.core.apiAddress + serviceUrl, config)
  },

  async [ANTRAG_TYPES.ACTIONS.GET_VERMITTLERAUSWAHL]({rootState}){
    let serviceUrl =  `/antraegeService/getVermittlerauswahlValues`
    return await axios.get(rootState.core.apiAddress + serviceUrl, config);
  },

  async [ANTRAG_TYPES.ACTIONS.GET_VERMITTLER_ADDRESS]({rootState},payload){
  
    let serviceUrl = payload == "M" ? `/antraegeService/getMaklerAdresse` : `/antraegeService/getVermittlerAdresse?id=` + payload
    return await axios.get(rootState.core.apiAddress + serviceUrl, config);
  },

  async [ANTRAG_TYPES.ACTIONS.PRODUCE_MUSTERVORLAGE_PDF]({rootState}, payload){

    let serviceUrl =  `/antraegeService/producemustervorlagePDF/${payload.type}/${payload.personId}`;
    return await axios.post(rootState.core.apiAddress + serviceUrl, payload.mustervorlage, config);
  },

  [ANTRAG_TYPES.ACTIONS.TO_SIGNATURE_VORLEGEN]({rootState, commit}, { antragId, antragsdatenId, protokollId, data }) {
    if ((antragsdatenId || protokollId) && data) {
      commit(ANTRAG_TYPES.MUTATIONS.START_SAVE_ANTRAG_STATE);
      axios.post(`${rootState.core.apiAddress}/antraegeService/to_signatur_vorlegen?antragsdatenId=${antragsdatenId || ''}&protokollId=${protokollId || ''}`, data, config).then(response => {
        if (response.data) {
          commit(ANTRAG_TYPES.MUTATIONS.UPDATE_IS_VORGELEGT, { IS_VORGELEGT: response.data.IS_VORGELEGT, id: antragId, warnings: response.data.warnings || []});
        }
      })
      .finally(() => {
        commit(ANTRAG_TYPES.MUTATIONS.END_SAVE_ANTRAG_STATE);
      });
    }
  },

  [ANTRAG_TYPES.ACTIONS.GET_PDF_LIST]({rootState}, payload){
    return axios.get(`${rootState.core.apiAddress}/antraegeService/getPdfList?antragsdatenId=${payload}`, config);
  },

}