var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id:
            _vm.antragCustomNavigation.currentOptionMenuId ||
            _vm.$appNavigation.currentOptionMenuId,
          defaultMenu:
            _vm.antragCustomNavigation.currentOptionMenu ||
            _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("AntragHeaderTitleNavigation", {
        attrs: {
          title: _vm.lagerstelleName,
          subtitle: _vm.antragLabel,
          antragId: _vm.antragId,
          antrag: _vm.antrag,
          antragData: _vm.antragData,
          warnings: _vm.warnings,
          currentStepAdditionalButtons: _vm.additionalButtonsForCurrentStep,
          disabled: _vm.disabled
        }
      }),
      _c("div", { staticClass: "row my-0" }, [
        _vm.isSmallOrMediumScreen
          ? _c(
              "div",
              { staticClass: "d-lg-none col-12" },
              [
                _vm.steps
                  ? _c("AntragStep", {
                      attrs: {
                        antragStep: _vm.steps,
                        antragData: _vm.antragData
                      },
                      on: {
                        setStep: function($event) {
                          return _vm.setStepByKey($event)
                        },
                        setSubstep: function($event) {
                          return _vm.setSubstepByKey($event)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ]),
      !_vm.isSmallOrMediumScreen
        ? _c("div", { staticClass: "row my-0 hide-on-small-screen" }, [
            _vm.routeStep !== "aktionen"
              ? _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("AntragSidetext", {
                      attrs: {
                        warnings: _vm.warnings,
                        steps: _vm.antrag && _vm.antrag.steps,
                        showExpanded: _vm.antragId === "FK-aenderungsformular",
                        highestStepVisited:
                          _vm.antrag && _vm.antrag.highestStepVisited,
                        showHinweisOnEveryStep: _vm.showHinweisOnEveryStep
                      },
                      on: {
                        setStep: function($event) {
                          return _vm.setStepByKey($event)
                        },
                        setSubstep: function($event) {
                          return _vm.setSubstepByKey($event)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      !_vm.isSmallOrMediumScreen
        ? _c("ContentWithStepper", {
            attrs: { stepperCollapsed: _vm.stepperCollapsed },
            scopedSlots: _vm._u(
              [
                !_vm.hideStepper
                  ? {
                      key: "stepper",
                      fn: function() {
                        return [
                          _vm.steps
                            ? _c("AntragVerticalStep", {
                                attrs: {
                                  antragStep: _vm.steps,
                                  antragData: _vm.antragData
                                },
                                on: {
                                  setStep: function($event) {
                                    return _vm.setStepByKey($event)
                                  },
                                  setSubstep: function($event) {
                                    return _vm.setSubstepByKey($event)
                                  },
                                  collapsed: function($event) {
                                    _vm.stepperCollapsed = $event
                                  },
                                  zusammenfassung: function($event) {
                                    return _vm.saveAntrag($event)
                                  }
                                }
                              })
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "row my-0" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _vm.components
                              ? _c("AntragComponentList", {
                                  attrs: {
                                    antragComponents: _vm.components,
                                    comboboxSelections: _vm.comboboxSelections,
                                    multiSelectionsTabledata:
                                      _vm.multiSelectionsTabledata,
                                    antragId: _vm.antragId,
                                    warnings: _vm.warnings,
                                    antragData: _vm.antragData,
                                    showTwoColumns: ""
                                  },
                                  on: { change: _vm.changeEvent }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _vm.routeStep === "aktionen"
                        ? _c("div", { staticClass: "row my-0" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("AntragSidetext", {
                                  attrs: {
                                    warnings: _vm.warnings,
                                    steps: _vm.antrag && _vm.antrag.steps,
                                    highestStepVisited:
                                      _vm.antrag &&
                                      _vm.antrag.highestStepVisited,
                                    showMessageErrorMustBeFixed: "",
                                    showExpanded: ""
                                  },
                                  on: {
                                    setStep: function($event) {
                                      return _vm.setStepByKey($event)
                                    },
                                    setSubstep: function($event) {
                                      return _vm.setSubstepByKey($event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.routeStep === "zusdokumente"
                        ? _c("div", { staticClass: "row my-0" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("AntragAttachments", {
                                  attrs: {
                                    steps: _vm.antrag && _vm.antrag.steps,
                                    antragData: _vm.antragData,
                                    antragId: _vm.antragId
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm.antrag && !_vm.antrag.hideBottomButtons
                        ? _c("div", { staticClass: "row my-0" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("AntragBottomButtons", {
                                  attrs: {
                                    antragStep: _vm.steps,
                                    routeStep: _vm.routeStep,
                                    antragData: _vm.antragData,
                                    antragId: _vm.antragId
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _c("BaseModal", {
        ref: "infoModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c("PhInfo", {
                  staticStyle: { color: "var(--color-success)" }
                }),
                _vm._v(" " + _vm._s(_vm.infoTitle) + " ")
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", {
                  staticClass: "info-modal--content",
                  domProps: {
                    innerHTML: _vm._s(_vm.antragData.aenderungenHinweise)
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: _vm.currentAntragStepTitle,
            size: "lg",
            showCancelButton: false,
            actions: _vm.baseModalActions,
            showConfirmButton: false,
            autoCloseOnRouteNavigation: false
          },
          on: {
            onCloseButton: function($event) {
              return _vm.onCloseEditModal()
            },
            "action-WEITER": function($event) {
              return _vm.navigateNextStep()
            },
            "action-ZURUCK": function($event) {
              return _vm.navigatePreviousStep()
            },
            "action-SCHLIESSEN": function($event) {
              return _vm.onSchliessen()
            },
            "action-PREVIOUS_FORM": function($event) {
              return _vm.navigateToPreviousAntrag()
            }
          }
        },
        [
          !_vm.loadingStep
            ? _c("div", { staticClass: "modal__container" }, [
                _c("div", { staticClass: "row my-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _vm.components
                        ? _c("AntragComponentList", {
                            attrs: {
                              antragComponents: _vm.components,
                              comboboxSelections: _vm.comboboxSelections,
                              multiSelectionsTabledata:
                                _vm.multiSelectionsTabledata,
                              antragId: _vm.antragId,
                              warnings: _vm.warnings,
                              antragData: _vm.antragData,
                              showTwoColumns: ""
                            },
                            on: { change: _vm.changeEvent }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm.routeStep === "aktionen"
                  ? _c("div", { staticClass: "row my-0" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("AntragSidetext", {
                            attrs: {
                              warnings: _vm.warnings,
                              steps: _vm.antrag && _vm.antrag.steps,
                              highestStepVisited:
                                _vm.antrag && _vm.antrag.highestStepVisited,
                              showMessageErrorMustBeFixed: "",
                              showExpanded: ""
                            },
                            on: {
                              setStep: function($event) {
                                return _vm.setStepByKey($event)
                              },
                              setSubstep: function($event) {
                                return _vm.setSubstepByKey($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.routeStep === "zusdokumente"
                  ? _c("div", { staticClass: "row my-0" }, [
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("AntragAttachments", {
                            attrs: {
                              steps: _vm.antrag && _vm.antrag.steps,
                              antragData: _vm.antragData,
                              antragId: _vm.antragId
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            : _c("GhostLoading", { attrs: { type: "table" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }