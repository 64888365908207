import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './configs' // All configs are loaded into config/index.js
import './directives'
import './plugins'
import './filters'
import './browser-virtual-keyboard';

import '@/styles/index.scss';
import 'echarts/i18n/langDE';
import '@/views/cms/template8/fondsshop-webcomponent';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

