<template>
    <div>
        <div class="mb-5">
            <InputToggleSwitch 
                v-model="djeAktiv" 
                inLineLabel
                label="Anlage in Managed Depot erfassen" 
                :disabled="isDisabled"
                @input="updateStore('zusatzDJE', 'djeAktiv', $event)"/>
        </div>
        <Table
            :headers="headers"
            :rows="rows"
            rowId="zeithorizont"
            hidePagination
            >
            <template v-slot:betraege="row">
                <InputField
                    label="einmalig"
                    type="currency"
                    :precision="2"
                    :value="row.einmalig"
                    :disabled="isDisabled || !isZusatzDJEAktiv"
                    @change="updateTableValue(row, 'einmalig', $event)" />
                <InputField
                    label="ratierlich"
                    type="currency"
                    :precision="2"
                    :value="row.ratierlich"
                    :disabled="isDisabled || !isZusatzDJEAktiv"
                    @change="updateTableValue(row, 'ratierlich', $event)" />
            </template>
            <template v-slot:anlageziel="row">
                <InputRadioBoxGroup 
                    :value="row.anlageziel" 
                    :disabled="isDisabled || !isZusatzDJEAktiv"
                    :values="anlagezielValues"
                    @input="updateTableValue(row, 'anlageziel', $event)"/>
            </template>
        </Table>
    </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import { SlotColumn, TextColumn,} from "@/components/table2/table_util.js";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch';
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';

export default {
    mixins: [anlegerprofilMixin],
    components: {
        Table,
        InputToggleSwitch,
        InputField,
        InputRadioBoxGroup,
    },
    data() {
        return {
            djeAktiv: false,
            zusatzDJETemp: null,
            headers: {
                lockedLeft: [
                    TextColumn("zeithorizontDesc", "Zeithorizont"),
                ],
                center: [
                    SlotColumn("betraege", "Betrag"),
                    SlotColumn("anlageziel", "Nachhaltigkeitspräferenzen"),
                ],
                lockedRight: [],
            }
        }
    },
    watch: {
        data: function (newData, oldData) {
            if (!this.zusatzDJETemp) {
                this.zusatzDJETemp = newData?.zusatzDJE;
                this.createForm();
            }
        },
    },
    computed: {
        rows() {
            return this.data?.zusatzDJE?.zielmarktRows || [];
        },
        anlagezielValues() {
            return this.data?.zusatzDJE?.anlagezielValues || [];
        },
    },
    mounted() {
        this.createForm();
    },
    methods: {
        updateTableValue(row, compId, value) {
            if (row && compId) {
                this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_DATA_STORE_ZUSATZ_DJE_ZIELMARKT_ROWS, 
                    { row, data: { [compId]: (compId === 'einmalig' || compId === 'ratierlich') && value ? +value : value || null}});            
            }
        },
        createForm() {
            this.djeAktiv = this.zusatzDJETemp?.djeAktiv || this.data?.zusatzDJE?.djeAktiv;
        },
    },
}
</script>

<style>

</style>