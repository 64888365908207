var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseFormWithStages", {
        attrs: {
          pageHeaderActions: _vm.actions,
          pageHeaderActionListeners: _vm.pageHeaderActionListeners,
          cardsData: _vm.cardsData,
          subtitle: _vm.processForm.description,
          loading: _vm.loading
        },
        scopedSlots: _vm._u([
          {
            key: "beforeCards",
            fn: function() {
              return [
                _c("div", { staticClass: "box__container" }, [
                  _c("div", [
                    _c("span", { staticClass: "font-strong" }, [
                      _vm._v("Makler Externer Zugang: ")
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.processForm.maklerExtZugang.bezeichnung)
                      )
                    ])
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "font-strong" }, [
                      _vm._v("Vorgangs-Nr.: ")
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.processForm.vorgangsNr))])
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "afterCards",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("Table", {
                      attrs: {
                        title: "Ereignisse",
                        headers: _vm.ereignisseHeaders,
                        rows: _vm.ereignisseRows,
                        rowsPerPage: 0,
                        hidePagination: ""
                      }
                    })
                  ],
                  1
                ),
                _vm.processDateCreated
                  ? _c("div", [
                      _vm._v(" " + _vm._s(_vm.processDateCreated) + " ")
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "formContent",
            fn: function() {
              return [
                _c("div", [
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [
                        _c("div", [_vm._v("Finanzbedarf")]),
                        _c(
                          "div",
                          [
                            _c("CurrencyLabel", {
                              staticClass: "currency_label_right",
                              attrs: {
                                label: "Gesamtkapitalbedarf",
                                value:
                                  _vm.processVorhabenOverview
                                    .gesamtkapitalbedarf
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [
                        _c("div", { staticClass: "text-small" }, [
                          _vm._v(" Kosten ")
                        ]),
                        _c(
                          "div",
                          [
                            _c("CurrencyLabel", {
                              staticClass: "currency_label_right",
                              attrs: {
                                label: "Kosten",
                                value: _vm.processVorhabenOverview.kosten
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Kaufpreis",
                              value: _vm.processVorhabenOverview.kaufpreis
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Grunderwerbsteuer",
                              value:
                                _vm.processVorhabenOverview.grunderwerbsteuer
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Mobiliarkosten",
                              value: _vm.processVorhabenOverview.mobiliarkosten
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Notargebühren",
                              value: _vm.processVorhabenOverview.notargebuehr
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Sonstige Kosten",
                              value: _vm.processVorhabenOverview.sonstigeKosten
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Maklergebühren",
                              value: _vm.processVorhabenOverview.maklergebuehr
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Modernisierungskosten",
                              value:
                                _vm.processVorhabenOverview.modernisierungKosten
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Renovierungskosten",
                              value:
                                _vm.processVorhabenOverview
                                  .modernisierungRenovierungsKosten
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [
                        _c("div", { staticClass: "text-small" }, [
                          _vm._v(" Finanzierungskosten ")
                        ]),
                        _c(
                          "div",
                          [
                            _c("CurrencyLabel", {
                              staticClass: "currency_label_right",
                              attrs: {
                                label: "Beratungshonorar",
                                value:
                                  _vm.processVorhabenOverview.beratungshonorar
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Beratungshonorar",
                              value:
                                _vm.processVorhabenOverview.beratungshonorar
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [
                        _c("div", [
                          _vm._v(
                            "Eingesetztes Eigenkapital, Vermögen und Drittdarlehen"
                          )
                        ]),
                        _c(
                          "div",
                          [
                            _c("CurrencyLabel", {
                              staticClass: "currency_label_right",
                              attrs: {
                                label: "Eingesetztes Kapital",
                                value:
                                  _vm.processVorhabenOverview
                                    .eingesetztesKapital
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [_c("div", [_vm._v("Präferenzen")])]
                    )
                  ]),
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [
                        _c("div", [_vm._v("Finanzierungswunsch")]),
                        _c(
                          "div",
                          [
                            _c("CurrencyLabel", {
                              staticClass: "currency_label_right",
                              attrs: {
                                label: "Noch benötigte Darlehen",
                                value:
                                  _vm.processVorhabenOverview
                                    .nochBenoetigteDarlehen
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "modalExportProcess",
          attrs: {
            modalTitle: "Vorgang exportieren",
            labelButtonConfirm: "exportieren"
          },
          on: { click: _vm.exportProcess }
        },
        [
          _c("ComboBox", {
            attrs: {
              label: "Vorgang exportieren nach",
              values: _vm.maklerExtZugangList
            },
            model: {
              value: _vm.exportMaklerExtZugangId,
              callback: function($$v) {
                _vm.exportMaklerExtZugangId = $$v
              },
              expression: "exportMaklerExtZugangId"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }