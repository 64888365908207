var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "checkbox__container",
      class: { "checkbox__check--disabled": _vm.disabled }
    },
    [
      _c(
        "label",
        {
          staticClass: "checkbox__clickable",
          attrs: { "aria-label": "Checkbox" }
        },
        [
          _c("span", { staticClass: "checkbox__body" }, [
            _c("input", {
              attrs: {
                type: "checkbox",
                indeterminate: _vm.indeterminate,
                disabled: _vm.disabled,
                id: _vm.id
              },
              domProps: { checked: _vm.checked },
              on: {
                input: function($event) {
                  return _vm.onChange($event)
                }
              }
            }),
            _c("span", { staticClass: "checkbox__check" })
          ])
        ]
      ),
      _vm.label
        ? _c("span", {
            staticClass: "checkbox__label",
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }