<template>
    <div>
        <div class="row">
            <div class="col-12 col-xl-11">
                <div class="row d-none d-xl-flex justify-content-between header" v-if="first">
                    <label class="col-xl-2 px-1">Mind. aktueller Depotwert zzgl. Anlagebetrag einmalig</label>
                    <label class="col-xl-2 px-1">Anlagebetrag ratierlich inkl. bestehender Sparpläne</label>
                    <label class="col-xl-2 px-1">Zeithorizont</label>
                    <label class="col-xl-2 px-1">Welche Anlageziele haben Sie?</label>
                    <label class="col-xl-2 px-1" v-if="!hideSpezielleZiele">Welche speziellen Anlageziele haben Sie?</label>
                    <label class="col-xl-2 px-1">Bereitschaft Verluste zu tragen</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xl-11">
                <div class="row justify-content-between">
                    <label class="col-12"><span class="header">{{title}}</span><span style="font-style: italic">{{positionsStr}}</span></label>
                    <div class="col-12 col-xl-2 px-1">
                        <label class="d-xl-none">Einmalig</label>
                        <InputField 
                            v-model="einmalig"
                            type="currency"
                            :disabled="disabled"
                            @input="emitChange($event, 'einmalig', einmaligStr)"
                        >
                        </InputField> 
                    </div>
                    <div class="col-12 col-xl-2 px-1">
                        <label class="d-xl-none">Ratierlich</label>
                        <InputField
                            v-model="ratierlich"
                            type="currency"
                            :disabled="disabled"
                            @input="emitChange($event, 'ratierlich', ratierlichStr)"
                        >
                        </InputField> 
                    </div>
                    <div class="col-12 col-xl-2 px-1">
                        <label class="d-xl-none">Zeithorizont</label>
                        <ComboBox
                            v-model="zeithorizont" 
                            :values="comboboxValues ? comboboxValues.horizont : []"
                            :disabled="disabled"
                            :sortComboboxValues="false"
                            @change="emitChange($event, null, zeithorizontStr)">
                        </ComboBox>
                    </div>
                    <div class="col-12 col-xl-2 px-1">
                        <label class="d-xl-none">Anlageziel</label>
                        <ComboBox
                            v-model="anlageziel" 
                            :values="comboboxValues ? comboboxValues.anlageziel : []"
                            :firstEmpty="true"
                            :disabled="disabled"
                            @change="emitChange($event, null, anlagezielStr)">
                        </ComboBox>
                    </div>
                    <div class="col-12 col-xl-2 px-1" v-if="!hideSpezielleZiele">
                        <label class="d-xl-none">Anlageziel speziell</label>
                        <ComboBox
                            v-model="anlagezielSpeziell" 
                            :values="comboboxValues ? comboboxValues.anlagezielSpeziell : []"
                            :firstEmpty="true"
                            :disabled="disabled"
                            @change="emitChange($event, null, anlagezielSpeziellStr)">
                        </ComboBox>
                    </div>
                    <div class="col-12 col-xl-2 px-1">
                        <label class="d-xl-none">Verlusttragfähigkeit</label>
                        <ComboBox
                            v-model="verlust" 
                            :values="comboboxValues ? comboboxValues.verlust  : []"
                            :firstEmpty="true"
                            :disabled="disabled"
                            @change="emitChange($event, null, verlustStr)">
                        </ComboBox>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-1 mt-2 clickable d-flex align-items-center justify-content-end" >
                <span class="pr-1"><PhTrash :size="16" @click="removeZeile()" weight="bold" v-if="count !== 1"/></span>
                <span class="pl-1"><PhPlus :size="16" weight="bold" @click="addZeile()" v-if="count < maxRows"/></span>
            </div>
        </div>
    </div>  
</template>
    
<script>
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import validator from "@/mixins/validator";
import { PhTrash, PhPlus } from "phosphor-vue";

export const EINMALIG_STR = 'input_ziele_betrag_einmal_';
export const RATIERLICH_STR = 'input_ziele_betrag_ratierlich_';
export const HORIZONT_STR = 'input_ziele_zeithorizont_';
export const ZIEL_STR = 'input_ziele_ziel_';
export const ZIEL_SPEZIELL_STR = 'input_ziele_ziel_speziell_';
export const VERLUST_STR = 'input_ziele_vlt_';

export default {
    mixins: [validator],
    validators: {},
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        count: {
            type: Number,
            default: 1,
        },
        maxRows: {
            type: Number,
            default: 5,
        },
        suffix: {
            type: String,
            default: 'zeile',
        },
        data: {
            type: Object,
        },
        comboboxValues: {
            type: Object,
            default: () => {},
        },
        warnings: {
            type: Array,
            default: () => [],
        },
        positionsCount: {
            type: Number,
        },
        hideSpezielleZiele: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        ComboBox,
        InputField,
        PhTrash,
        PhPlus,
    },
    data() {
        return {
            einmalig: '',
            ratierlich: '',
            zeithorizont: '',
            anlageziel: '',
            anlagezielSpeziell: '',
            verlust: '',
            einmaligStr: '',
            ratierlichStr: '',
            zeithorizontStr: '',
            anlagezielStr: '',
            anlagezielSpeziellStr: '',
            verlustStr: '',
        };
    },
    computed: {
        first() {
            return this.count === 1;
        },
        title() {
            return 'Zeile ' + this.count;
        },
        positionsStr() {
            return this.positionsCount ? '  (' + this.positionsCount + ' Position' 
                + (this.positionsCount > 1 ? 'en' : '') + ')' : '';
        },
    },
    mounted() {
        this.createForm(this.suffix, this.count);
        this.patchForm();
        this.updateWarnings();
    },
    watch: {
        warnings: function(value) {
            this.updateWarnings();
        },
        data: function(value) {
            this.patchForm();
        }
    },
    methods: {
        createForm() {
            this.einmaligStr = `${EINMALIG_STR}${this.suffix}${this.count}`;
            this.ratierlichStr = `${RATIERLICH_STR}${this.suffix}${this.count}`;
            this.zeithorizontStr = `${HORIZONT_STR}${this.suffix}${this.count}`;
            this.anlagezielStr = `${ZIEL_STR}${this.suffix}${this.count}`;
            this.anlagezielSpeziellStr = `${ZIEL_SPEZIELL_STR}${this.suffix}${this.count}`;
            this.verlustStr = `${VERLUST_STR}${this.suffix}${this.count}`;
            this.patchForm();
        },
        getEmptyZeile(count) {
            return {
                [`${EINMALIG_STR}${this.suffix}${count}`]: 0,
                [`${RATIERLICH_STR}${this.suffix}${count}`]: 0,
                [`${HORIZONT_STR}${this.suffix}${count}`]: '',
                [`${ZIEL_STR}${this.suffix}${count}`]: '',
                [`${ZIEL_SPEZIELL_STR}${this.suffix}${count}`]: '',
                [`${VERLUST_STR}${this.suffix}${count}`]: '',
            }
        },
        getValues() {
            const values = {};
            values[this.einmaligStr] = this.einmalig;
            values[this.ratierlichStr] = this.ratierlich;
            values[this.zeithorizontStr] = this.zeithorizont;
            values[this.anlagezielStr] = this.anlageziel;
            values[this.anlagezielSpeziellStr] = this.hideSpezielleZiele ? '' : this.anlagezielSpeziell;
            values[this.verlustStr] = this.verlust;
            return values;
        },
        patchForm() {
            this.einmalig = this.data[this.einmaligStr] || 0;
            this.ratierlich = this.data[this.ratierlichStr] || 0;
            this.zeithorizont = this.data[this.zeithorizontStr],
            this.anlageziel = this.data[this.anlagezielStr],
            this.anlagezielSpeziell = this.data[this.anlagezielSpeziellStr],
            this.verlust = this.data[this.verlustStr],
            this.$emit('updateSum', { type: 'einmalig', count: this.count, value: this.einmalig });
            this.$emit('updateSum', { type: 'ratierlich', count: this.count, value: this.ratierlich });
        },
        addZeile() {
            if (!this.disabled) {
                this.$emit('addZeile', this.getEmptyZeile(this.suffix, this.count + 1));
            }
        },
        removeZeile() {
            if (!this.disabled) {
                if (this.einmalig || this.ratierlich) {
                    this.$confirmModal({
                        title: 'Anlageziele löschen', 
                        message: 'Wollen Sie die Anlageziele wirklich löschen?', 
                        labelButtonConfirm: 'Löschen',
                    }).then(() => {
                        this.doRemoveZeile();
                    })
                } else {
                    this.doRemoveZeile();
                }
            }
        },
        doRemoveZeile() {
            this.emitChange(0, 'einmalig', this.einmaligStr);
            this.emitChange(0, 'ratierlich', this.ratierlichStr);
            this.$emit('removeZeile', {suffix: this.suffix, count: this.count })
        },
        emitChange(value, type, param) {
             this.$emit('change', { type: type, count: this.count, value: value, param: param, fullSuffix: this.suffix + this.count });
        },
        updateWarnings() {
            (this.warnings || []).filter(warn => warn.id).forEach(warn => {
                let componentId;
                switch(warn.id) {
                    case this.einmaligStr:
                        componentId = 'einmalig';
                        break;
                    case this.ratierlichStr:
                        componentId = 'ratierlich';
                        break;
                    case this.zeithorizontStr:
                        componentId = 'zeithorizontStr';
                        break;
                    case this.anlagezielStr:
                        componentId = 'anlageziel';
                        break;
                    case this.verlustStr:
                        componentId = 'verlust';
                        break;

                }
                if (componentId) {
                    this.$pushErrors(componentId, [warn.message]);  
                }
            });
        },
    }
}
</script>

<style>
    .header {
        font-weight: bold;
    }
</style>