import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import dayjs from 'dayjs';
import VERSICHERUNG_TYPES from '@/store/versicherungen/types';
import { isDeepEquals } from '@/helpers/utils-helper.js'
import { required, regex, maxLength, kundennr } from '@/mixins/validator/rules';
import { parse } from '@/helpers/number-formatter.js';
import { buildMessage, } from '@/helpers/log-message-helper';
import LOG_TYPES from '@/store/log/types';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import customParseFormat from "dayjs/plugin/customParseFormat";
import { formatNumber, isNumber } from '@/helpers/number-formatter.js'
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import CALENDAR_TYPES from "@/store/calendar/types";
import { MAP_STATUS_TO_PILL_TYPE } from '@/views/versicherungen/insurance-group/insuranceSearchConstants';
import { ROLES, VIEW_ROLES } from '@/router/roles.js'
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import COMMUNICATION_TYPES from '@/store/communication/types';
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import EVENT_BUS, { SEND_EMAIL, SEND_BRIEF, } from '@/event-bus';
import axios from 'axios';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import INSURANCE_BUCHUNG_TYPES from "@/store/insuranceBuchung/types";
import EXTERNE_ZUGANG_TYPES from '@/store/externeZugang/types';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';

const hinweiseStepAllgemeine = [
  { label: 'Eigenbestand', text: 'die Versicherung befindet sich in Ihrem Bestand.'},
  { label: 'Geteilt mit Kunde', text: "Teilt die Versicherung mit der eingegebenen Kundennummer. "
  + "Sie wird dann bei dem geteilten Kunden zusammen mit seinen eigenen "
  + "Versicherungen angezeigt. "
  + "Liegt der Kunde mit dem geteilt wird in Ihrer Unterstruktur, kann "
  + "Ihr Untervermittler dieses Kunden die Versicherung bearbeiten, Dokumente "
  + "einsehen und hochladen. "
  + "Der Kunde mit dem geteilt wird kann die Versicherungsdaten einsehen, "
  + "Dokumente aber nur wenn Sie explizit freigegeben werden."},
];

const ROWS_PER_PAGE_DEFAULT = 20;

export default {
  data() {
    return {
      versicherung: {
        id: null,
        kundennr: null,
        bezeichnung: null,
        description: { id: null },
        gesellschaft: { id: null },
        nummer: null,
        kennzeichen: null,
        beginn: null,
        ende: null,
        versObject: null,
        tarif: null,
        praemiebto_zahlweise: null,
        praemiento_zahlweise: null,
        zahlrhythm: null,
        zahlweiseBemerkung: null,
        rueckkaufswert: null,
        produktbez: null,
        status: null,
        vtgGruppe: null,
        groupId: null,
        kategorie: null,
        kategorieName: null,
        doks: null,
        sparten: null,
        weitereSparten: null,
        action: null,
        fremdanlage: null,
        einlesen_updaten: true,
        statusAktuell: null,
        externeKennung: null,
        dateUpdatetdGDV: null,
        isFremdvertrag: null,
        isEigenerVertrag: null,
        kuendiegungsMailPojo: null,
        maklerGesellNr: null,
        zahlungsart: null,
        anzeigeModusDauer: 'ENDE',
        bankverbindung: "NICHT_VERWENDEN",
        bezug: '',
        anbindung: 'FPPLUS_PRIVAT',
        betreuungsstufe: 'Ohne',
        bestandsubertragung: false,
        geteiltmitKunde: null,
        versichertesObjekt: {},
        layoutModus: null,
        letzteAenderungGueltigAb: null,
        aenderungsGrund: null,
        korrespondenz: false,
        buAbgelehnt: false,
        konvertierung: false,
      },
      lastLoadedGesellschaftListParams: null,
      disableBetreungsstufe: true,
      rowsPerPage: ROWS_PER_PAGE_DEFAULT,
      statusToPillType: MAP_STATUS_TO_PILL_TYPE,
      selectedRowsValues: [],
      selectedStepKey: null,
    };
  },
  watch: {
    versicherungenDetails() {
      this.updateModel();
      this.validateNummer && this.validateNummer();
    },
    '$route.params.id': {
      handler() {
        if(this.$route?.params?.id){
          this.loadGesellschaftList(this.$route?.params?.id, this.versicherung?.description?.id, this.versicherung.displayFremdges)
        }
      }
    },
    'versicherung.description': {
      handler() {
        if(this.$route?.params?.id){
          this.loadGesellschaftList(this.$route?.params?.id, this.versicherung?.description?.id, this.versicherung.displayFremdges)
        }
      },
      deep: true,
    },
    'vertragsdatenFieldsDefinition.polNr.validation'() {
      if(this.versicherung?.gesellschaft?.id){
        this.validateNummer && this.validateNummer();
      }
    },
    'vertragsdatenFieldsDefinition.anbindung.value'() {
      if(this.versicherungDetailsEdited?.gesellschaft && this.versicherung?.anbindung) {
        this.dataChanged('anbindung', this.versicherung?.anbindung)
      }
    },
  },
  computed: {
    ...mapGetters({
      versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
      versicherungDetailsEdited : VERSICHERUNG_TYPES.GETTERS.RETRIEVE_VERSICHERUNG_DETAILS_EDITED,
      vertragsdatenFieldsDefinition: VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION,
      categorySelected: VERSICHERUNG_TYPES.GETTERS.INSURANCE_AUSWAHL_VERTRAGSART_SELECTED,
      gesellschaftMaklerGesellNr: VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_GESELLSCHAFT_MAKLERGESELLNR,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
      gesellschaftList: VERSICHERUNG_TYPES.GETTERS.GESELLSCHAFT_LIST,
      versicherungFormValidation: VERSICHERUNG_TYPES.GETTERS.VERSICHERUNG_FORM_VALIDATION,
      selectedCustomersExternal: CUSTOMER_SEARCH_TYPES.GETTERS.SET_SELECTED_CUSTOMERS,
      isOriginalUserBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      fcConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      externalUrl: VERSICHERUNG_TYPES.GETTERS.EXTERNAL_URL,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      versAdminA: CORE_TYPES.GETTERS.VERSADMIN_A,
      versAdminM: CORE_TYPES.GETTERS.VERSADMIN_M,
      maklerVollmacht: VERSICHERUNG_TYPES.GETTERS.MAKLER_VOLLMACHT,
      externeZugangVergleichPageHeaderActions: EXTERNE_ZUGANG_TYPES.GETTERS.EXTERNE_ZUGANG_VERGLEICH_PAGE_HEADER_ACTIONS,
      isSelectedAllInFolder: DOKUMENTENARCHIV_TYPES.GETTERS.IS_SELECTED_ALL_IN_FOLDER,
      selectedRowsDokArchiv: DOKUMENTENARCHIV_TYPES.GETTERS.SELECTED_ROWS,
    }),
    allowVersUpload() {
      return this.isViewCustomerAsBypass || this.hasRoles([ROLES.KUNDE_APP_ALLOW_VERS_UPLOAD]);
    },
    isViewCustomerAsBypass() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]);
    },    
    isMediumScreen() {
      return this.screenSize === 'md';
    },    
    isSmallOrMediumScreen() {
      return this.isSmallScreen || this.isMediumScreen;
    },    
    getLabel() {
      if (this.isFA) {
        return 'Inkasso- und OMDS-Daten';
      }

      return 'Vertragsdaten';
    },    
    canChangeVertragKundennr() {
      return this.hasRoles([ROLES.SONDERRECHT_KUNDENNR, [ROLES.FK, VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER]]);
    },
    pramieBruttoJahr() {
      return this.getPraemieJahr(this.versicherung.praemiebto_zahlweise);
    },
    pramieNettoJahr() {
      return this.getPraemieJahr(this.versicherung.praemiento_zahlweise);
    },
    isNewVertrag() {
      return !!this.$route.query.newVertrag;
    },
    fieldsValidators() {
      let validators = {
          gesellschaft : [required('Gesellschaft ist erforderlich!')],
          kennzeichen: [maxLength(12, 'Wert zu groß für Kennzeichen (maximal: 12)'),
          // To force the German pattern for Kennzeichen
          // regex(new RegExp(/^[A-ZÖÜÄ]{1,3}\-?\s?[A-ZÖÜÄ]{1,2}\s?[1-9]{1}[0-9]{1,3}[A-ZÖÜÄ]{0,2}$/), 
          //               'ungültiges Kennzeichen', {emptyAllowed: true})      
        ],
        geteiltmitKunde:  [kundennr('', {emptyAllowed: true})], 
      }
      if(this.isFPPlus) {
        validators.beginn = [required('Vertragsbeginn ist erforderlich!')];
        validators.laufzeit = [required('Laufzeit ist erforderlich!')];
      }
      const nummerValidators = [];
      if(this.vertragsdatenFieldsDefinition?.polNr?.validation && this.versicherung.gesellschaft.id) {
        nummerValidators.push(regex(new RegExp(this?.vertragsdatenFieldsDefinition?.polNr?.validation), 
                          this.vertragsdatenFieldsDefinition.polNr.validationMessage, {emptyAllowed: true}));
      }
      validators = {...validators, nummer: nummerValidators}

      return {versicherung: validators};
    },
        betreungsstufeComputedValues() {
      let betreungsstufeValues = this.vertragsdatenFieldsDefinition.betreuungsstufe.value;
      if('FPPLUS_GEWERBE' === this.vertragsdatenFieldsDefinition.anbindung.value[0].value || 'FPPLUS_GEWERBE' === this.versicherung.anbindung ) {
        //removing 'Ohne' value for FP+ Gewerbe GmbH
        betreungsstufeValues = this.vertragsdatenFieldsDefinition.betreuungsstufe.value.filter(item => item.value !== 'Ohne');
        this.disableBetreungsstufe = false;
        return betreungsstufeValues;
      }
      this.disableBetreungsstufe = true;
      return betreungsstufeValues;
    },
    isVorsorgeOrGesundheight() {
      return this.versicherungenDetails?.insurance?.kategorie == 'VORSORGE' || this.versicherungenDetails?.insurance?.kategorie == 'GESUNDHEIT';
    },
    headerActionsWithBearbeiten() {
      const actions = [];

      actions.push(PageHeaderSimpleAction('BEARBEITEN', 'Bearbeiten').withVisible(() => this.isViewCustomerAsBypass));
      return [...actions, ...this.headerActions];
    },
    headerActions() {
      const {isOriginalUserBroker, isOriginalUserIntern, versicherungenDetails } = this;

      const actions = [];

      if(isOriginalUserBroker || isOriginalUserIntern) {
        actions.push(PageHeaderSimpleAction('EMAIL-GESELLSCHAFT', 'E-Mail an Gesellschaft'));
        actions.push(PageHeaderSimpleAction('EMAIL-KUNDE', 'E-Mail an Kunde'));
      }

      if (this.hasRoles([ROLES.ALLOW_VERS_SCHADEN])) {
        actions.push(PageHeaderSimpleAction('SCHADEN-MELDEN', this.isVorsorgeOrGesundheight ? 'Leistungsabrechnung' : 'Schaden melden'));
      }

      if (this.externalUrl){
        actions.push(PageHeaderSimpleAction('EXTERNEN-ZUGANG-AUFRUFEN', 'Externen Zugang aufrufen'));
	    }


      actions.push(PageHeaderSimpleAction('BRIEF-ERSTELLEN', 'Brief erstellen')
        .withVisible(() => this.isOriginalUserBroker || this.isOriginalUserIntern));
       actions.push(PageHeaderSimpleAction('KUENDIGUNG-VERSICHERUNGEN', 'Kündigung Versicherungen')
       .withVisible(() =>  this.kuendigungAllowed && (this.isOriginalUserBroker || this.isOriginalUserIntern))
         .withDisabled(() => this.statusAktuell == 'beendet'));

      actions.push(PageHeaderSimpleAction('VERSICHERUNG-KOPIEREN', 'Versicherung kopieren')
      .withVisible(() =>  this.isCustomerOnly == false));

      if(isOriginalUserBroker || isOriginalUserIntern) {
        actions.push(PageHeaderSimpleAction('NEW-ACTIVITY', 'Aufgabe hinzufügen'));
      }


      if (this.isFA && (this.versAdminA || this.versAdminM)) {
        const typ = this.versAdminA ? 'A' : 'M';
        actions.push(PageHeaderSimpleAction('VERS-ADMIN-' + typ, `Vers-Admin ${typ} Formulare`));
      }
      if(isOriginalUserBroker || isOriginalUserIntern) {
        actions.push(PageHeaderSimpleAction('OLD-POLICE-NUMBERS', 'Historische VSNR'));
      }

      return [...actions, ...this.externeZugangVergleichPageHeaderActions];
    },     
    detailsData() {
      return this.versicherungenDetails || null;
    },
    gesellschaftName() {
      return this.detailsData?.insurance?.gesellschaft?.shortName || '';
    },
    bezeichnung() {
      return this.detailsData?.insurance?.bezeichnung || '';
    },
    nummer() {
      return this.detailsData?.insurance?.nummer || '';
    },
    bereichText() {
      return `Versicherung ${this.nummer || this.$route.params.id || this.bezeichnung} | ${this.bezeichnung} | ${this.detailsData?.insurance?.kundeVorname || ''} ${this.detailsData?.insurance?.kundeName || ''}`;
    },
    nodeId() {
      return this.versicherungenDetails?.nodeId || null;
    },    
    statusAktuell() {
      return this.detailsData?.insurance?.statusAktuell || '';
    },
    showKennzeichen() {
      return this.vertragsdatenFieldsDefinition?.kennzeichen?.visible;
    },
    kennzeichen() {
      return this.detailsData?.insurance?.kennzeichen || '';
    },
    kuendigungAllowed () {
      return !!this.detailsData?.insurance?.kuendigungAllowed;
    },
    shouldHideWertpapiere() {
      return (this.rueckkaufswerte?.length || this.eigebundeneWertpapiere?.length);
    },
    rueckkaufswerte() {
      const mainSparteStep = this.versicherungenDetails?.steps?.find(sparteStep => sparteStep?.config?.primary);

      if(this.versicherungenDetails?.insurance?.sparten?.length && mainSparteStep) {
        const sparte = this.versicherungenDetails.insurance.sparten.find(sparte => sparte.sparteId === mainSparteStep.stepKey);
        if(sparte?.rueckkaufswerte) {
          return sparte?.rueckkaufswerte
        }
      }
      return []
    },
    eigebundeneWertpapiere() {
      const mainSparteStep = this.versicherungenDetails?.steps?.find(sparteStep => sparteStep?.config?.primary);
            
      if(this.versicherungenDetails?.insurance?.sparten?.length && mainSparteStep) {
        const sparte = this.versicherungenDetails.insurance.sparten.find(sparte => sparte.sparteId === mainSparteStep.stepKey);
        if(sparte?.eigebundeneWertpapiere) {
          return sparte?.eigebundeneWertpapiere
        }
      }    
      return []
    },   
    ruckkaufswerte() {
      const mainSparteStep = this.versicherungenDetails?.steps?.find(sparteStep => sparteStep?.config?.primary);

      if(this.versicherungenDetails?.insurance?.sparten?.length && mainSparteStep) {
        const sparte = this.versicherungenDetails.insurance.sparten.find(sparte => sparte.sparteId === mainSparteStep.stepKey);
        if(sparte?.rueckkaufswerte) {
          return {rows: sparte?.rueckkaufswerte}
        }
      }
      return {rows: []}
    },       
    shouldHideRueckkaufswerteStep() {
      const allowedSubCategoriesFK = [
        {value: 25, label: 'Kapitalbildende Lebensversicherung'},
        {value: 28, label: 'Private Rentenversicherung'},
        {value: 30, label: 'Riesterrente'},
        {value: 31, label: 'BasisRente'},
        {value: 32, label: 'Pflegerente'},
        {value: 41, label: 'Betriebliche Altersvorsorge'},
        {value: 50, label: 'Fondsgebundene Lebensversicherung'},
        {value: 61, label: 'Fondsgebundene Rentenversicherung'},
        {value: 150, label: "Prämienbegünstigte Zukunftsvorsorge" },
      ];
      const allowedSubCategoriesFA = [
        {value: 188, label: 'Pflegerente'},
        {value: 24, label: 'Betriebliche Altersvorsorge'},
        {value: 151, label: 'Fondsgebundene Lebensversicherung'},
        {value: 61, label: 'Fondsgebundene Rentenversicherung'},
        {value: 150, label: "Prämienbegünstigte Zukunftsvorsorge" },
      ];

      const isAllowedCategory = category => category.value === this.versicherungenDetails?.insurance?.description?.id;
      const existInAllowedSubCategoriesFK = allowedSubCategoriesFK.some(isAllowedCategory);
      const existInAllowedSubCategoriesFA = allowedSubCategoriesFA.some(isAllowedCategory);
      return (this.isFK && !existInAllowedSubCategoriesFK) || 
             (this.isFA && !existInAllowedSubCategoriesFA) ||
             !this.hasRoles([[VIEW_ROLES.VIEW_INTERN], [VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER], [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN]]);
    },    
    canViewCourtageStep(){
      return this.hasRoles([
        [VIEW_ROLES.VIEW_BROKER],
        [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER],
        [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN],
      ]);
    },     
    shouldHideFahrzeugDatenStep() {
      const allowedSubCategory = {value: 21, label: "KFZ-Versicherung" }
      return allowedSubCategory.value !== this.versicherungenDetails?.insurance?.description?.id
    },
    currentFieldOrder() {
      // the "tableId" should be a computed function in the corresponding caller component
      const config = this.fcConfig['TABLE_COLUMN_CONFIG']?.[this.tableId]?.['content'];
      const result = {};

      if (config) {
        JSON.parse( config)?.forEach((c,index) => {
          result[c.key] = index;
        })
      }

      return result;
    },
    steps() {
      const steps = this.getStepsBegin.concat(this.additionalSteps).concat(this.dynamicSteps).concat(this.getStepsEnd);
      let result =  this.isDynamicStepsAvailable ? steps : this.getSteps;

      if(result.find((step) => step.stepKey === 'sparteUbersicht') && result.find((step) => step.stepKey === 'wertpapiere')){
        result = result.filter((step) => step.stepKey != 'wertpapiere')
      }
      return result.filter(r => !r.customOptionMenu).sort((a,b) => a?.position - b?.position);
    },
    isDynamicStepsAvailable() {
      return this.isOriginalUserBroker || this.isOriginalUserIntern;
    },
    dynamicSteps() {
      return (this.versicherungenDetails?.steps || []).map(step => Object.assign(step, { hidden: !this.hasSparte(this.getSpartenData, step) }));
    },    
    additionalSteps() {
      return this.versicherungenDetails?.additionalSteps || [];
    }, 
    getSteps() {
      return [
        {
          stepKey: 'allgemeine',
          label: 'Basisdaten',
          totalProgress: 1,
          position: 1
        },
        {
          stepKey: 'weitere-vertragsdetails',
          label: this.getLabel,
          totalProgress: 1,
          position: 2
        },
        {
          stepKey: 'fahrzeugDaten',
          label: 'Fahrzeugdaten',
          totalProgress: 1,
          hidden: this.shouldHideFahrzeugDatenStep,
          position: 3
        },
        {
          stepKey: 'dokumente',
          label: 'Dokumente',
          totalProgress: 1,
          position: 900
        },
        {
          stepKey: 'anmerkungen',
          label: 'Anmerkungen',
          totalProgress: 1,
          position: 901
        },
        {
          stepKey: 'wertpapiere',
          label: 'Wertpapiere',
          totalProgress: 1,
          hidden: !this.hasDepotPositions,
          position: 902
        },
        {
          stepKey: 'schadensmeldungen',
          label: this.isVorsorgeOrGesundheight ? 'Leistungsübersicht' : 'Schadensübersicht',
          totalProgress: 1,
          position: 903
        },
        {
          stepKey: 'courtage',
          label: 'Courtage',
          totalProgress: 1,
          hidden: !this.canViewCourtageStep,
          substeps: [
            { substepKey: 'abrechnungDetails', title: 'Details'},
            { substepKey: 'zubringercourtage', title: 'Zubringercourtage'},
          ],
          position: 904
        },
      ];
    },
    getStepsBegin() {
      return  [
        {
          stepKey: 'allgemeine',
          label: 'Basisdaten',
          totalProgress: 1,
          position: 1
        },
        {
          stepKey: 'weitere-vertragsdetails',
          label: this.getLabel,
          totalProgress: 1,
          position: 2
        },
        {
          stepKey: 'sparteUbersicht',
          label: 'Fondswerte & Rückkaufswerte',
          totalProgress: 1,
          hidden: this.shouldHideRueckkaufswerteStep,
          position: 3
        },
        {
          stepKey: 'fahrzeugDaten',
          label: 'Fahrzeugdaten',
          totalProgress: 1,
          hidden: this.shouldHideFahrzeugDatenStep,
          position: 4
        },
        {
          stepKey: 'aenderungslog',
          label: 'Änderungslog',
          totalProgress: 1,
          hidden: !this.shouldHideAenderungslog
        },
        {
          stepKey: 'sparten',
          label: 'Sparten Auswahl',
          totalProgress: 1,
          position: 6,
        }
      ];
    },
    getStepsEnd() {
      return [
        {
          stepKey: 'tasks',
          label: 'E-Mails & Aufgaben',
          totalProgress: 1,
          position: 901,
          customOptionMenu: true,
        },
        {
          stepKey: 'dokumente',
          label: 'Dokumente',
          totalProgress: 1,
          position: 902,
          customOptionMenu: true,
        },
        {
          stepKey: 'anmerkungen',
          label: 'Anmerkungen',
          totalProgress: 1,
          position: 903,
          customOptionMenu: true,
        },
        {
          stepKey: 'wertpapiere',
          label: 'Wertpapiere',
          totalProgress: 1,
          hidden: !this.hasDepotPositions,
          position: 904
        },
        {
          stepKey: 'schadensmeldungen',
          label: this.isVorsorgeOrGesundheight ? 'Leistungsübersicht' : 'Schadensübersicht',
          totalProgress: 1,
          position: 905
        },
        {
          stepKey: 'courtage',
          label: 'Courtage',
          totalProgress: 1,
          hidden: !this.canViewCourtageStep,
          substeps: [
            { substepKey: 'abrechnungDetails', title: 'Details' },
            { substepKey: 'zubringercourtage', title: 'Zubringercourtage' },
          ],
          position: 906
        },
      ];
    }, 
    hasDepot(){
      return !!this.versicherungenDetails?.insurance?.depotId
    },
    unselectedDocumentsIds() {
      // in case of all documents were selected except some, the store variable selectedRows will keep those unselected documents instead the selected ones
      return this.isSelectedAllInFolder && this.selectedRowsDokArchiv?.length ? this.selectedRowsDokArchiv.map(row => row?.dokId || row) : [];
    },
  },
  async mounted() {
    if (this.$route?.params?.id) {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION,
        {
          versVertrag: this.$route.params.id,
          groupId: this.categorySelected?.group,
        }
      );
          
      if (this.description?.id) {
        this.loadGesellschaftList(this.$route?.params?.id, this.description.id, this.versicherung.displayFremdges)
      }
  
      if(this.$route?.params?.id && !this.$route?.query?.usersselected) {
        this.updateModel();
      }
      
      // when new, select in combo same group selected
      if (this.categorySelected?.category && this.categorySelected?.group) {
        this.versicherung.description.id = this.categorySelected?.group;
      }
  
      this.updateVersicherungFormValidation();
    }

    if (this.isViewCustomerAsBypass) {
      await this.$store.dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.GET_EXTERNE_ZUGANG_VERGLEICH_PAGE_HEADER_ACTIONS);
    }
  },
  methods: {
    executeGenericAction(actionData) {
      if (actionData?.value?.isExterneZugangAction) {
        this.externeZugang = actionData;
        this.data = actionData?.value?.data;
        if (actionData?.value?.askOptions) {
          this.askOptions = actionData?.value?.askOptions;
        } else {
          this.$router.push({ path: `${actionData?.value?.path}` });
        }
      }
    },    
    editBankverbindung() {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG);
    },
    newBankverbindung() {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG);
    },    
    getCurrentRoute() {
      return this.$router.currentRoute.path;
    },
    openCustomerNewTab(kundennr) {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.VALIDATE_CUSTOMER, { kundennr }).then(() => {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: kundennr });
      });
    },      
    newAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {});
      this.openAppointment();
    },    
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },    
    async setStepByKey(stepKey) {
      this.$store.commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.RESET_INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR);
      if(this.selectedStepKey === stepKey 
      || this?.versicherungFormValidation?.versicherung?.gesellschaft.invalid) {
        return ;
      }

      if (stepKey === 'tasks') {
        try {
          this.loadingActivities = true;
          await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_COMMUNICATION_INSURANCE_LISTS, this.$route.params.id);
        } finally {
          this.loadingActivities = false;
        }
      }

      await this.saveBeforeLeave()?.then((canChangeStep) => {
          if(canChangeStep){
            this.selectedStepKey = stepKey

            const step = this.tabsStepperMediator.getCurrentStep(this.selectedStepKey);
            this.selectedSubstepKey = step?.substeps?.length && step?.substeps[0].substepKey || '';
          }
        })
    },    
    styleOrder(whatKey) {
      let index = this.currentFieldOrder[whatKey];

      if (typeof index === 'undefined') {
        index = 999;
      }
      return `order:${index}`
    },
    getPeriodMultiplier(period) {
      switch (period) {
        case "monatlich":
          return 12;
        case "zweimonatlich":
          return 6;
        case "vierteljährlich":
          return 4;
        case "halbjährlich":
          return 2;
        case "jährlich":
          return 1;
        default:
          return 0;
      }
    },
    loadGesellschaftList(versVertragId, groupId, displayFremdges) {
      const params = {versVertragId, groupId, displayFremdges}
      if (groupId && (!this.lastLoadedGesellschaftListParams || !isDeepEquals(this.lastLoadedGesellschaftListParams, params))) {
        this.lastLoadedGesellschaftListParams = params
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_GESELLSCHAFT, params)
      }
    },
    updateGeteiltmitKunde() {
      if (this.selectedCustomersExternal?.length && !this.$route?.query?.usersselected) {
        const selectedCustomers = this.versicherung?.geteiltmitKunde;
        const selectedCustomerId = this.selectedCustomersExternal?.pop()?.customerID || null;
        if (selectedCustomers !== selectedCustomerId) {
          this.dataChanged('geteiltmitKunde', selectedCustomerId)
          this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.SET_SELECTED_CUSTOMERS, []);
        }
      }
    },
    updateModel() {

      this.versicherung = Object.assign({}, this.versicherung, this.versicherungenDetails?.insurance);

      if(Object.keys(this.versicherungDetailsEdited).length){
        //ignore fehler attribute to not override
        const editedTemp = Object.assign({}, ...Object.keys(this.versicherungDetailsEdited)
        .filter(k => !k.includes('fehler'))
        .map(k => ({[k]: this.versicherungDetailsEdited[k]})));

        this.versicherung = Object.assign({}, this.versicherung, editedTemp);
      }

      if(this.isNewVertrag && this.versicherung?.gesellschaft?.id) {
        this.getMaklerGesellNrComboboxValues && this.getMaklerGesellNrComboboxValues(this.versicherung?.gesellschaft?.id);
      }

      this.updateGeteiltmitKunde();

    },
    laufzeitChanged(field, value) {

      const date1 = this.versicherung.beginn;
      const date2 = this.versicherung.ende;

      if (date1 && date2) {
        const d1 = dayjs(date1);
        const d2 = dayjs(date2);
        this.versicherung.laufzeit = Math.round(d2.diff(d1, 'year', true));
      }

      this.dataChanged(field, value);

    },
    insuranceObjectPropertyDataChanged(key, field, value) {
      this.versicherung[key][field] = value;
      this.updateChangedData();
    },
    dataChanged(field, value) {
      if(field === 'gesellschaft'){
        this.versicherung[field] = {id: value};
        this.getMaklerGesellNrComboboxValues(value);
        // if(this.isFPPlus) {
          this.getAnbindungComboboxValues(value)
        // }
      } else if(field === 'maklerGesellNr') {
        this.versicherung[field] = value;
        this.getMaklerGesellNrComboboxItemSelectedMessage(value);
      } else if(field === 'nummer') {
        setTimeout(() => this.validateNummer(), 100);
      } else if(field === 'anbindung' && value !== 'FPPLUS_GEWERBE') {
        this.versicherung[field] = value;
        this.versicherung.betreuungsstufe = 'Ohne';
      } else if (field === 'vertragsende') {
        const date1 = this.versicherung.beginn;
        const date2 = this.versicherung.ende;
  
        if (date1 && date2) {
          const d1 = dayjs(date1, 'DD.MM.YYYY');
          const d2 = dayjs(date2, 'DD.MM.YYYY');
          this.versicherung.laufzeit = d2.diff(d1, 'year');
        }
      }  else if (field === 'laufzeit') {
        const date1 = dayjs(this.versicherung.beginn, 'DD.MM.YYYY');

        if (date1.isValid()) {
          this.versicherung.ende = date1.add(this.versicherung.laufzeit, 'year');
        }

      } else if(field === 'displayFremdges') {
        const selectedGroup = this.categorySelected?.group || this.versicherungenDetails?.insurance?.description?.id;
        this.loadGesellschaftList(this.$route?.params?.id, selectedGroup, this.versicherung[field]);
      } else {
        this.versicherung[field] = value;
      }
      if (this.isChanged(this.versicherung)) {
        this.updateChangedData();
      }
    },
    isChanged(model) {
      return Object.keys(model)
        .filter(fieldName => this.versicherungenDetails?.insurance[fieldName] !== model[fieldName])
        .length > 0;
    },
    updateChangedData() {
        const payload = {
          ...this.versicherungDetailsEdited,
          ...this.versicherung,
          id: this.versicherung.id,
        };

        this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.APPEND_VERSICHERUNGEN_DETAILS_EDITED, payload);
        this.updateVersicherungFormValidation();
    },
    formatDate(date) {
      if(date && !dayjs(date, 'DD.MM.YYYY').isValid()){
        return dayjs(date).format('DD.MM.YYYY');
      }
      return date;
    },
    saveSparten() {
      if (this.versicherung?.id) {
        return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.UPDATE_VERTRAG_SPARTEN);
      }
    },
    save(edited) {
      if(!edited) {
        //if nothing was edited just ignore saving
        return Promise.resolve(true);
      }
   
      const vertrag = { 
        nummer: this.versicherung?.nummer,
        id: this.versicherung?.id,
        ...edited, 
        description: {
          ...this.versicherung?.description,
          ...edited?.description
        },
        gesellschaft: {
          ...this.versicherung.gesellschaft,
          ...edited?.gesellschaft
        }
      };

      if(!vertrag?.description?.id || !vertrag?.gesellschaft?.id) {
        // without category and gesellschaft don't save and don't allow change step
        return Promise.resolve(false);
      }

      if(vertrag.beginn){
        vertrag.beginn = this.formatDate(vertrag.beginn);
      }

      if(vertrag.ende){
        vertrag.ende = this.formatDate(vertrag.ende);
      }

      if(vertrag.erfassungsdatum){
        vertrag.erfassungsdatum = this.formatDate(vertrag.erfassungsdatum);
      }

      if(vertrag.hauptfaelligkeit){
        vertrag.hauptfaelligkeit = this.formatDate(vertrag.hauptfaelligkeit);
      }

      const addValidationErrorToField = (error) => {
        const errorObject = error?.response?.data;
        if (errorObject) {
          if(this.selectedStepKey && this.selectedStepKey === 'allgemeine') {
            if(errorObject?.field === 'nummer') {
              this.$refs.allgemeine.validateNummer(errorObject?.message);
            }
            if(errorObject?.field === 'vertragssparteGesellschaft') {
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(errorObject?.message, 'danger'));
            }
          } else {
            this.validateNummer(errorObject?.message);
          }
          return Promise.resolve(false);
        }
      }

      if(this?.versicherungFormValidation?.versicherung?.valid){
        if(vertrag.id){
          return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.UPDATE_VERTRAG, vertrag)
          .catch(addValidationErrorToField)
        } else {
          return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.SAVE_VERTRAG, vertrag)
          .catch(addValidationErrorToField)
        }
      }
    },
    updateVersicherungFormValidation() {
      if(this.validation){
        this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.UPDATE_VERSICHERUNGEN_FORM_VALIDATION, this.validation);
      }
    },
    getHinweise(stepKey) {
      if (stepKey) {
        switch (stepKey) {
          case 'allgemeine': 
              return hinweiseStepAllgemeine;
        }
      }
    },
    inputHauptdataChanged(event, sparteId) {
      if (event?.componentId && sparteId) {
        this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.UPDATE_STORE_SPARTEN_DATA, Object.assign(event, { sparteId: sparteId, isHauptdata: true }));
      }
    },
    inputCurrencyChanged(componentId, value, sparteId) {
      if (componentId && sparteId) {
        this.inputHauptdataChanged({ componentId: componentId, value: formatNumber(value)}, sparteId)
        
      }
    },
    hasSparte(spartenValues, step) {
      if (spartenValues && step?.stepKey) {
        return spartenValues?.some(sp => sp.sparteId === step.stepKey)
      }
    },
    getPraemieJahr(praemieMonth) {
      if(praemieMonth) {
        if (typeof(praemieMonth) === 'string') {
          praemieMonth = parse(praemieMonth);
        }
        const zahlrhythm = this.versicherung.zahlrhythm || "jährlich";
        return (praemieMonth * this.getPeriodMultiplier(zahlrhythm)).toFixed(2);
      }
    },
    openItemSelectedDetail(itemSelected) {
      this.selectedItem = itemSelected;
      if(this.isBroker || this.isIntern) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, 
          { customerId: itemSelected.kundennr, insurances: true, path: `/home/versicherungen/insurance-group/details/${itemSelected.id}` });
      } else {
        this.$router.push(`/home/versicherungen/insurance-group/details/${itemSelected.id}`);
      }
    },    
    openItemSelectedOverview(itemSelected) {
      this.selectedItem = itemSelected;
      if(this.isBroker || this.isIntern) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, 
          { customerId: itemSelected.kundennr, insurances: true, path: `/home/versicherungen/insurance-group/overview/${itemSelected.id}` });
      } else {
        this.$router.push(`/home/versicherungen/insurance-group/overview/${itemSelected.id}`);
      }
    },
    // in order to send email/brief you need to have EmailVersand.vue in the component that uses this mixin
    async emailStart(toCompany, attachments) {
      await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_MAKLER_VOLLMACHT,
        {
          versVertrag: this.$route.params.id || this.$route.params.versicherungId,
        }
      );

      const insuranceId = this.$route.params.id || this.$route.params.versicherungId
      EVENT_BUS.$emit(SEND_EMAIL, {
        insuranceId,
        toCompany,
        attachments,
        isSelectedAllDocumentsInFolder: this.isSelectedAllInFolder,
        unselectedDocuments: this.unselectedDocumentsIds,
        // Note: passing the info for maklervollmacht here is not very good because we do not have that information in documents archive
        hasMaklerVollmachtDocuments: this.maklerVollmacht?.hasMaklerVollmachtDocuments,
        maklerVollmacht: this.maklerVollmacht?.maklerVollmacht,
      });
    },
    briefStart() {
      const insuranceId = this.$route.params.id || this.$route.params.versicherungId
      EVENT_BUS.$emit(SEND_BRIEF, {insuranceId});
    },
    getMailSubject() {
      const vertragsparteItem = this.vertragsdatenFieldsDefinition?.vertragssparte?.value?.find(vd => vd.value == this.versicherungenDetails?.insurance?.description?.id)
      const vertragssparte = vertragsparteItem?.label || '';
      let gesellschaft = ''
      let nummer = ''
      if (this.vertragsdatenFieldsDefinition?.gesellschaft?.visible) {
          const gesellschaftItem = this.gesellschaftList?.find(vd => vd.value == this.versicherungenDetails?.insurance?.gesellschaft.id)
          gesellschaft = gesellschaftItem?.label;
      }
      if (this.vertragsdatenFieldsDefinition?.polNr?.visible) {
          nummer = this.versicherungenDetails?.insurance?.nummer || '-';
      }
      return [gesellschaft, vertragssparte, nummer].join(', ')
    },  
    async emailCancel() {

      // retrieves Maklervollmacht information on demand
      await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_MAKLER_VOLLMACHT,
        {
          versVertrag: this.$route.params.id || this.$route.params.versicherungId,
        }
      );

      if(!this.maklerVollmacht?.hasMaklerVollmachtDocuments) {
        this.$confirmModal({
          message: 'Für die Nutzung des Kündigungstool wird eine Maklervollmacht benötigt. Bitte hinterlegen Sie eine unterzeichnete Maklervollmacht beim Kunden.',
          title: 'Hinweis',
          labelButtonConfirm: 'ok'
        });
        return;
      }
      let promise = Promise.resolve();
      if(!this.detailsData?.insurance?.kuendiegungsMailPojo?.email) {
        promise = this.$confirmModal({
          message: `Es gibt keine E-Mail-Adresse der Gesellschaft ${this.detailsData?.insurance?.gesellschaft?.zweigstelleName}, die für die Kündigung der Versicherung eingerichtet wurde.`,
          title: 'Hinweis',
          labelButtonConfirm: 'Trotzdem fortfahren'
        });
      }

      promise.then(() => {
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
          type: 'mailcomposer-insurance-cancel',
          action: 'clear',
        });
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, {
          data: [this.versicherungId || this.$route.params.id || this.$route.params.versicherungId],
          hasMaklerVollmacht: this.maklerVollmacht?.hasMaklerVollmachtDocuments
        });
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE, null);
        
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
         type: 'mailcomposer-insurance-cancel_one',
         action: 'clear',
         });
         this.$router.push(`/communication/mailcomposer-insurance-cancel_one`);
      });
    },  
    handleAddActivity() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: false});
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_AUFGABE);
      this.openAppointment();
    },      
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aufgabe bearbeiten',
        bereich: 'VersVertrag',
        subject: this.nummer ? `VSNR ${this.nummer}` : this.getMailSubject(),
        vsnr: this.nummer,
        bereichId: this.versicherungId,
        discardChanges: this.resetAppointment,
        isActivity: false,
        selectedDate: null,
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    resetAppointment() {
    },    
    versicherungKopierenBestaetigung() {
      this.$refs.copyInsuranceConfirmationModal.open();
    },
    showOldPoliceNumbers() {
      this.$refs.showOldPoliceNumbersModal.open({vertragId: this.versicherungId});
    },
    openAddDocumentModal(files) {
      this.$refs.addDocumentModal.open(files);
    },    
    dateiHochladen() {
      const files = [];
      this.openAddDocumentModal(files)
    },       
    createSchaden() {
      this.$router.push(`/home/versicherungen/insurance-group/schadensmeldung/${this.$route.params.id}/neuesSchaden`);
    },
    versAdminn(typ) {
      this.$router.push(`/beratung/formulare/antrag/VERSADMIN_${typ}`);
    },
    selectedRows(rows) {
      this.selectedRowsValues = rows;
    },
    goToDownloadArea(insuranceId, isOverview = false) {
      if (!this.isSmallOrMediumScreen) {
        const fromOverview =  isOverview || !!this.$route?.query?.fromOverview;
        const basePath = '/home/versicherungen/insurance-group/dokumente';
        const path = `${basePath}/${insuranceId}?fromOverview=${fromOverview}`;
        if (!this.$route.path.includes(basePath)) {
          this.$router.push(path);
        }

      }
    },
    navigateToGesellschaft() {
      if (this.versicherungenDetails?.insurance?.gesellschaft?.id) {
        this.$store.commit(GESELLSCHAFT_TYPES.MUTATIONS.RESET_STATE);

        const gesellschaftArt = this.versicherungenDetails?.insurance?.gesellschaft?.gesellschaftArt||VERSICHERUNGEN_GESELLSCHAFT_ART;
        const URL = `/service/gesellschaften/${gesellschaftArt}/${this.detailsData.insurance.gesellschaft.id}/ansprechpartnerList`;
        this.$router.push({
          path: URL, 
          query: { gesellArt: gesellschaftArt}
        });
      }
    },    
    navigateToGesellschaftsInfos() {
      if (this.versicherungenDetails?.insurance?.gesellschaft?.id) {
        const URL = `/home/versicherungen/insurance-group/gesellschaftsInfos/${this.detailsData.insurance.gesellschaft.id}`;
        this.$router.push({
          path: URL, 
        });
      }
    },     
    overviewLink(insuranceId) {
      return `/home/versicherungen/insurance-group/overview/${insuranceId}`;
    },
    externenZugangOeffnen(){
      this.saveBeforeLeave().then( () => {
        axios.get(`${process.env.VUE_APP_API}/insurances/getExternalUrl?id=${this.versicherung.id}`).then(resp => {
          if (resp.data){ 
            const wHandle = window.open(resp.data);
            wHandle.focus();
          }
        });
      })
    },
    openDepot(){
      this.$router.push({name: 'vermogensubersicht-depot', params: {depotId: this.versicherungenDetails?.insurance?.depotId}})
      
    },  
    resetExternalUrl(){
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_EXTERNAL_URL, null);
    },
    saveBeforeLeave() {
      const vertrag = Object.keys(this.versicherungDetailsEdited).length ? { ...this.versicherungDetailsEdited } : null;
      if(this.steps.some(step => step.stepKey === this.selectedStepKey)) {
        if(vertrag?.extraData?.spartenChanged?.length || this.selectedStepKey == 'sparten') {
          return this.saveSparten();
        }
        return this.save(vertrag);
      }
    }, 
    getMaklerGesellNrComboboxItemSelectedMessage(maklerGesellNr) {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_VERTRAGSDATEN_MAKLERGESELLNR_MESSAGE, {maklerGesellNr})
      .then(result => {
        if(result){
          this.$set(this.versicherung, 'vermittlernummerBemerkung', result);
        }
      })
    }, 
  },
}
