<template>
    <div>
        <InputField id="scenario.title"
            label="Name des Scenarios"
            :value="scenario.title"
            validateUntouched
            @input="doChanges('title', $event)"
        />
        <InputTextArea
            label="Notizen"
            :value="scenario.bemerkung"
            @input="doChanges('bemerkung', $event)"
            :rows="5"
        />
        <!-- <InputField
            label="Alter bei Beginn Ruhestand"
            :value="scenario.alterRente"
            type="number"
            :precision="0"
            @change="doChanges('alterRente', $event)"
        /> -->
        <InputToggleSwitch
            :value="scenario.ohneKapitalverzehr"
            label="Rente ohne Kapitalverzehr"
            inLineLabel
            @change="doChanges('ohneKapitalverzehr', $event)"
        />
        <InputField
            label="monatlicher Bedarf in Euro in Rente (nach heutigem Geldwert)"
            :value="scenario.bedarfMonatlich"
            type="currency"
            :disabled="scenario.ohneKapitalverzehr"
            @change="doChanges('bedarfMonatlich', $event)"
        />
        <InputField
            label="geschätzte Renteneinnahmen pro Monat"
            :value="scenario.renteMonatlichErwartet"
            type="currency"
            @change="doChanges('renteMonatlichErwartet', $event)"
        />
        <InputField
            label="Rendite im Ruhestand"
            :value="scenario.renditeRuhestand"
            type="percent"
            @change="doChanges('renditeRuhestand', $event)"
        />
        <InputField
            label="Restkapital bei Tot"
            :value="scenario.restKapital"
            type="currency"
            :precision="0"
            :disabled="scenario.ohneKapitalverzehr"
            @change="doChanges('restKapital', $event)"
        />
        <InputField
            label="Annahme durchschnittliche Inflation"
            :value="scenario.inflation"
            type="percent"
            @change="doChanges('inflation', $event)"
        />
    </div>
</template>

<script>
import InputField from "@/components/core/forms/InputField.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import mixin from '@/views/ruhestandsplanung/mixin.js';
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import Table from '@/components/table2/Table.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
    mixins: [mixin, validator],
    components: {
        InputField,
        InputTextArea,
        Table,
        CurrencyLabel,
        InputToggleSwitch,
    },
    validators: {
        scenario: {
            title: [required('Bitte geben Sie eine Bezeichnung.')],
        }
    },
}
</script>

<style lang="scss" scoped>
</style>