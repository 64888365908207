import store from '@/store';
import CORE_TYPES from '@/store/core/types';

/**
 * Overrides the 'window.open' methods to detect if an opened tab was blocked
 */
window.open = ((open) => {
  return function () {
    const args = arguments;
    const windowRef = open.apply(window, args);
    if(!windowRef || windowRef.closed || typeof windowRef.closed == 'undefined') {
      store.dispatch(CORE_TYPES.ACTIONS.CONFIRM_MODAL, {
        title: 'Browser-Tab öffnen', 
        message: `die App versucht, einen Browser-Tab zu öffnen, aber sie wurde blockiert. Können Sie Popups für die "${location.origin}" in den Website-Einstellungen zulassen oder auf „Öffnen“ klicken?`, 
        labelButtonConfirm: 'Öffnen',
        autoCloseOnRouteNavigation: false,
      }).then(() => open.apply(window, args));
    }
    return windowRef;
  };
})(window.open);
