var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showAddImage
    ? _c(
        "div",
        [
          _c(
            "tippy",
            {
              attrs: {
                to: "addImageTrigger",
                arrow: "true",
                trigger: "click",
                interactive: "true",
                multiple: ""
              }
            },
            [
              _c("h5", [_vm._v("Bild")]),
              _c(
                "BaseButton",
                {
                  attrs: { label: "Aus Datei" },
                  on: {
                    click: function($event) {
                      return _vm.onAddImageFromFile()
                    }
                  }
                },
                [_vm._v(" Aus Datei ")]
              ),
              _c(
                "BaseButton",
                {
                  attrs: { label: "Aus URL" },
                  on: {
                    click: function($event) {
                      return _vm.showAddImageFromUrl()
                    }
                  }
                },
                [_vm._v(" Aus URL ")]
              )
            ],
            1
          ),
          _c("input", {
            ref: "imageFileInput",
            staticClass: "input-hidden",
            attrs: {
              "aria-label": "Datei hochladen",
              disabled: _vm.disabled,
              type: "file",
              accept: "image/png,image/jpeg,image/gif"
            },
            on: {
              change: function($event) {
                return _vm.onAddImage($event)
              }
            }
          }),
          _vm.showUrlModal
            ? _c("BaseToolbarImageURL", {
                attrs: { linkForm: _vm.linkForm },
                on: {
                  close: function($event) {
                    _vm.showUrlModal = false
                  },
                  onConfirmButton: _vm.onAddImageFromUrl
                }
              })
            : _vm._e(),
          _c("BaseToolbarButton", {
            directives: [
              {
                name: "tippy",
                rawName: "v-tippy",
                value: { arrow: true, multiple: true },
                expression: "{ arrow: true, multiple: true }"
              }
            ],
            staticClass: "mr-0 tb-image-button",
            attrs: {
              name: "addImageTrigger",
              title: "Image",
              icon: _vm.icon,
              disabled: _vm.disabled,
              content: "Bild"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }