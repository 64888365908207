var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Object.keys(_vm.information).length
    ? _c(
        "div",
        { staticClass: "broker-info__container" },
        _vm._l(Object.keys(_vm.information), function(infoItem, index) {
          return _c("div", { key: index, staticClass: "broker-info__item" }, [
            _vm.information[infoItem]
              ? _c(
                  "span",
                  { staticClass: "item__status color-success" },
                  [_c("PhCheck", { attrs: { size: 24 } })],
                  1
                )
              : _vm._e(),
            !_vm.information[infoItem]
              ? _c(
                  "span",
                  { staticClass: "item__status color-danger" },
                  [_c("PhWarning", { attrs: { size: 24 } })],
                  1
                )
              : _vm._e(),
            _c("span", { staticClass: "item__text" }, [
              _vm._v(" " + _vm._s(infoItem) + " ")
            ])
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }