<template>
    <DynamicModal ref="resizablePopup" :modalTitle="title" :initialWidth="initialWidth" :initialHeight="initialHeight"
        :sizeIsPercentage="sizeIsPercentage" :startPosition="startPosition" :fullClientHeight="fullClientHeight"
        :saveLastPosition="saveLastPosition" :id="id" withBackdropLayer @onMinimize="resizePDFContainer"
        @onMaximize="resizePDFContainer" @onResize="resizePDFContainer" @onClose="closeViewer" style="display: flex; flex-direction: column;" :actions="actions"
        @action-SAVE="saveDocument" @action-FINISH="finishDocument" @action-PAGE_OVERVIEW="showThumbnails"
        @action-ADD_SIGNATURE="addSignatureField">
        <!-- Additional header buttons -->
        <template #toolbar-buttons>
            <div class="flex justify-between  items-center w-full ">
                <div class="flex justify-between items-center gap-1">
                    <span v-if="!$isSmallScreen" class="icon-button" @click="showThumbnails()" content="Seiten"
                        v-tippy="{ arrow: true, touch: false }">
                        <ph-squares-four :size="26" />
                    </span>
                    <span class="icon-button relative" @click="showSummarySignaturesDialog()" content="Unterschriften"
                        v-tippy="{ arrow: true, touch: false }">
                        <ph-pencil-simple :size="26" />
                        <div class="bubble">{{ missingSignatureFields }}</div>
                    </span>
                    <!-- <span class="icon-button" @click="startSigningProcess()" content="Nächste Unterschrift" v-tippy="{ arrow: true }">
                        <ph-pencil-line :size="36" />
                    </span> -->
                    <span v-if="isMakler && !$isSmallScreen" class="icon-button" @click="addSignatureField()"
                        content="Signaturfeld hinzufügen" v-tippy="{ arrow: true, touch: false }">
                        <PhAddSignature color="white" :size="26" />
                    </span>
                </div>
                <div class="flex justify-between items-center">
                    <div>
                        <span :disabled="!previousPage" class="icon-button" @click="showPrevPage()"
                            content="Vorherige Seite" v-tippy="{ arrow: true, touch: false }">
                            <ph-caret-left :size="26" />
                        </span>
                    </div>
                    <div class="mx">
                        <span>{{ currentPage }} / {{ session.pages }}</span>
                    </div>
                    <div>
                        <span :disabled="!nextPage" class="icon-button" @click="showNextPage()" content="Nächste Seite"
                            v-tippy="{ arrow: true, touch: false }">
                            <ph-caret-right :size="26" />
                        </span>
                    </div>
                </div>
                <div class="flex justify-between items-center gap-1">
                    <span v-if="fit != 'screenWidth'" class="icon-button" @click="fitPageToScreenWidth()"
                        content="An Breite anpassen" v-tippy="{ arrow: true, touch: false }">
                        <ph-arrows-out-line-horizontal :size="26" />
                    </span>

                    <span v-if="fit != 'screenHeight'" class="icon-button" @click="fitPageToScreenHeight()"
                        content="An Höhe anpassen" v-tippy="{ arrow: true, touch: false }">
                        <ph-arrows-out-line-vertical :size="26" />
                    </span>

                    <span v-if="!$isSmallScreen" :disabled="!saveButton" class="icon-button" @click="saveDocument()"
                        content="Speichern" v-tippy="{ arrow: true, touch: false }">
                        <ph-floppy-disk :size="26" />
                    </span>

                    <span v-if="!$isSmallScreen && isAbschliessenButtonVisible(elem)" class="icon-button"
                        @click="finishDocument()" content="Speichern & Abschließen"
                        v-tippy="{ arrow: true, touch: false }">
                        <ph-file-lock :size="26" />
                    </span>
                </div>
            </div>
        </template>

        <div class="object-container" ref="objectContainer">
            <iframe @load="registerEventListeners" :src="session.uri" ref="frame" width="100%" height="100%" class="signo_viewer"></iframe>
        </div>
    </DynamicModal>
</template>
<script lang="js">
import DynamicModal from '@/components/core/dynamic-modal/DynamicModal.vue';
import PhAddSignature from './PhAddSignature.vue';
import { PhSquaresFour, PhPencilSimple, PhPencilLine, PhPlus, PhFloppyDisk, PhCaretLeft, PhCaretRight, PhArrowsOutLineHorizontal, PhArrowsOutLineVertical, PhFileLock } from 'phosphor-vue'
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from "@/helpers/log-message-helper";
import { VIEW_ROLES, ROLES } from '@/router/roles';
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import mixin from '@/components/openSigns/offene-interschriften-mixin.js'

export default {
    mixins: [mixin],
    components: {
        PhSquaresFour,
        PhPencilSimple,
        PhPencilLine,
        PhPlus,
        PhFloppyDisk,
        PhCaretLeft,
        PhCaretRight,
        PhArrowsOutLineHorizontal,
        PhArrowsOutLineVertical,
        PhAddSignature,
        PhFileLock,
        DynamicModal,
    },
    props: {
        initialHeight: {
            type: Number,
            default: 400,
        },
        initialWidth: {
            type: Number,
            default: 712,
        },
        sizeIsPercentage: {
            type: Boolean,
            default: false,
        },
        fullClientHeight: {
            type: Boolean,
            default: false,
        },
        startPosition: {
            type: String,
            default: 'BOTTOM_RIGHT',
            validator(value) {
                return ['TOP_RIGHT', 'TOP_LEFT', 'BOTTOM_RIGHT', 'BOTTOM_LEFT'].includes(value);
            }
        },
        saveLastPosition: {
            type: Boolean,
            default: true,
        },
        id: {
            type: String,
            required: true,
            validator: (value) => {
                const notEmpty = !!value && !!value.trim();
                return notEmpty;
            },
        },
        session: {},
    },

    data() {
        return {
            elem: {},
            currentPage: 1,
            missingSignatureFields: 0,
            signedSignatureFields: 0,
            
            fit: 'screenWidth',

            saveButton: true,
            previousPage: false,
            nextPage: false,
        }
    },

    computed: {
        ...mapGetters({
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        title() {
            return this.session?.title || ''
        },
        actions() {
            return [
                BaseModalSimpleAction('SAVE', 'Speichern'),
                BaseModalSimpleAction('FINISH', 'Abschließen'),
                BaseModalSimpleAction('PAGE_OVERVIEW', 'Seiten Übersicht'),
                BaseModalSimpleAction('ADD_SIGNATURE', 'Signatur hinzufügen').withVisible(() => this.isMakler),
            ]
        }
    },

    methods: {
        isMakler() {
            return this.hasRoles([[VIEW_ROLES.VIEW_BROKER_AS_INTERN], [VIEW_ROLES.VIEW_BROKER, ROLES.IS_BYPASS_SLASH]]);
        },
        resizePDFContainer(size) {
            let pdfContainer = document.querySelector('.object-container');
            pdfContainer.style.setProperty("min-height", `${size?.height - 80}px`, 'important');
        },
        showPopup(newElement) {
            if (newElement) this.elem = newElement

            this.$refs.resizablePopup.showPopup();
        },
        showThumbnails() {
            this.$refs.frame?.contentWindow?.viewerFunctions.showThumbnails()
        },
        startSharingCaseCreation() {
            this.$refs.frame?.contentWindow?.viewerFunctions.startSharingCaseCreation()
        },
        showSummarySignaturesDialog() {
            this.$refs.frame?.contentWindow?.viewerFunctions.showSummarySignaturesDialog()
        },
        addSignatureField() {
            this.$refs.frame?.contentWindow?.viewerFunctions.addSignatureField()
        },
        fitPageToStandardDocumentSize() {
            this.$refs.frame?.contentWindow?.viewerFunctions.fitPageToStandardDocumentSize()
        },
        fitPageToScreenHeight() {
            this.fit = 'screenHeight'
            this.$refs.frame?.contentWindow?.viewerFunctions.fitPageToScreenHeight()
        },
        fitPageToScreenWidth() {
            this.fit = 'screenWidth'
            this.$refs.frame?.contentWindow?.viewerFunctions.fitPageToScreenWidth()
        },
        async saveDocument() {
            await this.$refs.frame?.contentWindow?.viewerFunctions.saveDocument()
        },
        startSigningProcess() {
            this.$refs.frame?.contentWindow?.viewerFunctions.startSigningProcess()

            //this.$refs.frame?.contentWindow?.viewer.openSignaturePadForSigning(-1, false);
        },
        async closeViewer() {
            if (this.elem) {
                // Cleanup rest 
                await this.cleanupSigoViewer(this.elem?.viewFileId, this.elem?.nodeId)
            }

            this.$refs.frame?.contentWindow?.viewerFunctions.closeViewer()
        },
        showPage(page) {
            this.$refs.frame?.contentWindow?.viewerFunctions.showPage(page)
        },
        showPrevPage() {
            this.$refs.frame?.contentWindow?.viewerFunctions.showPrevPage()
        },
        showNextPage() {
            this.$refs.frame?.contentWindow?.viewerFunctions.showNextPage()
        },
        async finishDocument() {
            await this.saveDocument();
            this.$refs.resizablePopup.closePopup()
            await this.submitDocument(this.elem)
        },
        async cleanupSigoViewer(viewFileId, nodeId) {
            await this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.CLEANUP_SIGNO_VIEWER, { viewFileId: viewFileId, nodeId: nodeId })
        },
        updateSession() {
            if(!this.session) return;

            this.missingSignatureFields = this.session.missingSignatureFields
            this.signedSignatureFields = this.session.signedSignatureFields
        },
        registerEventListeners() {
                this.$refs.frame?.contentWindow.addEventListener("SSUMessageEvent", (e) => {
                    console.info("SSUMessageEvent", e.detail.severity, e.detail.message);

                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(e.detail.message, e.detail.severity == 'ERROR' ? 'danger' : 'info'));

                    e.preventDefault();
                });

                this.$refs.frame?.contentWindow.addEventListener("SSUSignatureStartEvent", (e) => {
                    console.info("SSUSignatureStartEvent", e.detail);
                });

                //SSUpdateFormFieldsEvent
                //SSUUpdateFormFieldsEvent

                this.$refs.frame?.contentWindow.addEventListener("SSUpdateFormFieldsEvent", (e) => {
                    console.info("SSUUpdateFormFieldsEvent", e.detail);
                });
                this.$refs.frame?.contentWindow.addEventListener("SSUUpdateFormFieldsEvent", (e) => {
                    console.warn("SSUUpdateFormFieldsEvent name has been fixed", e.detail);
                });
                this.$refs.frame?.contentWindow.addEventListener("SSUButtonStateChangedEvent", (e) => {
                    console.info("SSUButtonStateChangedEvent", e.detail);
                    if (e.detail.buttonId == "saveButton") {
                        this.saveButton = e.detail.enabled
                    } else if (e.detail.buttonId == "prevPageButton") {
                        this.previousPage = e.detail.enabled
                    } else if (e.detail.buttonId == "nextPageButton") {
                        this.nextPage = e.detail.enabled
                    }
                });

                this.$refs.frame?.contentWindow.addEventListener("SSUSignaturePerformedEvent", (e) => {
                    console.info("SSUSignaturePerformedEvent", e.detail);

                    this.missingSignatureFields--;
                    this.signedSignatureFields++;
                });


                this.$refs.frame?.contentWindow.addEventListener("SSUDocumentLoadedEvent", (e) => {
                    console.info("SSUDocumentLoadedEvent", e.detail);
                });

                this.$refs.frame?.contentWindow.addEventListener("SSUDocumentSavedEvent", (e) => {
                    console.info("SSUDocumentSavedEvent", e.detail);

                    // if(e.detail.successful)
                });

                this.$refs.frame?.contentWindow.addEventListener("SSUPageNumberChangeEvent", (e) => {
                    console.info("SSUPageNumberChangeEvent", e.detail);
                    this.currentPage = e.detail.newPageNumber

                    this.sendKeepAlive()
                });
        },
        async sendKeepAlive() {
            await this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.KEEP_ALIVE_SIGNO_VIEWER, { viewFileId: this.elem?.viewFileId, nodeId: this.elem?.nodeId })
        }
    },

    watch: {
        session() {
            this.updateSession()
        }
    },

    beforeUnmount() {
        this.closeViewer()
    }
}
</script>
<style scoped>
.mx {
    margin-left: 1rem;
    margin-right: 1rem;
}

.relative {
    position: relative;
}

.bubble {
    position: absolute;
    background-color: red;
    border-radius: 16px;
    box-sizing: border-box;
    color: white;

    position: absolute;
    right: -8px;
    top: -4px;
    height: 18px;
    width: 18px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 12px;
}

.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}

.w-full {
    width: 100%;
}

.gap-1 {
    gap: 1rem;
}

.icon-button {
    cursor: pointer;
    padding: 4px;
}

.icon-button:disabled {
    cursor: not-allowed;
    opacity: 0.4;
}

.signo_viewer {
    width: 100% !important;
    height: 100% !important;
}

.object-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 5px;
}

.object-container object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}
</style>