<template>
  <div>
    <div class="box__container">
      <Table
        title="Makler Positiv Liste"
        :headerActions="tableHeaderActions"
        :headers="headers"
        :rows="positivRows"
        hidePagination
        rowId="maklerNr"
        @action-DELETE="deleteRow('positiv', $event)"
        @action-EDIT="editOrCreateRow('positiv', $event)"
        @headerAction-ADD="editOrCreateRow('positiv')"
      />
    </div>
    <div class="box__container">
      <Table
        title="Makler Negativ Liste"
        :headerActions="tableHeaderActions"
        :headers="headers"
        :rows="negativRows"
        hidePagination
        rowId="maklerNr"
        @action-DELETE="deleteRow('negativ', $event)"
        @action-EDIT="editOrCreateRow('negativ', $event)"
        @headerAction-ADD="editOrCreateRow('negativ')"
      />
    </div>

    <BaseModal 
        ref="maklerModal"
        :modalTitle="selectedRow.index ? 'Makler bearbeiten' : 'Makler hinzufügen'"
        :confirmDisabled="!selectedRowIsValid"
        @onConfirmButton="saveRow"
    >
      <InputToggleSwitch
        v-model="selectedRow.mitStruktur"
        label="Inkl. Struktur"
      />
      <InputField
        v-model="selectedRow.maklerNr"
        label="Maklernummer"
        isComponentHalfSize
        :renderDanger="!selectedRowIsValid"
      />
    </BaseModal>
  </div>
</template>

<script>
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";
import {
  TextColumn,
  PillColumn,
  ActionColumn,
  SimpleAction,
  ConfirmedAction,
} from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import InputField from "@/components/core/forms/InputField.vue";
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import BaseModal from '@/components/core/BaseModal.vue';

export default {
  mixins: [mixin],
  components: {
    Table,
    InputToggleSwitch,
    InputField,
    BaseModal,
  },
  data() {
    return {
      selectedRow: {},
      type: null,
    };
  },
  mounted() {
  },
  computed: {
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Makler hinzufügen')
      ];
    },
    headers() {            
      return {
        lockedLeft: [
          PillColumn("struktur", "mit Struktur", 200, 0),
          TextColumn("maklerNr", "Maklernr"),
        ],
        center: [],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ]
      };
    },
    positivRows() {
      return this.convertRows(this.dataSettings.sichtbarkeit?.positivList || [])
    },
    negativRows() {
      return this.convertRows(this.dataSettings.sichtbarkeit?.negativList || [])
    },
    selectedRowIsValid() {
      return this.selectedRowNoMaklernr
    },
    selectedRowNoMaklernr() {
      return this.selectedRow?.maklerNr?.length === 5
    },
  },
  methods: {
    convertRows(rows) {
      return rows.map((row, index) => ({
        ...row,
        index,
        struktur: {
          label: row.mitStruktur ? 'Ja' : 'Nein',
          type: row.mitStruktur ? 'success' : 'danger'
        },
        actions: [
          SimpleAction('EDIT', 'PhPencil', 'Bearbeiten'),
          ConfirmedAction('DELETE', 'PhTrash', 'Löschen', `Soll der Eintrag wirklich gelöscht werden?`, 'Eintrag löschen', 'Löschen')
        ]
      }))
    },
    editOrCreateRow(type, row) {
      this.type = type
      this.selectedRow = row
      this.$refs.maklerModal.open()
    },
    saveRow() {
      const records = this.type === 'positiv' ? this.dataSettings.sichtbarkeit?.positivList : this.dataSettings.sichtbarkeit?.negativList

      const index = records.findIndex(record => record.maklerNr == this.selectedRow.maklerNr);

      let newRow = {
        maklerNr: this.selectedRow.maklerNr,
        mitStruktur: this.selectedRow.mitStruktur 
      }

      if (index >= 0) {
        this.$set(records, index, newRow);
      } else {
        records.push(newRow)
      }

      this.updateStore({}, 'settings')
    },
    deleteRow(type, row) {
      const records = type === 'positiv' ? this.dataSettings.sichtbarkeit?.positivList : this.dataSettings.sichtbarkeit?.negativList

      const index = records.findIndex(record => record.maklerNr == row.maklerNr);
      if (index >= 0)
        records.splice(index, 1);

      this.updateStore({}, 'settings')
    }
  }
};
</script>

<style scoped>
</style>