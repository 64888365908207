export function removeFormatting(formattedNumber) {
    if (formattedNumber) {
        formattedNumber = String(formattedNumber);
        let lastSeparator = String(formattedNumber).lastIndexOf('.');
        if (formattedNumber.lastIndexOf(',') > lastSeparator) {
            lastSeparator = formattedNumber.lastIndexOf(',');
        }
        let numberPart = '';
        let decimalPart = '';
        if (lastSeparator > 0) {
            numberPart = formattedNumber.substring(0, lastSeparator).split(',').join('').split('.').join('');
            decimalPart = formattedNumber.substring(lastSeparator + 1, formattedNumber.length);
            if (decimalPart.indexOf(',') > 0) {
                lastSeparator = formattedNumber.lastIndexOf(',');
                decimalPart = formattedNumber.substring(lastSeparator + 1, formattedNumber.length);
            }
        } else {
            numberPart = formattedNumber;
        }
        return [numberPart, decimalPart].join('.');
    }
    return formattedNumber;
}
