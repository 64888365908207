import OPEN_SIGNS_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import { makeQueryParam } from '@/helpers/utils-helper';
import axios from "axios";

export default {
    [OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS](state) {
        return state.openSignatures
    },

    [OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS_META](state) {
        return state.openSignaturesMeta
    },

    [OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS_COUNT](state) {
        return state.openSignatures?.count || 0
    },

    [OPEN_SIGNS_TYPES.GETTERS.IS_COUNTING](state) {
        return state.isCounting === true
    },

    [OPEN_SIGNS_TYPES.GETTERS.HAS_OPEN_SIGNS](state) {
        return state.hasOpenSigns === true
    },
}