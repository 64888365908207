export const MUTATION_PREFIX = 'DEPOTPOSITIONS_MUTATIONS_';
export const ACTIONS_PREFIX = 'DEPOTPOSITIONS_ACTIONS_';
export const GETTERS_PREFIX = 'DEPOTPOSITIONS_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_DEPOTPOSITIONS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FAILTURE',
    RESET_RETRIEVED_DEPOTPOSITIONS: MUTATION_PREFIX + 'RESET_RETRIEVED_DEPOTPOSITIONS',
    RETRIEVE_DEPOTPOSITIONS_CHARTS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_CHARTS_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_CHARTS_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_CHARTS_FAILTURE',
    RETRIEVE_DEPOTPOSITIONS_ESG_CHART_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_ESG_CHART_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_ESG_CHART_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_ESG_CHART_FAILTURE',
    RETRIEVE_DEPOTPOSITIONS_CONFIG_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_CONFIG_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_CONFIG_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_CONFIG_FAILTURE',
    CHANGE_ID: MUTATION_PREFIX + 'CHANGE_ID',
    RETRIEVE_DEPOTPOSITIONS_TRANSACTIONS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_TRANSACTIONS_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_TRANSACTIONS_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_TRANSACTIONS_FAILTURE',

    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FAILTURE',
    
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_REPORT_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_REPORT_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_REPORT_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_REPORT_FAILTURE',

    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_KEYS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_KEYS_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_KEYS_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTRETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_KEYS_FAILTUREPOSITIONS_CONFIG_FAILTURE',

    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_KEYS_SUCCESS: MUTATION_PREFIX + 'RETRIERETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_KEYS_SUCCESSVE_DEPOTPOSITIONS_CONFIG_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_KEYS_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_KEYS_FAILTURE',

    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_GENERAL_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_GENERAL_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_GENERAL_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_GENERAL_FAILTURE',

    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_CHART_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_CHART_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_CHART_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_CHART_FAILTURE',

    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_CHART_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_CHART_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_CHART_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_CHART_FAILTURE',

    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_DESCRIPTION_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_DESCRIPTION_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_DESCRIPTION_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_DESCRIPTION_FAILTURE',

    GET_LIMITS_SUCCESS: MUTATION_PREFIX + 'GET_LIMITS_SUCCESS',
    GET_LIMITS_FAILTURE: MUTATION_PREFIX + 'GET_LIMITS_FAILTURE',
    SET_LIMITS_SUCCESS: MUTATION_PREFIX + 'SET_LIMITS_SUCCESS',
    SET_LIMITS_FAILTURE: MUTATION_PREFIX + 'SET_LIMITS_FAILTURE',
    DELETE_LIMITS_SUCCESS: MUTATION_PREFIX + 'DELETE_LIMITS_SUCCESS',
    DELETE_LIMITS_FAILTURE: MUTATION_PREFIX + 'DELETE_LIMITS_FAILTURE',
    GET_LIMITS_200_SUCCESS: MUTATION_PREFIX + 'GET_LIMITS_200_SUCCESS',
    GET_LIMITS_200_FAILTURE: MUTATION_PREFIX + 'GET_LIMITS_200_FAILTURE',
    SET_LIMITS_200_SUCCESS: MUTATION_PREFIX + 'SET_LIMITS_200_SUCCESS',
    SET_LIMITS_200_FAILTURE: MUTATION_PREFIX + 'SET_LIMITS_200_FAILTURE',
    DELETE_LIMITS_200_SUCCESS: MUTATION_PREFIX + 'DELETE_LIMITS_200_SUCCESS',
    DELETE_LIMITS_200_FAILTURE: MUTATION_PREFIX + 'DELETE_LIMITS_200_FAILTURE',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',

    SET_SELECTED_CHART_WIDGET: MUTATION_PREFIX + 'SET_SELECTED_CHART_WIDGET',

    GET_DEPOT_PDF_OPTIONS_SUCCESS: MUTATION_PREFIX + 'GET_DEPOT_PDF_OPTIONS_SUCCESS',
    RETRIEVE_DEPOTPOSITIONS_OUT_OF_LIMITS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_OUT_OF_LIMITS_SUCCESS',
    RETRIEVE_BROKER_CHANGES_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_BROKER_CHANGES_SUCCESS',
    
    SET_SHOW_COURTAGE: MUTATION_PREFIX + 'SET_SHOW_COURTAGE',
    SET_CALC_COURTAGE: MUTATION_PREFIX + 'SET_CALC_COURTAGE',
    SET_DEPOTPOSITIONS_GUV: MUTATION_PREFIX + 'SET_DEPOTPOSITIONS_GUV',
  },
  ACTIONS: {
    RETRIEVE_DEPOTPOSITIONS: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS',
    RESET_RETRIEVED_DEPOTPOSITIONS_IF_NEEDED: ACTIONS_PREFIX + 'RESET_RETRIEVED_DEPOTPOSITIONS_IF_NEEDED',
    RETRIEVE_DEPOTPOSITIONS_CHARTS: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_CHARTS_DETAILS',
    RETRIEVE_DEPOTPOSITIONS_CONFIG: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_CONFIG',
    RETRIEVE_DEPOTPOSITIONS_TRANSACTIONS: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_TRANSACTIONS',
    RETRIEVE_DEPOTPOSITIONS_ESG_CHART: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_ESG_CHART',

    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_NAME: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_NAME',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_REPORT: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_REPORT',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_KEYS: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_KEYS',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_KEYS: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_KEYS',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_GENERAL: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_GENERAL',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_CHART: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_PERFORMANCE_CHART',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_CHART: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_CHART',
    RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_DESCRIPTION: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_DETAILS_DESCRIPTION',

    GET_LIMITS: ACTIONS_PREFIX + 'GET_LIMITS',
    SET_LIMITS: ACTIONS_PREFIX + 'SET_LIMITS',
    DELETE_LIMITS: ACTIONS_PREFIX + 'DELETE_LIMITS',
    RESET_LIMITS: ACTIONS_PREFIX + 'RESET_LIMITS',
    GET_LIMITS_200: ACTIONS_PREFIX + 'GET_LIMITS_200',
    SET_LIMITS_200: ACTIONS_PREFIX + 'SET_LIMITS_200',
    DELETE_LIMITS_200: ACTIONS_PREFIX + 'DELETE_LIMITS_200',

    RETRIEVE_DEPOTPOSITIONS_OUT_OF_LIMITS: ACTIONS_PREFIX + 'RETRIEVE_DEPOTPOSITIONS_OUT_OF_LIMITS',
    RETRIEVE_BROKER_CHANGES: ACTIONS_PREFIX + 'RETRIEVE_BROKER_CHANGES',

    GET_DEPOT_PDF_OPTIONS: ACTIONS_PREFIX + 'GET_DEPOT_PDF_OPTIONS',

    GET_DEPOT_DETAILS: ACTIONS_PREFIX + 'GET_DEPOT_DETAILS',
    LOAD_SHOW_COURTAGE: ACTIONS_PREFIX + 'LOAD_SHOW_COURTAGE',
    LOAD_CALC_COURTAGE: ACTIONS_PREFIX + 'LOAD_CALC_COURTAGE',
    LOAD_DEPOTPOSITIONS_GUV: ACTIONS_PREFIX + 'LOAD_DEPOTPOSITIONS_GUV',
  },
  GETTERS: {
    DEPOTPOSITIONS: GETTERS_PREFIX + 'DEPOTPOSITIONS',
    DEPOTPOSITIONS_CHARTS: GETTERS_PREFIX + 'DEPOTPOSITIONS_CHARTS',
    DEPOTPOSITIONS_ESG_CHART: GETTERS_PREFIX + 'DEPOTPOSITIONS_ESG_CHART',
    DEPOTPOSITIONS_CONFIG: GETTERS_PREFIX + 'DEPOTPOSITIONS_CONFIG',
    DEPOTPOSITIONS_TRANSACTIONS: GETTERS_PREFIX + 'DEPOTPOSITIONS_TRANSACTIONS',
    DEPOTPOSITIONS_FONDSINFO: GETTERS_PREFIX + 'DEPOTPOSITIONS_FONDSINFO',
    LIMITS: GETTERS_PREFIX + 'LIMITS',
    LIMITS_200: GETTERS_PREFIX + 'LIMITS_200',
    DEPOT_ID: GETTERS_PREFIX + 'DEPOT_ID',
    SELECTED_DEPOT_TITLE: GETTERS_PREFIX + 'SELECTED_DEPOT_TITLE',

    SELECTED_CHART_WIDGET: GETTERS_PREFIX + 'SELECTED_CHART_WIDGET',

    DEPOT_PDF_OPTIONS: GETTERS_PREFIX + 'DEPOT_PDF_OPTIONS',
    DEPOTPOSITIONS_OUT_OF_LIMITS: GETTERS_PREFIX + 'DEPOTPOSITIONS_OUT_OF_LIMITS',
    BROKER_CHANGES: GETTERS_PREFIX + 'BROKER_CHANGES',
    GET_SHOW_COURTAGE: GETTERS_PREFIX + 'GET_SHOW_COURTAGE',
    GET_CALC_COURTAGE: GETTERS_PREFIX + 'GET_CALC_COURTAGE',
    GET_DEPOTPOSITIONS_GUV: GETTERS_PREFIX + 'GET_DEPOTPOSITIONS_GUV',
  }
}