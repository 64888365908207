var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          subtitle: _vm.subtitle,
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.actions
        },
        on: { "action-PDF": _vm.getPdf }
      }),
      _c("StepperForm", {
        attrs: {
          stepperName: "retirement",
          stepperMediator: _vm.stepper,
          selectedStepKey: _vm.stepKey
        },
        on: { "set-step": _vm.setStepByKey },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [_c("RuhestandsplanungStep")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }