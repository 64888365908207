var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseFilter", {
        ref: "baseFilter",
        attrs: {
          showSaveButton: "",
          hasSmartSearch: _vm.hasSmartSearch,
          filterId: _vm.saveKey,
          title: _vm.title,
          metadata: _vm.metadata,
          configFilter: _vm.configFilter,
          extraButton: _vm.extraButton,
          predefinedFilter: _vm.predefinedFilter,
          showButtons: _vm.showButtons,
          immidiateSearch: _vm.immidiateSearch,
          isCustomerSearch: _vm.isCustomerSearch,
          defaultOptions: _vm.defaultOptions,
          searchFromParam: _vm.searchFromParam,
          showSearchButton: _vm.showSearchButton,
          isCache: _vm.isCache,
          role: "form",
          "aria-label": "Kundenfilter"
        },
        on: {
          onFilter: function($event) {
            return _vm.filterForm($event)
          },
          extraButtonClick: function($event) {
            return _vm.$emit("extraButtonClick")
          },
          changeCombobox: _vm.onComboboxChange,
          onFilterZurucksetzen: function($event) {
            return _vm.onFilterZurucksetzen()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }