var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _c("div", { staticClass: "box__title" }, [_vm._v("Beruf")]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("ComboBox", {
            attrs: {
              label: "Beruf",
              labelClass: _vm.depoteroeffnungenLabelClass,
              isComponentHalfSize: true,
              values: _vm.professions,
              disabled: !_vm.isEditable,
              firstEmpty: true,
              filterValuesWithKeyPress: ""
            },
            on: {
              change: function($event) {
                return _vm.addCustomerDataEditedCombo(
                  "employment",
                  "profession",
                  $event
                )
              }
            },
            model: {
              value: _vm.employment.profession,
              callback: function($$v) {
                _vm.$set(_vm.employment, "profession", $$v)
              },
              expression: "employment.profession"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Branche",
              labelClass: _vm.depoteroeffnungenLabelClass,
              isComponentHalfSize: true,
              disabled: !_vm.isEditable
            },
            on: {
              change: function($event) {
                return _vm.addCustomerDataEdited("employment")
              }
            },
            model: {
              value: _vm.employment.industry,
              callback: function($$v) {
                _vm.$set(_vm.employment, "industry", $$v)
              },
              expression: "employment.industry"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Arbeitsverhältnis",
              isComponentHalfSize: true,
              values: _vm.customerDataConfig.employmentStates,
              disabled: !_vm.isEditable,
              firstEmpty: true
            },
            on: {
              change: function($event) {
                _vm.addCustomerDataEditedCombo(
                  "employment",
                  "workingCondition",
                  $event
                )
                _vm.handleWorkingConditionChange($event)
              }
            },
            model: {
              value: _vm.employment.workingCondition,
              callback: function($$v) {
                _vm.$set(_vm.employment, "workingCondition", $$v)
              },
              expression: "employment.workingCondition"
            }
          }),
          _vm.customerData &&
          _vm.customerData.personType &&
          _vm.customerData.personType === "Kontakt"
            ? _c("InputField", {
                attrs: {
                  label: "Firma",
                  isComponentHalfSize: true,
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited(
                      "employment",
                      "company",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.employment.company,
                  callback: function($$v) {
                    _vm.$set(_vm.employment, "company", $$v)
                  },
                  expression: "employment.company"
                }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }