var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "persoenliche-daten-view" },
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Vermittlerdaten", actions: _vm.headerActions },
        on: {
          "action-NEW_BEMERKUNG": _vm.createBemerkung,
          "action-NEW_BESUCHSBERICHT": _vm.createBesuchsbericht
        }
      }),
      _c("StepperForm", {
        attrs: {
          stepType: "tab",
          stepperName: "customer-data",
          stepperMediator: _vm.tabsStepperMediator,
          hasFertig: false,
          selectedStepKey: _vm.step,
          selectedSubstepKey: _vm.substep,
          additionalActionsForMobile: _vm.baseModalActions
        },
        on: {
          "set-step": function($event) {
            return _vm.navigateToPath($event)
          },
          "set-substep": function($event) {
            return _vm.navigateToSubstepPath($event)
          },
          handleOtherActions: _vm.handleOtherActions
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [_c("router-view", { staticClass: "pillar" })]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }