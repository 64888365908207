var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TeleportToBody", [
    _vm.isOpen
      ? _c(
          "div",
          {
            ref: "modalEl",
            staticClass: "base-modal__container is-modal-component ",
            class:
              ((_obj = {}),
              (_obj["modal-" + _vm.size] = !!_vm.size),
              (_obj["is-open"] = _vm.isOpen),
              (_obj["is-modal-as-full-page"] = _vm.isModalAsFullPageIntern),
              (_obj["always-scrollable"] = _vm.alwaysScrollable),
              _obj)
          },
          [
            _c(
              "div",
              {
                staticClass: "base-modal__backdrop",
                on: {
                  click: function($event) {
                    return _vm.handleCloseBackdrop($event)
                  }
                }
              },
              [
                _vm.showItemsAsFullPage ? _c("BaseTopProgressBar") : _vm._e(),
                _c(
                  "div",
                  { staticClass: "base-modal__content" },
                  [
                    _vm.hasHeader
                      ? _c("div", { staticClass: "base-modal__header" }, [
                          _c(
                            "div",
                            { staticClass: "base-modal__header--top" },
                            [
                              _vm.showItemsAsModal
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "base-modal__header--close-large "
                                    },
                                    [
                                      _vm.showCloseButton
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "base-modal__header--close clickable",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleCloseButton()
                                                }
                                              }
                                            },
                                            [
                                              _c("PhXCircle", {
                                                attrs: {
                                                  size: "20",
                                                  alt:
                                                    "Icon zum Schließen des Fensters"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.showItemsAsFullPage
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "base-modal__header--close-small"
                                    },
                                    [
                                      _vm.replacementActionForCloseButtonSmallScreen
                                        ? [
                                            _c(
                                              _vm
                                                .replacementActionForCloseButtonSmallScreen
                                                .component,
                                              _vm._b(
                                                {
                                                  key:
                                                    _vm
                                                      .replacementActionForCloseButtonSmallScreen
                                                      .key,
                                                  tag: "component",
                                                  attrs: {
                                                    tid: _vm._generateTidFromString(
                                                      _vm.id +
                                                        "-" +
                                                        _vm
                                                          .replacementActionForCloseButtonSmallScreen
                                                          .key
                                                    )
                                                  },
                                                  on: {
                                                    action:
                                                      _vm.handlePageHeaderAction
                                                  }
                                                },
                                                "component",
                                                _vm.replacementActionForCloseButtonSmallScreen,
                                                false
                                              ),
                                              [
                                                _c(
                                                  "template",
                                                  {
                                                    slot:
                                                      "action-" +
                                                      _vm
                                                        .replacementActionForCloseButtonSmallScreen
                                                        .actionKey
                                                  },
                                                  [
                                                    _vm._t(
                                                      "action-" +
                                                        _vm
                                                          .replacementActionForCloseButtonSmallScreen
                                                          .actionKey
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              2
                                            )
                                          ]
                                        : _c(
                                            "BaseButton",
                                            {
                                              staticClass: "m-0 p-0",
                                              attrs: { isLink: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleCloseButton()
                                                }
                                              }
                                            },
                                            [
                                              _c("PhCaretLeft", {
                                                attrs: { size: "22" }
                                              })
                                            ],
                                            1
                                          )
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "base-modal__header--title",
                                  class: { "ellipsis-all": _vm.$isSmallScreen }
                                },
                                [
                                  _vm._t("modalTitle", function() {
                                    return [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.sanitize(
                                              _vm.titleSetFromChild ||
                                                _vm.modalTitle
                                            )
                                          )
                                        }
                                      })
                                    ]
                                  })
                                ],
                                2
                              ),
                              _vm.showItemsAsFullPage
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "base-modal__header--actions",
                                      class: {
                                        "has-remain-actions":
                                          _vm.hasRemainActions
                                      }
                                    },
                                    [
                                      _vm._t("modalActions", function() {
                                        return [
                                          _vm.primaryAction
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "base-modal__header--first-action"
                                                },
                                                [
                                                  _vm.primaryAction.component
                                                    ? _c(
                                                        _vm.primaryAction
                                                          .component,
                                                        _vm._b(
                                                          {
                                                            tag: "component",
                                                            staticClass:
                                                              "ellipsis",
                                                            attrs: {
                                                              isMainAction: "",
                                                              tid: _vm._generateTidFromString(
                                                                _vm.id +
                                                                  "-" +
                                                                  _vm
                                                                    .primaryAction
                                                                    .key
                                                              )
                                                            },
                                                            on: {
                                                              action:
                                                                _vm.handlePageHeaderAction
                                                            }
                                                          },
                                                          "component",
                                                          _vm.primaryAction,
                                                          false
                                                        )
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.remainActions
                                            ? _c(
                                                "BaseContextMenu",
                                                {
                                                  key: _vm.actionsKey,
                                                  staticClass:
                                                    "base-modal__header--multiple-action"
                                                },
                                                _vm._l(
                                                  _vm.remainActions,
                                                  function(action) {
                                                    return _c(
                                                      "ContextMenuItem",
                                                      { key: action.key },
                                                      [
                                                        action.component
                                                          ? _c(
                                                              action.component,
                                                              _vm._b(
                                                                {
                                                                  tag:
                                                                    "component",
                                                                  attrs: {
                                                                    showAsLink:
                                                                      _vm.isModalAsFullPage,
                                                                    tid: _vm._generateTidFromString(
                                                                      _vm.id +
                                                                        "-" +
                                                                        action.key
                                                                    )
                                                                  },
                                                                  on: {
                                                                    action:
                                                                      _vm.handlePageHeaderAction
                                                                  }
                                                                },
                                                                "component",
                                                                action,
                                                                false
                                                              ),
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot:
                                                                      "action-" +
                                                                      action.actionKey
                                                                  },
                                                                  [
                                                                    _vm._t(
                                                                      "action-" +
                                                                        action.actionKey
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          ),
                          _vm.$slots.modalHeaderBottom
                            ? _c(
                                "div",
                                { staticClass: "base-modal__header--bottom" },
                                [_vm._t("modalHeaderBottom")],
                                2
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "base-modal__body",
                        attrs: { "data-scroll-container": "" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "base-modal__body--wrap" },
                          [_vm._t("default")],
                          2
                        )
                      ]
                    ),
                    _vm.showItemsAsModal
                      ? [
                          _vm.hasFooter
                            ? _c("div", { staticClass: "base-modal__footer" }, [
                                _c(
                                  "div",
                                  { staticClass: "layout__negative-margin--8" },
                                  [
                                    _vm.hasActions
                                      ? [
                                          _vm._l(_vm.visibleActions, function(
                                            action
                                          ) {
                                            return [
                                              action.component
                                                ? _c(
                                                    action.component,
                                                    _vm._b(
                                                      {
                                                        key: action.key,
                                                        tag: "component",
                                                        attrs: {
                                                          showAsLink:
                                                            _vm.isModalAsFullPage,
                                                          tid: _vm._generateTidFromString(
                                                            _vm.id +
                                                              "-" +
                                                              action.key
                                                          )
                                                        },
                                                        on: {
                                                          action:
                                                            _vm.handlePageHeaderAction
                                                        }
                                                      },
                                                      "component",
                                                      action,
                                                      false
                                                    ),
                                                    [
                                                      _c(
                                                        "template",
                                                        {
                                                          slot:
                                                            "action-" +
                                                            action.actionKey
                                                        },
                                                        [
                                                          _vm._t(
                                                            "action-" +
                                                              action.actionKey
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : _vm._e()
                                            ]
                                          })
                                        ]
                                      : _vm._e(),
                                    _vm._t("footer")
                                  ],
                                  2
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }