var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "base-filter__container box__container",
      attrs: { tid: "4ec7d0ef-582b-450c-9789-9282c9880a8d" }
    },
    [
      _vm.$isSmallScreen
        ? [
            _c("MobileInfoRow", {
              attrs: { label: _vm.title || "Filter" },
              on: {
                clickRow: function($event) {
                  return _vm.$refs.filterModal.open()
                }
              }
            }),
            _vm.extraButton
              ? _c(
                  "BaseButtonsContainer",
                  { staticClass: "action-button__container" },
                  [
                    _vm.extraButton && _vm.extraButton.buttonText
                      ? [
                          _c(
                            "BaseButton",
                            {
                              attrs: {
                                isPrimary: _vm.extraButton.isExtraButtonPrimary,
                                isSecondary: !_vm.extraButton
                                  .isExtraButtonPrimary
                              },
                              on: {
                                click: function($event) {
                                  _vm.$emit(
                                    "extraButtonClick",
                                    _vm.getFilterConfig()
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.extraButton.buttonText) + " "
                              )
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm._t("default")
                  ],
                  2
                )
              : _vm._e()
          ]
        : _c("div", { staticClass: "row mt-0" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-12 col-sm-12" },
              [
                _c("div", { staticClass: "row my-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("BaseFilterSelect", {
                        ref: "baseFilterSelect",
                        attrs: {
                          title: _vm.title,
                          showSaveButton: _vm.showSaveButton,
                          storedSearchParameter: _vm.storedSearchParameter,
                          programmaticSearchParameter:
                            _vm.configFilter &&
                            _vm.configFilter.programmaticSearchParameter
                        },
                        on: {
                          onEditFilter: function($event) {
                            return _vm.filterBearbeiten()
                          },
                          onSelect: function($event) {
                            return _vm.selectStoredSearchParameter($event, true)
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "titleToolbar",
                            fn: function() {
                              return [
                                (_vm.hasSmartSearch &&
                                  _vm.getFilterRows.length) ||
                                _vm.getFilterRows.length > 1
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-clear parameter-search__input-fields-toggle clickable",
                                        class: { active: _vm.collapsed },
                                        attrs: {
                                          "aria-label": "Einklappen",
                                          type: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.setCollapsed()
                                          }
                                        }
                                      },
                                      [
                                        _c("PhCaretUp", { attrs: { size: 18 } })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isEditAvailable
                                  ? _c(
                                      "BaseContextMenu",
                                      [
                                        _c(
                                          "ContextMenuItem",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.filterBearbeiten()
                                              }
                                            }
                                          },
                                          [_vm._v("Filterliste")]
                                        ),
                                        _c(
                                          "ContextMenuItem",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.emitFilterSpeichernEvent()
                                              }
                                            }
                                          },
                                          [_vm._v("Filter speichern")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "parameter-search__input-fields__container" },
                  [
                    !_vm.collapsed && _vm.hasSmartSearch
                      ? _c(
                          "div",
                          {
                            staticClass: "row parameter-search__input-fields",
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.noop.apply(null, arguments)
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.emitFilterEvent()
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-10 col-lg-10 col-md-10 col-sm-10"
                              },
                              [
                                _c("InputField", {
                                  ref: "defaultSearchInput",
                                  staticClass: "filterLabel",
                                  attrs: {
                                    placeholder:
                                      _vm.configFilter
                                        .placeholderForDefSearchInput,
                                    label: _vm.filterLabel,
                                    inputClass: _vm.setClassTabElement(
                                      _vm.isTabCtrl,
                                      null
                                    ),
                                    preventTab: _vm.isTabCtrl
                                  },
                                  on: {
                                    tabPressed: function($event) {
                                      return _vm.onTabPressed($event, -1)
                                    }
                                  },
                                  model: {
                                    value: _vm.defaultSearchInput,
                                    callback: function($$v) {
                                      _vm.defaultSearchInput = $$v
                                    },
                                    expression: "defaultSearchInput"
                                  }
                                }),
                                _vm.defaultSearchInput &&
                                _vm.filteredOptions &&
                                _vm.filteredOptions.length
                                  ? _c("div", { staticClass: "dropList" }, [
                                      _c(
                                        "ul",
                                        _vm._l(_vm.filteredOptions, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "li",
                                            {
                                              key: index,
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectFilterDropListItem(
                                                    item
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.label) + " "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            ),
                            !_vm.isSingleFilter
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "parameter-search__buttons-container col-2 col-lg-2 col-md-2 col-sm-2 d-flex align-items-center justify-content-end"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "parameter-controls__container"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-clear clickable",
                                            attrs: {
                                              type: "button",
                                              alt: _vm.ariaDescription
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.enableMoreOptions()
                                              }
                                            }
                                          },
                                          [
                                            _c("ph-plus", {
                                              attrs: {
                                                id:
                                                  _vm.filterId +
                                                  "base__filter__plus__default",
                                                size: 16,
                                                weight: "bold",
                                                alt: _vm.ariaDescription
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      : _vm._e(),
                    _vm.showMoreOptions ||
                    !_vm.hasSmartSearch ||
                    Object.keys(_vm.defaultOptions).length
                      ? _c(
                          "div",
                          {
                            ref: "moreOptionsEl",
                            staticClass:
                              "parameter-search__input-fields--more-options",
                            class: { collapsed: _vm.collapsed },
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.noop.apply(null, arguments)
                              },
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.emitFilterEvent()
                              }
                            }
                          },
                          _vm._l(_vm.getFilterRows, function(row, index) {
                            return _c(
                              "div",
                              {
                                key: row.secondaryKey + "-" + index,
                                staticClass: "parameter-search__input-fields",
                                class: { row: !_vm.collapsed }
                              },
                              [
                                !_vm.collapsed
                                  ? [
                                      _vm.showAlwaysPrimaryselection ||
                                      (row.primarySelection &&
                                        row.primarySelection.length > 1)
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "col-12 col-sm-3",
                                              class: {
                                                "d-none":
                                                  _vm.configFilter
                                                    .hideFirstColumn
                                              }
                                            },
                                            [
                                              _c("ComboBox", {
                                                attrs: {
                                                  values: row.primarySelection,
                                                  disabled:
                                                    row.disabled ||
                                                    row.isRequiredFilterGroup
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.onChangePrimary(
                                                      row,
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: row.key,
                                                  callback: function($$v) {
                                                    _vm.$set(row, "key", $$v)
                                                  },
                                                  expression: "row.key"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            "col-sm-3": !_vm.configFilter
                                              .hideFirstColumn,
                                            "col-sm-6":
                                              _vm.configFilter.hideFirstColumn,
                                            "col-9":
                                              !row.componentType ||
                                              row.componentType === "default",
                                            "col-12":
                                              row.componentType &&
                                              row.componentType !== "default",
                                            "parameter-search__component-default":
                                              !row.componentType ||
                                              row.componentType === "default"
                                          }
                                        },
                                        [
                                          _c("ComboBox", {
                                            attrs: {
                                              values: _vm.getSecondarySelection(
                                                row.key,
                                                index
                                              ),
                                              disabled: row.disabled
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.onChange(row, $event)
                                              }
                                            },
                                            model: {
                                              value: row.secondaryKey,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  row,
                                                  "secondaryKey",
                                                  $$v
                                                )
                                              },
                                              expression: "row.secondaryKey"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      row.componentType &&
                                      row.componentType !== "dateRange" &&
                                      row.componentType !== "numberRange" &&
                                      row.componentType !== "textRange" &&
                                      row.componentType !== "default" &&
                                      row.componentType !== "doubleCombo"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                key: row.__UPDATED_KEY__,
                                                staticClass:
                                                  "parameter-search__secondary-key-container col-9 col-sm-4"
                                              },
                                              [
                                                row.componentType == "text"
                                                  ? _c("InputField", {
                                                      attrs: {
                                                        id:
                                                          _vm.filterId +
                                                          "base__filter__" +
                                                          row.secondaryKey,
                                                        disabled: row.disabled,
                                                        inputmode: _vm.getComponentInputmode(
                                                          row.key,
                                                          row.secondaryKey
                                                        ),
                                                        inputClass: _vm.setClassTabElement(
                                                          row.isTabCtrl,
                                                          index
                                                        ),
                                                        preventTab: !!row.isTabCtrl
                                                      },
                                                      on: {
                                                        tabPressed: function(
                                                          $event
                                                        ) {
                                                          return _vm.onTabPressed(
                                                            $event,
                                                            index
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          row[row.secondaryKey],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            row.secondaryKey,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row[row.secondaryKey]"
                                                      }
                                                    })
                                                  : _vm._e(),
                                                row.componentType == "integer"
                                                  ? _c("InputField", {
                                                      attrs: {
                                                        value:
                                                          row[row.secondaryKey],
                                                        type: "number",
                                                        isInteger: "",
                                                        disabled: row.disabled,
                                                        inputClass: _vm.setClassTabElement(
                                                          row.isTabCtrl,
                                                          index
                                                        ),
                                                        preventTab: !!row.isTabCtrl
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.setValueChanged(
                                                            row,
                                                            row.secondaryKey,
                                                            $event
                                                          )
                                                        },
                                                        tabPressed: function(
                                                          $event
                                                        ) {
                                                          return _vm.onTabPressed(
                                                            $event,
                                                            index
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                row.componentType == "tel"
                                                  ? _c("InputField", {
                                                      attrs: {
                                                        value:
                                                          row[row.secondaryKey],
                                                        type: "tel",
                                                        disabled: row.disabled,
                                                        inputClass: _vm.setClassTabElement(
                                                          row.isTabCtrl,
                                                          index
                                                        ),
                                                        preventTab: !!row.isTabCtrl
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.setValueChanged(
                                                            row,
                                                            row.secondaryKey,
                                                            $event
                                                          )
                                                        },
                                                        tabPressed: function(
                                                          $event
                                                        ) {
                                                          return _vm.onTabPressed(
                                                            $event,
                                                            index
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                row.componentType ==
                                                "datepicker"
                                                  ? _c("DatePickerField", {
                                                      attrs: {
                                                        disabled: row.disabled
                                                      },
                                                      model: {
                                                        value:
                                                          row[row.secondaryKey],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            row.secondaryKey,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row[row.secondaryKey]"
                                                      }
                                                    })
                                                  : _vm._e(),
                                                row.componentType == "switcher"
                                                  ? _c("InputToggleSwitch", {
                                                      staticClass:
                                                        "parameter-search__input-toggle-switch",
                                                      attrs: {
                                                        disabled: row.disabled
                                                      },
                                                      model: {
                                                        value:
                                                          row[row.secondaryKey],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            row.secondaryKey,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row[row.secondaryKey]"
                                                      }
                                                    })
                                                  : _vm._e(),
                                                row.componentType ===
                                                "multipleItemSelector"
                                                  ? _c(
                                                      "InputMultipleItemSelector",
                                                      {
                                                        attrs: {
                                                          value:
                                                            row[
                                                              row.secondaryKey
                                                            ],
                                                          values:
                                                            row.comboOptions,
                                                          disabled: row.disabled
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.updateMultipleSelection(
                                                              row,
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      }
                                                    )
                                                  : _vm._e(),
                                                row.componentType === "chips"
                                                  ? _c("InputChips", {
                                                      staticClass: "p-0",
                                                      attrs: {
                                                        showToggle: true,
                                                        uniqueSelection:
                                                          row.uniqueSelection,
                                                        availableOptions:
                                                          row.comboOptions,
                                                        supportCustomValue:
                                                          row.supportCustomValue
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.updateMultipleSelection(
                                                            row,
                                                            $event
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          row[row.secondaryKey],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            row.secondaryKey,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row[row.secondaryKey]"
                                                      }
                                                    })
                                                  : _vm._e(),
                                                row.secondaryItem &&
                                                row.secondaryItem.parentValue.includes(
                                                  row[row.secondaryKey]
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row mt-0 mb-0"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6 col-sm-6"
                                                          },
                                                          [
                                                            row.componentType ==
                                                            "combobox"
                                                              ? _c("ComboBox", {
                                                                  attrs: {
                                                                    values: _vm.filteredComboOptions(
                                                                      row.comboOptions
                                                                    ),
                                                                    firstEmpty: false,
                                                                    disabled:
                                                                      row.disabled
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeCombobox(
                                                                        row,
                                                                        row.secondaryKey,
                                                                        $event
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      row[
                                                                        row
                                                                          .secondaryKey
                                                                      ],
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        row,
                                                                        row.secondaryKey,
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "row[row.secondaryKey]"
                                                                  }
                                                                })
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-6 col-sm-6"
                                                          },
                                                          [
                                                            row.secondaryItem
                                                              .type ==
                                                            "combobox"
                                                              ? _c("ComboBox", {
                                                                  attrs: {
                                                                    values: _vm.filteredComboOptions(
                                                                      row
                                                                        .secondaryItem
                                                                        .comboOptions
                                                                    ),
                                                                    firstEmpty: false,
                                                                    disabled:
                                                                      row.disabled
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      row[
                                                                        row
                                                                          .secondaryItem
                                                                          .key
                                                                      ],
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        row,
                                                                        row
                                                                          .secondaryItem
                                                                          .key,
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "row[row.secondaryItem.key]"
                                                                  }
                                                                })
                                                              : _vm._e(),
                                                            row.secondaryItem
                                                              .type == "text"
                                                              ? _c(
                                                                  "InputField",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        row.disabled
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        row[
                                                                          row
                                                                            .secondaryItem
                                                                            .key
                                                                        ],
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          row,
                                                                          row
                                                                            .secondaryItem
                                                                            .key,
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "row[row.secondaryItem.key]"
                                                                    }
                                                                  }
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  : row.componentType ==
                                                    "combobox"
                                                  ? _c(
                                                      "div",
                                                      [
                                                        row.componentType ==
                                                        "combobox"
                                                          ? _c("ComboBox", {
                                                              attrs: {
                                                                values: _vm.filteredComboOptions(
                                                                  _vm.getComboboxOptions(
                                                                    row.key,
                                                                    row.secondaryKey
                                                                  )
                                                                ),
                                                                firstEmpty: false,
                                                                sortComboboxValues:
                                                                  row.sortComboboxValues,
                                                                disabled:
                                                                  row.disabled
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.changeCombobox(
                                                                    row,
                                                                    row.secondaryKey,
                                                                    $event
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  row[
                                                                    row
                                                                      .secondaryKey
                                                                  ],
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    row,
                                                                    row.secondaryKey,
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "row[row.secondaryKey]"
                                                              }
                                                            })
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                row.componentType ==
                                                "splitedDatePicker"
                                                  ? _c("SplitedDatePicker", {
                                                      attrs: {
                                                        disabled: row.disabled
                                                      },
                                                      model: {
                                                        value:
                                                          row[row.secondaryKey],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            row,
                                                            row.secondaryKey,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "row[row.secondaryKey]"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e(),
                                      row.componentType == "dateRange"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "parameter-search__secondary-key-container col-9 col-sm-4"
                                              },
                                              [
                                                _c("DatePickerField", {
                                                  attrs: {
                                                    isRangePicker: "",
                                                    disabled: row.disabled,
                                                    value: _vm.getDateRangeValue(
                                                      row
                                                    )
                                                  },
                                                  on: {
                                                    onSelectRangeFromAsText: function(
                                                      $event
                                                    ) {
                                                      row[
                                                        row.secondaryKey + "_1"
                                                      ] = $event
                                                    },
                                                    onSelectRangeToAsText: function(
                                                      $event
                                                    ) {
                                                      row[
                                                        row.secondaryKey + "_2"
                                                      ] = $event
                                                    },
                                                    onSelectPreset: function(
                                                      $event
                                                    ) {
                                                      row["presetMeta"] = $event
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e(),
                                      row.componentType == "numberRange"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "parameter-search__secondary-key-container col-9 col-sm-2"
                                              },
                                              [
                                                _c("InputField", {
                                                  attrs: {
                                                    type: "number",
                                                    placeholder: "min.",
                                                    precision: _vm.getPrecision(
                                                      row
                                                    ),
                                                    disabled: row.disabled
                                                  },
                                                  model: {
                                                    value:
                                                      row[
                                                        row.secondaryKey + "_1"
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row,
                                                        row.secondaryKey + "_1",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row[row.secondaryKey + '_1']"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "parameter-search__secondary-key-container col-9 col-sm-2"
                                              },
                                              [
                                                _c("InputField", {
                                                  attrs: {
                                                    type: "number",
                                                    placeholder: "max.",
                                                    precision: _vm.getPrecision(
                                                      row
                                                    ),
                                                    disabled: row.disabled
                                                  },
                                                  model: {
                                                    value:
                                                      row[
                                                        row.secondaryKey + "_2"
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row,
                                                        row.secondaryKey + "_2",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row[row.secondaryKey + '_2']"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e(),
                                      row.componentType == "textRange"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "parameter-search__secondary-key-container col-9 col-sm-2"
                                              },
                                              [
                                                _c("InputField", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: "min.",
                                                    disabled: row.disabled
                                                  },
                                                  model: {
                                                    value:
                                                      row[
                                                        row.secondaryKey + "_1"
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row,
                                                        row.secondaryKey + "_1",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row[row.secondaryKey + '_1']"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "parameter-search__secondary-key-container col-9 col-sm-2"
                                              },
                                              [
                                                _c("InputField", {
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: "max.",
                                                    disabled: row.disabled
                                                  },
                                                  model: {
                                                    value:
                                                      row[
                                                        row.secondaryKey + "_2"
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row,
                                                        row.secondaryKey + "_2",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row[row.secondaryKey + '_2']"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e(),
                                      row.componentType == "doubleCombo"
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "parameter-search__secondary-key-container col-9 col-sm-2"
                                              },
                                              [
                                                _c("ComboBox", {
                                                  attrs: {
                                                    values: _vm.filteredComboOptions(
                                                      row.comboOptions1
                                                    ),
                                                    firstEmpty: false,
                                                    disabled: row.disabled
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.changeCombobox(
                                                        row,
                                                        row.secondaryKey + "_1",
                                                        $event
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      row[
                                                        row.secondaryKey + "_1"
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row,
                                                        row.secondaryKey + "_1",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row[row.secondaryKey + '_1']"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "parameter-search__secondary-key-container col-9 col-sm-2"
                                              },
                                              [
                                                _c("ComboBox", {
                                                  attrs: {
                                                    values: _vm.filteredComboOptions(
                                                      row.comboOptions2
                                                    ),
                                                    firstEmpty: false,
                                                    disabled: row.disabled
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.changeCombobox(
                                                        row,
                                                        row.secondaryKey + "_2",
                                                        $event
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      row[
                                                        row.secondaryKey + "_2"
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        row,
                                                        row.secondaryKey + "_2",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row[row.secondaryKey + '_2']"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "parameter-search__buttons-container col-3 col-sm-2 d-flex align-items-center justify-content-end"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "parameter-controls__container"
                                            },
                                            [
                                              row.isDeniable
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-clear clickable",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          row.filterNot = !row.filterNot
                                                          _vm.focusOnRow(
                                                            _vm.getFilterRows
                                                              .length - 1
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      row.filterNot
                                                        ? _c("ph-prohibit", {
                                                            attrs: {
                                                              size: 16,
                                                              weight: "bold",
                                                              alt:
                                                                "Filter negieren"
                                                            }
                                                          })
                                                        : _c(
                                                            "ph-check-circle",
                                                            {
                                                              attrs: {
                                                                size: 16,
                                                                weight: "bold",
                                                                alt:
                                                                  "Filter bestätigen"
                                                              }
                                                            }
                                                          )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !row.disabled
                                                ? [
                                                    !row.isRequiredFilterGroup &&
                                                    (index > 0 ||
                                                      (_vm.hasSmartSearch &&
                                                        index === 0))
                                                      ? _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-clear clickable",
                                                            attrs: {
                                                              id:
                                                                _vm.filterId +
                                                                "base__filter__minus" +
                                                                index,
                                                              type: "button",
                                                              alt: "Minus"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.removeRow(
                                                                  index
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("ph-minus", {
                                                              attrs: {
                                                                size: 16,
                                                                weight: "bold",
                                                                alt: "Minus"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                : _vm._e(),
                                              !_vm.isSingleFilter
                                                ? _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-clear clickable",
                                                      attrs: {
                                                        id:
                                                          _vm.filterId +
                                                          "base__filter__plus" +
                                                          index,
                                                        type: "button",
                                                        alt: "Plus"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.addRow()
                                                          _vm.focusOnRow(
                                                            _vm.getFilterRows
                                                              .length - 1
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("ph-plus", {
                                                        attrs: {
                                                          size: 16,
                                                          weight: "bold",
                                                          alt: "Plus"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ]
                                  : _c(
                                      "Pill",
                                      {
                                        attrs: { type: "black" },
                                        on: {
                                          click: function($event) {
                                            _vm.setCollapsed(false)
                                            _vm.focusOnRow(index)
                                          }
                                        }
                                      },
                                      [
                                        !_vm.configFilter.hideFirstColumn
                                          ? [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.mapListToKeyValue(
                                                      row.primarySelection
                                                    )[row.key]
                                                  ) + " "
                                                )
                                              ]),
                                              _c("span", [_vm._v("| ")])
                                            ]
                                          : _vm._e(),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.mapListToKeyValue(
                                                _vm.getSecondarySelection(
                                                  row.key,
                                                  index
                                                )
                                              )[row.secondaryKey]
                                            )
                                          )
                                        ]),
                                        row.secondaryKey &&
                                        row.componentType !== "default"
                                          ? [
                                              _c("span", [_vm._v(": ")]),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.rowValue(row))
                                                )
                                              ])
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                              ],
                              2
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                ),
                _c(
                  "BaseButtonsContainer",
                  { staticClass: "action-button__container" },
                  [
                    _vm.extraButton && _vm.extraButton.buttonText
                      ? [
                          _c(
                            "BaseButton",
                            {
                              attrs: {
                                isPrimary: _vm.extraButton.isExtraButtonPrimary,
                                isSecondary: !_vm.extraButton
                                  .isExtraButtonPrimary
                              },
                              on: {
                                click: function($event) {
                                  _vm.$emit(
                                    "extraButtonClick",
                                    _vm.getFilterConfig()
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.extraButton.buttonText) + " "
                              )
                            ]
                          )
                        ]
                      : _vm._e(),
                    _vm._t("default"),
                    _c(
                      "BaseButton",
                      {
                        attrs: { isSecondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.filterZurucksetzen()
                          }
                        }
                      },
                      [_vm._v(" Filter zurücksetzen ")]
                    ),
                    _vm.showSearchButton
                      ? _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.emitFilterEvent()
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.titleSearchButton) + " ")]
                        )
                      : _vm._e(),
                    _vm.additionalActionButtons &&
                    _vm.additionalActionButtons.length
                      ? _c(
                          "BaseDropdownMenu",
                          {
                            staticStyle: { "text-align": "right" },
                            attrs: { placement: "bottom-right" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "hook-target",
                                  fn: function() {
                                    return [
                                      _c(
                                        "a",
                                        { staticClass: "clickable" },
                                        [
                                          _vm._v("Aktionen "),
                                          _c("ph-caret-down", {
                                            attrs: { size: 16 }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              512278594
                            )
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "action-texts" },
                              [
                                _vm._l(_vm.additionalActionButtons, function(
                                  b
                                ) {
                                  return [
                                    b.type == "ACTION"
                                      ? _c("p", { key: b.key }, [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn btn-clean clickable",
                                              attrs: { target: "_blank" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.additionalActionButtonClick(
                                                    b
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(b.label))]
                                          )
                                        ])
                                      : _vm._e(),
                                    b.type == "LINK"
                                      ? _c(
                                          "p",
                                          { key: b.key },
                                          [
                                            _c("DownloadLink", {
                                              attrs: {
                                                title: b.label,
                                                href: _vm.getDownloadButtonUrl(
                                                  b
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ]),
      _vm.$isSmallScreen
        ? _c(
            "BaseModal",
            {
              ref: "filterModal",
              attrs: {
                noPrimaryAction: !_vm.showSearchButton,
                modalTitle: _vm.title,
                showDefaultButtons: false,
                actions: _vm.baseFilterSelectActions
              },
              on: {
                "action-FILTER_SEARCH": _vm.emitFilterEvent,
                "action-FILTER_LIST": _vm.filterBearbeiten,
                "action-FILTER_RESET": _vm.filterZurucksetzen,
                "action-FILTER_SAVE": _vm.emitFilterSpeichernEvent
              }
            },
            [
              _vm.showSaveButton && _vm.hasSearchParameters
                ? _c("BaseFilterSelect", {
                    ref: "baseFilterSelect",
                    staticClass: "filter-row__border",
                    attrs: {
                      showSaveButton: _vm.showSaveButton,
                      storedSearchParameter: _vm.storedSearchParameter,
                      programmaticSearchParameter:
                        _vm.configFilter &&
                        _vm.configFilter.programmaticSearchParameter
                    },
                    on: {
                      onEditFilter: function($event) {
                        return _vm.filterBearbeiten()
                      },
                      onSelect: function($event) {
                        return _vm.selectStoredSearchParameter($event, true)
                      }
                    }
                  })
                : _vm._e(),
              _c("div", { staticClass: "row mt-0" }, [
                _c("div", { staticClass: "col-12 col-md-12 col-sm-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "parameter-search__input-fields__container"
                    },
                    [
                      !_vm.collapsed && _vm.hasSmartSearch
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "row parameter-search__input-fields filter-row__border",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.noop.apply(null, arguments)
                                },
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.emitFilterEvent()
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-9 col-md-9 col-sm-9" },
                                [
                                  _c("InputField", {
                                    ref: "defaultSearchInput",
                                    attrs: {
                                      placeholder:
                                        _vm.configFilter
                                          .placeholderForDefSearchInput
                                    },
                                    model: {
                                      value: _vm.defaultSearchInput,
                                      callback: function($$v) {
                                        _vm.defaultSearchInput = $$v
                                      },
                                      expression: "defaultSearchInput"
                                    }
                                  }),
                                  _vm.defaultSearchInput &&
                                  _vm.filteredOptions &&
                                  _vm.filteredOptions.length
                                    ? _c("div", { staticClass: "dropList" }, [
                                        _c(
                                          "ul",
                                          _vm._l(_vm.filteredOptions, function(
                                            item,
                                            index
                                          ) {
                                            return _c(
                                              "li",
                                              {
                                                key: index,
                                                on: {
                                                  click: function($event) {
                                                    return _vm.selectFilterDropListItem(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.label) + " "
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                1
                              ),
                              !_vm.isSingleFilter
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "parameter-search__buttons-container col-3 col-md-3 col-sm-3 d-flex align-items-center justify-content-end"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "parameter-controls__container"
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-clear clickable",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.enableMoreOptions()
                                                }
                                              }
                                            },
                                            [
                                              _c("ph-plus", {
                                                attrs: {
                                                  size: 16,
                                                  weight: "bold"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      _vm.showMoreOptions ||
                      !_vm.hasSmartSearch ||
                      Object.keys(_vm.defaultOptions).length
                        ? _c(
                            "div",
                            {
                              ref: "moreOptionsEl",
                              staticClass:
                                "parameter-search__input-fields--more-options",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.noop.apply(null, arguments)
                                },
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.emitFilterEvent()
                                }
                              }
                            },
                            _vm._l(_vm.getFilterRows, function(row, index) {
                              return _c(
                                "div",
                                {
                                  key: row.secondaryKey + "-" + index,
                                  staticClass:
                                    "row parameter-search__input-fields filter-row__border"
                                },
                                [
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row parameter-search__input-fields col-9",
                                        staticStyle: {
                                          "flex-wrap": "wrap",
                                          padding: "0"
                                        }
                                      },
                                      [
                                        _vm.showAlwaysPrimaryselection ||
                                        (row.primarySelection &&
                                          row.primarySelection.length > 1)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "col-12 col-sm-3",
                                                class: {
                                                  "d-none":
                                                    _vm.configFilter
                                                      .hideFirstColumn
                                                }
                                              },
                                              [
                                                _c("ComboBox", {
                                                  attrs: {
                                                    values:
                                                      row.primarySelection,
                                                    disabled:
                                                      row.disabled ||
                                                      row.isRequiredFilterGroup
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.onChangePrimary(
                                                        row,
                                                        $event
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value: row.key,
                                                    callback: function($$v) {
                                                      _vm.$set(row, "key", $$v)
                                                    },
                                                    expression: "row.key"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-12",
                                            class: {
                                              "col-sm-3": !_vm.configFilter
                                                .hideFirstColumn,
                                              "col-sm-6":
                                                _vm.configFilter
                                                  .hideFirstColumn,
                                              "parameter-search__component-default":
                                                !row.componentType ||
                                                row.componentType === "default"
                                            }
                                          },
                                          [
                                            _c("ComboBox", {
                                              attrs: {
                                                values: _vm.getSecondarySelection(
                                                  row.key,
                                                  index
                                                ),
                                                disabled: row.disabled
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.onChange(
                                                    row,
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: row.secondaryKey,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    row,
                                                    "secondaryKey",
                                                    $$v
                                                  )
                                                },
                                                expression: "row.secondaryKey"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        row.componentType &&
                                        row.componentType !== "dateRange" &&
                                        row.componentType !== "numberRange" &&
                                        row.componentType !== "textRange" &&
                                        row.componentType !== "default" &&
                                        row.componentType !== "doubleCombo"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  key: row.__UPDATED_KEY__,
                                                  staticClass:
                                                    "parameter-search__secondary-key-container col-12 col-sm-6"
                                                },
                                                [
                                                  row.componentType == "text"
                                                    ? _c("InputField", {
                                                        attrs: {
                                                          disabled:
                                                            row.disabled,
                                                          inputmode: _vm.getComponentInputmode(
                                                            row.key,
                                                            row.secondaryKey
                                                          )
                                                        },
                                                        model: {
                                                          value:
                                                            row[
                                                              row.secondaryKey
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              row.secondaryKey,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row[row.secondaryKey]"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  row.componentType == "integer"
                                                    ? _c("InputField", {
                                                        attrs: {
                                                          value:
                                                            row[
                                                              row.secondaryKey
                                                            ],
                                                          type: "number",
                                                          isInteger: "",
                                                          disabled: row.disabled
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.setValueChanged(
                                                              row,
                                                              row.secondaryKey,
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  row.componentType == "tel"
                                                    ? _c("InputField", {
                                                        attrs: {
                                                          value:
                                                            row[
                                                              row.secondaryKey
                                                            ],
                                                          type: "tel",
                                                          disabled: row.disabled
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.setValueChanged(
                                                              row,
                                                              row.secondaryKey,
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  row.componentType ==
                                                  "datepicker"
                                                    ? _c("DatePickerField", {
                                                        attrs: {
                                                          disabled: row.disabled
                                                        },
                                                        model: {
                                                          value:
                                                            row[
                                                              row.secondaryKey
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              row.secondaryKey,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row[row.secondaryKey]"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  row.componentType ==
                                                  "switcher"
                                                    ? _c("InputToggleSwitch", {
                                                        staticClass:
                                                          "parameter-search__input-toggle-switch",
                                                        attrs: {
                                                          disabled: row.disabled
                                                        },
                                                        model: {
                                                          value:
                                                            row[
                                                              row.secondaryKey
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              row.secondaryKey,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row[row.secondaryKey]"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  row.componentType ===
                                                  "multipleItemSelector"
                                                    ? _c(
                                                        "InputMultipleItemSelector",
                                                        {
                                                          attrs: {
                                                            value:
                                                              row[
                                                                row.secondaryKey
                                                              ],
                                                            values:
                                                              row.comboOptions,
                                                            disabled:
                                                              row.disabled
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              return _vm.updateMultipleSelection(
                                                                row,
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        }
                                                      )
                                                    : _vm._e(),
                                                  row.componentType === "chips"
                                                    ? _c("InputChips", {
                                                        staticClass: "p-0",
                                                        attrs: {
                                                          showToggle: true,
                                                          uniqueSelection:
                                                            row.uniqueSelection,
                                                          availableOptions:
                                                            row.comboOptions
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            return _vm.updateMultipleSelection(
                                                              row,
                                                              $event
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            row[
                                                              row.secondaryKey
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              row.secondaryKey,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row[row.secondaryKey]"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  row.secondaryItem &&
                                                  row.secondaryItem.parentValue.includes(
                                                    row[row.secondaryKey]
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row mt-0 mb-0"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-6 col-sm-6"
                                                            },
                                                            [
                                                              row.componentType ==
                                                              "combobox"
                                                                ? _c(
                                                                    "ComboBox",
                                                                    {
                                                                      attrs: {
                                                                        values: _vm.filteredComboOptions(
                                                                          row.comboOptions
                                                                        ),
                                                                        firstEmpty: false,
                                                                        disabled:
                                                                          row.disabled
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.changeCombobox(
                                                                            row,
                                                                            row.secondaryKey,
                                                                            $event
                                                                          )
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          row[
                                                                            row
                                                                              .secondaryKey
                                                                          ],
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            row,
                                                                            row.secondaryKey,
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "row[row.secondaryKey]"
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-6 col-sm-6"
                                                            },
                                                            [
                                                              row.secondaryItem
                                                                .type ==
                                                              "combobox"
                                                                ? _c(
                                                                    "ComboBox",
                                                                    {
                                                                      attrs: {
                                                                        values: _vm.filteredComboOptions(
                                                                          row
                                                                            .secondaryItem
                                                                            .comboOptions
                                                                        ),
                                                                        firstEmpty: false,
                                                                        disabled:
                                                                          row.disabled
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          row[
                                                                            row
                                                                              .secondaryItem
                                                                              .key
                                                                          ],
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            row,
                                                                            row
                                                                              .secondaryItem
                                                                              .key,
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "row[row.secondaryItem.key]"
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              row.secondaryItem
                                                                .type == "text"
                                                                ? _c(
                                                                    "InputField",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          row.disabled
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          row[
                                                                            row
                                                                              .secondaryItem
                                                                              .key
                                                                          ],
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            row,
                                                                            row
                                                                              .secondaryItem
                                                                              .key,
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "row[row.secondaryItem.key]"
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    : row.componentType ==
                                                      "combobox"
                                                    ? _c(
                                                        "div",
                                                        [
                                                          row.componentType ==
                                                          "combobox"
                                                            ? _c("ComboBox", {
                                                                attrs: {
                                                                  values: _vm.filteredComboOptions(
                                                                    _vm.getComboboxOptions(
                                                                      row.key,
                                                                      row.secondaryKey
                                                                    )
                                                                  ),
                                                                  firstEmpty: false,
                                                                  disabled:
                                                                    row.disabled
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.changeCombobox(
                                                                      row,
                                                                      row.secondaryKey,
                                                                      $event
                                                                    )
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    row[
                                                                      row
                                                                        .secondaryKey
                                                                    ],
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      row,
                                                                      row.secondaryKey,
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "row[row.secondaryKey]"
                                                                }
                                                              })
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  row.componentType ==
                                                  "splitedDatePicker"
                                                    ? _c("SplitedDatePicker", {
                                                        attrs: {
                                                          disabled: row.disabled
                                                        },
                                                        model: {
                                                          value:
                                                            row[
                                                              row.secondaryKey
                                                            ],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              row.secondaryKey,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row[row.secondaryKey]"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e(),
                                        row.componentType == "dateRange"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "parameter-search__secondary-key-container col-12 col-sm-6"
                                                },
                                                [
                                                  _c("DatePickerField", {
                                                    attrs: {
                                                      isRangePicker: "",
                                                      disabled: row.disabled,
                                                      value: _vm.getDateRangeValue(
                                                        row
                                                      )
                                                    },
                                                    on: {
                                                      onSelectRangeFromAsText: function(
                                                        $event
                                                      ) {
                                                        row[
                                                          row.secondaryKey +
                                                            "_1"
                                                        ] = $event
                                                      },
                                                      onSelectRangeToAsText: function(
                                                        $event
                                                      ) {
                                                        row[
                                                          row.secondaryKey +
                                                            "_2"
                                                        ] = $event
                                                      },
                                                      onSelectPreset: function(
                                                        $event
                                                      ) {
                                                        row[
                                                          "presetMeta"
                                                        ] = $event
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e(),
                                        row.componentType == "numberRange"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "parameter-search__secondary-key-container col-12 col-sm-3"
                                                },
                                                [
                                                  _c("InputField", {
                                                    attrs: {
                                                      type: "number",
                                                      placeholder: "min.",
                                                      precision: _vm.getPrecision(
                                                        row
                                                      ),
                                                      disabled: row.disabled
                                                    },
                                                    model: {
                                                      value:
                                                        row[
                                                          row.secondaryKey +
                                                            "_1"
                                                        ],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          row.secondaryKey +
                                                            "_1",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row[row.secondaryKey + '_1']"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "parameter-search__secondary-key-container col-12 col-sm-3"
                                                },
                                                [
                                                  _c("InputField", {
                                                    attrs: {
                                                      type: "number",
                                                      placeholder: "max.",
                                                      precision: _vm.getPrecision(
                                                        row
                                                      ),
                                                      disabled: row.disabled
                                                    },
                                                    model: {
                                                      value:
                                                        row[
                                                          row.secondaryKey +
                                                            "_2"
                                                        ],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          row.secondaryKey +
                                                            "_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row[row.secondaryKey + '_2']"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e(),
                                        row.componentType == "textRange"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "parameter-search__secondary-key-container col-12 col-sm-3"
                                                },
                                                [
                                                  _c("InputField", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: "min.",
                                                      disabled: row.disabled
                                                    },
                                                    model: {
                                                      value:
                                                        row[
                                                          row.secondaryKey +
                                                            "_1"
                                                        ],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          row.secondaryKey +
                                                            "_1",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row[row.secondaryKey + '_1']"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "parameter-search__secondary-key-container col-12 col-sm-3"
                                                },
                                                [
                                                  _c("InputField", {
                                                    attrs: {
                                                      type: "text",
                                                      placeholder: "max.",
                                                      disabled: row.disabled
                                                    },
                                                    model: {
                                                      value:
                                                        row[
                                                          row.secondaryKey +
                                                            "_2"
                                                        ],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          row.secondaryKey +
                                                            "_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row[row.secondaryKey + '_2']"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e(),
                                        row.componentType == "doubleCombo"
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "parameter-search__secondary-key-container col-12 col-sm-3"
                                                },
                                                [
                                                  _c("ComboBox", {
                                                    attrs: {
                                                      values: _vm.filteredComboOptions(
                                                        row.comboOptions1
                                                      ),
                                                      firstEmpty: false,
                                                      disabled: row.disabled
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.changeCombobox(
                                                          row,
                                                          row.secondaryKey +
                                                            "_1",
                                                          $event
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        row[
                                                          row.secondaryKey +
                                                            "_1"
                                                        ],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          row.secondaryKey +
                                                            "_1",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row[row.secondaryKey + '_1']"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "parameter-search__secondary-key-container col-12 col-sm-3"
                                                },
                                                [
                                                  _c("ComboBox", {
                                                    attrs: {
                                                      values: _vm.filteredComboOptions(
                                                        row.comboOptions2
                                                      ),
                                                      firstEmpty: false,
                                                      disabled: row.disabled
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.changeCombobox(
                                                          row,
                                                          row.secondaryKey +
                                                            "_2",
                                                          $event
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        row[
                                                          row.secondaryKey +
                                                            "_2"
                                                        ],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          row.secondaryKey +
                                                            "_2",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row[row.secondaryKey + '_2']"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "parameter-search__buttons-container col-3 col-sm-3 d-flex align-items-center justify-content-end",
                                        staticStyle: {
                                          "align-self": "baseline",
                                          "margin-top": "4px"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "parameter-controls__container"
                                          },
                                          [
                                            row.isDeniable
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-clear clickable",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        row.filterNot = !row.filterNot
                                                        _vm.focusOnRow(
                                                          _vm.getFilterRows
                                                            .length - 1
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    row.filterNot
                                                      ? _c("ph-prohibit", {
                                                          attrs: {
                                                            size: 16,
                                                            weight: "bold"
                                                          }
                                                        })
                                                      : _c("ph-check-circle", {
                                                          attrs: {
                                                            size: 16,
                                                            weight: "bold"
                                                          }
                                                        })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            !row.disabled
                                              ? [
                                                  !row.isRequiredFilterGroup &&
                                                  (index > 0 ||
                                                    (_vm.hasSmartSearch &&
                                                      index === 0))
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn btn-clear clickable",
                                                          attrs: {
                                                            type: "button"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.removeRow(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("ph-minus", {
                                                            attrs: {
                                                              size: 16,
                                                              weight: "bold"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              : _vm._e(),
                                            !_vm.isSingleFilter
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-clear clickable",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.addRow()
                                                        _vm.focusOnRow(
                                                          _vm.getFilterRows
                                                            .length - 1
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("ph-plus", {
                                                      attrs: {
                                                        size: 16,
                                                        weight: "bold"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c("BaseFilterSave", {
        ref: "filterSaveModal",
        attrs: {
          storedSearchParameter: _vm.storedSearchParameter,
          filterSaveKey: _vm.filterId
        }
      }),
      _c("BaseModal", {
        ref: "filterSpeichernModal",
        attrs: { modalTitle: "Filter speichern", size: "sm" },
        on: { onConfirmButton: _vm.saveSearchEdit },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("InputField", {
                  attrs: { label: "Filter Name", type: "text" },
                  model: {
                    value: _vm.newFilterName,
                    callback: function($$v) {
                      _vm.newFilterName = $$v
                    },
                    expression: "newFilterName"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }