var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DynamicModal",
    {
      ref: "resizablePopup",
      staticStyle: { display: "flex", "flex-direction": "column" },
      attrs: {
        modalTitle: _vm.title,
        initialWidth: _vm.initialWidth,
        initialHeight: _vm.initialHeight,
        sizeIsPercentage: _vm.sizeIsPercentage,
        startPosition: _vm.startPosition,
        fullClientHeight: _vm.fullClientHeight,
        saveLastPosition: _vm.saveLastPosition,
        id: _vm.id,
        withBackdropLayer: "",
        actions: _vm.actions
      },
      on: {
        onMinimize: _vm.resizePDFContainer,
        onMaximize: _vm.resizePDFContainer,
        onResize: _vm.resizePDFContainer,
        onClose: _vm.closeViewer,
        "action-SAVE": _vm.saveDocument,
        "action-FINISH": _vm.finishDocument,
        "action-PAGE_OVERVIEW": _vm.showThumbnails,
        "action-ADD_SIGNATURE": _vm.addSignatureField
      },
      scopedSlots: _vm._u([
        {
          key: "toolbar-buttons",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "flex justify-between  items-center w-full " },
                [
                  _c(
                    "div",
                    { staticClass: "flex justify-between items-center gap-1" },
                    [
                      !_vm.$isSmallScreen
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { arrow: true, touch: false },
                                  expression: "{ arrow: true, touch: false }"
                                }
                              ],
                              staticClass: "icon-button",
                              attrs: { content: "Seiten" },
                              on: {
                                click: function($event) {
                                  return _vm.showThumbnails()
                                }
                              }
                            },
                            [_c("ph-squares-four", { attrs: { size: 26 } })],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tippy",
                              rawName: "v-tippy",
                              value: { arrow: true, touch: false },
                              expression: "{ arrow: true, touch: false }"
                            }
                          ],
                          staticClass: "icon-button relative",
                          attrs: { content: "Unterschriften" },
                          on: {
                            click: function($event) {
                              return _vm.showSummarySignaturesDialog()
                            }
                          }
                        },
                        [
                          _c("ph-pencil-simple", { attrs: { size: 26 } }),
                          _c("div", { staticClass: "bubble" }, [
                            _vm._v(_vm._s(_vm.missingSignatureFields))
                          ])
                        ],
                        1
                      ),
                      _vm.isMakler && !_vm.$isSmallScreen
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { arrow: true, touch: false },
                                  expression: "{ arrow: true, touch: false }"
                                }
                              ],
                              staticClass: "icon-button",
                              attrs: { content: "Signaturfeld hinzufügen" },
                              on: {
                                click: function($event) {
                                  return _vm.addSignatureField()
                                }
                              }
                            },
                            [
                              _c("PhAddSignature", {
                                attrs: { color: "white", size: 26 }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex justify-between items-center" },
                    [
                      _c("div", [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tippy",
                                rawName: "v-tippy",
                                value: { arrow: true, touch: false },
                                expression: "{ arrow: true, touch: false }"
                              }
                            ],
                            staticClass: "icon-button",
                            attrs: {
                              disabled: !_vm.previousPage,
                              content: "Vorherige Seite"
                            },
                            on: {
                              click: function($event) {
                                return _vm.showPrevPage()
                              }
                            }
                          },
                          [_c("ph-caret-left", { attrs: { size: 26 } })],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "mx" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.currentPage) +
                              " / " +
                              _vm._s(_vm.session.pages)
                          )
                        ])
                      ]),
                      _c("div", [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tippy",
                                rawName: "v-tippy",
                                value: { arrow: true, touch: false },
                                expression: "{ arrow: true, touch: false }"
                              }
                            ],
                            staticClass: "icon-button",
                            attrs: {
                              disabled: !_vm.nextPage,
                              content: "Nächste Seite"
                            },
                            on: {
                              click: function($event) {
                                return _vm.showNextPage()
                              }
                            }
                          },
                          [_c("ph-caret-right", { attrs: { size: 26 } })],
                          1
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex justify-between items-center gap-1" },
                    [
                      _vm.fit != "screenWidth"
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { arrow: true, touch: false },
                                  expression: "{ arrow: true, touch: false }"
                                }
                              ],
                              staticClass: "icon-button",
                              attrs: { content: "An Breite anpassen" },
                              on: {
                                click: function($event) {
                                  return _vm.fitPageToScreenWidth()
                                }
                              }
                            },
                            [
                              _c("ph-arrows-out-line-horizontal", {
                                attrs: { size: 26 }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.fit != "screenHeight"
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { arrow: true, touch: false },
                                  expression: "{ arrow: true, touch: false }"
                                }
                              ],
                              staticClass: "icon-button",
                              attrs: { content: "An Höhe anpassen" },
                              on: {
                                click: function($event) {
                                  return _vm.fitPageToScreenHeight()
                                }
                              }
                            },
                            [
                              _c("ph-arrows-out-line-vertical", {
                                attrs: { size: 26 }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.$isSmallScreen
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { arrow: true, touch: false },
                                  expression: "{ arrow: true, touch: false }"
                                }
                              ],
                              staticClass: "icon-button",
                              attrs: {
                                disabled: !_vm.saveButton,
                                content: "Speichern"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveDocument()
                                }
                              }
                            },
                            [_c("ph-floppy-disk", { attrs: { size: 26 } })],
                            1
                          )
                        : _vm._e(),
                      !_vm.$isSmallScreen &&
                      _vm.isAbschliessenButtonVisible(_vm.elem)
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tippy",
                                  rawName: "v-tippy",
                                  value: { arrow: true, touch: false },
                                  expression: "{ arrow: true, touch: false }"
                                }
                              ],
                              staticClass: "icon-button",
                              attrs: { content: "Speichern & Abschließen" },
                              on: {
                                click: function($event) {
                                  return _vm.finishDocument()
                                }
                              }
                            },
                            [_c("ph-file-lock", { attrs: { size: 26 } })],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("div", { ref: "objectContainer", staticClass: "object-container" }, [
        _c("iframe", {
          ref: "frame",
          staticClass: "signo_viewer",
          attrs: { src: _vm.session.uri, width: "100%", height: "100%" },
          on: { load: _vm.registerEventListeners }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }