export const MUTATION_PREFIX = 'STECKBRIEF: ';
export const ACTIONS_PREFIX = 'STECKBRIEF_ACTIONS_';
export const GETTERS_PREFIX = 'STECKBRIEF_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    GET_STECKBRIEF_CUSTOMER_INFO: MUTATION_PREFIX + 'STECKBRIEF_CUSTOMER_INFO',
    GET_TODO_VIDEO_INDENT: MUTATION_PREFIX + 'GET_TODO_VIDEO_INDENT',
    SET_TODO_VIDEO_INDENT_COUNT: MUTATION_PREFIX + 'SET_TODO_VIDEO_INDENT_COUNT',
    GET_DEPOTPOSITIONS: MUTATION_PREFIX + 'GET_DEPOTPOSITIONS',
    GET_INVESTMENT_BESTAND: MUTATION_PREFIX + 'GET_INVESTMENT_BESTAND',
    GET_MULTIBANKING_DATA: MUTATION_PREFIX + 'GET_MULTIBANKING_DATA',
    SIMPLY_CHART_GV: MUTATION_PREFIX + 'SIMPLY_CHART_GV',
    SET_FREISTELLUNGSANTRAG_SUBTOTALS: MUTATION_PREFIX + 'SET_FREISTELLUNGSANTRAG_SUBTOTALS',
  },
  ACTIONS: {
    GET_STECKBRIEF_CUSTOMER_INFO: ACTIONS_PREFIX + 'GET_STECKBRIEF_CUSTOMER_INFO',
    GET_TODO_VIDEO_INDENT: ACTIONS_PREFIX + 'GET_TODO_VIDEO_INDENT',
    GET_TODO_VIDEO_INDENT_COUNT: ACTIONS_PREFIX + 'GET_TODO_VIDEO_INDENT_COUNT',
    GET_DEPOTPOSITIONS: ACTIONS_PREFIX + 'GET_DEPOTPOSITIONS',
    GET_INVESTMENT_BESTAND: ACTIONS_PREFIX + 'GET_INVESTMENT_BESTAND',
    GET_MULTIBANKING_DATA: ACTIONS_PREFIX + 'GET_MULTIBANKING_DATA',
    SIMPLY_CHART_GV: ACTIONS_PREFIX + 'SIMPLY_CHART_GV',
    RETRIEVE_FREISTELLUNGSANTRAG_SUBTOTALS: ACTIONS_PREFIX + 'RETRIEVE_FREISTELLUNGSANTRAG_SUBTOTALS',
    SET_DEPOT_SERVICE_GEB: ACTIONS_PREFIX + 'SET_DEPOT_SERVICE_GEB',
  },
  GETTERS: {
    STECKBRIEF_CUSTOMER_INFO: GETTERS_PREFIX + 'STECKBRIEF_CUSTOMER_INFO',
    STECKBRIEF_BERATUNG_VERMITTLER: GETTERS_PREFIX + 'STECKBRIEF_BERATUNG_VERMITTLER',
    TODO_VIDEO_INDENT: GETTERS_PREFIX + 'TODO_VIDEO_INDENT',
    TODO_VIDEO_INDENT_COUNT: GETTERS_PREFIX + 'TODO_VIDEO_INDENT_COUNT',
    HAS_VIDEO_INDENT: GETTERS_PREFIX + 'HAS_VIDEO_INDENT',
    GET_DEPOTPOSITIONS: GETTERS_PREFIX + 'GET_DEPOTPOSITIONS',
    GET_INVESTMENT_BESTAND: GETTERS_PREFIX + 'GET_INVESTMENT_BESTAND',
    GET_MULTIBANKING_DATA: GETTERS_PREFIX + 'GET_MULTIBANKING_DATA',
    SIMPLY_CHART_GV: GETTERS_PREFIX + 'SIMPLY_CHART_GV',
    GET_SIGNO_DOCUMENT_PREVIEW_LINK_MAKER: GETTERS_PREFIX + 'GET_SIGNO_DOCUMENT_PREVIEW_LINK_MAKER',
    FREISTELLUNGSANTRAG_SUBTOTALS: GETTERS_PREFIX + 'FREISTELLUNGSANTRAG_SUBTOTALS',
  }
}