var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showLogout
    ? _c(
        "BaseButton",
        { attrs: { isClear: "" }, on: { click: _vm.logout } },
        [
          _c("ph-power", {
            attrs: { size: _vm.size, alt: _vm.ariaDescription }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }