<template>
  <SortableList 
    class="menu-structure-sortable__container" 
    :items="menus" 
    :disabled="disabled" 
    @orderChanged="$emit('orderChanged', { parent, menus: $event, })" 
  >
    <ul class="menu-list__container" data-sortable-container>
      <template v-for="(menu, index) in menus">
        <li :key="menu.id" data-sortable-item 
          class="menu-list--item"
          :class="{ 
            'group-menu': isGroupMenu(menu), 
            'custom-menu': isCustomGroupMenu(menu), 
            'async-menu': isAsyncMenu(menu),
          }"
        >
          <div class="menu-list--item__container">
            <div class="menu-list--item__container-wrap">
              <div v-if="!isPrimaryMenu(menu) && !disabled" class="menu-list--item__actions">
                <button type="button" class="btn-clear" data-sortable-item-handler><PhDotsNine :size="16" /></button>
              </div>
              <div class="menu-list--item__content">
                <div class="menu-list--item__position">{{ index + 1 }}.</div>
                <div v-if="menu.component && isNonTabMenu(menu)" class="menu-list--item__icon">
                  <component :is="menu.component" v-bind="menu.componentProps" />
                </div>
                <span class="menu-list--item__label" v-html="$sanitize(menu.label)" />
              </div>
              <div v-if="!disabled" class="menu-list--item__actions">
                <AddOptionMenuDropdown 
                  v-if="isGroupMenu(menu)" 
                  :parent="menu" 
                  @add="$emit('add', $event)" 
                  @addCustom="$emit('addCustom', $event)" 
                >
                  <button type="button" class="btn-clear clickable"><PhPlus :size="16" /></button>
                </AddOptionMenuDropdown>
                <button v-if="!isPrimaryMenu(menu) && isNonTabMenu(menu)" type="button" class="btn-clear clickable" @click="$emit('remove', { parent, menu, })"><PhTrashSimple :size="16" /></button>
              </div>
            </div>
          </div>
          <MenuStructureSortable 
            v-if="menu.subMenu && menu.subMenu.length > 0"
            :parent="menu" 
            :menus="menu.subMenu"
            :disabled="disabled"
            @add="$emit('add', $event)" 
            @remove="$emit('remove', $event)" 
            @addCustom="$emit('addCustom', $event)" 
            @orderChanged="$emit('orderChanged', $event)"
          />
        </li>
      </template>
    </ul>

    <div v-if="!disabled && !parent && hasAvailablePrimary" class="menu-list__add-new">
      <AddOptionMenuDropdown  :parent="parent" @add="$emit('add', $event)" @addCustom="$emit('addCustom', $event)">
        <button type="button" class="btn-clear clickable"><PhPlus :size="16" /> neues hinzufügen</button>
      </AddOptionMenuDropdown>
    </div>
  </SortableList>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_STRUCTURE_TYPES from '@/store/menuStructure/types';

import { PhPlus, PhTrashSimple, PhDotsNine } from 'phosphor-vue';
import SortableList from '@/components/core/SortableList.vue';
import AddOptionMenuDropdown from '@/components/core/menu/menuStructure/optionMenuCustom/AddOptionMenuDropdown.vue';

import { MenuType } from '@/menu/menu-utils';

export default {
  name: 'MenuStructureSortable',
  components: { 
    PhPlus,
    PhTrashSimple,
    PhDotsNine,
    SortableList,
    AddOptionMenuDropdown,
  },
  props: {
    parent: {
      type: Object,
      default: () => null,
    },
    menus: { 
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      menuStructureContext: MENU_STRUCTURE_TYPES.GETTERS.MENU_STRUCTURE_CONTEXT,
    }),
    hasAvailablePrimary() {
      return this.menuStructureContext?.availablePrimaryMenu?.length > 0;
    },
  },
  methods: {
    isPrimaryMenu(menu) {
      return menu?.type === MenuType.PRIMARY_MENU;
    },
    isGroupMenu(menu) {
      return [MenuType.PRIMARY_MENU, MenuType.GROUP_MENU, MenuType.CUSTOM_GROUP_MENU].indexOf(menu?.type) >= 0;
    },
    isCustomGroupMenu(menu) {
      return menu?.type === MenuType.CUSTOM_GROUP_MENU;
    },
    isAsyncMenu(menu) {
      return menu?.type === MenuType.ASYNC_MENU;
    },
    isTabMenu(menu) {
      return menu?.type === MenuType.TAB_MENU;
    },
    isNonTabMenu(menu) {
      return !this.isTabMenu(menu);
    },
  },
}
</script>

<style scoped lang="scss">
.menu-structure-sortable__container {
  --ms-sortable-background: #fafafa;
  --ms-sortable-border: #ccc;
}

@media (prefers-color-scheme: dark) {
  .menu-structure-sortable__container {
    --ms-sortable-background: var(--color-box);
    --ms-sortable-border: var(--color-background);
  }
}

.menu-list__container {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-list--item {
  list-style: none;
  font-size: 0.75rem;
  line-height: 1.2em;
  width: fit-content;

  &.group-menu {
    > .menu-list--item__container {
      background: var(--ms-sortable-border);
    }
  }

  &.custom-menu {
    > .menu-list--item__container {
      background: var(--color-primary);
      border-color: var(--color-primary);

      * {
        color: var(--color-text-neutral);
      }
    }
  }

  &.async-menu {
    > .menu-list--item__container {
      background: var(--color-warning);
      border-color: var(--color-warning);

      * {
        color: var(--color-text-neutral);
      }
    }
  }

  &.add-new-menu {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  ul,
  .menu-list__add-new {
    padding: 0 0 0 2rem;
  }

  > .menu-list--item__container {
    background: var(--ms-sortable-background);
    border-radius: 4px;
    border: 1px solid var(--ms-sortable-border);
    display: inline-block;
    margin: 1px 0;
    padding: 4px 8px;

    .menu-list--item__container-wrap {
      display: flex;
      align-items: center;
    }

    .menu-list--item__content {
      display: flex;
      align-items: center;

      .menu-list--item__position {
        font-size: .625rem;
        margin: 0 .375rem 0 0;
      }

      .menu-list--item__icon {
        margin: 0 .25rem 0 0;

        > * {
          width: 1.125rem;
          height: auto;
        }

        > .base-image__container {
          height: 1.125rem;
        }
      }
    }

    .menu-list--item__actions {
      display: flex;
      margin: -2px 0 -2px;

      &:first-child {
        margin-right: .25rem;
      }

      &:last-child {
        margin-left: .25rem;
      }

      > * {
        margin: 0 .125rem;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.menu-list__add-new {
  font-size: 0.75rem;
  line-height: 1.2em;
  margin: .375rem 0;
}
</style>
