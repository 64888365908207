var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.title,
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.optionsMenu,
          showBackButton: false
        }
      }),
      _vm.loading
        ? _c("GhostLoading", {
            attrs: {
              type: "block",
              config: { block: { height: 450 } },
              repeat: 4,
              inline: true
            }
          })
        : Object.keys(_vm.signatures).length > 0
        ? _c(
            "div",
            { staticClass: "cards__container layout__negative-margin--8" },
            _vm._l(_vm.signaturesForAuthor, function(signature, fileId) {
              return _c(
                "div",
                {
                  key: signature.title,
                  staticClass: "box__container cards__item"
                },
                [
                  _c("div", { staticClass: "cards__item-header" }, [
                    _c(
                      "div",
                      { staticClass: "box__title breakable-words" },
                      [
                        _c("DownloadLink", {
                          attrs: {
                            title: signature.title,
                            downloadServicePath: "/get_simple_file",
                            filename: signature.title,
                            queryParams: { fileId: fileId }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  signature.img
                    ? _c("div", [
                        _c("img", {
                          staticClass: "signature-img",
                          attrs: { src: signature.img }
                        })
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "action-button-list mt-3" },
                    [
                      _c("InputToggleSwitch", {
                        attrs: { label: "Abgelehnt" },
                        on: {
                          input: function($event) {
                            return _vm.onChangeSignatureAbgelehnt(
                              $event,
                              signature.title,
                              fileId
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            0
          )
        : _c("div", [_c("NoData")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }