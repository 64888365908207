var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.contracts
      ? _c("div", [_c("span", [_vm._v("Keine Daten")])])
      : _c(
          "div",
          [
            _c("Table", {
              attrs: {
                headers: _vm.headers,
                rows: _vm.rows,
                rowsPerPage: 10,
                mobileConfig: {
                  title: "accountDisplayName",
                  headers: ["categoryDisplayName", "amount"]
                }
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }