import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    fundsDetail : null,
    allgemeinDescription: null,
    ausschuttung : null,
    steuerlichesErgebnis : null,
    erbschaftssteuerlicheWerte : null,
    einzahlungen : null,
    mittelherkunft : null,
    investitionskosten : null,
    dokumente : null,
    performance: null,
    renditeChart: null,
    risiko: null,
    unterlagen: null,
    riskChart: null,
    performanceChart: null,
    montecarloChart: null,
    unterlagenLinks: null,
    purchase: null,
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}