import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import INSURANCE_BUCHUNG_TYPES from './types';
import axios from 'axios';
import { buildMessage } from '../../helpers/log-message-helper';

const config = {
  defaultSpinner: true
};

export default {
  [INSURANCE_BUCHUNG_TYPES.ACTIONS.BUCHUNG_FIELDS_DEFINITIONS]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurancesBuchungen/buchungFieldsDefinition`;

      let params = {};

      if(payload?.vertragId) {
        params.vertragId = payload.vertragId;
      }

      axios.get(url, {params, config}).then(response => {
        commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.BUCHUNG_FIELDS_DEFINITIONS_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  [INSURANCE_BUCHUNG_TYPES.ACTIONS.BUCHUNG_BY_ID]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurancesBuchungen/buchungById`;

      let params = {};

      if(payload?.buchungId) {
        params.buchungId = payload.buchungId;
      }

      axios.get(url, {params, config}).then(response => {
        resolve(response?.data || null);
      }).catch(error => {
        reject(error);
      });
    });
  },
  
  async [INSURANCE_BUCHUNG_TYPES.ACTIONS.SAVE_BUCHUNG]({ getters, commit, dispatch }, payload) {

    const URL = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurancesBuchungen/saveInsuranceBuchung`;
    const response = await axios.post(URL, payload, {params: {vertragId: payload.vertragId}}, config);

    if(response?.data){
      commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.SAVE_BUCHUNG_SUCCESS, response.data)

      if (response?.data?.information) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.information, 'info'));
      }
      if (response?.data?.fehlermeldung) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.fehlermeldung, 'danger'));
      }        
    }

    return response?.data;

  },
  
  async [INSURANCE_BUCHUNG_TYPES.ACTIONS.UPDATE_BUCHUNG]({commit, getters, dispatch }, payload) {
    
    const URL = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurancesBuchungen/updateInsuranceBuchung`;
    const response = await axios.put(URL, payload, {params: {vertragId: payload.vertragId}}, config);

    if(response?.data){
      commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.UPDATE_BUCHUNG_SUCCESS, response.data)

      if (response?.data?.information) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.information, 'info'));
      }
      if (response?.data?.fehlermeldung) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.fehlermeldung, 'danger'));
      }        
    }

    return response?.data;
  },

  [INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurancesBuchungen/findAllBuchungVerteilungStruktur`;
  
      let params = {};
  
      if(payload?.buchungId) {
        params.buchungId = payload.buchungId;
      }
  
      axios.get(url, {params, config}).then(response => {
        commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_SUCCESS, response.data)
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  [INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_DELETE]({ commit, getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      
      const URL = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurances/insuranceBuchungVerteilungStruktur`;
      
      let params = {};
      
      if(payload?.buchungsnr) {
        params.buchungsnr = payload.buchungsnr;
      }
      if(payload?.lfdNr) {
        params.lfdNr= payload.lfdNr
      }

      axios.delete(URL, {params}, config)
      .then(response => {
        if (response && response.data) {
          commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_DELETE_SUCCESS, response.data);
          resolve();
        }
      }).catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data.message, 'danger'));
        reject(error);
      });
    });
  },

  [INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_SAVE]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const URL = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurancesVerteilungStruktur/saveVerteilungStruktur`;
      axios.post(URL, payload, config)
      .then((response) => {
        commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_SUCCESS, response.data)
        resolve(response?.data);
      })
      .catch((error) => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
        reject(error);
      });
    });
  },
  
  [INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_UPDATE]({commit, getters, dispatch }, payload) {
    const URL = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/insurancesVerteilungStruktur/updateVerteilungStruktur`;
    return axios.put(URL, payload, config)
    .then(response => {
      if(response?.data){
        commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_SUCCESS, response.data)
      }
      return response.data
    })
    .catch((error) => {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      reject(error);
    });
  },
}