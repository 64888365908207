var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.actions
        },
        on: { "action-CREATE": _vm.openCreateBeratungsmappeModal }
      }),
      _c("BaseFilter", {
        attrs: {
          filterId: "790ca065-681f-4a9f-952f-ec8c993ca818",
          showSaveButton: _vm.isBroker,
          title: "Beratungsmappen filtern",
          saveKey: _vm.isBroker ? "beratungsmappeSearchAdvanced" : null,
          metadata: _vm.filterMetadata,
          hasSmartSearch: _vm.isBroker,
          immidiateSearch: "",
          defaultOptions: _vm.isBroker ? _vm.defaultOptions : {}
        },
        on: {
          onFilter: function($event) {
            return _vm.filterForm($event)
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "c646c2ab-ba83-4271-b654-ed7c43d189bb" }
        },
        [
          !_vm.loading.list && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "cc8c185e-0263-4401-b654-903010d11f8e",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20,
                  exportConfig: { roottext: "Beratungsmappen" },
                  mobileConfig: _vm.mobileConfig
                },
                on: {
                  clickRow: function($event) {
                    return _vm.editBeratungsmappe($event.data)
                  },
                  "click-kunde": function($event) {
                    return _vm.editBeratungsmappe($event.data)
                  },
                  "click-date_expired": function($event) {
                    return _vm.showInfoDateExpired($event.data)
                  },
                  "click-art": function($event) {
                    return _vm.editBeratungsmappe($event.data)
                  },
                  "action-COPY": function($event) {
                    return _vm.copyBeratungsmappe($event.data)
                  },
                  "action-DELETE": function($event) {
                    return _vm.deleteBeratungsmappe($event.data)
                  }
                }
              })
            : _vm.loading.list
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _vm.rows.length === 0
            ? _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
            : _vm._e()
        ],
        1
      ),
      _c("EditBeratungsmappeModal", {
        ref: "createBeratungsmappeModal",
        on: { confirm: _vm.createBeratungsmappe }
      }),
      _c(
        "BaseModal",
        {
          ref: "ablaufInfoModal",
          attrs: {
            modalTitle: "Ablaufdatum",
            labelButtonConfirm: "Schließen",
            showConfirmButton: true,
            showCancelButton: false,
            showCloseButton: false
          }
        },
        [
          _c("div", [
            _vm.ablaufInfoModalData.ablaufDate
              ? _c("span", [
                  _vm._v(
                    " Die Anträge der Beratungsmappe müssen vor dem " +
                      _vm._s(_vm.ablaufInfoModalData.ablaufDate) +
                      " eingereicht werden. "
                  )
                ])
              : _vm._e(),
            _vm.ablaufInfoModalData.list
              ? _c(
                  "div",
                  _vm._l(_vm.ablaufInfoModalData.list, function(row, index) {
                    return _c("ul", { key: index, staticClass: "row" }, [
                      _c("li", { staticClass: "col mb-2" }, [
                        _vm._v(_vm._s(row.datum) + ": " + _vm._s(row.grund))
                      ])
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "id",
                    rawName: "v-id",
                    value: _vm.ablaufInfoModalData.hinweis,
                    expression: "ablaufInfoModalData.hinweis"
                  }
                ]
              },
              [_vm._v(" " + _vm._s(_vm.ablaufInfoModalData.hinweis) + " ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }