<template>
  <div :key="__DYNAMIC_APP_ID__" id="app" :class="appClass">
    <div v-if="$route.matched.some(({ meta }) => meta && meta.docs)" key="docs">
      <router-view :key="completeTitle">docs</router-view>
    </div>
    <div v-if="$route.matched.some(({ meta }) => meta && meta.simplePage)">
      <router-view />
    </div>
    <div v-else>
        <UpdaterModal/>
        <BaseNotificationMessage v-if="!isLoadingRequiredData"/>
        <BaseKillSession/>
        <BaseHeaderBar />
        <BaseFooterBar />

        <div v-if="$route.matched.some(({ meta }) => meta && meta.bypassContainerSize)" data-app-content>
          <PageNotAvailableInSmallScreen>
            <router-view />
          </PageNotAvailableInSmallScreen>
        </div>
        <template v-else>
          <div data-app-content class="fc-container">
            <OptionMenu 
              v-show="showOptionsMenu"
              :isLoading="isLoadingRequiredData" 
              :id="currentAppNavigation.currentOptionMenuId" 
              :defaultMenu="currentAppNavigation.defaultOptionMenu" 
              :ignoreRender="false" 
            />

            <PageNotAvailableInSmallScreen v-if="!isLoadingRequiredData">
              <router-view />
            </PageNotAvailableInSmallScreen>
          </div>
        </template>

        <HeatmapCanvas/>

        <BaseConfirmModal v-for="pendingConfirm in pendingConfirmModal" :key="pendingConfirm._uuid" :config="pendingConfirm" />
    </div>

    <VStyle v-if="isMobileNativeContext">
      html,
      body {
        width: 100vw;
        overflow-x: hidden;
        overflow-y: scroll;
        overscroll-behavior: none;
      }
    </VStyle>

    <Offline />
  </div>
</template>

<style>

#app {
  box-sizing: border-box;
  display: flow-root;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  min-height: -webkit-fill-available;
  min-height: 100vh;
  min-height: 100svh;
  min-height: 100dvh;
  -webkit-overflow-scrolling: auto;
}

.app--is-mobile-native-context {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
}

</style>

<script>
import BaseHeaderBar from "@/components/core/BaseHeaderBar.vue";
import BaseFooterBar from "@/components/core/footer-bar/BaseFooterBar.vue";
import CORE_TYPES from "./store/core/types";
import WEBRTC_TYPES from "./store/webrtc/types";
import BRIDGE_TYPES from "./store/bridge/types";
import MENU_TYPES from './store/menu/types';
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import screenSize from "@/mixins/screen-size";
import BaseNotificationMessage from '@/components/core/BaseNotificationMessage.vue'
import BaseKillSession from '@/components/core/BaseKillSession.vue'
import UpdaterModal from '@/components/core/sr-updater/UpdaterModal.vue'
import BaseConfirmModal from '@/components/core/modal/BaseConfirmModal.vue';
import BrowserSupport from '@/browser-support';
import VStyle from '@/components/core/VStyle.vue'
import HeatmapCanvas from '@/components/heatmap/HeatmapCanvas.vue'
import { eventCoord } from '@/helpers/utils-helper';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageNotAvailableInSmallScreen from "@/components/core/PageNotAvailableInSmallScreen.vue";
import Offline from '@/components/core/Offline.vue';
import { VIEW_ROLES, ROLES } from '@/router/roles';
import menuManager from '@/menu/menu-manager';
import { getActiveColorScheme, ColorSchemeChangedEvent } from '@/configs/color-config';

export default {
  mixins: [
    InteractiveHelpCommonsMixin, screenSize
  ],
  computed: {
    ...mapGetters({
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      pageTitleBase: CORE_TYPES.GETTERS.PAGE_TITLE,
      callSymbol: WEBRTC_TYPES.GETTERS.CALL_SYMBOL,
      recordingTutorial: CORE_TYPES.GETTERS.RECORDING_TUTORIAL,
      isLoading: CORE_TYPES.GETTERS.GLOBAL_LOADING_STATE_STATUS,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      pendingConfirmModal: CORE_TYPES.GETTERS.PENDING_CONFIRM_MODAL,
      isTestUser: CORE_TYPES.GETTERS.IS_TEST,
      isLoadingToken: CORE_TYPES.GETTERS.IS_LOADING_TOKEN,
      isManagingLoginResponse: CORE_TYPES.GETTERS.IS_MANAGING_LOGIN_RESPONSE,
      isLoggingIn: CORE_TYPES.GETTERS.IS_LOGGING_IN,
      isLoggingOut: CORE_TYPES.GETTERS.IS_LOGGING_OUT,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      appNavigationByMenuPathFn: MENU_TYPES.GETTERS.APP_NAVIGATION_BY_MENU_PATH,
      hasMandatoryPendingActionActive: CORE_TYPES.GETTERS.HAS_MANDATORY_PENDING_ACTION_ACTIVE,
    }),
    isFK4() {
      return this.hasRoles([ROLES.IS_IN_TEST_MODE]);
    },
    isMakler() {
      return this.hasRoles([[VIEW_ROLES.VIEW_BROKER_AS_INTERN],[VIEW_ROLES.VIEW_BROKER, ROLES.IS_BYPASS_SLASH]]);
    },   
    isCustomer() {
      return this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN],[VIEW_ROLES.VIEW_CUSTOMER, ROLES.IS_BYPASS_SLASH]]);
    },
    isIntern() {
      return this.hasRoles([VIEW_ROLES.VIEW_INTERN]);
    },       
    __DYNAMIC_APP_ID__() { // forces App re-render
      return this.$store?.state?.__DYNAMIC_APP_ID__ || '';
    },
    currentAppNavigation() {
      return this.appNavigationByMenuPathFn(menuManager.currentId);
    },
    showOptionsMenu() {
      return this.isLoggedIn && !this.$route.matched.some(({ meta }) => meta?.pageMenuCfg?.showOptionsMenu === false)
        && !this.hasMandatoryPendingActionActive;
    },
    isLoadingRequiredData() {
      return this.isLoadingToken || this.isManagingLoginResponse || this.isLoggingIn || this.isLoggingOut;
    },
    completeTitle() {
      
      let enviromentPrefix = "";
      let prefix = "";

      if (this.isMakler && !this.isLoadingToken && this.isFK4) {
        prefix = "(V) ";
      } else if (this.isCustomer && !this.isLoadingToken && this.isFK4) {
        prefix = "(K) ";
      } else if (this.isIntern && !this.isLoadingToken) {
        prefix = "(I) ";
      }

      if (this.isFK4 && !this.isLoadingToken) {
        enviromentPrefix = "(T) ";
      }

      const { callSymbol, pageTitleBase } = this;
      const { pageTitle } = this.$route?.meta?.pageCfg || {};
      return prefix + enviromentPrefix + callSymbol + (pageTitle || pageTitleBase);
    },
    appClass() {
      return {
        'app--is-mobile-native-context': this.isMobileNativeContext,
        'app--web': !this.isMobileNativeContext,
        'app--is-test-user-context': this.isTestUser,
        [`screen-size--${this.$screenWidth}`]: true,
        [`app--${this.colorScheme}-scheme`]: true,
      }
    }
  },
  data() {
    return {
      screenData: {
        allowScrollUp: true,
        allowScrollDown: true,
      },
      colorScheme: getActiveColorScheme(),
    }
  },
  watch: {
    isMobileNativeContext() {
      this.addClassesToRootElement();
    },
    completeTitle: {
      handler(val) {
        document.title = val;
      },
      immediate: true,
    },
    $route() {
        window.setTimeout(function () {
            this.$nextTick(() => {
              if (this.recordingTutorial) {
                this.highlightRecordableItems();
              }
          });
        }.bind(this), 1900);
      
        this.$store.commit(MENU_TYPES.MUTATIONS.UPDATE_CURRENT_APP_NAVIGATION);
    },
    isLoading: function(newIsLoading) {
      if (newIsLoading) {
          // do nothing for now
      } else {
        if (this.recordingTutorial) {
          this._addTutorialSpinner(this._targetElement || this.$root.$el);
          window.setTimeout(function () {
            this.highlightRecordableItems();
            this._removeTutorialSpinner(this._targetElement || this.$root.$el);
          }.bind(this), 1900);        

        }
        
      }
    }         
  },
  methods: {
    addClassesToRootElement() {
      const { isMobileNativeContext, } = this;
      document.querySelector('html').classList.toggle('app--is-mobile-native-context', isMobileNativeContext);
    },
    highlightRecordableItems() {
        this.highlightElementsWithTidAttribute(this.recordingTutorial);
    },
    updateScrollStatus () {
      // store whether there is space enough above or below the scrolling
      this.screenData.allowScrollUp = (Math.floor(window.scrollY) >= 0);
      this.screenData.allowScrollDown = (Math.floor(window.scrollY) <= document.body.scrollHeight - window.innerHeight);
    },
    disableZoom() {
      if(!this.isMobileNativeContext) return;

      const handle = (event) => {
        if(event.scale !== undefined && event.scale !== 1) {
          event.preventDefault();
        }
      };
      const options = BrowserSupport.supportsPassive ? { passive: false, } : {};

      this.$nextTick(() => {
        document.addEventListener('touchmove', handle, options);
        document.addEventListener('dblclick', handle, options);
      });
    },
    disableOverScroll() {
      if(!this.isMobileNativeContext) return;

      const handleTouchStart = (event) => {
        // Store the first Y position of the touch
        this.screenData.startY = eventCoord(event, 'screenY');
        this.updateScrollStatus();
      };

      const handleMove = (event) => {
        this.preventOverScroll(event);
      };
      const options = BrowserSupport.supportsPassive ? { passive: false, } : {};

      this.$nextTick(() => {
        document.addEventListener('touchstart', handleTouchStart, options);
        document.addEventListener('touchmove', handleMove, options);
      });
    },
    setVirtualKeyboardDetected() {
      this.$store.commit(BRIDGE_TYPES.MUTATIONS.VIRTUAL_KEYBOARD_DETECTED, true);

      window.removeEventListener('custom.virtualKeyboardStateChanged', this.setVirtualKeyboardDetected);
    },
    preventOverScroll(event) {
      // Get the current Y position of the touch
      const curY = eventCoord(event, 'screenY');
      
      // get the direction of the touch movement
      const isScrollingUP = this.screenData.startY >= curY;
      const isScrollingDown = this.screenData.startY <= curY;

      if ((isScrollingUP && !this.screenData.allowScrollDown) || (isScrollingDown && !this.screenData.allowScrollUp)) {
        //stop the touch event progation in order to avoid bounce scrolling
        event.preventDefault();
      }

      this.screenData.startY = curY;
      this.updateScrollStatus()
    },
    handleTimeoutVisibilityChange() {
      if (document.visibilityState === "visible" && this.isMobileNativeContext) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.CHECK_BACKEND_TIMEOUT);
      }
    },
    handleColorSchemeChanged() {
      requestAnimationFrame(() => this.colorScheme = getActiveColorScheme());
    },
  },
  mounted() {
    this.addClassesToRootElement();

      if (this.recordingTutorial) {
        this._addTutorialSpinner(this._targetElement);
      
        window.setTimeout(function () {
          this.$nextTick(() => {
            this.highlightRecordableItems();
          });
          this._removeTutorialSpinner(this._targetElement);
        }.bind(this), 1900);    
      }  

    const screenWidth = this.$addResizeListener((ev, screenWidth) => {
      this.$store.commit(CORE_TYPES.MUTATIONS.SCREEN_WIDTH, screenWidth);
    });
    this.$store.commit(CORE_TYPES.MUTATIONS.SCREEN_WIDTH, screenWidth);

    this.disableZoom();
    this.disableOverScroll();

    window.addEventListener('custom.virtualKeyboardStateChanged', this.setVirtualKeyboardDetected);

    window.addEventListener("visibilitychange", this.handleTimeoutVisibilityChange);

    window.addEventListener(ColorSchemeChangedEvent.name, this.handleColorSchemeChanged);
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.handleTimeoutVisibilityChange);

    window.removeEventListener(ColorSchemeChangedEvent.name, this.handleColorSchemeChanged);
  },
  components: {
    BaseHeaderBar,
    BaseFooterBar,
    BaseNotificationMessage,
    BaseKillSession,
    UpdaterModal,
    BaseConfirmModal,
    VStyle,
    HeatmapCanvas,
    OptionMenu,
    PageNotAvailableInSmallScreen,
    Offline,
}
};
</script>

<style >
  .snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}
</style>