var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BemerkungenList", {
    attrs: { records: _vm.records, loading: _vm.loading, typ: "vvschema" },
    on: {
      delete: function($event) {
        return _vm.deleteBemerkungen({ ids: [$event.id] })
      },
      deleteSelected: _vm.deleteBemerkungen
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }