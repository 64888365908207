var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c(
      "div",
      { staticClass: "input-forms__input-container checkbox-input__container" },
      [
        _c(
          "div",
          {
            staticClass: "input-forms__label-container",
            attrs: { "aria-label": _vm.title || "Titel" }
          },
          [
            _vm.title
              ? _c("div", {
                  staticClass: "input-forms__label-content",
                  domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.title)) }
                })
              : _vm._e()
          ]
        ),
        _vm._l(_vm.normalizedValues, function(val) {
          return _c(
            "div",
            { key: val.path },
            [
              _c("InputCheckBoxItem", {
                attrs: {
                  id: val.path,
                  label: val.label,
                  value: _vm.value[val.path],
                  disabled: _vm.disabled || val.disabled
                },
                on: {
                  input: function($event) {
                    return _vm.onInput($event, val)
                  }
                }
              }),
              _vm.isValidationConfigured()
                ? [
                    !_vm.suppressValidationMessage && _vm.validation
                      ? _c(
                          "div",
                          { key: _vm.validation.updated },
                          _vm._l(
                            _vm.validation.getErrors(
                              _vm.getCheckboxErrorPath(val.path),
                              _vm.validateUntouched
                            ),
                            function(error) {
                              return _c(
                                "div",
                                {
                                  key: error,
                                  staticClass: "input-forms__error-message"
                                },
                                [_vm._v(" " + _vm._s(error) + " ")]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }