var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.wpRows.length
        ? _c("Table", {
            attrs: {
              title: _vm.TABLE_TITLE,
              tableId: _vm.tableId,
              headers: _vm.wpHeaders,
              rows: _vm.wpRows,
              headerActions: _vm.headerActions,
              mobileConfig: {
                title: "fondsname",
                headers: ["gesellschaft", "isin", "old_wkn"],
                singleSelection: _vm.singleSelection,
                selectionActive: _vm.showMobileSelectionAlways
              },
              rowId: "rowId",
              rowsPerPage: 15,
              selected: _vm.selectedRows,
              exportConfig: _vm.exportConfig,
              scrollHorizontally: _vm.scrollHorizontally
            },
            on: {
              selected: _vm.onSelect,
              "click-fondsname": _vm.gotoFondsinfo,
              action: _vm.handleAction,
              "action-ADD_FAVORITENLISTE": function($event) {
                return _vm.openAddFondsToListModal($event, "Favoritenlisten")
              },
              "action-ADD_MYDEPOT": function($event) {
                return _vm.openAddFondsToListModal($event, "MyDepot")
              },
              "action-ADD_FONDSVERGLEICH": function($event) {
                return _vm.openAddFondsToListModal($event, "Fondsvergleich")
              },
              headerAction: _vm.handleHeaderAction
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "selected",
                  fn: function(row) {
                    return [
                      _c("InputRadioBoxItem", {
                        attrs: { name: "selectFonds" },
                        on: {
                          change: function($event) {
                            return _vm.onSingleSelect(row)
                          }
                        }
                      })
                    ]
                  }
                },
                {
                  key: "fonds_ampel",
                  fn: function(ref) {
                    var fonds_ampel = ref.fonds_ampel
                    return [_c("div", { class: fonds_ampel })]
                  }
                }
              ],
              null,
              false,
              2749756181
            )
          })
        : _vm.loading
        ? _c("GhostLoading", {
            attrs: { type: "table", title: _vm.TABLE_TITLE }
          })
        : _c("NoData", {
            attrs: {
              title: _vm.TABLE_TITLE,
              content: "Es wurden keine Einträge gefunden"
            }
          }),
      _c("AddFondsToListModal", { ref: "addFondsToListModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }