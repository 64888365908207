<template>
  <div class="workspaces-logo__container">
    <a @click="openCurrentUserInNewTab()" :class="{ 'clickable': canOpenCurrentUserInNewTab, }">
      <img v-if="logo" :src="logo" class="container__img" alt="Logo mit Link für den aktuellen Nutzer in einem neuen Tab"/>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types';
import { VIEW_ROLES } from '@/router/roles';

export default {
  computed: {
    ...mapGetters({
      logo: CORE_TYPES.GETTERS.GET_USER_LOGO_URL,
      brokerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    canOpenCurrentUserInNewTab() {
      return this.hasRoles([
        VIEW_ROLES.VIEW_BROKER_AS_BYPASS,
        VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS,
        VIEW_ROLES.VIEW_BROKER,
        VIEW_ROLES.VIEW_INTERN_ONLY,
      ]);
    },
  },
  methods: {
    openCurrentUserInNewTab() {
      if(!this.canOpenCurrentUserInNewTab) return;
      if (this.isIntern) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CURRENT_LOGGED_INTERN, this.$route);
      } else {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CURRENT_LOGGED_BROKER);
      }
    },
  },
}
</script>

<style scoped>
  .workspaces-logo__company-name {
    font-size: 1.25rem;
  }
  .workspaces-logo__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .workspaces-logo__container a {
    cursor: default;
  }
  .workspaces-logo__container a.clickable {
    cursor: pointer;
  }
  .container__img {
    max-height: 75px;
  }
</style>