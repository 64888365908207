<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div
            class="input-forms__label-content input-forms__label-content--bigger"
          >
            <ph-bank :size="16" />&nbsp;{{title}}
          </div>
        </div>

        <div class="col-12 col-sm-4 col-xl-2 mx-0 ml-3 mb-3">
          <BaseButton
            @click="openFondsfinder('SPARPLAN')"
            isBlock
            :disabled="disabled"
          >
            {{config.buttonText}}
          </BaseButton>
        </div>
      </div>
      <div>
        <template v-for="(pos, index) in positions">
          <FormMorgenfundSparplanPositionCards
            :key="index"
            :position="pos"
            :antragId="antragId"
            :disabled="disabled"
            :config="config"
            :categoryId="categoryId"
          />
        </template>
      </div>
    </div>
    <div
      v-if="config.mitSummenbildung && this.positions && this.positions.length"
    >
      <Summenbildung v-if="categoryId == 'SPARPLAN'"
        :antragId="antragId"
        :summeSpar="summe"
        :mitSparplan="true"
        :ohneEinmal="true"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import FormMorgenfundSparplanPositionCards from "@/components/core/forms/antraege/wertpapierAuswahl/FormMorgenfundSparplanPositionCards.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import Summenbildung from "@/components/antrag/Summenbildung.vue";
import { PhBank } from "phosphor-vue";
export default {
  mixins: [antragNavigationMixin],
  props: {
    title: String,
    antragId: String,
    categoryId: String,
    config: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      summe: 0,
    };
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
    }),
    positions() {
      return (
        this.positionsAll?.[this.antragId]?.[this.categoryId]?.map((pos) => ({
          ...pos,
          fondsname: this.getFondsName(pos),
          sri: this.getSRI(pos),
          art: this.categoryId,
        })) || []
      );
    },
  },
  watch: {
    positions: {
      handler(newPositions) {
        this.summe = newPositions.reduce((sum, r) => sum + (r.sparplanBetrag || 0), 0);
      },
      immediate: true,
    },
  },
  components: {
    Summenbildung,
    BaseButton,
    FormMorgenfundSparplanPositionCards,
    PhBank,
  },
  methods: {
    openFondsfinder(posTyp) {
      this.goToFormsFinder(posTyp, this.config);
    },
    getFondsName(pos) {
      return this.positionInfo?.[pos.isin]?.wertpapiername || pos.fondsname || pos.wertpapiername;
    },
    getSRI(pos) {
      return this.positionInfo?.[pos.isin]?.sri;
    },
  },
};
</script>
