export const ResolverId = {
  ExterneZugangBank: 'externe-zugang-bank',
  ExterneZugang: 'externe-zugang',
  Form: 'form',
  Factsheet: 'factsheet',
  DatenschutzURL: 'DatenschutzURL',
  Europace2Vorgang: 'Europace2Vorgang',
};

export const WrapType = {
  Error: 'Error',
  ViewDocument: 'ViewDocument',
  FormData: 'FormData',
  Url: 'Url',
  DownloadLink: 'DownloadLink',
  DownloadLinkHref: 'DownloadLinkHref',
};
