var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.actions
        },
        on: {
          "action-NEW": function($event) {
            return _vm.chooseGoal()
          }
        }
      }),
      _c("div", [
        _c(
          "div",
          { staticClass: "box__container" },
          [
            _vm.rows.length
              ? _c("Table", {
                  attrs: {
                    tableId: "myGoalList",
                    title: _vm.TABLE_TITLE,
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowsPerPage: 10,
                    mobileConfig: {}
                  },
                  on: {
                    "click-title": function($event) {
                      return _vm.navigateTo("meineziele/" + $event.id)
                    },
                    "action-EDIT": function($event) {
                      return _vm.navigateTo("meineziele/" + $event.id)
                    },
                    "action-DELETE": _vm.deleteGoal
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "img",
                        fn: function(row) {
                          return [
                            _c(
                              "div",
                              { staticClass: "image-placeholder" },
                              [
                                _c("BaseImage", {
                                  staticClass: "logo",
                                  attrs: {
                                    src: _vm.goalImage(row),
                                    placeholder: _vm.imagePlaceholder
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "chart",
                        fn: function(row) {
                          return [
                            _c("PieChart", {
                              staticStyle: { width: "40px" },
                              attrs: {
                                chartData: _vm.eChartData(row),
                                customColors: _vm.eChartColors,
                                isTooltip: false,
                                height: "40px",
                                doughnut: ""
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2015313231
                  )
                })
              : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }