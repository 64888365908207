var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.webserviceWarnings && _vm.webserviceWarnings.length
    ? _c(
        "div",
        { staticClass: "color-danger" },
        [
          _c("div", { staticClass: "color-danger box__title" }, [
            _vm._v("Vorsicht, die folgenden Daten haben sich geändert:")
          ]),
          _vm._l(_vm.webserviceWarnings, function(warn, index) {
            return _c("div", { key: index }, [
              _vm._v(" " + _vm._s(warn.key) + ": " + _vm._s(warn.value) + " ")
            ])
          }),
          _vm.hasWeitereWarnungen
            ? _c(
                "a",
                {
                  staticClass: "color-danger",
                  on: {
                    click: function($event) {
                      return _vm.$emit("weitereWarnungen")
                    }
                  }
                },
                [_vm._v("weitere Warnungen ...")]
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }