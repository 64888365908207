import IZF from './types';

import axios from 'axios';

export default {

  [IZF.ACTIONS.COMBO]({ commit, rootState, state }, e) {
    const config = {
      defaultSpinner: true,
    };



    axios.get(rootState.core.apiAddress + '/InsZipFileService/combo').then(response => {


      commit(IZF.MUTATIONS.COMBO, response.data);


    })
  },

  async [IZF.ACTIONS.UPLOAD]({ commit, rootState, dispatch, state }, e) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: false,
      disableDefaultErrorMessage: false,
      cancelableRequest: {
        title: 'Edit Pages'
      }
    };



    await axios.post(rootState.core.apiAddress + '/InsZipFileService/upload', e, config).then(response => {

      commit(IZF.MUTATIONS.RESULT, response.data);
      

    });
  },
  




}


