<template>
  <div>
    <GhostLoading v-if="loading" type="table" />
    <span v-else-if="!hasLinks">
      <p style="text-align:center; font-weight:bold">Keine Daten vorhanden*</p>
      <br/>
      <p style="font-weight:bold">Hinweis zur Meldung: (*)</p> 
      Diese Meldung erscheint, wenn keine Antwort von der Versicherungsgesellschaft vorliegt.<br/>
      Dies bedeutet nicht zwangsläufig, dass die Deeplinks-Funktionalität fehlerhaft ist, sondern vielmehr, dass auf Basis der hinterlegten Kunden- und Vertragsdaten keine passenden Deeplinks von der Gesellschaft bereitgestellt wurden.
    </span>
     
    <ul v-else class="m-0">
      <li v-for="link in deepLinks" :key="link.name">
        <a v-if="!link.clicked" :href="link.url" target="_blank" name = key  @click="onLinkClick(link)">{{ link.name }} 
          <tippy v-if="hasSameBeschreibung" :to="key" trigger="mouseenter" theme="deeplinkToolTip" boundary="viewport" followCursor="Vertical" placement="right" arrow="true">
            Die vorgeschlagenen Links basieren auf den hinterlegten Zugangsdaten des Nutzers, die unter 'Interner Bereich -> Einstellungen -> Externe Zugänge einrichten -> BiPRO Zugänge' für die jeweilige Versicherungsgesellschaft hinterlegt sind.<br/>
Es kann vorkommen, dass für einen Vertrag mehrere Links mit unterschiedlichen Benutzer-IDs angezeigt werden.<br/>
Diese basieren auf den hinterlegten Zugangsdaten der BiPRO Zugänge für die jeweilige Versicherungsgesellschaft und werden durch Bezeichnungen wie z. B. 'Vertragssuche', 'Dokumente zum Vertrag' oder 'Schadenmeldung' etc. ergänzt.<br/>
Die Vertragsdaten können jedoch nur über eine Benutzer-ID abgerufen werden, da die angezeigten Links von den Berechtigungen und Zugangsdaten des jeweiligen Nutzers bei der Versicherungsgesellschaft abhängen.</tippy>
        </a>
        <span v-else>{{ link.name }}
          <tippy trigger="mouseenter" theme="deeplinkToolTip" boundary="viewport" followCursor="Vertical" placement="right" arrow="true">Der Deeplink kann nur einmal verwendet werden.<br/>Für eine erneute Nutzung bitte den Button<br/>'Deeplinks aktualisieren' klicken.</tippy>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>


import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import DeepLinksMixin from '@/components/versicherungen/Deeplinks-mixin.js';

export default {
  mixins: [DeepLinksMixin],
  props: {
    vertragId: {},
  },
  watch: {
    'deepLinksGetter': {
      handler() {
        let newLinks = Object.keys(this.deepLinksGetter).map(link => {
          return {
            url: link,
            name: this.deepLinksGetter[link],
            clicked: false
          };
        });

        this.deepLinks = newLinks;
      },
      deep: true,
    }
  },
  components: {
    GhostLoading,
  },
  data() {
    return {
      deepLinks: [],
      loading: false,
      hover: false
    }
  },
  computed: {
    hasLinks() {
      return Object.keys(this.deepLinks).length > 0;
    },
    hasSameBeschreibung() {
      const maklerList = [];
      var sameBeschreibung = false;
      this.deepLinks.forEach(link => {
        const linkBeschreibung = link.name.split(":");

        if (!maklerList.includes(linkBeschreibung[1])) {
          maklerList.push(linkBeschreibung[1]);
        } else {
          sameBeschreibung = true
        }
      });

      return sameBeschreibung;
    }

  },
  methods: {
    onLinkClick(whatLink) {
      whatLink.clicked = true;
    },
  },
  mounted() {
    this.findDeeplinks(this.vertragId);
  },
}
</script>

<style>
.tippy-tooltip.deeplinkToolTip-theme {
    background: #666;
    border-radius: 2px;
    box-sizing: border-box;
    color: #fff;
    font-size: 12px;
    padding: 3px 6px;
    animation: fcTooltipAnimation .1s ease;
    transform-origin: inherit;
}
</style>
