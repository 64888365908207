var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Finanzielle Verhältnisse")
      ]),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("1. Grundlage und Höhe des derzeit regelmäßigen Einkommens")
      ]),
      _c("FinanzenGrundlage", {
        attrs: { disabled: !_vm.isEditable, finance: _vm.finances },
        on: { changed: _vm.updateValue }
      }),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("2. aktuelle liquide Vermögenswerte")
      ]),
      _c("FinanzenLiquiditaet", {
        attrs: { disabled: !_vm.isEditable, finance: _vm.finances },
        on: { changed: _vm.updateValue }
      }),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("3. Immobilienvermögen")
      ]),
      _c("FinanceImmobilienvermoegen", {
        attrs: { isRefusedPage: !_vm.isEditable, finance: _vm.finances },
        on: {
          updateImmo: function($event) {
            return _vm.immobilienChanged($event)
          }
        }
      }),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("4. Kapitalanlage / sonstige Vermögenswerte")
      ]),
      _c("FinanzenKapitalanlage", {
        attrs: {
          disabled: !_vm.isEditable,
          finance: _vm.finances,
          showBondDepotRelevant: _vm.showBondDepotRelevant
        },
        on: { changed: _vm.updateValue }
      }),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("5. Verbindlichkeiten / Schulden")
      ]),
      _c("FinanzenVerbindlichkeiten", {
        attrs: { disabled: !_vm.isEditable, finance: _vm.finances },
        on: { changed: _vm.updateValue }
      }),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("6. regelmäßige finanzielle Verpflichtungen (pro Monat)")
      ]),
      _c("FinanzenRegelmaessigeRate", {
        attrs: { disabled: !_vm.isEditable, finance: _vm.finances },
        on: { changed: _vm.updateValue }
      }),
      !_vm.isFA
        ? _c(
            "div",
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Zulagenberechtigung")
              ]),
              _c("FinanzenWeitere", {
                attrs: {
                  disabled: !_vm.isEditable,
                  finance: _vm.finances,
                  isFA: _vm.isFA
                },
                on: { changed: _vm.updateValue }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }