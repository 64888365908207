<template>
  <div v-if="contentReady">
    <template  v-if="stepKey === 'AUSGEWAEHLTE_INVESTMENTFONDS' && isSmallOrMediumScreen"> 
      <Table
        title="Ausgewählte Investmentfonds"
        :headers="selectedFundsHeaders"
        :mobileConfig="{title: 'Ausgewählte Investmentfonds', headers: ['fondsname', 'isin', 'actions'], disableClickRow: true}"
        :rows="fundsRows"
				:headerActions="tableActions"
        v-model="delSelection"
        rowId="isin"
        hidePagination
        @action-DELETE="delFunds"
        @headerAction="handleHeaderAction"
      >
        <template #fondsname="{fondsname,isin}">
          <a @click="gotoFondsinfo(isin)">{{fondsname}}</a>
        </template>
      </Table>      

    </template>
    <template  v-if="stepKey === 'FILTER'">     
      <WertpapierSearch class="search-filter" name="CompareFunds"  type="I" ref="wp_filter"
        :respond="3" 
        :initSelected="selFunds" :tableHeaderActions="tableHeaderActions"
        isSelectable
        :scrollHorizontally="wpScrollH"
        @selected="selectedRow"
        @headerAction="wpHeaderAction"
      >
        <template v-slot:beforeTable>
          <p>Bitte wählen Sie die Wertpapiere für den Vergleich. (wiederholt suchen ist möglich)</p>
          <BaseButton isPrimary @click="addSelectedFonds()" :disabled="!selectedWertpapier || !selectedWertpapier.length" >Hinzufügen</BaseButton>          
        </template>
      </WertpapierSearch>

      <div class="box__container" v-if="source !== MODAL_SOURCE">
        <BaseButton :disabled="!selFunds.length" @click="setAuswahl">Weiter</BaseButton>
      </div>
    </template>
    
    <template v-if="stepKey === 'EINSTELLUNG'">
      <div>
        <div v-if="isSmallOrMediumScreen" class="box__container">
          <span class="text-bold">{{selFunds.length}} Fonds, {{indices.length}} Börsenindizes</span>
        </div>

        <div class="box__container" v-if="settings && settings.showWertEntw">
          <div class="box__title">Wertentwicklung Chart</div>
          <InputRadioBoxGroup v-model="wentwVal" :values="wentwOption"/>
          <template v-if="wentwVal && wentwVal === wentwOption[0].value">
            <ComboBox label="Anzahl Jahre" v-model="wentwJahr" 
            :values="wentwJahrOpt" isComponentHalfSize 
            :sortComboboxValues="false"
            />
            <InputCheckBoxItem label="&nbsp;Startdatum anpassen" v-model="cbAnpassen"/>
              Hat eines der Wertpapiere zum gewählten Startdatum keine Kurse, so wird der
              betrachtete Zeitraum automatisch so verkürzt, dass für alle Wertpapiere über
              den gesamten Zeitraum Kurse vorhanden sind.
              <div class="mt-2">Beginn der Wertentwicklung: {{startDate}}</div>
          </template>
          <template v-if="wentwVal && wentwVal === wentwOption[1].value">
            <div>Zeitraum</div>
            <DatePickerField label="von" v-model="zeitraum_von" @input="setSettings" isComponentHalfSize/>
            <DatePickerField label="bis" v-model="zeitraum_bis" @input="setSettings" isComponentHalfSize/>
          </template>
        </div>
        <div class="box__container" v-if="settings && settings.showRisiko">
          <div class="box__title">Rendite-Risiko Chart</div>
          <InputRadioBoxGroup v-model="risikoJahr" :values="risikoOption" @change="() => {}"/>
        </div>
      </div>
      <div >
        <div class="box__container">
          <Table
            title="Investmentfonds"
            :headers="investmentFundsHeaders"
            :rows="allFunds"
            rowId="isin"
            :rowsPerPage="25"
            :selected="selFunds"
            @selected="selectedFunds($event)"
            @action-DELETE="delFunds"
            @action-FACTSHEET="viewDocument($event.FACTSHEET)"
            @action-FACTSHEET_KAG="viewDocument($event.FACTSHEET_KAG)"
            @action-KID="viewDocument($event.KID)"
          >
            <template #fondsAmpel="{ fondsAmpel }">
              <div :class="fondsAmpel"></div>
            </template>
            <template #aa_manuell="{isin, aa_manuell}">
              <InputField :value="aa_manuell" @change="setAaManuell(isin, $event)"/>
            </template>
          </Table>

          <BaseButton isSecondary @click="$emit('setStep', 'FILTER')">Zurück zur Fondsauswahl</BaseButton>
        </div>
        <div class="box__container">
          <div class="box__title">Vergleichoptionen auswählen</div>
          <InputChips 
              class="p-0 indices"
              label="Indizes"
              isComponentHalfSize
              v-model="indicesSelected"
              :availableOptions="indicesOption"
              :showToggle="true"  />
        </div>

        <div class="fc-form-danger">{{errStr}}</div>
        <div v-if="!isSmallOrMediumScreen" class="box__container">
          <div>
            <div class="layout__negative-margin--8">
              <BaseButton isSecondary @click="resetVergleich">Vergleich zurückstellen</BaseButton>
              <BaseButton :disabled="!selFunds.length && !indices.length || ergebnisLoadingActive" :animated="ergebnisLoadingActive"
                  @click="getVergleich">Vergleich starten mit {{selFunds.length}} Fonds, {{indices.length}} Börsenindizes</BaseButton>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="stepKey === 'STAMMDATEN'">
      <div>
        <div v-if="hasStammdatenData || ergebnisLoading.fondsDaten" class="box__container">
          <InputRadioBoxGroup 
            v-model="showDatenAsCols" 
            :values="[
              {label:'Anzeige als Zeilen', value: true},
              {label:'Anzeige als Spalten', value: false},
            ]"
            :disabled="ergebnisLoading.fondsDaten"
          />
        </div>
        <GhostLoading v-if="ergebnisLoading.fondsDaten" type="table" useBoxContainer />
        <div class="box__container" v-else-if="stammdaten.rowsCount && showDatenAsCols">
          <Table class="table-container" ref="tblStamm"
                v-if="$isSmallScreen"
                :headers="stammdaten.headers"
                :rows="stammdaten.rows"
                :scrollHorizontally="!scrollFirstColunm"
                rowId="ISIN"
                hidePagination
                @action-FACTSHEET="viewDocument($event.FACTSHEET)"
                @action-FACTSHEET_KAG="viewDocument($event.FACTSHEET_KAG)"
                @action-KID="viewDocument($event.KID)"
          >
            <template #AUFLAGEDATUM="{AUFLAGEDATUM}">
                {{timeToStr(AUFLAGEDATUM)}}
            </template>
            <template #KURS1="{KURS1}">
                {{timeToStr(KURS1)}}
            </template>
            <template #header_wertpapier="{}">
              <span style="">Wertpapier </span>
            </template>
            <template #wertpapier="{wertpapier}">
              <div style="min-width:150px">{{wertpapier}}</div>
            </template>
          </Table>

         
          <BigTable
                  v-if="shouldShowBigTable"
                  :title="tableTitle"
                  :tableId="tableId"
                  :headers="bigTableHeaders"
                  :rows="bigTableRows"
                  :page="bigTablepage"
                  :sortingState="sortingState"
                  :initialPageSize="minNumRows"
                  @onClickHeader="onClickHeader"
                >
                <template #AUFLAGEDATUM="{AUFLAGEDATUM}">
                  {{timeToStr(AUFLAGEDATUM)}}
                </template>
                <template #KURS1="{KURS1}">
                    {{timeToStr(KURS1)}}
                </template>
          </BigTable>

        </div>
        <div v-else-if="stammdaten.rowsCount && !showDatenAsCols" >
          <template v-for="(row,idx) of stammdaten.records">
            <div :key="'T'+idx" class="box__title ml-2">{{row.label}}</div>
            <div class="box__container row p-2" :key="'R'+idx" >
              <div class="col-6 col-lg-4 col-xl-3" v-for="isin of isinRow" :key="isin">{{showValue(row[isin])}}</div>
            </div>
          </template>
        </div>
        <FondsVergleichNoData v-else @click="emitEinstellungStep" />
      </div>
    </template>

    <template v-if="stepKey === 'WERTENTICK'">
      <GhostLoading v-if="ergebnisLoading.wertentwicklung" useBoxContainer>
        <Block height="300" />
      </GhostLoading>
      <div v-else-if="hasWertentwicklungData" class= "box__container">
        <div class = "flex-c-c">
          <ComboBox class="mt-1 child-w2" v-model="wentwChartVal" 
            :values="wentwChartOpt"
            :sortComboboxValues="false" 
          />
          <InputCheckBoxItem class="child-w3" label="&nbsp;nach Agio" v-model="cbAgio"/>
          <InputCheckBoxItem class="child-w3" label="&nbsp;nach Steuern" v-model="cbSteuern"/>
          <InputCheckBoxItem class="child-w3" label="&nbsp;Rendite p.a." v-model="cbRenditePA"/>
        </div>
        <div>
          <ChartFunds type="Fondsvergleich" :chartData="chartWertentwicklung" :sortLegend="true"
            @newOrder="changeLegendSort" @newZeitIntervall="changeZeitIntervall"
          >
            <template #beforLegend>
              <InputChips 
                class="mb-2 indices"
                label="zusätzliche Benchmark verwalten *"
                v-model="indicesWESelected"
                :availableOptions="indicesWEOption" :showToggle="true"
                @addItem="addWEIntex" @removeItem="removeWEIntex"
              />
            </template>
          </ChartFunds>
          
          <template>
            <div class="mt-3" style="font-size: 13px;">* Zur Gewährleisung eines korrekten Vergleichs ist die Wertentwicklung des Vergleichsindex immer in Bezug auf die erfolgten Kapitalein- bzw. auszahlungen (zeit- und kapitalgewichtete Performancemessung) im jeweilig ausgewählten Zeitraum zu sehen, weshalb die nur auf die Zeitspanne bezogene Index-Performance erheblich abweichen kann.</div>
          </template>
        </div>
      </div>
      <FondsVergleichNoData v-else @click="emitEinstellungStep" />
    </template>
        
    <template v-if="stepKey === 'RENDITERISIKO'">
      <GhostLoading v-if="ergebnisLoading.rendRisiko" useBoxContainer>
        <Block height="300" />
      </GhostLoading>
      <div v-else-if="hasRendRisikoDaten" class= "box__container">
        <div class = "flex-c-c">
          <InputCheckBoxItem class="child-w3" label="&nbsp;nach Agio" v-model="cbAgio"/>
          <InputCheckBoxItem class="child-w3" label="&nbsp;nach Steuern" v-model="cbSteuern"/>
          <InputCheckBoxItem class="child-w3" label="&nbsp;Rendite p.a." v-model="cbRenditePA"/>
          <InputCheckBoxItem class="child-w3" label="&nbsp;Tendenz" v-model="cbTendenz"/>
        </div>
        <div>
          <ChartYieldRisk ref="riskChart" 
            :chartParam="chartParam"
            :chartSeries="rendRisikoDaten" 
            :customColors="getColorsOrder()"
            />
          <ChartLegend v-if="renditeRiskChart && rendRisikoDaten.length"
            :showAsTable="false"
            :chartSeries="rendRisikoDaten"
            :lineChart="renditeRiskChart"
          />
        </div>
      </div>
      <FondsVergleichNoData v-else @click="emitEinstellungStep" />
    </template>

  </div>
  <div v-else>
    <GhostLoading useBoxContainer>
      <Block type="title" />
      <Block height="150" />
    </GhostLoading>
    <GhostLoading useBoxContainer>
      <Block type="title" />
      <Block height="75" />
    </GhostLoading>
    <GhostLoading useBoxContainer>
      <Block type="title" />
      <Block type="table-row" />
      <Block type="table-row" />
      <Block type="table-row" />
    </GhostLoading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FONDSVERGLEICH_TYPES from "@/store/fondsvergleich/types";
import WERTPAPIERINFO_TYPES from "@/store/wertpapierinfo/types";
import LOG_TYPES from '@/store/log/types';
import BaseButton from '@/components/core/BaseButton.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import ChartFunds from '@/components/charts/ChartFunds.vue';
import ChartLegend from "@/components/charts/ChartLegend.vue";
import {utils, COLOR_STANDARD} from '@/components/charts/chartFondsUtil.js';
import ChartYieldRisk from '@/components/charts/ChartYieldRisk.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import Vue from 'vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import WertpapierSearch from '@/components/wertpapierinfo/WertpapierSearch.vue';
import {preformancePerAnno} from "@/helpers/commonfunctions"
import dayjs from 'dayjs';
import {parse, formatNumber} from '@/helpers/number-formatter.js';
import InputChips from '@/components/core/input-chips/InputChips.vue';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import InputField from '@/components/core/forms/InputField.vue'
import { PhTrash, PhFileArrowDown } from "phosphor-vue"
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import FondsVergleichNoData from '@/components/beratung/FondsVergleichNoData.vue';
import CORE_TYPES from '@/store/core/types';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import ROLES, { VIEW_ROLES } from '@/router/roles';
import EVENT_BUS, { FONDSVERGLEICH_WEITER, FONDSVERGLEICH_RESET_VERGLEICH, FONDSVERGLEICH_GET_VERGLEICH, FONDSVERGLEICH_DO_ACTION } from '@/event-bus';
import BigTable from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, DATE_COLUMN, ACTION_COLUMN, SLOT_COLUMN, FIXED_LEFT, FIXED_RIGHT, Icon, ICON_COLUMN, NUMBER_COLUMN, CURRENCY_COLUMN, INTEGER_COLUMN, FLOAT_COLUMN, DATETIME_COLUMN} from '@/components/bigTable/BigTable.vue';

const MODAL_SOURCE = 'modal';
const PAGE_SOURCE = 'page';

export default {
  name: 'CompareFunds',
  components: {
    BaseButton,
    ComboBox,
    ChartFunds,
    ChartYieldRisk,
    ChartLegend,
    DatePickerField,
    InputCheckBoxItem,
    InputRadioBoxGroup,
    Table,
    WertpapierSearch,
    InputChips,
    InputField,
    GhostLoading,
    Block,
    FondsVergleichNoData,
    BigTable,
  },
  props: {
    stepKey: {
      type: String,
      default: 'FILTER',
    },
    settings: {
      type: Object,
    },
    colorsOrder: {
      type: Object,
      default: () => ({})
    },
    setColorsOrder: {
      default: () => ({})
    },
    source: {
      type: String,
      default: PAGE_SOURCE,
    },
  },
  created() {
      this.FIXED_LEFT = FIXED_LEFT;
      this.FIXED_RIGHT = FIXED_RIGHT;
      this.STRING_COLUMN = STRING_COLUMN;
      this.NUMBER_COLUMN = NUMBER_COLUMN;
      this.CURRENCY_COLUMN = CURRENCY_COLUMN;
      this.DATE_COLUMN = DATE_COLUMN;
      this.FLOAT_COLUMN = FLOAT_COLUMN;
      this.DATETIME_COLUMN = DATETIME_COLUMN;
      this.SLOT_COLUMN = SLOT_COLUMN;
      this.INTEGER_COLUMN = INTEGER_COLUMN;
  },    
  data: function() {
    return {
      MODAL_SOURCE,
      PAGE_SOURCE,
      busy: 0,
      indices: [],
      ixIndex: -1,
      risikoJahr: '3',
      risikoOption: [],
      wentwVal: '',
      wentwOption: [],
      wentwJahr: '',
      wentwJahrOpt: [],
      wentwChartVal: 1,
      indicesWE: [],
      steuersatz: null,
      cbAnpassen: false,
      zeitraum_von: null,
      zeitraum_bis: null,
      legendWESort: null,
      errStr: '',
      cbAgio: false,
      cbSteuern: false,
      cbRenditePA: false,
      cbTendenz: true,
      minStartDate: null,
      maxStartDate: null,
      startDate: '',
      showAuswahl: true,
      showSettings: true,
      showDatenAsCols: true,
      rrChart: null,
      deleteId: null,
      configMess: {
        text: '',
        showConfirmButton: false,
        labelButtonCancel: ''
      },
      contentReady: false,
      investmentFundsHeaders: {
          lockedLeft: [
              SlotColumn("fondsAmpel", "", 80, 0).makeAlwaysVisible(),
              TextColumn("isin", "ISIN", 150).makeAlwaysVisible(),
              TextColumn("fondsname", "Wertpapier", 200),
          ],
          center: [
              TextColumn("aa_netto", "Agio", 150),
              SlotColumn("aa_manuell", "manuelles Agio", 150),
          ],
          lockedRight: [
              ActionColumn("actions", ""),
          ],
      },
      keysTHStammdaten: [],
      scrollFirstColunm: false,
      scrolling: false,
      scrollTo: '',
      stammdaten: {rowsCount: 0, headers: [], rows: []},
      colorIndex: {},
      renditeRiskChart: null,
      selectedWertpapier: [],
      delSelection: [],
      selectedFundsHeaders: {
        lockedLeft: [
            SlotColumn("fondsname", "Wertpapier", 200).makeAlwaysVisible(),
        ],
        center: [
            TextColumn("isin", "ISIN", 150),
        ],
        lockedRight: [
            ActionColumn("actions"),
        ],
      },
      tableHeaderActions: [PageHeaderSimpleAction('H-SCROLL', 'Horizontales Scrollen umschalten')],
      chartParam: {hasYears: false, hasPerAnno: false, years: [], hasLegend: false },
      wpScrollH: false,
      bigTableHeaders: [],
      bigTableRows: [],
      sortingState: { key: "wertpapier", direction: "desc" },
      minNumRows: 100,
      bigTablepage: 0,
      tableId: 'da141460-2de8-415e-827a-97f795928cf4',
      tableTitle: 'Stammdaten',
    }
  },
  computed: {
    ...mapGetters({      
      session: FONDSVERGLEICH_TYPES.GETTERS.ACT,
      funds: FONDSVERGLEICH_TYPES.GETTERS.GET_FUNDS,
      unsel: FONDSVERGLEICH_TYPES.GETTERS.GET_UNSEL_FUNDS, 
      zoom: FONDSVERGLEICH_TYPES.GETTERS.GET_START_END_ZOOM,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      screenSize: CORE_TYPES.GETTERS.SCREEN_WIDTH, 
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      getWPIAct: WERTPAPIERINFO_TYPES.GETTERS.ACT,
    }),
    shouldShowBigTable() {
      return !this.$isSmallScreen && this.bigTableRows?.length;
    },
    wentwChartOpt() {
      return [{label:'Performance', value: 1}].concat(
        this.session.settings.berechnungsbasis == 'FW' ? [] : [{label:'Einmalanlage', value: 2}, {label:'Sparplan monatlich', value: 3}
        , {label:'Sparplan vierteljährlich', value: 4}, {label:'Sparplan jährlich', value: 5}]
      )
    },
    filterdata() {
      return this.getWPIAct('CompareFunds..');
    },
    isSmallOrMediumScreen() {
      return this.isSmallScreen || this.isMediumScreen;
    },    
    isMediumScreen() {
      return this.screenSize === 'md';
    },   
    ergebnisLoading() {
      return this.session?.ergebnisLoading || {};
    },
    ergebnisLoadingActive() {
      return Object.values(this.ergebnisLoading).some(v => v === true);
    },
    fundsRows() {
      return (this.funds || []).map(fund => ({
        ...fund,
        actions: [SimpleAction('DELETE', 'PhTrash', 'entfernen')],
      }));
    },     
    tableActions() {
      return [
        PageHeaderSimpleAction('DEPOT', 'Depotbestand übernehmen').withVisible(() => this.isCusmomer),
        PageHeaderSimpleAction('DEL_SELECTION', 'Auswahl entfernen'),
      ]

    },  
    isCusmomer() {
      return this.hasRoles([
        VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS  ])
    },    
    allFunds() {
      return this.selFunds.concat(this.unselFunds);
    },
    fvp() {
      if (process.env.VUE_APP_USER === 'hannes' && this.session) console.log('session', JSON.parse(JSON.stringify(this.session)) );
      return this.session?.fvp;
    },
    selFunds() {
      return this.funds || [];
    },
    unselFunds() {
      return this.unsel || [];
    },
    indicesOption() {
      return this.settings?.Indizes?.map(i => ({label: i[1], value: i[0]}));
    },
    indicesWEOption() {
      return this.settings?.Indizes?.filter(i => -1 === this.indices.findIndex( f => f[0] === i[0]))
          .map(i => ({label: i[1], value: i[0]}));
    },
    indicesSelected: {
      get() {
        return this.indices.map(i => ({label: i[1], value: i[0]}));
      },
      set(value) {
        this.indices = value.map(i => ([i.value, i.label]));
      }
    },
    indicesWESelected: {
      get() {
        return this.indicesWE.map(i => ({label: i[1], value: i[0]}));
      },
      set(value) {
        this.indicesWE = value.map(i => ([i.value, i.label]));
      }
    },
    isinRow() {
      if (this.session?.fundsdata?.showAsRows) {
        const result = Object.keys(this.session.fundsdata.showAsRows.headers);
        return result.filter(i => i !== 'label');
      }
      return [];
    },
    hasStammdatenData() {
      return !!this.stammdaten?.rowsCount;
    },
    chartWertentwicklung() {
      if (this.session?.wertentw?.chartSerie?.length && this.cbRenditePA !== null) {
        const invest = [
          (this.wentwChartVal === 2 ? 1000 : (this.wentwChartVal > 2 ? 0 : 100 )),
          (this.wentwChartVal === 3 ? 100 : 0),
          (this.wentwChartVal === 4 ? 100 : 0),
          (this.wentwChartVal === 5 ? 100 : 0),
        ];
        
        const series = this.session.wertentw.chartSerie.map(serie => {
          let agio = null;
          if ( this.cbAgio ) {
            const fonds = this.selFunds.find(f => f.fondsname === serie.title);
            if ( fonds ) {
              agio = fonds.aa_manuell ? parse(fonds.aa_manuell, 2) : fonds.aa_netto;
            }
          }
          return {
            data: serie.data,
            name: serie.title.replace(/\n/g, ''),
            agio,
            isin: serie.isin,
          }

        })
        let sort = 'perf';
        switch (this.settings.WertEntwSort) {
          case 'FONDSNAME':
            sort = 'name';
            break;
          case 'VOLA':
            sort = 'vola';
            break;
        }
        const steuersatz = this.cbSteuern && this.steuersatz ? this.steuersatz : null;
        const format = this.wentwChartVal === 1 ? 'percent' : 'currency';
        return {series, invest, steuersatz, sort, format, legendWESort: this.legendWESort }
      }
      return {};
    },
    hasWertentwicklungData() {
      return !!this.session?.wertentw?.chartSerie?.length;
    },
    rendRisikoDaten() {
      if (this.session.renditeRisiko?.length) {
        const renditeRisiko = JSON.parse(JSON.stringify(this.session.renditeRisiko));
        const steuersatz = this.cbSteuern && this.steuersatz ? this.steuersatz : null;
        const risikoJahr = parseInt(this.risikoJahr, 10);
        let xMin = 0, xMax = 0, yMin = 0, yMax = 0, cnt = 0, dvola = 0, dperf = 0;
        renditeRisiko.forEach( rr => {
          let agio = null;
          if ( this.cbAgio ) {
            const fonds = this.selFunds.find(f => f.fondsname === rr.name);
            if ( fonds ) {
              agio = fonds.aa_manuell ? parse(fonds.aa_manuell, 2) : fonds.aa_netto;
            }
          }
          if ( !this.cbTendenz ) {
            rr.data.splice(1);
          }
          rr.color = this.getColor(this.colorIndex[rr.name])
          rr.data.forEach( rd => {
            if ( agio !== null ) {
              rd[1] = (100 + rd[1]) / (1 + agio / 100) - 100;
            }
            if ( steuersatz !== null ) {
              rd[1] = rd[1] * (1 - steuersatz / 100);
            }
            if ( this.cbRenditePA) {
              rd[1] = preformancePerAnno(risikoJahr, rd[1]);
            }
            rd[1] = Math.round( rd[1] * 100 ) / 100;
            if (cnt === 0) {
              xMin = rd[0], xMax = rd[0];
              yMin = rd[1], yMax = rd[1];
              cnt++;
            } else {
              xMin = Math.min(xMin, rd[0]), xMax = Math.max(xMax, rd[0]);
              yMin = Math.min(yMin, rd[1]), yMax = Math.max(yMax, rd[1]);
            }
          })
          dvola += rr.data[0][0];
          dperf += rr.data[0][1];
        });
        dvola = Math.round(dvola / renditeRisiko.length * 100 ) / 100;
        dperf = Math.round(dperf / renditeRisiko.length * 100 ) / 100;
        renditeRisiko[0].minmax = [xMin, xMax, yMin, yMax];
        renditeRisiko.push({
          name: 'Durchschnittswert',
          data: [[dvola, dperf, ['Durchschnittswert', renditeRisiko[0].data[0][2][1] ]]],
          markLine: {
            symbol:['none', 'none'],
            lineStyle: {
              type: 'solid',
              color: '#ccc'
            },
            data: [{ yAxis: dperf }, { xAxis: dvola }]
          },
          color: '#ccc'
        })
        return renditeRisiko;
      }
      return [];
    },
    hasRendRisikoDaten() {
      return !!this.session.renditeRisiko?.length;
    },
  },
  watch: {
    'selFunds': 'getMinMaxStartDatum',
    'showDatenAsCols': 'setStammdaten',
    'session': 'setStammdaten',
    'rendRisikoDaten': 'setRenditeRiskChart',
    'stepKey': 'prepareErgebnisData',
    'ergebnisLoading': 'prepareErgebnisData',
    'cbAnpassen': 'changeStartDatum',
    'wentwJahr': 'changeStartDatum',
    filterdata() {
      if (this.filterdata && !this.filterdata.maxCountRows) {
        this.filterdata.maxCountRows = 1000;
      }
    },
  },
  methods: {
    onClickHeader(headerMetadata) {
        this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
    },      
    registerEventBus() {
      EVENT_BUS.$on(FONDSVERGLEICH_RESET_VERGLEICH, () => {
        this.resetVergleich();
      });
      EVENT_BUS.$on(FONDSVERGLEICH_GET_VERGLEICH, () => {
        this.getVergleich();
      });      
      EVENT_BUS.$on(FONDSVERGLEICH_WEITER, () => {
        this.setAuswahl();
      });
      EVENT_BUS.$on(FONDSVERGLEICH_DO_ACTION, (args) => {
        this.doAktion(args.fvp, args.action);
      });      
    },   
    unregisterEventBus() {
      EVENT_BUS.$off(FONDSVERGLEICH_RESET_VERGLEICH);
      EVENT_BUS.$off(FONDSVERGLEICH_GET_VERGLEICH);
      EVENT_BUS.$off(FONDSVERGLEICH_WEITER);
      EVENT_BUS.$off(FONDSVERGLEICH_DO_ACTION);
    },      
    gotoFondsinfo(isin) {
      this.$addBreadcrumb({
        breadcrumb: 'Fondsinfo',
        fullPath: this.$route.fullPath,
      });
      const path = `/shared/vermogensubersicht/fondsinfo/${isin}`;
      this.$router.push({ path });
    },    
    setRenditeRiskChart() {
      this.$nextTick( () => {
        if (this.rendRisikoDaten?.length && this.stepKey === 'RENDITERISIKO' ){
          this.renditeRiskChart = this.$refs?.riskChart?.$refs?.scatterChart
        } else {
          this.renditeRiskChart = null;
        }
      })
      
    },
    setStammdaten() {
      if (this.session?.fundsdata?.showAsCols ) {
        if (this.showDatenAsCols) {
          this.scrolling = false;
          let lockedLeft = null;
          let center = null;
          if (this.scrollFirstColunm) {
            center = [].concat( this.session.fundsdata.showAsCols.headers.lockedLeft)
                        .concat(this.session.fundsdata.showAsCols.headers.center);
            center[0].neverHide = false;
            lockedLeft = [];
          } else {
            lockedLeft = [].concat( this.session.fundsdata.showAsCols.headers.lockedLeft);
            lockedLeft[0].neverHide = true;
            center = [].concat( this.session.fundsdata.showAsCols.headers.center);

            this.setBigTableHeadersAndRows();

          }
          const lockedRight = [].concat( this.session.fundsdata.showAsCols.headers.lockedRight);
          this.stammdaten = {
            rowsCount: this.session.fundsdata.showAsCols.records.length,
            headers: {lockedLeft, center, lockedRight},
            rows: this.session.fundsdata.showAsCols.records,
          };
        } else {
          this.stammdaten = {
            rowsCount:  this.session.fundsdata.showAsRows.records.length,
            headers: this.session.fundsdata.showAsRows.headers,
            records: this.session.fundsdata.showAsRows.records,
          };
        }
      } else {
        this.stammdaten = {rowsCount: 0, headers: [], rows: []};
      }
    },
    setBigTableHeadersAndRows() {
      let totalWidth = 0;
      const defaultWidth = 200;
      const clientAreaWidth = 1774;
            

      let headersLength = this.session.fundsdata.showAsCols.headers.lockedLeft?.length + 
          this.session.fundsdata.showAsCols.headers.center?.length +
              this.session.fundsdata.showAsCols.headers.lockedRight?.length;

      // Calculate total width based on default width
      totalWidth = headersLength * defaultWidth;

      let columnWidth = totalWidth >= clientAreaWidth ? defaultWidth : Math.floor(clientAreaWidth / headersLength);

      const bigTableFixedLeft = this.session.fundsdata.showAsCols.headers.lockedLeft.map((header, index) => {
        const precisionMetadata = header?.rowElement?.props?.precision?.default;
        const isNumber = precisionMetadata && precisionMetadata > 1;
        const isInteger = precisionMetadata && precisionMetadata == 0;              

        return {
          label: "Wertpapier", //header.label,
          key: header.key,
          width: columnWidth,
          type: isNumber ? NUMBER_COLUMN : (isInteger ? INTEGER_COLUMN : STRING_COLUMN ),
          position: FIXED_LEFT
        };
      });

      const bigTableCenterColumns = this.session.fundsdata.showAsCols.headers.center.map((header, index) => {
        const precisionMetadata = header?.rowElement?.props?.precision?.default;
        const isNumber = precisionMetadata && precisionMetadata > 1;
        const isInteger = precisionMetadata && precisionMetadata == 0;
        const slotColumns = ['AUFLAGEDATUM', 'KURS1'];

        let columnType = STRING_COLUMN;

        if (isNumber) {
          columnType = NUMBER_COLUMN;
        } else if (isInteger) {
          columnType = INTEGER_COLUMN;
        } else if (slotColumns.some (sc => sc == header.key)) {
          columnType = SLOT_COLUMN;
        }

        return {
          label: header.label,
          key: header.key,
          width: columnWidth,
          type: columnType,
          visible: true,
        };
      });

      this.bigTableHeaders = [...bigTableFixedLeft, ...bigTableCenterColumns];
      this.bigTableRows = this.session.fundsdata.showAsCols.records;
    },
    viewDocument(href) {
      viewDocument({href});
    },
    setAaManuell(isin, aa_manuell) {
      let row = this.selFunds.find(f => f.isin == isin);
      if (row) {
        row.aa_manuell = aa_manuell;
        this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FUNDS, this.selFunds);
        this.setSettings(false);
      } else {
        row = this.unselFunds.find(f => f.isin == isin);
        if (row) {
          row.aa_manuell = aa_manuell;
        this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_UNSEL_FUNDS, this.unselFunds);
        this.setSettings(false);
        }
      }
    },
    putConfig(arg) {
      if ( arg && arg.WertEntwZeitArt) {
        this.risikoJahr = arg.RisikoJahreValue;
        this.risikoOption = arg.RisikoJahreOptions;
        this.wentwVal = arg.WertEntwZeitArt;
        this.wentwOption = arg.WertEntwOptions;
        this.wentwJahr = arg.WertEntwJahreValue;
        this.wentwJahrOpt = arg.WertEntwJahreOptions;
        this.steuersatz = arg.steuersatz;
        this.zeitraum_bis = arg.zeitraum_bis ? this.strToDate(arg.zeitraum_bis) : new Date();
        this.zeitraum_von = arg.zeitraum_von ? this.strToDate(arg.zeitraum_von) : new Date(this.zeitraum_bis.getFullYear() - 1, this.zeitraum_bis.getMonth(), this.zeitraum_bis.getDate());
        this.indices = arg.indices || [];
      }
    },
    // selectedWP({selected, rows}) {
    //   if (this.stepKey !== 'FILTER') {
    //     return;
    //   }
    //   if (selected.length) {
    //     selected = selected.map(r => {
    //       const actions = [SimpleAction("DELETE", PhTrash, "Löschen")]
    //         .concat( r.actions.map(a => SimpleAction(a.legend.key, PhFileArrowDown, a.legend.label)))
    //       return {...r, actions}
    //     })
    //   }
    //   const unselected = rows.filter(row => !selected.some(r => r.isin == row.isin ));
    //   let selFunds = this.selFunds.filter(row => !unselected.some(r => r.isin == row.isin ));
    //   selFunds = selFunds.concat(selected.filter(row => !selFunds.some(r => r.isin == row.isin)));
    //   this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FUNDS, selFunds);
    //   this.setSettings(false);
    // },
    addSelectedFonds() {
      if (this.selectedWertpapier?.length) {
        const arr = Object.assign([], this.selFunds);
        this.selectedWertpapier?.forEach(sel => {
          if (!this.selFunds?.some(fond => fond?.isin === sel.isin)) {
            if (sel._fondsData?.startDatum?.length == 10) {
              sel.startDatum = sel._fondsData.startDatum;
            }
            arr.push(sel);
          }
        })
        // if (!this.selFunds.find( f => f.isin == row.isin)) {
        //   this.selFunds.push(row)
        // }
        this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FUNDS, arr);
        this.saveFVP();
      }
    },
    selectedRow(selected) {
      if (this.stepKey !== 'FILTER') {
        return;
      }
      this.selectedWertpapier = selected || [];
      // this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FUNDS, selected);
      // this.setSettings(false);
    },
    selectedFunds(selected) {
      let selFunds = selected;
      let unselFunds = this.allFunds;
      if (selected.length) {
        unselFunds= unselFunds.filter(row => !selFunds.some(r => r.isin == row.isin));
      }
      this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FUNDS, selFunds);
      this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_UNSEL_FUNDS, unselFunds);
      this.setSettings(false);
    },
    setAuswahl() {
      this.emitEinstellungStep();
      this.$refs.wp_filter.setFilter([]);
    },
    emitEinstellungStep() {
      this.$emit('setStep', 'EINSTELLUNG');
    },
    resetVergleich() {
      this.busy = 0;
      this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.RESET_ACT );
      this.$emit('setStep', 'FILTER');
    },
    delFunds(row) {
      const index = this.selFunds.findIndex(f => f.isin === row.isin)
      if (index != -1) {
        const selFunds = this.selFunds;
        selFunds.splice(index, 1);
        this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FUNDS, selFunds);
        this.setSettings(false);
        this.saveFVP();
      } else {
        const index = this.unselFunds.findIndex(f => f.isin === row.isin);
        if (index != -1) {
          const unselFunds = this.selFunds;
          unselFunds.splice(index, 1);
          this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_UNSEL_FUNDS, unselFunds);
          this.setSettings(false);
          this.saveFVP();
        }
      }
    },
    wpHeaderAction({ key, value }) {
      if (key == 'H-SCROLL') {
        this.wpScrollH = !this.wpScrollH;
      }
    },
    async handleHeaderAction({ key, selection }) {
      if (key == 'DEL_SELECTION') {
        if (selection?.length) {
          let isins = [], index;
          const selFunds = this.selFunds;
          selection.forEach(row => {
            index = this.selFunds.findIndex(f => f.isin === row.isin)
            if (index != -1) {
              isins.push(row.isin);
              selFunds.splice(index, 1);
            }
          })
          this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FUNDS, selFunds);
          this.setSettings(false);
          return true;
        }
      } else if (key == 'DEPOT') {
        const result = await this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.GET_DEPOT );
        if (result.data?.fonds?.length) {
          let index = 0;
          const selFunds = this.selFunds;
          const unselFunds = this.unselFunds;
          result.data.fonds.forEach(row => {
            index = selFunds.findIndex(f => f.isin === row.isin)
            if (index == -1) {
              selFunds.push(row);
            }
            if (unselFunds.length ){
              index = this.unselFunds.findIndex(f => f.isin === row.isin)
              if (index != -1) {
                unselFunds.splice(index, 1);
              }
            }
          })
          this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FUNDS, selFunds);
          if (unselFunds.length != this.unselFunds){
            this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_UNSEL_FUNDS, unselFunds);
          }
          this.setSettings(false);
          return true;
        }
        return false;
      }
    },
    timeToStr(time) {
      if (!time) {
        return '';
      }
      const result = dayjs( new Date(time)).format('DD.MM.YYYY');
      return result === '01.01.1970' ? '' : result ;
    },
    dateToStr(dat) {
      if (!dat) {
        return '';
      }
      const result = dayjs(dat).format('DD.MM.YYYY');
      return result === '01.01.1970' ? '' : result ;
    },
    strToDate(dt) {
      if (typeof dt === 'string') {
        const part = dt.split('.').map( v => parseInt(v, 10));
        return new Date(part[2], part[1] - 1, part[0]);
      }
      return dt;
    },
    showValue(value) {
      return value || 'k.A.';
    },
    setSettings(valid) {
      if (typeof this.zeitraum_von === 'string' || typeof this.zeitraum_bis === 'string') {
        return null;
      }
      const args = Object.assign({}, this.settings, {
        RisikoJahreValue: this.risikoJahr,
        WertEntwZeitArt: this.wentwVal,
        WertEntwJahreValue: this.wentwJahr,
        zeitraum_bis: this.dateToStr(this.zeitraum_bis),
        zeitraum_von: this.dateToStr(this.zeitraum_von),
        cbAnpassen: this.cbAnpassen,
        funds: this.selFunds,
        unsel: this.unselFunds,
        indices: this.indices
      });
      const fvp = this.fvp;
      fvp.params = this.getFVParams();
      this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {settings: args, fvp: fvp} );
      if ( valid && typeof valid === 'boolean' || this.errStr ) {
        this.errStr = '';
        
        const getTime = function(dt) {
          if (typeof dt === 'string') {
            const part = dt.split('.').map( v => parseInt(v, 10));
            return new Date(part[2], part[1] - 1, part[0]).getTime();
          }
          return dt.getTime();
        }
        if (this.wentwVal === this.wentwOption[1].value && getTime(this.zeitraum_bis) <= getTime(this.zeitraum_von)) {
          this.errStr = 'Falsche Eingaben für Zeitraum.';
        }
        return this.errStr;
      }
      return null;
    },
    setFVP(fvp) {
      const arg = fvp.params;
      const zeitraum_bis = !arg.zeitraum_bis ? new Date() : this.strToDate(arg.zeitraum_bis);
      const zeitraum_von = !arg.zeitraum_von ? new Date(zeitraum_bis.getFullYear() - 1, zeitraum_bis.getMonth(), zeitraum_bis.getDate()) : this.strToDate(arg.zeitraum_von);
     
      this.risikoJahr = arg.RisikoJahreValue;
      this.wentwVal = arg.WertEntwZeitArt;
      this.wentwJahr = arg.WertEntwJahreValue;
      this.zeitraum_bis = zeitraum_bis;
      this.zeitraum_von = zeitraum_von;
      this.cbSteuern = arg.cbSteuern;
      this.cbAgio = arg.cbAgio;
      this.cbRenditePA = arg.cbRenditePA;
      this.cbAnpassen = arg.cbAnpassen;
      this.wentwChartVal = this.session.settings.berechnungsbasis == 'FW' ? 1 : arg.wentwChartVal;
      this.legendWESort = arg.legendWESort;
      this.indices = [];
      // this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FUNDS, []);
      // this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_UNSEL_FUNDS, []);
      if ( arg.indices) {
        arg.indices.forEach( isin => {
          const index = this.settings.Indizes?.find( search => search[0] === isin);
          if (index) {
            this.indices.push(index);
          }
        });
      }
        this.contentReady = true;
    },
    getRows(data, fonds) {
      const rows = [];
      fonds.forEach( f => {
        const row = data.records.find(r => r.isin === f[0]);
        if (row) {
          if (f.length === 2 && f[1] !== null && f[1] !== undefined) {
            if ( typeof f[1] === 'number') {
              row.aa_manuell = formatNumber(f[1], 2);
            } else {
              row.aa_manuell = f[1];
            }
          }
          if (row._fondsData?.startDatum?.length == 10) {
            const teile = row._fondsData.startDatum.split('.').map(t => parseInt(t,10));
            row.timeStartDatum = new Date(teile[2], teile[1]-1, teile[0]).getTime();
          }
          if (row.actions && Array.isArray(row.actions) && row.actions.length) {
            try {
              const actions = JSON.parse(row.actions[0].replace(/'/g, '"'));
              row.actions = [
                  SimpleAction("DELETE", PhTrash, "Löschen"),
              ];
              if (actions.length) {
                const test = {
                  'Factsheet': 'FACTSHEET',
                  'Factsheet KAG': 'FACTSHEET_KAG',
                  'KID': 'KID'
                }
                actions.forEach( (a) => {
                  if (test[a.text]) {
                    row[test[a.text]] = a.href;
                    row.actions.push(SimpleAction(test[a.text], PhFileArrowDown, a.text));
                  }
                })
              }
            } catch (e) { console.error(e); }
          }
          rows.push(row);
        }
      })
      return rows;
    },
    async doAktion(fvp, action) {
      const strData = JSON.stringify(this.chartWertentwicklung);
      const fondsvergleich = utils.initFondsvergleich(strData);
      const arg = {
        RisikoJahreValue: this.risikoJahr,
        WertEntwZeitArt: this.wentwVal,
        WertEntwJahreValue: this.wentwJahr,
        zeitraum_bis: this.dateToStr(this.zeitraum_bis),
        zeitraum_von: this.dateToStr(this.zeitraum_von),
        cbSteuern: this.cbSteuern,
        cbAgio: this.cbAgio,
        cbRenditePA: this.cbRenditePA,
        cbAnpassen: this.cbAnpassen,
        wentwChartVal: this.wentwChartVal,
        indices: [],
        funds: [],
        unsel: [],
        fv_id: fvp.id ? '' + fvp.id : '',
        fv_description: fvp.description,
        chartWE: {
          selection: [],
          perf: {},
          vola: {},
          dateMin: fondsvergleich.dTime[this.zoom.ixVon == null ? 0 : this.zoom.ixVon],
          dateMax: fondsvergleich.dTime[this.zoom.ixBis == null ? fondsvergleich.dTime.length - 1 : this.zoom.ixBis],
        },
        action: action
      };
      fondsvergleich.series.forEach( s => {
        if (s.name !== 'Anlagesumme') {
          const name = s.name.replace(/ Index$/, '\n Index');
          arg.chartWE.selection.push(s.isin);
          if (this.zoom?.addOnRows && this.zoom.addOnRows[s.name]){
            const d = this.zoom.addOnRows[s.name];
            arg.chartWE.perf[name] = (this.cbRenditePA ? d.wepa : d.we) + 0.00001; // sicher Double auf dem Server
            arg.chartWE.vola[name] = d.vola + 0.00001;
            arg.chartWE.perf['0'+name] = d.we + 0.00001;
            arg.chartWE.perf['1'+name] = d.wepa + 0.00001;
          } else {
            arg.chartWE.perf[name] = s.wes[this.cbRenditePA ? 1 : 0] + 0.00001;
            arg.chartWE.vola[name] = s.vola + 0.00001;
            arg.chartWE.perf['0'+name] = s.wes[0] + 0.00001;
            arg.chartWE.perf['1'+name] = s.wes[1] + 0.00001;
          } 
        }
      })
      if ( action === 'pdf') {
        let response = await this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.PDF, arg );
        viewDocument({
          data: response.pdf,
          filename: 'Fondsvergleich.pdf',
          contentType: 'application/pdf',
        }, true);
      } else if ( action === 'xls') {
        let response = await this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.XLS, arg );
        viewDocument({
          data: response.xls,
          filename: 'Fondsvergleich.xls',
          contentType: 'application/xls',
        }, true);
      } else if ( action === 'mail') {
        let response = await this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.PDF, arg );
        response.node = false;
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, [response]);
        this.$router.push({ path: `/communication/mailcomposer-single` });
        setTimeout(() => {
          this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_INIT, {
            subject: response.subject,
            html: response.html,
            participants: [response.receiver]});
        }, 100);
        
      }
    },
    getFVParams() {
      const arg = {
        RisikoJahreValue: this.risikoJahr,
        WertEntwZeitArt: this.wentwVal,
        WertEntwJahreValue: this.wentwJahr,
        zeitraum_bis: this.dateToStr(this.zeitraum_bis),
        zeitraum_von: this.dateToStr(this.zeitraum_von),
        cbSteuern: this.cbSteuern,
        cbAgio: this.cbAgio,
        cbRenditePA: this.cbRenditePA,
        cbAnpassen: this.cbAnpassen,
        wentwChartVal: this.wentwChartVal,
        legendWESort: this.legendWESort,
        indices: [],
        funds: [],
        unsel: [],
      };
      if ( this.indices) {
        this.indices.forEach( index => {
          arg.indices.push(index[0]);
        });
      }
      if ( this.selFunds.length) {
        this.selFunds.forEach( row => {
          arg.funds.push([row.isin, typeof row.aa_manuell === 'string' ? parse(row.aa_manuell, 2) : row.aa_manuell]);
        });
      }
      if ( this.unselFunds.length) {
        this.unselFunds.forEach( row => {
          arg.unsel.push([row.isin, typeof row.aa_manuell === 'string' ? parse(row.aa_manuell, 2) : row.aa_manuell]);
        });
      }
      return arg;
    },
    saveFVP() {
      const arg = this.getFVParams();
      arg.fv_id = this.fvp.id ? '' + this.fvp.id : '';
      arg.fv_description = this.fvp.description || 'Unbennant';
      this.saveFVParams(arg);
    },
    getColor(colorIndex) {
      return COLOR_STANDARD[colorIndex % (COLOR_STANDARD.length - 1)];
    },
    addWEIntex(arg) {  
      const result = this.session;
      if (result.wertentw?.chartSerie ) {
        const series = [].concat(result.wertentw.chartSerie);
        const serie = result.wertentw.indicesSeries[arg.value];
        if (!serie) {
          return;
        }
        serie.title = serie.title.replace(/[\n]/g, '');
        for( let k=0, cell1, cell2, nr1, nr2; k < series[0].data.length; k++) {
          if ( serie.data.length <= k ) {
            serie.data.push( [series[0].data[k][0], null]);
          } else if ( series[0].data[k][0] !== serie.data[k][0] ) {
            cell1 = series[0].data[k][0];
            cell2 = serie.data[k][0];
            if (typeof cell1 === 'string'){
              nr1 = parseInt(cell1.substr(6)+cell1.substr(3,2)+cell1.substr(0, 2), 10);
            } else {
              cell1 = new Date(cell1);
              nr1 = cell1.getFullYear() * 10000 + (cell1.getMonth() + 1) * 100 + cell1.getDate();
            }
            nr2 = parseInt(cell2.substr(6)+cell2.substr(3,2)+cell2.substr(0, 2), 10);
            if ( nr1 < nr2 ) {
              serie.data.splice(k, 0, [series[0].data[k][0], null] );
            } else {
              while( nr1 > nr2 ) {
                serie.data.splice(k, 1 );
                cell2 = serie.data[k][0];
                nr2 = parseInt(cell2.substr(6)+cell2.substr(3,2)+cell2.substr(0, 2), 10);
              }
            }
          }
        }
        if ( serie.data.length > series[0].data.length) {
          serie.data.splice(series[0].data.length);
        }
        if (!this.colorIndex[serie.title]) {
          this.colorIndex[serie.title] = Object.values(this.colorIndex).reduce((m, v) => Math.max(m, v), 0 ) + 1;
        }
        serie.color = this.getColor(this.colorIndex[serie.title]);
        series.push(serie);
        Vue.set(result.wertentw, 'chartSerie', series);
      }
    },
    removeWEIntex(arg) {
      const result = this.session;
      if (result.wertentw?.chartSerie ) {
        const series = [].concat(result.wertentw.chartSerie);
        let idx = series.findIndex( s => s.isin === arg.value);
        if ( idx !== -1 ) {
          while  ( idx !== -1 ) {
            series.splice(idx, 1 );
            idx = series.findIndex( s => s.isin === arg.value);
          }
          Vue.set(result.wertentw, 'chartSerie', series);
        }
      }
    },
    changeLegendSort(legendWESort) {
      this.legendWESort = legendWESort.map( l => l.key).join(',');
      this.saveFVP();
    },
    changeZeitIntervall({ixVon, ixBis, addOnRows, start, end}) {
      this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_START_END_ZOOM, {start, end, ixVon, ixBis, addOnRows});
    },
    getVergleich() {
      const errStr = this.setSettings(true);
      if ( errStr ) {
        return false;
      }
      this.busy = 0;
      this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {
        hasVergleich: false
      });
      this.saveFVP();
      this.setColorIndex();

      if (this.settings.showDaten) {
        this.busy |= 1;
        this.getFondsDaten();
      }
      if (this.settings.showRisiko) {
        this.busy |= 2;
        this.getRendRisiko();
      }
      if (this.settings.showWertEntw) {
        this.busy |= 4;
        this.getWertentw();
      }

      this.selectErgebnisStep();
    },
    setColorIndex() {
      const colorIndex = {};
      let idx = 0;
      this.selFunds.forEach(f => {
        colorIndex[f.fondsname] = idx++;
      })
      this.indices.forEach(i => {
        colorIndex[i[1]] = idx++;
      });
      this.colorIndex = colorIndex;
    },
    selectErgebnisStep() {
      this.$emit('done', {stepKey: 'ERGEBNIS'});
    },
    prepareErgebnisData() {
      const { stepKey } = this;
      this.$nextTick(() => {
        switch (stepKey) {
          case 'STAMMDATEN':
            this.setStammdaten();
            break;
          case 'RENDITERISIKO':
            this.setRenditeRiskChart();
            break;
        }
      })
    },
    vergleichCompleted(mask) {
      this.busy &= mask;
        if (this.busy === 0){
          this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {hasVergleich: true});
        }
    },
    async getFondsDaten() {
      try {
        await this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.FUNDSDATA);
        if (this.session?.fundsdata?.showAsCols) {
          this.setStammdaten();
        }
        this.vergleichCompleted(0xFFFE);
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "getFondsDaten", error});
      }
    },
    async getWertentw() {
      try {
        await this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.WERTENTW);
        if (this.chartWertentwicklung.series) {
          let idx = 0, name='';
          this.chartWertentwicklung.series.forEach(serie => {
            name = serie.name.replace(/ Index$/, '');
            idx = this.colorIndex[name];
            serie.color = this.getColor(idx);
          })
        }
        this.vergleichCompleted(0xFFFB);
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "getWertentw", error});
      }
    },
    async getRendRisiko() {
      try {
        await this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.RENDRISIKO);
        if (this.rendRisikoDaten.length) {
          let idx = 0;
          this.rendRisikoDaten.forEach((serie, ix) => {
            idx = this.colorIndex[serie.name];
            if (idx == null) {
              serie.color = 'rgb(96, 96, 96)'
            } else {
              serie.color = this.getColor(idx);
            }
          })
        }
        this.vergleichCompleted(0xFFFD);
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "getRendRisiko", error});
      }
    },
    async saveFVParams(arg) {
      try {
        let response = await this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.FVPSAVE, arg );
        if (!arg.fv_id && response.fv_id) {
          this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {fvp: {
            id: response.fv_id,
            description: response.fv_description || 'Unbenannt',
            create: response.fv_create,
            params: this.getFVParams(),
          }});
        }
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "getFondsDaten", error});
      }
    },
    getColorsOrder() {
      return this.rendRisikoDaten.map(r => r.color) || null
    },
    getMinMaxStartDatum(rows) {
      this.minStartDate = null;
      this.maxStartDate = null;
      rows.forEach(row => {
        if (!row.timeStartDatum && row.startDatum?.length == 10) {
          const teile = row.startDatum.split('.').map(t => parseInt(t,10));
          row.timeStartDatum = new Date(teile[2], teile[1]-1, teile[0]).getTime();
        }
        if (row.timeStartDatum) {
          if (this.minStartDate === null) {
            this.minStartDate = row.timeStartDatum;
            this.maxStartDate = row.timeStartDatum;
          } else {
            this.minStartDate = Math.min(this.minStartDate, row.timeStartDatum);
            this.maxStartDate = Math.max(this.maxStartDate, row.timeStartDatum);
          }
        }
      })
      this.changeStartDatum();
    },
    changeStartDatum() {
      let wentwDate = new Date();
      if (this.wentwJahr == 'max' || !this.wentwJahr) {
        wentwDate = new Date(this.minStartDate);
      } else {
        wentwDate = new Date(wentwDate.getFullYear() - parseInt(this.wentwJahr, 10), wentwDate.getMonth(), wentwDate.getDate() + 1);
      }
      if (this.cbAnpassen && wentwDate.getTime() < this.maxStartDate) {
        wentwDate = new Date(this.maxStartDate);
      }
      this.startDate = dayjs(wentwDate).format('DD.MM.YYYY');
    }
  },
  destroyed() {
    this.unregisterEventBus();
  },  
  mounted() {
    this.registerEventBus();
    if (this.filterdata && !this.filterdata.maxCountRows) {
      this.filterdata.maxCountRows = 1000;
    }
  },   
  created() {
    if (this.session?.settings ) {
      this.putConfig(this.session.settings);
      this.setFVP(this.fvp);
    }

    this.setColorIndex();
    this.prepareErgebnisData();
  },
  beforeDestroy() {
    this.setSettings(false);
  }
}
</script>

<style scoped> 
::v-deep .indices .input-chips__input-container .input-chips__chips-container > .input-chips__chips > .color-danger {
  color: var(--color-text);
}
.collaps-area, .vergleich-filter {
  box-shadow: -1px 1px 0 1px rgba(0,0,0,0.16);
  padding: 0.5em 0 1em 1em;
}
.vergleich-filter {
  display: flex;
  gap: 3em;
}
.child-w {
  /* width: 25em; */
  width: 100%;
  max-width: 30em;
}
.child-w2 {
  width: 24em;
}
.child-w3 {
  width: 8em;
}
.vergleich-optionen, .vergleich-fonds {
  line-height: 32px;
  display: flex;
  gap: 1.5em;
  align-items: center;
  font-size: 14px;
}
.vergleich-optionen > span, .vergleich-fonds > span {
  flex-grow: 1;
}
.vergleich-fonds .ampel {
  width: 16px;
}
.vergleich-fonds .isin {
  width: 100px;
}
.vergleich-fonds .agio {
  width: 50px;
  text-align: right;
}
.vergleich-fonds .agiom {
  width: 100px;
  text-align: right;
}
.vergleich-optionen .uk-icon-button, .vergleich-fonds .action{
  width: 32px;
  height: 32px;
}
.flex-c-c {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.do-wait {
  display: inline-block;
  transform: translate(8px,3px);
}
.minus {
 width: 16px;
 height: 16px;
 padding: 8px;
 background-position: 8px;
 background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='16' height='16' fill='currentColor'%3E%3Cg%3E%3Cline x1='5' y1='16' x2='27' y2='16' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='3'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
 background-repeat: no-repeat;
}
.ampel-rot, .ampel-gelb, .ampel-gruen, .ampel-none{
 height: 16px;
 width: 16px;
 border-radius: 50%;
}
.ampel-rot{
 background-color: var(--color-danger);
}
.ampel-gelb{
 background-color: var(--color-warning);
}
.ampel-gruen{
 background-color: var(--color-success);
}
.ampel-none{
 background-color: transparent;
}
.btn-clean {
  color: var(--color-text);
}
.scrollColumns {
  background-color: var(--color-primary);
  color: var(--color-primary-text);
  /* border: 1px solid;
  border-color: var(--color-primary); */
  text-align: center;
  font-size: 1em;
  text-decoration: none;
  display: inline-block;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 20px;
}
</style>