import DEPOTPOSITIONS_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';
import { ALL_DEPOTS } from '../../components/depotpositions/types';

export default {
  [DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_SUCCESS](state, payload) {
    state.depotpositions = {
      ...state.depotpositions,
      ...payload
    };
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.RESET_RETRIEVED_DEPOTPOSITIONS](state) {
    Vue.set(state, 'depotpositions', {});
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_CHARTS_SUCCESS](state, payload) {
    const depotid = payload.depotid || ALL_DEPOTS
    Vue.set(state, 'depotpositionsCharts', {
      ...state.depotpositionsCharts,
      [depotid]: {
        ...state.depotpositionsCharts[depotid],
        charts: payload.charts && payload.charts.map(chart => ({[chart.type]: chart})).reduce((acc, curr) => ({...acc, ...curr}), {}),
      }
    });
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_ESG_CHART_SUCCESS](state, payload) {
    const depotid = payload.depotid || ALL_DEPOTS
    Vue.set(state, 'depotpositionsESGChart', {
      ...state.depotpositionsESGChart,
      [depotid]: {
        ...state.depotpositionsESGChart[depotid],
        ...payload,
        type: 'PaiChart'
      }
    });
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.CHANGE_ID](state, payload) {
    const depotid = payload || ALL_DEPOTS
    Vue.set(state, 'id', depotid)
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_CONFIG_SUCCESS](state, payload) {
    state.depotpositionsConfig = payload;
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_TRANSACTIONS_SUCCESS](state, payload) {
    state.transactions = payload;
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_SUCCESS](state, payload) {
    state.fondsinfo = {
      ...state.fondsinfo,
      ...payload
    };
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.GET_LIMITS_SUCCESS](state, payload) {
    state.limits = {
      id: payload.bgsNr,
      data: payload
    };
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.SET_LIMITS_SUCCESS](state, payload) {
    state.limits = {
      id: payload.errorStr ? state.limits?.id : payload.bgsNr,
      data: (payload.errorStr ? {
        ...state.limits.data,
        errorStr: payload.errorStr
      } : payload)
    };
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.GET_LIMITS_200_SUCCESS](state, payload) {
    state.limits200 = {
      id: payload.bgsNr,
      data: payload
    };
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.SET_LIMITS_200_SUCCESS](state, payload) {
    state.limits200 = {
      id: payload.errorStr ? state.limits?.id : payload.bgsNr,
      data: (payload.errorStr ? {
        ...state.limits200.data,
        errorStr: payload.errorStr
      } : payload)
    };
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.SET_SELECTED_CHART_WIDGET](state, { chart }) {
    Vue.set(state, 'selectedChartWidget', chart);
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.GET_DEPOT_PDF_OPTIONS_SUCCESS](state, payload) {
    Vue.set(state, 'depotPdfOptions', { ...payload });
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_DEPOTPOSITIONS_OUT_OF_LIMITS_SUCCESS](state, payload) {
    Vue.set(state, 'depotpositionsOutOfLimits', [ ...payload ]);
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.RETRIEVE_BROKER_CHANGES_SUCCESS](state, payload) {
    Vue.set(state, 'brokerChanges', [ ...payload ]);
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.SET_SHOW_COURTAGE](state, payload) {
    Vue.set(state, 'showCourtage', payload?.show == 1 ? 1 : -1);
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.SET_CALC_COURTAGE](state, payload) {
    Vue.set(state, 'calcCourtage', {...state.calcCourtage, ...payload});
  },
  [DEPOTPOSITIONS_TYPES.MUTATIONS.SET_DEPOTPOSITIONS_GUV](state, payload) {
    Vue.set(state, 'depotGuV', payload);
  },
}