var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vertical-stepper",
      class: {
        "vertical-stepper--collapsed": _vm.collapsed,
        "vertical-stepper--collapsable": _vm.showCollapse
      }
    },
    [
      _vm.showCollapse
        ? _c(
            "div",
            { staticClass: "vertical-stepper--collapse-toggle__container" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "vertical-stepper--collapse-toggle btn-clear clickable",
                  attrs: {
                    type: "button",
                    alt: "Icon zum Einklappen des Steppers"
                  },
                  on: {
                    click: function($event) {
                      return _vm.toggleCollapse()
                    }
                  }
                },
                [
                  _c("PhCaretLeft", {
                    attrs: { size: 24, alt: "Icon zum Einklappen des Steppers" }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "vertical-stepper--items" }, [
        _c(
          "div",
          { staticClass: "vertical-stepper--items__wrap" },
          [
            _vm.stepsComputed && _vm.stepsComputed.length
              ? _vm._l(_vm.stepsComputed, function(step, index) {
                  return _c(
                    "div",
                    {
                      key: index + _vm.lastUpdated,
                      staticClass: "vertical-stepper--item"
                    },
                    [
                      _c("VerticalStepItem", {
                        attrs: {
                          step: step,
                          stepType: _vm.stepType,
                          isCurrent: _vm.selectedStepKey === step.stepKey,
                          showSaveSpinner:
                            _vm.showSaveSpinner &&
                            _vm.stepClicked === step.stepKey,
                          collapsed: _vm.collapsed
                        },
                        on: {
                          click: function($event) {
                            return _vm.onClickStop(step.stepKey)
                          }
                        }
                      }),
                      _vm._l(_vm.getSubsteps(step), function(
                        substep,
                        subIndex
                      ) {
                        return _c("VerticalSubstepItem", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.showLastSubstep(index, step) &&
                                !substep.hidden,
                              expression:
                                "showLastSubstep(index, step) && !substep.hidden"
                            }
                          ],
                          key: subIndex + _vm.lastUpdated,
                          attrs: {
                            isCurrent:
                              _vm.selectedSubstepKey === substep.substepKey,
                            substep: substep,
                            collapsed: _vm.collapsed
                          },
                          on: {
                            click: function($event) {
                              return _vm.onClickSubstep(
                                step.stepKey,
                                substep.substepKey
                              )
                            }
                          }
                        })
                      })
                    ],
                    2
                  )
                })
              : _c("GhostLoading", {
                  attrs: {
                    type: "block",
                    config: { block: { height: 24 } },
                    repeat: 10
                  }
                })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }