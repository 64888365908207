<template>
  <header data-app-header class="header-login__container">
    <CallModal/>
    <IncomingCallDialog/>
    <HungUpCallDialog/>
    <div class="fc-container">
      <div class="top-navbar__container">
        <div class="top-nav__navigation-controls d-flex header-navbar__container-icons--spaced">
          <BaseBackButtonBreadcrumb :size="buttonsSize" class="top-nav__navigation-controls--breadcrumb" />
        </div>
        <div class="header__container-item header__container--end">
          <template v-if="!isMobileNativeContext && brokerInformation.showAppIconsOnLoginscreen">
            <a :href="appLinks.IOS" v-if="appLinks && appLinks.IOS" target="_blank">
              <PhAppleLogo :size="18" alt="Icon für den App Store"/>
            </a>
            <a :href="appLinks.google" v-if="appLinks && appLinks.google" target="_blank">
              <PhGooglePlayLogo :size="18" alt="Icon für den Google Play Store"/>
            </a>
          </template>
          <a :href="urlImpressum" target="_blank" >Impressum</a>
          <span>|</span>
          <a :href="urlDatenschutz" target="_blank" >Datenschutz</a>
        </div>
      </div>
    </div>
    <BaseTopProgressBar />
  </header>
</template>

<script>
import { PhGooglePlayLogo, PhAppleLogo } from "phosphor-vue";
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types'
import BRIDGE_TYPES from '@/store/bridge/types'
import BaseTopProgressBar from "@/components/core/BaseTopProgressBar.vue";
import BaseBackButtonBreadcrumb from '@/components/core/BaseBackButtonBreadcrumb.vue'
import CallModal from '@/components/webrtc/CallModal.vue';
import IncomingCallDialog from '@/components/webrtc/IncomingCallDialog.vue';
import HungUpCallDialog from '@/components/webrtc/HungUpCallDialog.vue';

const ICON_WEB_SIZE = 18;
const ICON_MOBILE_SIZE = 22;

export default {
  computed: {
    ...mapGetters({
      appLinks: CORE_TYPES.GETTERS.GET_APP_LINKS,
      brokerInformation: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    urlImpressum() {
      return this.brokerInformation?.urlImpressum ? this.brokerInformation?.urlImpressum : 'https://www.fondskonzept.ag/impressum-smart'
    },
    urlDatenschutz() {
      if((this.brokerInformation?.urlDatenSchutz || '').toString().includes("www.kapitalwerkstatt.de/datenschutz.html"))
        return 'https://fondskonzept.kapitalwerkstatt.de/datenschutz'
      else
        return this.brokerInformation?.urlDatenSchutz ? this.brokerInformation?.urlDatenSchutz : 'https://www.fondskonzept.ag/datenschutz'
    },
    buttonsSize() {
      return this.isMobileNativeContext || this.$isSmallScreen ? ICON_MOBILE_SIZE : ICON_WEB_SIZE;
    },
  },
  methods: {
    emitBackButtonPressed() {
      this.$emit('backButtonPressed');
    }
  },
  mounted() {
    document.addEventListener('back-button-pressed', this.emitBackButtonPressed)
  },
  beforeDestroy() {
    document.removeEventListener('back-button-pressed', this.emitBackButtonPressed)
  },
  components: {
    PhGooglePlayLogo, 
    PhAppleLogo,
    BaseTopProgressBar,
    BaseBackButtonBreadcrumb,
    CallModal,
    IncomingCallDialog,
    HungUpCallDialog,
  }
}
</script>

<style scoped>
  .header-login__container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1010;
    width: 100%;
    min-height: var(--topNavbar-min-height);
    background-color: var(--color-top-nav-background);
    color: var(--color-top-nav-text);
  }

  .header-login__container:before {
    background-color: var(--color-top-nav-background);
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    top: -495px;
    left: 0;
    width: 100%;
    height: 500px;
  }

  a {
    color: var(--color-top-nav-text);
  }

  .fc-container {
    height: 100%;
  }

  .header__container {
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }

  .header__container-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header__container--end > * {
    margin: 0 8px;
  }

  @media (max-width: 576px) { 
    .header__container--version {
      display: none;
    }
  }
</style>

<!-- GLOBAL STYLE -->
<style>
.header-login__container ~ [data-app-content] {
  padding-top: calc(var(--topNavbar-min-height) + 1em) !important; /** header-login height + 1em */
  margin-top: 0 !important;
}
.top-nav__navigation-controls--breadcrumb {
  display: none;
}
@media (max-width: 767px) {
  .top-nav__navigation-controls--breadcrumb {
    display: flex;
    align-items: stretch !important;
    justify-content: stretch !important;
    align-self: stretch;
    margin-left: 0;
  }

}
</style>
