var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "kundenBereinigenModal",
          attrs: {
            modalTitle: "Kunden bereinigen",
            labelButtonConfirm: "Bereinigen "
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            },
            onCancelButton: function($event) {
              return _vm.onCancelModal()
            },
            onCloseButton: function($event) {
              return _vm.onCancelModal()
            }
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("p", [_vm._v("Hier können Sie zwei Kunden bereinigen.")])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("hr"),
              _c("p", [_vm._v("ACHTUNG!")]),
              _c("p", [
                _vm._v(
                  "Dabei werden alle Produkte des Kunden mit der Kundennummer 2 dem Kunden mit der Kundennummer 1 zugeordnet und dann der Kunde 2 gelöscht!"
                )
              ]),
              _c("p", [
                _vm._v(
                  "Beide Kunden werden endgültig zusammengelegt. Diese Aktion kann nicht rückgängig gemacht werden."
                )
              ]),
              _vm.customerList && _vm.customerList.length == 2
                ? _c("p", [
                    _vm._v(
                      "Kunde " +
                        _vm._s(_vm.customerList[0]["name"]) +
                        " (" +
                        _vm._s(_vm.customerList[0]["customerID"]) +
                        ") löschen und alle Daten verschieben?"
                    )
                  ])
                : _vm._e(),
              _c("hr")
            ])
          ]),
          _c("div", { staticClass: "main__container" }, [
            _c("div", { staticClass: "header" }, [_vm._v(" Quell-Kunde ")]),
            _c("div", { staticClass: "control" }, [_vm._v("   ")]),
            _c("div", { staticClass: "header" }, [_vm._v(" Ziel-Kunde ")])
          ]),
          _vm.customerList &&
          _vm.customerList.length == 2 &&
          !_vm.changingPosition
            ? _c("div", { staticClass: "main__container" }, [
                _c("div", { staticClass: "item" }, [
                  _vm._v(" " + _vm._s(_vm.customerList[0]["customerID"]) + " "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.customerList[0]["name"]) + " ")
                ]),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c(
                      "BaseButton",
                      {
                        staticClass: "control-button m-0",
                        attrs: { isClear: "" },
                        on: {
                          click: function($event) {
                            return _vm.changePositions()
                          }
                        }
                      },
                      [
                        _c("PhArrowsClockwise", {
                          attrs: { size: 16, weight: "bold" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "item" }, [
                  _vm._v(" " + _vm._s(_vm.customerList[1]["customerID"]) + " "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.customerList[1]["name"]) + " ")
                ])
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }