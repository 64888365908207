import store from '@/store';
import router from '@/router';
import CORE_TYPES from '@/store/core/types';
import CMS_TYPES from "@/store/cms/types";
import BRIDGE_TYPES from '@/store/bridge/types';
import MENU_TYPES from '@/store/menu/types';
import LOG_TYPES from '@/store/log/types'
import NAVIGATION_LOG_TYPES from '@/store/navigation-log/types';
import { clearStorage, getObject, getSessionObject, removeItem, removeSessionItem, setSessionObject } from '../helpers/local-storage-helper'
import possiblePendingActions  from '@/configs/pending-actions.js'
import { buildMessageWith, MESSAGE_ACTIONS } from '@/helpers/log-message-helper';
import { VIEW_ROLES } from './roles';
import { isNativeBackButtonDisabled } from './breadcrumb/browser-back-button';

export const PATH_ROLE_FORBIDDEN = '/role-forbidden';

async function waitUntilTokenBeLoaded() {
  await new Promise(resolve => {
    if(store.getters[CORE_TYPES.GETTERS.IS_LOADING_TOKEN]) {
      const unwatch = store.watch(() => store.getters[CORE_TYPES.GETTERS.IS_LOADING_TOKEN], () => {
        if(store.getters[CORE_TYPES.GETTERS.IS_LOADING_TOKEN]) return;

        unwatch();
        resolve();
      });
    } else {
      resolve();
    }
  })
}

async function waitUntilMenuBeConfigured() {
  await new Promise(resolve => {
    if(store.getters[MENU_TYPES.GETTERS.IS_CONFIGURING_MENU]) {
      const unwatch = store.watch(() => store.getters[MENU_TYPES.GETTERS.IS_CONFIGURING_MENU], () => {
        if(store.getters[MENU_TYPES.GETTERS.IS_CONFIGURING_MENU]) return;

        unwatch();
        resolve();
      });
    } else {
      resolve();
    }
  })
}

/**
 * Before each route navigation, this function will check if the router requires authentication
 * 
 * Usage: 
 * 
 * on the route definition, add the object:
 * 
 * meta: { 
 *     requiresAuth: true
 * }
 * 
 */
export function isUserLoggedIn(to, from, next) {
  const isLoggedIn = store.getters[CORE_TYPES.GETTERS.IS_LOGGED_IN];
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      const domain = window.location.hostname;
      const isMobileNativeContext = store.getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT];
      if (domain === 'www.finance-cloud.de' || domain === 'localhost' || isMobileNativeContext) {
        next({
          path: '/login',
          query: { nextUrl: to.fullPath }
        })
      } else if(window.location.pathname != '' || window.location.pathname != '/') {
        next({
          path: '/',
          query: { nextUrl: to.fullPath }
        })
      }
    } else {
      next();
    }
  } else {
    next();
  }
}

/**
 * Before each router navigation, this function will check if there is any pending action
 * 
 * Usage: 
 * 1. Create a new route for the new action
 * 
 * 2. Add the new router in the 'pending-actions.js'
 * 
 * 3. the pending action key should be equal to the state.core.baseGlobalState.pendingActions key 
 *  e.g: 
 *    state: state.core.baseGlobalState.pendingActions.privacyCheck = true
 *    pending-actions.js: privacyCheck { ... }
 */
export function isUserHasPendingAction(to, from, next) {
  const pendingActions = store.getters[CORE_TYPES.GETTERS.PENDING_ACTIONS]
  const isPendingActionActiveFn = store.getters[CORE_TYPES.GETTERS.IS_PENDING_ACTION_ACTIVE];

  if (!Object.keys(pendingActions || {}).length || !Object.keys(pendingActions).some(key => isPendingActionActiveFn(key))) {
    next()
    return;
  }

  // sort by ordinal because Object.entries does not keep order
  const sortedEntries =  Object.entries(pendingActions).sort(([key1, val1], [key2, val2]) => val1.ordinal - val2.ordinal)

  for (const [key, currentValue] of sortedEntries) {
    // check if destination can bypass pending actions
    if (to.matched.some(record => record.meta.canBypassPendingActions)) {
      next();
      return
    }

    // check if destination itself is a pending action
    for (const possiblePAValue of Object.values(possiblePendingActions)) {
      if (to.matched.some(record => record.path === possiblePAValue.path)) {
        next();
        return
      }
    }

    // for each action that is pending
    if (isPendingActionActiveFn(key)) {
      const pathAction = possiblePendingActions[key]
  
        if (currentValue.mandatory && pathAction?.path) {
          next({ path: pathAction.path, query: {nextUrl: to.fullPath} })
          return
        } else {
          // since it's not mandatory, don't call next()/return
          const hasRoles = store.getters[CORE_TYPES.GETTERS.HAS_ROLES];

          const payload = {
            content: currentValue.messageBody || pathAction?.messageBody,
            type: currentValue.type,
            actions: MESSAGE_ACTIONS[key],
          };

          if (hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN, VIEW_ROLES.VIEW_BROKER_AS_INTERN])) {
            payload.timeout = 5000;
          }

          store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, 
            buildMessageWith(payload)
          );

          store.commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, { [key]: { pending: false} });
        }
    }
  }

  next();
}

export function isUserHasRequiredRole(to, from, next, inPlace = false, userRolesPredefined = null) {
  if (to.path === PATH_ROLE_FORBIDDEN) {
    next?.();
  } else {
    let isAllowed = false;
    let isDenied = true;
    const userRoles = userRolesPredefined || store.getters[CORE_TYPES.GETTERS.GET_USER_ROLES] || [];
    isAllowed = to.matched.every(record => {
      if (record && record.meta && record.meta.roles && record.meta.roles.allowed) {
        return record.meta.roles.allowed.some(r => Array.isArray(r) ? r.every(roleAnd => userRoles.includes(roleAnd)) : userRoles.includes(r));
      } else {
        return true;
      }
    });

    if (isAllowed) {
      isDenied = to.matched.some(record => {
        if (record && record.meta && record.meta.roles && record.meta.roles.denied) {
          return record.meta.roles.denied.some(r => Array.isArray(r) ? r.every(roleAnd => userRoles.includes(roleAnd)) : userRoles.includes(r));
        } else {
          return false;
        }
      });
    }

    // next should always be called, no matter how
    if (isAllowed && !isDenied) {
      // if next is called with no parameter, then the customer can navigate
      next?.();
    } else {
      // if next is called with a parameter, the customer cannot reach the original path and will be redirected to the 'path' parameter
      next?.({
        path: PATH_ROLE_FORBIDDEN
      });
    }

    if (inPlace) {
      return isAllowed && !isDenied;
    }
  }
}

export function isPathAllowed(path) {
  const route = router.matcher.match(path);
  return isUserHasRequiredRole(route, null, null, true);
}

export async function loadBrokerInfo(to, from, next) {
  const broker = to.query.id || to.params.broker_id || to.query.maklernr
  const host = window.location.host;
  const isSSOContext = window.location.pathname.toLowerCase().includes('sso');
  const regCode = to.query.regCode;

  store.commit(CMS_TYPES.MUTATIONS.SET_FONDSSHOP_PARAMS, {
    regCode: (/^\d{10}$/.test(regCode) ? regCode : '')
  });

  if (broker || host) {
    try {
      // await for broker layout request in order to prevent showing standart colors on slow connections
      await store.dispatch(CORE_TYPES.ACTIONS.GET_BROKER_LAYOUT, { broker, host, isSSOContext});
    } catch (error) {
      // if gets no response or an error is thrown, let the navigation happens
    }

    try {
      await store.dispatch(CORE_TYPES.ACTIONS.GET_BROKER_INFORMATION, { broker, host, to });
    } catch (error) {
      // if gets no response or an error is thrown, let the navigation happens
    }
  }
  next();
}

const IGNORED_PATHS_FOR_RELOAD = [
  '/token-login',
  '/open-link',
];
export function reloadLoggedInUser(to, from, next) {
  if (IGNORED_PATHS_FOR_RELOAD.indexOf(to.path) >= 0 || store.getters[CORE_TYPES.GETTERS.IS_LOGGED_IN]) {
    next()
    return;
  }

  let loginData = getSessionObject('deepLink-loginData')
  removeSessionItem('deepLink-loginData')

  const isKundenzugang = getSessionObject('isKundenzugang') === 'true';
  const isMaklerzugang = getSessionObject('isMaklerzugang') === 'true';

  if (!loginData || !loginData.currentDate || !loginData.timeoutSeconds) {
    if(isKundenzugang) {
      loginData = getSessionObject('kundenzugang-loginData') // kundenzugang-loginData doesn't exist in productive
    } else if(isMaklerzugang) {
      loginData = getSessionObject('maklerzugang-loginData') // maklerzugang-loginData doesn't exist in productive
    } else {
      loginData = getObject('loginData') // loginData doesn't exist in productive
    }

    if (!loginData || !loginData.currentDate || !loginData.timeoutSeconds) {
      if(isKundenzugang) {
        removeSessionItem('kundenzugang-loginData')
      } else if(isMaklerzugang) {
        removeSessionItem('maklerzugang-loginData')
      } else {
        removeItem('loginData')
      }
      next()
      return;
    }
  }

  const expiryDate = new Date(loginData.currentDate + loginData.timeoutSeconds * 1000).getTime()
  if (expiryDate > new Date().getTime()) {
    const userNumberPerspective = getSessionObject('userNumberPerspective')

    store.commit(CORE_TYPES.MUTATIONS.SET_LOADING_TOKEN, true);
    store.dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, { 
      data: {
        ...loginData,
        colorScheme: localStorage.getItem('app:colorScheme') || loginData.colorScheme,
        rights: {
          ...loginData?.rights,
          isKundenzugang,
          isMaklerzugang,
        }
      },
      isWaitingForNextToken: !!userNumberPerspective,
      isOriginalUser: true,
      nextUrl: to.fullPath === '/login' || to.fullPath?.includes('empty-overview') ? '/home' : to.fullPath,
    }).then(async () => {
      const isDeepLink = to.query?.isDeepLink
      if (!isDeepLink) {
        if (userNumberPerspective?.length === 7) { 
          await store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_STECKBRIEF, {
            customerId: userNumberPerspective, 
            tabsBroadcastId: loginData.tabsBroadcastId, 
            isMaklerzugang, 
            maklerzugangTabsBroadcastId: loginData.maklerzugangTabsBroadcastId, 
          })
        } else if (userNumberPerspective?.length === 5) { 
          await store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER, {
            maklernr: userNumberPerspective, 
            tabsBroadcastId: loginData.tabsBroadcastId, 
            isMaklerzugang, 
            maklerzugangTabsBroadcastId: loginData.maklerzugangTabsBroadcastId, 
          })
        }
      } else {
        delete to.query.isDeepLink;
      }
    })
    .finally(() => store.commit(CORE_TYPES.MUTATIONS.SET_LOADING_TOKEN, false));
  } else {
    removeItem('loginData')
    removeSessionItem('userNumberPerspective')
    removeSessionItem('kundenzugang-loginData')
    removeSessionItem('maklerzugang-loginData')
  }

  next()
}



export function isRolesIncludes(roles, __CONTEXT_ROLES__) {
  const userRoles = __CONTEXT_ROLES__ || store.getters[CORE_TYPES.GETTERS.GET_USER_ROLES] || [];
  return roles.some(r => Array.isArray(r) ? r.every(roleAnd => userRoles.includes(roleAnd)) : userRoles.includes(r));
}

export function clearLocalStorageIfLoginToken(to, from, next) {
  if (store.getters[CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION]) {
    const isMaklerzugang = getSessionObject('isMaklerzugang') === 'true';
    const isSuperCustomer = (to?.query?.isSuperCustomer) === 'true';

    if (to.path === '/token-login' && !isMaklerzugang && !isSuperCustomer) {
      clearStorage()
    }
  }
  next()
}

export function setKundenzugangStorageIfNeeded(to, from, next) {
  if (to.path === '/kundenzugang-login' && store.getters[CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION]) {
    setSessionObject('isKundenzugang', 'true');
    removeSessionItem('userNumberPerspective');
    removeSessionItem('isMaklerzugang');
    removeSessionItem('maklerzugang-loginData');
  }
  next()
}

export function setMaklerzugangStorageIfNeeded(to, from, next) {
  if (to.path === '/maklerzugang-login' && store.getters[CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION]) {
    setSessionObject('isMaklerzugang', 'true');
    removeSessionItem('userNumberPerspective');
  }
  next()
}

export async function checkMobileNativeContextParameter(to, from, next) {
  if (to?.query?.mobileNativeContext) {
    await store.dispatch(BRIDGE_TYPES.ACTIONS.MOBILE_NATIVE_CONTEXT, to?.query?.mobileNativeContext)
    await store.dispatch(BRIDGE_TYPES.ACTIONS.MOBILE_NATIVE_SPEC, {
      appInstallationId: to?.query?.appInstallationId,
      appId: to?.query?.appId,
      appVersion: to?.query?.appVersion,
      nativeOsVersion: to?.query?.nativeOsVersion,
      nativeDeviceModel: to?.query?.nativeDeviceModel,
    })
  }
  if (store.getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT] && to.matched.some(record => record.meta.isCmsPage)) {
    if (store.getters[CORE_TYPES.GETTERS.IS_LOGGED_IN]) {
      next({ path: '/home' })
    } else {
      next({ path: '/login' })
    }
  }
  next()
}

export async function checkColorThemeParameter(to, from, next) {
  if (to?.query?.colorTheme) {
    await store.dispatch(BRIDGE_TYPES.ACTIONS.COLOR_THEME, to?.query?.colorTheme)
  }
  next()
}

export async function configureMenu(to, from, next) {
  const isLoggedIn = store.getters[CORE_TYPES.GETTERS.IS_LOGGED_IN];
  if(isLoggedIn) {
    await waitUntilTokenBeLoaded();
    await store.dispatch(MENU_TYPES.ACTIONS.CONFIGURE_MENU);
  }
  next();
}

export async function isOptionMenuPermissionVisibleAsync(to, from, next) {
  await waitUntilMenuBeConfigured();

  isOptionMenuPermissionVisible(to, from, next);
}

export function resolvePathParams(path, params) {
  if(!path) return path;
  return Object.keys(params || {})
        .reduce((result, key) => result.replace(`:${key}`, params?.[key] || `:${key}`), path);
}

export function isOptionMenuPermissionVisible(to, from, next) {
  if (to.path === PATH_ROLE_FORBIDDEN) {
    next();
  } else {
    const relatedPaths = ([
      to.path, 
      to.fullPath, 
      ...to.matched.flatMap(r => [
        r.path, 
        resolvePathParams(r.path, to.params), 
        r.fullPath, 
        resolvePathParams(r.fullPath, to.params),
      ]),
    ]).filter(path => !!path);

    if(relatedPaths.every(path => store.getters[MENU_TYPES.GETTERS.IS_OPTION_MENU_PATH_VISIBLE](path))) {
      next();
    } else {
      next(PATH_ROLE_FORBIDDEN);
    }
  }
}

export function logRoutesChanges(to, from) {
  store.dispatch(NAVIGATION_LOG_TYPES.ACTIONS.ROUTE_CHANGE, { from, to });
}

const disableNativeBackButtonForward = () => history.forward();
export function disableNativeBackButtonIfNeeded(to, from) {
  window.removeEventListener('popstate', disableNativeBackButtonForward);

  if (isNativeBackButtonDisabled(to)) {
    window.history.pushState(history.state, document.title, location.href);
    window.addEventListener('popstate', disableNativeBackButtonForward);
  }
}
