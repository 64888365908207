<template>
  <div>
    <HinweiseUndFehler :errors="warnings" :hints="hints" />
    <div v-if="!brokerData.zugriffCourtage">Keine Daten</div>
    <div v-else class="box__container">
      <div class="font-bold">
        Bei den folgenden E-Mail Adressen können Sie auch mehrere Adressen
        hintereinander in ein Feld eintragen, diese dürfen NUR durch Leerstellen
        getrennt werden. Bedenken Sie dass bei automatischem Versand dann aber
        an all diese Adressen die E-Mails versandt werden.
      </div>
    </div>
    <div class="box__container">
      <BaseButton @click="standardEmailUebertragen" :disabled="isFormInvalid()">
        Standard E-Mail an übrige Adressen übertragen
      </BaseButton>
    </div>
    <div class="box__container">
      <InputField
        label="Standard"
        :isComponentHalfSize="true"
        :disabled="!(!isFPPlus || istFPPlusMaster || istDGFRP)"
        :validateUntouched="true"
        v-model="brokerData.emailStandard"
        @change="addBrokerDataEdited('brokerData')"
      />
      <ComboBox
        v-if="brokerData.zeigeAbsender"
        label="Absender"
        isComponentHalfSize
        :values="brokerData.absenderStandart"
        v-model="brokerData.absenderStandartEmail"
        @change="
          addBrokerDataEditedCombo(
            'brokerData',
            'absenderStandartEmail',
            $event
          )
        "
      />
    </div>
    <div class="box__container">
      <InputField
        label="Geburtstag"
        :isComponentHalfSize="true"
        v-model="brokerData.emailGeburtstag"
        @change="addBrokerDataEdited('brokerData')"
      />
      <ComboBox
        v-if="brokerData.zeigeAbsender"
        label="Absender"
        isComponentHalfSize
        :values="brokerData.absenderGeburtstag"
        v-model="brokerData.absenderGeburtstagEmail"
        @change="
          addBrokerDataEditedCombo(
            'brokerData',
            'absenderGeburtstagEmail',
            $event
          )
        "
      />
      <InputCheckboxItem
        v-model="brokerData.geburtstagZusenden"
        label="Zusenden"
        @input="addBrokerDataEdited('brokerData')"
      />
      <InputCheckboxItem
        v-model="brokerData.kundenMitBestand"
        label="Zusenden nur Kunden mit Bestand"
        @input="addBrokerDataEdited('brokerData')"
      />
      <div v-if="!brokerData.geburtstagZusenden && brokerData.kundenMitBestand" class="fc-alert fc-alert-warning fc-alert-small">
        <b>Zusenden</b> muss auch aktiviert sein, wenn <b>Zusenden nur Kunden mit Bestand</b> aktiviert ist. Andernfalls werden die E-Mails nicht versendet.
      </div>
    </div>
    <div class="box__container">
      <InputField
        label="Limit Check"
        :isComponentHalfSize="true"
        v-model="brokerData.emailLimitCheck"
        @change="addBrokerDataEdited('brokerData')"
      />
      <ComboBox
        v-if="brokerData.zeigeAbsender"
        label="Absender"
        isComponentHalfSize
        :values="brokerData.absenderLimitCheck"
        v-model="brokerData.absenderLimitEmail"
        @change="
          addBrokerDataEditedCombo('brokerData', 'absenderLimitEmail', $event)
        "
      />
    </div>
    <div class="box__container">
      <InputCheckboxItem
        v-model="brokerData.infoStorno"
        :disabled="!isBrokerBypassedByIntern"
        label="Info Stornowarnung"
        @input="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container">
      <div>Info Transaktionen</div>
      <ComboBox
        v-if="brokerData.zeigeAbsender"
        label="Absender"
        isComponentHalfSize
        :values="brokerData.absenderTransaktion"
        v-model="brokerData.absenderInfoTransaktionenEmail"
        @change="
          addBrokerDataEditedCombo(
            'brokerData',
            'absenderInfoTransaktionenEmail',
            $event
          )
        "
      />
      <InputCheckboxItem
        v-model="brokerData.infoTransaktionenZusenden"
        label="Zusenden"
        @input="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container">
      <InputField
        v-if="brokerData.isUntermaklerDesktop || isBrokerBypassedByIntern"
        label="Info Transaktionen als BCC"
        :isComponentHalfSize="true"
        v-model="brokerData.emailInfoTransaktionenBBC"
        @change="addBrokerDataEdited('brokerData')"
      />
      <ComboBox
        v-if="brokerData.zeigeAbsender && (brokerData.isUntermaklerDesktop || isBrokerBypassedByIntern)"
        label="Absender"
        isComponentHalfSize
        :values="brokerData.absenderTransaktionBCC"
        v-model="brokerData.absenderInfoTransaktionenBCCEmail"
        @change="
          addBrokerDataEditedCombo(
            'brokerData',
            'absenderInfoTransaktionenBCCEmail',
            $event
          )
        "
      />
    </div>
    <div class="box__container">
      <InputField
        label="Courtageabrechnung"
        :isComponentHalfSize="true"
        v-model="brokerData.emailCourtage"
        @change="addBrokerDataEdited('brokerData')"
      />
      <ComboBox
        v-if="brokerData.zeigeAbsender"
        label="Absender"
        isComponentHalfSize
        :values="brokerData.absenderCourtageabrechnung"
        v-model="brokerData.absenderCourtageEmail"
        @change="
          addBrokerDataEditedCombo(
            'brokerData',
            'absenderCourtageEmail',
            $event
          )
        "
      />
      <InputCheckboxItem
        v-model="brokerData.emailCourtageZusenden"
        label="Zusenden"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container">
      <InputField
        label="Kundenkontakt"
        :isComponentHalfSize="true"
        v-model="brokerData.emailKundenkontakt"
        @change="addBrokerDataEdited('brokerData')"
      />
      <ComboBox
        v-if="brokerData.zeigeAbsender"
        label="Absender"
        isComponentHalfSize
        :values="brokerData.absenderKundenkontakt"
        v-model="brokerData.absenderKundenKontaktEmail"
        @change="
          addBrokerDataEditedCombo(
            'brokerData',
            'absenderKundenKontaktEmail',
            $event
          )
        "
      />
    </div>
    <div class="box__container" v-if="brokerData.zugriffInvestment">
      <InputField
        label="Antragsprüfung Wertpapiere"
        :isComponentHalfSize="true"
        v-model="brokerData.emailWertpapiere"
        @change="addBrokerDataEdited('brokerData')"
      />
      <ComboBox
        v-if="brokerData.zeigeAbsender"
        label="Absender"
        isComponentHalfSize
        :values="brokerData.absenderWertpapiere"
        v-model="brokerData.absenderWertpapiereEmail"
        @change="
          addBrokerDataEditedCombo(
            'brokerData',
            'absenderWertpapiereEmail',
            $event
          )
        "
      />
    </div>

    <div class="box__container" v-if="brokerData.zugriffVersicherungen">
      <InputField
        label="Antragsprüfung Versicherungen"
        :isComponentHalfSize="true"
        v-model="brokerData.emailVersicherungen"
        @change="addBrokerDataEdited('brokerData')"
      />
      <ComboBox
        v-if="brokerData.zeigeAbsender"
        label="Absender"
        isComponentHalfSize
        :values="brokerData.absenderVersicherungen"
        v-model="brokerData.absenderVersicherungenEmail"
        @change="
          addBrokerDataEditedCombo(
            'brokerData',
            'absenderVersicherungenEmail',
            $event
          )
        "
      />
    </div>
    <div class="box__container" v-if="brokerData.zugriffKredite">
      <InputField
        label="Antragsprüfung Finanzierungen"
        :isComponentHalfSize="true"
        v-model="brokerData.emailFinanzierungen"
        @change="addBrokerDataEdited('brokerData')"
      />
      <ComboBox
        v-if="brokerData.zeigeAbsender"
        label="Absender"
        isComponentHalfSize
        :values="brokerData.absenderFinanzierung"
        v-model="brokerData.absenderFinanzierungenEmail"
        @change="
          addBrokerDataEditedCombo(
            'brokerData',
            'absenderFinanzierungenEmail',
            $event
          )
        "
      />
    </div>
    <div class="box__container" v-if="brokerData.zugriffVV">
      <InputField
        label="Vermögensverwaltung"
        :isComponentHalfSize="true"
        v-model="brokerData.emailVV"
        @change="addBrokerDataEdited('brokerData')"
      />      
    </div>
    <div
      class="box__container"
      v-if="brokerData.zugriffMaklerauftragVerwaltung"
    >
      <InputField
        label="Maklerauftrag Fehler"
        :isComponentHalfSize="true"
        v-model="brokerData.emailMaklerAuftragFehler"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div
      class="box__container"
      v-if="brokerData.zugriffMaklerauftragVerwaltung"
    >
      <InputField
        label="Maklerauftrag Kontrolle"
        :isComponentHalfSize="true"
        v-model="brokerData.emailMaklerAuftragKontrolle"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container" v-if="brokerData.zeigeEmailDepotbickIntern">
      <InputField
        label="Ersatzadresse Absender"
        :isComponentHalfSize="true"
        v-model="brokerData.emailErsatzAdresse"
        disabled="disabled"
      />
      <!-- <BaseButton @click="aliasAdresseBearbeiten">Aliasadresse anlegen / bearbeiten</BaseButton> -->
    </div>
  </div>
</template>
<script>
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import { mapGetters } from "vuex";
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";
import CORE_TYPES from "@/store/core/types";
import vermittlerDatenMixin from "@/views/intern/vermittler-daten-mixin";
import validator from '@/mixins/validator';
import { emailMultiple, maxLength } from '@/mixins/validator/rules';

export default {
  components: {
    ComboBox,
    InputField,
    InputCheckboxItem,
    BaseButton,
    HinweiseUndFehler,
  },
  validators: {
    brokerData : {
      emailStandard: [
        emailMultiple('Standard E-mail ist erforderlich!', {emptyAllowed: false}),
        maxLength(300, 'Wert zu groß für Standard (maximal: 300, Inklusive Leerräume)')
      ],
    }
  },
  mixins: [vermittlerDatenMixin, validator],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formErrors: []
    }
  },
  computed: {
    ...mapGetters({
      isFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
      istFPPlusMaster: CORE_TYPES.GETTERS.IS_FPP_PLUS_MASTER,
      istDGFRP: CORE_TYPES.GETTERS.IS_DGFRP_MASTER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    hints() {
      const result = [];
      if (this.brokerData.zugriffMaklerauftragVerwaltung) {
        result.push({
          title: "Makleraufträge Verwaltung",
          message:
            "Wird in der E-Mails an die Versicherungsgesellschaften als Absender verwendet. Falls Makleraufträge nicht versendet werden können wird eine E-Mail mit zusätzlichen Informationen an diese Adresse geschickt(z.B. wenn die Versicherungsgesellschaft keine Kontaktadresse eingetragen hat oder keine Vollmacht vorliegt).",
        });
      }
      return result;
    },
    warnings() {
      return this.formErrors;
    },
    isBrokerBypassedByIntern(){
      return this.hasRoles(this.VIEW_ROLES.VIEW_BROKER_AS_INTERN)
    },
  },
  methods: {
    standardEmailUebertragen() {
      this.brokerData.emailGeburtstag = this.brokerData.emailStandard;
      this.brokerData.emailLimitCheck = this.brokerData.emailStandard;
      this.brokerData.emailInfoTransaktionen = this.brokerData.emailStandard;
      this.brokerData.emailCourtage = this.brokerData.emailStandard;
      this.brokerData.emailWertpapiere = this.brokerData.emailStandard;
      this.brokerData.emailVersicherungen = this.brokerData.emailStandard;
      this.brokerData.emailWertpapiere = this.brokerData.emailStandard;
      this.brokerData.emailFinanzierungen = this.brokerData.emailStandard;
      this.brokerData.emailVV = this.brokerData.emailStandard;
      this.brokerData.emailMaklerAuftragFehler = this.brokerData.emailStandard;
      this.brokerData.emailMaklerAuftragKontrolle = this.brokerData.emailStandard;
      this.brokerData.emailErsatzAdresse = this.brokerData.emailStandard;
      this.brokerData.emailKundenkontakt = this.brokerData.emailStandard;
      this.addBrokerDataEdited('brokerData');
    },
    isFormInvalid() {
      return this?.validation?.isInvalid('brokerData');
    },
    addFormErrors() {
      if (this?.validation?.brokerData?.emailStandard?.invalid) {
        if(this.formErrors?.map(error => error.title).includes('Standard-E-Mail-Adresse ist erforderlich')){
          return
        }
        this.formErrors.push({
          title: 'Standard-E-Mail-Adresse ist erforderlich',
          message: `Die Standard-E-Mail-Adresse ist erforderlich und wurde nicht eingegeben oder ist ungültig. 
                    Wenn Sie fortfahren, ohne eine gültige E-Mail-Adresse einzugeben, wird das Formular nicht gespeichert.`,
        });
      }
    },
  },
  mounted() {
    if(this?.validation?.brokerData?.emailStandard?.invalid) {
      this?.addFormErrors();
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveChanges().then(() => next());
  },
};
</script>
<style scoped>

</style>