var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "text-bold" }, [_vm._v("Gebüren:")]),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            type: "percent",
            label: _vm.getLabelStrategiegebuehr,
            disabled: _vm.form.disabled,
            validateUntouched: ""
          },
          on: {
            change: function($event) {
              return _vm.changeStrategieGebuehr($event)
            }
          },
          model: {
            value: _vm.form.strategieGebuehr,
            callback: function($$v) {
              _vm.$set(_vm.form, "strategieGebuehr", $$v)
            },
            expression: "form.strategieGebuehr"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            type: "percent",
            label: _vm.getLabelVermoegensverwaltungsgebuehr,
            disabled: _vm.form.disabled,
            validateUntouched: ""
          },
          on: {
            change: function($event) {
              return _vm.changeVerwaltungsGebuehr($event)
            }
          },
          model: {
            value: _vm.form.verwaltungsGebuehr,
            callback: function($$v) {
              _vm.$set(_vm.form, "verwaltungsGebuehr", $$v)
            },
            expression: "form.verwaltungsGebuehr"
          }
        }),
        _c("ComboBox", {
          attrs: {
            isComponentHalfSize: "",
            values: _vm.valuesGebuehrenModell,
            label: "Gebührenmodell",
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateGebuehrenModell($event)
            }
          },
          model: {
            value: _vm.form.gebuehrenModell,
            callback: function($$v) {
              _vm.$set(_vm.form, "gebuehrenModell", $$v)
            },
            expression: "form.gebuehrenModell"
          }
        }),
        _vm.form.kostenTransaktion
          ? _c("InputField", {
              attrs: {
                isComponentHalfSize: "",
                value: _vm.form.kostenTransaktion,
                type: "currency",
                label: "Kosten pro Transaktion",
                disabled: ""
              }
            })
          : _vm._e(),
        _vm.form.kostenTransaktionProz
          ? _c("InputField", {
              attrs: {
                isComponentHalfSize: "",
                value: _vm.form.kostenTransaktionProz,
                type: "percent",
                label: "Transaktionskostenpauschale",
                disabled: ""
              }
            })
          : _vm._e(),
        _vm.form.verwahrGebuehrMitAfp
          ? _c("InputField", {
              attrs: {
                isComponentHalfSize: "",
                value: _vm.form.verwahrGebuehrMitAfp,
                type: "percent",
                label: "Verwahrgebühr p.a. inkl.ges. MwSt.",
                disabled: ""
              }
            })
          : _vm._e(),
        _vm.form.verwahrGebuehrBetrag
          ? _c("InputField", {
              attrs: {
                isComponentHalfSize: "",
                value: _vm.form.verwahrGebuehrBetrag,
                type: "currency",
                label: "Verwahrgebühr (maximal p.a.)",
                disabled: ""
              }
            })
          : _vm._e(),
        _vm.form.depotGebuehrFst
          ? _c("InputField", {
              attrs: {
                isComponentHalfSize: "",
                value: _vm.form.depotGebuehrFst,
                type: "currency",
                label: "Depotgebühr p.a. inkl. ges. MwSt.",
                disabled: ""
              }
            })
          : _vm._e(),
        _vm.form.depotGebuehrWeitere
          ? _c("InputField", {
              attrs: {
                isComponentHalfSize: "",
                value: _vm.form.depotGebuehrWeitere,
                type: "currency",
                label: "Depotgebühr p.a. inkl. ges. MwSt. weiteres Depot",
                disabled: ""
              }
            })
          : _vm._e(),
        _vm.form.depotGebuehrFstProz
          ? _c("InputField", {
              attrs: {
                isComponentHalfSize: "",
                value: _vm.form.depotGebuehrFstProz,
                type: "percent",
                label: "Depotgebühr p.a. inkl. ges. MwSt.",
                disabled: ""
              }
            })
          : _vm._e(),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            type: "percent",
            label: "Bestandsrückvergütung der Fonds",
            disabled: !_vm.schemaData.isVermoegensverwalter,
            validateUntouched: ""
          },
          on: {
            change: function($event) {
              _vm.updateStore(
                { bestandsrueckverguetung: _vm.formatNumber($event) },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.bestandsrueckverguetung,
            callback: function($$v) {
              _vm.$set(_vm.form, "bestandsrueckverguetung", $$v)
            },
            expression: "form.bestandsrueckverguetung"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            type: "percent",
            label: "Produktkosten p.a.",
            disabled: !_vm.schemaData.isVermoegensverwalter,
            validateUntouched: ""
          },
          on: {
            change: function($event) {
              _vm.updateStore(
                { kostenProduktPa: _vm.formatNumber($event) },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.kostenProduktPa,
            callback: function($$v) {
              _vm.$set(_vm.form, "kostenProduktPa", $$v)
            },
            expression: "form.kostenProduktPa"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            type: "percent",
            label: "geschätzte jährliche Wertentwicklung",
            validateUntouched: ""
          },
          on: {
            change: function($event) {
              _vm.updateStore(
                { jaehrlicheEntwicklung: _vm.formatNumber($event) },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.jaehrlicheEntwicklung,
            callback: function($$v) {
              _vm.$set(_vm.form, "jaehrlicheEntwicklung", $$v)
            },
            expression: "form.jaehrlicheEntwicklung"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }