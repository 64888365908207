import ZUSAETZLICHE_INFORMATIONEN_TYPES from './types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.GET_ZUSAETZLICHE_DATEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/getZusaetzlicheDaten';

      const tableType = payload?.tableType;

      if (tableType) {
        const seqParam = payload?.seq ? '&seq='+payload.seq : '';
        serviceUrl = `${serviceUrl}?tableType=${tableType}${seqParam}`;
      }

      axios.post(rootState.core.apiAddress + serviceUrl, payload.customers, config)
        .then((response) => {
          commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.GET_ZUSAETZLICHE_DATEN_SUCCESS, response.data);

          if (tableType == 'MAIN_TABLE') {
            commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.UPDATE_MAIN_TABLE_ROWS, response.data?.mainTableRows);
          } else {
            commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.UPDATE_SECONDARY_TABLE_ROWS, response.data?.secondaryTableRows);
          }

          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });



  },

  [ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.GET_ALLE_STRUKTUR_ZUSAETZLICHE_INFOS]({ getters, dispatch, commit, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/getAlleStrukturZusaetzlicheInfos';

      axios.get(rootState.core.apiAddress + serviceUrl, config)
        .then((response) => {
          commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.GET_ALLE_STRUKTUR_ZUSAETZLICHE_INFOS_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },  

  [ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.NEW_ZUSAETZLICH_DATEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    if (payload?.inputZusaetzlicheDaten) {
      const url = `/customerFilters/newZusaetzlichDaten?inputZusaetzlicheDaten=${payload.inputZusaetzlicheDaten}`;
      axios.post(rootState.core.apiAddress + url, payload.customers, config)
        .then(resp => {
          console.log(resp.data);
          if (resp.data.key == 'message') {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(resp.data.value, 'info'));
          }
        })
    }
  },

  [ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.UPDATE_OBJECT_ZUSAETZLICHE_TABELLE]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    const url = '/customerFilters/updateObjectZusaetzlicheTabelle';
    return axios.post(rootState.core.apiAddress + url, payload, config)
        .then(resp => {
          console.log(resp.data);
          if (resp.data.key == 'message') {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(resp.data.value, 'info'));
          }
          return true;
        })

  },  

  [ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.DELETE_ZUSAETZLICHE_DATEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    if (payload?.kundennr && payload?.zusaetzlicheInfo) {
      const url = `/customerFilters/deleteZusaetzlicheDaten?kundennr=${payload.kundennr}&zusaetzlicheInfo=${payload.zusaetzlicheInfo}`;
      return axios.delete(rootState.core.apiAddress + url, config)
          .then(resp => {
            console.log(resp.data);
            if (resp.data.key == 'message') {
              dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(resp.data.value, 'info'));
            }
            return true;
          })
    }
  },


  

}