<template>
  <div>
    <BaseCollapsable :showSection="showExpanded">
      <template v-slot:title>
        <div class="box__title">Richtbandbreite</div>
      </template>
      <template v-slot:content>
        <Table
          :headers="headers"
          :rows="rows"
          :rowsPerPage="20"
          rowId="vvrichbrandbreite"
          hidePagination
        />
      </template>
    </BaseCollapsable>
  </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  PercentageColumn,
} from "@/components/table2/table_util.js";
import BaseCollapsable from "@/components/core/BaseCollapsable.vue";

export default {
  components: {
    Table,
    BaseCollapsable,
  },
  props: {
    tableRows: {
      type: Array,
      default: () => [],
    },
    isAdvisor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showExpanded: true,
    };
  },
  computed: {
    headers() {
      return {
        lockedLeft: [],
        center: [
          TextColumn("category", ""),
          PercentageColumn("defaultSetting", "Erlaubt bis"),
          ...(this.isAdvisor
            ? [
                PercentageColumn(
                  "actualSetting",
                  "Berechneter Zielmarkt"
                ).setColorFn((row) => row.actualSettingColor),
                PercentageColumn("actualValue", "Aktueller Wert").setColorFn(
                  (row) => row.actualValueColor
                ),
              ]
            : []),
        ],
        lockedRight: [],
      };
    },
    rows() {
      return this.tableRows?.map((row, index) => {
        return {
          ...row,
          index: index,
          status: {
            type: this.getColor(row),
            label:
              row.statusType === "PUFFER_UEBERSCHRITTEN"
                ? "Pufferwarnung"
                : row.statusType === "LIMIT_UEBERSCHRITTEN"
                ? "Grenzwert überschritten"
                : "",
          },
        };
      });
    },
  },
  methods: {
    getColor(row, preffix = "") {
      return row.statusType === "PUFFER_UEBERSCHRITTEN"
        ? `${preffix}warnung`
        : row.statusType === "LIMIT_UEBERSCHRITTEN"
        ? `${preffix}danger`
        : "";
    },
  },
};
</script>

<style>
</style>