<template>
  <div class="box__container-wrap" v-if="hasAktivitaetenAccess">
    <div v-if="showTitle" class="box__title">Aufgaben</div>
      <div class="box__container-rows">
        <Table
          v-if="!loading && rows.length"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="5"
          rowId="rowId"
          hidePagination
          :mobileConfig="mobileConfig"
          @click-subject="openDayView"
        />
        <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 5, }, }" />
        <NoData v-else noIcon />
      </div>

      <div v-if="!loading && !$isSmallScreen" class="bottom-row text-right mt-3">
        <router-link to="/communication/postfach/aufgaben">weitere...</router-link>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn} from "@/components/table2/table_util.js";
import dayjs from 'dayjs'
import CALENDAR_TYPES from '@/store/calendar/types';
import CORE_TYPES from '@/store/core/types'
import { EMPLOYEE_ROLES } from '@/router/roles';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import axios from 'axios';

export default {
  components: {
    Table,
    GhostLoading,
    NoData,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      records: [],
    }
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    hasAktivitaetenAccess() {
      return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_AKTIVITAETEN]])
    },

    headers() {
        return {
            lockedLeft: [
                DateTimeColumn("date", "Datum", undefined, 1),
                TextColumn("subject", "Titel").makeLink(),
            ],
            center: [
                TextColumn("category", "Typ"),
                TextColumn("status", "Status"),
            ],
        };
    },
    rows() {
      return this.records.map(record =>
        ({
          ...record,
          rowId: (record.commId + record.category + record.date),
        })
      )
    },
    mobileConfig() {
      return {
          title: '',
          headers: ['date', 'subject', 'category', 'status'],
      }
    }
  },
  mounted() {
    this.doSearch();
  },
  methods: {
    doSearch() {
      this.loading = true;
  
      axios.get(`${process.env.VUE_APP_API}/postfachOverview/list/aktivitaeten`, { defaultSpinner: true, })
      .then(response => this.records = response.data || [])
      .finally(() => this.loading = false)
    },
    openDayView(data) {
      const thisday = dayjs(data.date);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_DAY_ELEMENTS, {
        day: {
          label: "",
          day: thisday,
          inMonth: true,
          isToday: false,
          isSelected: true,
        },
        dayIndex: dayjs(thisday).weekday(),
        appointment: data.commId,
      });
      this.$router.push({ path: `/communication/calendar/daycalendar` })
    },
  },
}
</script>