<template>
  <div>
    <OptionMenu
      :id="$appNavigation.parentOptionMenuId"
      :defaultMenu="$appNavigation.parentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      :actions="headerActions"
      @action="handleAction"
    >
      <template #title>{{ title }}</template>
      <template #subtitle>
        <template v-for="(status, index) of variante.stati || []">
          <span v-if="index > 0" :key="index">&nbsp;|&nbsp;</span>
          <span :class="status.status" :key="index">{{ status.label }}</span>
        </template>
      </template>
    </PageHeaderTitleNavigation>

    <div class="box__container">
      <GhostLoading v-if="isSchemaDataLoading" type="table" />
      <VVWertpapiereList
        :positions="(variante && variante.positions) || []"
        :isEditor="true"
        v-else-if="variante"
        :disabled="disabled"      
      />
      <InputTextArea
        disabled
        label="Anmerkung des Erstellers"
        v-model="commentZulassung"        
      />
      <InputTextArea
        disabled
        label="Kommentar Vermögensverwalter"
        v-model="commentVerwalter"        
      />
    </div>
    <div class="box__container">
      <StrategienZielmarktTable
        :zielmarktdaten="variante.zielmarktdaten"
        :disabled="disabled"
      />
    </div>
    <div class="box__container">
      <StrategienRichtbandbreiteTable
        :tableRows="variante.richtbandbreiten || []"
        :isAdvisor="variante.isAdvisor"
      />
    </div>
  </div>
</template>

<script>
import VV_TYPES from "@/store/vermoegensverwaltung/types";

import mixin_vv from "@/mixins/vermoegensverwaltung/vv-mixin.js";
import { mapGetters } from "vuex";

import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import VVWertpapiereList from "@/components/vermoegensverwaltung/VVWertpapiereList.vue";
import StrategienZielmarktTable from "@/components/vermoegensverwaltung/StrategienZielmarktTable.vue";
import StrategienRichtbandbreiteTable from "@/components/vermoegensverwaltung/StrategienRichtbandbreiteTable.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import { SimpleAction } from "@/components/table2/table_util.js";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import { PageHeaderSimpleAction } from "@/components/core/header-title-navigation/page-header-utils";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";

export default {
  mixins: [mixin_vv],
  components: {
    VVWertpapiereList,
    StrategienZielmarktTable,
    StrategienRichtbandbreiteTable,
    InputTextArea,
    PageHeaderTitleNavigation,
    GhostLoading,
    OptionMenu,
  },
  data() {
    return {
      commentZulassung: "",
      commentVerwalter: "",

      archivVariante: {},
      actions: [],
    };
  },
  mounted() {
    this.$store
      .dispatch(VV_TYPES.ACTIONS.GET_ARCHIV_VARIANTE, this.substepKey)
      .then((response) => (this.archivVariante = response.data || {}))
      .then(() => this.initValues(this.archivVariante));
  },
  watch: {
    variante(value) {
      this.initValues(value);
    },
  },
  computed: {
    ...mapGetters({
      isSchemaDataLoading: VV_TYPES.GETTERS.STRATEGIEN_SCHEMA_DATA_LOADING,
    }),
    headerActions() {
      return this.variante?.actions?.map((act) =>
        PageHeaderSimpleAction(act.value, act.label).withIcon(act.status)
      );
    },
    variante() {
      return this.archivVariante;
    },
    substepKey() {
      console.info(this.$route.params)
      return this.$route.params.varianteId || "";
    },
    title() {
      return this.variante?.title || "Variante ";
    },
    disabled() {
      return true;
    },
  },
  methods: {
    initValues(values) {
      this.commentZulassung = values?.commentZulassung;
      this.commentVerwalter = values?.commentVerwalter;
      this.actions = this.variante?.actions?.map((act) =>
        SimpleAction(act.value, act.status, act.label)
      );
    },
    handleAction(data) {
      if (data?.key === "CLONE_VARIANTE") {
        this.$store.dispatch(VV_TYPES.ACTIONS.CLONE_SCHEMA_VERSION, {
          versionId: this.variante?.id,
          params: {},
        });
      }
    },
  },
};
</script>

<style>
</style>