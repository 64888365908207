<template>
  <div>
    <BaseCollapsable :showSection="showExpanded">
      <template v-slot:title>
        <div class="box__title">Zielmarktdaten</div>
      </template>
      <template v-slot:content>
        <Table
          :headers="headers"
          :rows="rows"
          :rowsPerPage="20"
          rowId="vvstrategienzielmarkt"
          hidePagination
        />
      </template>
    </BaseCollapsable>
  </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import { TextColumn } from "@/components/table2/table_util.js";
import BaseCollapsable from "@/components/core/BaseCollapsable.vue";

export default {
  components: {
    Table,
    BaseCollapsable,
  },
  props: {
    zielmarktdaten: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showExpanded: true,
    };
  },
  computed: {
    headers() {
      const headers = {
        lockedLeft: [],
        center: [
          TextColumn("category", ""),
          TextColumn("actualSetting", "Berechneter Zielmarkt").setColorFn(
            (row) => row?.actualSettingColor
          ),
          TextColumn("defaultSetting", "Zielmarkt laut Einstellungen"),
        ],
        lockedRight: [],
      };
      if (this.zielmarktdaten?.hasAktuellColumn) {
        headers.center.push(
          TextColumn("actualState", "Aktueller Stand").setColorFn(
            (row) => row?.actualStateColor
          )
        );
        if (this.rows?.some((row) => row.actualStateTooltip)) {
          headers.center.push(
            TextColumn(
              "actualStateTooltip",
              "Status Aktueller Stand"
            ).setColorFn((row) => row?.actualStateTooltip)
          );
        }
      }
      headers.center.push(
        TextColumn("comment", "Erklärungen", 600).addCellProps({
          lineClamp: 10,
        })
      );
      if (this.rows?.some((row) => row.actualSettingTooltip)) {
        headers.center.splice(
          2,
          0,
          TextColumn(
            "actualSettingTooltip",
            "Status",
            600
          ).setColorFn((row) => row?.actualSettingColor)
        );
      }
      return headers;
    },
    rows() {
      return this.zielmarktdaten?.tableRows || [];
    },
  },
  methods: {},
};
</script>

<style>
</style>