var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.bezeichnung || "Beratungstitel",
          actions: _vm.actions
        },
        on: {
          "action-OPEN_ERZEUGEN": function($event) {
            return _vm.openErzeugen()
          },
          "action-OPEN_SIGNIEREN": function($event) {
            return _vm.openSignieren()
          },
          "action-OPEN_MAIL": function($event) {
            return _vm.openMail()
          },
          "action-ADD_ACTIVITY": function($event) {
            return _vm.handleAddActivity()
          },
          "action-FORTSETZEN": function($event) {
            return _vm.beratungsmappeFortsetzen()
          },
          "action-ABSCHLIESSEN": function($event) {
            return _vm.beratungsmappeAbschliessen()
          },
          "action-CHANGE_DESCRIPTION": function($event) {
            return _vm.openEditBeratungsmappe()
          },
          "action-START_INVESTEMENT_ADVICE": function($event) {
            return _vm.startInvestementAdvice()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("span", [_vm._v(_vm._s(_vm.pageHeaderSubtitle))]),
                _vm.statusText ? _c("span", [_vm._v(" | ")]) : _vm._e(),
                _vm.statusText
                  ? _c("Pill", {
                      attrs: { label: _vm.statusText, type: "danger" }
                    })
                  : _vm._e(),
                _vm.expirationText
                  ? _c("Pill", {
                      attrs: {
                        label: _vm.expirationText,
                        type: _vm.expirationTextType
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "deleteAntraegeConfirmation",
          attrs: {
            modalTitle: "Antrag löschen",
            showDefaultButtons: false,
            actions: _vm.deleteAntraegeBaseModalActions
          },
          on: {
            "action-CANCEL": function($event) {
              return _vm.closeDeleteAntraegeConfirmation()
            },
            "action-REMOVE": function($event) {
              return _vm.deleteAntraege()
            },
            "action-DELETE": function($event) {
              return _vm.deleteAntraege(true)
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.deleteAntraegMessage) + " ")]
      ),
      _c("EditBeratungsmappeModal", {
        ref: "editBeratungsmappeModal",
        attrs: {
          isEdit: "",
          isBeratungsmappeClosed: _vm.isBeratungsmappeClosed
        },
        on: { confirm: _vm.saveBeratungsmappe }
      }),
      _c("PictureAttachmentsModal", {
        ref: "pictureAttachmentsModal",
        attrs: { selectedDoc: _vm.selectedDoc }
      }),
      _vm.erzeugen && !_vm.loading.getPdf
        ? _c("div", { staticClass: "box__container" }, [
            _c("p", [_vm._v(_vm._s(_vm.actionsTitle))]),
            !_vm.beratungsmappeActions.signedAttachments ||
            !_vm.beratungsmappeActions.signedAttachments.length
              ? _c("div", [
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-6 col-md-12 mb-2" },
                      [
                        _c("BaseButton", {
                          attrs: {
                            label: "Beratungsmappe erzeugen",
                            disabled: _vm.isSomeButtonLoading,
                            animated: _vm.loading.pdf
                          },
                          on: {
                            click: function($event) {
                              return _vm.beratungsmappeErzeugen()
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.pdfErzeugenCheckboxesGroups &&
                  _vm.pdfErzeugenCheckboxesGroups.length
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.pdfErzeugenCheckboxesGroups, function(
                            group,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "mt-2" },
                              [
                                group
                                  ? _c("InputCheckBoxGroup", {
                                      attrs: {
                                        title: group.label,
                                        values: group.values,
                                        value: _vm.checkboxValues
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _c("p", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.beratungsmappeActions.nachricht))
                          ])
                        ],
                        2
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.signieren && !_vm.loading.getSign
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("p", [_vm._v(_vm._s(_vm.actionsTitle))]),
              _vm.beratungsmappeActions.webserviceWarnings &&
              _vm.beratungsmappeActions.webserviceWarnings.length
                ? _c("WebserviceWarnings", {
                    staticClass: "mb-2",
                    attrs: {
                      webserviceWarnings:
                        _vm.beratungsmappeActions.webserviceWarnings
                    }
                  })
                : _vm._e(),
              _vm._l(_vm.beratungsmappeActions.signatures, function(
                signableDocument,
                index
              ) {
                return _c(
                  "div",
                  { key: index },
                  [
                    signableDocument.url
                      ? _c("SignoViewerLink", {
                          attrs: {
                            target: "_blank",
                            href: signableDocument.url,
                            title: signableDocument.fileName
                          }
                        })
                      : signableDocument.signaturStatus !== "KOMPLETT" &&
                        signableDocument.isPictureAttach
                      ? _c(
                          "a",
                          {
                            attrs: { target: "_blank" },
                            on: {
                              click: function($event) {
                                return _vm.openPictureAttach(signableDocument)
                              }
                            }
                          },
                          [_vm._v(_vm._s(signableDocument.fileName))]
                        )
                      : _c("span", { staticClass: "grayed-out" }, [
                          _vm._v(_vm._s(signableDocument.fileName))
                        ])
                  ],
                  1
                )
              }),
              _c("p", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.beratungsmappeActions.nachricht))
              ])
            ],
            2
          )
        : _vm._e(),
      _vm.versenden && !_vm.loading.getSend
        ? _c("div", { staticClass: "box__container" }, [
            _c("p", [_vm._v(_vm._s(_vm.actionsTitle))]),
            !_vm.beratungsmappeActions.signedAttachments ||
            !_vm.beratungsmappeActions.signedAttachments.length
              ? _c("div", [
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-6 col-md-12 mb-2" },
                      [
                        _c("BaseButton", {
                          staticClass: "btn btn-primary ",
                          attrs: {
                            label: "Beratungsmappe versenden",
                            disabled: _vm.isSomeButtonLoading,
                            animated: _vm.loading.send
                          },
                          on: {
                            click: function($event) {
                              return _vm.beratungsmappeVersenden()
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm.pdfErzeugenCheckboxesGroups &&
                  _vm.pdfErzeugenCheckboxesGroups.length
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.pdfErzeugenCheckboxesGroups, function(
                            group,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "mt-2" },
                              [
                                group
                                  ? _c("InputCheckBoxGroup", {
                                      attrs: {
                                        title: group.label,
                                        values: group.values,
                                        value: _vm.checkboxValues
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _c("p", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.beratungsmappeActions.nachricht))
                          ])
                        ],
                        2
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.beratungsmappeCards && _vm.cardsData && _vm.cardsData.length
        ? _c("div", { staticClass: "beratung-edit__main" }, [
            _c(
              "div",
              { staticClass: "beratung-edit__sidebar" },
              [
                _vm.loading.init
                  ? _c(
                      "div",
                      [
                        _c("GhostLoading", {
                          attrs: {
                            useBoxContainer: "",
                            type: "block",
                            config: { block: { height: 100 } }
                          }
                        }),
                        _c("GhostLoading", {
                          attrs: {
                            useBoxContainer: "",
                            type: "block",
                            config: { block: { height: 100 } }
                          }
                        }),
                        _c("GhostLoading", {
                          attrs: {
                            useBoxContainer: "",
                            type: "block",
                            config: { block: { height: 100 } }
                          }
                        }),
                        _c("GhostLoading", {
                          attrs: {
                            useBoxContainer: "",
                            type: "block",
                            config: { block: { height: 100 } }
                          }
                        }),
                        _c("GhostLoading", {
                          attrs: {
                            useBoxContainer: "",
                            type: "block",
                            config: { block: { height: 100 } }
                          }
                        }),
                        _c("GhostLoading", {
                          attrs: {
                            useBoxContainer: "",
                            type: "block",
                            config: { block: { height: 100 } }
                          }
                        })
                      ],
                      1
                    )
                  : _c("BoxList", {
                      attrs: { items: _vm.cardsData },
                      on: { clickItem: _vm.handleCardItemClick },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.key == "unterschriften_info_text"
                                  ? _c("ph-info", {
                                      staticClass: "color-info",
                                      attrs: { size: 24 }
                                    })
                                  : item.key ==
                                    "erfassung_position_papierantrag"
                                  ? _c("ph-info", {
                                      staticClass: "color-info",
                                      attrs: { size: 24 }
                                    })
                                  : item.key == "bemerkung_text"
                                  ? _c("ph-info", {
                                      staticClass: "color-info",
                                      attrs: { size: 24 }
                                    })
                                  : item.key ==
                                    "unterschriften_question_mark_text"
                                  ? _c("ph-question", {
                                      staticClass: "color-info",
                                      attrs: { size: 24 }
                                    })
                                  : item.status == "success"
                                  ? _c("ph-check-circle", {
                                      staticClass: "color-success",
                                      attrs: { size: 24 }
                                    })
                                  : item.status == "warning"
                                  ? _c("ph-warning-circle", {
                                      staticClass: "color-warning",
                                      attrs: { size: 24 }
                                    })
                                  : item.status == "danger"
                                  ? _c("ph-warning-circle", {
                                      staticClass: "color-danger",
                                      attrs: { size: 24 }
                                    })
                                  : item.status == "info"
                                  ? _c("ph-info", {
                                      staticClass: "color-info",
                                      attrs: { size: 24 }
                                    })
                                  : _c("ph-circle", {
                                      staticClass: "color-primary",
                                      attrs: { size: 24 }
                                    })
                              ]
                            }
                          },
                          {
                            key: "default",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.key !== "unterschriften_info_text" &&
                                item.key !== "unterschriften_question_mark_text"
                                  ? _c("h3", { staticClass: "mt-0 mb-1" }, [
                                      _vm._v(_vm._s(item.label))
                                    ])
                                  : _vm._e(),
                                item.key !== "unterschriften_info_text"
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "mt-0 mb-0",
                                        attrs: {
                                          tid:
                                            item.description &&
                                            item.description.length
                                              ? _vm._generateTidFromString(
                                                  item.description + item.key
                                                )
                                              : null
                                        }
                                      },
                                      [
                                        _c("small", [
                                          _vm._v(_vm._s(item.description))
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._l(item.warnings, function(warning, index) {
                                  return _c(
                                    "p",
                                    { key: index, staticClass: "mt-0 mb-0" },
                                    [
                                      _c(
                                        "small",
                                        {
                                          class: _vm.getStatusClass(
                                            warning.status
                                          )
                                        },
                                        [_vm._v(" " + _vm._s(warning.message))]
                                      )
                                    ]
                                  )
                                }),
                                item.key == "unterschriften_info_text"
                                  ? _c("p", { staticClass: "mt-0 mb-0" }, [
                                      _c("small", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.sanitize(
                                              _vm.unterschriftenModalMsg
                                            )
                                          )
                                        }
                                      })
                                    ])
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "link",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.clickable
                                  ? _c("a", [_vm._v(_vm._s(item.linkText))])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        19665767
                      )
                    })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "beratung-edit__content" },
              [
                _vm.beratungsmappeCards.mitarbeiterRabattVisible
                  ? _c(
                      "div",
                      {
                        staticClass: "box__container",
                        attrs: {
                          tid: _vm._generateTidFromString("box__container")
                        }
                      },
                      [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Bestätigung Mitarbeiterrabatt")
                        ]),
                        _c("InputToggleSwitch", {
                          attrs: {
                            label:
                              "Der beantragte Mitarbeiterrabatt ist erlaubt",
                            inLineLabel: true,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.updateMitarbeiterrabatt($event)
                            }
                          },
                          model: {
                            value:
                              _vm.beratungsmappeCards
                                .mitarbeiterRabattConfirmed,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.beratungsmappeCards,
                                "mitarbeiterRabattConfirmed",
                                $$v
                              )
                            },
                            expression:
                              "beratungsmappeCards.mitarbeiterRabattConfirmed"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showForms
                  ? _c("OptionMenu", {
                      attrs: {
                        id: _vm.FORMULARE_OPTIONS_MENU_ID,
                        showEditButton: false,
                        defaultMenu: _vm.customOptionMenu,
                        customOptionMenu: _vm.customOptionMenu,
                        ignoreRender: false
                      }
                    })
                  : _vm._e(),
                _vm.showForms &&
                _vm.selectedLagerstelle &&
                _vm.selectedLagerstelle.path
                  ? _c(
                      "div",
                      [
                        _vm.verwende !== "DJE_KUNDENDOKU_2018"
                          ? _c(
                              "div",
                              {
                                staticClass: "box__container",
                                attrs: {
                                  tid: _vm._generateTidFromString(
                                    "box__container"
                                  )
                                }
                              },
                              [
                                _c("div", { staticClass: "box__title" }, [
                                  _vm._v(_vm._s(_vm.selectedLagerstelle.label))
                                ]),
                                _c("InputField", {
                                  attrs: {
                                    isComponentHalfSize: "",
                                    placeholder: "Suchen nach Bezeichnung"
                                  },
                                  model: {
                                    value: _vm.descriptionSearch,
                                    callback: function($$v) {
                                      _vm.descriptionSearch = $$v
                                    },
                                    expression: "descriptionSearch"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(_vm.groupedForms, function(value, propName) {
                          return _c(
                            "div",
                            { key: propName, staticClass: "box__container" },
                            [
                              _c("Table", {
                                attrs: {
                                  rowId: "index_plus_category",
                                  headers: _vm.antragListeHeaders(propName),
                                  rows: _vm.antragListeRows(value, propName),
                                  hidePagination: ""
                                },
                                on: { "click-label": _vm.handleLabelClick }
                              })
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.loading.init
                  ? _c("GhostLoading", {
                      attrs: {
                        useBoxContainer: "",
                        type: "block",
                        config: { block: { height: 100 } }
                      }
                    })
                  : _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _vm.antragRows.length
                          ? _c("Table", {
                              attrs: {
                                rowId: "index",
                                title: "Anträge",
                                headers: _vm.antragHeaders,
                                rows: _vm.antragRows,
                                hidePagination: ""
                              },
                              on: { "click-label": _vm.editAntraeg },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "delete",
                                    fn: function(row) {
                                      return [
                                        _c("ph-trash", {
                                          staticClass: "ml-3",
                                          attrs: { size: 18 },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteAntraegeConfirmation(
                                                row
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                236335729
                              )
                            })
                          : _c("NoData", {
                              attrs: {
                                noIcon: "",
                                content: "Keine Anträge vorhanden"
                              }
                            })
                      ],
                      1
                    ),
                !!_vm.referredFromInvestmentAdvice === false
                  ? _c(
                      "div",
                      [
                        _vm.loading.init
                          ? _c("GhostLoading", {
                              attrs: {
                                useBoxContainer: "",
                                type: "block",
                                config: { block: { height: 100 } }
                              }
                            })
                          : _c(
                              "div",
                              { staticClass: "box__container" },
                              [
                                _vm.positionRows.length
                                  ? _c("Table", {
                                      attrs: {
                                        tableId:
                                          "bd07a11c-2664-413b-99ba-0bc846a0d90c",
                                        rowId: "index",
                                        title: "Orderpositionen",
                                        headers: _vm.positionHeaders,
                                        rows: _vm.positionRows,
                                        hidePagination: ""
                                      },
                                      on: {
                                        "click-wertpapier":
                                          _vm.openOrderPosition
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "delete",
                                            fn: function(row) {
                                              return [
                                                row.isManuellePosition
                                                  ? _c("ph-trash", {
                                                      staticClass: "ml-3",
                                                      attrs: { size: 18 },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deletePosition(
                                                            row
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3127210471
                                      )
                                    })
                                  : _c("NoData", {
                                      attrs: {
                                        noIcon: "",
                                        content: "Keine Positionen vorhanden"
                                      }
                                    })
                              ],
                              1
                            ),
                        _vm.positionRows.length && !_vm.loading.init
                          ? _c("div", { staticClass: "box__container" }, [
                              _c("table", { staticStyle: { width: "100%" } }, [
                                _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "header-cell" },
                                        [_vm._v("Einmalanlagen")]
                                      ),
                                      _c("CurrencyLabel", {
                                        staticClass: "table-cell",
                                        attrs: {
                                          value: _vm.positionSums.summeEinmal
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "header-cell" },
                                        [_vm._v("Verkäufe")]
                                      ),
                                      _c("CurrencyLabel", {
                                        staticClass: "table-cell",
                                        attrs: {
                                          value: _vm.positionSums.summeVerkauf
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "header-cell" },
                                        [_vm._v("Gesamt")]
                                      ),
                                      _c("CurrencyLabel", {
                                        staticClass: "table-cell",
                                        attrs: {
                                          value:
                                            _vm.positionSums.summeGesamtEinmal
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]),
                                _c("tr", [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "header-cell" },
                                        [_vm._v("monatl. Anlage")]
                                      ),
                                      _c("CurrencyLabel", {
                                        staticClass: "table-cell",
                                        attrs: {
                                          value: _vm.positionSums.summeSpar
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "header-cell" },
                                        [_vm._v("monatl. Entnahme")]
                                      ),
                                      _c("CurrencyLabel", {
                                        staticClass: "table-cell",
                                        attrs: {
                                          value: _vm.positionSums.summeEntnahme
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "header-cell" },
                                        [_vm._v("Gesamt")]
                                      ),
                                      _c("CurrencyLabel", {
                                        staticClass: "table-cell",
                                        attrs: {
                                          value:
                                            _vm.positionSums.summeGesamtSpar
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                !!_vm.referredFromInvestmentAdvice
                  ? _c("div", [
                      _vm.investmentAdviceOrderList.length > 0
                        ? _c(
                            "div",
                            [
                              _c("div", [_vm._v("Wertpapierorder*")]),
                              _c("BoxList", {
                                attrs: { items: _vm.investmentAdviceOrderList },
                                on: {
                                  clickItem:
                                    _vm.handleInvestmentAdviceOrderListClick
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "icon",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          item.checked
                                            ? _c("ph-check-circle", {
                                                staticClass: "color-success",
                                                attrs: { size: 24 }
                                              })
                                            : _c("ph-warning-circle", {
                                                staticClass: "color-warning",
                                                attrs: { size: 24 }
                                              })
                                        ]
                                      }
                                    },
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "h3",
                                            {
                                              staticClass: "mt-0 mb-1",
                                              attrs: {
                                                tid:
                                                  item.label &&
                                                  item.label.length
                                                    ? _vm._generateTidFromString(
                                                        item.label + item.key
                                                      )
                                                    : null
                                              }
                                            },
                                            [_vm._v(_vm._s(item.label))]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "mt-0 mb-0" },
                                            [
                                              !item.checked
                                                ? _c("small", [
                                                    _vm._v(
                                                      " Bitte überprüfen Sie die Antragsdaten "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "link",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          !item.checked
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:;"
                                                  }
                                                },
                                                [_vm._v(" Öffnen ")]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4195558988
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.investmentAdviceAntraege.length > 0
                        ? _c(
                            "div",
                            [
                              _c("div", [_vm._v("Anträge*")]),
                              _c("BoxList", {
                                attrs: { items: _vm.investmentAdviceAntraege },
                                on: {
                                  clickItem:
                                    _vm.handleInvestmentAdviceAntraegeClick
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "icon",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          item.checked
                                            ? _c("ph-check-circle", {
                                                staticClass: "color-success",
                                                attrs: { size: 24 }
                                              })
                                            : _c("ph-warning-circle", {
                                                staticClass: "color-warning",
                                                attrs: { size: 24 }
                                              })
                                        ]
                                      }
                                    },
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _c(
                                            "h3",
                                            {
                                              staticClass: "mt-0 mb-1",
                                              attrs: {
                                                tid:
                                                  item.label &&
                                                  item.label.length
                                                    ? _vm._generateTidFromString(
                                                        item.label + item.key
                                                      )
                                                    : null
                                              }
                                            },
                                            [_vm._v(_vm._s(item.label))]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "mt-0 mb-0" },
                                            [
                                              !item.checked
                                                ? _c("small", [
                                                    _vm._v(
                                                      " Bitte überprüfen Sie die Antragsdaten "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "link",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          !item.checked
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "javascript:;"
                                                  }
                                                },
                                                [_vm._v(" Öffnen ")]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4195558988
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "multipleFilesModal",
          attrs: {
            modalTitle: _vm.bezeichnung + ".pdf" || "Beratungstitel",
            showCancelButton: false,
            showConfirmButton: false
          }
        },
        [
          _c("FileAttachmentsModal", {
            attrs: {
              signedAttachments: _vm.executeActionResult.signedAttachments,
              unsignedAttachments:
                _vm.executeActionResult.unsignedAttachments || []
            },
            on: {
              close: function($event) {
                return _vm.$refs.multipleFilesModal.close()
              }
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "investementAdvice",
          attrs: {
            modalTitle: "Antragspositionen werden gelöscht",
            labelButtonCancel: "Nein",
            labelButtonConfirm: "Ja"
          },
          on: { onConfirmButton: _vm.startInvestementAdvice2 }
        },
        [
          _c("p", [
            _vm._v(
              "Durch die Anlageempfehlung werden alle bestehenden Antragspositionen, die noch nicht beantragt wurden, gelöscht."
            )
          ]),
          _vm._v(" Möchten Sie fortfahren? ")
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "grundlagenwissenModal",
          attrs: {
            modalTitle: "Grundlagenwissen Wertpapiere",
            labelButtonConfirm: "Speichern",
            confirmDisabled: _vm.isBeratungsmappeClosed
          },
          on: { onConfirmButton: _vm.confirmGrundlagenwissenModal }
        },
        [
          _c("InputRadioBoxGroup", {
            attrs: {
              title:
                "Soll dem Kunden die Broschüre Grundlagenwissen Wertpapiere kostenpflichtig (1,28€) zur Verfügung gestellt werden?",
              disabled: _vm.isBeratungsmappeClosed,
              values: _vm.grundlagenwissenValues
            },
            model: {
              value: _vm.grundlagenwissenData.SHOW_GRUNDLAGENWISSEN,
              callback: function($$v) {
                _vm.$set(_vm.grundlagenwissenData, "SHOW_GRUNDLAGENWISSEN", $$v)
              },
              expression: "grundlagenwissenData.SHOW_GRUNDLAGENWISSEN"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              title:
                "Kunde wird / wurde die Broschüre Grundlagenwissen Wertpapiere in gedruckter / CD Form ausgehändigt.",
              disabled: _vm.isBeratungsmappeClosed,
              values: _vm.grundlagenwissenValues
            },
            model: {
              value: _vm.grundlagenwissenData.IGNORE_GRUNDLAGENWISSEN,
              callback: function($$v) {
                _vm.$set(
                  _vm.grundlagenwissenData,
                  "IGNORE_GRUNDLAGENWISSEN",
                  $$v
                )
              },
              expression: "grundlagenwissenData.IGNORE_GRUNDLAGENWISSEN"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }