<template>
    <div>
        <OptionMenu
            :id="$appNavigation.currentOptionMenuId"
            :defaultMenu="$appNavigation.currentOptionMenu" >
        </OptionMenu>

        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.label"
            :subtitle="subtitle"
            :id="$appNavigation.currentOptionMenuId"
            :actions="actions"
            @action-PDF="getPdf"
            @action-EDIT="$refs.einstellungenModal.open()"
        >
        </PageHeaderTitleNavigation>

        <div>
            <div class="box__container">
                <div class="box__title"><CurrencyLabel label="Vermögen heute:" showInlineLabel :value="gesamtStartVermoegen(scenario)"/></div>
                <div class="font-bold">Alter bei Beginn Ruhestand</div>
                <InputSlider v-if="alterList && alterList.length"
                    :value="scenario.alterRente + ''"
                    :values="alterList"
                    @input="doChanges('alterRente', +$event)"
                />
                <div class="box__title" v-if="!scenario.ohneKapitalverzehr"><CurrencyLabel label="Wunschrente (zu Rentenbeginn berüksichtig Inflation):" showInlineLabel :value="wunschrenteMitInflation(scenario)"/></div>
                <div class="box__title" v-else>Monatliche Rente aus Kapitalvermögen (sieh Zahlungsplan)</div>
                </div>
                

                <div class="dashboard-panel__widgets">
                    <div class="box__container dashboard-panel-widget">
                        <div class="layout__negative-margin--8 pb-3 text-right">
                            <BaseButton isLink @click="openGoalModal({zuflussType: 'AUSGABE'})">
                                Ausgabe hinzufügen
                            </BaseButton>
                            <BaseButton isLink @click="openGoalModal({zuflussType: 'EINNAHME'})">
                                Einnahme hinzufügen
                            </BaseButton>
                        </div>
                        <Table v-if="rowsMyGoals && rowsMyGoals.length"
                            title="Meine Einahmen und Verbindlichkeiten"
                            rowId="id"
                            :headers="headersMyGoals"
                            :rows="rowsMyGoals"
                            :rowsPerPage="10"
                            hidePagination
                            :mobileConfig="{}"
                            @selected="onSelectedMyGoals"
                            :selected="selectedGoals"
                            @action-EDIT="editGoal"
                            @action-DELETE="deleteAction"
                            @click-title="editGoal"
                        >
                        <template #chart="row">
                            <PieChart 
                            style="width: 40px"
                            :chartData="eChartData(row)" 
                            :customColors="eChartColors"
                            :isTooltip="false"
                            height="40px"
                            doughnut
                            />
                        </template>
                        </Table>
                        <NoData v-else title="Meine Ziele" />

                        <GesamtVermoegen v-if="vermoegen && vermoegen.length || ausgaben && ausgaben.length"/>
                    </div>
                
                    <div class="box__container dashboard-panel-widget">
                        <div class="mt-3 mb-3">
                        <div>
                            <RetirementChart
                                :data="{
                                    data: apexSeriesWithOriginalWert,
                                    series: apexSeriesWithOriginalWert,
                                    xaxis: apexXaxisCurrentScenario,
                                    annotations: apexAnnotationsCurrentScenario,
                                    markers: apexMarkers,
                                    dataLabels: {enabled: false},
                                    stroke: {curve: 'smooth'},
                                    autoUpdateSeries: true,
                                }"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="box__container"> 
                <BaseCollapsable :showSection="showExpanded1">
                    <template v-slot:title>
                    <div class="box__title">
                        Berechnung benötiges Vermögen
                    </div>
                    </template>
                    <template v-slot:content>
                    <Table v-if="tilgungsplanVermoegenBenoetigt"
                        rowId="jahr"
                        :headers="headersTilgungsplan"
                        :rows="tilgungsplanVermoegenBenoetigt"
                        :rowsPerPage="50"
                        :mobileConfig="{}"
                    >
                    </Table>
                    </template>
                </BaseCollapsable>
            </div>  

            <div class="box__container"> 
                <BaseCollapsable :showSection="showExpanded2">
                    <template v-slot:title> <div class="box__title"> Zahlungsplan</div></template>
                    <template v-slot:content>
                    <Table v-if="tilgungsplanRowsCurrentScenario"
                        rowId="jahr"
                        :headers="headersTilgungsplan"
                        :rows="tilgungsplanRowsCurrentScenario"
                        :rowsPerPage="50"
                        :mobileConfig="{}"
                    >
                    </Table>
                    </template>
                </BaseCollapsable>
            </div>  

            <BaseModal
                :modalTitle="goalModalTitle"
                ref="goalModal"
                labelButtonConfirm="Speichern"
                :confirmDisabled="!canSaveGoal"
                @onConfirmButton="saveGoal"
            >
                <EditForm ref="editForm"
                    :goal="selectedGoal"
                    :comboboxEventTypes="comboboxEventTypes"
                    @isValid="updateCanSaveGoal"
                />
            </BaseModal>

            <BaseModal
                modalTitle="Einstellungen"
                ref="einstellungenModal"
                labelButtonConfirm="Speichern"
                :showCancelButton="false"
                @close="saveFinancialPlaning"
            >
                <Einstellungen/>
            </BaseModal>

            <BaseModal
                modalTitle="Financial planing erstellen"
                ref="initModal"
                labelButtonConfirm="Erstellen"
                :confirmDisabled="!canSaveScenario"
                @onConfirmButton="saveFinancialPlaning"
                @onCancelButton="initModalClosed"
                @onCloseButton="initModalClosed"
                @onEscButton="initModalClosed"
            >
                <InputField id="scenario.title"
                    label="Name des Scenarios"
                    :value="scenario.title"
                    validateUntouched
                    @input="doChanges('title', $event)"
                />
                <InputTextArea
                    label="Notizen"
                    :value="scenario.bemerkung"
                    @input="doChanges('bemerkung', $event)"
                    :rows="5"
                />
                <DatePickerField v-if="!scenario.geburtsdatum"
                    label="Geburtstag"
                    placeholder="DD.MM.JJJJ"
                    isValueAsString
                    :value="scenario.geburtsdatum"
                    @change="doChanges('geburtsdatum', $event)"
                />
            </BaseModal>
        </div>
    </div>
</template>

<script>
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import mixin from '@/views/ruhestandsplanung/mixin.js';
import RUHESTANDSPLANUNG_TYPES from '@/store/ruhestandsplanung/types';
import MY_GOALS_TYPES from '@/store/myGoals/types';
import {formatNumber} from '@/helpers/number-formatter.js';
import InputField from "@/components/core/forms/InputField.vue";
import RetirementChart from '@/components/retirementScenario/RetirementChart.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import PieChart from '@/components/charts/echarts/PieChart.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, PercentageColumn, CurrencyColumn, PillColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import EditForm from '@/views/ruhestandsplanung/EditForm.vue';
import Einstellungen from '@/views/ruhestandsplanung/Einstellungen.vue';
import GesamtVermoegen from '@/views/ruhestandsplanung/GesamtVermoegen.vue';
import {PhPencilLine, PhTrash} from 'phosphor-vue';
import BaseCollapsable from '@/components/core/BaseCollapsable.vue'
import NoData from '@/components/core/NoData.vue';
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import { required } from "@/mixins/validator/rules";
import validator from "@/mixins/validator";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputSlider from '@/components/core/forms/InputSlider.vue';
import { range } from '@/components/brokerSearch/brokerSearchConstants.js';

export default {
    mixins: [mixin, validator],
    validators: {
        scenario: {
            title: [required('Bitte geben Sie einen Name')],
            geburtsdatum: [required('Bitte geben Sie das Geburtsdatum ein')],
        }
    },
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        InputField,
        InputTextArea,
        RetirementChart,
        CurrencyLabel,
        PieChart,
        Table,
        BaseModal,
        BaseButton,
        EditForm,
        BaseCollapsable,
        NoData,
        Einstellungen,
        DatePickerField,
        GesamtVermoegen,
        InputSlider,
    },
    data() {
        return {
            showExpanded1: false,
            showExpanded2: false,
            selectedGoal: null,
            canSaveGoal: false,
            showOriginalWert: false,
            headersMyGoals: {
                lockedLeft: [
                    PillColumn("typeDescPill", "Typ"),
                    TextColumn("title", "Bezeichnung").makeAlwaysVisible().makeLink(),
                    CurrencyColumn("betrag", "Betrag").makeAlwaysVisible(),
                ],
                center: [
                    DateColumn("startDate", "Start"),
                    DateColumn("endDate", "Ende"),
                    TextColumn("frequenzDesc", "Frequenz"),
                    PercentageColumn("rendite", "Rendite")
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            },
            headersTilgungsplan: {
                lockedLeft: [
                    TextColumn("jahr", "Jahr"),
                ],
                center: [
                    CurrencyColumn("depotwert", "Vermögen"),
                    CurrencyColumn("einnahmen", "Einnahmen"),
                    CurrencyColumn("ausgaben", "Ausgaben"),
                    PercentageColumn("rendite", "Rendite"),
                ],
                lockedRight: [
                    CurrencyColumn("summe", "Endwert"),
                ],
            }
        }
    },
    async mounted() {
        this.$store.commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.RESET_SCENARIO);
        await this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_SCENARIO, this.id);
        this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_VERMOEGEN);
        this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.GET_GOALS);
        if (this.id === 'new') {
            this.$refs.initModal.open();
            this.initStartValues();
        }
    },
    computed: {
        ...mapGetters({
            comboboxEventTypes: RUHESTANDSPLANUNG_TYPES.GETTERS.COMBOBOX_GOAL_TYPES,
        }),
        alterList() {
            return range(this.lebenserwartung(this.scenario), this.alterAktuell(this.scenario) + 1).sort((a,b) => (a?.label || 0) - (b.label || 0)) || [];
        },
        subtitle() {
            return `<b>${this.scenario.title || ''}</b>`;
        },
        goalModalTitle() {
            return this.selectedGoal?.zuflussType === 'AUSGABE' ? 'Ausgabe erfassen' : 'Einnahmen erfassen'
        },
        rowsMyGoals() {
            const goals = this.goals?.map((row, index) => {
                return {
                    ...row,
                    betrag: row.zielsumme,
                    typeDescPill: {
                        label: row.typeDesc,
                        type: 'danger',
                    },
                    goalType: 'MY_GOAL',
                    actions: [SimpleAction("EDIT", PhPencilLine, "Bearbeiten")],
                }
            }) || [];
            const actions = [SimpleAction("EDIT", PhPencilLine, "Bearbeiten"), SimpleAction("DELETE", PhTrash, "Löschen")];
            goals.push(...this.scenario.scenarioEventList?.map((row, index) => {
                return {
                    ...row,
                    typeDescPill: {
                        label: row.typeDesc,
                        type: row.zuflussType === 'EINNAHME' ? 'success' : 'danger',
                    },
                    goalType: 'SCENARIO_EVENT',
                    rendite: row.rendite || '',
                    actions: actions,
                }
            }) || []);
            return goals;
        },
        apexSeriesWithOriginalWert() {
            const series = Object.assign([], this.apexSeriesCurrentScenario);
            if (this.showOriginalWert && this.hasOriginalWert) {
                series.push({
                    data: this.tilgungsplanRowsOriginalWert,
                });
            }
            return series;
        },
        tilgungsplanRowsOriginalWert() {
            return this.scenarioUrsprung ? this.tilgungsplanRows(this.scenarioUrsprung).map(row => row.summe || 0) : [];
        },
        actions() {
            const actions = [];
            if (this.scenario) {
                actions.push(PageHeaderSimpleAction('PDF', 'PDF'));
                actions.push(PageHeaderSimpleAction('NEW_TICKET', 'Nachricht an Betreuer'));
                actions.push(PageHeaderSimpleAction('EDIT', 'Daten anpassen'));
            }
            return actions;
        }
    },
    methods: {
        async getPdf() {
            await this.saveFinancialPlaning();
            const data = {
                apexSeries: this.apexSeriesCurrentScenario?.[0]?.data || [], 
                apexAnnotations: this.apexAnnotationsCurrentScenario?.xaxis?.map(elem => ({ x: elem?.x || 0, label: elem?.label?.text || ''})),
                vermoegenBenoetigtRenteBeginn: formatNumber(this.vermoegenBenoetigtRenteBeginn, 0),
                wunschrenteMitInflation: formatNumber(this.wunschrenteMitInflation(this.scenario), 0),
                restKapitalEffektiv: formatNumber(this.restKapitalEffektiv, 0),
                sparrate: formatNumber(this.sparrate, 2),
                vermoegenGeplant: formatNumber(this.vermoegenGeplant, 0),
                alterVermoegenEnde: formatNumber(this.alterVermoegenEnde, 0),
                renditeCalc: formatNumber(this.renditeCalc, 2),
                renditeDepotGewichtet: formatNumber(this.renditeDepotGewichtetCurrentScenario, 2),
                sparrateErhoehungCalc: formatNumber(this.sparrateErhoehungCalc()),
            }
            this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.GET_PDF, {id: this.scenario.id, data})
        },
        onSelectedMyGoals(data = []) {
            const unselected = this.selectedGoals?.filter(sel => !data?.some(elem => elem.id === sel.id));

            data?.forEach(sel => this.$store.commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_SELECTED_GOALS, {
                goal: Object.assign(sel, {selected: true})}));
            
            unselected?.forEach(sel => {
                this.$store.commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_SELECTED_GOALS, {goal: Object.assign(sel, {selected: false})});
            });
        },
        async openGoalModal(goal) {
            await this.saveFinancialPlaning();  
            this.selectedGoal = goal;
            this.$refs.goalModal.open();
        },
        saveGoal() {
            const goal = this.$refs.editForm.getData();
            if (goal?.betrag && goal?.type && goal?.title) {
                delete goal.typeDescPill;
                delete goal.actions;
                delete goal.goalType;
                this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.SAVE_GOAL_SCENARIO, { goal: goal });
            }
        },
        updateCanSaveGoal(value) {
            this.canSaveGoal = value;
        },
        deleteAction(goal) {
            if (goal?.id) {
                this.$confirmModal({
                    title: `${goal.zuflussType === 'EINNAHME' ? 'Einnhame' : 'Ausgabe'} löschen`, 
                    message: `Wollen Sie die ${goal.zuflussType === 'EINNAHME' ? 'Einnhame' : 'Ausgabe'} ${goal.title} wirklich löschen?`, 
                    labelButtonConfirm: 'Löschen',
                }).then(() => {
                    this.$store.dispatch(RUHESTANDSPLANUNG_TYPES.ACTIONS.REMOVE_GOAL_SCENARIO, goal.id);
                });
            }
        },
        async editGoal(goal) {
            if (goal?.id) {
                if (goal.goalType === 'MY_GOAL' && this.goals?.find(g => g.id === goal.id)) {
                    this.$addBreadcrumb({
                        label: 'zurück',
                        fullPath: this.$route.fullPath,
                    });
                    this.$router.push(`${this.$route.path.startsWith('/service') ? '/service' : '/beratung'}/meineziele/${goal.id}/preview`);
                } else {
                    this.openGoalModal(goal);
                }
            }
        },
        initModalClosed() {
            this.$router.push({ path: 'list' })
        },
        initStartValues() {
            this.doChanges('lebenserwartung', this.lifeLength);
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.canSaveScenario && this.id !== 'new') {
            this.saveFinancialPlaning();
        }
        next();
    },
}
</script>

<style lang="scss" scoped>
.cards__item--inputs {
  margin: 20px -8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > * {
        width: calc(30% - 1rem);
        flex-basis: auto;
        margin: 0 20px;
    }
}
.dashboard-panel__widgets {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.dashboard-panel-widget {
  margin: 0 12px 24px;
  width: calc(50% - 24px);
  &.box__container {
    .dashboard-panel-widget__content {
      .box__container,
      .box__shadow {
        background: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }
    }
  }
}
</style>