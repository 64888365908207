var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Hobbys", {
        attrs: {
          optionen: _vm.customerDataConfig.hobbies,
          liste: _vm.customerData.hobbies || [],
          isEditable: _vm.isEditable
        },
        on: {
          setHobbys: function($event) {
            return _vm.addCustomerDataEdited("hobbies", $event)
          }
        }
      }),
      _c("Vorlieben"),
      _c("ZusaetzlicheInfos")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }