export function getTicketStatusDescription(isKunde, isIntern, ticket) {
    if (!ticket?.status) {
        return "";
    };
    let statusDescription;
    if (isKunde) {
        statusDescription = ticket.status === "BEARBEITET" ? "bearbeitet" : (ticket.status === "GESCHLOSSEN" ? "geschlossen" : "offen");
    } else {
        switch (ticket.status) {
            case "ANFRAGE":
                statusDescription = "Anfrage";
                break;
            case "IN_BEARBEITUNG":
                statusDescription = "In Bearbeitung";
                break;
            case "BITTE_UM_BEARBEITUNG":
                if (isIntern) {
                    statusDescription ="Bitte um Bearbeitung";
                } else {
                    statusDescription = "In Bearbeitung";
                };
                break;
            case "BITTE_UM_KENNTNISNAHME":
                if (isIntern) {
                    statusDescription ="Bitte um Kenntnisnahme";
                } else {
                    statusDescription = "In Bearbeitung";
                };
                break;
            case "IN_BEARBEITUNG_GESELLSCHAFT":
                if (isIntern) {
                    statusDescription ="In Bearbeitung Gesellschaft";
                } else {
                    statusDescription = "In Bearbeitung";
                };
                break;
            case "IN_BEARBEITUNG_IT":
                if (isIntern) {
                    statusDescription ="In Bearbeitung IT";
                } else {
                    statusDescription = "In Bearbeitung";
                };
                break;
            case "BEARBEITET":
                statusDescription = "Bearbeitet";
                break;
            case "GESCHLOSSEN":
                statusDescription = "Geschlossen";
                break;
            default:
                statusDescription = "Unbekannter Status";
                console.log("unbekannter status: ", ticket.status);
        }
    };
    return statusDescription;
};