<template>
  <div>
    <div class="box__container" v-if="isBrokerBypassedByIntern">
      <ComboBox
        label="Organisationstyp"
        isComponentHalfSize
        :values="organizationTypes"
        v-model="vertriebsInfoEdit.organisationsTyp"
        :disabled="isDisabled"
        @input="wasEdited()"
      />
      <InputCheckboxItem
        v-model="vertriebsInfoEdit.isSaas"
        label="Software as a Service (SaaS)"
        :disabled="isDisabled"
        @input="wasEdited()"
      />
      <InputField
        label="Maklergruppe"
        isComponentHalfSize
        v-model="vertriebsInfoEdit.maklergruppe"
        :disabled="isDisabled"
        @input="wasEdited()"
      />
      <InputField
        label="Vertriebsgebiet"
        isComponentHalfSize
        v-model="vertriebsInfoEdit.vertriebsgebiet"
        disabled
        @input="wasEdited()"
      />
    </div>
  </div>
</template>
<script>
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import { mapGetters } from "vuex";
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import CORE_TYPES from "@/store/core/types";
import validator from '@/mixins/validator';
import { VIEW_ROLES } from '@/router/roles';
import VERTRIEBS_INFO_TYPES from '@/store/vertriebsinfo/types';
import { ORGANIZATION_TYPES_LABEL_VALUE } from '@/store/vertriebsinfo/index';

const INITIAL_FORM = {
  vertriebsgebiet: '',
  isSaas: false,
  maklergruppe: '',
  organisationsTyp: ''
}

export default {
  mixins: [validator],
  components: {
    ComboBox,
    InputField,
    InputCheckboxItem,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vertriebsInfoEdit: { ...INITIAL_FORM },
      organizationTypes: [ ...ORGANIZATION_TYPES_LABEL_VALUE ],
      loading: false,
      edited: false,
    }
  },
  watch: {
    loading(loading) {
      if (!loading && Object.keys(this.vertriebsInfo).length) {
        this.vertriebsInfoEdit = { ...this.vertriebsInfo }
      }
    }
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      vertriebsInfo: VERTRIEBS_INFO_TYPES.GETTERS.VERTRIEBS_INFO,
    }),
    isDisabled() {
      return this.disabled || this.loading;
    },
    isBrokerBypassedByIntern(){
      return this.hasRoles(VIEW_ROLES.VIEW_BROKER_AS_INTERN)
    },
  },
  methods: {
    async saveChanges() {
      if (this.vertriebsInfoEdit.maklergruppe.trim() === '') {
        delete this.vertriebsInfoEdit.maklergruppe;
      }
      await this.$store.dispatch(VERTRIEBS_INFO_TYPES.ACTIONS.SAVE_VERTRIEBS_INFO, this.vertriebsInfoEdit)
    },
    wasEdited() {
      this.edited = true;
    }
  },
  mounted() {
    this.loading = true
    this.$store.dispatch(VERTRIEBS_INFO_TYPES.ACTIONS.VERTRIEBS_INFO)
      .then(() => this.loading = false);
  },
  async beforeRouteLeave(to, from, next) {
    if (this.edited) {
      await this.saveChanges().then(() => next());
    } else {
      next();
    }
  },
};
</script>
