<template>
    <div>
        <InputField v-if="!isDialogFVV"
            label="Bargeld"
            v-model="form.cash"
            type="currency"
            :disabled="disabled"
            isComponentHalfSize
            @input="updateStore('cash', $event || null)" />
        <InputField class="font-bold"
            label="täglich verfügbare Bankeinlagen (z.B. Tagesgeld, Kontokorrent)"
            v-model="form.freeBankDeposit"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('freeBankDeposit', $event || null)" /> 

    </div>
  </template>

<script>

import InputField from '@/components/core/forms/InputField.vue';

export default {
    props: {
        disabled: {
            default: false,
        },
        finance: {
            default: () => {},
        },
        isDialogFVV: {
            default: false,
        }
    },
    data() {
        return {
            form: {}
        }
    },
    components: {
        InputField,
    },
    mounted() {
        this.form = Object.assign({}, this.finance);
    },
    methods: {
        updateStore(compId, value) {
            this.$emit('changed', {compId, value});
        },
    },

}
</script>