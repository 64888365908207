import MENU_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import { ROLES, VIEW_ROLES, } from '@/router/roles';

import { findNonTabMenu, } from '@/menu/menu-config-utils';
import { findConfiguredMenuActives, normalizeMenu, } from '@/components/core/option-menu/option-menu-utils';

import router from '@/router';
import { replaceRawPathParams, } from '@/helpers/commonfunctions';

const CONFIGS_MAKLER_HOME = '/intern/configs';
const CONFIGS_CUSTOMER_HOME = '/persoenlichedaten/configs';

const DEFAULT_APP_NAVIGATION = {
  currentMenu: {},
  currentOptionMenuId: undefined,
  currentOptionMenu: [],
  defaultOptionMenu: [],
};

export default {

  [MENU_TYPES.GETTERS.IS_CONFIGURING_MENU](state) {
    return state?.configuringMenu === true;
  },

  [MENU_TYPES.GETTERS.IS_MENU_CONFIGURED](state) {
    return state?.menuConfigured === true;
  },

  [MENU_TYPES.GETTERS.FLAT_MENU](state) {
    return [ ...state.flatMenu || [] ];
  },

  [MENU_TYPES.GETTERS.PRIMARY_MENU](state) {
    const { flatMenu, } = state;
    // It should get menus with no parents
    // It should get menus with no children: Workspaces with no options should not be visible
    const primaryMenu = flatMenu.filter(menu => !menu?.parent && menu?.subMenu?.length);
    return primaryMenu;
  },

  [MENU_TYPES.GETTERS.OPTION_MENU](state) {
    const { flatMenu, } = state;
    return findNonTabMenu(flatMenu); // options menu are available only for non tab menus
  },

  [MENU_TYPES.GETTERS.CONFIGURED_OPTION_MENU](state, getters) {
    const appNavigationByMenuPathFn = getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_MENU_PATH];

    const isGroup = menu => !!menu.group;
    const isNotAlreadyExtracted = (menuList, menu) => menuList.findIndex(m => m.parent?.path === menu.path) < 0;

    const extractCurrentMenuStructure = menuList => {
      return menuList.flatMap(menuItem => {
        const { currentOptionMenu } = appNavigationByMenuPathFn(menuItem.path);
        return currentOptionMenu?.map(item => ({
          ...item,
          parent: menuItem,
        }));
      });
    };

    const extractedMenuStructure = [];
    const primaryMenus = getters[MENU_TYPES.GETTERS.PRIMARY_MENU];
    extractedMenuStructure.push(...primaryMenus);
    let menuStructureCurrent = extractCurrentMenuStructure(primaryMenus);
    extractedMenuStructure.push(...menuStructureCurrent);
    while (menuStructureCurrent.some((menu) => isGroup(menu) && isNotAlreadyExtracted(extractedMenuStructure, menu))) {
      const groupsMenu = menuStructureCurrent.filter((menu) => isGroup(menu) && isNotAlreadyExtracted(extractedMenuStructure, menu));
      menuStructureCurrent = extractCurrentMenuStructure(groupsMenu);
      extractedMenuStructure.push(...menuStructureCurrent);
    }

    return extractedMenuStructure;
  },

  [MENU_TYPES.GETTERS.SEARCHABLE_OPTION_MENU](state, getters) {
    const optionMenu = getters[MENU_TYPES.GETTERS.OPTION_MENU];
    const configuredOptionMenu = getters[MENU_TYPES.GETTERS.CONFIGURED_OPTION_MENU];
    const isMenuVisible = menu => configuredOptionMenu.findIndex(cm => cm.path == menu.path) >= 0;
    return optionMenu.filter(menu => !menu.group || isMenuVisible(menu));
  },

  [MENU_TYPES.GETTERS.APP_NAVIGATION_BY_MENU_PATH](state, getters) {
    return (path) => {
      if(!path) return {};

      const { flatMenu, } = state;
      if(!flatMenu?.length) return {};

      const optionMenu = getters[MENU_TYPES.GETTERS.OPTION_MENU];

      // Get a menu by the path
      let currentMenu = flatMenu.find(menu => menu.path === path);
      if(!currentMenu) { // if any menu was found, it returns an empty object
        return { ...DEFAULT_APP_NAVIGATION };
      }

      // set current option menu
      const [defaultOptionMenu, currentOptionMenuId] = (() => {
        const findClosestOptionMenu = (menu) => {
          if (!menu) return [];

          if (!menu.hasTabMenu && (menu.hasSubMenu || menu.hasResolve)) {
            return [[...menu.subMenu], menu.id];
          }

          return findClosestOptionMenu(flatMenu.find(m => m.path === menu.parent?.path));
        };

        return findClosestOptionMenu(currentMenu);
      })();

      const currentOptionMenu = (() => {
        if (!currentOptionMenuId) return [];

        const optionsMenuConfig = getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_OPTION_MENU];
        const optionsMenuConfigRoute = optionsMenuConfig[currentOptionMenuId];
        const configuredOptionMenu = JSON.parse(optionsMenuConfigRoute?.content || '[]');
        const normalizedMenu = normalizeMenu(optionMenu, defaultOptionMenu, configuredOptionMenu);
        return findConfiguredMenuActives(normalizedMenu);
      })();

      // set current tab menu
      const [currentTabMenu, currentTabMenuId] = (() => {
        const findClosestTabMenu = (menu) => {
          if (!menu) return [];

          if (menu.hasTabMenu) {
            return [[...menu.subMenu], menu.id];
          }

          return findClosestTabMenu(flatMenu.find(m => m.path === menu.parent?.path));
        };

        return findClosestTabMenu(currentMenu);
      })();

      // check if current route path matches exactly to the menu matched path
      const isTabMenu = currentMenu?.tab === true;

      return {
        currentMenu: {
          ...currentMenu,
          $isTabMenu: isTabMenu,
        },
        currentOptionMenuId,
        defaultOptionMenu,
        currentOptionMenu,
        currentTabMenuId,
        currentTabMenu,
      };
    };
  },

  [MENU_TYPES.GETTERS.APP_NAVIGATION_BY_ROUTE_PATH](state, getters) {
    return (path) => {
      if (!path) return {};

      const { flatMenu, } = state;
      if(!flatMenu?.length) return {};

      let foundMenu = flatMenu.find(menu => menu.path === path);
      if(!foundMenu) { // If do not exists, it gets from the closest parent with a menu config
        const route = router.matcher.match(path);

        // all matched routes
        const matchedRoutes = [...route.matched];

        // find the closest parent with menu
        const closestParentWithMenu = matchedRoutes
          .reverse()
          .find(matchedRoute => flatMenu.some(menu => menu.path === replaceRawPathParams(matchedRoute.path, route.params)));

        if(!closestParentWithMenu) { // if any parent with menu was found, it returns an empty object
          return { ...DEFAULT_APP_NAVIGATION };
        }
        foundMenu = flatMenu.find(menu => menu.path === replaceRawPathParams(closestParentWithMenu.path, route.params));
      }

      const appNavigation = getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_MENU_PATH](foundMenu?.path);
      const currentMenuRoute = router.match(appNavigation.currentMenu?.path);
      return {
        ...appNavigation,
        $isExact: currentMenuRoute.path === path,
      };
    };
  },

  [MENU_TYPES.GETTERS.CURRENT_APP_NAVIGATION](state, getters) {
    if(state.updateCurrentAppNavigation) { // it is only to force update the current app navigation
      const { currentRoute } = router || {};
      return getters[MENU_TYPES.GETTERS.APP_NAVIGATION_BY_ROUTE_PATH](currentRoute?.path);
    } else {
      return {};
    }
  },

  [MENU_TYPES.GETTERS.PARENTS_MENU_BADGE_COUNT](state, getters) {
    const { parentsMenuBadge, } = state;

    // primary menu count by path
    return Object.keys(parentsMenuBadge)
      .reduce((acc, parentMenuPath) => ({
        ...acc,
        [parentMenuPath]: Object.keys(parentsMenuBadge[parentMenuPath])
          .reduce((acc, path) => {
            const { count } = parentsMenuBadge[parentMenuPath][path];
            const countArr = Array.isArray(count) ? count : [count];
            return acc + countArr.reduce((total, getter) => total + getters[ getter ], 0);
          }, 0),
      }), {});
  },

  [MENU_TYPES.GETTERS.OPTIONS_MENU_BADGE_COUNT](state, getters) {
    const { optionsMenuBadge, } = state;

    // Options menu count by path
    return Object.keys(optionsMenuBadge)
      .reduce((acc, menuPath) => ({
        ...acc,
        [menuPath]: (() => {
          const { count } = optionsMenuBadge[menuPath];
          const countArr = Array.isArray(count) ? count : [count];
          return countArr.reduce((total, getter) => total + getters[ getter ], 0);
        })(),
      }), {});
  },

  [MENU_TYPES.GETTERS.OPTIONS_MENU_PERMISSION](state) {
    if (!state.optionsMenuPermission) {
      return state.optionsMenuPermission;
    }

    const newOptionsMenuPermission = {};

    // decode all keys because some path has mixed content with some characters encoded and some characters not encoded
    for (const [key, value] of Object.entries(state.optionsMenuPermission)) {
      newOptionsMenuPermission[decodeURIComponent(key)] = value;
    }

    return newOptionsMenuPermission;
  },

  [MENU_TYPES.GETTERS.IS_OPTION_MENU_PATH_VISIBLE](_, getters) {
    return (path) => {
      const isPathVisible = (innerPath) => {
        const permission = getters[MENU_TYPES.GETTERS.OPTIONS_MENU_PERMISSION];
        const decodedPath = decodeURIComponent(innerPath);
        return permission?.[innerPath]?.visible !== false && permission?.[decodedPath]?.visible !== false;
      }

      // override menu configuration permission when accessing as a bypass slash session
      const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
      const isBypassSlash = hasRoles([ROLES.IS_BYPASS_SLASH]);
      const configsHomePath = hasRoles([VIEW_ROLES.VIEW_CUSTOMER]) ? CONFIGS_CUSTOMER_HOME : CONFIGS_MAKLER_HOME;
      if (isBypassSlash && path.startsWith(configsHomePath)) { // is it a config related path?
        const configsOverviewPath = `${configsHomePath}/`;
        const menuConfigPath = `${configsHomePath}/configuration`;
        const isConfigsHomeNotVisible = !isPathVisible(configsHomePath);
        if (isConfigsHomeNotVisible) { // when the entire configs is denied - force to make only configs and menu config always visible
          return path === configsHomePath || path === configsOverviewPath || path.startsWith(menuConfigPath);
        } else if (path.startsWith(menuConfigPath)) { // when configs is allowed - force to make menu config always visible
          return true;
        }
      }

      return isPathVisible(path);
    }
  },

  [MENU_TYPES.GETTERS.RECENT_MENU_OPENED](state, getters) {
    const userId = getters[CORE_TYPES.GETTERS.GET_USER_ID];
    const configuredOptionMenu = getters[MENU_TYPES.GETTERS.CONFIGURED_OPTION_MENU];
    const recentMenuOpened = state.recentMenuOpened?.[userId] ?? [];
    return recentMenuOpened
      .filter(m => configuredOptionMenu.findIndex(om => om.path === m.path) >= 0);
  },

}
