<template>
  <div>
    <BaseModal
      ref="kundenBereinigenModal"
      modalTitle="Kunden bereinigen"
      labelButtonConfirm="Bereinigen "
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()"
      @onCloseButton="onCancelModal()"
    >

      <div class="row">
        <div class="col-12">
          <p>Hier können Sie zwei Kunden bereinigen.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr>
          <p>ACHTUNG!</p>
          <p>Dabei werden alle Produkte des Kunden mit der Kundennummer 2 dem Kunden mit der Kundennummer 1 zugeordnet und dann der Kunde 2 gelöscht!</p>
          <p>Beide Kunden werden endgültig zusammengelegt. Diese Aktion kann nicht rückgängig gemacht werden.</p>
          <p v-if="customerList && customerList.length==2">Kunde {{customerList[0]["name"]}} ({{customerList[0]["customerID"]}}) löschen und alle Daten verschieben?</p>
        <hr>
        </div>
      </div>    


      <div class="main__container">
        <div class="header" >
          Quell-Kunde
        </div>
        <div class="control" >
          &nbsp;
        </div>

        <div class="header" >
          Ziel-Kunde
        </div>  
      </div>

      <div v-if="customerList && customerList.length==2 && !changingPosition" class="main__container">
          <div class="item">
            {{customerList[0]["customerID"]}}  <br> {{customerList[0]["name"]}}
          </div>
          <div class="control">
            <BaseButton class="control-button m-0" isClear @click="changePositions()">
              <PhArrowsClockwise :size="16" weight="bold" />
            </BaseButton>
          </div>
          <div class="item">
            {{customerList[1]["customerID"]}}  <br> {{customerList[1]["name"]}}
          </div>          
      </div>


    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/core/BaseModal.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import {
PhArrowsClockwise
} from "phosphor-vue";

export default {
  props: {
    customerList: { 
      type: Array,
      default: () => []
    },
  },  
  components: {
    BaseModal,
    BaseButton,
    PhArrowsClockwise
  },
  data() {
    return {
      customers: null,
      changingPosition: false,
    };
  },
  methods: {
    open() {
      this.$refs.kundenBereinigenModal.open();
    },
    onConfirmModal() {

      this.customers = {
        toRemove: {
          customerId: this.customerList[0].id
        },
        toKeep : {
          customerId: this.customerList[1].id
        },
      }  

      this.$emit("save", this.customers);
      this.$refs.kundenBereinigenModal.close();
      

    },
    onCancelModal() {
      this.$refs.kundenBereinigenModal.close();
    },
    changePositions() {
      this.changingPosition = true;

      let firstPosition = this.customerList[0];
      let secondPosition = this.customerList[1];

      this.customerList[0] = secondPosition;
      this.customerList[1] = firstPosition;

      this.customers = {
        toRemove: {
          customerId: firstPosition.id
        },
        toKeep : {
          customerId: secondPosition.id
        },
      } 

      this.changingPosition = false;


    },   
  },
  mounted() {}
};
</script>

<style>

.main__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0px;

}

.main__container .header {
  flex: 1 1 0px;
}

.main__container .item {
  flex: 1 1 0px;
  /* align-self: center; */
  border: 1px  solid var(--color-text);
  padding: 4px;
}

.main__container .control {
  flex: 0.2 1 0px;
  align-self: center;
  justify-self: center;
  text-align: center;
}

.main__container .control-button {
  min-width: 44px;
  min-height: 44px;
}


</style>