var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "askOptionBaseModal",
          attrs: {
            labelButtonConfirm: _vm.confirmButtonLabel,
            size: _vm.modalSize,
            modalTitle: _vm.title,
            showCancelButton: false,
            showConfirmButton: !_vm.isSoftfair,
            actions: _vm.modalActions
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.$emit("onConfirmButton", _vm.form)
            },
            onCancelButton: function($event) {
              return _vm.$emit("close")
            },
            onCloseButton: function($event) {
              return _vm.$emit("close")
            },
            onEscButton: function($event) {
              return _vm.$emit("close")
            },
            "action-DOWNLOAD": _vm.closeModal
          }
        },
        _vm._l(_vm.askOptions, function(askOption) {
          return _c(
            "div",
            { key: askOption, staticClass: "pb-2" },
            [
              askOption === "USER_ANONYMOUS"
                ? [
                    _c("InputRadioBoxGroup", {
                      attrs: {
                        values: [
                          { label: "Kundendaten übertragen", value: false },
                          {
                            label: "Kundendaten anonymisieren (Name, Adresse)",
                            value: true
                          }
                        ]
                      },
                      model: {
                        value: _vm.form.userAnonymous,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "userAnonymous", $$v)
                        },
                        expression: "form.userAnonymous"
                      }
                    })
                  ]
                : _vm._e(),
              askOption === "INNOSYSTEMS_BUTTONS"
                ? [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v("Vergleichsrechner Sachversicherung")
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "externezugang__button-container layout__negative-margin--8"
                      },
                      [
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_BAUHERRENHAFTPFLICHT"
                                )
                              }
                            }
                          },
                          [_vm._v("Bauherrenhaftpflicht")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_GEWAESSERSCHADEN"
                                )
                              }
                            }
                          },
                          [_vm._v("Gewässerschaden")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_HAUSUNDGRUNDBESITZER"
                                )
                              }
                            }
                          },
                          [_vm._v("Haus und Grundbesitzer")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_HAUSRAT"
                                )
                              }
                            }
                          },
                          [_vm._v("Hausrat")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_HUNDEHAFTPFLICHT"
                                )
                              }
                            }
                          },
                          [_vm._v("Hundehaftpflicht")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_PFERDEHAFTPFLICHT"
                                )
                              }
                            }
                          },
                          [_vm._v("Pferdehaftpflicht")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_PRIVATHAFTPFLICHT"
                                )
                              }
                            }
                          },
                          [_vm._v("Privathaftpflicht")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_RECHTSSCHUTZ"
                                )
                              }
                            }
                          },
                          [_vm._v("Rechtschutz")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_UNFALL"
                                )
                              }
                            }
                          },
                          [_vm._v("Unfall")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_WOHNGEBAEUDE"
                                )
                              }
                            }
                          },
                          [_vm._v("Wohngebäude")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v("Vergleichsrechner Gewerbeversicherung")
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "externezugang__button-container layout__negative-margin--8"
                      },
                      [
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_RECHTSSCHUTZSELBST"
                                )
                              }
                            }
                          },
                          [_vm._v("Rechtsschutz für Selbstständige/ Firmen")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v("Vergleichsrechner Krankenversicherung")
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "externezugang__button-container layout__negative-margin--8"
                      },
                      [
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_KRANKENHAUSTAGEGELD"
                                )
                              }
                            }
                          },
                          [_vm._v("Krankenhaustagegeld")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_KRANKENTAGEGELD"
                                )
                              }
                            }
                          },
                          [_vm._v("Krankentagegeld")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_KRANKENVOLLVERSICHERUNG"
                                )
                              }
                            }
                          },
                          [_vm._v("Krankenvollversicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_KRANKENZUSATZVERSICHERUNG"
                                )
                              }
                            }
                          },
                          [_vm._v("Krankenzusatzversicherung")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          "Vergleichsrechner Krankenzusatzversicherung Zahn"
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "externezugang__button-container layout__negative-margin--8"
                      },
                      [
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_KRANKENZUSATZVERSICHERUNGZAHN"
                                )
                              }
                            }
                          },
                          [_vm._v("Krankenzusatzversicherung Zahn")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          "Vergleichsrechner PKW-Versicherung und Motorrad-Versicherung"
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "externezugang__button-container layout__negative-margin--8"
                      },
                      [
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom("BUTTON_PKW")
                              }
                            }
                          },
                          [_vm._v("PKW und Motorrad (zum Auswahlmenu)")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickInnosystemsButtom(
                                  "BUTTON_EVBSERVICE"
                                )
                              }
                            }
                          },
                          [_vm._v("EVB-Service")]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              askOption === "MR_MONEY_BUTTONS"
                ? [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v("Angebots-Center")
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "externezugang__button-container layout__negative-margin--8"
                      },
                      [
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton("BUTTON_ANGEBOT")
                              }
                            }
                          },
                          [_vm._v("Angebot")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [_vm._v("Privat")])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "externezugang__button-container layout__negative-margin--8"
                      },
                      [
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton("BUTTON_BAU")
                              }
                            }
                          },
                          [_vm._v("Bau-Versicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_FAHRRADVERGLEICH"
                                )
                              }
                            }
                          },
                          [_vm._v("E-Bike/Fahrrad-Versicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_HAUSUNDGRUNDBESITZER"
                                )
                              }
                            }
                          },
                          [_vm._v("Haus- und Grundbesitzerhaft")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton("BUTTON_HAUSRAT")
                              }
                            }
                          },
                          [_vm._v("Hausrat-Versicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_PRIVATHAFTPFLICHT"
                                )
                              }
                            }
                          },
                          [_vm._v("Privat-Haftpflicht-Versicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_PHOTOVOLTAIK"
                                )
                              }
                            }
                          },
                          [_vm._v("Photovoltaik-Versicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_RECHTSSCHUTZ"
                                )
                              }
                            }
                          },
                          [_vm._v("Rechtsschutz-Versicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_RISIKOLEBEN"
                                )
                              }
                            }
                          },
                          [_vm._v("Risikolebens-Versicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_TIERHALTERHAFTPLICHT"
                                )
                              }
                            }
                          },
                          [_vm._v("Tierhalter-Haftpflicht")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton("BUTTON_UNFALL")
                              }
                            }
                          },
                          [_vm._v("Unfall-Versicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_WOHNGEBAEUDE"
                                )
                              }
                            }
                          },
                          [_vm._v("Wohngebäude-Versicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_OELTANKHAFTPFLICHT"
                                )
                              }
                            }
                          },
                          [_vm._v("Öltank-Haftpflicht-Versicherung")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [_vm._v("Gewerbe")])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "externezugang__button-container layout__negative-margin--8"
                      },
                      [
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_BETRIEBSINHALT"
                                )
                              }
                            }
                          },
                          [_vm._v("Betriebs-Inhaltsversicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_BETRIEBSHAFTPLICHT"
                                )
                              }
                            }
                          },
                          [_vm._v("Betriebs-Haftpflichtversicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton("BUTTON_BUERO")
                              }
                            }
                          },
                          [_vm._v("Büroversicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_ELEKTRONIK"
                                )
                              }
                            }
                          },
                          [_vm._v("Elektronikversicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_GEWERBERECHTSSCHUTZ"
                                )
                              }
                            }
                          },
                          [_vm._v("Gewerbe-Rechtsschutz")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_GEWERBEGEBAEUDE"
                                )
                              }
                            }
                          },
                          [_vm._v("Gewerbe-Gebäudeversicherung")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_MASCHINENABMG"
                                )
                              }
                            }
                          },
                          [_vm._v("Maschinenversicherung ABMG")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.clickMrMoneyButton(
                                  "BUTTON_MASCHINENAMB"
                                )
                              }
                            }
                          },
                          [_vm._v("Maschinenversicherung AMB")]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              askOption === "SOFTFAIR_FORM"
                ? [
                    _c("div", [_vm._v("Sehr geehrte Damen und Herren,")]),
                    _c("div", [
                      _vm._v(
                        "Sie haben noch keinen Zugang für den Vergleichsrechner von Softfair. Sehr gerne bieten wir Ihnen einen Zugang zu Softfair an. Hierfür bitten wir Sie das Bestellformular auszufüllen."
                      )
                    ]),
                    _c("div", { staticClass: "box__title mt-3" }, [
                      _vm._v("Softfair Registrierung")
                    ]),
                    _c("InputField", {
                      attrs: { label: "Firma", disabled: "" },
                      model: {
                        value: _vm.form.firma,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "firma", $$v)
                        },
                        expression: "form.firma"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Vorname" },
                      model: {
                        value: _vm.form.vorname,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "vorname", $$v)
                        },
                        expression: "form.vorname"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Name" },
                      model: {
                        value: _vm.form.nachname,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "nachname", $$v)
                        },
                        expression: "form.nachname"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Straße" },
                      model: {
                        value: _vm.form.strasse,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "strasse", $$v)
                        },
                        expression: "form.strasse"
                      }
                    }),
                    _c("InputZIP", {
                      attrs: { label: "PLZ" },
                      model: {
                        value: _vm.form.plz,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "plz", $$v)
                        },
                        expression: "form.plz"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Ort" },
                      model: {
                        value: _vm.form.ort,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "ort", $$v)
                        },
                        expression: "form.ort"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Telefon", inputmode: "tel" },
                      model: {
                        value: _vm.form.telefon,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "telefon", $$v)
                        },
                        expression: "form.telefon"
                      }
                    }),
                    _c("div", { staticClass: "font-bold mb-2" }, [
                      _vm._v("Bankdaten")
                    ]),
                    _c("InputField", {
                      attrs: { label: "Bankinstitut" },
                      model: {
                        value: _vm.form.bank,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bank", $$v)
                        },
                        expression: "form.bank"
                      }
                    }),
                    _c("InputIbanBic", {
                      attrs: {
                        validation_path: "form.iban",
                        label: "IBAN",
                        type: "IBAN",
                        componentData: _vm.form.iban
                      },
                      on: { change: _vm.updateIban }
                    }),
                    _c("InputIbanBic", {
                      attrs: {
                        validation_path: "form.bic",
                        label: "BIC",
                        type: "BIC",
                        componentData: _vm.form.bic
                      },
                      on: {
                        change: function($event) {
                          _vm.form.bic = $event
                        }
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Kontoinhaber" },
                      model: {
                        value: _vm.form.kontoinhaber,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "kontoinhaber", $$v)
                        },
                        expression: "form.kontoinhaber"
                      }
                    })
                  ]
                : _vm._e(),
              askOption === "COVOMO"
                ? [
                    _c("p", [
                      _c("b", [_vm._v("Covomo")]),
                      _vm._v(
                        " ist das marktführende Vergleichs- und Abschlussportal für "
                      ),
                      _c("b", [_vm._v("Spezial- und Nieschenversicherungen.")])
                    ]),
                    _c("p", [
                      _vm._v(
                        "Falls Sie noch keinen Zugang besitzen oder Ihr Account noch nicht mit der FondsKonzept verknüpft ist, melden Sie sich bitte bei einem Mitarbeiter des FondsKonzept-Versicherungsbereichs."
                      )
                    ]),
                    _c("p", [
                      _c("b", [_vm._v("Hinweis: ")]),
                      _vm._v(
                        "Es findet eine automatische Vertragsanlage im smartMSC statt, sofern der Kundendatensatz eindeutig mit den übermittelten Daten übereinstimmt. Bitte stellen Sie lediglich sicher, dass der Kunde mit seiner aktuellen Adresse angelegt ist."
                      )
                    ])
                  ]
                : _vm._e(),
              askOption === "OCC"
                ? [
                    _c("p", [
                      _c("b", [_vm._v("Wichtig:")]),
                      _vm._v(
                        " Verträge werden nicht automatisch im smartMSC angelegt! Dies muss beim Abschluss manuell vom Vermittler erfolgen. "
                      )
                    ])
                  ]
                : _vm._e(),
              askOption === "BALOISE_SACH"
                ? [
                    _c("p", [
                      _vm._v("Der "),
                      _c("b", [_vm._v("Baloise Sachversicherungsrechner")]),
                      _vm._v(
                        " ermöglicht Ihnen einen komfortablen Einstieg in die Berechnung für die Sparten "
                      ),
                      _c("b", [
                        _vm._v("Haftpflicht-, Hausrat- und Unfallversicherung")
                      ]),
                      _vm._v(", bis hin zum volldigitalen Abschluss.")
                    ]),
                    _c("p", [_c("b", [_vm._v("Hinweise:")])]),
                    _c("p", [
                      _vm._v("Es wird die Pool-Vermittlernummer übertragen."),
                      _c("br"),
                      _vm._v(
                        " Nach Abschluss findet binnen weniger Stunden eine automatische Vertragsanlage im smartMSC statt."
                      ),
                      _c("br"),
                      _vm._v(
                        " Sofern Ihre Maklervollmacht keine Regelung zur Entgegennahme der vorvertraglichen VVG-Dokumente enthält, bitten wir Sie, diese dem Kunden direkt zur Verfügung zu stellen."
                      )
                    ])
                  ]
                : _vm._e()
            ],
            2
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }