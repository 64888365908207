export const MUTATION_PREFIX = 'PERS_GESCHAEFT: ';
export const ACTIONS_PREFIX = 'PERS_GESCHAEFT_ACTIONS_';
export const GETTERS_PREFIX = 'PERS_GESCHAEFT_GETTERS_';

export default {
  MUTATIONS: {
    GET_FRAGEBOGEN_SUCCESS: MUTATION_PREFIX + 'GET_FRAGEBOGEN_SUCCESS',
    GET_GESPEICHERTE_FRAGEBOGEN_LIST_SUCCESS: MUTATION_PREFIX + 'GET_GESPEICHERTE_FRAGEBOGEN_LIST_SUCCESS',
    GET_OVERVIEW_LIST_SUCCESS: MUTATION_PREFIX + 'GET_OVERVIEW_LIST_SUCCESS',
    SET_OVERVIEW_FILTERS: MUTATION_PREFIX + 'SET_OVERVIEW_FILTERS',
    UPDATE_OVERVIEW_LIST_PAGE_INDEX: MUTATION_PREFIX + 'UPDATE_OVERVIEW_LIST_PAGE_INDEX',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_FRAGEBOGEN: ACTIONS_PREFIX + 'GET_FRAGEBOGEN',
    SAVE_FRAGEBOGEN: ACTIONS_PREFIX + 'SAVE_FRAGEBOGEN',
    GET_GESPEICHERTE_FRAGEBOGEN_LIST: ACTIONS_PREFIX + 'GET_GESPEICHERTE_FRAGEBOGEN_LIST',
    GET_OVERVIEW_LIST: ACTIONS_PREFIX + 'GET_OVERVIEW_LIST',
    GET_OVERVIEW_EXCEL: ACTIONS_PREFIX + 'GET_OVERVIEW_EXCEL',
    REMOVE_FRAGEBOGEN_DOCUMENT: ACTIONS_PREFIX + 'REMOVE_FRAGEBOGEN_DOCUMENT',
    REMOVE_FRAGEBOGEN: ACTIONS_PREFIX + 'REMOVE_FRAGEBOGEN',
    SAVE_BEMERKUNGEN: ACTIONS_PREFIX + 'SAVE_BEMERKUNGEN',
    CONFIRM_SELECTION: ACTIONS_PREFIX + 'CONFIRM_SELECTION',
  },
  GETTERS: {
    FRAGEBOGEN: GETTERS_PREFIX + 'FRAGEBOGEN',
    GESPEICHERTE_FRAGEBOGEN_LIST: GETTERS_PREFIX + 'GESPEICHERTE_FRAGEBOGEN_LIST',
    OVERVIEW_LIST: GETTERS_PREFIX + 'OVERVIEW_LIST',
    OVERVIEW_FILTERS: GETTERS_PREFIX + 'OVERVIEW_FILTERS',
    OVERVIEW_LIST_PAGE_INDEX: GETTERS_PREFIX + 'OVERVIEW_LIST_PAGE_INDEX',
    OVERVIEW_LIST_TOTAL_ROWS: GETTERS_PREFIX + 'OVERVIEW_LIST_TOTAL_ROWS',
    OVERVIEW_LIST_IS_LOADING: GETTERS_PREFIX + 'OVERVIEW_LIST_IS_LOADING',
  }
}
