<template>
  <div v-if="mainHtmlText">

    <template v-if="isLoggedIn">
      <PageHeaderTitleNavigation :title="pageTitle" />
    </template>

    <GenericPrivacyTerms
      :info="info"
      :contacts="contacts"
      :form="form"
      @open-link="openLink($event)"
      @terms-accepted="acceptTerms($event)"
      @terms-rejected="logoutAndRejectTerms()"
    />
  </div>
</template>

<script>
import store from '@/store';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import GenericPrivacyTerms from '@/components/core/privacy/GenericPrivacyTerms.vue';

import { viewDocument } from '@/components/core/download/DownloadLink.vue';

const DEFAULT_TITLE = 'Datenschutz&shy;erklärung';

import { openLink } from '@/link-resolvers';
import { ResolverId } from '@/link-resolvers/types';

export default {
  computed: {
    ...mapGetters({
      info: CORE_TYPES.GETTERS.GET_PRIVACY_DATA,
      contacts: CORE_TYPES.GETTERS.GET_USER_PRIVACY_CONTACTS,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      getPdfFilesLinkFn: CORE_TYPES.GETTERS.GET_PDF_FILES_LINK_FN,
    }),
    mainHtmlText() {
      return this.info?.config?.mainHtmlText
    },
    pageTitle() {
      return this.$appNavigation?.currentMenu?.label || DEFAULT_TITLE
    },
  },
  watch: {
    loginData() {
      this.updateConfirmation()
    }
  },
  data() {
    return {
      form: {
        isConfirmed: false,
        hasErstinformation: false,
        privacyChecks: {}
      }
    }
  },
  mounted() {
    this.$store.dispatch(CORE_TYPES.ACTIONS.GET_PRIVACY_DATA)
    this.updateConfirmation()
  },
  methods: {
    updateConfirmation() {
      this.form.isConfirmed = this.loginData?.privacy?.isConfirmed
    },
    logoutAndRejectTerms() {
      this.$store.dispatch(CORE_TYPES.ACTIONS.LOGOUT)
    },
    acceptTerms(terms) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.CONFIRM_PRIVACY, {nextUrl: this.$route.query?.nextUrl || '/home', terms: terms})
    },
    checkPrivacy() {
      this.$store.commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, {checkPrivacy: false});
    },
    openLink(event) {
      const type = event?.type;
      if(!type) {
        return ;
      }

      if (type === 'ersinf') {
        const payload = {
          paramName: 'ersInf',
          filename: 'Kundenerstinformation.pdf'
        }
        const href = this.getPdfFilesLinkFn(payload);
        viewDocument({ href });
      } else {
        const version = event?.params.version;
        const type = event?.params.type;

        this.$router.push(`terms-content?version=${version}&type=${type}`);
      }
    },
  },
  async beforeRouteEnter(to, from, next) {
    if (store.getters[CORE_TYPES.GETTERS.IS_FA]) {
      openLink(ResolverId.DatenschutzURL);
      next(false);
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {

    this.$addBreadcrumb({
      label: 'zurück zum Datenschutz', 
      to,
      from,
      breadcrumb: to?.query?.type === 'AGB_HTML' ? 'AGB' : 'Datenschutzerklärung',
    });

    next();
  },
  components: {
    PageHeaderTitleNavigation,
    GenericPrivacyTerms,
  }
}
</script>

<style scoped>

</style>