var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SortableList",
    {
      attrs: { items: _vm.rows },
      on: {
        orderChanged: function($event) {
          return _vm.$emit("orderChanged", $event)
        }
      }
    },
    [
      _c(
        "table",
        { attrs: { "data-sortable-container": _vm.sortableRows } },
        [
          _c(
            "colgroup",
            _vm._l(_vm.topHeaders, function(ref) {
              var key = ref.key
              var width = ref.width
              return _c("col", { key: key, style: { width: width + "px" } })
            }),
            0
          ),
          _c(
            "thead",
            _vm._l(_vm.topHeaders, function(ref) {
              var key = ref.key
              var classes = ref.classes
              var align = ref.align
              return _c(
                "th",
                {
                  key: key,
                  staticClass: "header-cell",
                  class: [classes, "text-" + align],
                  attrs: { "aria-label": "header_" + key }
                },
                [_vm._t("header_" + key, null, { collapsed: false })],
                2
              )
            }),
            0
          ),
          _vm._l(_vm.rows, function(ref, index) {
            var acceptsFile = ref.acceptsFile
            var open = ref.open
            var id = ref.id
            var row = ref.row
            var customRowStyle = ref.customRowStyle
            var modalOpen = ref.modalOpen
            var loadingCollapsed = ref.loadingCollapsed
            return _c(
              "DragnDropArea",
              {
                key: id + index,
                attrs: {
                  element: "tbody",
                  "data-sortable-item": _vm.sortableRows && !row.__FOOTER__,
                  hoverText: "Datei hier ablegen",
                  disabled: !acceptsFile
                },
                on: {
                  files: function($event) {
                    return _vm.handleFile(row, $event)
                  }
                }
              },
              [
                _c(
                  "tr",
                  {
                    directives: [
                      {
                        name: "draggable-source",
                        rawName: "v-draggable-source",
                        value: row.$draggable,
                        expression: "row.$draggable"
                      }
                    ]
                  },
                  _vm._l(_vm.topHeaders, function(ref) {
                    var key = ref.key
                    var classes = ref.classes
                    var align = ref.align
                    return _c(
                      "td",
                      {
                        key: key,
                        staticClass: "table-cell",
                        class: [classes, "text-" + align],
                        style: customRowStyle,
                        attrs: { valign: _vm.valign }
                      },
                      [
                        _vm.isToggleCollapseVisible(key, row)
                          ? _vm._t(
                              key,
                              function() {
                                return [
                                  row[key]
                                    ? _c("div", [
                                        _c("span", [_vm._v(_vm._s(row[key]))]),
                                        _c("br")
                                      ])
                                    : _vm._e(),
                                  row.constants
                                    ? _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.sanitize(
                                              row.constants[key] || ""
                                            )
                                          )
                                        }
                                      })
                                    : _vm._e()
                                ]
                              },
                              { row: row, open: open, id: id, index: index }
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  0
                ),
                _vm.isToOpenSmallScreenModal && modalOpen
                  ? _c(
                      "BaseSheetModal",
                      {
                        staticClass: "hide-on-large-screen",
                        attrs: {
                          modalTitle: "",
                          autoOpen: _vm.isToOpenSmallScreenModal && modalOpen
                        },
                        on: {
                          closed: function($event) {
                            return _vm.$emit("closeRowModal", id, index)
                          }
                        }
                      },
                      [
                        _c(
                          "TableCard",
                          {
                            attrs: {
                              topHeaders: _vm.topHeaders,
                              collapsedHeaders: _vm.collapsedHeaders,
                              loadingCollapsed: loadingCollapsed,
                              row: row,
                              acceptsFile: acceptsFile,
                              infoModal: ""
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(
                                  _vm.topHeaders.concat(_vm.collapsedHeaders),
                                  function(ref) {
                                    var key = ref.key
                                    return {
                                      key: "header_" + key,
                                      fn: function(data) {
                                        return [
                                          _vm._t(
                                            "header_" + key,
                                            function() {
                                              return [
                                                _vm._v(" " + _vm._s(key) + " ")
                                              ]
                                            },
                                            null,
                                            data
                                          )
                                        ]
                                      }
                                    }
                                  }
                                )
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._l(
                              _vm.topHeaders.concat(_vm.collapsedHeaders),
                              function(ref) {
                                var key = ref.key
                                return _c(
                                  "template",
                                  { slot: key },
                                  [
                                    _vm._t(
                                      key,
                                      function() {
                                        return [
                                          _vm._v(" " + _vm._s(row[key]) + " ")
                                        ]
                                      },
                                      {
                                        row: row,
                                        open: open,
                                        id: id,
                                        collapsed: ""
                                      }
                                    )
                                  ],
                                  2
                                )
                              }
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                open && _vm.collapsedHeaders.length > 0
                  ? _c("tr", { staticClass: "hide-on-small-screen" }, [
                      _c(
                        "td",
                        { attrs: { colspan: _vm.topHeaders.length } },
                        [
                          loadingCollapsed
                            ? _c("GhostLoading", {
                                attrs: {
                                  type: "block",
                                  config: {
                                    block: {
                                      height:
                                        Math.ceil(
                                          _vm.collapsedHeaders.length / 5
                                        ) * 75
                                    }
                                  }
                                }
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "expanded-row",
                                  style: {
                                    maxWidth: _vm.maxCollapsedWidth - 10 + "px"
                                  }
                                },
                                [
                                  _vm._l(_vm.collapsedHeaders, function(ref) {
                                    var key = ref.key
                                    var classes = ref.classes
                                    var align = ref.align
                                    return [
                                      _vm.isCollapsedContentVisible(key, row)
                                        ? _c(
                                            "div",
                                            {
                                              key: key,
                                              staticClass: "collapsed"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "header-cell collapsed-header"
                                                },
                                                [
                                                  _vm._t(
                                                    "header_" + key,
                                                    function() {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(key) +
                                                            " "
                                                        )
                                                      ]
                                                    },
                                                    { collapsed: "" }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "table-cell collapsed-cell",
                                                  class: [
                                                    classes,
                                                    "text-" + align
                                                  ]
                                                },
                                                [
                                                  _vm._t(
                                                    key,
                                                    function() {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(row[key]) +
                                                            " "
                                                        )
                                                      ]
                                                    },
                                                    {
                                                      row: row,
                                                      open: open,
                                                      id: id,
                                                      collapsed: ""
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ],
                                2
                              )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          }),
          _vm._t("bottom__table")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }