import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    antraege: {}, // indexed by antragId
    antraegeData: {}, // indexed by antragId
    positions: {}, // indexed by antragId
    positionData: {}, // indexed by antragId
    requestedEbaseDepotnummer: {}, // indexed by antragId
    requestedEbaseAABAnlagestrategien: {}, // indexed by antragId
    antraegeReloadPayload: {},  // indexed by antragId
    saveAntragState: false, // whether is saving or not
    antragList: {}, // the list of implemented antrag forms with links
    antragListFormulare: {}, // the list of implemented antrag forms with links for Formulare Page / Options Menu / Global Search
    gesellschaftsFormulare: [], // the list of Gesellschaft Formulare for the broker perspective
    gesellschaftsFormulareProcess: {},
    gesellschaftsFormulareLinks: {}, // the list of Gesellschaft Formulare with links for the broker perspective
    gesellschaftsMainStructure: [], // Gesellschaft Formulare groups
    attachmentsTemp:[], // list of tempFileIds for attachments added from archive
    videoindent: {},

    antragActionLoading: {
      pdf: false,
      sign: false,
      email: false,
      fax: false,
      zusatzDok: false,
      auftragsdokumente: false,
      eSignatur: false,
      remove_position: false,
    },
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}