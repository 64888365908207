var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "persoenliche-daten-view" },
    [
      _c("CustomerDataNavigation", { attrs: { subtitle: _vm.fullName } }),
      _c("StepperForm", {
        ref: "stepperForm",
        attrs: {
          stepType: "tab",
          stepperName: "customer-data",
          stepperMediator: _vm.tabsStepperMediator,
          hasFertig: false,
          selectedStepKey: _vm.step,
          selectedSubstepKey: _vm.substep,
          hideNavigationButtons: _vm.isFocusModeEnable,
          backNavigationOnClose: _vm.isFocusModeEnable,
          additionalActionsForMobile: _vm.additionalActionsForMobile
        },
        on: {
          "set-step": function($event) {
            return _vm.navigateToPath($event)
          },
          "set-substep": function($event) {
            return _vm.navigateToSubstepPath($event)
          },
          handleOtherActions: _vm.handleMobileActions
        },
        scopedSlots: _vm._u([
          {
            key: "headerTemplate",
            fn: function() {
              return [
                _c("HinweiseUndFehler", {
                  attrs: {
                    errors: _vm.warnings,
                    hints: _vm.hints,
                    errorTitle: _vm.errorTitle
                  },
                  on: {
                    "set-step": function($event) {
                      return _vm.navigateToPath($event)
                    },
                    "set-substep": function($event) {
                      return _vm.navigateToSubstepPath($event)
                    }
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "contentTemplate",
            fn: function() {
              return [_c("router-view")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }