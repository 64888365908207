<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="75" height="89.275" viewBox="0 0 75 89.275">
    <g id="Gruppe_58" data-name="Gruppe 58" transform="translate(-301.213 319)">
      <path id="Pfad_6" data-name="Pfad 6"
        d="M187.692,115.28v24.231h7.722l-1.246,2.825-2.642,5.991h0l-3.836,8.7h0a35.025,35.025,0,0,0,30.333-17.513h0L187.69,115.282Z"
        transform="translate(158.19 -406.865)" :fill="color"></path>
      <path id="Pfad_7" data-name="Pfad 7"
        d="M44.638,89.275a44.636,44.636,0,0,1-31.563-76.2A44.334,44.334,0,0,1,44.638,0V20.117a24.519,24.519,0,1,0,0,49.037V89.27Z"
        transform="translate(301.213 -319)" :fill="color"></path>
    </g>
  </svg>
</template>
<script>
import undraw from "@/components/icons/undraw/undraw-mixin.js";

export default {
  mixins: [undraw],
};
</script>