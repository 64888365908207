<template>
    <InputTextArea
        @input="input($event)"
        @change="$emit('change')"
        @keyup="$emit('keyup')"
        :label="label"
        :value="internalValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :validateUntouched="validateUntouched"
        :suppressValidationMessage="suppressValidationMessage"
        :isComponentHalfSize="isComponentHalfSize"
        :rows="rows"
        :autoGrow="autoGrow"
        :autoFocus="autoFocus"
        :maxlength="maxlength"
        :isNotReactive="isNotReactive"
        :hideScrollBar="hideScrollBar"
    ></InputTextArea>
</template>

<script>
import {mapGetters} from "vuex"
import CORE_TYPES from "@/store/core/types"
import TEMPLATES_EDIT_TYPES from "@/store/templatesEdit/types"
import InputTextArea from "@/components/core/forms/InputTextArea.vue"
import {VIEW_ROLES, ROLES} from "@/router/roles"

export default {
    components: {
        InputTextArea,
    },
    props: {
        label: {},
        value: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        validateUntouched: {
            type: Boolean,
            default: false,
        },
        suppressValidationMessage: {
            type: Boolean,
            default: false,
        },
        isComponentHalfSize: {
            type: Boolean,
            default: false,
        },
        rows: {
            type: Number,
        },
        autoGrow: {
            type: Boolean,
            default: false,
        },
        autoFocus: {
            type: Boolean,
            default: false,
        },
        maxlength: {
            type: Number,
            default: null,
        },
        isNotReactive: {
            type: Boolean,
            default: false,
        },
        hideScrollBar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            modifiedValue: undefined,
        }
    },
    watch: {
        value(newValue) {
            this.internalValue = newValue
        },
    },
    mounted() {
        if (
            this.hasRoles([
                VIEW_ROLES.VIEW_BROKER,
                VIEW_ROLES.VIEW_INTERN,
                VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS,
                ROLES.IS_MAKLERZUGANG,
            ])
        ) {
            this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_HTML_SHORTCUTS)
        }
    },
    computed: {
        ...mapGetters({
            htmlShortcuts: TEMPLATES_EDIT_TYPES.GETTERS.HTML_SHORTCUTS,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        internalValue: {
            get: function() {
                return this.isNotReactive ? this.modifiedValue || this.value || "" : this.value
            },
            set: function(modifiedValue) {
                if (this.htmlShortcuts) {
                    this.htmlShortcuts?.forEach((shortcut) => {
                        let reg = new RegExp(`(^|\\s)${shortcut.key}\\s`, "g")
                        let value = shortcut.value

                        if (value && modifiedValue) {
                            if (value.includes("<html")) {
                                // Replace html content by innerText of html
                                value = this.extractContentFromHtml(value)
                            }

                            modifiedValue = modifiedValue.replace(reg, value)
                        }
                    })
                }

                this.$emit("input", modifiedValue)
                if (this.isNotReactive) {
                    this.modifiedValue = modifiedValue
                }
            },
        },
    },
    methods: {
        extractContentFromHtml(html) {
            let span = document.createElement("span")
            span.innerHTML = html
            let content = span.textContent || span.innerText
            span.remove()
            return content
        },
        input(event) {
            this.modifiedValue = event
            this.$emit("input", event)
        },
    },
}
</script>
