var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formulare-list__container" },
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Formulare",
          subtitle: _vm.formArtTitle,
          defaultMenu: _vm.customOptionMenu
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: _vm._generateTidFromString("box__container") }
        },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v(_vm._s(_vm.formArtTitle))
          ]),
          _c("InputField", {
            attrs: {
              isComponentHalfSize: "",
              placeholder: "Suchen nach Bezeichnung",
              trimValue: "",
              disabled: _vm.isLoading
            },
            model: {
              value: _vm.descriptionSearch,
              callback: function($$v) {
                _vm.descriptionSearch = $$v
              },
              expression: "descriptionSearch"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: {
            tid: _vm._generateTidFromString(
              "cards__container layout__negative-margin--8"
            )
          }
        },
        [
          _vm.isLoading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _c("Table", {
                attrs: {
                  tableId: "0eca709b-022c-4078-871f-633007348bae",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20,
                  rowId: "name"
                },
                on: { "click-name": _vm.openFormList }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }