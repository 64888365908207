export const MUTATION_PREFIX = 'VERTRIEBS_INFO: ';
export const ACTIONS_PREFIX = 'VERTRIEBS_INFO_ACTIONS_';
export const GETTERS_PREFIX = 'VERTRIEBS_INFO_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    PRODUCT_UTILIZATION_UPDATE: MUTATION_PREFIX + 'PRODUCT_UTILIZATION_UPDATE',
    VERTRIEBS_INFO_UPDATE: MUTATION_PREFIX + 'VERTRIEBS_INFO_UPDATE',
  },
  ACTIONS: {
    LOAD_VERTRIEBS_DATA: ACTIONS_PREFIX + 'LOAD_VERTRIEBS_DATA',
    PRODUCT_UTILIZATION: ACTIONS_PREFIX + 'PRODUCT_UTILIZATION',
    VERTRIEBS_INFO: ACTIONS_PREFIX + 'VERTRIEBS_INFO',
    SAVE_VERTRIEBS_INFO: ACTIONS_PREFIX + 'SAVE_VERTRIEBS_INFO',
  },
  GETTERS: {
    PRODUCT_UTILIZATION: GETTERS_PREFIX + 'PRODUCT_UTILIZATION',
    FORMATTED_PRODUCT_UTILIZATION: GETTERS_PREFIX + 'FORMATTED_PRODUCT_UTILIZATION',

    VERTRIEBS_INFO: GETTERS_PREFIX + 'VERTRIEBS_INFO',
  },
}
