export const MUTATION_PREFIX = 'FILE_UPLOAD_MUTATIONS_';
export const ACTIONS_PREFIX = 'FILE_UPLOAD_ACTIONS_';
export const GETTERS_PREFIX = 'FILE_UPLOAD_GETTERS_';

export default {
  MUTATIONS: {
    UPLOAD_PDF: MUTATION_PREFIX + 'UPLOAD_PDF',
    UPLOAD_PDF_SUCCESS: MUTATION_PREFIX + 'UPLOAD_PDF_SUCCESS',
    REMOVE_PDF: MUTATION_PREFIX + 'REMOVE_PDF',
    UPDATE_PDF_PAGE: MUTATION_PREFIX + 'UPDATE_PDF_PAGE',
    UPDATE_PDF_DECKBLATT: MUTATION_PREFIX + 'UPDATE_PDF_DECKBLATT',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',

    GET_DECKBLATT_COMBOS_SUCCESS: MUTATION_PREFIX + 'GET_DECKBLATT_COMBOS_SUCCESS',
    GET_COMBO_VALUES_SUCCESS: MUTATION_PREFIX + 'GET_COMBO_VALUES_SUCCESS',
    SEND_RUN:MUTATION_PREFIX + 'SEND_RUN',
    RESULT_TABLE:MUTATION_PREFIX + 'RESULT_TABLE'
  },
  ACTIONS: {
    //FIND_CORNERS: ACTIONS_PREFIX + 'FIND_CORNERS',
    UPLOAD_PDF: ACTIONS_PREFIX + 'UPLOAD_PDF',
    UPLOAD_IMAGE: ACTIONS_PREFIX + 'UPLOAD_IMAGE',
    PDF_PAGE_TOGGLE_ACTIVE: ACTIONS_PREFIX + 'PDF_PAGE_TOGGLE_ACTIVE',
    PDF_PAGE_CHANGE_BARCODE: ACTIONS_PREFIX + 'PDF_PAGE_CHANGE_BARCODE',
    PDF_PAGE_SET_DECKBLATT: ACTIONS_PREFIX + 'PDF_PAGE_SET_DECKBLATT',
    PDF_PAGE_SET_NEXT_BARCODES: ACTIONS_PREFIX + 'PDF_PAGE_SET_NEXT_BARCODES',
    SEND_PDF: ACTIONS_PREFIX + 'SEND_PDF',

    GET_DECKBLATT_COMBOS: ACTIONS_PREFIX + 'GET_DECKBLATT_COMBOS',
    GET_COMBO_VALUES: ACTIONS_PREFIX + 'GET_COMBO_VALUES',
    UPLOAD_DECKBLATT: ACTIONS_PREFIX + 'UPLOAD_DECKBLATT',
    GET_EINGANG:ACTIONS_PREFIX + 'GET_EINGANG',
    RESULT_TABLE:ACTIONS_PREFIX + 'RESULT_TABLE'
  },
  GETTERS: {
    COLLATED_PDFS: GETTERS_PREFIX + 'COLLATED_PDFS',
    PDF_UPLOADS: GETTERS_PREFIX + 'PDF_UPLOADS',
    PDF_UPLOAD_STATUS: GETTERS_PREFIX + 'PDF_UPLOAD_STATUS',
    PDF_UPDATED_DECKBLATT: GETTERS_PREFIX + 'PDF_UPDATED_DECKBLATT',

    DECKBLATT_COMBOS: GETTERS_PREFIX + 'DECKBLATT_COMBOS',
    COMBO_VALUES: GETTERS_PREFIX + 'COMBO_VALUES',
    SEND_RUN:GETTERS_PREFIX + 'SEND_RUN',
    RESULT_TABLE:GETTERS_PREFIX + 'RESULT_TABLE'
  }
}