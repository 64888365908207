var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DragnDropArea",
    {
      staticClass: "mb-3",
      attrs: { hoverText: "Dokument hochladen" },
      on: { files: _vm.emitFiles }
    },
    [
      _c("BaseFileSelect", { on: { files: _vm.emitFiles } }, [
        _vm._v("Dokument hochladen")
      ]),
      _vm.fileId
        ? [
            _c(
              "DownloadLink",
              {
                attrs: {
                  target: "_blank",
                  rel: "noopener noreferer",
                  title: _vm.filename,
                  filename: _vm.filename,
                  downloadServicePath: "/get_simple_file",
                  queryParams: {
                    fileId: _vm.fileId
                  }
                }
              },
              [_c("Pill", { attrs: { label: _vm.filename, type: "default" } })],
              1
            ),
            _c(
              "BaseButton",
              { attrs: { isLink: "" } },
              [
                _c("PhTrash", {
                  staticClass: "ml-2",
                  attrs: { size: 16 },
                  on: { click: _vm.emitDelete }
                })
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }