import PROCESS_TYPES from './types';
import Vue from 'vue'
import { getInitialState } from './index';

export default {
  [PROCESS_TYPES.MUTATIONS.GET_PROCESS_LIST_SUCCESS](state, processList) {
    Vue.set(state, 'processList', processList);
  },
  [PROCESS_TYPES.MUTATIONS.GET_PROCESS_SUCCESS](state, process) {
    Vue.set(state, 'process', process);
  },
  [PROCESS_TYPES.MUTATIONS.GET_PROCESS_VORHABEN_OVERVIEW_SUCCESS](state, processVorhabenOverview) {
    Vue.set(state, 'processVorhabenOverview', processVorhabenOverview)
  },
  [PROCESS_TYPES.MUTATIONS.GET_ZUGANG_LIST_SUCCESS](state, maklerExtZugangList) {
    Vue.set(state, 'maklerExtZugangList', maklerExtZugangList);
  },
  [PROCESS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },

}