<template>
<div :tid="_generateTidFromString('cards__container layout__negative-margin--8')" >
    <Table
        v-if="isSingleSelection"
        tableId="f18fcd2c-7f2b-4a4a-9676-db8e64229473"
        title="Kontakte"
        :headers="headers"
        :rows="rows"
        rowId="id"
        :rowsPerPage="20"
        @click-fullName="onSelectParticipant"
    />

    <Table
        v-else
        tableId="e91ac4a9-6761-429c-9190-58e6c3c9cb49"
        title="Kontakte"
        :headers="headers"
        :rows="rows"
        rowId="id"
        :rowsPerPage="20"
        :selected="selectedRowsValues"
        @selected="selectedRows"
    />    
</div>
</template>

<script>
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";

export default {
    mixins: [InteractiveHelpCommonsMixin],
    components: {
        Table,
    },
    props: {
        records: {
            type: Array,
            default: () => [],
        },
        isSingleSelection: {
            type: Boolean,
            default: () => false,
        }
    },
    data() {
        return {
            recordsAp: [],
            canEdit: false,
            selectedRowsValues: [],
        }
    },
    computed: {
        headers() {

            let lockedLeft = [];

            if (this.isSingleSelection) {
                lockedLeft = [
                        TextColumn("fullName", "Name").makeLink(),
                    ]
            } else {
                lockedLeft = [
                        TextColumn("fullName", "Name"),
                    ]
            }

            lockedLeft.push(TextColumn("email", "E-Mail").addCellProps({ lineClamp: 2, }).makeAlwaysVisible());

            return {
                lockedLeft,
                center: [
                    TextColumn("typ", "Typ"),
                    TextColumn("telefon", "Telefon"),
                    TextColumn("mobil", "Mobil"),
                    TextColumn("zweigstelle", "Zweigstelle"),
                ],
            }
        },        
        rows() {
            return this.records?.map?.((row, index) => ({
                ...row,
                index,
                id: row?.user?.userId || index,
            })) || [];
        }
    },
    methods: {
        selectedRows(rows) {
            this.selectedRowsValues = rows;
            this.$emit('selectedRows', this.selectedRowsValues);
        },
        onSelectParticipant(row) {
            this.$emit('onSelectParticipant', row);
        }
    },
}
</script>