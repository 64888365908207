var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("Table", {
            attrs: {
              title: "Makler Positiv Liste",
              headerActions: _vm.tableHeaderActions,
              headers: _vm.headers,
              rows: _vm.positivRows,
              hidePagination: "",
              rowId: "maklerNr"
            },
            on: {
              "action-DELETE": function($event) {
                return _vm.deleteRow("positiv", $event)
              },
              "action-EDIT": function($event) {
                return _vm.editOrCreateRow("positiv", $event)
              },
              "headerAction-ADD": function($event) {
                return _vm.editOrCreateRow("positiv")
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("Table", {
            attrs: {
              title: "Makler Negativ Liste",
              headerActions: _vm.tableHeaderActions,
              headers: _vm.headers,
              rows: _vm.negativRows,
              hidePagination: "",
              rowId: "maklerNr"
            },
            on: {
              "action-DELETE": function($event) {
                return _vm.deleteRow("negativ", $event)
              },
              "action-EDIT": function($event) {
                return _vm.editOrCreateRow("negativ", $event)
              },
              "headerAction-ADD": function($event) {
                return _vm.editOrCreateRow("negativ")
              }
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "maklerModal",
          attrs: {
            modalTitle: _vm.selectedRow.index
              ? "Makler bearbeiten"
              : "Makler hinzufügen",
            confirmDisabled: !_vm.selectedRowIsValid
          },
          on: { onConfirmButton: _vm.saveRow }
        },
        [
          _c("InputToggleSwitch", {
            attrs: { label: "Inkl. Struktur" },
            model: {
              value: _vm.selectedRow.mitStruktur,
              callback: function($$v) {
                _vm.$set(_vm.selectedRow, "mitStruktur", $$v)
              },
              expression: "selectedRow.mitStruktur"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Maklernummer",
              isComponentHalfSize: "",
              renderDanger: !_vm.selectedRowIsValid
            },
            model: {
              value: _vm.selectedRow.maklerNr,
              callback: function($$v) {
                _vm.$set(_vm.selectedRow, "maklerNr", $$v)
              },
              expression: "selectedRow.maklerNr"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }