var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-forms__container captcha-input-field__container" },
    [
      _c(
        "div",
        { staticClass: "captcha-input-field--img" },
        [
          _vm.isLoading
            ? _c("GhostLoading", {
                attrs: {
                  type: "block",
                  config: { block: { width: 200, height: 50 } }
                }
              })
            : _c("img", { attrs: { src: _vm.captchaImg, alt: "CAPTCHA-Text" } })
        ],
        1
      ),
      _c("InputField", {
        staticClass: "captcha-input-field--input p-0 m-0",
        attrs: {
          label: _vm.label,
          placeholder: _vm.placeholder,
          renderDanger: _vm.isValid === false,
          validateUntouched: _vm.validateUntouched,
          validation_path: _vm.validationPath,
          trimValue: "",
          disabled: _vm.disabled || _vm.loadingToken
        },
        on: {
          input: function($event) {
            return _vm.onInput($event)
          },
          change: function($event) {
            return _vm.checkCaptcha($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function() {
              return [_vm.checking ? _c("AnimatedSpinner") : _vm._e()]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.internalValue,
          callback: function($$v) {
            _vm.internalValue = $$v
          },
          expression: "internalValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }