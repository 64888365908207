var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBackButtonAtAll
    ? _c(
        "div",
        {
          staticClass:
            "justify-content-between align-items-center breadcrumb__back-button"
        },
        [
          _vm._t("backLink", function() {
            return [
              _vm.showAsAnyButton
                ? [
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          disabled: _vm.disabled,
                          isSecondary: _vm.showAsSecondaryButton
                        },
                        on: {
                          click: function($event) {
                            return _vm.goToLastAction()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.backLabel))]
                    )
                  ]
                : [
                    _vm.lastBackToPreviousData &&
                    _vm.lastBackToPreviousData.fullPath
                      ? _c(
                          "a",
                          {
                            staticClass: "breadcrumb__link full-text",
                            attrs: {
                              tid: _vm._generateTidFromString(
                                _vm.lastBackToPreviousData.fullPath
                              )
                            },
                            on: {
                              click: function($event) {
                                return _vm.goToLastAction()
                              }
                            }
                          },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.sanitize(_vm.backLabel))
                              }
                            })
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "BaseButton",
                      {
                        staticClass: "breadcrumb__link short-text m-0",
                        attrs: {
                          isClear: "",
                          alt: "Icon zur Navigation auf die vorherige Seite"
                        },
                        on: {
                          click: function($event) {
                            return _vm.goToLastAction()
                          }
                        }
                      },
                      [
                        _c("PhCaretLeft", {
                          attrs: {
                            size: _vm.size,
                            alt: "Icon zur Navigation auf die vorherige Seite"
                          }
                        })
                      ],
                      1
                    )
                  ]
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }