import router from '@/router';
import { BACK_BUTTON_PARAM, BACK_ACTION_PATTERN } from '@/router/breadcrumb';

function addBackActionParamIfNeeded(href) {
  if(href.search(BACK_ACTION_PATTERN) < 0) {
    const separator = href.search(/\?/gi) < 0 ? '?' : '&';

    return `${href}${separator}${BACK_BUTTON_PARAM}`;
  }

  return href;
}

function handlePopState(event) {
  const href = addBackActionParamIfNeeded(location.href);

  // Add backAction param and Disable the forward button, when the user back to the last page visited
  history.replaceState(event.state, document.title, href);
}

/**
 * When the user clicks in the back/forward native buttons.
 * This function will add a "backAction=true" param to avoid the breadcrumb being added.
 * 
 * It was created outside from Vue.js, because it need to execute before the VueRouter hooks
 */
export function registerBrowserBackButtonEvent() {
  window.addEventListener('popstate', handlePopState);
}

export function isNativeBackButtonDisabled(route) {
  if (!route) return false;
  return router.match(route).matched.some(mr => mr.meta.disableNativeBackButton);
}
