<template>
  <div>
    <BoxContainer :title="selectedTabLabel">
      <Table
        v-if="tableData && tableData.records && tableData.records.length"
        :tableData="tableData"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :paginationEnabled="false"
        :rowsPerPage="tableData.records.length"
        @execute-action="handleTableAction($event)"
      >
        <template #Bezeichnung="{ data }">
          <a @click="handleDocumentClick(data)">{{ data.row.Bezeichnung }}</a>
        </template>
      </Table>
      <div v-if="!tableData.records || !tableData.records.length">Keine Daten</div>
    </BoxContainer>
  </div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import { mapGetters } from 'vuex';
import Table from "@/components/table/Table.vue";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

export default {
  components: {
    BoxContainer,
    Table,
  },
  name: "TabDokumente",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
  computed: {
    ...mapGetters({dokumente : FONDSINFO_TYPES.GETTERS.FONDSINFO_DOKUMENTE}),
    tableData() {
      if(this.dokumente && this.dokumente.tableHeaders) {

        const headers = this.dokumente.tableHeaders
        .map(header => {
          if (header.dataType === 'Action') {
            header.label = 'Aktionen';
            header.key = 'actions';
            header.dataType = 'tableAction'
          }
          if (header.key === 'Bezeichnung') {
            header.dataType = 'Slot'
          }
          return header;
        })
        .reduce((acc, curr) => ({...acc, ...({[curr.key] : curr})}), {});

        const headersKeys = Object.keys(headers);
        const records = this.dokumente.records.map(tuple => {
          return tuple.reduce((acc, curr, index) => ({...acc, ...({[headersKeys[index]] : curr})}), {});
        }).map(record => {
          const icon = {
            legend: {
              icon: 'PhFileArrowDown',
              index: 1,
              key: 'DOWNLOAD',
              label: 'Download',
            }
          }
           record.actions = [{...record.actions, ...icon}];
           return record;
        })
        return {headers, records};
      }
      return [];
    },
  },
  mounted() {
    if(!this.dokumente) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_DOKUMENTE, 
      {
        isin: this.$route.params?.isin,
        bgsNr: this.$route.params?.bgsNr,
      });
    }
  },
  methods: {
    handleDocumentClick(event) {
      const document = event?.row?.actions[0];
      this.openPDF(document, event?.row?.Bezeichnung);
    },
    handleTableAction(event) {
      const document = event?.value?.actions[0];
      this.openPDF(document, event?.value?.Bezeichnung);
    },
    async openPDF(document, filename) {
      const response = await this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.DOWNLOAD_PDF, document);
      if (response) {
        viewDocument({
          data: response.bytes || response,
          filename: response.fileName || `${filename.replace(/[ &\/\\#,+()$~%.'":*?<>{}]/g, '')}.pdf`,
          contentType: 'application/pdf',
        }, true);
      }
    },
  }

}
</script>

<style>

</style>