var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "zusatzDok",
          attrs: {
            labelButtonConfirm: "Weiter zur Unterschrift",
            labelButtonCancel: "Abbrechen",
            size: "lg"
          },
          on: {
            onConfirmButton: _vm.sendZusatzDoks,
            onCancelButton: _vm.closeZusatzListModal
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Zusätzliche Dokumente ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.pictureAttachMsg
            ? _c("div", { staticClass: "mb-1" }, [
                _vm._v(" " + _vm._s(_vm.pictureAttachMsg) + " ")
              ])
            : _vm._e(),
          _vm.webserviceWarnings && _vm.webserviceWarnings.length
            ? _c("WebserviceWarnings", {
                staticClass: "mb-2",
                attrs: { webserviceWarnings: _vm.webserviceWarnings }
              })
            : _vm._e(),
          _c("div", { staticClass: "font-strong mb-1" }, [
            _vm._v(
              " Wenn Sie möchten können Sie hier Dokumente auswählen, die vor der e-Signatur zum Antrag hinzugefügt werden. "
            )
          ]),
          _c("div", { staticClass: "font-strong mb-1" }, [
            _vm._v(
              " Andernfalls können Sie direkt mit der Unterschrift fortfahren. "
            )
          ]),
          _vm._l(_vm.bodyMessages, function(message, index) {
            return _c("div", { key: "body" + index }, [
              _c("div", { staticClass: "mb-1" }, [
                _vm._v(" " + _vm._s(message) + " ")
              ])
            ])
          }),
          _c("div", { staticClass: "mb-1" }, [
            _vm._v(
              " Bitte beachten Sie, dass das Anfügen der Dokumente dauerhaft ist, auch wenn Sie den Antrag doch nicht signieren. "
            )
          ]),
          _vm.webServiceMsg
            ? _c("div", { staticClass: "font-strong mb-1" }, [
                _vm._v(" " + _vm._s(_vm.webServiceMsg) + " ")
              ])
            : _vm._e(),
          _vm._l(_vm.zusatzList, function(dok, index) {
            return _c("div", { key: index, staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c("InputCheckBoxItem", {
                      attrs: { disabled: dok.appended },
                      model: {
                        value: _vm.zusatzDokForm[dok.id],
                        callback: function($$v) {
                          _vm.$set(_vm.zusatzDokForm, dok.id, $$v)
                        },
                        expression: "zusatzDokForm[dok.id]"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "ml-1" },
                      [
                        dok.id
                          ? _c("DownloadLink", {
                              attrs: {
                                href: _vm.attachmentLinkMaker(
                                  dok.filename,
                                  dok.id
                                ),
                                title:
                                  dok.bezeichnung + " (" + dok.herkunft + ")"
                              }
                            })
                          : _c("div", [
                              _vm._v(
                                _vm._s(dok.bezeichnung) +
                                  " (" +
                                  _vm._s(dok.herkunft)
                              )
                            ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ])
          })
        ],
        2
      ),
      _c(
        "BaseModal",
        {
          ref: "errorModal",
          attrs: { labelButtonConfirm: "Ok", showCancelButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("label", [_vm._v(_vm._s(_vm.errorMessage))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }