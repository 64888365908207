<template>
  <div>
    <OptionMenu
      :id="$appNavigation.parentOptionMenuId"
      :defaultMenu="$appNavigation.parentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      title="Wertpapiere"
    />

    <FondsFinderSuchMaske emitFilters @onSearch="search" />
    <FondsFinderPositionsList
      zurueckButtonText="Zurück zur Vermögensverwaltung"
      :hasDefaultButton="false"
      :isSelectable="false"
      @goBack="navigateToEditor"
      @handleTableAction="
        handleTableAction($event, 'zurück zur Wertpapiersuche')
      "
    />
  </div>
</template>

<script>
import FondsFinderSuchMaske from "@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue";
import FondsFinderPositionsList from "@/components/antrag/fondsfinder/FondsFinderPositionsList.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import CORE_TYPES from "@/store/core/types";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";

export default {
  mixins: [mixin],
  components: {
    FondsFinderSuchMaske,
    FondsFinderPositionsList,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data() {
    return {
      emitFilters: true,
    };
  },
  computed: {
    act() {
      return `search_${this.$route.query.categoryId}..`;
    },
  },
  methods: {
    search() {
      this.$store.dispatch(VV_TYPES.ACTIONS.VV_GET_NEW_FONDS, {
        act: this.act,
      });
    },
    navigateToEditor(data = []) {
      if (data?.length) {
        // this.$store.dispatch(VV_TYPES.ACTIONS.ADD_GENEHMIGT_WERTPAPIERE, {versionId: this.$route.params.versionId, isins: data.map(fond => fond.isin)});
      }
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE);
      this.$router.push({
        path: `/${this.basePath}/vv/vv-schema-editor/${this.$route.params.schemaId}/${this.$route.params.versionId}`,
        query: {
          backAction: true,
          substepKey: `${this.$route.params.versionId}`,
        },
      });
    },
  },
};
</script>

<style>
</style>