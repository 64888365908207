<template>
  <div>
    <div>
      <div v-if="!rows.length" class="box__title">
        {{ title }}
      </div>
      <Table
        v-if="rows.length"
        tableId="36688150-ebdd-11ec-8ea0-0242ac120002"
        :title="title"
        :headers="headers"
        :rows="rows"
        :paginationEnabled="false"
        :hidePagination="true"
        @action-OPEN="handleActionOpen"
        @action-REPORT="handleActionReport"
        :mobileConfig="{title: 'userId', headers: ['status','userId','barcode']}"
         
      />
      <GhostLoading v-else-if="loading" type="table" />
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import V_P_TYPES from "@/store/versandProtokolle/types";
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  DateTimeColumn,
  PillColumn,
  ActionColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import NoData from "@/components/core/NoData.vue";
import dayjs from "dayjs";
import { downloadLinkMaker } from '@/helpers/utils-helper';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

export default {
  components: {
    Table,
    GhostLoading,
    NoData,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    records: {
      type: Array,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabledColumns: {
      type: Array,
      default: () => [],
    },
    parentId: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      rec: V_P_TYPES.GETTERS.FILTER,
      setup:V_P_TYPES.GETTERS.SETUP
    }),
    headers() {
      return {
        lockedLeft: [
          DateTimeColumn("DateFormatted", "Datum"),
          PillColumn("category", "Kategorie"),
          PillColumn("status", "Status"),
        ].filter((column) => !this.disabledColumns.includes(column.key)),
        center: [
          TextColumn("gesellschaft", "Gesellschaft"),
          TextColumn("barcode", "Barcode"),
          TextColumn("userId", "Benutzer"),
          TextColumn("receiver", "Empfaenger"),
          TextColumn("versuche", "Versuche"),
          ActionColumn("actions", "Aktionen"),
        ],
        lockedRight: [].filter(
          (column) => !this.disabledColumns.includes(column.key)
        ),
      };
    },
    rows() {
     
      if (!this.records)
        return [];

      return this.records.map((record) => {
        
        const row = {
          ...record,
          selectedHidden: !record.delete,

          category: {
            label: record.category,
            type: record.category,
          },
          DateFormatted:
            record.DateFormatted ||
            (record.date &&
              dayjs(record.date, "DD.MM.YY HH:mm").format("DD.MM.YY HH:mm")) ||
            null,
          // DateFormatted: record.d.format('DD.MM.YYYY HH:mm')
        };
        row.tooltip = "hhhhhh";
        if (record.actions) {
          row.actions = record.actions
            .filter(
              (action) =>
                !this.isInsurance ||
                (record.category === "Aktivität" || record.category === "Aufgabe") ||
                action.key !== "DELETE"
            )
            .map((legend) => {
              return SimpleAction(legend.key, legend.icon, legend.label);
            });
        }
        //
        /*switch (record.status) {
                    case 'OK':
                        row.status = {label: "Ok", type: "success",tooltip:"lll"};
                        break;
                    case 'Error':
                        row.status = {label: "senden fehlgeschlagen", type: "danger"};
                        break;
                    case 'Sending':
                        row.status = {label: "wird versendet", type: "warning"};
                        break;
                }*/
        if (record.EXTERN != null)
          row.EXTERN = record.EXTERN ? "extern" : "intern";
        return row;
      });
    },
  },
  data() {
    return {
      apiAddress: process.env.VUE_APP_API,
      filterConfig: {
        checkDefaultSearchInput: false,
        cbDefSearchInput: null,
        hideFirstColumn: true,
        filterZurucksetzen: null, // () => {},
      },
      
      isTerminModalActivity: false,
      confirmWarning: "",
      confirmRow: {},
      selectedRows: [],
    };
  },
  methods: {
   
    openFax(tableRow) {
      const filename = 'Fax.pdf';
      const href = downloadLinkMaker(this.$store.getters, '/download_service/downloadFax', filename, { id: tableRow.id });
      viewDocument({ href, filename });
    },

    handleActionOpen(tableRow) {
     

      if (tableRow && tableRow.id) {
        if (tableRow.category.type == "EMAIL") {
          
          this.$router.push(`/communication/postfach/emails/email/${tableRow.id}`);
        } else if (tableRow.category.type == "FAX") {
          this.openFax(tableRow);
        } else if (tableRow.category.type == "WEB_SERVICE") {
          this.$store.dispatch(V_P_TYPES.ACTIONS.LOAD_WEB_SERVICE,tableRow.id);
        }else if (tableRow.category.type == "PDF") {
          this.$store.dispatch(V_P_TYPES.ACTIONS.LOAD_PDF,tableRow.id);
        }
      }
    },
    
    handleActionReport(tableRow) {
    

      if (tableRow && tableRow.id) {
        if (tableRow.category.type == "FAX") {
          this.$store.dispatch(V_P_TYPES.ACTIONS.LOAD_FAX_REPORT,tableRow.id);
        } else if (tableRow.category.type == "WEB_SERVICE") {
          this.$store.dispatch(V_P_TYPES.ACTIONS.LOAD_WEB_SERVICE_REPORT,tableRow.id);
        }  else if (tableRow.category.type == "EMAIL") {
          this.$store.dispatch(V_P_TYPES.ACTIONS.LOAD_MAIL_REPORT,tableRow.id);
        }
      }
    },

   
    
    selected(rows) {
      this.selectedRows = rows;
      this.$emit("selected", rows);
    },
  },
};
</script>

<style scoped>
.subject {
  display: inline-block;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>