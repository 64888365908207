<template>
    <div>
        <div v-if="!isFA">
            <InputToggleSwitch
                label="Arbeitnehmersparzulage" 
                :inLineLabel="true"
                v-model="form.employeeSavingAllowance" 
                :disabled="disabled"
                @input="updateStore('employeeSavingAllowance', $event)" />
            <InputToggleSwitch
                label="Riester-Rente" 
                :inLineLabel="true"
                v-model="form.riesterPension" 
                :disabled="disabled"
                @input="updateStore('riesterPension', $event)" />
            <InputToggleSwitch
                label="Rürup-Rente" 
                :inLineLabel="true"
                v-model="form.ruerupPension" 
                :disabled="disabled"
                @input="updateStore('ruerupPension', $event)" />
            <InputToggleSwitch
                label="Wohnungsbauprämie" 
                :inLineLabel="true"
                v-model="form.buildingSubsidy" 
                :disabled="disabled"
                @input="updateStore('buildingSubsidy', $event)" />
            <InputToggleSwitch
                label="bAV" 
                :inLineLabel="true"
                v-model="form.fedInsuranceComm" 
                :disabled="disabled"
                @input="updateStore('fedInsuranceComm', $event)" />
        </div>
        <div v-if="isAnlegerprofil">
            <InputField v-if="!isWealthKonzept"
                label="Wieviele Personen sind während des Anlagezeitraumes auf Ihre finanzielle Unterstützung angewiesen (z.B. nichtarbeitende Ehe-/Lebenspartner, Kinder, zu pflegende Angehörige etc.)?"
                v-model="form.numberPerson"
                isComponentHalfSize
                :disabled="disabled"
                @change="updateStore('numberPerson', $event)" />
            <InputField v-if="!isWealthKonzept"
                label="Bei Minderjährigen: Der Anlagebetrag stammt aus einer Zuwendung / Schenkung von (z.B. Eltern, Großeltern, Tante):"
                v-model="form.carePerson"
                isComponentHalfSize
                :disabled="disabled"
                @change="updateStore('carePerson', $event)" />
            <InputTextArea
                label="Zusätzliche Angaben zu den finanziellen Verhältnissen"
                v-model="form.annotationFinance"
                isComponentHalfSize
                :disabled="disabled"
                @change="updateStore('annotationFinance', $event)" />
        </div>
    </div>
  </template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
    props: {
        disabled: {
            default: false,
        },
        finance: {
            default: () => {},
        },
        isAnlegerprofil: {
            default: false,
        },
        isWealthKonzept: {
            default: false,
        },
        isFA: {
            default: false,
        },
    },
    data() {
        return {
            form: {}
        }
    },
    components: {
        InputField,
        InputTextArea,
        InputToggleSwitch,
    },
    mounted() {
        this.form = Object.assign({}, this.finance);
    },
    methods: {
        updateStore(compId, value) {
            this.$emit('changed', {compId, value});
        },
    },

}
</script>