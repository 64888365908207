import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import CORE_TYPES from '@/store/core/types'
import LOADING_TYPES from '@/store/loading/types'
import LOG_TYPES from '@/store/log/types'
import { mapGetters } from 'vuex'
import { isObject } from '@/helpers/commonfunctions.js'
import { ROLES, VIEW_ROLES } from '@/router/roles.js'

import {
  MAP_CUSTOMER_ROUTE_TO_DEFAULT_SUBSTEP,
  MAP_PERSON_ROUTE_TO_DEFAULT_SUBSTEP,
} from './persoenliche-daten-steps-utils';
import { DEPOTEROFFNUNGEN_FIELD_MESSAGE, } from './persoenliche-daten-warnings-utils';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { DEPOTEROFFNUNGEN_WARNINGS_ID_VALID_FOR_FIRMA_ALS_ZUS_PERSON } from './persoenliche-daten-warnings-utils';
import EVENT_BUS, {
  GESPEICHERTE_AUSWEISFOTOS_LOESCHEN,
  SAVE_LEGITIMATION,
} from "@/event-bus";

const persoenlicheDatenMixin = {
  computed: {
    ...mapGetters({
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      isMainPerson: CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON,
      additionalValidators: CUSTOMERDATA_TYPES.GETTERS.GET_ADDITIONAL_VALIDATORS,
      isNewPerson: CUSTOMERDATA_TYPES.GETTERS.IS_NEW_PERSON,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      isInvers: CORE_TYPES.GETTERS.IS_INVERSE,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFirma: CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA,
      isStateStammdaten: CUSTOMERDATA_TYPES.GETTERS.GET_STATE_STAMMDATEN,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isBrokerOrByPass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      isLoadingFn: LOADING_TYPES.GETTERS.IS_LOADING,
      legitimationImagesMobileActions: CUSTOMERDATA_TYPES.GETTERS.LEGITIMATION_IMAGES_MOBILE_ACTIONS,
    }),
    darfVersicherungMaklerauftragSehen() {
      return this.hasRoles(ROLES.DARF_VERSICHERUNG_MAKLERAUFTRAG_SEHEN);
    },    
    isSavingCustomerData() {
      return this.isLoadingFn('mrscustomerdata-save');
    },
    depoteroeffnungenLabelClass() {
      if(this.isByPass) {
        return 'text-bold';
      }
      return '';
    },
    isEditable() {
      return this.customerData?.isEditable;
    },
    isRisikolevelEditable() {
      return this.customerData?.isEditable && this.isIntern && this.personalDataAddress?.isRisikolevelManuel;
    },
    isLegitimationEditable() {
      return this.customerData?.isLegitimationEditable
    },
    fullName() {
      const personalDataAddress = this.customerDataEdited?.personalDataAddress || this.customerData?.personalDataAddress;
      const titles = personalDataAddress?.titles || '';
      const firstName = personalDataAddress?.firstName || '';
      const lastName = personalDataAddress?.lastName || '';
      return (`${titles} ${lastName} ${firstName}`).trim();
    },
    isPersonData() {
      return this.$route.path.search(/\/person-data\//gi) >= 0;
    },
    step() {
      return this.$route.path;
    },
    substep() {
      if(this.isPersonData) {
        const path = this.$route?.matched[this.$route.matched.length - 1]?.path;
        return this.$route?.query?.substep || MAP_PERSON_ROUTE_TO_DEFAULT_SUBSTEP[path] || '';
      } else {
        return this.$route?.query?.substep || MAP_CUSTOMER_ROUTE_TO_DEFAULT_SUBSTEP[this.$route?.path] || '';
      }
    },
    depoteroffnungenWarnings() {
      return this.customerData?.depoteroffnungenWarnings || [];
    },
    hatSyncUser() {
      return this.hasRoles([ROLES.HAT_SYNC_USER]);
    },
    canViewCourtage() {
      return !this.hasRoles([[ROLES.FA], [ROLES.SUPER_CUSTOMER]]) && this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS);
    },
    showRisikolevel() {
      return (this.isMainPerson || this.relationship.isLegalAgent || this.relationship.isExtraDepotOwner) && !this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_ONLY) && (this.hasRoles(ROLES.FK) || this.isIntern);
    },
    hintRisikolevel() {
      return this.personalDataAddress?.hintRisikolevel || '';
    },
    anredePronomen() {
      return (this.isPersonData ? this.customerDataConfig?.anredePronomenPerson : this.customerDataConfig?.anredePronomenKunde) || [];
    },
    headerActions() {
      const { loading, isBrokerOrByPass, canCopyCustomerGroup, isEditable} = this;
      return [
        PageHeaderSimpleAction('BEARBEITEN', 'Bearbeiten')
          .withVisible(() => isBrokerOrByPass || isEditable),
        PageHeaderSimpleAction('PDF', 'PDF Kundendaten').withDisabled(() => loading)
        .withVisible(() => isBrokerOrByPass),
        PageHeaderSimpleAction('VCARD', 'vCard Kundendaten').withDisabled(() => loading)
        .withVisible(() => isBrokerOrByPass),
        PageHeaderSimpleAction('COPY', 'Kunde kopieren').withDisabled(() => loading)
        .withVisible(() => isBrokerOrByPass),
        PageHeaderSimpleAction('COPY-GROUP', 'Gruppenoberhaupt erzeugen').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass && canCopyCustomerGroup),      
        PageHeaderSimpleAction('BRIEF', 'Brief aus Vorlage').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass),        
          PageHeaderSimpleAction('TICKET', 'Neue Nachricht').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass),
        PageHeaderSimpleAction('EMAIL', 'Neue E-Mail').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass),
        PageHeaderSimpleAction('ACTIVITY', 'Terminübersicht').withDisabled(() => loading)
          .withVisible(() => isBrokerOrByPass),                                 
      ];
    },    
    friendlyAnredePronomen() {
      return this.anredePronomen?.find(ap => ap.value==this.customerData?.personalDataAddress?.anredePronomen)?.label;

    },
    canCopyCustomerGroup() {
      return this.hasRoles([[ROLES.FA, VIEW_ROLES.VIEW_INTERN], [ROLES.FK, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]);
    },
    customerBetreuer(){
      return this.customerDataConfig.customerBetreuer.filter((betreuer) => !betreuer.disabled)
    },
    additionalActionsForMobile() {
      if (this.substep === "legitimationsurkunde") {
        return this.legitimationImagesMobileActions;
      }
      return [];
    },
  },
  watch: {
    additionalValidators: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.$configureValidators) {
          this.$configureValidators(newValue)
        }
      },
    },
    depoteroffnungenWarnings() {
      this.setFieldsError();
    },
    substep: {
      handler() {
        this.setFieldsError();
      },
      immediate: true,
    },
    customerDataEdited(data) {
      if(!Object.keys(data).length) {
        this.discardChanges();
      }
      this.updateUserFullName();
    },
    customerData() {
      this.updateGroupsIntoData();
    },
  },
  methods: {
    handleMobileActions(actionData) {
      switch (actionData?.key) {
        case 'LEGITIMATION_DELETE_IMAGES':
          EVENT_BUS.$emit(GESPEICHERTE_AUSWEISFOTOS_LOESCHEN);
          break;
        case 'LEGITIMATION_SAVE':
          EVENT_BUS.$emit(SAVE_LEGITIMATION);
          break;
      }
    },
    updateUserFullName() {
      if(this.isMainPerson) {
        this.$store.commit(CORE_TYPES.MUTATIONS.UPDATE_USER_FULL_NAME, this.fullName);
      }
    },
    openCopyCustomerGroupModal() {
      this.$refs.copyCustomerGroupModal.open();
    },
    closeCopyCustomerGroupModal() {
      this.$refs.copyCustomerGroupModal.close();
    },        
    closeCopyCustomerModal() {
      this.$refs.copyCustomerModal.close();
    },
    openCopyCustomerModal() {
      this.$refs.copyCustomerModal.open();
    },
    copyCustomer() {
      this.loading = true;
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA).then(() => {
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.COPY_CUSTOMER_DATA).then(() => {
          this.closeCopyCustomerModal();
        })
        .finally(() => {
          this.loading = false;
        });
      });
    },     
    copyCustomerGroup() {
      this.loading = true;
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA).then(() => {
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.COPY_CUSTOMER_DATA_AS_GROUP_CUSTOMER).then(() => {
          this.closeCopyCustomerGroupModal();
        }).finally(() => {
          this.loading = false;
        });
      });
    },       
    openStep(whatStep) {
      this.$router.push(`/persoenlichedaten/customer-data/steps/${whatStep}`);
    },
    discardChanges() {
      Object.assign(this.$data, this.initialState());
      this.updateGroupsIntoData();
    },

    initialState() { // Required to be implemented
      return {};
    },

    updateGroupsIntoData() { }, // Required to be implemented

    // get the information from customerData and add it into $data
    updateGroupIntoData(groupName) {
      Object.keys(this[groupName]).forEach(fieldName => {
        if (this.customerDataEdited && this.customerDataEdited[groupName] && fieldName in this.customerDataEdited[groupName]) {
          
          this[groupName][fieldName] = this.customerDataEdited[groupName][fieldName]
        } else if (this.customerData && this.customerData[groupName] && fieldName in this.customerData[groupName]) {
          this[groupName][fieldName] = this.customerData[groupName][fieldName]
        }
      })
    },

    // get the information from customerData and add it into $data (field)
    updateFieldIntoData(fieldName) {
      if(isObject(this[fieldName])) {
        console.error('[CHECK]: updateFieldIntoData cannot be used in object fields.')
        return;
      }

      this[fieldName] = fieldName in this.customerDataEdited ? this.customerDataEdited[fieldName] : this.customerData[fieldName];
    },

    // if something in this group has changed, return all fields
    getChangedFormDataGroup(groupName) {
      const group = Object.keys(this[groupName])
        .reduce((acu, cur) => {
          if (this[groupName][cur] && typeof this[groupName][cur] === 'string') {
            acu[cur] = this[groupName][cur].trim();
            return acu;
          } else {
            acu[cur] = this[groupName][cur]
          return acu;
          }
        }, {})

      return this.isChanged(group, groupName) ? group : null
    },

    // check if a group was changed
    isChanged(group, groupName) {
      const groupData = this.customerDataEdited[groupName] ?? this.customerData[groupName];
      return Object.keys(group)
        .filter(fieldName => {
          if (!groupData) {
            return group[fieldName]
          }
          return groupData[fieldName] !== group[fieldName]
        })
        .length > 0
    },

    // send the changed data to customerDataEdited. This will be saved later
    addCustomerDataEdited(group, data) {
      const groupData = arguments.length === 2 ? data : this.getChangedFormDataGroup(group)
      if (groupData !== null && groupData !== undefined) {
        const payload = {
          personId: this.personId,
          [group]: isObject(groupData) ? { ...this.customerData[group], ...groupData, } : groupData,
        }
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload)
      }
    },

    // send the changed data to customerDataEdited. This will be saved later (specific for ComboBox)
    addCustomerDataEditedCombo(group, field, value) {
      if(!group in this) return;

      this.$set(this[group], field, value);
      this.addCustomerDataEdited(group);
    },

    // get the list from customerData and add it into $data
    updateListIntoData(groupName) {
      const customerData = this.customerData || {};
      if(groupName in customerData) {
        this[groupName] = [...customerData[groupName]];
      }
    },

    hasValidComboValue(group, field, possibleValues = []) {
      const currentValue = this?.[group]?.[field];
      if(!currentValue) return false;
      return possibleValues?.findIndex(item => item === currentValue || item?.value === currentValue) >= 0;
    },

    async navigateToPath(path) {
      if(this.checkCustomerDataEdited()) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_STATE_STAMMDATEN)
      }
      if(this.$route.fullPath !== path) {
        await this.saveChanges();
        this.$router.push(path);
      }
    },

    navigateToSubstepPath(event) {
      this.navigateToPath(`${event?.stepKey}?substep=${event?.substepKey}`);
    },

    async saveChanges() {
      if (!this.customerData || !this.personId) {
        return
      }

      await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA);
    },

    setFieldsError() {
      if('$pushErrors' in this) {
        this.$nextTick(() => {
          this.depoteroffnungenWarnings?.forEach(warning => {
            if(this.isFirma && !this.isMainPerson){
              if(DEPOTEROFFNUNGEN_WARNINGS_ID_VALID_FOR_FIRMA_ALS_ZUS_PERSON.includes(warning.id)){
                this.$pushErrors(warning.id, warning.message ? warning.message : DEPOTEROFFNUNGEN_FIELD_MESSAGE);
              }           
            }else{
              this.$pushErrors(warning.id, warning.message ? warning.message : DEPOTEROFFNUNGEN_FIELD_MESSAGE);
            }
          });
        });
      }
    },

    checkCustomerDataEdited() { 
          return Object.keys(this.customerDataEdited).filter(key => key == 'personalDataAddress' || (key == 'bankingAccount' && this.checkBankverbindungExisting(this.customerDataEdited)) ).length > 0;
    },
    checkBankverbindungExisting(customerDataEdited){
      let bankverbindung = this.customerData?.bankAccounts.find(ba=>ba.id == customerDataEdited.bankingAccount.id)
      if(bankverbindung){
        return  customerDataEdited.bankingAccount.iban == bankverbindung.iban && customerDataEdited.bankingAccount.ibanChanged //this.customerDataEdited.bankingAccount.id != 'new_account'
      }   
    },
    saveTaxData(type) {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_EINKOMMEN_SOZIALVERSICHERUNGEN, type);
    },

    addRelationshipDataChanged(relationship) {
      this.relationship = { personId: this.personId, ...relationship, };
      this.addCustomerDataEdited('relationship');
    },

    navigateTo(event) {
      if(this.$route.fullPath === event) return;
      this.$router.push({ path: `${event}` });
    },
  },
};

export default persoenlicheDatenMixin;
