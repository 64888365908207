var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: "4d5f8286-e61f-4110-a06e-c8f2752042e1",
          title: _vm.dynamicTitle,
          actions: _vm.headerActions,
          noPrimaryAction: false
        },
        on: {
          "action-RELOAD-DATA": function($event) {
            return _vm.reloadData()
          }
        }
      }),
      !_vm.loading
        ? _c("div", { staticClass: "masonry__container" }, [
            _c("div", { staticClass: "item" }, [
              _vm._m(0),
              _vm.category
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("Kategorie")
                    ]),
                    _c("div", { staticClass: "card-row-value" }, [
                      _vm._v(_vm._s(_vm.category))
                    ])
                  ])
                : _vm._e(),
              _vm.fileName
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("Dateiname")
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-row-value" },
                      [
                        _c("DownloadLink", {
                          attrs: {
                            target: "_blank",
                            rel: "noopener noreferer",
                            title: _vm.fileName,
                            filename: _vm.fileName,
                            downloadServicePath:
                              _vm.downloadMetadata.downloadServicePath,
                            queryParams: _vm.downloadMetadata.queryParams
                          }
                        }),
                        _c(
                          "span",
                          { attrs: { title: "Vorschau der Datei" } },
                          [
                            _c("PhFilePdf", {
                              staticClass: "ml-1 clickable",
                              attrs: { size: 24 },
                              on: { click: _vm.fetchPDF }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.cause
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("Hinweis")
                    ]),
                    _c("div", { staticClass: "card-row-value" }, [
                      _vm._v(_vm._s(_vm.cause))
                    ])
                  ])
                : _vm._e(),
              _vm.foreignName || _vm.foreignVorname
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("Kunde")
                    ]),
                    _c("div", { staticClass: "card-row-value" }, [
                      _vm._v(
                        _vm._s(_vm.foreignName) +
                          " " +
                          _vm._s(_vm.foreignVorname)
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.foreignBirthName
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("Kundengeburtsname")
                    ]),
                    _c("div", { staticClass: "card-row-value" }, [
                      _vm._v(_vm._s(_vm.foreignBirthName))
                    ])
                  ])
                : _vm._e(),
              _vm.foreignAddress
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("Adresse")
                    ]),
                    _c("div", { staticClass: "card-row-value" }, [
                      _vm._v(_vm._s(_vm.foreignAddress))
                    ])
                  ])
                : _vm._e(),
              _vm.foreignBirthDate
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("Kundengeburtsdatum")
                    ]),
                    _c("div", { staticClass: "card-row-value" }, [
                      _vm._v(_vm._s(_vm.foreignBirthDate))
                    ])
                  ])
                : _vm._e(),
              _vm.foreignPartnernummer
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("Fremdnummer")
                    ]),
                    _c("div", { staticClass: "card-row-value" }, [
                      _vm._v(_vm._s(_vm.foreignPartnernummer))
                    ])
                  ])
                : _vm._e(),
              _vm.insuranceId
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("VSNR")
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-row-value" },
                      [
                        _c("InputField", {
                          ref: "insuranceIdInputField",
                          attrs: { validateUntouched: "" },
                          model: {
                            value: _vm.insuranceId,
                            callback: function($$v) {
                              _vm.insuranceId = $$v
                            },
                            expression: "insuranceId"
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.damageNumber
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("Schaden-Nr.")
                    ]),
                    _c("div", { staticClass: "card-row-value" }, [
                      _vm._v(_vm._s(_vm.damageNumber))
                    ])
                  ])
                : _vm._e(),
              _vm.licensePlateNumber
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("KFZ")
                    ]),
                    _c("div", { staticClass: "card-row-value" }, [
                      _vm._v(_vm._s(_vm.licensePlateNumber))
                    ])
                  ])
                : _vm._e(),
              _vm.referenceNumber
                ? _c("div", { staticClass: "card-row" }, [
                    _c("div", { staticClass: "card-row-label" }, [
                      _vm._v("Referenz")
                    ]),
                    _c("div", { staticClass: "card-row-value" }, [
                      _vm._v(_vm._s(_vm.referenceNumber))
                    ])
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "item" }, [
              _vm._m(1),
              _c("div", { staticClass: "card-row" }, [
                _c("div", { staticClass: "card-row-label" }, [
                  _vm._v("Kategorie")
                ]),
                _c(
                  "div",
                  { staticClass: "card-row-value" },
                  [
                    _c("ComboBox", {
                      attrs: { values: _vm.categories },
                      model: {
                        value: _vm.comboCategory,
                        callback: function($$v) {
                          _vm.comboCategory = $$v
                        },
                        expression: "comboCategory"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "card-row" }, [
                _c("div", { staticClass: "card-row-label" }, [
                  _vm._v("Interne Nummer "),
                  _c(
                    "span",
                    { attrs: { title: _vm.interneNummerInfo } },
                    [
                      _c("PhInfo", { staticClass: "ml-1", attrs: { size: 24 } })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "card-row-value" },
                  [
                    _c("InputField", {
                      ref: "mscInternalIdInputField",
                      attrs: { id: "mscInternalId", validateUntouched: "" },
                      model: {
                        value: _vm.mscInternalId,
                        callback: function($$v) {
                          _vm.mscInternalId = $$v
                        },
                        expression: "mscInternalId"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "card-row" }, [
                _c(
                  "div",
                  { staticClass: "card-row-label" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: { isPrimary: "" },
                        on: { click: _vm.advancedSearch }
                      },
                      [_vm._v("Erweiterte Suche")]
                    ),
                    _c(
                      "span",
                      { attrs: { title: _vm.erweiterteSucheInfo } },
                      [
                        _c("PhInfo", {
                          staticClass: "ml-1",
                          attrs: { size: 24 }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "card-row-value" },
                  [
                    _c("ComboBox", {
                      attrs: { values: _vm.gesellschaftOptionsMitDefault },
                      model: {
                        value: _vm.comboGesellschaft,
                        callback: function($$v) {
                          _vm.comboGesellschaft = $$v
                        },
                        expression: "comboGesellschaft"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "card-row" }, [
                _c(
                  "div",
                  { staticClass: "card-row-value" },
                  [
                    _c("InputToggleSwitch", {
                      attrs: {
                        label: _vm.prfx + "-Versicherungsnummer übernehmen",
                        inLineLabel: ""
                      },
                      model: {
                        value: _vm.transferInsuranceNumber,
                        callback: function($$v) {
                          _vm.transferInsuranceNumber = $$v
                        },
                        expression: "transferInsuranceNumber"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "card-row" }, [
                _c(
                  "div",
                  { staticClass: "card-row-value" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          isPrimary: "",
                          disabled: !_vm.mscInternalId,
                          isSmall: ""
                        },
                        on: { click: _vm.buttonVersicherungsdokumentZuordnen }
                      },
                      [_vm._v(" Versicherungsdokument zuordnen ")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        attrs: { isSecondary: "", isSmall: "" },
                        on: { click: _vm.searchInsurances }
                      },
                      [_vm._v(" Versicherungssuche ")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        attrs: { isSecondary: "", isSmall: "" },
                        on: {
                          click: function($event) {
                            return _vm.buttonNewCustomer(false)
                          }
                        }
                      },
                      [_vm._v(" Neukunden anlegen ")]
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm.showBiproCard
              ? _c("div", { staticClass: "item" }, [
                  _vm._m(2),
                  _vm.biproFileName
                    ? _c("div", { staticClass: "card-row" }, [
                        _c("div", { staticClass: "card-row-label" }, [
                          _vm._v("Dateiname")
                        ]),
                        _c("div", { staticClass: "card-row-value" }, [
                          _vm._v(_vm._s(_vm.biproFileName))
                        ])
                      ])
                    : _vm._e(),
                  _vm.biproForeignName
                    ? _c("div", { staticClass: "card-row" }, [
                        _c("div", { staticClass: "card-row-label" }, [
                          _vm._v("Kundenname")
                        ]),
                        _c("div", { staticClass: "card-row-value" }, [
                          _vm._v(_vm._s(_vm.biproForeignName))
                        ])
                      ])
                    : _vm._e(),
                  _vm.biproForeingVorname
                    ? _c("div", { staticClass: "card-row" }, [
                        _c("div", { staticClass: "card-row-label" }, [
                          _vm._v("Kundenvorname")
                        ]),
                        _c("div", { staticClass: "card-row-value" }, [
                          _vm._v(_vm._s(_vm.biproForeingVorname))
                        ])
                      ])
                    : _vm._e(),
                  _vm.biproForeignGeburtsname
                    ? _c("div", { staticClass: "card-row" }, [
                        _c("div", { staticClass: "card-row-label" }, [
                          _vm._v("Kundengeburtsname")
                        ]),
                        _c("div", { staticClass: "card-row-value" }, [
                          _vm._v(_vm._s(_vm.biproForeignGeburtsname))
                        ])
                      ])
                    : _vm._e(),
                  _vm.biproForeignAnshrift
                    ? _c("div", { staticClass: "card-row" }, [
                        _c("div", { staticClass: "card-row-label" }, [
                          _vm._v(" Kundenanschrift")
                        ]),
                        _c("div", { staticClass: "card-row-value" }, [
                          _vm._v(_vm._s(_vm.biproForeignAnshrift))
                        ])
                      ])
                    : _vm._e(),
                  _vm.biproForeignGeburtsdatum
                    ? _c("div", { staticClass: "card-row" }, [
                        _c("div", { staticClass: "card-row-label" }, [
                          _vm._v("Kundengeburtsdatum")
                        ]),
                        _c("div", { staticClass: "card-row-value" }, [
                          _vm._v(_vm._s(_vm.biproForeignGeburtsdatum))
                        ])
                      ])
                    : _vm._e(),
                  _vm.biproMSCCategory
                    ? _c("div", { staticClass: "card-row" }, [
                        _c("div", { staticClass: "card-row-label" }, [
                          _vm._v(" MSC Kategorie")
                        ]),
                        _c("div", { staticClass: "card-row-value" }, [
                          _vm._v(_vm._s(_vm.biproMSCCategory))
                        ])
                      ])
                    : _vm._e(),
                  _vm.biproVersicherungsnummer
                    ? _c("div", { staticClass: "card-row" }, [
                        _c("div", { staticClass: "card-row-label" }, [
                          _vm._v("Versicherungsnummer")
                        ]),
                        _c("div", { staticClass: "card-row-value" }, [
                          _vm._v(_vm._s(_vm.biproVersicherungsnummer))
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.rows.length
                ? _c("BigTable", {
                    attrs: {
                      selectable: "",
                      title: "Vorschläge",
                      tableId: "7020d022-7380-4209-9afd-2c0ac46e0ce2",
                      headers: _vm.insuranceHeaders,
                      rows: _vm.rows,
                      page: _vm.page,
                      sortingState: _vm.sortingState,
                      headerActions: _vm.tableActions,
                      initialPageSize: _vm.minNumRows
                    },
                    on: {
                      onClickHeader: _vm.onClickHeader,
                      selectedRow: _vm.onRowSelection,
                      selectedRows: _vm.onAllRowsSelection,
                      "click-link-kundennr": _vm.openCustomerNewTab,
                      "click-icon-ICON": _vm.actionAssign,
                      "click-icon-SCHAEDEN": function($event) {
                        return _vm.actionContract($event, "schadensmeldungen")
                      },
                      "click-icon-KUNDEN_BEARBEITEN_OEFFNEN":
                        _vm.openCustomerNewTab,
                      "action-CONTRACT": _vm.actionContract,
                      "action-NEW_CONTRACT": _vm.openCustomerNewInsurance,
                      "action-KUNDEN_BEARBEITEN_OEFFNEN":
                        _vm.openCustomerNewTab,
                      "action-TICKET_NEED_VERTRAG": _vm.sendMaklerTicket,
                      "headerAction-UNITE": _vm.actionUnite
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "insuranceId",
                          fn: function(row) {
                            return [
                              row.insuranceId
                                ? _c(
                                    "div",
                                    { class: { green: row.insuranceId.green } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(row.insuranceId.text) + " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "name",
                          fn: function(row) {
                            return [
                              _c("div", { class: { green: row.name.green } }, [
                                _vm._v(" " + _vm._s(row.name.text) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "vorname",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.vorname.green } },
                                [_vm._v(" " + _vm._s(row.vorname.text) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "street",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.street.green } },
                                [_vm._v(" " + _vm._s(row.street.text) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "plz",
                          fn: function(row) {
                            return [
                              _c("div", { class: { green: row.plz.green } }, [
                                _vm._v(" " + _vm._s(row.plz.text) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "ort",
                          fn: function(row) {
                            return [
                              _c("div", { class: { green: row.ort.green } }, [
                                _vm._v(" " + _vm._s(row.ort.text) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "birthdate",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.birthdate.green } },
                                [_vm._v(" " + _vm._s(row.birthdate.text) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "gesellnr",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.gesellnr.green } },
                                [_vm._v(" " + _vm._s(row.gesellnr.text) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      230661966
                    )
                  })
                : _c("NoData", { attrs: { title: "Vorschläge", noIcon: "" } })
            ],
            1
          )
        : _vm._e(),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "box__container" },
            [_c("GhostLoading", { attrs: { type: "table" } })],
            1
          )
        : _vm._e(),
      _vm.uniteInsurance1 && _vm.uniteInsurance2
        ? _c("UniteContracts", {
            attrs: {
              insurance1: _vm.uniteInsurance1,
              insurance2: _vm.uniteInsurance2
            },
            on: {
              close: function($event) {
                _vm.uniteInsurance1 = null
                _vm.uniteInsurance2 = null
              }
            }
          })
        : _vm._e(),
      _c("PreviewPDF", {
        ref: "resizablePopup",
        attrs: {
          saveLastPosition: false,
          startPosition: "BOTTOM_RIGHT",
          initialHeight: 75,
          initialWidth: 35,
          sizeIsPercentage: "",
          pdfUrl: _vm.pdfUrl,
          id: "c5ad9400-c09d-4258-ac12-6eaa8af8ae27"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-title" }, [_vm._v(" Details ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-title" }, [_vm._v(" Vertragsdokumente ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "card-title" }, [_vm._v(" BiPRO Daten ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }