var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showButton
    ? _c(
        "BaseButton",
        {
          attrs: { isClear: _vm.showAsButton, isLink: !_vm.showAsButton },
          on: {
            click: function($event) {
              return _vm.clickPhone()
            }
          }
        },
        [
          _vm.showAsButton
            ? [
                !_vm.disabled
                  ? _c("ph-phone", {
                      attrs: {
                        weight: "fill",
                        size: _vm.size,
                        alt: _vm.ariaDescription
                      }
                    })
                  : _c("ph-phone-x", { attrs: { size: _vm.size } })
              ]
            : [_vm._v(" " + _vm._s(_vm.callButtonLabel) + " ")]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }