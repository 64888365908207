var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Transaktionsdaten",
          actions: _vm.headerActions
        },
        on: {
          "action-CANCEL": _vm.abbrechen,
          "action-COMPLETE": function($event) {
            return _vm.setBgsTransakt("complete_transakt", 0)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container d-lg-none col-12" },
        [
          _c("Stepper2", {
            ref: "refStepper2",
            attrs: {
              stepType: "step",
              visible: true,
              stepperName: "BegleitscheinData",
              selectedStepKey: _vm.selectedStepKey,
              stepperService: _vm.steps
            },
            on: {
              setStep: function($event) {
                return _vm.setStep($event)
              }
            }
          })
        ],
        1
      ),
      _vm.hinweise.length
        ? _c("BaseCollapsable", {
            staticClass: "box__container",
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c(
                        "span",
                        {
                          staticClass: "box__title",
                          class: { "fc-form-danger": _vm.blinkHinweis }
                        },
                        [_vm._v(" " + _vm._s(_vm.hinweisTitle()) + " ")]
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-block" },
                        _vm._l(_vm.hinweise, function(text, idx) {
                          return _c("div", { key: idx }, [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.sanitize(text))
                              }
                            })
                          ])
                        }),
                        0
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3221447229
            ),
            model: {
              value: _vm.showHinweis,
              callback: function($$v) {
                _vm.showHinweis = $$v
              },
              expression: "showHinweis"
            }
          })
        : _vm._e(),
      _c("ContentWithStepper", {
        attrs: { stepperCollapsed: _vm.stepperCollapsed },
        scopedSlots: _vm._u([
          {
            key: "stepper",
            fn: function() {
              return [
                _c(
                  "BoxContainer",
                  [
                    _c("VerticalStepper", {
                      attrs: {
                        stepType: "tab",
                        visible: true,
                        stepperName: "BegleitscheinTransakt",
                        selectedStepKey: _vm.selectedStepKey,
                        stepperService: _vm.steps,
                        showCollapse: true
                      },
                      on: {
                        setStep: function($event) {
                          return _vm.setStep($event)
                        },
                        collapsed: function($event) {
                          _vm.stepperCollapsed = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c("ComponentList", {
                      ref: "components",
                      attrs: {
                        components: _vm.fields,
                        data: _vm.values,
                        comboboxValues: _vm.combos,
                        warnings: {},
                        foreignCurrencies: _vm.foreignCurrencies,
                        disabled: !_vm.isEditable
                      },
                      on: { change: _vm.updateValue, isValid: _vm.setValid }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "box__container antrag-bottom-buttons-container row"
                  },
                  [
                    !_vm.hasOnlyOneStep && _vm.selectedStepKey !== "TRANSAKT"
                      ? _c(
                          "div",
                          { staticClass: "col-12 my-1 col-sm-auto" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: { isBlock: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.setStep("TRANSAKT")
                                  }
                                }
                              },
                              [_vm._v("Zurück")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.hasOnlyOneStep && _vm.selectedStepKey !== "STEUER"
                      ? _c(
                          "div",
                          { staticClass: "col-12 my-1 col-sm-auto" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: { isBlock: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.setStep("STEUER")
                                  }
                                }
                              },
                              [_vm._v("Weiter")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }