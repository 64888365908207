<template>
    <div>
        <div class="mb-5">
            <InputToggleSwitch 
                v-model="djeAktiv" 
                inLineLabel
                label="Anlage in Managed Depot erfassen" 
                :disabled="true"
            />
        </div>
        <InputRadioBoxGroup 
            v-model="djeKontakt" 
            :validateUntouched="true"
            :disabled="isDisabled || !isZusatzDJEAktiv"
            :values="values"
            @input="updateStore('zusatzDJE', 'djeKontakt', $event)"/>
        <InputCheckBoxGroup
            v-model="form"
            :disabled="isDisabled || !isZusatzDJEAktiv || djeKontakt !== '1'"
            title="Zulagenberechtigung bzw. Interesse an der Nutzung"
            :values="[ 
                { label: 'per Telefon', path: 'einwilligungTel' },
                { label: 'per Fax', path: 'einwilligungFax' }, 
                { label: 'per E-Mail', path: 'einwilligungEmail' },
                { label: 'per Post', path: 'einwilligungPost' },
                { label: 'per Kundenapp', path: 'einwilligungApp' },
            ]"
            @checkBoxToggle="updateStoreCheckBoxGroup('zusatzDJE', $event)"/>
    </div>
</template>

<script>
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch';

export default {
    mixins: [anlegerprofilMixin],
    components: {
        InputRadioBoxGroup,
        InputCheckBoxGroup,
        InputToggleSwitch,
    },
    data() {
        return {
            djeAktiv: false,
            djeKontakt: '',
            form: {
                einwilligungTel: false,
                einwilligungFax: false,
                einwilligungEmail: false,
                einwilligungPost: false,
                einwilligungApp: false,
            },
            values: [{label: 'Ich möchte nicht durch meinen Vermittler/Berater in telefonischer-, schriftlicher-, elektronischer oder anderer Form kontaktiert werden.', value: '0'}, 
            { label: 'Ich/wir wünsche/n den Kontakt', value: '1'}]
        }
    },
    mounted() {
        this.createForm();
    },
    methods: {
        createForm() {
            if (this.data?.zusatzDJE) {
                this.djeAktiv = this.data.zusatzDJE.djeAktiv;
                this.djeKontakt = this.data.zusatzDJE.djeKontakt;
                this.form.einwilligungTel = this.data.zusatzDJE.einwilligungTel || false;
                this.form.einwilligungFax = this.data.zusatzDJE.einwilligungFax || false;
                this.form.einwilligungEmail = this.data.zusatzDJE.einwilligungEmail || false;
                this.form.einwilligungPost = this.data.zusatzDJE.einwilligungPost || false;
                this.form.einwilligungApp = this.data.zusatzDJE.einwilligungApp || false;
            }
        },
    }
}
</script>

<style>

</style>