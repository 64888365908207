var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.useParentOptionMenu
        ? _c("OptionMenu", {
            attrs: {
              id: _vm.$appNavigation.parentOptionMenuId,
              defaultMenu: _vm.$appNavigation.parentOptionMenu
            }
          })
        : _c("OptionMenu", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              defaultMenu: _vm.$appNavigation.currentOptionMenu
            }
          }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.aktDeepTitle
        }
      }),
      _vm.prevDeepTitle
        ? _c("div", { staticClass: "titel box__container" }, [
            _c("a", { on: { click: _vm.toPreviusLevel } }, [
              _vm._v("zurück zu " + _vm._s(_vm.prevDeepTitle))
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { ref: "divTableWidth", staticClass: "m-0 p-0" }),
          _vm.viewData.loading
            ? [
                _c(
                  "GhostLoading",
                  [_c("Block", { attrs: { height: "300" } })],
                  1
                )
              ]
            : _vm.displayTable
            ? [
                _vm.shouldShowBigTable || _vm.shouldShowSmallTable
                  ? [
                      _vm.shouldShowBigTable
                        ? _c("BigTable", {
                            attrs: {
                              title: _vm.tableTitle,
                              tableId: _vm.tableId,
                              headers: _vm.bigTableHeaders,
                              rows: _vm.bigTableRows,
                              page: _vm.bigTablepage,
                              sortingState: _vm.sortingState,
                              initialPageSize: _vm.minNumRows,
                              footerMetadata: _vm.footerMetadata,
                              headerActions: _vm.tableActions
                            },
                            on: {
                              onClickHeader: _vm.onClickHeader,
                              "headerAction-EXPORT-PDF": function($event) {
                                return _vm.getExportBigTable("pdf")
                              },
                              "headerAction-EXPORT-XLS": function($event) {
                                return _vm.getExportBigTable("xls")
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "key0",
                                  fn: function(row) {
                                    return [
                                      _c("div", { staticClass: "ellipsis" }, [
                                        _vm.viewData.table.records[
                                          row.rowIndex
                                        ][0].load
                                          ? _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toNextLevel(
                                                      row.rowIndex
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.viewData.table.records[
                                                      row.rowIndex
                                                    ][_vm.ixCell0].value
                                                  )
                                                )
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.viewData.table.records[
                                                    row.rowIndex
                                                  ][_vm.ixCell0].value
                                                )
                                              )
                                            ])
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2999347208
                            )
                          })
                        : _vm._e(),
                      _vm.shouldShowSmallTable
                        ? _c("Table", {
                            ref: "viewstatistik",
                            staticClass: "table-container",
                            attrs: {
                              tableId: _vm.tableId,
                              title: _vm.tableTitle,
                              headers: _vm.headers,
                              rows: _vm.rows,
                              scrollHorizontally: true,
                              exportConfig: _vm.exportConfig,
                              rowId: "index",
                              rowsPerPage: _vm.pageRows,
                              hidePagination: _vm.hidePagination
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "key0",
                                  fn: function(ref) {
                                    var key0 = ref.key0
                                    return [
                                      _c("div", { staticClass: "ellipsis" }, [
                                        _vm.viewData.table.records[key0][0].load
                                          ? _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toNextLevel(key0)
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.viewData.table.records[
                                                      key0
                                                    ][_vm.ixCell0].value
                                                  )
                                                )
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.viewData.table.records[
                                                    key0
                                                  ][_vm.ixCell0].value
                                                )
                                              )
                                            ])
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1051450691
                            )
                          })
                        : _vm._e(),
                      _vm.count && _vm.$isSmallScreen
                        ? _c(
                            "div",
                            { staticClass: "table-pagination" },
                            [
                              _c("PageRowCount", {
                                attrs: {
                                  displayedItems: _vm.pageRows,
                                  totalRows: _vm.rowCount
                                }
                              }),
                              _vm.pageRows < _vm.rowCount
                                ? _c("Pagination", {
                                    attrs: {
                                      totalPages: _vm.count,
                                      currentPage: _vm.page
                                    },
                                    on: { pagechanged: _vm.onPageChange }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  : _c("NoData")
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "BaseModal",
        {
          ref: "modalDownloadPin",
          attrs: {
            modalTitle: _vm.fileMitPIN
              ? "Download PIN geschützte Datei"
              : _vm.tempFileName,
            showConfirmButton: true,
            labelButtonConfirm: "Herunterladen",
            labelButtonCancel: "Abbrechen",
            autoClose: false
          },
          on: { onConfirmButton: _vm.downloadPinFile, close: _vm.onCloseModal }
        },
        [
          _c("p", [
            _vm._v(
              "Diese Statistik ist zu umfangreich um dargestellt zu werden."
            ),
            _c("br"),
            _vm._v(" Sie können aber eine CSV-Datei herunterladen. ")
          ]),
          _vm.fileMitPIN
            ? _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.downloadPinFile()
                    }
                  }
                },
                [
                  _c("InputField", {
                    attrs: {
                      label: "Bitte geben Sie die PIN ein",
                      placeholder: "Pin"
                    },
                    model: {
                      value: _vm.tempFilePin,
                      callback: function($$v) {
                        _vm.tempFilePin = $$v
                      },
                      expression: "tempFilePin"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "fc-form-danger" }, [
            _vm._v(" " + _vm._s(_vm.errorPinFile) + " ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }