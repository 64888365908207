import { BROKER_LEVEL_ROLES, BROKER_STRUCTURE_ROLES, EMPLOYEE_ROLES, ROLES, VIEW_ROLES } from '@/router/roles'
import { redirectToFirstAllowedChild, } from '@/router';

import versicherungRoutes from './versicherung'
import vermoegenRoutes from './vermoegen'
import berichteRoutes from './vermogensubersicht/berichte'

import limitRoutes from './limit'
import MSC_NEWS_TYPES from '@/store/mscnews/types';
import store from '@/store';

export default [
  {
    path: 'tutorial-recorded-items',
    component: () => import(/* webpackChunkName: "recordedItems" */ '@/views/RecordedItems.vue'),
    children: []
  },   
  {
    path: 'broker-overview',
    component: () => import(/* webpackChunkName: "broker-overview" */ '@/views/home/BrokerOverview.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER]
      },
    }
  },
  {
    path: 'intern-overview',
    component: () => import(/* webpackChunkName: "broker-overview" */ '@/views/home/InternOverview.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_INTERN]
      },
    }
  },
  {
    path: 'steckbrief-view',
    component: () => import(/* webpackChunkName: "steckbriefchunk" */ '@/components/steckbrief/Steckbrief.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
      },
    }  
  },
  {
    path: 'customer-overview',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER]
      },
    },
    component: () => import(/* webpackChunkName: "dashboardgrid" */ '@/views/home/CustomerOverview.vue'),
  },
  {
    path: 'freistellungsauftrag',
    component: () => import(/* webpackChunkName: "freistellungsauftrag" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]],
        denied: [ROLES.FA],
      }
    },
    children:[
      {
        path: '',
        component: () => import(/* webpackChunkName: "freistellungsauftrag" */ '@/views/home/Freistellungsauftraege.vue'),
      },
      {
        path: 'auftragBearbeiten',
        component: () => import(/* webpackChunkName: "auftragBearbeiten" */ '@/views/home/AuftragBearbeiten.vue'),
      },
    ]
  }, 

  {
    path: 'funds-chart',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER],
      },
    },
    component: () => import(/* webpackChunkName: "funds-chart" */ '@/views/home/FundsChart.vue'),
  },
  ...vermoegenRoutes,
  ...berichteRoutes,
  {
    path: 'verbindlichkeiten',
    component: () => import(/* webpackChunkName: "base_verbindlichkeiten_view" */ '@/views/verbindlichkeiten/BaseVerbindlichkeitenView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN]],
      }
    },
    children: [
      {
        path: '',
        redirect: redirectToFirstAllowedChild,
      },
      {
        path: 'credit',
        component: () => import(/* webpackChunkName: "credit" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "credit-search" */ '@/views/home/Credit.vue')
          },
          {
            path: 'edit/:creditId',
            redirect: 'edit/:creditId/vertragsdaten',
          },
          {
            path: 'edit/:creditId/:step',
            meta: {
              roles: {
                allowed: [
                  [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS], 
                  [VIEW_ROLES.VIEW_CUSTOMER, ROLES.ALLOW_EDIT_CREDIT],
                ]
              },
            },
            component: () => import(/* webpackChunkName: "credit-edit" */ '@/views/home/credit/CreditEdit.vue')
          },
          {
            path: 'overview/:creditId',
            component: () => import(/* webpackChunkName: "credit-edit" */ '@/views/home/credit/CreditOverview.vue')
          },          
        ],
      },
      {
        path: 'weitere-verbindlichkeiten',
        component: () => import(/* webpackChunkName: "sprod-weitere-verbindlichkeiten-base" */ '@/views/otherProducts/BaseOtherProducts.vue'),
        children: [
          {
            path: '',
            redirect: 'search',
          },
          {
            path: 'search',
            component: () => import(/* webpackChunkName: "sprod-weitere-verbindlichkeiten-search" */ '@/components/otherProducts/WeitereVerbindlichkeitenSearch.vue'),
            props: {
              type: 'WeitereVerbindlichkeiten',
            },
          },
          {
            path: 'edit/:sprodId',
            redirect: 'edit/:sprodId/produktdaten',
          },
          {
            path: 'edit/:sprodId/:step',
            component: () => import(/* webpackChunkName: "sprod-weitere-verbindlichkeiten-view" */ '@/components/otherProducts/OtherProductView.vue'),
            props: {
              type: 'WeitereVerbindlichkeiten',
            },
          },
          {
            path: 'overview/:sprodId',
            component: () => import(/* webpackChunkName: "sprod-immobilien-overview" */ '@/components/otherProducts/OtherProductOverview.vue'),
            props: {
              type: 'WeitereVerbindlichkeiten',
            },
          },            
        ],
      },
    ]
  },
  {
    path: 'vermogensubersicht/wertpapiere',
    component: () => import(/* webpackChunkName: "wertpapiere" */ '@/components/depotpositions/Depotpositions.vue'),
  },
  {
    path: 'vermogensubersicht/wertpapiere/diagramme',
    component: () => import(/* webpackChunkName: "diagramme" */ '@/views/vermogen/WertpapiereDiagramme.vue'),
  },
  ...versicherungRoutes,    
  {
    path: 'account',
    component: () => import(/* webpackChunkName: "account-base-home"*/ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER],
      },
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "account" */ '@/views/home/AccountHome.vue'),
      },
      {
        path: 'depotstammdaten/:kontonummer/:bgsNr',
        component: () => import(/* webpackChunkName: "account_info" */ '@/views/home/Depotstammdaten.vue'),
      },
    ],
  },
  {
    path: 'multibanking-account',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER, ROLES.MULTIBANKING, ROLES.FK], // finance-cloud.de
          [VIEW_ROLES.VIEW_CUSTOMER, ROLES.MULTIBANKING, ROLES.FA] // service.finanzadmin.at
        ],
      }
    },
    children: [
      {
        path: '',
        redirect: 'overview',
      },
      {
        path: 'overview',
        name: 'kontoverwaltung',
        component: () => import(/* webpackChunkName: "multibanking-account" */ '@/views/home/MultibankingAccount.vue'),
      },
      {
        path: 'kategorien',
        name: 'kategorien',
        component: () => import(/* webpackChunkName: "kategorien" */ '@/views/multiBanking/Kategorien.vue'),
        meta: {
          roles: {
            allowed: [ROLES.MULTIBANKING_DATA_ALLOWED],
          }
        },
      },
      {
        path: 'balance-per-period',
        name: 'balance-per-period',
        component: () => import(/* webpackChunkName: "kontoverlauf" */ '@/views/multiBanking/Kontoverlauf.vue'),
        meta: {
          roles: {
            allowed: [ROLES.MULTIBANKING_DATA_ALLOWED],
          }
        },
      },
      {
        path: 'einnahmen-und-ausgaben',
        name: 'einnahmen-und-ausgaben',
        component: () => import(/* webpackChunkName: "einnahmen-und-ausgaben" */ '@/views/multiBanking/EinnahmenAusgaben.vue'),
        meta: {
          roles: {
            allowed: [[ROLES.MULTIBANKING_DATA_ALLOWED]],
          },
        },
      },
      {
        path: 'monatliches-sparpotential',
        name: 'monatliches-sparpotential',
        component: () => import(/* webpackChunkName: "monatliches-sparpotential" */ '@/views/multiBanking/MonatlichesSparpotential.vue'),
        meta: {
          roles: {
            allowed: [[ROLES.MULTIBANKING_DATA_ALLOWED]],
          },
        },
      },
      {
        path: 'contracts',
        name: 'contracts',
        component: () => import(/* webpackChunkName: "contracts" */ '@/views/multiBanking/Contracts.vue'),
        meta: {
          roles: {
            allowed: [[ROLES.MULTIBANKING_DATA_ALLOWED]],
          },
        },
      },
    ]
  },
  {
    path: 'immobilien',
    component: () => import(/* webpackChunkName: "sprod-immobilien-base" */ '@/views/otherProducts/BaseOtherProducts.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER]
      },
    },
    children: [
      {
        path: '',
        redirect: 'search',
      },
      {
        path: 'search',
        component: () => import(/* webpackChunkName: "sprod-immobilien-search" */ '@/components/otherProducts/OtherProductsSearch.vue'),
        props: {
          type: 'Immobilie',
        },
      },
      {
        path: 'edit/:sprodId',
        redirect: 'edit/:sprodId/produktdaten',
      },
      {
        path: 'edit/:sprodId/:step',
        component: () => import(/* webpackChunkName: "sprod-immobilien-view" */ '@/components/otherProducts/OtherProductView.vue'),
        props: {
          type: 'Immobilie',
        },
      },
      {
        path: 'overview/:sprodId',
        component: () => import(/* webpackChunkName: "sprod-immobilien-overview" */ '@/components/otherProducts/OtherProductOverview.vue'),
        props: {
          type: 'Immobilie',
        },
      },       
    ],
  },
  {
    path: 'depotpositionenedit',
    component: () => import(/* webpackChunkName: "depotpositionenedit" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN], 
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN], 
          [VIEW_ROLES.VIEW_INTERN],
        ],
      },
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "depotpositionenedit" */ '@/views/vermogen/DepotpositionenEdit.vue'),
      },
      {
        path: 'begleitscheinsteps/:bgsnr/:stepKey',
        component: () => import(/* webpackChunkName: "depotpositionenedit" */ '@/components/begleitscheine/BegleitscheinSteps.vue'),
        props: true,
      },
      {
        path: 'transaktionen-uebertragen/:bgsnr',
        component: () => import(/* webpackChunkName: "depotpositionenedit" */ '@/views/vermogen/TransaktionenUebertragen.vue'),
        props: true,
      },
    ],
  },
  {
    path: 'fondsvergleich',
    component: () => import(/* webpackChunkName: "FondsVergleich" */ '@/views/beratung/FondsVergleich.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER]
      }
    }
  },
  {
    // TODO: this has only been added to test the FotoButton component
    path: 'pdf-upload',
    component: () => import('@/views/beratung/FotoUpload.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER],
        ]
      }
    }  
  },
  {
    path: 'antragsliste-versicherungen',
    component: () => import(/* webpackChunkName: "AntragslisteVersicherungen" */ '@/views/versicherungen/AntragslisteVersicherungen.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN],
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN],
        ]
      },
    },
  },
  {
    path: 'maklerauftraege',
    component: () => import(/* webpackChunkName: "Maklerauftraege" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN],
          [VIEW_ROLES.VIEW_INTERN, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]
        ],
        denied: [ROLES.FA]
      },
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "Maklerauftraege" */ '@/views/versicherungen/Maklerauftraege.vue'),
      },
      {
        path: 'aenderungslog',
        component: () => import(/* webpackChunkName: "Aenderungslog" */ '@/views/versicherungen/Aenderungslog.vue'),
      },
      {
        path: 'emailliste',
        component: () => import(/* webpackChunkName: "EMailliste" */ '@/views/versicherungen/MaklerauftragEMailliste.vue'),
      },  

    ]
  },

  {
    path: 'pruefstatus-change',
    component: () => import(/* webpackChunkName: "PruefstatusChange" */ '@/views/versicherungen/PruefstatusChange.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN],
          [VIEW_ROLES.VIEW_INTERN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]
        ]
      },
    },
  },
  {
    path: 'sso',
    name: 'sso',
    component: () => import(/* webpackChunkName: "sso" */ '@/views/core/sso/SsoLoggedIn.vue'),
  },
  {
    path: 'sso-invite',
    name: 'sso-invite',
    component: () => import(/* webpackChunkName: "sso" */ '@/views/core/sso/SsoInvite.vue'),
  },
  {
    path: 'limit',
    meta: {
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_CUSTOMER_ONLY]]
      },
    },
    component: () => import(/* webpackChunkName: "LimitOverview" */ '@/views/BaseRouterView.vue'),
    children: [...limitRoutes]
  },
  {
    path: 'fk-events',
    name: 'fk-events',
    meta: {
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER, ROLES.SHOW_EVENTS]]
      },
    },
    component: () => import(/* webpackChunkName: "fk-events" */ '@/views/home/FkEvents.vue'),
  },
  {
    path: 'home',
    name: 'home',
    meta: {
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_GESELLSCHAFT]]
      },
    },
    beforeEnter(to, from, next) {
      let url = store?.getters?.[MSC_NEWS_TYPES.GETTERS.MSC_HOME_LINK]?.mscLink

      const HAS_PROTOCOL = /(https?):\/\//gi

      if (url && !HAS_PROTOCOL.test(url)) {
        url = `//${url}`
      }

      if (url) window.open(url)
      next(false);
    }
  },
  {
    path: 'unterdepots',
    component: () => import(/* webpackChunkName: "virtual-unterdepots" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]],
        denied: [ROLES.SUPER_CUSTOMER]
      } 
    },
    children: [
      {
        path: '',
        redirect: 'overview',
      },
      {
        path: 'overview',
        component: () => import(/* webpackChunkName: "virtual-unterdepots" */ '@/views/vermogen/VirtualUnterdepots.vue'),
      },
      {
        path: 'subdepot/:id',
        component: () => import(/* webpackChunkName: "subdepot" */ '@/components/virtualsubdepots/Subdepot.vue'),
      },
    ],
  },
  {
    path: 'depot-gebuehr',
    component: () => import(/* webpackChunkName: "vermogensubersicht" */ '@/views/vermogen/DepotSGebuehrRabatt.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]
      } 
    },
  },
  {
    path: 'kennzahlen',
    component: () => import(/* webpackChunkName: "vermogensubersicht" */ '@/views/vermogen/Kennzahlen.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]]
      } 
    },
  },
  {
    path: 'schaden-suche',
    component: () => import(/* webpackChunkName: "SchadenSuche" */ '@/views/schaden-suche/SchadenSuche.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER],
          [VIEW_ROLES.VIEW_INTERN], 
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]
        ]
      } 
    },
  },  
  {
    path: 'show-marketing-campaign',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_INTERN]
      },
    },
    component: () => import(/* webpackChunkName: "show-marketing-campaign" */ '@/views/marketingCampaign/ShowMarketingCampaign.vue'),
  },
  {
    path: 'gesellschaftsdaten',
    component: () => import(/* webpackChunkName: "gesellschaftsdaten" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_GESELLSCHAFT]],
      },
    },
    children: [
      {
        path: '',
        redirect: 'daten',
      },
      {
        path: 'daten',
        component: () => import(/* webpackChunkName: "gesell-daten" */ '@/views/gesellschaft/intern/GesellschaftDaten.vue'),
      },
      {
        path: 'ansprechpartnerList',
        component: () => import(/* webpackChunkName: "gesell-aplist" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "gesell-aplist" */ '@/views/gesellschaft/GesellschaftAnsprechpartner.vue'),
          },
          {
            path: 'ansprechpartner/:personId?',
            component: () => import(/* webpackChunkName: "gesell-ap" */ '@/views/gesellschaft/ansprechpartner/AnsprechpartnerMain.vue'),
            children: [
              {
                path: '',
                redirect: 'stammdaten'
              },
              {
                path: 'stammdaten',
                component: () => import(/* webpackChunkName: "personendaten" */ '@/views/gesellschaft/ansprechpartner/AnsprechpartnerPersonenDaten.vue')
              },
            ]
          },
        ],
      },
      {
        path: 'lesezeichen',
        component: () => import(/* webpackChunkName: "gesell-lese" */ '@/views/gesellschaft/intern/GesellschaftLesezeichen.vue'),
        meta: {
          roles: {
            denied: [VIEW_ROLES.VIEW_CUSTOMER_ONLY]
          },
        },
      },
      {
        path: 'antragsbearbeitung',
        component: () => import(/* webpackChunkName: "gesell-antragsbearbeitung" */ '@/views/gesellschaft/intern/GesellschaftAntragsbearbeitung.vue'),
      },
    ],
  },
  {
    path: 'licenses',
    name: 'licenses',
    component: () => import(/* webpackChunkName: "licenses" */ '@/views/home/Licenses.vue'),
  },
  // { not implemented
  //   path: 'notwendige-unterlagen-makler',
  //   meta: {
  //     roles: {
  //       allowed:
  //         [ [ROLES.FK, VIEW_ROLES.VIEW_INTERN, VIEW_ROLES.VIEW_BROKER_AS_INTERN], 
  //         ]
  //     },
  //   },
  //   component: () => import(/* webpackChunkName: "notwendige-unterlagen-by-intern" */ '@/views/home/NotwendigeUnterlagenByIntern.vue'),
  // },
]