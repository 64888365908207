
import LOG_TYPES from '@/store/log/types.js';
import CORE_TYPES from '@/store/core/types.js';
import WEBRTC_TYPES from '@/store/webrtc/types.js';
import { MessageFromClientToPusher } from '@/store/webrtc/utils/signalingService_types.js';
import { logSocketCloseConnection, readThriftSwspPayload, translateAndLogPushSocketMessage, writeThrift } from '@/store/webrtc/sockets/helpers.js';
import { handlePushSocket } from './push-socket-handler.js';

function onOpen({ pushSocket, regData, dispatch, getters }) {
  dispatch(LOG_TYPES.ACTIONS.INFO, "pushSocket opened");

  const dbPrefix = getters[CORE_TYPES.GETTERS.GET_DB_PREFIX];
  const pushSocketId = regData.pushSocketId
  const tMsg = new MessageFromClientToPusher({ dbPrefix, pushSocketId });
  pushSocket.send(writeThrift(tMsg));
}

function onMessage({ event, dispatch, getters, commit, state }) {
  const msg = readThriftSwspPayload(event.data);
  translateAndLogPushSocketMessage(msg, dispatch);
  handlePushSocket({ msg, dispatch, getters, commit, state });
}

function onClose({ event, pushSocket, regData, dispatch, commit }) {
  const timeWaitingForReconnect = regData.tryAgainLater ? (60 + Math.random() * 540) : (10 + Math.random() * 60);

  dispatch(LOG_TYPES.ACTIONS.INFO, { message: "pushSocket closed", event });
  logSocketCloseConnection(dispatch, event);

  pushSocket.onopen = null
  pushSocket.onmessage = null
  pushSocket.onclose = null
  pushSocket.onerror = null
  commit(WEBRTC_TYPES.MUTATIONS.SET_PUSH_SOCKET, null);

  if (event.code !== 1000) {
    dispatch(LOG_TYPES.ACTIONS.WARN, { message: `pushSocket waiting ${timeWaitingForReconnect} seconds to reconnect`, event });
    setTimeout(() => {
      dispatch(WEBRTC_TYPES.ACTIONS.START_PUSH_SOCKET)
    }, timeWaitingForReconnect * 1000)
  }
}

function onError({ error, dispatch }) {
  dispatch(LOG_TYPES.ACTIONS.WARN, { message: `pushSocket error`, error });
}

export async function setupPushSocket({ socketUrl, commit, dispatch, state, getters, regData }) {
  const pushSocket = new WebSocket(socketUrl)

  pushSocket.onopen = () => onOpen({ pushSocket, regData, dispatch, getters });
  pushSocket.onmessage = (event) => onMessage({ event, dispatch, getters, commit, state });
  pushSocket.onclose = (event) => onClose({ event, pushSocket, regData, dispatch, commit });
  pushSocket.onerror = (error) => onError({ error, dispatch });

  await dispatch(WEBRTC_TYPES.ACTIONS.STOP_PUSH_SOCKET, { message: "Close previous socket. A new connection is established" });
  commit(WEBRTC_TYPES.MUTATIONS.SET_PUSH_SOCKET, pushSocket);

  const endCall = () => {
    // these two actions check whether the user is in a call or is being called and end those connections if necessary
    dispatch(WEBRTC_TYPES.ACTIONS.HANG_UP);
    dispatch(WEBRTC_TYPES.ACTIONS.REJECT_CALL);
    // finally, the pushSocket is ended
    dispatch(WEBRTC_TYPES.ACTIONS.STOP_PUSH_SOCKET, { message: 'Browser closed' });
  };
  window.addEventListener('beforeunload', endCall);
}