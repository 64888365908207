<template>
  <div>
    <div class="box__container">
      <div v-if="brokerData.isUntermaklerDesktop || isBrokerBypass">
        <InputCheckboxItem
          v-if="brokerData.isUntermaklerDesktop || isBrokerBypass"
          class="mb-3"
          v-model="brokerData.isPassivMakler"
          label="Passivmakler §34f/h, die Kunden werden betreut von:"
          @input="addBrokerDataEdited('brokerData')"
        />

        <ComboBox
          v-if="brokerData.isUntermaklerDesktop || isBrokerBypass"
          isComponentHalfSize
          :values="brokerData.passivMaklerValues"
          v-model="brokerData.passivMaklerAuswahl"
          @change="
            addBrokerDataEditedCombo(
              'brokerData',
              'passivMaklerAuswahl',
              $event
            )
          "
        />
        <div v-if="brokerData.isUntermaklerDesktop || isBrokerBypass">
          <ph-warning :size="16" class="color-danger mr-2" />Bitte beachten Sie,
          dass bei Passivmaklern alle Courtagen außer Bestandscourtage auf 0
          gesetzt werden.
        </div>
      </div>

      <InputField
        label="Gerichtsstand"
        :isComponentHalfSize="true"
        v-model="brokerData.sonstiges.gerichtsstand"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Zust. Behörde §34c"
        :isComponentHalfSize="true"
        v-model="brokerData.sonstiges.vertragP34CRrgisterbeh"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Zust. Behörde §34d"
        :isComponentHalfSize="true"
        v-model="brokerData.sonstiges.vertragP34DRrgisterbeh"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Zust. Behörde §34f/h"
        :isComponentHalfSize="true"
        v-model="brokerData.sonstiges.vertragP34FHRrgisterbeh"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Zust. Behörde §34i"
        :isComponentHalfSize="true"
        v-model="brokerData.sonstiges.vertragP34IRrgisterbeh"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container">
      <BaseButton @click="gesellschaftenEinstellen"
        >Erlaubte Gesellschaften nach §34d/e und §34f/h einstellen
      </BaseButton>
    </div>
    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach §32"
        :disabled="!brokerData.sonstiges.darfAendern32"
        v-model="brokerData.sonstiges.vertragP32Chk"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="BAK Nummer"
        :isComponentHalfSize="true"
        :disabled="!brokerData.sonstiges.darfAendern32"
        v-model="brokerData.sonstiges.vertrag32RegisterNr"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>

    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach § 34c Abs. 1 Satz 1"
        :disabled="!brokerData.sonstiges.darfAendern34c1"
        v-model="brokerData.sonstiges.vertragPara34cAbs1Satz1"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>

    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach § 34c Abs. 1 Satz 2"
        :disabled="!brokerData.sonstiges.darfAendern34c2"
        v-model="brokerData.sonstiges.vertragPara34cAbs1Satz2"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>

    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach § 34d Absatz 1"
        :disabled="!brokerData.sonstiges.darfAendern34d1"
        v-model="brokerData.sonstiges.vertragPara34d"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Registernummer / IHK Nummer"
        :isComponentHalfSize="true"
        :disabled="!brokerData.sonstiges.darfAendern34d1"
        v-model="brokerData.sonstiges.vertragP34dAbsatz1Registernr"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach § 34d Absatz 2"
        :disabled="!brokerData.sonstiges.darfAendern34e"
        v-model="brokerData.sonstiges.vertragPara34e"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Registernummer / IHK Nummer"
        :isComponentHalfSize="true"
        :disabled="!brokerData.sonstiges.darfAendern34e"
        v-model="brokerData.sonstiges.para34eRegisternummer"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach § 34i"
        :disabled="!brokerData.sonstiges.darfAendern34i"
        v-model="brokerData.sonstiges.vertragPara34i"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Registernummer / IHK Nummer"
        :isComponentHalfSize="true"
        :disabled="!brokerData.sonstiges.darfAendern34i"
        v-model="brokerData.sonstiges.vertragP34iRegisternr"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>

    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach § 34f Abs. 1 Satz 1 Nr. 1"
        :disabled="!brokerData.sonstiges.darfAendern34f1"
        v-model="brokerData.sonstiges.vetragPara34fAbs1Satz1Nr1"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Registernummer / IHK Nummer"
        :isComponentHalfSize="true"
        :disabled="!brokerData.sonstiges.darfAendern34f1"
        v-model="brokerData.sonstiges.vertragP34fRegisternr"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach § 34f Abs. 1 Satz 1 Nr. 2"
        :disabled="!brokerData.sonstiges.darfAendern34f2"
        v-model="brokerData.sonstiges.vetragPara34fAbs1Satz1Nr2"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach § 34f Abs. 1 Satz 1 Nr. 3"
        :disabled="!brokerData.sonstiges.darfAendern34f3"
        v-model="brokerData.sonstiges.vetragPara34fAbs1Satz1Nr3"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>

    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach § 34h Abs. 1 Satz 1 Nr. 1"
        :disabled="!brokerData.sonstiges.darfAendern34h1"
        v-model="brokerData.sonstiges.vertragPara34hAbs1Satz1Nr1"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Registernummer / IHK Nummer"
        :isComponentHalfSize="true"
        :disabled="!brokerData.sonstiges.darfAendern34h1"
        v-model="brokerData.sonstiges.vertragP34hRegisternr"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach § 34h Abs. 1 Satz 1 Nr. 2"
        :disabled="!brokerData.sonstiges.darfAendern34h2"
        v-model="brokerData.sonstiges.vertragPara34hAbs1Satz1Nr2"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container">
      <InputCheckboxItem
        label="Erlaubnis nach § 34h Abs. 1 Satz 1 Nr. 3"
        :disabled="!brokerData.sonstiges.darfAendern34h3"
        v-model="brokerData.sonstiges.vertragPara34hAbs1Satz1Nr3"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>

    <div class="box__container">
      <div class="box__title">Der beratende Makler unterhält gemäß den gesetzlichen Bestimmungen eine Vermögensschadenshaftpflichtversicherung</div>
      <InputField
        label="§34d Vers.-Nr."
        isComponentHalfSize
        v-model="brokerData.sonstiges.vshNr34d"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="bei der"
        isComponentHalfSize
        v-model="brokerData.sonstiges.vshGesell34d"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="§34f Vers.-Nr."
        isComponentHalfSize
        v-model="brokerData.sonstiges.vshNr34f"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="bei der"
        isComponentHalfSize
        v-model="brokerData.sonstiges.vshGesell34f"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="§34i Vers.-Nr."
        isComponentHalfSize
        v-model="brokerData.sonstiges.vshNr34i"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="bei der"
        isComponentHalfSize
        v-model="brokerData.sonstiges.vshGesell34i"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
  </div>
</template>
<script>
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import { mapGetters } from "vuex";
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import CORE_TYPES from "@/store/core/types";
import BaseModal from "@/components/core/BaseModal.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import vermittlerDatenMixin from "@/views/intern/vermittler-daten-mixin";
import { PhWarning } from "phosphor-vue";
import { VIEW_ROLES } from "@/router/roles";

export default {
  mixins: [vermittlerDatenMixin],
  data() {
    return {
      sonstiges: {},
    };
  },
  computed: {
    ...mapGetters({
      brokerData: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isBrokerBypass() {
      return this.hasRoles([[VIEW_ROLES.VIEW_BROKER_AS_INTERN]]);
    },
  },
  mounted() {
    this.getBrokerData();
  },
  components: {
    ComboBox,
    InputCheckboxItem,
    BaseButton,
    BaseModal,
    InputRadioBoxGroup,
    InputField,
    BaseButton,
    PhWarning,
  },
  methods: {
    gesellschaftenEinstellen() {
      this.$router.push({ path: `/intern/configs/makler34f` });
    },
    getBrokerData() {
      this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_BROKER_DATA);
    },
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveChanges().then(() => next());
  },
};
</script>