<template>
  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :id="$appNavigation.currentOptionMenuId">
    </PageHeaderTitleNavigation>
    <BaseModal
      ref="modalCreateProcess"
      modalTitle="Vorgang erstellen"
      labelButtonConfirm="Erstellen"
      :confirmDisabled="!isValid"
      @onCloseButton="navigateBack"
      @onCancelButton="navigateBack"
      @onConfirmButton="createProcess">

      <InputField
        label="Beschreibung"
        v-model="processForm.description"/>

      <ComboBox
        label="Makler Externer Zugang"
        v-model="processForm.maklerExtZugang.id"
        :values="maklerExtZugangList"
        validateUntouched
      />
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/core/BaseModal.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import { mapGetters } from "vuex";
import PROCESS_TYPES from "@/store/process/types";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";

export default {
  name: "ProcessCreateForm",
  mixins: [validator],
  validators: {
    processForm: {
      description: [required("Beschreibung ist erforderlich")],
      maklerExtZugang: {
        id: [required("Makler Externer Zugang ist erforderlich")],
      }
    },
  },
  data: function () {
    return {
      processForm: {
        description: 'Neu Vorgang',
        maklerExtZugang: {
          id: null,
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      maklerExtZugangList: PROCESS_TYPES.GETTERS.ZUGANG_LIST,
    }),
    isValid() {
      return this.validation.updated && this.validation.isValid('processForm');
    },
  },
  methods: {
    async createProcess() {
      if (!this.isValid) {
        return;
      }
      const id = await this.$store.dispatch(PROCESS_TYPES.ACTIONS.SAVE_PROCESS, this.processForm);
      if (id) {
        await this.$router.push({
          path: '/beratung/processes/form',
          query: { id }
        });
      }
    },
    navigateBack() {
      this.$router.go(-1);
    },
    loadInitialData() {
      this.loadAuxiliarData();
    },
    async loadAuxiliarData() {
      await Promise.all([
        this.$store.dispatch(PROCESS_TYPES.ACTIONS.GET_ZUGANG_LIST)
      ])
    },
  },
  components: {
    PageHeaderTitleNavigation,
    InputField,
    ComboBox,
    BaseModal,
  },
  mounted() {
    this.$refs.modalCreateProcess.open();
    this.loadInitialData();
  }
}
</script>
