export const MUTATION_PREFIX = 'MENU: ';
export const ACTIONS_PREFIX = 'MENU_ACTIONS_';
export const GETTERS_PREFIX = 'MENU_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',

    SET_CONFIGURING_MENU: MUTATION_PREFIX + 'SET_CONFIGURING_MENU',
    SET_MENU_CONFIGURED: MUTATION_PREFIX + 'SET_MENU_CONFIGURED',
    SET_FLAT_MENU: MUTATION_PREFIX + 'SET_FLAT_MENU',
    UPDATE_CURRENT_APP_NAVIGATION: MUTATION_PREFIX + 'UPDATE_CURRENT_APP_NAVIGATION',

    SET_PARENTS_MENU_BADGE: MUTATION_PREFIX + 'SET_PARENTS_MENU_BADGE',

    SET_OPTIONS_MENU_BADGE: MUTATION_PREFIX + 'SET_OPTIONS_MENU_BADGE',

    PREPEND_RECENT_MENU_OPENED: MUTATION_PREFIX + 'PREPEND_RECENT_MENU_OPENED',

    SET_OPTIONS_MENU_PERMISSION: MUTATION_PREFIX + 'SET_OPTIONS_MENU_PERMISSION',
  },
  ACTIONS: {
    CONFIGURE_MENU: ACTIONS_PREFIX + 'CONFIGURE_MENU',

    CONFIG_PARENTS_MENU_BADGE: ACTIONS_PREFIX + 'CONFIG_PARENTS_MENU_BADGE',
    LOAD_PARENTS_MENU_BADGE: ACTIONS_PREFIX + 'LOAD_PARENTS_MENU_BADGE',

    CONFIG_OPTIONS_MENU_BADGE: ACTIONS_PREFIX + 'CONFIG_OPTIONS_MENU_BADGE',
    LOAD_OPTIONS_MENU_BADGE: ACTIONS_PREFIX + 'LOAD_OPTIONS_MENU_BADGE',

    LOAD_OPTIONS_MENU_CONFIGS: ACTIONS_PREFIX + 'LOAD_OPTIONS_MENU_CONFIGS',
    LOAD_OPTIONS_MENU_PERMISSION: ACTIONS_PREFIX + 'LOAD_OPTIONS_MENU_PERMISSION',

    TRACK_RECENT_MENU_OPENED: ACTIONS_PREFIX + 'TRACK_RECENT_MENU_OPENED',

    FIND_MENU_STRUCTURE: ACTIONS_PREFIX + 'FIND_MENU_STRUCTURE',
  },
  GETTERS: {
    IS_CONFIGURING_MENU: GETTERS_PREFIX + 'IS_CONFIGURING_MENU',
    IS_MENU_CONFIGURED: GETTERS_PREFIX + 'IS_MENU_CONFIGURED',
    FLAT_MENU: GETTERS_PREFIX + 'FLAT_MENU',
    PRIMARY_MENU: GETTERS_PREFIX + 'PRIMARY_MENU',
    OPTION_MENU: GETTERS_PREFIX + 'OPTION_MENU',
    CONFIGURED_OPTION_MENU: GETTERS_PREFIX + 'CONFIGURED_OPTION_MENU',
    SEARCHABLE_OPTION_MENU: GETTERS_PREFIX + 'SEARCHABLE_OPTION_MENU',
    APP_NAVIGATION_BY_MENU_PATH: GETTERS_PREFIX + 'APP_NAVIGATION_BY_MENU_PATH',
    APP_NAVIGATION_BY_ROUTE_PATH: GETTERS_PREFIX + 'APP_NAVIGATION_BY_ROUTE_PATH',
    CURRENT_APP_NAVIGATION: GETTERS_PREFIX + 'CURRENT_APP_NAVIGATION',

    PARENTS_MENU_BADGE_COUNT: GETTERS_PREFIX + 'PARENTS_MENU_BADGE_COUNT',

    OPTIONS_MENU_BADGE_COUNT: GETTERS_PREFIX + 'OPTIONS_MENU_BADGE_COUNT',

    OPTIONS_MENU_PERMISSION: GETTERS_PREFIX + 'OPTIONS_MENU_PERMISSION',
    IS_OPTION_MENU_PATH_VISIBLE: GETTERS_PREFIX + 'IS_OPTION_MENU_PATH_VISIBLE',

    RECENT_MENU_OPENED: GETTERS_PREFIX + 'RECENT_MENU_OPENED',
  },
}
