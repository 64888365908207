<template>
  <div>
    <Table v-if="rows.length" tableId="dbbdcfe4-b5c1-4386-86d8-efac26962b2c" :headers="headers" :rows="rows"
      :rowsPerPage="10" :headerActions="tableHeaderActions"
      :mobileConfig="{ title: 'meldedatum', headers: ['schadennr', 'sparte', 'schadensursacheText', 'bearbstandLabel',] }"
      rowId="schadenId"
      v-model="selectedRows"
      @click-meldedatum="openSchaden" @headerAction-Schaden-VEREINIGEN="openVertragVereinigenModal()"
      @action-DELETE="deleteSchadensmeldung">

      <template #zurdnenAktion="row" v-if="!insuranceAssigned">
        <div class="insurance__table--company" title="Schaden übernehmen und Dokument direkt zuordnen">
          <PhArrowFatRight  class="clickable"  @click="confirmAssignInsurance(row)" :size="24"/>
        </div>
      </template>  

    </Table>

    <div v-else>Keine Daten</div>
    <SchadenVereinigenModal ref="SchadenVereinigenModal" :vertragList="selectedRows" @save="mergeSchaden($event)" />

    <BaseModal @onConfirmButton="assignInsurance()" ref="assignInsuranceConfirmation" modalTitle="Schaden übernehmen und Dokument direkt zuordnen" labelButtonConfirm="Bestätigen">
      Bitte bestätigen Sie, dass Sie den Schaden annehmen und die Dokumente direkt zuordnen möchten.
    </BaseModal>

  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import Table from "@/components/table2/Table.vue";
import { TextColumn, DateColumn, ActionColumn, ConfirmedAction, SlotColumn } from "@/components/table2/table_util.js";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import CORE_TYPES from "@/store/core/types";
import { ROLES, VIEW_ROLES } from '@/router/roles'
import SchadenVereinigenModal from '@/components/versicherungen/SchadenVereinigenModal.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import store from '@/store';
import { PhArrowFatRight } from 'phosphor-vue';
import MR_MONEY_TYPES from "@/store/mrmoney/types";
import BaseModal from '@/components/core/BaseModal.vue';

export default {

  mixins: [InteractiveHelpCommonsMixin],
  props: {
    schadenInfos: {
      type: Array,
      default: () => [],
    },
    isCalledFromMrMoneyPdfbox: {
      type: Boolean,
      default: false,
    },
    mrMoneyId: {
      type: String,
      default: null,
    },
    gesellName: {
      type: String,
      default: null,
    },
    vSNR: {
      type: String,
      default: null,
    },
  },
  async mounted(){
   
    if(!store.getters[CORE_TYPES.GETTERS.IS_ALLOWED_MERGE_SCHADEN]){
      this.selectedRows=null;
    } 
    if(!this.schadenBearbstandValues?.length){
      await this.retrieveSchadensBearbStandValues()
    }
  },
  data() {
    return {
      selectedRows: [],
      selectedSchadennr: null,
      insuranceAssigned: false,
    }

  },
  computed: {
    ...mapGetters({
      schadensmeldungen: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
      configData: SCHADENSMELDUNG_TYPES.GETTERS.CONFIG_DATA,
      isBypass: CORE_TYPES.GETTERS.IS_BYPASS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isOriginalUserBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      schadenBearbstandValues: SCHADENSMELDUNG_TYPES.GETTERS.SCHADEN_BEARBSTAND_VALUES,  
    }),
    headers() {
      const headers = {
        center: [
          DateColumn("meldedatum", "Meldung vom").makeLink(),
          TextColumn("schadennr", "Schadensnummer"),
          TextColumn("sparte", "Sparte"),
          DateColumn("ereignisdatum", "Ereignisdatum"),
          TextColumn("schadensursacheText", "Ursache"),
          TextColumn("bearbstand", "Bearbeitungsstand"),
        ],
        lockedRight: [],
      };
      if (this.isBypass) {
        headers.lockedRight.push(ActionColumn("actions", "Aktionen"));
      }
      if(this.isCalledFromMrMoneyPdfbox) {
        headers.center.unshift(SlotColumn("zurdnenAktion", ""));
      }
      return headers;
    },
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('Schaden-VEREINIGEN', 'Schaden vereinigen')
          .withDisabled(() => this.selectedRows && this.selectedRows.length != 2),

      ];
    },
    rows() {
      return this.schadenInfos.map(row => ({
        ...row,
        bearbstand: this.findBearbstandValue(row.bearbstand),
        actions: [ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Möchten Sie die Meldung vom ${row.meldedatum} wirklich löschen?`, "Eintrag löschen", "Löschen")],
      }));
    },
    
  },
  methods: {
    confirmAssignInsurance(row) {
      this.selectedSchadennr = row.schadennr;
      this.$refs.assignInsuranceConfirmation.open();
    },
    assignInsurance() {
      this.$store.dispatch(MR_MONEY_TYPES.ACTIONS.ASSIGN_INSURANCE, {
        mrMoneyId: this.mrMoneyId,
        mscInternalId: this.$route.params.id,
        insuranceId: this.vSNR,
        gesellName: this.gesellName,
        schadennr: this.selectedSchadennr,
        mscCategory: 'SCHADENSDOKUMENT',
      }).then((response) => {
        if (response?.message) {
            this.insuranceAssigned = true;
        }

      });



    },
    findBearbstandValue(bearbstand){
      let res = this.schadenBearbstandValues.filter(sbv=>sbv.value == bearbstand).map(sbv=>sbv.label);
      return res.length ? res[0] : bearbstand;
    },
    openSchaden(schaden) {
      this.$router.push(`/home/versicherungen/insurance-group/schadensmeldung/${this.$route.params.id}/${schaden.schadenId}`);
    },
   
    handleSelected(rows) {
      
      //const newlySelected = rows.map(r => r.label);
      //this.$set(this, 'selectedRows', [ ...newlySelected, ]);
    },
    deleteSchadensmeldung({ schadenId }) {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.DELETE_SCHADENSMELDUNG, {
        schadenId,
      });
    },
    openVertragVereinigenModal() {
      this.$refs.SchadenVereinigenModal.open();
    },
   async   mergeSchaden(row) {
      console.log("mergeSchaden",row);
      await this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.MERGE_SCHADEN,row);
      this.$router.push(`/home/versicherungen/insurance-group/schadensmeldung/${this.$route.params.id}/${row.toKeep.schadenId}`);
    },
    async retrieveSchadensBearbStandValues() {
        await this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.RETRIEVE_SCHADEN_BEARBSTAND_VALUES, {schadenId: this.schadenId === 'neuesSchaden' ? null : this.schadenId});
      },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Details',
      fullPath: from.fullPath,
      breadcrumb: 'Schadensmeldung',
    });
    next();
  },
  components: {
    Table, SchadenVereinigenModal, PhArrowFatRight, BaseModal
  },
}
</script>

<style scoped>
.insurance__table--company {
  display: flex;
  align-items: center;
}
.insurance__table--company__logo {
  margin: 0 8px 0 0;
  max-width: 40px;
  height: auto;
}
</style>
