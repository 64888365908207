<template>
  <div>
    <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      :title="kreditform || 'Kredite bearbeiten'"
      :subtitle="kreditnummer"
    />

    <StepperForm
      ref="stepperForm"
      stepperName="credit"
      stepType="tab"
      :stepperMediator="stepperMediator"
      :hasFertig="false"
      :selectedStepKey="selectedStepKey"
      :additionalActionsForMobile="baseModalActions"
      @handleOtherActions="handleOtherActions"
      @set-step="setStep($event)"
    >
      <template #headerTemplate>
        <HinweiseUndFehler :hints="hints" />
      </template>
      <template #contentTemplate>
        <Vertragsdaten v-if="selectedStepKey === 'vertragsdaten'" ref="vertragsdaten" />
        <Anpassungen v-else-if="selectedStepKey === 'anpassungen'" />
        <Abrechnung v-else-if="selectedStepKey === 'abrechnung'" />
        <Aktivitaet v-else-if="selectedStepKey === 'aktivitaet'" />
        <Dokumente v-else-if="selectedStepKey === 'dokumente'" />
        <Prohyp v-else-if="selectedStepKey === 'prohyp'" />
      </template>
    </StepperForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CREDIT_TYPES from '@/store/credit/types';
import CORE_TYPES from '@/store/core/types';

import StepperForm from '@/components/stepper/StepperForm.vue';
import HinweiseUndFehler from '@/components/core/HinweiseUndFehler.vue';

import Vertragsdaten from './steps/Vertragsdaten.vue';
import Anpassungen from './steps/Anpassungen.vue';
import Abrechnung from './steps/Abrechnung.vue';
import Aktivitaet from './steps/Aktivitaet.vue';
import Dokumente from './steps/Dokumente.vue';
import Prohyp from './steps/Prohyp.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import { StepperMediator } from '@/components/stepper/utils';

import { KREDITFORM_BAUFINANZIERUNG } from './credit-utils';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import EVENT_BUS, {  ANPASSUNGEN_NEUE_ANPASSUNG, } from '@/event-bus';

const DEFAULT_STEP = 'vertragsdaten';


export default {
  data() {
    let index = 0;
    return {
      steps: [
        {
          stepKey: 'vertragsdaten',
          label: 'Vertragsdaten',
          totalProgress: 1,
          index: index++,
        },
        {
          stepKey: 'anpassungen',
          label: 'Anpassungen',
          totalProgress: 1,
          index: index++,
        },
        {
          stepKey: 'abrechnung',
          label: 'Abrechnung',
          totalProgress: 1,
          index: index++,
          hidden: true,
        },
        {
          stepKey: 'aktivitaet',
          label: 'Aufgabe',
          totalProgress: 1,
          index: index++,
          hidden: true,
        },
        {
          stepKey: 'dokumente',
          label: 'Dokumente',
          totalProgress: 1,
          index: index++,
        },
        {
          stepKey: 'prohyp',
          label: 'Prohyp',
          totalProgress: 1,
          index: index++,
          hidden: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      creditId: CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID,
      credit: CREDIT_TYPES.GETTERS.CREDIT,
      creditEdit: CREDIT_TYPES.GETTERS.CREDIT_EDITED,
      rights: CREDIT_TYPES.GETTERS.RIGHTS,
      hints: CREDIT_TYPES.GETTERS.HINTS,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
    }),
    stepperMediator() {
      return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
    },
    selectedStepKey() {
      return this.$route.params?.step || DEFAULT_STEP;
    },
    kreditform() {
      return this.creditEdit?.kreditform || this.credit?.kreditform;
    },
    kreditnummer() {
      return this.creditEdit?.kreditnummer || this.credit?.kreditnummer;
    },
    baseModalActions() {
        const actions =  [];

        if (this.selectedStepKey === 'anpassungen') {
          actions.push(new BaseModalSimpleAction('NEUE_ANPASSUNG', 'Neue Anpassung'));
        }

        return actions;
      },    
  },
  watch: {
    '$route'(_, from) {
      if(from?.params?.step === 'vertragsdaten' && !this.$refs?.vertragsdaten?.checkData()) {
        this.$router.push(`/home/verbindlichkeiten/credit/edit/${this.creditId}/vertragsdaten`);
      }
    },
    rights() {
      this.configureSteps();
    },
    kreditform() {
      this.configureSteps();
    },
  },
  methods: {
    handleOtherActions(actionData) {
      if (actionData?.key === 'NEUE_ANPASSUNG') {
        EVENT_BUS.$emit(ANPASSUNGEN_NEUE_ANPASSUNG);
      }
    },      
    init() {
      this.$store.commit(CREDIT_TYPES.MUTATIONS.SET_SELECTED_CREDIT_ID, this.$route.params?.creditId);

      this.$store.dispatch(CREDIT_TYPES.ACTIONS.GET_COMBOS);
      this.$store.dispatch(CREDIT_TYPES.ACTIONS.GET_CREDIT);

      this.configureSteps();
    },
    setStep(stepKey) {
      if(this.selectedStepKey === stepKey) return;
      this.$router.push(`/home/verbindlichkeiten/credit/edit/${this.creditId}/${stepKey}`);
    },
    configureSteps() {
      this.showStep('abrechnung', this.rights?.allowReadVertragAbrechnung);
      this.showStep('aktivitaet', this.isByPass);
      this.showStep('dokumente', this.loginData?.rights?.showDocumentArchive || this.loginData?.rights?.updateDocumentArchive);
      this.showStep('prohyp', this.rights?.prohypMenuItemVisible && this.kreditform === KREDITFORM_BAUFINANZIERUNG);
    },
    showStep(stepKey, show = false) {
      const step = this.steps.find(s=>s.stepKey === stepKey);
      step.hidden = !show;
      this.$refs.stepperForm.setStepHidden(stepKey, !show);
    },
    addDefaultBreadcrumbIfNotExists(insurance) {
      this.$addDefaultBreadcrumb([
        {
          label: 'zurück zur Kredite',
          fullPath: '/home/verbindlichkeiten/credit',
          breadcrumb: 'Kredit Detail',
        },
      ]);
    },
  },
  beforeCreate() {
    this.$store.commit(CREDIT_TYPES.MUTATIONS.RESET_CREDIT_STATE);
  },
  mounted() {
    this.init();
    this.addDefaultBreadcrumbIfNotExists();
  },
  async beforeRouteLeave(to, from, next) {
    try {
      if(this.$refs?.vertragsdaten?.checkData()) {
        await this.$store.dispatch(CREDIT_TYPES.ACTIONS.SAVE_ONLY);
      }
    } catch(e) {
      // empty block
    }

    this.$store.commit(CREDIT_TYPES.MUTATIONS.RESET_CREDIT_STATE);

    this.$addBreadcrumb({
      to,
      from,
    });
    next();
  },
  components: {
    StepperForm,
    HinweiseUndFehler,
    Vertragsdaten,
    Anpassungen,
    Abrechnung,
    Aktivitaet,
    Dokumente,
    Prohyp,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
}
</script>
