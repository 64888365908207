import VV_TYPES from './types';

export default {
  [VV_TYPES.GETTERS.MENU](state) {
    return state.menu;
  },

  [VV_TYPES.GETTERS.STRATEGIEN](state) {
    return state.strategien;
  },

  [VV_TYPES.GETTERS.STRATEGIEN_FILTERS](state) {
    return state.strategienFilters;
  },

  [VV_TYPES.GETTERS.STRATEGIEN_EDIT_RIGHTS](state) {
    return state.strategienEditRights;
  },

  [VV_TYPES.GETTERS.HAT_ADVISOR_RECHTE](state) {
    return state.hatAdvisorRechte;
  },

  [VV_TYPES.GETTERS.STRATEGIEN_MENU_ITEMS](state) {
    return state.strategienFilterItems;
  },

  [VV_TYPES.GETTERS.STRATEGIEN_SCHEMA_DATA](state) {
    return state.strategienSchemaData;
  },

  [VV_TYPES.GETTERS.GET_SUBSCRIPTION](state) {
    return state.subscription;
  },

  [VV_TYPES.GETTERS.VV_ARCHIV](state) {
    return state.vvArchiv;
  },

  [VV_TYPES.GETTERS.WERTPAPIER_BEANTRAGEN_DATA](state) {
    return state.wertpapierBeantragenData;
  },

  [VV_TYPES.GETTERS.BESCHWERDE](state) {
    return state.beschwerde;
  },

  [VV_TYPES.GETTERS.STRATEGIEN_SCHEMA_DATA_LOADING](state) {
    return state.strategienSchemaDataLoading;
  },
  [VV_TYPES.GETTERS.MISSING_LEGITIMATIONS](state) {
    return state.missingLegitimations;
  },
  [VV_TYPES.GETTERS.SUITABILITYRESULTLIST](state) {
    return state.suitabilityResultList || [];
  },
  [VV_TYPES.GETTERS.SUITABILITYSIGNATURES](state) {
    return state.suitabilitySignatures || [];
  },
  [VV_TYPES.GETTERS.SUITABILITYRESULT](state) {
    return state.suitabilityResult;
  },
  [VV_TYPES.GETTERS.PORTFOLIO_LIST](state) {
    return state.portfolioList;
  },
}