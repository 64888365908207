import CORE_TYPES from '@/store/core/types';
import LIMIT_TYPES from '@/store/limit/types';
import { mapGetters } from 'vuex';
import {TextColumn, DateColumn, NumberColumn, ActionColumn, IconColumn, PercentageColumn, SimpleAction, Icon } from "@/components/table2/table_util.js";
import { PhCheck, PhWarning } from "phosphor-vue";
import { openLink } from '@/link-resolvers';
import { ResolverId } from '@/link-resolvers/types';

const mixin = {
    computed: {
        ...mapGetters({
            isCustomerView: CORE_TYPES.GETTERS.IS_CUSTOMER,
            isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
            isBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
            isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
        }),
        isCustomerOrigin() {
            return !this.isBroker && !this.isIntern && !this.isByPass;
        },
        mainPath() {
            return this.isCustomerOrigin ? '/home/limit' : (this.isCustomerView ? '/service/limit' : '/customer/limit');
        },
    },
    methods: {
        headersWertpapierLimits() {
            const headers = { 
                lockedLeft: [
                    IconColumn("status", "Status", 80).makeSortable(cell => cell?.length && cell[0].title),
                    TextColumn("isin", "ISIN").makeAlwaysVisible(),
                    TextColumn("wkn", "WKN"),
                    TextColumn("gesellschaft", "Gesellschaft"),
                    TextColumn("wertpapierName", "Wertpapier"),
                ],
                center: [
                    TextColumn("currency", "Währung"),
                    NumberColumn("ausgangskurs", this.columnLabelAusgangskurs || "Ausgangskurs", 2),
                    NumberColumn("aktuellKurs", "Aktueller Kurs", 2),
                    NumberColumn("unteresLimit", "Unteres Limit", 2),
                    PercentageColumn("unteresLimitAbweichungTable", "% Abweichung", 2),
                    NumberColumn("oberesLimit", "Oberes Limit", 2),
                    PercentageColumn("oberesLimitAbweichungTable", "% Abweichung", 2),
                    DateColumn("lastChangeDate", "Letzte Änderung"),
                    DateColumn("informedDate", "Benachrichtigung"),
                ],
                lockedRight: [
                    ActionColumn("actions", ""),
                ],
            };
            return headers;
        },
        openCustomerNewTab(limit) {
            if (limit?.kundennr) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: limit.kundennr });
            }
        },
        viewDocument(event, type) {
            if (type && event?.isin) {
                openLink(ResolverId.Factsheet, {
                    type,
                    isin: event?.isin,
                });
            }
        },
        addFondsActionsAndStatus(limits, hasInfo = true) {
            if (limits?.length) {
                const result = !limits ? [] : limits.map((limit, index) => {
                    const actions = [SimpleAction("edit", 'PhPencil', "Limit bearbeiten"), SimpleAction("delete", 'PhTrash', "Limit löschen")];
                    if (hasInfo) {
                        actions.push(SimpleAction("fondsinfo", "PhInfo", "Fondsinfo"))
                    }
                    if (limit?.factsheet) {
                        actions.push(SimpleAction("factsheet", 'PhFile', "Factsheet"));
                    }
                    if (limit?.kag) {
                        actions.push(SimpleAction("kag", 'PhFile', "Factsheet KAG"));
                    }
                    if (limit?.kid) {
                        actions.push(SimpleAction("kid", 'PhFile', "PRIIP-BIB"));
                    }
                    return {
                        ...limit,
                        status: [limit.statusStr === 'ok'
                            ? Icon(PhCheck, "Ok", 20, 'bold', 'color-success')
                            : Icon(PhWarning, "Fehler", 20, 'bold', 'color-danger')
                        ],
                        rowId: limit.isin + index,
                        actions: actions,
                    };
                });
                return result;
            }
        },
        confirmNextRoute() {
            this.nextRoute && this.nextRoute();
        },
        async doConfirmAction(exec, stateName) {
            let confirm = true;
            try {
                await this.$confirmModal({
                    title: '<b>' + exec.slice(0,1).toUpperCase() + exec.slice(1) + '</b>',
                    icon: PhWarning,
                    iconClass: 'color-danger',
                    message: 'Wollen Sie wirklich alle überschrittenen Limits ' + exec,
                    labelButtonConfirm: 'Ja',
                    labelButtonCancel: 'Nein',
                });
            } catch (e) {
            	confirm = false;
            }
            if (confirm) {
                const params = {includeStruktur: this.includeStruktur||'0', stateName};
                if (exec == 'zurücksetzen'){
                    try {
                        this.loading = true;
                        await this.$store.dispatch(LIMIT_TYPES.ACTIONS.RESET_MORE_LIMITS, params);
                    } finally {
                        this.loading = false;
                    }
                } else {
                    // await this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_MORE_LIMITS, params);
                }
            }
        },
    },    
}

export default mixin;