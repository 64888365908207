import axios from 'axios';

import store from '@/store';

import { downloadLinkMaker } from '@/helpers/utils-helper';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

export function openPdfFile(servicePath, queryParams){
  const href = downloadLinkMaker(store.getters, servicePath, null, queryParams);
  viewDocument({ href, filename: 'file.pdf' });
}

  export   function bla(){
    const config = {
      defaultSpinner: true,
    };

    let res ='vers981';
    alert(process.env.VUE_APP_API);
    axios.get(process.env.VUE_APP_API+ '/versandProtokolle/menuItem').then(response => {
      
    
      //commit(V_P.MUTATIONS.LABEL, response.data);
      res= response.data;
      return res;  
     
    });
    
    return res;
  }
  export function  isLegi(b){
    return b&&b.kundenname&&b.kundennrIn&&b.deckblattLabel=='LEGITIMATIONSURKUNDE';
  }