<template>
  <div class="box__container">
    <div class="box__title">Finanzielle Verhältnisse</div>

    <div class="box__title">1. Grundlage und Höhe des derzeit regelmäßigen Einkommens</div>
    <FinanzenGrundlage :disabled="!isEditable" :finance="finances"
      @changed="updateValue"/>

    <div class="box__title">2. aktuelle liquide Vermögenswerte</div>
    <FinanzenLiquiditaet :disabled="!isEditable" :finance="finances"
      @changed="updateValue"/>

    <div class="box__title">3. Immobilienvermögen</div>
    <FinanceImmobilienvermoegen :isRefusedPage="!isEditable" :finance="finances" @updateImmo="immobilienChanged($event)"/>

    <div class="box__title">4. Kapitalanlage / sonstige Vermögenswerte</div>
    <FinanzenKapitalanlage :disabled="!isEditable" :finance="finances" :showBondDepotRelevant="showBondDepotRelevant" 
      @changed="updateValue"/>

    <div class="box__title">5. Verbindlichkeiten / Schulden</div>
    <FinanzenVerbindlichkeiten :disabled="!isEditable" :finance="finances"
      @changed="updateValue" />

    <div class="box__title">6. regelmäßige finanzielle Verpflichtungen (pro Monat)</div>
    <FinanzenRegelmaessigeRate :disabled="!isEditable" :finance="finances" 
      @changed="updateValue"/>

    <div v-if="!isFA">
      <div class="box__title">Zulagenberechtigung</div>
      <FinanzenWeitere :disabled="!isEditable" :finance="finances" :isFA="isFA" 
        @changed="updateValue"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CORE_TYPES from '@/store/core/types.js';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import FinanceImmobilienvermoegen from '@/components/anlegerprofil/FinanceImmobilienvermoegen.vue';
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import persoenlicheDatenMixin from './persoenliche-daten-mixin.js';
import FinanzenGrundlage from '@/components/anlegerprofil/FinanzenGrundlage.vue';
import FinanzenLiquiditaet from '@/components/anlegerprofil/FinanzenLiquiditaet.vue';
import FinanzenRegelmaessigeRate from '@/components/anlegerprofil/FinanzenRegelmaessigeRate.vue';
import FinanzenVerbindlichkeiten from '@/components/anlegerprofil/FinanzenVerbindlichkeiten.vue';
import FinanzenKapitalanlage from '@/components/anlegerprofil/FinanzenKapitalanlage.vue';
import FinanzenWeitere from '@/components/anlegerprofil/FinanzenWeitere.vue';

const PRECISION = 2;
const HOLLY_OWNED = true;
const CO_OWNER = false;

const PROPRIETARY_OPTIONS = [
  { value: HOLLY_OWNED, label: 'Alleineigentümer' },
  { value: CO_OWNER, label: 'Miteigentümer' },
];

export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      finances: {},
      precision: PRECISION,
      proprietary: [...PROPRIETARY_OPTIONS],
    };
  },
  computed: {
    ...mapGetters({
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
    }),
    isFA() {
      return this.loginData?.rights?.isFA;
    },
    showBondDepotRelevant() {
      return this.finances?.showBondDepotRelevant;
    },
  },
  watch: {
    customerData: {
      handler() {
        this.copyFinancesData();
      },
      immediate: true,
    },
    customerDataEdited(data) {
      if(!Object.keys(data).length) {
        this.discardChanges();
      }
    },
  },
  methods: {
    copyFinancesData() {
      if (!this.finances || !Object.keys(this.finances).length) {
        this.finances = Object.assign({}, this.customerData?.finances);
      }
    },
    updateValue({compId, value}) {
      if (compId) {
        this.finances[compId] = value || null;

        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, {personId: this.personId, finances: this.finances});
      }
    },
    dataChanged() {
      const finances = this.finances;

      // Currency
      finances.salary = finances?.salary === '' ? null : finances.salary;
      finances.freelancerRevenue = finances?.freelancerRevenue === '' ? null : finances.freelancerRevenue;
      finances.capitalRevenue = finances?.capitalRevenue === '' ? null : finances.capitalRevenue;
      finances.otherRevenue = finances?.otherRevenue === '' ? null : finances.otherRevenue;
      finances.cash = finances?.cash === '' ? null : finances.cash;
      finances.freeBankDeposit = finances?.freeBankDeposit === '' ? null : finances.freeBankDeposit;
      finances.boundBankDeposit = finances?.boundBankDeposit === '' ? null : finances.boundBankDeposit;
      finances.home = finances?.home === '' ? null : finances.home;
      finances.appartments = finances?.appartments === '' ? null : finances.appartments;
      finances.otherProperty = finances?.otherProperty === '' ? null : finances.otherProperty;
      finances.bondDepot = finances?.bondDepot === '' ? null : finances.bondDepot;
      finances.bondDepotNotRelevant = finances?.bondDepotNotRelevant === '' ? null : finances.bondDepotNotRelevant;
      finances.alternativeInvestment = finances?.alternativeInvestment === '' ? null : finances.alternativeInvestment;
      finances.art = finances?.art === '' ? null : finances.art;
      finances.buildingSaving = finances?.buildingSaving === '' ? null : finances.buildingSaving;
      finances.insurance = finances?.insurance === '' ? null : finances.insurance;
      finances.otherInvestment = finances?.otherInvestment === '' ? null : finances.otherInvestment;
      finances.longTermCredit = finances?.longTermCredit === '' ? null : finances.longTermCredit;
      finances.shortTermCredit = finances?.shortTermCredit === '' ? null : finances.shortTermCredit;
      finances.otherCredit = finances?.otherCredit === '' ? null : finances.otherCredit;
      finances.rent = finances?.rent === '' ? null : finances.rent;
      finances.leasing = finances?.leasing === '' ? null : finances.leasing;
      finances.creditPayment = finances?.creditPayment === '' ? null : finances.creditPayment;
      finances.household = finances?.household === '' ? null : finances.household;
      finances.maintainance = finances?.maintainance === '' ? null : finances.maintainance;
      finances.insurancePayment = finances?.insurancePayment === '' ? null : finances.insurancePayment;
      finances.otherPayment = finances?.otherPayment === '' ? null : finances.otherPayment;

      // Percent
      finances.homePercentage = finances?.homePercentage === '' ? null : finances.homePercentage;
      finances.appartmentsPercentage = finances?.appartmentsPercentage === '' ? null : finances.appartmentsPercentage;
      finances.otherPropertyPercentage = finances?.otherPropertyPercentage === '' ? null : finances.otherPropertyPercentage;

      const payload = {
        personId: this.personId,
        finances,
      };

      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload);
    },
    discardChanges() {
      this.copyFinancesData();
    },
    immobilienChanged(event) {
      if (event?.compId) {
        this.finances[event.compId] = event.value;
        this.dataChanged();
      }
    }
  },
  components: {
    InputRadioBoxGroup,
    InputToggleSwitch,
    InputField,
    FinanceImmobilienvermoegen,
    DatePickerField,
    FinanzenGrundlage,
    FinanzenLiquiditaet,
    FinanzenRegelmaessigeRate,
    FinanzenKapitalanlage,
    FinanzenVerbindlichkeiten,
    FinanzenWeitere,
  },
}
</script>
