


export function updateQueryStringParameter(uri, key, value) {
    const re = new RegExp('([?&])' + key + '=.*?(&|$)', "i");
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    value = encodeURIComponent(value);
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
        return uri + separator + key + '=' + value;
    }
}

export function replaceRawPathParams(path, params) {
  const paramsKeys = Object.keys(params || {});
  if(!path || paramsKeys.length === 0) return path;
  return paramsKeys.reduce((acc, key) => acc.replace(`:${key}`, params[key]), path);
}

export function preformancePerAnno( years, perf ) {
  if ( years <= 1 ) {
    return perf;
  } else {
    let yieldPA = 1 + perf / 100;
    yieldPA = ( yieldPA < 0 ? -1 : 1) * Math.pow(Math.abs(yieldPA), 1 / years );
    return (yieldPA - 1) * 100;
  }
}

/**
 * Check if item is an object
 * 
 * @param {*} item 
 * @returns boolean
 */
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Assign an object considering deep attributes
 * 
 * @param {*} target 
 * @param  {...any} sources 
 * @returns { Object }
 */
export function deepAssign(target, ...sources) {
  if (!sources.length) return target;

  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepAssign(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepAssign(target, ...sources);
}

/**
 * Check if item is a function
 * 
 * @param {*} item 
 * @returns boolean
 */
export function isFunction(item) {
  return item && typeof item === 'function';
}

/**
 * Check if value is a date
 * 
 * @param {*} value 
 * @returns boolean
 */
export function isDate(value) {
  return value instanceof Date && !isNaN(value.valueOf());
}

export function tableMetaFromArrays(tabledata, alt = null) {
  const result = {
    headers: {},
    records: []
  };
  const keys = [];
  tabledata.headers.forEach(h => {
    if (h.key) {
      keys.push(h.key);
      if (h.dataType !== 'Hidden') {
        result.headers[h.key] = h;
        h.visible = h.visible == undefined ? true : h.visible;
        h.sortable = h.sortable == undefined ? true : h.sortable;
        if (h.key === 'actions') {
          h.align = !h.align ? 'right' : h.align;
        }
      }
    }
  });
  tabledata.records.forEach(r => {
    const row = {};
    keys.forEach((key, idx) => {
      row[key] = r[idx];
      if (alt && r[idx]) {
        row[alt + key] = r[idx];
      }
    })
    result.records.push(row);
  });
  return result;
}

export function debounce(func, timeout = 300) {
  let timer = null;
  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeout);
  };
}

export function groupBy(arr, key) {
  if(!arr || !key) return {};

  return arr.reduce((acc, item) => {
    if(!acc[item[key]]) {
      acc[item[key]] = [];
    }
    acc[item[key]].push(item);
    return acc;
  }, {});
}

export function replaceUmlaute(text) {
  const umlautMap = {
    '\u00dc': 'UE',
    '\u00c4': 'AE',
    '\u00d6': 'OE',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u00df': 'ss',
  };
  return text.replace(new RegExp(`[${Object.keys(umlautMap).join('')}]`, 'g'), (a) => umlautMap[a]);
}

export function replaceAccentChars(text) {
  if (!text) return text;
  return text
          .replace(/[àáâãå]/g, "a")
          .replace(/[ÀÁÂÃÅ]/g, "A")
          .replace(/[èéêë]/g, "e")
          .replace(/[ÈÉÊË]/g, "E")
          .replace(/[ìíî]/g, "i")
          .replace(/[ÌÍÎ]/g, "I")
          .replace(/[òóôõ]/g, "o")
          .replace(/[ÒÓÔÕ]/g, "O")
          .replace(/[ùúû]/g, "u")
          .replace(/[ÙÚÛ]/g, "u")
          .replace(/[ç]/g, "c")
          .replace(/[Ç]/g, "C");
}

export function stripTags(html) {
  if(!html) return html;
  return html.replace(/(<([^>]+)>)/gi, '');
}

export function baseURLFromPath(path, base) {
  if(!path || !base) return '';
  return path.substr(0, path.indexOf(base)) + base;
}

export function convertNewLineToBR(text) {
  return (text ?? '').replaceAll(/\n/gi, '<br>');
}

export function escapeRegex(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
