<template>
  <BaseModal ref="updaterModal" 
    :modalTitle="modalTitle"
    labelButtonCancel="Schliessen"
    :labelButtonConfirm="confirmButtonLabel"
    :autoCloseOnRouteNavigation="false"
    :confirmLoading="refreshing"
    @onConfirmButton="refreshApp"
  >
    <template #default>{{ message }}</template>
  </BaseModal>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import BaseModal from '@/components/core/BaseModal.vue';
import templateRoutes from '@/views/cms/templateRoutes';

const PATH_ALLOWED_TO_REFRESH = [
  '/',
  '/login',
  '/login/nc',
  /\/page\//,
  ...templateRoutes.map(route => `/${route.path}`),
];

function isAllowedToAutomaticallyRefresh(routePath) {
  return PATH_ALLOWED_TO_REFRESH.some(path => (path.test && path.test(routePath)) || path === routePath);
}

let currentAppBuildHash = null;
const SKIP_WAITING_TYPE = 'SKIP_WAITING';
const REQUEST_UPDATE_TYPE = 'REQUEST_UPDATE';

export default {
  data() {
    return {
      mandatory: false,
      refreshing: false,
      registration: null,
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
    }),
    ignoreUpdate() {
      return !!this.$isMobileNativeContext;
    },
    isServiceWorkerEnabled() {
      return (process.env.VUE_APP_ENABLE_SERVICE_WORKER+'') === 'true';
    },
    modalTitle() {
      return this.mandatory ? 'Erforderliche Maßnahme' : 'Empfohlene Maßnahme';
    },
    confirmButtonLabel() {
      return this.isLoggedIn ? 'Abmelden' : 'neu laden';
    },
    message() {
      if (this.isLoggedIn) {
        return 'Es gibt eine neues Update. Bitte melden Sie sich ab und erneut an!';
      }
      return 'Es gibt eine neues Update. Bitte laden Sie die Seite neu!';
    },
  },

  methods: {
    async findCurrentAppBuildHash() {
      if (!this.isServiceWorkerEnabled) return;

      if (!currentAppBuildHash) {
        const appBuildHash = await axios.get(`${process.env.VUE_APP_PUBLIC_PATH}metadata.json`)
          .then(result =>  result?.data?.appBuildHash);
        currentAppBuildHash = appBuildHash;
      }

      this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, `currentAppBuildHash "${currentAppBuildHash}" found`);

      return currentAppBuildHash;
    },
    updateAvailable(event) {
      if (this.ignoreUpdate) return;

      this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, { message: 'updateAvailable', detail: event.detail });

      const { mandatory, registration } = event.detail;
      this.mandatory = mandatory;
      this.registration = registration;

      if (isAllowedToAutomaticallyRefresh(this.$route.path)) {
        this.refreshApp();
      } else {
        this.$refs.updaterModal.open();
      }
    },
    refreshApp() {
      this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, { message: 'refreshApp', refreshing: this.refreshing });

      if (this.refreshing) {
        return
      }

      this.refreshing = true;

      const _reloadApp = async () => {
        this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, '_reloadApp');

        try {
          if (this.isLoggedIn) {
            const nextUrl = this.$route.fullPath;
            await this.$store.dispatch(CORE_TYPES.ACTIONS.LOGOUT, { nextUrl });
          }
        } finally {
          // reloads even when logout fails
          this.$refs?.updaterModal?.close();
          requestAnimationFrame(() => location.reload());
        }
      };

      const _refreshApp = () => {
        this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, '_refreshApp');

        if (this.registration?.active) {
          navigator.serviceWorker.removeEventListener('controllerchange', _refreshApp);

          const _shouldReloadApp = async (event) => {
            this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, { message: '_shouldReloadApp', data: event?.data });

            const { type, appBuildHash } = event?.data ?? {};
            const currentAppBuildHash = await this.findCurrentAppBuildHash();
            if (type === REQUEST_UPDATE_TYPE && currentAppBuildHash !== appBuildHash) {
              _reloadApp();
            } else {
              this.refreshing = false;
            }
            navigator.serviceWorker.removeEventListener('message', _shouldReloadApp);
          };
          navigator.serviceWorker.addEventListener('message', _shouldReloadApp);
          this.registration.active.postMessage({ type: REQUEST_UPDATE_TYPE });
        } else {
          _reloadApp();
        }
      };

      if (this.registration?.waiting) {
        navigator.serviceWorker.addEventListener('controllerchange', _refreshApp);
        this.registration.waiting.postMessage({ type: SKIP_WAITING_TYPE });
      } else {
        _refreshApp();
      }
    },
  },

  created() {
    document.addEventListener('app-updated', this.updateAvailable);
  },

  mounted() {
    this.findCurrentAppBuildHash();
  },

  beforeDestroy() {
    document.removeEventListener('app-updated', this.updateAvailable);
  },

  components: {
    BaseModal,
  },

}
</script>
