<template>
  <div>
    <InputRadioBoxGroup 
        title="Wird sich in absehbarer Zeit Ihre familiäre Situation ändern?"
        v-model="isFamilyChange" 
        validateUntouched
        :inlineLabelValues="false"
        :values="[
          {value: 'no', label: 'nein'},
          {value: 'yes', label: 'ja'}
        ]"
        :disabled="isDisabled"
        @input="updateStore(getCategory(), 'isFamilyChange', getBooleanValue($event))"/>
    <DatePickerField v-if="isFamilyChange === 'yes'"
      v-model="familyChangeDate" 
      dateFormat="DD.MM.YYYY" 
      placeholder="DD.MM.JJJJ"
      isValueAsString
      isComponentHalfSize
      validateUntouched
      @input="updateStore(getCategory(), 'familyChangeDate', $event)"
      >
    </DatePickerField>
    <InputRadioBoxGroup 
      title="Wird sich in absehbarer Zeit Ihre berufliche Situation ändern?"
      v-model="isJobChange" 
      validateUntouched
      :inlineLabelValues="false"
      :values="[
        {value: 'no', label: 'nein'},
        {value: 'yes', label: 'ja'}
      ]"
      :disabled="isDisabled"
      @input="updateStore(getCategory(), 'isJobChange', getBooleanValue($event))"/>
    <DatePickerField v-if="isJobChange === 'yes'"
      v-model="jobChangeDate" 
      dateFormat="DD.MM.YYYY" 
      placeholder="DD.MM.JJJJ"
      isValueAsString
      isComponentHalfSize
      validateUntouched
      @input="updateStore(getCategory(), 'jobChangeDate', $event)"
    >
    </DatePickerField>
     <InputTextArea
      label="Erläuterung:"
      v-model="changesAnnotation"
      :disabled="isDisabled"
      isComponentHalfSize
      validateUntouched
      @change="updateStore(getCategory(), 'changesAnnotation', $event)" />
  </div>
</template>

<script>

import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    InputRadioBoxGroup,
    DatePickerField,
    InputTextArea
  },
  data() {
    return {
        isFamilyChange: '',
        isJobChange: '',
        familyChangeDate: '',
        jobChangeDate: '',
        changesAnnotation: '',
    };
  },
  mounted() {
    this.patchForm();
    this.updateWarnings();
  },
  methods: {
    patchForm() {
      const data = this.getPerson(this.data, this.getPersonType);
      if (data) {
        this.isFamilyChange = data.isFamilyChange ? 'yes' : 'no';
        this.familyChangeDate = data.familyChangeDate; 
        this.isJobChange = data.isJobChange  ? 'yes' : 'no';
        this.jobChangeDate = data.jobChangeDate; 
        this.changesAnnotation = data.changesAnnotation;
      }
    },
  },
}
</script>

<style scoped>

</style>
