var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("BaseCollapsable", {
        attrs: { showSection: _vm.showExpanded },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("div", { staticClass: "cards__item--header" }, [
                  _c(
                    "div",
                    { staticClass: "box__title cards__item--header-title" },
                    [
                      _c("span", [
                        _c("a", [_vm._v(_vm._s(_vm.position.fondsname))])
                      ]),
                      _c("span", { staticClass: "text-divider" }, [
                        _vm._v("|")
                      ]),
                      _c("span", [_vm._v("SRI " + _vm._s(_vm.position.sri))]),
                      _c("span", { staticClass: "text-divider" }, [
                        _vm._v("|")
                      ]),
                      _c("span", { class: _vm.getColorClass(_vm.position) }, [
                        _vm._v(_vm._s(_vm.position.art))
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "cards__item--header-actions" }, [
                    _c(
                      "a",
                      {
                        attrs: { title: "Position löschen" },
                        on: {
                          click: function($event) {
                            return _vm.openModalDeleteFond(_vm.position)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "cards__item--header-action--item-icon"
                          },
                          [_c("PhTrash", { attrs: { size: 16 } })],
                          1
                        )
                      ]
                    )
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "cards__item--inputs" },
                  [
                    _vm.position.art == "KAUF"
                      ? _c("InputField", {
                          staticClass: "cards__item--input-240",
                          attrs: {
                            label: "Einmalanlage (mind. 100,- EUR)",
                            isEmbedded: "",
                            type: "currency",
                            precision: "2",
                            id: "einmalBetrag" + _vm.position.index,
                            placeholder: "mind. 100,- EUR",
                            value: _vm.position.einmalBetrag,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "einmalBetrag"
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.position.art == "SPARPLAN"
                      ? _c("InputField", {
                          staticClass: "cards__item--input-240",
                          attrs: {
                            label: "Sparplan (mind. 10,- EUR)",
                            isEmbedded: "",
                            type: "currency",
                            precision: "2",
                            id: "sparplanBetrag" + _vm.position.index,
                            placeholder: "mind. 10,- EUR",
                            value: _vm.position.sparplanBetrag,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "sparplanBetrag"
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    !_vm.isWebservice
                      ? _c("InputField", {
                          attrs: {
                            isEmbedded: "",
                            label: "Bemerkung",
                            id: "bemerkung" + _vm.position.index,
                            value: _vm.position.bemerkung
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "bemerkung"
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.position.art == "SPARPLAN"
                      ? _c("ComboBox", {
                          attrs: {
                            label: "zum:",
                            isEmbedded: "",
                            id: "planTag" + _vm.position.index,
                            value: _vm.position.planTag,
                            values: [
                              { label: "", value: "" },
                              { label: "1.", value: "1" },
                              { label: "15.", value: "15" }
                            ],
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "planTag"
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.position.art == "SPARPLAN"
                      ? _c("ComboBox", {
                          attrs: {
                            label: "Turnus",
                            isEmbedded: "",
                            sortComboboxValues: false,
                            id: "planFrequenz" + _vm.position.index,
                            value: _vm.position.planFrequenz,
                            values: [
                              { label: "", value: "" },
                              { label: "monatlich", value: "MONATLICH" },
                              { label: "vierteljährlich", value: "VIERTELJ" },
                              { label: "halbjährlich", value: "HALBJ" },
                              { label: "jährlich", value: "JAEHRLICH" }
                            ],
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "planFrequenz"
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.position.art == "KAUF"
                      ? _c("InputRadioBoxGroup", {
                          staticClass:
                            "cards__item--input-no-label cards__item--input-295",
                          attrs: {
                            id: "einmalModusAbbuchung" + _vm.position.index,
                            value: _vm.position.einmalModusAbbuchung,
                            values: _vm.einmalModusValues,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "einmalModusAbbuchung"
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.position.art == "KAUF" &&
                    _vm.position.einmalModusAbbuchung == 2
                      ? _c("DatePickerField", {
                          staticClass: "cards__item--input-240",
                          attrs: {
                            label: "Abbuchungstermin Einmalanlage",
                            isEmbedded: "",
                            id: "einmalDatumAbbuchung" + _vm.position.index,
                            value: _vm.position.einmalDatumAbbuchung,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "einmalDatumAbbuchung"
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.position.art == "SPARPLAN"
                      ? _c("DatePickerField", {
                          staticClass: "cards__item--input-240",
                          attrs: {
                            monthPicker: "",
                            label: "Abbuchungstermin Sparplan",
                            isEmbedded: "",
                            id: "sparplanBeginnDatum" + _vm.position.index,
                            value: _vm.position.sparplanBeginnDatum,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "sparplanBeginnDatum"
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.position.art == "SPARPLAN"
                      ? _c("DatePickerField", {
                          attrs: {
                            monthPicker: "",
                            label: "Letzte Ausführung",
                            isEmbedded: "",
                            id: "sparplanEndDatum" + _vm.position.index,
                            value: _vm.position.sparplanEndDatum,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "sparplanEndDatum"
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.position.art == "SPARPLAN"
                      ? _c("ComboBox", {
                          attrs: {
                            label: "Dynamik",
                            isEmbedded: "",
                            sortComboboxValues: false,
                            id: "sparplanDynamik" + _vm.position.index,
                            value: _vm.position.sparplanDynamik,
                            values: _vm.sparplanDynamikValues,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "sparplanDynamik"
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.position.art == "SPARPLAN"
                      ? _c("ComboBox", {
                          staticClass: "cards__item--input-295",
                          attrs: {
                            label: "Der Einzug der Sparpläne erfolgt vom:",
                            isEmbedded: "",
                            sortComboboxValues: false,
                            id: "spKontoFlex" + _vm.position.index,
                            value: _vm.position.spKontoFlex,
                            values: [
                              { label: "Konto flex", value: true },
                              {
                                label: "externen Konto (siehe Sonstiges)",
                                value: false
                              }
                            ],
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "spKontoFlex"
                              )
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.position.art == "SPARPLAN" &&
                    _vm.position.sparplanDynamik != -1 &&
                    _vm.position.sparplanDynamik != 3 &&
                    _vm.position.sparplanDynamik != null
                      ? _c("InputField", {
                          attrs: {
                            label: "Prozent Dynamik",
                            isEmbedded: "",
                            id: "sparplanDynamikFrei" + _vm.position.index,
                            value: _vm.position.sparplanDynamikFrei,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                _vm.position,
                                "sparplanDynamikFrei"
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.position, positionLabel: _vm.position.art },
        on: {
          delete: function($event) {
            return _vm.removePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }