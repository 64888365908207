import { render, staticRenderFns } from "./VVSettingsSichtbarkeit.vue?vue&type=template&id=908dc6ea&scoped=true&"
import script from "./VVSettingsSichtbarkeit.vue?vue&type=script&lang=js&"
export * from "./VVSettingsSichtbarkeit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "908dc6ea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('908dc6ea')) {
      api.createRecord('908dc6ea', component.options)
    } else {
      api.reload('908dc6ea', component.options)
    }
    module.hot.accept("./VVSettingsSichtbarkeit.vue?vue&type=template&id=908dc6ea&scoped=true&", function () {
      api.rerender('908dc6ea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/vermoegensverwaltung/VVSettingsSichtbarkeit.vue"
export default component.exports