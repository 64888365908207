var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.pageTitle,
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.toolAction("neu")
          }
        }
      }),
      _vm.filter.hinweise
        ? _c("BaseCollapsable", {
            staticClass: "box__container",
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c("span", { staticClass: "box__title" }, [
                        _vm._v(" " + _vm._s(_vm.hinweisTitle()) + " ")
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "d-block" },
                        [
                          _vm._l(_vm.filter.hinweise, function(text, idx) {
                            return [
                              _c("div", {
                                key: idx,
                                domProps: {
                                  innerHTML: _vm._s(_vm.sanitize(text))
                                }
                              })
                            ]
                          })
                        ],
                        2
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2077511058
            )
          })
        : _vm._e(),
      _vm.filter.metadata
        ? _c("BaseFilter", {
            ref: "refFilter",
            attrs: {
              title: "Depotpositionenfilter",
              filterId: "c97dab9f-d659-4aaf-a6e9-483700b50ebf",
              metadata: _vm.filter.metadata,
              hasSmartSearch: "",
              immidiateSearch: !_vm.isIntern && !_vm.excelOnly,
              configFilter: _vm.configFilter,
              defaultOptions: _vm.defaultOptions,
              titleSearchButton: _vm.titleSearchButton,
              showSaveButton: ""
            },
            on: { onFilter: _vm.handleSearch }
          })
        : _vm._e(),
      !_vm.excelOnly
        ? _c("HandleTablePin", {
            attrs: { keyOfPIN: "TABELLE_EDIT_DEPOTPOSITIONEN" }
          })
        : _c("ExportButtons", {
            ref: "exportExcel",
            staticClass: "d-none",
            attrs: { headers: [], rows: [], config: _vm.exportConfig }
          }),
      !_vm.excelOnly
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.loading && !_vm.scrollLoading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: _vm.TABLE_TITLE }
                  })
                : _c("PaginatedTable", {
                    ref: "depotliste",
                    attrs: {
                      title: _vm.TABLE_TITLE,
                      tableId: "d987e6e2-fb13-4d13-ae6e-11da6c918d71",
                      headers: _vm.headers,
                      pages: _vm.pages,
                      pageCount: _vm.pageCount,
                      rowCount: _vm.rowsCount,
                      page: _vm.pageIndex,
                      pageSize: _vm.rowsPerPage,
                      menuRowsPerPage: _vm.menuRowsPerPage,
                      exportConfig: _vm.exportConfig,
                      mobileConfig: _vm.mobileConfig,
                      noDataContent: "Keine Depotpositionen",
                      sorted: _vm.tableSort
                    },
                    on: {
                      sort: _vm.onSetTableSort,
                      localSort: function($event) {
                        _vm.tableSort = $event
                      },
                      page: _vm.setPageIndex,
                      rowsPerPage: _vm.onPageSizeChange,
                      requestPage: _vm.loadPage,
                      "click-positionsnr": _vm.gotoDepotposition,
                      "action-delete": function($event) {
                        return _vm.doAction("delete", $event)
                      },
                      "action-limit": function($event) {
                        return _vm.doAction("limit", $event)
                      },
                      "action-courtage": function($event) {
                        return _vm.doAction("courtage", $event)
                      },
                      "action-transakt": function($event) {
                        return _vm.doAction("transakt", $event)
                      },
                      "action-transaktPdf": function($event) {
                        return _vm.doAction("transaktPdf", $event)
                      },
                      "action-transTzuT": function($event) {
                        return _vm.doAction("transTzuT", $event)
                      },
                      "action-info": function($event) {
                        return _vm.doAction("info", $event)
                      },
                      "action-bemerkung": function($event) {
                        return _vm.doAction("bemerkung", $event)
                      },
                      "action-edit": function($event) {
                        return _vm.doAction("edit", $event)
                      },
                      onScroll: _vm.onScroll
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "infoActions",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                _vm._l(row.infoActions, function(line, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      line.isLink
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "text-color",
                                              on: {
                                                click: function($event) {
                                                  return _vm.openInfo(line, row)
                                                }
                                              }
                                            },
                                            [
                                              _c("Pill", {
                                                attrs: {
                                                  label: line.label,
                                                  type: line.type,
                                                  bold: line.bold
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c("Pill", {
                                            attrs: {
                                              label: line.label,
                                              type: line.type,
                                              bold: line.bold
                                            }
                                          })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3987510130
                    )
                  })
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "modalRef",
          attrs: {
            modalTitle: _vm.modalArg.title,
            showConfirmButton: _vm.modalArg.showConfirmButton,
            labelButtonConfirm: _vm.modalArg.labelButtonConfirm,
            labelButtonCancel: _vm.modalArg.labelButtonCancel
          },
          on: { onConfirmButton: _vm.modalArg.onConfirm }
        },
        [_c("div", { domProps: { innerHTML: _vm._s(_vm.modalArg.body) } })]
      ),
      _c("BaseModal", {
        ref: "editBemerkung",
        attrs: {
          modalTitle: "Bemerkung bearbeiten",
          labelButtonConfirm: "Speichern"
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.saveBemerkung()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("InputTextArea", {
                  attrs: {
                    label: "Freitextfeld zur persönlichen Bemerkung:",
                    value: _vm.interneBemerkungRowEdit.bemerkung
                  },
                  on: {
                    input: function($event) {
                      return _vm.bemerkungChanged($event)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }