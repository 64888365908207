var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.componentKey },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("DashboardPanel", {
        attrs: {
          id: "f8ec5a24-4fe6-453f-90b2-3378ca2c4b28",
          subtitle: _vm.depotTitle,
          data: _vm.dashboardData,
          noPrimaryAction: false,
          headerActions: _vm.headerActions
        },
        on: {
          widgetsActived: _vm.handleWidgetsActivated,
          executeAction: _vm.handleAction
        },
        scopedSlots: _vm._u([
          {
            key: "Wertpapiere",
            fn: function() {
              return [
                _c("div", { staticClass: "box__container-wrap" }, [
                  _c("div", { staticClass: "box__container-rows" }, [
                    _vm.isWertpapiere
                      ? _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-12",
                              class: {
                                "d-flex justify-content-end": !_vm.isMobileNativeContext
                              }
                            },
                            [
                              !_vm.isMobileNativeContext
                                ? _c("div", { staticClass: "tbl" }, [
                                    _c("div", { staticClass: "tbl-row" }, [
                                      _vm.showSaldozeile
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tbl-cell text-right text-small"
                                            },
                                            [_vm._v("G&V")]
                                          )
                                        : _vm._e(),
                                      _vm.hasPerformance
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tbl-cell text-right text-small"
                                            },
                                            [_vm._v("Wertentwicklung")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tbl-cell text-right text-small"
                                        },
                                        [_vm._v("Depotbestand")]
                                      )
                                    ]),
                                    _c("div", { staticClass: "tbl-row" }, [
                                      _vm.showSaldozeile
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tbl-cell text-right text-small",
                                              class: _vm.getClassValueAmount(
                                                _vm.GandV
                                              )
                                            },
                                            [
                                              _c("CurrencyLabel", {
                                                attrs: { value: _vm.GandV }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.hasPerformance
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tbl-cell text-right text-small",
                                              class: {
                                                "d-flex": !_vm.$isExtraSmallScreen
                                              }
                                            },
                                            [
                                              _vm.performance.hasTotal
                                                ? _c("PercentLabel", {
                                                    attrs: {
                                                      value:
                                                        _vm.performance.total,
                                                      info: _vm.$isExtraSmallScreen
                                                        ? "ges."
                                                        : "gesamt",
                                                      precision:
                                                        _vm.performanceDecimalPrecision
                                                    }
                                                  })
                                                : _vm._e(),
                                              !_vm.$isExtraSmallScreen &&
                                              _vm.performance.hasTotal &&
                                              _vm.performance.hasPA
                                                ? _c(
                                                    "span",
                                                    { staticClass: "mx-1" },
                                                    [_vm._v("/")]
                                                  )
                                                : _vm._e(),
                                              _vm.performance.hasPA
                                                ? _c("PercentLabel", {
                                                    attrs: {
                                                      value: _vm.performance.pa,
                                                      info: "p.a.",
                                                      precision:
                                                        _vm.performanceDecimalPrecision
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tbl-cell text-right text-small"
                                        },
                                        [
                                          _c("CurrencyLabel", {
                                            attrs: { value: _vm.bestand }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c("TableMobile", {
                                        ref: "wertpapiereTable",
                                        attrs: {
                                          tableId:
                                            "9d2d3b33-f6fb-4a01-84fe-2cbb499b6702",
                                          rowId: "id",
                                          cardView: "",
                                          hidePagination: "",
                                          headers:
                                            _vm.mobileWertpapierTable.headers,
                                          rows: _vm.mobileWertpapierTable.rows
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          )
                        ])
                      : _vm._e(),
                    !_vm.isMobileNativeContext
                      ? _c("div", { staticClass: "row mb-1" }, [
                          _vm.hasDataEChartWertpapiere
                            ? _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _vm.hasDataEChartWertpapiere
                                    ? _c("LineChart", {
                                        attrs: {
                                          chartData: _vm.eChartWertpapiereData,
                                          customTooltipFormatter:
                                            _vm.wertpapiereTooltipFormatter,
                                          height: "470px",
                                          gridLeft: 40
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.hasDataEChartWertpapiere
                            ? _c(
                                "div",
                                { staticClass: "col-12 text-centered" },
                                [_vm._v(" Keine Daten ")]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  !_vm.isMobileNativeContext
                    ? _c("div", { staticClass: "bottom-row" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "mr-24",
                                attrs: { to: "/home/berichte/transaktionen" }
                              },
                              [_vm._v("Transaktionen")]
                            ),
                            _c(
                              "router-link",
                              { attrs: { to: "/home/berichte/performance" } },
                              [_vm._v("Wertentwicklung")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "Montecarlo",
            fn: function() {
              return [
                _c("div", { staticClass: "box__container-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "box__container-rows" },
                    [
                      _c("ChartMontecarlo", { attrs: { depotId: _vm.depotid } })
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "Risikoverteilung",
            fn: function() {
              return [
                _c("div", { staticClass: "box__container-wrap" }, [
                  _c("div", { staticClass: "box__container-rows" }, [
                    _c(
                      "div",
                      [
                        !_vm.showRiskoverteilungDetails
                          ? _c("Risikoverteilung", {
                              attrs: {
                                displayAnlegerprofilLink: !_vm.isMobileNativeContext
                              }
                            })
                          : _vm._e(),
                        _vm.showRiskoverteilungDetails &&
                        !_vm.isMobileNativeContext
                          ? _c("div", [
                              _c("p", [
                                _vm._v(
                                  "Die durchschnittliche Risikoverteilung (GSRI 1-7) Ihres Depots errechnet sich aus dem gewichteten Durchschnitt aller einzelnen gewichteten Risikokennzoffern (SRI 1-7) der einzelnen Wertpapierpositionen. "
                                ),
                                _c("br"),
                                _vm._v(
                                  "Dabei stellt SRI 1 ein geringens Risiko und SRI 7 ein sehr hohes Risiko dar"
                                )
                              ]),
                              _c("dl", { staticClass: "row m-0" }, [
                                _c("dt", { staticClass: "pl-0 col-md-1" }, [
                                  _vm._v("SRI 1:")
                                ]),
                                _c("dd", { staticClass: "pl-0 col-md-11" }, [
                                  _vm._v(
                                    "Wertpapiere mit sehr geringer Schwankungsbreite (kleiner als 0,5% p.a.)."
                                  )
                                ]),
                                _c("dt", { staticClass: "pl-0 col-md-1" }, [
                                  _vm._v("SRI 2:")
                                ]),
                                _c("dd", { staticClass: "pl-0 col-md-11" }, [
                                  _vm._v(
                                    "Wertpapiere mit geringer Schwankungsbreite (zwischen 0,5% und 5% p.a.)."
                                  )
                                ]),
                                _c("dt", { staticClass: "pl-0 col-md-1" }, [
                                  _vm._v("SRI 3:")
                                ]),
                                _c(
                                  "dd",
                                  {
                                    staticClass: "pl-0 col-md-11",
                                    staticStyle: { "white-space": "nowrap" }
                                  },
                                  [
                                    _vm._v(
                                      "Wertpapiere mit geringer bis mittlerer Schwankungsbreite (zwischen 5% und 12% p.a.)."
                                    )
                                  ]
                                ),
                                _c("dt", { staticClass: "pl-0 col-md-1" }, [
                                  _vm._v("SRI 4:")
                                ]),
                                _c("dd", { staticClass: "pl-0 col-md-11" }, [
                                  _vm._v(
                                    "Wertpapiere mit mittlerer Schwankungsbreite (zwischen 12% und 20% p.a.)."
                                  )
                                ]),
                                _c("dt", { staticClass: "pl-0 col-md-1" }, [
                                  _vm._v("SRI 5:")
                                ]),
                                _c("dd", { staticClass: "pl-0 col-md-11" }, [
                                  _vm._v(
                                    "Wertpapiere mit erhöhter Schwankungsbreite (zwischen 20% und 30% p.a.)."
                                  )
                                ]),
                                _c("dt", { staticClass: "pl-0 col-md-1" }, [
                                  _vm._v("SRI 6:")
                                ]),
                                _c("dd", { staticClass: "pl-0 col-md-11" }, [
                                  _vm._v(
                                    "Wertpapiere mit hoher Schwankungsbreite (zwischen 30% und 80% p.a.)."
                                  )
                                ]),
                                _c("dt", { staticClass: "pl-0 col-md-1" }, [
                                  _vm._v("SRI 7:")
                                ]),
                                _c("dd", { staticClass: "pl-0 col-md-11" }, [
                                  _vm._v(
                                    "Wertpapiere mit sehr hoher Schwankungsbreite (höher als 80% p.a.)."
                                  )
                                ])
                              ])
                            ])
                          : _vm._e(),
                        _vm.isMobileNativeContext
                          ? _c(
                              "BaseModal",
                              {
                                ref: "risikoverteilungMobileModal",
                                attrs: {
                                  actions: _vm.risikoverteilungModalActions,
                                  showDefaultButtons: false
                                },
                                on: {
                                  "action-ANLEGERPROFIL":
                                    _vm.navigateToAnlegerprofil
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "modalTitle",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "dashboard-panel-config-modal--title-container clearfix"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "dashboard-panel-config-modal--title",
                                                  staticStyle: {
                                                    "font-size": "14px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Details zu den Risikoziffern"
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  1198075611
                                )
                              },
                              [
                                _c("div", [
                                  _c("p", [
                                    _vm._v(
                                      "Die durchschnittliche Risikoverteilung (GSRI 1-7) Ihres Depots errechnet sich aus dem gewichteten Durchschnitt aller einzelnen gewichteten Risikokennzoffern (SRI 1-7) der einzelnen Wertpapierpositionen. "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "Dabei stellt SRI 1 ein geringens Risiko und SRI 7 ein sehr hohes Risiko dar"
                                    )
                                  ]),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex divisorLine justify-content-between align-items-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-2 col-xs-2 text-bold"
                                          },
                                          [_vm._v("SRI 1")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-md-9 text-right ",
                                            staticStyle: {
                                              "overflow-wrap": "break-word"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Sehr geringer Schwankungsbreite "
                                            ),
                                            _c("br"),
                                            _vm._v("(kleiner als 0,5% p.a.).")
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex divisorLine justify-content-between align-items-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-2 col-xs-2 text-bold"
                                          },
                                          [_vm._v("SRI 2")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-md-9 text-right ",
                                            staticStyle: {
                                              "overflow-wrap": "break-word"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Geringe Schwankungsbreite "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "(zwischen 0,5% und 5% p.a.)."
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex divisorLine justify-content-between align-items-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-2 col-xs-2 text-bold"
                                          },
                                          [_vm._v("SRI 3")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-md-9 text-right ",
                                            staticStyle: {
                                              "overflow-wrap": "break-word"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Geringe bis mittlerer Schwankungsbreite "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "(zwischen 5% und 12% p.a.)."
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex divisorLine justify-content-between align-items-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-2 col-xs-2 text-bold"
                                          },
                                          [_vm._v("SRI 4")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-md-9 text-right ",
                                            staticStyle: {
                                              "overflow-wrap": "break-word"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Mittlere Schwankungsbreite "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "(zwischen 12% und 20% p.a.)."
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex divisorLine justify-content-between align-items-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-2 col-xs-2 text-bold"
                                          },
                                          [_vm._v("SRI 5")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-md-9 text-right ",
                                            staticStyle: {
                                              "overflow-wrap": "break-word"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Erhöhte Schwankungsbreite "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "(zwischen 20% und 30% p.a.)."
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex divisorLine justify-content-between align-items-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-2 col-xs-2 text-bold"
                                          },
                                          [_vm._v("SRI 6")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-md-9 text-right ",
                                            staticStyle: {
                                              "overflow-wrap": "break-word"
                                            }
                                          },
                                          [
                                            _vm._v("Hohe Schwankungsbreite "),
                                            _c("br"),
                                            _vm._v(
                                              "(zwischen 30% und 80% p.a.)."
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex divisorLine justify-content-between align-items-center"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-2 col-xs-2 text-bold"
                                          },
                                          [_vm._v("SRI 7")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pl-0 col-md-9 text-right ",
                                            staticStyle: {
                                              "overflow-wrap": "break-word"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "Sehr hoher Schwankungsbreite "
                                            ),
                                            _c("br"),
                                            _vm._v("(höher als 80% p.a.).")
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  !_vm.isMobileNativeContext
                    ? _c("div", { staticClass: "bottom-row" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end" },
                          [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.showRiskoverteilungDetails,
                                    expression: "!showRiskoverteilungDetails"
                                  }
                                ],
                                on: {
                                  click: function($event) {
                                    return _vm.toggleRiskoverteilungInfo()
                                  }
                                }
                              },
                              [_vm._v("Details zu den Risikoziffern")]
                            ),
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showRiskoverteilungDetails,
                                    expression: "showRiskoverteilungDetails"
                                  }
                                ],
                                on: {
                                  click: function($event) {
                                    return _vm.toggleRiskoverteilungInfo()
                                  }
                                }
                              },
                              [_vm._v("Übersicht")]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "Versicherungen",
            fn: function() {
              return [
                _c("div", { staticClass: "box__container-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "box__container-rows" },
                    [
                      _vm.isInsurances && _vm.isMobileNativeContext
                        ? _c("TableMobile", {
                            ref: "insuranceTable",
                            attrs: {
                              tableId: "e6c786cb-0dca-4578-8c11-e26f080a9706",
                              rowId: "id",
                              hidePagination: "",
                              mobileConfig:
                                _vm.mobileInsuranceTable.mobileConfig,
                              headers: _vm.mobileInsuranceTable.headers,
                              rows: _vm.mobileInsuranceTable.rows
                            }
                          })
                        : _vm.isInsurances && !_vm.isMobileNativeContext
                        ? _c("Table", {
                            attrs: {
                              tableData: _vm.insurances,
                              cardViewEnabled: false,
                              exportEnabled: false,
                              paginationEnabled: false,
                              lockedColumns: _vm.insuranceLockedColumns
                            }
                          })
                        : _c("div", [
                            _vm._v(" Keine Versicherungen vorhanden ")
                          ])
                    ],
                    1
                  ),
                  _vm.isInsurances && !_vm.isMobileNativeContext
                    ? _c("div", { staticClass: "bottom-row" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/home/versicherungen" } },
                              [_vm._v("Übersicht")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }