var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.$isSmallScreen
        ? _c(
            "ResizableModal",
            {
              ref: "resizablePopup",
              attrs: {
                withBackdropLayer: _vm.withBackdropLayer,
                modalTitle: _vm.modalTitle,
                initialWidth: _vm.initialWidth,
                initialHeight: _vm.initialHeight,
                sizeIsPercentage: _vm.sizeIsPercentage,
                startPosition: _vm.startPosition,
                fullClientHeight: _vm.fullClientHeight,
                saveLastPosition: _vm.saveLastPosition,
                id: _vm.id
              },
              on: {
                onMinimize: function($event) {
                  return _vm.$emit("onMinimize")
                },
                onMaximize: function($event) {
                  return _vm.$emit("onMaximize")
                },
                onResize: function($event) {
                  return _vm.$emit("onResize")
                },
                onClose: _vm.onClose
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "toolbar-buttons",
                    fn: function() {
                      return [_vm._t("toolbar-buttons")]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [_vm._t("default")],
            2
          )
        : _c(
            "BaseModal",
            {
              ref: "baseModal",
              attrs: {
                modalTitle: _vm.modalTitle,
                showDefaultButtons: false,
                actions: _vm.actions
              },
              on: {
                "action-SAVE": function($event) {
                  return _vm.$emit("action-SAVE")
                },
                "action-FINISH": function($event) {
                  return _vm.$emit("action-FINISH")
                },
                "action-PAGE_OVERVIEW": function($event) {
                  return _vm.$emit("action-PAGE_OVERVIEW")
                },
                "action-ADD_SIGNATURE": function($event) {
                  return _vm.$emit("action-ADD_SIGNATURE")
                },
                onCloseButton: _vm.goToLastAction
              }
            },
            [
              _c(
                "div",
                { staticClass: "modal-content" },
                [
                  _vm._t("default"),
                  _c(
                    "div",
                    { staticClass: "toolbar" },
                    [_vm._t("toolbar-buttons")],
                    2
                  )
                ],
                2
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }