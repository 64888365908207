<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row">
        <div class="col">
          <div
            class="input-forms__label-content input-forms__label-content--bigger"
            v-if="title"
          >
            <PhBank :size="16" />&nbsp;{{ title }}
          </div>
        </div>
        <div class="col-auto" v-if="config && config.buttonText">
          <BaseButton @click="openFormsFinder">{{
            config.buttonText
          }}</BaseButton>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <div v-if="rows.length">
        <Table
          tableId="092c3a63-545b-4316-8d07-803a6e7d3215"
          :headers="headers"
          :rows="rows"
          rowId="index"
          hidePagination
          @action-DELETE="openModalDeleteFond"
        >
          <template v-slot:prozent="row">
            <InputField
              type="percent"
              :value="row.prozent"
              :disabled="isMusterDepot"
              :id="'prozent' + row.index"
              @input="onChangeInputField($event, row, 'prozent')"
            />
          </template>
          <template v-slot:modus="row">
            <ComboBox :value="row.modus" :id="'modus' + row.index" :values="config.modus"
                :firstEmpty="true"
                @input="onChangeInputField($event, row, 'modus')" />
          </template>
        </Table>
        <WertpapierAuswahlPositionFields
          v-if="
            positions && positions.length && config && config.positionFields
          "
          :config="config"
          :categoryId="categoryId"
          :antragId="antragId"
        />
      </div>
      <div v-else>Wertpapierauswahl ist leer</div>
    </div>
    <DeletePositionModal
      ref="deleteModal"
      :position="positionToDelete"
      @delete="doDeletePosition()"
    >
    </DeletePositionModal>
    <BaseModal
      ref="fehlerModal"
      labelButtonCancel="Ok"
      :showConfirmButton="false"
    >
      <template v-slot:modalTitle>
        <PhWarning :size="16" class="color-danger mr-2" />
        <span class="color-danger">Fehler</span>
      </template>

      Fehler: Sie dürfen nur 10 Positionen wählen.
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import Table from "@/components/table2/Table.vue";
import InputField from "@/components/core/forms/InputField.vue";
import WertpapierAuswahlPositionFields from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import { PhWarning, PhBank } from "phosphor-vue";
import { TextColumn, ActionColumn, SlotColumn, SimpleAction } from "@/components/table2/table_util.js";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";

export default {
  mixins: [antragNavigationMixin],
  props: {
    title: String,
    antragData: Object,
    config: Object,
    categoryId: String,
    antragId: String,
    antragTyp: String,
    suppressFilterLagerstelle: { type: Boolean, default: false },
    values: { type: Array, default: () => [] },
    betragTitle: { type: String, default: "Verteilung" },
    isMusterDepot: { type: Boolean, default: false },
  },
  components: {
    BaseButton,
    Table,
    InputField,
    WertpapierAuswahlPositionFields,
    DeletePositionModal,
    ComboBox,
    BaseModal,
    PhWarning,
    PhBank,
  },
  data() {
    return {
      positionToDelete: null,
    };
  },
  watch: {
    positions: {
      immediate: true,
      handler(newValue) {
        if (newValue.length > 10) this.$refs.fehlerModal.open();
      },
    },
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
    }),
    positions() {
      return this.positionsAll?.[this.antragId]?.[this.categoryId]?.map((pos, index) => ({
        ...pos,
        index,
        isin: this.getIsin(pos),
        fondsname: this.getFondsName(pos),
      })) || [];
    },
    headers() {
      return {
        lockedLeft: [
          TextColumn("isin", "ISIN"),
          TextColumn("fondsname", "Fondsname"),
          SlotColumn("prozent", this.betragTitle).withConstantFooter(`Gesamt: ${this.percentageSum} %`),
          //SlotColumn("modus", "Tarif"),
        ],
        lockedRight: [ActionColumn("actions", "")],
      };
    },
    rows() {
      return this.positions.map((row) => ({
        ...row,
        actions: [SimpleAction("DELETE", "PhTrash", "Löschen")],
      }));
    },
    percentageSum() {
      return this.rows.reduce((sum, pos) => sum + (parseFloat(pos.prozent) || 0), 0);
    },
  },
  methods: {
    getFondsName(pos) {
      return this.positionInfo?.[pos.isin]?.wertpapiername || pos.fondsname || pos.wertpapiername;
    },
    getIsin(pos) {
      return pos?.isin || "";
    },
    openFormsFinder() {
      if (!this.positions || this.positions.length < 10) {
        this.goToFormsFinder(this.categoryId, this.config, this.suppressFilterLagerstelle);
      } else {
        this.$refs.fehlerModal.open();
      }
    },
    onChangeInputField(value, position, field) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [{ [field]: value, isin: position.isin }],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open();
    },
    doDeletePosition() {
      if (this.positionToDelete) {
        const deletePayload = {
          id: this.antragId,
          categoryId: this.categoryId,
          isin: this.positionToDelete.isin,
          posGrpId: this.positionToDelete.posGrpId,
        };
        this.$store.dispatch(
          ANTRAG_TYPES.ACTIONS.DELETE_POSITION,
          deletePayload
        );
        this.positionToDelete = null;
      }
    }
  },
};
</script>

<style scoped>
.input-forms__container {
  margin-bottom: 20px;
}
</style>
