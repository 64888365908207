<template>
  <div class="color-picker__container input-forms__container">
    <div class="color-picker--content">
      <div class="input-forms__label-content" v-if="label">{{ label }}</div>

      <BaseDropdownMenu ref="colorsDropdown" class="input-color-picker--dropdown" placement="bottom-left"
        containerClass="input-color-picker--dropdown-container" :disabled="disabled"
        :style="{ width: size + 'px', height: size + 'px' }">
        <template #hook-target>
          <button type="button" class="btn-clear input-color-picker--selected-color color-item" :class="getInputClass" aria-label="Ausgewählte Farbe"
            :style="selectedColorStyle">
            <PhX v-if="!selectedColor" :size="24" alt="Icon zur Anzeige der ausgewählten Farbe"/>
          </button>
        </template>
        <template #default>
          <div class="input-color-picker--colors__container">
            <div v-for="(colorArr, i) in allColors" :key="'r' + i" class="input-color-picker--colors">
              <div v-for="(color, i) in colorArr" :key="'c' + i" class="input-color-picker--color-item">
                <div class="color-item" :style="{ 'background-color': color }" @click="onChange(color)"></div>
              </div>
            </div>
          </div>
          <BaseButton isSecondary class="mt-3" @click="moreColors()">
            Weitere Farben
          </BaseButton>
          <BaseButton isPrimary class="mt-3" @click="automaticColor()">
            Automatisch
          </BaseButton>
        </template>
      </BaseDropdownMenu>

      <div class="input-color-picker--open-modal">
        <button type="button" class="btn-clear input-color-picker--selected-color color-item" :class="getInputClass" aria-label="Ausgewählte Farbe"
          :style="selectedColorStyle" @click="openColorsModal()">
            <PhX v-if="!selectedColor" :size="24" alt="Icon zur Anzeige der ausgewählten Farbe"/>
          </button>
      </div>

      <BaseSheetModal ref="colorsModal" modalTitle="">
        <div class="input-color-picker--colors__container input-color-picker--colors__container-modal">
          <div v-for="(colorArr, i) in allColors" :key="'r' + i" class="input-color-picker--colors">
            <div v-for="(color, i) in colorArr" :key="'c' + i" class="input-color-picker--color-item">
              <div class="color-item" :style="{ 'background-color': color }"
                @click="onChange(color); closeColorsModal()">
              </div>
            </div>
          </div>
        </div>
        <div class="action-list">
          <BaseButton isSecondary @click="moreColors()">
            Weitere Farben
          </BaseButton>
          <BaseButton isPrimary class="" @click="automaticColor();  closeColorsModal()">
            Automatisch
          </BaseButton>
        </div>
      </BaseSheetModal>
      <BaseModal ref="moreColors" modalTitle="Farbe auswählen" labelButtonConfirm="Ok" :confirmDisabled="!isColorValid"
        @onConfirmButton="saveColor" @close="closeMoreColors()">
        <div class="row">
          <div class="col-8" v-if="allColorsExtended">
            <div class="row p-0 m-0">
              <div class="col fg-n p-0 m-0" v-for="(colorsExtendedArr, i) in allColorsExtended.colors" :key="'a' + i">
                <div class="input-color-picker--colors__container input-color-picker--colors__container-modal">
                  <div v-for="(colorArr, i) in colorsExtendedArr" :key="'r' + i" class="input-color-picker--colors">
                    <div v-for="(color, i) in colorArr" :key="'c' + i" class="input-color-picker--color-item">
                      <div class="color-item color-item-sm" :style="{ 'background-color': color }"
                        @click="setColor(color);">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col fg-n p-0 m-0">
              <div
                class="input-color-picker--colors__container input-color-picker--colors__container-modal text-variations">
                <div v-for="(color, i) in allColorsExtended.textVariations" :key="'ct' + i"
                  class="input-color-picker--color-item">
                  <div class="color-item color-item-sm" :style="{ 'background-color': color }"
                    @click="setColor(color);">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div>Hervorheben</div>
            <div class="color-pin" :style="{ 'background-color': colorPin }"></div>
            <InputField v-model="colorPin" label="Ausgewählte Farbe" />
            <BaseButton isSecondary @click="removeColor()">
              Entfernen
            </BaseButton>
          </div>
        </div>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';
import BaseSheetModal from '@/components/core/BaseSheetModal.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import { PhX } from 'phosphor-vue'

import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import { getColorLimitedListBasedOnLightingDecreasing, rgbToHex, REGEX_COLOR_HEX } from '@/helpers/colors-helper';

const AUTOMATIC_COLOR_PREVIEW = '#000'

export default {
  mixins: [validatorComponentUtils],
  components: {
    BaseDropdownMenu,
    BaseSheetModal,
    BaseButton,
    InputField,
    BaseModal,
    PhX
  },
  props: {
    inputStyle: {
      type: [String, Array],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    stringOnFormGroup: {
      type: Boolean,
      default: false
    },
    invalidMessage: {
      type: String,
      default: ''
    },
    validateUntouched: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Object, Date]
    },
    label: {
      type: String
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '18'
    },
  },
  data: function () {
    return {
      selectedColor: '',
      allColors: [],
      allColorsExtended: [],
      colorPin: '',
    }
  },
  computed: {
    getInputClass() {
      return {
        'fc-form-danger': this.isValidationConfigured() && this.validation.isInvalid(this.validationPath, this.validateUntouched),
        'disabled': this.disabled,
      };
    },
    selectedColorStyle() {
      return {
        'background-color': this.selectedColor || AUTOMATIC_COLOR_PREVIEW,
        'width': `${this.size}px`,
        'height': `${this.size}px`,
      };
    },
    isColorValid() {
      return REGEX_COLOR_HEX.test(this.colorPin);
    }
  },
  watch: {
    value(val) {
      this.selectedColor = val;
      this.$runValidator(this.value);
    },
    disabled() {
      if (this.disabled) {
        this.$reset();
      } else {
        this.$runValidator(this.internalValue);
      }
    },
  },
  methods: {
    onChange(color) {
      if (color != '' && !REGEX_COLOR_HEX.test(color)) {
        return;
      }
      this.selectedColor = color;
      this.$emit('input', color);
      this.$emit('change', color)
    },
    colors() {
      const colorPrimary = document.documentElement.style.getPropertyValue('--color-primary')
      const colorSecondary = document.documentElement.style.getPropertyValue('--color-secondary')
      const colorSuccess = document.documentElement.style.getPropertyValue('--color-success')
      const colorDanger = document.documentElement.style.getPropertyValue('--color-danger')
      const colorWarning = document.documentElement.style.getPropertyValue('--color-warning')
      const customColors = ['#cff', '#ccf', '#fcc', '#fcf', '#ffc', '#cfc'];

      const textVariations = [
        'rgb(200, 200, 200)',
        'rgb(170, 170, 170)',
        'rgb(140, 140, 140)',
        'rgb(110, 110, 110)',
        'rgb(80, 80, 80)',
        'rgb(50, 50, 50)',
        'rgb(20, 20, 20)',
        'rgb(0, 0, 0)',].map(rgbToHex);

      const colorsVariations = [
        ...customColors,
        colorPrimary,
        colorSecondary,
        colorSuccess,
        colorDanger,
        colorWarning
      ].map(color => getColorLimitedListBasedOnLightingDecreasing(color, { quantity: 8, fromStepsHigher: 2 }).map(rgbToHex))

      return [
        textVariations,
        ...colorsVariations,
      ]
    },
    colorsExtended() {
      const customColors1 = ['#00002e', '#003300', '#006633', '#009933', '#00cc33', '#00ff0d'];
      const customColors2 = ['#33000d', '#333300', '#33660d', '#339901', '#33cc01', '#33ff33'];
      const customColors3 = ['#660000', '#663333', '#666633', '#669933', '#66cc01', '#66ff33'];
      const customColors4 = ['#990000', '#993333', '#996633', '#999933', '#99cc11', '#99ff33'];
      const customColors5 = ['#cc0033', '#cc3333', '#cc6633', '#cc9933', '#cccc11', '#ccff00'];
      const customColors6 = ['#ff0011', '#ff3311', '#ff6633', '#ff9933', '#ffcc11', '#ffff01'];

      const textVariations = [
        'rgb(0, 0, 0)',
        'rgb(14, 14, 14)',
        'rgb(20, 20, 20)',
        'rgb(30, 30, 30)',
        'rgb(44, 44, 44)',
        'rgb(68, 68, 68)',
        'rgb(85, 85, 85)',
        'rgb(102, 102, 102)',
        'rgb(119, 119, 119)',
        'rgb(136, 136, 136)',
        'rgb(153, 153, 153)',
        'rgb(170, 170, 170)',
        'rgb(187, 187, 187)',
        'rgb(204, 204, 204)',
        'rgb(221, 221, 221)',
        'rgb(238, 238, 238)',
        'rgb(255, 255, 255)',
        'rgb(255, 255, 255)'].map(rgbToHex);

      const level = 90;
      const colorsVariations1 = [
        ...customColors1
      ].map(color => getColorLimitedListBasedOnLightingDecreasing(color, { quantity: 8, level }).reverse().map(rgbToHex))
      const colorsVariations2 = [
        ...customColors2
      ].map(color => getColorLimitedListBasedOnLightingDecreasing(color, { quantity: 8, level }).reverse().map(rgbToHex))
      const colorsVariations3 = [
        ...customColors3
      ].map(color => getColorLimitedListBasedOnLightingDecreasing(color, { quantity: 8, level }).reverse().map(rgbToHex))
      const colorsVariations4 = [
        ...customColors4
      ].map(color => getColorLimitedListBasedOnLightingDecreasing(color, { quantity: 8, level }).reverse().map(rgbToHex))
      const colorsVariations5 = [
        ...customColors5
      ].map(color => getColorLimitedListBasedOnLightingDecreasing(color, { quantity: 8, level }).reverse().map(rgbToHex))
      const colorsVariations6 = [
        ...customColors6
      ].map(color => getColorLimitedListBasedOnLightingDecreasing(color, { quantity: 8, level }).reverse().map(rgbToHex))

      return {
        colors: [
          colorsVariations1,
          colorsVariations2,
          colorsVariations3,
          colorsVariations4,
          colorsVariations5,
          colorsVariations6,
        ],
        textVariations
      }
    },
    openColorsModal() {
      this.$refs.colorsModal?.open();
    },
    closeColorsModal() {
      this.$refs.colorsModal?.close();
    },
    handleResize() {
      this.$refs.colorsDropdown?.close();
      this.$refs.colorsModal?.close();
    },
    moreColors() {
      this.$refs.moreColors.open();
      this.colorPin = this.value;
    },
    removeColor() {
      this.colorPin = '';
    },
    automaticColor() {
      this.onChange('')
    },
    setColor(color) {
      this.colorPin = color;
    },
    saveColor() {
      this.onChange(this.colorPin);
      this.$refs.colorsDropdown?.close();
      this.$refs.colorsModal?.close();
      this.$refs.moreColors?.close();
    },
    closeMoreColors() {
      this.removeColor();
      this.$refs.moreColors?.close();
    },
  },
  mounted() {
    this.selectedColor = this.value || '';
    this.allColors = this.colors();
    this.allColorsExtended = this.colorsExtended();
    this.$runValidator(this.value, true);

    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style lang="scss" scoped>
.action-list {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.fg-n {
  flex-grow: initial;
}

.input-color-picker--colors__container-modal.text-variations {
  justify-content: start;
}

.color-item.color-item-sm {
  width: 18px;
  height: 18px;
  margin: 0;
  border: none;
  border-radius: 0;
  padding: 0;
}

.color-item {
  width: 18px;
  height: 18px;
  color: var(--color-text);
  border: 1px solid;
  border-color: var(--color-text);
  border-radius: 4px;
  margin: 0 0.25rem 0.25rem 0;
}

.input-color-picker--selected-color {
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.input-color-picker--colors__container {
  display: flex;
}

.input-color-picker--colors:last-child .color-item {
  margin-right: 0;
}

.input-color-picker--color-item:last-child .color-item {
  margin-bottom: 0;
}

.input-color-picker--open-modal {
  display: none;
}

.input-color-picker--colors__container-modal {
  justify-content: center;
}

.color-pin {
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 400px) {
  .input-color-picker--dropdown {
    display: none;
  }

  .input-color-picker--open-modal {
    display: block;
  }
}
</style>

<!-- GLOBAL STYLE -->
<style lang="scss">
.dropdown-menu__container.input-color-picker--dropdown-container {
  padding: 8px;

  &.positioned-top-left,
  &.positioned-bottom-left {
    margin-left: -14px;
  }

  &.positioned-top-right,
  &.positioned-bottom-right {
    margin-left: 14px;
  }
}
</style>
