<template>
  <div class="persoenliche-daten-view">
    <CustomerDataNavigation :subtitle="fullName" />

    <StepperForm
      ref="stepperForm"
      stepType="tab"
      stepperName="customer-data"
      :stepperMediator="tabsStepperMediator"
      :hasFertig="false"
      :selectedStepKey="step"
      :selectedSubstepKey="substep"
      :hideNavigationButtons="isFocusModeEnable"
      :backNavigationOnClose="isFocusModeEnable"
      :additionalActionsForMobile="additionalActionsForMobile"
      @set-step="navigateToPath($event)"
      @set-substep="navigateToSubstepPath($event)"
      @handleOtherActions="handleMobileActions"
    >
      <template #headerTemplate>
        <HinweiseUndFehler :errors="warnings" :hints="hints" :errorTitle="errorTitle"
          @set-step="navigateToPath($event)" @set-substep="navigateToSubstepPath($event)" />
      </template>
      <template #contentTemplate>
        <router-view></router-view>
      </template>
    </StepperForm>
  </div>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex'
import { StepperMediator } from '@/components/stepper/utils';

import CustomerDataNavigation from '@/components/persoenliche-daten/components/CustomerDataNavigation.vue';
import StepperForm from '@/components/stepper/StepperForm.vue';
import HinweiseUndFehler from '@/components/core/HinweiseUndFehler.vue';

import { MAP_CUSTOMER_ROUTE_PATH_TO_SUBSTEPS } from './persoenliche-daten-steps-utils';
import { FIRMA_AVAILABLE_STEPS } from './persoenliche-daten-steps-utils';
import { prepareDepoteroffnungenWarningsCustomer, hasDepoteroffnungenWarnings } from './persoenliche-daten-warnings-utils';

import persoenlicheDatenMixin from './persoenliche-daten-mixin';

const HINT_GROUP_DISABLED = 'NICHT_EDITIERBAR';

export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      tabs: [],
      stepperCollapsed: false,
      nextCall: false,
      errorTitle: 'Fehlen notwendige Daten',
    };
  },
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      hints: CUSTOMERDATA_TYPES.GETTERS.HINTS,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
    }),
    personalDataAddress() {
      return this.customerData?.personalDataAddress
    },
    personalDataAddressEdited() {
      return this.customerDataEdited?.personalDataAddress
    },
    title() {
      return this.personalDataAddressEdited?.title || this.personalDataAddress?.title
    },
    getPersonId() {
      return this.$route.query.personId || 'person1'
    },
    tabsStepperMediator() {
      return new StepperMediator(this.tabs);
    },
    warnings() {
      console.log('warnings', prepareDepoteroffnungenWarningsCustomer(this.depoteroffnungenWarnings, this.isFirma, true) );
      return [...prepareDepoteroffnungenWarningsCustomer(this.depoteroffnungenWarnings, this.isFirma, true)];
    },
    isSmallOrMediumScreen() {
      return this.$isSmallScreen || this.$isMediumScreen;
    },
    isFocusModeEnable() {
      const isFocusMode = !!(this.$route.query?.focusMode);
      return this.isSmallOrMediumScreen && isFocusMode;
    },
  },
  watch: {
    title(newTitle, oldTitle) {
      if(newTitle !== oldTitle) {
        this.setTabs();
      }
    },
    depoteroffnungenWarnings() {
      this.setTabs();
    },
  },
  methods: {
    async init() {
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, 'person1');
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_CUSTOMER_DATA_EDITED);
      await this.findData();
      this.configureTabs();

      // add hint, if original user is a customer and not editable
      if(!this.isByPass && !this.isEditable && !this.hints?.some(hint => hint.group === HINT_GROUP_DISABLED)) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP_DISABLED,
          title: 'Kundendaten sind nicht editierbar',
          message: 'Kundendaten sind nicht editierbar, es besteht ein aktives Vertragsverhältnis. Bitte wenden Sie sich an Ihren Berater.'
        });
      }
    },
    async findData() {
      const promiseToWait = this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA, {newCustomer: this.$route.query.newCustomer});
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG),
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS, { list: true });
      await promiseToWait;
    },
    configureTabs() {
      setTimeout(() => {
        this.setTabs();
        this.$refs.stepperForm?.setStepByKey(this.step);
        this.$refs.stepperForm?.setSubstepByKey(this.step, this.substep);
      });
    },
    setTabs() {
      this.tabs = this.findTabs();
    },
    findTabs() {
      const extraKey = `${this.title === 'Firma' ? 'firma' : 'privat'}`;
      const steps = this.$appNavigation.currentTabMenu
      ?.filter((menu) => {
        return !this.isFirma || FIRMA_AVAILABLE_STEPS.has(menu.path);
      })
        .map((menu, i) => { 
          const key = `${menu.path}`;
          return {
            stepKey: menu.path, 
            label: menu.label, 
            current: false, 
            totalProgress: i + 1,
            substeps: MAP_CUSTOMER_ROUTE_PATH_TO_SUBSTEPS[key] || MAP_CUSTOMER_ROUTE_PATH_TO_SUBSTEPS[`${key}:${extraKey}`] || null,
          };
        });
        if(this.isFirma) {
          const personStep = steps.find(step => {
            return step.stepKey === '/persoenlichedaten/customer-data/zusaetzliche-personen';
        });
        if(personStep) {
          personStep.label = personStep.label + ' | Wirtschaftlich berechtigte Personen';
        }
      }

      return steps.map(step => {
        const hasWarning = hasDepoteroffnungenWarnings(this.depoteroffnungenWarnings, step.stepKey, this.isFirma, true);
        return {
          ...step,
          warning: hasWarning,
          substeps: step.substeps?.map(substep => ({
            ...substep,
            warning: hasWarning && step.substeps.length > 1 && this.warnings?.some(warning => warning?.substepKey === substep.substepKey),
          }))
        };
      });
    },
  },
  mounted() {
    this.init();
  },
  async beforeRouteLeave(to, from, next) {
    // nextCall needed because of route change in confirmModal
    if (this.nextCall) {
      this.nextCall = false;
      next();
    } else {

      let isEdited = this.checkCustomerDataEdited();
      const isCreatedRecently = this.customerData?.isCreatedRecently;
      if (isEdited) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_STATE_STAMMDATEN);
      }
      isEdited = this.isStateStammdaten;

      await this.saveChanges();

      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_HINTS);
      if(to?.path?.indexOf('person-data') < 0 && to?.path?.indexOf('bemerkungen/bearbeiten') < 0) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_STATE);
      }

      this.$addBreadcrumb({
        label: 'zurück zur Hauptperson', 
        to,
        from,
      });

      if ((isEdited || this.isStateStammdaten) && !isCreatedRecently) {
        this.nextCall = true;

        this.$confirmModal({
          title: 'Änderungsformulare ausfüllen',
          message: 'Möchten Sie Änderungsformulare für die Gesellschaften des Kunden ausfüllen?',
          labelButtonCancel: 'Nein',
          labelButtonConfirm: 'Ja',
          showCancelButton: true,
        }).then(() => {
          this.$router.push({
            path: `/beratung/formulare/antrag/FK/aenderungsformular/STEP_LAGERSTELLEN?hideBeratungsmappepopup=true&lagerstelleLabel=weitere%20Unterlagen`
          });
        }).catch(() => {
          next();
        });
      } else {
        next();
      }
    }
  },
  components: {
    CustomerDataNavigation,
    StepperForm,
    HinweiseUndFehler,
  }
}
</script>

<style scoped>

.router-link-active {
  font-weight: bold;
}
</style>
