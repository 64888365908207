<template>
  <div>
    <OptionMenu
      :id="$appNavigation.parentOptionMenuId"
      :defaultMenu="$appNavigation.parentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :title="title"
      subtitle="Einstellungen"
      :actions="headerActions"
      @action-NEW_BEMERKUNG="createBemerkung"
      @action-VV_BUTTON_CHANGE_KOMMENTAR_ALL="openKommentarAllModal"
      @action-VV_UPLOAD_DOCUMENT="uploadDocument"
    />

    <AntragSidetext
      :warnings="warnings"
      :hinweiseProps="hinweise"
      :steps="stepper.getCurrentStepList()"
      hinweisText="Die Strategie ist fehlerhaft"
      showMessageErrorMustBeFixed
      :showExpanded="showExpanded"
      :highestStepVisited="4"
      @setStep="setStepByKey($event)"
      @setSubstep="setSubstepByKey($event)"
    />

    <StepperForm
      ref="stepperForm"
      stepType="tab"
      stepperName="vv-schema-einstellungen-stepper"
      :stepperMediator="stepper"
      :selectedStepKey="stepKey"
      :selectedSubstepKey="substepKey"
      @set-step="setStepByKey"
      @set-substep="setSubstepByKey"
      @on-fertig="$goToLastPage()"
    >
      <template #contentTemplate>
        <router-view></router-view>
      </template>
    </StepperForm>

  </div>
</template>

<script>
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";
import EVENT_BUS, { BEMERKUNGEN_CREATE, VV_BUTTON_CHANGE_KOMMENTAR_ALL, VV_UPLOAD_DOCUMENT } from '@/event-bus';

import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import AntragSidetext from "@/components/antrag/AntragSidetext.vue";
import StepperForm from "@/components/stepper/StepperForm.vue";
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';


export default {
  mixins: [mixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    AntragSidetext,
    StepperForm,
  },
  data() {
    return { showExpanded: false };
  },
  mounted() {},
  computed: {
    title() {
      return this.schemaData?.title || "";
    },
    headerActions() {
      return [
        PageHeaderSimpleAction("VV_BUTTON_CHANGE_KOMMENTAR_ALL", "Marktkommentar für alle ändern")
          .withVisible(() => this.schemaData.isVermoegensverwalter && this.stepKey === 'descriptions'),
        PageHeaderSimpleAction('VV_UPLOAD_DOCUMENT', 'Datei hochladen')
          .withVisible(() => this.stepKey === 'documents'),
        PageHeaderSimpleAction('NEW_BEMERKUNG', 'Neue Bemerkung')
          .withVisible(() => this.stepKey === 'bemerkungen'),
      ];
    },
  },
  methods: {
    createBemerkung() {
        this.$addBreadcrumb({
          label: `nothing`,
          fullPath: this.$route.fullPath,
        });

        EVENT_BUS.$emit(BEMERKUNGEN_CREATE);
      },
      openKommentarAllModal() {
        EVENT_BUS.$emit(VV_BUTTON_CHANGE_KOMMENTAR_ALL);
      },
      uploadDocument() {
        EVENT_BUS.$emit(VV_UPLOAD_DOCUMENT);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store
      .dispatch(VV_TYPES.ACTIONS.SAVE_SCHEMA, {
        schemaId: this.schemaId,
        stepKey: this.stepKey === "documents" ? this.stepKey : this.substepKey,
      })
      .finally(() => next());
  },
};
</script>

<style>
</style>