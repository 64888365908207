<template>
    <BemerkungenList 
      :records="records" 
      :loading="loading" 
      typ="vvschema"
      @delete="deleteBemerkungen({ids: [$event.id]})"
      @deleteSelected="deleteBemerkungen"
    />
</template>

<script>
import BemerkungenList from "@/components/persoenliche-daten/BemerkungenList.vue";
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

export default {
  components: {
    BemerkungenList,
  },
  data() {
    return {
      records: [],
      loading: false,
    }
  },
  mounted() {
    this.loadBemerkungen()
  },
  computed: {
    substepKey() {
      return this.$route.query.substepKey || "settings";
    },
  },
  methods: {
    loadBemerkungen() {
        this.loading = true;
        
        axios.get(`${process.env.VUE_APP_API}/vv/bemerkungen?schemaId=${this.$route.params.schemaId}`, { defaultSpinner: true, })
        .then(response => {
            this.records = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteBemerkungen({ids, next}) {
      const records = this.records || []
      const params = makeQueryParam({ids, typ: "vvschema"})

      axios.delete(`${process.env.VUE_APP_API}/postfachBemerkung/bemerkungen?${params}`, {defaultSpinner: true})
      .then(response => {
        if (response && response.data && response.data.length) {
          response.data.forEach(id => {
            const index = records.findIndex(record => record.id == id);
            if (index >= 0)
              records.splice(index, 1);
          })

          if (typeof next === "function") {
            next()
          }
        }
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from, });
    next();
  },
};
</script>

<style scoped>
</style>