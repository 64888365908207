var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.headerActions,
          title: "Bemerkung"
        },
        on: { "action-FILE_SELECT": _vm.onFile, "action-PDF": _vm.showPDF }
      }),
      _c(
        "DragnDropArea",
        {
          attrs: {
            hoverText: "Datei hier ablegen",
            disabled: _vm.loading || _vm.isCustomerOnly
          },
          on: { files: _vm.onFile }
        },
        [
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex",
                  staticStyle: { "flex-wrap": "wrap", gap: "20px" }
                },
                [
                  _c("DatePickerField", {
                    staticStyle: { flex: "1 1 auto", "padding-bottom": "0px" },
                    attrs: {
                      label: "Datum",
                      isValueAsString: "",
                      disabled: _vm.loading || _vm.isCustomerOnly,
                      renderDanger: !_vm.bemerkungData.datum
                    },
                    on: {
                      input: function($event) {
                        _vm.bemerkungDataChanged = true
                      }
                    },
                    model: {
                      value: _vm.bemerkungData.datum,
                      callback: function($$v) {
                        _vm.$set(_vm.bemerkungData, "datum", $$v)
                      },
                      expression: "bemerkungData.datum"
                    }
                  }),
                  _c("DatePickerField", {
                    staticStyle: { flex: "1 1 auto", "padding-bottom": "0px" },
                    attrs: {
                      label: "Erstellt am",
                      value: _vm.bemerkung.dateCreated,
                      disabled: ""
                    }
                  }),
                  _c("DatePickerField", {
                    staticStyle: { flex: "1 1 auto" },
                    attrs: {
                      label: "Zuletzt geändert am",
                      value: _vm.bemerkung.dateModified,
                      disabled: ""
                    }
                  })
                ],
                1
              ),
              _c("ShortcutInputField", {
                attrs: {
                  label: "Thema",
                  disabled: _vm.loading || _vm.isCustomerOnly
                },
                on: {
                  input: function($event) {
                    _vm.bemerkungDataChanged = true
                  }
                },
                model: {
                  value: _vm.bemerkungData.betreff,
                  callback: function($$v) {
                    _vm.$set(_vm.bemerkungData, "betreff", $$v)
                  },
                  expression: "bemerkungData.betreff"
                }
              }),
              !_vm.isCustomerOnly
                ? _c("InputToggleSwitch", {
                    attrs: {
                      label: _vm.sonderHinweisLabel,
                      inLineLabel: "",
                      disabled: _vm.loading || _vm.isCustomerOnly
                    },
                    on: {
                      input: function($event) {
                        _vm.bemerkungDataChanged = true
                      }
                    },
                    model: {
                      value: _vm.bemerkungData.important,
                      callback: function($$v) {
                        _vm.$set(_vm.bemerkungData, "important", $$v)
                      },
                      expression: "bemerkungData.important"
                    }
                  })
                : _vm._e(),
              _vm.isBrokerOrBypass && _vm.isCustomer
                ? _c("InputToggleSwitch", {
                    attrs: {
                      label: "Für Kunde sichtbar",
                      inLineLabel: "",
                      disabled: _vm.loading || _vm.isCustomerOnly
                    },
                    on: {
                      input: function($event) {
                        _vm.bemerkungDataChanged = true
                      }
                    },
                    model: {
                      value: _vm.bemerkungData.sichtbar,
                      callback: function($$v) {
                        _vm.$set(_vm.bemerkungData, "sichtbar", $$v)
                      },
                      expression: "bemerkungData.sichtbar"
                    }
                  })
                : _vm._e(),
              _c("HtmlEditor", {
                staticClass: "mb-3",
                class: { "chat-editor": !_vm.isBemerkungTextValid },
                attrs: { isFontParams: "", disabled: _vm.isCustomerOnly },
                on: {
                  input: function($event) {
                    _vm.bemerkungDataChanged = true
                    _vm.validateText()
                  }
                },
                model: {
                  value: _vm.bemerkungData.text,
                  callback: function($$v) {
                    _vm.$set(_vm.bemerkungData, "text", $$v)
                  },
                  expression: "bemerkungData.text"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BemerkungFiles", {
                attrs: { title: "Anhänge", files: _vm.bemerkungFiles },
                on: {
                  deleteFile: function($event) {
                    return _vm.deleteAttachment($event)
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }