import RUHESTANDSPLANUNG_TYPES from './types';

export default {
  [RUHESTANDSPLANUNG_TYPES.GETTERS.SCENARIO](state) {
    return state.scenario;
  },
  [RUHESTANDSPLANUNG_TYPES.GETTERS.SCENARIO_LIST](state) {
    return state.scenarioList;
  },
  [RUHESTANDSPLANUNG_TYPES.GETTERS.VERMOEGEN](state) {
    return state.vermoegen;
  },
  [RUHESTANDSPLANUNG_TYPES.GETTERS.AUSGABEN](state) {
    return state.ausgaben;
  },
  [RUHESTANDSPLANUNG_TYPES.GETTERS.COMBOBOX_GOAL_TYPES](state) {
    return state.comboboxEventTypes;
  },
  [RUHESTANDSPLANUNG_TYPES.GETTERS.SCENARIO_URSPRUNG](state) {
    return state.scenarioUrsprung;
  },
}