import GESELLSCHAFT_TYPES from './types';
import CORE_TYPES from "@/store/core/types";

import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

const config = {
  defaultSpinner: true
};

const multipartConfig = {
  defaultSpinner: true,
  headers: {'Content-Type': 'multipart/form-data'},
};

export default {
  
  [GESELLSCHAFT_TYPES.ACTIONS.SAVE_USER_PICTURE]({ getters, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', payload.file);
      if(payload.personId) {
        formData.append('personId', payload.personId);
      }
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsuserpicture`, formData, multipartConfig)
        .then((response) => {
          dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_USER_PICTURE, payload);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  async [GESELLSCHAFT_TYPES.ACTIONS.GET_EIGENE_DOKUMENTE]({ getters, commit, }, { gesellschaftId, }) {
    if(!gesellschaftId) {
      return;
    }

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/eigeneDokumente?gesellschaftId=${gesellschaftId}`, config)
      .then(response => commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_EIGENE_DOKUMENTE_SUCCESS, response?.data || {}));
  },

  async [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_ID_LOGGED_USER]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/gesellschaft_id`, config);

    if (response?.data) {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_ID_LOGGED_USER_SUCCESS, response?.data);
    }
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_DATEN]({ getters, commit, state, }, { gesellschaftId, }) {

    if (state.gesellschaftData.daten) {
      return
    }

    const params = makeQueryParam({ gesellschaftId }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/daten?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_DATEN_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_LESEZEICHEN]({ getters, commit, state, }, { gesellschaftId, }) {

    if (state.gesellschaftData.lesezeichen) {
      return
    }

    const params = makeQueryParam({ gesellschaftId }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/lesezeichen?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_LESEZEICHEN_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_EINSTELLUNGEN]({ getters, commit, state, }, { gesellschaftId, }) {

    if (state.gesellschaftData.settings) {
      return
    }

    const params = makeQueryParam({ gesellschaftId }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/settings?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_EINSTELLUNGEN_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_MENU]({ getters, commit, state, }, payload) {
    
    if (state.gesellschaftData.menu) {
      return
    }

    commit(GESELLSCHAFT_TYPES.MUTATIONS.SET_GESELLSCHAFT, {gesellArt: payload.gesellArt})
    const params = makeQueryParam({ ...payload }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/menu?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_MENU_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_AENDERUNGSLOG]({ getters, commit, }, payload) {

    const params = makeQueryParam({ ...payload }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/aenderungslog?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_AENDERUNGSLOG_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_NUMMERN]({ getters, commit, state, }, payload) {

    if (state.gesellschaftData.nummern) {
      return
    }

    const params = makeQueryParam({ ...payload }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/nummern?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_NUMMERN_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_MEHRFACHPOSITIONEN]({ getters, commit, state, }, { isReload, payload, }) {

    if (state.gesellschaftData.mehrfachpositionen && !isReload) {
      return
    }

    const params = makeQueryParam({ ...payload }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/mehrfachpositionen?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_MEHRFACHPOSITIONEN_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_VERS_SPARTEN]({ getters, commit, state, }, { gesellschaftId, }) {
    
    if (state.gesellschaftData.versSparten) {
      return
    }

    const params = makeQueryParam({ gesellschaftId }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/versSparten?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_VERS_SPARTEN_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_PROVISION_VORGABEN]({ getters, commit, state, }, { gesellschaftId, isReload, }) {
    
    if (state.gesellschaftData.provisionVorgaben && !isReload) {
      return
    }

    const params = makeQueryParam({ gesellschaftId }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/provisionVorgaben?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_PROVISION_VORGABEN_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_FONDSAUFTRAG]({ getters, commit, state, }, { gesellschaftId, }) {
    
    if (state.gesellschaftData.fondsauftrag) {
      return
    }

    const params = makeQueryParam({ gesellschaftId }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/fondsauftrag?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_FONDSAUFTRAG_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_AKTIVITAETEN]({ getters, commit, state, }, { gesellschaftId, }) {
    
    if (state.gesellschaftData.aktivitaeten) {
      return
    }

    const params = makeQueryParam({ gesellschaftId }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/aktivitaeten?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_AKTIVITAETEN_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_KENNUNGEN]({ getters, commit, state, }, { gesellschaftId, isReload}) {
    
    if (state.gesellschaftData.kennungen && !isReload) {
      return
    }

    const params = makeQueryParam({ gesellschaftId }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/kennungen?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_KENNUNGEN_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_DOKUMENTE]({ getters, commit, state, }, { gesellschaftId, isReload}) {
    
    if (state.gesellschaftData.dokumente && !isReload) {
      return
    }

    const params = makeQueryParam({ gesellschaftId }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/dokumente?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_DOKUMENTE_SUCCESS, response.data)
    });
  },

  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_ANTRAGSBEARBEITUNG]({ getters, commit, state, }, { gesellschaftId, isReload}) {
    
    if (state.gesellschaftData.antragsbearbeitung && !isReload) {
      return
    }

    const params = makeQueryParam({ gesellschaftId }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/antragsbearbeitung?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_ANTRAGSBEARBEITUNG_SUCCESS, response.data)
    });
  },
  
  [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_BEMERKUNGEN]({ getters, commit, state, }, { payload, isReload }) {
    
    if (state.gesellschaftData.bemerkungen && !isReload) {
      return
    }

    const params = makeQueryParam({ ...payload }, true)

    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/bemerkungen?${params}`, config).then(response => {
      commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_BEMERKUNGEN_SUCCESS, response.data)
    });
  },

  async [GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFTS_INFOS]({ getters, commit, }, { gesellschaftId, }) {
    if(!gesellschaftId) {
      return;
    }

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/gesellschaftenService/gesellschaftsInfos?gesellschaftId=${gesellschaftId}`, config)
      .then(response => commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFTS_INFOS_SUCCESS, response?.data || {}));
  },
}