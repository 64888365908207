var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "color-picker__container input-forms__container" },
    [
      _c(
        "div",
        { staticClass: "color-picker--content" },
        [
          _vm.label
            ? _c("div", { staticClass: "input-forms__label-content" }, [
                _vm._v(_vm._s(_vm.label))
              ])
            : _vm._e(),
          _c("BaseDropdownMenu", {
            ref: "colorsDropdown",
            staticClass: "input-color-picker--dropdown",
            style: { width: _vm.size + "px", height: _vm.size + "px" },
            attrs: {
              placement: "bottom-left",
              containerClass: "input-color-picker--dropdown-container",
              disabled: _vm.disabled
            },
            scopedSlots: _vm._u([
              {
                key: "hook-target",
                fn: function() {
                  return [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-clear input-color-picker--selected-color color-item",
                        class: _vm.getInputClass,
                        style: _vm.selectedColorStyle,
                        attrs: {
                          type: "button",
                          "aria-label": "Ausgewählte Farbe"
                        }
                      },
                      [
                        !_vm.selectedColor
                          ? _c("PhX", {
                              attrs: {
                                size: 24,
                                alt: "Icon zur Anzeige der ausgewählten Farbe"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "input-color-picker--colors__container" },
                      _vm._l(_vm.allColors, function(colorArr, i) {
                        return _c(
                          "div",
                          {
                            key: "r" + i,
                            staticClass: "input-color-picker--colors"
                          },
                          _vm._l(colorArr, function(color, i) {
                            return _c(
                              "div",
                              {
                                key: "c" + i,
                                staticClass: "input-color-picker--color-item"
                              },
                              [
                                _c("div", {
                                  staticClass: "color-item",
                                  style: { "background-color": color },
                                  on: {
                                    click: function($event) {
                                      return _vm.onChange(color)
                                    }
                                  }
                                })
                              ]
                            )
                          }),
                          0
                        )
                      }),
                      0
                    ),
                    _c(
                      "BaseButton",
                      {
                        staticClass: "mt-3",
                        attrs: { isSecondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.moreColors()
                          }
                        }
                      },
                      [_vm._v(" Weitere Farben ")]
                    ),
                    _c(
                      "BaseButton",
                      {
                        staticClass: "mt-3",
                        attrs: { isPrimary: "" },
                        on: {
                          click: function($event) {
                            return _vm.automaticColor()
                          }
                        }
                      },
                      [_vm._v(" Automatisch ")]
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("div", { staticClass: "input-color-picker--open-modal" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn-clear input-color-picker--selected-color color-item",
                class: _vm.getInputClass,
                style: _vm.selectedColorStyle,
                attrs: { type: "button", "aria-label": "Ausgewählte Farbe" },
                on: {
                  click: function($event) {
                    return _vm.openColorsModal()
                  }
                }
              },
              [
                !_vm.selectedColor
                  ? _c("PhX", {
                      attrs: {
                        size: 24,
                        alt: "Icon zur Anzeige der ausgewählten Farbe"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _c(
            "BaseSheetModal",
            { ref: "colorsModal", attrs: { modalTitle: "" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "input-color-picker--colors__container input-color-picker--colors__container-modal"
                },
                _vm._l(_vm.allColors, function(colorArr, i) {
                  return _c(
                    "div",
                    { key: "r" + i, staticClass: "input-color-picker--colors" },
                    _vm._l(colorArr, function(color, i) {
                      return _c(
                        "div",
                        {
                          key: "c" + i,
                          staticClass: "input-color-picker--color-item"
                        },
                        [
                          _c("div", {
                            staticClass: "color-item",
                            style: { "background-color": color },
                            on: {
                              click: function($event) {
                                _vm.onChange(color)
                                _vm.closeColorsModal()
                              }
                            }
                          })
                        ]
                      )
                    }),
                    0
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "action-list" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.moreColors()
                        }
                      }
                    },
                    [_vm._v(" Weitere Farben ")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: { isPrimary: "" },
                      on: {
                        click: function($event) {
                          _vm.automaticColor()
                          _vm.closeColorsModal()
                        }
                      }
                    },
                    [_vm._v(" Automatisch ")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "BaseModal",
            {
              ref: "moreColors",
              attrs: {
                modalTitle: "Farbe auswählen",
                labelButtonConfirm: "Ok",
                confirmDisabled: !_vm.isColorValid
              },
              on: {
                onConfirmButton: _vm.saveColor,
                close: function($event) {
                  return _vm.closeMoreColors()
                }
              }
            },
            [
              _c("div", { staticClass: "row" }, [
                _vm.allColorsExtended
                  ? _c("div", { staticClass: "col-8" }, [
                      _c(
                        "div",
                        { staticClass: "row p-0 m-0" },
                        _vm._l(_vm.allColorsExtended.colors, function(
                          colorsExtendedArr,
                          i
                        ) {
                          return _c(
                            "div",
                            { key: "a" + i, staticClass: "col fg-n p-0 m-0" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "input-color-picker--colors__container input-color-picker--colors__container-modal"
                                },
                                _vm._l(colorsExtendedArr, function(
                                  colorArr,
                                  i
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: "r" + i,
                                      staticClass: "input-color-picker--colors"
                                    },
                                    _vm._l(colorArr, function(color, i) {
                                      return _c(
                                        "div",
                                        {
                                          key: "c" + i,
                                          staticClass:
                                            "input-color-picker--color-item"
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "color-item color-item-sm",
                                            style: {
                                              "background-color": color
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.setColor(color)
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "col fg-n p-0 m-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-color-picker--colors__container input-color-picker--colors__container-modal text-variations"
                          },
                          _vm._l(_vm.allColorsExtended.textVariations, function(
                            color,
                            i
                          ) {
                            return _c(
                              "div",
                              {
                                key: "ct" + i,
                                staticClass: "input-color-picker--color-item"
                              },
                              [
                                _c("div", {
                                  staticClass: "color-item color-item-sm",
                                  style: { "background-color": color },
                                  on: {
                                    click: function($event) {
                                      return _vm.setColor(color)
                                    }
                                  }
                                })
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c("div", [_vm._v("Hervorheben")]),
                    _c("div", {
                      staticClass: "color-pin",
                      style: { "background-color": _vm.colorPin }
                    }),
                    _c("InputField", {
                      attrs: { label: "Ausgewählte Farbe" },
                      model: {
                        value: _vm.colorPin,
                        callback: function($$v) {
                          _vm.colorPin = $$v
                        },
                        expression: "colorPin"
                      }
                    }),
                    _c(
                      "BaseButton",
                      {
                        attrs: { isSecondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.removeColor()
                          }
                        }
                      },
                      [_vm._v(" Entfernen ")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }