<template>
    <div>
        <component v-if="dynamicTemplate" :is="dynamicTemplate" 
        />
    </div>
</template>

<script>
import mixin from '@/views/ruhestandsplanung/mixin.js';

export default {
    mixins: [mixin],
    computed: {
        stepKey () {
            return this.$route.params?.stepKey || '';
        },
        dynamicTemplate() {
            if (this.stepKey) {
                switch (this.stepKey) {
                case 'settings':
                    return () => import('@/views/ruhestandsplanung/Settings.vue')
                case 'gesamtvermoegen':
                    return () => import('@/views/ruhestandsplanung/GesamtVermoegenStep.vue')
                case 'scenario':
                    return () => import('@/views/ruhestandsplanung/Scenario.vue')
                case 'overview':
                    return () => import('@/views/ruhestandsplanung/Overview.vue')
                }
            }
        },
    }
}
</script>

<style>

</style>