<template>
  <div>
    <div v-if="!contracts">
      <span>Keine Daten</span>
    </div>
    <div v-else>
      <Table :headers="headers" :rows="rows" :rowsPerPage="10"
        :mobileConfig="{ title: 'accountDisplayName', headers: ['categoryDisplayName', 'amount'] }" />
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import dayjs from "dayjs";
import Table from "@/components/table2/Table.vue";
import { TextColumn, DateColumn, CurrencyColumn,  ActionColumn } from "@/components/table2/table_util.js";
export default {
  components: {
    Table,
  },

  props: {
    contracts: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data: function () {
    return {
      selectedFilter: {},
      headers: {
        lockedLeft: [
          TextColumn("name", "Name"),
          CurrencyColumn("amountTotal", "Gesamtbetrag"),
        ],
        center: [
          DateColumn("dateFirst", "Erstes Datum"),
          DateColumn("dateLatest", "Letztes Datum"),
          DateColumn("dateNext", "Nächstes Datum"),
          CurrencyColumn("amountFirst", "Erster Betrag"),
          CurrencyColumn("amountLatest", "Letzter Betrag"),
          CurrencyColumn("amountNext", "Nächster Betrag"),
          TextColumn("category", "Kategorien"),
          TextColumn("period", "Turnus"),
          TextColumn("insurance", "Versicherungsarten")
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ],
      }
    }
  },

  computed: {
    ...mapGetters({

    }),
    rows() {
      const actions = []
      return this.contracts.map((contract) => ({
        name: contract.details.counterpartyName,
        dateFirst: contract.details.dateFirst ? dayjs(contract.details.dateFirst).format('DD.MM.YYYY') : null,
        dateLatest: contract.details.dateLatest ? dayjs(contract.details.dateLatest).format('DD.MM.YYYY') : null,
        dateNext: contract.details.dateNext ? dayjs(contract.details.dateNext).format('DD.MM.YYYY') : null,
        amountFirst: contract.details.amountFirst,
        amountLatest: contract.details.amountLatest,
        amountNext: contract.details.amountNext,
        amountTotal: contract.details.amountTotal,
        category: contract.tagTreeItemCategory.displayName,
        period: contract.tagTreeItemPeriod.displayName,
        insurance: contract.tagTreeItemInsuranceType.displayName,
        contract,
        actions,
      }))
    },
  },

  methods: {

  },
}
</script>

<style scoped></style>