var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex flex-col my-2" }, [
    _c("div", { staticClass: "customer-overview__container" }, [
      _c("div", { staticClass: "customer-overview--image" }, [
        _c("div", { staticClass: "img-wrap" }, [
          _c("img", { attrs: { src: _vm.betreuer.bildUrl } })
        ])
      ]),
      _c("div", { staticClass: "customer-overview--content pl-2" }, [
        _c("span", { staticClass: "font-bold" }, [
          _vm._v(_vm._s(_vm.betreuer.fullName))
        ]),
        _c("span", [_vm._v(_vm._s(_vm.betreuer.berufsbezeichnung))]),
        _c("span", [_vm._v(_vm._s(_vm.betreuer.email))]),
        _c("span", { staticClass: "mb-1" }, [
          _vm._v(_vm._s(_vm.betreuer.telefon))
        ])
      ])
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-2" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.fondskonzept.ag/ihre-ansprechpartner",
            target: "_blank"
          }
        },
        [_vm._v("Hier finden Sie alle Ansprechpartner der Fondskonzept AG")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }