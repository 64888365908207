var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.messageListReverse.length,
      ref: "container",
      staticClass: "container"
    },
    [
      _vm._l(_vm.messageListReverse, function(message) {
        return _c("div", { key: message.messageId }, [
          _c(
            "div",
            {
              staticClass: "message",
              class: [
                message.direction
                  ? message.direction == _vm.sending
                    ? "chat-sender"
                    : "chat-receiver"
                  : "chat-center",
                message.isInternalRemark ? "internalRemark" : ""
              ]
            },
            [
              _vm.showMessageBody(message)
                ? _c(
                    "div",
                    { staticClass: "message-body" },
                    [
                      _c("span", {
                        staticClass: "message-text",
                        domProps: { innerHTML: _vm._s(message.messageText) },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onMessageClick.apply(null, arguments)
                          }
                        }
                      }),
                      message.newStatus && message.messageText
                        ? _c("span", {
                            staticClass: "chat-info",
                            domProps: { innerHTML: _vm._s(message.newStatus) }
                          })
                        : _vm._e(),
                      _vm._l(message.imgAttachments, function(imgAttachment) {
                        return _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "message-real-download-link__container d-none"
                            },
                            [
                              _c(
                                "DownloadLink",
                                {
                                  key: imgAttachment.id,
                                  attrs: {
                                    asButton: "",
                                    title: "Anhang",
                                    downloadServicePath: "/getChatAtt",
                                    filename: imgAttachment.name,
                                    queryParams: { id: imgAttachment.id },
                                    "data-img-attachment-id": imgAttachment.id
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.setImgSrc(
                                        imgAttachment,
                                        message.direction
                                      )
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "forward" }, [
                            _c(
                              "a",
                              {
                                staticClass: "d-flex px-0",
                                staticStyle: { "text-overflow": "ellipsis" },
                                attrs: {
                                  rel: "noopener noreferer",
                                  draggable: "false",
                                  href: null
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openLinkAttachmentToInsuranceModal(
                                      imgAttachment,
                                      null,
                                      _vm.UPLOAD_ATTACHMENT
                                    )
                                  }
                                }
                              },
                              [
                                _c("ph-share", {
                                  staticClass: "mr-8px cursor-pointer",
                                  attrs: { size: 12 }
                                }),
                                _vm._v(" Einem Vertrag zuweisen ")
                              ],
                              1
                            ),
                            _vm.customerId
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "d-flex px-0",
                                    staticStyle: {
                                      "text-overflow": "ellipsis"
                                    },
                                    attrs: {
                                      rel: "noopener noreferer",
                                      draggable: "false",
                                      href: null
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openLinkAttachmentToInsuranceModal(
                                          imgAttachment,
                                          null,
                                          _vm.FORWARD_ATTACHMENT
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("ph-share", {
                                      staticClass: "mr-8px cursor-pointer",
                                      attrs: { size: 12 }
                                    }),
                                    _vm._v(" Weiterleiten ")
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ])
                      }),
                      _vm._l(message.externalAttachments, function(
                        externalAttachment
                      ) {
                        return _c(
                          "div",
                          { key: externalAttachment.id },
                          [
                            _c(
                              "DownloadLink",
                              {
                                staticClass: "d-flex px-0",
                                attrs: {
                                  asButton: "",
                                  title: "Anhang",
                                  downloadServicePath: "/getChatAtt",
                                  filename: externalAttachment.name,
                                  queryParams: { id: externalAttachment.id }
                                }
                              },
                              [
                                _c("ph-paperclip", {
                                  staticClass: "mr-8px cursor-pointer",
                                  attrs: { size: 18 }
                                }),
                                _vm._v(
                                  " " + _vm._s(externalAttachment.name) + " "
                                )
                              ],
                              1
                            ),
                            _vm.spreadsheetContent[externalAttachment.id]
                              ? _c(
                                  "div",
                                  { staticClass: "spreadsheet__container" },
                                  [
                                    _c("Table", {
                                      attrs: {
                                        tableId:
                                          "f5efcb61-1f0a-4317-90a8-841b2f2b7f5e",
                                        scrollHorizontally: true,
                                        headers:
                                          _vm.spreadsheetContent[
                                            externalAttachment.id
                                          ].headers,
                                        rows:
                                          _vm.spreadsheetContent[
                                            externalAttachment.id
                                          ].rows
                                      }
                                    })
                                  ],
                                  1
                                )
                              : externalAttachment.renderSpreadsheet
                              ? _c("div", [_c("AnimatedSpinner")], 1)
                              : _vm._e(),
                            _c("div", { staticClass: "forward" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "d-flex px-0",
                                  staticStyle: { "text-overflow": "ellipsis" },
                                  attrs: {
                                    rel: "noopener noreferer",
                                    draggable: "false",
                                    href: null
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openLinkAttachmentToInsuranceModal(
                                        externalAttachment,
                                        "application/pdf",
                                        _vm.UPLOAD_ATTACHMENT
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("ph-share", {
                                    staticClass: "mr-8px cursor-pointer",
                                    attrs: { size: 12 }
                                  }),
                                  _vm._v(" Einem Vertrag zuweisen ")
                                ],
                                1
                              ),
                              _vm.customerId
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "d-flex px-0",
                                      staticStyle: {
                                        "text-overflow": "ellipsis"
                                      },
                                      attrs: {
                                        rel: "noopener noreferer",
                                        draggable: "false",
                                        href: null
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openLinkAttachmentToInsuranceModal(
                                            externalAttachment,
                                            "application/pdf",
                                            _vm.FORWARD_ATTACHMENT
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("ph-share", {
                                        staticClass: "mr-8px cursor-pointer",
                                        attrs: { size: 12 }
                                      }),
                                      _vm._v(" Weiterleiten ")
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      }),
                      message.isInternalRemark
                        ? _c("div", [
                            _c(
                              "span",
                              { staticClass: "text-small" },
                              [
                                _vm._v("Interne Bemerkung "),
                                _c("PhLockKey", { attrs: { size: 16 } })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      message.isDeletionAllowed
                        ? _c(
                            "div",
                            { staticClass: "chat-delete cursor-pointer" },
                            [
                              _c("ph-trash", {
                                attrs: { size: 18 },
                                on: {
                                  click: function($event) {
                                    return _vm.openDeleteMessageModal(
                                      message.messageId
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              message.newStatus && !message.messageText
                ? _c("div", { staticClass: "message-body" }, [
                    _c("span", {
                      staticClass: "message-text",
                      domProps: { innerHTML: _vm._s(message.newStatus) }
                    })
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticClass: "sent-by",
              style: {
                justifyContent: message.direction
                  ? message.direction == _vm.sending
                    ? "flex-end"
                    : "start"
                  : "center"
              }
            },
            [
              message.direction == _vm.sending
                ? _c(
                    "div",
                    { staticClass: "chat-info" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(message.date) +
                          " | " +
                          _vm._s(message.fromName) +
                          " | "
                      ),
                      !message.isGelesen
                        ? _c("ph-check", { attrs: { size: 18 } })
                        : _c("ph-checks", { attrs: { size: 18 } })
                    ],
                    1
                  )
                : message.direction
                ? _c("div", { staticClass: "chat-info" }, [
                    _vm._v(
                      " " +
                        _vm._s(message.date) +
                        " | " +
                        _vm._s(message.fromName) +
                        " "
                    )
                  ])
                : _c("div", { staticClass: "chat-info" }, [
                    _vm._v(
                      " " +
                        _vm._s(message.date) +
                        " | " +
                        _vm._s(message.fromName) +
                        " "
                    )
                  ])
            ]
          )
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "forwardAttachmentModal",
          attrs: {
            modalTitle:
              _vm.attachmentAction == _vm.UPLOAD_ATTACHMENT
                ? "Anhang hochladen"
                : "Anhang weiterleiten",
            showDefaultButtons: false,
            actions: _vm.forwardAttachmentModalActions
          },
          on: {
            "action-SAVE": _vm.saveAttachementMenu,
            close: _vm.closeForwardAttachementMenu
          }
        },
        [
          [
            _c(
              "DownloadLink",
              {
                staticClass: "d-flex px-0 pb-2",
                attrs: {
                  asLabel: "",
                  title: "Anhang",
                  downloadServicePath: "/getChatAtt",
                  filename: _vm.selectedAttachment.name,
                  queryParams: { id: _vm.selectedAttachment.id }
                }
              },
              [
                _c("ph-paperclip", {
                  staticClass: "mr-8px cursor-pointer",
                  attrs: { size: 18 }
                }),
                _vm._v(" " + _vm._s(_vm.selectedAttachment.name) + " ")
              ],
              1
            ),
            _c("ComboBox", {
              attrs: {
                disabled: !_vm.insuranceList || _vm.insuranceList.length == 0,
                values: _vm.insuranceList
              },
              model: {
                value: _vm.selectedInsurance,
                callback: function($$v) {
                  _vm.selectedInsurance = $$v
                },
                expression: "selectedInsurance"
              }
            })
          ]
        ],
        2
      ),
      _c("EmailVersand")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }