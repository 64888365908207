<template>
    <DragnDropArea class="mb-3" hoverText="Dokument hochladen" @files="emitFiles">
        <BaseFileSelect @files="emitFiles">Dokument hochladen</BaseFileSelect>
        <template v-if="fileId">
            <DownloadLink target="_blank" rel="noopener noreferer" :title="filename" :filename="filename"
                downloadServicePath="/get_simple_file" :queryParams="{
                    fileId: fileId,
                }">
                <Pill :label="filename" type="default"></Pill>

            </DownloadLink>
            <BaseButton isLink>
                <PhTrash class="ml-2" :size="16" @click="emitDelete" />
            </BaseButton>
        </template>
    </DragnDropArea>
</template>

<script>

import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import { PhTrash } from "phosphor-vue";
import Pill from '@/components/core/Pill.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue';

export default {
    components: {
        DragnDropArea,
        DownloadLink,
        Pill,
        PhTrash,
        BaseButton,
        BaseFileSelect
    },
    props: {
        filename: {
            type: String
        },
        fileId: {
            type: Number
        }

    },
    emits: ['file', 'delete'],
    methods: {
        emitDelete() {
            this.$emit('delete', this.fileId);
        },
        emitFiles(files) {
            if (!files.length) {
                return;
            }
            this.$emit('file', files[0])
        }
    }
}
</script>