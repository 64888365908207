<template>
  <div>
    <BaseModal ref="changeCategoryModal" modalTitle="Kategorie ändern" @close="close()" @onConfirmButton="submit()">

      <ComboBox v-model="chosenCategory" :values="categoriesComboBox">
      </ComboBox>

    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import BaseModal from '@/components/core/BaseModal.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue'

export default {
  components: {
    BaseModal,
    ComboBox,
  },

  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      chosenCategory: this.transaction.category.category,
    }
  },

  computed: {
    ...mapGetters({
      categoryTreeAll: MULTIBANKING_TYPES.GETTERS.CATEGORY_TREE_ALL,
    }),

    categoriesComboBox() {
      return this.categoryFlat
        .filter(cat => !this.categoryTreeAll.includes(cat))
        .map(cat => ({
          label: cat.displayName,
          value: cat.category,
        }));
    },

    categoryFlat() {
      if (!this.categoryTreeAll) {
        return [];
      }

      let result = new Map();
      let nameCount = new Map();
 
      // Count occurrences of each display name
      this.categoryTreeAll.forEach(parentCategory => {
        parentCategory.children.forEach(child => {
          nameCount.set(child.displayName, (nameCount.get(child.displayName) || 0) + 1);
        });
      });

      this.categoryTreeAll.forEach(parentCategory => {
        result.set(parentCategory.category, parentCategory);

        parentCategory.children.forEach(child => {
          if (nameCount.get(child.displayName) > 1) {
            child.displayName = `${child.displayName} - ${parentCategory.displayName}`;
          }
          result.set(child.category, child);
        });
      });

      return Array.from(result.values());
    },
  },

  methods: {
    submit() {
      let category = this.categoryFlat.find((ct) => ct.category === this.chosenCategory)
      this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.SAVE_TRANSACTION_CATEGORY, {
        transactionId: this.transaction.id,
        category: category,
      })
      this.close()
    },
    close() {
      this.$refs.changeCategoryModal.close()
      this.$emit("close")
    },
  },
  mounted() {
    this.$refs.changeCategoryModal.open()
  }
}
</script>

<style scoped>
.change-category-head,
.change-category-item {
  width: 25em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: .5em;
}

.change-category-item {
  background-color: var(--color-secondary);
  color: var(--color-secondary-text);
}

.change-category-name {
  flex-grow: 1;
}

input[type=text] {
  width: 100%;
}
</style>