
import WERTPAPIERINFO_TYPES from "@/store/wertpapierinfo/types";
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from 'vuex';
import { parse } from '@/helpers/number-formatter.js';
import {formatNumber} from '@/helpers/number-formatter.js';
import CORE_TYPES from '@/store/core/types';

export const wpFilterMixin = {
  props: {
    /**
     * Ignore menu items combo options values
     * Example: 
     *   {
     *     // <key>: <values>,
     *     '1.1.06': [{ label: 'Alle', value: '', }],
     *   }
     */
    ignoreOptionValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function() {
    return {
      act: '',
      options: { init: false },
      ready: false,
      defValue: '',
      predefinedFilter: {},
      defaultFilters: {},
      callAddFilter: false,
      // saveKeyFilter: 'searchInvestmentfonds',
      watchBase: false,
      wpRows: [],
    }
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
    }),
    phase() {
      return !this.doRender ? null : this.instData && this.instData.phase;
    },
    saveKeyFilter() {
     if (this.phase?.wp_type ) {
      const idx = parseInt(this.phase.wp_type) - 1;
      return ['searchInvestmentfonds','searchZertifikate','searchBeteiligungen'][idx];
     } else {
      return 'searchInvestmentfonds';
     }
    },
    searchFilter() {
      return this.instData && this.instData.filters || [];
    },
    f_state() {
      return !this.doRender ? null : this.phase && this.phase.f_state || {item: '1.1.01'};
    },
    apTerms() {
      const result = [];
      if (this.phase?.wp_type === '1' && this.config?.apTerms?.length) {
        this.config.apTerms.forEach((item, idx) => {
          item.searchLabel = 'AP ' + item.name;
          item.title = item.tipp;
          item.onClick = () => { this.addAPFilter(idx); };
          result.push(item);
        });
      }
      return !this.doRender ? null : result;
    },
    filterLoading() {
      return !this.filter[0]?.menuItems;
    },
    filter() {
      const filter = [];
      const searchfilter = [];
      if ( this.config && this.phase && this.phase.wp_type && this.options ) {
        this.config[this.phase.wp_type + '.0'].forEach( id => {
          const childs = [];
          const menuItems = [];
          if (this.config[id+'.0']) {
            this.config[id+'.0'].forEach( sid => {
              if (this.gesellschaft && this.config[sid][2].indexOf('ff_filter_gesellsch_') === 0 ) {
                // 
              } else 
              if (this.lagerstelle && this.config[sid][2].indexOf('ff_filter_lagerst_') === 0 ) {
                // const field = this.config[sid];
              } else {
                childs.push({
                  id: sid,
                  field: this.config[sid],
                  value: ['']
                });
                const field = this.config[sid];
                let result = this.convFilter(field, sid, this.options);
                if ( null !== result ) {
                  menuItems.push(result);
                }
              }
            });
            filter.push({
              value: id,
              label: this.config[id],
              childs: childs
            });
            searchfilter.push({
              type: 'group',
              key: id,
              label: this.config[id],
              menuItems: menuItems
            });
          }
        });
      }
      const { ignoreOptionValues = {}, } = this;
      const searchFilterList = searchfilter.map(filter => ({
        ...filter,
        menuItems: filter?.menuItems?.map(item => {
          if(item.key in ignoreOptionValues) { // has values to be ignored
            const valuesToIgnore = ignoreOptionValues[item.key];
            const comboOptions = item?.comboOptions
              ?.filter(option => valuesToIgnore.findIndex(ignore => option.label === ignore.label && option.value === ignore.value) < 0);
            return {
              ...item,
              comboOptions,
            };
          } else {
            return item;
          }
        }),
      }));

      return searchFilterList;
    },
    configFilter() {
      const filterKeys = {
        default: {
          isin: '1.1.02',
          wkn: '1.1.03',
          fondsname: '1.1.04'
        },
        zertificate: {
          isin: '2.1.02',
          wkn: '2.1.03',
          fondsname: '2.1.04'
        },
        beteiligungen: {
          isin: '3.1.02',
          wkn: '3.1.03',
         fondsname: '3.1.04'
        }
      }
      let defaultSearchInputKeys = [filterKeys.default.isin, filterKeys.default.wkn, filterKeys.default.fondsname];
      if(this.saveKeyFilter === 'searchZertifikate') {
        defaultSearchInputKeys =  [filterKeys.zertificate.isin, filterKeys.zertificate.wkn, filterKeys.zertificate.fondsname] 
      } else if(this.saveKeyFilter === 'searchBeteiligungen') {
        defaultSearchInputKeys =  [filterKeys.beteiligungen.isin, filterKeys.beteiligungen.wkn, filterKeys.beteiligungen.fondsname] 
      }
      return {
        placeholderForDefSearchInput: 'ISIN, WKN, Fondsname',
        defaultSearchInputKeys,
        cbDefSearchInput: this.defaultValue,
        hideFirstColumn: true,
        filterZurucksetzen: () => {this.setFilter([]);},
        programmaticSearchParameter: this.apTerms || [],
        filterType: 'wertpapierinfo',
        noResetOnDefaultSearchInputExit: true,
      }
    },
  },
  watch: {
    type: 'getFilter',
  },
  beforeUpdate() {
    if( this.$refs.filter?.$children?.length && !this.watchBase ) {
      const base = this.$refs.filter.$children[0];
      if (base.$watch) {
        base.$watch('filterRows', (arg) => this.onChangeFilterRows(arg), {immediate: true, deep: true});
        this.watchBase = null;
      }
    }
  },
  methods: {
    onChangeFilterRows(fRows) {
      if (fRows.length) {
        let refresh = false;
        fRows.forEach( item => {
          const elem = this.filter[0]?.menuItems?.find( f => f.key === item.secondaryKey)
          if (item.componentType !== elem?.type) { // old saved filter
            item.componentType = elem?.type;
            item.comboOptions = elem?.comboOptions;
          }
          if(-1 !== elem?.label.indexOf('Favoritenliste') && elem?.type==='combobox' && !item[elem?.key]) {
            item[elem?.key] = elem?.comboOptions[0].value;
            refresh = true;
          }
        });
        if (refresh) {
            const base = this.$refs.filter.$children[0];
            if (base.$data.filterRows) {
              base.$data.filterRows = JSON.parse(JSON.stringify(fRows));
            }
        }
      }
    },
    async initFilter() {
      
      const cb = () => {
        this.getFilter();
        // if (this.searchFilter && this.searchFilter.length ) {
        //   this.addFilter({filter: this.searchFilter, key: '', name: ''}, true);
        const data = this.getWPIAct(this.act);
        if (data?.filters ) {
          this.addFilter({filter: data.filters, key: '', name: ''}, true);
        }
        this.ready = true;
      }
      this.getOptions(cb);
    },
    async getOptions(cb) {
      try {
        this.options = await this.$store.dispatch(WERTPAPIERINFO_TYPES.ACTIONS.OPTIONS, {
          act: this.act,
          gesellschaft: this.gesellschaft,
          lagerstelle: this.lagerstelle,
          isCourtage: this.isCourtage,
        });
        if (this.options){
          this.options.init = true;
          if (cb) {
            cb();
          }
        }
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "getOptions", error});
      }
    },
    addAPFilter(idx) {
      this.addFilter(this.apTerms[idx], false);
    },
    addFilter(data, searched) {
      if (!this.config) {
        return;
      }
      const newFilters = [];
      const parameters = [];
      this.getOptions();
      const primarySelection = this.filter.map( f => ({ 
        value: f.key,
        label: f.label,
        type: f.type}));
      data.filter.forEach( f => {
        const k = newFilters.findIndex( r => r[0] === f[0]) ;
        if (!f[1] || f[1][0] === false) {
          if (k !== -1) {
            newFilters.splice(k, 1);
          }
        } else {
          if (f[0] === 'generic' ) {
            this.defValue = (searched ? f[2] : f[1]);
            const bf = this.getBaseFilter();
            if (bf) {
              bf.$data.defaultSearchInput = this.defValue;
            }
          } else {
            if (k === -1) {
              newFilters.push( [f[0], this.config[f[0]][1], f[1], this.config[f[0]], f[1]] );
            } else {
              newFilters[k][2] = f[1];
            }
            const field = this.config[f[0]];
            let result = this.convFilter(field, f[0], (searched ? this.instData.options : this.options), (searched ? f[2] : f[1]));
            parameters.push({
              componentType: result.type,
              [result.key]: result.value,
              disabled: false,
              key: result.key.substr(0, result.key.lastIndexOf('.')),
              primarySelection: primarySelection,
              secondaryKey: result.key,
              type: 'group',
              comboOptions: result.comboOptions,
              value: result.value,
            });
          }
        }
      });
      this.predefinedFilter = {
        id: -1,
        parameters: JSON.stringify(parameters),
        searchKey: data.key,
        searchLabel: data.name,
        visible: false
      };
      this.defaultFilters = parameters?.length && parameters.reduce((acc, curr) => ({ ...acc, [curr.secondaryKey]: { value: curr.value} }, {})) || {};
      // parameters?.forEach(param => this.defaultFilters[param.secondaryKey] = { value: param.value});
    },
    convFilter(field, sid, options, value) {
      let result = null ;
      if ( field[0] === 'text') {
        result = {
          key: sid,
          type: 'text',
          label: field[1],
          emptyDenied: true
        };
        if (value ) {
          result.value = value[0];
        }
        if(sid === '1.1.02' || sid === '2.1.02' || sid === '3.1.02') { // ISIN
          // result.pattern= /\b([A-Z]{2})((?![A-Z]{10}\b)[A-Z0-9]{10})\b/i
          result.pattern= /^[A-Z]{2}[A-Z0-9]{10}$/i
        }
        if(sid === '1.1.03' || sid === '2.1.03' || sid === '3.1.03') { // WKN
          /* is considered WKN if and only if:
           * 1) has 6 digits only OR has 6 alphanumeric digits only
           * 2) It is not 6 characters word
           * 3) It is not match with ISIN pattern
           */
          // result.pattern= /\b(?=(\d{6}|[a-z0-9]{6}))(?!([a-z]){6})(?!(\b([A-Z]{2})((?![A-Z]{10}\b)[A-Z0-9]{10})\b))\b/i
          result.pattern= /^[a-z0-9]{6}$/i
        }
      } else if ( field[0] === 'percent') {
        result = {
          key: sid,
          type: 'text',
          label: field[1],
        };
        if (value ) {
          result.value = value[0];
        }
      } else if ( field[0] === 'select') {
        result = {
          key: sid,
          type: 'combobox',
          label: field[1],
          comboOptions: options.init && options[field[2]] ? options[field[2]].map(o => ({label: o[1] === '' ? 'Alle' : o[0], value: o[1]})) : []
        };
        if (value ) {
          result.value = value[0];
        }
      } else if ( field[0] === 'list_jn') {
        result = {
          key: sid,
          type: 'default',
          label: field[1]
        };
        if (value ) {
          result.value = true;
        }
      } else if ( field[0] === 'list_cb') {
        result = {
          key: sid,
          type: 'multipleItemSelector',
          label: field[1],
          comboOptions: field[2].map(o => ({label: o, value: parseInt(o, 10)})),
          emptyDenied: true
        };
        if (value ) {
          result.value = value[0].split(',').map( v => ({label: v, value: parseInt(v, 10)}));
        }
      } else if ( field[0] === 'range_date') {
        result = {
          key: sid,
          type: 'dateRange',
          label: field[1],
          emptyDenied: false
        };
        if (value ) {
          result.value = value[0].split('\t').map( (v, i) => ({value: v, key: (i === 0 ? 'min' : 'max')}));
        }
      } else if ( field[0] === 'chips') {
        result = {
          key: sid,
          type: 'chips',
          label: field[1],
          comboOptions: options.init && options[field[2]] ? options[field[2]].map(o => ({label: o[0], value: o[1]})) : []
        };
        if (value && Array.isArray(value)) {
          result.value = value.map(elem =>  result.comboOptions?.find(combo => combo.value === elem));
        }
      } else if ( field[0] === 'numberRange') {
        result = {
          key: sid,
          type: 'numberRange',
          label: field[1],
          emptyDenied: false,
        };
        if (field.length > 4 && field[4] ) {
          result.comboOptions = [{label: 'precision', value: parseInt(field[4], 10) }]
        }
        if (value ) {
          result.value = value[0].split('\t').map( (v, i) => ({value: v, key: (i === 0 ? 'min' : 'max')}));
        }
      } 
      return result;
    },
    defaultValue(value) {
      this.defValue = value;
    },
    handleSearch(criteria) {
      const filters = [];
      if (this.defValue) {
        filters.push(['generic', 'generische suche']);
      }
      let errMessage = '';
      Object.keys(criteria).forEach( key => {
        const field = this.config && this.config[key];
        if (field) {
          if ((criteria[key] === undefined || criteria[key] === null) && field[0] !== 'percent') {
            if (process.env.VUE_APP_USER === 'hannes') console.log('criteria', key, field, criteria )
          } else if ( field[0] === 'text') {
            filters.push([key, field[1], [criteria[key]]]);
          } else if ( field[0] === 'percent') {
            let value = parse( criteria[key] || '0');
            if (isNaN(value) || value < 0 || value > 100 ) {
              errMessage += '<br><b>' + field[1] + '</b><br>soll entweder Leer, oder eine Zahl zwischen 0 und 100 sein'
            } else {
              filters.push([key, field[1], [value.toLocaleString('de-DE')]]);
            }
          } else if ( field[0] === 'select') {
            filters.push([key, field[1], [criteria[key]]]);
          } else if ( field[0] === 'list_jn') {
            filters.push([key, field[1], ['Ja']]);
          } else if ( field[0] === 'list_cb' && criteria[key] && criteria[key].length) {
            let value = '';
            criteria[key].forEach( v => {
              value += ',' + v.label;
            })
            filters.push([key, field[1], [value.substr(1)]]);
          } else if ( field[0] === 'range_date') {
            let value = '';
            if ( criteria[key] && criteria[key].length > 0 && criteria[key][0].value) {
              value = criteria[key][0].value;
            }
            if ( criteria[key] && criteria[key].length > 1 && criteria[key][1].value) {
              value += '\t' + criteria[key][1].value;
            }
            filters.push([key, field[1], [value]]);
          } else if ( field[0] === 'chips') {
            let value = [];
            if ( criteria[key] && criteria[key].length > 0) {
              value = criteria[key].map( item => item.value);
            }
            filters.push([key, field[1], value]);
          } else if (field[0] === 'numberRange') {
            let value = '';
            if ( criteria[key] && criteria[key].length > 0 && criteria[key][0].value != null ){
              value = formatNumber(criteria[key][0].value, 6);
            }
            if ( criteria[key] && criteria[key].length > 1 && criteria[key][1].value != null) {
              value += '\t' + formatNumber(criteria[key][1].value, 6);
            }
            if (value){
              filters.push([key, field[1], [value]]);
            }
          }
        }
      })
      if (errMessage) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, errMessage.substring(4));
        return;
      }

      this.setFilter(filters);
      
      if(this.emitFilters || this.$listeners['onSearch']) {
        this.$emit("onSearch", {criteria, onInitLoading: () => this.onInitLoading(), onLoadingDone: () => this.onLoadingDone()})
      } else {
        this.filterOk();
      }
    },
    getBaseFilter() {
      for ( let k = 0; k < this.$children.length; k++) {
        for ( let j = 0;  this.$children[k].$children && j < this.$children[k].$children.length; j++) {
          if (this.$children[k].$children[j].filterZurucksetzen ) {
            return this.$children[k].$children[j];
          }
        }
      }
      return null;
    },
  },
}

export const wpListeMixin = {
  data: function() {
    return {
      rowsCount: null,
      actFilter: '',
    }
  },
  methods: {
    gotoFondsinfo(row, type) {
      this.$addBreadcrumb({
        breadcrumb: 'Fondsinfo',
        fullPath: this.$route.fullPath,
      });
      const isin = typeof row === 'string' ? row :( row.row || row._fondsData?.isin );
      const p = type == 'B' ? 'beteiligungen' : 'vermogensubersicht' ;
      const path = `/shared/${p}/fondsinfo/${isin}`;
      this.$router.push({ path });
    },
    gotoFondsinfoOverview(row, type) {
      this.$addBreadcrumb({
        breadcrumb: 'Fondsinfo',
        fullPath: this.$route.fullPath,
      });
      const isin = typeof row === 'string' ? row :( row.row || row._fondsData?.isin );
      const p = type == 'B' ? 'beteiligungen' : 'vermogensubersicht' ;
      const path = `/home/${p}/fondsinfooverview/${isin}`;
      this.$router.push({ path });
    },
    openModalEditCustomerSearchResultTable() {
        // TODO: remove this method once all tables are replaced
      this.$refs.tableResult.openColumnsConfig();
    },
    getAktionLink(items, visible) {
      const result = JSON.parse(items.replace(/'/g, '"'));
      console.log('getAktionLink',  JSON.parse(JSON.stringify(result)) );
      return visible ? result.slice(0, 2) : result.slice(2);
    },
    handleTableAction(data) {
      console.log('handleTableAction-0', data)
      this.$emit('handleTableAction', data);
    },
  },
}