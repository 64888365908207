<template>
    <div class="box__container">
        <GesamtVermoegen />
    </div>
</template>

<script>

import GesamtVermoegen from '@/views/ruhestandsplanung/GesamtVermoegen.vue';

export default {
    components: {
        GesamtVermoegen
    },
}

</script>

<style>

</style>