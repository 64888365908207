import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    menu: {},
    strategien: [],
    strategienFilters: {},
    strategienEditRights: null,
    strategienFilterItems: [],
    strategienSchemaData: {},
    strategienSchemaDataLoading: false,
    strategienPositions: [],
    subscription: { status: {} },
    vvArchiv: {},
    wertpapierBeantragenData: null,
    beschwerde: null,
    hatAdvisorRechte: null,
    portfolioList: {},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}