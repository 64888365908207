var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DragnDropArea",
        {
          attrs: { hoverText: "Neues Dokument hochladen" },
          on: {
            files: function($event) {
              return _vm.openDocumentUploadModal($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Dokumente und Prospekte")
              ]),
              _c("Table", {
                attrs: {
                  tableId: "vvstrategienfiles",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10,
                  rowId: "fileId",
                  hidePagination: ""
                },
                on: {
                  "click-bezeichnung": _vm.showDocument,
                  "action-DELETE": _vm.deleteDocument,
                  "action-EDIT": _vm.openEditModal
                }
              })
            ],
            1
          )
        ]
      ),
      _c("DocumentsUploadModal", {
        ref: "uploadForm",
        attrs: {
          files: _vm.files,
          additionalInputs: _vm.uploadFields,
          uploadType: 3,
          multipleFileUpload: true,
          openOnMount: false,
          customUpload: _vm.upload
        },
        on: {
          close: function($event) {
            _vm.files = null
          }
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: "Datei hochladen",
            labelButtonConfirm: "Ok",
            labelButtonCancel: "Abbrechen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveFile()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { disabled: true, label: "Dateiname" },
            model: {
              value: _vm.editFile.fileName,
              callback: function($$v) {
                _vm.$set(_vm.editFile, "fileName", $$v)
              },
              expression: "editFile.fileName"
            }
          }),
          _c("ComboBox", {
            attrs: { values: _vm.typValues, label: "Typ" },
            model: {
              value: _vm.editFile.typ,
              callback: function($$v) {
                _vm.$set(_vm.editFile, "typ", $$v)
              },
              expression: "editFile.typ"
            }
          }),
          _c("InputField", {
            attrs: { label: "Bezeichnung" },
            model: {
              value: _vm.editFile.bezeichnung,
              callback: function($$v) {
                _vm.$set(_vm.editFile, "bezeichnung", $$v)
              },
              expression: "editFile.bezeichnung"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Bemerkung" },
            model: {
              value: _vm.editFile.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.editFile, "bemerkung", $$v)
              },
              expression: "editFile.bemerkung"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }