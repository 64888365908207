var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.selectedNav === "ergebnis-depotoptimierung"
      ? _c(
          "div",
          [
            _c("ErgebnisRiskReturnView", {
              attrs: { adviceId: _vm.id },
              on: { apply: _vm.resetAllocation, update_hints: _vm.updateHints }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.selectedNav === "ergebnis-backtesting"
      ? _c(
          "div",
          [
            _vm.subtab === "Performance"
              ? _c("ErgebnisPerformanceView", {
                  attrs: { adviceId: _vm.id },
                  on: { update_hints: _vm.updateHints }
                })
              : _vm.subtab === "Diagramme"
              ? _c("ErgebnisDiagrammeView", { attrs: { adviceId: _vm.id } })
              : _vm.subtab === "Prognose"
              ? _c("ErgebnisMontecarloView", { attrs: { adviceId: _vm.id } })
              : _c("ErgebnisBacktestingView", {
                  attrs: {
                    adviceId: _vm.id,
                    stepData: _vm.stepData,
                    tab: _vm.subtab
                  },
                  on: {
                    update_hints: _vm.updateHints,
                    display_server_question: function($event) {
                      return _vm.$emit("display_server_question", $event)
                    }
                  }
                })
          ],
          1
        )
      : _vm._e(),
    _vm.debug
      ? _c("div", [
          _vm._v(
            " Egebnis " +
              _vm._s(_vm.id) +
              " params: " +
              _vm._s(_vm.stepParameters) +
              " "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }