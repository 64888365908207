var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contentReady
    ? _c(
        "div",
        [
          _vm.stepKey === "AUSGEWAEHLTE_INVESTMENTFONDS" &&
          _vm.isSmallOrMediumScreen
            ? [
                _c("Table", {
                  attrs: {
                    title: "Ausgewählte Investmentfonds",
                    headers: _vm.selectedFundsHeaders,
                    mobileConfig: {
                      title: "Ausgewählte Investmentfonds",
                      headers: ["fondsname", "isin", "actions"],
                      disableClickRow: true
                    },
                    rows: _vm.fundsRows,
                    headerActions: _vm.tableActions,
                    rowId: "isin",
                    hidePagination: ""
                  },
                  on: {
                    "action-DELETE": _vm.delFunds,
                    headerAction: _vm.handleHeaderAction
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "fondsname",
                        fn: function(ref) {
                          var fondsname = ref.fondsname
                          var isin = ref.isin
                          return [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gotoFondsinfo(isin)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(fondsname))]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    739024575
                  ),
                  model: {
                    value: _vm.delSelection,
                    callback: function($$v) {
                      _vm.delSelection = $$v
                    },
                    expression: "delSelection"
                  }
                })
              ]
            : _vm._e(),
          _vm.stepKey === "FILTER"
            ? [
                _c("WertpapierSearch", {
                  ref: "wp_filter",
                  staticClass: "search-filter",
                  attrs: {
                    name: "CompareFunds",
                    type: "I",
                    respond: 3,
                    initSelected: _vm.selFunds,
                    tableHeaderActions: _vm.tableHeaderActions,
                    isSelectable: "",
                    scrollHorizontally: _vm.wpScrollH
                  },
                  on: {
                    selected: _vm.selectedRow,
                    headerAction: _vm.wpHeaderAction
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "beforeTable",
                        fn: function() {
                          return [
                            _c("p", [
                              _vm._v(
                                "Bitte wählen Sie die Wertpapiere für den Vergleich. (wiederholt suchen ist möglich)"
                              )
                            ]),
                            _c(
                              "BaseButton",
                              {
                                attrs: {
                                  isPrimary: "",
                                  disabled:
                                    !_vm.selectedWertpapier ||
                                    !_vm.selectedWertpapier.length
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.addSelectedFonds()
                                  }
                                }
                              },
                              [_vm._v("Hinzufügen")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2248933709
                  )
                }),
                _vm.source !== _vm.MODAL_SOURCE
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c(
                          "BaseButton",
                          {
                            attrs: { disabled: !_vm.selFunds.length },
                            on: { click: _vm.setAuswahl }
                          },
                          [_vm._v("Weiter")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm.stepKey === "EINSTELLUNG"
            ? [
                _c("div", [
                  _vm.isSmallOrMediumScreen
                    ? _c("div", { staticClass: "box__container" }, [
                        _c("span", { staticClass: "text-bold" }, [
                          _vm._v(
                            _vm._s(_vm.selFunds.length) +
                              " Fonds, " +
                              _vm._s(_vm.indices.length) +
                              " Börsenindizes"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.settings && _vm.settings.showWertEntw
                    ? _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("div", { staticClass: "box__title" }, [
                            _vm._v("Wertentwicklung Chart")
                          ]),
                          _c("InputRadioBoxGroup", {
                            attrs: { values: _vm.wentwOption },
                            model: {
                              value: _vm.wentwVal,
                              callback: function($$v) {
                                _vm.wentwVal = $$v
                              },
                              expression: "wentwVal"
                            }
                          }),
                          _vm.wentwVal &&
                          _vm.wentwVal === _vm.wentwOption[0].value
                            ? [
                                _c("ComboBox", {
                                  attrs: {
                                    label: "Anzahl Jahre",
                                    values: _vm.wentwJahrOpt,
                                    isComponentHalfSize: "",
                                    sortComboboxValues: false
                                  },
                                  model: {
                                    value: _vm.wentwJahr,
                                    callback: function($$v) {
                                      _vm.wentwJahr = $$v
                                    },
                                    expression: "wentwJahr"
                                  }
                                }),
                                _c("InputCheckBoxItem", {
                                  attrs: { label: "&nbsp;Startdatum anpassen" },
                                  model: {
                                    value: _vm.cbAnpassen,
                                    callback: function($$v) {
                                      _vm.cbAnpassen = $$v
                                    },
                                    expression: "cbAnpassen"
                                  }
                                }),
                                _vm._v(
                                  " Hat eines der Wertpapiere zum gewählten Startdatum keine Kurse, so wird der betrachtete Zeitraum automatisch so verkürzt, dass für alle Wertpapiere über den gesamten Zeitraum Kurse vorhanden sind. "
                                ),
                                _c("div", { staticClass: "mt-2" }, [
                                  _vm._v(
                                    "Beginn der Wertentwicklung: " +
                                      _vm._s(_vm.startDate)
                                  )
                                ])
                              ]
                            : _vm._e(),
                          _vm.wentwVal &&
                          _vm.wentwVal === _vm.wentwOption[1].value
                            ? [
                                _c("div", [_vm._v("Zeitraum")]),
                                _c("DatePickerField", {
                                  attrs: {
                                    label: "von",
                                    isComponentHalfSize: ""
                                  },
                                  on: { input: _vm.setSettings },
                                  model: {
                                    value: _vm.zeitraum_von,
                                    callback: function($$v) {
                                      _vm.zeitraum_von = $$v
                                    },
                                    expression: "zeitraum_von"
                                  }
                                }),
                                _c("DatePickerField", {
                                  attrs: {
                                    label: "bis",
                                    isComponentHalfSize: ""
                                  },
                                  on: { input: _vm.setSettings },
                                  model: {
                                    value: _vm.zeitraum_bis,
                                    callback: function($$v) {
                                      _vm.zeitraum_bis = $$v
                                    },
                                    expression: "zeitraum_bis"
                                  }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.settings && _vm.settings.showRisiko
                    ? _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("div", { staticClass: "box__title" }, [
                            _vm._v("Rendite-Risiko Chart")
                          ]),
                          _c("InputRadioBoxGroup", {
                            attrs: { values: _vm.risikoOption },
                            on: { change: function() {} },
                            model: {
                              value: _vm.risikoJahr,
                              callback: function($$v) {
                                _vm.risikoJahr = $$v
                              },
                              expression: "risikoJahr"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "box__container" },
                    [
                      _c("Table", {
                        attrs: {
                          title: "Investmentfonds",
                          headers: _vm.investmentFundsHeaders,
                          rows: _vm.allFunds,
                          rowId: "isin",
                          rowsPerPage: 25,
                          selected: _vm.selFunds
                        },
                        on: {
                          selected: function($event) {
                            return _vm.selectedFunds($event)
                          },
                          "action-DELETE": _vm.delFunds,
                          "action-FACTSHEET": function($event) {
                            return _vm.viewDocument($event.FACTSHEET)
                          },
                          "action-FACTSHEET_KAG": function($event) {
                            return _vm.viewDocument($event.FACTSHEET_KAG)
                          },
                          "action-KID": function($event) {
                            return _vm.viewDocument($event.KID)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "fondsAmpel",
                              fn: function(ref) {
                                var fondsAmpel = ref.fondsAmpel
                                return [_c("div", { class: fondsAmpel })]
                              }
                            },
                            {
                              key: "aa_manuell",
                              fn: function(ref) {
                                var isin = ref.isin
                                var aa_manuell = ref.aa_manuell
                                return [
                                  _c("InputField", {
                                    attrs: { value: aa_manuell },
                                    on: {
                                      change: function($event) {
                                        return _vm.setAaManuell(isin, $event)
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3864811172
                        )
                      }),
                      _c(
                        "BaseButton",
                        {
                          attrs: { isSecondary: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("setStep", "FILTER")
                            }
                          }
                        },
                        [_vm._v("Zurück zur Fondsauswahl")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "box__container" },
                    [
                      _c("div", { staticClass: "box__title" }, [
                        _vm._v("Vergleichoptionen auswählen")
                      ]),
                      _c("InputChips", {
                        staticClass: "p-0 indices",
                        attrs: {
                          label: "Indizes",
                          isComponentHalfSize: "",
                          availableOptions: _vm.indicesOption,
                          showToggle: true
                        },
                        model: {
                          value: _vm.indicesSelected,
                          callback: function($$v) {
                            _vm.indicesSelected = $$v
                          },
                          expression: "indicesSelected"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "fc-form-danger" }, [
                    _vm._v(_vm._s(_vm.errStr))
                  ]),
                  !_vm.isSmallOrMediumScreen
                    ? _c("div", { staticClass: "box__container" }, [
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "layout__negative-margin--8" },
                            [
                              _c(
                                "BaseButton",
                                {
                                  attrs: { isSecondary: "" },
                                  on: { click: _vm.resetVergleich }
                                },
                                [_vm._v("Vergleich zurückstellen")]
                              ),
                              _c(
                                "BaseButton",
                                {
                                  attrs: {
                                    disabled:
                                      (!_vm.selFunds.length &&
                                        !_vm.indices.length) ||
                                      _vm.ergebnisLoadingActive,
                                    animated: _vm.ergebnisLoadingActive
                                  },
                                  on: { click: _vm.getVergleich }
                                },
                                [
                                  _vm._v(
                                    "Vergleich starten mit " +
                                      _vm._s(_vm.selFunds.length) +
                                      " Fonds, " +
                                      _vm._s(_vm.indices.length) +
                                      " Börsenindizes"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              ]
            : _vm._e(),
          _vm.stepKey === "STAMMDATEN"
            ? [
                _c(
                  "div",
                  [
                    _vm.hasStammdatenData || _vm.ergebnisLoading.fondsDaten
                      ? _c(
                          "div",
                          { staticClass: "box__container" },
                          [
                            _c("InputRadioBoxGroup", {
                              attrs: {
                                values: [
                                  { label: "Anzeige als Zeilen", value: true },
                                  { label: "Anzeige als Spalten", value: false }
                                ],
                                disabled: _vm.ergebnisLoading.fondsDaten
                              },
                              model: {
                                value: _vm.showDatenAsCols,
                                callback: function($$v) {
                                  _vm.showDatenAsCols = $$v
                                },
                                expression: "showDatenAsCols"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ergebnisLoading.fondsDaten
                      ? _c("GhostLoading", {
                          attrs: { type: "table", useBoxContainer: "" }
                        })
                      : _vm.stammdaten.rowsCount && _vm.showDatenAsCols
                      ? _c(
                          "div",
                          { staticClass: "box__container" },
                          [
                            _vm.$isSmallScreen
                              ? _c("Table", {
                                  ref: "tblStamm",
                                  staticClass: "table-container",
                                  attrs: {
                                    headers: _vm.stammdaten.headers,
                                    rows: _vm.stammdaten.rows,
                                    scrollHorizontally: !_vm.scrollFirstColunm,
                                    rowId: "ISIN",
                                    hidePagination: ""
                                  },
                                  on: {
                                    "action-FACTSHEET": function($event) {
                                      return _vm.viewDocument($event.FACTSHEET)
                                    },
                                    "action-FACTSHEET_KAG": function($event) {
                                      return _vm.viewDocument(
                                        $event.FACTSHEET_KAG
                                      )
                                    },
                                    "action-KID": function($event) {
                                      return _vm.viewDocument($event.KID)
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "AUFLAGEDATUM",
                                        fn: function(ref) {
                                          var AUFLAGEDATUM = ref.AUFLAGEDATUM
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.timeToStr(AUFLAGEDATUM)
                                                ) +
                                                " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "KURS1",
                                        fn: function(ref) {
                                          var KURS1 = ref.KURS1
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.timeToStr(KURS1)) +
                                                " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "header_wertpapier",
                                        fn: function(ref) {
                                          return [
                                            _c("span", {}, [
                                              _vm._v("Wertpapier ")
                                            ])
                                          ]
                                        }
                                      },
                                      {
                                        key: "wertpapier",
                                        fn: function(ref) {
                                          var wertpapier = ref.wertpapier
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "min-width": "150px"
                                                }
                                              },
                                              [_vm._v(_vm._s(wertpapier))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1605706902
                                  )
                                })
                              : _vm._e(),
                            _vm.shouldShowBigTable
                              ? _c("BigTable", {
                                  attrs: {
                                    title: _vm.tableTitle,
                                    tableId: _vm.tableId,
                                    headers: _vm.bigTableHeaders,
                                    rows: _vm.bigTableRows,
                                    page: _vm.bigTablepage,
                                    sortingState: _vm.sortingState,
                                    initialPageSize: _vm.minNumRows
                                  },
                                  on: { onClickHeader: _vm.onClickHeader },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "AUFLAGEDATUM",
                                        fn: function(ref) {
                                          var AUFLAGEDATUM = ref.AUFLAGEDATUM
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.timeToStr(AUFLAGEDATUM)
                                                ) +
                                                " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "KURS1",
                                        fn: function(ref) {
                                          var KURS1 = ref.KURS1
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.timeToStr(KURS1)) +
                                                " "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    870082067
                                  )
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm.stammdaten.rowsCount && !_vm.showDatenAsCols
                      ? _c(
                          "div",
                          [
                            _vm._l(_vm.stammdaten.records, function(row, idx) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: "T" + idx,
                                    staticClass: "box__title ml-2"
                                  },
                                  [_vm._v(_vm._s(row.label))]
                                ),
                                _c(
                                  "div",
                                  {
                                    key: "R" + idx,
                                    staticClass: "box__container row p-2"
                                  },
                                  _vm._l(_vm.isinRow, function(isin) {
                                    return _c(
                                      "div",
                                      {
                                        key: isin,
                                        staticClass: "col-6 col-lg-4 col-xl-3"
                                      },
                                      [_vm._v(_vm._s(_vm.showValue(row[isin])))]
                                    )
                                  }),
                                  0
                                )
                              ]
                            })
                          ],
                          2
                        )
                      : _c("FondsVergleichNoData", {
                          on: { click: _vm.emitEinstellungStep }
                        })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.stepKey === "WERTENTICK"
            ? [
                _vm.ergebnisLoading.wertentwicklung
                  ? _c(
                      "GhostLoading",
                      { attrs: { useBoxContainer: "" } },
                      [_c("Block", { attrs: { height: "300" } })],
                      1
                    )
                  : _vm.hasWertentwicklungData
                  ? _c("div", { staticClass: "box__container" }, [
                      _c(
                        "div",
                        { staticClass: "flex-c-c" },
                        [
                          _c("ComboBox", {
                            staticClass: "mt-1 child-w2",
                            attrs: {
                              values: _vm.wentwChartOpt,
                              sortComboboxValues: false
                            },
                            model: {
                              value: _vm.wentwChartVal,
                              callback: function($$v) {
                                _vm.wentwChartVal = $$v
                              },
                              expression: "wentwChartVal"
                            }
                          }),
                          _c("InputCheckBoxItem", {
                            staticClass: "child-w3",
                            attrs: { label: "&nbsp;nach Agio" },
                            model: {
                              value: _vm.cbAgio,
                              callback: function($$v) {
                                _vm.cbAgio = $$v
                              },
                              expression: "cbAgio"
                            }
                          }),
                          _c("InputCheckBoxItem", {
                            staticClass: "child-w3",
                            attrs: { label: "&nbsp;nach Steuern" },
                            model: {
                              value: _vm.cbSteuern,
                              callback: function($$v) {
                                _vm.cbSteuern = $$v
                              },
                              expression: "cbSteuern"
                            }
                          }),
                          _c("InputCheckBoxItem", {
                            staticClass: "child-w3",
                            attrs: { label: "&nbsp;Rendite p.a." },
                            model: {
                              value: _vm.cbRenditePA,
                              callback: function($$v) {
                                _vm.cbRenditePA = $$v
                              },
                              expression: "cbRenditePA"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("ChartFunds", {
                            attrs: {
                              type: "Fondsvergleich",
                              chartData: _vm.chartWertentwicklung,
                              sortLegend: true
                            },
                            on: {
                              newOrder: _vm.changeLegendSort,
                              newZeitIntervall: _vm.changeZeitIntervall
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "beforLegend",
                                  fn: function() {
                                    return [
                                      _c("InputChips", {
                                        staticClass: "mb-2 indices",
                                        attrs: {
                                          label:
                                            "zusätzliche Benchmark verwalten *",
                                          availableOptions: _vm.indicesWEOption,
                                          showToggle: true
                                        },
                                        on: {
                                          addItem: _vm.addWEIntex,
                                          removeItem: _vm.removeWEIntex
                                        },
                                        model: {
                                          value: _vm.indicesWESelected,
                                          callback: function($$v) {
                                            _vm.indicesWESelected = $$v
                                          },
                                          expression: "indicesWESelected"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              2322185558
                            )
                          }),
                          [
                            _c(
                              "div",
                              {
                                staticClass: "mt-3",
                                staticStyle: { "font-size": "13px" }
                              },
                              [
                                _vm._v(
                                  "* Zur Gewährleisung eines korrekten Vergleichs ist die Wertentwicklung des Vergleichsindex immer in Bezug auf die erfolgten Kapitalein- bzw. auszahlungen (zeit- und kapitalgewichtete Performancemessung) im jeweilig ausgewählten Zeitraum zu sehen, weshalb die nur auf die Zeitspanne bezogene Index-Performance erheblich abweichen kann."
                                )
                              ]
                            )
                          ]
                        ],
                        2
                      )
                    ])
                  : _c("FondsVergleichNoData", {
                      on: { click: _vm.emitEinstellungStep }
                    })
              ]
            : _vm._e(),
          _vm.stepKey === "RENDITERISIKO"
            ? [
                _vm.ergebnisLoading.rendRisiko
                  ? _c(
                      "GhostLoading",
                      { attrs: { useBoxContainer: "" } },
                      [_c("Block", { attrs: { height: "300" } })],
                      1
                    )
                  : _vm.hasRendRisikoDaten
                  ? _c("div", { staticClass: "box__container" }, [
                      _c(
                        "div",
                        { staticClass: "flex-c-c" },
                        [
                          _c("InputCheckBoxItem", {
                            staticClass: "child-w3",
                            attrs: { label: "&nbsp;nach Agio" },
                            model: {
                              value: _vm.cbAgio,
                              callback: function($$v) {
                                _vm.cbAgio = $$v
                              },
                              expression: "cbAgio"
                            }
                          }),
                          _c("InputCheckBoxItem", {
                            staticClass: "child-w3",
                            attrs: { label: "&nbsp;nach Steuern" },
                            model: {
                              value: _vm.cbSteuern,
                              callback: function($$v) {
                                _vm.cbSteuern = $$v
                              },
                              expression: "cbSteuern"
                            }
                          }),
                          _c("InputCheckBoxItem", {
                            staticClass: "child-w3",
                            attrs: { label: "&nbsp;Rendite p.a." },
                            model: {
                              value: _vm.cbRenditePA,
                              callback: function($$v) {
                                _vm.cbRenditePA = $$v
                              },
                              expression: "cbRenditePA"
                            }
                          }),
                          _c("InputCheckBoxItem", {
                            staticClass: "child-w3",
                            attrs: { label: "&nbsp;Tendenz" },
                            model: {
                              value: _vm.cbTendenz,
                              callback: function($$v) {
                                _vm.cbTendenz = $$v
                              },
                              expression: "cbTendenz"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("ChartYieldRisk", {
                            ref: "riskChart",
                            attrs: {
                              chartParam: _vm.chartParam,
                              chartSeries: _vm.rendRisikoDaten,
                              customColors: _vm.getColorsOrder()
                            }
                          }),
                          _vm.renditeRiskChart && _vm.rendRisikoDaten.length
                            ? _c("ChartLegend", {
                                attrs: {
                                  showAsTable: false,
                                  chartSeries: _vm.rendRisikoDaten,
                                  lineChart: _vm.renditeRiskChart
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  : _c("FondsVergleichNoData", {
                      on: { click: _vm.emitEinstellungStep }
                    })
              ]
            : _vm._e()
        ],
        2
      )
    : _c(
        "div",
        [
          _c(
            "GhostLoading",
            { attrs: { useBoxContainer: "" } },
            [
              _c("Block", { attrs: { type: "title" } }),
              _c("Block", { attrs: { height: "150" } })
            ],
            1
          ),
          _c(
            "GhostLoading",
            { attrs: { useBoxContainer: "" } },
            [
              _c("Block", { attrs: { type: "title" } }),
              _c("Block", { attrs: { height: "75" } })
            ],
            1
          ),
          _c(
            "GhostLoading",
            { attrs: { useBoxContainer: "" } },
            [
              _c("Block", { attrs: { type: "title" } }),
              _c("Block", { attrs: { type: "table-row" } }),
              _c("Block", { attrs: { type: "table-row" } }),
              _c("Block", { attrs: { type: "table-row" } })
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }