var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.$appNavigation.currentMenu.label
        }
      }),
      _c("BaseFilter", {
        ref: "filter",
        attrs: {
          filterId: _vm.saveKey,
          isCache: "",
          configFilter: _vm.configFilter,
          metadata: _vm.searchMenu,
          defaultOptions: _vm.initialFilters,
          showSaveButton: true,
          hasSmartSearch: "",
          immidiateSearch:
            _vm.isNotCommingFromPruefstautsChangePage() && _vm.isBroker
        },
        on: { onFilter: _vm.handleSearch }
      }),
      _c("HandleTablePin", {
        attrs: { keyOfPIN: "TABELLE_VERSICHER_ANTRAEGE" }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _vm._e(),
          !_vm.loading && (_vm.$isSmallScreen || !_vm.istBearbeiter)
            ? _c("Table", {
                ref: "tableResult",
                attrs: {
                  tableId: "antragslisteVersicherung",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.computedRows,
                  rowsPerPage: _vm.rowsPerPage,
                  exportConfig: { roottext: "Versicherungsanträge" },
                  thHoverDisabled: true,
                  mobileConfig: {
                    title: "gesellschaftName",
                    headers: ["kundenLinkName", "pruefstatus", "vertragsbeginn"]
                  }
                },
                on: {
                  "click-kundenLinkName": _vm.openCustomerNewTab,
                  "click-kundennr": _vm.openCustomerNewTab,
                  "click-gesellschaftName": function($event) {
                    return _vm.navigateToGesellschaft(
                      $event.gesellschaft,
                      $event.gesellschaftArt
                    )
                  },
                  "click-sender": function($event) {
                    return _vm.openBrokerNewTab($event.sender)
                  },
                  "click-maklernr": function($event) {
                    return _vm.openBrokerNewTab($event.maklernr)
                  },
                  "click-policennummer": _vm.openInsurancesNewTab,
                  "click-vertragId": _vm.openInsurancesNewTab,
                  "click-courtageErhalten": function($event) {
                    return _vm.openInsurancesNewTab($event, "courtage")
                  },
                  "action-INFO": _vm.openMaklervertragInfoModal,
                  "action-INSURANCE": _vm.openInsurancesNewTab,
                  "action-PROTOKOLL": _vm.showProtokoll
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "pruefstatus",
                      fn: function(row) {
                        return [
                          _vm.istBearbeiter && !row.mobileTableContext
                            ? _c(
                                "div",
                                [
                                  _c("ComboBox", {
                                    attrs: {
                                      value: row.pruefstatus.value,
                                      values:
                                        row.pruefStatusComboboxValues || []
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changePruefStatus(
                                          $event,
                                          row
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c("Pill", {
                                    attrs: {
                                      type: "info",
                                      label: row.pruefstatus.label
                                    }
                                  })
                                ],
                                1
                              )
                        ]
                      }
                    },
                    {
                      key: "document",
                      fn: function(row) {
                        return [
                          row.fileWithSignedAttachments
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(
                                      "versicherungsantraege-table-" + row.index
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewDocument(row.id)
                                    }
                                  }
                                },
                                [_c("PhAppWindow", { attrs: { size: 20 } })],
                                1
                              )
                            : _vm._e(),
                          !row.fileWithSignedAttachments &&
                          !_vm.looksLikePDFFile(row)
                            ? _c(
                                "DownloadLink",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(
                                      "versicherungsantraege-table-" + row.index
                                    ),
                                    downloadServicePath:
                                      "/getVersicherungantraegeFile",
                                    filename: row.fileName || row.fremdnr,
                                    queryParams: {
                                      id: row.id
                                    }
                                  }
                                },
                                [
                                  _c("PhDownloadSimple", {
                                    attrs: { size: 20 }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.fetchPDF(row)
                                    }
                                  }
                                },
                                [_c("PhFilePdf", { attrs: { size: 20 } })],
                                1
                              )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1440758209
                )
              })
            : _vm._e(),
          !_vm.loading &&
          !_vm.$isSmallScreen &&
          _vm.istBearbeiter &&
          _vm.rows.length
            ? _c("BigTable", {
                attrs: {
                  title: _vm.dynamicTitle,
                  tableId: "5e12619a-b032-44a6-8c99-ac90b1785355",
                  headers: _vm.bigTableHeaders,
                  rows: _vm.rows,
                  page: _vm.page,
                  sortingState: _vm.sortingState,
                  initialPageSize: _vm.minNumRows,
                  headerActions: _vm.tableActions
                },
                on: {
                  onClickHeader: _vm.onClickHeader,
                  "action-INFO": _vm.openMaklervertragInfoModal,
                  "action-INSURANCE": _vm.openInsurancesNewTab,
                  "action-PROTOKOLL": _vm.showProtokoll,
                  "click-link-policennummer": _vm.openInsurancesNewTab,
                  "click-link-gesellschaftName": function($event) {
                    return _vm.navigateToGesellschaft(
                      $event.gesellschaft,
                      $event.gesellschaftArt
                    )
                  },
                  "click-link-kundenLinkName": _vm.openCustomerNewTab,
                  "click-link-courtageErhalten": function($event) {
                    return _vm.openInsurancesNewTab($event, "courtage")
                  },
                  "click-link-kundennr": _vm.openCustomerNewTab,
                  "click-link-sender": function($event) {
                    return _vm.openBrokerNewTab($event.sender)
                  },
                  "click-link-maklernr": function($event) {
                    return _vm.openBrokerNewTab($event.maklernr)
                  },
                  "click-link-vertragId": _vm.openInsurancesNewTab,
                  "headerAction-EXPORT-PDF": function($event) {
                    return _vm.getExportBigTable("pdf")
                  },
                  "headerAction-EXPORT-XLS": function($event) {
                    return _vm.getExportBigTable("xls")
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "pruefstatus",
                      fn: function(row) {
                        return [
                          _vm.istBearbeiter && !row.mobileTableContext
                            ? _c(
                                "div",
                                [
                                  _c("ComboBox", {
                                    attrs: {
                                      value: row.pruefstatus.value,
                                      values:
                                        row.pruefStatusComboboxValues || []
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changePruefStatus(
                                          $event,
                                          row
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c("Pill", {
                                    attrs: {
                                      type: "info",
                                      label: row.pruefstatus.label
                                    }
                                  })
                                ],
                                1
                              )
                        ]
                      }
                    },
                    {
                      key: "document",
                      fn: function(row) {
                        return [
                          row.fileWithSignedAttachments
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(
                                      "versicherungsantraege-table-" + row.index
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewDocument(row.id)
                                    }
                                  }
                                },
                                [_c("PhAppWindow", { attrs: { size: 20 } })],
                                1
                              )
                            : _vm._e(),
                          !row.fileWithSignedAttachments &&
                          !_vm.looksLikePDFFile(row)
                            ? _c(
                                "DownloadLink",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(
                                      "versicherungsantraege-table-" + row.index
                                    ),
                                    downloadServicePath:
                                      "/getVersicherungantraegeFile",
                                    filename: row.fileName || row.fremdnr,
                                    queryParams: {
                                      id: row.id
                                    }
                                  }
                                },
                                [
                                  _c("PhDownloadSimple", {
                                    attrs: { size: 20 }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.fetchPDF(row)
                                    }
                                  }
                                },
                                [_c("PhFilePdf", { attrs: { size: 20 } })],
                                1
                              )
                        ]
                      }
                    },
                    {
                      key: "anbindungstyp",
                      fn: function(row) {
                        return [
                          row.anbindungstyp
                            ? _c("Pill", {
                                attrs: {
                                  label: row.anbindungstyp,
                                  type: _vm.anbindungstypColoring(
                                    row.anbindungstyp
                                  )
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "maklervertrag",
                      fn: function(row) {
                        return [
                          row.maklervertrag
                            ? _c("Pill", {
                                attrs: {
                                  label: _vm.getMaklerVertragLabel(row),
                                  type: _vm.getMaklerVertragType(row)
                                }
                              })
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1178750783
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "hatFehler",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            !_vm.hasMandatoryFilters
              ? _c("p", [
                  _vm._v(
                    "Wählen Sie bitte einen Status: geprüft, nicht geprüft, nicht versendet, erledigt, selbst gesendet, abgelehnt."
                  )
                ])
              : _vm._e(),
            !_vm.hasZeitraumFilters
              ? _c("p", [
                  _vm._v(
                    "Wählen Sie bitte eine Zeitraum: 30 Tage, 14 Tage, 7 Tage oder Zwischen."
                  )
                ])
              : _vm._e()
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "infoMaklervertrag",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          on: { onConfirmButton: _vm.closeInfoModal },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.infoTitle) + " ")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.infoText && _vm.infoText.length
            ? _c(
                "div",
                _vm._l(_vm.infoText, function(line, index) {
                  return _c("div", { key: index, staticClass: "mt-1" }, [
                    _vm._v(" " + _vm._s(line) + " ")
                  ])
                }),
                0
              )
            : _vm._e()
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "emptyFile",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v("Es liegen keine Dokumente vor.")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "errorFile",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v("Dokument kann nicht geöffnet werden.")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "multipleFilesModal",
          attrs: {
            showCancelButton: false,
            showConfirmButton: false,
            modalTitle: _vm.currentFilename
          }
        },
        [
          _c("FileAttachmentsModal", {
            attrs: {
              signedAttachments: _vm.signedAttachments,
              unsignedAttachments: _vm.unsignedAttachments
            },
            on: {
              close: function($event) {
                return _vm.$refs.multipleFilesModal.close()
              }
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "downloadErrorModal",
          attrs: {
            modalTitle: "Fehler",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_c("label", [_vm._v(_vm._s(_vm.downloadErrorMessage))])]
      ),
      _c("PreviewPDF", {
        ref: "resizablePopup",
        attrs: {
          withBackdropLayer: "",
          saveLastPosition: false,
          pdfUrl: _vm.pdfUrl,
          id: "6de58b11-331c-4f24-b636-a4c58d3f8bcf",
          fullClientHeight: "",
          startPosition: "TOP_RIGHT"
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "modalDownloadPin",
          attrs: {
            modalTitle: _vm.fileMitPIN
              ? "Download PIN geschützte Datei"
              : _vm.tempFileName,
            showConfirmButton: true,
            labelButtonConfirm: "Herunterladen",
            labelButtonCancel: "Abbrechen",
            autoClose: false
          },
          on: { onConfirmButton: _vm.downloadPinFile, close: _vm.onCloseModal }
        },
        [
          _c("p", [
            _vm._v(
              "Diese Statistik ist zu umfangreich um dargestellt zu werden."
            ),
            _c("br"),
            _vm._v(" Sie können aber eine CSV-Datei herunterladen. ")
          ]),
          _vm.fileMitPIN
            ? _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.downloadPinFile()
                    }
                  }
                },
                [
                  _c("InputField", {
                    attrs: {
                      label: "Bitte geben Sie die PIN ein",
                      placeholder: "Pin"
                    },
                    model: {
                      value: _vm.tempFilePin,
                      callback: function($$v) {
                        _vm.tempFilePin = $$v
                      },
                      expression: "tempFilePin"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "fc-form-danger" }, [
            _vm._v(" " + _vm._s(_vm.errorPinFile) + " ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }