import VV_TYPES from './types';
import Vue from 'vue';
import { getInitialState } from './index'


export default {
  [VV_TYPES.MUTATIONS.GET_MENU_SUCCESS](state, payload) {
    Vue.set(state, 'menu', Object.assign({}, payload || {}));
  },
  [VV_TYPES.MUTATIONS.GET_MISSING_LEGITIMATIONS_SUCCESS](state, payload) {
    Vue.set(state, 'missingLegitimations', Object.assign({}, payload || {}))
  },
  [VV_TYPES.MUTATIONS.GET_STRATEGIEN_SUCCESS](state, payload) {
    Vue.set(state, 'strategien', Object.assign([], payload?.rows || []));
    Vue.set(state, 'strategienEditRights', payload?.strategienEditRights);
    Vue.set(state, 'hatAdvisorRechte', payload?.hatAdvisorRechte);
  },

  [VV_TYPES.MUTATIONS.SET_STRATEGIEN_FILTERS](state, payload) {
    Vue.set(state, 'strategienFilters', Object.assign({}, payload || {}));
  },

  [VV_TYPES.MUTATIONS.GET_STRATEGIEN_FILTER_ITEMS_SUCCESS](state, payload) {
    state.strategienFilterItems = payload || [];
  },

  [VV_TYPES.MUTATIONS.RESET_STRATEGIEN_SCHEMA_DATA](state) {
    Vue.set(state, 'strategienSchemaData', {});
  },

  [VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_SUCCESS](state, payload) {
    Vue.set(state, 'strategienSchemaData', Object.assign({}, payload || {}));
  },

  [VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_LOADING](state, payload) {
    state.strategienSchemaDataLoading = payload;
  },

  [VV_TYPES.MUTATIONS.WERTPAPIER_BEANTRAGEN_DATA_SUCCESS](state, payload) {
    Vue.set(state, 'wertpapierBeantragenData', Object.assign({}, payload || {}));
  },

  [VV_TYPES.MUTATIONS.SAVE_COMMENT_SUCCESS](state, payload) {
    if (payload.versionId) {
      const data = state.strategienSchemaData || {};
      const index = data.varianten?.findIndex(variante => variante.versionId === payload.versionId);
      if (index >= 0) {
        data.varianten[index] = payload;
      }

      Vue.set(state, 'strategienSchemaData', Object.assign({}, data));
    }
  },

  [VV_TYPES.MUTATIONS.UPLOAD_SCHEMA_DOCUMENT_SUCCESS](state, files) {
    const data = state.strategienSchemaData || {};
    data.files = files;
    Vue.set(state, 'strategienSchemaData', Object.assign({}, data));
  },

  [VV_TYPES.MUTATIONS.UPDATE_SCHEMA_DATA_STORE](state, { data, category }) {
    if (category && data) {
      const categoryData = Object.assign(state.strategienSchemaData[category], data);
      state.strategienSchemaData[category] = categoryData
      Vue.set(state, 'strategienSchemaData', Object.assign(state.strategienSchemaData, { dataHasChanged: true }));
    } else {
      Vue.set(state, 'strategienSchemaData', Object.assign(state.strategienSchemaData, data, { dataHasChanged: true }));
    }
  },

  [VV_TYPES.MUTATIONS.UPDATE_VERWAHRSTELLE_SETTINGS](state, data) {
    Vue.set(state, 'strategienSchemaData.settings', Object.assign(state.strategienSchemaData.settings, data));
  },
  
  [VV_TYPES.MUTATIONS.UPDATE_SCHEMA_DATA_VARIANTE_STORE](state, { versionId, data = {} }) {
    // if (versionId && data) {
    if (Object.keys(data).length) {
      let variante, index;
      if (versionId && versionId !== '0') {
        index = state.strategienSchemaData?.varianten?.findIndex(variante => variante.versionId === versionId);
        if (index >= 0) {
          variante = Object.assign(state.strategienSchemaData?.varianten[index], data);
        }
      } else if (versionId === '0') {
        if (!state.strategienSchemaData?.varianten?.length) {
          state.strategienSchemaData.varianten = [];
          variante = { versionId: '0' };
        } else {
          variante = state.strategienSchemaData.varianten[0];
        }
        variante = Object.assign(variante, data);
        index = 0;
      } else {
        return;
      }
      if (variante) {
        state.strategienSchemaData.varianten[index] = variante;
        state.strategienSchemaData.dataHasChanged = true;
      }
    }
  },

  [VV_TYPES.MUTATIONS.UPDATE_SCHEMA_DATA_VARIANTE_POSITION_STORE](state, { versionId, positionId, data }) {
    if (versionId && positionId && data) {
      if (state.strategienSchemaData?.varianten) {
        const indexVariante = state.strategienSchemaData.varianten.findIndex(variante => variante.versionId === versionId);

        if (indexVariante >= 0) {
          const variante = state.strategienSchemaData.varianten[indexVariante];

          if (variante?.positions?.length) {
            const indexPosition = variante.positions.findIndex(pos => pos.id === positionId);

            if (indexPosition >= 0) {
              const position = Object.assign(variante.positions[indexPosition], data);
              variante.positions[indexPosition] = position;
              state.strategienSchemaData.varianten[indexVariante] = variante;
              Vue.set(state, 'strategienSchemaData', Object.assign(state.strategienSchemaData, { dataHasChanged: true }));
            }
          }
        }
      }
    }
  },

  [VV_TYPES.MUTATIONS.GET_ARCHIV_LIST_SUCCESS](state, data = {}) {
    Vue.set(state, 'vvArchiv', data);
  },

  [VV_TYPES.MUTATIONS.SET_UPDATE_VARIANTEN_STEP](state, isUpdateSubstep) {
    Vue.set(state, 'strategienSchemaData', Object.assign({}, state.strategienSchemaData, { isUpdateSubstep: isUpdateSubstep }));
  },

  [VV_TYPES.MUTATIONS.SAVE_BESCHWERDE_SUCCESS](state, data = {}) {
    Vue.set(state, 'beschwerde', data);
  },

  [VV_TYPES.MUTATIONS.RESET_BESCHWERDE](state) {
    Vue.set(state, 'beschwerde', null);
  },

  [VV_TYPES.MUTATIONS.PUT_SUBSCRIPTION](state, payload) {
    state.subscription = { ...payload };
  },

  [VV_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [VV_TYPES.MUTATIONS.DELETE_WERTPAPIER_SUCCESS](state, data) {
    let variante = state.strategienSchemaData?.varianten?.find(variante => variante.versionId === data.versionId);

    if (variante) {
      variante.positions = variante.positions.filter(pos => pos.id !== data.positionId);
    }
  },

  [VV_TYPES.MUTATIONS.GET_ZIELMARTKT_DATA_UPDATE_SUCCESS](state, data) {
    let variante = state.strategienSchemaData?.varianten?.find(variante => variante.versionId === data.versionId);

    if (variante) {
      variante.zielmarktdaten.tableRows = data.tableRows;
      variante.richtbandbreiten = data.richtbandbreiten;
    }
  },
  [VV_TYPES.MUTATIONS.GET_SUITABILITYRESULTLIST_SUCCESS](state, data) {
    Vue.set(state, 'suitabilityResultList', data);
  },
  [VV_TYPES.MUTATIONS.GET_SUITABILITYSIGNATURES_SUCCESS](state, data) {
    Vue.set(state, 'suitabilitySignatures', data);
  },
  [VV_TYPES.MUTATIONS.GET_SUITABILITYRESULT_SUCCESS](state, data) {
    Vue.set(state, 'suitabilityResult', data);
  },
  [VV_TYPES.MUTATIONS.GET_PORTFOLIO_LIST_SUCCESS](state, data) {
    Vue.set(state, 'portfolioList', data);
  },
}