var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label: "aktueller Bestand gem. Depotpositionen:",
          disabled: true,
          isComponentHalfSize: "",
          type: "currency"
        },
        model: {
          value: _vm.form.depotbestand,
          callback: function($$v) {
            _vm.$set(_vm.form, "depotbestand", $$v)
          },
          expression: "form.depotbestand"
        }
      }),
      _vm.showBondDepotRelevant
        ? _c(
            "div",
            [
              _c("InputField", {
                attrs: {
                  label:
                    "beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)",
                  type: "currency",
                  isComponentHalfSize: "",
                  disabled: _vm.disabled
                },
                on: {
                  input: function($event) {
                    return _vm.updateStore("bondDepot", $event || null)
                  }
                },
                model: {
                  value: _vm.form.bondDepot,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "bondDepot", $$v)
                  },
                  expression: "form.bondDepot"
                }
              }),
              _c("InputField", {
                attrs: {
                  label:
                    "nicht beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)",
                  type: "currency",
                  isComponentHalfSize: "",
                  disabled: _vm.disabled
                },
                on: {
                  input: function($event) {
                    return _vm.updateStore(
                      "bondDepotNotRelevant",
                      $event || null
                    )
                  }
                },
                model: {
                  value: _vm.form.bondDepotNotRelevant,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "bondDepotNotRelevant", $$v)
                  },
                  expression: "form.bondDepotNotRelevant"
                }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c("InputField", {
                staticClass: "font-bold",
                attrs: {
                  label:
                    "Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)",
                  type: "currency",
                  isComponentHalfSize: "",
                  disabled: _vm.disabled
                },
                on: {
                  input: function($event) {
                    return _vm.updateStore("bondDepot", $event || null)
                  }
                },
                model: {
                  value: _vm.form.bondDepot,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "bondDepot", $$v)
                  },
                  expression: "form.bondDepot"
                }
              })
            ],
            1
          ),
      _c("InputField", {
        attrs: {
          label: "gebundene Spareinlagen (z.B. Sparbuch, Festgeld)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("boundBankDeposit", $event || null)
          }
        },
        model: {
          value: _vm.form.boundBankDeposit,
          callback: function($$v) {
            _vm.$set(_vm.form, "boundBankDeposit", $$v)
          },
          expression: "form.boundBankDeposit"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Alternative Investments",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("alternativeInvestment", $event || null)
          }
        },
        model: {
          value: _vm.form.alternativeInvestment,
          callback: function($$v) {
            _vm.$set(_vm.form, "alternativeInvestment", $$v)
          },
          expression: "form.alternativeInvestment"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Kunstgegenstände, Antiquitäten",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("art", $event || null)
          }
        },
        model: {
          value: _vm.form.art,
          callback: function($$v) {
            _vm.$set(_vm.form, "art", $$v)
          },
          expression: "form.art"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Bausparverträge (aktuelle Werte)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("buildingSaving", $event || null)
          }
        },
        model: {
          value: _vm.form.buildingSaving,
          callback: function($$v) {
            _vm.$set(_vm.form, "buildingSaving", $$v)
          },
          expression: "form.buildingSaving"
        }
      }),
      _c("InputField", {
        attrs: {
          label:
            "Lebensversicherungen, Kapitalversicherungen (aktuelle Rückkaufswerte)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("insurance", $event || null)
          }
        },
        model: {
          value: _vm.form.insurance,
          callback: function($$v) {
            _vm.$set(_vm.form, "insurance", $$v)
          },
          expression: "form.insurance"
        }
      }),
      _c("InputField", {
        staticClass: "font-bold",
        attrs: {
          label: "Sonstiges",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("otherInvestment", $event || null)
          }
        },
        model: {
          value: _vm.form.otherInvestment,
          callback: function($$v) {
            _vm.$set(_vm.form, "otherInvestment", $$v)
          },
          expression: "form.otherInvestment"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }