<template>
  <div>
    <MultiBankingNavigation subtitle="Contracts" />

    <div class="box__container">
      <ContractsList :contracts="contractsData"/>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import KontoverlaufGraph from '@/components/multiBanking/KontoverlaufGraph.vue'
import ContractsList from '@/components/multiBanking/ContractsList.vue'
import LoginNeeded from '@/components/multiBanking/LoginNeeded.vue'
import { PhWarning } from 'phosphor-vue'
import MultiBankingNavigation from '@/components/multiBanking/MultiBankingNavigation.vue';
import MultiBankingFilter from '@/components/multiBanking/MultiBankingFilter.vue';

export default {
  components: {
    KontoverlaufGraph,
    LoginNeeded,
    PhWarning,
    MultiBankingNavigation,
    MultiBankingFilter,
    ContractsList,
  },

  props: {
    /*accountId: {
      default: null,
    },*/
  },

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters({
      contractsData: MULTIBANKING_TYPES.GETTERS.LOAD_CONTRACTS,
    }),
    title() {
      return 'regelmäßige Zahlungen'
    },
  },
}
</script>

<style scoped>
.kontoverlauf__account-filter {
  flex-grow: 1;
  align-self: center;
}
</style>