<template>
  <div :class="!isSmallScreen ? 'box__container' : 'box__container__smallscreen'">
    <BaseCollapsable :showSection="showExpanded">
     <template v-slot:title>
        <span class="box__title">
          Hinweise und Fehler
        </span>
        <span v-if="hasHinweis">&nbsp;|&nbsp; ({{hinweise.length}} Hinweis{{hinweise.length > 1 ? 'e' : ''}})</span>
        <span v-if="hasWarnings" class="color-danger">&nbsp;|&nbsp; Das Formular enthält Fehler</span>
      </template>
      <template v-slot:content>
        <div class="antrag-sidetext__container mt-8px" v-if="!hasHinweis && !hasWarnings">
          <div class="row">
            <label class="col-12">
              Keine Hinweise vorhanden.
            </label>
          </div>
        </div>
        <div class="antrag-sidetext__container mt-8px" v-if="hasHinweis || hasWarnings">
          <template v-if="hasWarnings">
            <div class="row">
              <div class="col-12">
                <span class="font-bold">Fehler:</span>
              </div>
            </div>
            <ul>
              <div class="row" v-for="(warning, index) of warnings" :key="index">
                <div class="col-12" 
                  :class="{clickable: isWarningNavigable(warning), 'color-danger': true}"
                  @click="navigateToStepWithWarning(warning)">
                  <li>
                    <span class="font-bold">{{getWarningTitle(warning)}}</span>
                    <span>{{getWarningMessage(warning)}}</span>
                    <span v-if="isWarningNavigable(warning)"><ph-pencil-line :size="16" /></span>
                  </li>
                </div>
              </div>
            </ul>
          </template>
          <template v-if="hasHinweis">
            <div class="row">
              <div class="col-12">
                <span class="font-bold">Hinweise:</span>
              </div>
            </div>
            <div class="row" v-for="(hinweis, index) of hinweise" :key="index">
              <div class="col-12">
                <span class="font-bold" v-if="hinweis && hinweis.label">{{ hinweis.label + ' : '}}</span> {{ hinweis.text }}
              </div>
            </div>
          </template>
          <template v-if="hasFinanceSummary">
            <FinanceSummary></FinanceSummary>
          </template>
        </div>
      </template>
    </BaseCollapsable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import hinweise from '@/mixins/anlegerprofil/hinweise.js';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import FinanceSummary from '@/components/anlegerprofil/FinanceSummary.vue';
import BaseCollapsable from '@/components/core/BaseCollapsable.vue'
import { PhPencilLine } from 'phosphor-vue'
import CORE_TYPES from "@/store/core/types";

export default {
  mixins: [hinweise, anlegerprofilMixin],
  components: {
    FinanceSummary,
    BaseCollapsable,
    PhPencilLine
  },
  props: {
    hasFinanceSummary: {
      type: Boolean,
      default: false,
    },
    showExpanded:{}
  },
  computed: {
    ...mapGetters({
      _warnings: ANLEGERPROFIL_TYPES.GETTERS.WARNINGS,
      data: ANLEGERPROFIL_TYPES.GETTERS.ANLEGERPROFIL,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
    }),
    hasWarnings() {
      return this.warnings?.length > 0;
    },
    hasHinweis() {
      return this.hinweise?.length
    },
    hinweise() {
      return this.getHinweise(this.getSubstepKey, this.data && this.data.extraData?.schiene); 
    },
    warnings() {
      if (this.getStepKey === 'aktionen' && this._warnings?.length) {
        return (this._warnings || []).sort((a,b) => a.title?.localeCompare(b.title));
      }
      return this._warnings?.length && this._warnings
        .filter(warn => (this.isPersonenAuswahlStep(this.getStepKey) || warn.personType === this.getPersonType) && warn.stepKey === this.getStepKey)
        .filter(warn => !warn.substepKey || warn.substepKey.includes(this.getSubstepKey));
    },
  },
  methods: {
    isSimpleWarning(warning) {
      return typeof warning === 'string';
    },
    getDescription(warning) {
      if (warning) {
        return this.isSimpleWarning(warning) ? warning : this.getWarningMessage(warning);
      }
    },
    getWarningTitle(warning) {
      return warning?.title ? (warning?.title + ': ') : '';
    },
    getWarningMessage(warning) {
      return warning?.message || ''
    },
    isWarningNavigable(warning) {
      return (this.getStepKey === 'aktionen' && warning?.stepKey !== 'aktionen' && warning?.title) 
        || this.isCustomerDataLink(warning);
    },
    navigateToStepWithWarning(warning){
      if (this.isCustomerDataLink(warning)) {
        this.goToCustomerData(warning)
      } else {
        this.$emit('goToWarning', warning);
      }
    },
  }
}
</script>
<style scoped>
</style>
