<template>
  <div>
    <FinanzenWeitere :disabled="isDisabled || isRefusedPageFinance" :finance="finance" :isAnlegerprofil="true" 
      :isWealthKonzept="isWealthKonzept" :isFA="isFA" @changed="valueChangedFinance"/>
  </div>
</template>

<script>

import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import FinanzenWeitere from '@/components/anlegerprofil/FinanzenWeitere.vue';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    FinanzenWeitere,
  },
  mounted() {
    this.updateWarnings();
  },
}
</script>

<style scoped>
</style>