<template>
  <div class="customer-settings-view">
    <PageHeaderTitleNavigation :title="parentTitle" />
    <HinweiseUndFehler v-if="hints" :hints="hints" />
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 col-12">
        <div class="box__container">
          <Stepper :steps="$appNavigation.currentTabMenu" :currentStep="$route"></Stepper>
        </div>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12 col-12">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Stepper from '@/components/persoenliche-daten/components/Stepper.vue';
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";


export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Stepper,
    HinweiseUndFehler,
  },
  computed: {
    ...mapGetters({
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
    }),
    parentTitle() {
      return this.$appNavigation.currentMenu?.parent?.label;
    },
    hints() {
      if (this.isCustomer && this.$route.path.includes('/settings/access-management')) {
        return [
          {
            title: 'Zugang freigeschaltet',
            message: `Über die primäre E-Mail Adresse und Mobilnummer kann der Kunde sein Passwort neu setzen und so den Zugang wieder freischalten.<br>
Stellen Sie dazu bitte sicher, dass die E-Mail Adresse und Mobilnummer des Kunden korekt eingetragen sind.`
          },
          {
            title: 'Kunde ist inaktiv',
            message: `Der Kunde kann sich nicht mehr einloggen und keine E-Mails und / oder Nachrichten aus der FinanceCloud / FinanceApp heraus empfangen und senden.`
          },
        ]
      }
      return null;
    }
  },
}
</script>
