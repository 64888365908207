<template>
  <div>
    <PageHeaderTitleNavigation
      title="Vermögensverwaltungen"
      subtitle="Produktliste"
      :defaultMenu="noHeaderMenu ? [] : $appNavigation.currentTabMenu"
      :actions="headerActions"
      @action-NEW="openRegularFondsfinder"
    />

    <div class="box__container">
      <p>
        Für die Zusammenstellung Ihrer persönlichen Strategie benötigen Sie vom
        Vermögensverwalter überprüfte und bestätigte Investmentfonds. Unter den
        unten aufgeführten Reitern können Sie sich einen Überblick über bereits
        zertifizierte, beantrage oder abgelehnte Wertpapiere schaffen. Sollten die
        von Ihnen gewünschten Wertpapiere fehlen, bitten wir Sie diese über
        <b>„Neues Wertpapier vorschlagen“</b> dem Vermögensverwalter zur
        Zertifizierung vorzulegen. Bitte beachten Sie, dass Sie den Status auf
        „Beantragt“ setzen. Der Status „in Vorbereitung“ dient ausschließlich
        Ihrem Auswahlverfahren. Bitte beachten Sie, dass der von Ihnen gewünschte
        Fonds ein Fondsvolumen von mindestens 30 Mio € und älter als drei Jahre
        sein sollte. Unter Bemerkungen können Sie weitere Informationen zum Fonds
        eingeben. Nach der Überprüfung des Wertpapiers, durch den
        Vermögensverwalter, erhalten Sie eine Benachrichtigung per Mail über den
        Status des Fonds.
      </p>

      <InputToggleSwitch
        v-if="isIntern && !isSelectable"
        label="Nur die Wertpapiere zur Prüfung zeigen" 
        v-model="showWertpapierForCheck" 
        @input="search()"
      />
    </div>

    <WertpapierSearch 
      :name="name" 
      @selected="selectedRows = $event"
      :respond="isSelectable ? 1 : 2" 
      :isSelectable="isSelectable"
      @onSearch="search"
      @handleTableAction="handleTableAction"
      @confirm-selected="navigateToEditor"
    />
  </div>
</template>

<script>
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import CORE_TYPES from "@/store/core/types";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";
import { PageHeaderSimpleAction } from "@/components/core/header-title-navigation/page-header-utils";
import { mapGetters } from "vuex";
import BaseButton from '@/components/core/BaseButton.vue';
import WertpapierSearch from '@/components/wertpapierinfo/WertpapierSearch.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
  mixins: [mixin],
  props: {
    noHeaderMenu: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseButton,
    WertpapierSearch,
    OptionMenu,
    PageHeaderTitleNavigation,
    InputToggleSwitch,
  },
  data() {
    return {
      showWertpapierForCheck: false,
      selectedRows: [],
    };
  },
  mounted() {
    setTimeout(() => this.search(), 200);
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    headerActions() {
      return [PageHeaderSimpleAction("NEW", "Neues Wertpapier vorschlagen")];
    },
    name() {
      return `search_${this.$route.query.categoryId}`
    },
    act() {
      return `${this.name}..`;
    },
    isSelectable() {
      return !!this.$route.params.versionId;
    },
  },
  methods: {
    openRegularFondsfinder() {
      this.$addBreadcrumb({
        label: "zurück zur Liste der verfügbaren Wertpapiere",
        fullPath: this.$route.fullPath,
        breadcrumb: "Wertpapiersuche",
      });
      this.$router.push({
        path: `/${this.basePath}/vv/fondsfinder-neu/${this.$route.params.schemaId}/${this.$route.params.versionId}`,
        query: { categoryId: `neu_${this.$route.query.categoryId}` },
      });
    },
    search(event) {
      event?.onInitLoading()

      this.$store.dispatch(VV_TYPES.ACTIONS.VV_GET_AVAILABLE_FONDS, {
        versionId: this.$route.params.versionId,
        act: this.act,
        showWertpapierForCheck: this.showWertpapierForCheck,
        showOnlyGenehmigt: this.isSelectable ? "1" : "0",
      }).finally(() => event?.onLoadingDone())
    },
    navigateToEditor() {
      if (this.selectedRows?.length) {
        this.$store.dispatch(VV_TYPES.ACTIONS.ADD_GENEHMIGT_WERTPAPIERE, {
          versionId: this.$route.params.versionId,
          isins: this.selectedRows.map((fond) => fond.isin),
        });
      }
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE);
      this.$router.push({
        path: `/${this.basePath}/vv/vv-schema-editor/${this.$route.params.schemaId}/${this.$route.params.versionId}`,
        query: {
          backAction: true,
          substepKey: `${this.$route.params.versionId}`,
        },
      });
    },
  },
};
</script>

<style>
</style>