import COMMUNICATION_TYPES from './types';
import dayjs from 'dayjs'

export default {
  [COMMUNICATION_TYPES.GETTERS.TEMPLATE](state) {
    return state.template;
  },
  [COMMUNICATION_TYPES.GETTERS.COMMUNICATIONS_UNREAD](state) {
    return Object.values(state.unreadMessages).reduce((a, b) => a + b, 0);
  },
  [COMMUNICATION_TYPES.GETTERS.COMMUNICATIONS_UNREAD_CHATS](state) {
    return state.unreadMessages.chats || 0;
  },
  [COMMUNICATION_TYPES.GETTERS.COMMUNICATION_INSURANCE_RECORDS](state) {
    return state.communicationInsuranceRecords;
  },
  [COMMUNICATION_TYPES.GETTERS.EMAILS](state) {
    return state.emails;
  },
  [COMMUNICATION_TYPES.GETTERS.SMSS](state) {
    return state.smss;
  },
  [COMMUNICATION_TYPES.GETTERS.TALKS](state) {
    return state.talks;
  },
  [COMMUNICATION_TYPES.GETTERS.TALK_CATEGORIES](state) {
    return state.talkCategories;
  },
  [COMMUNICATION_TYPES.GETTERS.SANITIZE_HTML_CONFIG](state) {
    return state.sanitizeHtmlConfig;
  },
  [COMMUNICATION_TYPES.GETTERS.DEFAULT_RECEIVER](state) {
    return state.defaultReceiver;
  },
  [COMMUNICATION_TYPES.GETTERS.TICKETS_NACHRICHTEN](state) {
    return state.ticketsNachrichten;
  },
  [COMMUNICATION_TYPES.GETTERS.TICKETS_NACHRICHTEN_LAZY](state) {
    return state.ticketsNachrichtenLazy;
  },
  [COMMUNICATION_TYPES.GETTERS.TICKETS_NACHRICHTEN_BEARBEITER](state) {
    return state.ticketsNachrichtenBearbeiter;
  },
  [COMMUNICATION_TYPES.GETTERS.TICKETS_NACHRICHTEN_STATUSES](state) {
    return state.ticketsNachrichtenStatuses;
  },
  [COMMUNICATION_TYPES.GETTERS.TICKETS_NACHRICHTEN_GROUP_RESTRICTIONS](state) {
    return state.ticketsNachrichtenGroupRestrictions;
  },
  [COMMUNICATION_TYPES.GETTERS.TICKET_INFO](state) {
    return state.ticketInfo;
  },
  [COMMUNICATION_TYPES.GETTERS.BEWERTUNG_INFO](state) {
    return state.bewertungInfo;
  },
  [COMMUNICATION_TYPES.GETTERS.TICKET_CHATPARTNERS](state) {
    return state.chatPartnerOptions;
  },
  [COMMUNICATION_TYPES.GETTERS.TICKET_BEARBEITERS](state) {
    return state.bearbeiterOptions;
  },

  [COMMUNICATION_TYPES.GETTERS.CONFIGURED_CHATPARTNER](state) {
    return { ...(state.configuredChatpartner || {}) };
  },
  [COMMUNICATION_TYPES.GETTERS.TICKETS_NACHRICHTEN_CAN_EXECUTE_LAZY_LOAD](state) {
    return () => {
      if (!state.ticketsNachrichtenLastLoadedTime) {
        return true;
      }

      const nextLoadTime = dayjs(state.ticketsNachrichtenLastLoadedTime).add(3, 'minute');
      const isAfter = dayjs().isAfter(nextLoadTime);
      return isAfter;
    }
  },
  [COMMUNICATION_TYPES.GETTERS.POSTFACH_DEFAULT_FILTERS]() {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 28);

    const toDate = new Date();
    toDate.setDate(toDate.getDate() + 13);

    return {
      fromDate, 
      toDate, 
    };
  },
  [COMMUNICATION_TYPES.GETTERS.UNSENT_NACHRICHT](state) {
    return state.unsentNachricht;
  },
  [COMMUNICATION_TYPES.GETTERS.ABSENCE_NOTE](state) {
    return state.absenceNote;
  },

  [COMMUNICATION_TYPES.GETTERS.CAN_EDIT_THEMA](state) {
    return state.canEditThema;
  },
  
}