export const MUTATION_PREFIX = 'RUHESTANDSPLANUNG_MUTATIONS_';
export const ACTIONS_PREFIX = 'RUHESTANDSPLANUNG_ACTIONS_';
export const GETTERS_PREFIX = 'RUHESTANDSPLANUNG_GETTERS_';

export default {
  MUTATIONS: {
    GET_SCENARIO_SUCCESS: MUTATION_PREFIX + 'GET_SCENARIO_SUCCESS',
    GET_SCENARIO_LIST_SUCCESS: MUTATION_PREFIX + 'GET_SCENARIO_LIST_SUCCESS',
    GET_VERMOEGEN_SUCCESS: MUTATION_PREFIX + 'GET_VERMOEGEN_SUCCESS',
    UPDATE_STORE: MUTATION_PREFIX + 'UPDATE_STORE',
    UPDATE_VERMOEGEN_AUSGABEN: MUTATION_PREFIX + 'UPDATE_VERMOEGEN_AUSGABEN',
    UPDATE_SELECTED_GOALS: MUTATION_PREFIX + 'UPDATE_SELECTED_GOALS',
    SCENARIO_URSPRUNG: MUTATION_PREFIX + 'SCENARIO_URSPRUNG',
    RESET_SCENARIO: MUTATION_PREFIX + 'RESET_SCENARIO',
  },
  ACTIONS: {
    GET_SCENARIO: ACTIONS_PREFIX + 'GET_SCENARIO',
    GET_SCENARIO_LIST: ACTIONS_PREFIX + 'GET_SCENARIO_LIST',
    DELETE_SCENARIO: ACTIONS_PREFIX + 'DELETE_SCENARIO',
    SAVE_SCENARIO: ACTIONS_PREFIX + 'SAVE_SCENARIO',
    GET_VERMOEGEN: ACTIONS_PREFIX + 'GET_VERMOEGEN',
    SAVE_GOAL_SCENARIO: ACTIONS_PREFIX + 'SAVE_GOAL_SCENARIO',
    REMOVE_GOAL_SCENARIO: ACTIONS_PREFIX + 'REMOVE_GOAL_SCENARIO',
    GET_PDF: ACTIONS_PREFIX + 'GET_PDF',
  },
  GETTERS: {
    SCENARIO: GETTERS_PREFIX + 'SCENARIO',
    SCENARIO_LIST: GETTERS_PREFIX + 'SCENARIO_LIST',
    VERMOEGEN: GETTERS_PREFIX + 'VERMOEGEN',
    AUSGABEN: GETTERS_PREFIX + 'AUSGABEN',
    COMBOBOX_GOAL_TYPES: GETTERS_PREFIX + 'COMBOBOX_GOAL_TYPES',
    SCENARIO_URSPRUNG: GETTERS_PREFIX + 'SCENARIO_URSPRUNG',
  }
}