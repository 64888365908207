var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ResizableModal",
    {
      ref: "resizablePopup",
      attrs: {
        modalTitle: _vm.modalTitle,
        initialWidth: _vm.initialWidth,
        initialHeight: _vm.initialHeight,
        sizeIsPercentage: _vm.sizeIsPercentage,
        startPosition: _vm.startPosition,
        fullClientHeight: _vm.fullClientHeight,
        saveLastPosition: _vm.saveLastPosition,
        withBackdropLayer: _vm.withBackdropLayer,
        id: _vm.id
      },
      on: {
        onMinimize: _vm.resizePDFContainer,
        onMaximize: _vm.resizePDFContainer,
        onResize: _vm.resizePDFContainer
      }
    },
    [
      _c(
        "div",
        {
          ref: "objectContainer",
          staticClass: "object-container",
          class: { "ios-style": _vm.isIOS }
        },
        [
          _vm.pdfUrl
            ? _c(
                "object",
                {
                  class: { "ios-style": _vm.isIOS },
                  attrs: {
                    data: _vm.pdfUrl,
                    type: "application/pdf",
                    width: "100%",
                    height: "100%"
                  }
                },
                [
                  _vm._v(
                    " This browser does not support PDFs. Please download the PDF to view it: "
                  ),
                  _c("a", { attrs: { href: _vm.pdfUrl } }, [
                    _vm._v("Download PDF")
                  ]),
                  _vm._v(". ")
                ]
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }