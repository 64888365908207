var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container-wrap" }, [
    _c(
      "div",
      { staticClass: "box__container-rows" },
      [
        _vm.rows.length
          ? _c("Table", {
              attrs: {
                tableId: "f0470771-ba6e-49a6-b5d9-7d60142b3cc4",
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "kontonr",
                ignoreTablePageSize: "",
                rowsPerPage: 5,
                tableRowsPerPage: [],
                hidePagination: _vm.$isSmallScreen,
                mobileConfig: _vm.mobileTableConfig
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "gebuehrProz",
                    fn: function(row) {
                      return [
                        row.gebuehrProzMin != row.gebuehrProzMax
                          ? [
                              _c("NumberCell", {
                                attrs: {
                                  row: row,
                                  column: "gebuehrProzMin",
                                  symbol: "%"
                                }
                              }),
                              _c("span", [_vm._v("-")])
                            ]
                          : _vm._e(),
                        _c("NumberCell", {
                          attrs: {
                            row: row,
                            column: "gebuehrProzMax",
                            symbol: "%"
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "rabatt",
                    fn: function(row) {
                      return [
                        row.rabattMin != row.rabattMax
                          ? [
                              _c("NumberCell", {
                                attrs: {
                                  row: row,
                                  column: "rabattMin",
                                  symbol: "%"
                                }
                              }),
                              _c("span", [_vm._v("-")])
                            ]
                          : _vm._e(),
                        _c("NumberCell", {
                          attrs: { row: row, column: "rabattMax", symbol: "%" }
                        })
                      ]
                    }
                  }
                ],
                null,
                false,
                4188388644
              )
            })
          : _c("NoData", { attrs: { noIcon: "" } })
      ],
      1
    ),
    !_vm.$isSmallScreen
      ? _c("div", { staticClass: "bottom-row" }, [
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c("router-link", { attrs: { to: "/home/depot-gebuehr" } }, [
                _vm._v("Manuell hinterlegen")
              ])
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }