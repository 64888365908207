import FONDSINFO_TYPES from './types';
import { getInitialState } from "./index";
import Vue from 'vue';

export default {
  [FONDSINFO_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_DETAILS_SUCCESS](state, payload) {

    const getAttributeName = (name) => name.replace(' ', '')?.toLowerCase();

    let tempObject = {};

    payload.outputs.forEach(section => {
      if(!section.headerLabels || !section.headerLabels.length) {
        const idNumbersClassifications = section.records.flatMap(tuple => tuple.map((curr, index) => 
          ({
            label: section.tableHeaders[index].key,
            value: curr, 
            dataType: section.tableHeaders[index].dataType
          })
        ));
        Object.assign(tempObject, {kennnummernUndKlassifizierung: idNumbersClassifications});
      } else {
        const item = {
          label: section.headerLabels[0],
          records: section.records.map(row => ({type: row[0], value: row[1]})),
          footerLabels: section.footerLabels,
        }
  
        Object.assign(tempObject, {[getAttributeName(item.label)] : item});
      }


    });

    const finalObject =  {...{namme: payload.name}, ...tempObject}

    Vue.set(state, 'fundsDetail', Object.assign({}, finalObject));
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_ALGEMEIN_SUCCESS](state, payload) {
    Vue.set(state, 'allgemeinDescription', payload)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_AUSSCHUTTUNG_SUCCESS](state, payload) {
    Vue.set(state, 'ausschuttung', payload)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_STEUERLICHES_ERGEBNIS_SUCCESS](state, payload) {
    Vue.set(state, 'steuerlichesErgebnis', payload.list)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE_SUCCESS](state, payload) {
    Vue.set(state, 'erbschaftssteuerlicheWerte', payload.data)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_EINZAHLUNGEN_SUCCESS](state, payload) {
    Vue.set(state, 'einzahlungen', payload.list)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_MITTELHERKUNFT_SUCCESS](state, payload) {
    Vue.set(state, 'mittelherkunft', payload)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_INVESTITIONSKOSTEN_SUCCESS](state, payload) {
    Vue.set(state, 'investitionskosten', payload)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_DOKUMENTE_SUCCESS](state, payload) {
    Vue.set(state, 'dokumente', payload.data)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_PERFORMANCE_SUCCESS](state, payload) {
    Vue.set(state, 'performance', payload)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_RISIKO_SUCCESS](state, payload) {
    Vue.set(state, 'risiko', payload)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_UNTERLAGEN_SUCCESS](state, payload) {
    Vue.set(state, 'unterlagen', payload.data)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_UNTERLAGEN_LINKS_SUCCESS](state, payload) {
    Vue.set(state, 'unterlagenLinks', payload.data)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_RISK_CHART_SUCCESS](state, payload) {
    Vue.set(state, 'riskChart', payload.data)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_PERFORMANCE_CHART_SUCCESS](state, payload) {
    Vue.set(state, 'performanceChart', payload)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_MONTECARLO_CHART_SUCCESS](state, payload) {
    Vue.set(state, 'montecarloChart', payload)
    Vue.set(state, 'montecarloError', "")
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_MONTECARLO_CHART_ERROR](state, payload) {
    Vue.set(state, 'montecarloChart', [])
    Vue.set(state, 'montecarloError', payload)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_PURCHASE_SUCCESS](state, payload) {
    Vue.set(state, 'purchase', payload)
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FAILTURE](state, payload) {
    console.log(payload);
  },
  [FONDSINFO_TYPES.MUTATIONS.GET_FONDSINFO_RENDITE_CHART_SUCCESS](state, payload) {
    Vue.set(state, 'renditeChart', payload)
  },

}
